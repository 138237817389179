.moduleNavigations-main-div{
height: 40px;
padding-top: 7px;

}
.moduleNavigation{
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #7782A1;
    margin-right: 60px;
    padding-bottom: 10px;
}
.moduleNavigation:hover{
    color: var(--custom_color4) !important;
    border-bottom: 2px solid var(--custom_color5) !important;
}
.moduleNavigation-active{
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #3064F9;
    border-bottom: 2px solid #3064F9;
}
@media screen and (max-width: 1200px) {
    .moduleNavigations-main-div{
        display: none;
    }
}