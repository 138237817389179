.chatbg-upgrade {
    background-color: #fff;
    border-radius: 6px!important;
    /* border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important; */
    padding-top: 142px;
    min-height: calc(100vh - 170px);
    background-image: url(../../assets/images/dashboard/app-icon-background.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
}
.chat-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.chat-main-topdiv{
    position: relative;
    min-height: 80vh;
    border-radius: 6px!important;
    border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
}
.chat-main-topdiv .newspinner{
    border-radius: 6px!important;
}
.chat-head h1{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}
.chat-head-right{
    display: flex;
    align-items: center;
    gap:20px;
}
.chat-head-right p{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    margin-right: 20px;
    text-align: center;
}
.chat-head button {
    text-decoration: none;
    border-radius: 3px;
    color: white;
    background-color: #3064f9;
    border: 1px solid #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 18px;
    transition: 0.5s;
    cursor: pointer;
    width: 100px;
    height: 40px;
}
.chat-upgrade-page{
    max-width: 1275px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: auto;
    position: relative;
    left: -5%;
}
.chat-upgrade-left{
    max-width: 470px;
}
.disable-role-btn{
    height: 50px;
    line-height: 48px;
    padding: 0;
    width: 220px;
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    background-color: #fff;
    text-decoration: none;
    border-radius: 4px;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-weight: 500;
    border: 1px solid #E3E6F1;
    transition: .5s;
}

.chat-upgrade-left h3{
    font-family: "Axiforma-Bold";
    font-size: 48px;
    line-height: 69px;
    color: #1B2952;
    padding-top: 50px;
}
.chat-upgrade-left h4{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    line-height: 18px;
    color: #7782A1;
    margin: 0px 0px 26px 0px;
}
.chat-upgrade-left ul{
    padding-left: 15px;
}
.chat-upgrade-left ul li{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    line-height: 23px;
    color: #7782A1;
    margin-bottom: 15px;
}
.chat-upgrade-left a{
    width: 200px;
    height: 50px;
    border-radius: 3px;
    background-color: #3064F9;
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Axiforma-Regular";
    transition: .5s;
    display: block;
    text-align: center;
    line-height: 48px;
}
.chat-upgrade-left a:hover{
    background-color: #134bec;
    color: #FFFFFF;
    transition: .5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.chat-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 6px;
}
.chat-tooltip .chat-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 27px;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    font-family: "Axiforma-Regular";
}
.chat-tooltip:hover .chat-tooltipsubheader {
    visibility: visible;
    text-align: left !important;
}
.chat-tooltip .chat-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 14%;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.chatbg{
    background-color: #fff;
    border-radius: 6px!important;
    /* border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important; */
    min-height: calc(100vh - 170px);
    display: flex;
}
.chat-left{
    width: 50%;
    min-width: 50%;
    padding-left: 20px;
}
.chat-right{
    width: 50%;
    padding: 75px 30px 60px 30px;
    text-align: center;
}
.chat-name-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chat-name-head .chat-name-head-right{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chat-name-head h2{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin: 6px 0px 0px 10px;
}
.chat-type{
    border-bottom: 1px solid #E3E6F1;
    padding: 24px 0px;
}
.chat-name-body{
    padding: 5px 0px 6px 0px;
}
.chat-name-body h4{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 20px 0px 9px 0px;
}
.chat-name-body input{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    font-size: 13px;
    font-family: "Axiforma-Regular2";
    outline: none;
    transition: .5s;
}
.chat-name-body input:hover , .chat-name-body input:focus{
    background-color: #f8fafe;
    border-color: #3064f9 !important;
    outline: none;
    transition: .5s;
}
.chat-setting-bg{
    background-color: #fff;
    border-radius: 6px!important;
    border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
    min-height: calc(100vh - 170px);
    display: flex;
}
.chat-setting-left{
    width: 50%;
    min-width: 50%;
    padding-left: 20px;
}
.chat-setting-right{
    width: 50%;
    padding: 95px 30px 60px 30px;
    text-align: center;
}
.chat-preview-screen-one {
    height: 651px;
    width: 310px;
    background-color: #fff;
    margin: auto;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
    overflow-y: auto;
    overflow-x: hidden;
    border: 4px solid #E3E6F1;
    border-radius: 20px;
    background-image: url(../../assets/images/dashboard/bottombar-canvas.png);
    background-size: auto;
    background-position: 0px 30px;
    position: relative;
}
.chat-setting-header{
    border-bottom: 1px solid #E3E6F1;
}
.chat-setting-header h2{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    margin: 20px 0px 7px 0px;
}
.chat-setting-header h3{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px 0px 19.5px 0px;
}
.chat-setting-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.chat-setting-toggle h5{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
}
.chat-setting-toggle .switch{
    margin-right: 20px;
}
.chat-setting-toggle svg{
    position: relative;
}
.chat-setting-main{
    border-bottom: 1px solid #E3E6F1!important;
}
.chat-setting-main h4{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 17px 0px 9px 0px;
    display: flex;
    align-items: center;
}
.chat-setting-main .head-gap{
    margin-top: 0px!important;
}
.chat-color-input{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #fff;
    padding: 7px 7px 7px 3px!important;
    height: 50px!important;
    transition: .5s!important;
}
.chat-color-input .color_input{
    height: auto!important;
}
.chat-setting-main input{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    font-size: 13px;
    font-family: "Axiforma-Regular2";
    outline: none;
    transition: .5s;
}
.chat-setting-main input:hover , .chat-setting-main input:focus{
    background-color: #f8fafe;
    border-color: #3064f9 !important;
    outline: none;
    transition: .5s;
}
.chat-preview-head-one{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
    height: 39px;
    padding: 0px 15px;
    background-color: #fff;
}
.chat-preview-head-one div{
    display: flex;
}
.chat-preview-head-one h2{
    font-family: 'Axiforma-Bold';
    font-size: 12px;
    color: #333333;
    margin: 0px 0px 0px 15px;
}
.floating-chat-icon{
    position: absolute;
    bottom: 56px;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    overflow: hidden;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.floating-chat-icon img{
    max-width: 20px;
    max-height: 20px;
}
.chat-preview-screen-two {
    height: 651px;
    width: 310px;
    background-color: #fff;
    margin: auto;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
    overflow-y: auto;
    overflow-x: hidden;
    border: 4px solid #E3E6F1;
    border-radius: 20px;
    position: relative;
}
.chat-preview-head-two{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
    height: 39px;
    padding: 0px 15px;
    background-color: #fff;
}
.chat-preview-head-two h2{
    font-family: 'Axiforma-Bold';
    font-size: 12px;
    color: #333333;
    margin: 0px 0px 0px 15px;
}
.chat-intro{
    text-align: center;
    padding: 30px 0px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 6%)!important;
}
.chat-intro h3{
    font-family: "Montserrat-Medium";
    font-size: 12px;
    color: #333333;
}
.chat-intro h4{
    font-family: "Montserrat-Regular";
    font-size: 10px;
    color: #A1A1A1;
}
.chat-list-two-entry{
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0px 15px 0px 18px;
    border-bottom: 1px solid #F2F2F2;
    position: relative;
}
.chat-list-two-entry h5{
    position: absolute;
    top: 22px;
    right: 15px;
    font-family: "Axiforma-Regular";
    font-weight: 600;
    font-size: 11px;
    color: #333333;
    margin: auto;
}
.chat-list-two-entry p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #333333;
    margin: 0px 0px 0px 11px;
    text-align: left;
}
.chat-preview-bottombar{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    border-top: 1px solid #74A4FF;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.chat-preview-bottombar .bottombar-options {
    width: 20%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
}
.chat-preview-bottombar .bottombar-options img {
    margin: auto;
    max-width: 18px;
    max-height: 18px;
    margin-top: 0px;
}
.chat-preview-bottombar .bottombar-options p {
    font-family: "Axiforma-Regular";
    font-weight: 600;
    font-size: 8px;
    margin: 2px 0px 0px 0px;
}
.chat-preview-screen-three {
    height: 651px;
    width: 310px;
    background-color: #FAFAFA;
    margin: auto;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
    overflow-y: auto;
    overflow-x: hidden;
    border: 4px solid #E3E6F1;
    border-radius: 20px;
    position: relative;
}
.chat-preview-head-three{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
    height: 39px;
    padding: 0px 15px;
    background-color: #fff;
}
.chat-preview-head-three h2{
    font-family: 'Axiforma-Bold';
    font-size: 12px;
    color: #333333;
    margin: 0px 0px 0px 15px;
}
.chat-preview-list-three{
    background-color: #fff;
    padding-bottom: 36px;
    box-shadow: 0px 3px 20px rgb(0 0 0 / 6%)!important;
    height: 94%;
}
.chat-preview-list-type-one{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 11px 25px 12px;
}
.chat-preview-list-type-one h4{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #333333;
    margin: 0px 0px 0px 0px;
}
.chat-preview-list-type-two{
    display: flex;
    justify-content: space-between;
    padding: 17px 11px 0px 12px;
}
.chat-preview-list-type-two-gap{
    padding-top: 34px;
}
.chat-preview-list-type-two-gap div{    
    max-width: calc(100% - 60px);
}
.chat-preview-list-type-two h5{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #333333;
    margin: 0px 0px 0px 0px;
    line-height: 15px;
    text-align: left;
}
.chat-preview-list-type-two p{
    font-family: "Axiforma-Regular";
    font-size: 10px;
    color: #A1A1A1;
    margin: 0px 0px 0px 0px;
    line-height: 15px;
    text-align: left;
}
.chat-mob-btns{
    display: none;
}
.chat-setting-accordion .accordion-button{
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 30px 0px;
}
.chat-setting-accordion .collapsed .thechatarrow{
    transform: rotate(180deg);
}
.chat-setting-switch{
    display: flex;
    align-items: center;
}
.chat-setting-accordion .accordion-header{
    margin-bottom: 0px;
    font-size: 0px!important;
}
.chat-setting-accordion .accordion-body{
    padding-bottom: 30px!important;
}

.chat-setting-switch .switch{
    position: relative;
    z-index:1;
}
.chat-type .cm-editor{
    height: 239px;
    background-color: #1B2952;
}
.chat-type .ͼo .cm-gutters {
    background-color: #1B2952;
}
.chat-type .ͼo .cm-activeLineGutter {
    background-color: #2c313a;
}
.chatafterupload {
    height: 24px!important;
    width: 24px!important;
    min-width: 24px!important;
  }
.chat-icon-background{
    background-color: #EDF0F5;
    border-radius: 3px;
    width: 36px;
    height: 36px;
    margin: 0px 0px 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upgrade-skip-chat {
    display: flex;
    align-items: center;
    margin-top: 30px;
    grid-gap: 20px;
    gap: 20px;
}




























@media (min-width: 1201px) and (max-width: 1800px){

    .chat-upgrade-page {
        left: 0%;
        padding: 0px 80px 120px 80px;
    }
    .chat-upgrade-left h3 {
        font-family: "Axiforma-Bold";
        font-size: 33px;
        line-height: 45px;
        padding-top: 24px;
    }
    .chatbg-upgrade {
        padding-top: 120px;
    }
    .chat-upgrade-left h4 {
        font-size: 13px;
    }
    .chat-upgrade-left ul li {
        font-size: 12px;
        line-height: 18px;
    }

}




@media screen and (max-width: 1200px){
    .chat-upgrade-page {
        max-width: 100%;
        display: block;
        position: relative;
        left: 0%;
    }
    .disable-role-btn {
        width: 100%;
        margin: 0px 0px 40px;
    }
    .chat-upgrade-left {
        max-width: 100%;
        padding: 0px 20px;
    }
    .chatbg-upgrade {
        padding-top: 20px;
        border: 1px solid #E3E6F1!important;
        box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
        min-height: calc(100vh - 140px);
    }
    .chat-upgrade-left h3 {
        font-size: 30px;
        line-height: 40px;
        padding-top: 0px;
        margin-bottom: 16px;
    }
    .chat-head-right p{
        display: none;
    }
    .chat-head-right button{
        display: none;
    }
    .chat-head {
        margin: 12px 15px 13px 15px;
    }
    .chat-head h1 {
        font-size: 20px;
        margin-top: 5px;
    }
    .chat-upgrade-left a {
        width: 100%;
    }
    .upgrade-skip-chat{
        margin-bottom: 40px;
    }
    .chat-upgrade-left ul li {
        margin-bottom: 10px;
    }
    .chat-right{
        display: none;
    }
    .chat-left {
        width: 100%;
        min-width: 100%;
        padding: 0px;
    }
    .chatbg {
        /* margin: 13px 15px 15px 15px; */
        min-height: auto;
        border: 1px solid #E3E6F1!important;
        box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
    }
    .chat-main-topdiv {
        margin: 15px 15px 15px 15px;
        border: none!important;
        box-shadow: none!important;
    }
    .chat-name-head .switch {
        align-self: center;
    }
    .chat-type {
        padding: 20px 15px 20px 15px;
    }
    .chat-name-body {
        padding: 0px 0px 6px 0px;
    }
    .chat-setting-right{
        display: none;
    }
    .chat-setting-left {
        width: 100%;
        min-width: 100%;
        padding: 0px;
    }
    .chat-setting-header{
        padding: 0px 15px;
    }
    .chat-setting-main{
        padding: 0px 15px 0px 15px;
    }
    .chat-setting-bg{
        margin: 13px 0px 15px 0px;
        min-height: auto;
    }
    .chat-color-input .color_input {
        padding: 0px!important;
    }
    .chat-setting-main:last-child{
        border: none;
        margin-bottom: 0px;
    }
    .chat-setting-main .head-gap {
        margin-top: 0px!important;
    }
    .chat-name-head h2 {
        font-size: 14px;
        margin: 3px 0px 0px 10px;
    }
    .chat-mob-btns{
        display: block;
        margin: 20px 0px;
    }
    .chat-mob-btns button{
        width: 100%;
        color: #fff;
        border: none;
        background-color: #3064F9;
        border-radius: 3px;
        font-family: "Axiforma-Regular";
        font-size: 16px;
        height: 50px;
    }
    .chat-mob-btns p{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        margin-top: 20px;
        text-align: center;
    }
    .chat-setting-main .gsetting-tooltip .gsetting-tooltipsubheader {
        z-index: 2;
    }
}