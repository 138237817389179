.addcardbox .iconaddcardplus{
  align-items: center;
  display: flex !important;
  }
  .addcardtext{
    margin-top: 2px;
  }
  .addbutton-card1{
    display: none;
  }
   .addcardbox{
    display: flex;
  }
  .payment-head{
    display: none;
  }
  .payment-billingnewspinner{
    width: 100%;
    height: 100%;
    /* background: white; */
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0px;
    opacity: 1;
    height: 75vh;
  }
  @media (max-width: 1200px){
    .payment-head{
      display: block !important;
      font-size: 20px;
      font-family: 'Axiforma-Bold';
      margin: 20px 0px 15px 0px;
      color: #1B2952;
      width: 100%;
    }
    .addbutton-card1{
      display: block;
      width: 100%;
      position: relative;
    }
    .addcardbox .iconaddcardplus{
      margin-left: 0px !important;
      }
      .addcardbox .iconaddcard{
        display: none !important;
      }
      .addcardtext{
        margin-top: 0px;
      }
      .addcardbox{
        display: flex;
      }
  
    .card-mob-background{
        background: #F8FAFE;
        padding: 0px 15px !important;
    }
    .addcarded-mob{
        width: 100%;
        margin-bottom: 15px !important; 
    }
    .addbutton-card{
        padding: 20px 20px 0px 20px;
        margin-bottom: 0px !important; 
        width: 100%;
    }
    .cardaddeddate{
      text-align: end !important;
    }
    /* .paymentmethod-billing_nodataimg{
      justify-content: center;
    } */
}
@media (max-width: 430px){

  /* .billing-cardchip{
    width: 40px !important;
  } */
}