
.connectivityspinn{
    padding-top: 0px;
    opacity: 1;
    height: 76vh;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-align: center;
    display: flex;
}
.mainspinner{
    display: flex;
    align-items: center;
}
.col2{
    min-height: 73vh !important;
}
.time1{
    align-items: center;
}
.troublebutton{
display: flex;
align-items: center;
justify-content: center;
}
.troublebutton p{
    color: #FF4848;
    font-family:  "Axiforma-Bold";
    font-size: 16px;
    margin-bottom: 0px;
}
.mob-troubleshoot-filed{
    color: #FF4848 !important;
    font-family:  "Axiforma-Bold" !important;
    font-size: 16px !important; 
}
.troubleshoot-filed{
    display: flex;
    justify-content: space-between;
}
.troublebutton .disabledtakethere{
    min-width: 200px !important;
    height: 40px !important;
}
.before-troubleshoot {
    color: #1b2952 !important;
  }
  .after-troubleshoot {
    color: #7782A1 !important;
  }
.trouble-spinner{
    position: initial !important;
}
.troubleshoot-not-connected{
    margin-top: 228.5px !important;
}
/* .troubleshootingtime{
    width: 200px !important;
} */
.consent-modal-tab h1{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin: 0px;
}

.notificationdiv{
    border: 1px solid #7782A1 !important;
    height: 50px;
    background: #7782A1 !important;
    border-radius: 3px;
}
.nottrouble .nottroubleheader {
    font-size: 20px;
    font-family: 'Axiforma-Bold';
     margin-top: 18.7px !important;
}
.nottrouble .nottroublepara{
    font-size: 14px !important;
    color: #7782A1 !important;
    margin-top: 31px;
    line-height: 24px;
    /* max-width: 25%; */
}
.imgdivtrouble{
    margin-top: 16.7px !important;
}
.Starttroubleshoot{
    /* display: none; */
    position: fixed;
    left: 55%;
    transform: translate(-50%, -50%);
    margin-top: 262px;
}
.Starttroubleshoot img{
    margin-bottom: 0px;
}
/* .messagefont {
    min-height: 15px !important;
    margin-top: 4px;
} */
.startpara{
    position: fixed;
    top: 61%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #7782A1;
    width: 572px;
    margin-top: 93px;
}
.stratheader{
    position: fixed;
    top: 58%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    margin-top: 66px;
    width: 375px;
}
.notificationdiv h5{
    color: #E3E6F1 !important;
}


.notificationdivss{
    display: none !important;
}
.headPannel{
    padding: 0px !important;
    padding: 0px !important;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    height: auto;
    /* min-height: 100%; */
    padding: 30px;
    margin-bottom: 12px;
    padding-top: 0px;
    position: relative;
    border: 1px solid #E3E6F1;
}
.headingdiv{
    padding: 19px 30px 19.5px !important;
    border-bottom: 1px solid #E3E6F1;
    margin-top: 0px;
   
}
.troublebutton .altbutspac{
    height: 40px ;
    min-width: 160px ;
    display: flex ;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
    transition: 0.5s;
    line-height: 38px;
    border-radius: 4px;
    border: 1px solid #3064f9;
    color: white;
    cursor: pointer;
    background: #3064f9;
    font-family: "Axiforma-Regular";
    margin-left: 20px;
}
/* .troublebutton .takethere:hover{
 
  color: white;
} */

.troublebutton .altbutspac:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
.col2{
    padding-left: 0px !important;
}
/* .tabletroubleshoot:nth-child(odd) {background: #F8FAFE;} */
.selectetdHistoryTiles{	
    background: #F8FAFE;	
}
/* .historylisttable:first-child{
    background: #F8FAFE;
} */
.time1{
    color: #BCC1CE;
     font-size: 12px;
}
.successfontt{
color: #BCC1CE;
margin-bottom: 0px;
font-size: 12px ;
}

.tabletroubleshoot{
    padding: 20px 35px 20px 30px;
    min-height: 60px;
    display: flex;
    justify-content: space-between ;
}

.pagetop{
    padding: 0px;
    min-height: 75vh;
}
.buttonspadding{
    padding: 0px 0px 0px 30px !important;
    border-bottom: 1px solid #E3E6F1 !important;
}
.buttonsdivpadding{
    padding: 12.5px 0px 0px 0px !important;
}
.settingname{
       font-size: 14px !important;
       color: #7782A1 !important;
       margin-bottom: 0px;
       font-family: 'Axiforma-Bold';
       padding-bottom: 17.5px;
       cursor: pointer;
       width: 72.5px;
       text-align: center;
}

.resultnotclicked{
    font-size: 14px !important;
    color: #7782A1 !important;
    margin-bottom: 0px;
    font-family: 'Axiforma-Bold';
    padding-bottom: 17px;
    cursor: pointer;
    width: 72.5px;
    text-align: center;
}


.settingpage{
    display: flex;
    height: 100px;
    background-color: #F8FAFE; 
    border: none;
    padding: 30px;
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.settingpage h6{
    color: #1B2952;
}


.result{
    font-size: 14px !important;
    color: #3064F9 !important;
    margin-bottom: 0px;
    font-family: 'Axiforma-Bold';
    border-bottom: 2px solid #3064F9;
    padding-bottom: 17px;
    cursor: pointer;
    width: 72.5px;
    text-align: center;
}

.resultsetting{
    font-size: 14px !important;
    color: #3064F9 !important;
    margin-bottom: 0px;
    font-family: 'Axiforma-Bold';
    border-bottom: 2px solid #3064F9;
    padding-bottom: 17px;
    cursor: pointer;
    width: 72.5px;
    text-align: center;
}

.col1{
    padding-right: 0px !important;
}
/* .col2{
    border-left: 1px solid #E3E6F1;
} */

.notificationdiv h6{
    color: white;
    font-size: 14px !important;
    font-family: 'Axiforma-Regular';

}
.notificationdiv h6 img{
    margin-bottom: 3px;

}

.notificationdiv h5{
    font-size: 13px !important;
    font-family: 'Axiforma-Regular';

}

.notificationdivs{
    background: #f8f9fa !important;
    height: 100px;
    color: #1B2952;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border: none !important;
    display: flex;
}
.mobscreen svg{
    margin-right: 10.5px
}

.notificationdivs h6{
    color: #1B2952;
}

.notificationdivs{
    display: none;
}
.itemfont{
    font: 14px "Axiforma-Regular" !important;
    min-width: 20px !important;
    color: #1b2952 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    width: 25%;
}
.messagefont{
    font-size: 12px !important;
     color: #7782A1 !important;
     /* margin-left: 33px !important; */
     padding-left: 0px!important;
     font-family: 'Axiforma-Regular';
}
.timefont{
  
    font: 14px "Axiforma-Regular" !important;
    /* justify-content: end !important; */
  
    list-style-type: none;
    text-transform: capitalize;
    
    }

.successfont{
    font-size: 14px !important;
    color: #7782A1 !important;
    /* border: #707070; */
    font-family: 'Axiforma-Regular' !important;
    min-height: 2px !important;
    margin: 0px;
    height: 18px;
    padding: 1px;
    text-transform: capitalize;
}

.failedfont{
    font-size: 14px !important;
    /* background-color: #FFF6F6; */
    color: #FF4848 !important;
    border: none;
    font-family: 'Axiforma-Regular' !important;
    min-height: 2px !important;
    margin: 0px;
    text-transform: capitalize;
    /* height: 23px; */
  
}

   

.linkfont{
    font-size: 13px !important;
}
.timediv{
    display: none;
}
.successdiv{
    display: none;
}
.settingpage h6{
    font-size: 14px;
    font-family: "Axiforma-Regular";
}
/* .notificationdivss{
    display: none;
} */

.successfailmessage {
    display: flex !important;
    min-height: 19px !important;
    justify-content: space-between !important;
    /* width: 126px !important; */
    /* justify-content: space-between !important; */
}
.successfailmessagebe{
    display: flex !important;
    min-height: 19px !important;
    justify-content: start !important;
    
}
.successfailmessage p{
    margin-top: 0px !important;
    
}
.successfailmessage .medias{
    position: relative !important;
    right: 0px !important;
    color: #3064F9 !important;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
 }
 .connect-troublshootinglink{
    color: white;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
 }
 .connect-troublshootinglink:hover{
    color: white;
 }
 .trobleshoot-test{
    padding: 13px 30px 10px 30px;
    border-bottom: 1px solid #F1F2F8;
    display: flex;
}
.trobleshoot-test h3{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
}
.mobscreen h3{
    display: none;
   
  }
  .messagemobile{
    width: 35%;
  }
  .messagemobile h3{
    display: none;
    
  }

  .mob-responsetimediv{
    width: 10%;
  }
  .mob-responsetimediv h3{
    display: none;
  }
  .mob-resultdiv{
    width: 21.5%;
   
  }
  .mob-resultdiv h3{
    display: none;
  
  }
 @media (max-width: 1200px) {
    .troubleshoot-not-connected{
        margin-top: 140px !important;
    }
    .mob-troubleshoot-btn{
        width: 200px !important;
        margin-top: 35px !important;
    }
    .connectivityspinn{
        text-align: center;
        display: flex;
        background: white;
        padding-top: 0px;
        opacity: 1;
        height: calc(100vh - 140px) !important;
        justify-content: center;
        align-items: center;
        border: 1px solid #E3E6F1;
        border-radius: 6px;
    }

    .troublebutton .altbutspac{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        font-size: 16px;
        margin-left: 0px;
    }
    .notificationdivss {
        border: none !important;
        display: flex;
        padding: 10px 0px 10px 0px;
        height: 60px !important;
        background: #FFFFFF !important;
        border-radius: 3px;
        margin-bottom: 0px !important;
        margin: 0px;
    }
    .tabletroubleshoot{
        display: grid;
    justify-content: unset;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 4px 6px rgba(0,0,0, 0.06);
    border-radius: 6px;
    margin-bottom: 20px;
    background-color: white;
    padding: 0px;
    }
    .trobleshoot-test{
        display: none;
    }
    .mobscreen svg {
        margin-right: 0px;
    }
    .mainspinner {
       
        flex-direction: row-reverse;
        justify-content: space-between;
    }
 /* @media (min-width: 1200px) and (max-width: 1350px){

    .successfailmessage{
        display: grid !important;
    }
    .successfailmessage .medias{
        margin-left: 5px !important;
         margin-top: 4px !important;
    }
 } */
/* @media (min-width: 1000px) and (max-width: 1200px){
    .successfailmessage{
        display: grid !important;
    }
    .successfailmessage .medias{
        margin-left: -5px !important;
        margin-top: 4px !important;
    }
    .headborder{
        margin-top: 70px !important;
    }
    .Starttroubleshoot{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0px;
    }

    .Starttroubleshoot img{
        margin-left: 36px;
        margin-bottom: 130px;
    }
  
    .startpara{
        position: fixed;
        font-size: 14px;
        color: #7782A1;
        margin-top: 34px;
        width: 323px;
        text-align: center;
    }
    .stratheader{
        position: fixed;
        font-size: 18px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        width: 338px;
        margin-top: 0px;
    }
    .timediv{
        display: none;
    }
    .successdiv{
        display: none;
    }
    .headPannel{
        padding-left: 0px !important;
    }
 

    .itemfont{
        font: 11px "Axiforma-Regular" !important;
    }
    .messagefont{
        font-size: 10px !important;
         color: #7782A1 !important;
    }
    .timefont{
        font: 12px "Axiforma-Regular" !important;
       
    }
    .successfont{
        font: 11px "Axiforma-Regular" !important;
        color: #BCC1CE  !important;
    }
    .failedfont{
        font: 11px "Axiforma-Regular" !important;
        
        color: #FF4848 !important;
     
    }
    .headingdiv{
    
        border-bottom: 1px solid #E3E6F1;
    }

    .notificationdiv h6{
      
        font-size: 11px !important;
      
    
}
.notificationdiv h5{
    font-size: 10px !important;
}

.linkfont{
    font-size: 10px !important;
}
.notificationdiv{
    padding-top: 7px;
}

.successfailmessage .medias{
   position: relative !important;
   right: -10px !important;
   color: #3064F9 !important;
}
} */

   
    .successfailmessage{
        display: flex !important;
        justify-content: space-between !important;
    }
    /* .successfailmessage .medias{
        margin-left: -5px !important;
        margin-top: 4px !important;
    } */
    .Starttroubleshoot{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0px;
    }

    .Starttroubleshoot img{
        margin-left: 36px;
        margin-bottom: 130px;
    }
    .startpara{
        position: fixed;
        font-size: 14px;
        color: #7782A1;
        margin-top: 34px;
        width: 323px;
       text-align: center;
    }
    .stratheader{
        position: fixed;
        font-size: 18px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        width: 338px;
        margin-top: 0px;
    }
    .timediv{
        display: none;
    }
    .successdiv{
        display: none;
    }
    .headPannel{
        padding: 0px !important;
        background: unset;
        border-radius: unset;
        box-shadow: unset;
        height: auto;
        margin: 0px 15px;
        padding-top: 0px;
        position: relative;
        border: none;

    }
    .headborder{
        margin-top: 0px !important;
    }

    .itemfont{
        font: 14px "Axiforma-Regular" !important;
        width: 100%;
    }
    .messagefont{
        font-size: 13px !important;
         color: #7782A1 !important;
    }
    .timefont{
            font: 14px "Axiforma-Regular" !important;
            justify-content: start !important;
            margin-right: -1.5px !important;  
            list-style-type: none;        
    }
    .successfont{
        font: 14px "Axiforma-Regular" !important;
        color: #7782A1  !important;
    }
    .failedfont{
        font: 14px "Axiforma-Regular" !important;
        
        color: #FF4848 !important;
     
    }
    .headingdiv{
    
        border-bottom: 1px solid #E3E6F1;
    }

    .notificationdiv h6{
      
        font-size: 11px !important;
      
    
}
.notificationdiv h5{
    font-size: 10px !important;
}

.linkfont{
    font-size: 10px !important;
}
.notificationdiv{
    padding-top: 7px;
}

.tabdatebox{
    display: block;
    display: flex;
    background-color: white;
    border: 1px solid #E3E6F1;
    height: 60px;
    padding-left: 30px !important;
}

.tabdatebox h6{
    color:#1B2952;
    font-size: 16px;
    font-family: 'Axiforma-Regular';
}
.tabdatebox img{
    padding-right: 14.7px;
 
    margin-right: 0px !important;
}
.mobilenotificationdiv{
    display: none !important;
}
.troubleshootrow .col1{
    flex: none;
    max-width: none;
    width: 100%;
    /* padding-right: 15px !important; */
}
.troubleshootrow .col2{
    flex: none;
    max-width: none;
    width: 100%;
    /* padding-right: 15px !important; */
    min-height: 95vh;
}
/* .tabletroubleshoot {
    padding: 20px 30px 20px 40px;
} */

.mob-responsetimediv{
    padding: 24px 15px;
    border-bottom: 1px solid #E3E6F1;
    width: 100%;
}
.mob-resultdiv{
    padding: 24px 15px;
    width: 100%;
}
.mobscreen{
    width: 100%;
    padding: 24px 15px;
    border-bottom: 1px solid #E3E6F1;
  }
  .messagemobile{
    padding: 24px 15px;
    border-bottom: 1px solid #E3E6F1;
    width: 100%;
  }
  .mobscreen h3{
    display: block;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
  }
  .messagemobile h3{
    display: block;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
  }
  .mob-responsetimediv h3{
    display: block;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
  }
  .mob-resultdiv h3{
    display: block;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
  }
}

/* @media (min-width: 300px) and (max-width: 767px){
    .successfailmessagebe{
        display: none !important;
    }
    .successfailmessage{
        display: none !important;
    }
    .notification3padding{
        margin: 0px 20px 15px 20px !important;
    }
    .nottrouble .nottroubleheader {
        font-size: 18px;
        font-family: 'Axiforma-Bold';
    }
    .troubleshoot-not-connected {
        margin-top: 50% !important;
    }
   .media1{
        margin-top: 2px !important;
        left: 1px !important;
        margin-left: 10px;
    }
    .buttonsdivpadding{
        padding: 15px 0px 0px 0px !important;
    }
    .headPannel{
       margin-top: -18px !important;
    }
    .headPannel{
        margin-top: 1px !important;
     }
    .messagemobile{
        margin-top: 8px ;
        max-width: 280px !important;
    }
    .nottrouble .nottroublepara{
        max-width: 127%;
    }
    .successfailmessage {
        display: none !important;
    }
    .Starttroubleshoot{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0px;
    }

    .settingtoggle{
        margin-top: 0px;
    }
    .Starttroubleshoot img{
       
        margin-bottom: 100px;
    }
    .startpara{
        position: fixed;
        font-size: 14px;
        color: #7782A1;
        margin-top: 34px;
        width: 323px;
        text-align: center;
    }
    .stratheader{
        position: fixed;
        font-size: 18px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        width: 338px;
        margin-top: 0px;
    }
    .itemfont{
        font: 13px "Axiforma-Regular" !important;
    }
    .messagefont{
        font-size: 11px !important;
         color: #7782A1 !important;
         margin-left: 30px !important;
    }
    .timefont{
        font: 12px "Axiforma-Regular" !important;
        width: 50px !important;
    margin-right: 0px !important;
        
    }
    .successfont{
        font: 11px "Axiforma-Regular" !important;
        color: #BCC1CE  !important;
        margin-top: 0px;
    }
    .failedfont{
        font: 11px "Axiforma-Regular" !important;
        
        color: #FF4848 !important;
        margin-top: 0px;
    }
    .headingdiv{
    
        border-bottom: 1px solid #E3E6F1;
    }

    .notificationdiv h6{
      
        font-size: 12px !important;
      
    
}
.col2{
    padding-left: 15px !important;
}
.notificationdiv{
    display: none !important;

}
.notificationdiv h5{
    font-size: 11px !important;
}

.linkfont{
    font-size: 11px !important;
}
.col1{
    display: block !important;
}

.headingdiv{
    
   border: none;
   padding: 0px !important;
}
.timediv{
    display: flex !important;
    margin-left: 30px;
    margin-top: 17px !important;
    justify-content: space-between;
    height: 17px;
}
.successdiv{
    display: block !important;
    position: absolute;
    right: -126px;
   bottom: -7px;

}

.successdiv .media{
    left: 10px !important;
}
.successdiv p{
    font-size: 11px;
    margin-top: 0px;
}
.col2{
    margin-bottom: 70px;
}
li {
    list-style-type: none;
   
  }
  .mobscreen{
    display: grid;
    width: 100%;
  }

.notificationdivss{
    border: none !important;
    display: flex;
    padding: 10px 0px 10px 0px;
    height: 60px !important;
    background: #FFFFFF !important;
    border-radius: 3px;
    margin-bottom: 0px !important;
    margin : 0px ;
}

.notificationdivss h5{
    color: #1B2952 !important;
    font-size: 13px !important;
}

.notificationdivss h5{
    color: #1B2952 !important;
    font-size: 13px !important;
}

.notificationdivss h5 span{
    color: #7782A1 !important;
}



.notificationdivss h6{
    color: #1B2952;
    font-size: 16px !important;
    font-family: 'Axiforma-Regular';
    margin-left: 14px ;
}
.notificationdivss h6 img{
    height: 13.99px;
    width: 19.35px;
    margin-right: 14.7px !important;
    margin-bottom: 4px;
}
.notificationdivss h6{
    color: #1B2952;
    font-size: 16px !important;
    font-family: 'Axiforma-Regular';
    margin-left: 14px ;
}
.settingpage{
    display: flex;
    height: 100px;
    background-color: #F8FAFE; 
    border: none;
    padding: 30px;
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.settingpage h6{
    font-size: 13px;
    max-width: 256px;
    line-height: 24px;
}
.testingtype{
    margin-bottom: 0px !important;
}
.tabletroubleshoot {
    padding: 20px 20px 20px 20px;
}
.resulttext{
    padding-right: 0px !important;
    min-height: 16px;
}
.resulttext p{
    font-size: 12px !important
}
.lineheighttrouble{
    line-height: 18px !important;
}
.settingname{
    margin-left: 30px;
}
.resultsetting{
    margin-left: 30px;
}
.failedfont{
    padding: 2px 7px !important;
    height: 18px !important
}
.mainspinner {
    display: block;
}
.mobscreencol{
    max-width: 100% !important;
}
.messagefont {
    padding: 0px !important;
    margin-top: 0px !important;
}
.col2 {
    border-left: none !important;
}
.tabdatebox{
    display: none !important;
}
 .time1{
    display: none !important;
 }

} */
/* 
@media (min-width: 250px) and (max-width: 300px){
    .successfailmessagebe{
        display: none !important;
    }
    .successfailmessage{
        display: none !important;
    }
   
    .notification3padding{
        margin: 0px 20px 15px 20px !important;
    }
    .tabdatebox{
        display: none !important;
    }
    .mobscreencol{
        max-width: 100% !important;
    }
    .troubleshoot-not-connected {
        margin-top: 50% !important;
    }
    .tabletroubleshoot {
        padding: 10px 10px 10px 10px;
    }
    .resulttext {
       
        padding-right: 0px !important
    
    }
    .resulttext p{
        font-size: 12px !important;
       
    }
  
    .lineheighttrouble{
        line-height: 18px !important;
    }
  .media1{
        margin-top: 2px !important;
        left: 1px !important;
        margin-left: 10px;
    }
    .failedfont{
        padding: 2px 7px !important;
        height: 18px !important
    }
    .Starttroubleshoot{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0px;
    }

    .Starttroubleshoot img{
       
        margin-bottom: 70px;
    }
    .headPannel{
        margin-top: 1px !important;
     }
    .startpara{
       
    position: fixed;
    font-size: 10px;
    color: #7782A1;
    margin-top: 20px;
    width: 266px;
    text-align: center;


}
.successfailmessage {
    display: none !important;
}
    .stratheader{
        position: fixed;
        font-size: 12px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        width: 221px;
        margin-top: 0px;
    }
    .itemfont{
        font: 10px "Axiforma-Regular" !important;
    }
    .messagefont{
        font-size: 8px !important;
         color: #7782A1 !important;
         margin-left: 30px !important;
    }
    .timefont{
        font: 9px "Axiforma-Regular" !important;
        margin-top: 0px;
        width: 50px !important;
        margin-left: 30px;
    }
    .successfont{
        font: 10px "Axiforma-Regular" !important;
        color: #BCC1CE  !important;
        margin-top: 0px;
    }
    .failedfont{
        font: 10px "Axiforma-Regular" !important;
        
        color: #FF4848 !important;
        margin-top: 0px;
    }
    .successdiv a{
        font-size: 10px !important;
    }
    .headingdiv{
    
        border-bottom: 1px solid #E3E6F1;
    }

    .notificationdiv h6{
      
        font-size: 10px !important;
      
    
}
.col2{
    padding-left: 15px !important;
}
.col2 {
    border-left: none !important;
}
.successdiv p{
    font-size: 9px;
    margin-top: 0px;
}
.notificationdiv h5{
    font-size: 9px !important;
}

.linkfont{
    font-size: 9px !important;
}
.col1{
    display: block !important;
    min-height: 80vh;
}
.headingdiv{
    
   border: none;
   padding: 0px !important;
}
.timediv{
    display: flex !important;
    justify-content: space-between;
    margin-top: 17px !important;
    height: 17px;
}
.resulttext {
    min-height: 0px !important;
}
.successdiv{
    display: block !important;
    position: absolute;
    right: -97px;
   bottom: -7px;

}
.successdiv li{
    height: 29px;
    padding-right: 0px !important;
}
.col2{
    margin-bottom: 70px;
}
li {
    list-style-type: none;
   
  }
  .mobscreen{
    display: grid;
    width: 100%;
  } */
  /* .notificationdivss{
    display: flex;
    padding: 10px 0px 10px 0px;
    margin: 0px ;
}
.notificationdivss{
    border: none !important;
   
    height: 50px;
    background: #FFFFFF !important;
    border-radius: 3px;
} */
/* 
.notificationdivss h5{
    color: #1B2952 !important;
    font-size: 9px !important;
}
.notificationdiv h5 span{
    color: #7782A1 !important;
}
.notificationdivss h6{
    color: #1B2952;
    font-size: 10px !important;
    font-family: 'Axiforma-Regular';
    margin-left: 14px ;
}
.notificationdivss h6 img{
    height: 11px;
}
.settingpage{
    display: flex;
    height: 100px;
    background-color: #F8FAFE; 
    border: none;
    padding: 15px;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.settingpage h6{
 
    font-size: 13px;
    max-width: 200px;
    line-height: 24px;
}
.historydivtop{
    margin-top: 0px !important
}
.settingname{
    margin-left: 30px;
}
.resultsetting{
    margin-left: 30px;
}
.messagefont {
    padding: 0px !important;
    margin-top: 0px !important;
}
.messagemobile{
    margin-top: 8px ;
    max-width: 260px ;
}
.nottrouble .nottroublepara {
    font-size: 14px !important;
    color: #7782A1 !important;
    margin-top: 16.3px;
    line-height: 24px;
    max-width: 86%;
}
} */