.pagenotfound-mainsection{
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    background-color: #F8FAFE;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0px;
}
.notfound-mob-img{
    display: none;
}
.notFound-div-main{
    display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
}
.notfound-head{
    font-family: 'Axiforma-Bold';
    font-size: 48px;
    margin-bottom: 20px;
}
.notfount-para{
    font-family: 'Axiforma-Regular';
font-size: 16px;
color: #5F6578;
margin-bottom: 30px;
}
.gotohome-btn{
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px 0px #0000001A;
    border-radius: 3px;
    margin-bottom: 40px;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    color: #3064F9;
}

@media (max-width: 767px) {
    .pagenotfound-mainsection {
        align-items: unset;
        padding: 0px;
    }
    .notfound-mob-img{
        display: block;
        width: 100%;
    }
    .notfound-web-img{
        display: none;
    }
    .notfound-head{
        font-size: 32px;
        margin-bottom: 20px;
        line-height: 44px;
    }
    .notfount-para {
        text-align: left !important;
    }
    .notFound-div-main {
        align-items: unset;
        padding: 0px 20px;
    }
    .gotohome-btn{
        font-size: 16px;
    }
}