.notActive_analyticsimgdiv{
    text-align: right !important;
    padding-top: 140px !important
}
.notActive_analyticsimgdiv img{
    padding-right: 55px !important;
}
.notactive_coltwo{
    padding-left: 45px !important;
    padding-top: 180px !important;
}
.notactive_coltwo h3{
    margin: 25px 0px !important;
    color: #1b2952;
    font: 30px "Axiforma-Bold";
    margin-top: 0px !important;
}
.notactivate_rightimg ul{
    padding-left: 20px;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJCAYAAADkZNYtAAAABHNCSVQICAgIfAhkiAAAAJFJREFUGFdjZCAAShvmGzAxMfb//Pc/kBGfWpBCRkbG/UAs8P8/QyNYcUXDfIWOhsQHyBrRFC7sqo9PYKxoWpTwn+H/fEYGxsSOurgFIA3YFILEGcsaFy5gZGSIB3OAGv7++3sByWqwiTAbwc5A1vD///8PUDeiKIQYBgWoGhgwFKIohtkAopGtRvY03qBDD1YAYyBFwQb7vkkAAAAASUVORK5CYII=);
}
.notactive_coltwo p{
    max-width: 100% !important;
    font: 16px "Axiforma-Regular" !important;
    color: #1B2952 !important;
    margin-bottom: 30px !important;
    max-width: 436px !important;
    line-height: 28px !important;
}
.notactive_coltwo li{
color: #1B2952 !important;
font: 14px "Axiforma-Regular";
margin: 20px 0px;
line-height: 21px;
}
.notactivate_upgrade-colone .notactive_purchagebtn{
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 200px;
    margin: 30px 0;
    text-align: center;
    font-size: 14px;
    background-color: #3064f9;
    text-decoration: none;
    border-radius: 4px;
    color: #fff;
    font-family: "Axiforma-Regular";
    font-weight: 500;
    border: 1px solid #3064f9;
    transition: .5s;
    cursor: pointer;
}
.notactivate_upgrade-colone .notactive_purchagebtn:hover{
    background-color: #134bec;
}
.notactivate_upgrade-div{
    display: flex;
    background-color: #fff;
    background-image: url(../../assets/images/dashboard/app-icon-background.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    justify-content: center;
    margin: 17px 0px 0px ;
    position: relative;
    background-size: contain;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .06);
    min-height: calc(100vh - 160px);
    width: 100%;
}

.notactivate_upgrade-coltwo{
    width: 52%;
    padding: 135px 0px 0px 15px;
}
.notactivate_upgrade-colone{
    width: 48%;
    padding: 196px 115px 0px 120px;
    justify-content: right;
    display: flex;
}
.notactivate_upgrade-colone h3{
    font-size: 48px;
    font-family: 'Axiforma-Bold';
    line-height: 69px;
    margin-bottom: 25px;
    color: #1B2952;
}
.notactivate_upgrade-colone p{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    line-height: 18px;
    text-align: left !important;
    margin-bottom: 30px;
}
.notactivate_upgrade-colone ul li{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    list-style-type: none;
    margin-bottom: 17px;
    position: relative;
}
.notactivate_upgrade-colone ul li::before {
    content: url("../..//assets/images/dashboard/cms/dot.png");
    position: absolute;
    left: -15px;
    top: -2px;
    opacity: 0.5;
}
.notactivate_upgrade-colone ul{
    padding: 0px 0px 0px 15px;
    position: relative;
}
@media (min-width: 1201px) and (max-width: 1600px){
    .notactivate_upgrade-colone {
        padding: 176px 20px 0px 20px;
    }
}
@media (min-width: 768px) and (max-width: 1000px){
    /* .notactivate_leftimg .d-md-block{
        display: none !important;
    } */
    .notactivate_leftimg .col-md-6{
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .notactive_coltwo {
        padding-left: 0px !important;
        padding-top: 0px !important;
    }
    /* .notactive_coltwo .mt-5.pt-5.mobile-analytics-data{
        padding-top: px !important
    } */
    .notactive_coltwo p {
        max-width: 100% !important;
        text-align: left !important;
        margin-bottom: 30px !important;
    }
    .notActive_analyticsimgdiv{
        text-align: center !important;
        padding-top: 50px !important;
    }
    .notactive_coltwo h3 {
        margin: 0px 0px 25px 0px !important;
    }
    .notActive_analyticsimgdiv img {
        padding-right: 0px !important;
    }
}

@media (min-width: 250px) and (max-width: 767px){
    .notactive_coltwo {
        padding-left: 0px !important;
        padding-top: 0px !important;
    }
    .notactive_coltwo .mt-5.pt-5.mobile-analytics-data{
        padding-top: 0px !important
    }
    .notactive_coltwo p {
        max-width: 100% !important;
        margin-bottom: 30px !important;
    }
    .notactive_coltwo p {
        max-width: 100% !important;
        text-align: left !important;
        margin-bottom: 30px !important;
        line-height: 28px !important;
    }
    .notactive_coltwo h3 {
        margin: 25px 0px !important;
        margin: 25px 0px !important;
        color: #1b2952;
        font: 28px "Axiforma-Bold";
        margin-top: 10px !important;
}
}
@media (max-width: 1200px){
  
    .notactivate_upgrade-div {
        flex-direction: column;
    background-image: unset;
    justify-content: unset;
    margin: 13px 0px 0px;
    min-height: unset;
    }
    .notactivate_upgrade-colone {
        width: 100%;
        padding: 17px 20px 20px;
        justify-content: flex-start;

    }
    .notactivate_upgrade-colone div{ 
        width: 100%;
    }
    .notactivate_upgrade-coltwo{
        width: 100%;
        justify-content: unset;
        padding: 20px 10px;
    align-items: center;
    display: flex;
    }
    .notactivate_upgrade-colone .notactive_purchagebtn {
        width: 100%;
        font-size: 16px;
        margin: 20px 0px 0px;
    }
   
    .notactivate_upgrade-colone h3 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;

    }
   
    .notactivate_upgrade-colone p {
        margin-bottom: 22px;
    }
    .notactivate_upgrade-colone ul li {
        margin-bottom: 15px;
    }
    .notactivate_upgrade-colone ul {
        padding: 0px 7px 0px 15px;

    }
}