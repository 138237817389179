.toaster-text{
    font-size: 14px;
    color: white;
    font-family: 'Axiforma-Regular';
    margin-top: 2px !important;
    margin-bottom: 0px; 
    
}
 .Toaster__alert_close{
    color: white !important;
    font-size: 30px;
    line-height: 19px;
    font-family: emoji;
    font-weight: 100;
    opacity: 1;
    height: 100%;
    top: 0px !important;
}
.toaster-text-margin{
    margin-left: 10px !important;
    padding-right: 20px;
}
.toaster-layout{
/* height: 60px; */
    width: 480px;
    background-color: #52CB86;
    padding: 19px 20px;
    text-align: left;
}
.red-toaster-layout{
    /* height: 60px; */
    width: 480px;
    background-color: #FF4848;
    padding: 19px 20px;
    text-align: left;
    }
    .Toaster__alert {
        border-radius: 4px !important;
    }
    .Toaster__message-wrapper {
        padding-top: 30px !important;
        padding-bottom: 0px !important;
    }
    .Toaster__alert {
       box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1) !important;
    }
@media (max-width: 1200px) {
    .toaster-layout{
     
        width: 350px;
       
        }
        .red-toaster-layout{
     
            width: 350px;
           
            }
}