.weight700{
  font-weight: 700;
}
.weight600{
  font-weight: 600;
}
.weight500{
  font-weight: 500;
}
.colorblack{
  color: #0F193B;
}
.colorblue{
  color: #3064F9;
}
.cologrey{
  color: #5F6578;
}
.blueback{
  background-color: #F8FAFE!important;
}
.paddingtop160{
  padding-top: 124px;
}
.paddingtop140{
  padding-top: 135px;
}
.paddingtop100{
  padding-top: 100px;
}
.paddingtop80{
  padding-top: 80px;
}
.paddingbottom80 {
  padding-bottom: 0px;
}
.paddingbottom100{
  padding-bottom: 100px;
}
.paddingbottom120{
  padding-bottom: 120px;
}
.paddingbottom60{
  padding-bottom: 60px;
}

.sectionheadings{
  color: #0F193B;
  font-size: 36px;
  font-family: 'Axiforma-Bold';
  line-height: 54px;
}
.subheadings1{
  color: #7782A1;
  font-size: 16px;
  font-family: 'Axiforma-Regular';
  line-height: 30px;
}
.subheadingtop{
  margin-top: 0px;   
}
.subheadings2{
  color: #0F193B;
  font-size: 20px;
  font-family: 'Axiforma-Bold';
  line-height: 30px;
}
.normaltext{
  color: #5F6578;
  font-family: 'Axiforma-Regular';
  font-size: 14px;
  line-height: 24px;
}

.customersaying .paddingtop100 div{
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.customersaying p{
  color: #C4C7D1;
  font-size: 16px;
  font-family: 'Axiforma-Regular';
}
.customersaying .paddingtop100 .midflex div{
  width: 17%;
  margin-bottom: 20px;
  text-align: center;
}
.customersaying .reviewlink{
  margin-top: 40px;
  color: #3064F9;
  text-decoration: none;
  font-size: 14px;
  display: block;
  width: 130px;
}
.reviewlink svg{
  display: none
}
.reviewsection{
  position: relative;
}
.reviewsection .reviewimage{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.awards{
  margin-top: 70px;
}
.awards p{
  margin-bottom: 48px;
}
.pricebox{
  background-color: #fff;
  width: 24%;
  border-radius: 6px;
  position: relative;
  border: 2px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  transition: .5s;
}
.pricebox:hover{
  border: 2px solid #7D9FFF;
  transition: .5s;
  box-shadow: 0px 4px 8px rgb(48 100 249 / 13%);
}
.price-first-half{
  padding: 25px 20px 20px 20px;
}
.price-second-half{
  padding: 5px 20px 30px 20px;
}
.borderblue{
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 20px;
  width: 3px;
  height: 30px;
}
.borderyellow{
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 20px;
  width: 3px;
  height: 30px;
}
.borderorange{
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 20px;
  width: 3px;
  height: 30px;
}
.bordergreen{
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 20px;
  width: 3px;
  height: 30px;
}
.teampricing h1{
  color: #0F193B;
  font-size: 36px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 46px;
}
.agencypricing h1{
  color: #0F193B;
  font-size: 36px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 20px;
}
.pricebox h2{
  color: #0F193B;
  font-size: 16px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 0px;
}
.pricebox h3{
  color: #B8BFD0;
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
}
.pricebox h4{
  color: #0F193B;
  font-size: 36px;
  font-family: 'Axiforma-Bold';
  margin-top: 25px;
  margin-bottom: 0px;
}
.pricebox h5{
  color: #7782A1;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin-top: 8px;
  margin-bottom: 0px;
  min-height: 15px;
}
.pricebox .price-first-half a{
  width: 100%;
  background-color: #3064F9;
  border: 1px solid #3064F9;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  text-decoration: none;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin-top: 40px;
  transition: .5s;
  display: block;
}
.pricebox .price-first-half a:hover{
  transition: .5s;
  background-color: #0040f9;
  border: 1px solid #0040f9;
  color: #fff;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.pricebox button{
  color: #3064F9;
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: center;
  margin-top: 7px;
  font-family: 'Axiforma-Regular';
  font-size: 12px;
}
#agencylink .pricebox a{
  background-color: #7A41FF;
  border: 1px solid #7A41FF;
}
#agencylink .pricebox a:hover{
  color: #7A41FF;
  background-color: #fff;
}
.pricebox .compareplan{
  background-color: transparent!important;
  color: #3064F9!important;
  font-size: 14px!important;
  border: none!important;
  height: 20px!important;
}
.pricebox .compareplan img{
  display: none;
}
#agencylink .pricebox .compareplan{
  color: #7A41FF!important;
}
.pricelist{
  display: flex;
  align-items: baseline;
}
.pricelist p{
  margin-left: 10px;
  color: #7782A1;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin-top: 0px;
  margin-bottom: 5px;
}
.pricebox .highlight span{
  color: #000;
  font-family: 'Axiforma-Bold';
}
.featurebox{
  max-height: 730px;
  overflow: hidden;
  min-height: 730px;
}
.wordpress-featurebox{
  max-height: 770px;
  overflow: hidden;
  min-height: 770px;
}
.woocommerce-featurebox{
  max-height: 860px;
  overflow: hidden;
  min-height: 860px;
}
.price-second-half h6{
  color: #B8BFD0;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
}
.forthcoming p{
  color: #B8BFD0;
}
.platforms{
  margin: 0px 0px 0px 0px;
}
.teampricing .container{
  position: relative;
  max-width: 1110px !important;
}
.teampricing .container .tabs{
  position: absolute;
  right: 15px;
  top: -4px;
  background: #E3E6F1;
  padding: 3px 3px 4px 3px;
  border-radius: 3px;
}
.agencylink{
  text-align: center;
  padding-top: 60px;
  display: none;
}
.agencylink a , .agencylink a:hover{
  color: #B8BFD0;
  font-size: 16px;
  font-family: 'Axiforma-Regular';
  cursor: pointer;
  text-decoration: none;
}
.agencypricing .container{
  position: relative;
}
.agencypricing .container .tabs{
  position: absolute;
  right: 15px;
  top: -15px;
  background: #E3E6F1;
  padding: 3px 3px 4px 3px;
  border-radius: 3px;
}
.plandetail{
  margin-top: 60px;
  border-top: 1px solid #E3E6F1;
  padding-top: 3px;
}
.plandetail .allflex{
  height: 51px;
}
.plandetail h5{
  color: #0F193B;
  font-size: 16px;
  font-family: 'Axiforma-Bold';
  margin-bottom: -10px;
}
.plandetail .pricesechead{
  background-color: #fff;
  padding: 30px 0px 12px 12px;
  border-top: 1px solid #E3E6F1;
  margin-top: 12px;
}
.pricesechead h6{
  color: #0F193B;
  font-size: 16px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 0px;
}
.plandetail p{
  color: #7782A1;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.pricemajor{
  width: 30%;
  min-width: 30%;
  padding-left: 10px;
}
.pricemajor .full-page-tooltip .full-page-tooltipsubheader {
  top: -7px;
}
.priceminor{
  width: 17.5%;
  min-width: 17.5%;
  text-align: center;
}
.priceminor p{
  justify-content: center;
}
.pricingflex-new{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.plandetail a , .plandetail a:hover{
  color: #3064F9;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  text-decoration: none;
}










.tabbed [type="radio"] {
display: none;
}

.tabs {
display: flex;
align-items: stretch;
list-style: none;
padding: 0;
}
.tab > label {
display: block;
margin-bottom: -1px;
padding: 7px 16px 8px 16px;
background: #E3E6F1;
color: #7782A1;
font-size: 13px;
text-transform: capitalize;
letter-spacing: .8px;
cursor: pointer;	
transition: all 0.3s;
  font-family: "Axiforma-Regular";
}
.mobilecomparison .tab > label {
padding: 10px 14px 8px 14px;
font-size: 12px; 
}
.mobilecomparison .tab {
  width: 25%;
  text-align: center;
}
.tab{
  border-radius: 4px;
}
.tab:hover label {
border-top-color: #333;
color: #1B2952;
}

/* .tab-content {
display: none;
color: #777;
} */

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed [type="radio"]:nth-of-type(5):checked ~ .tabs .tab:nth-of-type(5) label {
border-bottom-color: #fff;
background: #fff;
color: #1B2952;
  border-radius: 3px;
  font-weight: 700;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
display: block;
}
/* .accordionWrapper{
  margin-top: 42px;
} */
.pricingfaq .accordionItem{
  display:block;
  width:100%;
  box-sizing: border-box;
  border-bottom: 1px solid #E3E6F1;
  padding: 0px;
}
.pricingfaq .accordion-header{
  cursor: pointer;
  padding: 40px 0px 20px 0px!important;
  width: 100%;
  font-size: 18px!important;
  color: #0F193B;
  font-family: 'Axiforma-Bold';
  margin: 0px;
  background-color: transparent;
  text-align: left;
  border: none;
}
.pricingfaq .accordion-item {
  border-bottom: 1px solid #e3e6f1;
  padding-bottom: 10px;
}
.pricingfaq .accordion-item p , .pricingfaq .accordion-item ul li{
  font-size: 14px;
  color: #5f6578;
  font-family: 'Axiforma-regular';
  text-align: left!important;
}
.accordion-flush{
  margin-top: 50px;
}
.close .accordionItemContent{
  height:0px;
  transition:height 1s ease-out;
  -webkit-transform: scaleY(0);
-o-transform: scaleY(0);
-ms-transform: scaleY(0);
transform: scaleY(0);
  float:left;
  display:block;
}
.accordionItemContent p{
  font-size: 14px;
  color: #5F6578;
  font-family: 'Axiforma-Regular';
}
.accordionItemContent ul li{
  font-size: 14px;
  color: #5F6578;
  font-family: 'Axiforma-Regular';
  margin-bottom: 15px;
}
.accordionItemContent ul li a{
  text-decoration: none;
  font-size: 14px;
  color: #3064F9;
  font-family: 'Axiforma-Regular';
}
.accordionItemContent p a , .accordionItemContent p a:hover{
  font-size: 14px;
  color: #3064F9;
  font-family: 'Axiforma-Regular';
  text-decoration: none;
}
.accordionItemContent p span , .accordionItemContent ul li span{
  font-family: 'Axiforma-Bold';
  color: #000;
}
.open .accordionItemContent{
  padding: 20px 0px;
  width: 100%;
  margin: 0px 0px 10px 0px;
  display:block;
transition: transform .1s ease-in;
}
.open .accordionItemHeading{
  padding: 40px 0px 0px 0px;
  color: #000;
  width: 100%;
}
.haveidea{
  padding: 57px 40px;
  border: 1px solid #E3E6F1;
  border-radius: 6px;
  margin-bottom: 0px;
  margin-top: 100px;
  display: none;
}
.haveidea h4{
  margin-bottom: 0px;
}
.haveidea a , .haveidea a:hover{
  color: #3064F9;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Axiforma-Regular';
}
.customersaying .row .col-md-6:nth-child(1) {
  padding-right: 80px;
}
.customersaying .row .col-md-6:nth-child(1) h3{
  padding-top: 23px;
}
.customersaying .paddingtop100 {
  padding-top: 60px!important;
}
.hiddenfaq{
  display: none;
}
.pricingfaq h5{
  margin-top: 30px;
  color: #7782A1;
  font-size: 16px;
  font-family: 'Axiforma-Regular';
  cursor: pointer;
  /* position: relative;
  top: 20px; */
}
#mainheader{
  height: 80px;
}
.d-none{
  display: none;
}
.pricingfaq img{
  width: auto!important;
  height: auto!important;
}
.allplans h1 {
  color: #0F193B;
  font-size: 36px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 65px;

}
.allplans .allflex .plansummary{
  background-color: #fff;
  width: 32%;
  padding: 46px 20px 26px 20px;
  text-align: center;
  border-radius: 5px;
  position: relative;
}
.allplans .allflex .plansummary p{
  color: #B8BFD0;
  font-size: 12px;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
  margin-top: 22px;
  height: 16px;
}
.allplans .allflex .plansummary img{
  margin-bottom: 40px;
}
.allplans .allflex .plansummary h4{
  color: #0F193B;
  font-size: 22px;
  font-family: 'Axiforma-Bold';
}
.allplans .allflex .plansummary h5{
  color: #5F6578;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  margin-top: 20px;
  line-height: 24px;
}
.allplans .allflex .plansummary h6{
  color: #5F6578;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  margin-top: 19px;
}
.allplans .allflex .plansummary h6 span{
  color: #0F193B;
  font-size: 24px;
  font-family: 'Axiforma-Bold';
  margin-right: 4px;
}
.allplans .allflex .plansummary a{
  margin: auto;
  width: 200px;
  background-color: #3064F9;
  border: 1px solid #3064F9;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 44px;
  padding-top: 5px;
  margin-top: 30px;
  transition: .5s;
}
.allplans .allflex .plansummary a:hover {
  transition: .5s;
  background-color: #fff;
  color: #3064F9;
}
.allplans .allflex .plansummary div{
  width: 50px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.borderweb{
  background-color: #7297FF;
}
.borderwordpress{
  background-color: #4BB1D2;
}
.borderwoocomerce{
  background-color: #AB79E8;
}
.unlimited-plan{
  background-color: #7A41FF!important;
  border: 1px solid #7A41FF!important;
}
.allplans .allflex .plansummary .unlimited-plan:hover{
  transition: .5s;
  background-color: #fff!important;
  color: #7A41FF;
}
.plan-cancel-popup h4{
  color: #1B2952;
  font-size: 18px;
  font-family: 'Axiforma-Bold';
  text-align: center;
  line-height: 34px;
  width: 60%;
  margin: auto;
}
.plan-cancel-popup h6{
  color: #7782A1;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  text-align: center;
  line-height: 25px;
  width: 70%;
  margin: auto;
}
.plan-cancel-popup h6 span{
  color: #000;
}
.plan-cancel-popup{
 max-width: 480px !important;
  max-height: 296px
}
.plan-cancel-popup-footer .plan-cancelbtn{
  border: 1px solid #BCC1CE;
  width: 140px;
  height: 44px;
  background-color: transparent;
  border-radius: 3px;
  color: #7782A1;
  font-size: 13px;
 font-family: "Axiforma-Regular";
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
  cursor: pointer;
}
.plan-cancel-popup-footer .plan-upgradebtn{
  border: 1px solid #3064F9;
  width: 140px;
  height: 44px;
  background-color: #3064F9;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;

  text-align: center;
  line-height: 40px;
  margin-left: 10px;
  cursor: pointer;
}
/* .plan-cancel-popup-footer .plan-upgradebtn:hover {
  background-color: #134bec;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
} */
.plan-cancel-popup-footer{
  margin-top: 30px;
}
.plan-cancel-popup-footer p{
  margin-bottom: 0px;
}
.plan-cancel-popup .modal-body{
  padding: 60px 0px!important;
}
.plan-cancel-popup .crossmodal{
  cursor: pointer;
  z-index: 9;
}



/*Mega Menu Starts*/
@keyframes expand{
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}

.mainmenuitems .mega-menu-solutions{
  width: 744px;
  height: 390px;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  top: 80px;
  right: 0;
  left: 0px;
  margin: auto;
  display: none;
  opacity: 1;
  border-radius: 6px;
  -webkit-animation: expand .8s;
  box-shadow: 0px 2px 8px rgba(15, 25, 59 , 0.16);
  padding: 50px;
}
.solutions-sub{
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  margin-left: 0px!important;
}
.solutions-sub:before {
  content: "";
  position: absolute;
  background: #fff;
  z-index: -2;
  top: -12px;
  left: 200px;
  height: 26px;
  width: 26px;
  box-shadow: -6px 6px 6px 0px rgb(15 25 59 / 5%);
  transform: rotate(46deg);
  -ms-transform: rotate(46deg);
  -webkit-transform: rotate(135deg);
}
.solutions-sub:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -3;
  left: 0;
  top: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.solutions-sub ul{
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
}
.mega-menu-solutions .solutions-sub ul li{
  width: 50%;
  margin-left: 0px!important;
  margin-bottom: 25px;
}
.mainmenuitems .mega-menu-industries{
  width: 1080px;
  height: 410px;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  top: 80px;
  right: 0;
  left: 0px;
  margin: auto;
  display: none;
  opacity: 1;
  border-radius: 6px;
  -webkit-animation: expand .8s;
  box-shadow: 0px 2px 8px rgba(15, 25, 59 , 0.16);
  padding: 50px;
}
.industries-sub{
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  margin-left: 0px!important;
}
.industries-sub ul{
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
}
.industries-sub:before {
  content: "";
  position: absolute;
  background: #fff;
  z-index: -2;
  top: -12px;
  right: 321px;
  height: 26px;
  width: 26px;
  box-shadow: -6px 6px 6px 0px rgb(15 25 59 / 5%);
  transform: rotate(46deg);
  -ms-transform: rotate(46deg);
  -webkit-transform: rotate(135deg);
}
.industries-sub:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -3;
  left: 0;
  top: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.mega-menu-solutions .mega-menu-items{
  display: flex;
  align-items: flex-start;
  width: 50%;
  position: relative;
}
.mainmenuitems .mega-menu-industries .mega-menu-items{
  display: flex;
  align-items: flex-start;
  width: 33%;
  position: relative;
  margin-left: 0px!important;
  margin-bottom: 25px;
}
.mega-menu-items:hover p{
  color: #3064F9!important;
  transition: 2s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.mega-menu-items:hover h5{
  color: #3064F9!important;
  transition: 2s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.mega-menu-items .coverlink{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.disabled-mega-menu h5 , .disabled-mega-menu:hover h5{
  color: #0F193B!important;
}
.disabled-mega-menu p , .disabled-mega-menu:hover p{
  color: #B8BFD0!important;
}
.small-sub-menu li{
  width: 100%!important;
  margin-bottom: 0px!important;
}
.small-sub-menu{
  padding-left: 55px!important;
  padding-top: 0px!important;
}
.small-sub-menu li a{
  font-size: 13px!important;
  color: #3064F9!important;
}
.mega-menu-solutions .mega-menu-items div:nth-child(2) , .disabled-mega-menu  div:nth-child(1){
  margin-right: 10px!important;
}
.mega-menu-industries .mega-menu-items div:nth-child(2){
  margin-right: 10px!important;
}
.mega-menu-solutions .mega-menu-items h5{
  color: #0F193B;
  font-size: 16px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 10px;
  margin-top: 5px;
  transition: .5s;    
}
.mega-menu-solutions .mega-menu-items h5 span{
  color: #B8BFD0;
  font-size: 13px
}
.mega-menu-solutions .mega-menu-items p{
  color: #5F6578;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin-top: 0px;
  transition: .5s;
  width: 80%;
}
.mega-menu-industries .mega-menu-items h5{
  color: #0F193B;
  font-size: 16px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 10px;
  margin-top: 5px;
  transition: .5s;
}
.mega-menu-industries .mega-menu-items h5 span{
  color: #B8BFD0;
  font-size: 13px
}
.mega-menu-industries .mega-menu-items p{
  color: #7782A1;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin-top: 0px;
  transition: .5s;
  width: 80%;
}
#solutions-menu:hover .mega-menu-solutions{
  opacity: 1;
  display: flex;
}
#solutions-menu .mega-menu-solutions:hover{
  opacity: 1;
  display: flex;
}
#industry-menu:hover .mega-menu-industries{
  opacity: 1;
  display: flex;
}
#industry-menu .industries-sub:hover{
  opacity: 1;
  display: flex;
}
.bodyback:after{
  position: absolute;
  background: rgba(0,0,0,.5);
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  animation: showme 1s forwards;
  position: fixed;
}
.mega-menu-industry-link{
  width: 33%;
  background-color: #F8FAFE;
  height: 64px;
  border: 1px solid #F8FAFE;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 1s;
  margin-left: 0px!important;
}
.mega-menu-industry-link:hover{
  border: 1px solid #3064F9;
  transition: 1s;
}
.mega-menu-industry-link p{
  color: #3064F9;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  display: flex;
  align-items: center;
  padding-left: 55px;
  margin-bottom: 0px;
  margin-top: 20px;
}
.mega-menu-industry-link p svg{
  margin-left: 14px;
}
.mega-menu-industry-link a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.mb-0{
  margin-bottom: 0px!important;
}
.mb-0 p{
  margin-bottom: 0px;
}

/*Mega Menu Ends*/


.newtabs1 {
  width: 100%;
}
.newtabs1 input {
  opacity: 0;
}
.newtabs1 label {
  cursor: pointer;
  background: transparent;
  color: #B8BFD0;
  padding: 0px;
  float: left;
  margin-bottom: 20px;
  font-family: 'Axiforma-Regular';
  margin-right: 10px;
  font-size: 13px;
  transition: .5s;
}
.newtabs1 label:hover {
  background: transparent;
  color: #0F193B;
  transition: .5s;
}

.newtabs1 input:checked + label {
  background: transparent;
  color: #0F193B;
}
.newtabs1 input:nth-of-type(1):checked ~ .panels1 .panel1:first-child,
.newtabs1 input:nth-of-type(2):checked ~ .panels1 .panel1:nth-child(2){
  display: block;
  -webkit-transition: .3s;
}
.newtabs1 .panels1 {
  clear: both;
  position: relative;
  width: 100%;
}
.newtabs1 .panel1 {
  width: 100%;
  display: none;
  border-radius: 0 10px 10px 10px;
  box-sizing: border-box;
}





.newtabs2 {
  width: 100%;
}
.newtabs2 input {
  opacity: 0;
}
.newtabs2 label {
  cursor: pointer;
  background: transparent;
  color: #B8BFD0;
  padding: 0px;
  float: left;
  margin-bottom: 20px;
  font-family: 'Axiforma-Regular';
  margin-right: 10px;
  font-size: 13px;
  transition: .5s;
}
.newtabs2 label:hover {
  background: transparent;
  color: #0F193B;
  transition: .5s;
}

.newtabs2 input:checked + label {
  background: transparent;
  color: #0F193B;
}
.newtabs2 input:nth-of-type(1):checked ~ .panels2 .panel2:first-child,
.newtabs2 input:nth-of-type(2):checked ~ .panels2 .panel2:nth-child(2){
  display: block;
  -webkit-transition: .3s;
}
.newtabs2 .panels2 {
  clear: both;
  position: relative;
  width: 100%;
}
.newtabs2 .panel2 {
  width: 100%;
  display: none;
  border-radius: 0 10px 10px 10px;
  box-sizing: border-box;
}


.newtabs3 {
  width: 100%;
}
.newtabs3 input {
  opacity: 0;
}
.newtabs3 label {
  cursor: pointer;
  background: transparent;
  color: #B8BFD0;
  padding: 0px;
  float: left;
  margin-bottom: 20px;
  font-family: 'Axiforma-Regular';
  margin-right: 10px;
  font-size: 13px;
  transition: .5s;
}
.newtabs3 label:hover {
  background: transparent;
  color: #0F193B;
  transition: .5s;
}

.newtabs3 input:checked + label {
  background: transparent;
  color: #0F193B;
}
.newtabs3 input:nth-of-type(1):checked ~ .panels3 .panel3:first-child,
.newtabs3 input:nth-of-type(2):checked ~ .panels3 .panel3:nth-child(2){
  display: block;
  -webkit-transition: .3s;
}
.newtabs3 .panels3 {
  clear: both;
  position: relative;
  width: 100%;
}
.newtabs3 .panel3 {
  width: 100%;
  display: none;
  border-radius: 0 10px 10px 10px;
  box-sizing: border-box;
}
.enterprise-solution{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 56px 40px 64px 40px;
  margin-top: 52px;
  position: relative;
  border-radius: 6px;
  border: 2px solid #E3E6F1;
  transition: .5s;
}
.enterprise-solution img{
  width: 96px;
  margin-right: 30px;
}
.enterprise-solution div{
  width: calc(100% - 180px);
}
.enterprise-solution div h3{
  font-family: 'Axiforma-Bold';
  font-size: 36px;
  line-height: 30px;
  color: #0F193B;
}
.enterprise-solution div p{
  font-family: 'Axiforma-Regular';
  font-size: 14px;
  line-height: 24px;
  color: #7782A1;
  margin-bottom: 0px;
  margin-top: 21px;
  text-align: left!important;
}
.enterprise-solution a{
  width: 160px;
  height: 50px;
  color: #3064F9;
  border: 1px solid #3064F9;
  text-decoration: none;
  border-radius: 4px;
  transition: .5s;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  line-height: 50px;
  text-align: center;
  transition: .5s;
}
.enterprise-solution a:hover{
  transition: .5s;
  color: #0040f9;
  border: 1px solid #0040f9;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.price-main-tab .tab-content .allflex{
  flex-wrap: wrap;
  align-items: inherit;
}
.pricingsummary{
  background-color: #fff;
  width: 31.482%;
  padding: 43px 0px 40px 0px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #E3E6F1;
  text-align: center;
}
.pricingsummary h4{
  font-family: 'Axiforma-Bold';
  font-size: 20px;
  color: #0F193B;
  margin-top: 30px;
  text-align: center;
}
.pricingsummary h4 img{
  margin-right: 14px;
}
.pricingsummary p{
  font-family: 'Axiforma-Regular2';
  font-size: 13px;
  color: #5F6578;
  line-height: 24px;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  min-height: 65px;
}
.pricingsummary h5{
  font-family: 'Axiforma-Regular2';
  font-size: 13px;
  color: #BCC1CE;
  margin-top: 32px;
  margin-bottom: 0px;
}
.pricingsummary h5 span{
  font-family: 'Axiforma-Regular2';
  font-size: 13px;
  color: #BCC1CE;
  line-height: 22px;
  margin-left: 6px;
}
.pricingsummary h6{
  font-family: 'Axiforma-Regular2';
  font-size: 13px;
  color: #BCC1CE;
  margin-top: 36px;
  margin-bottom: 0px;
}
.pricingsummary a{
  color: #3064F9;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pricingsummary h3 svg{
  margin-left: 5.5px;
  position: relative;
  top: 2px;
}
.pricingsummary h6.comingsoon{
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  color: #BCC1CE;
}
.greyed h4 , .greyed p, .greyed h3 ,.greyed h6 , .greyed h5{
  color: #BCC1CE!important;
}
.greyed svg{
  display: none!important;
}
.greyed a{
  cursor: not-allowed!important;
}
.greyed div{
  background-color: #BCC1CE!important;
}
.greyed{
  cursor: not-allowed!important;
}
.newplangrid{
  padding-bottom: 30px;
}
.enterprise-solution-mobile{
  display: none;
}

.mobi-tab label{
  display: none;
}
.mobi-tab input{
  display: none;
}
.discount-percent{
  position: absolute;
  top: -34px;
  right: 27px;
  display: flex;
  align-items: baseline;
}
.discount-percent svg{
  position: relative;
  top: 10px;
}
.discount-percent p{
  color: #BCC1CE;
  font-size: 12px;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
  margin-left: 8px;
}
.mobilecomparison .pricingflex a{
  display: none;
}
.platforms img{
  margin-left: 4px;
}
.activeprice , .activeprice:hover{
  background-color: #B8BFD0!important;
  border: 1px solid #B8BFD0!important;
  color: #fff!important;
  cursor: not-allowed;
}
.the-active-plan{
  position: absolute;
  right: 0;
  top: -13px;
  left: 0;
  margin: auto;
}
.the-active-plan span{
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #B1C5FE;
  padding: 5px 17px 4px 17px;
  color: #3064F9;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin: 0px;
}
.pricedowngrade{
  color: #FF4848;
  background-color: transparent;
  border: none;
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-family: 'Axiforma-Regular';
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}
.pricedowngradeone{
  color: #ff4848;
    background-color: transparent;
    border: none;
    width: 100%;
    margin: 15px 0px 0px;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    gap: 10px;
  
}

.pricedowngrade img{
  margin-left: 9px;
  cursor: pointer;
}
.lowerplan{
  color: #FFFFFF!important;
  background-color: #BCC1CE!important;
  border: 1px solid #BCC1CE!important;
  cursor:not-allowed;
}
.lowerplan:hover {
  box-shadow: unset !important
}
.currentplan{
  color: #BCC1CE!important;
  background-color: #fff!important;
  border: 1px solid #E3E6F1!important;
  cursor:not-allowed;
}
.currentplan:hover {
  box-shadow: unset !important
}
.price-strip{
  border-radius: 3px;
  background-color: #7782A1;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 40px;
  margin-top: -60px;
}
.price-strip p{
  font-size: 13px;
  color: #E3E6F1;
  margin-bottom: 0px;
  font-family: 'Axiforma-Regular';
}
.price-strip p a{
  color: #fff;
  text-decoration: underline;
}
.price-strip svg{
  margin-right: 8px;
  min-width: 20px;
}
.new-price-grid{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.new-price-grid .pricingsummary{
  margin-right: 30px;
}

.unavailable-plan-head h4{
  color: #7782A1;
  font-size: 24px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 12px;
  margin-top: 60px;
}
.unavailable-plan-head h5{
  color: #7782A1;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  margin-bottom: 30px;
}
.all-in-one-filter{
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}
.all-in-one-filter div{
  display: flex;
  align-items: center;
  margin-right: 40px;
  padding-bottom: 11px;
  padding-right: 7px;
  border-bottom: 2px solid rgba(256, 256, 256, .0001);
  cursor: pointer;
}
.all-in-one-filter div p{
  margin-bottom: 0px;
  margin-left: 6px;
  color: #7782A1;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
}
.all-in-one-filter div:hover p{
  color: #3064F9;
}
.all-in-one-filter div:hover{
  border-bottom: 2px solid rgba(48, 100, 249, .5);
}
.all-active{
  border-bottom: 2px solid rgba(48, 100, 249, .5)!important;
}
.all-active p{
  color: #3064F9!important;
}
.not-all-active{
  cursor: not-allowed!important;
  border-bottom: 2px solid rgba(256, 256, 256, .0001)!important;
}
.not-all-active p{
  color: #7782A1!important;
}
#seecomparison .all-in-one-filter{
  margin-top: 42px;
  margin-bottom: -20px;
  justify-content: center;
}
.pricing-main-page{
background-image: url(../../assets/images/dashboard/app-icon-background.png);
background-repeat: no-repeat;
background-position: right bottom;
min-height: 100vh;
background-size: contain;
background-attachment: fixed;
}
.deals-link{
  position: absolute;
  right: 298px;
  top: 0px;
  cursor: pointer;
  transition: .5s;
  color: #52CB86;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  border: 1px dashed #52CB86;
  padding: 7px 15px 6px 16px;
  cursor: pointer;
  width: max-content;
  background-color: #fff;
}
.deals-link img{
  margin-right: 6px;
}
.pricing-blank{
  background-color: transparent;
  border: none;
  width: 100%;
  margin-top: 7px;
  font-family: 'Axiforma-Regular';
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-blank-two{
  background-color: transparent;
  border: none;
  width: 100%;
  margin-top: 20px;
  font-family: 'Axiforma-Regular';
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deals-link svg{
  display: none;
}
.pricingsummary{
  transition: .5s;
}
.web-to-app-pricingsummary:hover{
  border: 1px solid #5684FD;
  transition: .5s;
  box-shadow: 0px 4px 8px rgb(86 132 253 / 13%);
}
.wordpress-pricingsummary:hover{
  border: 1px solid #60BBD9;
  transition: .5s;
  box-shadow: 0px 4px 8px rgb(0 116 154 / 13%);
}
.wooCommerce-to-app-pricingsummary:hover{
  border: 1px solid #7F54B3;
  transition: .5s;
  box-shadow: 0px 4px 8px rgb(127 84 179 / 13%);
}
.custom-app-pricingsummary:hover{
  border: 1px solid #5E64FF;
  transition: .5s;
  box-shadow: 0px 4px 8px rgb(94 100 255 / 13%);
}
.support-plan{
  padding-top: 120px;
  background-color: #F8FAFE;
  padding-bottom: 83px;
}
.support-plan .container {
  position: relative;
  max-width: 1110px;
}
.support-plan h3{
  color: #1B2952;
  font-size: 36px;
  line-height: 51px;
  font-family: 'Axiforma-Bold';
  margin: 0px;
}
.support-plan h6{
  color: #7782A1;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  margin: 10px 0px 30px 0px;
}
.support-featurebox h4{
  color: #1B2952;
  font-size: 12px;
  font-family: 'Axiforma-Bold';
  line-height: 20px;
  margin: 0px;
}
.support-featurebox p{
  color: #7782A1;
  font-size: 12px;
  font-family: 'Axiforma-Regular';
  line-height: 16px;
  margin-bottom: 0px;
}
.featureone{
  min-height: 37px;
  margin-bottom: 20px;
}
.featuretwo{
  min-height: 100px;
  margin-bottom: 20px;
}
.featurethree{
  min-height: 55px;
  margin-bottom: 20px;
}
.featurefour{
  min-height: 72px;
  margin-bottom: 20px;
}
.featurefive{
  min-height: 55px;
  margin-bottom: 20px;
}
.featuresix{
  min-height: 55px;
  margin-bottom: 20px;
}
.featureseven{
  min-height: 40px;
}
.support-getintouch{
  width: 100%;
  text-align: center;
  margin-top: 60px;
}
.support-getintouch p{
  color: #7782A1;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
}
.support-getintouch p a , .support-getintouch p a:hover{
  color: #3064F9;
  font-size: 14px;
  margin-left: 3px;
  font-family: 'Axiforma-Regular';
}
.price-first-half .support-cancellation{
  color: #FF4848;
  font-size: 12px;
  font-family: 'Axiforma-Regular';
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 0px;
  min-height: 18px;
}
.price-first-half .support-cancellation svg{
  cursor: pointer;
}
.pricewithfeature{
  margin-bottom: 12px;
}
.pricing-decimal{
  font-size: 18px;
  font-family: 'Axiforma-Regular';
}

.support-plan .pricebox {
  width: 32.5%;
}
.agency-header-gap{
  padding-top: 140px;
}
.all-discount-percent {
  position: absolute;
  top: -30px;
  right: 65px;
  display: flex;
  align-items: baseline;
}
.all-discount-percent img {
  position: relative;
  top: 10px;
  left: 5px;
}
.all-discount-percent p {
  color: #BCC1CE;
  font-size: 12px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 0px;
  margin-left: 8px;
}
.pricing-notice{
  border: 1px solid #BACBFF;
    background-color: #F3F6FF;
    margin: auto;
    position: relative;
    max-width: 1080px !important;
    margin-bottom: 35px;
    border-radius: 6px;
    padding: 16px 20px;
    margin-top: -33px;
}
.pricing-notice-header{
  font-family: 'Axiforma-Bold';
    font-size: 14px;
    line-height: 19.67px;
    margin-bottom: 4px;
    color: #1B2952
}
.pricing-notice-para{
  font-family: 'Axiforma-Regular';
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}



@media screen and (max-width: 1500px) and (min-width: 1201px) {

  .sectionheadings {
      font-size: 26px;
      line-height: 42px;
  }
  .subheadings1 {
      font-size: 15px;
      line-height: 24px;
  }
  .subheadings2 {
      font-size: 18px;
      line-height: 24px;
  }
  /* img{
      height: auto;
      max-width: 100%;
  } */
  .paddingbottom120 {
      padding-bottom: 80px;
  }
  .paddingtop100 {
      padding-top: 70px;
  }
  .paddingbottom80 {
      padding-bottom: 60px;
  }
  .paddingbottom100 {
      padding-bottom: 70px;
  }
  .allplans h1 {
      font-size: 34px;
  }
  .teampricing h1 {
      font-size: 34px;
  }
  .accordionItemHeading {
      font-size: 16px;
  }
  .pricingfaq h5 {
      font-size: 14px;
  }
  .haveidea {
      padding: 40px 30px;
  }
  .agencylink a {
      font-size: 13px;
  }


}



@media screen and (min-width: 1201px){
  
.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-md-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-md-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.mobilecomparison{
  display: none;
}
.webshow{
  display: block!important;
}
.mobileshow{
  display: none!important;
} 
.ratingsbox{
  min-height: 334px;
} 
.customersaying .paddingtop100 .midflex div{
  min-height: 60px;
}  
.new-price-grid .pricingsummary:nth-child(3) , .new-price-grid .pricingsummary:nth-child(6){
  margin-right: 0px;
}
 

}

@media screen and (max-width: 767px){

.pricebox h5 {
  min-height: auto;
}    
.all-in-one-filter p{
  display: none;
}    
.all-in-one-filter div {
  display: flex;
  align-items: center;
  margin-right: 30px;
  padding-bottom: 11px;
  padding-right: 0px;
}
/* .plan-cancel-popup .modal-content {
  height: auto;
}     */
.teampricing {
  padding-top: 104px!important;
}
.teampricing .container{
  max-width: 100%!important;
    padding-right: 20px;
    padding-left: 20px;
}
.allplans .allflex{
  display: flex;
}    
.tabbed .tab-content{
  padding-bottom: 0px;
  margin-top: 20px;
}    
.enterprise-solution{
  display: none;
}
.enterprise-solution-mobile{
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 40px 20px 40px 20px;
  margin-top: 20px;
  position: relative;
  border-radius: 5px;
}
.enterprise-solution-mobile div {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.enterprise-solution-mobile div h3{
  font-family: 'Axiforma-Bold';
  font-size: 20px;
  line-height: 30px;
  color: #0F193B;
}    
.enterprise-solution-mobile div img{
  display: none;
}
.enterprise-solution-mobile p {
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  line-height: 24px;
  color: #5F6578;
  margin-bottom: 20px;
}
.enterprise-solution-mobile a {
  width: 140px;
  height: 40px;
  color: #3064F9;
  border: 1px solid #3064F9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  transition: .5s;
  font-size: 14px;
}  
.discount-percent {
  position: relative;
  top: -22px;
  right: auto;
  left: 50%;
  width: 185px;
}
.mobilecomparison .all-in-one-filter{
  margin-top: 20px;
}
.all-in-one-filter div:hover {
  border-bottom: none!important;
}
  
}


@media screen and (max-width: 1200px){
  .pricing-notice{
      margin-bottom: 20px;
      margin-top: -23px;
  }


.support-getintouch{
  margin-top: 40px;
}
.featureone{
  min-height: auto;
}
.featuretwo{
  min-height: auto;
}
.featurethree{
  min-height: auto;
}
.featurefour{
  min-height: auto;
}
.featurefive{
  min-height: auto;
}
.featuresix{
  min-height: auto;
}
.support-plan h6 {
  margin: 3px 0px 20px 0px;
} 
.support-plan {
  padding-top: 96px;
  padding-bottom: 60px;
} 
.support-plan h3 {
  font-size: 26px;
  line-height: 40px;
}
.pricingsummary {
  margin-bottom: 20px;
  
}
.plan-cancel-popup-footer .plan-upgradebtn {	
  width: 300px;	
  font-size: 16px;	
  margin-left: 0px;	
}	

.plan-cancel-popup-footer .plan-cancelbtn {	
  margin: 0px;	
  line-height: 16px;	
  font-size: 16px;	
  height: 16px;	
  margin-top: 30px;	
  border: none;	
  width: 100%;	
}	
.plan-cancel-popup-footer {	
  flex-direction: column-reverse;	
}	
.plan-cancel-popup h4 {	
   width: 100%;	
}	
.plan-cancel-popup h6 {	
  width: 100%;	
}	
.plan-cancel-popup .modal-body {	
  justify-content: center;	
  align-items: center;	
  display: flex;	
  padding: 60px 20px!important;	
}
.tab > label {
  padding: 12px 20px 12px 20px;
}    
.enterprise-solution-mobile div img{
  display: none;
}    
.deals-link svg{
  display: block;
}    
#seecomparisonmobile .plandetail {
  margin-top: 0px;
  border-top: none!important;
  padding-top: 0px!important;
}    
.new-price-grid .pricingsummary {
  margin-right: 0px;
}   
.mobi-tab label{
  display: flex;
}
.mobi-tab input{
  display: none;
}
#chck1 {
position: absolute;
opacity: 0;
z-index: -1;
}

/* Accordion styles */

.mobi-tab {
width: 100%;
color: white;
overflow: hidden;
}
.mobi-tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  margin-bottom: 0px!important;
  width: 100%;
  border-top: 1px solid #DCE1EB;
  padding: 20px!important;
  color: #B8BFD0!important;
  font-size: 14px!important;
}
.mobi-tab-label::after {
    content: "\276F";
    width: 10px;
    height: 10px;
    text-align: center;
    transition: all .35s;
  }
.mobi-tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all .35s;
}
.mobi-tab-content-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  cursor: pointer;
}
.mobi-tab-content:hover {
    background: darken(#2c3e50, 10%);
  }

#chck1:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck1:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}
  
#chck2:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck2:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}
  
#chck3:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck3:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}
  
#chck4:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck4:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}
  
#chck5:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck5:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck6:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck6:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}
  
#chck7:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck7:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
} 
  
#chck8:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck8:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck9:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck9:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
} 
  
#chck10:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck10:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck11:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck11:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck12:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck12:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck13:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck13:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck14:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck14:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck15:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck15:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck16:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck16:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck17:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck17:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
} 
  
#chck18:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck18:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck19:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck19:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck20:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck20:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck21:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck21:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    
  
#chck22:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck22:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck23:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck23:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

#chck24:checked + .mobi-tab-label::after{
    transform: rotate(90deg);
}
#chck24:checked ~ .mobi-tab-content {
  max-height: 100vh;
  padding: 1em;
}    

  
  
.price-second-half{
  border-top: none!important;
  padding: 0px!important;
}    
.featurebox{
  height: auto!important;
  min-height: inherit!important;
  max-height: none!important;
  overflow: visible!important;
}    
.reviewlink{
  display: none!important;
}    
.pricingsummary {
  width: 100%;
}
 
#mainheader .slider-menu p{
  text-align: left!important;
}

/*    Mega Menu Mobile Starts*/
  
  .custom-mobilemenu, .slider-menu {
      display: block!important;
  }
  .solution-menu{
      position: fixed;
      left: 100%;
      height: 100%;
      transition: 1s;
      overflow: auto;
      width: 100%;
      background-color: #fff;
      top: 0;
      z-index: 99999999;
      display: block!important;
  }
  .show-solutions {
      left: 0%!important;
      transition: 1s;
      box-shadow: 0px 2px 8px rgb(15 25 59 / 16%)
  }
  .solution-menu .mega-menu-solutions , .solution-menu .mega-menu-industries{
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      margin: auto;
      display: block;
      opacity: 1;
      padding-left: 0px;
  }
  .solution-menu .solutions-sub , .solution-menu .industries-sub{
      z-index: 2;
      background-color: #fff;
      padding: 20px;
      display: block;
      flex-wrap: wrap;
      border-radius: 6px;
      box-shadow: none;
  }
  .solution-menu .mega-menu-solutions .mega-menu-items , .solution-menu .mega-menu-industries .mega-menu-items{
      display: flex;
      align-items: flex-start;
      width: 100%;
      position: relative;
      margin-bottom: 30px;
  }
  .solution-menu .mega-menu-solutions .mega-menu-items div:nth-child(1) , .solution-menu .mega-menu-industries .mega-menu-items div:nth-child(1){
      margin-right: 10px!important;
      min-width: 44px;
  }
  .solution-menu .mega-menu-solutions .mega-menu-items p , .solution-menu .mega-menu-industries .mega-menu-items p {
      width: 80%;
      text-align: left!important;
  }
  .solution-menu .solutions-sub:before , .solution-menu .solutions-sub:after{
      display: none;
  }
  .solution-menu .industries-sub:before , .solution-menu .industries-sub:after{
      display: none;
  }
  .disabled-mega-menu div:nth-child(1){
      margin-right: 10px!important;
      min-width: 44px;
  }
  .solution-menu .slidermenu-header{
      border-bottom: 1px solid #ccc;
      height: 70px;
      padding: 0px 21px;
  }
  .solution-menu .slidermenu-header a{
      color: #0F193B;
      font-size: 18px;
      font-family: 'Axiforma-Bold';
      text-decoration: none;
      display: flex;
      align-items: center;
  }
  .solution-menu a{
      text-decoration: none;
  }
  .solution-menu .slidermenu-header a svg{
      margin-right: 20px;
  }
  .mega-menu-industry-link {
      width: 100%!important;
      justify-content: start;
      padding-left: 55px;
  }
  .mobile-small-sub-menu{
      list-style-type: none;
      width: 100%!important;
  }
  .small-sub-menu li{
      list-style-type: none;
      margin-bottom: 10px!important;
  }
  
/*    Mega Menu Mobile Ends*/
   
  
  
  
  .m-0{
      margin: 0px;
  }

  .mainmenuitems{
      display: none;
  }
  .container {
      width: 100%!important;
  }
  /* header {
      height: 70px;
  } */
  header svg{
      margin-top: 5px;
  }
  .paddingtop100{
      padding-top: 60px;
  }
  .paddingtop160{
      padding-top: 100px;
  }
  .paddingtop80{
      padding-top: 60px;
  }
  .paddingbottom80{
      padding-bottom: 0px;
  }
  .paddingbottom100{
      padding-bottom: 60px;
  }
  .paddingbottom120{
      padding-bottom: 60px;
  }
  
  .sectionheadings {
      color: #0F193B;
      font-size: 24px!important;
      font-family: 'Axiforma-Bold';
      line-height: 35px;
      width: 100%!important;
  }
  .normaltext {
      line-height: 19px;
      width: 100%!important;
  }
  .subheadings2 {
      width: 100%!important;
  }
  .subheadingtop {
      margin-top: 23px;
  }
  .subheadings1 {
      font-size: 16px;
      line-height: 24px;
  }
  .footergrid.semiflex{
      display: block;
  }
  .footergrid div {
      width: 100%;
  }
  .customersaying .paddingtop100 .midflex div {
      width: 50%;
      margin-bottom: 20px;
  }
  .customersaying .paddingtop100 .midflex div:nth-child(9),.customersaying .paddingtop100 .midflex div:nth-child(10) {
      margin-bottom: 0px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
  }
  .customersaying .paddingtop100 div {
      margin-top: 20px;
  }
  .customersaying .container .paddingtop100{
      padding-top: 35px;
  }
  .customersaying  .subheadings1{
      margin-bottom: 20px;
  }
  footer {
      padding: 60px 0px 19px 0px;
  }
  .footergrid h5 {
      font-size: 14px;
      margin-top: 40px;
      margin-bottom: 31px;
  }
  .bottombar {
      margin-top: 40px;
      padding-top: 22px;
  }
  .bottombar .allflex{
      display: block;
  }
  .bottombar a {
      margin-left: 0px;
      margin-right: 22px;
      margin-bottom: 0;
      padding-bottom: 0;
      line-height: 10px;
  }
  .bottombar p{
      margin-bottom: 21px!important;
  }
  .pricingplans.allflex{
      display: block;
  }
  .pricebox{
      width: 100%;
      margin-bottom: 20px;
  }
  .support-plan .pricebox {
    width: 100%;
}
  .teampricing .container .tabs {
      position: relative;
      left: 0px;
      top: -6px;
      margin-bottom: 20px;
      width: 100%;
  }
  .teampricing .container .tabs .tab{
    width: 33.33%;
    text-align: center;
  }
  .agencypricing .container .tabs{
      position: relative;
      left: 0px;
  }
  .webcomparison{
      display: none;
  }
  .pricemajor {
      width: 65%;
      min-width: 65%;
  }
  .priceminor {
      width: 35%;
      min-width: 35%;
      text-align: right;
      padding-right: 10px;
  }
  .pricebox h2 {
      font-size: 20px;
  }
  .pricelist p {
      font-size: 14px;
  }
  .customersaying .row .col-md-6:nth-child(1) {
      padding-right: 15px;
  }
  .customersaying .row .col-md-6:nth-child(1) h3{
      padding-top: 0px;
  }
  .accordionItemHeading {
      font-size: 16px;
  }
  .webshow{
      display: none!important;
  }
  .mobileshow{
      display: block!important;
  }
  .slider-menu {
      position: fixed;
      left: 100%;
      height: 100%;
      transition: .8s;
      overflow: auto;
      width: 100%;
      background-color: #fff;
      top: 0;
      z-index: 99999;
      display: none;
  }
  .show-sidebar {
      left: 0%!important;
      transition: .8s;
      z-index: 9999999;
  }
  .custom-mobilemenu, .slider-menu {
      display: block!important;
  }
  .opac:after {
      position: absolute;
      background: rgba(0,0,0,.5);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      transition: 2s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      z-index: 9999;
      position: fixed;
  }
  .slider-menu ul li a {
      font-family: 'Axiforma-Bold';
      font-size: 16px!important;
      text-decoration: none;
  }
  .slider-menu ul li {
      list-style-type: none;
      margin-top: 12px;
      padding-bottom: 30px;
      padding-left: 25px;
      text-align: left;
  }
  .slidermenu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px 0px 20px;
      background-color: #fff;
  }
  .slider-menu ul {
      padding-left: 0!important;
      margin-top: 42px;
  }
  .slider-menu .allflex a{
      width: 48%;
      margin-left: 0px;
      margin-right: 0px;
      text-align: center;
      text-decoration: none;
  }
  .slider-menu .allflex{
      padding: 0px 20px;
  }
  .slider-menu .loginbtn , .slider-menu .startfree{
      padding: 10px 22px 8px!important;
  }
  .haveidea.allflex{
      display: none;
  }
  .haveidea{
      padding: 0px;
      border: none;
      border-radius: 0px;
      margin-bottom: 60px;
      margin-top: 60px;
  }
  .haveidea h4 {
      margin-bottom: 20px;
  }
  .pricingfaq .accordionItemHeading {
      padding: 30px 0px 30px 0px!important;
      font-size: 16px!important;
  }
  .open .accordionItemHeading {
      padding: 30px 0px 0px 0px;
  }
  .open .accordionItemContent {
      padding: 10px 0px 10px 0px;
  }
  #mainheader{
      height: 70px;
  }
  .awards {
      margin-top: 50px;
  }
  .agencypricing {
      padding-bottom: 40px;
      padding-top: 81px;
  }
  .mobilecomparison{
      padding: 40px 0px 20px 0px;
  }
  .pricingfaq .container{
      overflow: hidden;
  }
  .teampricing{
      padding-top: 160px;
  }
  .featurebox {
      max-height: 240px;
      overflow: hidden;
      min-height: 150px;
  }
  footer br{
      display: block!important;
  }
  
  .allplans .newplangrid{
      display: flex;
  }
  .plansummary{
      width: 100%!important;
      padding: 46px 44px 26px 44px!important;
      margin-bottom: 20px;
  }
  .allplans .allflex .plansummary:last-child{
      margin-bottom: 0px;
  }
  .allplans h1 {
      font-size: 26px;
      margin-bottom: 30px;
      text-align: center!important;
  }
  .all-in-one-filter {
      margin-top: -10px;
  }
  .paddingtop140 {
      padding-top: 93px!important;
  }
  .unavailable-plan-head h4{
      text-align: center!important;
  }
  .pricing-main-page{
      background-image: none;
  }
  .unavailable-plan-head h5{
      text-align: center!important;
      line-height: 20px;
  }
  .teampricing h1 {
      font-size: 26px;
      margin-bottom: 30px;
  }
  .teampricing .container .deals-link {
    left: calc(50% - 62px);
    top: 12px;
    position: relative;
    right: auto;
    margin-bottom: 30px;
  }
  .pricingfaq .accordion-header button {
      font-size: 15px!important;
      line-height: 24px;
      padding: 30px 0px 10px 0px!important;
  }
  .accordion-flush {
      margin-top: 15px;
  }
  .pricingfaq .accordion-item {
    padding-bottom: 0px;
  }
  .price-strip {
    margin-bottom: 25px;
    margin-top: -10px;
  }
  .discount-percent-all-mob{
    display: none;
  }
  .all-discount-percent {
    position: relative;
    top: -20px;
    left: calc(50% - 27px);
    display: flex;
    align-items: baseline;
    width: max-content;
  }
  .agency-header-gap .container .tabs {
    width: 100%;
  }
  .agency-header-gap  .container .tab {
    width: 50%;
    text-align: center;
  }
  .workspace-priceing .container .tabs .tab{
    width: 50%!important;
  }
  .pricemajor .full-page-tooltip .full-page-tooltipsubheader {
    top: 21px;
  }
  .priceminor p{
    justify-content: end;
  }
}

@media screen and (max-width: 1200px) and (min-width: 720px) {
  .new-price-grid .pricingsummary {
    margin-right: 24px;
}
.pricingsummary {
  width: 48%;
}
}
@media screen and (max-width: 1200px) and (min-width: 600px) {
  .pricing-notice{
    margin-top: -40px;
}

  .teampricing {
      padding-top: 120px!important;
  }
  .teampricing .container{
      max-width: 90%!important;
  }
  .all-in-one-filter div p{
      display: none;
  }
  .enterprise-solution div {
      width: calc(100% - 280px);
  }
  .new-price-grid .pricingsummary:nth-child(even){
      margin-right: 0px;
  }
  /* .new-price-grid .pricingsummary {
      margin-right: 25px;
  } */
  .plandetail p {
      text-align: left!important;
  }
  /* .pricingsummary {
      width: 48%;
  } */
  .pricebox {
      width: 49%;
  }
  .pricingplans.allflex{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
  }
  .priceminor p{
      text-align: right!important;
  }
  .enterprise-solution {
      margin-top: 0px;
      padding: 50px 40px 45px 40px;
  }
  .tab-content {
      padding-bottom: 40px;
  }
  .mobilecomparison .tabbed .tabs{
      margin-bottom: 0px!important;
  }
  .featurebox {
      min-height: 220px;
  }
  .customersaying .paddingtop100 .midflex div {
      width: 20%;
  }
  .customersaying .paddingtop100 .midflex div:nth-child(9), .customersaying .paddingtop100 .midflex div:nth-child(10) {
      margin-bottom: 20px;
  }
  .customersaying .col-md-6{
      width: 50%;
  } 
  .customersaying .row{
      align-items: center;
  }
  .footerbanner{
      text-align: center!important;
  }
  .footerbanner a {
      margin: auto;
  }
  .footergrid.semiflex {
      display: flex;
      align-items: baseline;
  }
  .footergrid p{
      text-align: left!important;
  }
  .bottombar .allflex {
      display: flex;
  }
  .bottombar p {
      margin-bottom: 0px!important;
  }
  footer {
      padding: 25px 0px 19px 0px;
  }
  .discount-percent {
    left: 50%;
    top: 20px;
  }
  .enterprise-solution div h3 {
      font-size: 26px;
  }
  
}




















