@font-face {
  font-family: "Proxima-Regular";
  src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../assets/fonts/ProximaNova-Bold.otf);
}
p {
  font-family: "Axiforma-Regular";
}
.tab ul {
  text-align: center;
  margin: auto;
  width: 330px;
  margin-top: 30px;
  border: 1px solid #f4f5f9;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.new_tab_class ul {
  width: 300px;
  border: 1px solid #e0e3eb;
}
.tab ul li {
  width: 50%;
  display: flex;
  align-items: center;

  padding: 2px;
}
.tab ul li div {
  color: #b0b8c9;
  font-size: 14px;
  height: 42px;
  line-height: 42px;
  width: 100%;
  display: inline-block;
  margin: auto;
  font-family: "Axiforma-Bold";
  letter-spacing: 1px;
  cursor: pointer;
}
.new_tab_class ul li div {
  height: 38px;
  line-height: 38px;
}
.summarypara {
  color: #7782a1;
  font-size: 14px;
  min-height: 130px;
}
.active {
  color: #ffffff !important;
  background: #7782a1 !important;
  border-radius: 4px;
}
.summarychart {
  background: white;
  border: 1px solid #e0e3eb;
  border-right: none;
  border-radius: 10px;
  width: 100%;
  margin: auto;
  /* box-shadow: 0 0 6px rgb(112 155 250 / 20%); */
  display: flex;
  overflow: hidden;
}
.widthset {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.summarydetail {
  padding: 30px;
  flex-grow: 1;
  border-right: 1px solid #e0e3eb;
  transition: 0.3s;
  flex-basis: 0;
}
/* .summarydetail:hover{
    background:#FCFDFF;
    border: 1px solid #E0E3EB;
    border-radius: 15px;
} */
.listing {
  list-style: none;
  padding: 0px;
  margin-top: 34px;
}
.listing li {
  font-size: 14px;
  color: #000000;
  font-family: "Axiforma-Regular";
  margin-bottom: 30px;
}
.summarydetail h2 {
  color: #000000;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 15px;
  padding: 0px;
}
.line {
  border-top: 1px solid #b0b8c9;
  width: 26.5px;
  height: 2px;
  display: block;
}
.listpricing h4 {
  font-size: 30px;
  color: #000000;
  font-family: "Axiforma-Bold";
}
.listpricing span {
  color: #7782a1;
  font-family: "Axiforma-Bold";
  font-size: 14px;
}
.savetag {
  color: #7782a1;
  font-family: "Axiforma-Bold";
  font-size: 14px;
}
.listpricing h4 span {
  font-size: 14px;
  color: #000000;
  font-family: "Axiforma-Bold";
  position: relative;
  top: -3px;
}
.getstarted {
  color: #7782a1;
  border: 1px solid #dce0ee;
  background: #fff;
  width: 200px;
  height: 48px;
  border-radius: 4px;
  display: block;
  text-align: center;
  line-height: 48px;
  margin-top: 25px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  transition: 1s;
}
.getstartedactive {
  color: #fff;
  border: 1px solid #3064f9;
  background: #3064f9;
  width: 200px;
  height: 48px;
  border-radius: 4px;
  display: block;
  text-align: center;
  line-height: 48px;
  margin-top: 25px;
  transition: 1s;
  font-family: "Axiforma-Bold";
}
.getintouch {
  color: #3064f9;
  border: 1px solid #3064f9;
  width: 220px;
  height: 54px;
  border-radius: 4px;
  display: block;
  text-align: center;
  line-height: 54px;
  font-size: 16px;

  transition: 1s;

  font-family: "Axiforma-Regular";
}
.getstarted:hover {
  color: #fff;
  background-color: #7782a1;
  transition: 1s;
}
.getstartedactive:hover {
  color: #fff;
  background: #3064f9;
}
.seefull {
  text-align: center;
  margin: 55px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.seefull p {
  color: #3064f9;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  padding: 0px;
  margin-right: 12px;
  cursor: pointer;
}
.looking {
  background: #f4f5f9;
  padding: 60px 50px;
  border: 1px solid #f4f5f9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.looking h5 {
  color: #000000;
  font-size: 30px;
  font-family: "Axiforma-Bold";
  margin-top: 0px;
}
.looking p {
  color: #000000;
  font-size: 18px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  padding: 0px;
}
.compareplan {
  margin-top: 54px;
  text-align: center;
}
.compareplan h5 {
  color: #000000;
  font-size: 36px;
  font-family: "Axiforma-Bold";
}
.compareplan p {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}
.tableview {
  border: 1px solid #e0e3eb;
  border-radius: 6px;
  margin-top: 40px;
  display: flex;
}
.tableiteam {
  flex-grow: 1;
  border-right: 1px solid #e0e3eb;
}
.tableiteam:last-child {
  border-right: 0px;
}

.tableheading {
  padding: 30px;
  /* border-bottom: 1px solid #E0E3EB; */
}
.tablelist {
  padding: 30px;
  border-top: 1px solid #e0e3eb;
}
.tablelist h5 {
  color: #000000;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.tableheading h6 {
  color: #000000;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.pricelst {
  border-top: 1px solid #e0e3eb;
  padding: 30px;
}
.heading h3 {
  color: #000000;
  font-size: 36px;
  font-family: "Axiforma-Bold";
  text-align: center;
}
.note {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 22px;
}
.setbackground {
  background: #fcfdff;
  padding-top: 120px;
  padding-bottom: 60px;
}
.makechange {
  padding: 0px;
  margin: 0px;
}
.testimonial {
  display: flex;
}
.testimonial h6 {
  color: #000000;
  font-size: 40px;
  margin-bottom: 70px;
  font-family: "Axiforma-Bold";
}
.firstleft {
  width: 33%;
}
.secondhalf {
  width: 67%;
  padding-left: 120px;
}
.testimonial p {
  color: #676b77;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}
.first {
  background: white;
  padding: 40px;
  box-shadow: 0 0 6px rgb(112 155 250 / 20%);
  border-radius: 10px;
  width: 48%;
}
.first h6 {
  font-size: 24px;
}
.first p {
  color: #676b77;
  font-size: 14px;
}
.mobileratingget {
  justify-content: flex-end;
}

.text-center.subscription-tab-new ul.plan-nav {
  padding: 3px;
}

@media screen and (max-width: 980px) {
  .summarychart {
    flex-wrap: wrap;
    display: block;
  }
  .summarypara {
    min-height: auto;
  }
  .heading h3 {
    font-size: 22px;
  }
  .setbackground {
    padding-top: 20px;
  }
  .tab ul {
    margin-top: 20px;
  }
  .summarychart {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 10px 5px;
  }
  .summarydetail {
    margin-bottom: 20px;
    box-shadow: 0 0 6px rgb(112 155 250 / 20%);
    border-radius: 10px;
    overflow: hidden;
    background: white;
    border: none;
  }
  .seefull {
    margin: 35px 0px 7px 0px;
  }
  .looking {
    flex-wrap: wrap;
    padding: 19px;
    border-radius: 6px;
  }
  .looking h5 {
    font-size: 20px;
  }
  .looking p {
    font-size: 15px;
    margin-bottom: 24px;
  }
  .getintouch {
    height: 40px;
    line-height: 40px;
    width: 160px;
    font-size: 16px;
  }
  .compareplan {
    margin-top: 0px;
  }
  .compareplan h5 {
    font-size: 22px;
  }
  .compareplan p {
    display: none;
  }
  .tableview {
    flex-wrap: wrap;
  }
  .mobilelist {
    list-style: none;
    padding: 0px;
    display: flex;
    margin-bottom: 0px;
  }
  .mobilelist li {
    flex-grow: 1;
    background: #e3e6f1;
    height: 40px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    font-size: 14px;
    line-height: 40px;
    font-weight: 600;
    border-radius: 0px 0px 0px 0px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0px 0px;
  }
  .mobilelist li:first-child {
    margin-left: 0px;
  }
  .mobilelist li:last-child {
    margin-right: 0px;
  }
  .firstleft {
    width: 100%;
  }
  .testimonial {
    flex-wrap: wrap;
  }
  .testimonial h6 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .firstleft > p {
    text-align: center;
  }
  .secondhalf {
    width: 100%;
    padding: 0px;
  }
  .first {
    width: 100%;
    margin-bottom: 40px;
  }
  .first:last-child {
    margin-bottom: 0px;
  }
  .testmob {
    flex-wrap: wrap;
  }
  .mobilerating,
  .mobileratingget {
    justify-content: center !important;
    margin-bottom: 50px;
  }
  .tablelist .listing {
    margin-top: 10px;
  }
  .tablelist .listing li {
    margin-bottom: 15px;
  }
  .tablelist {
    padding: 15px 20px;
  }
  .tableview {
    margin-top: 0px;
    border-radius: 0px 0px 6px 6px;
  }
  .compareplan h5 {
    margin-bottom: 20px;
  }
  .mobileactive {
    background: white !important;
    border: 1px solid #e0e3eb;
    color: #3064f9 !important;
    border-bottom: 0px;
  }
}
