@font-face {
  font-family: "Proxima-Regular";
  src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../assets/fonts/ProximaNova-Bold.otf);
}
.ui_selection li {
  width: 122px;
  display: block;
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
}
.ui_selection li a {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.ui_selection {
  border-bottom: 1px solid #f1f5fc;
}

.ui_selection li.active {
  border-bottom: 2px solid #3064f9;
  color: #7782a1;
}

.heading h4 {
  color: #1b2952;
  font: 16px "Axiforma-Bold";
}

.heading h5 {
  color: #7782a1;
  font: 14px "Axiforma-Regular";
}

.childContent {
  margin: 30px 0px;
}

.uploadInput {
  padding: 15px 0px;
}

.upload_btn input {
  height: 60px;
}

.bundleId img {
  cursor: pointer;
}
.bundleId p {
  color: #1b2952;
  font: 14px "Axiforma-Regular";
}
.bundleId p span {
  color: #7782a1;
  margin-right: 5px;
  font: 14px "Axiforma-Regular";
}
.adjustshawidth {
  width: 90% !important;
}
@media screen and (max-width: 1200px) {
  .ui_selection li {
    width: 50%;
  }
  .buttonfixed {
    width: 100% !important;
    margin: 8px;
  }
  .bundleId {
    justify-content: flex-end;
    margin-right: 10px;
  }
  .mobile_ios_view {
    justify-content: flex-start;
    padding-left: 16px;
  }
}
