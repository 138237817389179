@font-face {
  font-family: "Axiforma-Regular";
  src: url(../../assets/fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../../assets/fonts/Axiforma-Regular2.woff2);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../../assets/fonts/Axiforma-Bold.ttf);
}

body {
  font-family: "Axiforma-Regular";
}
/* Icon popup Css */

.icon-library-popup .icon-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-header h4 {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.icon-search {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  height: 40px;
  width: calc(100% - 231px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.icon-search input {
  border: none;
  width: 100%;
  outline: none !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  padding: 5px 15px 5px 0px;
  height: 38px;
}
.icon-search button {
  border: none;
  background-color: #fff;
  /* height: 40px; */
  display: flex;
  align-items: center;
  /* width: 35px; */
  padding: 10px 10px 10px 15px;
}
.icon-library-popup .modal-header,
.icon-library-popup .modal-body,
.icon-library-popup .modal-footer {
  padding: 0px;
}
.icon-library-popup .icon-header {
  border-bottom: 1px solid #e3e6f1;
  /* padding: 15px 20px !important; */
  padding: 0px 20px !important;
    height: 70px;
}
.icon-library-popup .icon-main {
  display: flex;
}
.icon-lib-filter {
  width: 200px;
  min-width: 200px;
  box-shadow: 0px 0px 5px rgb(188 193 206 / 50%);
  padding: 21px 10px;
  border-right: 1px solid #e3e6f1;
}
.icon-library {
  width: calc(100% - 200px);
  overflow: auto;
  height: 520px;
  padding: 20px 20px 20px 20px;
  background-color: #f8fafe;
}
.icon-library::-webkit-scrollbar {
  width: 4px !important;
}
.icon-library-popup .modal-footer {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1 !important;
  z-index: 1;
  background-color: #fff;
}
.icon-library-popup .modal-footer .cancel-icon {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 14px;
  margin: 0px !important;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  background-color: transparent;
}
.icon-library-popup .modal-footer .cancel-icon:hover {
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}

.icon-library-popup .modal-footer .d-flex .done-icon {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--custom_color1) !important;
  background-color: var(--custom_color2) !important;
  color: #fff;
  font-size: 14px;
  margin: 0px !important;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  /* text-transform: uppercase; */
  &:hover {
    background-color: var(--custom_color5) !important;
    color: var(--custom_color4) !important;
    border: 1px solid var(--custom_color6) !important;
  }
}

.icon-library-popup .modal-footer .d-flex .disable-icon-popup {
  background-color: #bcc1ce !important;
  border: 1px solid #bcc1ce !important;
  cursor: not-allowed;
}

.icon-library-popup .modal-footer .d-flex .done-icon:hover {
  /* background-color: #0040f9!important;
    border: 1px solid #0040f9!important; */
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}

.icon-library-popup .modal-footer .d-flex .disable-icon-popup:hover {
  box-shadow: none !important;
  background-color: #bcc1ce !important;
  border: 1px solid #bcc1ce !important;
}

.icon-library-popup .modal-footer p {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-right: 20px;
}
.select-icon-type {
  display: flex;
  border-radius: 3px;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
  gap: 10px;
}
.select-icon-type .form-group {
  /* margin-right: 10px;
    margin-bottom: 10px; */
  width: 9.125%;
  margin-bottom: 0px;
}
.select-icon-type label {
  margin-bottom: 0px !important;
  width: 100%;
}
.addicon-ratio > input {
  display: none;
}
.select-icon-type .icon-image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 95px; */
  width: 100%;
  height: 95px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  border-radius: 3px;
  background-color: #ffffff;
}
.select-icon-type .icon-image img {
  max-width: 45px;
  max-height: 45px;
}
.icon-library input:checked + .icon-image:before {
  transform: scale(1);
  content: "";
  display: block;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100px;
  transition: 0.4s;
  z-index: 3;
  border: 2px solid #3064f9;
}
.select-icon-type div:last-child {
  border-right: none;
}
.icon-color-filter .form-group {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.icon-color-filter .icon-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 8px 8px 10px;
  height: 34px;
  border-radius: 4px;
}
.Upload-icon-left-toggle div svg {
  margin-right: 14px;
  width: 20px;
}
.Upload-icon-left-toggle div {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  padding: 7px 10px;
  margin-bottom: 10px;
}
.Upload-icon-left-toggle .selected-upload-icondiv {
  color: #3064f9;
  background-color: #f1f4fb;
  border-radius: 4px;
}
.icon-color-filter .icon-image p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.icon-color-filter {
  margin-top: 11px;
  margin-bottom: 8px !important;

}
.select-icon-color > input {
  display: none;
}
.select-icon-color {
  width: 100%;
  margin-bottom: 0px;
}
.icon-lib-filter h6 {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 0px 10px;
}
.icon-lib-filter input:checked + .icon-image {
  /* transform: scale(1);
    content: "";
    display: block;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 100px;
    transition: 0.4s;
    z-index: 3;
    border: 1px solid #3064f9; */
  background-color: #f1f4fb;
}
.icon-lib-filter input:checked + .icon-image p {
  color: #3064f9 !important;
}
.reset-color {
  position: relative;
}
.reset-color img {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}
.icon-lib-filter button {
  width: 100%;
  height: 50px;
  background-color: #3064f9;
  border: none;
  color: #fff;
  text-transform: uppercase;
  margin-top: 10px;
}
.mob-icon-header {
  display: none;
}
.icon-filter-btn {
  display: none;
}
.mob-icon-filterdiv {
  width: calc(100% - 200px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.upload-icon-button {
  /* width: 120px; */
  width: fit-content;
    height: 70px;
    font-size: 13px;
    color: #3064f9;
    border: unset;
    border-bottom: 2px solid #3064f9;
    /* border-radius: 3px; */
    margin-left: 30px;
    font-family: "Axiforma-Regular";
    background-color: transparent;
    transition: 0.5s;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-icon-disable-btn{
  color: #BCC1CE;
  font-family: "Axiforma-Regular";
font-size: 13px;
line-height: normal;
margin-left: 30px;
border: unset;
background-color: transparent;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
border-bottom: 2px solid transparent;
height: 70px;
}
.upload-icon-disable-btn svg {
  margin-right: 6px;
}
  
/* .upload-icon-library-btn {
  width: 140px;
} */
.upload-icon-button svg {
  margin-right: 6px;
}
/* .upload-icon-button:hover {
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
} */
.mob-icon-lib-filter.webshow {
  display: none !important;
}
.icon-library-uploadlink-div {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

}
.icon-library-uploadlink-div .upload-icon-crop-delete{
  width: 32px !important;
  height: 32px !important;
}
.icon-library-uploadlink-div img{
  width: 200px;
  height: 200px;
}

.icon-library-uploadlink-div h2 {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 9px;
}
.icon-library-uploadlink-input {
  width: 600px;
  border: 1px solid #bcc1ce;
  height: 50px;
  border-radius: 3px;
  background-size: 21px;
  background-position: 98% center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-right: 15px;
}
.icon-library-uploadlink-input input {
  width: 100%;
  border: none;
  height: 48px;
  padding: 0px 10px 2px 20px;
  outline: none !important;
  background-color: transparent;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #1b2952;
}
/* .icon-library-uploadlink-input input::placeholder{
  max-width:  95% !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}
.icon-library-uploadlink-input input:placeholder-shown{
  max-width:  95% !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
} */
.icon-library-uploadlink-div p {
  font-size: 11px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-top: 10px;
  text-align: left !important;
}
.icon-library-uploadlink-input img {
  cursor: pointer;
  height: unset;
    width: unset;
}
.upload-icon-file-div {
  width: 100%;
  height: 100%;
  /* display: flex; */
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.upload-icon-crop-delete {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 999;
  cursor: pointer;
}
.upload-icon-file {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 480px;
  /* width: 480px; */
  /* border: 2px dashed #BCC1CE; */
  border-radius: 6px;
}
.upload-icon-file img{
  width: 200px;
  height: 200px;
}
.upload-icon-default-file {
  width: 60px;
  height: 60px;
  display: flex;
  background-color: #3064f9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.upload-icon-file p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 26px;
  margin-top: 26px;
  line-height: 26px;
  margin-bottom: 6px;
}
.upload-icon-file .dragupload-image {
  width: unset;
}
.upload-icon-file p span {
  color: #3064f9;
}
.upload-icon-file h6 {
  color: #7782a1;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  line-height: 15px;
}
.icon-library-popup .modal-footer .icon-footer-error-text {
  font-size: 12px;
  line-height: 12px;
  font-family: "Axiforma-Regular";
  color: #ff4848;
  margin-bottom: 0px;
  margin-left: 5.5px;
  margin-right: 19.5px;
  margin-top: 3px;
}
.icon-library-popup .modal-footer .icon-select-text {
  margin-bottom: 0px;
  font-size: 12px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-right: 20px;
}
.icon-footer-error {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-div-mob-link {
  display: none;
}
.upload-icon-file .dragupload-image {
  border: unset;
}
.mob-icon-footer-error{
  display: none;
}
.web-uploaded-icon{
  font-size: 12px;
  font-family: 'Axiforma-Regular';
  margin-right: 24px;
    color: #7782A1


}
.mob-uploaded-icon{
  display: none;
}
.upload-icon-file section{
  height: 100%;
  width: 100%;
}

.mob-icon-done-btn{
  display: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imagecenter{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mob-icon-footer-error svg{
  margin-top: 2px;
    }








@media (max-width: 1200px) {
  .mob-uploaded-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    margin-top: 20px;
    color: #7782A1
  }
  .mob-icon-footer-error {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .mob-icon-footer-error-text{
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #FF4848 !important;
    margin-bottom: 0px !important;
    margin-left: 6px !important;
    margin-top: 0px !important;
    text-align: left !important;
  }
  .upload-link-main-div {
    width: 100%;
  }
  .mob-icon-done-btn {
    background-color: #3064f9;
    text-decoration: none;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border: 1px solid #3064f9;
    line-height: 15px;
    transition: 0.5s;
    cursor: pointer;
    height: 50px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center
}
.mob-icon-header p{
  color: #3064f9;
        font-size: 14px;
        margin-left: 20px !important;
        font-family: "Axiforma-Regular";
        margin-bottom: 0px;
}

.upload-icon-disable-btn{
  display: none;
}
  .mobview-icon-library {
    background-color: #ffff;
    padding: 0px 20px 20px 20px;
  }
  .icon-library-uploadlink-input {
    width: 100%;
  }
  .icon-library-uploadlink-div h2 {
    display: none;
  }
  .icon-library-uploadlink-div {
    height: unset;
    flex-direction: column;
  }
  .upload-div-mob-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .upload-div-link {
    color: #7782a1;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin-right: 40px;
  }
  .upload-icon-crop-delete {
    display: none;
  }
  .upload-div-link svg {
    margin-right: 12px;
    margin-bottom: 0px;
    width: 20px;
  }
  .selected-upload-view {
    color: #3064f9 !important;
  }
  .mob-upload-icon-button {
    border: unset;
    width: max-content;
    padding: 0px;
    font-size: 15px;
    color: #3064f9;
    border-radius: 0px;
    margin-left: 40px;
    font-family: "Axiforma-Regular";
    background-color: transparent;
    transition: 0.5s;
    height: 60px;
    border-bottom: 2px solid #3064f9;
  }
  .mob-upload-icon-disable-btn{
    border: unset;
    width: max-content;
    padding: 0px;
    border-radius: 0px;
    margin-left: 40px;
    font-family: "Axiforma-Regular";
    background-color: transparent;
    transition: 0.5s;
    height: 60px;
    border-bottom: 2px solid transparent;
  }
  /* .mob-upload-icon-button svg {
    margin-right: 6px;
  } */
  .upload-icon-button {
    display: none;
  }
  .select-icon-type .icon-image img {
    max-width: 40px;
    max-height: 40px;
  }
  .mob-colorfilter-div {
    padding: 26px 20px;
  }
  .web-icon-lib-filter {
    display: none;
  }
  .mob-icon-lib-filter {
    display: block;
  }
  .icon-color-filter {
    margin-top: 11px;
    display: flex;
    flex-wrap: wrap;
  }
  .icon-color-filter .icon-image {
    padding: 11px 20px 11px 20px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #bcc1ce;
  }
  .icon-color-filter .icon-image p {
    margin-right: 6px;
  }
  .icon-lib-filter button {
    text-transform: unset;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    font-size: 16px;
  }
  .icon-lib-filter input:checked + .icon-image {
    background-color: #f1f4fb;
    border: unset;
  }
  .icon-color-filter .form-group {
    width: unset;
  }
  .select-icon-color {
    width: unset;
  }
  .upload-icon-file-div {
    align-items: unset;
  }

  .icon-library-popup .modal-content {
    position: fixed !important;
  }
  .icon-library-popup .modal-footer {
    display: none;
  }
  .mob-icon-filterdiv {
    width: 100%;
  }
  .icon-search {
    border: 1px solid #e3e6f1;
    border-radius: 3px;
    height: 44px;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(188, 193, 206, 0.2);
  }
  .upload-icon-file {
    background-color: #f8fafe;
    cursor: pointer;
    height: 388px;
    width: 100%;
    border: unset;
    /* border: 2px dashed #BCC1CE; */
  }
  .mobile-icon-search button {
    border-left: 1px solid #e3e6f1;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-filter-btn {
    display: block;
    min-width: 44px;
    min-height: 44px;
    background-color: #ffff;
    border: 1px solid #e3e6f1;
    margin-left: 10px;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgba(188, 193, 206, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-filter-btn svg{
 margin-bottom: 0px;
  }
  .mobile-icon-search button svg {
    margin-bottom: 0px;
  }
  /* .icon-lib-filter:before {
      content: "";
      position: absolute;
      background: #fff;
      z-index: -2;
      top: -7px;
      right: 11px;
      height: 26px;
      width: 26px;
      box-shadow: -6px 6px 6px 0 rgb(15 25 59 / 5%);
      transform: rotate(46deg);
      -ms-transform: rotate(46deg);
      -webkit-transform: rotate(135deg);
    } */
  .icon-library {
    width: 100%;
  }
  .select-icon-type .form-group {
    width: 22.6%;
  }
  .select-icon-type .icon-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
  }
  .icon-library-popup .icon-header {
    border: none;
  }
  .icon-library-popup .modal-footer p {
    margin-right: 10px;
  }
  .icon-library-popup .icon-header {
    padding: 15px 20px 20px 20px !important;
    flex-direction: column;
    align-items: unset;
    height: unset;
  }
  .icon-header h4 {
    width: 100%;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .mob-icon-filterdiv {
    display: flex;
  }
  .mob-icon-header {
    display: flex;
    height: 60px;
    padding: 0px 20px;
    align-items: center;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    justify-content: space-between;
  }
  .mob-icon-header svg {
    margin-bottom: 0px;
  }
  /* .icon-search {
      border: 1px solid #e3e6f1;
      border-radius: 0px;
    } */
  .icon-library-popup .modal-body {
    height: calc(100vh - 170px);
  }
  .icon-library-popup .icon-main {
    height: 100%;
    overflow-x: hidden;
  }
  .icon-library-popup .icon-main .icon-library {
    height: 100%;
    max-height: inherit !important;
  }
  .icon-lib-filter h6 {
    margin: 0px;
  }
  .select-icon-type .icon-image .mob-selected-icon-spinner svg{
        width: 100%;
    height: 100%;
  }
  .icon-search button svg{
margin-bottom: 0px;
  }
  .icon-lib-filter {
    box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
    position: absolute;
    z-index: 9;
    background-color: #fff;
    width: 100%;
    min-width: 100%;
    height: max-content;
    height: 100%;
    padding: 0px;
  }
  .icon-library-popup .icon-main {
    display: block;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .select-icon-type .icon-image {
    width: 100%;
    height: 85px;
  }
  .select-icon-type .form-group {
    width: 15.2%;
  }
  /* .icon-library-popup{
    min-height: auto!important;
    max-height: inherit!important;
  } */
}
@media (min-width: 901px) and (max-width: 1200px) {
  .select-icon-type .icon-image {
    width: 100%;
    height: 100px;
  }
  .select-icon-type .form-group {
    width: 13.3%;
  }
}
@media (max-width: 330px) {
  .select-block-type .form-group {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 26% !important;
  }
  .icon-library-popup .modal-footer .cancel-icon,
  .icon-library-popup .modal-footer .d-flex .done-icon {
    width: 82px !important;
  }
  .icon-library-popup .modal-footer p {
    margin-right: 5px;
  }
}
@media (max-width: 360px) {
  .select-icon-type .form-group {
    width: 22%;
  }
}

