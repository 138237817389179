.payperapp-team_page{
    background-color: #fcfdff;
    display: flex;
    min-height: 100vh;
    position: relative;
}
.payperapp-team-head{
    color: #1b2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0;
}
.payperapp-team-tophead{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.payperapp-team-topheadright{
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 5px;
}
.limitmodal-back{
  font-family:"Axiforma-Regular"
}
.pay-per-app-upgrade-btn{
        height: 44px;
        text-transform: unset;
        line-height: 20px;
        font-family:"Axiforma-Regular"
}
.payperapp-user-email-label{
        font-size: 13px ;
        font-family: "Axiforma-Regular";
        color: #7782A1;
        margin-bottom: 3px;
        margin-top: 18px
}
.payperapp-team-topheadright p{
    font-family: "Axiforma-Regular";
    font-size: 16px;
    color: #7782a1;
    margin-bottom: 0;
    margin-right: 0;
}
.payperapp-team-topheadright .payperapp-team-usercountspan{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #bcc1ce;
    margin-bottom: 0;
    /* margin-right: 20px; */
}
.payperapp-team-invitebtn{
    width: 120px;
    height: 40px;
    border: none;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: .5s;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.payperapp-team-invitebtn:hover{
    /* background-color: #134bec; */
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    /* border: 1px solid var(--custom_color6)!important; */
}
/* .payperapp-team-invitebtn img{
    margin-right: 5px;
} */
.payperapp-team-maindiv{
    /* min-height: 83vh; */
    background-color: #fff;
    border: 1px solid #e3e6f1;
    box-shadow: 0 2px 6px rgba(0,0,0,.06);
    border-radius: 6px;
    margin-top: 15px;
}
.payperapp-team-maindiv .newspinner{
   
    border-radius: 6px;
}
.payperapp-user-popup .modal-body {
    padding: 20px 31px 30px;
}
.payperapp-gray-btn{
    margin-right: 20px;
   
}
.payperapp-team-upgrademain{
    position: relative;
    /* min-height: calc(100vh - 90px); */
    background-image: url("../../assets/images/dashboard/app-icon-background.png");
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    background-attachment: fixed;
    /* background-color: #F8FAFE; */
    min-height: 80vh;
    display: flex;
}
.team-upgrade-left{
    width: 47%;
    padding-top: 197px;
    padding-left: 120px;

}
.team-upgrade-right{
    padding-top: 137px;
    width: 53%;

}
.team-upgrade-left h2{
    color: #1b2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    margin-bottom: 20px !important;
}
.team-upgrade-left p{
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 19px;
    margin-bottom: 30px;
    text-align: left !important;
}
.team-upgrade-left ul {
    padding-left: 0 !important;
}
.team-upgrade-left ul li{
    line-height: 17px;
    font-size: 13px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;
    padding-bottom: 18px;
    list-style-type: none;
    position: relative;
}
.team-upgrade-left ul li:before {
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 5px;
}
.team-upgrade-left a{
    width: 200px;
    background-color: #3064f9;
    border-radius: 3px;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    font-family: "Axiforma-Regular";
    height: 50px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    margin-top: 18px;
    border: 1px solid transparent;
}
.team-upgrade-left a:hover {
    background-color: #0040f9;
    color: white !important;
  
}
.payperapp-team-mobinvitebtn{
 display: none;
}

.payperapp-teamtable-head{
 
    display: flex;
    align-items: center;
    height: 40.5px;
    padding: 0 20px;
    border-bottom: 1px solid #f1f2f8;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    margin: 0;
}
.payperapp-teamtable-head div:first-child {
    width: 28%;
}
.payperapp-teamtable-head div:nth-child(2) {
    width: 27%;
}
.payperapp-teamtable-head div:nth-child(3) {
    width: 20%;
}
.payperapp-teamtable-head div:nth-child(4) {
    width: 20%;
}
.payperapp-teamtable-head div:nth-child(5) {
    width: 15%;
}
.payperapp-teamtable-list{
    display: flex;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border-bottom: 1px solid #e3e6f1;
    padding: 0 20px;
    min-height: 100px;
}
.payperapp-teamtable-list:hover {
    background-color: #f8f9fd;
    transition: .5s;
}

.payperapp-teammember-img{
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 100px;
    margin-right: 11px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
    
}
.payperapp-teammember-img .rounded-circle{
    width: 60px;
    height: 60px;
}
.payperapp-teammember-img .img-fluid{
    width: 60px;
    height: 60px;
}

.payperapp-user-name{
    display: flex;
    align-items: center;
    width: 28%;
}
.payperapp-team-email{
    width: 27%;
}
.payperapp-team-Role{
    width: 20%;

}
.payperapp-team-seen{
    width: 20%;
}
.payperapp-team-statusmain{
    width: 15%;
}
.payperapp-team-status{
   
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.payperapp-user-namediv{
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0;
}
.payperapp-user-iddiv{
    color: #7782a1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
    margin-top: 0px;
    text-align: left !important;
}
.payperapp-teammob-label{
    display: none;
}
.payperapp-team-email .payperapp-team-email-id{
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0;
    text-align: left !important;
    padding-right: 5px;
    word-break: break-word;
}
.payperapp-team-Role .payperapp-team-role-type{
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
    text-align: left !important;
}

.payperapp-team-seendate{
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0;
    text-align: left !important;
}

.payperapp-team-seenip{
    color: #bcc1ce;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
    margin-top: 10px;
    text-align: left !important;
}
.payperapp-team-status h5{
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0;
    color: #7782a1;
}

.payperapp-team-status span{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #3064f9;
    margin: 10px 0 0;
    cursor: pointer;
    display: none;
    transition: .5s;
}
.payperapp-teamtable-list:hover .payperapp-team-status span {
    display: block;
}
.payperapp-team-status svg{
cursor: pointer;
}

.limit-modal-upgrade-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.payper-app-invite-user{
    padding: 25px 20px 0px 20px !important;
}
.payper-app-invite-user .user_modalheading{
    font-size: 18px !important;
    font-family:"Axiforma-Bold" ;
    color: #1b2952;
    margin-bottom: 8px;
}
.pay-per-app-limit-btn-hover:hover{
background-color: #134bec;
transition: 0.5s;
}
.payperapp-user-body-label{
        font-size: 13px;
        font-family: "Axiforma-Regular";
        color: #7782A1;
        margin-bottom: 3px;
        margin-top: 18px;
}
.payper-app-invite-user .user_modalsubheading{
    font-size: 13px ;
    font-family:"Axiforma-Regular" ;
    color: #7782a1;
    margin-bottom: 0px;
}
.payperapp-user-body-label-heading{
    font-size: 14px;
    font-family: "Axiforma-Bold";
    color: #1b2952;
}
.payperapp-user-body-label-paragraph{
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #7782A1;
}
.payperapp-user-input{
    height: 50px !important;
    font-size: 13px !important;
    font-family: "Axiforma-Regular";
    border: 1px solid #BCC1CE !important;
    outline: none !important;
    color: #1b2952;
    width: 100%;
    padding: 16px 15px;
    border-radius: 3px;
}

.payperapp-user-input:hover{
    border: 1px solid #3064f9!important;
    background-color: #f8fafe!important;
    transition: .5s;
}
.payperapp-user-input:focus{
    border: 1px solid #3064f9!important;
    background-color: #f8fafe!important;
    transition: .5s;
}

.payperapp-user-body{
    padding: 0px 20px 0px 20px !important;
}
.payperapp-push_notification .container_1{
    display: block;
    position: relative;
    min-height: 40px;
    /* line-height: 40px; */
    margin-bottom: 0px;
    cursor: pointer;
    /* font-size: 16px; */
    color: #7782a1;
    user-select: none;
    font-family: "Axiforma-Regular";
    padding-left: 30px;
    width: 100%;
}
/* .payperapp-push_notification .container_1 h5{
    font-size: 14px ;
    font-family:"Axiforma-Bold" ;
    color: #1b2952;
} */
.payperapp-push_notification .checkmark {
    position: absolute;
    top: -2px;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #bcc7e6;
}

/* .payperapp-push_notification .container_1 p{
    margin-bottom: 0px;
    font-size: 13px ;
    font-family:"Axiforma-Regular" ;
    color: #7782A1;
} */
.payperapp-color_picker{
    margin: 30px 0px 0px 0px;
    width: 100%;
}
.payperapp-push_notification .container_1 input {
    display: none;
}
.payperapp-push_notification .container_1 input:checked ~ .checkmark {
    background-color: #3064f9;
    border: none !important;
}

.payperapp-push_notification .container_1 input:checked ~ .checkmark:after {
    display: block;
}

.payperapp-push_notification .container_1 .checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    position: absolute;
}
.payperapp-user-cancel-user{
    padding: 15px 20px !important;
    margin-top: 44px;
    border-top: 1px solid #E3E6F1;
    display: flex;
    justify-content: space-between;

}
.payperapp-user-cancel-user .cancel-btn{
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    border: 1px solid #BCC1CE;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    cursor: pointer;
}
.payperapp-team-user-inactive {
    background-color: #BCC1CE !important;
    border: 1px solid #BCC1CE !important;
    box-shadow: none;
    cursor: not-allowed!important;
    text-decoration: none;
    border-radius: 4px;
    color: white !important;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    padding: 11px 30px;
    transition: 0.5s;
    width: 100px;
    height: 40px;
    cursor: pointer;
}
.payperapp-team_btn {
    background-color: #3064f9;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    padding: 11px 30px;
    border: 1px solid #3064f9;
    transition: 0.5s;
    cursor: pointer;
    width: 100px;
    height: 40px;
}
.payperapp-team_btn:hover {   
     /* background-color: #134bec;
     color: #fff; */
     background-color: var(--custom_color5)!important;
     color: var(--custom_color4)!important;
     border: 1px solid var(--custom_color6)!important;
    box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
    transition: 0.5s;
}
.mob-payperapp-btn-bottom{
    display: none ;
}
.addon-red-deletebtn.red-btn{
    width: 140px !important;
    height: 44px !important;
    font-size: 14px;
    border: unset;
    border: 1px solid #ff4848;
    background-color: white;
    color: #ff4848;
    border-radius: 3px;
    text-transform: capitalize;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    margin: 0;
}
.addon-red-deletebtn.red-btn:hover {
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(255 72 72 / 29%);
}
.customer-activate-btn-team:hover{
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
}
.payper-app-customer-cancel-btn{
    font-family: 'Axiforma-Regular';
}
.mob-team-delete-div{
    display: none;
}
.user-web-delete-icondiv{
    display: flex;
    gap: 25px;
}
.user-delete-modal-popup .modal-body{
    padding: 60px 40px;
}
.user-delete-modal-popup h5{
    color: #1b2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    margin-bottom: 20px;
}
.user-delete-modal-popup h6{
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-align: center;
    margin-bottom: 30px;
    line-height: 18px;
}
.user-delete-cross-icon .menu-crosssvg{
    top: 15px;
}
.newuser-modal-popup{
    max-width: 480px !important;
}
.user-delete-modal-title{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    text-align: center;
    margin: auto;
    line-height: 25px;
    margin-bottom: 15px;
}
.user-delete-modal-para{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 25px;
    text-align: center;
    margin-bottom: 30px;
}
.newuser-modal-popup .modal-body { 
    padding: 60px 40px;
}
.user-new-modal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.usermodal-grey-btn{
    border: 1px solid #C8CEDB;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #7782A1;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
}
.usermodal-red-btn{
    width: 140px !important;
    height: 44px !important;
    font-size: 14px;
    border: unset;
    border: 1px solid #ff4848;
    background-color: white;
    color: #ff4848;
    border-radius: 3px;
    text-transform: capitalize;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    margin: 0;
}
.usermodal-red-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(255 72 72 / 29%);
}
.usermodal-blue-btn{
    border: 1px solid #3064F9;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #fff !important;
    background-color: #3064F9;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.usermodal-blue-btn:hover{  
        background-color: var(--custom_color5)!important;
        color: var(--custom_color4)!important;
        border: 1px solid var(--custom_color6)!important;
}
    
@media (min-width: 2100px) {
    .team-upgrade-right {
        width: unset;
    }
    .team-upgrade-left {
        width: unset;
        padding-left: unset;
        padding-right: 20px;
    }
}

@media (max-width: 1200px) {
    .payperapp-team-topheadright {
       
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .user-new-modal-footer {
        flex-direction: column-reverse;
    }
    .usermodal-red-btn{
        width: 280px !important;
        height: 50px !important;
        font-size: 16px;
        border: 1px solid #ff4848;
        background-color: #ff4848;
        color: white;
    }
    .usermodal-blue-btn{
        width: 280px !important;
        height: 50px !important;
        font-size: 16px;
    }
    .usermodal-grey-btn{
        border: unset;
        font-size: 16px;
      
    }
    .addon-red-deletebtn.red-btn{
        width: 280px !important;
        height: 50px !important;
        font-size: 16px;
        border: 1px solid #ff4848;
        background-color: #ff4848;
        color: white;
    }
    .user-delete-cross-icon .menu-crosssvg {
        top: 40px;
        right: 20px;
    }
    .payperapp-team-head {
        color: #1b2952;
        font-size: 20px;
        font-family: "Axiforma-Bold";
        margin-bottom: 0;
        line-height: 20px;
    }
    .user-web-delete-icondiv{
        display: none !important;
    }
    .mob-payperapp-team-status{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .mob-team-delete-div{
        display: flex;
        gap: 30px;
    }
    .payperapp-team-tophead .app-dashboard-head{
     margin: 0px;
    }
    .mob-last-seendiv{
        display: flex;
        justify-content: space-between;
    }
    .limit-modal-upgrade-div .team-limit-btn {
        width: 280px !important;
        height: 50px !important;
        font-size: 16px;
        margin: auto;
    }
    .payperapp-team-tophead {
        margin-top: 5px;
    }
    .payperapp-teammember-img {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .payperapp-team-user-inactive{
        width: 100%;
        height: 50px;
        font-size: 16px;
        margin-bottom: 36px;
    }
    .payper-app-invite-user .crossmodal{
        right: 20px;
        top: 26px;
    }
    .payperapp-team_btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        margin-bottom: 36px;
    }
    .mob-payperapp-btn-bottom{
        display: flex;
        border-top: unset;
        padding: 0px 0px 20px 0px !important;
        margin-top: 40px;
        flex-direction: column-reverse;
    }
    .mob-payperapp-btn-bottom .cancel-btn{
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Axiforma-Regular';
        color: #7782A1;
        cursor: pointer;
    }
    .web-payperapp-btn-bottom{
        display: none !important;
    }
    .payper-app-invite-user {
        padding: 19px 20px 0px 20px !important;
    }
    .pay-perapp-team-btn-mob{
        display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Axiforma-Regular";
    height: 50px;
    font-size: 16px;
    }
    .limit-modal-upgrade-div{
        display: unset;
    
    }
  
    .payperapp-team-invitebtn{
        display: none; 
    }
    .payperapp-team-maindiv .newspinner{
   
        border: 1px solid #e3e6f1;
        box-shadow: 0 2px 6px rgba(0,0,0,.06);
        border-radius: 6px;
        height: 79vh;
    }
    .payperapp-team-mobinvitebtn{
        width: 100%;
    height: 50px;
    border: 1px solid #3064f9;
    background-color: transparent;
    font-size: 16px;
    color: #3064f9;
    font-family: "Axiforma-Regular";
    border-radius: 4px;
    transition: .5s;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    /* margin-left: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;
    }
    /* .payperapp-team-mobinvitebtn:hover{
        background-color: #134bec;
    } */
    /* .payperapp-team-mobinvitebtn img{
        margin-right: 8px;
    } */

    .payperapp-team-maindiv {
        min-height: unset;
        background-color: unset;
        border: unset;
        box-shadow: unset;
       
      
    }
    .payperapp-team-upgrademain{ 
        position: relative;
        background-color: #fff;
        border: 1px solid #e3e6f1;
        box-shadow: 0 2px 6px rgba(0,0,0,.06);
        border-radius: 6px;
    background-image: unset;
    justify-content: unset;
    flex-direction: column;
    }
    

    .payperapp-team_page .right-panel-gap {
        padding: 15px 15px !important;
    }
.team-upgrade-left{
    width: 100%;
    padding: 20px 20px;

}
.team-upgrade-left p {
    margin-bottom: 15px;
}
.team-upgrade-left h2 {
  
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px !important;
}
.team-upgrade-left a {
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
}
.team-upgrade-left ul li {
    font-size: 14px;
}
.team-upgrade-right {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.payperapp-teamtable-head{
    display: none;
}
.payperapp-teamtable-list {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0,0,0,.06);
    border-radius: 6px;
    border: 1px solid #e3e6f1;
    display: grid;
    align-items: center;
    justify-content: unset;
    border-bottom: 1px solid #e3e6f1;
    padding: 0px;
    margin-bottom: 15px;
}
.payperapp-teammob-label{
    display: block;
    font-size: 12px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;

}
.payperapp-team-email p {
    margin-top: 2px;
}
.payperapp-team-status span {
 display: block;
 margin: 0px 0 0;
 position: relative;
    bottom: 10px;
}
.payperapp-teamtable-list:hover {
    background-color: white;
}
.payperapp-team-seen, .payperapp-team-Role, .payperapp-team-email {
    width: unset;
    border-bottom: 1px solid #E3E6F1;
    padding: 20px 15px 20px;
}
.payperapp-user-name {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: unset;
    border-bottom: 1px solid #E3E6F1;
    padding: 21px 15px;
}
.payperapp-team-status {
    margin-top: 3px;
}
.payperapp-team-statusmain{
    width: unset;
    padding: 21px 15px 24px;
}
.payperapp-teammember-img {
    margin-right: 0px;
}
.payperapp-user-iddiv {
    margin-top: 0px;
}
.payperapp-team-seenip {
font-size: 14px;
margin-top: 2px;
}
.addon-user-delete{
    flex-direction: column;
}
.addon-red-deletebtn{
    width: 100%;
}
.addon-red-deletebtn .red-btn p{
    height: 50px;
    border: unset;
    background-color: #ff4848;
    color: white;
    border-radius: 3px;
    font-size: 16px;
    width: 280px;

}
.addon-user-delete .grey-btn p{
    margin-top: 30px;
    border: none;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
}
.addon-user-delete .grey-btn p:hover{
    color: #7782a1;
    background-color: unset;
    transition: 0.5s;
}
}