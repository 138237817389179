/*Preview Page Starts*/

/* Page Details */
:root {
  --menu-fontname: "Axiforma-Bold";
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.otf);
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.ttf);
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}
/* Page Details Ends */
.top_screenshot .preview-new-button {
  border: none;
  cursor: pointer;
  transition: 0.5s;
  width: auto;
  border-radius: 0px;
  margin: 5px 7px;
  height: 48px;
}
.right_gap {
  margin-right: 20px;
}
.top_screenshot .preview-new-button.form-group .http select {
  cursor: pointer;
}

.top_screenshot .preview-new-button:hover {
  box-shadow: none;
}
.page-detail {
  padding: 0px 8px;
}
.page-detail p {
  font-size: 14px;
  margin-bottom: 10px;
}
.page-detail h2 {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
}
.page-detail h1,
.page-detail h3,
.page-detail h4,
.page-detail h5,
.page-detail h6,
.page-detail span {
  font-size: 15px !important;
}
.page-detail ol {
  padding-left: 18px;
}
/* Recent Blogs */
.blogs,
.recent-blog-viewbtn {
  padding: 5px;
}
.blogs h4 {
  padding-left: 0px !important;
}
.blogs .rece-blogs {
  padding: 5px;
}

.blogs .recent-blog-image {
  height: 122px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.two-blogs a {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  cursor: pointer;
}
.two-blogs {
  position: relative;
}
/* Recent Blogs Ends */

/* Blog Banner */
.blog-banner-image {
  height: 145px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.blog_banner .rece-blogs h4 {
  font-family: "Axiforma-Bold";
  font-weight: 600;
  padding: 5px 10px !important;
  max-height: 40px;
  line-height: 16px;
  font-size: 11px;
  background-color: #f8faff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Blog Banner Ends */
.mystyle {
  position: relative;
}
.mystyle:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}
/* Review Module */

.additionalinfo h4,
.reviewbox h4,
.relatedprods h4 {
  color: #192f6b;
  font-size: 13px;
  padding: 3px;
  text-align: left;
}
.review-bars {
  margin-top: 5px;
}
.greenbox {
  width: 95px;
  margin-right: 8px;
}
.greenbox-bar {
  width: calc(100% - 95px);
}
.greenbox {
  background-color: #70c33f;
  border-radius: 4px;
  padding: 12px 11px;
}
.greenbox h3 {
  color: #fff;
  margin: 10px 0px 5px 0px;
  font: 600 18px "Montserrat-Regular";
}
.greenbox p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
  font: 12px "Montserrat-Regular";
}
.greenbox-bar svg {
  margin-left: 3px;
}
.greenbox-bar .bar {
  margin-bottom: 2px;
}
progress[value] {
  -webkit-appearance: none;
  appearance: none;
  height: 7px;
  margin-left: 5px;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
}
progress[value] {
  background-color: #eee;
  border-radius: 5px;
}
progress[value].five-rating::-webkit-progress-value,
progress[value].five-rating {
  background-image: -webkit-linear-gradient(left, #70c33f, #70c33f);
}
progress[value].four-rating::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, #bfd741, #bfd741);
}
progress[value].three-rating::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, #fdda44, #fdda44);
}
progress[value].two-rating::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, #fda63c, #fda63c);
}
progress[value].one-rating::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, #ef4949, #ef4949);
}
.review-bars label {
  font: 14px "Montserrat-Bold";
}
.review-comment {
  margin-top: 10px;
}
.review-comment .profile-image {
  width: 30px;
}
.bar label {
  width: 30px;
  padding-left: 2px;
}
.reviewcountnumber p {
  margin: 0px;
  font: 10px "Montserrat-Regular";
}
.reviewcountnumber {
  width: 15px;
  text-align: center;
}
.bar label p {
  margin: 0px;
  width: 9px;
  margin-top: 2px;
}
.bar .progress {
  width: calc(100% - 47px);
}
.review-comment .star-image {
  width: 70px;
  float: left;
  margin-left: 4px;
}
.review-comment p {
  font: 12px "Montserrat-Regular";
}
.review-comment .date {
  color: #7581a1;
  font: 10px "Montserrat-Regular";
  margin: 0px;
}
.review-comment .review-details {
  color: #333;
  font: 10px "Montserrat-Regular";
  margin: 10px 0px 0px 0px;
  text-align: left;
}
.review-comment h5 {
  color: #000;
  font: 600 12px "Montserrat-Regular";
  margin: 0px 0px 0px 5px;
}

/* Blog Detail Page */
.blog-detail {
  padding: 0px 10px;
}
.blog-detail h2 {
  color: #000;
  font-family: "Axiforma-Bold";
  font-weight: 600;
  font-size: 15px;
  margin-top: 15px;
}
.blog-detail p,
.blog-detail li {
  color: #333;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 10px;
}
.blog-detail h3,
.blog-detail h1,
.blog-detail h4,
.blog-detail h5,
.blog-detail h6,
.blog-detail span,
.blog-detail a {
  font-size: 13px !important;
}

.blog-detail img {
  max-width: 100%;
  height: auto;
}
.blog-detail video,
.blog-detail iframe {
  max-width: 100%;
  height: auto;
}
/* Blog Detail Page Ends */

/* Blog Category */
.blog-cate {
  width: 33.3%;
  padding: 5px;
}
.blog_categories,
.blog-category-viewbtn {
  padding: 5px;
}
.blog_categories h4 {
  padding-left: 0px !important;
}
.blog-cate-new {
  height: 50px;
  padding: 0px 5px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid #d5e0ff;
  border-radius: 3px;
  background-color: white;
}

/* Blog Category Ends */

/* All Blog Category */

.all-blog-category {
  margin: 5px;
}
.all-blog-category .blog-cate {
  margin: 5px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  width: 46%;
  position: relative;
  border: 1px solid #d5e0ff;
  border-radius: 3px;
  background-color: white;
}

/* All Blog Category Ends */

#navbar {
  position: sticky;
  top: 0;
  width: 100%;
  display: block;
  transition: top 0.3s;
}

.apphead {
  width: 247px;
  background-color: #000;
  height: 95px;
  padding: 20px 10px;
  position: sticky;
  margin-top: -5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 7;
  top: 0;
}

.apppreview .mobile-head {
  top: 23px;
}

.storename p {
  margin-bottom: -1px;
  margin-left: 10px;
  color: #fff;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  margin-top: 3px;
}

.apppreview .white-mobile-border {
  padding: 0px !important;
  display: block;
  overflow-y: scroll;
}

.profile {
  margin-right: 3px;
}

.searchbar {
  width: 100%;
  height: 28px;
  border: 1px solid #fff;
  margin-top: 7px;
  border-radius: 4px;
}

.searchbar input {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  font-size: 13px;
  text-align: left;
  padding-left: 10px;
  color: #fff;
}

.searchbar input::placeholder {
  text-align: center;
}

.previewbanner h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0px;
}

.previewcat::-webkit-scrollbar,
.recentproductss::-webkit-scrollbar {
  display: none !important;
}

.previewcat::-webkit-scrollbar-track {
  background-color: rgba(48, 100, 249, 0.2) !important;
  border-radius: 9px !important;
}

.previewcat::-webkit-scrollbar-thumb {
  background-color: #3064f9 !important;
  border-radius: 9px !important;
}

.previewcat {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 10px;
  padding-bottom: 10px;
}

.relatedprods h4 {
  margin-bottom: 7px !important;
}

.recentproductss {
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 10px;
  padding-bottom: 10px;
}

.catge a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.catge h5 {
  color: #fff;
  font-size: 10px;
  margin-bottom: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 4px;
  width: 100%;
  text-align: center;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.featuredimg-content {
  width: calc(100% - 80px);
  margin-top: 8px;
}

.apppreview h4 {
  font-size: 12px;
  font-family: "Axiforma-Bold";
  text-align: left;
  padding: 10px 0px 10px 10px;
  margin-bottom: 0px;
}

.saleitems {
  background-color: #fff3eb;
  padding: 10px;
}
.previewsticky_blogs {
  padding: 10px;
}
.previewsticky_blogs .viewallbtn {
  padding: 0px !important;
}
.infinity-menu-node-container {
  border-bottom: 0px !important;
  cursor: pointer !important;
}
.infinity-menu-node-container label {
  cursor: pointer !important;
  color: var(--menu-color);
  font-family: var(--menu-fontname);
  font-size: 14px;
}
.previewsticky_blogs h4 {
  padding: 0px;
  margin: 0px;
}
.previewsticky_blogs .previewsale {
  box-shadow: none;
}
.previewsticky_blogs p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.saleitems .salelink {
  font-size: 10px;
  font-family: "Axiforma-Bold";
  text-decoration: none;
  color: #000;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 5px 12px 3px 12px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 0px;
}
.categories .salelink {
  font-size: 10px;
  font-family: "Axiforma-Bold";
  text-decoration: none;
  color: #000;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 5px 12px 3px 12px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 0px;
  margin-right: 10px;
  margin-top: 10px;
}

.previewsale,
.pro-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 0px;
}

.saleitems h4 {
  padding: 0px;
  margin-bottom: 10px;
}

.saleproduct {
  width: 113px;
  padding: 5px;
  background-color: #fff;
  margin-bottom: 2px;
  position: relative;
}

.saleproduct .salebadge {
  position: absolute;
  top: 0;
  right: 0;
}

.saleproduct a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.saleproduct p {
  font-size: 10px;
  text-align: left;
  margin-bottom: 0px;
  font-family: "Axiforma-Bold";
  font-weight: 600;

  overflow: hidden;
  margin-top: 8px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  max-height: 29px;
}

.saleproduct h6 {
  font-size: 11px;
  color: #fe5c5b;
  text-align: left;
  margin-bottom: 8px;
}

.saleproduct h6 strike {
  color: #bdc9dc;
  font-size: 9px;
}

.saleimg {
  height: 100px;
}

.apppreview .white-mobile-border {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.apppreview .white-mobile-border::-webkit-scrollbar {
  display: none !important;
}

.white-mobile-border::-webkit-scrollbar-track {
  background-color: rgba(48, 100, 249, 0.2) !important;
  border-radius: 9px !important;
}

.white-mobile-border::-webkit-scrollbar-thumb {
  background-color: #3064f9 !important;
  border-radius: 9px !important;
}

.cstmproduct {
  min-width: 100px;
  margin-right: 8px;
  background-color: #fff;
  margin-bottom: 0px;
  position: relative;
  padding: 4px;
  border: 1px solid #e8e8e8;
}

.cstmproduct a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cstmproduct p {
  font-size: 10px;
  text-align: left;
  font-family: "Poppins-Regular";
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
  margin-top: 5px;
  height: 17px;
  font-weight: 600;
  overflow: hidden;
}
.addcartbtn.newbox {
  height: 630px;
}
.cstmproduct h6 {
  color: #fe0000;
  font-size: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: normal !important;
}

.cstmproduct h6 strike {
  color: #bdc9dc;
  font-size: 9px;
}

.sidemenu {
  cursor: pointer;
}

.mymenu {
  position: absolute;
  left: -100px;
  width: 200px;
  display: none;
  transition: 0.5s;
  margin-top: 32px;
  text-align: left;
  background-color: #000;
}

.mymenu ul {
  padding-left: 0px;
}

.mymenu ul li {
  list-style-type: none;
  margin-top: 40px;
}

.mymenu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}

.mymenu ul li a img {
  margin-left: 10px;
}

.menuslider {
  position: inherit;
  left: 0px;
  display: block;
  height: 100%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  padding-left: 15px;
  padding-right: 10px;
  min-width: 200px;
  transition: 0.5s;
}

.openmenu {
  border-top-left-radius: 0px;
  height: 572px !important;
}

.relatecls {
  position: relative;
  border-top-left-radius: 0px;
}

.topshift {
  margin-top: 0px !important;
}

.newbox {
  width: 247px;
  height: 588px;
  border-radius: 20px;
  margin: auto;
}

.featuredpro {
  padding: 5px;
  border-bottom: 1px solid #fff3eb;
  background-color: #fff;
}

.featuredimg {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}

.viewallbtn a {
  margin-bottom: 8px;
}

.featuredpro h5 {
  color: #111426;
  font-size: 10px;
  text-align: left;
  margin-bottom: 0px;
  max-height: 23px;
  overflow: hidden;
  font-family: "Poppins-Regular";
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.featuredpro h6 {
  color: #fe5c5b;
  font-size: 11px;
  text-align: left;
}

.featuredsection {
  padding: 10px;
}

.rece-blogs {
  position: relative;
}

.rece-blogs a {
  position: absolute;
  width: 100%;
  height: 100%;
}
.website-description h1 {
  font-size: 15px;
  text-align: left;
  padding-left: 5px;
}
.rece-blogs h4 {
  font-family: "Axiforma-Bold";
  font-weight: 600;
  font-size: 9px;
  margin-bottom: 0px;
  color: #000;
  max-height: 32px;
  padding: 10px 0px !important;
  /* white-space: nowrap; */
  /* background-color: #F8FAFF; */
  padding-bottom: 0px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latestblog-heading {
  background-color: #f8faff;
  padding: 7px;
}

.latestblog-heading h3 {
  font-family: "Axiforma-Bold";
  text-align: left;
  font-size: 11px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
}

.latestblog-heading p {
  font-family: "Axiforma-Regular";
  font-size: 9px;
  margin-bottom: 0px;
  overflow: hidden;
  color: #bbc3db;
  text-align: left;
  padding-bottom: 0px !important;
  padding-top: 2px;
}

.rece-blogs p {
  font-family: "Axiforma-Bold";
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0px;
  overflow: hidden;
  color: #bbc3db;
  text-align: left;
  padding-bottom: 0px !important;
  padding-top: 2px;
  padding-left: 10px;
}
.checkdate {
  color: #bbc3db !important;
  font-size: 10px;
  padding-left: 0px;
  padding-top: 2px;
  font-size: 10px !important;
}
.blog-cate p {
  font-family: "Axiforma-Bold";
  font-weight: 600;
  font-size: 9px;
  margin-bottom: 0px;
  /* word-break: break-all; */
  /* text-overflow: ellipsis;
	white-space: nowrap; */
  /* height: 12px; */
  /* overflow: hidden; */
  color: #3064f9;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.backdate {
  background-color: #f8faff !important;
  padding-bottom: 8px;
}
.blog-cate a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
/* 
.blog-cate {
	width: 31%;
	height: 50px;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	border: 1px solid #D5E0FF;
	border-radius: 3px;
	background-color: white;
} */

.viewallbtn a {
  font-size: 10px;
  font-family: "Axiforma-Bold";
  text-decoration: none;
  color: #000;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 0px;
  padding: 5px 12px 3px 12px;
}

.featuredsection h4 {
  padding: 10px 0px 10px 0px;
}

.apppreview {
  height: 630px !important;
  position: relative;
}

.apppreview .white-mobile-border {
  height: 567px;
  /* padding-top: 40px!important;
    padding-bottom: 40px!important; */
}

.featuredonedata p {
  color: #111426;
  font-size: 10px;
  text-align: left;
  margin-bottom: 0px;
  /* height: 37px; */
  overflow: hidden;
  font-family: "Poppins-Regular";
  font-weight: 600;
}

.featuredonedata {
  background-color: #fff;
  padding: 5px 5px;
}

.featuredonedata h6 {
  color: #fe5c5b;
  font-size: 11px;
  text-align: left;
  margin-bottom: 0px;
}

.featuredtwodata {
  background-color: #fff;
  padding: 5px 5px;
  height: 32px;
}

.featuredtwodata p {
  color: #111426;
  font-size: 10px;
  text-align: left;
  margin-bottom: 0px;
  overflow: hidden;
  line-height: 10px;
  font-family: "Poppins-Regular";
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.featuredtwodata h6 {
  margin-bottom: 0px;
  color: #fe5c5b;
  height: 15px;
  overflow: hidden;
  font-size: 11px;
  text-align: left;
}

.productdetailpg {
  height: 55px !important;
}

.productspage .w-50 {
  position: relative;
}

.productspage .w-50 a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.productspage .pro {
  height: 116px;
  width: 116px;
}

.productspage p {
  color: #111426;
  font-size: 10px;
  margin-top: 5px;
  text-align: left;
  margin-bottom: 0px;
  max-height: 24px;
  line-height: 12px;
  font-family: "Axiforma-Bold";
  font-weight: 600;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productslideroption {
  padding: 5px 5px 0px 5px;
}

.productspage h6 {
  color: #fe0000;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: left;
  font-weight: normal !important;
}

.productspage h6 strike {
  color: #828282;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: left;
}

.productspage .w-50:nth-of-type(odd) {
  border-right: 1px solid #eef1fa;
}

.productspage .w-50 {
  border-bottom: 1px solid #eef1fa;
  padding: 10px;
}

.produtpg {
  height: 557px !important;
  top: 28px;
}

.productpgfilter {
  position: sticky;
  /* margin-top: 54px; */
  z-index: 9;
  border-bottom: 1px solid #eef1fa;
  background-color: #eef1fa;
  top: 55px;
}

/* .productspage{
    margin-top: 70px;
} */

.newlyadded3 {
  width: 95px !important;
}

.newlyaddedtwo {
  width: 95px;
  padding: 5px;
  background-color: #fff;
  border-bottom: 1px solid #eef1fa;
}

.newlyaddedone {
  width: calc(100% - 95px) !important;
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
  padding: 5px;
  background-color: #fff;
  border-right: 1px solid #eef1fa;
}

.cartsection {
  border-bottom: 1px solid #eeeeee;
  padding: 8px 0px 10px 0px;
}

.cartproductimg {
  width: 100px;
}

.cartdetail {
  width: calc(100% - 100px);
  padding-right: 10px;
}

.cartproname p {
  color: #333333;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 0px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  /*    white-space: nowrap;*/
  height: 32px;
}

.cartpricequant p {
  color: #ff3d3d;
  font-size: 14px;
  margin-bottom: 0px;
}

.cartpricequant {
  margin-top: 15px;
}

.delprocart {
  width: 30px;
}

.cartproname {
  width: calc(100% - 30px);
}

.cartdadd button {
  background-color: transparent;
  border: none;
  outline: none;
}

.cartdadd input {
  width: 30px;
  border: none;
  outline: none;
}

.cartgap {
  margin-top: 45px;
}

.previewlogin .white-mobile-border {
  background-color: #ccc;
  /* background-image: url(../images/dashboard/splash.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.previewlogin .signup-form {
  left: 0;
  right: 0;
  margin: auto;
}

.logindesigninput input {
  width: 100%;
  color: white;
  border: none;
  outline: none;
  font-size: 13px;
}

.logoposition {
  padding: 50px 10px;
  position: relative;
}

.logindesigninput a {
  text-decoration: none;
  color: white;
  font-size: 10px;
  line-height: 20px;
}

.buttonbackcolor {
  display: block;
}

.password-field {
  margin-top: 30px;
}

.buttonbackcolor_forgot {
  margin-bottom: 30px;
  margin-top: 20px;
}

.create-account-logo {
  top: 70px !important;
}

.buttontextcolor {
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  display: block;
  height: 35px;
  line-height: 35px;
  font-weight: 500;
  text-decoration: none;
}

.loginsocialbtn {
  position: relative;
}

.loginsocialbtn a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.continueguest a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  margin-bottom: 0px;
}

.newuser a {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0px;
  text-decoration: none;
}

.newuser span {
  color: #b1b1b1;
  font-size: 11px;
}

.signupdesigninput input {
  width: 100%;
  color: white;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 13px;
}

.logindesignicon svg {
  margin-top: -4px;
}

/* .productslidermain{
    margin-top: 45px;
} */

.productname h5 {
  color: #333333;
  font-size: 14px;
  text-align: left;
  font-family: "Axiforma-Bold";
  margin-bottom: 8px;
}

.product-pricing h6 {
  color: #ff3d3d;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.sku {
  margin-bottom: 8px;
  color: #9a9a9a;
  font-size: 10px !important;
  margin-top: 10px;
}

.product-pricing h6 strike {
  color: #949494;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-left: 10px;
}

.product-pricing p {
  color: #37c137;
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  padding-right: 5px;
}

.productrating {
  padding-left: 5px;
  margin-top: 5px;
}

.website-description p,
.website-description h2,
.website-description h3,
.website-description h4,
.website-description h5,
.website-description h6,
.website-description span,
.website-description li,
.website-description div {
  text-align: left;
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.website-description ul {
  padding-left: 19px;
  font-family: "Axiforma-Regular";
  font-size: 12px;
}
.sku {
  font-size: 12px;
}

.productrating svg {
  margin-right: 3px;
  margin-bottom: 5px;
}

.productrating p {
  font-size: 12px;
  color: #5aa3fd;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-left: 10px;
}

.productfeature ul {
  padding-left: 15px;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}
.productfeature table tr td {
  font-size: 14px;
}
.ratereviews p {
  font: 12px "Montserrat-Bold";
}

.productfeature ul li {
  list-style-type: disc;
  font-size: 12px;
}

.productfeature a {
  color: #f68751;
  text-decoration: none;
  font-size: 12px;
}

.productfeature a svg {
  margin-left: 5px;
}

.radio-toolbar {
  margin-top: 5px;
  margin-right: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.product-pricing .woocommerce-Price-amount,
.product-pricing .woocommerce-Price-currencySymbol {
  font-size: 14px;
}

.productsdetailpg {
  margin-bottom: 40px;
}

.price-sale p {
  font-size: 10px;
  height: 19px;
  margin-bottom: 0px;
}

.saleproduct del,
.saleproduct del span,
.productspage del,
.productspage del span {
  text-decoration-color: #949494;
  color: #949494;
}

.productspage ins {
  text-decoration: none;
}

.border-right-sale {
  width: 49.5%;
}

.saleproduct ins {
  text-decoration: none;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #fff;
  padding: 3px 6px;
  font-size: 11px;
  border: 1px solid #d6dced;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.radio-toolbar label:hover {
  background-color: #f68751;
  color: #fff;
}

/* .product-description p,h2,h3,h4,h5,h6,span{
    font-size: 14px;
    margin-bottom: 0px;
    
    text-align: left;
} */

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #f68751;
  border-color: #f68751;
  color: #fff;
}

.optionsradio p {
  text-align: left;
  padding-left: 10px;
  font-family: "Axiforma-Bold";
  color: #333;
  font-size: 14px;
  margin-bottom: 0px;
}

.addtocartbtn {
  position: fixed;
  width: 272px;
  z-index: 999;
  margin-top: 548px;
}

/* .videopart div{
    width: 100%!important;
} */

.website-description {
  box-shadow: 0px 6px 6px 0px rgba(25, 47, 107, 0.04);
  padding-bottom: 10px;
}
.productdescription {
  margin-top: 12px;
}
.productdescription .website-description {
  box-shadow: none;
}
.additionalinfo,
.relatedprods {
  box-shadow: 0px 1px 8px 0px rgba(25, 47, 107, 0.1);
  margin-top: 12px;
}

.optionsradio {
  margin-top: 12px;
  padding: 10px 0px;
  box-shadow: 0px 1px 8px 0px rgba(25, 47, 107, 0.1);
}

.productdescription {
  padding: 5px;
}

.productdescription h4 {
  color: #192f6b;
  font-size: 13px;
  padding: 5px;
  text-align: left;
}

.productdescription p {
  color: #192f6b;
  font-size: 12px;
  padding: 5px;
  text-align: left;
  margin-bottom: 0px;
}
.variationselect {
  width: 100%;
  background: rgba(220, 220, 220, 0.4);
  border: 1px solid #dcdcdc;
  color: black;
  border-radius: 3px;
  font-size: 12px;
  height: 35px;
  font-family: "Axiforma-Bold";
  padding-left: 8px;
  padding-right: 8px;
  background-image: url(../images/dashboard/varitiondrop.png);
  background-repeat: no-repeat;
  background-position: 96%;
  -webkit-appearance: none;
  appearance: none;
  background-size: 12px;
}
.variationselect option {
  width: 100%;
}
.hideheight {
  overflow: hidden;
}
.readmoredes {
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #e22c25;
  box-shadow: 0px 6px 6px 0px rgba(25, 47, 107, 0.04);
}
.productdescription a {
  color: #f68751;
  text-decoration: none;
  font-size: 12px;
}

.productdescription a svg {
  margin-left: 5px;
}

.additionalinfo,
.relatedprods {
  padding: 12px 5px;
}

.additionalinfo h4,
.relatedprods h4 {
  color: #192f6b;
  font-size: 13px;
  padding: 5px;
  text-align: left;
}

.profeture h5 {
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
  color: #9a9a9a;
}

.profeturedesc h6 {
  font-size: 13px;
  text-align: left;
  padding-left: 5px;
  color: #8e8e93;
  margin-bottom: 10px;
}

.additionalinfo a {
  color: #f68751;
  text-decoration: none;
  font-size: 12px;
}

.additionalinfo a svg {
  margin-left: 5px;
}

.additionalinfo h4 {
  margin-bottom: 10px;
}

.prodessection {
  position: relative;
}

.addtocartbtn button {
  width: 100%;
  background-color: #ad1522;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  height: 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* .addtocartbtn{
    position: fixed;
    width: 247px;
    margin-top: 521px;
    z-index: 999;
} */

#sharepopup {
  z-index: 9999;
}

#sharepopup h5 {
  margin-bottom: 0px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 18px;
}

#sharepopup p {
  margin-bottom: 0px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
}

.shareqr {
  width: 260px;
}

.shareyouapp {
  width: calc(100% - 260px);
}

.shareyouapp input {
  width: 100%;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  height: 40px;
  padding: 10px;
  outline: none;
}

.shareyouapp button {
  background-color: #3064f9;
  border-radius: 4px;
  color: #fff;
  border: none;
  width: 100px;
  transition: 0.5s;
  height: 40px;
  margin-top: 15px;
  outline: none;
}

.shareyouapp label {
  margin-bottom: 0px;
  font-size: 16px;
}

.shareyouapp button:hover {
  box-shadow: 0px 4px 6px rgba(112, 155, 250, 0.69);
}

.close:hover {
  outline: none;
}

/*Preview Page Ends*/

.my-app-preview .apppreview {
  height: 665px !important;
  position: relative;
  width: 350px;
}

.my-app-preview .apppreview .white-mobile-border {
  height: 588px !important;
  width: 272px;
  top: 38px;
  border-radius: 25px;
}

.my-app-preview .newbox {
  width: 272px;
}

.my-app-preview .apppreview .mobile-head {
  top: 33px;
  width: 245px;
}

.my-app-preview .apphead {
  width: 272px;
  height: 90px;
  margin-top: 0px;
}
/* Slider Menu */
.dash-menu {
  margin-top: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.slide-menu .infinity-menu-display-tree-container ul {
  padding-top: 0px;
  padding-bottom: 0px;
}
.dash-menu p {
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  font-size: 13px;

  margin-left: 10px;
}
.infinityme {
  border-bottom-width: 0.01em;
  border-bottom-style: solid;
  padding-bottom: 20px;
}
.infinity-menu-display-tree-container .infinity-menu-node-container label {
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  font-size: 12px;
  padding-left: 10px;
}
.infinity-menu-display-tree-container ul label {
  opacity: 0.7;
}
.infinity-menu-display-tree-container ul label::before {
  font-weight: bold;
  margin-right: 5px;
  content: "-";
}
.infinity-menu-display-tree-container ul ul label {
  opacity: 0.5;
}
/* Slider Menu */

.banner-images {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  background-position: center center !important;
}

.loginlogo {
  height: 100px !important;
  width: 214px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

@media screen and (max-width: 1200px) {
  .right_gap {
    margin-right: 0px;
  }
  .previewcat {
    padding-right: 5px;
  }
  .mystyle:after {
    display: none;
  }

  .categories .previewcat .catge {
    width: 100% !important;
    height: 115px !important;
  }
  .mobile-download-app .modal-content {
    bottom: 50px;
    top: auto;
    height: auto;
  }
  .mobile-download-app .conecttoapi a {
    width: 100%;
  }
  .mobile-download-app .modal-categories {
    padding-top: 15px !important;
  }
  .mobile-download-app .modal-categories p {
    margin-bottom: 0px;
    color: #1b2952;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    margin-left: 10px;
  }
  .mobile-download-app .conecttoapi .cancel-btn {
    display: block;
    background: #fff;
    width: 100%;
    height: 44px;
    color: #3064f9;
    border-radius: 4px;
    margin: auto;
    line-height: 42px;
    transition: all 0.5s ease;
    border: 1px solid #3064f9;
    text-transform: uppercase;
  }
  .mobile-download-app .update-woo-plugin {
    padding: 0px 15px 15px 15px !important;
  }
  .mobile-cross {
    display: block;
    width: 25%;
    margin: auto;
  }
  /* .preview-items{	
        margin-top: 50px;	
    } */
  .preview-items .proceed-to-checkout {
    bottom: 0px;
  }
  .preview-items .light-blue-cart {
    bottom: 47px;
  }
  .my-app-preview .apphead {
    width: 100%;
    border-radius: 0px;
  }
  /* .productspage {	
        margin-top: 52px;	
    } */
  .productpgfilter {
    display: none;
  }
  .my-app-preview .apppreview .white-mobile-border {
    width: 100%;
    height: 100% !important;
    top: 0px;
  }
  header {
    display: none;
  }
  .header-responsive-new {
    display: block;
  }
  .statusspacing {
    padding-right: 16px;
    padding-top: 16px;
  }
  /* .app_description {
		display: none!important;
	} */
  .transparent_background {
    padding: 0px !important;
  }
  .right_panel {
    padding: 0px !important;
  }
  .previewlogin .mymenu {
    display: none;
  }
  .tab-content {
    margin-bottom: 0px !important;
  }
  .newbox {
    width: 100%;
    height: 100%;
  }
  .apppreview .white-mobile-border {
    height: 100%;
  }
}
.page-open p {
  margin-bottom: 0px;
  color: #7581a1;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
  font-size: 13px;
}

.open-new-page {
  height: 80%;
}
