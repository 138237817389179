.billinghp {
  min-width: 180px;
    max-width: 250px;
    padding-right: 20px;
    word-break: break-all;
}
.security-selectflag {
  background-color: #fff;
  z-index: 1;
  width: 100%;
  height: 275px;
  position: absolute;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.security-selectflagtop {
  height: 50px;
  padding: 0 20px;
  border: none;
  border-bottom: 1px solid #f1f2f8;
  -webkit-align-items: center;
  align-items: center;
}
.billingnewspinner {
  width: 100%;
  height: 100%;
  background: white;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0px;
  opacity: 1;
  height: 75vh;
  border-radius: 6px;
}

.security-selectflagtop .searchcountryinput {
  background-color: #fff;
  outline: none !important;
  border: 1px solid #bcc1ce;
  height: 50px;
  color: #1b2952 !important;
  font-size: 13px !important;
  border-radius: 3px !important;
  width: 100%;
  border: none !important;
  height: 28px !important;
  margin-bottom: 0px !important;
}
.security-selectflagtop .searchcountryinput:focus {
  background-color: white !important;
}
.security-selectflagtop .searchcountryinput:hover {
  background-color: white !important;
}
.security-selectflagtop .searchcountryinput::placeholder {
  margin-top: 1px !important;
  font-size: 13px;
}
.securitycountrydiv {
  padding: 7px 10px;
  height: 290px;
  overflow-y: auto;
}
.countrytype {
  height: 208px;
  overflow-y: auto;
}
.react-select22__option .headers-profil-img {
  width: 40px !important;
  height: 40px !important;
  background-size: cover !important;
}
.countrytype .country-line-flag {
  padding: 7px 10px;
  cursor: pointer;
}
.security-selectflagtop .clear-search {
  color: #bcc1ce;
  font-size: 12px;
  margin-bottom: 0px;
  cursor: pointer;
}
.react-select22__placeholder {
  font-size: 13px;
}
.countrytype .country-line-flag:hover {
  background-color: #f8f9fd;
}
.countrytype .country-name {
  font-size: 13px;
  color: #1b2952;
  margin-bottom: 0px;
}
.billing-react-select-dropdown {
  width: 100%;
}
.details-mob-page-head {
  display: none;
}
.inactive-red {
  color: #e20000 !important;
  background-color: #fff6f6 !important;
  font-size: 12px !important;
  border-radius: 10px;
  padding: 2px 8px;
}

.billingdetail-selectdiv .css-dng0pk-control:hover {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.billingdetail-selectdiv .css-dng0pk-control:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.billingdetail-selectdiv .css-nhj2gg-control:hover {
  border: 1px solid #3064f9 !important;
  box-shadow: none !important;
}

.billingdetail-selectdiv .react-select22__value-container {
  display: flex;
  align-items: center;
  height: 49px;
}

.billingdetail-selectdiv .react-select22__single-value {
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  color: #1b2952 !important;
}
/* .billingdetail-selectdiv .css-1uccc91-singleValue:focus{
  margin-top: 4px !important;
} */

.billingdetail-selectdiv .css-1n7v3ny-option {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  background-color: #f8f9fd;
}
.billingdetail-selectdiv .css-15nhzs4-MenuList {
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  color: #1b2952 !important;
}

.billing-head-hideformobile {
  display: flex;
}
.billing-upgrade-model .modal-content {
  border: none;
  border-radius: 6px !important;
}
.min-height-page {
  min-height: 79.5vh;
}

.billing-card-head .modal-title {
  font-size: 16px !important;
  font-family: "Axiforma-Bold";
}
.billing-options {
  display: grid;
  margin-top: 2px;
}
.card-expire-date {
  color: #7782a1;
  font-size: 12px !important;
  line-height: 1.5;
  font-family: "Axiforma-Regular";
}
.redeem-buttons a {
  font-size: 14px;
  margin-left: 40px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.redeem-buttons a:hover {
  color: #7782a1;
}
.pay-card-red-inner.cardnumber {
  font-size: 28px !important;
  margin-top: 24px;
  margin-bottom: 8px;
}
.pay-card-red-inner.cardnumber span {
  margin-left: 16px;
}
.pay-card-red-inner.cardnumber span:last-child {
  margin-left: 5px;
  font-size: 20px !important;
}
.addedcard:nth-child(4n + 2) {
  margin-right: 0;
}
/* .billingdetail-colname,
.billings_subscriptions-redesign .billingdetail-colname:nth-child(7),
.billings_subscriptions-redesign .billingdetail-colname:nth-child(9) {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 10px;
  flex: 0 0 50%;
  max-width: 50%;
} */
.billingdetail-colname{
  position: relative;
  width: 100%;
  padding-right: 10px;
    padding-left: 11px;
  flex: 0 0 50%;
  max-width: 50%;
}
/* .billingdetail-colname:nth-child(odd),
.billingdetail-colname:nth-child(6),
.billingdetail-colname:nth-child(8) {
  padding-left: 14px;
  padding-right: 10px;
} */
.billingdetails-addressdiv {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.react-select22__value-container
  .react-select22__single-value
  .billing-options-workspace-name {
  color: #1B2952 !important;
  font-size: 13px !important;
}
.react-select22__single-value .billing-options .billing-options-workspace-type {
  display: none;
}

/* .react-select22__single-value .billing-options .billing-options-workspace-type{
  display: none !important;
 } */

.billing-react-select-dropdown .css-1pahdxg-control {
  border-color: #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgba(000, 000, 000, 0.06);
  height: 40px !important;
  border-radius: 6px !important;
}
.billing-react-select-dropdown .css-26l3qy-menu {
  margin-top: 0px !important;
  border-color: #e3e6f1 !important;
  box-shadow: 0px 10px 16px rgba(000, 000, 000, 0.06);
  visibility: visible !important;
  padding: 6px 10px 10px 10px !important;
}
.billingdetail-selectdiv select {
  background-position: 97% !important;
}
.billingdetail-selectdiv .css-rw249k-control {
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border: 1px solid #bcc1ce;
  outline: none !important;
  color: #1b2952;
  transition: 0.5s;
  margin-bottom: 15px !important;
}
/* .billing-react-select-dropdown .css-26l3qy-menu .css-4ljt47-MenuList{
    padding: 0px !important;
} */
.billing-react-select-dropdown .react-select22__option {
  color: #7782a1 !important;
  background-color: #fff !important;
  font-size: 13px !important;
  padding: 10px 10px 10px 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s;
  line-height: 17px;
}
.billing_billingsub .react-select22__value-container {
  height: 38px;
  padding: 2px 12px!important;
}
.billing_billingsub .react-select22__placeholder {
  margin-top: 2px !important;
}
.billing-react-select-dropdown .react-select22__option img {
  width: 40px !important;
  height: 40px !important;
}
.billing-react-select-dropdown .react-select22__option:hover {
  background-color: #f8f9fd !important;
  border-radius: 4px !important;
}
.billing-react-select-dropdown
  .react-select22__option
  .billing-options-workspace-name {
  color: #1b2952 !important;
  font-family: "Axiforma-Regular" !important;
}
.billing-react-select-dropdown
  .react-select22__option
  .billing-options-workspace-type {
  font-size: 10px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  line-height: 13px;
}
/* .billing-react-select-dropdown .css-26l3qy-menu div{
 padding-left: 10px !important 
}
.billing-react-select-dropdown .css-26l3qy-menu div:hover{
    background-color: #F8F9FD !important;
}
.billing-react-select-dropdown .css-26l3qy-menu img{
    height: 40px !important;
    width: 40px !important;
   } */
.crossmodal-delete img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.crossmodal-card img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  height: 14px;
}
.billing-card-head .crossmodal-card img {
  right: 12px;
  top: 13px;
}

.billing-mob-cardpopuphead {
  display: none;
}
.billing-card-model .modal-content {
  height: 413px;
  border-radius: 6px !important;
  border: none !important;
}
.billing-card-model .modal-header {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border: none !important;
}
.billing-mob-latest-details-time {
  display: none;
}
.billings-viewstatus-responsive.billings-viewstatus-responsive-red {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
p.billings-viewstatus-responsive {
  width: auto !important;
  /* padding-left: 12px;
  padding-right: 12px; */
  max-width: fit-content;
  padding-top: 2px;
  border: none !important;
  padding: 0px !important;
}
.billings-viewstatus {
  border-radius: 10px;
  text-align: center !important;
  height: 18px !important;
  line-height: 17px !important;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
}

.billing-head-redesign {
  display: flex;
  margin-top: -8px;
  margin-left: 0px !important;
  width: 100% !important;
}
.billings-head-flex {
  flex: 1 1;
  height: 40px;
}

.billings-head-flex img {
  margin-left: 8px;
  cursor: pointer;
}
.billing-wallet-design {
  align-items: center;
  justify-content: space-between;
}
.billing-savebtn {
  display: none;
}

.cardnumber {
  margin-top: 13px;
  margin-bottom: 0px;
  font-size: 20px !important;
  color: #1B2952#ffffff !important;
  letter-spacing: 3px;
  text-align: left !important;
  text-shadow: 0px 2px 2px rgba(36, 28, 28, 0.18);
  opacity: 1 !important;
}
.cardnumber span {
  margin-left: 10px;
}
.expiredate {
  font-size: 12px !important;
  color: #1b2952 !important;
  font-family: "Axiforma-Regular" !important;
  opacity: 0.8 !important;
}
.expiredate p {
  font-size: 12px !important;
  color: #1b2952 !important;
  font-family: "Axiforma-Regular" !important;
  margin-left: 4px;
  margin-bottom: 0px !important;
}
.billing-head-redesign h1 {
  color: #1b2952 !important;
  font-size: 24px !important;
  font-family: "Axiforma-Bold";
  margin: 0px;
  line-height: 33px;
  padding-top: 1px;
}
.billing-head-redesign h5 {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 3px;
  color: #7782a1;

}
.billing-react-select-dropdown .css-yk16xz-control {
  height: 40px !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 6px rgba(000, 000, 000, 0.06);
  border: 1px solid #e3e6f1 !important;
  cursor: pointer;
}
.billing_billingsub {
  width: 280px;
  margin-left: 20px;
  height: 40px;
}

.billing_wallet {
  background-color: #f8fafe !important;
  border-radius: 3px;
  padding: 14px 38px 12px 38px;
  height: 40px !important;
  border: 1px solid #e3e6f1;
  margin: 0px;
}
.plan-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.plan-tooltip .plan-tooltiptext {
  visibility: hidden;
  font-size: 10px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 87px;
  margin-left: -100px;
  padding: 7px 11px;
  opacity: 0.8 !important;
  transition: 0.3s;
}

.plan-tooltip .plan-tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 27px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.plan-tooltip:hover .plan-tooltiptext {
  visibility: visible;
}
.billing_wallet p {
  color: #7782a1 !important;
  margin: 0px !important;
  font: 13px "Axiforma-Regular";
}
.wallet_balence {
  color: #1b2952 !important;
  font: 13px "Axiforma-Regular" !important;
}
.billing_app_iconname h5 {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  color: #7782a1;
  border-bottom: none;
  margin-bottom: 0px !important;
  padding-top: 6px !important;
  padding-bottom: 0px !important;
}
.billing_right_panel {
  background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(000, 000, 000, 0.06);
  height: auto;
  padding: 0px;
  padding-top: 0px;
  position: relative;
  margin-bottom: 0px !important;
  border: 1px solid #e3e6f1 !important;
  overflow: hidden;
}
.deals-billing_right_panel {
  /* background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(000, 000, 000, 0.06); */
  height: auto;
  padding: 0px;
  padding-top: 0px;
  position: relative;
  margin-bottom: 0px !important;
  /* border: 1px solid #e3e6f1 !important; */
}
.billing-invoice-head {
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}
.Deals_header {
  /* border-bottom: 1px solid #e0e5ec; */
  justify-content: space-between;
  /* padding: 16px 30px; */
}
.billing_bill-pay {
  border-bottom: 1px solid #dce1eb;
}
.billing_bill-pay ul li a {
  padding: 20px 46px;
  font-family: "Axiforma-Bold";
  color: #7782a1;
  font-size: 14px;
}
.billing_redeenlink {
  border: 1px solid #3064f9;
  width: 120px;
  height: 40px;
  border-radius: 3px;
}
.billing_product.product-plan-over::first-letter {
  text-transform: capitalize;
}
.billing_redeenlink a {
  margin-left: 5.5px;
}
.billing_redeenlink a {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  margin-top: 2px;
}
.billing_redeenlink a:hover {
  color: #3064f9;
}
.billing_nodatatext .span1 {
  margin-top: 20px;
  margin-bottom: 0px;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  font-weight: 400;
}
.billing_nodatatext {
  text-align: center;
  margin-top: 21px;
}
.billings-invoice-mob .billing_nodatatext {
  margin-top: 25px;
}

.billing-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
}

.billing-tooltip .billing-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 130%;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.billing-product-plan {
  width: 80% !important;
}
.billing-tooltip-learnmore img {
  height: 10px;
}
.billing-tooltip-learnmore {
  margin-top: 12px;
  color: #c8cedb;
  cursor: pointer;
  margin-bottom: 0px;
}
.billing-tooltip .billing-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 14%;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.billing-tooltip:hover .billing-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}

.billing_nodatatext .span2 {
  width: 100%;
  margin: auto;
  font-size: 14px;
  color: #3064f9;
  cursor: pointer;
  margin-top: 25px;
}

/* .billing_nodataimg{
    padding: 0px 30px 0px 30px;
} */

.billing_nodataimg .noInvoice {
  margin-top: 23.8vh;
}
.billing_nodataimg .noDeals {
  margin-top: 23vh;
}
.billings-deal-container {
  border-radius: 8px;
  margin-top: 0px;
  border: 1px solid #e3e6f1;
  background-color: #f1f4fb;
  margin-bottom: 20px;
}
.billings_next_btn {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;

  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  font-weight: 500;
  border: 1px solid #3064f9;
  line-height: 15px;

  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
}
.billings_next_btn:hover {
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
  color: #fff;
}
.billings_subscriptions-redesign {
  padding: 24px 30px 38px 30px;
}
.billing_right_panel label {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 5px;
}
.billingdetails-billing_right_panel .newspinner {
  border-radius: 6px;
}
.billingdetails-billing_right_panel label {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 5px;
}
.billingdetails-billing_right_panel {
  background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(000, 000, 000, 0.06);
  height: auto;
  padding: 0px;
  padding-top: 0px;
  position: relative;
  margin-bottom: 0px !important;
  border: 1px solid #e3e6f1 !important;
}
.billings_subscriptions-redesign input {
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border: 1px solid #bcc1ce;
  outline: none !important;
  color: #1b2952;
  transition: 0.5s;
  margin-bottom: 14px !important;
}
.billings_subscriptions-redesign input:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.billings_subscriptions-redesign select:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.billings_subscriptions-redesign input:hover {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.billings_subscriptions-redesign select:hover {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.billingdetails-countrydropdown{
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border: 1px solid #bcc1ce;
  outline: none !important;
  color: #1b2952;
  transition: 0.5s;
  margin-bottom: 14px !important;
  display: flex;
  align-items: center;
  padding: 15px 20px;
    border-radius: 0.25rem;
    cursor: pointer;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAABHNCSVQICAgIfAhkiAAAAHdJREFUGFdjrGhalPCf4Z99Z11CIgMBUNa4cAETI+MBRhCDkZEhnoHh/wJ8GmHqGBkYExlBhhPSiKyhoy5uAVgTPo3oGkBq4ZqwacSmAUMTisb//x8wMDIqgPwAchJyGKHYhO5UbBqw2gTTWNE836GjNvEAtlgAABxNRAEE3qOEAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 97%;
}
.billingdetails-countrydropdown:hover, .billingdetails-countrydropdown:focus{
  background-color: #f8fafe;
    border-color: #3064f9 !important;
}
.billingdetails-countrydropdown p{
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.billings_subscriptions-redesign select {
  height: 50px !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  border: 1px solid #bcc1ce !important;
  outline: none !important;
  color: #1b2952 !important;
  transition: 0.5s !important;
  opacity: 1 !important;
  background-position: 98% !important;
}
.billing-required {
  margin-bottom: 11px;
  margin-top: -5px;
}
.billingdetails-validation {
  border-color: #ff4848 !important;
}
.billing-required p {
  margin-bottom: 0px;
  margin-left: 5.5px;
  color: #ff4848;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.billings_billingsub {
  margin-right: 0px;
  width: 240px;
}

.invoice_table-top {
  border-bottom: 1px solid #f1f2f8;
  padding: 11px 40px 10.5px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 1.2%;
  padding-right: 2.46%;
}
.invoice_subscriptions-redesign {
  padding-left: 30px;
  padding-right: 30px;
}
.billings_bill-pay-version {
  padding: 0px;
}
.billing_social_pannel {
  display: flex;
  justify-content: space-between !important;
}
.billing-save-button {
  display: flex;
}
.billing-latest-details-time {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin-right: 20px;
  margin-bottom: 0px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.billing-expiry-date {
  color: #7782a1;
  font-size: 12px !important;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 7px;
}
/* .billling-mobile-landing-changes .right-panel-gap,
.portal-body .right-panel-gap,
.dasboard_page .right-panel-gap {
  padding: 30px;
  padding-top: 20px;
} */
.dasboard_page .right-panel-gap .scrollbar-settings {
  overflow-x: inherit;
}
.redeem-mob-space {
  margin-top: -3px;
}
img.iconaddcardplus {
  margin-left: -5px;
}
.billing_social_pannel ul li a {
  color: #7782a1;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 0px 0px 10px 0px !important;
  cursor: pointer;
  line-height: 20px;
}
.billing_social_pannel ul {
  padding-left: 0px;
  display: flex;
  margin-bottom: 0px;
}
.billing_social_pannel ul li {
  list-style-type: none;
  display: flex;
  margin: 6px 60px 18px 0px;
}
/* .billing_social_pannel ul li:nth-of-type(2) {
  margin-bottom: 20px;
} */
.billing_social_pannel ul li:last-child {
  margin: 6px 0px 18px 0px;
}
/* .billing_social_pannel ul li a{
    padding: 0px 0px 13px 0px !important;
} */
.billing_social_pannel ul li .active,
.billing_social_pannel ul li a:hover {
  color: #3064f9 !important;
  border-bottom: 2px solid #3064f9;
  font-family: "Axiforma-Bold";
}
.mobilemain {
  padding: 0px !important;
  margin: 0px !important;
}
.billing_redeem {
  margin-right: 30px !important;
  font-size: 13px;
}
.right_panel.right_panel_billing {
  padding-left: 0;
  padding-right: 0;
}
.subs-head-redesign {
  padding-left: 0px;
  padding-right: 0px;
}
.subs-head-payment {
  padding-left: 0px;
  padding-right: 0px;
}
/* .bill-pay-version,
.subscriptions-redesign {
  padding-left: 0px;
  padding-right: 0px;
} */
/* .table-top-bill {
    background-color: #FFF;
    border: none;
} */
.biling-page .invoice-common-height {
  height: auto;
  padding: 31px 40px 30px 20px !important;
  border-bottom: 1px solid #f1f2f8;
  transition: 0.5s;
  padding-left: 1.2% !important;
  padding-right: 2.46% !important;
}
.biling-page .invoice-common-height:hover {
  background-color: #f8f9fd;
}
.subscriptions-type .invoice-datetime {
  font-size: 14px !important;
  color: #1b2952 !important;
  margin-top: 0px !important;
  font-family: "Axiforma-Regular";
}

.billings_subscriptions .next-payments .invoice-id-name {
  font-size: 14px !important;
  color: #1b2952 !important;
  margin-top: 0px !important;
  font-family: "Axiforma-Regular";
}
.billings_subscriptions .next-payments .billings-bill-url {
  font-size: 12px !important;
  word-break: break-all;
}
.table-top-bill p {
  margin-top: 0 !important;
  color: #bcc1ce !important;
  font-family: "Axiforma-Regular" !important;
  font-size: 12px !important;
}

.bill-download.bill-download-invoice,
.bill-download {
  text-align: left !important;
}
.bill-download.bill-download-invoice p {
  float: left !important;
  padding: 0px !important;
}
.subscriptions-over-new {
  padding: 0px 0px 0px 0px;
}
.subscriptions-over-new .billing_table-top {
  background-color: #fff;
  border-bottom: 1px solid #f1f2f8;
  padding: 11px 40px 10.5px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 1.2%;
  padding-right: 0.7%;
}
.subscriptions-over-new .billing_table-top p {
  font-size: 12px !important;
  color: #bcc1ce !important;
  margin-top: 0px !important;
}
.subscriptions-over-new .common-height-over {
  height: auto;
  padding: 24px 40px 23px 20px !important;
  padding-left: 1.2% !important;
  border-bottom: 1px solid #f1f2f8;
  transition: 0.5s;
  padding-right: 0.7% !important;
}
.subscriptions-over-new .common-height-over:hover {
  background-color: #f8f9fd;
}
/* .subscriptions-over-new .common-height:nth-child(even) {
    background-color: #F8FAFE;
} */
.common-height-over .product .product-plan-over,
.workspace-card-red .card-name-init {
  margin-top: 0;
}

.new_wallet_design .wallet.high-balance p {
  color: #fff !important;
}
.billings_subscriptions .subscriptions-type {
  /* min-width: 300px;
  max-width: 380px; */
  width: 26%;
}
.billings_subscriptions .invoice-subscriptions-type {
  width: 22.29%;
}
.billings_subscriptions .product {
  /* width: 210px; */
  width: 24.65%;
}
.billings_subscriptions.invoice_table .product,
.billings_subscriptions.invoice_table-top .product {
  width: 22.41%;
}

.billings_subscriptions .next-payments {
  /* width: 250px; */
  width: 18%;
  padding-right: 10px;
}
.billings_subscriptions.invoice_table .next-payments,
.billings_subscriptions.invoice_table-top .next-payments {
  width: 21.91%;
}
.billings_subscriptions .payment-method {
  /* width: 250px; */
  width: 18.16%;
}
.billings_subscriptions .payment-status {
  /* width: 170px; */
  width: 10.82%;
}
.billings_subscriptions.invoice_table-top .payment-status,
.billings_subscriptions.invoice_table .payment-status {
  width: 15.43%;
}
.billings_subscriptions .action {
  min-width: 64px;
  width: 4.85%;
}

.billing_card-update {
  color: #3064f9 !important;
  font-size: 12px !important;
  font-family: "Axiforma-Regular" !important;
  cursor: pointer;

  align-items: center;
}
.billing_card-update img {
  margin-left: 6.5px;
}
.billing_app-icon {
  margin-right: 15px !important;
  border-radius: 14px !important;
}

.billings_subscriptions h4 {
  color: #1b2952 !important;
  font-size: 14px !important;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
  word-break: break-word;
  line-height: 20px;
}
.billing_subscriptions-type p {
  color: #7782a1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-top: 7px;
  word-break: break-all;
}
.billings_subscriptions p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.billing_product p:nth-of-type(2),
.subscriptions-type p {
  color: #7782a1;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
}
.billings_subscriptions .next-payments h4 {
  color: #1b2952 !important;
  font-size: 14px !important;
  font-family: "Axiforma-Regular" !important;
  margin-bottom: 0px;
}
.billings-frequency {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.billings_subscriptions .next-payments p {
  color: #7782a1;
  font-size: 12px !important;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 7px;
}

.billings_subscriptions .next-payments .next-payments-head {
  font-size: 12px !important;
  color: #bcc1ce !important;
}
.billing_invoices {
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.billings-bill-app p {
  color: #1b2952;
  font-size: 16px !important;
  font-family: "Axiforma-Regular";
}
.billings-bill-app .billings-bill-url {
  color: #7782a1;
  font-size: 12px !important;
  font-family: "Axiforma-Regular";
}
.bill-payment-status .active-green {
  background-color: #eefef3 !important;
  border: none !important;
  color: #47ce70 !important;
  font-size: 11px !important;
  border-radius: 18px;
  font-family: "Axiforma-Regular";
  width: 65px;
  text-align: center;
  line-height: 22px;
  float: left !important;
  height: 22px;
}
/* Redeem code*/
.redeem_img {
  display: flex;
  justify-content: end;
  padding-right: 130px !important;
  margin-top: 124.4px !important;
}
.redeem_input {
  padding-left: 60px !important;
  margin-top: 145px !important;
}
.billings-redeemsubheader {
  margin-top: 0px !important;
  margin-bottom: 25px !important;
  color: #1b2952 !important;
  font-family: "Axiforma-Regular";
  font-size: 16px;
}
.billings-formspace {
  width: 540px !important;
  height: 50px !important;
  margin-bottom: 30px !important;
  background-color: white !important;
  padding: 18px 20px 18px 20px !important;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
}

.billings-formspace:focus {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
}
.billings-formspace:hover {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
}
.billing-redeem-button {
  height: 50px !important;
  width: 160px !important;
  font-size: 14px !important;
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  border: 1px solid #3064f9;
  line-height: 15px;
  transition: 0.5s;
}
.redeem-buttons {
  display: flex;
  align-items: center;
}
.redeem-buttons p {
  margin-left: 40px;
  margin-bottom: 0px !important;
  color: #7782a1;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.billing-mainheader {
  justify-content: space-between;
  border-bottom: 1px solid #e3e6f1;
  padding: 17px 30px 17px 30px;
  display: flex;
}
.billing-mainheader h4 {
  font-size: 20px;
  font-family: "Axiforma-Bold";
  margin-bottom: 2px;
}
.billing-mainheader p {
  margin-bottom: 0px;
  font-size: 13px;
  color: #7782a1;
}
.billing-mainheader button {
  width: 100px;
  height: 40px;
  background-color: #ffff;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 2px;
  border: 1px solid #bcc1ce;
  margin-top: 4px;
}
.billing-right-panel {
  padding: 0px !important;
}
.billings-redeemsmallspace {
  padding: 0px 30px 0px 30px;
}
.billing-redeemheading {
  font-family: "Axiforma-Bold";
  font-size: 30px;
}
.billing-mob-form input {
  padding: 18px 0px 18px 20px !important;
}
/* .billing-dealsbackground{
    background-image: url("../../assets/images/dashboard/Billing/dealsbackground.png");
} */
/* Redeem code*/
.billing-action-red a {
  color: #3064f9 !important;
  font-size: 12px !important;
  font-family: "Axiforma-Regular" !important;
  cursor: pointer;
}
.billing-mob-cancle {
  display: none;
}
.billings-updateApppopup {
  display: none;
}
.billings-update-popuptext h6 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  text-align: center;
  line-height: 34px;
  color: #1b2952;
}
.billings-update-popuptext p {
  font-size: 14px;
  line-height: 25px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  text-align: center;
  margin-bottom: 25px;
}
.billing-web-cancle {
  margin-right: 20px;
  width: 140px;
  height: 44px;
  border: 1px solid #c8cedb;
  background-color: white;
  color: #7782a1;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  line-height: 14px;
}
.billing-mob-update {
  width: 140px;
  height: 44px;
  border: 1px solid #ff4848;
  color: #ff4848;
  background-color: white;
  border-radius: 4px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 14px;
}
.billing-mob-update:hover {
  background-color: #ff4848;
  color: white;
}

.modal-dialog.billing-upgrade-model {
  padding-bottom: 38px !important;
}
/* .billing-mob-update:focus {
  background-color: #ff4848;
  color: white;
} */
.billings-cancle-update {
  justify-content: center;
  display: flex;
}
.billings-update-popuptext {
  justify-content: center;
  display: grid;
}
.billings-update-popuptext p {
  width: 338px;
}
.billing-mob-firstpageview {
  display: none;
}
.billing-upgrade-model {
  width: 480px !important;
}
.billing-upgrade-model .modal-body {
  min-height: 230px;
  max-height: 350px;
  overflow: auto;
}
.billing-card-model {
  width: 600px !important;
  max-width: 600px !important;
}
.billing-card-head {
  padding: 23px 20px 16px 20px !important;
}
.billing-card-body {
  padding: 0 20px 5px !important;
  overflow-y: auto;
  padding-right: 12px !important;
}
.billing-card-model .billing-card-footer {
  padding: 15px 20px !important;
  border-top: 1px solid #e3e6f1 !important;
}
.billing-card-list {
  display: flex !important;
  padding: 0px !important;
  background-color: #fff !important;
  margin-bottom: 9px;
  border-radius: 4px !important;
  border: 1px solid #e3e6f1 !important;
  margin-right: 0px !important;
}
.billing-card-list:hover {
  background-color: #f8fafe !important;
}
.modals-card-list {
  display: flex;
  flex-direction: column;
}
.card-add-img {
  margin: 19px 15.5px 20px 20px;
}
.billing-card-num {
  padding: 0px !important;
  justify-content: center !important;
  align-items: flex-start !important;
  margin-top: 0px !important;
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
}
.billing-form-check-label p {
  margin: 0px !important;
  top: 0px !important;
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 4px !important;
  line-height: 1.5;
  text-align: left !important;
}
.billing-custom-radio-btn:hover {
  box-shadow: 0px 0px 0px 0px !important;
  border-color: #e3e6f1 !important;
}
.billing-custom-radio-btnclick {
  border-color: #e3e6f1 !important;
}
.billing-card-listback {
  background-color: #f8fafe !important;
}
.billing-custom-radio-btn input[type="radio"]:checked,
.billing-custom-radio-btn input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.billing-custom-radio-btn input[type="radio"]:checked + label,
.billing-custom-radio-btn input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 10px;
  padding-right: 105px;
  padding-top: 6px;
  padding-bottom: 10px;
  cursor: pointer;
  line-height: 50px;
  display: inline-block;
  color: #7782a1;
  height: 100%;
  width: 100%;
  font-size: 13px;
}
.billing-card-list [type="radio"]:checked + label:before,
.billing-card-list [type="radio"]:not(:checked) + label:before {
  right: 20px !important;
  content: "";
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #c8cedb;
  border-radius: 100%;
  background: #fff;
  width: 20px;
  height: 20px;
}
.billing-custom-radio-btn [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(1) translateY(-50%);
}
.billing-card-list [type="radio"]:checked + label:after,
.billing-card-list [type="radio"]:not(:checked) + label:after {
  right: 20px !important;
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: auto;
  border-radius: 100%;
  transition: all 0.2s ease;

  border: 6px solid #3064f9;
  display: inline-block;
  transform: translateY(-50%);
}
/* .billing-card-num span{
    margin-top: -9px !important;
} */
.billing-cus-button {
  height: 40px !important;
  align-items: center;
  justify-content: center;
  display: flex !important;
  margin: 0px !important;
  width: 100px;
  border-radius: 3px !important;
  color: #7581a1 !important;
  min-width: 100px !important;
  padding: 12px 13px !important;
  border: 1px solid #bcc1ce !important;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
}
.billing-card-footer .disable-card {
  height: 40px !important;
  align-items: center;
  justify-content: center;
  display: flex !important;
  background-color: #3064f9 !important;
  opacity: 1 !important;
  margin: 0px !important;
  color: #ffffff !important;
  min-width: 100px !important;
  padding: 12px 15px !important;
  border: 1px solid #3064f9 !important;
  font-size: 14px !important;
  line-height: 14px;
}
.modal-body .mob-done-button {
  display: none;
}
.invoice-subscriptions-type {
  min-width: 220px !important;
  max-width: 380px !important;
}
/* .invoice-payment-method {
  width: 150px !important;
} */
.biiling-iconsize {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 100%;
  /* width: 14.59%;
  height: 14.59%; */
}
.biiling-iconsize img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: contain;
  /* width: 14.59%;
  height: 14.59%; */
  max-width: none;
  margin-left: 0px !important;
}
.billing-react-select-dropdown .react-select22__option--is-disabled {
  background-color: #f8f9fd !important;
  border-radius: 4px !important;
}
.billing-react-select-dropdown .react-select22__option--is-disabled:hover {
  background-color: #f8f9fd !important;
  border-radius: 4px !important;
}
.disable-class-mobile-dropdown {
  background-color: #f8f9fd;
}
.billing-web-paymentgetway-id {
  color: white !important;
  margin-top: 5px !important ;
  font-size: 10px !important;
}
.workspace-right-panel-gap {
  width: calc(100% - 200px) !important;
  margin-left: 200px !important;
}
.billings-dropdown .dropdown-toggle {
  white-space: normal !important;
  width: 100%;
  border: 1px solid #E3E6F1 !important;
  border-radius: 4px;
  text-align: left;
  height: 40px;
  margin-bottom: 15px;
  color: #1b2952 !important;
  /* background-image: url(../../assets/images/dashboard/email-support-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important; */
  -webkit-appearance: none;
  padding: 3px 15px 0px 15px;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  background-color: #fff!important;
  box-shadow: 0px 2px 6px rgba(000, 000, 000, 0.06);
}
.billings-dropdown .dropdown-menu {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  overflow-x: hidden;
}
.billings-dropdown .form-group {
  margin-bottom: 0px !important;
}
.billings-dropdown .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px !important;
  color: #1b2952;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  margin: auto !important;
  border: 0px solid #fff !important;
}
.billings-dropdown .dropdown-item:hover {
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
}
.billings-dropdown .dropdown-item.active,
.billings-dropdown .dropdown-item:active {
  background-color: #f8f9fd !important;
  color: #1b2952 !important;
}
.billings-dropdown-before {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.billings-dropdown .dropdown-menu::-webkit-scrollbar-track {
  background-color: white !important;
}
.billings-dropdown .select-default {
  color: #7782a1 !important;
}
.billings-dropdown .dropdown-search input{
  width: 100%;
}
.billing-new-dropdown{
  display: flex;
}
.billings-dropdown .billing-options h5{
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1B2952;
  margin: 4px 0px -3px 0px;
}
.billings-dropdown .billing-options h6{
  font-size: 10px;
  font-family: "Axiforma-Regular";
  color: #BCC1CE;
  margin: 0px;
}
.billings-dropdown .dropdown-toggle::after{
  display: none;
}
.billing-mob-drop-section{
  display: flex;
}
.billing-mob-select-option{
  width: 100%;
}
.billings-all-option{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billing-yearly-saving{
  border: 1px solid #B0F8CE;
  background-color: #ECFFF4;
  border-radius: 2px;
  padding: 2px 11px;
  width: max-content;
  margin-top: 10px;
}
.billing-yearly-saving h5{
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #1B2952;
  margin: 0px;
}












@media (max-width: 1200px) {
  .right-panel-gap {
    overflow-x: hidden;
  }
  .billings-deal-container {
    margin-bottom: 15px;
  }
  .billings_subscriptions-redesign input {
     margin-bottom: 13px !important;
  }
  .billing-plan-frequency {
    justify-content: flex-start !important;
  }
  .billing-mob-paymentgetway-id {
    font-size: 14px !important;
    color: #7782a1 !important;
    font-family: "Axiforma-Regular";
  }
  .billingnewspinner {
    height: calc(100vh - 65px);
    border-radius: 0px;
    border: none;
  }
  .right-panel-gap-mobil {
    background-color: white !important;
  }
  .billing_right_panel-mobil {
    background-color: white !important;
  }

  /* .min-height-page {
    min-height: 100vh;
  } */
  .billing-tooltip {
    margin-bottom: 3px;
  }
  .billing-upgrade-type {
    margin-top: -7px !important;
  }
  .billing_right_panel {
    background-color: #f8fafe;
    border-radius: 0px !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
    border: none !important;
  }
  .billingdetail-colname {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .billing-mobblank-screen {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-top: 20px;
    display: block;
  }
  .edit-link-redesign {
    width: 60px !important;
    box-shadow: 0px 4px 6px rgba(188, 193, 206, 0.06);
    height: 60px;
    border-radius: 14px;
  }
  .edit-link-redesign img {
    border-radius: 14px;
    width: 60px;
    height: 60px;
    margin-right: 0px !important;
  }
  .right-panel-gap {
    height: calc(100vh - (30px + 30px));
  }
  .crossmodal-card {
    display: none !important;
  }
  .billings-frequency {
    color: #1b2952 !important;
  }
  .billing-card-model .modal-content {
    height: 100% !important ;
  }
  .billing-card-head {
    padding: 25px 20px 18px 20px !important;
    background-color: #f8fafe;
  }
  .billing-card-body {
    padding: 0px 15px !important;
  }
  .billing-mob-cardpopuphead {
    height: 60px;
    background-color: white;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    display: flex;
    align-items: center;
    padding-left: 21px;
  }
  .billing-card-list {
    margin-bottom: 15px;
    background-color: white !important;
    border-radius: 6px !important;
    box-shadow: 0px 3px 6px rgba(188, 193, 206, 0.2);
  }
  .card-add-img {
    margin: 29px 15.5px 30px 20px;
  }
  .modal-body .mob-done-button {
    display: block;
    width: 100%;
    margin-top: 10px;
    opacity: 1 !important;
    background-color: #3064f9 !important;
  }
  .billing-card-footer {
    display: none !important;
  }
  .billing-card-body {
    background-color: #f8fafe;
  }
  /* Redeem page code*/
  .mobilemain {
    padding: 0 34px !important;
  }
  .redeem_img {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
    padding: 0px !important;
    margin-top: 80px !important;
  }
  .redeem_img img{
    width: 241px;
  }
  .redeem_input {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding-left: 0px !important;
    margin-top: 20px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
  .billing-redeemheading {
    font-family: "Axiforma-Bold";
    font-size: 22px;
    justify-content: center;
    display: flex;
    margin-bottom: 0px !important;
    width: 100%;
  }
  .billing-mob-form input {
    padding: 18px 29px 18px 30px !important;
  }
  .billings-redeemsubheader {
    margin-top: 15px !important;
    margin-bottom: 25px !important;
    color: #7782a1 !important;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    justify-content: center;
    display: flex;
  }
  .billings-formspace {
    width: 100% !important;
    margin-bottom: 20px !important;
    padding: 18px 51px 18px 50px !important;
  }
  .billing-redeem-button {
    width: 100% !important;
  }
  .billing-mob-back {
    margin: 15px 0px 0px 0px;
    padding: 0px !important;
  }
  .billing-plan-text {
    margin-top: 7px !important;
  }
  .creditpopup .modal-body {
    max-height: 100%;
  }
  .mob-rounded {
    border-radius: 14px;
  }
  .billing_social_pannel {
    display: none !important;
  }
  .billingsub .css-yk16xz-control {
    border: 1px solid #e3e6f1 !important;
    height: 50px;
  }

  .billing_right_panel .col-md-12 {
    padding: 0px;
  }
  .billing_mobile-payment {
    border: 1px solid #e3e6f1 !important;
    background-color: #ffffff;
    margin: 0px 15px 15px 15px;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(000, 000, 000, 0.06);
  }
  .billing-mob-firstdiv {
    padding: 23px 17px 24px 15px;
    border-bottom: 1px solid #e3e6f1;
  }
  .billing-mob-cardupdate {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .billing-link-card {
    width: 100% !important;
    /* position: absolute; */
    text-align: end;
    margin-top: -15px;
    color: #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
  }
  .link-update-card {
    margin-top: 5px !important;
  }

  .mob-paid-link h4 {
    font-size: 14px !important;
    font-family: "Axiforma-Regular" !important;
  }
  .billing-expiry-date {
    font-size: 14px !important;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .billing-mob-form {
    width: 100% !important;
  }
  .billing-mainheader {
    display: none !important;
  }
  .billing-dashboard-page {
    min-height: auto !important;
  }
  .billing-head-redesign {
    display: none;
  }
  .billing-savebtn {
    display: block;
  }
  .billings_next_btn {
    width: 100%;
    margin-top: 10px;
  }
  .billings_subscriptions-redesign {
    padding: 0px;
  }

  .billings-invoice-mob {
    background-color: #f8fafe;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .billings-mob-invoice {
    border: 1px solid #e3e6f1 !important;
    background-color: white !important;
    margin: 0px 15px 15px 15px;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(000, 000, 000, 0.06);
  }
  .billings-mob-product {
    border-bottom: 1px solid #e3e6f1;
    padding: 24px 15px 18px 15px;
    border-radius: 2px;
    align-items: center;
  }
  .billings-mob-nextpayment {
    border-bottom: 1px solid #e3e6f1;
    padding: 24px 15px 24px 15px;
    border-radius: 2px;
  }
  .billings-mob-paymentmethod {
    padding: 20px 15px 20px 15px;
    border-radius: 2px;
  }
  .billings-mob-paymentmethod h6 {
    margin-bottom: 0px !important;
  }
  .billings-update-popuptext p {
    width: 100%;
  }
  .billing-yearly-saving {
    width: 100%;
    text-align: center;
    padding-bottom: 3px;
  }
  .billings-mob-subscription h4 {
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #1b2952;
    margin-bottom: 8px;
    word-break: break-word;
  }
  .billings-sm-hidden {
    font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #7782a1 !important;
    margin-bottom: 0px;
    margin-top: 5px !important;
    display: flex;
    word-break: break-all;
    margin-right: 8px;
    text-align: left !important;
  }
  .billing-planbox {
    margin-top: 0px;
  }
  .billing-planbox h6 {
    font-size: 12px;
    color: #bcc1ce;
    margin-bottom: 5px;
    font-family: "Axiforma-Regular" !important;
  }
  .billing-planbox p {
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
  }
  .link-update-card a {
    color: #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-top: -12px;
  }
  .link-update-card a img {
    margin-left: 6px;
  }
  .billing-planbox span {
    font-size: 14px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
  }
  .billing-planbox .frequency {
    color: #1b2952 !important;
  }
  .billings-mob-product a {
    color: #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
  }
  .billing-mob-cardupdate a {
    color: #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
  }
  /* .billing-mob-card{
    margin-top: 7px !important;
    
} */
  /* .billing-mob-card p{
    color: #7782A1 !important;
} */
  .billings-updateappmod {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-height: 100% !important;
    overflow: hidden !important;
  }
  .billings-updateApppopup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }
  .billings-update-popuptext h6 {
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    line-height: 34px;
    color: #1b2952;
  }
  .billings-update-popuptext p {
    font-size: 14px;
    line-height: 25px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    text-align: center;
    margin-bottom: 50px;
  }
  .billing-mob-cancle {
    border: none;
    background: white;
    color: #7782a1;
    margin-top: 35px;
    display: block;
  }
  .billing-web-cancle {
    display: none;
  }
  .billings-cancle-update {
    display: grid;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: inherit;
  }
  .billing-mob-update {
    height: 44px;
    border: 1px solid #ff4848;
    color: white;
    background-color: #ff4848;
    border-radius: 4px;
    font-family: "Axiforma-Regular";
    width: 100%;
  }
  .billing-mob-firstpage {
    display: grid;
    background-color: white;
    align-items: center;
    padding: 0px 0px 0px 20px;
  }
  .billing-mob-firstpage .billing-pages {
    margin-bottom: 0px;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    width: 100%;
    justify-content: start;
    display: flex;
    color: #1b2952;
    padding: 26px 0px 14px 0px;
  }
  .billing-firstmobpage {
    margin-bottom: 0px;
  }
  .billing-pages-mob-deals {
    padding-bottom: 30.5px !important;
    border-bottom: 1px solid #edf0f5;
  }
  .billing-first-mobpage {
    padding: 30px 0px 25px 0px;
    border-bottom: 1px solid #edf0f5;
  }
  .billing-first-mobpage h1 {
    font-size: 20px;
    margin-bottom: 0px;
    font-family: "Axiforma-Bold";
  }
  .billing-mob-firstpageview {
    display: block;
    margin-top: 0px;
    height: 100%;
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: #fff;
  }
  .billling-mobile-landing-changes {
    overflow: hidden;
    max-height: 100vh;
  }
  .billings-dropdown {
    display: none !important;
  }
  .billing-mob-select {
    width: 100%;
    height: 50px;
    border: 1px solid #e3e6f1;
    background-color: white;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0px 19px 1px 15px;
  }
  .billing-mob-select-div {
    left: 0;
    width: 100%;
    top: 185px;
    position: fixed;
    border-radius: 0;
    z-index: 999;
    box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
    background-color: #fff;
    background-clip: padding-box;
    max-height: 434px;
  }
  .billing-mob-select-div-user {
    padding: 10px;
  }
  .billing-mob-select-divtwo {
    max-height: 434px;
    overflow-y: auto;
    padding: 20px 10px 24px;
  }
  .billing-mob-select-div::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    z-index: 99;
    top: -10px;
    right: 30px;
    margin: auto;
    transform: rotate(0deg);
    right: 30px;
  }
  .billing-select-name {
    margin-bottom: 4px;
    font-size: 13px;
    color: #1b2952;
  }
  .billing-select-type {
    margin-bottom: 0px !important;
    font-size: 10px !important;
    color: #bcc1ce !important;
    float: left !important;
  }
  .subscription-mob-page-head {
    font-size: 20px;
    font-family: "Axiforma-Bold";
    margin: 25px 0px 20px 15px;
    color: #1b2952;
    line-height: 20px;
  }
  .details-mob-page-head {
    display: block;
    font-size: 20px;
    font-family: "Axiforma-Bold";
    margin: 20px 0px 0px 0px;
    color: #1b2952;
    padding: 15px 0px !important;
  }
  .billings-mob-subscription p {
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
  }
  .mob-form-details {
    background-color: white;
    margin: 0px 0px 10px 0px !important;
    padding: 14px 0px 10px 0px;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(000, 000, 000, 0.06) !important;
    border: 1px solid #E3E6F1;
  }
  .billing-mob-latest-details-time {
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #bcc1ce;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .billing-tooltip .billing-tooltipsubheader {
    top: -2px;
  }
  .wallet-billing-pages {
    color: #7782a1 !important;
    padding: 26px 0px 26px 0px !important;
  }
  .billing-mob-firstpage .walletmoney-count {
    margin-left: 5px !important;
    color: #1b2952 !important;
  }
  .billing-mob-user {
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    display: flex;
    align-items: center;
  }
  .redeem-buttons p {
    display: none !important;
  }
  .crossmodal-delete img {
    position: absolute;
    right: 20px;
    top: 40px;
    cursor: pointer;
  }
  .mob-right-general {
    padding: 0px 15px 0px 15px !important;
  }
  .modal-mob-select-div {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
  }
  .billing-subs-paddle-update {
    justify-content: space-between;
    width: 100%;
  }
  .billing-subs-paddle-update .billing_card-update {
    margin-top: -7px;
    font-size: 14px !important;
  }

  .billing-head-hideformobile {
    display: none !important;
  }
  .redeem-buttons a {
    display: none !important;
  }
  .billing-mob-select-option .css-1ray7zr-control {
    height: 50px !important;
    border: 1px solid #e3e6f1;
  }
  .billing-mob-select-option
    .css-1ray7zr-control
    .billing-options-workspace-type {
    display: none !important;
  }
  .billing-mob-select-option .css-1ray7zr-control .billing-options {
    color: #7782a1;
    font-size: 13px;
    font-family: "Axiforma-Regular";

    margin-top: 3px;
  }
  .billing-mob-select-option .test {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .billing-mob-select-option
    .css-1ray7zr-control
    .css-109onse-indicatorSeparator {
    display: none;
  }
  .billing-country-model .modal-body {
    min-height: 230px;
    max-height: 100%;
    overflow: auto;
    padding: 0px 20px;
    margin-top: 15px;
  }
  .billing-country-model .modal-body p {
    text-align: left !important;
  }
  .security-country-search {
    height: 60px;
    border: none;
    padding: 20px;
    border: none;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
  }
  .security-country-search .searchfleginput {
    color: #1b2952 !important;
    font-size: 16px !important;
    font-family: "Axiforma-Regular";
    border: none !important;
  }
  .security-code-modal .modal-content {
    position: fixed !important;
  }
  .security-code-modal .modal-header {
    padding: 0px;
  }
  .security-code-modal .modal-body {
    overflow-y: auto;
    padding: 10px !important;
  }
  .security-code-modal .modal-body .country-name {
    padding: 0px;
    margin-bottom: 0px;
  }
  .security-code-modal .country-line-flag {
    padding: 10px;
    cursor: pointer;
    color: #1b2952;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    padding: 20px 20px !important;
  }
  .security-code-modal .profile-countrycode-flag {
    margin-top: 0px;
  }
  .security-code-modal .country-line-flag:hover {
    background-color: #f8f9fd;
  }
  .clear-search {
    color: #bcc1ce;
    font-size: 12px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  .billings_next_btn {
    font-size: 16px;
    height: 50px;
  }
  .billingdetails-billing_right_panel {
    background: #f8fafe;
    border-radius: 0px;
    border: none;
  }
  .billing-dropdown-mob-two .dropdown{
    height: 50px !important;
    border: 1px solid #e3e6f1;
    background-color: #fff;
    display: flex;
    align-items: center;
  }
  .billing-dropdown-mob-two .dropdown-menu{
    top: 118px!important;
    transform: none;
    min-width: calc(100vw + 9px);
    left: 0;
    max-height: calc(100vh - 160px);
    overflow: auto;
  }
  .plan-text-responsive .billing-expiry-date-mob{
    font-size: 12px!important;
  }
  .billing-mob-drop-two{
    height: 50px !important;
    border: 1px solid #e3e6f1;
    background: #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 15px!important;
    justify-content: space-between;
  }
  .billing-mob-drop-two p{
    color: #1B2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: unset;
    word-break: unset;
  }
  .billing-mob-select-option {
    width: 49%;
  }
  .billing-mob-drop-two img{
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 6px;
  }
  .billing-mob-drop-two div{
    display: flex;
    align-items: center;
    width: 85%;
        overflow: hidden;
  }
  .billing-dropdown-two-search{
    border-bottom: 1px solid #F1F2F8;
    position: relative;
  }
  .billing-dropdown-two-search input{
    height: 58px;
    width: 100%;
    border: none;
    outline: none;
    color: #1b2952;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    padding: 0px 60px 0px 20px;
  }
  .billing-dropdown-two-search p{
    position: absolute;
    right: 20px;
    top: 20px;
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    cursor: pointer;
  }
  .billing-new-dropbdown-mob{
    padding: 0px!important;
  }
  .billing-dropdown-two-entry{
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .billing-dropdown-two-entry img{
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }
  .billing-dropdown-two-entry h5{
    color: #1B2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px 0px 3px 0px;
  }
  .billing-dropdown-two-entry h6{
    color: #BCC1CE;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .billing-dropdown-two-entry div{
    margin-left: 10px;
  }
  .billing-mob-drop-section {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 15px 15px;
  }
  .billing-mob-one.billing-mob-select-div::before{
    right: auto!important;
    left: 155px!important;
  }
  

}

@media (min-width: 1920px) {
  .billing-product-plan {
    width: 100% !important;
  }
  .billings_subscriptions .product {
    width: 250px;
    width: 24.65%;
  }
  .billings_subscriptions.invoice_table .product {
    width: 22.41%;
  }
}
@media (max-width: 350px) {
  .mob-rounded {
    border-radius: 7px;
  }
}
@media (max-width: 450px) {
  /* .billing-tooltip .billing-tooltipsubheader {
    width: 175px !important;
  } */
  .billing-tooltip .billing-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 33px;
    left: -223%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
  }
  .billing-tooltip .billing-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 75px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .billings-formspace {
    width: 420px !important;
  }
}
