.mobile-view-click:hover .new-enterprisecontact-dropdown__control {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}

.contactHelplinks li {
  list-style: none;
  text-align: right;
  position: relative;
  margin: 0px 0px 0px 48px;
}

/* .contactUsform {
  padding: 50px;
} */
.contactus-errorinput {
  border: 1px solid #ff4848 !important;
}
.contact-errormessages p {
  margin-bottom: 0px;
  color: #ff4848;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5.5px;
}
.contact-errormessages svg {
  margin-top: -3px;
}
.contact-us-error {
  margin-top: 10px;
}
.new-contect-us-para {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 17px;
  line-height: 18px;
  text-align: left !important;
}

.new-contect-us-head {
  font-size: 40px;
  font-family: "Axiforma-bold";
  color: #1b2952;
  margin-bottom: 6px;
  line-height: 69px;
}
.bottom-mob1-menu li {
  list-style: none;
  text-align: right;
  position: relative;
  /* margin: 0px 30px; */
}
.mt-md-1,
.my-md-1 {
  margin-top: 0px !important;
}
.bottom-mob1-menu a {
  font-size: 13px;
  font-family: "Axiforma-regular";
  color: #b8bfd0;
}

.bottom-mob1-menu a:hover {
  color: #b8bfd0;
}

.leftsidedata li {
  list-style: none;
  font-family: "Axiforma-Regular";
}

.leftsidedata ul,
.contactHelplinks ul {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 90px;
}

.bottom-mob1-menu ul {
  display: flex;
  justify-content: end;
  padding: 0;
  margin-bottom: 90px;
}

/* .custom-container {
  width: 1200px;
  padding: 18.5px 74px 43px 40px;
  margin: 0 auto;
} */

.leftsidedata li a,
.contactHelplinks li a {
  font-size: 14px;
  font-family: "Axiforma-regular";
  color: #7782a1;
}

.contact-us-heading h5 {
  font-size: 36px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}

.contact-us-heading p {
  font-size: 16px;
  color: #7782a1;
  margin-bottom: 0;
  width: 460px;

  margin-top: 19px;
}
.phoneinput {
  border-color: #e3e6f1 !important;
}
.phoneinput1 {
  border-color: #e3e6f1 !important;
  height: 50px !important;
}

.checkLabel {
  margin-top: 26px;
}

.checkLabel img {
  margin-bottom: 1px;
}

.checkLabel .checkLabelli {
  margin-top: 31px;
}
.checkLabel label {
  color: #7782a1;
  margin-left: 14px;
  margin-bottom: 0px;
}

.rightsecdata {
  width: 480px;
  /* padding: 0px 30px; */
  background-color: #fff;
}

.rightsecdata textarea.form-control {
  resize: none;
}

.rightsecdata label {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-regular";
  margin-bottom: 9px;
  line-height: 17px;
}

.save-contact {
  background-color: #3064f9;
  color: #fff;
  border-radius: 4px;
  width: auto;
  height: 50px;
  line-height: 50px;
  border: 1px solid #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;

  text-align: center;
  transition: all 0.5s ease;
  width: 100%;
  margin-top: 18px;
}
.contactHelplinks ul {
  margin-bottom: 5px;
  margin-top: 37px;
}

.contactHelplinks li {
  border-right: 1px solid #c8cedb;
  padding: 0 10px;
}

.contactHelplinks li:last-of-type {
  border: 0;
}

.contactHelplinks {
  display: none;
}

.talk1 {
  display: none;
}

.bottom-mob1-menu ul {
  margin-bottom: 29.5px;
}

.bottom-mob1-menu li {
  border-right: 1px solid #c8cedb;
  padding: 0px 30px;
  line-height: 13px;
}

.bottom-mob1-menu li:last-of-type {
  border: 0;
}

.back-home a,
.back-home a:hover,
.login-help button p {
  color: #7782a1;
}
.contact-us-enterprise {
  margin-top: 37px;
}
.contact-us-enterprise p {
  font-size: 14px;
  color: #0f193b;
  font-family: "Axiforma-Regular";
}
.contact-us-enterprise a {
  font-size: 14px;
  color: #3064f9 !important;
  font-family: "Axiforma-Regular";
  margin-left: 10px;
}
.login-help .btn {
  padding-bottom: 0;
  padding-top: 3px;
}

.login-help.login-help-red .btn {
  padding-top: 0;
}
.new-enterprisecontact-dropdown__value-container {
  height: 48px !important;
  padding: 0px 10px 0px 18px !important;
}
.new-enterprisecontact-dropdown__control {
  height: 50px;
  border: 1px solid;
  border-radius: 3px !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  box-shadow: none !important;
  cursor: pointer !important;
}
.new-enterprisecontact-dropdown__control:hover,
.new-enterprisecontact-dropdown__control:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.new-enterprisecontact-dropdown__menu {
  margin-top: 0px !important;
}
.new-enterprisecontact-dropdown__menu-list {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
}
.new-enterprisecontact-dropdown__option {
  font-size: 13px !important;
  background-color: #ffffff !important;
  color: #1b2952 !important;
  padding: 13px 10px !important;
  margin: auto;
  width: calc(100% - 10px) !important;
  font-family: "Axiforma-Regular";
}
.new-enterprisecontact-dropdown__option:hover {
  font-size: 13px !important;
  background-color: #f8f9fd !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 10px !important;
}
.contact-usphone-dropdown .dropdown-toggle:hover,
.contact-usphone-dropdown .dropdown-toggle:focus,
.contactus-page textarea.form-control:hover,
.contactus-page textarea.form-control:focus,
.contactus-page .form-group input:hover,
.contactus-page .form-group input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.save-contact:hover {
  background-color: #134bec;
}
.contact-usphone-dropdown .dropdown-toggle {
  white-space: normal !important;
  width: 100%;
  border: 1px solid #bcc1ce !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-radius: 0px;
  text-align: left;
  height: 50px;
  margin-bottom: 15px;
  color: #1b2952 !important;
  background-image: url(../../assets/images/dashboard/grey-dropdown.png) !important;
  background-repeat: no-repeat !important;
  background-position: 87% !important;
  -webkit-appearance: none;
  padding: 0px 15px 4px 15px;
  transition: 0.5s;
  width: 125.5px;
  margin-bottom: 0px;
}
.contact-usphone-dropdown .dropdown-toggle::after {
  display: none !important;
}
.contact-dropdownmenu {
  width: 480px;
  padding: 0px !important;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  min-height: 300px;
  overflow: overlay;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
}
.contact-dropdownmenu .dropdown-search {
  display: flex;
  border-top: 1px solid #f1f2f8 !important;
  border-left: 1px solid #f1f2f8 !important;
  /* border-right: 1px solid #f1f2f8 !important; */
  border-bottom: 1px solid #f1f2f8 !important;
}
.contact-dropdownmenu .dropdown-search p {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #bcc1ce;
  font-size: 12px;
  margin: 0px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.contact-dropdownmenu .dropdown-search input {
  width: 100%;
  border: none !important;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0px;
}
.contact-dropdownmenu .dropdown-search input:focus,
.contact-dropdownmenu .dropdown-search input:hover {
  background-color: #ffffff !important;
  border: none !important;
}
.contact-dropdownmenu .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px !important;
  margin: auto !important;
  border: 0px solid #fff !important;
  align-items: center;
}
.contact-dropdownmenu .dropdown-item h1 {
  font-size: 13px !important;
  color: #1b2952;
  font-family: "Axiforma-Regular" !important;
  margin-bottom: 0px;
  margin-top: 2px;
}
.contact-dropdownmenu .dropdown-item img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 1px;
}
.contact-dropdownmenu .dropdown-item p {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-top: 2px;
}
.contact-dropdownmenu .dropdown-item:hover {
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
}

.connectus-dropdowntoggle {
  display: flex !important;
  align-items: center;
  padding-bottom: 0px !important;
}
.connectus-dropdowntoggle img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.connectus-dropdowntoggle h1 {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
  margin-top: 2px;
}
.contact-flaginputbox {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.contact-flaginputbox:focus {
  border-left: none !important;
}

/* .contactus-page {
  background-color: #F8FAFE;
  height: calc(100vh - 80px);
  display: flex;

} */

/* .enterprisecontact {
  background-image: url("../../assets/images/signup/newbackground.png");
  display: flex;
  justify-content: center;
  background-size: cover;
  height: calc(100vh - 90px);
} */
.sociallaccount {
  position: fixed;
  right: 40px;
  bottom: 40px;
  align-items: center;
  display: flex;
}
.sociallaccount svg {
  margin-left: 28.9px;
  cursor: pointer;
}
.contactUs {
  background-image: url("../../assets/images/dashboard/app-icon-background.png");
  /* height: calc(100vh - 90px); */
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 100vh;
  background-size: contain;
  background-attachment: fixed;
  padding-top: 56px;
}
.newcontactUs {
  background-image: url("../../assets/images/dashboard/app-icon-background.png");
  /* height: calc(100vh - 90px); */
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: contain;
  background-attachment: fixed;
  padding-top: 56px;
}

.newcontactUsWithInsideHeader{
  padding-top: 146px !important;
}
.new-enterprisecontact-dropdown__placeholder {
  line-height: 26px;
  margin-left: -4px !important;
}
.new-enterprisecontact-dropdown__single-value {
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  margin-top: 2px;
}
.contactus-page .leftsidedata ul {
  justify-content: flex-end;
}

.contactus-page .leftsidedata ul li::before {
  content: "";
  border-left: 1px solid #c8cedb;
  position: relative;
  left: -25px;
}

.contactus-page .leftsidedata ul li:first-child::before {
  display: none;
}

.contactus-page .form-group {
  margin-bottom: 11px;
}

.contactus-page textarea.form-control {
  height: 100px;
  border: 1px solid #bcc1ce;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.contactus-page textarea.form-control:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}

.contact-page .contact-mobile-login {
  margin-left: 26px;
}

.contactus-page .form-group input {
  height: 50px;
  padding: 0 16px;
  border: 1px solid #bcc1ce;
  border-radius: 3px !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  padding: 18px 20px 18px 20px;
}

.new-enterprisecontact-dropdown__indicator.new-enterprisecontact-dropdown__dropdown-indicator.css-tlfecz-indicatorContainer {
  padding: 9px;
}
/* .contactus-page .form-group input:focus {
  border: 1px solid #BCC1CE !important;

} */

.contact-captcha {
  margin-top: 20px;
}

.iconimg {
  margin-top: 36px;
}

.talk {
  display: grid;
  background-color: #e3e6f1;
  width: 460px;
  height: 60px;
  margin-top: 35.5px;
  border-radius: 4px;
}

.talk p {
  font-size: 14px;
  font-weight: 500;
  margin: 19px 20px 22px;
  color: #0f193b;
}

.talk a {
  color: #3064f9;
}

.talk a:hover {
  color: #3064f9;
}
.talk1 {
  margin-bottom: 42px;
  margin-top: 39.5px;
  padding: 0 20px;
}

.talk1 p {
  background-color: #e3e6f1;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #0f193b;
  margin-bottom: 0;
  line-height: 60px;
  text-align: center;
}

.talk1 a {
  color: #3064f9;
}

/* .contactUsform {
  padding: 50px;
} */

.form-group .work {
  display: flex;
  column-gap: 20px;
}

.form-group .workinput1 {
  flex: 1 1 0px;
}
.form-group .workinput2 {
  flex: 1 1 0px;
}

.workinput1 select {
  height: 50px;
  padding: 0 30px;
}

.workinput2 select {
  height: 50px;
  padding: 0 30px;
}
.sociallaccount-mob {
  display: none;
}

[dataclass~="countrycode"] .enterprise-country-name {
  margin-bottom: 0px;
  padding-left: 10px;
}

[dataclass~="countrycode"] .mob-company-size {
  font-size: 16px;
  color: rgb(27, 41, 82);
  font-family: Axiforma-Regular;
  margin: 0px 20px 40px;
  display: block;
}
.new-enterprisecontact-dropdown__indicator-separator {
  width: 0px !important;
}
.contact-usphone-dropdown .dropdown-menu::-webkit-scrollbar-track {
  background-color: white !important;
}

@media (max-width: 1200px) {
  /* .new-enterprisecontact-dropdown__menu-list{
     display: none;
  } */
  .save-contact {
    font-size: 16px;
  }
  .new-contect-us-head {
    font-size: 30px;
    margin-bottom: 2px;
  }
  .newcontactUs,
  .contactUs {
    background: none;
    display: grid;
    padding: 20px 20px 20px;
  }
  .newcontactUsWithInsideHeader {
    padding-top: 80px !important;
}
  .sociallaccount-mob svg {
    margin-right: 28.9px;
    margin-left: 0px;
    cursor: pointer;
  }
  .sociallaccount {
    display: none;
  }
  .mob-popup-company-size {
    padding-top: 43px;
    overflow: auto;
    height: calc(100vh - 80px);
  }
  .sociallaccount-mob {
    display: flex;
    align-items: center;
    /* margin-top: 50px; */
    margin-bottom: 23px;
    /* padding: 0px 30px; */
    margin-top: 26px;
  }
  .sociallaccount-mob.mb-social {
    margin-top: 43px;
  }
  .sociallaccount-mob svg {
    margin-right: 28.9px;
  }
  .contact-us-headings .new-contect-us-para {
    margin-bottom: 24px;
  }
}
@media (max-width: 520px) {
  .rightsecdata {
    width: 100%;
    padding: 0px;
  }
  .workinput2 {
    margin-bottom: 10px;
  }
  .newcontactUs,
  .contactUs {
    justify-content: inherit;
  }
  .contact-us-enterprise p {
    font-size: 13px;
  }
  .form-group .work {
    display: grid;
  }
  .sociallaccount-mob {
    padding: 0px;
  }
}
