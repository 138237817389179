@font-face {
  font-family: "Proxima-Regular";
  src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../assets/fonts/ProximaNova-Bold.otf);
}
p {
  font-family: "Axiforma-Regular";
}

.addcard {
  border: 1px solid #3064f9;
  height: 40px;
  width: 100px;
  border-radius: 5px;
  background: #f8fafe;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 19px 19px 19px 25px;
}

.billing_right_panel-paymentmethod {
  background: none !important;
  border-radius: 6px;
  box-shadow: none !important;
  height: auto;
  padding: 0px;
  padding-top: 0px;
  position: relative;
  margin-bottom: 0px !important;
  border: none;
}
.addedcard {
  height: 240px !important;
  width: 400px;
  min-width: 330px;
  border-radius: 10px;
  background: #ffffff;
  margin-right: 0px;
  position: relative;
  margin-top: 0px;
  background-image: url("../../assets/images/dashboard/Billing/card-back-new.png");
  box-shadow: 0px 4px 16px rgba(000, 000, 000, 0.1);
  background-size: cover;
  background-color: #f8fafe;
}

.addedcard.addedcardzoho {
  height: 150px;
}
.addedcard.addedcardzoho .carddetail {
  padding: 24px 20px;
}
.carddetail {
  padding: 34px 20px;
}
.carddetail h3 {
  color: #1b2952;
  font-size: 17px;
  font-family: "Axiforma-Bold";
}
.carddetailForAppSumo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
}
.carddetailForAppSumo h3 {
  width: 100%;
}
.carddetail p {
  font-size: 12px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  text-align: end;
  opacity: 0.8;
  line-height: 16px;
}

.carddetail .expire {
  font-size: 12px;
}
.addcard h2 {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0px 5.5px 0px 5.5px;
}
.bottomcard {
  /* border-top: 1px solid #dce1eb; */
  padding: 0px 20px 18px 20px;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomcard span {
  color: #ff4848;
  font-size: 12px;
  cursor: pointer;
  font-family: "Axiforma-Regular";
  margin-top: 2px;
}
.bottomcard p {
  color: #1b2952;
  font-size: 12px;
  margin-bottom: 0px !important;
  opacity: 0.8;
  margin-top: 2px;
}

.addedcard {
  margin-bottom: 20px;
  margin-right: 20px;
}

.addcard {
  margin-left: 0px;
}

.billing-upgrade-model .crossmodal-delete img {
  right: 12px;
  top: 13px;
}
.billing-upgrade-model .billings-update-popuptext h6 {
  line-height: 25px;
  margin-bottom: 15px;
}
.billing-upgrade-model .billings-update-popuptext p {
  line-height: 18px;
  margin-bottom: 36px;
}
@media (width:1920px) { 
  .addedcard:nth-child(4n + 2) {
  margin-right: 0;
}
 }
@media (max-width: 1200px) {
  .addcard {
    width: 100%;
  }
  .addcard {
    margin-bottom: 15px !important;
    border: 1px solid #3064f9;
    margin-top: 0px;
    height: 50px;
    padding: 0px;
  }
  .addedcard:nth-child(2n) {
    margin-bottom: 15px;
    margin-right: 0px;
  }
}
/* @media (max-width: 879px){
  .addedcard:nth-child(1n) {
    margin-right: 0px !important;
  }
} */
@media (max-width: 430px) {
  /* .addedcard {
    height: 200px !important;
  } */

  /* .carddetail {
    padding: 25px 20px;
  } */

  .addedcard:nth-child(1n) {
    margin-right: 0px !important;
  }
}
