@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}

.app-dashboard-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-dashboard-head .title {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin: 0px;
  display: flex;
  align-items: center;
}
.app-dashboard-head .appdash-tooltip {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.app-dashboard-full {
  display: flex;
  align-items: flex-start;
  margin-top: 21px;
}
.appdashboard-loader {
  overflow: hidden;
  min-height: 80vh;
  margin-top: 10px;
}
.app-dashboard-left {
  width: calc(100% - 408px);
  margin-right: 20px;
}
.app-dashboard-right {
  width: 400px;
  min-width: 400px;
}
.dashboard-app-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.dashboard-app-info-one {
  background-color: #fff;
  width: 32.8%;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 32px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e6f1;
}
.dashboard-appicon {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 14px;
  overflow: hidden;
  margin-right: 20px;
  box-shadow: 0px 2px 6px rgb(188 193 206 / 30%);
}
.dashboard-app-info-one .appNametype {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px 0px -1px 0px;
  line-height: 25px;
  word-break: break-word;
}
.dashboard-app-info-one .appLink,
.dashboard-app-info-one .appLink:hover {
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  margin: 0px;
  word-break: break-word;
}
.dashboard-app-info-one .appId {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 10px 0px 0px 0px;
  line-height: 16px;
  text-align: left !important;
}
.dashboard-app-info-one-child {
  display: flex;
  width: 67%;
  gap: 20px;
}
.dashboard-app-info-two {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e6f1;
}
.dashboard-app-info-two .appName {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
  line-height: 30px;
}
.dashboard-app-info-two .appCreatedDate {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 3px 0px 0px 0px;
  line-height: 16px;
}
.dashboard-app-info-two .img-fluid {
  margin-right: 20px;
}
.dashboard-app-info-three {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e6f1;
}
.dashboard-app-info-three .userImagediv {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin-right: 17px;
  border-radius: 100%;
  overflow: hidden;
}
.dashboard-app-info-three .appUserName {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.dashboard-app-info-three .appUserEmail {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 7px 0px 10px 0px;
  word-break: break-word;
}
.dashboard-app-info-three .appUserId {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.dashboard-app-connectivity {
  display: flex;
  justify-content: space-between;
}
.dashboard-app-connectivity-one {
  background-color: #fff;
  width: 32%;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 36px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e3e6f1;
  margin-top: 20px;
  position: relative;
  transition: 0.5s;
}
.dashboard-app-connectivity-one:hover {
  border: 1px solid #6e94ff;
  transition: 0.5s;
  cursor: pointer;
}
.dashboard-app-connectivity-one h3 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.dashboard-app-connectivity-one h4 {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 7px 0px 0px 0px;
}
.dashboard-app-connectivity-one a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.app-plan-detail {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  padding: 23px 20px 8px 19px;
  margin-bottom: 20px;
}
.app-plan-detail-one {
  width: calc(100% - 100px);
}
.app-plan-detail-one h3 {
  font-size: 16px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 9px;
}
.app-plan-detail-one p {
  font-size: 12px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  line-height: 16px;
  width: 90%;
  margin-bottom: 0px;
}
.app-plan-detail-one h6 {
  font-size: 12px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  line-height: 16px;
  margin-top: 10px;
}
.app-plan-detail-two {
  min-height: 100px;
  max-width: 100px;
}
.app-status-upgrade {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 22px;
  margin-top: 10px;
  margin-bottom: 11px;
}
.app-status-upgrade p {
  font-size: 13px;
  color: #47ce70;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.app-status-upgrade a,
.app-status-upgrade a:hover {
  font-size: 13px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.app-plan-alignment {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.app-total-steps {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  border: 1px solid #e3e6f1;
}
.app-dashboard-progress {
  padding: 20px 0px 0px 20px;
}
.app-dashboard-progress h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  display: flex;
  align-items: center;
}
.app-dashboard-progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edf0f5;
  padding-bottom: 15px;
  margin-bottom: 17px;
  width: calc(100% - 20px);
}
.app-dashboard-progress-bar p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin: 0px 10px 0px 0px;
}
.app-progress {
  height: 3px !important;
  width: 100%;
}
.app-progress-bar {
  background-color: #6e94ff !important;
  height: 3px;
}
.app-steps a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.app-steps img {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: 0.5s;
}
.app-steps:hover img {
  opacity: 1;
  transition: 0.5s;
}
.app-steps-animation {
  position: absolute;
  height: 82px;
  top: 0;
  z-index: 0;
  transition: all 0.2s ease 0s;
  border-radius: 4px;
}
.app-seven-steps {
  position: relative;
  border-radius: 8px;
  font-size: 0;
  display: block;
  width: calc(100% - 20px);
  padding: 0px 10px 10px 10px;
}
.app-steps:nth-child(1):hover ~ .app-steps-animation {
  width: 100%;
  top: 0;
  background-color: #f1f4fb;
}
.app-steps:nth-child(2):hover ~ .app-steps-animation {
  width: 100%;
  top: 82px;
  background-color: #f1f4fb;
}
.app-steps:nth-child(3):hover ~ .app-steps-animation {
  width: 100%;
  top: 164px;
  background-color: #f1f4fb;
}
.app-steps:nth-child(4):hover ~ .app-steps-animation {
  width: 100%;
  top: 246px;
  background-color: #f1f4fb;
}
.app-steps:nth-child(5):hover ~ .app-steps-animation {
  width: 100%;
  top: 328px;
  background-color: #f1f4fb;
}
.app-steps:nth-child(6):hover ~ .app-steps-animation {
  width: 100%;
  top: 410px;
  background-color: #f1f4fb;
}
.app-steps:nth-child(7):hover ~ .app-steps-animation {
  width: 100%;
  top: 492px;
  background-color: #f1f4fb;
}
.app-dashboard-progress {
  padding: 20px 0px 0px 20px;
}
.app-steps {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  height: 82px;
  z-index: 1;
  width: 100%;
  padding: 0px 0px 0px 14px;
  background-position: right center;
  background-repeat: no-repeat;
}
.app-steps:hover {
  background-image: url(../images/dashboard/arrow-grey.png);
  background-position: right center;
  background-repeat: no-repeat;
  transition: 0.5s;
}
.app-steps h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 6px;
}
.app-steps p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.app-steps div:nth-child(2) {
  margin-right: 20px;
}
.unfinished-steps {
  animation: shadowToggle 2s ease-in infinite;
  border: 2px solid;
  border-image-source: linear-gradient(
    0deg,
    #68ccff -40.25%,
    #5f65ff 35.99%,
    #af68ff 113.76%
  );
}
@keyframes shadowToggle {
  0% {
    box-shadow: 0px 2px 6px 0px #0000000f;
    border: 2px solid #e3e6f1;
  }
  50% {
    box-shadow: 0px 0px 12px 0px #3064f966, inset 0px 0px 12px 0px #3064f9cc;
    border: 2px solid;
    border-image-source: linear-gradient(
      0deg,
      #68ccff -40.25%,
      #5f65ff 35.99%,
      #af68ff 113.76%
    );
    border-image-slice: 1;
    border-radius: 0px;
  }
  90%,
  100% {
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border: 2px solid #e3e6f1;
  }
}
.app-steps-right {
  border-radius: 6px;
  overflow: hidden;
  animation: shadowToggle1 2s ease-in infinite;
}
@keyframes shadowToggle1 {
  0% {
    box-shadow: 0px 2px 6px 0px #0000000f;
  }
  50% {
    box-shadow: 0px 0px 12px 0px #3064f966, inset 0px 0px 12px 0px #3064f9cc;
  }
  90%,
  100% {
    box-shadow: 0px 2px 6px 0px #0000000f;
  }
}
.app-support-each-links {
  background-color: #fff;
  width: 32%;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 40px 27px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e6f1;
  margin-bottom: 20px;
  position: relative;
  transition: 0.5s;
}
.new-dashboard-barheight {
  position: relative;
  display: block !important;
  overflow: hidden;
}
.dashboard-no-graph-data {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.app-support-each-links img {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: 0.5s;
}
.app-support-each-links:hover .app-support-each-links img {
  opacity: 1;
  transition: 0.5s;
}
.app-support-each-links:hover {
  border: 1px solid #6e94ff;
  cursor: pointer;
}
.the-app-support h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin: 20px 0px 15px 0px;
}
.the-app-support h5 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 13px;
  margin: 0px 0px 0px 27px;
}
.the-app-support p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin: 8px 0px 0px 27px;
}
.app-support-each-links a {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
}
.app-support-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.app-build-info {
  background-color: #fff;
  width: 50%;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  box-shadow: 0px 2px 6px 0px #0000000f;
  padding: 29px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-app-build-icon {
  margin-right: 19px;
}
.build-info-left-one {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin: 2px 0px 6px 0px;
}
.build-info-left-two {
  color: #7782a1;
  line-height: 17px;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  padding-right: 20px;
  margin: 0px;
}
.dashboard-app-build-left {
  display: flex;
  align-items: center;
}
.build-info-left-status {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin: 0px;
  text-align: right;
  width: max-content;
}
.dasboard-build-draft {
  color: #bcc1ce;
}
.dasboard-build-failed {
  color: #ff4848;
}
.dasboard-build-complete {
  color: #52cb86;
}
.version-available-text {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 13px;
  margin: 7px 0px 0px 0px;
  line-height: 18.27px;
}
.build-btn-text {
  cursor: pointer;
  color: #3064f9;
}
.buildapp-dashboard,
.buildapp-dashboard:hover {
  width: 100%;
  background-color: #f8fafe;
  border-radius: 4px;
  height: 40px;
  color: #bcc1ce;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 45px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}
.app-downloads {
  margin-top: 20px;
  display: block;
}
.app-dashbuild-info {
  display: flex;
  gap: 20px;
  width: 100%;
}
.rebuildapp-dashboard,
.rebuildapp-dashboard:hover {
  width: 100%;
  background-color: #f8fafe;
  border-radius: 4px;
  height: 40px;
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 45px;
  display: none;
  align-items: center;
  justify-content: center;
}
.dashboard-analytics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 0px 6px 0px;
}
.app-graph-upgrade-link {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.dashboard-analytics h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin: 0px 0px 0px 0px;
}
.app-download-info {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  padding: 0px 15px 15px 19px;
  margin-top: 20px;
  width: 100%;
  position: relative;
  min-height: 200px;
  height: 440px;
}
.app-graph-upgrade {
  height: 440px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  padding: 0px 15px 15px 15px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
}
.app-graph-upgrade .graph-link-upgrade {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.app-team h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
}
.app-team {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 24px 20px 28px 20px;
  border: 1px solid #e3e6f1;
  margin-top: 20px;
}
.app-teamlist {
  display: flex;
  margin-top: 23px;
  overflow: hidden;
  background-image: url(../images/dashboard/blank-placeholder.png);
  background-repeat: repeat-x;
}
.app-teamlist .teammember {
  width: 54px;
  margin-right: 10px;
  min-height: 54px;
  min-width: 54px;
  position: relative;
  background-color: #f8fafe;
  border-radius: 100%;
}
.app-teamlist .teammember img {
  border-radius: 100%;
}
.app-teamlist .teammember .online,
.app-teamlist .teammember .offonline {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0px;
  bottom: 6px;
  border: 3px solid #fff;
}
.app-teamlist .teammember .online {
  background-color: #47ce70;
}
.app-teamlist .teammember .offonline {
  background-color: #bcc1ce;
}
.app-dashboard-img {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-size: cover !important;
}
.dash-app-upgrade {
  width: 100%;
  height: 40px;
  background-color: #f8fafe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.dash-app-upgrade p {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 12px;
  margin: 0px;
}
.dash-app-upgrade p span {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
}
.app-mob-plans {
  display: none;
}
.app-dash-steps-mob {
  display: none;
}
.app-dash-team-mob {
  display: none;
}
.appdash-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 6px !important;
}
.appdash-tooltip .appdash-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 159%;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  text-align: left !important;
  transition: 0.3s;
}
.appdash-tooltip .appdash-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 18px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.appdash-tooltip:hover .appdash-tooltipsubheader {
  visibility: visible;
}
.app-support-each-links svg {
  min-width: 24px;
}
.analytics2k {
  display: block;
  height: 100% !important;
}
.app-dashboard-graph-upgrade {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 40px;
}
.app-dashboard-graph-upgrade h3 {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin: 0px;
}
.app-dashboard-graph-upgrade p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin: 5px 0px 0px 0px;
}
.web-to-app-graph img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.analytics-Loader h1 {
  font-family: "Axiforma-Regular"!important;
  font-size: 13px!important;
  line-height: 17.47px!important;
  color: #bcc1ce!important;
  position: absolute;
  top: 30%;
  left: 45%;
}
.dot-one {
  animation: dot-one 2s infinite linear;
}
.dot-two {
  animation: dot-two 2s infinite linear;
}
.dot-three {
  animation: dot-three 2s infinite linear;
}
@keyframes dot-one {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.dashBoard-dummydata-loader {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.analyticsUpgrade-laptop-screen {
  display: none;
}
.analyticsUpgrade-bigscreen {
  display: none;
}
.analyticsUpgrade-middlescreen {
  display: block;
}
.analyticsUpgrade-screen {
  display: none;
}
.no-checklist-shadow {
  overflow: unset;
  animation: none;
}
.analytics-dawnload-info canvas {
  height: calc(100% - 50px) !important;
}
.success-checklist-img{
  margin-left: 7px;
}

@media (min-width: 2700px) {
  .analyticsUpgrade-middlescreen {
    display: none !important;
  }
  .analyticsUpgrade-bigscreen {
    display: block;
  }
}

@media (min-width: 2200px) {
  .analyticsUpgrade-middlescreen {
    display: block;
  }
  .analyticsUpgrade-screen {
    display: none;
  }
  .dashboard-app-info-three,
  .dashboard-app-connectivity-one:nth-child(3) {
    margin-right: 0px;
  }
  .app-support-each-links {
    width: 32.5%;
  }
}
@media (min-width: 1481px) and (max-width: 1800px) {
  .analyticsUpgrade-middlescreen {
    display: block;
  }
  .analyticsUpgrade-screen {
    display: none;
  }
  .app-downloads {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .app-dashbuild-info {
    width: 100%;
    margin-top: 20px;
  }
  .app-build-info:nth-child(2) {
    margin-right: 0px;
  }
  .app-download-info {
    width: 100%;
  }
  .app-build-info {
    padding: 20px 20px 20px 10px;
  }
  .dashboard-app-info-one {
    padding: 24px 20px;
  }
  .dashboard-app-info-one .appId {
    margin: 4px 0px 0px 0px;
  }
}

@media (min-width: 1201px) and (max-width: 1480px) {
  .app-dashboard-left {
    width: calc(100% - 320px);
  }
  .app-dashboard-right {
    width: 320px;
    min-width: 320px;
  }
  .dashboard-appicon {
    width: 52px;
    max-width: 52px;
    min-width: 52px;
    height: 52px;
  }
  .agency-support-body-card {
    padding: 21px 10px 21px 10px;
  }
  .dashboard-app-info-two .img-fluid {
    max-width: 52px;
  }
  .dashboard-app-info-three .userImagediv {
    width: 52px;
    height: 52px;
    min-width: 52px;
    margin-bottom: 15px;
  }
  .dashboard-app-info-one,
  .dashboard-app-info-two,
  .dashboard-app-info-three {
    padding: 20px 20px 18px 20px;
    width: 100%;
    margin-bottom: 10px;
  }
  .dashboard-app-connectivity-one {
    padding: 26px 15px;
  }
  .app-downloads {
    margin-top: 0px;
  }
  .app-build-info {
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 20px 20px 10px;
  }
  .dashboard-app-info-one-child {
    width: 100%;
    gap: 10px;
  }
  .app-dashbuild-info {
    width: 100%;
    display: block;
  }
  .app-build-info:nth-child(2) {
    margin-right: 0px;
  }
  .app-download-info {
    width: 100%;
    margin-top: 10px;
  }
  .app-support-each-links {
    width: 49%;
  }
  .app-steps svg {
    margin-top: -15px;
  }
  .dashboard-app-info {
    gap: 10px;
    display: block;
  }
}

@media (max-width: 600px) {
  .analyticsUpgrade-screen {
    display: block;
  }
  .app-download-info {
    width: 100%;
    height: 320px;
    padding: 0px 15px 15px 10px;
  }
  .analytics-graph-laptop{
    height: 232px;
  }
  .app-graph-upgrade {
    height: 320px;
  }
  .app-graph-upgrade-link {
    margin-top: 10px;
  }
  .analytics2k{
    height: 232px !important;
  }
}
@media (max-width: 1200px) {
  .app-dashboard-full {
    display: block;
    margin: 17px 15px 20px 15px;
  }
  .app-dashboard-left {
    width: 100%;
    margin-right: 0px;
  }
  .app-dashboard-right {
    width: 100%;
    min-width: 100%;
    margin-top: 15px;
  }
  .app-team {
    margin-top: 15px;
  }
  .dashboard-app-info {
    display: block;
  }
  .dashboard-app-info-one,
  .dashboard-app-info-two,
  .dashboard-app-info-three {
    width: 100%;
    margin-bottom: 15px;
    padding: 0px 20px;
    height: 120px;
  }
  .dashboard-app-connectivity {
    display: block;
  }
  .dashboard-app-connectivity-one {
    width: 100%;
    margin-top: 20px;
    padding: 28px 20px;
  }
  .app-downloads {
    display: block;
    margin-top: 15px;
  }
  .app-dashbuild-info {
    display: flex;
    width: 100%;
  }
  .app-download-info {
    width: 100%;
  }
  .app-dashbuild-info {
    display: block;
    width: 100%;
  }
  .app-build-info {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 15px;
    padding: 30px 10px 30px 10px;
    display: block;
  }
  .version-available-text {
    margin: 7px 0px 10px 0px;
  }
  .build-info-left-status {
    margin-left: 100px;
  }
  .app-support-each-links {
    width: 100%;
    padding: 30px 27px;
  }
  .app-dashboard-head {
    margin: 17px 15px 0px 15px;
  }
  .app-dashboard-head .title {
    font-size: 20px;
  }
  .buildapp-dashboard,
  .rebuildapp-dashboard,
  .rebuildapp-dashboard {
    margin-top: 32px;
  }
  .dash-app-upgrade {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .app-teamlist {
    margin-top: 16px;
  }
  .app-mob-plans {
    display: block;
  }
  .app-dash-steps-mob {
    display: block;
    margin-top: 20px;
  }
  .app-dash-team-mob {
    display: block;
  }
  .app-steps {
    padding: 0px 0px 0px 10px;
  }
  .appdash-tooltip .appdash-tooltipsubheader {
    width: 200px;
    top: 29px;
    left: -30px;
  }
  .appdash-tooltip .appdash-tooltipsubheader::after {
    top: -8px;
    right: 156px;
    transform: rotate(90deg);
  }
  .app-plan-detail-one p {
    text-align: left !important;
  }
  .dashboard-app-info-one-child {
    display: block;
    width: 100%;
  }
}
