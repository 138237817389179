.mob-deals {
  display: none;
}
.disableddeals {
  pointer-events: none;
  opacity: 0.4;
}
.disableddeals .deals-coupon-head {
  background: #f8fafe;
  border: 1px dashed #e3e6f1;
}
.delas-icons {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 185px;
}
.delasline-div {
  height: 85%;
  border-right: 2px dashed #c8cedb;
  position: absolute;
  left: 164px;
  opacity: 0.5;
}
.deals-status{
  display: inline-block;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #fff;
    text-align: center;
    line-height: 13px;
    padding: 3px;
    text-transform: capitalize;
    padding-right: 0px;

   
}
.down-border {
  top: -9px;
  left: 155px;
  padding: 10px 10px;
  background-color: #f8fafe;
  border-radius: 50%;
  position: absolute;
  box-shadow: inset 3px 3px 3px rgba(188, 193, 206, 0.2);
  transform: rotate(210deg);
}
.deals-coupon-head {
  background: #f9fffb;
  border: 1px dashed #47ce70;
}

.up-border {
  bottom: -9px;
  left: 154px;
  padding: 10px 10px;
  background-color: #f8fafe;
  /* background-color: #F1F4FB; */
  border-radius: 50%;
  position: absolute;
  box-shadow: inset 3px 3px 3px rgba(188, 193, 206, 0.2);
  transform: rotate(36deg);
}
.billing-dealsbackground {
  width: 100%;
  height: 160px;
  background: #fff;
  border: none !important;
  border-width: 0 0 1px 0;
  border-radius: 8px;
  display: flex;
  position: relative;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.deals-plans-namehead {
  padding-left: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-right: 30px;
  display: grid;
  align-items: center;
}
.deals-plans-namehead h5 {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin-bottom: 6.5px;
}
.deals-plans-namehead h3 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  display: flex;
  align-items: center;
  margin-bottom: 6.5px;
}
.deals-plans-namehead .deal-activation-date {
  font-size: 12px !important;
  font-family: "Axiforma-Regular";
  color: #7782a1 !important;
  margin-bottom: 0px !important;
}
.deals-plan-textdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.deals-count {
  align-items: center;
  display: grid;
  padding-top: 62px;
  padding-bottom: 62px;
  line-height: 22px;
}
.deals-count h5 {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
}
.deals-count .deals-expiry-date h5 {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-top: 7px;
}
.deals-coupon-head {
  align-items: center !important;
  justify-content: center;
  display: flex !important;
  margin-bottom: 20px;
}
.deals-coupon-code-text {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;

  margin-bottom: -3px;
  margin-right: 10px;
  justify-content: center;
}

.deals-info-coupons {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30.5px;
}
.deals-mob-head {
  display: none;
}
.redeem-mob-button {
  display: none;
}
.dealss-desc-list {
  list-style-type: none;
  position: relative;
  margin-bottom: 0px !important;
}
.payment-billingnewspinner{
  width: 100%;
  height: 100%;
  /* background: white; */
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0px;
  opacity: 1;
  height: 75vh;
}
ul.dealss-desc-list li::before {
  content: url("../../assets/images/dashboard/analytics-tick.png");
  position: absolute;
  left: 31px;
  opacity: 0.5;
}
.upgrade-linkk {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  line-height: 1.5;
  margin-left: 10px;
  cursor: pointer;
}
.upgrade-linkk:hover {
  color: #3064f9;
}
.upgrade-linkk img {
  margin-left: 10px !important;
}
.dealss-seconddesc-list {
  list-style-type: none;
  position: relative;
  margin-left: 362px;
  margin-bottom: 0px;
}
ul.dealss-seconddesc-list li::before {
  content: url("../../assets/images/dashboard/analytics-tick.png");
  position: absolute;
  left: 20px;
  opacity: 0.5;
}
.deals-description-newchart img {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.deals-description-newchart {
  border-radius: 5px;
  padding: 30px 0px 20px 0px;
  position: relative;
}
ul.dealss-seconddesc-list li {
  font-size: 13px;
  line-height: 34px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-left: 8px;

}
ul.dealss-seconddesc-list h5 {
  font-size: 14px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-left: -12px;
}
ul.dealss-desc-list h5 {
  font-size: 14px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-left: 0px;
}
ul.dealss-desc-list li {
  font-size: 13px;
  line-height: 34px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-left: 18px;
}
.deals-terms-popup-head{
  padding: 27px;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .dealss-seconddesc-list {
    margin-left: 200px;
  }
}
@media (min-width: 1201px) and (max-width: 1400px) {
  .dealss-seconddesc-list {
    margin-left: 100px;
  }
}
@media (max-width: 1200px) {
  .deals-status{
    font-size: 14px;
  }
  .mob-disableddeals {
    pointer-events: none;
    opacity: 0.4;
  }
  .mob-disableddeals .mob-disableddealss {
    background: #f8fafe;
    border: 1px dashed #e3e6f1;
  }
  .redeem-mob-button {
    display: block;
    display: flex;
    align-items: center;
    border: 1px solid #3064f9;
    justify-content: center;
    margin: 0 15px 15px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .redeem-mob-button a {
    font-size: 16px !important;
    color: #3064f9 !important;
    margin-bottom: 0px !important;
    margin: 0px 0px 0px 5.5px !important
  }
  .mob-deals {
    display: grid;
    background: white;
    border-radius: 8px;
  }
  .deals-mob-head {
    display: block !important;
    font-size: 20px;
    font-family: "Axiforma-Bold";
    margin: 19px 0 15px 15px;
    color: #1b2952;
  }
  .web-delas {
    display: none;
  }
  .Deals_header {
    display: none;
  }
  .billings-deal-container {
    /* margin-top: 15px; */
    position: relative;
  }
  .billing_nodataimg {
    padding: 0px 15px 0px 15px;

    margin-bottom: 15px;
  }
  .delas-mob-delashead {
    border-bottom: 2px dotted rgba(200, 206, 219, 0.5);
    height: 125px;
    padding: 30px 10px 20px 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .down-mob-border {
    top: 115px;
    right: -8px;
    padding: 10px 10px;
    background-color: #f8fafe;
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 3px 3px 3px rgba(188, 193, 206, 0.2);
    transform: rotate(318deg);
  }
  .up-mob-border {
    top: 115px;
    left: -8px;
    -webkit-transform: rotate(90deg);
    padding: 10px 10px;
    background-color: #f8fafe;
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 3px 3px 3px rgba(188, 193, 206, 0.2);
    transform: rotate(149deg);
  }
  .billing-dealsbackground {
    height: 125px;
    margin-top: 0px !important;
  }
  .delas-mob-title h5 {
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #bcc1ce;
    margin-bottom: 8px;
  }
  .delas-mob-title h3 {
    font-size: 16px;
    color: #1b2952;
    font-family: "Axiforma-Bold";
    margin-bottom: 7px;
  }
  .delas-mob-title .deal-activation-date {
    font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    margin-bottom: 0px;
  }
  .deals-mob-bottomdiv {
    padding: 30px 20px 17px 20px;
  }
  .deals-img img {
    width: 51px;
  }
  .deals-mob-count h5 {
    font-size: 13px;
    color: #1b2952;
    font-family: "Axiforma-regular";
    margin-bottom: 0px;
  }
  .deals-expiry-date h5 {
    font-size: 12px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    margin-bottom: 30px;
    margin-top: 3px;
  }

  .deals-coupon-mob-head {
    align-items: center !important;
    display: flex !important;
    margin-bottom: 20px;
    background: #f9fffb;
    border: 1px dashed #47ce70;
  }
  .deals-coupon-code-text {
    font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #1b2952;
    margin-bottom: 0px;
    margin-right: 10px;
    justify-content: center;
  }

  .crossmodal-deals {
    position: absolute;
    right: 20px;
    top: 41px;
    cursor: pointer;
  }
  .deals-updateappmod {
    align-items: center;
    max-height: 100% !important;
    padding: 0px !important;
  }
  .deals-descriptions-chart {
    flex-direction: column;
  }
  .deals-desc-heading {
    font-size: 18px;
    font-family: "Axiforma-Bold";
    margin-top: 0px;
    margin-bottom: 22px;
    color: #1b2952;
  }
  .dealss-desc-heading {
    font-size: 18px;
    font-family: "Axiforma-Bold";
    margin-top: 40px;
    margin-bottom: 22px;
    color: #1b2952;
  }
  .deals-desc-list li {
    font-size: 14px;
    font-family: "Axiforma-Regular";
    padding-left: 20px;
    line-height: 25px;
    color: #7782a1;
  }
  .deals-desc-list {
    padding-left: 20px;
    list-style-type: none;
  }
  ul.deals-desc-list li:not(:last-child) {
    margin-bottom: 12px;
  }
  ul.deals-desc-list li::before {
    content: url("../../assets/images/dashboard/analytics-tick.png");
    position: absolute;
    left: 20px;
    opacity: 0.5;
  }
  .dealsss-desc-heading {
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin-top: 30px;
    margin-bottom: 21px;
    color: #1b2952;
  }

  .dealss-desc-list li {
    font-size: 13px;
    font-family: "Axiforma-Regular";
    padding-left: 20px;
    color: #7782a1;
  }
  ul.dealss-desc-list li:not(:last-child) {
    margin-bottom: 12px;
  }
  .dealss-desc-list {
    padding-left: 20px;
  }
  ul.dealss-desc-list li::before {
    content: url("../../assets/images/dashboard/analytics-tick.png");
    position: absolute;
    left: 20px;
  }
}
