.logs-head{
    font-size: 24px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    line-height: 24px;
    margin-bottom: 0px;
}
.logs-head .productsetting-tooltip{
    margin-left: 6px;
}
.log-main-page{
    min-height: 80vh;
    background-color: #fff;
    border-radius: 6px!important;
    border: 1px solid #e3e6f1!important;
    box-shadow: 0 2px 6px rgb(0 0 0/6%)!important;
    margin-top: 20px;
    position: relative;
}
.modal-data {
    width: 360px;
    margin: auto;
    word-break: break-all;
}

.modal-heading-text {
    margin-left: 0;
}

.modal-error-box {
    padding-right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-right: 20px;
}
.modal-error-box svg {
    margin-bottom: 0;
    width: 24px;
    height: 24px;
}
a {
    cursor: pointer;
}
.heading-container {
    display: flex;
}

.alf {
    align-items: flex-start;
}
.errorlistalt p {
    color: #000!important;
    margin-bottom: 0px;
    font-size: 14px;
}
.errorlistalt {
    background: #F6F9FF;
}
/* tbody>tr:nth-child(even)>td, 
tbody>tr:nth-child(even)>th {
   background-color: #F6F9FF;
 } */
 .closebutton {
    line-height: 0.8;
    padding-right: 20px;
    font-size: 28px;
    font-weight: 100;
    color: #7782A1;
    cursor: pointer;
}
 .logimage {
     padding-top: 15px;
 }
 .logtext {
     padding-top: 57px;
     color: #1B2952;
 }
 .log-subtext {
     padding-top: 5px;
     color: #7782A1;
     text-align: center;
 }
 .submenu {
     flex-flow: wrap;
     display: inline-flex;
     width: 100%;
     margin-bottom: 8px;
 }
 .minor {
     width: auto;
 }
 .logsTable {
    display: block;
}
.new-heading {
    flex-flow: wrap;
}
.smalldropdown {
    height: 100%;
    display: flex;
    align-items: center;
}
.smalldropdown .logfirebasesvg{
 margin-right: 10px;
 width: 115px;
    gap: 6px;
    position: relative;
    height: 40px;
    border-radius: 3px;
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), -3px -3px 3px 0px #ffff;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #1B2952;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
}
.logfirebase-text{
    margin-bottom: 0px;
}
.smalldropdown .search-dropdown .dropdown-toggle{
    height: 40px !important;
    width: 160px !important;
    background-color: white !important;
    margin-bottom: 0px ;
    line-height: 13px;
    background-position: 90% !important;
}
.mob-logdropdown .search-dropdown .dropdown-toggle{
    background-color: white !important;
   
    line-height: 13px;

}
.second-heading {
    white-space: nowrap;
}
.logsTable {
    font-size: 14px;
}
.Clogstable {
    font-size: 16px;
}
.logsTable thead {
    display: none;
}
.logsTable thead th {
    white-space: nowrap;
}
.logsTable tr {
    display: flex;
    flex-flow: wrap;
    padding: 12px 0;
}
.slowlink {
    display: block;
    margin: 12px 0 9px 33px;
    text-align: center;
    border-radius: 10px;
    font: 12px Proxima-Regular;
    background-color: #FFFFFF;
    color: #C8CEDB;
    border: 1px solid #C8CEDB;
}
/* .logsTable tbody tr {
    height: 82px;
} */
.logsTable thead tr {
    font-family: 'Axiforma-Regular' !important;
    font-size: 12px !important;
    color: #BCC1CE !important;
    border-bottom: 1px solid #F1F2F8;
}
.logsTable thead tr th {
    font-weight: 400 !important;
}
.logsTable tbody tr:nth-child(odd) {
    background-color: #F8FAFE !important;
}
.logsTable tbody tr:nth-child(even) {
    background-color: #FFF !important;
}
.logsTable tr:nth-child(2) {
    padding-top: 10px;
}
.logsTable tr td {
    width: 50%;
    padding: 2px 12px;
    vertical-align: middle;
}
td p {
    margin-bottom: 0;
    padding-left: 22px;
}
.logsTable tr td:nth-child(1) {
    padding: 14.5px 12px 14.5px 20px;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
}
.logsTable tr td:nth-child(2) {
    text-align: left;
    padding: 12px 12px 12px 70px;
}
.logsTable tr th:nth-child(1) {
    padding: 12px 10px 12px 20px;
}
.logsTable tr th:nth-child(2) {
    padding-left: 70px;
}

.logsTable tr td:nth-child(3) {
    width: auto;
    padding: 12px;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
}
.logsTable tr td:nth-child(4) {
    width: 100%;
    padding: 12px;
    word-break: break-word;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
}
.logsTable tr td:nth-child(5) {
    text-align: right;
    width: 100%;
    padding-right: 32px;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
}
.logsTable tr td:first-child {
    white-space: nowrap;
}
.bottomSpinner {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.right_panel.right_panel_logs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: 100% !important;
}
.mob-logdropdown{
    display: none;    
}
.logmob-header-td{
    display: none;
}
.log-datediv{
    margin-left: 6px
      }

      .log-popup-tech{
        padding: 13px;
        border-radius: 8px;
        border: 1px solid #C8CEDB;
        position: relative;
        display: flex;
        margin-right: 20px;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
    }
    .log-error-exclamation{
        position: absolute;
        bottom: -4px;
        right: -10px;
    }
    .log-mod-errorhead{
        font-size: 18px;
        color: #1B2952;
        font-family: 'Axiforma-Bold';
    }
    .log-mod-title{
        font-size: 14px;
        color: #7782A1;
        font-family: 'Axiforma-Regular';
    }
    .log-spinner{
        border-radius: 6px !important;
      }
/* .submenu-logs, .logsTable-main {
    padding-left: 30px !important;
    padding-right: 30px !important;
} */

.log-error-key{
    background-color: #e3e6ed;
    border-radius: 3px;
}

@media (max-width: 1200px) {
    .smalldropdown .logfirebasesvg{
        margin-right: 0px;
        width: 44px;
        height: 44px;
        position: relative;
        border: 1px solid #E3E6F1;
        border-radius: 3px;
        background-color: #Ffffff;
        border: none;
        box-shadow: 0px 3px 6px 0px #BCC1CE33;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
       }
       .logfirebase-text{
           display: none;
       }
    .mob-dropdown-log{
        z-index: 1;
        left: 1px;
    }
    .mob-dropdown-log .billing-mob-select-div-user{
        font-size: 13px;
    color: #1b2952;
    font-family: 'Axiforma-Regular';
    cursor: pointer;
    }
    .log-main-page{
        min-height: 80vh;
        background-color: unset;
        border-radius: unset!important;
        border: unset!important;
        box-shadow: unset!important;
        margin-top: unset;
    }
    .logsTable tbody tr:nth-child(odd) {
        background-color: #FFF !important;
    }
    .logsTable tbody tr{
        margin-bottom: 15px;
        border: 1px solid #e3e6f1!important;
        box-shadow: 0 2px 6px rgb(0 0 0/6%)!important;
        border-radius: 6px;
    }
    .logsTable tr td:nth-child(5){
        text-align: left;
        padding: 0px 15px 20px;
    font-size: 12px;
    color: #3064F9;
    font-family: 'Axiforma-Regular';
    }
    .logsTable tr td:nth-child(2) {
        text-align: right;
    }
    .logsTable tr td:nth-child(1) {
        width: 50%;
    border-bottom: 1px solid #F1F2F8;
    padding: 24px 5px 24px 15px;
    font-size: 14px;
    line-height: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    } 
    .logsTable tr td:nth-child(1) svg{
        display: none;
    }
    .logsTable tr td:nth-child(2) {
        width: 50%;
   
    border-bottom: 1px solid #F1F2F8;
    padding: 24px 15px 24px 5px;
    align-items: center;
    display: flex;
    justify-content: end;
    }
    .logsTable tr td:nth-child(3){
        width: 100%;
    border-bottom: 1px solid #F1F2F8;
    padding: 21px 15px 24px 15px;
    font-size: 14px;
    line-height: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    }
    .logsTable tr td:nth-child(4){
        width: 100%;
        max-width: unset;
        font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    padding: 21px 15px 10px;
    }
    .smalldropdown .profile-role-dropdown--is-disabled{
  display: none;
    }
    .mob-logdropdown{
display: block;
margin: 15px 0px;
    }
    .right-panel-gap.mob-log-padding{
        padding: 20px 15px 0px !important;
    }
    .logsTable tr {
        padding: 0px;
    }
    .logmob-header-td{
        display: block;
        color: #BCC1CE;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    line-height: 12px;
    margin-bottom: 10px;
    }
    .log-datediv{
  margin-left: 0px
    }
    .heading-container {
        display: grid;
    }
    .modal-heading-text{
        margin-top: 20px;
    }
  .log-mod-errorhead {
    font-size: 20px;
  }
  .log-spinner{
    border-radius: 6px!important;
    border: 1px solid #e3e6f1!important;
    box-shadow: 0 2px 6px rgb(0 0 0/6%)!important;
  }
}





 @media screen and (min-width:1200px) {
    .modal-data {
        width: auto;
    }
     .logsTable {
        display: table;
        table-layout: fixed;
    }
    .logsTable thead {
        display: table-header-group;
    }
    .logsTable tr {
        display: table-row;
        padding: 0;
        border-bottom: 1px solid #F1F2F8;
    }
    .logsTable tr td {
        width: auto;
        padding: 12px;
    }
    .logsTable tr td svg {
        width: 24px;
        height: 24px;
    }
    .slowlink {
        display: inline;
        margin: 12px 0 9px 14px;
        text-align: center;
        border-radius: 10px;
        font: 12px Proxima-Regular;
        background-color: #FFFFFF;
        color: #C8CEDB;
        border: 1px solid #C8CEDB;
    }
    .logsTable tr .cbox {
        align-items: center;
    }
    .cbox2 p {
        color: red !important;
    }
    /* .logsTable tr td:nth-child(2) {
        text-align: left;
    } */
    .Clogstable.logsTable tr td:nth-child(2) {
        width: 15%;
    }
    /* .logsTable tr td:nth-child(3) {
        width: auto;
        font-size: 14px;
        padding-bottom: 12px;
    } */
    .Clogstable.logsTable tr td:nth-child(3) {
        width: 25%;
    }
    .Clogstable.logsTable tr td:nth-child(3) a {
        justify-content: center;
    }
    .logsTable tr td:nth-child(4) {
        width: 100%;
        padding-left: 12px;
        word-break: break-word;

    }
    /* .logsTable tr td:nth-child(5) {
        text-align: right;
        width: 100%;
        padding-left: 12px;
    } */
    .logsTable tr td .clogsf {
        font-size: 16px;
    }
    .logsTable tr:nth-child(even) {
        background-color: transparent;
    }
    /* .logsTable tr td:nth-child(3) {
        padding-left: 0.75rem;
    } */
    td p {
        padding-left: 0;
    }
    .closebutton {
        padding-right: 0;
        margin-right: 14px;
        font-size: 26px;
        font-weight: 100;
        color: #7782A1;
        cursor: pointer;
    }
    .specialtext {
        font-size: 20px !important;
    }
    .modal-error-box svg {
        width: 28px;
        height: 28px;
    }
}
