/* @font-face {
  font-family: "Poppins-SemiBold";
  src: url(../../assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins-Light";
  src: url(../../assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
} */

.bottombarbg {
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
display: flex;
}
.bottombarbg-spinner-div{
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  min-height: 78vh;
  overflow: hidden;
  position: relative;
  width: 100%;

}
.new-bottombar .draggable-container,
.disabled-bottombar .draggable-container {
  top: auto !important;
  left: 0px !important;
  animation: theshadow 2s;
  position: relative !important;
}
.accordionItem {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6f1;
  padding: 0px 0px;
}
.bottombar-page .accordionItemHeading {
  cursor: pointer;
  padding: 34px 0px 26px 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  justify-content: flex-start !important;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}
.bottombar-page .accordionItemHeading:before {
  content: url("../../../assets/images/dashboard/bottom-bar/dots.png");
  margin-right: 10px;
  cursor: move;
  margin-top: 0px;
  z-index: 0;
  opacity: 0;
  position: absolute;
  left: -6px;
}
.disabled-bottombar .accordionItemHeading:hover.accordionItemHeading:before {
 opacity: 0 !important;
}
.bottombar-page .accordionItemHeading:hover.accordionItemHeading:before {
  opacity: 1;
}
.bottombar-page .non-draggable:hover.accordionItemHeading:before {
  opacity: 0 !important;
  cursor: pointer;
}
.bottombar-default {
  position: absolute;
  right: 40px;
  top: 36px;
}
.bottombar-default p {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #bcc1ce;
  margin-bottom: 0px;
}
.bottombar-page .accordionItemHeading .accordian-icon-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bottombar-page
  .accordionItemHeading
  .accordian-icon-div
  .accordian-icon-div-img {
  position: absolute;
  top: 30px;
  right: 0px;
  transition: 0.3s;
}
.accordian-icon-div-img-rotate-up{
  transform: rotate(270deg);
}
.accordian-icon-div-img-rotate-down{
  transform: rotate(90deg);
}
.bottombar-width {
  display: flex;
}
.bottombar-left {
  width: 50%;
  min-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
}
.bottombar-right {
  width: 50%;
  min-width: 50%;
}
.bottombar-preview-right {
  padding: 54px 30px 60px 22px;
  border-left: none;
}
.bottombar-preview-screen {
  height: 659px;
  width: 310px;
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  overflow-y: auto;
  overflow-x: hidden;
  border: 4px solid #e3e6f1;
  border-radius: 20px;
  background-image: url(../../../assets/images/dashboard/bottombar-newcanvas.png);
  background-size: auto;
  /* background-position: 0px 30px; */
  position: relative;
  background-color: #EEEEEE;
}
.bottombar-bottom-menu .bottombar-theme .color_input .fixback {
  bottom: 0px;
}
.bottombar-master-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e6f1;
  margin-top: 30px;
  padding-bottom: 28px;
  margin-left: 10px;
}
.bottombar-master-toggle h4 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin: 0px;
}
.accordian-item-name {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin: 4px 0px 12px 0px;
}
.new-bottombar .accordionItemContent p,
.disabled-bottombar .accordionItemContent p {
  margin-bottom: 0px;
  /* word-break: break-word; */
  /* width: 90%; */
  margin-bottom: 0px;
  text-align: left !important;
}
.set-default-text {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px !important;
  margin-bottom: 0;
  line-height: 17px;
}
.accordian-item-name-input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  width: 100%;
  padding: 0px 15px 0px 15px;
  transition: 0.5s;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.new-bottombar .accordionItemContent .accordian-item-name-input:hover,
.new-bottombar .accordionItemContent .accordian-item-name-input:focus {
  outline: none;
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.new-bottombar .accordionItemContent,
.disabled-bottombar .accordionItemContent {
  padding-bottom: 30px;
  padding-left: 10px;
}
.bottombar-gap {
  margin-top: 17px !important;
  margin-bottom: 3px;

}
.bottombar-gap2 {
  margin-top: 30px !important;
}
.new-bottombar .search-dropdown .dropdown-toggle {
  padding: 0px 15px 0px 15px;
  position: relative;
}
.bottombar-filterby-post .search-dropdown-before {
  position: relative;
  top: 1px;
}
.new-bottombar .dropdown-toggle::after,
.disabled-bottombar .dropdown-toggle::after {
  display: none;
}
.bottombar-preview-head {
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-top: -5px;
  display: none;
}
.the-bottombar {
  min-height: 44px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 20px 0px;
}
.bottombar-module h1 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.bottombar-page-link {
  width: 130px;
  height: 50px;
  line-height: 46px;
  border-top: 1px solid #bcc1ce;
  border-bottom: 1px solid #bcc1ce;
  border-left: 1px solid #bcc1ce;
  border-right: none;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-left: 12px !important;
}
.bottombar-page-link span {
  font-family: "Axiforma-Regular2";
  font-size: 13px;
}
.bb-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  top: -4px;
}
.bottombar-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  border-top: 1px solid #74a4ff;
}
.bottombar-bottom .bottombar-options {
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
}
.bottombar-bottom .bottombar-options img {
  margin: auto;
  max-width: 16px;
  max-height: 16px;
  margin-top: 0px;
}
.bottombar-bottom-text {
  font-family: "Axiforma-Regular";
  font-size: 9px;
  margin: 4px 0px 4px 0px;
}
.bottombar-expand {
  padding: 32px 8px 22px 9px;
  position: relative;
  max-height: 558px;
  overflow-y: auto;
}
.bottombar-expand::-webkit-scrollbar {
  width: 0px !important;
}
.bottombar-expand svg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 8px;
}
.bottombar-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
}
.bottombar-tooltip .bottombar-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.bottombar-tooltip .bottombar-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.bottombar-tooltip:hover .bottombar-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.bottombar-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.bottombar-head-left a,
.bottombar-head-left a:hover {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  color: #7782a1;
  margin-right: 60px;
  padding-bottom: 10px;
  &:hover {
    color: var(--custom_color4) !important;
    border-bottom: 2px solid var(--custom_color5) !important;
  }
  &:active {
    color: var(--custom_color4);
    border-bottom: 2px solid var(--custom_color5);
  }
}
.bottombar-head-left a.active,
.bottombar-head-left a.active:hover {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  color: #3064f9;
  border-bottom: 2px solid #3064f9;
  &:hover {
    color: var(--custom_color4) !important;
    /* border: 1px solid var(--custom_color6)!important; */
  }
  &:active {
    color: var(--custom_color4);
    border-bottom: 2px solid var(--custom_color5);
  }
}
.bottombar-head-right {
  display: flex;
  align-items: center;
}
.bottombar-save-btn {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  color: white;
  background-color: #3064f9;
  border: none;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  line-height: 40px;
  transition: 0.5s;
  &:hover {
    background-color: var(--custom_color5) !important;
    color: var(--custom_color4) !important;
    border: 1px solid var(--custom_color6) !important;
  }
}
.bottombar-save-btn:hover {
  /* background-color: #134bec; */
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.bottombar-save-disable,
.bottombar-save-disable:hover {
  background-color: #bcc1ce !important;
  cursor: not-allowed !important;
  box-shadow: none;
}
.bottombar-settings {
  width: 50%;
  min-width: 50%;
  padding-left: 30px;
  padding-right: 20px;
}
.bottombar-settings-head {
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #e3e6f1;
}
.bottombar-settings-title {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1b2952;
  margin-bottom: 7px;
}
.bottombar-settings-description {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 0px;
}
.bottombar-theme {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bottombar-theme-options .color_input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: 0.5s !important;
  position: relative;
 
}
.bottombar-setting-label {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 9px;
}
.bottombar-theme-options .color_input p {
  font-size: 13px;
  color: #1b2952;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.bottombar-theme-options {
  width: 48%;
}
.bottombar-theme-options-div{
  width: 49% !important;
}
/* .bottombar-theme-options-div:nth-of-type(odd) .color_input{
  margin-right: 20px;
} */
.bottombar-setting-one-title {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  color: #1b2952;
  margin: 33px 0px 14px;
}
.bottombar-settings-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
}
.bottombar-setting-toggle-label {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin: 0px;
  display: flex;
}
.bottombar-bottom-menu {
  border-top: 1px solid #e3e6f1;
  margin-top: 30px;
}
.bottombar-setting-menu-title {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  color: #1b2952;
  margin: 30px 0px 25px;
}
.bottombar-bottom-menu input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  width: 100%;
  padding: 0px 15px;
  transition: 0.5s;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.bottombar-bottom-menu .image-uploader p {
  margin-bottom: 0px;
}
.bottombar-bottom-menu input:hover,
.bottombar-bottom-menu input:focus {
  outline: none;
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.bottombar-bottom-menu h6 {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin: 0px 0px 9px 0px;
  display: flex;
  align-items: center;
}
.bottombar-shape {
  display: flex;
  margin: 0px !important;
}
.bottombar-shape label {
  margin: 0px !important;
}
.bottombar-shape.select-headings-ratio div {
  height: auto;
}
.bottombar-shape .form-group {
  margin: 0px 0px 0px 20px !important;
  cursor: pointer;
}
.bottombar-shape .form-group label {
  cursor: pointer;
}
.bottombar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottombar-footer .agree-terms .checkmark:after {
  top: 3px;
}
.bottombar-tile-align {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  overflow: hidden;
}
.bottombar-tiles .bottombar-tile-align div img {
  margin: auto;
  margin-top: 6px;
}
.bottombar-tile-text-name {
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.bottombar-tiles {
  display: flex;
  flex-wrap: wrap;
}
.bottombar-list div {
  display: flex;
  align-items: center;
  margin: 0px 6px 26px 6px;
}
.bottombar-list div:last-child {
  margin: 0px 6px 0px 6px;
}
.bottombar-list div img {
  max-width: 18px;
  max-height: 18px;
  margin-right: 12px;
}
.bottombar-list div p {
  font-size: 11px;
  line-height: 11px;
  font-family: "Axiforma-Regular";
  color: #333333;
  margin: 0px;
}
.bbsetting-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px !important;
  margin-left: 6px !important;
}
.bbsetting-tooltip .bbsetting-tooltipsubheader {
  visibility: hidden;
  width: 250px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.bbsetting-tooltip .bbsetting-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.bbsetting-tooltip:hover .bbsetting-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}


.bottombar-head-right p {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin: 0px 20px 0px 0px;
}

.loader-duration {
  position: relative;
}
.loader-duration h5 {
  position: absolute;
  right: 55px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  top: 45px;
}
.bottombar-mobile-btns {
  display: none;
}

.disabled-bottombar .draggable-container {
  cursor: not-allowed;
}
.disabled-bottombar .accordionItemHeading {
  color: #bcc1ce;
  cursor: not-allowed;
}

.disabled-bottombar .bb-icon {
  opacity: 0.3;
}
.new-bottombar .duplicate-delete span,
.disabled-bottombar .duplicate-delete span {
  margin-left: 30px;
  cursor: pointer;
}
.disabled-bottombar .accordionItemContent {
  opacity: 0.3;
}
input[type="number"] {
  position: relative;
}
/* Spin Buttons modified */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: #fff url(../../../assets/images/dashboard/number-arrow.png) no-repeat
    center center;
  width: 2em;
  opacity: 0.5; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button:hover,
input[type="number"]::-webkit-inner-spin-button:active {
  opacity: 0.8;
}
/* Override browser form filling */
input:-webkit-autofill {
  background: black;
  color: red;
}
.add-bottom-bar-item {
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
  margin-top: 34px;
  margin-left: 10px;

}
.add-bottom-bar-item-icon {
  margin-right: 6px;
}
.image-uploader .uploadpixabayimage.afterupload {
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-left: 15px;
}
.bottombartint {
  /* background: rgba(0,0,0,.2); */
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: 2s;
  animation-timing-function: ease-in-out;
  z-index: 1;
  position: absolute;
  top: 0px;
}
.new-bottombar .search-dropdown .dropdown-toggle .new-blue-loader {
  position: absolute;
  top: 15px;
}
.bottombar-visiblity {
  position: absolute;
  right: 40px;
  top: 32px;
  stroke: #bcc1ce;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
  transition: 0.5s;
}
.new-bottombar .scroll-bottombar .accordionItem:hover .bottombar-visiblity {
  opacity: 1;
  transition: 0.5s;
}
.bottombar-section-hidden .bottombar-visiblity,
.bottombar-section-hidden:hover .bottombar-visiblity {
  stroke: #1b2952;
  opacity: 1;
}
.bottombar-theme-options-disabled .color_input {
  background-color: #f8fafe;
  cursor: not-allowed;
  border: 1px solid #e3e6f1;
}
.bottombar-theme-options-disabled .color_input div {
  cursor: not-allowed !important;
}
.bottombar-theme-options-disabled .colorbox {
  border: 1px solid #e3e6f1 !important;
}
.bottombar-theme-options-disabled .color_input p {
  color: #bcc1ce !important;
}

/* Multiple tiles icon and font size */
.bottombar-onetiles-text {
  margin-top: 14px;
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.bottombar-twotiles-text {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.bottombar-threetiles-text {
  margin-top: 13px;
  margin-bottom: 0px;
  font-size: 9px;
  font-family: "Axiforma-Regular";
}
.bottombar-fourtiles-text {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 8px;
  font-family: "Axiforma-Regular";
}

.mob-bottombar-footer-div {
  display: none;
}
.bottombar-mob-btn{
  display: none;
 } 
 .bottomBar-haptic-div{
  border-top: 1px solid #e3e6f1;
  margin-top: 30px;
 }

@media screen and (min-width: 2100px) {
  .bb-icon {
    top: 0px;
  }
  .search-dropdown .dropdown-toggle {
    background-position: 98% !important;
  }
}

@media screen and (max-width: 1200px) {
  .new-bottombar .accordionItemContent,
.disabled-bottombar .accordionItemContent {
  padding-left: 0px;
}
.bottombar-page .accordionItemHeading:before {
  left: 0px;
}
.bottombar-page .accordionItemHeading {
  padding: 34px 0px 26px 20px;
}
  .bottombar-theme-options-div {
    width: 100% !important;
}
.bottombar-mob-btn{
 
    display: block;
    padding: 5px 0px 0px 0px;

 } 
.bottombar-gap {
  margin-top: 17px !important;
  margin-bottom: 4px;
}
  .mob-bottombar-footer-div {
    width: 100%;
    padding: 0px 15px 20px;
    display: block;
  }
  .bottombarbg-spinner-div {
    margin: 0px;
    min-height: 84vh;
  }
  .bottombar-footer-save-btn {
    width: 100%;
    height: 50px;
    border: unset;
    font-family: "Axiforma-Regular";
    color: #ffffff;
    background-color: #3064f9;
    border-radius: 3px;
    margin-bottom: 15px;
  }
  .bottombar-right {
    display: none;
  }
  .bottombar-left {
    width: 100%;
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
 
  .bottombar-setting-one {
    padding: 0px 15px 15px;
  }
  .bottombar-bottom-menu {
    margin: 30px 15px;
  }
  .bottomBar-haptic-div {
    margin: 30px 15px;
  }
  .bottombar-settings {
    width: 100%;
    min-width: 100%;
    padding: 0px;
  }
  .bottombarbg {
    padding-bottom: 15px;
    margin: 0px;
  }
  .bottombar-width {
    padding: 15px;
  }
  .bottombar-theme-options {
    width: 100%;
  }
  .bottombar-settings-head {
    padding: 20px 15px 20px 15px;
  }
  .bottombar-theme .bottombar-theme-options {
    margin-top: 17px;
  }
  .bottombar-shape div {
    height: auto !important;
  }
  .bottombar-tooltip .bottombar-tooltipsubheader {
    width: 200px !important;
    top: 32px !important;
    left: -10px !important;
  }
  .bottombar-tooltip .bottombar-tooltipsubheader::after {
    top: -8px !important;
    right: 175px !important;
    transform: rotate(90deg) !important;
  }
  .bbsetting-tooltip .bbsetting-tooltipsubheader {
    width: 165px !important;
    top: 27px !important;
    left: -5px !important;
  }
  .bbsetting-tooltip .bbsetting-tooltipsubheader::after {
    top: -7px !important;
    right: 148px !important;
    transform: rotate(90deg) !important;
  }
  .bottombar-mobile-btns {
    display: block;
  }
  .bottombar-mobile-btns {
    margin: 15px;
  }
  .bottombar-mobile-btns-blue {
    border: 1px solid #3064f9;
    height: 50px;
    background-color: #3064f9;
    border-radius: 3px;
    color: #fff;
    width: 100%;
    transition: 0.5s;
    position: relative;
    margin-top: 20px;
    text-align: center;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottombar-mobile-btns h5 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    text-align: center;
    margin-top: 17px;
    width: 100%;
  }
  .bottombar-head .bottombar-head-right {
    display: none;
  }
  .bottombar-head {
    margin: 19px 0px 18px 15px;
  }
  .bottombar-module h1 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .bottombar-tooltip {
    margin-bottom: 0px;
  }
  .bottombar-head-left {
    display: none;
  }
  .bb-settings-option {
    display: flex !important;
  }
  .bb-settings-option button {
    display: none !important;
  }
  .bottombar-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 15px 0px 15px;
  }
  .bottombar-parent .bottombar-head {
    margin: 0px !important;
  }
  .bottombar-module .bottombarbg {
    padding-bottom: 0px;
  }
  .bottombar-master-toggle {
    padding: 0px 15px 27px 15px;
    margin-left: 0px;
  }
  .bottombar-page .accordionItem {
    padding: 0px 15px 0px 15px;
  }
  .bottombar-page .accordionItemHeading.accordionItemHeading:before {
    opacity: 1;
  }
  .newbottomvar h4 {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 20px;
  }
  .bottombar-footer .agree-terms {
    margin-bottom: 0px;
  }
  .bottombar-footer .duplicate-delete {
    margin-top: 12px;
  }
  .bottombar-head-right p {
    display: none;
  }
  .bottombar-defaultpopup .modal-body {
    padding: 23vh 0px 0px 0px !important;
  }
  .bottombar-defaultpopup .modal-body .generalstngs2-ok {
    width: 80%;
    max-width: 500px;
    margin-left: 0px;
  }
}
