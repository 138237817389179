.homescreenhead {
  padding: 20px 30px;
  border-bottom: 1px solid #e3e6f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homescreenhead h1 {
  font-family: "Axiforma-Bold";
  font-size: 20px;
  line-height: 27px;
  color: #1b2952;
  margin-bottom: 4px;
}
.homescreenhead p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17px;
  color: #7782a1;
  margin-bottom: 0px;
}
.homescreenhead p a {
  font-family: "Axiforma-Regular";
  color: #3064f9;
}
.createhomescreen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.createhomescreen div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.createhomescreen div input {
  width: 290px;
  height: 40px;
  border: 1px solid #e3e6f1;
  padding: 0px 17px;
  font-size: 13px;
  border-radius: 2px 0px 0px 2px;
  border-right: none;
}
.createhomescreen div input:focus,
.createhomescreen div input:focus-visible {
  border: 1px solid #e3e6f1;
  outline: none;
}
.createhomescreen .createnew {
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
}
.createhomescreen .createnew:hover {
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
  color: #fff;
  transition: 0.5s;
}
.createhomescreen .saveasdraft {
  background-color: #fff;
  color: #3064f9;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: 0.5s;
  font-size: 14px;
  padding: 9px 22px 10px 22px;
  margin-right: 20px;
}
.createhomescreen .backbutton {
  background-color: #fff;
  color: #7782a1;
  border: 1px solid #bcc1ce;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: 0.5s;
  font-size: 14px;
  padding: 9px 33px 10px 33px;
  margin-right: 20px;
}
.createhomescreen div input::placeholder {
  color: #c8cedb;
  opacity: 1;
}

.createhomescreen div input:-ms-input-placeholder {
  color: #c8cedb;
}

.createhomescreen div input::-ms-input-placeholder {
  color: #c8cedb;
}
.undosection {
  margin-right: 40px;
}
.undosection a:nth-child(1) {
  margin-right: 40px;
}
.home-preview-screen {
  height: 570px;
  width: 296px;
  background-color: #fff;
  margin: auto;
  filter: drop-shadow(0px 2px 12px rgba(188, 193, 206, 0.3));
  overflow-y: auto;
}
.home-preview-screen::-webkit-scrollbar {
  width: 0.1px !important;
}

.home-preview-screen::-webkit-scrollbar-track {
  background-color: rgba(256, 256, 256, 0.1) !important;
  border-radius: 9px !important;
}

.home-preview-screen::-webkit-scrollbar-thumb {
  background-color: rgba(256, 256, 256, 0.1) !important;
  border-radius: 9px !important;
}
.create-homescreen-preview {
  background-color: #f8fafe;
  min-height: 700px;
  padding: 30px;
  border: 30px solid #fff;
}

.homescreen-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 51px;
  border-bottom: 1px solid #e3e6f1;
  padding-right: 30px;
  padding-left: 30px;
}
.homescreen-options .addmoreblock,
.homescreen-options .addmoreblock:hover,
.homescreen-options .addmoreblock:focus {
  color: #3064f9 !important;
  font-family: "Axiforma-Regular" !important;
  font-size: 13px !important;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.homescreen-banner-tabs {
  display: flex;
  align-items: center;
}
.homescreen-banner-tabs li {
  width: 23%;
  margin-right: 19px;
}
.homescreen-banner-tabs li:last-child{
  margin-right: 0px;
}
.homescreen-banner-tabs li a {
  padding: 0px;
}
.homescreen-banner-tabs.nav-tabs {
  border: none !important;
}
.homescreen-banner-tabs .nav-link {
  border: none !important;
  border-radius: none !important;
  border: 2px solid #fff !important;
}
.homescreen-banner-tabs .nav-link.active {
  color: #3064f9 !important;
  border: 2px solid #80a0ff !important;
  position: relative;
}
.homescreen-banner-tabs .nav-link.active::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid #80a0ff;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}
.homescreen-banner-tabs.nav-tabs .nav-link:hover,
.homescreen-banner-tabs .nav-tabs .nav-link:focus {
  border: 2px solid #80a0ff !important;
  color: #3064f9 !important;
}
.homescreen-banner-tabs.nav-tabs .nav-item {
  margin-bottom: -7px;
  position: relative;
}
.homescreen-banner-tabs.nav-tabs .nav-item p{
  position: absolute;
  z-index: 9;
  right: 10px;
  top: 10px;
}
.homescreen-banner-tabs.nav-tabs .nav-item h4{
  position: absolute;
  right: 0;
  left: 0;
  top: -12px;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}
.homescreen-fields input,
.homescreen-fields input:focus-visible,
.homescreen-fields input:focus {
  height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #f8fafe;
  border: 1px solid #e3e6f1;
  padding: 20px;
  outline: none;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.select-img-ratio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #f8fafe;
  margin-bottom: 0px !important;
}
.select-img-ratio div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e3e6f1;
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin-bottom: 0px;
}
.select-img-ratio div:last-child {
  border-right: none;
}
.img-ratio > input:checked + div {
  background-color: #e3e6f1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.img-ratio > input {
  display: none;
}
.img-ratio > input + div svg {
  stroke: #bcc1ce !important;
}
.img-ratio > input + div svg {
  fill: #f8fafe !important;
}
.img-ratio > input:checked + div svg {
  stroke: #3064f9 !important;
}
.img-ratio > input:checked + div svg {
  fill: #e2eaff !important;
}
.select-img-ratio label {
  margin-bottom: 0px !important;
  width: 100%;
}

/* Heading CSS */
.heading-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #f8fafe;
  margin-top: 20px;
  margin-bottom: 0px !important;
}
.heading-options div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e3e6f1;
  width: 100%;
  height: 50px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
}
.heading-options div:last-child {
  border-right: none;
}
.heading-ratio > input:checked + div {
  background-color: #e3e6f1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.heading-ratio > input {
  display: none;
}
.heading-options label {
  margin-bottom: 0px !important;
  width: 100%;
}
/* Heading CSS */
.homescreen-delete-option {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 18px;
  padding-bottom: 30px;
}
.homescreen-delete-option p {
  color: #bcc1ce !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 55px;
  cursor: pointer;
}
.homescreen-delete-option p:nth-child(2) {
  color: #ff4848 !important;
}
.homescreen-delete-option p img {
  margin-right: 8px;
}
.set-full-img {
  width: 100%;
  margin-bottom: 0px;
}
.homescreen-uploader {
  border: 1px solid #e3e6f1;
  background-color: #f8fafe;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.homescreen-uploader .d-flex {
  width: calc(100% - 50px);
}
.homescreen-uploader .uploadpixabayimage {
  background-position: 50% !important;
  height: 43px;
  width: 43px;
  min-width: 43px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
}
.homescreen-uploader p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1b2952 !important;
  font-size: 13px !important;
}

/* select Block */

.select-banner-layout {
  display: flex;
  align-items: flex-start;
  border-radius: 3px;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.select-banner-layout .image {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  background-color: #ffffff;
}
.select-banner-layout .form-group {
  margin-right: 20px;
  margin-bottom: 20px;
  width: 23.3%;
}
.select-banner-layout div:last-child {
  border-right: none;
}
.select-banner-layout > input {
  display: none;
}
.select-banner-layout label {
  margin-bottom: 0px !important;
  width: 100%;
}
.select-banner-layout .image p {
  margin-top: 20px;
  color: #7782a1;
  font-size: 13px;
}
.select-banner-layout .image img {
  margin: auto;
}
.selectbanner-ratio input:checked + .image:before {
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
  content: url("../../../assets/images/dashboard/tick.png");
  display: block;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 400px;
  transition: 0.4s;
  z-index: 3;
  border: 2px solid #3064f9;
}

.selectbanner-ratio > input {
  display: none;
}
.bannerlayoutoptions .modal-footer {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1 !important;
}
.bannerlayoutoptions .modal-footer button:nth-child(1) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 13px;
  margin: 0px;
}
.bannerlayoutoptions .modal-footer button:nth-child(2) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #3064f9 !important;
  background-color: #3064f9 !important;
  color: #fff !important;
  font-size: 13px;
  margin: 0px;
}
.homescreen-section-name .catsection-name {
  margin-left: 10px;
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  z-index: 99;
  position: relative;
}
.homescreen-section-name .catsection-name svg{
  display: none;
  transition: .5s;
  margin-left: 5px;
}
.category-customize-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category-customize-layout div a {
  color: #7782a1;
  font-size: 13px;
  margin-left: 40px;
  font-family: "Axiforma-Regular";
}
.category-customize-layout div a svg {
  margin-right: 6.5px;
}
.input-box-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-box-checkbox {
  display: block;
  position: relative;
  padding-left: 31px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.homescreen-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
}
.homescreen-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.input-box-checkbox:hover input ~ .homescreen-checkmark {
  background-color: #ccc;
}
.input-box-checkbox input:checked ~ .homescreen-checkmark {
  background-color: #3064f9;
}
.input-box-checkbox input:checked ~ .homescreen-checkmark:after {
  display: block;
}
.input-box-checkbox .homescreen-checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.input-box-checkbox p {
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
}
.homescreen-fields .color_input {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #f8fafe;
  padding: 7px 7px 7px 4px;
  width: 100%;
  height: 50px;
}
.homescreen-color-manage{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #f8fafe;
  padding: 7px 7px 7px 4px;
  width: 100%;
  height: 50px;
}
.homescreen-color-manage svg{
  cursor: pointer;
  position: relative;
  right: 10px;
}
.homescreen-color-manage .color_input{
  border: none!important;
  border-radius:0px;
  background-color: transparent!important;
  padding: 0px!important;
  width: 100%;
  height: auto!important;
}
.homescreen-fields label {
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
}
.row-column-tabs-homescreen .nav-link {
  border: 1px solid #e3e6f1 !important;
  border-radius: 3px !important;
  background-color: #f8fafe;
  border-radius: 3px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  position: relative;
}
.row-column-tabs-homescreen .nav-link.active {
  border: 1px solid #e3e6f1 !important;
  border-radius: 3px !important;
  background-color: #e3e6f1 !important;
  border-radius: 3px;
}
.row-column-tabs-homescreen .nav-link svg {
  fill: #bcc1ce !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.row-column-tabs-homescreen .nav-link.active svg {
  fill: #7782a1 !important;
}
.homescreen-category-layout {
  background-color: #f8fafe !important;
  width: 70px !important;
  height: 40px !important;
  position: absolute;
  right: 0;
  top: -55px;
  border: 1px solid #e3e6f1;
  font-family: "Axiforma-Regular";
  padding: 12px 12px 12px 12px !important;
}
.category-slders-option {
  margin: 30px 0px;
}
.select-cat-popup {
  position: absolute;
  right: 36px;
  top: 15px;
}
.select-cat-popup img {
  margin-left: 20px;
}
.select-cat-popup img:nth-child(2) {
  cursor: pointer;
}
.hide-homecustomize-options {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}
.hide-homecustomize-options p {
  color: #7782a1 !important;
  font-size: 13px !important;
}
.hide-homecustomize-options svg {
  margin-right: 5px;
}
.homebanner-custom .heading-ratio > input + div svg path{
  fill: #bcc1ce !important;
}
.homebanner-custom .heading-ratio > input:checked + div svg path{
  fill: #3064f9!important;
}
.homescreen-allignment .heading-ratio > input + div svg{
  stroke: #7782a1 !important;
  fill: #7782a1 !important;
}
.homescreen-allignment .heading-ratio > input:checked + div svg{
  stroke: #3064f9 !important;
  fill: #3064f9 !important;
}

/* select Block */

.homescreen-fields .homescreensection {
  position: absolute;
  right: 60px;
  max-width: 150px;
  display: flex;
  align-items: center;
}
.homescreen-fields .accordionItem {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6f1;
  padding: 0px 30px;
}
.homescreen-fields .accordionItemHeading {
  cursor: pointer !important;
  padding: 30px 0px 30px 0px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  position: relative;
}
.accordionWrapper .close {
  color: #1b2952 !important;
  opacity: 1 !important;
}
.close .accordionItemContent {
  height: 0px;
  transition: height 1s ease-out;
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  float: left;
  display: block;
}
.homescreen-fields .accordionItemContent p {
  font-size: 14px;
  color: #000;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.accordionItemContent ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
}
.accordionItemContent p a,
.accordionItemContent p a:hover {
  font-size: 14px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  text-decoration: none;
}
.homescreen-fields .open .accordionItemContent {
  padding: 20px 0px;
  background-color: #fff;
  width: 100%;
  margin: 0px 0px 10px 0px;
  display: block;
  transition: transform 0.1s ease-in;
}
.homescreen-fields .open .accordionItemHeading {
  padding: 30px 0px 0px 0px;
  background-color: #fff;
  color: #000;
}
.homescreen-fields .accordionWrapper h5 {
  margin-top: 40px;
  color: #000;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}

.homebanner-custom h6 {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.banner-thumb-img {
  border: 1px dashed #e3e6f1;
  height: 135px;
}
.homescreen-color-input {
  /* border: 1px solid #e3e6f1; */
  background-color: #f8fafe;
  border-radius: 3px;
  height: 50px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.homebanner-custom select {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  background: #f8fafe url(../../../assets/images/dashboard/dark-grey.png);
  background-repeat: no-repeat;
  background-position: 96%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular2";
  width: 100%;
  outline: none !important;
  font-size: 14px;
}
.homescreen-edits {
  border-right: 1px solid #e3e6f1;
  padding-right: 0px !important;
}
.homescreen-preview-box {
  padding-left: 0px !important;
}
.sliderbanner .carousel .slider-wrapper {
  width: 100%;
}
.sliderbanner .slider {
  width: 100%;
  background-color: #fff;
}
.sliderbanner .carousel.carousel-slider {
  overflow: visible;
}
.sliderbanner .carousel .control-dots .dot {
  box-shadow: none;
  background-color: rgb(0 0 0 / 20%);
  width: 5px;
  height: 5px;
  margin: 0 5px;
}
.sliderbanner .carousel-status {
  display: none;
}
.homescreen-slide {
  position: relative;
  width: 100%;
}
.homescreen-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.homescreen-screen h4 {
  position: absolute;
  /* top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px; */
  margin: auto;
  height: max-content;
}
.category-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 9px;
}
.category-head h2 {
  color: #333333;
  font-size: 12px;
  font-family: "Poppins-Semibold";
  margin-bottom: 0px;
}
.home-category {
  padding: 0px 2px 2px 12px;
}
.home-cat-layout1 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}
.home-cat-layout1 .single-category {
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.home-cat-layout1 .single-category div {
  background-color: #f2f2f2;
  border: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-cat-layout1 .single-category p {
  color: #333333;
  font-size: 10px;
  font-family: "Poppins-Semibold";
  margin-bottom: 0px;
  word-break: break-word;
}
.home-cat-layout1 .single-category h4 {
  color: #333333;
  font-size: 10px;
  font-family: "Poppins-Semibold";
  margin-bottom: 0px;
  margin-top: 4px;
  word-break: break-word;
  z-index: 1;
}
.threexlayout .single-category {
  width: 29.6%;
}
.threexlayout .single-category div {
  width: 100%;
  height: 84px;
}
.twoxlayout .single-category {
  width: 46.3%;
}
.twoxlayout .single-category div {
  width: 100%;
  height: 131px;
}
.fourxlayout .single-category {
  width: 21.3%;
}
.fourxlayout .single-category div {
  width: 60px;
  height: 60px;
}
.onexlayout .single-category {
  width: 100%;
}
.onexlayout .single-category div {
  width: 100%;
  height: 272px;
}
.layoutlink {
  margin-bottom: 20px;
}
.layoutlink a,
.layoutlink a:hover {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.bannerlayoutoptions .modal-body {
  height: 520px;
  overflow: auto;
  background-color: #f8fafe;
  padding: 20px;
  padding-right: 0px;
}
.bannerlayoutoptions .modal-header {
  padding: 22px 20px 24px 20px;
  border-bottom: 1px solid #e3e6f1 !important;
}
.bannerlayoutoptions .modal-header h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.homescreenaddblock .modal-header {
  padding: 22px 20px 24px 20px;
  border-bottom: 1px solid #e3e6f1 !important;
}
.homescreenaddblock .modal-header h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.homescreenaddblock .modal-body {
  height: 520px;
  overflow: auto;
  background-color: #f8fafe;
  padding: 20px;
  padding-right: 0px;
}
.homescreenaddblock .modal-footer {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1 !important;
}
.homescreenaddblock .modal-footer button:nth-child(1) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 13px;
  margin: 0px;
}
.homescreenaddblock .modal-footer button:nth-child(2) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #3064f9 !important;
  background-color: #3064f9 !important;
  color: #fff !important;
  font-size: 13px;
  margin: 0px;
}
.select-homescreen-block {
  display: flex;
  align-items: flex-start;
  border-radius: 3px;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.select-homescreen-block .image {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  background-color: #ffffff;
  padding-top: 40px;
}
.select-homescreen-block .form-group {
  margin-right: 20px;
  margin-bottom: 20px;
  width: 137px;
}
.select-homescreen-block div:last-child {
  border-right: none;
}
.select-homescreen-block > input {
  display: none;
}
.select-homescreen-block label {
  margin-bottom: 0px !important;
  width: 100%;
}
.select-homescreen-block .image p {
  margin-top: 15px;
  color: #7782a1;
  font-size: 13px;
  height: 38px;
  margin-bottom: 12px;
}
.select-homescreen-block .image img {
  margin: auto;
}
.selecthometype input:checked + .image:before {
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
  content: url("../../../assets/images/dashboard/tick.png");
  display: block;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 140px;
  transition: 0.4s;
  z-index: 3;
  border: 2px solid #3064f9;
}
.selecthometype > input {
  display: none;
}
.homescreenaddblock .modal-body h4 {
  color: #1b2952;
  font-size: 12px;
  font-family: "Axiforma-Bold";
}
.second-cat-layout {
  display: block;
  margin-top: 10px;
}
.home-cat-layout2 {
  margin-bottom: 8px;
  margin-right: 10px;
}
.home-cat-layout2 p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 10px;
}
.home-cat-layout2 a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.singlecatarrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
}
.third-cat-layout {
  display: block;
  margin-top: 10px;
}
.home-cat-layout3 {
  margin-bottom: 8px;
  margin-right: 10px;
  justify-content: space-between;
}
.home-cat-layout3 p {
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 10px;
}
.home-cat-layout3 a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* New CSS */

.category-customize h6 {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.category-customize select {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  outline: none;
  font-size: 13px;
  background: #f8fafe url(../../../assets/images/dashboard/grey-dropdown.png);
  background-repeat: no-repeat;
  background-position: 98%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  cursor: pointer;
}
.category-shapes p {
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 13px;
}
.category-shapes .heading-ratio > input + div svg {
  stroke: #bcc1ce;
  fill: #f8fafe;
}
.category-shapes .heading-ratio > input:checked + div svg {
  stroke: #3064f9 !important;
  fill: #e2eaff !important;
}
.category-shapes .heading-ratio > input:checked + div p {
  color: #3064f9 !important;
}
.select-category div {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 15px;
  background-color: #f8fafe;
}
.select-category div a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.select-category div p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  width: calc(100% - 40px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;
}
.gallery-tabs {
  position: relative;
}

.image-cover-center {
  background-size: cover !important;
  background-position: center !important;
}
.products-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 6px 6px 6px;
  border-radius: 2px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.products-grid .single-product-one {
  width: 48%;
  padding-bottom: 16px;
  padding-top: 6.5px;
}
.single-product-one img {
  margin-top: 6px;
}
.products-grid .single-product-one::after {
  border-right: 1px solid #f5f5f5;
}
.product-discount {
  position: absolute;
  top: 0px;
  right: 6px;
}
.single-product-one div {
  position: relative;
}
.single-product-two {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 6.5px;
  margin-bottom: 6.5px;
  width: 100%;
}
.products-section {
  padding: 10px 10px 10px 10px;
}
.single-product-three {
  display: flex;
  align-items: flex-start;
}
.single-product-four {
  display: flex;
  overflow: hidden;
}
.slider-product-width {
  width: 116px;
  margin-right: 10px;
}
.homescreen-select-category .menu-builder-l2 .menubuilder-radio-btn input {
  position: absolute;
  right: 0;
}
.blogs-section h2 {
  color: #000000;
  font-size: 10px;
  font-family: "Axiforma-Regular";
}
.blogs-section p {
  color: #a1a1a1;
  font-size: 8px;
  font-family: "Axiforma-Bold";
}
.blog-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 6px 6px 6px;
  border-radius: 2px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.blog-grid .blog-layout-one {
  width: 48%;
  padding-bottom: 16px;
  padding-top: 6.5px;
}
.blog-grid img{
  margin-top: 10px;
}
.blogs-section {
  padding: 10px 10px 10px 10px;
}
.blog-layout-two {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
}
.blog-layout-two div {
  margin-right: 10px;
}
.blog-layout-three {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}
.blog-grid .blog-layout-three:last-child{
  margin-bottom: 10px;
}
.blog-grid .blog-layout-three:first-child{
  margin-top: 10px;
}
.blog-layout-four {
  display: flex;
  overflow: hidden;
}
.slider-product-width {
  width: 140px;
  margin-right: 10px;
  padding-top: 7px;
}
.homescreen-webview h6 {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
}

.homescreen-selection-checkbox {
  display: block;
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #1b2952;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  font-family: "Axiforma-Regular";
}
.homescreen-selection-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.home-checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 100%;
}
.homescreen-selection-checkbox:hover input ~ .home-checkmark {
  background-color: #ccc;
}
.homescreen-selection-checkbox input:checked ~ .home-checkmark {
  background-color: #3064f9;
}
.home-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.homescreen-selection-checkbox input:checked ~ .home-checkmark:after {
  display: block;
}
.homescreen-selection-checkbox .home-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.category-select-link {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  font-size: 13px;
  background-color: #f8fafe;
  display: flex;
  align-items: center;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.homescreen-pages-preview h4,
.homescreen-pages-preview h5 {
  width: 100%;

  /* border-bottom: 1px solid rgba(216, 221, 238, 0.2); */
  /* color: #333333; */
  font-size: 11px;
  font-family: "Axiforma-Regular";
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 0px 10px;
  margin: 0px;
  cursor: pointer;
}
.homescreen-pages-preview h5 {
  border-bottom: none;
  color: #a1a1a1;
  font-family: "Axiforma-Bold";
}
.homescreen-fields .accordionItem .accordionItemHeading a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.homescreen-fields .accordionItem .accordionItemHeading a img {
  position: absolute;
  top: 30px;
  right: 0;
}
.blog-banner-preview .carousel .slider-wrapper {
  width: 100%;
}
.blog-banner-preview .carousel-status {
  display: none;
}
.blog-banner-preview .slider:before {
  display: none;
}
.blog-banner-preview .slider {
  background-image: none;
}
.homescreen-blog-meta {
  position: absolute;
  bottom: 6px !important;
  left: 10px;
  text-align: left;
}
.homescreen-blog-meta h4 {
  width: 100%;
  color: #000000;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
}
.homescreen-blog-meta p {
  width: 100%;
  color: #a1a1a1;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  margin: 3px 0px 0px 0px;
}
.blog-banner-preview .carousel .control-dots {
  position: absolute;
  right: 10px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  bottom: 10px;
}
.blog-banner-preview .carousel .control-dots .dot {
  margin: 0 3px;
  background-color: rgb(0 0 0 / 50%);
  width: 5px;
  height: 5px;
}
.blog-banner-preview .carousel .control-dots .dot.selected,
.blog-banner-preview .carousel .control-dots .dot:hover {
  background-color: #707070 !important;
}
.blog-banner-preview .carousel.carousel-slider {
  overflow: visible;
}
.blog-banner-preview .carousel .slider-wrapper {
  overflow: visible;
}
.blog-banner-preview .homescreen-blog-meta.below-banner-text {
  bottom: -35px !important;
}
.blog-banner-preview .carousel .control-dots.below-banner-dots {
  bottom: -25px;
}
.homescreen-preview-layout .homescreen-bottom-bar-first-class{
  width: 296px!important;
  margin: auto;
  height: 45px;
}
.homescreen-preview-layout .homescreen-bottom-bar-first-class div .homescreen-bottom-bar-second-class .bottom-bar-icons img{
  max-width: 18px!important;
  max-height: 18px!important;
}
.homescreen-preview-layout .homescreen-bottom-bar-first-class div .homescreen-bottom-bar-second-class .bottom-bar-icons .w-100{
  text-align: center;
}
.homescreen-preview-layout .homescreen-bottom-bar-first-class div .homescreen-bottom-bar-second-class p{
  font-size: 10px!important;
}
.homescreen-preview-layout .homescreen-bottom-bar-first-class div .homescreen-bottom-bar-second-class p {
  margin-bottom: 0px;
}
.homescreen-preview-layout .global-header{
  width: 296px!important;
  margin: auto;
}
.global-header span{
  margin-left: 8px;
}
.blog-categories-preview1 .category-head .seemoresection{
  margin-right: 10px!important;
}
.homescreen-banner-tabs .nav-link.active div{
  border: 1px solid transparent;
}
.homescreen-banner-tabs.nav-tabs .nav-link:hover div{
  border: 1px solid transparent;
}
.homescreen-edits .font-dropdown .dropdown-toggle {
  height: 50px;
  background-color: #f8fafe!important;
  background-position: 98%!important;
}
.homescreen-category-popup .homescreen-selection-checkbox{
  width: 20px!important;
}
.homescreen-edits .homescreen-fields .input-box-checkbox {
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
  margin-top: 20px;
}
.home-category .backselectRound:before{
  border-radius: 10px!important;
}
.pages-preview .category-head{
  padding-top: 10px;
}
.home-category .category-head{
  padding-top: 10px;
}
.homescreen-full-width-popup .homescreen-selection-checkbox p{
  margin-bottom: 0px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1B2952;
}
.homescreen-full-width-popup  .L2-level {
  padding: 0px;
}
.homescreen-full-width-popup .homescreen-selection-checkbox{
  padding: 10px 20px 10px 20px;
}
.homescreen-full-width-popup .homescreen-selection-checkbox .home-checkmark {
  top: 10px;
  right: 15px;
  background-color: #fff;
  border: 1px solid #BCC1CE;
}
.homescreen-section-space{
  margin-bottom: 40px!important;
}
.homescreen-fields .accordionItemHeading:before {
  content: url("../../../assets/images/dashboard/bottom-bar/dots.png");
  margin-right: 10px;
  cursor: move;
  margin-top: 0px;
  z-index: 0;
  opacity: 0;
  position: absolute;
  left: -18px;
}
.homescreen-fields .accordionItemHeading:hover.accordionItemHeading:before {
  opacity: 1;
}
.blog-grid .blog-layout-two:first-child{
  margin-top: 10px;
}
.blog-grid .blog-layout-two:last-child{
  margin-bottom: 5px;
}
.blog-grid .blog-layout-two img {
  margin-top: 0px;
}
.homescreen-fields .draggable-container {
  top: auto !important;
  left: 10px !important;
}
.homescreen-fields .accordionItem .accordionItemContent .duplicate-delete{
  padding-bottom: 30px!important;
}
.homescreen-fields .homescreensection {
  right: 42px;
}
.homescreen-fields .select-img-ratio div p{
  color: #7782A1!important;
}
.homescreen-fields .img-ratio > input:checked + div p{
  color: #3064f9 !important;
}
.blog-grid .blog-layout-three img{
  margin-top: 0px;
}
.home-category .category-head{
  padding-right: 8px;
}
.products-grid .single-product-three .mt-3{
  margin-top: 10px;
}
.products-grid img{
  margin-top: 6px!important;
}
.image-uploaded-url div {
  width: 100%;
  max-height: 100%;
}
.image-uploaded-url input{
  position: absolute;
  z-index: 9;
  bottom: 20px;
  width: 50%;
  margin: auto;
  left: 0;
  right: 0;
  cursor: pointer;
}
.select-category-popup .menubuilder-modal-header{
  padding: 24px 20px 25px 20px !important;
}
.select-category-popup .menubuilder-modal-header .item-selection-menu {
  margin-right: 0px;
}
.select-category-popup .menu-modal-body{
  padding: 0rem 1rem;
}
.select-category-popup .menu-modal-body {
  min-height: auto;
}
.select-category-popup .menu-option-list {
  padding: 20px 0px 0px 0px;
}
.select-category-popup .L2-level {
  padding: 10px 20px 10px 20px;
}
.select-category-popup .dynamic-list ul:nth-child(1) {
  padding-right: 0px;
  margin-bottom: 0px;
}
.homescreen-section-move {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease;
  transform: scale(1);
}
.l2-name{
  width: 100%;
  color: #1B2952 !important;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.select-category-popup .underline-text {
    cursor: pointer;
}
.select-category-popup .dynamic-list .menu-builder-l2 {
  overflow-y: auto;
}
.select-category-popup .menu-option-list p{
  color: #1B2952;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
  width: 100%;
  margin: 0px;
}
.select-category-popup .homescreen-selection-checkbox:hover input ~ .home-checkmark {
  background-color: #fff!important;
}







@media screen and (min-width: 1201px) {
  .bannerlayoutoptions {
    width: 1280px !important;
    max-width: 100% !important;
  }
  .homescreenaddblock {
    width: 1280px !important;
    max-width: 100% !important;
  }
  .hide-homescreen-preview{
    display: none;
  }
  .homescreen-fields .accordionItemHeading:hover .catsection-name svg{
    display: initial;
    transition: .5s;
  }
}

@media screen and (max-width: 1200px) {
  .createhomescreen {
    display: block;
    width: 100%;
  }
  .createhomescreen a {
    background-color: #fff;
    width: 90%;
    height: 50px;
    border: 1px solid #c8cedb;
    color: #7782a1 !important;
    border-radius: 4px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: none !important;
  }
  .createhomescreen div button {
    margin-right: 0px;
  }
  .homescreenhead {
    padding: 0px;
  }
  .createhomescreen div input {
    width: 100%;
    height: 50px;
  }
  .createhomescreen div button {
    width: 55px;
    height: 50px;
  }
  .nopagecreated {
    min-height: 75vh;
  }
  .nopagecreated div h4 {
    font-size: 18px;
  }
  .pagecreator-detail,
  .pageaction {
    display: none;
  }
  .pagestatussection {
    width: 100px;
  }
  .pagedetail-name {
    width: calc(100% - 200px);
  }
  .firstchild,
  .secondchild,
  .thirdchild {
    padding-left: 0px;
  }
  .pagedetail-name h2 {
    font-size: 14px;
    margin-bottom: -5px;
  }
  .pagedetail {
    padding: 0px 20px;
    min-height: 79px;
  }
  .pagestatus {
    font-size: 12px;
  }
  .pageedit {
    width: 20px;
  }
  .hide-homescreen-preview {
    display: block;
    position: fixed;
    bottom: 100px;
    left: 0px;
    right: 0px;
    text-align: center;
    cursor: pointer;
  }
  .homescreen-full-width-popup .homescreen-selection-checkbox p {
    text-align: left!important;
  }
  .homescreen-preview-btn{
    background-color: #fff;
    height: 40px!important;
    text-align: center!important;
    border: none!important;
    color: #3064F9!important;
    font-size: 16px!important;
    font-family: "Axiforma-Regular";
    margin: 0px!important;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .createhomescreen .createnew {
    background-color: #3064f9;
    color: #fff;
    border: 1px solid #3064f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 16px;
    width: 100%;
  }
  .createhomescreen{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    padding: 10px 20px;
    box-shadow: 0px 0px 6px rgb(188 193 206 / 36%);
  }
  .homescreen-fields .accordionItemContent p{
    text-align: left!important;
  }
  .homescreen-edits .homescreen-fields .input-box-checkbox {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
  }
  .main-homescreen .transparent_background{
    overflow: hidden!important;
  }
  .homescreen-fields .tab-content {
    padding-bottom: 10px;
  }
  .homescreen-fields .accordionItemHeading.accordionItemHeading:before {
    opacity: 1;
    left: -10px;
  }
  .homescreen-fields .accordionItem {
    padding: 0px 40px 0px 25px;
  }
}



@media screen and (max-width: 767px) {
  .homescreen-banner-tabs.nav-tabs{
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: scroll;
  }
  .homescreen-banner-tabs.nav-tabs .nav-item {
    min-width: 180px!important;
    width: 180px;
    height: 180px;
    min-height: 180px;
    margin-right: 12px;
    margin-bottom: 0px;
  }
  .homescreen-banner-tabs::-webkit-scrollbar{
    width: 0px!important;
    height: 0px!important;
  }
  .banner-thumb-img {
    height: 160px;
  }
  .homescreen-banner-tabs.nav-tabs .nav-item h4 {
    bottom: 20px;
  }
  .homescreen-section-name{
    position: relative;
    top: -6px;
  }
  .homescreen-section-name .catsection-name{
    position: absolute;
    top: 22px;
    margin-left: 0px;
    left: 0;
    width: max-content;
  }
  .homescreen-edits {
    border-right: none;
    padding-right: 15px !important;
 }
 .homescreen-fields .accordionItem {
  padding: 0px 20px 0px 20px;
 }
 .homescreen-fields .accordionItemHeading{
  padding: 28px 0px 28px 5px;
 }
.homescreen-fields .homebanner-custom{
  margin-top: 0px!important;
}
.homescreen-fields .homebanner-custom h6 {
  margin-top: 15px!important;
}
.homescreen-fields .homebanner-custom .mt-20 h6{
  margin-top: 0px!important;
}
.homescreen-fields .homebanner-custom .mt-20 .col-md-6:nth-child(3){
  margin-top: 20px;
}
.category-customize-layout div a {
  margin-left: 15px;
}
.homescreen-edits .homescreen-fields .input-box-checkbox {
  margin-top: 12px;
}
.homescreen-edits .mt-30 {
  margin-top: 20px;
}
.homescreen-edits .mt-30 .col-md-6:nth-child(2){
  margin-top: 10px;
}
.homescreen-edits .jodit-toolbar-editor-collection .jodit-toolbar-button {
  margin: 2px 0px !important;
}
.homescreen-fields .input-box-checkbox {
  padding-left: 25px!important;
}
.homescreen-fields .accordionItemHeading.accordionItemHeading:before {
  top: 28px;
}
.homescreen-fields .accordionItem .accordionItemHeading a img {
  top: 28px;
}
.homescreen-delete-option p {
  margin-left: 45px;
}
.any-url-option {
  width: 90%!important;
}

}