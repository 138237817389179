.new-signupmain-new{
  display: flex;
}
.support-dropdown button {
  padding: 0px !important;
}
.newsignup-errorinput {
  border: 1px solid #ff4848 !important;
}
.new-signup-head {
  background: linear-gradient(to left, #f8fafe 50%, #fff 50%) !important;
  padding: 30px 40px;
}
.contact-us-header-white-bg {
  background: linear-gradient(to left, #fff 50%, #fff 50%) !important;
}

/* .signup-header-text {
  margin-top: 5px;
} */
.new-signup-headcontent {
  display: flex;
  justify-content: space-between;
}
.new-signup-subheadcontent {
  display: flex;
  align-items: center;
}
.new-signup-subheadtext {
  color: #7782a1;
  font-size: 14px;
  margin: 0px 0px 0px 40px;
  font-family: "Axiforma-Regular";
  line-height: 18px;
  cursor: pointer;
}
.new-signup-subheadtext:hover {
  color: #7782a1;
}
.signuppage-new {
  background: linear-gradient(to left, #f8fafe 50%, #fff 50%) !important;
  height: calc(100vh - 90px);
}
.new-signup-left {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 63px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .new-signupmain-div .new-signup-left {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 75px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.new-signup-leftdiv {
  width: 480px;
}
.new-signup-right {
  flex: 0 0 50%;
  max-width: 50%;
  justify-content: center;
  display: flex;
  padding: 94px 0px 0px 0px;
  background-color: #f8fafe;
}
.new-signup-right .carousel .control-dots {
  margin-top: 11px;
}
.opec4 {
  overflow: hidden;
}
.opec4:after {
  background: rgba(0, 0, 0, 0.2)!important;
  top: 90px!important;
  left: 0!important;
  width: 100%!important;
  height: 100%!important;
  content: ""!important;
  transition: 2s!important;
  animation-timing-function: ease-in-out;
  z-index: 1!important;
  position: fixed!important;
}
.signup-support-menu {
  position: fixed;
  right: -400px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 90px;
  /* z-index: 99999; */
  z-index: 999;
  padding-left: 10px;
  border-left: 1px solid #e3e6f1;
  border-top: 1px solid #e3e6f1;
  padding-bottom: 40px;
}
.signup-support-menu::before {
  display: block;
  content: url(./../images/dashboard/support-arrow.png);
  position: absolute;
  z-index: 99;
  top: -18px;
  right: 57px;
  margin: auto;
  transform: rotate(0deg);
}
.signup-crousel h2 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  text-align: center;
  margin-top: 16px;
  color: #1b2952;
  line-height: 23px;
}
.signup-crousel .carousel-status {
  display: none;
}
.signup-crousel button {
  display: none;
}
.signup-crousel .carousel .slider-wrapper {
  width: 100% !important;
}
.signup-crousel .carousel-slider {
  overflow: inherit !important;
}
.signup-crousel .control-dots {
  top: 81px !important;
}
.signup-crousel .carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  /* opacity: .3; */
  filter: alpha(opacity=30);
  box-shadow: none !important;
  background: #7782a1 !important;
  border-radius: 10px !important;
  width: 10px;
  height: 3px;
  cursor: pointer;
  display: inline-block;
  margin: 0px !important;
}
.signup-crousel .slide {
  background-color: #f8fafe !important;
}
.signup-slider {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.crousel-select {
  height: 3px;
  background-color: #e3e6f1;
  width: 40px;
}

.crousel-select p {
  width: 10px;
  height: 3px;
  border-radius: 10px;
  background-color: #7782a1;
  margin-bottom: 0px;
}
.signup-crousel h3 {
  font-size: 13px;
  font-family: "Axiforma-Bold";
  text-align: center;
  /* margin-top: 9px; */
  color: #7782a1;
  line-height: 17px;
}
.new-signupcontent h3 {
  color: #1b2952;
  font-size: 40px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  /* line-height: 69px; */
  line-height: 56px;
}
.new-signup-google {
  border: 1px solid #BCC1CE;
  align-items: center;
  display: flex;
  border-radius: 3px;
  height: 50px;
  justify-content: center;
}
.new-socialoptions-inner {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  gap: 20px;
  margin-bottom: 20px;
}
.new-facebooksignup {
  border: 1px solid #e3e6f1;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 3px;
  height: 50px;
  cursor: pointer;
}

.socialoptionstart {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-top: 26px;
  margin-bottom: 10px;
  line-height: 17px;
}
.signup-ordiv {
  align-items: center;
}
.signup-ordiv hr {
  margin: 0px;
  width: calc(50% - 9px);
  border-bottom: 1px solid #e3e6f1;
}
.signup-or {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px 11.5px 0px 11px;
  /* line-height: 18px; */
  line-height: 16px; /* To make it as per design we are keeping it same as height given in design */
}
.new-signup-leftdiv label {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 9px;
  line-height: 18px;
}
.new-signup-leftdiv .new-signup-form input {
  height: 50px !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
  border: 1px solid #bcc1ce;
  outline: none !important;
  color: #1b2952 !important;
  border-radius: 3px !important;
  padding: 19px 15px 18px 15px !important;
  background-color: #fff !important;
}
.new-signup-leftdiv .new-signup-form input:focus,
.new-signup-leftdiv .new-signup-form input:hover {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.new-error {
  margin-top: 12px;
}
.signup-web-google {
  margin-bottom: 2px;
}
.login-error-msg-red-color{
  color: #ff4848;
}
.new-error p {
  font-size: 12px !important;
  margin-bottom: 0px;
  color: #ff4848;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 5.5px;
  text-align: left !important;
}
.new-error svg {
  margin-top: -3px;
  min-width: 13px;
}
.password-error svg {
  margin-top: 1px !important;
  min-width: 13px;
}
.new-signup-leftdiv .new-signup-form {
  margin-top: 16px !important;
  margin-bottom: 0;
}
.new-signup-eye {
  position: absolute;
  top: 42px;
  right: 18px;
  cursor: pointer;
}
.new-signup-progressbar {
  margin-bottom: 20px;
  margin-top: 10px;
}
.new-signup-progressbar p {
  color: #bcc1ce;
  font-size: 11.5px;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
  display: none;
}
.signup-check {
  margin-top: 20px;
}
.new-signup-progressbar p span {
  color: #1b2952;
}
.new-signup-progressbar div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-signup-progressbar div progress {
  margin-left: 0px !important;
  background-color: #e3e6f1 !important;
  height: 3px;
  margin-right: 5px;
  width: 25%;
}
.new-signup-progressbar div progress:last-child {
  margin-right: 0px !important;
}

.redprogress::-webkit-progress-value {
  background: #ff0000;
}
.orangeprogress::-webkit-progress-value {
  background: #ff8426;
}
.lightgreenprogress::-webkit-progress-value {
  background: #ffc814;
}
.greenprogress::-webkit-progress-value {
  background: #5cce7b;
}
.sign-uploginbtn {
  background-color:  var(--custom_color2)!important;
  color: var(--custom_color1)!important;
  border-radius: 3px;
  width: auto;
  height: 50px;
  border: none;
  line-height: 67px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-align: center;
  transition: all 0.5s ease;
  width: 100%;
  margin: 18px 0px 30px 0px;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
}
/* .sign-uploginbtn:hover {
  background-color: #134bec;
} */
.newsignup-tc h6 {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  line-height: 19px;
  
}
.newsignup-tc h6 a {
  color: #7782a1;
}

.signup-mob-google {
  display: none;
}
.carousel .thumbs-wrapper {
  margin-top: 31px !important;
}

/*.newsignup-errorinput
{
	border: 1px solid #ff4848 !important;
}*/
.headeroutside-mobile-view {
  display: none;
}
.appSumobtn-login{
  background: #FFBC00;
   border-color: #FFBC00
}
.appSumospinner{
 width: 27px;
  height: 27px
}
@media (max-width: 1500px) {
  .new-signup-right {
    padding: 100px 20px 0px;
  }
  .new-signup-right img {
 width: 100%;
  }
}
@media (max-width: 1200px) {
  .signup-check {
    margin-top: 26px;
}
  .new-signup-leftdiv .new-signup-form {
    margin-top: 13px !important;
  }
  .new-signup-leftdiv label {
    margin-bottom: 11px;
  }
  .headeroutside-mobile-view {
    display: block;
    margin-left: 20px;
  }
  .new-signup-subheadsupport,
  .signup-website-hide {
    display: none !important;
  }
  .signup-support-menu {
    width: 100%;
    right: -100%;
    top: 60px;
  }
  .signup-support-menu::before {
    right: 25px;
  }
  .opec4:after {
    top: 60px;
  }
  .sign-uploginbtn {
    font-size: 16px;
    margin: 23px 0px 25px 0px;
  }
  /* .signup-header-text {
    margin-top: 5px;
  } */

  .new-signup-right {
    display: none;
  }
  .signuppage-new {
    background: #fff !important;
    height: calc(100vh - 60px);
  }
  .new-signup-left {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 40px 0px 75px;

  }

  .new-signup-head {
    background: #fff !important;
    padding: 10px 20px 15px 20px;
    border-bottom: none !important;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    position: relative;
    display: block !important;
  }
  .new-signupcontent h3 {
    font-size: 30px;
  }
  .socialoptionstart {
    margin-top: 20px;
    margin-bottom: 9px;
  }
  .support-dropdown img {
    margin-left: 20px;
  }
  /* .new-signup-subheadcontent {
      margin-right: 20px;
    } */
}
@media (max-width: 520px) {
 
  .signup-mob-google {
    display: block;
  }
  /* .new-signup-google{
         padding: 0px !important;
         width: 50px;
         justify-content: center;
    } */
  .new-signup-leftdiv {
    width: 100%;
  }
  .new-signup-left {
    padding: 34px 20px 40px 20px;
  }
 
  .new-facebooksignup {
    width: auto;
  }
  .new-socialoptions-inner {
    gap: 10px;
  }
}

.login-loader {
  position: absolute;

  height: 24px;
  width: 24px;
  box-sizing: border-box;
  background:conic-gradient(from 88.94deg at 50% 50%, #FFFFFF 0deg, #BCC1CE 360deg);
  border-radius:50%;
  animation: 1s rotate infinite linear;
}
.login-loader::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 18px;
  background: #fff;
  border-radius: 28px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
