.dashhead h2 {
  font-family: "Axiforma-Bold";
  font-size: 20px;
}
.dashhead p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
}
.dashhead a,
.dashhead hover {
  color: #3064f9 !important;
}
.newdashboard {
  display: flex;
  align-items: flex-start;
}
.newdashmidpanel {
  display: flex;
  width: 100%;
}
.dashboardmidsection {
  width: calc(100% - 400px);
}
.newconnectivity {
  background-color: #fff;
  width: 400px;
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
  border-radius: 4px;
  padding: 35px 20px 40px 20px;
}
.planinfo {
  background-color: #fff;
  width: 460px;
  margin: 0px 20px 0px 20px;
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  border-radius: 4px;
}
.appinfo {
  background-color: #fff;
  width: calc(100% - 460px);
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
  border-radius: 4px;
}
.appicon {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  border-radius: 20px;
  overflow: hidden;
  filter: drop-shadow(0px 2px 12px rgba(188, 193, 206, 0.3));
}
.appsummary {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 20px;
}
.appdata {
  margin-left: 20px;
}
.appdata h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 12px;
}
.appdata a,
.appdata:hover {
  color: #3064f9 !important;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  display: block;
}
.appdata p {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  margin-top: 12px;
  margin-bottom: 0px;
}
.appdata p span {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #c8cedb;
  margin-left: 40px;
}
.planinfo h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  line-height: 25px;
}
.planinfo h3 span {
  font-family: "Axiforma-Regular";
  font-size: 11px;
  border-radius: 20px;
  padding: 1px 8px 1px 8px;
  margin-left: 14px;
  letter-spacing: 0.5px;
}
.plangrey {
  color: #bcc1ce;
  border: 1px solid #c8cedb;
  background-color: #fcfdff;
}
.plangreen {
  color: #47ce70;
  border: 1px solid #47ce70;
  background-color: #effff4;
}
.planred {
  color: #ff4848;
  border: 1px solid #ff4848;
  background-color: #fefcfc;
}
.planinfo p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin: 10px 0px 11px 0px;
}
.planinfo a,
.planinfo a:hover {
  color: #3064f9 !important;
  font-family: "Axiforma-Regular";
  font-size: 12px;
}
.planinfo div:nth-child(2) {
  width: 100px;
  min-width: 100px;
  text-align: right;
}
.planinfo div:nth-child(1) {
  width: calc(100% - 100px);
}
.appversions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1;
  padding: 20px 20px 16px 20px;
}
.appversions div {
  display: flex;
}
.appversions .appversionnumber p {
  font-size: 12px;
  margin-bottom: 0px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: #bcc1ce;
}
.appversions .appversionnumber p.buildselected {
  color: #1b2952;
}
.appversions .appversionnumber a {
  margin-right: 10px !important;
}
.appversions .appversionnumber img {
  margin-right: 6.5px;
}
.appversions .connectionsummary img {
  margin-left: 20px;
}
.portalsteps {
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 21px;
  margin-right: 10px;
}
.steplink {
  position: relative;
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
  width: 10.37%;
  background-color: #fff;
  height: 115px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  border-radius: 4px;
  transition: 0.5s;
  margin-right: 9px;
}

.steplink:hover {
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.8));
  transition: 0.5s;
}
.steplink p {
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-top: 11px;
}
.steplink a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.markedcomplete {
  position: absolute;
  right: 7px;
  top: 7px;
}
.supportlinks {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 20px;
}
.supportlinks .supportweb {
  background-color: #fff;
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
  width: 24%;
  text-align: center;
  padding: 55px 28px 20px 28px;
  position: relative;
  border-radius: 4px;
  transition: 0.5s;
}
.supportlinks .supportweb:hover {
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.8));
  transition: 0.5s;
}
.supportweb h6 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  margin-top: 34px;
  margin-bottom: 12px;
}
.supportweb p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
}
.supportweb a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.newconnectivity h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newconnectivity h4 {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  margin-top: 15px;
}
.lowconnetivity {
  color: #bcc1ce;
}
.midconnetivity {
  color: #ff4848;
}
.highconnetivity {
  color: 11/13;
}
.newconnectivity h4 span {
  color: #7782a1;
  font-size: 12px;
  margin-left: 9px;
}
.newconnectivity p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.newconnectivity h6 {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.newconnectivity h6 span {
  color: #0f193b;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 14px;
  margin-right: 10px;
}
.newconnectivity .connectivitysummary {
  padding-bottom: 37px;
  position: relative;
}
.newconnectivity .apptraffic {
  padding-top: 37px;
  /* padding-bottom: 40px; */
  border-top: 1px solid rgba(227, 230, 241, 0.5);
}
.newconnectivity .apptraffic h6 {
  margin-top: 30px;
}
.newconnectivity .team {
  padding-top: 37px;
  margin-top: 37px;
  border-top: 1px solid rgba(227, 230, 241, 0.5);
}
.teamlist {
  display: flex;
  margin-top: 13px;
  overflow: hidden;
  background-image: url(../images/dashboard/blank-placeholder.png);
  background-repeat: repeat-x;
}
.teamlist .teammember {
  width: 54px;
  margin-right: 10px;
  min-width: 54px;
  position: relative;
  background-color: #f8fafe;
  border-radius: 100%;
}
.teamlist .teammember img {
  border-radius: 100%;
}
.teamlist .teammember .online,
.teamlist .teammember .offonline {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0px;
  bottom: 6px;
  border: 3px solid #fff;
}
.teamlist .teammember .online {
  background-color: #47ce70;
}
.teamlist .teammember .offonline {
  background-color: #bcc1ce;
}
.desktopslider {
  margin-top: 20px;
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
  border-radius: 3px;
  background-color: #ffffff;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 28px 20px;
}
.desktopslider p {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 12px;
  margin-bottom: 0px;
}
.desktopslider p a,
.desktopslider p a:hover {
  color: #3064f9;
  text-decoration: underline !important;
}

.newconnectivity .connectivitysummary a {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.dashboardmidsection .desktopslider .carousel-root .control-dots {
  display: none;
}
.desktopslider .slider:before {
  display: none;
}

.desktopslider .slider {
  background-image: none;
  background-color: #fff;
  cursor: auto;
}
.desktopslider .carousel-root .carousel:nth-child(2) {
  display: none;
}
.desktopslider .carousel .control-next.control-arrow:before {
  border: solid currentColor;
  border-width: 0 0.1em 0.1em 0;
  display: inline-block;
  padding: 0.1em;
  border-color: #bcc1ce;
  transform: rotate(-45deg);
}
.desktopslider .carousel .control-prev.control-arrow:before {
  border: solid currentColor;
  border-width: 0 0.1em 0.1em 0;
  display: inline-block;
  padding: 0.1em;
  border-color: #bcc1ce;
  transform: rotate(135deg);
}
.desktopslider .carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}
.desktopslider .carousel .control-disabled.control-arrow {
  opacity: 1 !important;
  display: block !important;
  right: 60px;
}
.desktopslider .carousel .control-arrow,
.desktopslider .carousel.carousel-slider .control-arrow {
  opacity: 1;
  top: -8px;
}
.desktopslider .carousel .slide {
  text-align: left;
}
.desktopslider h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 14px;
}
.desktopslider h6 {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
  width: 90%;
}
.desktopslider p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.desktopslider h6 a {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  text-decoration: underline;
}
.desktopslider .carousel .carousel-status {
  top: 7px;
  right: 25px;
  font-size: 12px;
  text-shadow: none;
  color: #bcc1ce;
}
.desktopslider .carousel .control-prev {
  left: initial !important;
  right: 60px;
}
.desktopslider .carousel .control-next {
  right: 0px !important;
}

.steplink.v-link p {
  color: #1b2952;
}

.carousel .slider-wrapper {
  width: 93%;
  margin-right: auto;
  margin-left: 0;
}

.newconnectivity .apptraffic h6 {
  display: flex;
  align-items: center;
}

.activeandroidspinn .spinner-border span {
  color: #7782a1 !important;
  border-color: #7782a1 !important;
}

.activeandroidspinn .spinner-border {
  width: 21px;
  height: 21px;
}
.activeandroidspinn {
  max-width: 25px;
  margin-right: 10px;
}

.newupdate {
  margin-top: 20px;
  border: 1px solid #ffbfbf;
  border-radius: 3px;
  background-color: #fffbfb;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 11px 20px 9px 20px;
}
.newupdate p {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 12px;
  margin-bottom: 0px;
}
.newupdate p a,
.newupdate p a:hover {
  color: #3064f9;
  text-decoration: underline !important;
}

.fullwidthdashboard {
  width: calc(100% - 20px) !important;
}

.menu-margin {
  margin-left: 30px;
  margin-right: 30px;
}
.workspacedashboard {
  background-color: #fff;
  height: 98px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
}
.workspacedashboard div div {
  padding: 0px 20px;
  width: 100%;
}
.workspacedashboard h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.workspacedashboard p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.workspacedashboard a {
  position: absolute;
  width: 100%;
  height: 100%;
}
/* Faisal changes */
.cmshideconnectivity {
  padding-top: 0px !important;
  border-top: none !important;
}

@media (min-width: 1701px) and (max-width: 1910px) {
  .steplink {
    width: 10.2%;
  }
}
@media (min-width: 1551px) and (max-width: 1700px) {
  .steplink {
    width: 15.6%;
  }
}
@media (min-width: 1201px) and (max-width: 1550px) {
  .steplink {
    width: 15.4%;
  }
  .supportlinks {
    flex-wrap: wrap;
  }
  .supportlinks .supportweb {
    width: 24%;
    margin-bottom: 15px;
  }
  .newconnectivity {
    width: 325px;
  }
  .planinfo {
    width: 325px;
  }
  .dashboardmidsection {
    width: calc(100% - 325px);
  }
  .appinfo {
    background-color: #fff;
    width: calc(100% - 325px);
  }
  .appdata a,
  .appdata:hover {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }
  .appversions .appversionnumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .appversions .appversionnumber p {
    margin-bottom: 0px;
    margin-right: 0px;
    font-size: 13px;
  }
  .appversions .connectionsummary div:nth-child(1) img {
    margin-left: 0px;
    margin-right: 10px;
  }
  .appversions .connectionsummary div:nth-child(2) img {
    margin-right: 0px;
    margin-left: 10px;
  }
  .connectionsummary {
    justify-content: space-between;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1201px) {
  .planinfo div:nth-child(2) {
    width: 70px;
    min-width: 70px;
  }
  .planinfo div:nth-child(1) {
    width: calc(100% - 90px);
  }
  .steplink {
    width: 18.5%;
  }
  .supportlinks .supportweb {
    width: 49%;
    margin-bottom: 15px;
  }
  .appdata a,
  .appdata:hover {
    width: 220px;
  }
  .newconnectivity {
    width: 300px;
  }
  .planinfo {
    width: 300px;
  }
  .appinfo {
    width: calc(100% - 300px);
  }
  .planinfo h3 {
    width: 210px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 600px) {
  .steplink {
    width: 23.76% !important;
  }
  .supportlinks {
    display: flex !important;
    flex-wrap: wrap;
  }
  .supportlinks .supportweb {
    width: 49% !important;
  }
  .supportweb p {
    text-align: left !important;
  }
  .newconnectivity {
    width: 100%;
  }
  .newconnectivity p,
  .planinfo p,
  .appdata p,
  .desktopslider p {
    text-align: left !important;
  }
  .dasboard_page.mobile-display .transparent_background {
    padding: 0px;
    margin-top: 70px !important;
  }
  .appdata a,
  .appdata:hover {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }
}
@media screen and (max-width: 1200px) {
  .newdashboard {
    display: block;
  }
  .dashboardmidsection {
    width: 100%;
    overflow: hidden;
  }
  .appinfo {
    width: 100%;
  }
  .planinfo {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
    padding: 20px;
  }
  .steplink {
    width: 30.7%;
  }
  .newdashmidpanel {
    display: block;
  }
  .portalsteps {
    margin-right: -10px;
    margin-top: 10px;
    padding: 0px 20px 0px 20px;
  }
  .supportlinks .supportweb {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 26px 28px 29px 20px;
    margin-bottom: 10px;
  }
  .appdata a,
  .appdata:hover {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 235px;
    white-space: nowrap;
  }
  .supportweb h6 {
    margin-top: 0px;
  }
  .supportlinks {
    display: block;
    margin-right: 0px;
    padding: 0px 20px 0px 20px;
  }
  .supportlinks .supportweb div {
    margin-left: 29px;
  }
  .supportweb p {
    margin-bottom: 0px;
  }
  .desktopslider {
    margin: 10px;
  }
  .appversions {
    padding: 0px;
    display: block;
    border-top: 1px solid rgba(227, 230, 241, 0.5);
  }
  .appversions .appversionnumber {
    display: block;
    padding: 20px 20px 10px 20px;
  }
  .appversions .appversionnumber p {
    margin-bottom: 15px;
    font-size: 12px;
  }
  .appversions .connectionsummary {
    border-top: 1px solid rgba(227, 230, 241, 0.5);
    padding: 20px 20px 16px 20px;
  }
  .appversions .connectionsummary div:nth-child(1) img {
    margin-right: 20px;
    margin-left: 0px;
  }
  .appversions .connectionsummary div:nth-child(2) img {
    margin-right: 0px;
    margin-left: 20px;
  }
  .portalsteps .mobileshow {
    width: 32%;
  }
  .newconnectivity {
    padding: 26px 20px 30px 20px;
    margin-top: 10px;
  }
  .newconnectivity .connectivitysummary {
    padding-bottom: 30px;
  }
  .newconnectivity .apptraffic {
    padding-top: 26px;
    /* padding-bottom: 30px; */
  }
  .newconnectivity .team {
    margin-top: 30px !important;
  }
  .dashhead {
    display: none;
  }
  .connectionsummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .newconnectivity {
    width: 100% !important;
  }
  .desktopslider {
    padding: 21px 20px;
  }
  .desktopslider h6 {
    margin-bottom: 20px;
  }
  .desktopslider .carousel .carousel-status {
    top: auto;
    right: 25px;
    bottom: -10px;
    left: auto;
  }
  .desktopslider .carousel .control-arrow,
  .desktopslider .carousel.carousel-slider .control-arrow {
    bottom: -15px;
    top: auto !important;
  }
  .appdata p span {
    margin-left: 0px;
    display: block !important;
  }
  .appdata a img {
    margin-left: 5px;
  }

  .newupdate {
    margin: 10px;
  }
  .fullwidthdashboard {
    width: 100% !important;
  }
  .workspacedashboard {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .planinfo h3 {
    font-size: 13px;
  }
}
@media screen and (max-width: 350px) {
  .appdata a,
  .appdata:hover {
    width: 160px;
  }
  .steplink {
    width: 46.7%;
  }
}
