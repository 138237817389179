.Single-select-popup .modal-header{
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
}
.Single-select-popup .modal-content{
    max-height: 560px;
    min-height: 560px;
}
.Single-select-popup .modal-body{
    max-height: 377px;
    overflow: auto;
    padding: 0px 0px;

}
.Single-select-popup{
 max-width: 600px !important;
}
.single-child-scrolldiv{
    max-height: 330px;
    overflow-y: scroll;
  
}
.single-child-scrolldiv .newspinner{
 position: unset;
 height: unset;
}
.Single-select-container {
    word-break: break-word;
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0px;
    cursor: pointer;
    color: #1B2952;
    font-size: 13px;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Axiforma-Regular';
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 45px 11px 48px;
  }
  .Single-select-container:hover{
    background-color: #F8F9FD;
    color: #3064F9;
  }
  .Single-select-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .Single-select-checkmark {
    position: absolute;
    top: 12px;
    left: 20px;
    height: 18px;
    width: 18px;
    background-color: #ffff;
    border-radius: 50%;
    border: 1px solid #BCC1CE;
  }
  
  .Single-select-container:hover input ~ .Single-select-checkmark {
    background-color: #ffff;
  }
  
  .Single-select-container input:checked ~ .Single-select-checkmark {
       background-color: #3064F9;
       border: unset;
  }
  
  .Single-select-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .Single-select-container input:checked ~ .Single-select-checkmark:after {
    display: block;
  }
  
  .Single-select-container .Single-select-checkmark:after {
       top: 6px;
      left: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: white;
  }

.single-select-body-header{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 8px;
    padding: 0px 20px;
}
.single-select-second-header{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 0px 20px;
}
.single-select-header h2{
    font-size: 16px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    line-height: 23px;
    margin-bottom: 8px;
}
.single-select-header input{
    width: 100%;
    height: 50px;
    border-radius: 3px;
    padding: 17px 60px 16px 15px;
    border: 1px solid #BCC1CE;
    outline: unset;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    margin-top: 10px;
    position: relative;
}
.singleselect-serachdiv{
    position: relative;
    width: 100%;
}
.Single-selct-search-clear{
    position: absolute;
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    cursor: pointer;
    margin-bottom: 0px;
    top: 26px;
    right: 20px;
}


.single-select-header input:hover, .single-select-header input:focus{
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
}
.single-select-bredcrums{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
    font-size: 12px;
    color: #7782A1;
   
}
.single-select-bredcrums svg{
margin-right: 5px;
}
.single-select-bredcrums p{
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    margin: 0px 5px;
    cursor: pointer;
}
.single-select-bredcrumchild{
    color: #BCC1CE !important;
    cursor: unset !important;
}
.Single-select-popup .modal-footer{
    border-top: 1px solid #E3E6F1 !important;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.Single-select-done-btn{
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #ffff;
    background-color: #3064F9;
    font-family: 'Axiforma-Regular';
    margin: 0px !important;
    border-radius: 3px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Single-select-done-disable{
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #ffff;
    background-color: #BCC1CE;
    cursor: not-allowed !important;
    font-family: 'Axiforma-Regular';
    margin: 0px !important;
    border-radius: 3px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Single-select-done-btn:hover{
    background-color: #134bec;
    transition: 0.5s;
}
.Single-select-cancel-btn{
    width: 100px;
    height: 40px;
    border: 1px solid #BCC1CE;
    font-size: 14px;
    color: #BCC1CE;
    margin: 0px !important;
    border-radius: 3px;
    background-color: #ffff;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
}
.single-select-selected{
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    margin-right: 20px;
}
.mob-single-select-bredcrums{
    display: none;
}
.mob-single-select-search svg{
    display: none;
}
.mob-single-select-search .single-select-serach-crossdiv{
    display: none;
}

/* Multi-select-popup */
.multi-select-popup .modal-header{
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
}
.multi-select-popup .modal-content{
    max-height: 560px;
    min-height: 560px;
}
.multi-select-popup .modal-body{
    max-height: 377px;
    overflow: auto;
    padding: 0px 0px;

}
.multi-select-popup{
 max-width: 600px !important;
}

.multi-select-container {
    word-break: break-all;
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0px;
    cursor: pointer;
    color: #1B2952;
    font-size: 13px;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Axiforma-Regular';
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 45px 11px 48px;
  }
  .multi-select-container:hover{
    background-color: #F8F9FD;
    color: #3064F9;
  }
  .multi-select-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .multi-select-checkmark {
    position: absolute;
    top: 12px;
    left: 20px;
    height: 18px;
    width: 18px;
    background-color: #ffff;
    border-radius: 50%;
    border: 1px solid #BCC1CE;
  }
  
  .multi-select-container:hover input ~ .multi-select-checkmark {
    background-color: #ffff;
  }
  
  .multi-select-container input:checked ~ .multi-select-checkmark {
       background-color: #3064F9;
       border: unset;
  }
  
  .multi-select-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .multi-select-container input:checked ~ .multi-select-checkmark:after {
    display: block;
  }
  
  .multi-select-container .multi-select-checkmark:after {
    left: 6px;
    top: 3.7px;
    width: 5px;
    height: 9px;
    border: solid white;
      /* background: white; */
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
  }

.multi-select-body-header{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 8px;
    padding: 0px 20px;
}
.multi-select-second-header{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 0px 20px;
}
.multi-select-header h2{
    font-size: 16px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    line-height: 23px;
    margin-bottom: 8px;
}
.multi-select-header input{
    width: 100%;
    height: 50px;
    border-radius: 3px;
    padding: 17px 60px 16px 15px;
    border: 1px solid #BCC1CE;
    outline: unset;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    margin-top: 10px
}
.multi-select-header input:hover, .multi-select-header input:focus{
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
}
.multi-select-bredcrums{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
    font-size: 12px;
    color: #7782A1;
   
}
.multi-select-bredcrums svg{
margin-right: 5px;
}
.multi-select-bredcrums a{
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    margin: 0px 5px;
}
.multi-select-bredcrumchild{
    color: #BCC1CE !important;
}
.multi-select-popup .modal-footer{
    border-top: 1px solid #E3E6F1 !important;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.multi-select-done-btn{
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #ffff;
    background-color: #3064F9;
    font-family: 'Axiforma-Regular';
    margin: 0px !important;
    border-radius: 3px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multi-select-done-btn:hover{
    background-color: #134bec;
    transition: 0.5s;
}
.multi-select-cancel-btn{
    width: 100px;
    height: 40px;
    border: 1px solid #BCC1CE;
    font-size: 14px;
    color: #BCC1CE;
    margin: 0px !important;
    border-radius: 3px;
    background-color: #ffff;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
}
.multi-select-selected{
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    margin-right: 20px;
}
.mob-multi-select-bredcrums{
    display: none;
}
.mob-multi-select-search svg{
    display: none;
}
.mob-multi-select-search .multi-select-serach-crossdiv{
    display: none;
}
.single-select-maindiv{
 position: relative;
}

.single-select-maindiv .subdata-svg-container{
    position: absolute;
    top: 6px;
    right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: end;
    width: 30px;
    height: 30px;
    /* background-color: white; */
   }
   /* .multi-select-container:hover .subdata-svg-container{
    background-color: #F8F9FD;
} */
   .single-select-web-cross{
    position: absolute;
    z-index: 9;
    top: 0px;
    right: 10px;
    height: 16px;
    width: 16px;
   }

/* Not found */
.select-no-data-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 320px;
    justify-content: center;
}
.select-no-data-found p{
    font-size: 13px;
    color: #BCC1CE;
    margin-top: 25px;
}

.single-select-popup-spin{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 338px;
    justify-content: center;
}

.single-select-popup-spin .newspinner{
position: unset;
width: 20%;
height: unset;
}

.mob-popUp-done-disable{
    display: none;

   }
   .mob-popUp-done-enable{
    display: none;

   }


@media (max-width: 1200px) {  
    .single-child-scrolldiv{
        /* max-height: 100%; */
        height: calc(100vh - 135px);
        max-height: unset;
    
    }
    .mob-popUp-done-disable{
        display: block;
        position: absolute;
        top: 16px;
        right: 20px;
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        color: #BCC1CE;
       }
       .mob-popUp-done-enable{
        display: block;
        position: absolute;
        top: 16px;
        right: 20px;
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        color: #3064F9;
       }

    .single-select-popup-spin{
        height: calc(100vh - 150px);
    }
    .multi-select-container:hover {
        background-color: unset;
        color: unset;
    }
  /* Not found */
  
  .Single-selct-search-clear{
    top: 16px;
}
.select-no-data-found {
    margin-top: unset;
    height: calc(100vh - 100px);
}
/* Single-select-popup */

.single-select-maindiv .subdata-svg-container{
    top: 19px;

   }
.mob-single-select-search svg{
    display: block;
}
.mob-single-select-search .single-select-serach-crossdiv{
    display: block;
}
.Single-select-popup .modal-content {
    max-height: unset;
    min-height: unset;
}
.Single-select-popup .modal-body {
    max-height: unset;
    padding: 16px 0px 20px;
}
.Single-select-popup .modal-footer {
    display: none;
}
.single-select-header h2 {
    display: none;
}
.Single-select-popup .modal-header {
    padding:0px;
    margin: 0px;

}
.single-select-web-cross{
display: none;
}

.single-select-header input {
    margin-top: 0px;
    border: unset;
    padding: 17px 60px 16px 18px;
}
.single-select-header input:hover, .single-select-header input:focus{
    border: unset!important;
    background-color: unset !important;
}
.mob-single-select-top{
display: flex;
flex-direction: column-reverse;
}
.mob-single-select-search{
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36 );
    padding: 0px 20px;
    height: 60px;
}
.single-select-bredcrums {
    padding: 14px 20px 0px;
}

.mob-single-select-search svg{
    margin: 0px !important;
    cursor: pointer;
}
.mob-single-select-search img{ 
    cursor: pointer;
    margin-top: -4px;
}
.single-select-serach-crossdiv{
    padding-left: 20px;
    border-left: 1px solid #E3E6F1;
}
/* .mob-single-select-top .single-select-bredcrums{
display: none !important;
} */
.mob-single-select-bredcrums{
    display: flex;
}

.single-select-body-header{
    padding: 24px 20px 0px;
}
.Single-select-container {
    padding: 21px 40px 23px 54px;
    font-size: 16px;
    word-break: break-word;
}
.Single-select-container .Single-select-checkmark:after {
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
}
.single-select-second-header {
    margin-bottom: 16px;
    margin-top: 16px;
}
.Single-select-container:hover {
    background-color: unset;
        color: unset;
}
.Single-select-checkmark {
    top: 20px;
    left: 20px;
    height: 24px;
    width: 24px;
}


/* Multi-select-popup */
.mob-multi-select-search svg{
    display: block;
}
.mob-multi-select-search .multi-select-serach-crossdiv{
    display: block;
}
.multi-select-popup .modal-content {
    max-height: unset;
    min-height: unset;
}
.multi-select-popup .modal-body {
    max-height: unset;
    padding: 16px 0px 20px;
}
.multi-select-popup .modal-footer {
    display: none;
}
.multi-select-header h2 {
    display: none;
}
.multi-select-popup .modal-header {
    padding:0px;
    margin: 0px;

}
.multi-select-web-cross{
display: none;
}

.multi-select-header input {
    margin-top: 0px;
    border: unset;
    padding: 17px 60px 16px 18px;
}
.multi-select-header input:hover, .multi-select-header input:focus{
    border: unset!important;
    background-color: unset !important;
}
.mob-multi-select-top{
display: flex;
flex-direction: column-reverse;
}
.mob-multi-select-search{
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36 );
    padding: 0px 20px;
    height: 60px;
}
.multi-select-bredcrums {
    padding: 14px 20px 0px;
}

.mob-multi-select-search svg{
    margin: 0px !important;
    cursor: pointer;
}
.mob-multi-select-search img{ 
    cursor: pointer;
    margin-top: -4px;
}
.multi-select-serach-crossdiv{
    padding-left: 20px;
    border-left: 1px solid #E3E6F1;
}
.mob-multi-select-top .multi-select-bredcrums{
display: none !important;
}
.mob-multi-select-bredcrums{
    display: flex;
}

.multi-select-body-header{
    padding: 24px 20px 0px;
}
.multi-select-container {
    padding: 21px 40px 23px 54px;
    font-size: 16px;
}

.multi-select-second-header {
    margin-bottom: 16px;
    margin-top: 16px;
}
.multi-select-container:hover {
    background-color: unset;
}
.multi-select-checkmark {
    top: 20px;
    left: 20px;
    height: 24px;
    width: 24px;
}
.multi-select-container .multi-select-checkmark:after {
    left: 9px;
    top: 3.7px;
    width: 6px;
    height: 13px;
}
}
