.notvalidusertxt h1 {
  font-size: 30px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-top: 60px;
  margin-bottom: 20px;
}
.notvalidusertxt p {
  font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    margin-bottom: 32px;
    line-height: 25px;
}
/* .new-notvalid-user-skip{
    border: 1px solid #C8CEDB;
    border-radius: 3px;
    height: 50px;
    width: 180px;
    line-height: 50px;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
} */
.new-notvalid-user-skip:hover {
  color: #7782a1;
}
.new-notvalid-user-btn {
  border: 1px solid #3064f9;
  border-radius: 3px;
  height: 50px;
  width: 180px !important;
  line-height: 67px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: white;
  background-color: #3064f9;
  padding: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
  /* padding-bottom: 5px; */
}
.new-notvalid-user-btn:hover {
  background-color: #134bec;
}
.new-notvaliduser-padding {
  padding-top: 270px;
}
.myapps-notvaliduser {
  position: relative;
  min-height: calc(100vh - 60px);
  /* background-image: url(../../assets/images/signup/newbackground.png); */
  background-image: url("../../assets/images/dashboard/app-icon-background.png");
  justify-content: center;
  background-size: cover;
  margin-top: 60px !important;

  width: 100% !important;
  padding: 0px !important;
  background-color: white !important;
  padding-top: 232px !important;
}

@media (max-width: 1200px) {
  .myapps-notvaliduser {
    background: none;
    padding: 165px 20px 0px 20px !important;
  }
  .new-notvaliduser-padding {
    padding-top: 135px;
  }
  .new-notvalid-user-skip,
  .new-notvalid-user-btn {
    font-size: 16px;
  }
  .notvalidusertxt h1 {
    font-size: 24px;
    margin-top: 38px;
  }
  .new-notvalid-user-btn {
    width: 280px !important;
  }
  .notvalidusertxt p {
    line-height: 18px;
    margin-bottom: 56px;
  }
}
@media (max-width: 800px) {
  .new-notvaliduser-padding {
    padding: 135px 20px 0px 20px;
  }
  /* .new-notvalid-buttons{
        display: flex;
        flex-direction: column-reverse;
    } */
  .new-notvalid-user-btn {
    margin-left: 0px;
  }
  .new-notvalid-user-skip {
    border: none;
    margin-top: 40px;
    line-height: 16px;
  }
}
