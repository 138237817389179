.new-signin-error {
  background-color: #ffecec;
  padding: 14px 51px 8px 15px;
  margin-bottom: 20px;
  border-radius: 3px;
  margin-top: -47px !important;
}
.new-signin-error p {
  color: #ff4848;
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 20px;
  text-align: left !important;
}
.new-signinpage {
  position: relative;
  min-height: calc(100vh - 90px);
  background-image: url("../images/dashboard/app-icon-background.png");
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: contain;
  background-attachment: fixed;
}
.sigin-web-google {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}

.sigin-web-google .disablelogin {
  margin-bottom: 0px;
  margin-left: 9.9px;
  margin-top: 2px;
  font-size: 14px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
}
.sigin-web-google .enablelogin {
  margin-bottom: 0px;
  margin-left: 9.9px;
  margin-top: 2px;
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
}
.new-signin {
  width: 540px;
  padding: 0px 30px;
  background-color: #fff;
}
.new-signin-left {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 74px 0px 0px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.new-signinhead {
  margin-bottom: 26px;
}
.new-signin-form label {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 9px;
  line-height: 17px;
}
.new-signin-form .form-group {
  margin-bottom: 0;
  margin-top: 17px;
}
.new-signin-form .form-group input {
  height: 50px !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
  border: 1px solid #bcc1ce;
  outline: none !important;
  color: #1b2952 !important;
  border-radius: 3px !important;
  padding: 19px 15px 18px 15px !important;
  background-color: #fff !important;
}
.new-signin-form .form-group input:focus,
.new-signin-form .form-group input:hover {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.new-signineye {
  position: absolute;
  top: 43px;
  right: 18px;
  cursor: pointer;
}
.new-forgot_pw {
  margin: 20px 0px 12px;
}
.new-forgot_pw .container1 {
  padding-top: 2px !important;
  padding-left: 30px;
}
.new-forgot_pw .checkmark {
  background-color: #fff;
}
.new-forgot_pw .checkmark:hover {
  background-color: #fff !important;
}
.new-signinforgot_password {
  line-height: 1;
}
.new-signinforgot_password a {
  text-decoration: none;
  margin-bottom: 0px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17px;
}
.new-signinforgot_password a:hover {
  color: #7782a1;
}
.socialoptionstart-signin{
  font-size: 13px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    margin-top: 25px;
    margin-bottom: 10px;
    line-height: 17px;
}
.new-facebooksignin{
  border: 1px solid #e3e6f1;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 3px;
    height: 50px;
    width: 230px;
    margin-left: 20px;
    margin-bottom: 17px;
    cursor: pointer;
}
.sign-inloginbtn 
{
  background-color:  var(--custom_color2)!important;
  color: var(--custom_color1)!important;
  border-radius: 3px;
  width: auto;
  height: 50px;
  border: none;
  line-height: 67px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-align: center;
  transition: all 0.5s ease;
  width: 100%;
  margin: 18px 0px 30px 0px;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
}

.new-socialoptions-inner-signin {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  gap: 20px;
  margin-bottom: 30px;
}
/* .newsignin-container1{
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 20px !important;
    cursor: pointer;
    user-select: none;
    padding-top: 2px;
}
.newsignin-container1 input{

    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #bcc7e6;
}
.new-signin-checkmark{
    position: absolute;
    border: 1px solid #bcc7e6;
    border-radius: 3px;
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;

}
.new-signin-checkmark::after{
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #547df2;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none;
}
.newsignin-container1 input:checked ~ .checkmark:after {
    display: block;
} */
@media (max-width: 520px) {
  .new-signin {
    width: 100%;
    padding: 0px;
  }
  .new-facebooksignin{
    width: auto;
  }
  .new-socialoptions-inner-signin {
    gap: 10px;
  }
}
@media (max-width: 1200px) {
  .sign-inloginbtn {
    font-size: 16px;
    margin: 18px 0px 25px 0px;
}
  .new-facebooksignin{
    margin-left: 10px;
    margin-bottom: 17px;
  }
  .socialoptionstart-signin{
    margin-top: 15px;
    margin-bottom: 8px;
  }
  .new-signin-left {
    padding: 28px 20px 20px 20px;
  }
  .new-signinpage {
    background: none !important;
  }
  .new-signin-error {
    margin-top: -20px !important;
  }
}


.login-loader {
  position: absolute;

  height: 24px;
  width: 24px;
  box-sizing: border-box;
  background:conic-gradient(from 88.94deg at 50% 50%, #FFFFFF 0deg, #BCC1CE 360deg);
  border-radius:50%;
  animation: 1s rotate infinite linear;
}
.login-loader::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  width: 18px;
  background: #fff;
  border-radius: 28px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
