.see-comparison-btn {
    padding-top: 50px;
}
.see-comparison-btn a h4 {
    color: #b8bfd0;
    font-size: 16px !important;
    font-family: 'Axiforma-Regular';
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0;
    margin-right: 4px;
    font-weight: 400;
    line-height: 24px;
}
.customer-reviews-link-main {
    margin-top: 40px;
    display: block;
    width: 130px;
}
.customer-reviews-link a h4 {
    font-family: 'Axiforma-Regular';
    font-weight: 400;
    color: #3064f9;
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 4px;
}
.see-more-btn {
    color: #7782A1 !important;
}
.contact-us-planlist {
    font-family: 'Axiforma-Regular' !important;
}
.contact-us-svg {
    margin-left: 6px !important;
}
.haveidea-redesign a h5 {
    font-family: 'Axiforma-Regular' !important;
    font-size: 16px !important;
    color: #3064F9 !important;
    margin-bottom: 0 !important;
}
@media (max-width: 767px) {
    .see-comparison-btn {
        padding-top: 30px;
    }
    .customer-reviews-link-main {
        margin-top: 0;
        margin-bottom: 20px;
    }
    .customer-review-text.customer-review-pad {
        padding-right: 0px;
        padding-left: 0;
    }
    .haveidea-redesign {
        flex-wrap: wrap;
        padding: 57px 18px;
    }
}