.publish-headh {
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin: 0px;
    line-height: 24px;
}

.publish-headh p {
    margin-left: 6px;
}

.consent-modal-tab .publish-modal-tab a {
    color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px 60px -5px 0px;
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
    transition: .5s;
}

.publish-red-color {
    height: 10px;
    width: 10px;
    position: absolute;
    top: -2px;
    background-color: #FF4848;
    border-radius: 50%;
    right: 5px;
    border: 2px solid #fff;
}

.main-publishpage {
    min-height: 78vh;
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.publish-update-page {
    position: relative;
    background-image: url("../../../assets/images/dashboard/app-icon-background.png");
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    background-attachment: fixed;
    min-height: 79vh;
    display: flex;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.comming-soon-publish-btn{
    border: 1px solid #BCC1CE;
    color: #BCC1CE;
    border-radius: 3px;
    font-size: 14px;
    background-color: white;
    height: 50px;
    width: 200px;
    font-family: 'Axiforma-Regular';
    margin-top: 14px;
    cursor: auto;
}
.timeestimated-div p{
    margin: 30px 0px -24px !important;
    color: #BCC1CE !important;
    font-size: 12px !important;
}
.tick-for-identify{
    display: flex;
    justify-content: center;
    margin: -10px 0px 30px;
}
.publish-popup-cancel svg{
    margin-left: 6px;
}
.publish-popup-cancel a{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #7782A1;
}
.publish-popup-cancel a:hover{
    color: #7782A1;
}
.publish-popup-cancel:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(188 193 206 / 39%);
}
.tick-for-identify p{
    margin: 0px 0px 0px 12px !important;
    font-size: 13px;
}
.tick-for-identify input:checked{
    background-color: #3064F9;

}
.comming-soon-publish-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.publish-update-page-left {
    width: 50%;
    padding: 146px 194px 0px 120px;
}

.publish-update-page-right {
    padding: 93px 30px 0px 0px;
    width: 50%;
}

.publish-update-page-left h2 {
    color: #1b2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    margin-bottom: 15px !important;
}

.publish-update-page-left p {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 19px;
    margin-bottom: 25px;
    text-align: left !important;
}
.publish-modal-tab a {
    margin: 0px 40px -5px 0px !important;
    padding: 0px 10px 12px 10px !important;
    transition: .5s;
}
.publish-update-page-left ul li {
    line-height: 17px;
    font-size: 13px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;
    padding-bottom: 18px;
    list-style-type: none;
    position: relative;
}
.App-Store-Connect-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}
.publish-update-page-left ul {
    padding-left: 0 !important;
}

.publish-update-page-left ul li:before {
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 5px;
}

.publish-update-page-left a {
    width: 200px;
    height: 50px;
    border-radius: 3px;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    font-family: 'Axiforma-Regular';
    transition: 0.5s;
}
.upgrade-skip-publish{
    display: flex;
    align-items: center;
    margin-top: 14px;
    gap: 20px;
}
.publish-update-page-left a:hover {
    background-color: #134bec !important;
}

.publishsave-preview {
    width: 100px;
    height: 40px;
    border-radius: 3px;
    background-color: #F8FAFE;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
}

.publishsave-preview.web-publish-disable {
    border: 1px solid #BCC1CE;
    color: #BCC1CE;
    cursor: not-allowed;

}

.publishsave-preview.web-publish-enable {
    border: 1px solid #3064f9;
    color: #3064f9;
}

.publish-technology {
    margin-right: 30px;
    position: relative;
}

.publish-tab-right {
    display: flex;
    align-items: center;
}

.consent-modal-tab .publishsubmit-preview {
    width: 180px;
    height: 40px;
    border-radius: 3px;
    border: none;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px 0px 0px 20px;
}

.consent-modal-tab .publishsubmit-preview.web-publish-disable {
    color: #ffff !important;
    background-color: #BCC1CE;
    cursor: not-allowed;
}

.publishsubmit-preview.web-publish-enable {
    color: #ffff !important;
    background-color: #3064F9;
}

.publishsubmit-preview.web-publish-enable:hover {
    background-color: #134bec;
    transition: 0.5s;
    color: #ffff !important;
}

.appstore-disable {
    fill: #7782a1
}

.main-publishpage p {
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin: 30px 0px;
    font-size: 14px;
}

.publishweb-date {
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #BCC1CE;
    margin-bottom: 0px;
    margin-right: 20px;
}

.newpublish-ios-connect {
    width: 140px;
    height: 40px;
    border: none;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: 0.5s;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newpublish-ios-connect:hover {
    background-color: #134bec;
    color: #ffff !important;
}

.newpublish-ios-connect img {
    margin-right: 5px;

}

.publis-tech-image {
    background-color: white;
    height: 111px;
    width: 111px;
    border: 1px solid #E3E6F1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);
}

/* .appleappstore-connect {
    display: flex;
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}

.appleappstore-connect-left {
    width: 50%;
    padding: 20px 20px 38px 20px;
}

.appleappstore-connect-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.publish-apple-id-field {
    display: grid;
    margin-bottom: 20px;
}

.publish-apple-id-field label {
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 9px;
}

.publish-apple-id-input {
    height: 50px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #bcc1ce;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 15px;
}

.publish-apple-id-input:hover,
.publish-apple-id-input:focus {
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}

/* .appleappstoreconnect h2 {
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;

}

.appleappstoreconnect p {
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;

}

.appleappstoreconnect {
    padding-bottom: 19px;
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 29px;
} */

.publish-howtoget {
    width: 140px;
    height: 40px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #ffff;
    margin-top: 10px;
}

.publishconectmethod {
    display: flex;
    margin-top: 67px;
}

.publishconectsteps {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: center;
}
.publishconectsteps:last-child{
    margin-left: 55px;
}
.publishstepwise {
    margin-left: 20px;
}

.publishstepwise h2 {
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 10px;
}

.publishstepwise p {
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}

.publish-apple-id-textarea {
    height: 120px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #bcc1ce;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 15px;
    resize: none;
}

.publish-apple-id-textarea:hover,
.publish-apple-id-textarea:focus {
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}

.publishhistory-head {
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    margin-top: 30px;
    margin-bottom: 16px;
}

.appleappstore-connect-history {
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}

.appleappstore-table-head {
    display: flex;
    align-items: center;
    height: 40.5px;
    padding: 0 20px;
    border-bottom: 1px solid #f1f2f8;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    margin: 0;
}

.appleappstore-table-data {
    display: flex;
    padding: 0 20px;
    min-height: 108px;
    align-items: center;
    border-bottom: 1px solid #F1F2F8;
}

.appleappstore-table-head div:first-child {
    width: 28%;

}

.appleappstore-table-head div:nth-child(2) {
    width: 22%;
}

.appleappstore-table-head div:nth-child(3) {
    width: 23%;
}

.appleappstore-table-head div:nth-child(4) {
    width: 21%;
}

.appleappstore-table-head div:nth-child(5) {
    width: 8%;
}

.appleappstore-table-data div:first-child {
    width: 28%;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
    display: flex;
    align-items: center;
}

.appleappstore-table-data div:first-child img {
    margin-right: 10px;
}

.appleappstore-table-data div:nth-child(2) {
    width: 22%;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
}

.appleappstore-table-data div:nth-child(3) {
    width: 23%;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
}

.appleappstore-table-data div:nth-child(4) {
    width: 21%;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
}

.appleappstore-table-data div:first-child p {
    margin-bottom: 0px;
    text-align: left !important;
    word-break: break-word;
    margin-right: 5px;
}

.appleappstore-table-data div:nth-child(2) p {
    margin-bottom: 0px;
    text-align: left !important;
    word-break: break-word;
    margin-right: 5px;
}

.appleappstore-table-data div:nth-child(3) p {
    margin-bottom: 0px;
    text-align: left !important;
    word-break: break-word;
    margin-right: 5px;
}

.appleappstore-table-data div:nth-child(4) p {
    margin-bottom: 0px;
    text-align: left !important;
    word-break: break-word;
    margin-right: 5px;
}




.appleappstore-table-data div:nth-child(5) {
    width: 8%;
}
.failed-publish-view-err{
    display: grid;
    width: 100% !important;
}
.failed-publish-view-err span{
    font-size: 13px;
    color: #3064F9;
    font-family: 'AXIFORMA-REGULAR';
    cursor: pointer;
}

.apple-submitted-review {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}

.apple-submitted-review.connection-uploaded {
    color: #52CB86;
}

.apple-submitted-review.connection-failed {
    color: #FF4848;
}

.appleappstore-table-data div:nth-child(5) a {
    color: #3064F9;
    font-family: "Axiforma-Regular";
    font-size: 12px;
}

.appleappstore-table-data div:nth-child(5) a:hover {
    color: #3064F9;

}

.publish-merged {
    margin-top: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.publish-grid {
    display: flex;
    align-items: center;
    width: 530px;
    margin: auto;
}

.publish-parent-app {
    background-color: #fff;
    border-radius: 8px;
    width: 220px;
    min-height: 220px;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
    text-align: center;
    position: relative;
    padding: 0px 10px;
}

.publish-parent-tag,
.publish-child-tag {
    border: 1px solid #E3E6F1;
    border-radius: 30px;
    width: 90px;
    height: 22px;
    position: absolute;
    top: -9px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #fff;
}

.publish-parent-tag h3,
.publish-child-tag h3 {
    font-family: "Axiforma-Regular";
    font-size: 10px;
    color: #BCC1CE;
    text-align: center;
    margin-top: 4px;
}

.publish-appicon {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgb(188 193 206 / 30%);
    margin: auto;
    margin-top: 42px;
    margin-bottom: 20px;
}

.publish-grid h4 {
    font-family: "Axiforma-Bold";
    font-size: 15px;
    color: #1B2952;
}

.publish-grid h5 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    word-break: break-word;
}

.publish-child-app {
    background-color: #fff;
    border-radius: 8px;
    width: 220px;
    min-height: 220px;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
    text-align: center;
    position: relative;
    padding: 0px 10px;
}

.publish-merged h1 {
    font-family: "Axiforma-Bold";
    font-size: 30px;
    color: #1B2952;
    margin: 65px 0px 20px 0px;
}

.publish-merged h2 {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    max-width: 800px;
    margin: auto;
    margin: 0px 0px 40px 0px;
    line-height: 18px;
}

.publish-merged-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.publish-merged-btns a:nth-child(1) {
    color: #3064F9;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #3064F9;
    border-radius: 3px;
    transition: 0.5s;
    text-decoration: none;
    height: 50px;
    line-height: 48px;
    display: block;
    width: 160px;
    text-align: center;
    margin-right: 10px;
}

.publish-merged-btns a:nth-child(1):hover {
    color: #134bec;
    border: 1px solid #134bec;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}

.publish-merged-btns button {
    color: #7782A1 !important;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    transition: 0.5s;
    text-decoration: none;
    height: 50px;
    line-height: 48px;
    display: block;
    width: 160px;
    text-align: center;
    margin-left: 10px;
}

.publish-merged-btns a:nth-child(2):hover {
    color: #484e5e;
    border: 1px solid #7782A1;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}

.mob-publish-history-head {
    display: none;
}

/* .mob-publish-connect {
    display: none;
} */

.mob-publish-btns {
    display: none;
}

.mob-publish-iossvgdiv {
    display: none;
}



.publish-crossmodal{
    position: absolute;
    top: 0px;
    right: 12px;
    cursor: pointer;
    height: 14px;
    width: 14px;
}
.publish-firstmodal{
    max-width: 760px !important;
}
.publish-firstmodal .modal-body{
    padding: 0px;
}
.publish-firstmodal-content{
    padding: 0px 30px 0px 30px;
}
.publish-firstmodal-content h2{
    font-size: 24px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    line-height: 33px;
    margin-bottom: 0px;
}
.publish-firstmodal-content p{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left !important;
}
.publish-firstmodal-content ul li {
    padding-top: 15px;
    margin-bottom: 15px;
    font-size: 13px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;  
    list-style-type: none;
    position: relative;
}

.publish-firstmodal-content ul {
    padding-left: 0 !important;
}

.publish-firstmodal-content ul li:before {
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 21px;
}

.publish-firstmodal-bottom{
    padding: 15px 0px 15px 0px;
}
.publish-firstmodal-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-top: 1px solid #E3E6F1;
}
.publish-Okay-button{
    width: 140px;
    height: 40px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    color: #7782A1;
    background-color: #ffff;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
}
.publish-generate-button{
    width: 180px;
    height: 40px;
    border-radius: 3px;
    color: #fff;
    background-color: #3064F9;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    border: none
}
.publish-generate-button:hover{
background-color: #134bec;
transition: 0.5s;
}
.publish-secondmodal .modal-body{
    padding: 13px 60px 60px 60px;
}
.time-required-btn button{
    width: 220px;
}
.time-out-modal .modal-body{
    padding: 13px 50px 60px 50px !important;
}
.publish-popup-head{
    display: flex;
    align-items: center;
    justify-content: center;
}
.publish-popup-head h2{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    text-align: center;
    line-height: 25px;
    margin-bottom: 0px;
   
}
.publish-popup-head svg{
    margin-right: 6px;
}
.publish-popup-maindiv p{
    font-size: 14px;
    color: #7782A1;
    text-align: center;
    font-family: 'Axiforma-Regular';
    margin-top: 18px;
    margin-bottom: 40px;
    word-break: break-word;
}
.publish-popup-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
}
.publish-popup-cancel{
    width: 140px;
    height: 44px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    color: #7782A1;
    background-color: #ffff;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    margin-right: 20px;
}
.publish-popup-confirm{
    width: 140px;
    height: 44px;
    border-radius: 3px;
    color: #fff;
    background-color: #3064F9;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    border: none;
    transition: 0.5s;
}
.publish-popup-confirm:hover{
    background-color: #134bec;
   
}
.mob-publish-headh{
    display: none;
}
.publish-update-left-div{
    background-color: white;
    height: fit-content;
}
.publish-ios-build-version-div{
    height: 50px;
    border: 1px solid #bcc1ce;
    outline: none !important;
    font-size: 13px;
    padding: 13px 15px;
    border-radius: 3px;
    position: relative;
}
.publish-ios-build-version-div:hover{
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}
.publish-ios-build-version-div input{
    font-family: 'Axiforma-Regular';
    width: 80%;
}
.goTodownload-div{
    position: absolute;
    right: 15px;
    top: 14px;
    font-family: 'Axiforma-Regular';
}
.publish-main-div{
    position: relative;
    min-height: 78vh;
    border-radius: 6px;
   
}
.publish-main-div .newspinner{
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}

.processing-publish-btn-web{
    height: 40px;
    width: 160px;
}
.processing-publish-btn-web svg{
margin-right: 6px;
}


/* input tickbox */

.tick-for-identify label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px; 
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 15px;
  }
  

  .tick-for-identify input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  
  }
  
 
  .tick-for-identify span {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
     background-color: #ffff;
     border: 1px solid #BCC1CE;
     border-radius: 3px;
  }
  

  .tick-for-identify label:hover input ~ span {
    background-color: #ffff;
  }
  
 
  .tick-for-identify label input:checked ~ span {
    background-color: #3064F9;
  }
  
 
  .tick-for-identify span:after {
    content: "";
    position: absolute;
    display: none;
  }
  
 
  .tick-for-identify input:checked ~ span:after {
    display: block;
  }
  
  
  .tick-for-identify span:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

/* input tickbox */

.mob-conn-publish-top{
    display: none;
}

.in-app-timeoutbtns button{
    width: 180px;
}

@media (min-width: 2200px) { 
    .publish-update-page-left {
        display: flex;
    justify-content: end;
    }
}
@media (max-width: 1500px) {
    .publish-update-page-left {
        padding: 146px 70px 0px 20px;
    }
}
@media (max-width: 550px) {  
    .tick-for-identify p {
        text-align: start !important;
    }  
}
@media (max-width: 1200px) {  
    .publish-update-page-right img{
        width: 100%;
    }
    .tick-for-identify label {
        margin-right: 0px;
        width: 18px;
        height: 18px;
        min-width: 18px;
    } 
    .mob-conn-publish-top{
        display: flex;
    }
     
    .in-app-timeoutbtns {
flex-direction: column !important;
    }
    .in-app-timeoutbtns .publish-popup-cancel {
        width: 280px !important;
        height: 50px !important;
        margin-top: 0px !important;
        margin-bottom: 20px !important;
        border: 1px solid #BCC1CE !important;
    }
    .time-required-btn{
        flex-direction: column !important;
    }
    .time-required-btn .publish-popup-cancel {
        width: 280px !important;
        height: 50px !important;
        margin-top: 0px !important;
        margin-bottom: 20px !important;
        border: 1px solid #BCC1CE !important;
    }
    .failed-publish-view-err{
        padding: 0 !important;
        border-bottom: unset !important;
    }
    .publish-main-div {
       min-height: 80vh;
    }
    .publish-merged h1 {
        font-size: 24px;
        margin: 25px 0px 6px 0px;
    }
    .publish-merged h2 {
        margin: 0px 15px 20px 15px;
        line-height: 17px;
    }
    .publish-merged-btns a:nth-child(1) {
        width: 40%;
        max-width: 160px;
    }
    .publish-merged-btns button {
        width: 40%;
        max-width: 160px;
    }
    .publish-merged {
        margin-top: 145px;
    }
    .publish-grid h4 {
        display: none;
    }
    .publish-grid h5 {
        display: none;
    }
    .publishstepwise {
        margin-left: 18px;
    }
    .publishconectsteps:last-child{
        margin-left: unset;
    }
    .App-Store-Connect-div {
      
        margin-bottom: 60px;
    }
    .main-publishpage p { 
        margin: 20px 0px 24px;
        text-align: center;
    }
    .publish-headh {
   display: none;
    }
    .comming-soon-publish-btn{
        width: 100%;
    }
    .mob-publish-headh{
        color: #1B2952;
        font-size: 20px;
        font-family: "Axiforma-Bold";
        margin: 0px;
        line-height: 28px;
        display: block;
        margin-bottom: 12px;
    }

.publish-crossmodal {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
    height: 16px;
    width: 16px;
}
.publish-popup-cancel{
    width: unset;
    height: 24px;
    margin-top: 36px;
    border: unset;
    font-size: 16px;
    margin-right: 0px;
}
.publish-popup-confirm{
    width: 280px !important;
    height: 50px;
    font-size: 16px;
    border: none;
}
.publish-popup-bottom {
    flex-direction: column-reverse;
}
.publish-secondmodal .modal-body {
    padding: 0px 20px 0px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.time-out-modal .modal-body {
    padding: 0px 20px 0px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}
    .publish-grid {
        width: unset;
    justify-content: center;
    }
    .publish-appicon {
        margin-top: 32px;
    }
    .publish-parent-app{
        width: 140px;
        min-height: 140px;
    }
    .publish-child-app {
        width: 140px;
        min-height: 140px;
    }

    .publish-firstmodal .modal-body {
        display: flex;
        flex-direction: column;
      }
      .publish-firstmodal-content {
        padding: 0px;
    }
    .publish-firstmodal .modal-content{
        position: relative !important;
    }
      .publish-firstmodal .modal-body {
        padding: 15px;
        display: flex;
        justify-content: center;
      }
      .publish-firstmodal-button {
      flex-direction: column;
      border-top: none;
      padding: 0px;
      }
      .publish-Okay-button{
        width: 100%;
    height: 50px;
    font-size: 16px;
    margin-bottom: 20px;
      }
      .publish-generate-button {
        width: 100%;
    height: 50px;
    font-size: 16px;
    margin-bottom: 20px;
      }
    .mob-publish-btns {
        display: block;
    }

    .mob-publish-paddingdiv {
        padding: 0px 15px 18px 15px;
    }
    .mob-publish-paddingdiv.mob-publish-divpadding{
    padding: 0px 15px 4px 15px;
}
    /* .mob-publish-connect {
        display: block;
        margin: 30px 0px;
    } */

    .mob-publish-Disconnected {
        display: flex;
    }

    .mob-publish-Connected {
        display: flex;
    }

    .mob-publish-Disconnected p {
        font-size: 13px;
        color: #FF4848;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        text-align: left !important;
        display: flex;
        margin-left: 6px;
    }

    .mob-publish-Connected p {
        font-size: 13px;
        color: #52CB86;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        text-align: left !important;
        margin-left: 6px;

    }

    .publishconectmethod {
        display: grid;
        margin-top: 0px;
    }

    .publishconectsteps {
        display: flex;
        width: 100%;
        align-items: unset;
        justify-content: unset;
        margin: 40px 0px 20px;

    }

    .publish-update-page-right {
        width: 100%;
        padding: 0px;
        margin-top: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }

    .publish-update-page-left {
        width: 100%;
        padding: 24px 20px 20px 20px;
    }

    .publish-update-page {
        display: grid;
        background-image: unset;
        justify-content: unset;
    }

    .publish-headh {
        margin-bottom: 20px;
    }

    .publish-update-page-left a {
        width: 100%;
        font-size: 16px;
    }

    .publish-update-page-left h2 {
        font-size: 30px;
        margin-bottom: 10px !important;
        line-height: 40px;
    }

    .publish-update-page-left p {
        margin-bottom: 20px !important;
    }

    .main-publishpage {
        background-color: unset;
        border: unset;
        box-shadow: unset;
    }

    .appleappstore-connect-right {
        display: none;
    }

    .appleappstore-connect-left {
        width: 100%;
        padding: 0px;

    }

    /* .appleappstoreconnect {
        padding: 24px 15px;
    } */

    .appleappstore-connect-history {
        background-color: unset;
        border: unset;
        box-shadow: unset;
        border-radius: unset;
    }

    .appleappstore-table-head {
        display: none;
    }

    .mob-publish-history-head {
        display: block;
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        margin-bottom: 5px;
        text-align: left !important;
        flex-direction: column !important;
    }

    .appleappstore-table-data {
        padding: 0px;
        display: grid;
        background-color: #fff;
        border-bottom: unset;
        border: 1px solid #E3E6F1;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        margin-bottom: 15px;
    }

    .appleappstore-table-data div:first-child {
        width: 100%;
        display: grid;
        border-bottom: 1px solid #E3E6F1;
        padding: 24px 15px;
    }

    .appleappstore-table-data div:first-child p {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .appleappstore-table-data div:nth-child(2) {
        width: 100%;
        border-bottom: 1px solid #E3E6F1;
        padding: 21px 15px 24px 15px;
    }

    .appleappstore-table-data div:nth-child(3) {
        width: 100%;
        border-bottom: 1px solid #E3E6F1;
        padding: 21px 15px 24px 15px;
    }

    .appleappstore-table-data div:nth-child(4) {
        width: 100%;
        border-bottom: 1px solid #E3E6F1;
        padding: 21px 15px 24px 15px;
    }

    .appleappstore-table-data div:nth-child(5) {
        width: 100%;
        padding: 21px 15px 24px 15px;
    }

    .mob-publish-varify-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: "Axiforma-Regular";
        border-radius: 3px;
        border: none;
    }

    .mob-publish-varify-btn.publish-disable {
        color: white;
        background-color: #BCC1CE;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mob-publish-varify-btn.publish-enable {
        color: white;
        background-color: #3064F9;
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .appleappstoreconnect-mob h2{
        margin-bottom: 0px;
    }
    .appleappstoreconnect-mob{
        padding: 20px 15px 15px;
    }
    .mob-publish-save-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: "Axiforma-Regular";
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 3px;
        background-color: unset;

    }

    .mob-publish-save-btn.publish-disable {
        color: #BCC1CE;
        border: 1px solid #BCC1CE;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .mob-publish-save-btn.publish-enable {
        color: #3064F9;
        border: 1px solid #3064F9;

    }
    .publishhistory-head {
        margin-top: 25px;
    }
    .mob-publish-update {
        font-size: 12px;
        font-family: "Axiforma-Regular";
        color: #BCC1CE;
        margin-top: 20px;
        margin-bottom: 0px;
        text-align: center;
    }

    .publish-headh .consent-tooltip {
        display: none !important;
    }

    .publish-head {
        display: flex;
        justify-content: space-between;
    }

    .mob-publish-iossvgdiv {
        display: block;
        width: 40px;
        height: 40px;
        box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        margin-top: -9px;
    }

    .red-publish-iossvgdiv {
        border: 1px solid #FF4848;
        border-radius: 3px;
        background-color: #FFF1F1;
    }
}
@media (max-width: 420px) {  
    .publish-ios-build-version-div input{
        width: 55%;
    }
}