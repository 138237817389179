a{
    text-decoration-line: none !important;
}

/* Bootstrap utility classes from V4 */
.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: 0.25rem !important; }
.ml-2 { margin-left: 0.5rem !important; }
.ml-3 { margin-left: 1rem !important; }
.ml-4 { margin-left: 1.5rem !important; }
.ml-5 { margin-left: 3rem !important; }

.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: 0.25rem !important; }
.mr-2 { margin-right: 0.5rem !important; }
.mr-3 { margin-right: 1rem !important; }
.mr-4 { margin-right: 1.5rem !important; }
.mr-5 { margin-right: 3rem !important; }

.pl-0 { padding-left: 0 !important; }
.pl-1 { padding-left: 0.25rem !important; }
.pl-2 { padding-left: 0.5rem !important; }
.pl-3 { padding-left: 1rem !important; }
.pl-4 { padding-left: 1.5rem !important; }
.pl-5 { padding-left: 3rem !important; }

.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: 0.25rem !important; }
.pr-2 { padding-right: 0.5rem !important; }
.pr-3 { padding-right: 1rem !important; }
.pr-4 { padding-right: 1.5rem !important; }
.pr-5 { padding-right: 3rem !important; } 
.mt-20 {margin-top: 20px !important;}
.mt-30 {margin-top: 30px !important;}
.col-md-12{position: relative !important;}

.accordion {
  --bs-accordion-border-color: transparent;
}
.accordion-button::after{
  display: none!important;
}
.accordion-button:not(.collapsed){
  background-color: transparent !important;
}
.accordion-body{
  padding: 0px!important;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
}
.row{
  margin-right: -15px;
  margin-left: -15px;
}
.row > *{
  padding-right: 15px;
  padding-left: 15px;
}
body {
  font-family: "Axiforma-Regular";
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
  }
}