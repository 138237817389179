@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../../assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins-Light";
  src: url(../../assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}
.notif-cross-ic {
  position: absolute;
  right: 50px;
  top: 12px;
  cursor: pointer;
}
.mobile-notification-save-button {
  display: none;
}
.mob-notification-save-date {
  display: none;
}
.notification-deactivate-customer-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 36px;
}
.notification-app-icon-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pushnotification-section {
  /* max-width: 1920px; */
  margin: 0 auto;
}
.createpushnotificationright-text {
  margin-bottom: 0px;
}
.pushnotification-section .right-panel-gap {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.web-notification-head .consent-tooltip .consent-tooltipsubheader {
  left: 160% !important;
}
.notification-right-page .right_general {
  width: 100%;
  padding: 0px !important;
}
.user-consent-setting {
  margin-right: 0px !important;
}
/*report*/
.pushreporthead h3 {
  font-size: 24px;
  font-family: "Axiforma-Bold";
  /* line-height: 33px; */
  margin-bottom: 0px;
  color: #1b2952;
}
.pushreport-title-div {
  display: flex;
  justify-content: space-between;
}
.os-report-maindiv {
  width: 100%;
}
.icon-btn-disable {
  background-color: #c8cedb;
  color: #ffffff;
  border: none;
  cursor: not-allowed !important;
  box-shadow: none !important;
  cursor: not-allowed;
  width: 100px;
  height: 40px;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.platformstatistics canvas {
  width: 100% !important;
}
.notification-file-formet {
  border: unset;
  width: 140px;
  height: 44px;
  line-height: 44px;
  border-radius: 4px;
  color: #ffff;
  background-color: #3064f9;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
}
.notification-file-formet:hover {
  background-color: #134bec !important;
  transition: 0.5s;
}
.reportstatistics canvas {
  width: 100% !important;
}
.notification-img-gap {
  gap: 20px;
  align-items: center;
}
.os-messagesave-div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.notificationusersreport {
  display: flex;
  justify-content: space-between;
}
.pushreportdonut {
  width: 240px;
  height: 240px !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pushnotificationdata {
  position: absolute;
}
.pushnotificationdata h3 {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}
.pushnotificationdata p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}

.pushreporthead p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  line-height: 18px;
  margin-bottom: 19px;
  text-align: left !important;
  margin-top: 4px;
}
.pushreporthead p span {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin-left: 10px;
}
.pushreport-firstdiv {
  display: flex;
}
.pushreport-seconddiv {
  display: flex;
}
.pushreport-thirddiv {
  display: flex;
}
.deliveredreport {
  width: 33.333%;
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  margin-right: 20px;
  padding: 40px 30px;
}
.opensreport h2,
.deliveredreport h2 {
  font-size: 36px;
  font-family: "Axiforma-Bold";
  line-height: 47px;
  /* display: flex; */
  /* align-items: center; */
  margin-bottom: 0px;
}
.pushnotification-finalselection {
  display: flex;
  justify-content: center;
}
.pushnotification-finalselection div p {
  border: 1px solid #c8cedb;
  width: 140px;
  height: 44px;
  line-height: 40px;
  border-radius: 4px;
  color: #7782a1;
  font-size: 14px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 0px;
}
.pushnotification-finalselection div p:first-child:hover {
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.notification-icon-page {
  margin: 0px !important;
}
.pushnotification-finalselection div:last-child p {
  border: 1px solid #ff4848;
  /* background-color: #ff4848; */
  width: 140px;
  height: 44px;
  line-height: 40px;
  border-radius: 4px;
  color: #ff4848;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 0px;
  /* border: unset; */
}
.notificationcreate-new .consent-setting {
  margin-right: 0px;
}
.notificationcreate-new .consent-setting-clicked {
  margin-right: 0px;
}
.notificationcreate-new .consent-save-date {
  margin-right: 0px !important;
}
/* .notificationcreate-new .send-notificationsend {
  margin-left: 20px;
} */
.notificationcreate-new .send-buttondisabled {
  margin-left: 20px;
}
.pushnotification-finalselection div:last-child p:hover {
  transition: 0.5s;
  box-shadow: 0 2px 4px rgb(255 72 72/29%);
  color: #fff;
  background-color: #ff4848;
}

.pushnotification-finalselection div:last-child p:hover .new-red-loader {
  border: 2px solid #fff;
  border-top: 2px solid transparent;
}
.mob-delete-notification {
  display: none;
}
.opensreport p,
.deliveredreport p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
  text-align: left !important;
  line-height: 31px;
}
.opensreport {
  width: 33.333%;
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
}
.reportstatistics {
  width: 50%;
  background-color: white;
  margin-top: 20px;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 25px 30px 40px 30px;
}
.platformstatistics h2,
.reportstatistics h2 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  line-height: 25px;
}
.platformstatistics {
  width: 50%;
  background-color: white;
  margin-top: 20px;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  padding: 25px 30px 40px 30px;
}
.deliverydata {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 10px;
}
.deliverystatus {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
  line-height: 18px;
}
.notification-confirmdata {
  position: absolute;
  top: -29px;
  left: 3px;
}
.deliverystatus span {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.ioscolor {
  width: 7px;
  height: 7px;
  background-color: #6e94ff;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 10px;
}
.failedunsubscription {
  width: 7px;
  height: 7px;
  background-color: #fc8855;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 10px;
}
.failederror {
  width: 7px;
  height: 7px;
  background-color: #ff5858;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 10px;
}
.remaining {
  width: 7px;
  height: 7px;
  background-color: #bcc1ce;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 10px;
}
.androidcolor {
  width: 7px;
  height: 7px;
  background-color: #a2bbff;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 10px;
}
/* .ratereport{
    width: 33.333%;
    background-color: white;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
 
} */
.firebase a {
  margin-left: 30px;
  color: #3064f9;
  text-decoration: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.newparentsection-date .date-input-post2 {
  height: 40px !important;
  width: 100% !important;
  color: #1b2952 !important;
  padding: 0px;
}
.post-filter-dropdown .dropdown-item.active {
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
  color: #1b2952 !important;
}
.notification-filter-dropdown .dropdown-toggle {
  margin-bottom: 10px !important;
}
.post-filter-dropdown .dropdown-item:hover {
  color: #1b2952 !important;
}
.post-filter-dropdown .dropdown-toggle::after {
  display: none;
}
.post-filter-dropdown .dropdown-menu.show {
  padding: 5px 0px;
}
.pushNotification-app {
  font-size: 9px;
  margin-bottom: 0px;
  color: #a1a1a1;
  font-family: "Axiforma-Regular";
  margin-left: 6.3px;
  margin-right: 5.7px;
  margin-top: 3px;
}
.android-pushnotificationmsg img {
  height: 14px;
  width: 14px;
}
.newnotify-time p {
  color: #a1a1a1;
  font-size: 9px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-left: 5.3px;
}
.iosnotify-time {
  display: flex;
  width: 220px;
  justify-content: space-between;
  align-items: center;
}
.iosnotify-time .newnotification-title {
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 22px);
}
.notification-userdatafilter {
  display: flex;
  margin-top: 11px;
  flex-wrap: wrap;
}
.notification-dot-icon{
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background-color: #707070;
}
.notification-userdatafilter h4 {
  border: 1px solid #e3e6f1;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7782a1;
  margin-right: 6px;
  margin-bottom: 6px;
}
.newnotification-title h2 {
  font-size: 12px;
  font-family: "Axiforma-Bold";
  text-align: left;
  margin-bottom: 0px;
  line-height: 18px;
  color: #333333;
  word-break: break-word;
}
.pushnotification-firebase {
  cursor: pointer;
  width: 115px;
    gap: 6px;
    position: relative;
    height: 40px;
    border-radius: 3px;
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), -3px -3px 3px 0px #ffff;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #1B2952;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
}
.newparentsection-date {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 40px;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 8px;
  background-color: #fff;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  font-family: "Axiforma-Regular";
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: white !important;
}
.notification-userdatafilter h1 {
  font-size: 12px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  display: flex;
  padding: 4px;
}
.newdelete-post-filter {
  display: flex;
}
.newnotification-title p {
  font-size: 10px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin-bottom: 0px;
  line-height: 14px;
  color: #333333;
  word-break: break-word;
}
.schedulestatus {
  font-size: 9px;
  color: #a1a1a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.firebase svg {
  margin-right: 7px;
}

.newmobile-displa {
  max-width: 1920px;
  margin: 0 auto;
}
.push-newnotificationsandroid .message-offerimg {
  width: 36px;
  height: 36px;
}
.message-offerimg {
  width: 22px;
  height: 22px;
  border-radius: 5px;
}
.newnotification-heading {
  width: 100%;
}
.ios-icontochange {
  margin-right: 16px;
}
.notification-title {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
  margin-bottom: 0px;
  word-break: break-word;
  margin-top: 0px;
}
.rich-notification-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.os-engaged-user {
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
}
.newnotification-heading {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.newscheduled {
  margin-bottom: 0px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  border: none;
  text-align: left !important;
  margin-bottom: 0px;
  line-height: 14px;
}
.firebasenotificationhead {
  margin-bottom: 20px;
}
.notification-description {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
  /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  /* width: calc(100% - 60px); */
  text-align: left !important;
  word-break: break-word;
  /* -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; */
}
.newnotification-time {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin-bottom: 0px;
  text-align: left !important;
}
.firebase p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.web-notification-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;
}
.web-notification-head h1 {
  font-size: 24px;
  line-height: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.os-list-notification-list {
  display: flex;
  align-items: center;
}
.notification-modal-tab {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 20px;
  align-items: end;
  height: 40px;
}

.notification-modal-tab .notificationlink a {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin: 0px 60px -5px 0px;
  border-bottom: 2px solid transparent;
  padding-bottom: 10px;
  transition: 0.5s;
}

.notification-modal-tab div a.active,
.notification-modal-tab div a:hover {
  color: #3064f9;
  border-bottom: 2px solid #3064f9;
  transition: 0.5s;
}

.web-notification-head .consent-tooltip {
  margin-left: 6px;
}

.right-panel-gap-notification {
  background-color: #f8fafe;
  padding: 21px 30px;
  width: calc(100% - 210px);
  margin-left: 210px;
  margin-top: 60px;
  position: relative;
  transition: 0.4s;
  min-height: calc(100vh - 60px);
}
.newnotimage {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  width: 80px;
  height: 80px;
}
.newnotificationimag {
  margin-right: 20px;
}
.notification-main-page {
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  justify-content: center;
  margin: 0px !important;
  position: relative;
  background-size: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  min-height: calc(100vh - 208px);
}
.nodatintable {
  min-height: calc(100vh - 208px);
  display: flex;
  /* margin-top: 247px; */
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.usertableheader {
  background-color: #fff;
  border-bottom: 1px solid #f1f2f8;
  padding: 9px 70px 9px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  display: flex;
  justify-content: space-between;
}
.usertabledata {
  background-color: #fff;
  border-bottom: 1px solid #f1f2f8;
  padding: 40px 65px 40px 20px;
  display: flex;
  justify-content: space-between;
}
.user-languagecode {
  width: 8% !important;
}
.user-lastactivedate {
  width: 15.5% !important;
}
/* .os-engaged-user{
    margin-top: 6px;
} */
/* .os-image-margin{
    gap: 20px;
    display: flex;
} */
.mob-os-engaged-user {
  display: none;
}
.os-listshow {
  display: inline-block !important;
  line-height: 24px;
  color: #bcc1ce !important;
  font-size: 12px;
}

.os-listshow span {
  margin-right: 2px;
}
.os-engaged-user .os-engaged-userptag {
  border: 1px solid #e3e6f1;
  border-radius: 20px;
  display: inline-block;
  /* align-items: center;
    justify-content: center; */
  padding: 4px 10px;
  margin-top: 0px;
  /* margin-right: 6px; */
  font-size: 10px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.os-engaged-user .os-engaged-userptag svg {
  margin-left: 5px;
}
.os-notimage {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  width: 80px !important;
  height: 80px;
  border-radius: 3px;
}
.pushnotification-lastsetSend {
  width: 100%;
}
.pushnotification-lastsetSend .publish-immediately {
  display: flex;
  justify-content: space-between;
}
.os-notimage-default {
  background-image: url(../../assets/images/dashboard/no-img-category.png);
}
.os-notimage img {
  height: 80px;
}
.usertabledata p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
  width: 11%;
  text-align: left !important;
}
.first-usertabledata svg {
  margin-left: 22px;
}
.mob-user-lastactive,
.mob-user-duration {
  display: none;
}
.usertableheader p {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  width: 11%;
  text-align: left !important;
}
/* .notificationusertable{
    min-height: calc(100vh - 208px);
} */
.nodatintable p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-top: 30px;
}
.notification-userupgate-page {
  background-color: #fff;
  max-height: 800px;
  background-image: url(../../assets/images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  /* justify-content: center; */
  margin: 0px !important;
  position: relative;
  background-size: contain;
  min-height: calc(100vh - 215px);
  display: flex;
}
.notification-right-page {
  background-color: #fff;
  /* max-height: 800px; */
  background-image: url(../../assets/images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  justify-content: center;
  margin: 0px !important;
  position: relative;
  background-size: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  min-height: calc(100vh - 217px);
  width: 100%;
}
.notification-userupgate-page .pushnot-upgradeimg {
  padding-top: 171px !important;
  padding-right: 20px;
}
.notification-userupgate-page .pushnot-upgradetext {
  padding-top: 164px !important;
}
.osnotification-upgrade-page {
  background-image: url(../../assets/images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative;
  background-size: contain;
  min-height: calc(100vh - 215px);
}

.pushnotification-right_panel {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e3e6f1;

  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  min-height: calc(100vh - 179px);
}

.pushnotification-platform {
  display: flex;
}

.pushnot-upgradetext {
  padding: 184px 259px 20px 120px !important;
  flex: 0 0 47%;
  /* max-width: 50%; */
  display: flex;
  /* justify-content: end; */
}
.notification-userupgate-page .pushnot-upgradetext {
  flex: 0 0 46%;
}
.pushnot-upgradetext.richpushnot-upgradetext {
  padding: 171px 204px 20px 120px !important;
}
.pushnot-upgradeimg.richpushnot-upgradeimg {
  padding-top: 171px !important;
}
.pushnot-upgradeimg {
  /* padding-right: 200px; */
  margin-left: 0px;
  padding-top: 132px !important;
  /* flex: 100%; */
  /* flex: 0 0 50%;
    max-width: 50%; */
  /* display: flex; */
}
.pushnotification-icon {
  padding-bottom: 74px !important;
  max-height: 886px !important;
}
.pushnot-upgradetext h3 {
  font-size: 48px;
  font-family: "Axiforma-Bold";
  line-height: 69px;
  margin-bottom: 10px;
  color: #1b2952;
}
.pushnotification-app-icon-small {
  width: 261px !important;
  background-color: white;
  border-radius: 14px !important;
  left: 21px !important;
  padding: 9px !important;
  position: absolute;
  top: 225px;
  display: flex;
}
.pushnot-upgradetext p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  line-height: 18px;
  text-align: left !important;
  margin-bottom: 30px;
}
.pushnotification-app-iconpreview {
  margin-left: 8px;
}
.pushnotification-app-iconpreview h3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}
.pushnotification-app-iconpreview span {
  font-size: 9px;
  color: #a1a1a1;
  font-family: "Axiforma-Regular";
  line-height: 13px;
}
.pushnotification-app-iconpreview p {
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  line-height: 14px;
}
.pushnotificationapp-icon-small-preview {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pushnotificationapp-icon-small-preview img {
  border-radius: 10px;
}
.pushnotification-app-iconpreview h2 {
  font-size: 12px;
  color: #333333;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  line-height: 18px;
}
.pushnot-upgradetext ul {
  padding: 0px 0px 0px 15px;
  position: relative;
}

.pushnot-upgradetext li {
  font-size: 13px;
  line-height: 17px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  list-style-type: none;
  margin-bottom: 23px;
  position: relative;
}

.pushnot-upgradetext li::before {
  content: url("../..//assets/images/dashboard/cms/dot.png");
  position: absolute;
  left: -15px;
  top: -2px;
  opacity: 0.5;
}
.notificationsend-all label {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 6px !important;
  color: #7782a1;
  margin-top: 17px;
}
.sendallmargin-top {
  margin-top: 28px;
}
.notificationcreate-new a {
  border: unset !important;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notificationcreate-new a:hover {
  border: unset !important;
}
.notification_upgradebtn {
  background-color: #3064f9;
  /* text-decoration: none; */
  border-radius: 3px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  border: none;
  line-height: 15px;
  transition: 0.5s;
  cursor: pointer;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_upgradebtn:hover {
  background-color: #134bec;
  color: white;
}

.notification-createbtn {
  background-color: #3064f9;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  border: none;
  line-height: 15px;
  transition: 0.5s;
  cursor: pointer;
  width: 120px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 30px; */
  margin-right: 0px !important;
  /* margin-left: 20px; */
  cursor: pointer;
  &:hover {
    background-color: var(--custom_color5) !important;
    color: var(--custom_color4) !important;
    /* border: 1px solid var(--custom_color6)!important; */
  }
}

.notification-createbtn:hover {
  background-color: #134bec;
  color: white !important;
  border-bottom: unset !important;
}

.pushnotification-notavalable {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.plain-notify-buttons {
  gap: 20px;
}

/* .plian-web-notification-head h1{
    align-items: flex-start !important;
} */
/* .plian-web-notification-head .notificationcreate-new{
    margin-top: -5px !important;
} */
.plain-notification-right-page {
  min-height: calc(100vh - 176px);
}
.plain-notification-right-page .notification-table {
  padding: 11px 24px 10px 20px;
}
.plain-notification-right-page .newpushcommon-height {
  padding: 24px 24px 24px 20px;
}

.pushnotification-notavalable p {
  font-size: 14px;
  color: #7782a1;
  margin-top: 30px;
  font-family: "Axiforma-Regular";
}

.notificationtab {
  width: 100%;
  background: white;
  border-radius: 6px;
}
.firebase_button_disable {
  cursor: not-allowed;
  border: 1px solid #7782a1;
  border-radius: 4px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 160px;
  /* margin-right: 20px; */
}
.firebase_button_disable svg {
  fill: #7782a1;
}
.firebase_button_disable p {
  font-size: 14px;
  color: #7782a1;
  margin: 3px 6.4px 0px 6px;
  font-family: "Axiforma-Regular";
}
.createpushnotification {
  display: flex;
}

.createpushnotificationleft {
  padding: 0px 35px 0px 30px;
  width: 50%;
}

.createpushnotificationright {
  width: 50%;
  justify-content: center;
  display: flex;
  padding-right: 20px;
}

.newtop-portion {
  margin-bottom: 18px;
  /* margin-top: -10px; */
  display: flex;
}

.pushnotification-title {
  width: 100%;
  /*padding: 30px 0px 30px 0px;*/
  border-bottom: 1px solid #e3e6f1;
  /* margin-bottom: 30px;
    cursor: pointer; */
}
.pushnotification-title-div {
  width: 100%;
  /* padding: 30px 0px 30px 0px; */
  border-bottom: 1px solid #e3e6f1;
}
.planenotification-input-box {
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  color: #1b2952;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  padding: 16px 103px 16px 15px !important;
  width: 100%;
}
.planenotification-input-box:hover,
.planenotification-input-box:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.planenotification-input-div {
  margin-bottom: 30px;
  position: relative;
}
.pushnotification-title .form-group input {
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  color: #1b2952;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  padding: 16px 103px 16px 15px !important;
}
.image-upload-input-placeholder {
  color: #bcc1ce;
}
.pushnotification-title .form-group input::placeholder {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
}
.react-datepicker__day--in-range {
  border-radius: 100% !important;
  background-color: #5884ff !important;
}
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #5884ff !important;
}
.pushnotification-title .form-group {
  margin-bottom: 30px !important;
  position: relative;
}
.pushnotification-title .form-group textarea:hover,
.pushnotification-title .form-group input:hover {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.pushnotification-title .form-group textarea:focus,
.pushnotification-title .form-group input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.popupimage-uploader {
  border: 1px solid #bcc1ce;
  background-color: #fff;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  transition: 0.5s !important;
}
.notification-app-icon {
  max-height: unset !important;
}
.popupimage-uploader div {
  width: 96%;
}
.link-popuphttps {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  width: 80px;
  /* display: flex;
    justify-content: center; */
}
.link-form-group label {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
/* .link-popupinput{
    width: 100%;
    border: none !important;
    border-left: 1px solid #BCC1CE !important;
    border-radius: 0px !important;
} */
.link-form-group {
  margin-bottom: 30px !important;
  position: relative;
  /*margin-top: 25px;*/
}
.link-popupinput {
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  color: #1b2952;
  padding: 16px 15px !important;
  width: 100%;
  border: none !important;
  border-left: 1px solid #bcc1ce !important;
  border-radius: 0px !important;
}
.link-popupinput:hover,
.link-popupinput:focus {
  border-left: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.link-popupinput:focus .popupimage-uploader {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.popupimage-uploader p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1b2952;
  font-size: 13px !important;
  margin-left: 7px;
}
.popupimage-uploader .uploadpixabayimage {
  background-position: 50% !important;
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
}
.popuplink-uploader {
  border: 1px solid #bcc1ce;
  background-color: #fff;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0px;
  transition: 0.5s !important;
  padding: 16px 15px 16px 15px;
}
.listselected-frompopup {
  font-size: 14px;
  color: #5f6578;
  text-align: left !important;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 50px);
}
.popupLink-url-input {
  margin-top: 20px;
}
.popuplink-uploader:hover {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.popupLink-url-input:focus-within {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.newtop-portion .app-dashboard-head {
  margin: unset !important;
}
.newparentsection-selectdate:focus-within {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.link-popupinput:hover .popuplink-uploader {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.link-popupinput:focus .popuplink-uploader {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.popupimage-uploader:focus,
.popupimage-uploader:hover {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
/* .pushnotification-lastsetting input:focus .newparentsection {
    border: 1px solid #3064F9!important;
    background-color: #F8FAFE!important;
    transition: .5s;
}

.pushnotification-lastsetting input:hover .newparentsection {
    border: 1px solid #3064F9!important;
    background-color: #F8FAFE!important;
    transition: .5s;
} */
.pushnotification-titlefirstdiv {
  /* margin-bottom: 30px; */
  position: relative;
  cursor: pointer;
  padding: 29px 0px;
}

.pushnotification-titlefirstdiv p {
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}

.pushnotification-title .form-group span {
  position: absolute;
  top: 18px;
  right: 15px;
  font-size: 10px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  /* background-color: white; */
  /* padding: 0px 10px; */
}
.pushnotification-title
  .form-group
  textarea:hover
  .pushnotification-title
  .form-group
  span,
.pushnotification-title
  .form-group
  input:hover
  .pushnotification-title
  .form-group
  span {
  background-color: #f8fafe !important;
}
.pushnotification-title
  .form-group
  textarea:focus
  .pushnotification-title
  .form-group
  span,
.pushnotification-title
  .form-group
  input:focus
  .pushnotification-title
  .form-group
  span {
  background-color: #f8fafe !important;
}
.pushnotification-title .form-group textarea {
  height: 120px;
  resize: none;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  font-size: 13px;
  color: #1b2952;
  padding: 16px 15px;
  font-family: "Axiforma-Regular";
}
.notification-image-uploader {
  width: 100%;
  height: 100%;
}
.planenotification-textarea-box {
  height: 120px;
  resize: none;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  font-size: 13px;
  color: #1b2952;
  padding: 16px 15px;
  font-family: "Axiforma-Regular";
  width: 100%;
  outline: unset;
}
.planenotification-textarea-box:hover,
.planenotification-textarea-box:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.planenotification-textarea-box::placeholder {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
}
.pushnotification-title .form-group textarea::placeholder {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
}
.textarea-count {
  top: unset !important;
  bottom: 17px;
}
.pushnotification-lastsetting {
  width: 100%;
}

.pushnotification-lastsetting .publish-immediately {
  display: flex;
  justify-content: space-between;
}
.newparentsection {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 18px 15px;
  background-color: #fff;
  margin-top: 10px;
}
.newparentsection-selectdate {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 10px;
}
.newparentsection-selectdate .date-input-post2 {
  width: 100% !important;
  border: unset;
  height: 100%;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin: 0px;
  outline: unset;
  background-image: url(../images/dashboard/cms/calendar.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  padding: 15px;
}
.newparentsection-selectdate .react-datepicker__input-container {
  width: 100%;
}
.newparentsection-selectdate .react-datepicker-wrapper {
  width: 100%;
}
.pushnotification-lastsetting input {
  height: 40px !important;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0px 15px 0px 0px;
  font-family: "Axiforma-Regular";
}
.notificatiosetting-head {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 12px;
  line-height: 18px;
}
/* .notificationsetting-div p {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  padding-bottom: 20px;
  line-height: 13px;
  margin-bottom: 0px;
  text-align: left !important;
} */
.notificationsetting-para {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  padding-bottom: 20px;
  line-height: 13px;
  margin-bottom: 0px;
  text-align: left !important;
}
.notificationsetting-ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding-top: 28px;
}
.notificationsetting-ul .notificationsetting-li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}
.notificationsetting-ul .notification-li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}
/* .notificationsetting-ul p {
  margin-bottom: 0px;
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
} */
.notification-setting-toggle-div {
  margin-bottom: 0px;
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.notificationsetting-div {
  border-bottom: 1px solid #e3e6f1;
  padding-top: 25px;
}
.pushnotification-savedraft {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background: none;
  border: 1px solid #3064f9;
  width: 120px;
  /* margin-right: 20px; */
  border-radius: 3px;
  line-height: 38px;
  height: 40px;
  /*&:hover {
        transition: .5s;
        box-shadow: 0 2px 4px rgb(112 155 250/69%);
        background-color: var(--custom_color5)!important;
        color: var(--custom_color4)!important;
        border: 1px solid var(--custom_color6)!important;
      }*/
}

.pushnotification-savedraft:hover {
  transition: 0.5s;
  box-shadow: 0 2px 4px rgb(112 155 250/69%);
  /* background-color: var(--custom_color5)!important; */
  color: var(--custom_color5) !important;
  border: 1px solid var(--custom_color6) !important;
}

.notificationmodal {
  padding: 15px 20px !important;
}
.newtabwrapper {
  padding-top: 75px !important;
  position: relative;
}
.techselect svg {
  fill: #7782a1;
  cursor: pointer;
}
.technotselect svg {
  fill: #e3e6f1;
  cursor: pointer;
}
.newplatswitch {
  /* position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 25px; */
  margin: 40px 0px 20px;
}
.push-newnotifications {
  width: 88%;
  margin: auto;
  position: absolute;
  padding: 8px 8px 15px 8px;
  top: 222px;
  right: 0px;
  background-color: #fff;
  left: 0px;
  border-radius: 8px;
  z-index: 0;
  top: 232px;
}
.push-newnotificationsandroid {
  top: 128px;
}
.pushmsg-aerodown {
  width: 18px;
  height: 12px;
  background-color: #f2f2f2;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .new-pus_notification_head h2 {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
} */
.newmobile_bg {
  position: relative;
  width: 300px;
  /* height: 514px; */
  margin: auto;
}
.newpush-notification-body .round-corner {
  border: 1px solid #e3e6f1;
  background-color: #f8fafe;
  padding: 20px;
  border-radius: 5px;
}
.newpush-notification-body .round-corner img {
  border-radius: 6px;
  margin-top: 16px;
  width: 100%;
}

.newpush-notification-body .round-corner h4 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 8px;
  word-break: break-word;
}

.newpush-notification-body .round-corner p {
  margin-bottom: 0px !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  text-align: left !important;
  word-break: break-word;
}

.newpush-notification-body {
  padding: 0px 20px 34px 20px !important;
}

.push-messagesendto {
  display: flex;
  align-items: center;
  margin-top: 28px;
}

.push-messagesendto p {
  margin-right: 55px;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  color: #bcc1ce;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  /* margin-top: 3px; */
}

.push-messagesendto .deviceSelected {
  column-gap: 20px;
  display: flex;
}
.pushnotification-schedule h4 {
  margin-right: 40px;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  color: #bcc1ce;
  margin-bottom: 0px;
}

.pushnotification-schedule {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.pushnotification-schedule span {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.pushnotification-scheduleaudience h4 {
  margin-right: 43px;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  color: #bcc1ce;
  margin-bottom: 0px;
  width: 60px;
}

.pushnotification-scheduleaudience {
  display: flex;
  /* align-items: center; */
  margin-top: 25px;
  /* flex-wrap: wrap; */
}
.pushnotification-scheduleaudience p {
  margin-bottom: 0px;
  /*padding-bottom: 6px;*/
  display: flex;
  flex-wrap: wrap;
}
.pushnotification-scheduleaudience p svg {
  margin-left: 6px;
}
.pushnotification-scheduleaudience .notification-audience-div {
  border: 1px solid #e3e6f1;
  padding: 4px 8px 4px 10px;
  border-radius: 20px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-right: 6px;
  font-size: 10px;
  margin-bottom: 6px;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  min-width: fit-content;
}
[dataclass~="newnotification"] .modal-footer {
  border-top: 1px solid #e3e6f1 !important;
  padding: 14px 20px;
}

.newpus_notification_footer {
  margin: 0px !important;
}

.push-notificationcancel {
  background: none;
  font-size: 13px;
  height: 40px;
  width: 100px;
  border: 1px solid #bcc1ce;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: pointer;
  /* line-height: 40px; */
  padding: 10px 20px 12px;
}
.push-notificationcancel:hover {
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.push-notificationsend {
  background: #3064f9;
  font-size: 13px;
  height: 40px;
  width: 100px;
  border: none;
  color: white;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: pointer;
  line-height: 40px;
  transition: 0.5s;
}
.push-notificationsend:hover {
  background-color: #134bec;
}
.notimobimg img {
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
}
.send-notificationsend {
  background: #3064f9;
  font-size: 14px;
  height: 40px;
  width: 100px;
  border: none;
  color: white;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: pointer;
  line-height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    transition: 0.5s;
    box-shadow: 0 2px 4px rgb(112 155 250/69%);
    background-color: var(--custom_color5) !important;
    color: var(--custom_color4) !important;
    /* border: 1px solid var(--custom_color6) !important; */
  }
}
.review-notification-header {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}
.newcrossmodal {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  display: flex;
  width: 10px;
}

.notification-table {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f2f8;
  padding: 8px 24px 9px 20px;
}

/* .newsocial_pannel {
    margin: 0px -15px;
} */

.notification-message {
  /* width: calc(100% - 800px); */
  width: 40%;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 12px;
}

.notification-platform {
  width: 10%;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 12px;
}

.mob-notification-label {
  display: none;
}

.newnotification-status {
  width: 10%;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 12px;
  display: flex;
}
.statusdate-table {
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}
.pushnotification-delete {
  text-align: center;
}
.message-delete {
  width: 5%;
  gap: 25px;
}
.message-delete svg {
  cursor: pointer;
}
.notification-blank {
  width: 5%;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.secondnotificationmodal {
  padding: 0px !important;
  height: 25px;
}

.secondnewpush-notification-body {
  padding: 20px 30px 50px 30px !important;
}

.secondmodal-texts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.pushnotification-firebase-head-text {
  font-family: "Axiforma-Bold";
  font-size: 24px !important;
  color: #1b2952;
  text-align: center;
  line-height: 25px;
  margin-bottom: 17px;
}
.secondmodal-texts h2 {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1b2952;
  text-align: center;
  line-height: 25px;
  margin-bottom: 17px;
}
.deletemsg-icon {
  cursor: pointer;
}
.pushnotification-longtext {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  line-height: 17px;
  text-align: center;
  width: 85%;
  margin: auto;
  margin-bottom: 26px;
}

.secondmodal-pushbutton {
  display: flex;
  gap: 20px;
}
.planenotification-image-div {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  width: 64px !important;
  height: 64px;
  border-radius: 3px;
}
.plane-notification-img {
  object-fit: cover;
}
.pushfirstbutton {
  border: 1px solid #3064f9;
  border-radius: 4px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 160px;
  margin-right: 20px;
}

.pushfirstbutton p {
  font-size: 14px;
  color: #3064f9;
  margin: 0px 6.4px 0px 4px;
  font-family: "Axiforma-Regular";
}

.pushsecondbutton {
  border: 1px solid #3064f9;
  border-radius: 4px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 160px;
}

.pushsecondbutton p {
  font-size: 14px;
  color: #3064f9;
  margin: 0px 6.4px 0px 4px;
  font-family: "Axiforma-Regular";
}

.newcrossmodaltwo {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  height: 10px;
  display: flex;
}

.newpushcommon-height {
  padding: 26px 24px 26px 20px;
  border-bottom: 1px solid #f1f2f8;
  display: flex;
}

/* .notification-delete {
    margin-right: 27px;
} */

.pushnotification-selecttech {
  margin-bottom: 0px;
  text-align: left !important;
  display: flex;
  gap: 20px;
}

.notificationcreate-new {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* .pushnotification-firebase {
    margin-right: 20px;
} */
.mob-notification-userdatafilter {
  display: none;
}
.pushnotification-Search {
  width: 300px;
  border: 1px solid #e3e6f1;
  border-radius: 4px;
  background-color: white;
  height: 40px;
  margin-right: 0px;
  display: flex;
  box-shadow: 0px 3px 6px 0px #bcc1ce33;
}

.pushnotification-Search form {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}

.pushnotification-Search button {
  border: none;
  background: white;
  width: 41px;
  background: none;
  border: none;
  height: 100%;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pushnotification-Search input {
  font-size: 13px !important;
  width: 100%;
  border: none;
  /* height: 39px; */
  background-color: transparent;
  outline: none !important;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  padding: 0px 0px 0px 0px !important;
  height: 40px;
}

.pushnotification-Search input:focus {
  border: none;
}
.notification-filterapplyed-reddot {
  top: -1px !important;
}
.notification-upgrade-support {
  text-align: unset !important;
}
.notification-upgrade-feature {
  margin-bottom: 0px;
}
.upgrade-feature-with-margin {
  margin-bottom: 15px;
}
.createdNotification-review {
  max-width: 600px !important;
  max-height: 350px !important;
}
.unlink-app-popup {
  max-width: 480px !important;
  max-height: 270px !important;
}
.pushnotification-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.created-msg-review-modal {
  max-width: 600px !important;
  max-height: 350px !important;
}
.notification-filter-red-dot {
  top: 8px;
}
.build-setting-error-modal .publish-crossmodal {
  z-index: 9;
}
.build-setting-error-modal {
  max-width: 480px !important;
  max-height: 280px !important;
}
.deleteNotification-modal {
  max-width: 480px !important;
  max-height: 270px !important;
}
.savedraft-buttondisabled {
  width: 120px;
  height: 40px;
  border: none;
  color: white;
  background-color: #bcc1ce;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: not-allowed !important;
  /* margin-right: 20px; */
}
.send-buttondisabled {
  width: 100px;
  height: 40px;
  border: none;
  color: white;
  background-color: #bcc1ce;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: not-allowed !important;
  justify-content: center;
  display: flex;
  align-items: center;
}
.pushnotification-filterclose {
  position: fixed;
  right: -400px;
  /* right: 0px; */
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 99999;
  border-left: 1px solid #e3e6f1;
  border-top: 1px solid #e3e6f1;
  padding: 22px 19px 75px 21px;
  overflow-y: auto;
}

.pushnotification-filteropen {
  position: fixed;
  /* right: -400px; */
  right: 0px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 999;
  border-left: 1px solid #e3e6f1;
  border-top: 1px solid #e3e6f1;
  padding: 22px 19px 75px 21px;
  overflow-y: auto;
}

.push-filterhead {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  line-height: 18px;
  color: #1b2952;
  margin-bottom: 20px;
}

.push-resetbtn {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #7782a1 !important;
  line-height: 12px;
  cursor: pointer;
}

.add-more-field {
  color: #3064f9 !important;
  font: 14px "Axiforma-Regular";
  margin-left: 5px;
  margin-bottom: 0px;
  line-height: 14px;
  margin-top: 2px;
}

.pushNotification-filteradd {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px 0px;
  cursor: pointer;
  align-items: center;
}

.pushNotificationfilter-input {
  width: 100%;
  height: 40px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 13px !important;
  color: #1b2952;
  padding: 12px 15px;
  font-family: "Axiforma-Regular";
  line-height: 40px;
}

.pushNotificationfilter-input:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
}

.pushdelete-fieldsvg {
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-bottom: 0px !important;
  padding: 0px 12px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pushdelete-fieldbtn {
  display: flex;
}

.andpushnotificaion {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #c8cedb;
  margin-top: 2px;
  margin-bottom: 14px;
}

.notification-setting {
  width: 40px !important;
  height: 40px;
  border-radius: 3px;
  color: white;
  background-color: #f8fafe;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.2), 0px 0px 3px 3px #ffff;
  margin-right: 0px;
  justify-content: center !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
}
.notification-red-dot {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #ff4848;
  position: absolute;
  top: 10px;
  right: 10px;
}
.mobile-schedulebtn {
  display: none;
}

.mobnewdraft_fotter {
  display: none;
}
.nwcreate-notification-head .appdash-tooltip {
  display: none;
}
.webnewdraft_fotter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.plain-notify-buttons .common-navigation-setting-btn-clicked {
  margin-left: 0px !important;
}
.update-mob-upgadehead {
  display: none;
}
.richNotification-setting-btn{
  margin-right: 20px;
  margin-left: 20px;
}
.textnumber-count {
  position: absolute;
  right: 15px;
  font-size: 10px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  bottom: 17px;
  top: unset !important;
}
.pushnotification-title-text {
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}
.push-mobtopview {
  display: none;
}

/* .webnewdraft_fotter button{
 width: 50%;
} */
.pushnotification-mobfilterdiv {
  display: none;
  position: relative;
}

.newimage-noftication {
  width: 40%;
}

.pushnotificationstatus-edit {
  display: contents;
}
.newapp_iconname {
  margin-top: -10px;
}
.newapp_iconname h3 {
  font: 24px "Axiforma-Bold";
  margin-bottom: 0px;
  color: #1b2952;
}
.newnotify-y-n {
  width: 10%;
}
.uniquepara {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.unique-data {
  font-size: 13px !important;
  color: #bcc1ce !important;
}

/* build-setting-page */

.build-setting-page {
  padding: 20px 30px;
}
.build-setting-pagehead {
  border-bottom: 1px solid #e3e6f1;
}
.build-setting-android {
  border-bottom: 1px solid #e3e6f1;
  /* padding: 30px 0px; */
}
.build-setting-ios {
  /* padding: 30px 0px; */
  border-bottom: 1px solid #e3e6f1;
  /* margin-bottom: 70px; */
}
.build-setting-pagehead h2 {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}

.build-setting-pagehead p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 17px;
  text-align: left !important;
}
.build-setting-android-head {
  display: flex;
  align-items: center;
}
.build-setting-bundle {
  display: flex;
  align-items: center;
}
.build-setting-androidtop {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 30px 0px;
}
.buils-setting-toggle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}
.android-build-setting-div {
  width: 50%;
  padding-right: 20px;
}
.build-setting-googleinput {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 7px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  position: relative;
}
.upload-crossicon {
  position: absolute;
  right: 15px;
}
.build-setting-googleinput svg {
  cursor: pointer;
}

.build-setting-inputfirstdiv p {
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 8px;
  word-break: break-all;
  width: calc(100% - 54px);
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.build-setting-inputfirstdiv .download-bayimage {
  width: 36px;
  height: 36px;
}
.build-setting-inputfirstdiv .documentdiv {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  background-color: #edf0f5;
  border-radius: 3px;
  justify-content: center;
  cursor: pointer;
}
.build-setting-inputfirstdiv svg {
  margin: 0px !important;
  cursor: pointer;
}
.build-setting-inputfirstdiv {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
}
.build-setting-googleinput label {
  margin-bottom: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 30px);
  cursor: pointer;
}

.build-setting-android-icon {
  padding: 13px 15px;
  background-color: #f8fafe;
  border-radius: 3px;
  margin-right: 20px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.build-setting-android-head p {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  color: #1b2952;
}
.build-setting-bundle p {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-right: 6px;
  text-align: left !important;
  cursor: default;
}
.build-setting-bundle p span {
  color: #1b2952;
  word-break: break-all;
}
.build-setting-bundleright {
  display: flex;
  margin-right: 35px;
  align-items: center;
  justify-content: end;
}
.buils-setting-toggle h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.buils-setting-toggle svg {
  margin-left: 7px;
}
.apns-certificate-input {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  resize: none;
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  outline: none;
  padding: 15px 16px;
}
.build-setting-googlefield {
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}
.build-setting-googlefield h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  /* margin-right: 10px; */
  margin-bottom: 9px;
}
.build-setting-googlefield svg {
  margin-left: 7px;
}
.build-setting-text h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  /* margin-right: 10px; */
  margin-bottom: 9px;
}
.build-setting-text svg {
  margin-left: 7px;
}
.build-setting-text textarea {
  width: 100%;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  resize: none;
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  outline: none;
  padding: 15px 16px;
  margin-bottom: 25px;
}
.build-setting-text textarea:hover,
.build-setting-text textarea:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.mobbuild-setting-bundleright {
  display: none;
}
.build-settingmob {
  display: flex;
  align-items: center;
}
.build-settingmob-icon {
  margin-left: 0px !important;
}
.build-settingmob .pushnotification-build-setting-icon {
  margin-left: 20px;
}
.richpushnot-mobupgradeimg {
  display: none;
}
.notification-sha-key {
  position: absolute;
  top: 36px;
  right: 15px;
  padding-left: 10px;
  background-color: #ffff;
}
.notification-sha-key .new-blue-loader {
  margin-top: 6px;
}
.notification-sha-generate {
  font-size: 13px;
  color: #3064f9;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
  margin-top: 4px;
  padding-left: 10px;
  background-color: #ffff;
}
.firebase-build-setting-page {
  margin: 0px -15px;
}

.mobsend-notificationsend {
  display: none;
}

.notification-lock-switch.notification-monitor-slider-padding {
  padding: 0;
}
.notification-lock-switch input {
  display: none;
}
.notification-lock-switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
  margin-bottom: 0px;
}

.notification-access-slider.round {
  border-radius: 34px;
}
.notification-access-slider {
  background-color: #d7dbeb;
  /* background-image: url(../../assets/images/dashboard/togglecross.png); */
  background-image: url(../../assets/images/dashboard/toggle-lock.png);
  /* background-size: 7px 7px; */
  background-size: 8.47px 11.29px;
  background-repeat: no-repeat;
  /* background-position: bottom 6.2px right 8.8px; */
  background-position: bottom 4px left 26px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

/*input:checked + .notification-access-slider:before {
    transform: translateX(20px);
    background-color: #fff;
}*/

.notification-access-slider.round:before {
  border-radius: 50%;
}
.notification-access-slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
}

.filter-delete-pushnotification {
  width: 44.3px !important;
  margin-bottom: 10px !important;
}

.notification-report-spinner {
  width: 100%;
  height: 87vh;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  border: 1px solid #e3e6f1;
  border-radius: 6px;
}
.notification-report-spinner .newspinner {
  border-radius: 6px;
}
.createnotificationmodal .modal-content {
  overflow-y: auto;
}

/* Merge */
.notification-merged {
  margin-top: 171px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.notification-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.notification-parent-app {
  background-color: #fff;
  border-radius: 8px;
  width: 220px;
  min-height: 220px;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
  text-align: center;
  position: relative;
  padding: 0px 10px;
}

.notification-parent-tag,
.notification-child-tag {
  border: 1px solid #e3e6f1;
  border-radius: 30px;
  width: 90px;
  height: 22px;
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
}

.notification-parent-tag h3,
.notification-child-tag h3 {
  font-family: "Axiforma-Regular";
  font-size: 10px;
  color: #bcc1ce;
  text-align: center;
  margin-top: 4px;
}

.notification-appicon {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgb(188 193 206 / 30%);
  margin: auto;
  margin-top: 42px;
  margin-bottom: 20px;
}
.notification-appicon img {
  width: 100%;
}
.notification-merged-parent-app-name {
  font-family: "Axiforma-Bold";
  font-size: 15px;
  color: #1b2952;
}

.notification-merged-parent-app-id {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  word-break: break-word;
}

.notification-child-app {
  background-color: #fff;
  border-radius: 8px;
  width: 220px;
  min-height: 220px;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
  text-align: center;
  position: relative;
  padding: 0px 10px;
}

.notification-merged h1 {
  font-family: "Axiforma-Bold";
  font-size: 30px;
  color: #1b2952;
  margin: 60px 0px 20px 0px;
}

.notification-merged h2 {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782a1;
  max-width: 800px;
  margin: auto;
  margin: 0px 0px 40px 0px;
  line-height: 18px;
}

.notification-merged-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.notification-merged-btns a:nth-child(1) {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #3064f9;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 50px;
  line-height: 48px;
  display: block;
  width: 160px;
  text-align: center;
  /* margin-right: 10px; */
}

.notification-merged-btns a:nth-child(1):hover {
  color: #134bec;
  border: 1px solid #134bec;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}

.notification-merged-btns a:nth-child(2) {
  color: #7782a1 !important;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 50px;
  line-height: 48px;
  display: block;
  width: 160px;
  text-align: center;
  /* margin-left: 10px; */
}

.notification-merged-btns a:nth-child(2):hover {
  color: #484e5e;
  border: 1px solid #7782a1;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.notificationsetting-li .bbsetting-tooltip .bbsetting-tooltipsubheader {
  text-align: left !important;
}

.notification-li .bbsetting-tooltip .bbsetting-tooltipsubheader {
  text-align: left !important;
}

.web-confirm-button {
  background-color: #3064f9 !important;
  border: unset !important;
  color: white;
  width: 140px;
  height: 44px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  transition: 0.5s;
}
.web-confirm-button:hover {
  background-color: #134bec !important;
  transition: 0.5s;
}

.notification-conf-secondmodal .modal-body {
  padding: 13px 40px 60px 40px;
}
.notification-conf-secondmodal-crossmodal {
  position: absolute;
  top: 0px;
  right: 12px;
  cursor: pointer;
  height: 14px;
  width: 14px;
}
.notification-conf-popup-maindiv p {
  font-size: 14px;
  color: #7782a1;
  text-align: center;
  font-family: "Axiforma-Regular";
  margin-top: 18px;
  margin-bottom: 30px;
  word-break: break-word;
}
.notification-conf-popup-head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-conf-popup-head h2 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  text-align: center;
  line-height: 25px;
  margin-bottom: 0px;
}
.notification-conf-popup-head svg {
  margin-right: 6px;
}
.notification-conf-popup-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-conf-popup-cancel {
  width: 140px;
  height: 44px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  color: #7782a1;
  background-color: #ffff;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-right: 20px;
}
.notification-conf-popup-confirm {
  width: 140px;
  height: 44px;
  border-radius: 3px;
  color: #fff;
  background-color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  border: none;
  transition: 0.5s;
}
.notification-conf-popup-confirm:hover {
  background-color: #134bec;
}
.notification-mob-create-btn {
  display: none;
}
/* .mob-upgradenotificationpage {
  overflow: hidden;
} */
.notification-provisioning
  .productsetting-tooltip
  .productsetting-tooltipsubheader {
  top: -100px;
  width: 380px !important;
}
.notification-provisioning
  .productsetting-tooltip
  .productsetting-tooltipsubheader::after {
  top: 107px;
}
.notificaton-build-head {
  margin-top: 1px;
  align-items: flex-start;
}
.move-buildsettings div:last-child p {
  background-color: #3064f9 !important;
  border: 1px solid #3064f9 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.mobile-review-noticfication {
  display: none;
}
.notificationsend-all .notification-category-list-container {
  color: #1b2952 !important;
  font-size: 13px;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  font-family: "Axiforma-Regular";
}
.notification-sent-color {
  color: #52cb86;
}
.notification-failed-color {
  color: #e20000;
}
.notification-bottom-spinner {
  background: none;
  margin: auto;
}
.build-settings-btn {
  border: unset;
  margin: 0px;
}
.upgrade-skip-notification{
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}

@media (min-width: 1921px) {
  .pushnot-upgradetext {
    justify-content: end;
  }
}

@media (min-width: 1301px) and (max-width: 1600px) {
  .notification-modal-tab .notificationlink-list a {
    margin: 0px 30px -5px 0px;
  }
  .notification-modal-tab .notificationlink-list a:last-child {
    margin: 0px 0px -5px 0px;
  }
  .pushnotification-Search {
    width: 240px;
  }
}

@media (max-width: 1300px) {
  .notification-modal-tab .notificationlink-list a {
    margin: 0px 20px -5px 0px;
  }
  /* .pushnotification-filter {
             margin-right: 20px;
             margin-left: 20px
        } */
  .notification-modal-tab .notificationlink a:last-child {
    margin: 0px 0px -5px 0px;
  }
  .pushnotification-Search {
    width: 230px;
  }
}

@media (max-width: 1250px) {
  .notification-modal-tab div a {
    margin: 0px 40px -5px 0px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .user-languagecode {
    width: 11% !important;
  }
  .usertableheader {
    padding: 11px 20px 10.5px 20px;
  }
  .usertabledata {
    padding: 42px 20px 42px 20px;
  }
  .pushnot-upgradetext {
    padding: 184px 20px 20px 20px !important;
    justify-content: start;
  }
  .pushnot-upgradetext.richpushnot-upgradetext {
    padding: 171px 10px 20px 20px !important;
  }
  .pushnot-upgradeimg {
    padding-top: 160px !important;
  }
}
.notification-right-page .newspinner {
  border-radius: 6px !important;
}
@media (max-width: 469px) {
  .mob-build-tooltip .consent-tooltipsubheader::after {
    left: 55px !important;
  }
}
@media (max-width: 1200px) {
  .mob-notification-footer {
    padding: 0px 15px;
  }
  .richNotification-setting-btn{
    margin-right: 0px
  }
  .newpush-notification-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pushnotification-firebase-head-text {
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }
  .notification-modal-tab {
    display: none !important;
  }
  .notification-provisioning
    .productsetting-tooltip
    .productsetting-tooltipsubheader {
    top: -415px;
    width: 215px !important;
    left: -87px;
  }
  .notification-provisioning
    .productsetting-tooltip
    .productsetting-tooltipsubheader::after {
    top: 413px;
    transform: rotate(271deg);
    right: 110px;
  }
  .pushnotification-titlefirstdiv {
    padding: 25px 0px;
  }
  .plain-notification-right-page .newpushcommon-height {
    padding: 0px;
  }
  .pushnotification-title .plain-form-group {
    margin-bottom: 45px !important;
  }

  .pushnotification-notavalable {
    padding-bottom: 180px;
  }
  .pushnotification-notavalable p {
    margin-top: 20px;
  }

  .notification-mob-create-btn {
    border: 1px solid #3064f9;
    border-radius: 3px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -3px 15px 10px;
  }
  .notification-mob-create-btn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pushnotification-finalselection div:last-child p .new-red-loader {
    border: 2px solid #fff;
    border-top: 2px solid transparent;
  }
  .build-setting-bundle p svg {
    margin-left: 6px;
  }
  .btndisabled-build-setting {
    background-color: #bcc1ce !important;
  }
  .notification-file-formet {
    width: 280px;
    height: 50px;
    line-height: 50px;
    background-color: #3064f9;
    font-family: "Axiforma-Regular";
  }
  .mobile-notification-save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: white;
    background-color: #3064f9;
    border-radius: 3px;
    border: none;
    width: 100%;
    /* margin: auto; */
    margin-bottom: 20px;
    font-family: "Axiforma-Regular";
    font-size: 16px;
  }
  .mob-notification-save-date {
    font-size: 12px;
    color: #bcc1ce;
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 17px;
  }
  .ntfcn-mob-tab {
    display: none !important;
  }
  .notification-access-slider.round:before {
    bottom: 2px;
    height: 14px;
    width: 14px;
  }
  .notification-lock-switch {
    height: 18px;
    width: 34px;
  }
  .notification-access-slider {
    background-size: 8px 12.29px;
    background-position: bottom 3px left 22px;
  }
  .newpush-notification-body .round-corner {
    padding: 26px 15px 24px;
  }
  .build-setting-page .newspinner {
    border: unset !important;
    box-shadow: unset !important;
    margin: 0px !important;
  }
  .notification-conf-secondmodal .modal-body {
    padding: 0px 34px 0px 34px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .notification-conf-secondmodal-crossmodal {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  .notification-conf-popup-bottom {
    flex-direction: column-reverse;
  }
  .notification-conf-popup-cancel {
    width: unset;
    height: 24px;
    margin-top: 36px;
    border: unset;
    font-size: 16px;
    margin-right: 0px;
  }
  .notification-conf-popup-confirm {
    width: 280px;
    height: 50px;
    font-size: 16px;
    border: none;
  }

  .mobmultialert-reverse {
    flex-direction: column !important;
  }
  .notification-mobilesearchsuggestion {
    padding-top: 6px !important;
  }
  /* .notification-grid {
    width: 140px;
    min-height: 140px;
    justify-content: center;
  } */

  .createnotificationmodal .modal-content {
    overflow-y: auto;
  }
  .notification-report-spinner {
    height: 84vh;
  }
  .notification-category-list-container {
    font-size: 16px !important;
    color: #1b2952 !important;
    font-family: "Axiforma-Regular" !important;
    margin-top: 13px !important;
  }
  .notification-category-list-container .category-checkmark {
    top: 0px !important;
  }
  .notification-userupgate-page .pushnot-upgradeimg {
    padding-top: 60px !important;
  }
  .notification-userupgate-page .pushnot-upgradetext {
    padding-top: 21px !important;
  }
  .pushreporthead h3 {
    font-size: 20px;
  }
  .newnotification-heading {
    width: calc(100% - 100px);
  }
  .newnotification-time {
    font-size: 14px;
  }
  .newparentsection-date {
    height: 50px;
  }
  .notification-filter-dropdown .post-filter__control {
    height: 50px !important;
    font-family: "Axiforma-Regular";
  }
  .notification-filter-dropdown .dropdown-toggle {
    height: 50px !important;
    font-family: "Axiforma-Regular";
  }
  .filter-delete-pushnotification {
    width: 53.5px !important;
    height: 50px !important;
  }
  .pushnotification-finalselection {
    flex-direction: column-reverse;
  }
  .secondmodal-pushbutton {
    display: flex;
    justify-content: space-between;
    width: 320px;
  }
  .firebase_button_disable {
    margin-right: 0px;
  }
  .message-delete {
    width: unset;
  }
  .build-settingmob .consent-setting svg {
    margin-left: 0px !important;
  }
  
  .mobsend-notificationsend {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0px 20px 0px;
    height: 50px;
    font-size: 16px;
    background: #3064f9;
    border: none;
    color: white;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      transition: 0.5s;
      box-shadow: 0 2px 4px rgb(112 155 250/69%);
      background-color: var(--custom_color5) !important;
      color: var(--custom_color4) !important;
      /* border: 1px solid var(--custom_color6) !important; */
    }
  }
  .richpushnot-mobupgradeimg {
    padding-top: 50px;
    padding-bottom: 60px;
    padding-right: 6.5px;
    padding-left: 6.5px;
    max-width: 100%;
    justify-content: center;
    display: flex;
  }
  .pushnot-upgradeimg.user-mobupgradeimg {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    padding-right: 20px;
    padding-left: 20px;
    max-width: 100%;
    justify-content: center;
    display: flex;
  }
  .build-setting-page {
    padding: 0px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #e3e6f1;
    background-color: white;
    margin: 0px 0px 20px;
  }
  .android-build-setting-div {
    width: 100%;
    padding-right: unset;
  }
  .build-setting-pagehead {
    padding: 25px 15px 0px 15px;
  }
  .build-setting-android {
    border-bottom: 1px solid #e3e6f1;
    padding: 0px 15px;
  }
  .build-setting-ios {
    padding: 0px 15px;
  }
  .mobbuild-setting-bundleright {
    margin-bottom: 30px;
    justify-content: normal;
    margin-right: 0px;
    display: flex;
  }
  .mobbuild-setting-bundleright p {
    width: 100%;
    display: flex;
  }
  .mobbuild-setting-bundleright p .mob-notification_buid_id {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 170px);
    word-break: normal;
    margin-left: 5px;
  }
  .mobbuild-setting-bundleright p .mob-packageName {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 125px);
    word-break: normal;
    margin-left: 5px;
  }
  .mobbuild-setting-bundleright p .mob-build-id {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 90px);
    word-break: normal;
    margin-left: 5px;
  }
  .build-setting-bundle p {
    margin-right: 0px;
  }

  .webbuild-setting-bundleright {
    display: none;
  }
  .build-setting-android-icon {
    padding: 5px;
    background-color: unset;
    border-radius: 3px;
    margin-right: 20px;
    width: unset;
    height: unset;
  }
  /* build-setting-page */

  .osnotification-upgrade-page {
    background-image: unset;
    background-repeat: unset;
    background-position: unset;
    position: unset;
    background-size: unset;
    min-height: unset;
  }
  .notification-merged {
    margin: 60px 15px 15px 20px;
  }
  .mob-pushnotification-platform {
    padding: 0px 0px 20px;
  }
  .web-notification-head {
    display: none;
  }
  .os-icon-mob-div {
    padding: 20px 15px;
  }
  .os-icon-mob-div .notification-app-icon {
    margin-top: 20px;
  }
  .os-report-maindiv .pushNotification-right-panel {
    padding: 20px 15px !important;
  }
  .mob-os-engaged-user {
    display: block;
  }
  .os-image-margin {
    margin-right: 0px;
    margin-top: -10px;
  }
  .web-list-user {
    display: none;
  }
  .os-list-notification-list {
    display: grid;
  }
  .os-messagesave-div {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
  .notification-img-gap {
    flex-direction: row-reverse;
    align-items: flex-start !important;
    align-items: start;
  }
  .usermob-spinner .newspinner {
    position: unset;
    height: 80vh;
    border-radius: 6px;
    border: 1px solid;
    border: 1px solid #e3e6f1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  }
  .pushnotification-finalselection {
    flex-direction: column;
  }
  .pushnotification-finalselection div p {
    width: 280px;
    border: none;
    margin: 20px auto auto;
    font-size: 16px;
  }
  .mob-delete-notification {
    display: block;
  }
  .pushnotification-finalselection div:last-child p {
    width: 280px;
    border: 1px solid #ff4848;
    height: 50px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #ff4848;
    color: #fff;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin: auto;
    display: block;
    display: flex;
    font-size: 16px;
  }
  .web-delete-notification {
    display: none;
  }
  .newtop-portion {
    margin: 15px 0px;
    padding: 0px 15px;
    display: flex;
  }
  .mobilesearchsuggestion p {
    text-align: left !important;
  }
  .textarea-count {
    top: unset !important;
    bottom: -22px;
    left: 0;
    padding: 0px 0px !important;
  }

  .pushnotification-title .form-group {
    margin-bottom: 30px !important;
  }
  .pushnotification-title .form-group input {
    padding: 16px 60px 16px 15px !important;
  }
  .newcrossmodaltwo {
    top: 40px;
    right: 20px;
    height: 14px;
  }
  .secondnotificationmodal {
    height: 57px;
  }
  .notification-title {
    width: unset;
  }
  .notification-userdatafilter {
    display: none;
  }
  .mob-notification-userdatafilter {
    display: flex;
    margin-top: 11px;
    flex-wrap: wrap;
  }
  .mob-notification-userdatafilter h4 {
    border: 1px solid #e3e6f1;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7782a1;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  .mob-notification-userdatafilter h1 {
    font-size: 12px;
    color: #bcc1ce;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    display: flex;
    padding: 4px;
  }
  .newnotificationimag {
    margin-right: 0px;
  }
  .user-lastactivedate,
  .first-usertabledata,
  .web-users-duration {
    display: none;
  }
  .mob-user-lastactive {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: 1px solid #e3e6f1;
    align-items: center;
  }
  .mob-user-duration {
    display: grid;
    justify-content: space-between;
    padding: 17px 15px;
    border-bottom: 1px solid #e3e6f1;
    align-items: center;
  }
  .mob-user-device {
    padding: 20px 15px;
  }
  .mob-user-country {
    padding: 22px 15px;
  }
  .usertabledata {
    background-color: #fff;
    border: 1px solid #e3e6f1;
    padding: 0px;
    display: grid;
    margin-bottom: 15px;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    justify-content: unset;
  }

  .mob-user-duration h2,
  .mob-user-lastactive h2 {
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #bcc1ce;
    margin-bottom: 5px;
  }
  .usertableheader {
    display: none;
  }
  .usertabledata p {
    width: 100%;
  }
  .notification-main-page {
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    background-color: unset;
    min-height: unset;
    padding: 0px 15px;
  }
  .notificationusertable {
    min-height: unset;
  }
  .nodatintable {
    min-height: unset;
    display: flex;
    margin-top: 250px;
  }
  .notification-userupgate-page {
    display: grid;
    background-image: unset;
    background-color: #fff;
    justify-content: unset;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #e3e6f1;
    max-height: unset;
    margin-bottom: 20px !important;
  }
  .notificationusersreport {
    display: unset;
  }
  .pushreportdonut {
    width: 100%;
    margin-top: 30px;
  }
  .pushreport-firstdiv {
    display: grid;
  }
  .pushreport-seconddiv {
    display: grid;
  }
  .pushreport-thirddiv {
    display: grid;
  }
  .platformstatistics {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    padding: 20px 15px 40px 15px;
  }
  .reportstatistics {
    width: 100%;
    margin-bottom: 15px;
    padding: 20px 15px 40px 15px;
  }
  .opensreport {
    width: 100%;
    padding: 27px 20px;
  }
  .deliveredreport {
    width: 100%;
    margin-bottom: 15px;
    padding: 27px 20px;
  }
  .opensreport h2,
  .deliveredreport h2 {
    font-size: 30px;
    line-height: 43px;
  }
  .opensreport p,
  .deliveredreport p {
    line-height: 21px;
  }
  .notificatiosetting-head {
    font-size: 16px;
  }
  .newapp_iconname h3 {
    font: 20px "Axiforma-Bold";
  }
  .notification-setting {
    margin-right: 0px;
  }
  .notificationsetting-div {
    padding: 25px 15px 0px;
  }
  .notificationsetting-div p {
    padding-bottom: 23px;
  }
  .notification-li {
    margin-bottom: 0px !important;
  }
  .notificationsetting-ul {
    list-style: none;
    padding: 30px 15px;
    padding-top: 30px;
  }
  .pushnotificationstatus-edit {
    display: flex !important;
    width: 100%;
    padding: 20px 15px 23px 15px;
    justify-content: space-between;
  }

  .mob-platform-status {
    display: grid !important;
    width: 100%;
    border-bottom: 1px solid #e3e6f1;
    padding: 21px 15px 24px 15px;
  }

  .mob-notification-label {
    display: block;
    text-align: left !important;
    margin-bottom: 6px;
  }

  .newimage-noftication {
    width: 100%;
    border-bottom: 1px solid #e3e6f1;
    padding: 20px 15px 23px 15px;
  }
  .mob-image-rowreverse {
    flex-direction: row-reverse;
  }
  .newpushcommon-height {
    background-color: white;
    border: 1px solid #e3e6f1;
    border-radius: 6px;
    padding: 0px;
    display: inherit;
    margin: 0px 0px 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  }

  .notification-table {
    display: none;
  }
  .mob-status-table {
    display: grid;
    justify-content: unset;
    width: unset;
  }
  .notification-right-page {
    background-color: unset;
    max-height: unset;
    background-image: unset;
    border: unset;
    justify-content: center;
    margin: 0px !important;
    position: relative;
    box-shadow: unset;
    min-height: calc(100vh - 224px);
    padding: 0px 15px;
  }
  .notification-right-page .newspinner {
    /* height: calc(100vh - 140px); */
    position: unset;
    height: 80vh;
    border-radius: 6px;
    border: 1px solid;
    border: 1px solid #e3e6f1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    /* margin: 0px 15px; */
  }
  .notification-right-page .right_general {
    width: 100%;
  }
  .notificationtab {
    background: unset;
  }

  /* .mob-pushnotification-platform{
        background-color: #fff;
    max-height: 800px;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    justify-content: center;
    margin: 0px !important;
    background-size: contain;
    box-shadow: 0 2px 6px rgba(0,0,0,.06);
    min-height: calc(100vh - 208px);
    } */
  .pushnotification-platform {
    display: grid;
    background-color: #fff;
    /* max-height: 800px; */
    border-radius: 6px;
    border: 1px solid #e3e6f1;

    margin: 0px !important;
    background-size: contain;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    min-height: calc(100vh - 208px);
  }

  .push-mobtopview {
    display: block;
  }

  .pushnotification-filter {
    width: 44px;
    height: 44px;
    min-width: 44px;
    margin-right: 0px;
    border: 1px solid #e3e6f1;
    background-color: white;
    border-radius: 3px;
    margin-left: 10px;
  }

  .pushnotification-Search {
    width: calc(100% - 44px);
    height: 44px;
    margin-right: 0px;
    display: flex;
  }

  .pushnot-upgradetext {
    padding: 21px 20px 0px 20px !important;
    max-width: 100%;
    justify-content: start;
  }

  .pushnot-upgradetext.richpushnot-upgradetext {
    padding: 30px 20px 0px 20px !important;
  }
  .pushnot-upgradetext div {
    width: 100%;
  }
  .pushnotification-Search input {
    width: 95%;
  }

  .pushnot-upgradeimg {
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 0px;
    padding-top: 0px !important;
    max-width: 100%;
    justify-content: center;
    display: flex;
  }
  .pushnot-upgradetext p {
    margin-bottom: 30px;
  }
  .pushnot-upgradetext li {
    margin-bottom: 20px;
  }
  .update-mob-upgadehead {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px 15px;
    padding: 0px 15px;
    align-items: center;
  }
.update-mob-upgadehead-right{
  display: flex;
  align-items: center;
}
.update-mob-upgadehead-right .build-settingmob{
  margin-left: 20px;
}
.update-mob-upgadehead-right .user-consent-setting{
  margin-left: 20px;
}
  .pushnotification-mobfilterdiv {
    display: flex;
    margin-bottom: 10px;
    padding: 0px 15px;
  }

  .update-mob-upgadehead svg {
    margin-left: 0px;
  }

  .update-mob-upgadelink {
    font-size: 15px;
    font-family: "Axiforma-Regular";
    color: #3064f9;
  }

  .update-mob-upgadelink:hover {
    color: #3064f9;
  }

  .push-desktopview {
    display: none;
  }

  .notification_upgradebtn {
    width: 100%;
    font-size: 16px;
    padding: 0px;
  }

  .pushnot-upgradetext h3 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .update-mob-upgadehead p {
    font-size: 20px;
    font-family: "Axiforma-Bold";
    margin-bottom: 17px;
    line-height: 20px;
    text-align: left !important;
    margin-bottom: 0px;
  }

  .webnewdraft_fotter {
    display: none;
  }

  .mob-upgradenotificationpage {
    overflow: unset;
  }

  .right-panel-gap-notification {
    padding: 20px 15px !important;
  }

  .pushnotification-right_panel {
    min-height: unset;
    margin: 0px 15px !important;
    background-color: unset;
    border: unset;
    box-shadow: unset;
  }
  .pushnotification-right_panel .newspinner {
    height: 80vh;
    height: 80vh;
    border: 1px solid #e3e6f1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }
  .mobnewdraft_fotter {
    display: grid;
    width: 100%;
    /* padding: 0px 15px; */
  }
  .mob-notification-create-back {
    background-color: white;
    border-radius: 6px;
  }

  .mobnewdraft_fotter button {
    width: 100%;
    margin-top: 20px;
    font-size: 16px !important;
    height: 50px;
    /* cursor: not-allowed !important; */
  }

  .pushnotification-title {
    padding: 0px 17px 0px 15px;
    margin-bottom: 0px;
  }

  .pushNotification-right-panel {
    padding: 20px !important;
  }

  .mobile-schedulebtn {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .push-notificationsend {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    width: 100%;
  }

  [dataclass~="newnotification"] .modal-footer {
    display: none;
  }

  .push-notificationcancel {
    font-size: 16px;
    height: unset;
    width: unset;
    border: none;
    line-height: 16px;
    margin-top: 36px;
  }

  .newcrossmodal {
    top: 35px;
  }

  .createpushnotificationright {
    display: none;
  }

  .createpushnotificationleft {
    padding: 0px;
    border: 1px solid #e3e6f1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
  }

  .notificationmodal {
    padding: 35px 20px 15px 20px !important;
    /* margin-top: 131px; */
  }
  .newcrossmodal {
    right: 20px;
    width: 14px;
  }

  .right-panel-gap-notification {
    width: 100%;
    padding: 0px;
    margin-left: 0px;
  }
  .web-review-noticfication {
    display: none;
  }
  .mobile-review-noticfication {
    margin-bottom: 16px;
    display: block;
    font: 20px "Axiforma-Bold";
  }
  .pushdelete-fieldsvg {
    height: 50px;
    width: 50px;
  }

  .pushnotification-filteropen {
    width: 100%;
  }

  .pushNotificationfilter-input {
    height: 50px;
  }

  .secondnewpush-notification-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 34px !important;
  }
  .move-buildsettings .web-cancel-note {
    position: relative;
    top: 70px;
  }
  .move-buildsettings .web-delete-notification {
    position: relative;
    top: -50px;
  }
  .firebasechartimg {
    width: 112%;
  }
  .pushNotification-right-panel.user-spacing{
    padding: 0px!important;
  }
}

@media (max-width: 767px) {
  .notifications-tab {
    justify-content: space-between;
  }
  .notification-merged-parent-app-name {
    display: none;
  }
  .notification-appicon {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .notification-merged-parent-app-id {
    display: none;
  }
  .notification-parent-app {
    width: 140px;
    min-height: 140px;
  }
  .notification-child-app {
    width: 140px;
    min-height: 140px;
  }
  .firebase {
    position: absolute;
    top: -25px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .notificationtab .createnotif {
    padding-bottom: 24px;
  }

  .notificationtab .social_pannel {
    position: relative;
  }

  .firebase p {
    top: 0px;
  }

  .pushfirstbutton {
    width: 150px;
  }

  .pushsecondbutton {
    width: 150px;
  }
  .firebase_button_disable {
    width: 150px;
  }
  .secondnotificationmodal {
    height: 64px;
  }
}
@media (max-width: 520px) {
  .createpushnotificationleft .react-datepicker__day-name,
  .createpushnotificationleft .react-datepicker__day,
  .createpushnotificationleft .react-datepicker__time-name {
    font-size: 13px !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
    text-align: center !important;
    margin: 0.166rem !important;
  }
  .createpushnotificationleft .react-datepicker__day:hover {
    height: 24px !important;
  }
  .createpushnotificationleft
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 80px !important;
  }
  .createpushnotificationleft
    .react-datepicker-popper
    .react-datepicker__time-container {
    width: 80px !important;
  }
  .createpushnotificationleft
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px !important;
    padding: 8px 0px 8px 0px !important;
    line-height: 17px;
  }
  .createpushnotificationleft .react-datepicker__time-list {
    height: 215px !important;
  }
  .createpushnotificationleft
    .react-datepicker__time-box
    .react-datepicker__time-list
    li {
    min-width: 74px;
  }
  .secondmodal-pushbutton {
    width: 100%;
  }
  .firebase_button_disable {
    width: 50%;
  }
}
@media (max-width: 360px) {
  .pushnot-upgradetext h3 {
    font-size: 27px;
    line-height: 37px;
  }
}
