/*Preview Page Starts*/

/* Page Details */
:root {
  --menu-fontname: "Axiforma-Bold";
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.otf);
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.ttf);
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}
.notification-strip {
  background-color: #7782a1;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 20px;
  margin-top: -20px;
}
.profile-notificationdiv p {
  font-size: 13px;
  margin-bottom: 0px;
  color: #ffffff;
  margin-left: 7px;
  text-align: left !important;
  line-height: 20px;
  margin-top: 2px;
}
.profile-code-modal .modal-header {
  padding: 0px;
}
.profile-country-search {
  height: 60px;
  border: none;
  padding: 20px;
  border: none;
  box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
}
.profile-country-search .cleartext {
  margin-bottom: 0px;
  color: #bcc1ce;
  font-size: 12px;
}
.errordiv {
  width: 100%;
}
.profile-selectflag {
  background-color: white;
  z-index: 1;
  width: 100%;
  top: 50px;
  left: 0;
  /* padding-right: 10px; */
  height: 340px;
  position: absolute;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
/* .profile-state-code{
margin-bottom: 0px;
} */
.profile-selectflagtop {
  height: 50px;
  border: none;
  padding: 0px 20px 0px 20px;
  border-bottom: 1px solid #f1f2f8;
  align-items: center;
}
.searchfleginput {
  width: 100%;
  border: none !important;
  height: 28px !important;
}
.searchfleginput::placeholder {
  color: #c8cedb;
  font-size: 13px;
  font-family: "Axiforma-light";
}
.countrydiv {
  padding: 7px 10px;
  height: 290px;
  overflow-y: auto;
}
.countryflagtype {
  cursor: pointer;
  border-radius: 4px;
  display: grid !important;
}
/* .countryflagtype:hover{
background-color: #F8F9FD;

} */
.countryflagtype p {
  padding-bottom: 0px;
  font-size: 13px;
  margin-left: 10px;

  color: #1b2952;
  margin-bottom: 0px;
}
.countryflagtype .country-line-flag {
  padding: 13px 10px;
}
.search-box .flagsearch-input {
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.countryflagtype .country-line-flag:hover {
  background-color: #f8f9fd;
  padding: 13px 10px;
}
.countryflagtype img {
  width: 16px;
  height: 16px;
}
.searchfleginput:focus {
  background-color: white !important;
}
.searchfleginput:hover {
  background-color: white !important;
}
.profile-selectflagtop p {
  margin-bottom: 0px;
  color: #bcc1ce;
  font-size: 12px;
  cursor: pointer;
}
.profile-countrycode-flag .country-line-flag {
  padding: 15px 5px;
}
.code-selector {
  overflow-y: scroll;
}
.country-line-flag img {
  width: 16px;
  height: 16px;
}

.backaero-svgimg {
  display: none;
}
.profile-email-input-status {
  position: absolute;
  top: 0px;
  right: 20px;
  height: 100%;
  color: #ff4848;
  font-size: 13px;
  align-items: center;
  font-family: Axiforma-Regular;
}
.profile-email-input-status p {
  margin-bottom: -2px !important;
}
.profile-email-input-status:hover {
  color: #ff4848;
}

.profile-lastupdate-button {
  display: flex !important;
  align-items: center;
}
.profile-latest-update {
  color: #bcc1ce;
  font-size: 12px;
  margin-right: 20px;
  margin-bottom: -3px;
}
.profile-spinner {
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0px;
  opacity: 1;
  min-height: 75vh;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  border: 1px solid #e3e6f1;
}
.profile-mob-save-btn {
  display: none;
}
.mob-imageupload-remove {
  display: none;
}
.label-profile-pic-new img {
  cursor: pointer;
}
.label-profile-pic-new img:hover {
  opacity: 1;
}

.label-profile-pic-new:hover .middle {
  opacity: 1;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.new-profile-social {
  background-color: #fff;
  padding: 25px 30px 38px 30px;
}
.new-profile-social .social-account-add{
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 6px;
  line-height: 23px;
}
.new-profile-social .social-account-text{
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 30px;
  line-height: 17px;
}
.myprofile-tooltip-learnmore img {
  margin-left: 8px;
}
.newp-profile {
  color: #1b2952 !important;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  text-align: left !important;
  line-height: 18px;
  margin-top: -2px;
}

.profile-validation {
  margin-top: 10px;
}
.profile-validation svg {
  margin-top: 1px;
}
.profile-redinput {
  font-size: 12px;
  margin-left: 6px;
  color: #ff4848;
  margin-bottom: 0px;
}
.newprofile-afterconnect {
  font-size: 13px;
  margin-right: 20px;
  color: #52cb86 !important;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.new-profileconnect {
  margin-top: 5px !important;
}
.connect-status {
  line-height: 17px;
  align-items: center;
  display: flex;
}
.new-profile-social a {
  color: #3064f9 !important;
  font-size: 13px !important;
  text-decoration: none;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
}

.new-profile-social .col-md-6 .d-flex .social-parent-div {
  margin-left: 15px;
}
.new-profile-social .row .col-md-6:last-child {
  padding-left: 10px;
}
.new-profile-social a.remove {
  color: #bcc1ce !important;
  font-size: 13px !important;
}
.new-my-profile-page {
  width: 100%;
}
.new-my-profile-head {
  margin-top: -10px;
  margin-bottom: 20px;
}
.new-my-profile-head h1 {
  font-size: 24px;
  margin-bottom: 0px;
  font-family: "Axiforma-Bold";
  margin-right: 10px;
}
.myprofile-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
}
.myprofileimage-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
}
.myprofileimage-tooltip .myprofile-tooltipupload {
  visibility: visible;
  color: #7782a1;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 84%;
  padding: 10px 30px 10px 30px;
  opacity: 0.8 !important;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  text-align: left !important;
  justify-content: space-between;
}
.myprofile-tooltipupload .profilepic-remove {
  color: #ff4848;
  margin-bottom: 0px;
  margin-left: 30px;
  cursor: pointer;
}
.myprofile-tooltipupload .profilepic-upload {
  margin-bottom: 0px;
  cursor: pointer;
}
.myprofile-tooltip img {
  cursor: pointer;
  margin-bottom: 2px;
}
.myprofile-tooltip .myprofile-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -1px;
  left: 159%;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  text-align: left !important;
  transition: 0.3s;
}

/* .myprofileimage-tooltip .myprofile-tooltipupload::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: #e6ebff14 white #e6ebff14 #e6ebff14;
} */
/* .myprofileimage-tooltip:hover .myprofile-tooltipupload {
  visibility: visible;
} */

.myprofile-tooltip-learnmore {
  margin-top: 12px;
  color: #c8cedb;
  cursor: pointer;
  margin-bottom: 0px;
  text-align: left !important;
}
.myprofile-tooltip .myprofile-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 14%;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.myprofile-tooltip:hover .myprofile-tooltipsubheader {
  visibility: visible;
}
.new-my-profile-personal {
  background-color: #fff;
  padding: 30px 30px;
  border-radius: 6px;
}
.my-profile h1 {
  color: #1b2952;
  font-size: 20px !important;
  font-family: "Axiforma-Bold";
  margin-bottom: 5px;
  margin-top: 0px;
}
.my-profile p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 0px;
  color: #7782a1;
  text-align: left !important;
}
.newprofile-save-btn {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border: 1px solid #3064f9;
  line-height: 25px;
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
  &:hover {
    background-color: var(--custom_color5)!important;
    border: 1px solid var(--custom_color6)!important;
    color: var(--custom_color4)!important;
  }
}

.newprofile-save-btn:hover {
  /* background-color: #134bec;
  border: 1px solid #3064f9; */
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.label-profile-pic-new {
  margin-bottom: 20px;
  position: relative;
}
.new-my-profile-shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-bottom: 20px;
  border: 1px solid #e3e6f1;
}
.new-my-profile-shadowsecond {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid #e3e6f1;
  position: relative;
}
.new-my-profile-thirdshadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 0px;
  border: 1px solid #e3e6f1;
}
.new-my-profile-personal .userProfile-name-label{
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 7px;
  margin-bottom: 9px;
  line-height: 17px;
}
.new-my-profile-personal .color_picker {
  margin-bottom: 10px;
  width: 50% !important;
  position: relative !important;
}
.new-my-profile-personal .userprofile-input{
  background-color: white;
  outline: none !important;
  border: 1px solid #bcc1ce;
  height: 50px;
  color: #1b2952 !important;
  font-size: 13px !important;
  border-radius: 3px !important;
  padding: 15px !important;
  line-height: 17px;
}
.new-my-profile-personal .userprofile-email-input{
  background-color: white;
  outline: none !important;
  border: 1px solid #bcc1ce;
  height: 50px;
  color: #1b2952 !important;
  font-size: 13px !important;
  border-radius: 3px !important;
  padding: 15px !important;
  line-height: 17px;
  padding-right: "90px"
}

.profile-role-dropdown__value-container {
  padding: 0px !important;
}
.profile-role-dropdown__control,
.inputtypeone .css-yk16xz-control,
.inputtypetwo .css-yk16xz-control {
  background-color: white !important;
  outline: none !important;
  border: 1px solid #bcc1ce !important;
  height: 50px !important;
  color: #1b2952 !important;
  font-size: 13px !important;
  border-radius: 3px !important;
  padding: 0px 5px 0px 15px !important;
  cursor: pointer!important;
}
.profile-role-dropdown__indicator-separator{
  display: none;
}
.inputtypetwo .css-yk16xz-control:hover,
.inputtypetwo .css-yk16xz-control:focus,
.inputtypeone .css-yk16xz-control:hover,
.inputtypeone .css-yk16xz-control:focus {
  background-color: #f8fafe !important;
  border: 1px solid #3064f9 !important;
  box-shadow: none !important;
}

.mobile-view-click:hover .profile-role-dropdown__control {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}

.profile-role-dropdown__menu {
  margin-top: 0px !important;
  padding: 7px 10px !important;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16) !important;
}
.profile-role-dropdown__menu-list {
  padding: 0px;
}
.profile-role-dropdown__option {
  padding: 13px 10px !important;
  font-size: 13px !important;
  color: #1b2952 !important;
  font-family: "Axiforma-Regular" !important;
}

.profile-role-dropdown__option--is-focused {
  background-color: #f8f9fd !important;
}
.profile-role-dropdown__option:hover {
  background-color: #f8f9fd !important;
}
.inputtypeone {
  padding-right: 10px !important;
}
.inputtypetwo {
  padding-left: 10px !important;
}
.new-my-profile-personal .color_picker input:focus,
.new-my-profile-personal .color_picker input:hover {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.profile-validate-border {
  border-color: #ff4848 !important;
}
.profile-connect-socialmedia {
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
  margin-top: 0px;
}
.profile-social-login {
  width: 54px;
  height: 54px;
  background-color: #f8fafe;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 3px;
}
.new-my-profile-personal .color_picker select {
  background-color: white !important;
  outline: none !important;
  border: 1px solid #bcc1ce !important;
  background: url(../images/deals/downArrow.png) 97.5%/10% no-repeat;
  background-size: auto;
  -webkit-appearance: none;
  height: 50px !important;
  color: #1b2952 !important;
  font-size: 13px !important;
  padding-left: 15px !important;
  border-radius: 3px !important;
}
.new-my-profile-personal .color_picker select:focus,
.new-my-profile-personal .color_picker select:hover {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
}
.new-my-profile-personal .color_picker .phonecodeselects {
  background-color: white;
  border: 1px solid #bcc1ce !important;
  padding: 17px 20px 19px 20px;
  font-size: 13px !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  width: 151px !important;
}
.phonecodeselects {
  background: url(../images/deals/downArrow.png) 82%/10% no-repeat;
  appearance: none;
}
.new-my-profile-personal .color_picker .phonecodeselects img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.new-my-profile-personal .color_picker .phonecodeselects:hover,
.new-my-profile-personal .color_picker .phonecodeselects:focus {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
}
.new-my-profile-personal .color_picker .phone-inputs {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  background-color: white;
  outline: none !important;
  border: 1px solid #bcc1ce;
  height: 50px;
  color: #1b2952 !important;
  font-size: 13px !important;
  padding:  15px !important;
  line-height: 17px;
}
.new-my-profile-personal .select-input {
  border: none;
}
.profile-notificationdiv {
  align-items: center;
}
.new-profile-delete {
  background-color: #fff;
  padding: 33px 30px 39px 30px;
  display: flex;
  justify-content: space-between;
}

.new-my-profile-delete {
  width: 140px;
  /* height: 40px; */
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 11px 11px 9px 12px;
  transition: 0.5s;
  line-height: 18px;
  color: #bcc1ce;
  margin-top: 6px;
}
.new-my-profile-delete:hover {
  background-color: #ff4848;
  border: 1px solid #ff4848;
  color: #fff;
  transition: 0.5s;
}
.new-profile-delete .profile-delete-head {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 6px;
  line-height: 23px;
}

.new-profile-delete .profile-delete-text {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  line-height: 17px;
}
.new-before-delete-ok {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 4px;
  width: 140px;
  height: 44px;
  line-height: 44px;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
}
.new-before-delete-ok p {
  color: #fff;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0px;
}
/* .before-delete-ok:hover{
background-color: #fff;
transition: .5s;
} */
/* .before-delete-ok:hover p{
color: #3064f9;
transition: .5s;
} */
.before-delete-cancel {
  background-color: #fff;
  border: 1px solid #c8cedb;
  border-radius: 4px;
  width: 140px;
  height: 44px;
  line-height: 44px;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}
.before-delete-cancel p {
  color: #7782a1;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0px;
}
.before-delete-cancel:hover {
  background-color: #c8cedb;
  transition: 0.5s;
}
.before-delete-cancel:hover p {
  color: #fff;
  transition: 0.5s;
}
.confirmation-app-delete {
  max-width: 480px !important;
  max-height: 273px !important;
}
.new-before-delete-step .profile-detele-step-active {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  text-align: center;
  line-height: 25px;
}
.new-before-delete-step .profile-detele-step-active-text {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  line-height: 18px;
  margin-bottom: 30px;
}
.new-before-delete-step {
  padding-top: 25px !important;
  padding-bottom: 51px !important;
}
.before-delete-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-delete-account-popup .delete-account-head {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-top: -2px;
}
.new-delete-account-popup .delete-account-text-para {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 15px;
  line-height: 20px;
}
.new-delete-account-popup .delete-account-text-input-text {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 22px;
  line-height: 20px;
  margin-bottom: 12px;
}
.new-delete-account-popup .delete-account-input {
  width: 100%;
  height: 50px;
  border: 1px solid #bcc1ce;
  background-color: white;
  padding: 0px 15px;

  outline: none !important;
  border-radius: 3px;
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
}
.new-delete-account-popup .delete-account-input:hover,
.new-delete-account-popup .delete-account-input:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}
.profileemail-validation {
  margin-top: 10px;
}
.confirm-email-validation {
  margin-bottom: 39px;
}
.new-delete-account-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1;
}
.new-delete-my-account {
  padding: 0px !important;
}
.new-delete-account-popup {
  padding: 0px 20px 0px 20px;
}
.new-delete-account-footer {
  padding: 15px 20px;
}
.new-delete-account-footer div:nth-child(1) {
  background-color: #fff;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
}
.new-delete-account-footer div:nth-child(1) p {
  color: #7782a1;
  font-size: 13px;
  transition: 0.5s;
  margin-bottom: 0px;
}
.new-delete-account-footer div:nth-child(1):hover {
  color: #484e5e;
  border: 1px solid #7782a1 !important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}

.new-delete-account-footer div:nth-child(2) {
  background-color: #fff;
  border: 1px solid #ff4848;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
}
.new-delete-account-footer div:nth-child(2) p {
  color: #ff4848;
  font-size: 13px;
  transition: 0.5s;
  margin-bottom: 0px;
}
.new-delete-account-footer div:nth-child(2) p:hover {
  color: white;
}
.new-delete-account-footer div:nth-child(2):hover {
  border: 1px solid #ff4848 !important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(250 165 165 / 40%);
  background-color: #ff4848;
}
.qr-authenticate {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 40px 30px;
}
.qr-code {
  width: 120px;
  height: 120px;
  position: relative;
}
.qr-code button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
}
.hide-qr {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  opacity: 0.96;
}
.qr-verify {
  margin-left: 30px;
}
.qr-verify input {
  width: 60px;
  height: 50px;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: rgba(227, 230, 241, 0.5);
  margin-right: 10px;
  outline: none !important;
  padding: 10px 23px;
  font-size: 15px;
  font-family: "Axiforma-Regular";
}
.qr-verify input.error-authenticate {
  border: 1px solid #ffbaba;
  background-color: #fffcfc;
}
.qr-authenticate h4 {
  font-size: 13px;
  color: #1b2952;
  margin-left: 30px;
  margin-bottom: 10px;
  font-family: "Axiforma-Regular";
}
.enable-authenticate {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  transition: 0.5s;
  text-transform: uppercase;
}
.enable-authenticate:hover {
  background-color: #779aff;
  border: 1px solid #779aff;
  transition: 0.5s;
}
.disable-authenticate {
  background-color: #fff;
  border: 1px solid #e3e6f1;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #bcc1ce;
  font-size: 14px;
  transition: 0.5s;
  text-transform: uppercase;
}
.disable-authenticate:hover {
  color: #fff;
  background-color: #bcc1ce;
  transition: 0.5s;
}
.qr-verified {
  padding: 40px 30px;
  background-color: #fff;
}
.qr-verified h5 {
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  margin-bottom: 0px;
}
.qr-verified h5 img {
  margin-right: 10px;
}
.code-popup {
  max-width: 600px;
  max-height: 400px;
}
.code-popup h4 {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}
.code-popup h6 {
  font-size: 13px;
  color: #7782a1;
  line-height: 20px;
  font-family: "Axiforma-Regular";
  margin-bottom: 23px;
}
.code-popup .modal-body {
  padding: 0px !important;
}
.code-list {
  background-color: #f8fafe;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  padding: 14px 20px 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}
.code-controls {
  position: relative;
}
.code-controls img {
  margin-left: 20px;
  cursor: pointer;
}
.code-list p {
  font-size: 16px;
  color: #7782a1;
  line-height: 26px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.code-popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1;
  padding: 15px 20px;
}
.code-popup-footer button:nth-child(1) {
  background-color: #fff;
  border: 1px solid #e3e6f1;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #bcc1ce;
  font-size: 13px;
  transition: 0.5s;
  text-transform: uppercase;
}
.code-popup-footer button:nth-child(1):hover {
  border-color: #e3e6f1;
  background-color: #e3e6f1;
  transition: 0.5s;
}
.code-popup-footer button:nth-child(2) {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 13px;
  transition: 0.5s;
  text-transform: uppercase;
}
.code-popup-footer button:nth-child(2):hover {
  background-color: #779aff;
  border: 1px solid #779aff;
  transition: 0.5s;
}
.code-popup-body {
  padding: 30px 20px;
}
.my-profile-picture {
  margin-bottom: 0px !important;
}
.new-profile-panel .pannel_title {
  margin-top: 15px !important;
}
.new-profile-panel .web_logo {
  margin-top: 15px;
}
.code-popup .modal-content {
  border: none;
}
.new-social-error {
  border-radius: 3px;
  background-color: #ffecec;
  padding: 8px 15px 8px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: -11px;
}
.new-social-error p {
  color: #ff4848 !important;
  font-family: "Axiforma-Regular";
  font-size: 13px !important;
  line-height: 22px;
  margin-bottom: 0px;
  /* margin-top: 2px; */
}
.new-social-error div:nth-child(2) svg {
  margin-top: -5px;
}
.new-delete-account-footer div .new-red-loader {
  border: 2px solid #ff4848 !important;
  border-radius: 50% !important;
  border-top: 2px solid transparent !important;
  width: 16px !important;
  height: 16px !important;
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
  box-shadow: none !important;
  margin: auto;
  margin-top: 10px;
}

.new-delete-account-footer div:nth-child(2):hover .new-red-loader {
  border: 2px solid #fff !important;
  transition: 0.5s;
  background-color: #ff4848;
  border-top: 2px solid transparent !important;
}
.deletemodaltype .modal-content {
  border-radius: 6px;
  border: none !important;
}

.profile-crossmodal img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.profile-my-profile-shadow {
  position: relative !important;
}
.profile-role-dropdown__single-value{
color: #1b2952 !important;
    font-size: 13px !important;
    font-family: "Axiforma-Regular" !important;
}
.profile-pic-img{
    width: 80px;
    border-radius: 50%;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover
}
.phonecodemodal-div{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer
}
.userprofile-spinnerdiv{
 background-color: white;
 min-height: 200px;
    width: 100%;
}
.profile-spinner .newspinner{
  border-radius: 6px;
}
.appsumo-user-text{
    color: #1B2952;
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 10px
}
.userdeleteModal{
  max-width: 600px;
   max-height: 340px
}
.mob-contrycode-modal{
  max-width: 480px;
  max-height: 296px
}
@media (max-width: 1200px) {
  .profile-role-dropdown__menu{
    display: none;
  }
  .new-my-profile-head .app-dashboard-head {
    margin: 0px !important;
  }
  .profile-spinner {
    min-height: calc(100vh - 125px);
  }
  .profile-role-dropdown__control,
  .inputtypeone .css-yk16xz-control,
  .inputtypetwo .css-yk16xz-control {
    padding-right: 0px !important;
  }
  .profile-my-profile-shadow {
    position: unset !important;
  }
  .confirm-email-validation {
    margin-bottom: 0px;
  }
  .new-social-error p {
    width: 95%;
  }
  .new-my-profile-head h1 {
    font-size: 20px;
    line-height: 28px;
  }
  .deletemodaltype .modal-content {
    border-radius: 0px;
  }
  .div-my-profile-page {
    padding: 0px 15px;
  }
  .new-my-profile-head {
    margin-top: 15px;
    margin-bottom: 18px;
  }
  .label-profile-pic-new {
    margin-bottom: 0px;
    margin-left: 1px;
  }
  .profile-pic-container-new {
    width: 80px;
    margin-bottom: 19px;
   width: 100%
  }
  .new-my-profile-personal .color_picker {
    width: 100% !important;
  }
  .inputtypeone {
    padding-right: 0px !important;
  }
  .inputtypetwo {
    padding-left: 0px !important;
  }
  .new-social-error {
    padding: 14px 15px 9px 15px;
    margin-top: 30px;	
    margin-bottom: -12px;
  }
  .new-social-error p {
    text-align: left !important;
    font-size: 13px !important;
    line-height: 19px;
  }
  .new-social-error div:nth-child(2) svg {
    width: 16px;
    height: 16px;
  margin-top: -15px;
  }
  /* .new-profile-social .row .col-md-6:nth-child(1) {
    margin-bottom: 30px;
  } */
  /* .new-profile-social .row .col-md-6:nth-child(2) {
    margin-bottom: 30px;
  } */
  .new-profile-social .row .col-md-6:last-child {
    padding-left: 15px;
  }
  .my-profile-head button {
    display: none !important;
  }
  .new-my-profile-personal {
    padding: 30px 15px 20px 15px;
  }
  .new-my-profile-personal .color_picker input,
  .new-my-profile-personal .color_picker input:focus,
  .new-my-profile-personal .color_picker input:focus-visible {
    padding-left: 20px !important;
  }
  .new-my-profile-personal .color_picker select,
  .new-my-profile-personal .color_picker select:focus,
  .new-my-profile-personal .color_picker select:focus-visible {
    padding-left: 10px !important;
  }
  .new-my-profile-personal button {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    height: 50px;
  }
  .profile-social-login {
    margin-bottom: 0px;
  }
  .new-profile-social {
    background-color: #fff;
    padding: 27px 15px 32px 15px;
  }
  .new-profile-social .social-account-text{
    line-height: 17px;
    font-size: 13px;
  }
  .new-profile-social .col-md-6 .d-flex .social-parent-div {
    margin-left: 15px;
  }
  .new-profile-delete {
    display: block !important;
    padding: 25px 15px 30px 15px;
    display: grid !important;
    justify-content: inherit;
  }
  .new-my-profile-delete {
    width: 100%;
   margin-top: 36px;
    height: 50px;
    background-color: #ff4848;
    border: 1px solid #ff4848;
    color: #fff;
    font-size: 16px;
  }
  .new-profile-delete .profile-delete-text {
    line-height: 17px;
    font-size: 13px;
  }
  .qr-authenticate {
    display: block;
  }
  .qr-authenticate h4 {
    margin-left: 0px;
    margin-top: 30px;
  }
  .qr-verify {
    margin-left: 0px;
  }
  .qr-verify input {
    width: 12.5%;
    height: 45px;
    padding: 10px 16px;
  }
  .qr-code {
    width: 160px;
    height: 160px;
  }
  .qr-authenticate {
    padding: 30px 20px;
  }
  .qr-verified {
    padding: 30px 20px;
  }
  .qr-verified h5 img {
    margin-right: 5px;
  }
  .qr-verified h5 {
    font-size: 12px;
  }
  .enable-authenticate {
    width: 100%;
    margin-top: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .profile-spinner {
    border-radius: 0px;
  }
  .disable-authenticate {
    width: 100%;
    margin-top: 30px;
  }
  .code-popup-footer button {
    width: 45% !important;
  }
  .phonecodeselects {
	  background-position-x: 88%;
  }
  .new-delete-account-popups .modal-content {
    position: inherit !important;
    height: auto;
  }
  .new-before-delete-step {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .profile-code-modal .modal-content,
  .deletemodaltype .modal-content {
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    border: none !important;
  }
  .old-imgremove {
    margin-bottom: 0px;
    margin-left: 40px;
    font-size: 13px;
    color: #ff4848;
    font-family: "Axiforma-Regular";
  }
  .newimg-upload {
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
  }
  .mob-imageupload-remove {
    display: block;
    display: flex;
    margin-left: 17px;
  }
  .middle {
    display: none;
  }
  .profile-mob-save-btn {
    display: block;
  }
  .newprofile-save-mob-btn {
    background-color: #3064f9;
    text-decoration: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border: 1px solid #3064f9;
    line-height: 15px;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    height: 50px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .mob-profile-update {
    color: #bcc1ce;
    font-size: 12px;
    text-align: center;
  margin-bottom: 17px;
  }
  .profile-lastupdate-button {
    display: none !important;
  }
  .myprofileimage-tooltip .myprofile-tooltipupload {
    display: none;
  }
  .profile-crossmodal img {
    position: absolute;
    right: 20px;
    top: 40px;
    cursor: pointer;
  }
  .new-before-delete-ok {
    width: 70% !important;
    height: 50px;
    line-height: 50px;
  }
  .new-before-delete-step {
    padding: 0px;
    margin-top: 25px;
  }
  .new-before-delete-step .profile-detele-step-active-text {
    margin: 0px 34px 30px 34px;
    width: auto;
  }
  .new-delete-my-account {
    align-items: center;
    display: flex;
    margin-top: 25px;
  }
  .new-red-loader {
    border: 2px solid #ff4848;
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 16px !important;
    height: 16px !important;
    animation: spin 0.6s linear infinite;
  }
  .new-delete-account-popup {
    padding: 0px 34px 0px 34px;
  }
  .new-delete-account-popup .delete-account-head,
  .new-delete-account-popup .delete-account-text-para,
  .new-delete-account-popup .delete-account-text-input-text {
    text-align: center;
  }
  .new-delete-account-footer {
    flex-direction: column-reverse;
    border-top: none;
    padding: 0px 34px;
  }
  .new-delete-account-popup .delete-account-text-para {
    font-size: 14px;
    margin-top: 20px;
    line-height: 25px;
  }
  .new-delete-account-popup .delete-account-text-input-text {
    font-size: 14px;
    line-height: 25px;
  }
  .new-delete-account-popup .delete-account-input {
    text-align: center;
    background-color: white;
    margin-bottom: 20px;
  }
  .new-delete-account-footer div:nth-child(2) {
    width: 100%;
    background-color: #ff4848;
    color: white;
    height: 50px;
  }
  .new-delete-account-footer div:nth-child(1) {
    border: none;
    width: auto;
    height: 0;
    padding: 0px;
    margin-top: 30px;
  }
  .new-delete-account-footer div:nth-child(1):hover {
    border: none !important;
  }
  .new-delete-account-footer div:nth-child(1) p {
    font-size: 16px;
  }
  .new-delete-account-footer div:nth-child(2) p {
    color: white;
    font-size: 16px;
    line-height: 50px;
  }
  .profile-code-modal .modal-header {
    padding: 0px;
  }
  .profile-country-search {
    height: 60px;
    border: none;
    padding: 20px;
    border: none;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
  }
  .profile-country-search .cleartext {
    margin-bottom: 0px;
    color: #bcc1ce;
    font-size: 12px;
  }
  .profile-countrycode-flag {
    margin-top: 15px;
  }
  .profile-countrycode-flag .country-line-flag {
    padding: 25px 20px;
  }
  .code-selector {
    overflow-y: scroll;
  }
  .country-line-flag img {
    width: 16px;
    height: 16px;
  }
  .backaero-svgimg {
    display: block;
  }
  .profile-selectflag {
    display: none;
  }
  .new-my-profile-thirdshadow {
    margin-bottom: 60px;
  }
  .profileemail-validation {
    margin-top: -10px;
  }
  .new-before-delete-ok p {
    font-size: 16px;
  }
  .new-my-profile-personal .color_picker select {
    background: url(../images/deals/downArrow.png) 95.5%/10% no-repeat;
    background-size: auto;
  }
  .profile-email-input-status {
    right: 10px;
  }

  .notification-strip {
    margin-top: 15px;
  }
  .profile-notificationdiv {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .myprofile-tooltip .myprofile-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 33px;
    left: -375%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
  }
  .myprofile-tooltip .myprofile-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 75px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}
