@font-face {
    font-family: "Poppins-SemiBold";
    src: url(../../assets/fonts/Poppins-SemiBold.ttf);
  }
  @font-face {
    font-family: "Poppins-Light";
    src: url(../../assets/fonts/Poppins-Light.ttf);
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url(../../assets/fonts/Poppins-Regular.ttf);
  }
  
  @font-face {
    font-family: "Poppins-Bold";
    src: url(../../assets/fonts/Poppins-Bold.ttf);
  }
  .upgrade-support{
    font-size: 14px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    line-height: 20px;
    margin-bottom: 0px;
    text-align: end;
  }
  .upgrade-unavailable{
    font-size: 14px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    line-height: 20px;
    margin-bottom: 0px;
    text-align: end;
  }
  .build-setting-tooltip .productsetting-tooltipsubheader{
    top: -23px !important;
  }
  .date-and-savediv a{
    border-bottom: unset !important;
  }
  .build-setting-tooltip .productsetting-tooltip .productsetting-tooltipsubheader::after{
    top: 29px;
  }
  .produclogintsetting-tooltipsubheader{
    width: 400px !important;
    top: -48px !important;
  }
  .produclogintsetting-tooltipsubheader::after {
    top: 54px !important;
  }
  .consent-headingerror{
    position: absolute;
    top: 46px;
    right: 15px;
    font-size: 10px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
  }
  /* .consent-headingerrorinput::after{
    content: "| 400 words";
    position: absolute;
    right: 3px;
    top: 2px;
  } */

  .access-loader{
    height: 75vh;
  }
  .color_input .accessfixback{	
    box-shadow: 0px 2px 4px rgb(188 193 206 / 50%) !important;	
    background-color: #fff !important;	
    padding: 20px !important;	
    height: auto !important;	
    position: absolute !important;	
    left: auto !important;	
    top: auto !important;	
    font-size: 12px;	
    z-index: 12!important;	
    bottom: 36%;	
}	
.color_input .accessfixback .mobileok{	
    width: 100% !important;	
    margin-top: 20px;	
}	
.color_input .loginfixback{	
    box-shadow: 0px 2px 4px rgb(188 193 206 / 50%) !important;	
    background-color: #fff !important;	
    padding: 20px !important;	
    height: auto !important;	
    position: absolute !important;	
    left: auto !important;	
    top: auto !important;	
    font-size: 12px;	
    z-index: 12!important;	
    bottom: 26%;	
}	
.color_input .loginfixback .mobileok{	
    width: 100% !important;	
    margin-top: 20px;	
}	


.new-consent-login{
    /* background-color: #f8fafe; */
    display: flex;
   
}
.new-consent-head h1{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin: 0px;
}
.new-consent-head h1 svg{
    margin-left: 6px;
}
.consent-modal-tab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 15px;
}
.consent-modal-tab div a{
    color: #7782A1 !important;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px 60px -5px 0px;
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
    transition: .5s;
    /* &:hover {
        color: var(--custom_color4) !important;
        border-bottom: 2px solid var(--custom_color5);
      }
    &:active {
        color: var(--custom_color4) !important;
        border-bottom: 2px solid var(--custom_color5);
    } */
}
.consent-modal-tab div a.active, .consent-modal-tab div a:hover{
    color: var(--custom_color4) !important;
    border-bottom: 2px solid var(--custom_color5);
    transition: .5s;
}
.consent-login-save{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    color: white ;
    background-color: #BCC1CE;
    border: none;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    line-height: 40px;
}
.consent-login-save-enable{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    color: white ;
    background-color: #3064F9;
    border: none;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    line-height: 40px;
    &:hover {
        color: var(--custom_color4)!important;
        background-color: var(--custom_color5)!important;
    }
}
.consent-login-save-disable{
    background-color: #BCC1CE!important;
    cursor: no-drop !important;
    border: none;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    line-height: 40px;
    width: 100px;
    height: 40px;
    border-radius: 3px;
    color: white!important;
}
.consent-setting{
    /* padding: 10px 12px; */
    width: 134px;
    display: flex;
    gap: 6px;
    position: relative;
    /* width: 40px; */
    height: 40px;
    border-radius: 3px;
    color: white ;
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff;;
    /* margin-right: 20px; */
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.build-settings-text{
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
    font-family: 'Axiforma-Regular';
}
.consent-setting-clicked{
    /* width: 40px !important; */
    height: 40px;
    border-radius: 3px;
    color: white ;
    background-color: #F8FAFE;
    border: none;
    box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16)inset;
    /* margin-right: 20px; */
    justify-content: center !important;
    align-items: center;
    display: flex;
    cursor: pointer;
    position: relative;
}
.mob-page-loginhead-icon{
    gap: 15px;
}
.new-consentpage{
    min-height: 75vh;
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0,  0.06);
    border-radius: 6px;
    /* overflow: hidden; */
}
.new-consentpage-upgrade{
    display: flex;
    background-color: #fff;
    background-image: url(../../assets/images/dashboard/app-icon-background.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    border-radius: 6px;
    justify-content: space-between;
    margin: 0px !important;
    position: relative;
    background-size: contain;
    min-height: calc(100vh - 217px);
}
.new-concentpage-columnupgrade{
    width: 50%;
    display: flex;
    padding: 154px 120px 20px 120px;
    /* justify-content: end; */
}
.new-concentpage-colutwomnupgrade{
    width: 50%;
    padding: 120px 0px 20px 20px;
    display: flex;
    justify-content: start;
}
.new-concentpage-columnupgrade h3{
    font-size: 48px;
    font-family: 'Axiforma-Bold';
    line-height: 69px;
    margin-bottom: 15px;
    color: #1B2952;
}

.new-concentpage-columnupgrade p{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    line-height: 18px;
    text-align: left !important;
    margin-bottom: 30px;
}

.new-concentpage-columnupgrade ul {
    padding: 0px 0px 0px 15px;
    position: relative;
    list-style-type: none;
}

.new-concentpage-columnupgrade li {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    list-style-type: none;
    margin-bottom: 23px;
}
.new-concentpage-columnupgrade a{
    background-color: #3064f9;
    /* text-decoration: none; */
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    padding: 11px 30px;
    border: none;
    line-height: 15px;
    transition: 0.5s;
    cursor: pointer;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.new-concentpage-columnupgrade a:hover{
    background-color: #134BEC;
    color: white;
}
.new-concentpage-columnupgrade li::before {
    content: url("../..//assets/images/dashboard/cms/dot.png");
    position: absolute;
    left: 0px;
    opacity: 0.5;
}
.new-concentpage-row{
    display: flex;
}
.new-concentpage-columnone{
    /* flex: 50%; */
    padding: 30px 25px 30px 30px;
    width: 100%;
}
.new-concentpage-columntwo{
    /* flex: 50%; */
    width: 100%;
    padding: 75px 30px 20px;
    /* justify-content: center; */
    /* align-items: center; */
    /* display: grid; */
}
.column-left-head{
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 25px 0px;
    border-bottom: 1px solid #E3E6F1;
    align-items: end;
}
.column-left-head .column-head-topdiv{
    font-size: 16px;
    color: #1B2952;
}

.consent-Layout-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 28.5px;	
    padding-bottom: 31.5px;
    cursor: pointer;
    transition: .5s;
}

.consent-toggle-div{
    border-bottom: 1px solid #E3E6F1;
}
.accordian-content{
    padding-bottom: 30px;
    transition: .5s;
}
.consent-Layout-head p{
   font-size: 16px;
   font-family: 'Axiforma-Bold';
   margin-bottom: 0px;
   color: #BCC1CE;
   line-height: 16px;
}
.consent-Layout-head .consent-para{
    font-size: 16px;
    font-family: 'Axiforma-Bold';
    margin-bottom: 0px;
    color: #1B2952;
    line-height: 23px;
}
.consent-rotate-aero{
    transform: rotate(180deg);
}
.access-layout-select{
    fill: #7782a1;
    stroke: #7782a1;
}
.access-layout-notselect{
    fill: #e3e6f1;
    stroke: #e3e6f1;
}
 .defaultdown-aero{
 stroke: #E3E6F1;
}
.activateaero-down{
    stroke: #7782a1;
}

.social-nametag{
    font-size: 10px;
    color: #333333;
    margin: 0px;
    font-family: "Axiforma-Regular";
    text-align: right;
    margin-left: 7px;
    margin-top: 1px;
  }

  .single-social-login{
    height: 42px;
    width: 100%;
    border-radius: 4px;
  }

  .ovalsingle-social-login{
    height: 42px;
    width: 100%;
    border-radius: 100px;
  }

  .double-social-login{
    height: 42px;
    width: 130px;
    border-radius: 6px;
  }

  .ovaldouble-social-login{
    height: 42px;
    width: 130px;
    border-radius: 100px;
  }

.columntwo-mobile-screen{
    border: 4px solid #E3E6F1;
    width: 302px;
    height: 651px;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.10);
    display: flex;
    margin: auto;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
}
.columntwo-mobile-screencenter{
    position: relative;
    align-items: center;
}
.columntwo-mobile-screentop{
    align-items: baseline;
}
.columntwo-mobile-screenbottom{
    align-items: end;
}
.loginpre-mobile-screen{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 4px solid #E3E6F1;
    width: 302px;
    height: 651px;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.10);
    /* overflow: hidden; */
    padding: 48px 0px 45px 0px;
    position: relative;
    margin: auto;
}
.loginpre-mobile-screenopacity{
opacity: 0.4;
}

.consentpage-div{
    display: grid;
}
.consentpage-divtwo{
    display: grid;
    margin-top: 20px;
    position: relative;
}
.consentpage-inputtdiv{
    margin-bottom: 0px !important;
}
.consentpage-inputtdiv label{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 8px;
}
.consentpage-inputtdiv input{
    height: 50px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #BCC1CE;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 0px 100px 0px 15px;
  
}

.consentpage-inputtdiv input:hover, .consentpage-inputtdiv input:focus{
    border: 1px solid #3064F9!important;
    background-color: #F8FAFE!important;
    transition: .5s;
}
.consent-layout-div svg{
    cursor: pointer;
}
.consent-layout-svg{
    margin: 0px 30px;
}
.login-social-iconshape div{
    cursor: pointer;
}
.consent-background-div{
    height: 50px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #BCC1CE;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 7px 20px 7px 7px;
    display:flex;
}
.consent-theme-div label{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
}
.consent-theme-div .consent-theme-inputfield{
    height: 50px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #BCC1CE;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 7px 7px 7px;
    display: flex;
    align-items: center;
}
.consent-theme-divone{
    display: flex;
}
.consent-theme-divone div:nth-child(1){
    margin-right: 20px;
}
/* .consent-theme-divone div:nth-child(3){
    margin-left: 10px !important;
} */
.consent-theme-divtwo{
    display: flex;
    margin-top: 15px;
}
.consent-theme-divtwo div:nth-child(1){
    margin-right: 20px ;
}
.consent-theme-divone div{
    flex: 50% 1;
}
.consent-theme-divtwo div{
    flex: 50% 1;
}
.consent-color-div{
    width: 36px !important;
    height: 36px;
    margin-right: 10px !important;
    flex: initial !important;
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    cursor: pointer;
}
.consent-theme-inputfield p{
    margin-bottom: 0px;
    color: #1B2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    line-height: 13px;
    margin-top: 6px;
}
.top
{
    top:0;
}
.down
{
    bottom:0;
}
.concent-text-div{
    position: absolute;
    display: grid; 
    padding: 69px 19px 53px 19px;
}
.concent-headtext{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    text-align: center;
    line-height: 24px;
    color: #333333;
    margin-bottom: 0px;
    word-break: break-word;
}
.concent-subheadtext{
    font-size: 10px;
    text-align: center;
    line-height: 15px;
    color: #A1A1A1;
    /* margin-bottom: 40px;
    margin-top: 3px; */
    font-family: 'Axiforma-Regular';
    word-break: break-word;
    margin: 9px 30px 40px;
}
.concent-acceptbutton{
    height: 40px;
    color: white;
    background-color: #3064F9;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    line-height: 40px;
    width: 256px;
}
.concent-closebutton{
   margin-top: 20px;
    height: 40px;
    border: none;
    border-radius: 6px;
    background: rgba(48, 100, 249, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 256px;
}

.concent-closediv{
    display: flex;
    justify-content: center;
}
.concent-closebutton p{
    color: #A1A1A1;
    opacity: unset;
    margin: 0px;
    font-size: 12px;
    line-height: 11px;
    font-family: 'Axiforma-Regular';

}
.upload-background-div{
    justify-content: center;
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    border-radius: 3px;
    background-color: #EDF0F5;
    margin-right: 10px;
    cursor: pointer;
}
.consent-background-div span{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    align-items: center;
    display: flex;
}
.upload-image-div{
    display: flex;
    align-items: center;
    width: calc(100% - 36px);
    justify-content: space-between;
}
.consent-mobile-head{
  display: none;
}
.mobile-save-button-disable, .mobile-save-button{
    display: none;
}
.mob-page-consenthead{
    display: none;
}
.mob-page-loginhead{
    display: none;
}
.login-social-iconshape{
    display: flex;
    margin-top: 30px;
    align-items: center;
}
.round-login-icon{
    border: 2px solid #E3E6F1;
    width: 18px;
    height: 18px;
    border-radius: 22px;
    margin-right: 40px;
    cursor: pointer;
}
.access_colorpicker .color_input {
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #fff;
    padding: 7px 7px 7px 3px!important;
    height: 50px!important;
    transition: .5s!important;
}
.social-login-shape-select{
    border: 2px solid #7782A1 !important;
}
.square-login-icon{
    border: 2px solid #E3E6F1;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-right: 40px;
    cursor: pointer;
}
.rectangle-login-icon{
    border: 2px solid #E3E6F1;
    width: 22px;
    height: 16px;
    border-radius: 2px;
    margin-right: 40px;
    cursor: pointer;
}
.oval-login-icon{
    border: 2px solid #E3E6F1;
    width: 24px;
    height: 16px;
    border-radius: 22px;
    cursor: pointer;

}
.login-mob-previewtop{
    display: flex;
    justify-content: center;
    height: 32px;
    margin-bottom: 20px;
}
.login-mob-previewtopback{
  
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    margin-right: 30px;
   cursor: pointer;
    margin-bottom: 20px;
}
.login-mob-previewtopbackopacity{
    opacity: 0.4;
    cursor: auto !important;
}
.login-mob-previewtopnext{
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    /* margin-right: 30px; */
    margin-bottom: 20px;
cursor: pointer;
}
.mob-previewtopnextdiv{
    margin-right: 0px !important;
}
.login-columntwo{
  padding: 20px 30px !important;
}
.login-preview-cross{
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(48, 100, 249, 0.1);
    border-radius: 60px;
    float: right;
    position: absolute;
    right: 16px;
}
.preview-login-bottomdiv{
    position: absolute;
    bottom: 45px;
}
.preview-login-bottomdiv p{
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #A1A1A1;
    margin-bottom: 0px;
    font-family: 'Axiforma-Regular';
    width: 270px;

}
.forgot-preview-head{
    font-family: 'Axiforma-bold';
    font-size: 18px !important;
    color: #333333;
}
.forgot-preview-para{
 font-size: 11px;
 font-family: 'Axiforma-Regular';
 color: #A1A1A1;
 margin: 9px 50px 30px 0px;
}
.loginopacity
{
    opacity: 0.4;
}
.login-preview-formdiv{
    height: 595px;
    padding: 45px 0px 75px;
    position: relative;
    /* display: grid; */
    display: flex;

}
.preview-crousal-signup{
    width: 100%;
    padding: 0px 19px;
}
.forgot-preview-button{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: #3064F9;
    color: white;
    font-size: 13px;
    margin: 16px 0px 0px;
    font-family: 'Axiforma-Regular';
}
.forgot-preview-formdiv{
    height: 100%;
    padding: 61px 0px 30px;
    position: relative;
    display: grid;
}
.forgit-form-center{
   width: 100%;
   padding: 0px 15px;
}
.forgit-form-end{
    align-items: end;
}
.login-preview-center{
   align-items: center;
}
.login-preview-bottom{
    align-items: end;
}
.login-preview-form{
 display: grid;
}
.login-preview-forgot_password{
    font-size: 10px;
    color: #333333;
    margin-top: 13px;
    line-height: 10px;
    text-align: center;
    font-family: 'Axiforma-Regular';
}
.login-preview-form label{
    font-size: 8px;
    color: #A1A1A1;
    font-family: 'Axiforma-Regular';
    text-align: left;
    margin-bottom: 2px;
}
.preview-login-social{
    display: flex;
    justify-content: center;
    margin-top: 21px;
    margin-bottom: 30px;
}
.login-preview-email-div{
    display: flex;
    flex-direction: column;
}
.login-preview-email{
    display: flex;
    justify-content: space-between;
    border: 1px solid #A1A1A1;
    padding: 6px 13px 5px 13px;
    align-items: center;
    border-radius: 6px;
}
.login-preview-email p{
    font-size: 10px;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    color: #333333;
}
.login-preview-password{
    display: flex;
    justify-content: space-between;
    border: 1px solid #A1A1A1;
    padding: 13px 13px 11px 13px;
    align-items: center;
    margin-top: 15px;
    /* margin-bottom: 7px; */
    border-radius: 6px;
}
.login-preview-password p{
    font-size: 10px;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    color: #A1A1A1;
}
.login-preview-button{
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: #3064F9;
    color: white;
    font-size: 13px;
    margin: 16px 0px 20px;
    font-family: 'Axiforma-Regular';
}
.preview-login-social div{
    
    border: 1px solid #A1A1A1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
/* 
.preview-login-social p{
    display: none;
} */

.preview-login-social-enable div{
    height: 40px;
    width: 127px;
    border-radius: 4px;
    border: 1px solid #A1A1A1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview-login-social-enable p{
    margin-bottom: 0px;
    margin-left: 7px;
    font-size: 11px;
    margin-top: 6px;
    font-family: 'Axiforma-Regular';
    color: #333333;
}
.preview-login-onesocial div{	
    width: 100%;	
    }
.preview-login-social-enable{
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    /* margin-bottom: 30px; */
}
.preview-login-socialcircle div{
    height: 42px;
    width: 42px;
    border-radius: 100px;
}
.preview-login-socialsquare div{
    height: 42px;
    width: 42px;
    border-radius: 6px;
  
}

.preview-login-socialoval div{
    height: 40px;
    width: 82px;
    border-radius: 20px;
  
}

.preview-login-socialrectangle div{
    height: 40px;
    width: 82px;
    border-radius: 4px;
}

.preview-login-socialrectangle p{
    display: block;
}

.preview-login-socialoval p 
{
    display: block;
}

.preview-login-socialsquare p 
{
    display: none;
}

.preview-login-socialcircle p 
{
    display: none;
}

.login-preview-ordiv{
    align-items: center;
    display: flex;
    justify-content: center;
}
.login-preview-ordiv hr{
    margin: 0px;
    width: calc(50% - 9px);
    border: 1px solid #A1A1A1;
}
.login-preview-or{
    color: #B1C5FF;
    font-size: 10px;
    font-family: 'Axiforma-Regular';
    margin: 0px;
    line-height: 13px;
}
.loginsetting-div .setting-head{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    margin-bottom: 12px;
    line-height: 18px;
}
.loginsetting-div p{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    padding-bottom: 20px;
    line-height: 13px;
    margin-bottom: 0px;
}
.loginsetting-div{
 border-bottom: 1px solid #E3E6F1;
}
.setting-loginpreview ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
    padding-top: 40px;
}
.setting-loginpreview ul li{
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;
}
.setting-loginpreview .setting-enablep{
 margin-bottom: 0px;
 font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
}
.setting-loginpreview ul li:last-child{
    margin-bottom: 0px;
}
.setting-enablep svg{
margin-left: 6px;
}


/* switch design */

.access-switch{
    display: inline-block;
    height: 20px;
    position: relative;
    width: 40px;
    margin-bottom: 0px;  
}

.access-switch.access-monitor-slider-padding{
    padding: 0;
}

.access-switch input {
    display: none;
}

input:checked + .access-slider {
    background-color: #668CFD;
    background-image: url(../../assets/images/dashboard/tick-toggle.png);
    background-size: 10.92px 8px;
    background-repeat: no-repeat;
    background-position: bottom 5px left 8px;
}
.access-slider.round {
    border-radius: 34px;
}

input:checked + .access-slider:before {
    transform: translateX(20px);
    background-color: #fff;
}

.access-slider-disable {
    background-image: url(../../assets/images/dashboard/toggle-lock.png) !important;
    background-size: 8.47px 11.29px !important;
    background-position: bottom 5.2px right 6.8px !important;
}

.access-slider.round:before {
    border-radius: 50%;
}
.access-slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 16px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    /* right: -2px; */
}

.access-slider {
    background-color: #D7DBEB;
    background-image: url(../../assets/images/dashboard/togglecross.png);
    background-size: 7px 7px;
    background-repeat: no-repeat;
    background-position: bottom 6.2px right 8.8px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.lock-access-switch{
    display: inline-block;
    height: 20px;
    position: relative;
    width: 40px;
    margin-bottom: 0px;  
}
.lock-access-switch.lock-access-monitor-slider-padding{
    padding: 0;
}
.lock-access-switch input {
    display: none;
}
input:checked + .lock-access-slider {
    background-image: url(../../assets/images/dashboard/toggle-lock.png);
    background-color: #BBCCFF;
    background-size: 8.47px 11.29px;
    background-repeat: no-repeat;
    background-position: bottom 5px left 8px;
}
input:checked + .lock-access-slider:before {
    transform: translateX(20px);
    background-color: #fff;
}
.lock-access-slider.round {
    border-radius: 34px;
}
.lock-access-slider.round:before {
    border-radius: 50%;
}
.lock-access-slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 16px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    /* right: -2px; */
}
.lock-access-slider {
    background-color: #D7DBEB;
    background-image: url(../../assets/images/dashboard/togglecross.png);
    background-size: 7px 7px;
    background-repeat: no-repeat;
    background-position: bottom 6.2px right 8.8px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

/* switch design end*/
.select-setting{
    fill: #7782A1;
    stroke: #7782A1;
}
 .notselect-setting{
    fill: white !important;
    stroke: #7782A1;
}
.date-and-savediv{
    display: flex;
    align-items: center;
    margin-top: -10px;
    gap: 20px;
}
.consent-save-date{
    margin-bottom: 0px;
    font-size: 12px;
    color: #BCC1CE;
    /* margin-right: 37px; */
}
.mob-consent-save-date{
    display: none;
}


.access-screen-ratio {
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
  }
  .access-screen-ratio div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 10px;
    cursor: pointer;
    margin-bottom: 0px;
    margin-right: 15px;
  }
  .access-screen-ratio div:last-child {
    border-right: none;
  }
  .new-consent-head .accordionItemContent .access-screen-ratio div p {
    font-family: "Axiforma-Regular";
    font-size: 11px;
    color: #BCC1CE!important;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .accessimg-ratio > input {
    display: none;
  }
  .accessimg-ratio > input + div svg {
    stroke: #E3E6F1 !important;
  }
  .accessimg-ratio > input + div svg {
    fill: #E3E6F1!important;
  }
  .accessimg-ratio > input:checked + div svg {
    stroke: #7782A1 !important;
    fill: #7782A1 !important;
  }
  .accessimg-ratio > input:checked + div svg {
    fill: #7782A1 !important;
    stroke: #7782A1 !important;
  }
  .new-consent-head .accordionItemContent .accessimg-ratio > input:checked + div p {
    color: #7782A1!important;
  }
.acces-theme-options .color_input{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #fff;
    padding: 7px 7px 7px 3px!important;
    height: 50px!important;
    transition: .5s!important;
}
.acces-theme-options .color_input:hover{
    background-color: #F8FAFE!important;
    border-color: #3064F9!important;
    transition: .5s!important;
}
.acces-theme{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.acces-social-theme{
    display: flex;
    flex-wrap: wrap;
}
.acces-theme-options{
    width: 48%;
}
.color_input p{
    font-family: "Axiforma-Regular";
    font-size: 13px !important;
    color: #1B2952;
}
.access-mt{
    margin-top: 15px;
}
.disabled-access .consent-para{
    color: #BCC1CE;
}
.disabled-access .accessaccordian .consent-Layout-head{
    cursor: not-allowed;
}
.disabled-access .accessaccordian .consent-Layout-head img{
    filter: brightness(1.5);
}
.specifytext{	
    top: -5px !important;	
}


.disabled-access .accessaccordian .consent-Layout-head svg{
    /* filter: brightness(1.5); */
    opacity: 0.3;
}


.consent-tooltip{
    position: relative;
    display: inline-block;
    margin-bottom: 3px;
}

.consent-tooltip .consent-tooltipsubheader{
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: -3px;
    left: 135%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
}

.consent-tooltip .consent-tooltipsubheader::after{
    content: "";
    position: absolute;
    top: 14px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.consent-tooltip:hover .consent-tooltipsubheader {
    visibility: visible;
    text-align: left !important;
  }

  .consent-tooltip .consent-tooltipsubheader-notify::after{
    content: "";
    position: absolute;
    top: 18px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.consent-tooltip:hover .consent-tooltipsubheader {
    visibility: visible;
    text-align: left !important;
  }
  .consent-tooltip:hover .consent-tooltipsubheader-notify {
    visibility: visible;
    text-align: left !important;
  }
  .consent-tooltip .consent-tooltipsubheader-notify{
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 147%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
  }

.displaynone{
    display: none!important;
}

.setting-tooltip .setting-tooltipsubheader::after{	
    content: "";	
    position: absolute;	
    top: 14%;	
    right: 100%;	
    margin-top: -2px;	
    border-width: 5px;	
    border-style: solid;	
    border-color: transparent black transparent transparent;	
}

.setting-tooltip:hover .setting-tooltipsubheader {	
    visibility: visible;	
    text-align: left !important;	
  }	
  .setting-tooltip .setting-tooltipsubheader{	
    visibility: hidden;	
    width: 305px;	
    background-color: #000000;	
    color: #fff;	
    border-radius: 4px;	
    position: absolute;	
    z-index: 1;	
    top: -7px;	
    left: 147%;	
    padding: 14px 30px 14px 22px;	
    opacity: 0.8 !important;	
    font-size: 12px;	
    line-height: 21px;	
    display: grid;	
    transition: 0.3s;	
  }	
.login-preview-carousel .carousel .slider-wrapper {	
    width: 100%;
}	
.login-preview-carousel .carousel .control-dots{	
    display: none;	
}	
.login-preview-carousel .carousel .carousel-status{	
    display: none;	
}	
.login-preview-carousel .slider:before{	
    display: none;	
}	
.login-preview-carousel .carousel .slide{	
    text-align: left;	
}	
.login-preview-carousel  .slider{	
    background-color: transparent;	
    background-image: none;	
}	
.login-preview-carousel .carousel .thumbs-wrapper{	
    display: none;	
}	
.login-preview-carousel .carousel.carousel-slider {	
    overflow: unset;	
}	
.login-preview-carousel  .carousel .control-prev.control-arrow {	
    left: 101px;	
} 	
.login-preview-carousel .carousel.carousel-slider .control-arrow {	
    top: -102px !important;	
    width: 32px;	
    height: 32px;   	
    font-size: 16px !important;	
    border-radius: 50%;  	
}	
.login-preview-carousel .carousel .control-next.control-arrow {	
    right: 101px;
}	
.login-preview-carousel .carousel .control-prev.control-arrow:before, .login-preview-carousel .carousel .control-next.control-arrow:before {	
   display: none;	
}	
.login-preview-carousel .carousel.carousel-slider .control-arrow:hover {	
    background: unset;	
}	
.consent-tooltip .setting-managed {	
    top: -9px !important;	
    left: 143% !important;	
}	
.consent-tooltip .permit-managed {	
    top: -3px !important;	
    left: 143% !important;	
}
.mob_consentupgrade{
    display: none !important;
}
.setting-loginpreview{
    margin: -10px -4px 0px 0px;
}



/* build-setting-page */

.accessbuild-setting-page{
    padding: 20px 30px;
    }
    .accessbuild-setting-pagehead{
        border-bottom: 1px solid #E3E6F1;
    }
    .accessbuild-setting-android{
        position: relative;
    }
    .accessbuild-setting-ios{
        position: relative;
    }
    .accessbuild-setting-pagehead h2{
        font-size: 18px;
        color: #1B2952;
        font-family: 'Axiforma-Bold';
    }
    .accessbuild-setting-android .accordion-button{
        background-color: transparent;
    width: 100%;
    border: none;
    padding: 30px 0px 30px 0px;
    position: relative;
    }
    .accessbuild-setting-ios .accordion-button{
        background-color: transparent;
    width: 100%;
    border: none;
    padding: 30px 0px 30px 0px;
    position: relative;
    }
    .accessaerodown{
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
    .accessbuild-setting-android .collapsed .accessaerodown {
        transform: rotate(180deg);
    }
    .accessbuild-setting-ios  .collapsed .accessaerodown {
        transform: rotate(180deg);
    }
    .accessbuild-setting-pagehead p{
        color: #7782A1;
        font-family: 'Axiforma-Regular';
        font-size: 13px;
        margin-bottom: 17px;
        text-align: left !important;
    }
    .accessbuild-setting-android-head{
        display: flex;
        align-items: center;
    }
    .accessbuild-setting-bundle{
        display: flex;
        align-items: center;
    }
    .accessbuild-setting-androidtop{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .accessbuils-setting-toggle{
        display: flex;
        justify-content: space-between;
        /* margin-top: 30px; */
        align-items: center;
    }
    .accessandroid-build-setting-div{
        width: 50%;
      
    }
    .accessbuild-setting-android .accordion-header{
        margin-bottom: 0px !important;
    }
    .accessbuild-setting-ios .accordion-header{
        margin-bottom: 0px !important;
    }
    .accessbuild-setting-android .accordion-collapse.show{
        padding: 0px 0px 30px 0px;
    }
    .accessbuild-setting-ios .accordion-collapse.show{
        padding: 0px 0px 30px 0px;
    }
    .accessbuild-setting-googleinput{
        border: 1px solid #BCC1CE;
        border-radius: 3px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px 0px 10px ;
        font-size: 13px;
        font-family: 'Axiforma-Regular';
        color: #1B2952;
        position: relative;
    }
    .accessbuild-setting-googleinput:hover{
        border: 1px solid #3064f9!important;
        background-color: #f8fafe!important;
        transition: .5s;
    }
    .accessbuild-inputincorect{
        border: 1px solid #ff4848!important;
    }
   .google-cross{
    position: absolute;
    right: 15px;
   }
.accessbuild-setting-googleinput label{
    margin-bottom: 0px;
    display: flex;
align-items: center;
justify-content: space-between;
width: calc(100% - 30px);
cursor: pointer;
}
.accessbuild-setting-googleinput input{
  
    width: calc(100% - 60px);
    }
    .accessbuild-setting-googleinput .download-bayimage{
        width: 36px;
        height: 36px;
        min-width: 36px;
    }
    .accessbuild-setting-inputfirstdiv p{
        margin-bottom: 0px;
        margin-left: 10px;
        margin-right: 8px ;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
      }
      .accessbuild-setting-inputfirstdiv .documentdiv{
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        background-color: #EDF0F5;
        border-radius: 3px;
        justify-content: center;
        cursor: pointer;
        min-width: 36px;
      }
      .accessbuild-setting-inputfirstdiv svg{
        margin: 0px !important;
        cursor: pointer;
      }
    .accessbuild-setting-inputfirstdiv{
        display: flex;
        /* justify-content: center; */
        align-items: center;
        cursor: pointer;
        width: 100%;
    }
    .accessbuild-setting-android-icon{
        padding: 13px 15px;
        background-color: #F8FAFE;
        border-radius: 3px;
        margin-right: 20px;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .accessbuild-setting-android-head p{
        font-size: 16px;
        font-family: 'Axiforma-Bold';
        margin-bottom: 0px;
        color: #1B2952;
    }
    .accessbuild-setting-bundleright p{
        font-size: 13px;
        color: #7782A1;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        margin-right: 6px;
        text-align: left !important;
    }
    .accessbuild-setting-bundleright p span{
        color: #1B2952;
    }
    .accessbuild-setting-bundleright p svg{
        margin-left: 6px;
    }
    .accessbuild-setting-bundleright svg{
        cursor: pointer;
    }
    .accessbuild-setting-bundleright{
        display: flex;
        margin-right: 35px;
        align-items: center;
        position: absolute;
    right: 13px;
    text-align: right;
    /* z-index: 2; */
    cursor: auto;
    top: 49px;
    }
    .accessbuils-setting-toggle h3{
        font-size: 13px;
        color: #7782A1;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
    }
    .accessbuils-setting-toggle svg{
        margin-left: 7px ;
      
    }
    .accessbuild-setting-googlefield{
     /* margin-bottom: 20px; */
     margin-top: 30px;
    }
    .accessbuild-setting-googlefield h3{
        font-size: 13px;
        color: #7782A1;
        font-family: 'Axiforma-Regular';
        /* margin-right: 10px; */
        margin-bottom: 9px;
    }
    .accessbuild-setting-googlefield svg{
        margin-left: 7px ;
        cursor: pointer;
    }
    .accessbuild-setting-text h3{
        font-size: 13px;
        color: #7782A1;
        font-family: 'Axiforma-Regular';
        /* margin-right: 10px; */
        margin-bottom: 9px;
    }
    .accessbuild-setting-text svg{
        margin-left: 7px ;
    }
    .accessbuild-setting-text textarea{
        width: 100%;
        height: 100px;
        border-radius: 3px;
        border: 1px solid #BCC1CE;
        resize: none;
        font-size: 13px;
        color: #1B2952;
        font-family: 'Axiforma-Regular';
        outline: none;
        padding: 15px 16px;
    }
    .accessbuild-setting-text textarea:hover, .accessbuild-setting-text textarea:focus{
        border: 1px solid #3064f9!important;
        background-color: #f8fafe!important;
        transition: .5s;
    }
    .accessmobaccessbuild-setting-bundleright{
        display: none;
    }
    .access-sha-input{
        height: 50px;
        font-size: 13px;
        font-family: "Axiforma-Regular";
        border: 1px solid #bcc1ce;
        outline: none!important;
        color: #1b2952;
        border-radius: 3px;
        padding: 15px;
        width: 100%;
        margin-bottom: 20px;
    }
    .access-sha-input:hover{
        border: 1px solid #3064f9!important;
        background-color: #f8fafe!important;
        transition: .5s;
    }
    .access-sha-inputdiv{
        position: relative;
    }
    .sha-generate{  
        position: absolute;
        top: 16px;
        right: 15px;
        font-size: 13px;
        color: #3064F9;
        margin-bottom: 0px;
        cursor: pointer;
        background-color: white;
        padding-left: 10px;
    }
    .sha-generate p{
    font-size: 13px;
    color: #3064F9;
    margin-bottom: 0px;
    font-family: "Axiforma-Regular";
    background-color: white;
    padding: 0px 0px 0px 10px;
    }
    .consent-Layout-head .consent-tooltip{
        margin-left: 6px;
    }
   

    .consent-theme-div .consent-tooltip{
        margin-left: 6px;
    }



    .column-left-child{
        display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    }
    .column-head-child .consent-tooltip{
        margin-bottom: 0px !important;
        margin-left: 0px !important
    }
    .column-head-child{
font-size: 13px !important;
color: #7782A1 !important;
font-family: "Axiforma-Regular" !important;
margin-bottom: 0px !important;
line-height: 18px;
    }


    .access-stack-view{
        display: grid;
    justify-content: normal;
    margin-top: 20px;
    margin-bottom: 30px;
    }
    .access-social-margin{
        margin-right: 12px;
    }
    .access-stack-div{
        width: 100%;
    }
    .access-stack-div-margin{
        margin-bottom: 8px;
    }
    .accessbuild-setting-android.accordion-item , .accessbuild-setting-ios.accordion-item{
        border-bottom: 1px solid #e3e6f1!important;
    }
    .upgrade-skip-consent{
        display: flex;
        align-items: center;
        margin-top: 37px;
        gap: 20px;
    }
    

    @media screen and (max-width: 1500px) and (min-width: 1201px) {
       
        .new-concentpage-columnupgrade{
            display: flex;
            padding: 154px 20px 20px 20px;
        }
        .new-concentpage-colutwomnupgrade{
            padding: 120px 20px 20px 0px;
        }
    }
    @media (min-width: 2001px) {
       
        .new-concentpage-columnupgrade {
            justify-content: end;
        }
    }
@media (max-width: 1200px) {
    .consent-setting{
        width: 40px;
        padding: 0px;
    }
  .upgrade-support{
        font-size: 14px !important; 
        color: #7782A1 !important;
        font-family: 'Axiforma-Regular'!important;
        line-height: 20px !important;
        margin: 0px !important;
        text-align: start !important;
      }
    .upgrade-unavailable{
        font-size: 14px !important;
        color: #1B2952 !important;
        font-family: 'Axiforma-Bold' !important;
        line-height: 20px;
        margin: 0px !important;
        text-align: start !important;
        
      }
      .upgrade-feature{
        margin: 0px 15px 20px;
      }
    .produclogintsetting-tooltipsubheader{
        width: 320px !important;
        top: -95px !important;
        left: -196px !important;
        padding: 7px !important;
        line-height: 15px !important;
      }
      .produclogintsetting-tooltipsubheader::after {
        top: 96px !important;
        right: 33% !important;
    transform: rotate(271deg) !important;

      }
    .build-setting-tooltip .productsetting-tooltipsubheader{
        top: -46px !important;
        left: 34px !important;
      }
    .build-setting-tooltip .productsetting-tooltip .productsetting-tooltipsubheader::after{
    top: 57px;
    right: 190px;
    margin-top: -9px;
    transform: rotate(0deg);
      }
    .accessbuild-setting-page {
        padding: 0px;
    }
    .accessandroid-build-setting-div {
        width: 100%;
    }
.accessbuild-setting-pagehead {
padding: 25px 15px 0px 15px;
}
.accessbuild-setting-android {
    /* border-bottom: 1px solid #E3E6F1; */
    padding: 0px 15px;
}
.accessbuild-setting-ios {
    padding: 0px 15px;
}
.accessmobaccessbuild-setting-bundleright{
    display: block;
    margin-bottom: 30px;
    position: unset;
}
.webaccessbuild-setting-bundleright{
    display: none;
}
.accessbuild-setting-android-icon {
    padding: 5px;
    background-color: unset;
    border-radius: 3px;
    margin-right: 20px;
    width: unset;
    height: unset;
}
.accessbuild-setting-android-icon svg{
    height: 24px !important;
}
/* build-setting-page */




    .consentpage-divtwo {
       margin-top: 17px; 
    }
    .setting-loginpreview{
        margin: unset;
    }
    .setting-loginpreview ul li {
    margin-bottom: 25px;
}
    .consent-Layout-head {
        padding-top: 25px;
    padding-bottom: 25px;
    }
    .new-concentpage-columnupgrade ul {
        padding: 10px 0px 0px 15px;
    }
    .web_consentupgrade{
        display: none !important;
    }
    .mob_consentupgrade{
        display: block !important;
    }
    .new-concentpage-columnupgrade p {
        margin-bottom: 23px;
    }
    .new-concentpage-columnupgrade a{
        font-size: 16px;
    }
    .new-concentupgradetext{
        width: 100%;
    }
    .new-consentpage-upgrade {
        /* display: grid; */
        background-image:unset;
        flex-direction: column;
        justify-content: normal;
    }
    .new-concentpage-columnupgrade {
        justify-content: center;
        padding: 22px 19px;
        width: 100%;
    }
    .new-concentpage-columnupgrade a {
        width: 100%;
    }
    .new-concentpage-colutwomnupgrade {
        justify-content: center;
        padding: 35px 20px 20px 20px;
        margin-left: 0px;
        width: 100%;
    }
    .new-concentpage-columnupgrade h3 {
        font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
    }
    .access-loader{
        height: calc(100vh - 140px );
    border-radius: 6px !important;
    margin: 0px 15px 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    border: 1px solid #E3E6F1;
      }
    .consent-tooltip .setting-managed {	
        top: -9px !important;	
        left: 143% !important;	
    }	
    .consent-tooltip .permit-managed {	
        top: -3px !important;	
        left: 143% !important;	
    }	
    input:checked + .lock-access-slider:before {	
        transform: translateX(16px);	
        	
    }	
    input:checked + .access-slider:before {	
        transform: translateX(16px);	
        	
    }	
    input:checked + .access-slider {	
        background-size: 9.92px 8px;	
    }	
    .access-slider:before, .lock-access-slider:before {	
        bottom: 2px;	
        height: 14px;	
        width: 14px;	
       	
    }	
    input:checked + .access-slider, input:checked + .lock-access-slider {	
        background-position: bottom 4px left 6px;	
    }	
    .access-slider, .lock-access-slider {	
        background-position: bottom 5.2px right 5.8px;	
    }	
    .access-switch, .lock-access-switch {	
        height: 18px;	
        width: 34px;   	
    }	
    .column-left-head .column-head-topdiv {	
        font-size: 14px;	
    }	
    .consent-Layout-head .consent-para {	
        font-size: 14px;	
    }	
    .image-uploader{	
        padding-right: 15px;	
    }	

    .consent-tooltip .consent-tooltipsubheader{
        visibility: hidden;
        width: 305px;
        background-color: #000000;
        color: #fff;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: -5px;	
        left: 135%;
        padding: 14px 30px 14px 22px;
        opacity: 0.8 !important;
        font-size: 12px;
        line-height: 21px;
        display: grid;
        transition: 0.3s;
    }
    .secondary-text-mob{
   margin-top: 15px;
    }
.color_input .accessfixback {
        bottom: unset;
    }
    .acces-theme {
        margin-top: -5px;
    }
    .acces-theme-options {
        width: 100%;
        position: relative;
    }
    .new-consentpage {
        min-height: unset;
    }
    .loginsetting-div{
        padding: 30px 15px 4px;
    }
    .loginsetting-div p {
        text-align: left !important;
    }
    .setting-loginpreview ul {
        padding: 30px 15px 40px;
    }
    .loginsetting-div .setting-head{
        font-size: 16px;
            margin-bottom: 8px;
    }
    .new-consent-headh{
      display: none ;
    }
    .new-consentpage {
        margin: 0px 15px 20px;
    }
    .new-concentpage-columntwo{
        display: none;
    }
    .consent-modal-tab{
        display: none;
    }
    .web-access-head{
        display: none;
    }
    .consent-setting{
     margin-right: 0px;
    }
    .consent-mobile-head h1{
        font-size: 20px;
        line-height: 20px;
        /* justify-content: center; */
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #EDF0F5;
    }
    .consent-mobile-head{
        padding: 25px 20px 0px;
    background-color: white;
    height: 100vh;
    display: block;
   
    }
    .mob-consent-save-date{
        font-size: 12px;
    color: #BCC1CE;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 17px;
    }
   
    .consent-pages-open p{
        text-align: left !important;
        line-height: 16px;
        margin-bottom: 0px;
        padding: 30px 0px;
        border-bottom: 1px solid #EDF0F5;
        color: #1B2952;
        justify-content: space-between;
    display: flex;
    font-family: 'Axiforma-Regular';
    cursor: pointer;
    }
    /* .mobile-consent-link{

    }
    .mobile-login-link{

    } */
    .new-concentpage-columnone {
        padding: 0px;
    }
    .column-left-head {
        padding: 30px 15px 30px 15px;
    }
    .consent-toggle-div {
        padding: 5px 15px 5px 15px;
    }
    .mobile-save-button{
        display: block;
        height: 50px;
        color: white;
        background-color: #3064F9;
        border-radius: 3px;
        border: none;
        width: calc(100% - 30px);
        margin: auto;
        margin-bottom: 20px;
        font-family: 'Axiforma-Regular';
        font-size: 16px;
        &:hover {
            color: var(--custom_color4)!important;
            background-color: var(--custom_color5)!important;
        }

    }
    .mobile-save-button-disable{
        display: block;
        height: 50px;
        color: white;
        background-color: #BCC1CE;
        border-radius: 3px;
        border: none;
        width: calc(100% - 30px);
        margin: auto;
        margin-bottom: 20px;
        font-family: 'Axiforma-Regular';
        font-size: 16px;

    }
    .new-consentpage {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    }
    .mob-page-consenthead{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 15px;
    }
    .mob-page-consenthead p{
        text-align: left !important;
        margin: 20px 15px 20px;
        line-height: 28px;
        font-size: 20px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
    }
  .new-concentpage-columnupgrade li {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 18px;
  }
  .new-concentpage-columnupgrade a{
    padding: 0px;
  }
    .mob-page-loginhead{
        display: flex;
        justify-content: space-between;
       margin: 12px 15px 13px;
        align-items: center;
    }
    .mob-page-loginhead p{
        text-align: left !important;
        line-height: 40px;
        font-size: 20px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        margin-bottom: 0px;
    }
    .consent-tooltip .consent-tooltipsubheader-notify{
        visibility: hidden;
    width: 264px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: -13px;
    left: 156%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    text-align: left !important;
      }
      .consent-tooltip:hover .consent-tooltipsubheader-notify {
        visibility: visible;
        text-align: left !important;
      }
      .consent-tooltip .consent-tooltipsubheader-notify::after{
        content: "";
    position: absolute;
    top: 18px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    }
}
@media (max-width: 469px) {
   
    .consent-theme-div .consent-tooltip .consent-tooltipsubheader-notify::after{
        left: 66px;
    }
    .consent-Layout-head .consent-tooltip .consent-tooltipsubheader-notify::after{
        left: 66px;
    }
    .consent-tooltip .setting-managed {
        top: 28px !important;
        left: -540% !important;
    }
    .consent-tooltip .setting-managed::after{
        left: 121px !important;
    }
    .consent-tooltip .permit-managed {
        top: 28px !important;
        left: -540% !important;
    }
    .consent-tooltip .permit-managed::after{
        left: 121px !important;
    }
    .consent-tooltip .consent-tooltipsubheader{
        visibility: hidden;
        width: 218px;
        background-color: #000000;
        color: #fff;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: 28px;
        left: -254%;
        padding: 14px 15px 14px 15px;
        opacity: 0.8 !important;
        font-size: 12px;
        line-height: 21px;
        display: grid;
        transition: 0.3s;
    }
    .specifytext {
        top: 28px !important;
    }
    .consent-tooltip .consent-tooltipsubheader::after{
        content: "";
        position: absolute;
        top: -7px;
        left: 75px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
    }
  .consent-tooltip .consent-tooltipsubheader-notify{
    visibility: hidden;
    width: 220px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 28px;
    left: -423%;
    padding: 14px 15px 14px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
  }
  .consent-tooltip:hover .consent-tooltipsubheader-notify {
    visibility: visible;
    text-align: left !important;
  }
  .consent-tooltip .consent-tooltipsubheader-notify::after{
    content: "";
    position: absolute;
    top: -7px;
    left: 96px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}
}
@media (max-width: 420px) {
    .accessbuild-setting-inputfirstdiv p {
        text-overflow: ellipsis;
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
    }
}