.errorsection {
    padding-top: 48px;
}
.logtext {
    font: 16px Axiforma-Bold;
    color: #1B2952;
}
.log-subtext {
    font-size: 14px;
    color: #7782A1;
    margin-top: 30px;
    font-family: 'Axiforma-Regular';
}
.log-nothingt-here{
height: 80vh;
}
@media screen and (max-width: 768px) {
    .logspac {
        padding: 0 12px 5px 12px;
    }
    .logsub {
        padding-bottom: 12px;
    }
}

@media screen and (min-width: 768px) {
    .submenu {
        display: flex;
    }
    .errorsection {
        padding-top: 38px;
    }
    .logtext {
        font: 18px Axiforma-Bold;
        color: #1B2952;
    }
    .log-subtext {
        font-size: 14px;
        color: #7782A1;
        margin-top: 30px;
        font-family: 'Axiforma-Regular';
    }
}
@media screen and (max-width: 1200px) {
    .log-nothingt-here{
        height: 65vh;
    }
}