@font-face {
  font-family: "Proxima-Regular";
  src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../assets/fonts/ProximaNova-Bold.otf);
}
@font-face {
  font-family: "Axiforma-Regular";
  src: url(../../assets/fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../../assets/fonts/Axiforma-Bold.woff);
}
canvas {
  font-family: "Axiforma-Regular";
}
.histogramparent canvas{
  height: 88% !important;
  width: 100% !important;
  padding: 0px 20px 20px 20px !important;
}
.canvas_donut canvas{

display: block;
    box-sizing: border-box !important;
    height: 176px !important;
    width: 346px !important;
}

.installs {
  display: flex;
  margin-top: 26px;
  align-items: center;
  background: #f8fafe;
  padding: 0px;
  margin-bottom: 20px;
  border-radius: 3px;
}
.installs > div {
  background-color: #fff;
  padding: 40px 25px;
    border-radius: 4px;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    margin-right: 20px;
    flex: 1 1;
    min-height: 120px;
}
.installs >div:nth-of-type(3){
  background-color: #fff;
  padding: 40px 25px;
  border-radius: 4px;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  margin-right: 0px;
  flex: 1 1;
  /* min-height: 120px; */
}
.installs h2 {
  font-size: 30px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}
.installs p {
  font-size: 14px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.installs p a{
  font-size: 14px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  text-decoration: none;
}
.app_count_head {
  margin-bottom: 21px; 
  justify-content: space-between;
}
.app_count_head h3 {
  font: 20px "Axiforma-Bold";
  color: #1b2952;
  padding-top: 0px;
  margin-bottom: 0px !important;
  line-height: 37px !important;

}
.app_count_head p {
  margin-bottom: 0px;
}
.shownodata h5 {
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin: 0px;
  padding: 0px;
}
.histogramparent {
  margin-bottom: 0;
  background-color: #fff;
  margin-left: 0;
  padding: 0px;
  position: relative;
  border: 1px solid #E3E6F1;
  height: 435px !important;
  width: calc(100% - 420px)!important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px !important;
  padding: 0px !important;
}
.donutwidth {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  right: 0;
}
.donutparent {
  background: #f8fafe;
  width: 400px!important;
  height: 435px !important;
  border: 1px solid #E3E6F1;
  position: relative;
  background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-left: 0;
}
.shownodata {
  display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.selectfield {
  border: 1px solid #c8cedb;
  height: 30px;
  width: 120px;
  border-radius: 3px;
  color: #7782a1;
  font: 12px "Axiforma-Regular";
  padding: 2px 7px 0px 10px;
}
.heading h2 {
  color: #1b2952;
  font: 16px "Axiforma-Bold";
}
.app_count_head p {
  font: 13px "Axiforma-Regular" !important;
  color: #7782a1 ;

}
.data_tables {
  /* width: 32.3%; */
  /* margin-bottom: 0%; */
  border-radius: 4px !important;
  /* margin-top: 20px !important; */
  border: 1px solid #E3E6F1;
  height: auto !important;
  min-height: 220px !important;
  padding: 25px 20px 10px 20px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  position: relative;
  margin: 20px 1.2% 0px 0px;
  width: 32.1%;
}
.analytics_table_head {
  background-color: #ffff;
  padding: 5px 0px 10px 0px;
}
.analytics_table_head h5 {
  font: 13px "Axiforma-Regular";
  color: #BCC1CE;
  margin-bottom: 0px;
  text-transform: capitalize;
  display: flex !important;
 
}
.table_without_picture h5 {
  margin-bottom: 0px;
}
.table_without_picture .analytics_table_data {
  padding: 20px;
}
.analytics_table_start h2 {
  font: 16px "Axiforma-Bold";
  text-transform: capitalize;
  color: #1b2952;
  margin-bottom: 0px;
  margin-left: 10px !important;
  padding-left: 0px !important;
}
/* .analytics-datatable-text {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
} */
.analytics_table {
  border: none !important;
  border-radius: 3px;
  /* min-height: 400px; */
}
.analytics_image{
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
}
.analytics_image h5 {
  font: 13px "Axiforma-Regular";
  color: #1b2952;
  text-transform: capitalize;
  margin-bottom: 0px;
   overflow: hidden;
  text-overflow: ellipsis;
}
.width {
  width: 1000px;
}
.height {
  height: 500px;
}
.total_views h5 {
  font: 13px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
}
.analytics_table_data {
  padding: 17px 2px 10px 2px;
}
.analytics_table_data:nth-child(even) {
  background-color: #ffff;
}
.analytics_scroll_table {
  height: auto;
  overflow-y: auto;
}
.analytics_scroll_table::-webkit-scrollbar-thumb {
  background-color: #c8cedb !important;
  border-radius: 9px !important;
}
.analytics_scroll_table::-webkit-scrollbar {
  width: 3px !important;
}
.tookval {
  font-size: 24px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}
.tookcount {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.histogramspinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adjustbargraphheight {
  /* background: url("../../assets/images/dashboard/nobargraph.png"); */
  width: 100%;
  height: 77% !important;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
  box-shadow: none !important;
  padding: 0px 20px;
}
.adjustbargraphheight img{
  max-width: 100%;
    height: auto;
}














/* @media screen and (min-width: 2100px) {

  .data_tables {
    margin: 20px 24px 0px 0px;
  }
  
} */

@media (min-width: 1200px) and (max-width: 1415px) {
  .app_count_head {
    display: block !important;
  }
  .histogramparent {
    width: calc(100% - 420px);
  }
  .donutparent {
    width: 400px;
    margin-left: 0px;
  }
  .donutwidth {
    width: 95%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .chart_data_tables__2THT9 {
    width: 31.5%;
  }
}

@media (min-width: 1280px) and (max-width: 1560px) {
  .chart_data_tables__2THT9 {
    width: 31.5%;
  }
}

@media (min-width: 1416px) and (max-width: 1478px) {
  .histogramparent {
    width: calc(100% - 420px);
  }
  .donutparent {
    width: 400px;
    margin-left: 0px;
  }
  .donutwidth {
    width: 95%;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media screen and (max-width: 980px) {
  .app_count_head {
    margin-top: 20px; 
  }
  /* .donutparent {
    height: 380px !important;
  } */
  /* .installs {
    display: block;
    margin-bottom: 10px !important;
    margin: 0px!important;
    margin-bottom: 10px !important;
  } */
  /* .installs > div {
    margin-bottom: 10px;
  } */
  /* .installs > div:last-child {
    margin-bottom: 0px;
  } */
  .adjustbargraphheight {
    background-size: 965px;
    padding: 0px;
  }
  .data_tables {
    width: 48.8%;
    margin-left: 0px !important ;
    margin-right: 0px !important ;
    margin-bottom: 0px;
    margin-top: 10px !important;
  }
  
  .app_count_head {
    width: 100%;
    margin: 0px !important;
   
  }
  .data_tables:nth-child(odd) {
    margin-right: 12px !important;
  }
  .histogram {
    width: 100% !important;

    padding: 0px !important;
    margin-bottom: 50px !important;
  }
  .donutparent {
    margin-top: 37px;
    margin-left: 0px;
  }
  .heading h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  /* .installs {
    padding: 15px 15px 0px 15px;
  } */
  .data_tables:nth-child(odd) {
    margin-right: 0px !important;
  }
 
}
@media (min-width: 768px) and (max-width: 1200px) {
  .ipad_display {
    display: block;
  }
  .app_count_head {
    margin-top: 20px !important; 
  }
}
@media (min-width: 250px) and (max-width: 980px) {
  .histogram {
    margin-bottom: 0px !important;
  }
  .donutparent {
    margin-top: 15px !important;
   
  }
  .donutwidth img{
    height: 240px;
  }
  .donutwidth .canvas_donut canvas{
    height: 240px !important;
  }
}
@media (min-width: 200px) and (max-width: 767px) {
  .histogramparent {
    height: 360px !important;
  }
  .histogramparent canvas{
    height: 80% !important;
    width: 100% !important;
    padding: 0px 20px 25px 20px !important;
  }
  .adjustbargraphheight{
    margin-top: 0px !important;
  }
  .data_tables {
    width: 100% !important;
    margin-left: 0px !important ;
    margin-right: 0px !important ;
    margin-bottom: 0px;
    margin-top: 15px !important;
  }
  .installs > div {
        margin-right: 0px;
    }
    .app_count_head{
      display: none;
    }
  
}
@media screen and (max-width: 1200px) {
  .installs > div {
    padding: 32px 20px;
  }
  .installs >div:nth-of-type(3){
    padding: 32px 20px;
  }
  .spacing {
    padding: 0px 15px 15px;
  }
  .installs > div {
    margin-bottom: 15px;
    margin-right: 0px;
  }
  /* .installs {
    margin-bottom: ;
  } */
  .installs {
    display: block;
    margin: 0px!important;
    margin-bottom: 15px !important;
  }

}








