.create-segment * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.new-audience-login {
  display: flex;
  min-height: 100vh;
  position: relative;
  /* max-width: 1920px; */
  margin: 0 auto;
  background-color: #fcfdff;
}
.notification-audience-home{
  border: none !important;
}
.notification-audience-home:hover{
  border: none !important;
}
/* .new-audience-login .right-panel-gap {
  background-color: #f8fafe;
  padding: 30px;
  width: calc(100% - 200px);
  margin-left: 200px;
  margin-top: 60px;
  position: relative;
  transition: 0.4s;
  min-height: calc(100% - 60px);
  padding-top: 20px;
} */
.table-mobile-view {
  display: none;
}
.audiebce-mob-head{
   display: none; 
}
.new-audience-head h1 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.new-audience-head h1 svg {
  margin-left: 6px;
}
.select-wrapper .profile-role-dropdown__control{
  padding: 0px 5px 0px 15px !important;
}
.audience-heading h1 {
  font-size: 24px;
  line-height: 24px;
  font-family: 'Axiforma-Bold';
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-view-click:hover .profile-role-dropdown__control{
  box-shadow: unset !important;
}
.segmet-error-input{
  border: 1px solid #ffbaba!important;
  background-color: #fffcfc!important;
  }    
  
.audience-heading h1 .audience-tooltip {
  margin-left: 6px;
}
.audience-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
}
.audience-tooltip svg {
  margin-top: 5px;
}
.audience-tooltip .audience-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: 32px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.audience-tooltip .audience-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 14%;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.audience-tooltip:hover .audience-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
  word-break: break-word;
}
.audience-social-pannel {
  justify-content: space-between;
}
.audience-social-pannel ul li a {
  color: #7782a1;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 0px 0px 10px 0px !important;
  cursor: pointer;
  line-height: 20px;
}
.audience-social-pannel ul {
  padding-left: 0px;
  display: flex;
  margin-bottom: 0px;
}
.audience-social-pannel ul li {
  list-style-type: none;
  display: flex;
  margin: 11px 60px 19px 0px;
}
.audience-social-pannel ul li div {
  /* padding-bottom: 7px; */
  padding-bottom: 9px;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  line-height: 20px;
  color: #7782a1;
}

.audience-social-pannel ul li:last-child {
  margin-right: 0px;
}

.audience-social-pannel ul li .active,
.audience-social-pannel ul li a:hover {
  color: #3064f9 !important;
  border-bottom: 2px solid #3064f9;
  font-family: "Axiforma-Bold";
}
.audience-createpage div,
.audience-createsegment div {
  align-items: center;
  justify-content: space-between;
}
.audience-createpage:hover,
.audience-createsegment:hover {
  background-color: #134bec;
}
.audience-createpage,
.audience-createsegment {
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  min-width: 120px;
  height: 40px;
  margin-left: 0px;
  text-transform: capitalize;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  /* margin-right: -5px; */
  line-height: 18px;
  padding: 0px 27px;
  font-size: 14px;
}
/* .audience-createsegment {
  width: 100px;
} */
.audience-createpage p,
.audience-createsegment p {
  margin-bottom: 0;
}

.audience-createpage .img-plus svg {
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-bottom: 2px;
}
.audience-segments {
  font-family: "Axiforma-Regular";
  padding-top: 0px!important;
  padding-right: 17px;
  margin-left: 20px;
}
.audience-segments-number {
  font-size: 16px;
  color: #7782a1;
  line-height: 21px;
  margin-bottom: 0px;
}
.audience-segments-info {

  line-height: 17px;
}
.audience-social-pannel-right-box{
  align-items: center;
}
.audiencehead {
  padding: 0px 0px 21px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location-select-wrapper{
  width: 33% !important;
  margin-right: 2.5% !important;
}
.location-select-wrapper input::placeholder{
  font-size: 13px;
  color: #BCC1CE !important;
  font-family: "Axiforma-Regular";
}
.location-select-lat input::placeholder{
  font-size: 13px;
  color: #BCC1CE !important;
  font-family: "Axiforma-Regular";
}
.location-select-lat{
  width: 33%;
}
.audiencehead h1 {
  font-family: "Axiforma-Bold";
  font-size: 24px;
  line-height: 33px;
  color: #1b2952;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.audiencehead p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17px;
  color: #7782a1;
  margin-bottom: 0px;
}
.audiencehead p a {
  font-family: "Axiforma-Regular";
  color: #3064f9;
  display: none;
}
.audience-whitebg {
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06) !important;
  position: relative;
 
}
.audience-spinner .newspinner{
  border: 1px solid #E3E6F1;
  background-size: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .06);
  min-height: calc(100vh - 208px);
  border-radius: 6px;
}
/*Page 1 Starts here */
.audience-icon-section {
  background-color: #fff;
  /* height: 79.3vh; */
  background-image: url(../../../src/assets/images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  justify-content: center;
  /* padding-top: 110px; */
  position: relative;
  background-size: contain;
  min-height: calc(100vh - 215px);
    border: 1px solid #E3E6F1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  display: flex;
    align-items: flex-start;
}
.audience-icon-section-width {
  display: flex;
    max-width: 1840px;
    padding-left: 118px;
    padding-right: 252px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 95px;
    width: 100%;
    justify-content: center;
}

.audience-icon-section .app-icon-spark {
  position: absolute;
  top: 30px;
  right: 33px;
  cursor: pointer;
  fill: #bcc1ce;
  stroke: #bcc1ce;
  transition: 0.5s;
}
.audience-icon-section .app-icon-spark:hover svg {
  fill: #f2c400;
  stroke: #f2c400;
  transition: 0.5s;
}
.audience-icon-section-one p {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular2";
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 29px;
  padding-bottom: 1px;
}
.audience-icon-section-one {
  max-width: 520px;
  /* min-width: 520px; */
  /* background-color: #fff; */
  margin-right: 139px;
}
.audience-icon-section-one h2 {
  color: #1b2952;
  font-size: 48px;
  font-family: "Axiforma-Bold";
  line-height: 69px;
  padding-top: 49px;
  margin-bottom: 0;
}

.audience-icon-section-one ul {
  padding-left: 15px;
  color: #bcc1ce;
}
.audience-icon-section-one ul li {
  list-style-type: none;
  position: relative;
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  margin-bottom: 23px;
}
.audience-icon-section-one ul li:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #bcc1ce;
  border-radius: 100%;
  position: absolute;
  left: -15px;
  top: 6px;
}

.audience-icon-section-one-width {
  width: 50%;
  max-width: 520px;
  /* min-width: 520px; */
  /* background-color: #fff; */
}
.audience-icon-section-two {
  margin-left: 0;
  display: flex;
  align-items: center;
}

.audience-icon-section-two img {
  width: 100%;
  height: auto;
  /* transform: scale(1.08); */
}

/*Page 1 Ends here */
/*Table Starts here */
.audience-inner-box {
  /* border: 1px solid #e3e6f1; */
  border-radius: 6px;
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06); */
  background: #fff;
  width: 100%;
  min-height: 77vh;
}
.audience-inner-box .table-hover tbody tr:hover {
  background-color: #f8f9fd;
}
.table-headings {
  border-bottom: 1px solid #f1f2f8;
  font-family: "Axiforma-Regular";
}
#web-table .heading:first-child {
  padding-left: 20px;
}
#web-table .table tbody + tbody {
  border: none;
}
.audience-inner-box .table > :not(caption) > * > * {
  padding: 26px 0 28px 0;
  /* vertical-align: middle; */
}
tr.table-headings-info .heading {
  padding: 11px 0 11px 0;
}
.audience-inner-box .table tbody {
  border-bottom: 1px solid #f1f2f8;
}
.audience-inner-box .table tbody + tbody {
  border-top: unset;
}
/* .audience-inner-box .table tbody + tbody:last-child {
  border-bottom: 1px solid #f1f2f8;
} */
.table-headings .heading {
  color: #bcc1ce;
  font-weight: normal;
  padding-left: 9px;
}

.edit-img a,
.delete-img a,
a.edit,
a.delete {
  height: 17px;
  width: 17px;
  background-repeat: no-repeat !important;
  display: inline-block;
}
a.edit,
a.delete {
  height: 17px;
  width: 17px;
  background-repeat: no-repeat !important;
  display: inline-block;
}
td.edit-img {
  padding-top: 25px;
}
.table thead.table-headings th {
  font-size: 12px;
  line-height: 16px;
}
/* td div,
td p {
  display: flex;
} */
.user-profile .sub-user {
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
  color: #1b2952;
}
.table-mobile-view .user-profile .sub-user {
  margin: 0;
  word-break: break-word;
}
.user-profile.one-liner .sub-user {
  margin-bottom: 0px;
}
.user-profile {
  flex-direction: column;
  display: flex;
  align-items: start;
}
.user-profile.one-liner {
  flex-direction: row;
}
.user-date p {
  font-size: 14px;
  line-height: 18px;
  color: #1b2952;
}
.aud-table table th,
.aud-table table td {
  padding: 0;
}

.user-date {
  width: 20.58%;
}
.sub-status,
.sub-audience-user-status-detail {
  color: #bcc1ce;
  font-size: 12px;
  line-height: 16px;
}
.sub-status {
  padding-left: 14px;
  padding-top: 5px;
  font-family: "Axiforma-Regular";
}
.sub-audience-user-status-detail {
  color: #7782a1;
}
.sub-user-img {
  margin-right: 10px;
    /* margin-bottom: 15px; */
    height: 16px;
}
.sub-user-detail{
  display: flex;
    margin-top: 15px;
}

.sub-user-img svg {
  height: 16px;
  width: 16px;
  vertical-align: top;
}
.audience-inner-box .audience-icon {
  width: 74.1%;
  padding-left: 20px !important;
}
.aud-table .table thead th,
.aud-table .table th,
.aud-table .table td {
  border: 0px;
}

/************************** Create Segment *****************************/
.audience-whitebg.create-segment {
  margin-top: 0px;
  min-height: 80vh;
  /* height: calc(100vh - 180px); */
  /* overflow-y: auto; */
}
.audience-whitebg.create-segment .left-section {
  padding-right: 0;
}
.create-segment .accordionItemHeading {
  cursor: pointer;
  padding: 29px 0px 29px 0px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  justify-content: flex-start !important;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
  line-height: 23px;
}

.create-segment .accordionItemHeading a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.create-segment .accordionItem {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6f1;
  padding: 0px 0px;
  position: relative;
}
.create-segment .accordionItemHeading {
  cursor: pointer;
  padding: 29px 0px 29px 0px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  justify-content: flex-start !important;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
  line-height: 23px;
}
.create-segment .accordionWrapper .close {
  color: #1b2952 !important;
  opacity: 1 !important;
}
.create-segment .close .accordionItemContent {
  height: 0px;
  transition: height 1s ease-out;
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  float: left;
  display: block;
}
.create-segment .accordionItemContent p {
  font-size: 13px !important;
  color: #333333 !important;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.create-segment .accordionItemContent ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
}
.create-segment .accordionItemContent p a,
.create-segment .accordionItemContent p a:hover {
  font-size: 14px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  text-decoration: none;
}
.create-segment .accordionItemContent p span,
.create-segment .accordionItemContent ul li span {
  font-family: "Axiforma-Regular";
  color: #000;
}
.create-segment .open .accordionItemContent {
  padding: 30px 0px;
  background-color: #fff;
  width: 100%;
  margin: 0px 0px 10px 0px;
  display: block;
  transition: transform 0.1s ease-in;
}
.create-segment .accordionItemContent,
.create-segment .accordion-text {
  padding-bottom: 30px;
  display: flex;
}
.create-segment .open .accordionItemHeading {
  padding: 30px 0px 0px 0px;
  background-color: #fff;
  color: #000;
}
.create-segment .accordionWrapper h5 {
  margin-top: 40px;
  color: #000;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.create-segment .accordionItemHeading a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.create-segment .accordionItemHeading a img {
  position: absolute;
  top: 29px;
  right: 0px;
}
.create-segment .tab-content .col-sm-12 {
  padding-right: 0;
}

.create-segment input,
.create-segment .accordion-text select {
  height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #bcc1ce;
  padding: 0px 100px 0px 15px !important;
  outline: none;
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  transition: 0.5s;
}
.create-segment input:hover,
.create-segment input:focus,
.create-segment input:focus-visible,
.form-select__control:hover,
.form-select__control:focus,
.form-select__control:focus-within,
.form-select__control:focus-visible {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.form-select__control .form-select__input input {
  background-color: inherit !important;
}
.form-select__control .form-select_dropdown-indicator,
.form-select__control .form-select_dropdown-indicator:hover {
  color: #bcc1ce !important;
}
.form-select__indicator svg {
  color: #bcc1ce !important;
}
.create-segment input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #bcc1ce; */
  color: #BCC1CE;
  opacity: 1; /* Firefox */
}
.create-segment input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* color: #bcc1ce; */
  color: #1b2952;
}
.create-segment input::-ms-input-placeholder {
  /* Microsoft Edge */
  /* color: #bcc1ce; */
  color: #1b2952;
}
.create-segment .accordionItemContent input::placeholder {
  color: #bcc1ce;
}
.create-segment .accordion-text select {
  width: 100%;
  position: relative;
}
.create-segment .accordion-text select option {
  font-size: 13px;
  line-height: 17px;
  color: #1b2952;
}
.create-segment .accordion-text input {
  width: 100%;
}
.create-segment .arrow-img svg {
  position: absolute;
  top: 37px;
  height: 7px;
  width: 13px;
  right: 6px;
}
.accordionItem .segment-input-full{
  padding: 0px 15px!important;
}
.create-segment .col-xl-6 {
  max-width: 48.9%;
  margin-left: 15px;
}
.fs-13 {
  font-size: 13px;
  line-height: 17px;
  color: #7782a1;
}
.create-segment .img-box:hover .fs-13{
  color: #3064F9;
}
.selected-segment .fs-13{
  color: #3064F9;
}
.create-segment .rules-para {
  padding-top: 27px;
  padding-bottom: 19px;
  margin-bottom: 0;
}
.create-segment .img-box {
  height: 140px;
  width: 17.5%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  border: 1px solid #e3e6f1;
  transition: 0.5s;
  flex-direction: column;
  margin-left: 0px;
  /* margin-bottom: 20px; */
  /* margin-right: 6px; */
  margin-left: 2.5%;
  border-radius: 3px;
  margin-bottom: 2.5%;
  position: relative;
}
.create-segment .img-box:hover {
  border: 1px solid #3064F9;
  background-color: #F1F4FB;
}
.selected-segment{
  border: 1px solid #3064F9 !important;
  background-color: #F1F4FB;
}
.img-container {
  margin-left: -2.5%;
}
.create-segment .txt-box {
  padding-top: 26px;
}
.create-segment .img-box svg {
  margin-top: 36px;
}
.heading-wrapper .heading {
  font-size: 16px;
  line-height: 23px;
  line-height: 16px;
  margin-bottom: 0;
  margin-left: 10px;
}
.audience-category-parent-dropdown .dropdown {
  border: 1px solid #bcc1ce !important;
  transition: 0.5s !important;
  cursor: pointer !important;
  height: 50px;
  background-image: url(../../assets/images/dashboard/grey-dropdown.png) !important;
  background-repeat: no-repeat !important;
  background-position: 98% !important;
  transition: 0.5s;
  border-radius: 3px;
}
.create-segment .img-box svg.top-svg {
  margin-top: 43px;
}
.img-box .txt-top {
  margin-top: 6px;
}
.create-segment .img-box .audience-box-tooltip {
  visibility: hidden;
  width: 143px;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  padding: 4px 14px 3px;
  opacity: .8 !important;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  line-height: 18px;
  display: grid;
  text-align: center;
  transition: .3s;
  top: 143px;
  margin: auto;
}
.create-segment .img-box:hover .audience-box-tooltip {
  visibility: visible;
  transition: .3s;
}
.create-segment .img-box .audience-box-tooltip:after {
  content: "";
  position: absolute;
  top: -8px;
  left: 47%;
  margin-top: -1px;
  border: 5px solid transparent;
  border-right-color: #000;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.txt-box-m2 {
  margin-top: 2px;
}
.txt-box-mr {
  margin-right: 4px;
}
.create-segment .txt-box.txt-box-pd {
  padding-top: 22px;
}
.create-segment .add-filters {
  /* padding: 25px 0px; */
  line-height: 1;
  cursor: pointer;

}
.create-segment .add-filters-txt,
.create-segment .add-filters-txt:hover,
.create-segment .add-filters-txt:focus {
  margin-left: 0px;
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  display: inline-block;
  padding: 28px 0px;
}
.create-segment .add-filters-txt svg {
  width: 12px;
  height: 12px;
  margin-right: 5px !important;
  margin-top: -2px;
  display: inline-block;
}
/* .create-segment .accordion-text input::-webkit-input-placeholder {
  color: #BCC1CE !important;
} */
.create-segment .delete-and-copy {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 0px;
  margin-bottom: 29.5px;
  max-height: 15px;
}
.create-segment .delete-and-copy a {
  line-height: 1;
}
.create-segment .delete-and-copy svg {
  height: 15px;
  width: 15px;
  border-style: none;
  cursor: pointer;
}
.create-segment .delete-and-copy svg {
  height: 15px;
  width: 15px;
  margin-left: 25px;
  border-style: none;
}
.create-segment .form-select {
  appearance: none;
  position: relative;
}
.create-segment .form-select::after {
  content: "";
  position: absolute;

  width: 12px;
  height: 6px;
  left: 0;
}
p.sub-user-status-detail {
  font-size: 12px;
  line-height: 16px;
  color: #7782a1;
}
.mobile-create {
  display: none;
}
.audience-category-parent-dropdown {
  position: relative;
}
.audience-category-parent-dropdown span {
  position: absolute;
  top: 17px;
  right: 32px;
  color: #bcc1ce;
  font-size: 13px;
  line-height: 17px;
  font-family: "Axiforma-Regular";
}
.form-select__value-container .css-b8ldur-Input {
  padding-top: 0;
  margin-top: -3px;
}
.form-select__placeholder {
  top: 57%;
}
/************************** Modal starts here ***************************/
.add-filter-modal h2 {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
  line-height: 25px;
}
.audience-modal-header {
  padding: 15px 20px !important;
  border-bottom: 1px solid #e3e6f1 !important;
  padding-bottom: 17px !important;
}
.audience-modal-header .cross-icon-modal {
  position: absolute;
  right: 12px;
  top: 2px;
  cursor: pointer;
}
.audience-modal-body.create-segment .img-box {
  height: 137px;
  width: 10.876%;
  margin-left: 1.62%;
}
.audience-modal-body.create-segment .img-container {
  margin-left: -1.4%;
}
.audience-modal-body.create-segment .txt-box {
  padding-top: 23px;
}
.audience-modal-body {
  padding-top: 20px !important;
}
.create-segment .img-box .audience-popup-tooltip {
  visibility: hidden;
  width: 100%;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  padding: 4px 14px 3px;
  opacity: .8 !important;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  line-height: 18px;
  display: grid;
  text-align: center;
  transition: .3s;
  top: 141px;
  margin: auto;
}
.create-segment .img-box:hover .audience-popup-tooltip {
  visibility: visible;
  transition: .3s;
}
.create-segment .img-box .audience-popup-tooltip:after {
  content: "";
  position: absolute;
  top: -8px;
  left: 47%;
  margin-top: -1px;
  border: 5px solid transparent;
  border-right-color: #000;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
[dataclass~="audience-modal-wrapper"] .modal-content {
  height: 660px;
}
[dataclass~="audience-modal-wrapper"] .modal-footer {
  padding: 14px 20px !important;
  border-top: 1px solid #e3e6f1 !important;
}
.modal-filter-footer {
  margin: 0px !important;
}
.filter-notification-cancel {
  background: none;
  font-size: 14px;
  height: 40px;
  width: 100px;
  border: 1px solid #bcc1ce;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: pointer;
  line-height: 40px;
}

.filter-notification-send {
  background: #3064f9;
  font-size: 13px;
  height: 40px;
  width: 100px;
  border: none;
  color: white;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: pointer;
  line-height: 40px;
}
.select-wrapper,
.audience-category-parent-dropdown {
  width: 49%;
}
.emailselect-wrapper{
width: 100%;
}
.select-wrapper {
  margin-right: 2.5%;
  position: relative;
}
.form-select__control {
  height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #bcc1ce !important;
  padding: 0px 7px 0px 12px;
  outline: none;
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  transition: 0.5s !important;
  box-shadow: none !important;
}
.form-select__value-container {
  padding: 0 !important;
}
.form-select__placeholder {
  font-size: 13px;
  line-height: 17px;
  color: #1b2952 !important;
}
.notification-selecterror .profile-role-dropdown__control{
  border: 1px solid #ffbaba!important;
  background-color: #fffcfc!important;
}
/* .audience-mobile-view {
  display: none;
} */
.mob-modal-filter-footer{
  display: none !important;
 }
 .select-wrapper .profile-role-dropdown__single-value{
  margin: 0px !important;
 }
 .audience-heading .notification-setting{
  margin-right: 20px;
 }
@media (min-width: 4000px) and (max-width: 7000px) {
  .create-segment .img-box {
    width: 14.1%;
  }
}

@media (min-width: 2200px) {
  .audience-icon-section-width{
    justify-content: center;
  }
  .table-headings {
    font-size: 20px;
    /* height: 60.5px; */
  }
  th.heading.user {
    padding-left: 35px;
  }
  /* .audience-icon-section-width {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  } */
  /* .audience-icon-section-one-width {
    margin-right: 400px;
    margin-left: 165px;
    max-width: 600px;
  } */
  .audience-icon-section-one h2 {
    font-size: 60px;
    line-height: 70px;
  }
  .audience-icon-section-one p {
    font-size: 18px;
    line-height: 30px;
  }
  .audience-icon-section-one ul li {
    font-size: 16px;
    line-height: 20px;
  }
  .audience-icon-section-width .app-icon-buttons span:nth-child(1) {
    width: 220px;
    font-size: 16px;
  }
  .audience-icon-section-two img {
    transform: scale(1.1);
  }
}

@media (min-width: 1650px) and (max-width: 1850px) {
  /* .audience-icon-section-width {
    padding-right: 75px;
  } */
  /* .audience-icon-section-one-width {
    margin-right: 80px;
  } */
  .create-segment .img-box {
    width: 22.5%;
  }
  .audience-modal-body.create-segment .img-box {
    width: 15%;
  }
}

@media (min-width: 1401px) and (max-width: 1649px) {
  .audience-icon-section-width {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .audience-icon-section-one-width {
    margin-right: 45px;
  } */
  .create-segment .img-box {
    width: 22.5%;
  }
  .audience-modal-body.create-segment .img-box {
    width: 18.2%;
  }
  /******************************* Modal ************************************/
  .audience-modal-body.create-segment .img-box {
    width: 15%;
    height: 135px;
  }
}
/* @media (min-width: 1200px) {
  .user-profile .sub-user {
    margin-bottom: 12px;
  }
} */
.audience-mob-create-btn{
  display: none;
}
@media (min-width: 1201px) and (max-width: 1400px) {
  .audience-icon-section-width {
    padding-right: 20px;
    padding-left: 20px;
  }
  /* .audience-icon-section-one-width {
    margin-right: 20px;
  } */
  .create-segment .img-box {
    width: 30.5%;
  }
  .audience-modal-body.create-segment .img-box {
    width: 18.2%;
  }
 
}

/******************************************************************** audience *************************************************************************/
@media (max-width: 1200px) {
  .audience-heading .notification-setting{
    margin-right: 0px;
   }
   .audience-heading{
    margin-top: 10px;
    margin-bottom: 15px !important;
    align-items: center!important;
   }
  .audience-mob-create-btn{
    border: 1px solid #3064F9;
        border-radius: 3px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3064F9;
        font-family: 'Axiforma-Regular';
        margin: 9px 0px;
  }
  .audience-heading-content{
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
  }
  .audience-search{
    margin-top: 9px;
  }
  .web-modal-filter-footer{
    display: none !important;
   }
  .mob-modal-filter-footer{
    display: block !important;
   }
  .audience-spinner .newspinner {
    margin-top: 15px;
  }
  .mob-create-segment{
    margin-top: 0px !important;
  }
  .audience-heading .consent-tooltip{
    display: none;;
  }
  .create-segment .arrow-img svg {
right: 15px;
  }
  
  .notification-modal-tab{
    display: none;
  }
  .audiebce-mob-head{
    display: block; 
 }
  .new-audience-login .audience-social-pannel,
  .new-audience-login .push-heading {
    display: none !important;
  }
  .audience-mobile-view {
    display: block;
    margin-top: 20px;
  }
  .audience-mobile-view h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 17px;
    font-family: "Axiforma-Regular";
  }
  .new-audience-login .right-panel-gap {
    margin-left: 0;
    width: 100%;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 15px !important;
    /* padding-top: 20px !important; */
  }
  .right-panel-gap.aud-icon .audience-whitebg {
    width: 100% !important;
    background-color: #fff;
    height: auto;
  }
  .audience-icon-section {
    background-image: none;
    padding: 0;
    height: auto;
    border: 0;
  }
  .audience-icon-section-width {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
  }
  .audience-icon-section-one-width {
    max-width: 100%;
    margin-right: 0;
    width: 100%;
  }
  .audience-icon-section-one h2 {
    padding-top: 0;
    font-size: 30px;
    line-height: 40px;
  }
  .audience-icon-section-one p {
    margin-bottom: 30px;
    padding-bottom: 0;
    text-align: left !important;
    margin-top: 17px;
  }
  .audience-icon-section-one ul {
    margin-bottom: 0px;
  }
  .audience-icon-section-one ul li {
    font-size: 14px;
    line-height: 18px;
  }
  .audience-icon-section-width .app-icon-buttons {
    margin-top: 45px;
    margin-bottom: 61px;
  }
  .audience-icon-section-width .app-icon-buttons span:nth-child(1) {
    font-size: 16px;
    line-height: 21px;
  }

  /*------------------- Mobile view audience Card Starts here -------------*/
  .table-mobile-view {
    display: block;
    width: 100%;
  }
  .web-view {
    display: none;
  }

  .table-mobile-view
    .audience-heading
    .audience-heading-content
    .tooltip-handler
    h1 {
    font-size: 20px;
    font-family: "Axiforma-Bold";
    padding: 0px 6px 0px 0px;

  }
  .table-mobile-view
    .audience-heading
    .audience-heading-content
    .tooltip-content {
    padding-left: 0px;
    padding-top: 3px; /* To match it with design */
  }
  .table-mobile-view .audience-right-box {
    /* margin-top: 60px; */
    background-color: #f8fafe;
    /* padding: 0 15px 13px 15px; */
  }
  .audience-info-number .audience-info-users {
    margin-right: 0;
  }
  .audience-heading-content .notifications-create-btn {
    color: #3064f9;
    font-size: 15px;
    line-height: 20px;
    /* margin-top: 20px; */
    margin-bottom: 0px;
    align-items: center;
  }
  .audience-heading-content .notifications-create-btn p{
    margin-bottom: 0px;
  }
  .audience-heading-content .notifications-create-btn-svg {
    margin-right: 5.8px;
    margin-top: -3px;
  }
  .audience-inner-status .status-icon a.delete {
    margin-left: 27.7px;
  }
  /* This is primary button for mobile view without modals  */
  .table-mobile-view .btn.btn-primary {
    width: 100%;
    height: 50px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .audience-invite {
    /* margin-top: 5px; */
    display: inline-block;
    padding-left: 5px;
  }
  .pushnotification-Search {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  }
  .user-name {
    font-size: 14px;
  }
  .table-mobile-view #plus-svg {
    margin-bottom: 2px;
  }
  .audience-inner-box {
    border: 1px solid #e3e6f1;
    min-height: 100%;
    width: 100% !important;
    margin-top: 20px;
    background: #fff;
    padding: 25px 15px 22px 15px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  }

  .audience-inner-box .audience-inner-userid {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: 1px solid #e3e6f1;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .audience-inner-box .userid-left-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    padding-top: 0px;
  }

  .userid-right-box {
    display: flex;
    align-items: center;
    padding-right: 17px;
  }

  .audience-inner-box .headings {
    font-size: 12px;
    color: #bcc1ce;
    margin-bottom: 0;
    margin-top: 21px;
    font-family: 'Axiforma-Regular';
  }

  .audience-inner-box .txt-dark {
    font-size: 14px;
    color: #1b2952;
  }

  .audience-inner-box .audience-inner-email,
  .audience-inner-box .audience-inner-role,
  .audience-inner-box .audience-inner-login,
  .audience-inner-box .audience-inner-status {
    display: flex;
    flex-direction: column;
    border-bottom: 0px;
    justify-content: center;
  }

  .sub-user-detail {
    display: flex;
    margin-top: 0px;
  }
  .sub-user-status-detail {
    font-size: 12px;
    line-height: 16px;
    color: #7782a1;
    margin-bottom: 21px;
  }
  .status-icon {
    margin-top: -6px;
    margin-right: -4px;
  }
  .profile-role-dropdown__indicator-separator{
    display: none;
  }
  .status-icon a{
    margin-left: 27px;
  }
  .table-mobile-view .user-profile .sub-user {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 20px;
    /* margin: 20px 0px 18px 15px; */
  }
  .default-status {
    margin-top: -21px;
    margin-bottom: 15px;
  }
  .audience-user-status {
    margin-right: 10px;
    font-size: 14px;
    line-height: 18px;
    font-family: "Axiforma Regular";
  }
  /***************************************************************** Create Segment *******************************************************************/
  .audience-whitebg.create-segment {
    border-radius: none !important;
    border: none !important;
    box-shadow: none !important;
    /* margin-top: 18px; */
  }
  .audience-whitebg{
    background-color: unset;
  }
  .create-segment .col-xl-6 {
    margin-right: 0;
    margin-left: 0;
    background-color: #fff;
    max-width: 100%;
    border-radius: 6px;
    border: 1px solid #e3e6f1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    padding: 0;
  }
  .create-segment .accordionItem,
  .create-segment .add-filters,
  .img-container,
  .create-segment .rules-para {
    padding-left: 15px;
    padding-right: 15px;
  }
  .create-segment .arrow-img {
    right: 14px;
  }

  .create-segment .accordionItemContent,
  .create-segment .accordion-text {
    flex-direction: column;
  }
  .create-segment .accordionItemHeading {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 31px;
  }
  .create-segment .accordion-text select {
    margin-bottom: 20px;
    margin-right: 0;
    width: 100%;
  }
  .create-segment .accordion-text input {
    width: 100%;
  }

  .audience-category-parent-dropdown {
    position: relative;
  }
  .audience-category-parent-dropdown span {
    position: absolute;
    top: 16px;
    right: 30px;
    color: #bcc1ce;
    font-size: 13px;
    line-height: 17px;
  }
  button.mobile-create {
    width: 100%;
    margin-top: 20px;
    font-size: 16px !important;
    height: 50px;
  }
  .mobile-create {
    display: grid;
    width: 100%;
  }
  .mobile-create .create-notification {
    background: #3064f9;
    border: none;
    color: white;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    cursor: pointer;
    line-height: 40px;
    width: 100%;
    margin-top: 20px;
    font-size: 16px !important;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .audience-createsegment {
    display: none !important;
  }
  .audience-tooltip {
    display: none;
  }
  .create-segment .img-box {
    width: 30.8%;
    /* margin-bottom: 10px; */
    height: 116px;
  }
  .create-segment .img-box svg {
    margin-top: 25.4px;
  }
  .create-segment .img-box .audience-box-tooltip {
    visibility: hidden;
    width: 100%;
    top: 122px;
    padding: 4px 10px 3px 10px;
  }
  .create-segment .txt-box {
    margin-top: 18.9px;
    padding-top: 0;
  }
  .create-segment .txt-box.txt-box-pd {
    padding-top: 0;
    margin-top: 15px;
  }
  .create-segment .img-box svg.top-svg {
    margin-top: 38px !important;
  }
  .create-segment .rules-para {
    text-align: left !important;
  }
  .create-segment .accordion-text {
    padding-bottom: 20px;
  }
  .create-segment .delete-and-copy {
    margin-bottom: 20px;
  }
  .audience-heading h1 {
    font-size: 20px;
    line-height: 20px;
    margin-top: 0px;
  }
  .heading-wrapper .heading {
    font-size: 14px;
    line-height: 20px;
  }
  .img-container {
    padding-bottom: 19px;
  }
  .select-wrapper,
  .audience-category-parent-dropdown {
    width: 100%;
  }
  .select-wrapper {
    margin-right: 0;
    padding-bottom: 20px;

  }
  .location-select-wrapper {
    width: 100% !important;
    margin-right: 0% !important;
    padding-bottom: 20px;
}
.location-select-lat {
  width: 100%;
}
  /************************************************** Modal **************************************************/

  [dataclass~="audience-modal-wrapper"] .modal-content {
    height: 100%;
  }
  .filter-notification-send {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    width: 100%;
  }
  .filter-notification-cancel {
    font-size: 16px;
    height: unset;
    width: unset;
    border: none;
    line-height: 16px;
    margin-top: 36px;
  }
  [dataclass~="audience-modal-wrapper"] .modal-footer {
    border-top: none !important;
    padding: 0px 15px 0px 15px !important;
  }
  .modal-filter-footer .d-flex {
    flex-direction: column-reverse;
    padding: 0px 15px;

  }
  
  .audience-modal-header {
    border-bottom: none !important;
    padding: 79px 23px 23px 15px !important;
  }

  .audience-modal-header .cross-icon-modal {
    right: 22px;
    top: 33px;
  }
  .add-filter-modal h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .audience-modal-body.modal-body {
    padding: 0px !important;
    max-height: 565px;
  }
  .audience-modal-body.create-segment .img-box {
    height: 126px;
    width: 30.7%;
    margin-left: 1.4%;
    margin-right: 1.2%;
  }
  .audience-modal-body.create-segment .img-container {
    margin-left: -1.4%;
    margin-right: -1.4%;
  }
  .audience-modal-body.create-segment .img-box svg {
    margin-top: 30.4px;
  }
  .audience-modal-body.create-segment .txt-box {
    padding-top: 0;
  }
  .create-segment .img-box .audience-popup-tooltip {
    top: 130px;
  }
  .upgrade-audience-mob{
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px 0px;
  }
  .upgrade-audience-mob .app-icon-buttons{
    margin: 0px!important;
    width: 100%;
  }
}
/* @media (max-width: 800px) {
  .audience-icon-section-width {
    padding-right: 19px;
  }
} */
@media (max-width: 400px) {
  .audience-icon-section-width .app-icon-buttons {
    margin-bottom: 40px;
  }
  /***************** Create Segement ********************/
  .create-segment .img-box {
    width: 46.99%;
  }
  /* .img-container {
    margin-right: -1.2%;
  } */
  /************* Modal ******************/
  .audience-modal-body.create-segment .img-container {
    margin-right: -2.6% !important;
  }
  /* .audience-modal-body.create-segment .img-box {
    width: 46.9% !important;
  } */
  .audience-modal-header {
    padding: 60px 23px 23px 15px !important;
  }
  .audience-modal-body.create-segment .img-box {
    height: 116px;
  }
}
@media (max-width: 360px) {
  .audience-icon-section-one h2 {
    font-size: 27px;
    line-height: 37px;
}
}