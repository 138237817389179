.report_header{
    padding: 0px !important;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.report_dasboard_page{
    margin: 0 auto;
}
.main-analytics-div{
    position: relative
}
.main-analytics-div .newspinner{
    box-shadow: 0 2px 6px rgba(0, 0, 0, .06);
    border-radius: 6px;
    border: 1px solid #e3e6f1;
    height: 82vh;
    top: 55px;
}
.Report_nodata_img{
    height: 352px !important;
}
.row-analyticss{
    justify-content: space-between;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.report_maindivpadding{
    padding: 20px 30px 30px 30px !important;
}
.report_header .app_iconname{
    margin-top: 0px !important;
}
.report_header h3{
    font-size: 24px !important;
    /* line-height: 37px !important; */
    margin: 0px !important;
    font-family: 'Axiforma-Bold';
    line-height: 24px;
    color: #1B2952;
}
/* .report_header .consent-tooltip{
    margin-left: 6px;
} */
.report_header h5 {
    font-size: 13px !important;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-top: 0px !important;
    color: #7782a1;
}
.report_analyticss .Report_new-analytics-bar-main{
    box-shadow: none !important;
    padding-top: 20px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
}
.analytics-dropdown .dropdown-item:disabled{
    cursor: not-allowed !important;
    pointer-events: unset;
    color: #adb5bd !important;
    background-color: #f8f9fd !important;
}
.analytics-dropdown .dropdown-item.disabled {
    color: #E3E6F1 !important;
    background-color: unset !important;

}
.analytics-dropdown .dropdown-item.disabled:hover {
    color: #E3E6F1 !important;
    background-color: unset !important;
}
.report_rightpannel{
    padding: 0px !important;
    margin: 0px!important;
}
/* .report_piechart{
    padding: 30px 20px 20px 20px !important;
} */
.chart_header{
    top: -60px !important;
    margin-left: 25px !important;
    margin-top: 20px !important;
    position: absolute;
}
/* .report_row .new-analytics-histogram{
    height: 435px !important;
} */
.Report_divsameheight{
    display: flex!important;
    flex-wrap: wrap!important;
    width: calc(100% + 1.2%)!important;
}
.analytics-dropdown .show .dropdown-toggle.btn-secondary , .analytics-dropdown .dropdown-toggle.btn-secondary{
    color: #7782A1!important;
    white-space: normal !important;
    width: 80px;
    border: none !important;
    border-radius: 3px;
    text-align: left;
    max-height: 50px;
    margin-bottom: 0px;
    background-image: url(../../assets/images/dashboard/email-support-arrow.png) !important;
    background-repeat: no-repeat !important;
    background-position: 97% !important;
    -webkit-appearance: none;
    padding: 3px 8px 0px 0px;
    transition: 0.5s;
    font-family: "Axiforma-Regular";
    font-size: 12px;
  }
.analytics-dropdown .dropdown-toggle::after{
    display: none;
  } 
.analytics-dropdown .dropdown-menu {
    width: 100%;
    padding: 0px;
    margin: 0px;
    max-height: 350px;
    overflow: auto;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #E3E6F1;
    margin-top: 15px;
    left: -5px;
    overflow: hidden;
}  
.analytics-track-data{
    display: flex;
    align-items: center;
    padding-left: 30px;
    /* border-left: 1px solid #E3E6F1;
    margin-left: 30px; */
}
.analytics-playstore-icon{
    margin-left: 30px ;
    position: relative;
}
.analytics-red-dot{
    position: absolute;
    height: 10px;
    width: 10px;
    background-color:#FF4848;
    border-radius: 50%;
    border: 2px solid #F8FAFE;
    top: 1px;
    right: 0px;
}
.analytics-track-data svg{
    cursor: pointer;
}
.analytics-track-data p{
    margin-right: 20px;
    font-size: 14px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
}
.analytics-dropdown .dropdown-menu::-webkit-scrollbar{
    width: 4px !important;
}
.analytics-dropdown .dropdown-menu .dropdown-item.active {
    background-color: #F8F9FD !important;
    color: #7782A1 !important;
}
.analytics-dropdown .dropdown{
    padding-right: 30px;
    border-right: 1px solid #E3E6F1
}

.analytics-dropdown .dropdown:nth-child(2) {
    padding-left: 30px;
    padding-right: 0px;
    border-right: unset
}

.analytics-dropdown .dropdown-menu::before{
  display: url('../../assets/images/dashboard/menuarrow.png');
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  right: 38px;
  margin: auto;
  transform: rotate(0deg);
}
.report-aerotop{
    /* z-index: 99;
    position: absolute;
    top: 13px;
    left: 38px;
    display: flex; */
    /* height: 15px; */
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #e3e6f1;
    top: 29px;
    z-index: 9991;
    right: 30px;
}
.report-aerotop::before{
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 13px solid #fff;
    top: 1px;
    left: -8px;
}
.report-aerotopsecond{
    /* z-index: 99;
    position: absolute;
    top: 13px;
    left: 73px;
    display: flex; */
     /* height: 15px; */
     width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #e3e6f1;
    top: 29px;
    z-index: 9991;
    right: 0;
}
.report-aerotopsecond::before{
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 13px solid #fff;
    top: 1px;
    left: -8px;
}
.report-aerohidden{
 
    display: none;
}
/* .analytics-dropdown .dropdown .show .report-aerotop{
    visibility: visible
} */

.analytics-dropdown .dropdown-item {
    display: block;
    width: calc(100% - 10px);
    padding: 12px 0px 12px 10px !important;
    color: #7782A1;
    font-size: 12px !important;
    font-family: "Axiforma-Regular" !important;
    margin: auto !important;
    border: 0px solid #fff !important;
    width: 158px;
}
.analytics-dropdown .dropdown-item:hover , .analytics-dropdown .dropdown-item:active {
    border: 0px solid #fff !important;
    background-color: #F8F9FD !important;
    color: #7782A1 !important;
}
.analyticstoggle{
    display: flex;
    align-items: center;
}
.analyticstoggle h6{
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px 30px;
    color: #7782a1 !important;
}
.analyticstoggle p{
    margin-right: 20px;
}
.analytics-mob-head h3{
    font: 20px "Axiforma-Bold";
    color: #1B2952;
    margin: 20px 0px 0px 15px;
}
.analytics-dropdown h6{
    color: #E3E6F1;
    margin: 0px 30px;
}
.analytics-dropdown{
    display: flex;
}
.analytics-second-dropdown .dropdown-menu{
    left: 20px;
}
/* .analytics-second-dropdown .report-aerotopsecond{
    left: 68px;
} */
.mob-track-data{
    display: none;
}

.analytics-track-data .productsetting-tooltip .productsetting-tooltipsubheader {
    top: 31px;
    left: unset;
    right: -70px;
    margin-right: 0px   
}
.analytics-track-data .productsetting-tooltip .productsetting-tooltipsubheader::after {
    top: 15px;
    right: 70px;
    margin-top: -25px;
    border-color: transparent transparent black transparent;
}

/* popup */
.analytics-secondmodal .modal-body{
    padding: 13px 37px 60px 37px;
  }
  .analytics-secondmodal-crossmodal{
    position: absolute;
    top: 0px;
    right: 12px;
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
  .analytics-popup-maindiv p{
    font-size: 14px;
    color: #7782A1;
    text-align: center;
    font-family: 'Axiforma-Regular';
    margin-top: 18px;
    margin-bottom: 40px;
    word-break: break-word;
  }
  .analytics-popup-head{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .analytics-popup-head h2{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    text-align: center;
    line-height: 25px;
    margin-bottom: 0px;
   
  }
  .analytics-popup-head svg{
    margin-right: 6px;
  }
  .analytics-popup-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .analytics-popup-cancel{
    width: 140px;
    height: 44px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    color: #7782A1;
    background-color: #ffff;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    margin-right: 20px;
  }
  .analytics-popup-confirm{
    width: 140px;
    height: 44px;
    border-radius: 3px;
    color: #fff;
    background-color: #3064F9;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    border: none;
    transition: 0.5s;
  }
  .analytics-popup-confirm:hover{
    background-color: #134bec;
  }
  
  .analytics-popup-deletebtn{
    width: 140px;
    height: 44px;
    border-radius: 3px;
    color: #ff4848;
    border: 1px solid #ff4848;
    background-color: white;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
   
    transition: 0.5s;
  }
  .analytics-popup-deletebtn:hover{
    background-color: #ff4848;
    color: #ffff;
  }
  .analytics-secondmodal-crossmodal{
    position: absolute;
    top: 0px;
    right: 12px;
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
  .analytics-mob-toggle-div{
    display: none;
   
}
/* popup */




.analytics-firebase-popup .modal-body{
    padding: 13px 90px 60px 90px;
}
.analytics-firebase-maindiv h2{
    font-size: 24px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    text-align: center;
    line-height: 33px;
    margin-bottom: 0px;
}
.analytics-firebase-maindiv p{
    font-size: 13px;
    color: #7782A1;
    text-align: center;
    font-family: 'Axiforma-Regular';
    margin-top: 18px;
    margin-bottom: 26px;
}
.analytics-firebase-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
}

.analytics-enable-btn{
    border: 1px solid #3064F9;
    border-radius: 4px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 160px;
    margin-right: 20px
}
.analytics-enable-btn p{
 color: #3064F9 !important;
  margin: 0px 6.4px 0px 4px !important ;
  font-size: 14px;
}
.analytics-firebase_disable p{
    font-size: 14px;
    color: #7782A1;
    margin: 0px 6.4px 0px 4px !important ;
    font-family: 'Axiforma-Regular';
}
.analytics-firebase_disable{
    cursor: not-allowed;
    border: 1px solid #7782A1;
    border-radius: 4px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 160px;
    margin-right: 20px;
}
.analytics-firebase_disable svg{
        fill: #7782A1;
}
.firebase-popup-crossmodal {
    position: absolute;
    top: 0px;
    right: 12px;
    cursor: pointer;
    height: 14px;
    width: 14px;
}
.report_header .consent-tooltip{
margin-left: 6px;
}
.analytics-spin{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* padding-top: 200px; */
    /* opacity: 0.5;
}


@media screen and (max-width: 768px) {
    /* .row-analyticss {
        padding: 0px 15px !important
    } */
    /* .Report_divsameheight {
        padding: 0px 15px;
        width: unset;
        width: unset !important;
    } */
   
  }


@media screen and (max-width: 1200px) {
    .analytics-popup-maindiv p {
        margin-left: 10px;
    margin-right: 10px;
    }
    .main-analytics-div .newspinner{
        box-shadow: unset;
        border-radius: 0px;
        border: unset;
        height: calc(100vh - 145px);
        margin: 0px 15px 15px 15px;
        width: calc(100% - 30px);
        top: 42px;
        border: 1px solid #E3E6F1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .06);
    border-radius: 6px;
    }
    .appcount_color {
        margin-top: -5px;
    }
    .report_header{
        margin-top: 15px;
        margin-bottom: 13px;
    }
    .report_header h3 {
        font-size: 20px !important;
     }
    .firebase-popup-crossmodal {
        position: absolute;
        top: 30px;
        right: 20px;
        cursor: pointer;
        height: 16px;
        width: 16px;
    }
    .analytics-firebase-popup .modal-body{
      
            padding: 0px 20px 0px 20px;
            justify-content: center;
            align-items: center;
            display: flex;
        
    }

    /* popup */

    
    .analytics-mob-toggle-div .switch{
        align-self: auto !important;
    }

.analytics-secondmodal .modal-body {
    padding: 0px 20px 0px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.analytics-popup-bottom {
  flex-direction: column-reverse;
}
.analytics-popup-confirm{
  width: 280px;
  height: 50px;
  font-size: 16px;
  border: none;
}
.analytics-popup-cancel{
  width: unset;
  height: 24px;
  margin-top: 36px;
  border: unset;
  font-size: 16px;
  margin-right: 0px;
}

.analytics-secondmodal-crossmodal {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}
/* popup */

.analytics-mob-track{
font-size: 14px;
color: #7782A1;
font-family: 'Axiforma-Regular';
 margin-bottom: 0px;
}
.analytics-mob-toggle-div{
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    align-items: center;
    padding: 0px 15px;
    height: 60px;
    margin-bottom: 15px;
    background-color: #fff;
    margin-top: 13px;
}
    .analytics-track-data{
        display: none;
    }

    .mob-track-data{
        display: flex;
    justify-content: space-between;
    background-color: #ffff;
    align-items: center;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    padding: 18px 15px;
    margin: 20px 0px 15px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    }

    .mob-track-data p{
        margin-bottom: 0px;
        font-size: 14px;
        color: #7782A1;
    }

.analyticstoggle{
    margin: 15px;
    background-color: #fff;
    border: 1px solid #E3E6F1;
    justify-content: space-between;
    padding: 21px 15px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.analyticstoggle p{
    margin: 0px;
    color: #7782A1;
    font-size: 14px;
}
.row-analyticss {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
/* .Report_divsameheight {
    margin: 0px 15px;
} */
.analytics-dropdown h6 {
    margin: 0px 12px;
}
.analytics-dropdown .show .dropdown-toggle.btn-secondary, .analytics-dropdown .dropdown-toggle.btn-secondary {
    width: 85px!important;
}

}


@media (min-width: 1000px) and (max-width: 1200px){
    .report_maindivpadding {
        padding: 28px 0px 30px 0px !important;
    }
   
}
@media (min-width: 768px) and (max-width: 1000px){
  
    .report_maindivpadding {
   
        padding: 20px 0px 0px 0px !important;
    }
    .chart_header {
        margin-top: 10px !important;
    }
}

@media (min-width: 250px) and (max-width: 767px){
    .report_maindivpadding {
        padding: 0px !important;
    }
    .report_dasboard_page{
        margin-top: 0px !important;
        min-height: 50% !important;

    }
    .report_maindivpadding{
        background-color: white !important;
    }
    .report_rightpannel{
        background-color: white !important;
    }
    .analytics-updated{
        background-color: transparent !important;
    }
    .chart_header {
        margin-top: 10px !important;
    }
    .Report_mobile_img{
        padding-left: 20px !important;
    }

}