.crello-iframe-in-popup , .crello-loader-in-popup {
    width: calc(100vw - 100px)!important;
    height: calc(100vh - 100px)!important;
    margin: auto!important;
    top: 50px!important;
    border-radius: 4px!important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
}
.crello-closeBtn{
    right: 48px!important;
    left: auto!important;
    top: 20px!important;
    background-color: rgb(148 148 148 / 90%);
}
.crello-overlayFrame {
    background-color: rgb(220 220 220 / 80%)!important;
}
.faisal .content-BtkwR{
    display: none!important;
}
.actionBtnContainer-1yRTA, .content-BtkwR, .undoRedoButtonsWrapper-6LUDE{
    display: none!important;
}