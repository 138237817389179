@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}

/* .app-icon-page h1 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin: 0px;
  line-height: 33px;
  margin-top: -5px;
}
.app-icon-page h1 svg {
  margin-left: 6px;
} */
.apperance-module-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 5px; */
  margin-bottom: 18px;
  margin-bottom: 2px;

  
} 
.apperance-module-tabs div {
  display: flex;
  align-items: center;
}
.apperance-module-tabs div a {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin: 0px 60px -5px 0px;
  border-bottom: 2px solid transparent;
  padding-bottom: 10px;
  transition: 0.5s;
}
.apperance-module-tabs div a.active,
.apperance-module-tabs div a:hover {
  color: #3064f9;
  border-bottom: 2px solid #3064f9;
  transition: 0.5s;
}
/* .apperance-head {
  display: flex;
  align-items: center;
} */
/* .apperance-head p {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  margin-right: 20px;
  text-align: center;
} */
.apperance-module-tabs button {
  text-decoration: none;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  line-height: 15px;
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: none;
}
.app-icon-section {
  background-color: #fff;
  height: 82vh;
  background-image: url(../images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  justify-content: center;
  padding-top: 140px;
  position: relative;
  background-size: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  padding-bottom: 35px;
  min-height: 750px;
}
.app-icon-section-loader {
  background-color: #fff;
  height: 78vh;
  background-image: url(../images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  justify-content: center;
  padding-top: 140px;
  position: relative;
  background-size: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  padding-bottom: 35px;
}
.app-icon-section-loader .newspinner{
  border-radius: 6px;
}
.app-icon-section-width {
  display: flex;
  max-width: 1420px;
  margin: auto;
}
.app-icon-section .app-icon-spark {
  position: absolute;
  /* top: 30px;
  right: 33px; */
  top: 26px;
  right: 29px;
  cursor: pointer;
  fill: #bcc1ce;
  stroke: #bcc1ce;
  transition: 0.5s;
}
.app-icon-section .app-icon-spark:hover svg {
  fill: #f2c400;
  stroke: #f2c400;
  transition: 0.5s;
}
.app-icon-section-one {
  /* width: 50%; */
  margin-right: 190px;
}
.app-icon-section-one-width {
   /* margin-left: -90px; */
  max-width: 520px;
  min-width: 520px;
  background-color: #fff;
}
.app-icon-page .billing-tooltip .billing-tooltipsubheader{
  top: 5px;
}
.app-icon-section-two {
  /* width: 50%; */
  /* margin-left: 71px; */
  margin-left: 70px;
}
/* .app-icon-section-two img{
    margin-left: 71px;
} */
.app-icon-section-one-h2 {
  color: #1b2952;
  font-size: 48px;
  font-family: "Axiforma-Bold";
  line-height: 69px;
  padding-top: 0px;
}
.app-icon-section-one-para{
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular2";
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 21px;
}
.app-icon-navigation-modal-div{
 height: 40px;
}
.rebuild-required-modal-custom{
  max-width: 480px;
  max-height: 270px
}
.app-icon-buttons {
  display: flex;
  align-items: center;
  /* margin-top: 40px;
  margin-bottom: 40px; */
  margin-top: 43px;
  margin-bottom: 37px;
}
.app-icon-buttons .primary-submit-web-btn, .app-icon-buttons .primary-submit-web-disable-btn{
  width: 200px;
  height: 50px;
  margin-left: 0px;
}
/* .app-icon-buttons span:nth-child(1) {
  width: 200px;
  background-color: #3064f9;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Axiforma-Regular2";
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
  }
} */
.app-icon-buttons span:nth-child(1):hover {
  transition: 0.5s;
}
.app-icon-buttons span:nth-child(2) {
  border-radius: 3px;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular2";
  text-align: center;
  margin-left: 40px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: var(--custom_color6)!important;
  }
}
.app-icon-buttons span:nth-child(2):hover {
  transition: 0.5s;
}
.app-icon-options {
  margin-bottom: 170px;
}
.app-icon-options span {
  border-radius: 3px;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular2";
  text-align: center;
  margin-right: 39px;
  display: none;
  line-height: 18px;
}
.appicon-ask-an-expert,
.appicon-ask-an-expert:hover {
  color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Regular2";
    text-decoration: none;
    line-height: 18px;
}
.app-icon-download {
  transition: 1.5s;
  position: relative;
  margin: 0px;
}
.download-icon {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transition: 1.5s;
}

.app-icon-download:hover .download-icon {
  opacity: 1;
  transition: 1.5s;
}
.download-icon svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}
.app-icon-download img {
  margin-left: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
/* .protip-popup {
  display: flex;
} */

/* .protip-popup-two {
  width: 50%;
  background-color: #f8fafe;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .protip-popup-message {
  max-width: 1000px !important;
} */
/* .protip-popup-message .modal-body {
  padding: 0px;
} */
/* .protip-popup-one button {
  background-color: #3064f9;
  width: 140px;
  height: 40px;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 20px;
} */
/* .protip-popup-one h4 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 20px;
  margin-top: 0px;
}
.protip-popup-one h6 {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 20px;
}
.protip-popup-one ul {
  margin-bottom: 0px;
  list-style-image: url("../images/dashboard/grey-tick-light.png");
  padding-left: 20px;
}
.protip-popup-one ul li {
  color: #7782a1;
  font-size: 13px;
  line-height: 24px;
  font-family: "Axiforma-Regular2";
  margin-bottom: 10px;
} */
/* .protip-popup-message .modal-content {
  overflow: hidden;
  border-radius: 6px;
} */
.app-icon-head-mobile {
  display: none;
}
.app-icon-small-preview {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  overflow: hidden;
}
.app-icon-small {
  position: absolute;
  top: 225px;
  left: 50px;
  width: 45px;
}
.app-icon-small img {
  margin-left: 0px;
}
.app-icon-small p {
  font-size: 9px;
  margin-top: 3px;
  font-family: "Axiforma-Regular2";
  text-align: center;
  width: 50px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.app-icon-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 1;
  transition: 1.5s;
}
.the-tooltip {
  display: flex;
  align-items: center;
  margin-top: 20px;
  transition: 0.2s;
  justify-content: center;
}
.app-icon-grid-line-icon-show{
  color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    cursor: pointer;
}
.app-icon-grid-line-icon-hide{
  color: #BCC1CE;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    cursor: pointer;
}
.app-icon-the-tooltip {
  display: flex;
  align-items: center;
  margin-top: 25px;
  transition: 0.2s;
  justify-content: center;
  gap: 6px;
}
.grid-line-icon-show {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  cursor: pointer;
}
.tooltip-view {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
}
.tooltip-view:hover .grid-line-show-tooltip {
  visibility: visible;
  transition: 0.2s;
}
.the-tooltip svg {
  margin-left: 7px;
}
.grid-line-show-tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 38px;
  left: -185px;
  padding: 10px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.2s;
  cursor: text;
}
.grid-line-show-tooltip::after {
  content: "";
  position: absolute;
  top: -9%;
  right: 92px;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}
.grid-line-icon-hide {
  color: #bcc1ce;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  cursor: pointer;
}
.edit-design-mobile-link {
  display: none;
}
.icon-slide-controls {
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 30px;

}
.icon-left-arow {
  width: 32px;
  height: 32px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
  z-index: 1;
  background-image: url(../images/dashboard/arrow-left-slider.png);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
}
.icon-right-arow {
  width: 32px;
  height: 32px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
  z-index: 1;
  background-image: url(../images/dashboard/arrow-right-slider.png);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
}
.apperance-slider {
  max-width: 420px;
  margin-top: -66px;
  animation: thefadeIn 3s;
  background-color: #fff;
}
.icon-slide-1 {
  width: 420px;
  margin: auto;
  padding-bottom: 100px;
  overflow: hidden ;
}
.icon-slide-2 {
  width: 302px;
  margin: auto;
}
.arrow-disabled {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  cursor: auto;
  opacity: 0.5;
}
.the-icon-slider {
  display: flex;
  overflow: hidden;
  animation: thefadeIn 3s;
}
.the-icon-width {
  min-width: 100%;
  transition: all 1s;
}
.the-icon-width-slide {
  transform: translateX(-100%);
  transition: all 1s;
  overflow: hidden;
}
.download-icon-popup-modal .modal-content {
  background-color: rgba(0, 0, 0, 0.7);
}
.download-icon-popup {
  position: relative;
}
.downloaded-icon {
  /* position: absolute;
  top: 240px;
  right: 0;
  left: 0;
  margin: auto; */
}


.download-icon-popup-footer {
  position: fixed;
  bottom: 15px;
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: auto;
  right: 0;
  left: 0;
}
.footer-save {
  display: none;
}
.app-icon-preview {
  position: relative;
  display: flex;
      align-items: center;
      justify-content: center;
}
.app-icon-default{
  background-color: #ffffff;
}
@keyframes thefadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.app-icon-spark .protiptooltip {
  visibility: hidden;
  width: 71px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: -85px;
  padding: 8px 9px 6px 16px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  margin-bottom: 0px;
}
.app-icon-spark:hover .protiptooltip {
  visibility: visible;
  text-align: left !important;   
}
.app-icon-spark .protiptooltip::after {
  content: "";
  position: absolute;
  left: 100%;
  margin-top: -2px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(180deg);
  top: 12px;
}
.app-icon-footer-save{
  display: none;
}




.apperance-slider .carousel .slider-wrapper {	
  width: 100%;
}	
.apperance-slider .carousel .control-dots{	
  display: none;	
}	
.apperance-slider .carousel .carousel-status{	
  display: none;	
}	
.apperance-slider .slider:before{	
  display: none;	
}	
.apperance-slider .carousel .slide{	
  text-align: left;	
}	
.apperance-slider  .slider{	
  background-color: transparent;	
  background-image: none;	
}	
.apperance-slider .carousel .thumbs-wrapper{	
  display: none;	
}	
.apperance-slider .carousel.carousel-slider {	
  overflow: unset;	
}	
.apperance-slider  .carousel .control-prev.control-arrow {	
  left: 101px;	
} 	
.apperance-slider .carousel.carousel-slider .control-arrow {	
  top: -102px !important;	
  width: 32px;	
  height: 32px;   	
  font-size: 16px !important;	
  border-radius: 50%;  	
}	
.apperance-slider .carousel .control-next.control-arrow {	
  right: 101px;
}	
.apperance-slider .carousel .control-prev.control-arrow:before, .apperance-slider .carousel .control-next.control-arrow:before {	
 display: none;	
}	
.apperance-slider .carousel.carousel-slider .control-arrow:hover {	
  background: unset;	
}
.apperance-slider .carousel .slide img {
  width: fit-content;
}
.apperance-slider .carousel img {
  width: fit-content;
}
.app-icon-subheader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 20px;
}
.appIcon-head-right{
  display: flex;
    align-items: center;
    gap: 20px;
}
.appIcon-head-right .primary-submit-web-btn, .appIcon-head-right .primary-submit-web-disable-btn{
  margin-left: 0px !important;
  min-width: 87px !important;
}

.app-icon-scale-div{
  padding: 15px 15px;
}
.app-icon-loader-div{
  height: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 10px 20px 0px #0000001A;
}




.img-scale-disable-upload-img-btn{
  color: #bcc1ce !important;
  cursor: not-allowed !important;
}

.img-scale-disable-upload-img-btn:hover{
  color: #bcc1ce !important;
  cursor: not-allowed !important;
}

@media (min-width: 1681px) and (max-width: 1760px) {
  .app-icon-section-width {
    max-width: 1250px;
  }
}

@media (min-width: 1541px) and (max-width: 1680px) {
  .app-icon-section-width {
    display: flex;
    max-width: 1140px;
  }
  .app-icon-section-one {
    margin-right: 110px;
  }
  .app-icon-section-two {
    margin-left: 50px;
  }
}
@media (min-width: 1400px) and (max-width: 1540px) {
  .app-icon-section-one-h2 {
    font-size: 42px;
    line-height: 60px;
  }
  .app-icon-section-one {
    padding-left: 80px;
    margin-right: 50px;
  }
  .app-icon-section-two {
    margin-left: 21px;
  }
  .app-icon-section-width {
    max-width: 1050px;
    margin: inherit;
  }
  .app-icon-section {
    max-height: 800px;
    padding-top: 130px;
  }
  .app-icon-options {
    margin-bottom: 120px;
  }
}
@media (min-width: 1201px) and (max-width: 1399px) {
  .app-icon-section-one-h2 {
    font-size: 36px;
    line-height: 50px;
    width: 90%;
  }
  .app-icon-section-one {
    padding-left: 80px;
    margin-right: 50px;
  }
  .app-icon-section-two {
    padding-right: 40px;
    margin-left: 0px;
  }
  .app-icon-section-two img {
    margin-left: 0px;
  
  }
  .app-icon-section-one-para {
    width: 90%;
  }
  .app-icon-section {
    padding-top: 120px;
  }
  .app-icon-section-one-width {
    min-width: auto;
  }
  .app-icon-options {
    margin-bottom: 100px;
  }
}
@media (min-width: 1201px) and (max-width: 1281px) {
  .app-icon-section-one-h2 {
    font-size: 27px;
    line-height: 36px;
  }
  .app-icon-buttons span:nth-child(1) {
    width: 170px;
  }
  .app-icon-buttons span:nth-child(2) {
    margin-left: 25px;
  }
}
@media (max-width: 1200px) {
  .app-icon-loader-div{
aspect-ratio: 1;
height: unset;
  }
  .app-icon-footer-save{
    display: block;
    margin-top: 20px;
  }
  .app-icon-page .app-dashboard-head .title {
    display: none;
  }
  .app-icon-subheader{
    display: none;
  }
  .app-icon-section {
    background-image: none;
    padding-top: 0px;
    max-height: initial;
    padding-bottom: 0;
    height: unset;
    min-height: unset;
  }
  .app-icon-section-loader {
    background-image: none;
    padding-top: 0px;
    max-height: initial;
    padding-bottom: 0;
    height: 80vh;
  }
  .app-icon-buttons .primary-submit-web-btn {
    width: 100%;
    height: 50px;
    margin-left: 0px;
}
  .app-icon-section-one-width {
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
  .app-icon-section-width {
    display: block;
    max-width: 100%;
    padding: 20px 20px 25px 20px;
  }
  .app-icon-page {
    padding: 20px 15px 15px 15px;
  }
  .app-icon-options {
    margin-bottom: 20px;
  }
  .app-icon-section .app-icon-spark {
    top: 20px;
    right: 15px;
  }
  .app-icon-section-one {
    width: 100%;
    margin-right: auto;
  }
  .app-icon-section-one-para{
    text-align: left !important;
    margin-top: 15px;
    margin-bottom: -5px;
  }
  .app-icon-section-two img {
    margin-left: 0px;
    width: 100%;
  }
  .app-icon-section-two {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .apperance-module-tabs {
    display: none;
  }
  .app-icon-buttons a:nth-child(2) {
    display: none;
  }
  .app-icon-buttons {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .app-icon-section-one-h2 {
    color: #1b2952;
    font-size: 30px;
    font-family: "Axiforma-Bold";
    line-height: 40px;
    padding-top: 0px;
  }
  .app-icon-buttons a:nth-child(1) {
    width: 100%;
  }
  .protip-popup-two {
    display: none;
  }
 
 
  .protip-popup {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .app-icon-options span {
    display: flex;
  }
  .app-icon-buttons span:nth-child(2) {
    display: none;
  }
  .app-icon-buttons span:nth-child(1) {
    width: 100%;
    font-size: 16px;
  }
  .apperance-slider {
    width: 100%;
    margin-top: 5px;
    margin-bottom: -11px;
  }
  .app-icon-options {
    display: flex;
    justify-content: space-between;
  }
  .app-icon-download {
    width: 300px;
    margin: auto;
  }
  /* .app-icon-page h1 {
    display: none;
  } */
  .app-icon-head-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .app-icon-head-mobile-text{
    color: #1b2952;
    font-size: 20px;
    font-family: "Axiforma-Bold";
    margin: 0px;
  }
  .app-icon-head-mobile .app-icon-spark {
    cursor: pointer;
    fill: #bcc1ce;
    stroke: #bcc1ce;
    transition: 0.5s;
    margin-top: -8px;
    margin-right: 8px;
    display: none;
  }
  /* .app-icon-section .app-icon-spark {
    display: none;
  } */
  .protip-popup-message .modal-content {
    border-radius: 0px;
    border: none;
  }
  .edit-design-mobile-link {
    display: block;
    color: #3064F9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-align: center;
    margin: 40px auto;
    line-height: 18px;
    margin-bottom: 40px;
  }
  .icon-slide-1,
  .icon-slide-2 {
    width: 100%;
    margin-bottom: 20px !important;
  }
  .grid-line-show {
    padding-bottom: 0px;
  }
  .footer-save {
    display: block;
  }
  .footer-save button {
    width: 100%;
    height: 50px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footer-save p {
    color: #bcc1ce;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    text-align: center;
  }
  .the-icon-width .app-icon-options {
    margin-bottom: 0px;
  }
  .icon-slide-2 .edit-design-mobile-link {
    margin: 5px auto 40px auto !important;
  }
  .icon-slide-controls {
    margin-bottom: 25px;
  }
  .app-icon-small {
    position: absolute;
    top: 233px;
    left: 50px;
  }
  .download-icon {
    display: none;
  }
  .no-editor .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no-editor-popup {
    margin-top: -80px;
  }
  .no-editor-popup h4 {
    color: #1b2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    margin-bottom: 18px;
    line-height: 25px;
  }
  .no-editor-popup-title {
    color: #1b2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    margin-bottom: 18px;
    line-height: 25px;
  }
  .no-editor-popup h6 {
    color: #7782a1;
    font-size: 14px;
    line-height: 18px;
    font-family: "Axiforma-Regular";
    text-align: center;
  }
  .no-editor-popup-desc{
    color: #7782a1;
    font-size: 14px;
    line-height: 18px;
    font-family: "Axiforma-Regular";
    text-align: center;
  }
  .no-editor-popup button {
    width: 80%;
    height: 50px;
    border: none;
    color: #ffffff;
    background-color: #3064f9;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    margin: auto;
    display: block;
    margin-top: 30px;
    margin-bottom: 36px;
  }
  .no-editor-popup-okay-btn{
    width: 80%;
    height: 50px;
    border: none;
    color: #ffffff;
    background-color: #3064f9;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    margin: auto;
    display: block;
    margin-top: 30px;
    margin-bottom: 36px;
  }
  .no-editor-popup span {
    width: 100%;
    text-align: center;
    color: #7782a1;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    display: block;
    line-height: 24px;
  }
  .no-editor-popup-proceed{
    width: 100%;
    text-align: center;
    color: #7782a1;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    display: block;
    line-height: 24px;
  }
  .no-editor .modal-content {
    border: none !important;
    border-radius: 0px;
  }
  .the-icon-slider {
    overflow: hidden;
  }
}


@media (min-width: 483px) and (max-width: 1200px) {
  .app-icon-small{
  top: 270px;
  left: 85px;
}
}
@media (min-width: 450px) and (max-width: 483px) {
  .app-icon-small{
  top: 282px;
  left: 78px;
}
}
@media (min-width: 430px) and (max-width: 449px) {
  .app-icon-small{
  top: 285px;
  left: 64px;
}
}
@media (min-width: 390px) and (max-width: 430px) {
  .app-icon-small{
  top: 263px;
  left: 56px;
}}

@media (min-width: 351px) and (max-width: 389px) {
  .app-icon-small{
  top: 255px;
  left: 50px;
}
}
@media (min-width: 320px) and (max-width: 350px) {
  .app-icon-small{
  top: 207px;
  left: 36px;
}
}


@media  screen and (max-width: 389px), screen and (max-height: 500px){
  .downloaded-icon{
      top: 80px;
  }
  .app-icon-small{
      top: 203px;
      left: 40px;
}
.app-icon-download {
  width: unset;
}
}
