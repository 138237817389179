.addons-dasboard_page {
  background-color: #fcfdff;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.addons-dasboard_page .right-panel-gap {
  overflow-x: unset !important;
}
.addon-head h1 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.addons-delete-popup .modal-body {
  min-height: 210px !important;
  padding: 60px 45px;
  height: unset !important;
  max-height: unset !important;
}
.addons-delete-popup .grey-btn p:hover {
  color: #7782a1;
  background-color: unset;
  transition: 0.5s;
}
.addon-minus-icon{
 height: 20px;
  display: flex; 
  align-items: center
}
.addons-ticket {
  height: 240px;
  /* width: 24%;
  min-width: 317px; */
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  position: relative;
  padding: 20px;
  overflow: hidden;
  transition: 0.5s;
}
.purchesed-addon-para {
  margin-right: 22px;
}
.addons-ticket:hover {
  border: 1px solid #a4bbff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
}
.addon-ticket-active {
  border: 1px solid #a4bbff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
}
.addons-ticket-div {

  padding: 20px 0px 35px;
  grid-template-columns: repeat(auto-fill, minmax(398px, 1fr));
  gap: 20px;
  display: grid;
}

.purchesed-addon {
  display: flex;
  align-items: center;
}
.addons-tickets-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.addons-pluginhead-name {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.addon-ticket-description {
  width: 90%;
  margin-bottom: 17px;
}
.addons-description-para {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
  text-align: left !important;
}
.addons-website-link {
  color: #3064f9 !important;
  white-space: nowrap;
}
.addons-services {
  font-size: 10px;
  color: #7782a1;
  background-color: #e3e6f1;
  width: 55px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  padding: 2px;
}
.addons-price-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  /* bottom: 20px; */
  /* padding: 0px 40px 0px 0px; */
  gap: 5px;
  
}
.addons-price {
  font-size: 20px;
  font-family: "Axiforma-Bold";
  color: #0f193b;
  margin-bottom: -5px;
  line-height: 20px;
  text-align: left !important;
}
.addons-price-afterdot {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #0f193b;
}
.addons-price-type {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.addon-plus-minus {
  display: flex;
  align-items: center;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 36px;
  padding: 0px 11px;
  margin-right: 10px;
  margin-top: 5px;
}

.addon-numberdiv {
  min-width: 48px;
  text-align: center;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  font-size: 14px;
}
.active-addons {
  margin-bottom: 0px;
  color: #52cb86;
  font-family: "Axiforma-Regular";
  align-items: end;
  text-align: end !important;
  font-size: 13px;
}
.cancellation-addons-date {
  margin-bottom: 0px;
  color: #FF4848;
  font-family: "Axiforma-Regular";
  align-items: end;
  text-align: end !important;
  font-size: 13px;
}
.addon-cancel {
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 12px;
  text-align: end;
  margin-bottom: 0px;
  cursor: pointer;
  text-align: right !important;
}
.addon-reactivation {
  font-family: "Axiforma-Regular";
  color: #3064F9;
  font-size: 12px;
  text-align: end;
  margin-bottom: 0px;
  cursor: pointer;
  text-align: right !important;
}
.addon-popUpback-btn {
  margin-right: 20px;
}
.cancled-addon {
  font-family: "Axiforma-Regular";
  color: #ff4848;
  font-size: 12px;
  text-align: end !important;
  margin-bottom: 0px;
}
.addons-ticket-name{
  display: flex;
  align-items: center;
}
.addon-buy-btn {
  color: white;
  background-color: #3064f9;
  border: none;
  width: 78px;
  height: 36px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  font-size: 14px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addon-buy-btn:hover {
  background-color: #134bec !important;
  color: white !important;
  transition: 0.5s;
}

.addon-add-btn-disable {
  width: 60px;
  border: none;

  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: white;
  border-radius: 3px;
  height: 36px;
  margin-top: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bcc1ce !important;
  cursor: not-allowed !important;
}
.addon-add-btn {
  width: 60px;
  border: none;
  background-color: #3064f9;

  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: white;
  border-radius: 3px;
  height: 36px;
  margin-top: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.addon-add-btn:hover {
  background-color: #134bec !important;
  color: white !important;
  transition: 0.5s;
}

.addon-add-btn-disable:hover {
  color: white !important;
  transition: 0.5s;
}

.addon-add-btnenable {
  background-color: #3064f9;
  cursor: pointer !important;
}
.addon-add-btnenable:hover {
  background-color: #134bec;
  transition: 0.5s;
}

.new-addons-spinner-div {
  position: relative;
}
.new-addons-spinner-div .newspinner {
  border-radius: 6px;
  height: calc(100vh - 140px);
  border: 1px solid #e3e6f1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  margin-top: 43px;

}

.addon-delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addon-delete-btn:hover .new-red-loader {
  border: 2px solid #fff;
  border-top: 2px solid transparent !important;
}
.addon-upgrade-btn {
  color: white !important;
  background-color: #3064f9 !important;
  border: none;
  width: 140px;
  height: 44px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.addon-upgrade-btn:hover {
  background-color: #134bec !important;
  color: white !important;
  transition: 0.5s;
}
.addon-strip-color {
  position: absolute;
  top: 20;
  left: 0;
  width: 3px;
  height: 40px;
}
.addons-pluginheadtwo {
  margin-top: 10px;
}
.addon-card-body{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}
.addon-card-main-div{
  height: 100%;
}
.inc-dec-icon{
cursor: pointer
}
.team-addon-footer{
  align-items: flex-end !important;
}
.new-addons-spinner-div .appdash-tooltip .appdash-tooltipsubheader {
  width: 184px;
  top: 29px;
  left: -77px;
  padding: 10px 10px 10px 10px;
}
.new-addons-spinner-div .appdash-tooltip .appdash-tooltipsubheader::after {
  right: 93px;
    top: -7px;
    border-color: transparent transparent black transparent;
}

.addon-discontinued .addons-pluginhead-name {
  color: #BCC1CE;
}
.addon-discontinued .addons-description-para {
  color: #BCC1CE;
}
.addon-discontinued .addons-price {
  color: #BCC1CE;
}
.addon-discontinued .addons-price-afterdot {
  color: #BCC1CE;
}
.addon-discontinued .addons-price-type {
  color: #BCC1CE;
}
.addon-discontinued .addon-strip-color{
  background-color: #BCC1CE !important;
}
.addon-discontinued .addons-ticket:hover {
  border: 1px solid #e3e6f1;
  box-shadow:unset !important;
}
.discontinued-addons-head-parent{
  display: flex;
  align-items: center;
}

@media (max-width: 750px) {
  .addons-ticket-div {
    padding: 20px 0px 35px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    display: grid;
  }
  .addons-ticket {
    height: unset;
  }
  .addon-card-body{
    gap: 20px;
  }
}
@media (max-width: 1200px) {

  .new-addons-spinner-div .app-dashboard-head {
    margin: 5px 0px;
  }
  .addons-delete-popup .modal-body {
    padding: 20px !important;
  }
  .addons-dasboard_page .right-panel-gap {
    height: unset !important;
    padding: 15px !important;
  }
  .addons-pluginhead .bottombar-tooltip .bottombar-tooltipsubheader {
    left: -117px !important;
  }

  .addons-pluginhead .bottombar-tooltip .bottombar-tooltipsubheader::after {
    right: 69px !important;
  }
  .addons-pluginheadtwo .bottombar-tooltip .bottombar-tooltipsubheader {
    left: -117px !important;
  }

  .addons-pluginheadtwo .bottombar-tooltip .bottombar-tooltipsubheader::after {
    right: 69px !important;
  }
  .addons-ticket-div {
    gap: 20px;
    padding: 10px 0px 20px;
  }
  .addon-upgrade-btn {
    width: unset;
    font-size: 16px;
    height: 50px;
    width: 280px;
    margin: auto;
    margin-top: 6px;
    
  }
  .addon-delete-btn {
    width: 280px !important;
    height: 50px !important;
    font-size: 16px !important;
    margin-top: 6px;
  }
  .addon-back-btn {
    font-size: 16px !important;
  }
  
}
