.new-create-app-new {
  background: linear-gradient(270deg, #f8fafe 50%, #fff 0) !important;
  /* height: calc(100vh - 90px);
    height: 100vh; */
}
.new-createapp-left {
  justify-content: center;
  display: flex;
  padding: 240px 0px 0px 0px !important;
}
.new-createapp-right {
  justify-content: center;
  display: flex;
  padding: 270px 0px 0px 26px !important;
  background-color: #f8fafe;
  height: 100vh;
}
.new-create-appdiv {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.new-createapp-form {
  width: 480px;
}
.new-createapp-form form {
  margin-bottom: 0;
}
.new-createapp-head a {
  font-size: 13px;
  color: #7782a1 !important;
  font-family: "Axiforma-Regular";
  margin: 0px;
  margin-right: 4.5px;
}
.new-createapp-head .title {
  font-size: 40px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin: 0px;
  line-height: 56px;
}
.new-createapp-head {
  justify-content: space-between;
  margin-bottom: 29px;
  padding-top: 1px;
}
.new-myapp-name {
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 8px;
  text-align: left !important;
  line-height: 17px;
}
.new-web-type-text {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px !important;
  margin-top: 17px;
  line-height: 17px;
  margin-bottom: 9px;
  text-align: left !important;
}
.new-createapp-firstinput{
  margin-bottom: 22px !important;
}
.new-create-app-group.form-group {
  line-height: 1;
  margin-bottom: 0;
}
.new-createapp-firstinput input {
  height: 50px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.new-createapp-firstinput input:hover,
.new-createapp-firstinput input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.new-apptype p {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-left: 10px;
  line-height: 17px;
}
.new-create-appdiv .new-error p {
  margin-bottom: -4px;
  line-height: 16px;
}
.apptype-notselect {
  border: 1px solid #e3e6f1;
}
.apptype-notselect p {
  color: #7782a1;
}
.new-apptype-div {
  width: 50%;
  margin-right: 10px;
  justify-content: space-between;
  margin-bottom: 0px;
  border-radius: 4px;
  padding: 14px 15px;
  cursor: pointer;
}
.new-apptype-seconddiv {
  width: 50%;
  margin-right: 10px;
  margin-left: 0px;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 14px 15px;
  cursor: pointer;
}
.new-wooapptype-div {
  width: 50%;
    margin-left: 10px;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    padding: 14px 15px;
    margin-bottom: 20px;
    margin-right: 0px;
}
.new-customapptype-seconddiv {
  width: 50%;
  margin-left: 10px;
  justify-content: space-between;
  border-radius: 4px;
  padding: 14px 15px;
  cursor: pointer;
}

.select-Customapp {
  border: 1px solid #4b7aff;
  box-shadow: 0px 2px 8px rgba(48, 100, 249, 0.13);
}
.select-Customapp p {
  color: #1b2952;
}
.select-wordpress {
  border: 1px solid #60bbd9;
  box-shadow: 0px 2px 8px rgba(0, 116, 154, 0.13);
}
.select-wordpress p {
  color: #1b2952;
}
.select-wooComerce {
  border: 1px solid #be9ae9;
  box-shadow: 0px 2px 8px rgba(127, 84, 179, 0.13);
}
.select-wooComerce p {
  color: #1b2952;
}
.select-web-toapp {
  border: 1px solid #66b9ff;
  box-shadow: 0px 2px 8px rgba(108, 185, 255, 0.13);
}
.select-web-toapp p {
  color: #1b2952;
}
.new-web-type-url {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px !important;
  margin-top: 20px;
  margin-bottom: 9px;
  line-height: 17px;
  text-align: left !important;
}
.new-http {
  border-radius: 3px;
  /* padding: 19px 15px 18px 15px; */
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  height: 50px;
  line-height: 50px;
  font-family: "Axiforma-Regular2";
  border-right: 0px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  min-width: 125.5px;
}
/* .new-http:hover, .new-http:focus{
    border: 1px solid #3064f9!important;
    background-color: #f8fafe!important;
} */
.form-group .new-http select {
  background: white url(../../../assets/images/signup/down.png);
  background-repeat: no-repeat;
  background-position: 90%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular2";
  border-top: 1px solid #bcc1ce;
  border-bottom: 1px solid #bcc1ce;
  border-left: 1px solid #bcc1ce;
  border-right: 0px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 50px;
  width: 100%;
  outline: none !important;
  padding: 0px 0px 0px 15px !important;
  font-size: 13px;
  /* font-family: "Axiforma-Regular" !important; */
  color: #1b2952;
}
.form-group .new-http select:hover,
.form-group .new-http select:focus {
  border-top: 1px solid #3064f9 !important;
  border-bottom: 1px solid #3064f9 !important;
  border-left: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.createapp-lastinput input {
  height: 50px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  /* border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px; */
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.createapp-lastinput input:hover,
.createapp-lastinput input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
}
.createapp-lastinput {
  margin-bottom: 0px !important;
}
.disabled-apptype {
  background-color: #f8fafe;
  border: 1px solid #e3e6f1;
  cursor: unset;
}
.disabled-apptype img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.4;
}
.disabled-apptype p {
  color: #bcc1ce;
  cursor: context-menu;
}
.createapp-lastinput .select-licance__control {
  width: 100%;
  height: 50px !important;
  border: 1px solid #bcc1ce !important;
  border-radius: 3px !important;
  margin-top: 10px;
  box-shadow: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.createapp-dropdown .dropdown-toggle {
  white-space: normal !important;
  width: 100%;
  border: 1px solid #bcc1ce !important;
  border-radius: 3px;
  text-align: left;
  height: 50px;
  margin-bottom: 0px;
  color: #1b2952 !important;
  background-image: url(../../../assets/images/dashboard/email-support-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  -webkit-appearance: none;

  transition: 0.5s;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  padding: 15px;
}
.createapp-dropdown .dropdown-toggle::after {
  display: none;
}
.createapp-dropdown .dropdown-menu {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  padding: 4px 0px;
}
.createapp-dropdown .form-group {
  margin-bottom: 0px !important;
}
.createapp-dropdown .dropdown-item {
  display: flex;
  width: calc(100% - 10px);
  padding: 12px 10px 12px 10px !important;
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  margin: auto !important;
  border: 0px solid #fff !important;
  align-items: center;
  justify-content: space-between;
}
.createapp-dropdown .dropdown-item:hover {
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
  color: #1b2952 !important;
}
.createapp-dropdown .dropdown-item.active,
.createapp-dropdown .dropdown-item:active {
  background-color: #f8f9fd !important;
  color: #1b2952 !important;
}
.createapp-dropdown-before {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.createapp-dropdown .dropdown-toggle:hover,
.createapp-dropdown .dropdown-toggle:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.createapp-dropdown .select-default {
  color: #7782a1 !important;
}
.createapp-dropdown .dropdown-menu::-webkit-scrollbar-track {
  background-color: white !important;
}
.createapp-dropdown .dropdown-item.disableddeal , .createapp-dropdown .dropdown-item.disableddeal:hover{
  background-color: transparent!important;
  cursor: not-allowed!important;
}
.createapp-dropdown .dropdown-item span:nth-child(1){
  font-size: 13px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
}
.createapp-dropdown .dropdown-item span:nth-child(2){
  font-size: 10px;
  color: #7782A1;
  font-family: "Axiforma-Regular";
}
.createapp-dropdown .dropdown-item.disableddeal span{
  color: #BCC1CE!important;
}
.createapp-dropdown .dropdown-item.expireddeal span:nth-child(2){
  color: #FF4848!important;
}
.createapp-dropdown .dropdown-item.expireddeal span:nth-child(1){
  color: #BCC1CE!important;
}
.expireddeal , .expireddeal:hover{
  background-color: transparent!important;
  cursor: not-allowed!important;
}
.select-licance__menu {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.16) !important;
  margin-top: 0px !important;
}
.select-licance__menu-list {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.select-licance__option {
  font-size: 13px !important;
  background-color: #ffffff !important;
  color: #1b2952 !important;
  padding: 13px 10px !important;
  margin: auto;
  width: calc(100% - 10px) !important;
  font-family: "Axiforma-Regular";
}
.select-licance__option:hover {
  font-size: 13px !important;
  background-color: #f8f9fd !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 10px !important;
}
.createapp-lastinput .select-licance__value-container {
  padding-left: 15px !important;
}

.createapp-lastinput .select-licance__control:hover,
.createapp-lastinput .select-licance__control:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
  height: 40px Im !important;
}
.mob-licance-head {
  padding: 21px;
  line-height: 15px;
  box-shadow: 0px 0px 6px rgba(118, 193, 206, 0.36);
}
.mob-licance {
  display: none;
}
.mob-licance ul li {
  margin-top: 35px;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  list-style: none;
  padding: 0px;
  color: #1b2952;
}
.mob-licance ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.new-create-app-group {
  margin-bottom: 0px;
}
.mob-licance {
  width: 100%;
  padding: 60px 20px 20px;
}
.createapp-disbutton button {
  width: 100%;
  border: none;
  height: 50px;
  background-color: #bcc1ce;
  border-radius: 3px;
  color: white;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-top: 30px !important;
  cursor: not-allowed !important;
}
.createapp-createbutton button {
  width: 100%;
  border: none;
  height: 50px;
  background-color: var(--custom_color1)!important;
  border-radius: 3px;
  color: var(--custom_color2)!important;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-top: 30px !important;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
}
.create-firstappdiv {
  display: flex;
}
.creteapp-errorborder {
  border: 1px solid #ff4848;
}
.createapp-right-steps{
  display: flex;
  align-items: center;
}
.createapp-right-steps h5{
  color: #1B2952;
  font-family: "Axiforma-Bold";
  font-size: 24px;
  margin-left: 45px;
  line-height: 33px;
  margin-bottom: 18px;
}
.createapp-right-steps ul{
  padding-left: 12px;
  margin-left: 45px;
}
.createapp-right-steps ul li::before{
  content: url("../../../assets/images/dashboard/grey-circle-tick.png");
  position: relative;
  left: -10px;
  top: 3px;
}
.createapp-right-steps ul li{
  color: #7782A1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  list-style-type: none;
  line-height: 14px;
  max-width: 305px;
  min-width: 305px;
  margin-bottom: 18px;
}
.create-app-steps-img{
  width: 251px;
  min-width: 251px;
  height: 476px;
}
.create-app-features{
  margin-top: 6px;
}


@media (min-width: 1201px) and (max-width: 1800px){
  .new-createapp-left{
    margin-bottom: 30px !important;
  }
}






@media (min-width: 1201px) and (max-width: 1590px) {
.new-createapp-left {
  padding: 180px 0px 0px 0px !important;
}
.new-createapp-right {
  padding: 200px 0px 0px 0px !important;
}
.createapp-right-steps ul li {
  max-width: auto;
  min-width: auto;
}

}





@media (max-width: 1200px) {
  .createapp-disbutton button {
    margin-top: 20px !important;
  }
  .createapp-createbutton button {
    margin-top: 20px !important;
  }
  .mob-licance {
    display: block;
  }
  .new-create-app-new {
    background: linear-gradient(270deg, #fff 50%, #fff 0) !important;
  }
  .new-createapp-left {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 60px 0px 0px 0px !important;
  }
  .create-firstappdiv {
    display: grid;
  }
  .new-apptype-div {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .new-apptype-seconddiv {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .new-wooapptype-div {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .new-customapptype-seconddiv {
    width: 100%;
    margin-left: 0px;
  }
  .createapp-disbutton button {
    font-size: 16px;
  }
  .createapp-createbutton button {
    font-size: 16px;
  }
  .new-createapp-head .title {
    font-size: 30px;
    margin: 30px 0px 14px;
    line-height: 30px;
  }
  .new-createapp-head {
    margin-bottom: 16px;
  }
  .new-http {
    min-width: 100.5px;
  }
  .new-createapp-form {
    width: 100%;
    padding: 0px 20px 40px 20px;
  }
  .new-createapp-right {
    padding: 0px 0px 0px 0px !important;
    background-color: #fff!important;
    height: auto!important;
    display: block;
  }
  .create-app-steps-img{
    display: none;
  }
  .createapp-right-steps h5 {
    margin-left: 20px;
  }
  .createapp-right-steps ul {
    margin-left: 20px;
    padding-bottom: 30px;
  }
}
/* @media (max-width: 520px) {
    .new-createapp-form{
        width: 100%;
        padding: 0px 20px 124px 20px;
    }
} */
