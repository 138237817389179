@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}

.premium-featureicon{
  margin-right: 0px !important;
  margin-left: 6px;
}
.fullwidthspinner{
  margin: 30px;
  height: 88vh;
  width: calc(100% - 60px);
}
.colaps-left-pannel{
 display: flex;
 position: relative;
}
.collapse-premium-featureicon{
  height: 9px;
    width: 9px;
    background-color: #FFB300;
    border-radius: 100%;
    border: 2px solid #FFFFFF;
    position: absolute;
    left: 11px;
}
.colaps-left-pannel svg{
  margin-right: 0px !important ;
 }
.colaps-left-pannel-star{
  width: 11px;
  height: 11px;
  margin-left: -5px;
  margin-top: -1px;
}
.mob-sidebar-header{
  display: none;
}
.sidebar-submenu li{
font-size: 12px;
font-family: "Axiforma-Regular";
width: calc(100% - 10px);
/* padding: 8px 14px; */
padding: 8px 4px 8px 14px;
list-style-type: none;
border-radius: 4px;
cursor: pointer;
margin-bottom: 0px ;
width: 147px;
}
.left-pannel-red-dot{
  height: 6px;
    width: 6px;
    background-color: #FF4848;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    position: absolute;
    top: 1px;
    right: 12px;
}


.sidebar-submenu {
  margin-bottom: 5px ;
  padding-left: 40px;;
}
.sidebar-submenu li:hover{
 background-color: #F1F4FB;
  }

  .sidebar-submenu li:hover p{
        color: var(--custom_color4);
    /* color: #3064F9; */
     }
.sidebar-submenu li p{
  color: #7782A1;
  text-align: left !important;
}
.sidebar--Collapse .sidebar-submenu{
  display: none;
}
.collapse-submenu-child{
   visibility: hidden;
    background-color: #ffffff;
    color: #7782a1;
    border-radius: 6px;
    position: fixed;
    z-index: 1;
    /* top: -23px; */
    /* left: 60px; */
    padding: 10px 10px 5px 10px;
    font-size: 13px;
    line-height: 21px;
    text-align: left !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    transition: 0.2s;
    display: grid;
}
.collapse-submenu-child::after{
  content: "";
    position: absolute;
    top: 20px;
    right: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: #e6ebff14 white #e6ebff14 #e6ebff14;
}
.collapse-submenu{
  visibility: hidden;
    background-color: #ffffff;
    color: #7782a1;
    border-radius: 6px;
    position: fixed;
    z-index: 1;
    /* top: -23px; */
    /* left: 60px; */
    padding: 10px 10px 5px 10px;
    font-size: 13px;
    line-height: 21px;
    text-align: left !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    transition: 0.2s;
}
.collapse-submenu::after{
  content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: #e6ebff14 white #e6ebff14 #e6ebff14;
}
.collapse-submenulink-child:hover .collapse-submenu-child {
  visibility: visible;
  transition: all 0.2s ease 0s;
}
.sidebar--Collapse .profile-panel-entry:hover .collapse-submenu {
  visibility: visible;
  transition: all 0.2s ease 0s;
}
.collapse-submenu .collapse-submenulink{
  width: 150px;
    padding: 8px 14px;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    line-height: 12px;
    border-radius: 4px;
    margin-bottom: 5px;
}
.collapse-submenu .collapse-submenulink:hover{
 background-color: #F1F4FB;
 /* color: #3064F9; */
 color: var(--custom_color4) !important;
}
.collapse-submenu-child .collapse-submenulink{
  width: 150px;
    padding: 8px 14px;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    line-height: 12px;
    border-radius: 4px;
    margin-bottom: 5px;
}
.collapse-submenu-child .collapse-submenulink:hover{
 background-color: #F1F4FB;
 /* color: #3064F9; */
 color: var(--custom_color4) !important;
}

.thesidebar {
  width: 200px;
  display: inline-block;
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #e3e6f1;
  height: calc(100vh - 60px);
  margin-top: 60px;
  position: fixed;
  transition: 0.4s;
}
.rotate {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}
#toggleIcon {
  -moz-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}
.portal-body {
  display: flex;
}
.sidebar--Collapse {
  width: 60px !important;
  transition: 0.4s;
}
.sidebar--Collapse .profile-panel-list {
  height: calc(100% - 78px);
}
.profile-panel-list {
  position: relative;
  font-size: 0;
  display: block;
  background-color: #fff;
  /* margin-top: 18px; */
  height: calc(100% - 106px);
    /* overflow-y: auto;
    overflow-x: hidden; */
    padding-top: 10px;
    padding-bottom: 0px;
}
.profile-panel-list::-webkit-scrollbar { 
  width: 2px !important;
}
.right-panel-gap {
  background-color: #f8fafe;
  padding: 28px;
  width: calc(100% - 200px);
  margin-left: 200px;
  margin-top: 60px;
  position: relative;
  transition: 0.4s;
  min-height: calc(100vh - 60px);
  padding-right: 30px;
  padding-left: 30px;
}
.userProfile-name-label .full-page-tooltip .full-page-tooltipsubheader {
  width: 200px;
}
.right-panel-gap-expand {
  width: calc(100% - 60px);
  margin-left: 60px;
}
.profile-panel-head {
  padding: 20px 10px 21.5px 0;
  border-bottom: 1px solid #edf0f5;
  margin: 0px 0px 0px 20px;
  transition: all 0.5s ease;
  overflow: hidden;
  height: 104.5px;
  display: flex;
  align-items: center;
}
.profile-panel-head h2 {
  font-family: "Axiforma-Bold";
  color: #1b2952;
  font-size: 14px;
  margin: 0px;
  line-height: 20px;
  max-width: 179px;
    padding: 0px;
    word-break: break-word;
}
.profile-panel-head h5 {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 16px;
}
.profile-panel-info {
  position: relative;
  left: 0px;
  max-width: 179px;
  animation-name: accountname;
  /* animation-duration: 1s; */
  animation-iteration-count: 1;
  overflow: hidden;
}
@keyframes accountname {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.profile-panel-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: none;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.sidebar--Collapse .profile-panel-img {
  display: block;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  margin: auto;
}

.app-panel-img {
  width: 36px;
  height: 36px;
  border-radius: 30%;
  display: none;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.sidebar--Collapse .app-panel-img {
  display: block;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  margin: auto;
}
.sidebar--Collapse .profile-panel-info {
  display: none;
  transition: 0.5s;
  position: relative;
  left: -113px;
}
.thesidebar::before {
  display: block;
  content: url(./../images/dashboard/sidebar-gap.png);
  position: absolute;
  z-index: 99;
  top: 18.5px;
  right: -16px;
  margin: auto;
  transform: rotate(0deg);
}
.collapseToggle {
  position: absolute;
  right: -13px;
  top: 27px;
  z-index: 99;
  cursor: pointer;
  height: 24px;
  padding: 0px;
  font-size: 10px;
  line-height: 20px;
  width: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapseToggle svg {
  stroke: #7782a1;
}
.collapseToggle:hover svg {
  stroke: #3064f9;
}
.sidebar--Collapse .profile-panel-head {
  padding: 20px 0px 20px 0px;
  margin: 0px 5px 0px 5px;
  transition: all 0.5s ease;
  height: unset;
}
.profile-panel-entry {
  margin: auto;
  margin-bottom: 4px;
  margin-top: 4px;
  width: calc(100% - 20px);
  border-radius: 4px;
  transition: 0.3s ease-out;
  padding: 0px 0px;
}
.profile-panel-entry:hover {
  background-color: #f1f4fb;
  transition: 0.3s ease-out;
}
.profile-panel-entry a {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 9px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
}
.profile-panel-entry .Customers{
  stroke: #1B2952;
}
.profile-panel-entry:hover .Customers{
  stroke: #3064f9;
}
.profile-panel-entry-active .Customers{
  stroke: #3064f9;
}
.profile-panel-entry-help {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 9px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin: auto;
  margin-bottom: 4px;
  margin-top: 4px;
  width: calc(100% - 20px);
  border-radius: 4px;
  transition: 0.3s ease-out;
  /* padding: 0px 0px; */
}
.profile-panel-entry-help:hover {
  background-color: #f1f4fb;
  transition: 0.3s ease-out;
}
.profile-panel-entry-help a {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 9px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.leftpanel-parent-child-section{
  display: flex;
  align-items: center;
  padding: 8px 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
/* .profile-panel-entry a{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */
.profile-panel-entry p {
  margin: 0px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  /* animation-name: sidebarnameblock; */
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  overflow: hidden;
  position: relative;
  top: 0px;
  line-height: 17px;
  /* margin-top: 2px; */
  display: flex;
    align-items: center;
}
.profile-panel-entry p img{
 margin-left: 6px;
 
}


.profile-panel-entry-help p {
  margin: 0px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  /* animation-name: sidebarnameblock; */
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  overflow: hidden;
  position: relative;
  top: 0px;
  line-height: 17px;
  /* margin-top: 2px; */
  display: flex;
    align-items: center;
}
.profile-panel-entry-help p img{
 margin-left: 6px;
 
}
@keyframes sidebarnameblock {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.profile-panel-entry svg {
  margin-right: 14px;
  fill: #1b2952;
  position: relative;
  width: 20px;
}
.profile-panel-entry-help svg {
  margin-right: 14px;
  stroke: #1b2952;
  position: relative;
  width: 20px;
}
.profile-panel-entry .sidebar-iconstroke{
 stroke: #1b2952;
}

.sidebar--Collapse .profile-panel-list p {
  animation-name: sidebarname;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  display: none;
}
@keyframes sidebarname {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* .profile-panel-entry:hover{
    background-color: #F1F4FB;
    border-radius: 4px;
    cursor: pointer;
    transition: .5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
} */
.profile-panel-entry:hover svg {
    fill: var(--custom_color4);
  /* fill: #3064f9; */
}

.profile-panel-entry-help:hover svg {
  stroke: var(--custom_color4);
/* fill: #3064f9; */
}
.profile-panel-entry:hover .sidebar-iconstroke {
/* stroke: #3064f9; */
stroke: var(--custom_color4);
}
.profile-panel-entry:hover p {
      color: var(--custom_color4);
  /* color: #3064f9; */
}

.profile-panel-entry-help:hover p {
  color: var(--custom_color4);
/* color: #3064f9; */
}
.sidebar--Collapse .collapseToggle {
  top: 26px;
  right: -14px;
}
.paneltiptext {
  white-space: nowrap;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 10px;
  text-align: center;
  border-radius: 6px;
  position: fixed;
  z-index: 1;
  /* top: 4px; */
  /* left: 61px; */
  padding: 9px 12px 9px 12px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 24%);
  transition: all 0.2s ease 0s;
}
.paneltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: -15px;
  margin-left: 0px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent rgb(0 0 0 / 80%) transparent;
  transform: rotate(270deg);
  top: 9px;
}
.sidebar--Collapse .profile-panel-entry-help:hover .paneltiptext {
  visibility: visible;
  transition: all 0.2s ease 0s;
}
.sidebar--Collapse .profile-panel-entry:hover .paneltiptext {
  visibility: visible;
  transition: all 0.2s ease 0s;
}
/* .profile-panel-animate{
    position: absolute;
    height: 32px;
    top: 0;
    z-index: 0;
    transition: all .2s ease 0s;
    margin: auto;
    border-radius: 4px;
    left: 0;
    right: 0;
}
.profile-panel-entry:nth-child(1):hover~.profile-panel-animate {
	width: calc(100% - 20px);
	top: 0px;
	background-color: #F1F4FB;
}
.profile-panel-entry:nth-child(2):hover~.profile-panel-animate {
	width: calc(100% - 20px);
	top: 42px;
	background-color: #F1F4FB;
}
.profile-panel-entry:nth-child(3):hover~.profile-panel-animate {
	width: calc(100% - 20px);
	top: 84px;
	background-color: #F1F4FB;
} */
.profile-panel-entry-active {
  background-color: #f1f4fb;
  /* height: 32px; */
  transition: all 0.2s ease 0s;
  /* margin: auto; */
  border-radius: 4px;
}
.profile-panel-entry-active p {
  color: var(--custom_color4);
  /* color: #3064f9; */
}
.profile-panel-entry-active svg {
  fill: var(--custom_color4);
  /* fill: #3064f9; */
}
.profile-panel-entry-active .sidebar-iconstroke{
  stroke: var(--custom_color4);
 /* stroke: #3064f9; */
}

.submenu-active{
  background-color: #F1F4FB;
  /* color: #3064f9 !important; */
  color: var(--custom_color4) !important;;

}
.submenu-active p{ 
  color: var(--custom_color4)!important;
  /* color: #3064f9 !important; */
} 
.submenu-active-svg{
  /* fill: #3064f9 !important; */
  fill: var(--custom_color4) !important;
}
.overflowonexpand{
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling in Webkit browsers */
}

.overflowwhencolleps{
  overflow-y: auto;
  overflow-x: hidden;

}
.overflowwhencolleps::-webkit-scrollbar{
  width: 0px !important;
}
.app-leftpanel-seprator{
  border-top: 1px solid #EDF0F5;
  margin: 20px 0px;
}
.mob-submenudesign{
  display: none;
}
.mob-richnotificationli{
  display: none;
}
.colaps-app-version, .app-version{
  font-size: 10px;
  color: #BCC1CE;
  font-family: 'Axiforma-Regular';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 20px;
}
.mobileworkspace-version-div
{
  display: none;
}
.iap-submenu:hover .collapse-submenu-child{
  visibility: visible;
}











@media (max-width: 1200px) {
  .profile-panel-entry:hover svg, .profile-panel-entry:hover p{
    color: unset;
    fill: unset;
  }
  .mob-richnotificationli{
    display: block;
  }
  .web-richnotificationli{
    display: none;
  }
 .colaps-app-version{
    display: none;
  }
  .mobileworkspace-version-div
  {
    display: block;
  }
  .workspace-version-div
  {
    display: none;
  }
  .mob-submenudesign{
   
    margin: 15px 10px 15px 20px;
  }
  .mob-submenudesign a{
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    padding: 12px 14px 12px;
    border-radius: 4px;
    margin-bottom: 18px;
    color: #7782A1;
  }
  .mob-submenudesign{
    display: grid;
  }
  .mob-submenudesign a:last-child{
    margin-bottom: 4px;
  }
  .collapse-submenu-child{
    display: none;
  }
  .collapse-submenulink-child{
display: unset;
  }
  .profile-panel-info{
    max-width: unset;
  }
  .overflowwhencolleps::-webkit-scrollbar{
    width: 0px !important;
  }
  .fullwidthspinner{
    margin: 0px;
    height: 100%;
    width: 100%;
  }
  .profile-panel-list {
    overflow-y: auto;
    height: calc(100% - 75px);
    padding-bottom: 100px;
  }
  .profile-panel-entry:hover {
    background-color: unset;
  }
  .profile-panel-entry-help:hover {
    background-color: unset;
  }
  .mob-close-sidebar{
    display: none !important;
  }
  .profile-panel-entry-help {
    padding: 24px 10px 24px 10px !important;
  
  }
  .profile-panel-entry {
    padding: 16px 0px;
  
  }
  .mob-sidebar-header{
    display: block;
    padding: 18px 10px 18px 20px;
    box-shadow: 0px 0px 6px rgba(188, 198,206, 0.36);
    cursor: pointer;
  }
 
  .profile-panel-head h2 {
    font-size: 16px;
    max-width: unset;
    padding: 0px;
  }
  .sidebar-submenu li {
    font-size: 14px;
    padding: 12px 14px 12px;
    width: calc(100% - 10px);
  }
  .profile-panel-entry p {
    font-size: 16px;
    margin-top: 0px;
    line-height: 20px;
     overflow: hidden;
  }
  .profile-panel-entry-help p {
    font-size: 16px;
    margin-top: 0px;
  }
  .thesidebar::before {
    display: none;
  }
  .collapseToggle {
    display: none;
  }
  .thesidebar {
    width: 100%;
    margin-top: 0px;
    z-index: 99999;
  }
  .right-panel-gap {
    width: 100%;
    padding: 0px!important;
    margin-left: 0px;
  }
  .right-panel-gap-expand {
    width: 100%;
    padding: 0px;
    margin-left: 0px;
  }
}
@media (min-width: 1200px) and (min-height:1025px) {

  .profile-panel-animate{
    position: absolute;
    width: 100%;
    bottom: 0px;
  }

}
@media (min-width: 1200px) and (min-height:600px) {
  .workspace-version-div{
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
}
@media (max-width: 1200px) and (min-height:820px) {
  .workspace-version-div{
    position: absolute;
    width: 100%;
    bottom: 90px;
  }
}