.Appcount_android{
    margin-left: 20px !important;
    display: flex;
    align-items: center;
}
.Appcount_android h2.appcount_user-stats-data{
    margin-right: 10px !important;
}
.donut_box {
  margin-top: 0px !important;
}
.appcount_color{
  display: flex;
    align-items: center;
    margin: 0px;
}
.appcount_color svg{
  cursor: pointer;
}
.account_analyticsfirebase{
  align-items: center;
    margin-right: 0px;
}
/* .appcount_color p{
  color: #1B2952 !important;
    display: flex;
    align-items: center;
    position: relative;
    top: 0px;
    margin-bottom: 0px !important;
} */
.appcount-fireimg{
  color: #1B2952 !important;
  display: flex;
  align-items: center;
  position: relative;
  top: 0px;
  margin-bottom: 0px !important;
  margin-right: 30px;
}
.appcount-fireimg1{
  color: #1B2952 !important;
  display: flex;
 
  position: relative;
  top: 2px;
  margin-bottom: 0px !important;
  margin-right: 0px;
  padding-left: 20px;

}
.appcount-fireimg1 span{
  margin-left: 5px ;
}
.account_analyticsfirebase svg{
  margin-right: 6.5px;
}
.appcount_count a{
  margin-left: 0px;
  margin-right: 30px;
  color: #3064f9;
  text-decoration: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.analtics-firebase-btn{
  width: 115px;
     gap: 6px;
     position: relative;
     height: 40px;
     border-radius: 3px;
     background-color: #F8FAFE;
     border: none;
     box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), -3px -3px 3px 0px #ffff;
     justify-content: center;
     align-items: center;
     display: flex;
     cursor: pointer;
     color: #1B2952;
     font-size: 13px;
     font-family: 'Axiforma-Regular';
 }
 .analtics-firebase-btn-text{
     margin-bottom: 0px;
 }



@media screen and (max-width: 1200px) {
  .analtics-firebase-btn{
    margin-right: 0px;
    width: 44px;
    height: 44px;
    position: relative;
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    background-color: #Ffffff;
    border: none;
    box-shadow: 0px 3px 6px 0px #BCC1CE33;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
   }
   .analtics-firebase-btn-text{
       display: none !important;
   }



  .Appcount_android {
    display: grid;
  }
  .appcount-fireimg1{
    border-left: none !important;
  }
  /* .appcount_color{
    justify-content: space-between;
    margin-top: 10px;

  } */
}
@media (min-width: 768px) and (max-width: 1000px){
  .appcount_gplay{
    margin-right: 0px !important
  }
     /* .appcount_color{
    justify-content: space-between;
    margin-bottom: 15px;
    
  } */
}

@media (min-width: 200px) and (max-width: 767px){
  .appcount_analytics{
    justify-content: flex-start !important;
    background-color: #F8FAFE !important;
    display: flex !important;
  }
  .appcount_analytics a{
    margin-left: 20px !important;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
  }
  .appcount_analytic{
    background-color: #F8FAFE !important;
    padding: 0px 10px 0px 15px !important;
  }
  .appcount_color p{
    color: #1b2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
  }
  /* .appcount_color svg{
    margin-right: 6.5px;
  } */
}
