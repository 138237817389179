.newAdvertisment-page {
  background-color: #fcfdff;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.ad-select-tooltip .productsetting-tooltip{
  
  height: 14px;
  display: flex;
}
.newAdvertisment-maindiv .newspinner{
  border-radius: 6px ;
}
.newAdvertisment-head {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0;
  line-height: 24px;

}
.advertisment-parent-input-popup{
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #BCC1CE;
  padding: 15px 15px 15px 15px;
  color: #1b2952;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
  overflow: hidden;
}

.categogy-chip-section.categogy-chip-section-dropdown .dropdown .dropdown-toggle{
   height: 40px!important;
   min-height: 40px!important;
  background-position: 97% 16px!important;
  margin-bottom: 15px;
}
.categogy-chip-section.categogy-chip-section-dropdown .dropdown-menu{
  padding: 5px 0px !important;
}
.categogy-chip-section.categogy-chip-section-dropdown .dropdown-item{
  padding: 10px 0px 10px 10px !important;
  border: 0px solid #fff !important;
  width: calc(100% - 10px);
  margin: auto !important;
}
.categogy-chip-section.categogy-chip-section-dropdown .dropdown-item.active{
  border: 0px solid #fff !important;
    background-color: #F8F9FD !important;
}
.categogy-chip-section.categogy-chip-section-dropdown .dropdown-item:hover{
  border: 0px solid #fff !important;
    background-color: #F8F9FD !important;
}
.categogy-chip-section.categogy-chip-section-dropdown .cms-author p {
 display: flex;
 align-items: center;
}
.advertisment-delete-filter {
  min-width: 40px;
}
/* .categogy-chip-section-dropdown .dropdown-toggle:hover, .categogy-chip-section-dropdown .dropdown-toggle:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
} */

.advertisment-parent-input-popup:hover{
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.no-ad-avalable {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.advertisment-labeldiv .ͼo .cm-gutters {
  background-color: #1B2952;

}
.advertisment-labeldiv .ͼo .cm-content{
  font-family: Axiforma-Regular;
  color: white;
  font-size: 13px;
}
.advertisment-labeldiv .ͼo .cm-activeLineGutter {
  background-color: #1B2952;
}
.advertisment-labeldiv .ͼo {
  background-color: #1B2952;
}

.advertisment-labeldiv .ͼ1 .cm-scroller::-webkit-scrollbar:horizontal{
  height: 8px
}
.no-ad-avalable p {
  font-size: 14px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
  margin-bottom: 0px;
}
.advertisment-tab-setting{
  height: 33px;
    display: flex;
    align-items: center;
    margin-top: 7px;
}
.newAdvertisment-tophead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
 
}
.newAdvertisment-tophead .consent-modal-tab{
  margin-bottom: 20px;
}
.newAdvertisment-topheadright {
  display: flex;
  align-items: center;
  gap: 20px;
}
.newAdvertisment-topheadright p {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #7782a1;
  margin-bottom: 0;
  margin-right: 0;
}
.create-ad-page {
  display: flex;
}
.newAdvertisment-topheadright p span {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #bcc1ce;
  margin-bottom: 0;
  /* margin-right: 20px; */
}

.newAdvertisment-topheadright a{
  
  border-bottom: unset !important; 
  padding-bottom: 0px !important;
  margin: unset !important;

}
.newAdvertisment-topheadright a:hover{

  border-bottom: unset !important; 
  padding-bottom: 0px !important;
  margin: unset !important;

}
.newAdvertisment-invitevtb {
  width: 120px;
  height: 40px;
  border: none;
  background-color: #3064f9;
  font-size: 14px !important;
  color: #fff !important;
  font-family: 'Axiforma-Regular' !important;
  border-radius: 3px;
  transition: 0.5s !important;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  margin-left: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: unset !important;
  padding: 10px;
 
}
.edit-newAdvertisment-tophead{
  margin-bottom: 20px;
}
a.newAdvertisment-invitevtb{
  padding-bottom: 10px !important;
}
a.newAdvertisment-invitevtb:hover{
  padding-bottom: 10px !important;
}
.consent-modal-tab .newAdvertisment-invitevtb {
  color: #fff !important;
}

.newAdvertisment-invitevtb:hover {
  /* background-color: #134bec;
  color: #fff !important; */
  background-color: var(--custom_color5) !important;
  color: var(--custom_color4) !important;
  /* border: 1px solid var(--custom_color6); */
  /* border: unset !important; */
}
.newAdvertisment-invitevtb img {
  margin-right: 5px;
}
.newAdvertisment-maindiv {
  min-height: 79vh;
  background-color: #fff;
  border: 1px solid #e3e6f1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-top: 0px;
  position: relative;
}
.newAdvertisment-upgrademain {
  position: relative;
  /* min-height: calc(100vh - 90px); */
  background-image: url("../../assets/images/dashboard/app-icon-background.png");
  /* justify-content: center; */
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: contain;
  background-attachment: fixed;
  /* background-color: #F8FAFE; */
  min-height: 80vh;
  display: flex;
}
.newAdvertisment-savebtn {
  width: 100px;
  height: 40px;
  /* border: none; */
  border: 1px solid #3064f9;
  font-size: 14px;
  color: #3064f9;
  background-color: transparent;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  transition: 0.5s;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newAdvertisment-upgrade-left {
  width: 44%;
    padding-top: 147px;
    padding-left: 120px;
}
.newAdvertisment-upgrade-right {
  padding-top: 138px;
}
.newAdvertisment-upgrade-left h2 {
  color: #1b2952;
  font-size: 48px;
  font-family: "Axiforma-Bold";
  line-height: 69px;
  margin-bottom: 10px !important;
}
.newAdvertisment-upgrade-left p {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  line-height: 19px;
  margin-bottom: 30px;
  text-align: left !important;
}
.newAdvertisment-upgrade-left ul {
  padding-left: 0 !important;
}
.newAdvertisment-upgrade-left ul li {
  line-height: 17px;
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  padding-left: 15px !important;
  padding-bottom: 17px;
  list-style-type: none;
  position: relative;
}
.newAdvertisment-upgrade-left ul li:before {
  content: "";
  background-color: #bcc1ce;
  position: absolute;
  height: 5px;
  width: 5px;
  left: 0px;
  border-radius: 100%;
  top: 5px;
}
.newAdvertisment-upgrade-left a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  background-color: #3064f9;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-family: "Axiforma-Regular";
  height: 50px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 18px;
  border: 1px solid transparent;
}
.newAdvertisment-upgrade-left a:hover {
  background-color: #0040f9;
  color: #fff !important;
}
.mob-newAdvertisment-head {
  display: none;
}
.ad-create-mob {
  font-family: "Axiforma-Regular";
  color: #3064f9;
  font-size: 15px;
  margin-right: 20px;
  display: none;
  cursor: pointer;
}
.newAdvertisment-mobinvitebtn {
  display: none;
}

.newAdvertisment-teamtable-head {
  display: flex;
    align-items: center;
    /* height: 40.5px; */
    padding: 12px 20px;
    border-bottom: 1px solid #f1f2f8;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    margin: 0;
}
.newAdvertisment-teamtable-head div:first-child {
  width: 49.5%;
}
.newAdvertisment-teamtable-head div:nth-child(2) {
  width: 60%;
}
/* .newAdvertisment-teamtable-head div:nth-child(3) {
    width: 15%;
}
.newAdvertisment-teamtable-head div:nth-child(4) {
    width: 20%;
}
.newAdvertisment-teamtable-head div:nth-child(5) {
    width: 20%;
} */
.newAdvertisment-teamtable-list {
  display: flex;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid #e3e6f1;
  padding: 20px 20px;
  min-height: 108px;
}
.newAdvertisment-teamtable-list:hover {
  background-color: #f8f9fd;
  transition: 0.5s;
}
.newAdvertisment-teammember-img {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 100px;
  margin-right: 11px;
}
.newAdvertisment-user-name {
  display: flex;
  align-items: center;
}
.newAdvertisment-team-email {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.advertisment-table-firstdiv {
  width: 49.5%;
}
.newAdvertisment-team-Role {
  width: 15%;
}
.newAdvertisment-team-seen {
  width: 20%;
}
.newAdvertisment-team-statusmain {
  width: 20%;
}
.newAdvertisment-team-status {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.newAdvertisment-user-namediv {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0;
  margin-bottom: 8px;
  text-align: left !important;
  word-break: break-word;
}
.newAdvertisment-user-iddiv {
  color: #7782a1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0;
  margin-top: 10px;
  text-align: left !important;
}
.newAdvertisment-teammob-label {
  display: none;
}
.newAdvertisment-team-email p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0;
  text-align: left !important;
  margin-left: 10px;
}
.newAdvertisment-team-Role p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0;
  text-align: left !important;
}

.newAdvertisment-team-seendate {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0;
  text-align: left !important;
}

.newAdvertisment-team-seenip {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0;
  margin-top: 10px;
  text-align: left !important;
}
.newAdvertisment-team-status h5 {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0;
  color: #7782a1;
}

.newAdvertisment-team-status span {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #3064f9;
  margin: 10px 0 0;
  cursor: pointer;
  display: none;
  transition: 0.5s;
}
.newAdvertisment-teamtable-list:hover .newAdvertisment-team-status span {
  display: block;
}
.newAdvertisment-team-status svg {
  cursor: pointer;
}
.newAdvertisment-user {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.newAdvertisment-user-active {
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.newAdvertisment-user-active .active {
  color: #47ce70;
  margin-right: 10px;
}
.newAdvertisment-user-active .advertisment-draft {
  color: #7782a1;
  margin-right: 10px;
}
.advertisment-second-row {
  display: flex;
  align-items: center;

}
.newAdvertisment-team-moblastdiv {
  display: none;
}
.create-new-add-left {
  width: 50%;
  padding: 19px 10px 20px 30px;
  height: 80vh;
  overflow: auto;
  overflow-x: hidden;
}

.create-new-add-left::-webkit-scrollbar{
  width: 2px !important;
}
.create-new-add-right {
  width: 50%;
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
}
.advertisment-labeldiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.advertisment-labelselect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  padding: 16px 15px 16px 15px !important;
  height: 50px;
  font-size: 13px;
    font-family: "Axiforma-Regular";
    cursor: pointer;
}

.ads-select-ad-text{
  font-size: 12px;
    color: #3064f9 !important;
    font-family: "Axiforma-Regular";
}

.advertisment-labelselect a {
  font-size: 12px;
  color: #3064f9 !important;
  font-family: "Axiforma-Regular";
}
.advertisment-labelselect p {
  margin-bottom: 0px;
}
.create-new-add-left-top h3 {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 7px;
}
.create-new-add-left-top {
  padding-bottom: 19px;
  border-bottom: 1px solid #e3e6f1;
  margin-bottom: 30px;
}
.create-new-add-left-top p {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: left !important;
}
.advertisment-labeldiv label {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 7px;
}
.advertisment-labeldiv .productsetting-tooltip {
  margin-left: 6px;
}
.advertisment-labeldiv input {
  height: 50px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  color: #1b2952;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  padding: 16px 15px 16px 15px !important;
  width: 100%;
}
.customadd-previewframe .carousel .slide img{
  width: unset !important;
}
.advertisment-labeldiv textarea {
  height: 239px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  color: #ffff;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  padding: 20px 20px 20px 20px !important;
  resize: none;
  background-color: #1b2952;
}
.advertisment-labeldiv input::placeholder {
  color: #bcc1ce;
}
.advertisment-labeldiv input:hover,
.advertisment-labeldiv input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.create-ad-mob-publish,
.create-ad-mob-save {
  display: none;
}

.create-ad-mob-save-disable-build {
  display: none;
}
.new-ad-locationdiv {
 
  border-top: 1px solid #e3e6f1;
  margin-right: 10px;
}
.new-ad-locationdiv-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 30px;
}
.new-ad-locationdiv-head p {
  font-family: "Axiforma-Bold";
  font-size: 16px;
  color: #1b2952;
  margin-bottom: 0px;
  text-align: left !important;
}
.ad-delete-flexdiv {
  display: flex;
  margin-bottom: 15px !important;
}
.ad-delete-btn {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bcc1ce;
  margin-left: 20px;
  border-radius: 3px;
  cursor: pointer;
  min-width: 50px;
}
.advertisment-popup-data-name{
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ad-position-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 25px;
}
.ad-position-div svg {
  margin-left: 30px;
  cursor: pointer;
}
.ad-position-div p svg {
  margin-left: 0px;
}
.new-ad-dropdowndiv label {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px;
  margin-bottom: 7px;
}
.new-ad-dropdowndiv .mobile-view-click {
  margin-bottom: 25px;
  position: relative;
}
.mobile-view-click:hover .profile-role-dropdown__control {
  box-shadow: unset !important;
}
.new-ad-dropdowndiv .mobile-view-click .new-blue-loader{
  position: absolute;
  top: 48px;
  z-index: 3;
  left: 20px;
}
.ad-position-div p {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.ad-andiv {
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 15px;
}
.ad-addlocation-new {
  font-size: 13px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  cursor: pointer;
  padding-top: 10px;
}
.profile-role-dropdown__option{
  cursor: pointer !important;
}
/* build-setting-page */
.adbuild-setting-page {
  padding: 20px 30px;
}
.adbuild-setting-pagehead {
  border-bottom: 1px solid #e3e6f1;
}
.adbuild-setting-android {
  border-bottom: 1px solid #e3e6f1;
  /* padding: 30px 0px; */
}
.adbuild-setting-ios {
  /* padding: 30px 0px; */
  border-bottom: 1px solid #e3e6f1;
}
.adbuild-setting-pagehead h2 {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}

.adbuild-setting-pagehead p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 17px;
  text-align: left !important;
}
.adbuild-setting-android-head {
  display: flex;
  align-items: center;
}
.adbuild-setting-bundle {
  display: flex;
  align-items: center;
}
.adbuild-setting-androidtop {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}
.adbuils-setting-toggle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}
.adandroid-build-setting-div {
  width: 50%;
}
.adbuild-setting-googleinput {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 10px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.adbuild-setting-googleinput svg {
  cursor: pointer;
}

.adbuild-setting-inputfirstdiv p {
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 8px;
}
.adbuild-setting-inputfirstdiv .documentdiv {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  background-color: #edf0f5;
  border-radius: 3px;
  justify-content: center;
  cursor: pointer;
}
.adbuild-setting-inputfirstdiv svg {
  margin: 0px !important;
  cursor: pointer;
}
.adbuild-setting-inputfirstdiv {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.adbuild-setting-android-icon {
  padding: 13px 15px;
  background-color: #f8fafe;
  border-radius: 3px;
  margin-right: 20px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adbuild-setting-android-head p {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  color: #1b2952;
}
.adbuild-setting-bundle p {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-right: 6px;
  text-align: left !important;
}
.adbuild-setting-bundle p span {
  color: #1b2952;
}
.adbuild-setting-bundleright {
  display: flex;
  margin-right: 35px;
  align-items: center;
}
.adbuils-setting-toggle h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.adbuils-setting-toggle svg {
  margin-left: 7px;
}
.adbuild-setting-googlefield {
  margin-bottom: 30px;
  /* margin-top: 30px; */
}
.adbuild-setting-googlefield h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  /* margin-right: 10px; */
  margin-bottom: 9px;
}
.adbuild-setting-googlefield svg {
  margin-left: 7px;
}
.adbuild-setting-text h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  /* margin-right: 10px; */
  margin-bottom: 9px;
}
.adbuild-setting-text svg {
  margin-left: 7px;
}
.adbuild-setting-text textarea {
  width: 100%;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  resize: none;
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  outline: none;
  padding: 15px 16px;
}
.adbuild-setting-text textarea:hover,
.adbuild-setting-text textarea:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.admobadbuild-setting-bundleright {
  display: none;
}
.ad-setting-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;

}
.ad-setting-page h3 {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px;
  margin-bottom: 0px;
}
.ad-setting-page svg {
  margin-left: 6px;
}
.ad-save-date {
  display: none;
}
.ad-page-setting {
  width: 134px;
  height: 40px;
  border-radius: 3px;
  color: white;
  background-color: #f8fafe;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.2), 0px 0px 3px 3px #ffff;
  /* margin-right: 20px; */
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: 6px;
}
.ad-setting-clicked {
  width: 134px;
  height: 40px;
  border-radius: 3px;
  color: white;
  background-color: #f8fafe;
  border: none;
  box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset;
  /* margin-right: 20px; */
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: 6px;
}
.ad-notselect-setting {
  fill: white !important;
  stroke: #7782a1;
}
.ad-select-setting {
  fill: #7782a1;
  stroke: #7782a1;
}
.mob-ad-page-setting {
  display: none;
}
.ad-advancesearch_takethere {
  background: transparent;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  color: white;
  cursor: pointer;
  border-radius: 4px;
}
.toggle-advertisment-right {
  margin-right: 0px !important;
}
.ad-select-modal {
  max-width: 1000px !important;
}
.ad-select-modal .modal-body {
  padding: 22px 30px 30px 30px;
}
.ad-modal-head h3 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  line-height: 23px;
  margin-bottom: 6px;
}
.ad-modal-head p {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px;
  margin-bottom: 0px;
  text-align: left !important;
}
.ad-type-select-main {
  display: flex;
  margin-top: 32px;
}

.ad-select-title-type h2 {
  font-family: "Axiforma-Regular";
  color: #1b2952;
  font-size: 13px;
  margin-bottom: 8px;
}
.ad-select-title-type {
  text-align: center;
  margin-top: 21px;
  margin-bottom: 5px;
}
.ad-select-title-type p {
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 10px;
  margin-bottom: 0px;
}
.ad-select-banner-type {
  width: 25%;
  border: 1px solid #e3e6f1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  margin-right: 20px;
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
}
.ad-select-banner-type:hover {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.ad-selected-banner{
    border: 1px solid #3064f9 !important;
 
  transition: 0.5s;
}
.ad-select-banner-type:last-child {
  margin-right: 0px;
}
.ad-select-tooltip {
  text-align: right;
  display: flex;
    justify-content: end;
}
.modal-ad-image-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ad-crossmodal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.ad-select-modal .modal-header {
  padding: 0px;
  height: 0px;
}
.ad-type-select-main-first {
  display: flex;
}
.ad-type-select-main-second {
  display: flex;
}
.ad-select-modal .modal-header svg {
  display: none;
}
.ad-advance_searchmargin {
  margin-right: 0px !important;
  /* padding-left: 16px !important; */
  width: 300px;
  border: 1px solid #e3e6f1;
  height: 40px;
  justify-content: space-between;
  border-radius: 3px;
  align-items: center;
  background-color: white;
  display: flex;
}

.ad-advance_searchmargin input {
  margin-bottom: 0px;
  width: 100%;
  height: 40px;
}
.ad-search-btn-main {
  /* border-left: 1px solid #e3e6f1 !important; */
  padding-left: 3.8px;
  padding-right: 3.5px;
  padding-top: 3px;
  width: 44px;
  background: none;
  border: none;
  height: 100%;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad-advance_searchmargin input {
  font-size: 13px !important;
  width: 100%;
  border: none;
  height: 40px;
  background-color: transparent;
  outline: none !important;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  padding: 12px 0px 12px 0px !important;
}
.mob-newAdvertisment-topheadright {
  display: none;
}
.ad-header-type {
  display: flex;
  align-items: center;
}
.newAdvertisment-topheadright .ad-header-type-name {
  font-size: 12px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  margin-left: 6px;
  margin-right: 20px !important;

}
.newAdvertisment-topheadright .ad-header-type-name svg {
  margin-left: 6px;
}
.create-new-add-navigate-left {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.14);
  margin-right: 30px;
  cursor: pointer;
  justify-content: center;
}
.create-new-add-navigate-right {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.14);
  /* margin-right: 30px; */
  cursor: pointer;
  justify-content: center;
}
.create-new-add-navigate {
  display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    /* margin: auto; */
    width: 100%;
    top: 20px;
}

.ad-previewtopbackopacity {
  opacity: 0.4;
  cursor: auto !important;
}
.ad-mob-preview-frame {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border: 4px solid #e3e6f1;
  width: 310px;
  height: 659px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  /* padding: 30px 16px; */
  position: relative;
  margin: auto;
  margin-top: 20px;
  background-color: #eeeeee;
}
.first-ad-top {
  background-color: white;
  display: flex;
  height: 48px;
  width: 235px;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  position: absolute;
  top: 39px;
  right: 0px;
  padding: 8px 11px 8px 8px;
}
.first-ad-top-div {
  display: flex;
  align-items: center;
}
.first-ad-top-div p {
  font-size: 10px;
  color: #333333;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-left: 10px;
}
.first-ad-top-div-img {
  width: 32px;
  height: 32px;
  background-color: #ebebeb;
  border-radius: 3px;
}
.first-ad {
  width: 100%;
  height: 100%;
}
.first-ad-last {
  height: calc(100% - 104px);
  position: relative;
  top: 104px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 9px 11px 11px 11px;
}
.ad-first-open-title {
  font-family: "Axiforma-Regular";
  color: #333333;
  font-size: 10px;
  margin-bottom: 9px;
  display: flex;
  justify-content: center;
}
.ad-first-open-text-div {
  height: calc(100% - 24px);
  border-radius: 6px;
  background-image: linear-gradient(#8f2dff, #ff486d);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad-first-open-text {
  font-size: 12px;
  color: #ffff;
  font-family: "Axiforma-Regular";
  opacity: 0.5;
  margin-bottom: 0px;
}
.ad-first-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 8px;
  margin-bottom: 0px;
  height: 12px;
  width: 20px;
  background-color: #f6b033;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-ad {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 11px;
}
.second-ad-div-main {
  height: 500px;
  position: relative;
  background-image: linear-gradient(#8f2dff, #ff486d);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.second-ad-div-cross {
  height: 20px;
  width: 20px;
  border: 1px solid #ffffff;
  display: flex;
  border-radius: 20px;
  opacity: 0.5;
  top: 10px;
  right: 10px;
  position: absolute;
}
.second-div-ad {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 8px;
  margin-bottom: 0px;
  height: 12px;
  width: 20px;
  background-color: #f6b033;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-ad-div-text {
  font-size: 12px;
  color: #ffff;
  font-family: "Axiforma-Regular";
  opacity: 0.5;
  margin-bottom: 0px;
}
.third-ad-div {
  /* display: flex; */
  /* background-color: #fff; */
  /* background-image: url(../../assets/images/dashboard/adthird-backimg.png); */
  /* background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 20px;
  justify-content: center;
  margin: 0px !important; */
  position: relative;
  /* background-size: contain;
  height: 100%; */
}
.every-ad-img {
  /* display: flex; */
  background-color: #fff;
  /* background-image: url(../../assets/images/dashboard/advertisement/webviewadd.png); */
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 20px;
  justify-content: center;
  margin: 0px !important;
  position: relative;
  background-size: contain;
  height: 100%;
}
.every-ad-img img{
  width: 100%;
  height: 649px;
}
.fourth-ad-div {
  /* display: flex; */
  background-color: #fff;
  background-image: url(../../assets/images/dashboard/adfourth-backimg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 20px;
  justify-content: center;
  margin: 0px !important;
  position: relative;
  background-size: contain;
  height: 100%;
}
.third-ad-text {
  width: 100%;
  height: 44px;
  position: absolute;
  background-image: linear-gradient(#8f2dff, #ff486d);
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 54px; */
}
.ad-page-body iframe{
  margin: 0px !important;
  width: 100% !important;
}
.ad-no-width-class img{
  width: unset !important;
}
.third-ad-text-withoutabsolute{
  position: unset !important;
  margin: 10px 0px 10px 0px;
}
.fourth-ad-text-withoutabsolute{
  position: unset !important;
  margin: 10px 0px 10px 0px;
}
.third-ad-text p {
  font-size: 12px;
  color: #ffff;
  font-family: "Axiforma-Regular";
  opacity: 0.5;
  margin-bottom: 0px;
}
.third-ad-textdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
}

.fourth-ad-text {
  width: 100%;
  /* height: 88px; */
  height: 44px;
  position: absolute;
  /* background-image: linear-gradient(to top right, #66B9FF, #6865FF); */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  /* top: 54px; */
}
.bottomadd-div{
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.fourth-ad-text p {
  font-size: 12px;
  color: #ffff;
  font-family: "Axiforma-Regular";
  opacity: 0.5;
  margin-bottom: 0px;
}
.fourth-ad-textdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad-with-padding {
  width: 95% !important;
}

.ad-diasable-btn {


  width: 120px;
  height: 40px;
  border: none;

  font-size: 14px !important;

  font-family: 'Axiforma-Regular' !important;
  border-radius: 3px;
  transition: 0.5s !important;
  line-height: 40px;
  text-align: center;
  margin-left: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: unset !important;
  padding-bottom: 0 !important;
  color: white !important;
  background-color: #bcc1ce !important;
  cursor: not-allowed !important;
  border: none !important;

}
.ad-diasable-save-btn {
  width: 100px;
  height: 40px;
  border: none;
  font-size: 14px !important;
  font-family: 'Axiforma-Regular' !important;
  border-radius: 3px;
  transition: 0.5s !important;
  line-height: 40px;
  text-align: center;
  margin-left: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bcc1ce !important;
  background-color: transparent !important;
  cursor: not-allowed !important;
  border: 1px solid #bcc1ce !important;
  padding-bottom: 0 !important;
  cursor: not-allowed !important;


}
.advertisment-divmain{
  margin-right: 10px;
  margin-bottom: 30px;
}
.selcted-ad-alignment path{
 stroke: #7782a1;
}
.selcted-ad-alignment rect{
  fill: #7782a1;
 }
.ad-alignment path{
stroke: #e3e6f1;
}
.ad-alignment rect{
  fill: #e3e6f1;
}
.ad-mob-preview-frame .posts-preview-list-box, .ad-mob-preview-frame .pages-preview-list-box, .ad-mob-preview-frame .page-preview-screen{
  height: 643px;
  width: unset !important;
  background-color: #fff;
  margin: auto;
  filter: unset !important;
  overflow-y: auto;
  border:unset !important;
  border-radius: 20px;
}

 .ad-mob-preview-frame .carousel-status{
  display: none !important;
 }

 .ad-mob-preview-frame .slider-wrapper{
  width: 100%;
}
.ad-mob-preview-frame .slider{
  background-color: unset;

}

.ad-mob-preview-frame .control-dots{
  display: none;
}
.ad-mob-preview-frame .slider:before{
  display: none !important;
}
.ad-mob-preview-frame p{
text-align: left ;
}


.customadd-previewframe .carousel-status{
  display: none !important;
 }

 .customadd-previewframe .slider-wrapper{
  width: 100%;
}
.customadd-previewframe .slider{
  background-color: unset;

}

.customadd-previewframe .control-dots{
  display: none;
}
.customadd-previewframe .slider:before{
  display: none !important;
}


.ad-save-date-web {
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  margin-bottom: 0;
  margin-right: 20px;
}
.page-preview-screen .create-post-title h1{
  text-align: left;
}
.page-preview-screen .post-detail-author h4{
  text-align: left;
}
.page-preview-screen .headings{
  text-align: left;
}
.ad-mob-preview-frame .cms-banner-img img{
  width: unset !important;
}

.customadd-previewframe{
  background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border: 4px solid #e3e6f1;
    width: 302px;
    height: 651px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    margin-top: 20px;
    background-color: #ffff;
    overflow: hidden;
}
.customadd-webviewback{
  background-color: #F9F9F9 !important;
}

.customad-bottomad{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #ffff;
}

.customad-previewbody{
  padding: 5px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-height-text-right{
  position: absolute;
  right: 30px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  top: 14px;
  color: #bcc1ce;
  z-index: 2;
}
.advertisment-labeldiv .productsetting-tooltip svg{
  cursor: pointer;
}
.advertisment-popup-tooltip{
  width: 230px !important;
  top: 23px !important;
  left: unset !important;
  padding: 14px 14px 14px 14px !important;
  right: -29px;
}

.advertisment-popup-tooltip::after{
  top: -8px !important;
  right: 14% !important;
  border-color: transparent transparent black transparent !important;
}


/* popup */

.advertisment-secondmodal .modal-body{
  padding: 13px 60px 60px 60px;
}
.advertisment-secondmodal-crossmodal{
  position: absolute;
  top: 0px;
  right: 12px;
  cursor: pointer;
  height: 14px;
  width: 14px;
}
.advertisment-popup-maindiv p{
  font-size: 14px;
  color: #7782A1;
  text-align: center;
  font-family: 'Axiforma-Regular';
  margin-top: 18px;
  margin-bottom: 40px;
  word-break: break-word;
}
.advertisment-popup-head{
  display: flex;
  align-items: center;
  justify-content: center;
}
.advertisment-popup-head h2{
  font-size: 18px;
  font-family: 'Axiforma-Bold';
  color: #1B2952;
  text-align: center;
  line-height: 25px;
  margin-bottom: 0px;
 
}
.advertisment-popup-head svg{
  margin-right: 6px;
}
.advertisment-popup-bottom{
  display: flex;
  align-items: center;
  justify-content: center;
}
.advertisment-popup-cancel{
  width: 140px;
  height: 44px;
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  color: #7782A1;
  background-color: #ffff;
  font-family: 'Axiforma-Regular';
  font-size: 14px;
  margin-right: 20px;
}
.advertisment-popup-confirm{
  width: 140px;
  height: 44px;
  border-radius: 3px;
  color: #fff;
  background-color: #3064F9;
  font-family: 'Axiforma-Regular';
  font-size: 14px;
  border: none;
  transition: 0.5s;
}
.advertisment-popup-confirm:hover{
  background-color: #134bec;
}

.advertisment-popup-deletebtn{
  width: 140px;
  height: 44px;
  border-radius: 3px;
  color: #ff4848;
  border: 1px solid #ff4848;
  background-color: white;
  font-family: 'Axiforma-Regular';
  font-size: 14px;
 
  transition: 0.5s;
}
.advertisment-popup-deletebtn:hover{
  background-color: #ff4848;
  color: #ffff;
}
.advertisment-secondmodal-crossmodal{
  position: absolute;
  top: 0px;
  right: 12px;
  cursor: pointer;
  height: 14px;
  width: 14px;
}
.advertisment-custom-size{
  display: flex;
  justify-content: space-between;
}
.advertisment-custom-size .advertisment-custom-size-div{
  width: 49%;
}
.advertisment-mob{
  display: none;
}
.third-ad-text-withbottom-fixed{
  bottom: 0px;
  margin: 0px 0px 2px 0px;
  position: fixed;
}
@media (min-width: 1921px) {
  .newAdvertisment-upgrademain {
      justify-content: center;
  }
  .newAdvertisment-upgrade-left {
    width: unset;
    padding-right: 30px;
  }
}
@media (max-width: 1730px) {
  .newAdvertisment-upgrade-left {
    padding-left: 30px;
  }
}
@media (max-width: 1200px) {
  .ad-save-date-web{
 display: none;
  } 
  .ad-setting-clicked {
    width: 40px;
  }
  .newAdvertisment-user-namediv {
    font-size: 14px;
    line-height: 18px;
}
  .adbuild-setting-pagehead h2 {
    margin-bottom: 4px;
}
.ad-search-btn-main img{
 width: 16px
}
.newAdvertisment-topheadright {
  margin-top: -12px;
}
.advertisment-divmain{
  margin-right: unset;
}
.advertisment-mob{
  display: block;
}
.advertisment-web{
  display: none;
}
.advertisment-popup-deletebtn{
  width: 280px;
  background-color: #ff4848;
  color: #fff;
  height: 50px;
  font-size: 16px;
}
.edit-newAdvertisment-tophead {
  margin-bottom: 15px !important;
  height: unset !important;
}
/* .advertisment-labeldiv {
  margin-bottom: 0px;
} */
.adbuild-setting-googlefield {
  margin-bottom: 30px;
}
  .newAdvertisment-topheadright .ad-header-type-name {
    margin-right: 0px !important;
  
  }
  .create-ad-mob-save-disable-build {
    display: block;
    /* border: 1px solid #3064F9; */
    border-radius: 3px;
    height: 50px;
    background-color: #bcc1ce !important;
    /* margin: 20px 0px; */
    font-family: "Axiforma-Regular";
    color: #fff;
    font-size: 16px;
    border: none;
    transition: 0.5s;
    cursor: not-allowed;
}

  .analytics-post-filter .post-filter__control{
    height: 50px !important;
  }
  .analytics-post-filter-dropdown .dropdown-toggle{
    height: 50px;
  }
  .post-filter-dropdown  .analytics-post-filter .post-filter__single-value{
    font-family: 'Axiforma-Regular' !important;
}
  .advertisment-delete-filter {
    min-width: 50px;
    height: 50px;
    width: 50px;
  }
  .categogy-chip-section.categogy-chip-section-dropdown .dropdown .dropdown-toggle {
    height: 50px !important;
    min-height: 50px !important;
    background-position: 97% 21px !important;
}
  .advertisment-custom-size {
    display: unset;
    justify-content: unset;
}
.advertisment-custom-size .advertisment-custom-size-div {
  width: 100%;
}
.advertisment-custom-size .advertisment-custom-size-div:first-child {
  margin-bottom: 20px;
}
  /* popup */
  .advertisment-secondmodal .modal-body {
    padding: 0px 20px 0px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.advertisment-popup-bottom {
  flex-direction: column-reverse;
}
.advertisment-popup-confirm{
  width: 280px;
  height: 50px;
  font-size: 16px;
  border: none;
}
.advertisment-popup-cancel{
  width: unset;
  height: 24px;
  margin-top: 36px;
  border: unset;
  font-size: 16px;
  margin-right: 0px;
}

.advertisment-secondmodal-crossmodal {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
/* popup */




  .advertisment-popup-tooltip {
    left: unset !important;
    right: -29px;
    width: 190px !important;

}
.advertisment-popup-tooltip::after {
 transform: unset !important;
 top: -3px !important;
}
  .create-new-add-left-top h3 {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 16px;
}
  .newAdvertisment-head {
    font-size: 20px;
    line-height: 24px;
  }

  .newAdvertisment-topheadright .ad-create-mob {
    margin-right: 30px !important;
    color: #3064f9;
  }
  .newAdvertisment-topheadright .ad-create-mob:hover {
    margin-right: 30px !important;
    color: #3064f9;
  }
  .newAdvertisment-tophead {
    align-items: center;
    margin-bottom: 10px;
  }
  .newAdvertisment-maindiv .newspinner{
    height: 80vh;
    border: 1px solid #e3e6f1;
    box-shadow: 0 4px 6px rgba(0,0,0,.06);
    border-radius: 6px ;
  }
  .ad-header-type-name {
    margin-right: 0px !important;
  }
  .create-new-add-right {
    display: none;
  }
  .ad-advancesearch_takethere {
    border: 1px solid #e3e6f1;
    width: 44px;
    min-width: 44px;
    height: 44px;
    margin-left: 10px;
    margin-right: 0px;
    position: relative;
    background-color: white;
  }
  .ad-mob-setting-icon {
    /* margin-right: 0px !important; */
    /* margin-right: 20px !important; */
  }
  .ad-select-modal .modal-content{
    position:  relative !important;
  }
  .mob-newAdvertisment-topheadright {
    display: flex;
    margin-bottom: 13px;
    margin-top: 15px;

  }
  .ad-advance_searchmargin input {
    height: 44px;
  }
  .ad-advance_searchmargin {
    width: 100%;
    height: 44px;
  }
  .ad-type-select-main {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ad-mob-margin-right {
    margin-right: 0px !important;
  }
  .ad-select-banner-type {
    width: 49%;
    margin-right: 0px;
    margin-bottom: 10px;
    background-color: #fff;
    position: relative;
  }
  .ad-select-modal .modal-body {
    padding: 15px;
    background-color: #f8fafe;
  }
  .ad-select-modal .modal-header {
    height: 60px;
    padding: 0px 21px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
  }
  .ad-select-modal .modal-header svg {
    margin-bottom: 0px !important;
    display: block;
  }
  .ad-crossmodal {
    display: none;
  }
  .ad-select-modal {
    max-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
  }
  .mob-ad-page-setting {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #f8fafe;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.2), 0px 0px 3px 3px #ffff;
    /* margin-right: 20px; */
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .ad-create-mob {
    display: block;
  }
  .web-newAdvertisment-head {
    display: none;
  }
  .newAdvertisment-savebtn {
    display: none;
  }
  .mob-newAdvertisment-head {
    display: block;
  }
  .ad-setting-page {
    padding: 0px 15px 30px;
    margin-right: 0px;
  }
  .ad-save-date {
    font-family: "Axiforma-Regular";
    color: #bcc1ce;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .adbuild-setting-page {
    border: 1px solid #e3e6f1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-color: #fff;
  }
  .adbuild-setting-page {
    padding: 0px;
  }
  .adandroid-build-setting-div {
    width: 100%;
  }
  .adbuild-setting-pagehead {
    padding: 25px 15px 0px 15px;
  }
  .adbuild-setting-android {
    border-bottom: 1px solid #e3e6f1;
    padding: 0px 15px;
  }
  .adbuild-setting-ios {
    padding: 0px 15px;
  }
  .adbuild-setting-androidtop {
    padding: 20px 0px;
}
  .admobadbuild-setting-bundleright {
    display: block;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .webadbuild-setting-bundleright {
    display: none;
  }
  .adbuild-setting-android-icon {
    padding: 5px;
    background-color: unset;
    border-radius: 3px;
    margin-right: 20px;
    width: unset;
  }

  .new-ad-locationdiv {
    padding: 0px 15px 25px 15px;
    margin-right: 0px;
  }
  .create-ad-mob-publish {
    display: block;
    /* border: 1px solid #3064F9; */
    border-radius: 3px;
    height: 50px;
    background-color: #3064f9;
    /* margin: 20px 0px; */
    font-family: "Axiforma-Regular";
    color: #fff;
    font-size: 16px;
    border: none;
    transition: 0.5s;
  }
  .create-ad-mob-publish:hover {
    background-color: #134bec;
  }
  .create-ad-mob-save {
    display: block;
    border: 1px solid #3064f9;
    border-radius: 3px;
    height: 50px;
    background-color: white;
    margin: 19px 0px;
    font-family: "Axiforma-Regular";
    color: #3064f9;
    font-size: 16px;
    background-color: transparent;
  }
  .create-ad-page {
    display: flex;
    flex-direction: column;
  }
  .create-new-add-left-top {
    padding: 24px 15px;
    margin-bottom: 20px;
  }
  .advertisment-divmain {
    padding: 0px 15px 10px;
    margin-bottom: 0px;
  }
  .advertisment-labeldiv {
    margin-bottom: 12px;
}
  .create-new-add-left {
    width: 100%;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #e3e6f1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    /* margin-top: 20px; */
    height: unset;
    overflow: unset;
  }
  .create-ad-mob-save-disable{
    margin-top: 20px;
    margin-bottom: 20px;
    color: #bcc1ce ;
    background-color: transparent ;
    cursor: not-allowed !important;
    border: 1px solid #bcc1ce ;
    font-size: 16px;
    height: 50px;
    border-radius: 3px;
    font-family: 'Axiforma-Regular';
  }
  .create-ad-mob-publish-disable{
    margin-bottom: 20px;
    color: white;
    background-color: #bcc1ce;
    cursor: not-allowed;
    border: none ;
    border-radius: 3px;
    font-family: 'Axiforma-Regular';
    font-size: 16px;
    height: 50px;
  }
 .ad-first-disable{
  margin-top: 30px;
  margin-bottom: 20px;
  color: white !important;
  background-color: #bcc1ce !important;
  cursor: not-allowed !important;
  border: none !important;
 }
  .newAdvertisment-user-active {
    font-size: 14px;
    margin-top: 0px;
  }
  .web-newadvertisment-user {
    display: none !important;
  }
  .newAdvertisment-user {
    margin-left: 10px;
  }
  .advertisment-svgdiv {
    display: none;
  }

  .newAdvertisment-team-email {
    width: unset;
    display: grid;
    border-bottom: 1px solid #e3e6f1;
    padding: 20px 15px 19px 15px;
    /* justify-content: space-between; */
  }
  .newAdvertisment-team-moblastdiv {
    display: flex;
    padding: 18px 15px 22px 15px;
    justify-content: space-between;
    align-items: center;
  }
  .advertisment-table-firstdiv {
    width: 100%;
    border-bottom: 1px solid #e3e6f1;
    padding: 18px 15px 19px 15px;
  }
  .newAdvertisment-invitevtb {
    display: none;
  }
  .newAdvertisment-mobinvitebtn {
    width: 100%;
    height: 50px;
    border: none;
    background-color: #3064f9;
    font-size: 16px;
    color: #fff;
    font-family: "Axiforma-Regular";
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    /* margin-left: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
  }
  .newAdvertisment-mobinvitebtn:hover {
    background-color: #134bec;
  }
  .newAdvertisment-mobinvitebtn img {
    margin-right: 8px;
  }

  .newAdvertisment-maindiv {
    min-height: unset;
    background-color: unset;
    border: unset;
    box-shadow: unset;
  }
  .newAdvertisment-upgrademain {
    position: relative;
    background-color: #fff;
    border: 1px solid #e3e6f1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-image: unset;
    justify-content: unset;
    display: grid;
  }

  .newAdvertisment-page .right-panel-gap {
    padding: 19px 15px 20px !important;
  }
  .newAdvertisment-upgrade-left {
    width: 100%;
    padding: 21px 19.5px 10px 20px;

  }
  .newAdvertisment-upgrade-left h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px !important;
  }
  .newAdvertisment-upgrade-left p {
    margin-bottom: 20px;
    line-height: 14px;
  }
  .newAdvertisment-upgrade-left a {
    width: 100%;
    font-size: 16px;
    margin-top: 14px;
  }
  .newAdvertisment-upgrade-left ul li {
    font-size: 13px;
    padding-bottom: 12px;

  }
  .newAdvertisment-upgrade-right {
    padding-top: 0px;
    display: flex;
    justify-content: center;
  }
  .newAdvertisment-teamtable-head {
    display: none;
  }
  .newAdvertisment-teamtable-list {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #e3e6f1;
    display: grid;
    align-items: center;
    justify-content: unset;
    border-bottom: 1px solid #e3e6f1;
    padding: 0px;
    margin-bottom: 14px;
  }
  .newAdvertisment-teammob-label {
    display: block;
    font-size: 12px;
    color: #bcc1ce;
    font-family: "Axiforma-Regular";
    margin-bottom: 11px;
  }
.advertisment-svgmob{
  display: flex;
  align-items: center;
}
  .newAdvertisment-team-status span {
    display: block;
    margin: 5px 0 0;
  }
  .newAdvertisment-teamtable-list:hover {
    background-color: white;
  }

  /* .newAdvertisment-user-name {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: unset;
    border-bottom: 1px solid #E3E6F1;
    padding: 24px 15px;
} */
  .newAdvertisment-team-statusmain {
    width: unset;
    padding: 21px 15px 24px;
  }
  .newAdvertisment-teammember-img {
    margin-right: 0px;
  }
  .newAdvertisment-user-iddiv {
    margin-top: 5px;
  }
  .newAdvertisment-team-seenip {
    font-size: 14px;
    margin-top: 5px;
  }
  .ad-select-modal .modal-title {
    height: 60px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 360px){
.ad-select-banner-type {
    width: 100%;
}
}
@media (max-width: 400px){
  .newAdvertisment-topheadright .ad-create-mob {
  margin-right: 15px !important;
}
.newAdvertisment-topheadright .ad-create-mob:hover {
  margin-right: 15px !important;
}

}