.menuview {
  max-width: 296px;
  height: 640px;
  position: relative;
  box-shadow: 3px 7px 43px 14px rgb(0 0 0 / 8%);
  margin: 45px auto;
  background-color: #ffffff;
}
.wpicon{
  margin-right: 20px !important;
}
.emaildiv{
  margin-top: 20px;
}
.emailborder{
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0px;
}
.emailbox{
  background-color: #FFFFFF;
    height: 79px;
}
.webViewdropdown .dropdown-toggle{
  margin-bottom: 0px !important;
}
.page-setting-icon-margin-left{
  margin-left: 20px;
}
.calldiv{
  border-radius: 0px;
  /* border-top: 0px; */
  border-bottom: 0px;
}
.sharediv{
  border-radius: 0px 0px 4px 4px;
}
.menuiconclass{
  display: flex;
}

.uploadiconhead{
  width: 24px;
  height: 24px;
  background-color: #F8FAFE;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.uploadiconhead img{
  min-width: 22px;
  height: auto;
  margin-right: 0.5rem !important;
}
.menuiconclass h5{
  font-size: 16px;
  line-height: 21px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0;
  height: 15px;
}

.dropdownicon{
  margin-right: 20px;
}

.iconform{
  padding: 0 30px 30.5px 30px;
}

.iconform input{
  background-color: #F8FAFE;
  height: 50px;
}
/* .iconform .iconinput{
  background-image: url("../../assets/images/dashboard/email_icon.png");
} */
.iconform label{
  font-size: 13px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
}
.iconform input{
  font-size: 13px;
  padding-left: 15px !important;
  outline: none;
}

.menudoticon{
  margin-right: 10.7px;
  height: 14.8px;
  margin-top: 4px;
  width: 8.33px;
}

.goBack{
  height: 16.1px;
    margin-right: 40px;
}
.headpading{
  padding: 0px !important;
}
.iconnamediv{
  height: 50px;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #F8FAFE;
}
/* .menudoticon{
  display: none;
} */
.uploadiconpng{
  margin-top: 5px;
  margin-left: 6px;
  height: 38px;
  width: 38px;
}
.iconstable{
  padding: 5px 20px 0 4px;
}
.iconselectscreen{
  padding: 30px 0 30px !important;
}

.iconimagespan{
  display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 38px;
    height: 38px;
    border-radius: 3px;
}
.iconimagespan img{
  height: 19.04px;
    width: 25px;
}

.iconName{
  font-size: 14px;
  color: rgb(119, 130, 161);
  margin-left: 13px !important;
  margin-bottom: 0px;
}
.menubuilder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  border-bottom: 1px solid #f2f2f2;
}

.menubuilder-body {
  margin-top: 21px;
  min-height: 525px;
}
.menubuilder-body-preview {
  height: 601px;
  overflow-y: auto;
}

ul.menu-list-view,
ul.static-list {
  list-style: none;
  padding-left: 15px;
  padding-right: 15px;
}

.menu-list-view {
  margin-bottom: 20px;
}

.menu-list-view li,
ul.static-list li {
  font-size: 12px;
  font-family: "Poppins-Regular";
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.menu-list-view li:last-child {
  margin-bottom: 0px;
}

ul.static-list li {
  font-weight: normal;
}

.menubuider-bottom {
  padding: 20px 15px;
  width: 100%;
}

.menubuilder-modal-header h5 {
  font-size: 16px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  line-height: 21px;
  margin-bottom: 0;
}
.menubuider-bottom p {
  font-size: 10px;
  font-family: "Axiforma-Regular";
  color: #a1a1a1;
  margin-bottom: 0;
}

.menubuilder-modal-header {
  padding: 22px 20px 27px 20px !important;
  border-bottom: 1px solid #e3e6f1 !important;
}
.menu-modal-body {
  min-height: 500px;
}

.menu-build-bottom {
  border-top: 1px solid #e3e6f1 !important;
  padding: 15px 20px;

  justify-content: space-between !important;
}

.modal-left-side ul,
.menu-option-list ul,
.dynamic-list ul {
  list-style: none;
  padding-left: 0;
}
.dynamic-list .menu-builder-l2 {
  position: relative;
  height: 385px;
  overflow-y: scroll;
  padding: 0px;
}

.modal-left-side li a {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  padding: 7px;
  display: inline-block;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  min-width: 160px;
  transition: 0.5s;
}
.modal-left-side ul li {
  margin-bottom: 10px;
  text-align: center;
}
.modal-left-side ul li.active a,
.modal-left-side ul li a:hover {
  color: #3064f9;
  border: 1px solid #3064f9;
  transition: 0.5s;
}
.card-num.menu-name {
  padding: 0;
}
.custom-radio-btn.menubuilder-radio-btn:hover {
  box-shadow: none;
  background-color: #f1f4ff !important;
  transition: all ease 0.3s;
}
.custom-radio-btn.menubuilder-radio-btn input[type="radio"]:checked + label,
.custom-radio-btn.menubuilder-radio-btn
  input[type="radio"]:not(:checked)
  + label {
  padding-right: 75px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.custom-radio-btn.menubuilder-radio-btn [type="radio"]:checked + label:before,
.custom-radio-btn.menubuilder-radio-btn
  [type="radio"]:not(:checked)
  + label:before {
  right: 21px !important;
}
.custom-radio-btn.menubuilder-radio-btn [type="radio"]:checked + label:after,
.custom-radio-btn.menubuilder-radio-btn
  [type="radio"]:not(:checked)
  + label:after {
  right: 21px !important;
}

.menu-option-list {
  position: relative;
  height: 460px;
  overflow-y: auto;
}

.menu-option-list:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #e3e6f1;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
}

.dynamic-list a {
  text-align: center;
  display: flex;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  width: 140px;
  height: 36px;
  color: #7782A1;
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

/* Bottom Button */

button.menu-cancel {
  border: 1px solid #bcc1ce;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  padding: 10px 20px;
  font-size: 13px;
  min-width: 100px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.95);
  text-transform: uppercase;
  margin: 0px;
}

button.menu-build {
  border: 1px solid #3064f9;
  border-radius: 3px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  min-width: 100px;
  padding: 10px 20px;
  display: inline-block;
  color: rgba(255, 255, 255, 0.97);
  background: #3064f9;
  text-transform: uppercase;
  margin: 0px;
}

.L2-level .form-menu-label {
  padding-right: 0px !important;
  max-width: 41px !important;
}

.L2-level {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 20px;
}

.underline-text {
  text-decoration: underline;
}
.create-menu .menu-builder-block {
  background-color: #f8fafe;
}
.menu-option-list,
.menu-builder-l2 {
  position: relative;
}
.spinner-border,
.menu-builder-l2 .spinner-border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.menubuilder .right_general .col-md-6 .row .col-md-12 .create-menu.mt-3 {
  margin-top: 20px !important;
}
.menubuilder
  .right_general
  .col-md-6
  .row
  .col-md-12
  .create-menu.mt-3
  .customonoff {
  padding-top: 20px !important;
}
.menu-builder-l2 .menubuilder-radio-btn {
  position: relative;
}
.menu-list-view {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 21px;
}
.menu-inner {
  padding-top: 0px;
}
.delete-menu-list {
  padding-bottom: 30px !important;
}
.menubuilder-select .form-control:disabled {
  background-color: #f8fafe;
}
.menubuildervalue .menu-inner .form-value .form-control {
  background-color: #f8fafe;
  font-family: "Axiforma-Regular";
}
.menubuildervalue {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.menu-inner .menubuilder-icon {
  background-color: #f8fafe;
}
.Bottom_upload_pop_icon_img__3cUtz label:before {
  line-height: 100px;
}
.menu-modal-body .col-md-10 .col-md-6:nth-child(2) {
  background-color: #f8fafe;
}
.menu-modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.menu-modal-body .col-md-2 {
  box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.5);
}
.dynamic-list ul:nth-child(1) {
  padding-right: 9px;
  margin-bottom: 7px;
}
.dynamic-list ul li:nth-child(1) {
  margin-top: 0px !important;
}
.h5.menubuilder-h5 {
  margin-bottom: -7px;
}
.menubuilder-page .fixed-button .menumobileshow {
  display: none;
}
.menu-modal-body .modal-left-side{
  padding-top: 20px;
}
.menu-modal-body .col-md-10 .col-md-6 .menu-option-list {
  margin-top: 42px;
}
.mobilemenupreview {
  display: none;
}
.disable-menu-done-btn {
  background-color: #bcc1ce !important;
  border: 1px solid #bcc1ce !important;
  color: #ffffff !important;
  cursor: not-allowed;
}
.active-menu-tab {
  color: #3064f9 !important;
  border: 1px solid #3064f9 !important;
}

.show-menu-mobile-preview {
  display: block;
}

.menu-modal-body .breadcrumb ul {
  padding-left: 0px;
  margin-bottom: 0px !important;
}
.menu-modal-body .breadcrumb ul li {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  cursor: pointer;
  font-size: 12px;
}
.menu-modal-body .breadcrumb ul li img {
  cursor: auto;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  top: -1px;
}
.menu-modal-body .breadcrumb ul li:last-child {
  cursor: auto;
  color: #7782a1;
}
.menu-modal-body .breadcrumb ul li p {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}
.menu-modal-body .breadcrumb {
  background-color: transparent;
  margin-bottom: 0px;
}
.form-value .menubuilder-icon {
  width: 100%;
}
.form-value .menubuilder-icon .align-items-center {
  width: calc(100% - 30px);
}
.form-value .menubuilder-icon div:nth-child(2) {
  width: 30px;
}
.customonoff {
  min-height: 70px;
}
.menubuilder-card .menubuilder-list .menumover .d-flex {
  display: flex !important;
  align-items: center !important;
}
.menubuilder-card .menubuilder-list .menumover .d-flex img {
  min-width: 22px;
  height: auto;
}
.menu-builder-l2 .menubuilder-radio-btn .underline-text {
  cursor: pointer;
}
.category-popup.modal-dialog-centered {
  height: calc(100% - 60px) !important;
}
.category-popup.modal-dialog {
  max-height: 100% !important;
}
.menubuilder-select input {
  cursor: pointer;
}
.wordpressmenu {
  position: relative;
}
.wordpressmenu Spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.container1.container1-menu {
  padding-top: 0 !important;
}
.item-selection-menu {
  margin-right: 20px;
  margin-bottom: 0;
  font-family: 'Axiforma-Regular' !important;
  font-size: 13px !important;
  color: #7782A1 !important;
}
.menu-modal-body .menu-option-list .form-check-label p {
  top: 0px;
}
.menubuilder-card .menubuildervalue .web-view-toggle .Bottom_default_icon_check2__1lGEC {
  top: -9px !important;
}
.menubuilder-list{
  position: relative;
}
.menubuilder-list:before {
  content: url("../../assets/images/dashboard/bottom-bar/dots.png");
  left: 12px;
  cursor: move;
  margin-top: 4px;
  z-index: 9;
  opacity: 0;
  position: absolute;
}
.menubuilder-list:hover.menubuilder-list:before {
  opacity: 1;
}
.new-static-menu .add-border{
  border: none;
  border-bottom: 1px solid #dbe4f3;
}
.new-static-menu .create-menu .ptb-3{
  cursor: pointer;
}
.new-static-menu{
  margin-top: 20px;
}







@media (max-width: 359px) {
  .menu-ipad-align {
    flex-direction: column;
    align-items: flex-start;
  }
  .menu-ipad-align li.menu-ipad-item {
    width: 100%;
  }
  .emailbox {
    background-color: #FFFFFF;
    height: 73px;
}
.iconselectscreen{
  padding: 0px 0 30px !important;
}
.iconform {
  padding: 0 15px 15px 15px;
}
}
@media (max-width: 767px) {
  .item-selection-menu {
    margin-right: 0 !important;
  }
  .dasboard_page.mobile-display .transparent_background.transparent_background_menu {
    margin-top: 0 !important;
  }
  .menu-item-align {
    text-align: left !important;
  }
  .emailbox {
    background-color: #FFFFFF;
    height: 73px;
}
.iconselectscreen{
  padding: 0px 0 30px !important;
}
.iconform {
  padding: 0 15px 15px 15px;
}
}
@media (min-width: 768px) and (max-width: 786px) {
  .show-menu-mobile-flex {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .emailbox {
    background-color: #FFFFFF;
    height: 73px;
}
.iconform {
  padding: 0 15px 15px 15px;
}
.menubuilder .col-md-6.pd-3 {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 50px;
}
}
@media (min-width: 768px) and (max-width: 1200px) {
   .menu-item-align {
    text-align: left !important;
  }
  .menu-img-name {
    text-align: left !important;
  }
  .menu-search-ipad {
    margin-right: 10px;
  }
  .ipad-add-icon {
    margin-right: 10px;
  }
  .dynamic-list ul.dynamic-list-all {
    padding-right: 1px !important;
  }
  ul.menu-ipad-align {
    flex-direction: column;
  }
  .ipad-col-small.ipad-col-red1 {
    max-width: 25% !important;
  }
  .ipad-col-big.ipad-col-red2 {
    max-width: 75% !important;
  }
  .container1.container1-menu {
    padding-top: 0 !important;
  }
  .show-menu-red {
    margin-left: auto;
    margin-right: auto;
  }
  .ipad-view-menu-red {
    min-height: 96% !important;
  }
  .emailbox {
    background-color: #FFFFFF;
    height: 73px;
}
/* .iconselectscreen{
  padding: 0px 0 30px !important;
} */
.iconform {
  padding: 0 15px 15px 15px;
}
.iconselectscreen {
  padding: 30px 0 80px !important;
}

.menubuilder .col-md-6.pd-3 {
  padding-left: 20px !important;
  padding-right: 20px !important;
  /* padding-bottom: 50px; */
}
}
@media (min-width: 1001px) and (max-width: 1200px) {
  .transparent_background.transparent_background_menu {
    margin-top: 60px !important;
  }
}
@media (max-width: 1200px) {
  .form-check-menu {
    display: flex !important;
  }
  .menu-add-popup {
    margin-top: 0 !important;
  }
  .menu-img-name {
    text-align: left !important;
  }
  .emailbox {
    background-color: #FFFFFF;
    height: 73px;
}
.iconselectscreen{
  padding: 30px 0 80px !important;
}
}
@media (max-width: 1909px) {
  .gobackicon{
    display: none;
  }
}
@media screen and (max-width: 786px) {
  .dynamic-list ul.dynamic-list-all {
    padding-right: 2px !important;
  }
  .menubuilder .left-border:before {
    display: none;
  }
  .iconselectscreen{
    padding: 0px 0 30px !important;
  }
  .menubuilder .col-md-6.pd-3 {
    padding-left: 20px !important;
    padding-right: 20px !important;
    /* padding-bottom: 50px; */
  }
  .menubuilder .right_general .col-md-6:nth-child(2) {
    /* display: none; */
    border: none;
    padding: 0px !important;
    background-color: #fff;
  }
  .menubuilder-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 26px 45px 26px 15px;
  }
  .menu-builder-block {
    height: 63px;
  }
  .edit-btn a img {
    top: 25px;
    right: 15px;
  }
  .menu-inner {
    padding: 25px 15px;
  }
  .delete-menu-list {
    padding-bottom: 0px !important;
  }
  .menuview {
    max-width: 296px;
    min-height: 640px;
    margin: 0px auto;
  }
  .hide-menu-mobile-preview {
    display: none;
  }
  .menubuilder-page .fixed-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  }

  .menubuilder-page .fixed-button .menumobileshow {
    display: block;
    border: none;
    background-color: #fff;
    color: #3064f9;
    font-size: 16px;
    width: 100%;
   
  }
  .mobilemenupreview {
    display: block;
    position: fixed;
    bottom: 100px;
    left: 0px;
    right: 0px;
    text-align: center;
    cursor: pointer;
  }
  .mobilemenupreview button {
    border: none;
    background-color: transparent;
  }
  .modal-left-side ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-modal-body .col-md-10 .col-md-6:nth-child(2) {
    background-color: #fff;
  }
  .modal-left-side li a {
    width: 100% !important;
  }
  .menu-build-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
  }
  .menu-modal-body {
    min-height: auto;
    height: calc(100% - 66px);
    overflow-y: scroll;
    padding-bottom: 80px;
  }
  .menu-option-list {
    height: auto;
  }
  .dynamic-list .menu-builder-l2 {
    height: auto;
  }
  .dynamic-list ul:nth-child(1) {
    padding-right: 0px;
  }
  .api-not-connected {
    padding: 80px 20px;
  }
}
