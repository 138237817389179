.download-head{
  margin-bottom: 20px;
}
.download-head h1{
  color: #1B2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.download-head h1 span{
  color: #47CE70;
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.download-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 6px!important;
  top: -2px;
}
.download-updated-date{
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #BCC1CE;
  margin: 0px -10px 0px 0px;
}
.download-tooltip .download-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -4px;
  left: 159%;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  text-align: left !important;
  transition: 0.3s;
}
.download-tooltip:hover .download-tooltipsubheader {
  visibility: visible;
}
.download-tooltip .download-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 18px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.download-build{
  display: flex;
  justify-content: space-between;
}
.download-build .download-build-section{
  background-color: #fff;
  width: 49.5%;
  border-radius: 6px;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  padding: 30px 30px 30px 30px;
}
.download-build-section-one{
  display: flex;
  justify-content: space-between;
}
.download-build-section-one div{
  width: calc(100% - 54px);
}
.download-build-section-one h2{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 10px;
}
.download-build-section-one h3{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  min-height: 33px;
}
.download-build-section-two{
  display: flex;
  margin-top: 35px;
}
.download-build-section-two .download-apk{
  color: #fff;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #3064f9;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 44px;
  line-height: 42px;
  display: block;
  width: 160px;
  text-align: center;
}
.download-build-section-two .download-apk:hover{
  transition: .5s;
  /* background-color: #134bec; */
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  /* border: 1px solid var(--custom_color6)!important; */
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.download-build-section-two .download-aab{
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #3064f9;
  border-radius: 4px;
  transition: 0.5s;
  text-decoration: none;
  height: 44px;
  line-height: 42px;
  display: block;
  width: 160px;
  text-align: center;
  margin-left: 20px;
}
.download-build-section-two .download-aab:hover{
  transition: .5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.download-build-section-four{
  margin-top: 25px;
}
.download-build-section-four progress{
  width: 100%;
  margin-left: 0px;
  height: 4px!important;
  border-radius: 0px!important;
}
/* .shimmer {
  -webkit-mask: linear-gradient(-45deg, #E3E6F1 45%, #e3e5f100, #E3E6F1 60%) right / 350% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
} */

.our-progress{
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 8px 0px 6px;
}

.shimmer {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: "";
  height: 5px;
  background-repeat : no-repeat;
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}





.download-build-section-four progress::-webkit-progress-bar {
  background-color: #E3E6F1;
}
.download-build-section-four progress::-webkit-progress-value {
  background-color: #3064F9;
}
.download-build-section-four h4{
  color: #1B2952;
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin-bottom: -4px;
  text-align: left;
}
.download-build-section-four div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.download-build-section-four h5{
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 0px 0px;
}
.download-build-section-four h6{
  color: #BCC1CE;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.download-build-section-four h6 svg{
  margin-right: 5px!important;
  margin-bottom: 0px!important;
}
.download-build-section-three{
  border-top: 1px solid #E3E6F1;
  margin-top: 40.5px;
  padding-top: 30.5px;
}
.build-section-one{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.build-section-one div{
  display: flex;
  align-items: center;
}
.build-section-one h3{
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  width: 100px;
  min-width: 100px;
}
.build-section-one h4{
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.build-section-one h5{
  color: #1B2952;
  font-size: 12px!important;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.build-section-two{
  margin-top: 30px;
  height: 40px;
}
.build-section-two a{
  color: #3064F9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #3064f9;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 40px;
  line-height: 38px;
  display: block;
  width: 100px;
  text-align: center;
  margin-left: 20px;
}
.build-section-two a:hover{
  transition: .5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.build-complete{
  color: #47CE70!important;
}
.build-incomplete{
  color: #FF4848!important;
}
.build-draft{
  color: #c8cedb!important;
}
.build-incomplete span{
  color: #3064F9!important;
  margin-left: 20px;
  cursor: pointer;
}
.download-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 120px;
}
.setup-download{
  display: flex;
  align-items: center;
  margin-top: 61px;
  margin-bottom: 30px;
}
.setup-download img {
  margin-right: 20px;
}
.setup-download p {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #BCC1CE;
  margin: 0px 0px 10px 0px;
}
.setup-download h3 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  margin: 0px;
}
.download-history h3 {
  font-family: "Axiforma-Bold";
  font-size: 16px;
  color: #1B2952;
  margin: 30px 0px 15px 0px;
}
.download-history-head {
  display: flex;
  align-items: center;
  height: 40.5px;
  padding-left: 20px;
  border-bottom: 1px solid #F1F2F8;
}
.download-history-body-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F1F2F8;
  min-height: 100px;
  width: 100%;
  padding-left: 20px;
  transition: .5s;
}
.download-build-header .full-page-tooltip .full-page-tooltipsubheader{
  left: -90px !important;
}
.download-build-header .full-page-tooltip .full-page-tooltipsubheader::after {
  right: 31% !important;
}
.history-table{
  background-color: #fff;
  min-height: 342px;
  border-radius: 6px;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
}
.download-history-head div:nth-child(1), .download-history-entry:nth-child(1) {
  width: 20%;
  padding-right: 10px;
}
.download-history-head div:nth-child(2), .download-history-entry:nth-child(2) {
  width: 10%;
  padding-right: 10px;
}
.download-history-head div:nth-child(3), .download-history-entry:nth-child(3) {
  width: 13%;
  padding-right: 10px;
}
.download-history-head div:nth-child(4), .download-history-entry:nth-child(4) {
  width: 12%;
  padding-right: 10px;
}
.download-history-head div:nth-child(5), .download-history-entry:nth-child(5) {
  width: 20%;
  padding-right: 10px;
}
.download-history-head div:nth-child(6), .download-history-entry:nth-child(6) {
  width: 15%;
  padding-right: 10px;
}
.download-history-head div:nth-child(7), .download-history-entry:nth-child(7) {
  width: 10%;
  padding-right: 10px;
}
.download-history-head div h6 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #BCC1CE;
  margin: 0px;
}
.download-history-entry h4{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #1B2952;
  margin: 0px;
  word-break: break-word;
}
.download-history-entry:nth-child(1) h4 img{
  margin-right: 10px;
  position: relative;
  top: -3px;
}
.download-history-entry h5{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #1B2952;
  margin: 0px;
}
.download-history-entry h5{
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin: 0px;
}
.download-history-entry h6{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #3064F9;
  margin: 8px 0px 0px 0px;
  cursor: pointer;
}
.download-history-entry:nth-child(7) svg{
  cursor: pointer;
}
.download-error-body{
  text-align: center;
  padding: 0px 40px;
}
.download-error-body h4{
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1B2952;
  margin: 23px 0px 0px 0px;
}
.download-error-body h4 svg{
  margin-right: 7px;
  position: relative;
  top: -2px;
}
.download-error-body h5{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782A1;
  margin: 18px 0px 32px 0px;
  line-height: 18px;
}
.download-error-body button{
  color: #fff;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #3064f9;
  border: none;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 44px;
  display: block;
  width: 140px;
  text-align: center;
  margin: auto;
  margin-bottom: 58px;
}
.download-error-body button:hover{
  transition: 0.5s;
  background-color: #134bec;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.download-error-popup .modal-body{
  padding: 0px;
}
.download-reset-popup .modal-body{
  padding: 0px;
}
.parent-app{
  background-color: #fff;
  border-radius: 8px;
  width: 220px;
  min-height: 220px;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
  text-align: center;
  position: relative;
}
.child-app{
  background-color: #fff;
  border-radius: 8px;
  width: 220px;
  min-height: 220px;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
  text-align: center;
  position: relative;
}
.download-grid{
  display: flex;
  align-items: center;
  width: 530px;
  margin: auto;
}
.download-grid h4{
  font-family: "Axiforma-Bold";
  font-size: 15px;
  color: #1B2952;
}
.download-grid h5{
  font-family: "Axiforma-Regular";
  word-break: break-all;
  padding: 0px 10px;
  font-size: 12px;
  color: #7782A1;
}
.download-appicon {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgb(188 193 206 / 30%);
  margin: auto;
  margin-top: 42px;
  margin-bottom: 20px;
}
.download-merged{
  margin-top: 171px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.download-parent-tag , .download-child-tag{
  border: 1px solid #E3E6F1;
  border-radius: 30px;
  width: 90px;
  height: 22px;
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
}
.download-parent-tag h3 , .download-child-tag h3{
  font-family: "Axiforma-Regular";
  font-size: 10px;
  color: #BCC1CE;
  text-align: center;
  margin-top: 4px;
}
.download-merged h1{
  font-family: "Axiforma-Bold";
  font-size: 30px;
  color: #1B2952;
  margin: 60px 0px 20px 0px;
}
.download-merged h2{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782A1;
  max-width: 800px;
  margin: auto;
  margin: 0px 0px 40px 0px;
  line-height: 18px;
}
.download-merged-btns{
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-merged-btns a:nth-child(1){
  color: #3064F9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #3064F9;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 50px;
  line-height: 48px;
  display: block;
  width: 160px;
  text-align: center;
  margin-right: 10px;
}
.download-merged-btns a:nth-child(1):hover{
  color: #134bec;
  border: 1px solid #134bec;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.download-merged-btns a:nth-child(2){
  color: #7782A1!important;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 50px;
  line-height: 48px;
  display: block;
  width: 160px;
  text-align: center;
  margin-left: 10px;
}
.download-merged-btns a:nth-child(2):hover{
  color: #484e5e;
  border: 1px solid #7782A1;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.the-download-svg{
  display: none;
}
.download-history-mob{
  display: none;
}
.build-configuration{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.build-configuration-head-right{
  display: flex;
  align-items: center;
}
.download-build-app-btn {
  width: 120px;
  height: 40px;
  border-radius: 3px;
  color: white;
  background-color: #3064F9;
  border: 1px solid #3064F9;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  line-height: 40px;
  transition: .5s;
  margin-left: 20px;
}
.download-build-app-btn:hover{
  transition: .5s;
  /* background-color: #134bec; */
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.download-save-btn {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  color: #3064F9;
  border: 1px solid #3064F9;
  background-color: transparent;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  line-height: 40px;
  transition: .5s;
  margin-left: 40px;
}
.download-save-btn:hover{
  transition: .5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.download-reset-btn {
  height: 40px;
  border-radius: 3px;
  color: #7782A1;
  border: none;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
  background-color: transparent;
  line-height: 40px;
  transition: .5s;
  border: none;
}
.download-reset-btn:hover{
  color: #42485A;
  transition: .5s;
}
.android-build-config{
  display: flex;
}
.android-build-conf-left{
  width: calc(100% - 400px);
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  padding: 0px 30px 50px 30px;
}
.android-build-conf-right{
  width: 400px;
  margin-left: 30px;
}
.android-build-conf-right h3{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  padding-bottom: 20px;
  border-bottom: 1px solid #EDF0F5;
  margin-bottom: 9.5px;
  padding-top: 12px;
}
.android-config-main{
  position: relative;
}
.android-config-main .accordion-header .accordion-button{
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 30px 0px 30px 0px;
  position: relative;
}
.android-config-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.android-id-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.android-config-toggle h5{
  font-family: "Axiforma-Bold";
  font-size: 16px;
  color: #1B2952;
  margin: 0px 0px 0px 0px;
}
.android-config-toggle h5 svg{
  margin-right: 20px;
}
.android-id-web{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  margin: 0px 30px 0px 0px;
  position: absolute;
  width: max-content;
  right: 30px;
  text-align: right;
  z-index: 2;
  cursor: auto;
  top: 49px;
}
.android-id-web span{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #1B2952;
  margin: 0px 0px 0px 0px;
}
.android-id-web svg{
  margin-left: 6px;
  cursor: pointer;
}
.android-setting-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.android-setting-toggle h6 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  margin: 0px;
}
.android-config-main .accordion-header{
  margin-bottom: 0px;
}
.download-width-50{
  width: 50%;
}
.android-setting-input h5{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  margin: 20px 0px 9px 0px;
}
.android-setting-input input{
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 0px 15px!important;
  font-family: "Axiforma-Regular";
  font-size: 13px!important;
  transition: .5s;
  outline: none;
}
.android-setting-input input:hover , .android-setting-input input:focus{
  transition: .5s;
  background-color: #f8fafe!important;
  border-color: #3064f9!important;
}
.android-build-conf-left .accordion-collapse.show{
  padding-bottom: 32px;
}
.android-seven-steps{
  position: relative;
  font-size: 0;
  display: block;
  /* padding: 0px 10px 10px 10px; */
}
.android-steps{
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  height: 76px;
  z-index: 1;
  width: 100%;
  padding: 0px 0px 0px 20px;
}
.android-steps h4{
  color: #1B2952;
  font-family: "Axiforma-Bold";
  font-size: 13px;
  margin-top: 0px;
}
.android-steps p{
  color: #7782A1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.android-steps div:nth-child(3){
  margin-right: 19px;
}
.android-steps a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.android-steps img{
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: .5s;
}
.android-steps:hover img{
  opacity: 1;
  transition: .5s;
}
.android-steps:nth-child(1):hover~.android-steps-animation {
  width: 100%;
  top: 0;
  background-color: #F1F4FB;
}
.android-steps:nth-child(2):hover~.android-steps-animation {
  width: 100%;
  top: 76px;
  background-color: #F1F4FB;
}
.android-steps:nth-child(3):hover~.android-steps-animation {
  width: 100%;
  top: 152px;
  background-color: #F1F4FB;
}
.android-steps:nth-child(4):hover~.android-steps-animation {
  width: 100%;
  top: 228px;
  background-color: #F1F4FB;
}
.android-steps-animation{
  position: absolute;
  height: 76px;
  top: 0;
  z-index: 0;
  transition: all .2s ease 0s;
  border-radius: 4px;
}
.android-build-conf-left .android-config-main .collapsed .thechatarrow {
  transform: rotate(180deg);
}
.build-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
}
.build-tooltip .build-tooltipsubheader {
  visibility: hidden;
  width: 350px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -24px;
  left: 24px;
  padding: 14px 18px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  font-family: "Axiforma-Regular";
  text-align: left!important;
}
.build-tooltip:hover .build-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.build-tooltip .build-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 30px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.build-tooltip svg{
  position: relative;
  top: -1px;
}


.download-full-img {
  width: 100%;
  margin-bottom: 0px;
}
.download-image-uploader {
  border: 1px solid #BCC1CE;
  background-color: #fff;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  transition: .5s!important;
}
.download-image-uploader .d-flex {
  width: calc(100% - 50px);
}
.download-image-uploader .download-bayimage {
  background-position: 50% !important;
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
}
.download-image-uploader svg {
  z-index: 20;
}
.download-image-uploader label{
  margin: 0px;
  cursor: pointer;
  width: calc(100% - 20px);
  overflow: hidden;
}
.download-image-placeholder{
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1b2952;
  font-size: 13px !important;
  margin-left: 7px;
  margin-bottom: 0px;
}
.build-gap-zero{
  margin-top: 0px;
}
.android-mob-btns{
  display: none;
}
.api-key-field{
  margin-top: 30px;
  position: relative;
}
.api-key-field a , .api-key-field a:hover{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #3064F9;
  position: absolute;
  top: 42px;
  right: 15px;
  margin-bottom: 0px;
  cursor: pointer;
}
.domain-name-download{
  position: relative;
}
.domain-name-download a , .domain-name-download a:hover{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #3064F9;
  position: absolute;
  top: 42px;
  right: 15px;
  margin-bottom: 0px;
  cursor: pointer;
}
.download-toggle-lock input:checked + .slider {
  background-color: #BBCCFF;
  background-image: url(../../assets/images/dashboard/toggle-lock.png)!important;
  background-size: 9px 12px;
  background-repeat: no-repeat;
  background-position: bottom 5.5px left 9px;
}
.domain-name-download a svg{
  margin-left: 5.5px;
}
.api-key-field-error input , .api-key-field-error input:hover{
  border: 1px solid #FF8E8E;
  background-color: #fff;
}
.api-key-field-error a{
  color: #FF4848!important;
}
.build-tick{
  position: absolute;
  top: 44px;
  right: 15px;
}
.advance-settings-toggle{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #3064F9;
  margin: 28px 0px 0px 0px;
  width: max-content;
  cursor: pointer;
}
.build-advance-settings-fields{
  overflow: visible;
  height: auto;
  transition: 1s;
  background-color: #F8FAFE;
  border-radius: 6px;
  margin-top: 30px;
  padding: 13px 30px 31px 30px;
}
.build-advance-settings-fields h6{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  margin: 34px 0px 10px 0px;
}
.hide-advance-settings-toggle{
  overflow: hidden;
  height: 0px;
  transition: 1s;
}
.advance-settings-toggle svg{
  margin-left: 6px;
  transition: .5s;
}
.rotate-build-setting svg{
  transform: rotate(180deg);
  transition: .5s;
}
.build-permission-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.build-permission-toggle h6 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1;
  margin: 0px;
}
.bp-gap0{
  margin-top: 0px;
}
.bp-gap1{
  margin-top: 30px;
}
.bp-gap2{
  margin-top: 20px;
}
.download-reset-body{
  text-align: center;
  padding: 0px 40px;
}
.download-reset-body h4{
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1B2952;
  margin: 23px 0px 0px 0px;
}
.download-reset-body h5{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782A1;
  margin: 18px 0px 32px 0px;
  line-height: 18px;
}
.download-reset-body button:nth-child(1){
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 44px;
  display: block;
  width: 140px;
  text-align: center;
  margin-bottom: 58px;
  border: 1px solid #3064f9;
  margin-right: 10px;
}
.download-reset-body button:nth-child(1):hover{
  transition: .5s;
  box-shadow: 0 2px 4px rgb(112 155 250/69%);
}
.download-reset-body button:nth-child(2){
  color: #fff;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #3064f9;
  border: none;
  border-radius: 3px;
  transition: 0.5s;
  text-decoration: none;
  height: 44px;
  display: block;
  width: 140px;
  text-align: center;
  margin-bottom: 58px;
  border: 1px solid #3064f9;
  margin-left: 10px;
  position: relative;
}
.download-reset-body button:nth-child(2):hover{
  transition: .5s;
  /* background-color: #134bec; */
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
  box-shadow: 0 2px 4px rgb(112 155 250/69%);
}
.download-reset-body button:nth-child(2) div{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.download-reset-footer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-reset-popup{
  max-width: 480px;
}
.download-error-popup{
  max-width: 480px;
}
.build-tooltip-two {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
}
.build-tooltip-two .build-tooltipsubheader-two {
  visibility: hidden;
  width: 350px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -89px;
  left: 24px;
  padding: 14px 18px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  font-family: "Axiforma-Regular";
  text-align: left!important;
}
.build-tooltip-two:hover .build-tooltipsubheader-two {
  visibility: visible;
  text-align: left !important;
}
.build-tooltip-two .build-tooltipsubheader-two::after {
  content: "";
  position: absolute;
  top: 95px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.main-permission-ios{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-ios-child{
  width: 49%;
}
.individual-permission-ios{
  width: 100%;
}
.generate-certificate-btn {
  width: 240px;
  height: 50px;
  border-radius: 3px;
  color: #3064F9;
  background-color: transparent;
  border: 1px solid #3064F9;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  line-height: 40px;
  transition: .5s;
  
}
.generate-certificate-btn:hover{
  transition: .5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.generate-certificate-btn .new-grey-loader{
  margin: 0px 6px 0px 0px;
}
.download-custom-row{
  align-items: end;
}
.generate-certificate-btn-grey , .generate-certificate-btn-grey:hover {
  color: #BCC1CE!important;
  border: 1px solid #E3E6F1!important;
  transition: .5s;
  box-shadow: none!important;
  cursor: not-allowed!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-seprator{
  border-top: 1px solid #E3E6F1;
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-seprator p{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #BCC1CE;
  margin: 0px;
  background-color: #F8FAFE;
  margin-top: -12px;
  padding: 0px 15px;
}
.generate-certificate-status{
  display: flex;
  align-items: center;
}
.generate-certificate-status h4{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #FF4848;
  margin: 0px 0px 0px 20px;
}
.generate-certificate-status h5{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #52CB86;
  margin: 0px 0px 0px 20px;
}
.generate-certificate-status h6{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #BCC1CE;
  margin: 6px 0px 0px 20px;
}
.generate-certificate-status-error , .generate-certificate-status-error:hover{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #3064F9;
  margin: 6px 0px 0px 20px;
}

.download-save-btn-disabled{
  color: #BCC1CE!important;
  border: 1px solid #BCC1CE!important;
  cursor: not-allowed!important;
}
.download-save-btn-disabled:hover{
  transition: .5s;
  box-shadow: none!important;
}
.download-build-app-btn-disabled{
  background-color: #BCC1CE!important;
  border: 1px solid #BCC1CE!important;
}
.download-build-app-btn-disabled:hover{
  background-color: #BCC1CE!important;
  border: 1px solid #BCC1CE!important;
  transition: .5s;
  cursor: not-allowed!important;
  box-shadow: none!important;
}
.disable-proceed-download-btn{
  background-color: #BCC1CE !important;
  cursor: not-allowed !important;
}
.disable-proceed-download-btn:hover{
  background-color: #BCC1CE !important;
  cursor: not-allowed !important;
}
.builds-used-web{
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #7782A1;
}
.builds-used-web span{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #BCC1CE;
}
.builds-used-mobile{
  display: none;
}
.download-history-head .full-page-tooltip .full-page-tooltipsubheader{
  top: 29px;
    left: -65px;
    padding: 10px 10px 10px 10px;
    width: 150px;
}
.download-history-head .full-page-tooltip .full-page-tooltipsubheader::after {
  top: -8px;
    right: 49%;
    margin-top: -2px;
    border-color: transparent transparent black transparent;
}
















@media (min-width: 1201px) and (max-width: 1400px) {
.android-build-conf-left {
  width: calc(100% - 300px);
}
.android-build-conf-right {
  width: 300px;
}
.android-config-toggle h5 svg {
  margin-right: 10px;
}
.android-id-web {
  right: 10px;
}
.download-history-head div:nth-child(1), .download-history-entry:nth-child(1) {
  width: 25%;
}
.download-history-head div:nth-child(3), .download-history-entry:nth-child(3) {
  width: 11%;
}
.download-history-head div:nth-child(4), .download-history-entry:nth-child(4) {
  width: 10%;
}
.download-history-head div:nth-child(6), .download-history-entry:nth-child(6) {
  width: 12%;
}
.download-history-entry:nth-child(7) svg {
  width: 30px;
}
.build-section-one h5 {
  width: 250px;
  text-align: right;
}

}






@media screen and (max-width: 1200px){
  .download-updated-date{
    display: none;
  }
  .download-updated-mobdate{
       font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        text-align: center;
        margin-top: 0px;
        width: 100%;
        margin-bottom: 0px;
  }
.download-error-popup .modal-body {
  margin-top: 28vh;
}
.download-error-body button {
  width: 80%;
}
.download-reset-popup .modal-body {
  margin-top: 28vh;
}
.download-reset-body{
  padding: 0px 20px;
}
.download-reset-body button{
  width: 80%;
}
.download-build{
  display: block;
}
.download-build .download-build-section {
  width: 100%;
  text-align: center;
  padding: 44px 20px 30px 20px;
}
.download-build .download-build-section svg{
  margin: auto;
  margin-bottom: 2px !important;
}
.download-apk{
  width: 100%!important;
}
.download-aab{
  width: 100%!important;
}
.download-head{
  margin: 17px 15px 15px 15px;
}
.download-build{
  margin: 15px;
}
.download-build-left{
  margin-bottom: 15px;
}
.download-build-section-one svg{
  display: none;
}
.the-download-svg{
  display: block;
}
.build-section-one h3 {
  text-align: left;
}
.build-section-one {
  display: block;
}
.build-section-two a, .build-section-two a:hover {
  width: 100%;
  margin-top: 30px;
  height: 44px;
  line-height: 42px;
  margin-left: 0px;
}
.build-section-one h5{
  margin-left: 100px;
  text-align: left;
  margin-top: 5px;
}
.build-section-one h4{
  width: calc(100% - 100px);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history-table{
  display: none;
}
.download-steps {
  display: block;
  padding: 0px 15px;
}
.download-history{
  margin: 0px 15px;
}
.download-history-mob{
  display: block;
}
.download-history-entry-mob{
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  width: 100%;
  margin-bottom: 15px;
}
.download-history-entry-mob h6{
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #BCC1CE;
  margin-bottom: 7px;
}
.download-history-entry-mob h5{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #1B2952;
  margin-bottom: 0px;
  word-break: break-word;
}
.download-history-entry-mob h4{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782A1;
}
.history-entry-one{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E3E6F1;
  padding: 0px 15px 0px 15px;
  height: 93.5px;
}
.history-entry-two{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E3E6F1;
  padding: 0px 15px 0px 15px;
  height: 85px;
}
.history-entry-three{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E3E6F1;
  padding: 0px 15px 0px 15px;
  height: 84px;
}
.history-entry-four{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E3E6F1;
  padding: 0px 15px 0px 15px;
  height: 86px;
}
.history-entry-five{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E3E6F1;
  padding: 0px 15px 0px 15px;
  height: 86px;
}
.history-entry-six{
  padding: 21px 15px 25px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history-entry-six h3{
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin: 0px;
}
.history-entry-six p{
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #3064F9;
  margin: 10px 0px 0px 0px;
}
.parent-app {
  width: 140px;
  min-height: 140px;
}
.child-app{
  width: 140px;
  min-height: 140px;
}
.download-merged h2 {
  max-width: 90%;
  width: 90%;
  margin: 0px auto 40px auto;
  line-height: 18px;
}
.download-grid {
  width: 100%;
  justify-content: center;
}
.download-grid h4 , .download-grid h5{
  display: none;
}
.download-appicon {
  margin-top: 33px;
}
.download-merged h1 {
  font-size: 24px;
  margin: 30px auto 19px auto;
  width: 90%;
}
.download-merged {
  margin-top: 140px;
}
.download-tooltip .download-tooltipsubheader {
  visibility: hidden;
  width: 200px;
  top: 35px;
  left: -9px;
}
.download-tooltip .download-tooltipsubheader::after {
  top: -8px;
  right: 177px;
  transform: rotate(90deg);
}
.android-build-config {
  display: block;
  margin: 57px 15px 22px 15px;
}
.android-build-conf-left {
  width: 100%;
  padding: 0px;
}
.android-build-conf-right {
  width: 100%;
  margin-left: 0px;
  margin-top: 30px;
}
.download-save-btn , .download-build-app-btn{
  display: none;
}
.download-head h1 {
  font-size: 20px;
}
.android-config-main .accordion-header .accordion-button {
  padding: 21px 15px;
}
.android-steps{
  background-color: #F1F4FB;
  margin-bottom: 10px;
}
.android-steps-animation{
  display: none;
}
.android-id-web {
  right: auto;
  top: -43px;
}
.android-build-conf-left .accordion-collapse{
  padding: 0px 15px 32px 15px;
}
.row .col-md-6:nth-child(2) .build-gap-zero{
  margin-top: 30px;
}
.build-tooltip .build-tooltipsubheader {
  width: 200px;
  top: 26px;
  left: -85px;
}
.build-tooltip .build-tooltipsubheader::after {
  top: -8px;
  transform: rotate(90deg);
  right: 102px;
}
.android-mob-btns{
  display: block;
  padding: 0px 15px;
}
.download-build-app-btn-mob {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  color: white;
  background-color: #3064F9;
  border: 1px solid #3064F9;
  font-size: 16px;
  font-family: 'Axiforma-Regular';
  line-height: 40px;
  transition: .5s;
  margin-top: 20px;
  margin-bottom: 17px;
}
.download-save-btn-mob {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  color: #3064F9;
  border: 1px solid #3064F9;
  background-color: transparent;
  font-size: 16px;
  font-family: 'Axiforma-Regular';
  line-height: 40px;
  transition: .5s;
}
.android-steps img{
  opacity: 1;
}
.ios-app-permissions .row .col-md-6:nth-child(2) .bp-gap0{
  margin-top: 20px;
}
.download-reset-footer{
  display: block;
}
.download-reset-body button:nth-child(1){
  width: 280px;
  margin: auto;
  position: relative;
  top: 70px;
  font-size: 16px;
}
.download-reset-body button:nth-child(2){
  width: 280px;
  margin: auto;
  position: relative;
  top: -40px;
  font-size: 16px;
}
.build-section-two {
  height: auto;
}
.download-build-section-one div {
  width: 100%;
}
.build-tooltip-two .build-tooltipsubheader-two {
  width: 292px;
  top: 25px;
  left: -80px;
}
.build-tooltip-two .build-tooltipsubheader-two::after{
  top: -8px;
  transform: rotate(90deg);
  right: 199px;
}
.main-permission-ios{
  display: block;
}
.main-ios-child{
  width: 100%;
}
.main-permission-ios .main-ios-child:nth-child(2){
  margin-top: 30px;
}
.download-reset-body button:nth-child(1) {
  border: none;
  color: #7782A1;
}
.download-reset-body button:nth-child(2) {
  height: 50px;
}
.download-build-section-one{
  margin-top: 35px;
}
.download-build-section-one h3 {
  max-width: 80%;
  margin: auto;
}
.build-advance-settings-fields {
  border-radius: 0px;
  padding: 13px 14px 31px 14px;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: -31px;
}
.generate-certificate-status {
  display: block;
}
.generate-certificate-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 33px;
}
.generate-certificate-status-one{
  display: flex;
  align-items: center;
  justify-content: center;
}
.generate-certificate-status h4{
  margin: 0px 10px 0px 0px;
}
.generate-certificate-status-error{
  margin: 0px 0px 0px 10px !important;
}
.generate-certificate-status-two{
  display: flex;
  align-items: center;
  justify-content: center;
}
.generate-certificate-status h5{
  margin: 0px;
}
.generate-certificate-status h6{
  display: none;
}
.builds-used-web{
  display: none;
}
.buildlimit {
  display: block!important;
}
.builds-used-mobile{
  display: block;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #7782A1;
  margin-top: 20px;
}
.builds-used-mobile span{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #BCC1CE;
}

}




@media screen and (max-width: 375px){

.parent-app {
  width: 90px;
  min-height: 90px;
}
.child-app {
  width: 90px;
  min-height: 90px;
}
.download-appicon {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
}
.download-merged h1 {
  font-size: 19px;
}
.download-merged-btns a:nth-child(1) {
  height: 44px;
  line-height: 42px;
  width: 120px;
  font-size: 13px;
}
.download-merged-btns a:nth-child(2){
  height: 44px;
  line-height: 42px;
  width: 120px;
  font-size: 13px;
}

}