@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}

.all-pages-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.all-pages-head h3{
  color: #1B2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.pagebg {
  background-color: #fff;
  border-radius: 6px!important;
  border: 1px solid #E3E6F1!important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
  padding-bottom: 50px;
  margin-top: 20px;
}
.full-page-width{
  display: flex;
}
.full-page-width-left{
  width: 50%;
  min-width: 50%;
  padding-left: 20px;
}
.full-page-width-right{
  width: 50%;
  min-width: 50%;
}
.full-page-preview-right {
  padding: 75px 30px 60px 30px;
  border-left: none;
  text-align: center;
}
.full-page-header{
  width: 100%;
  border-bottom: 1px solid #E3E6F1;
  padding: 19px 0px;
  margin-bottom: 30px;
}
.full-page-header h2{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 7px;
}
.full-page-header p{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.section-margin-toggle{
  margin-top: 32px;

}
.section-margin-toggle h4{
  color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 10px;
}
.section-margin-input-main{
  display: flex;
    justify-content: center;
}
.section-margin-input-first{
  width: 50%;
    margin-right: 20px;
    position: relative;
}
.section-margin-input-first input{
  height: 50px;
  border-radius: 3px;
  outline: none !important;
  padding: 13px 15px 13px 15px !important;
  border: 1px solid #bcc1ce;
  width: 100%;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #1B2952;
 
}
.section-margin-input-main-first{
  display: flex;
  width: 50%;
}
.section-margin-input-main-second{
  display: flex;
  width: 50%;
}
.section-margin-input-last{
  width: 50%;
  position: relative;
}
.section-margin-input-last p{
  color: #BCC1CE;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    position: absolute;
    margin-bottom: 0px;
    top: 14px;
    left: 45px;
}
.section-margin-input-first p{
  color: #BCC1CE;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    position: absolute;
    margin-bottom: 0px;
    top: 14px;
    left: 45px;
}
.section-margin-input-last input{
  height: 50px;
  border-radius: 3px;
  outline: none !important;
  padding: 13px 15px 13px 15px !important;
  border: 1px solid #bcc1ce;
  width: 100%;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #1B2952;
 
}
.section-margin-input-last input:hover, .section-margin-input-last input:focus{
  border: 1px solid #3064F9 !important;
  background-color: #F8FAFE !important;
  transition: .5s;
}
.section-margin-input-first input:hover, .section-margin-input-first input:focus{
  border: 1px solid #3064F9 !important;
  background-color: #F8FAFE !important;
  transition: .5s;
}
.full-page-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-page-toggle h4{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.full-page-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
}
.full-page-tooltip .full-page-tooltipsubheader {
  visibility: hidden;
  width: 300px;
  background-color: #000000;
  color: #fff!important;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: 25px;
  padding: 12px 15px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  text-align: left !important;
}
.full-page-tooltip .full-page-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.full-page-tooltip:hover .full-page-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.all-pages-head-right{
  display: flex;
  align-items: center;
}
.all-pages-head-right p{
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-right: 20px;
}
.full-page-button-footer{
  display: none;
}

@media (max-width: 1500px) {
  .section-margin-input-main {
    display: unset;
  }
  .section-margin-input-main-first {
    width: 100%;
    margin-bottom: 20px;
  }
  .section-margin-input-main-second {
    width: 100%;
    margin-bottom: 20px;
  }
  .section-margin-input-first-bottom{
   margin-right: 0px !important;
  }
}













@media (max-width: 1200px) {
  .section-margin-toggle {
    margin-top: 0px;
    padding: 0px 15px;
  }

.full-page-preview-right{
  display: none;
}
.full-page-width-left {
  width: 100%;
  min-width: 100%;
  padding-left: 0px;
}

/* .all-pages-head-right{
  display: none;
} */

.hide-detail-web{
  display: none;
}
.all-pages-head{
  margin: 17px 15px 20px 15px;
}
.pagebg{
  margin: 0px 15px;
  padding-bottom: 0px;
}
.full-page-header{
  padding: 25px 15px;
}
.full-page-toggle{
  padding: 0px 15px 40px 15px;
}
.full-page-tooltip .full-page-tooltipsubheader {
  width: 200px;
  padding: 15px;
  top: 30px;
  left: -17px;
}
.full-page-tooltip .full-page-tooltipsubheader::after {
  border-color: transparent transparent black;
  top: -7px;
  left: 20px;
  right: unset
}
.full-page-button-footer{
  display: block;
  text-align: center;
  margin: 0px 15px;
}
.full-page-button-footer button{
  border: none;
  border-radius: 3px;
  background-color: #3064F9;
  color: #fff;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  width: 100%;
  margin-top: 20px;
  height: 50px;
}
.full-page-button-footer p{
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  margin-bottom: 0px;
  margin-top: 20px;
}


}