.topp{
    padding: 0px !important;
}
.monitor-headPannel{
    padding: 0px !important;
    padding: 0px !important;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    height: auto;
    /* min-height: 100%; */
    padding: 30px;
    margin-bottom: 12px;
    padding-top: 0px;
    position: relative;
    border: 1px solid #E3E6F1;
}
/* .rightsid{
    min-height: 70vh;
} */
.firstcolback{
    /* background-color: #FFFFFF !important; */
    padding: 25px 30px 37px;
  }
  /* .monitorbutton .takethere:hover{
    color: white !important;
   
  } */
  .monitorbutton .takethere:hover{
 
        box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
        color: #fff;
        background-color:#3064f9 !important ;
      
  }
  .firstcol h3{
color: #7782A1;
      font-size: 13px !important;
    font-family: "Axiforma-Regular" !important;
    margin-bottom: 10px !important;
  
  }
  .firstcol1{
    /* border-left: 1px solid #e0e5ec; */
    padding-left: 0px !important;
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }
 .firstcol{
    padding-right: 0px !important;
    flex: 0 0 50%;
    max-width: 50%;
 }
  
  .rightpaneldiv{
          padding: 0px !important;
      margin-bottom: 0px !important;
  }
  
  
  .topHead{
      padding: 19px 30px 19.5px 30px;;
      border-bottom: 1px solid #e0e5ec;
      margin-top: 0px;
  }
  .formselect{
      margin-top: 30px !important;
      margin-bottom: 4px !important;
  }
 
  
  .formselect select{
    height: 50px !important;
    font-size: 13px !important;
    background-color: #F8FAFE;
    padding: 14px 17px 16px 16px !important;
  }
  .secondselect{
      margin-bottom: 0px!important;
      margin-top: 0px !important

  }
  .monitor-dropdown-day{
    margin-bottom: 20px !important;
    margin-top: 0px !important;
  }
  .connectivity-send-buttondisabled{
    width: 100px;
    height: 40px;
    border: none;
    color: white;
    background-color: #bcc1ce;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    cursor: not-allowed !important;
  }
  .secondselect select{
 
      height: 50px !important;
      font-size: 13px !important;
      background-color: #F8FAFE;
      padding: 14px 17px 16px 16px !important;
  }
  .savebutton {
      height: 40px;
      width: 100px;
      text-transform: uppercase;
  }
  .monitortoggle{
    margin-bottom: 0px;         
  }
  
.monitor-radio-firstbtn{
    padding-right: 227px;
}
.monitor-radio-button {
    cursor: pointer;
    /* padding-right: 25px; */
    margin-bottom: 18px;
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-left: -3px;

  }
  .monitor-radio-button input[type=radio] {
      box-sizing: border-box;
      padding: 0;
  }
  .monitor-checkmark{
    position: absolute;
    top: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #BCC1CE;
    border-radius: 100%;
  }
  .monitor-radio-button input {
      font-size: 1rem;
      line-height: 1.5;
      padding: 11px 23px;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 0;
      outline: 0;
      background-color: transparent;
  }
  
  .monitor-radio-button__input {
      opacity: 0;
      position: absolute;
  }
  
  .monitor-radio-button__control {
      position: relative;
      display: inline-block;
      width: 27px;
      height: 27px;
      margin-right: 6px;
      vertical-align: middle;
      background-color: inherit;
      color: #678EFF;
      border: 2px solid #BCC1CE;
      border-radius: 24px;
  }
  .user-category-list .category-checkmark {
    top: 9px !important;
  }
  .monitor-radio-button__input:checked+.monitor-radio-button__control:after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 11px;
    height: 11px;
    background-color: white;
    border-radius: 12px;
  }
  .monitor-dropdown-date{
    margin-top: 0px !important;
    margin-bottom: 4px !important;
  }
  .monitor-dropdown-date .dropdown-toggle:hover, .monitor-dropdown-date .dropdown-toggle:focus {
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: 0.5s;
}
  .monitor-dropdown-day .dropdown-toggle:hover, .monitor-dropdown-day .dropdown-toggle:focus {
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: 0.5s;
}
 .connectivity-search-dropdown .dropdown-toggle{
    padding: 3px 15px 5px 15px !important;
  }
  .monitor-radio-button__input:checked+.monitor-radio-button__control {
      border-color: #678EFF;
      background-color: #678EFF;
  }
  
  .monitor-radio-button__control {
    transform: scale(0.75);
  }
  .monitor-plugin_img{
    margin-top: 110px;
  }
  .firstcolback h2{
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    font-size: 16px;
  }
  .firstcolback p{
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    font-size: 13px;
    margin-bottom: 30px;
    text-align: left !important;

  }
  .connect-email-chip-section label, .connect-categogy-chip-section label{
    color: #1b2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
  }
  .monitor-ticktoggle{
    padding-left: 32px;
    margin-bottom: 20px !important;
  }
.connect-email-chip-section .dropdown .dropdown-toggle{
  background-image: unset !important;
 
}
.monitor-emaildropdown{
  display: grid;
}
.monitor-name{
  font-size: 13px;
  color: #1B2952;
  font-family: 'Axiforma-Regular';
}
.monitor-email{
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  font-size: 12px;
  margin-top: 4px;
}
.connect-email-chip-section .search-dropdown-before div {
  color: #BCC1CE !important;
}
.monitor-disable-dropdown .dropdown-toggle{
  background-color: #e9ecef !important;
  /* opacity: 1; */
  cursor: unset !important;
}
.monitor-disable-dropdown button::after{
  display: none !important;
}
  /* .savebutton :hover{
      background-color: #3064F9;
      color: white;
  } */
  
  /* @media (min-width: 600px) and (max-width: 765px){
    .topp .transparent_background{
        margin-top: 0px !important;
    }
  } */
  /* @media (min-width: 1001px) and (max-width: 1200px){
  .firstcol{
      flex: 0 0 50% !important;
      max-width: 50% !important;
  }
  .firstcol1{
      flex: 0 0 50% !important;
      max-width: 50% !important;
      padding-right: 0px !important
  }
  .topHead{
      margin-top: 70px !important;
  }
  .firstcol{
    padding-right: 15px !important;
 }
 .rightsid .plugin_img {
    margin-top: 43.5px !important;
  }
  .rightsid .monitor-img-spacing {
    margin-top: 0px !important;
}
.rightsid .installed-plugin {
    padding-top: 0px !important;
}
} */
@media (max-width: 1200px) {
  .monitor-radio-firstbtn{
    padding-right: 0px;
}
  .mob-category-list{
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    font-size: 16px;
    color: rgb(27, 41, 82);
    font-family: Axiforma-Regular;
    cursor: pointer;
    display: flex;
  }
  .user-mob-category-list{
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    font-size: 16px;
    color: rgb(27, 41, 82);
    font-family: Axiforma-Regular;
    cursor: pointer;
    display: flex;
  }
  .user-mob-category-list .category-checkmark {
    top: 9px;
  }

  .mob-category-created:hover{
    padding: 5px 15px;
    cursor: unset;
    background-color: unset;
    transition: .5s;
  }
  .connectivity-send-buttondisabled{
    width: 100%;
    height: 50px;
    font-size: 16px;
  }
    .monitor-lastrow{
        display: grid;
    }
      .firstcol{
          flex: 0 0 100% !important;
          max-width: 100% !important;
      }
      .firstcol1{
          display: none !important;
      }
      .topHead{
          padding: 24px 30px 18px 30px;
          border-bottom: 1px solid #e0e5ec;
          margin-top: 0px;
      }
      .monitor-headPannel {
margin: 0px 15px 0px 15px;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
      }
      /* .firstcol{
        padding-right: 15px !important;
     } */
     .firstcolback {
    
        padding: 25px 15px 37px;
    }
      }
  
  /* @media (max-width: 767px){
      .firstcol1{
       display: none;
      }
      .topHead{
          padding: 0px;
      }
  } */
  
  /* @media (min-width: 250px) and (max-width: 767px){
      .firstcol1{
          display: none;
         }
         .topHead{
          padding: 0px;
          border-bottom: none !important;
          margin: 0px;
         }
        
         .tback1{
          margin-top: 3px !important
         }
         .firstcol{
            padding-right: 15px !important;
         }
         .secondselect .form-group  select{
            height: 50px !important;
            font-size: 13px !important;
            background-color: #F8FAFE;
            padding: 14px 17px 16px 16px !important;
          }
          .formselect .form-group select{
 
            height: 50px !important;
            font-size: 13px !important;
            background-color: #F8FAFE;
            padding: 14px 17px 16px 16px !important;
        }
    
      } */