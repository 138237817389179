.monatisation-head h3{
    font-size: 24px !important;
    margin: 0px !important;
    font-family: 'Axiforma-Bold';
    line-height: 24px;
    color: #1B2952;
}
.monatisation-head .consent-tooltip{
    margin-left: 6px;
}
.monatisation-head .premium-tag{
    display: none;
}
.consent-modal-tab .monetisation-varify-enable{
    width: 120px;
    height: 40px;
    border-radius: 3px;
    color: #3064F9 !important;
    border: 1px solid #3064F9;
    background-color: transparent;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 20px !important;

}
.consent-modal-tab .monetisation-varify-enable:hover{
    color: #3064F9 !important;
    border: 1px solid #3064F9 !important;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
    transition: 0.5s;
}
.consent-modal-tab .monetisation-app-store-conn-svg:hover{
    border: unset !important;
}

.consent-modal-tab.monetisation-tab  div a {
    padding-bottom: 10px;
}
.mob-publish-paddingdiv .bbsetting-tooltip .bbsetting-tooltipsubheader{
    width: 370px;
    top: -45px;
}
.mob-publish-paddingdiv .bbsetting-tooltip .bbsetting-tooltipsubheader::after {
   top: 51px
}
.monetisation-red-dot{
    position: absolute;
    background-color: #FF4848;
    width: 8px;
    height: 8px;
    border-radius: 18px;
    border: 2px solid #ffff;
    top: 2px;
    right: 5px;
}
.monetisation-error-conection{
    position: relative;
}
.monetisation-connect-btn{
    display: flex;
    align-items: center;
}
.monetisation-connect-btn a{
    padding-bottom: 0px !important;
}
.monetisation-connect-btn .monetisation-app-store-conn-svg{
    margin-left: 20px!important;
}
.monetisation-upgrade-main {
    position: relative;
    background-image: url("../../assets/images/dashboard/app-icon-background.png");
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    background-attachment: fixed;
    min-height: 79vh;
    display: flex;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.monetisation-goto{
    margin-left: 6px
}
.monetisation-upgrade-left {
    display: flex;
    /* justify-content: end; */
    width: 50%;
    padding: 120px 100px 20px 120px;

}
.monetisation-serverurl-left{
    padding-top: 71px !important;
}
.monetisation-upgrade-left h2{
    color: #1b2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    margin-bottom: 18px !important;
}
.monetisation-upgrade-left p {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 19px;
    margin-bottom: 20px;
    text-align: left !important;
   
}
.monetisation-upgrade-left ul {
    padding-left: 0 !important;
    margin-top: 20px;
}
.monetisation-upgrade-left ul li {
    line-height: 18.82px;
    font-size: 14px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;
    padding-bottom: 20px;
    list-style-type: none;
    position: relative;
}
.ul-open-aerodiv{
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.ul-open-aerodiv-steptwo{
    border-top: 1px solid #E3E6F1;
}
.ul-open-aerodiv div{
    text-align: end;
}
.inapp-steps-head{
    font-size: 12px !important;
    color: #1B2952 !important;
    padding-top: 20px;
    margin-bottom: 0px !important;
    font-family: 'Axiforma-Bold' !important;
}
.monetisation-upgrade-left ul li:before {
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 5px;
}

.monetisation-upgrade-left .in-app-purchge-upgrade {
    width: 200px;
    height: 50px;
    border-radius: 3px;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    font-family: 'Axiforma-Regular';
    transition: 0.5s;
}
.upgrade-skip-monetisation{
    display: flex;
    align-items: center;
    margin-top: 14px;
    gap: 20px;
}
.in-app-purchage-accordian-head h3 {
    font-size: 14px;
    color: #BCC1CE;
    font-family: 'Axiforma-Bold';
    line-height: 20px;
    margin-bottom: 7px;
}
.monetisation-upgrade-left .in-app-purchge-upgrade:hover {
    background-color: #134bec !important;
}
.monetisation-upgrade-right{
    padding-top: 146px;
    width: 50%;
}
.monetisation-purchase-right{
    padding-top: 90px;
    width: 50%;
}
.monetisation-purchase-right-android{
    padding-top: 90px;
    width: 50%;
}
.monetisation-purchase-right img:last-child{
    margin-left: -10px;
}
.monetisation-serverurl-div{
    margin-top: 20px;
    border-radius: 6px;
    background-color: #ffff;
    padding: 23px 20px 20px 20px;
    margin-bottom: 20px;
    border: 1px solid #E3E6F1;
}
.in-app-purchage-steps{
    background-color: #ffff;

}
.monetisation-serverurl-div label{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    line-height: 17px;
    margin-bottom: 7px;
}
.monetisation-serverurl-input{
    border: 1px solid #E3E6F1;
    border-radius: 3px;
}
.monetisation-serverurl-div input{
    width: 100%;
    height: 50px;
    padding: 15px;
    border: none;
    background-color: #ffff;
    outline: none !important;
    border-radius: 3px;
    font-size: 13px;
    color: #1B2952;
    font-family: 'Axiforma-Regular';
}
.monetisation-serverurl-input:hover{
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}
.monetisation-serverurl-input{
    position: relative;
}
.monetisation-serverurl-svg{
    position: absolute;
    top: 19px;
    right: 15px;
    background-color: white;
    height: 15px;
    width: 20px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.monetisation-serverurl-svg svg{
 cursor: pointer;
}
.monetisation-connect-div{
    min-height: 75vh;
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.monetisation-connect-div-img{
    background-color: white;
    height: 111px;
    width: 111px;
    border: 1px solid #E3E6F1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);
}
.monetisation-connect-div p {
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin: 30px 0px;
    font-size: 14px;
}
.monetisation-connect-div a{
    width: 140px;
    height: 40px;
    border: none;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff !important;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: 0.5s;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.monetisation-connect-div a:hover {
    background-color: #134bec;
    color: #ffff !important;
}
.monetisation-connect-div img {
    margin-right: 5px;
}
.monetisation-serverurl-left h2{
 font-size: 36px !important;
 line-height: 51px !important;
}
.monatisation-head h2 {
    display: none;
}
.monatisation-head{
    margin-bottom: 0px;
} 
.mob-monetisation-btn-div{
  display: none;
}
.in-app-purchage-accordian{
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 10px;
}
.in-app-purchage-accordian-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.in-app-purchage-accordian-head p{
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 0px;
}
.in-app-purchage-accordian-head-svg{
    text-align: end;
}
.android-datasave-btn{
    background-color: #3064f9;
    border: none;
    color: #fff;
    height: 40px;
    width: 120px;
    border-radius: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    margin-left: 20px;
   
}
.android-datasave-btn:hover{
    background-color: #134bec;
    transition: 0.5s;
    color: #ffff !important;
}
.mobandroid-datasave-btn{
    display: none;
}
.web-Connect-inapp-btn a{
    background-color: #3064f9;
    border: none;
    color: #fff;
    height: 40px;
    width: 180px;
    border-radius: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    margin-bottom: 44px;
}
.web-Connect-inapp-btn img{
    margin-right: 5px;
}
.web-Connect-inapp-btn a:hover{
    background-color: #134bec;
    transition: 0.5s;
    color: #ffff !important;
}
.monetisation-mobConfigure{
    display: none;
}
.web-monetisation-tech-clicked{
    width: 40px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #F8FAFE;
    border: none;
    box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16)inset;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin-left: 20px;
}
.web-monetisation-tech-unclicked{
    padding-bottom: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff;
    margin-right: 20px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    border-bottom: unset !important;
}
.web-monetisation-tech-unclicked:hover{
    border: unset !important;

}
.mob-Inapp-header{
    display: none;
}
.mob-monetisation-tech-unclicked{
    display: none;
}
.mob-monetisation-tech-clicked{
    display: none;
}
.monetisation-serverurl-input .monetisation-serverurl-svg:hover .monetisation-serverurl-input{
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}
.uploadJson-div{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    position: relative;
}
.uploadJson-div label{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 5px 15px 5px 7px;
}
.iap-setting-inputfirstdiv{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: calc(100% - 30px);
    cursor: pointer;
}
.iap-download-bayimage{
    width: 36px;
    height: 36px;
    min-width: 36px;
}
.iap-setting-inputfirstdiv p{
    margin-bottom: 0px !important;
    margin-left: 10px !important;
    margin-right: 8px !important;
    word-break: break-all;
    /* width: 100%; */
    text-align: left !important;
}

.iap-setting-inputfirstdiv .documentdiv{
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 3px;
    background-color: #EDF0F5;
}
.fileUploadedJson{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    white-space: normal;
    color: #1B2952 !important;
    font-size: 13px !important;
}
@media (min-width: 2000px){
    .monetisation-upgrade-left {
        justify-content: end;
    }
}
@media (min-width: 1201px) and (max-width: 1650px){
    .monetisation-upgrade-left {
        padding: 146px 20px 0px 20px;
    }
    .monetisation-purchase-right img{
        width: 50%;
    }
}

@media (min-width: 1201px) and (max-width: 1475px){
    .monetisation-upgrade-right img{
        width: 100%;
    }
    .monetisation-purchase-right-android img{
        width: 100%;
    }
}
@media (max-width: 1200px){
    .monetisation-upgrade-right img{
        width: 100%;
    }
    .monetisation-purchase-right-android img{
        width: 100%;
    }
    .mob-publish-paddingdiv .bbsetting-tooltip .bbsetting-tooltipsubheader{
        width: 250px !important;
        top: 27px !important;
        left: -58px !important;
    }
    .mob-publish-paddingdiv .bbsetting-tooltip .bbsetting-tooltipsubheader::after {
        right: 180px !important;
    }
    .mob-Inapp-header{
        display: flex;
        justify-content: space-between;
    }
    .mob-monetisation-tech-unclicked{
        width: 40px;
        height: 40px;
        border-radius: 3px;
        color: white;
        background-color: #F8FAFE;
        border: none;
        box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        margin-top: -10px;
    }
    .monetisation-mobConfigure{
        display: block;
    }
    .monetisation-mobConfigure a{
        height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3064F9;
    border-radius: 3px;
    font-size: 15px;
    color: #3064F9;
    font-family: 'Axiforma-Regular';
    margin-bottom: 20px;
    }
    .in-app-purchage-steps{
        margin: 0px -20px;
    }
    .in-app-purchage-steps .in-app-purchage-accordian:nth-of-type(3){
        border-bottom: 1px solid #E3E6F1 !important;
    }
    .in-app-purchage-accordian {
      
    border-radius: 0px;
    border: unset;
    margin-bottom: 0px;
    border-top: 1px solid #E3E6F1 !important;
   
    }
    .android-stepfour{
        border-bottom: 1px solid #E3E6F1 !important;
    }
    .uploadJson-div {
        margin-bottom: 10px;
    }
    .in-app-purchage-accordian-head p {
        margin-bottom: 0px !important;
    }
    .monetisation-upgrade-left p {  
        margin-bottom: 20px;
        line-height: 18px;
        margin-right: unset;
    }
    .in-app-purchage-accordian-head {
        align-items: unset;
    }
    .monetisation-serverurl-div input {
        text-overflow: ellipsis;
        width: 85%;
    }
    .monetisation-upgrade-left ul li {
        line-height: 23px;
    padding-bottom: 11px; 
    }
    .monetisation-upgrade-left ul li:before {
        top: 10px;
    }
    .mob-monetisation-tech{
        width: 40px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    }
    
    .mob-monetisation-tech-clicked{
            width: 40px;
            height: 40px;
            border-radius: 3px;
            color: white;
            background-color: #F8FAFE;
            border: none;
            box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16)inset;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            margin-left: 20px;
        }
        .monetisation-error-connection-mob{
            width: 40px;
            height: 40px;
            border-radius: 3px;
            color: white;
            background-color: #FFF1F1;
            border: 1px solid #FF4848;
            box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff;
            margin-left: 20px;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
        }
    .mob-monetisation-btn-div a{
        font-size: 14px;
        color: #3064F9 !important;
       font-family: 'Axiforma-Regular'
    }
    .mob-monetisation-btn-div{
        display: flex;
        align-items: center;
        margin-top: -10px;
    }
    .monatisation-head h3 {
        display: none !important;
    }
    .monatisation-head h2 {
        font-size: 20px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        line-height: 28px;
        display: block;
        margin-bottom: 0px;
    }
    .monatisation-head{
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    } 
    .monetisation-upgrade-main {
        background-image: unset;
    display: grid;
    justify-content: unset;
    }
    .monetisation-upgrade-left{
        width: 100%;
        padding: 21px 20px;
        display: unset;

    }
    .monetisation-serverurl-left {
        padding-top: 21px !important;
    }
    .monetisation-upgrade-right {
        padding: 20px 15px 18px;
        /* align-items: center; */
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .monetisation-purchase-right-android, .monetisation-purchase-right {
        padding: 0px;
    /* align-items: center; */
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    }
    .monetisation-purchase-right img{
        width: 50%;
    }
    .monetisation-upgrade-left .in-app-purchge-upgrade {
        width: 100%;
        font-size: 16px;
    }
    .monetisation-serverurl-left h2{
        font-size: 30px !important;
        line-height: 40px !important;
       }
       .monetisation-upgrade-left h2 {
        font-size: 30px !important;
    line-height: 35px !important;
    margin-bottom: 8px !important;
       }
       .monetisation-serverurl-div {
        margin: 0px -20px 0px;
        padding: 30px 15px 34px 15px;
        border: unset;
        border-bottom: 1px solid #E3E6F1;
        border-radius: unset;
       }
       .web-Connect-inapp-btn a {
        width: calc(100% + 10px);
    height: 50px;
    font-size: 16px;
    margin: 20px -5px 0px -5px;
       }
       .monetisation-connect-div{
        background-color: unset;
        border: unset;
        box-shadow: unset;
       }
       .mobandroid-datasave-btn{
        background-color: #3064f9;
    border: none;
    color: #fff;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Axiforma-Regular';
    font-size: 16px;
    margin-top: 20px;

       }
       .monatisation-head .premium-tag{
        display: flex;
        }
}