@font-face {
  font-family: "Proxima-Regular";
  src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../assets/fonts/ProximaNova-Bold.otf);
}
.download_app h3 {
  font: 22px "Axiforma-Bold";
}

.release_grey {
  font: 13px "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
  margin-right: 5px;
}

.builder_status {
  font: 14px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
}

.preview_status_alignment {
  text-align: left;
  padding-left: 15px;
}

.operational {
  font: 14px "Axiforma-Regular";
  color: #47ce70;
}
.description {
  color: #ff4848;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
@media screen and (max-width: 1200px) {
  .resizebutton {
    width: 50% !important;
  }
  .resizeappbundle {
    width: 50% !important;
  }
}
