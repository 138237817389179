@font-face {
    font-family: "Axiforma-Regular";
    src: url(../fonts/Axiforma-Regular.woff);
  }
  @font-face {
    font-family: "Axiforma-Regular2";
    src: url(../fonts/Axiforma-Regular2.woff2);
  }
  @font-face {
    font-family: "Axiforma-Bold";
    src: url(../fonts/Axiforma-Bold.ttf);
  }
.upgrade-popuptext-div{
    display: flex;
}
.splash-icon-head-mobile{
    display: none;
}
.upgrade-popuptext-first-ul{
    margin-right: 55px;
}
.upgrade-protio-popup .protip-popup-one {
    padding: 90px 30px;
}
.upgrade-protio-popup .protip-popup-one h6 {
    margin-bottom: 30px;
}
.upgrade-protio-popup .protip-popup-one h4 {
    margin-bottom: 20px;
}
.upgrade-protio-popup .protip-popup-one ul li {
    margin-bottom: 17px;
}
.upgrade-protio-popup .protip-popup-one a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 44px;
    margin-top: 19px;
    background-color: #3064F9;
    border-radius: 3px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-family: "Axiforma-Regular";
}


/* 
.app-icon-page h1{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin: 0px;
    margin-top: -5px;
} */
/* .app-icon-page .apperance-head button{
    margin-top: -5px;
} */
/* .app-icon-page h1 svg{
    margin-left: 6px;
} */
.apperance-module-tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 18px;
}
.apperance-module-tabs div{
    display: flex;
    align-items: center;
}
.apperance-module-tabs div a{
    color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px 60px -5px 0px;
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
    transition: .5s;
    &:hover {
        color: var(--custom_color4);
        border-bottom: 2px solid var(--custom_color5);
      }
    &:active {
        color: var(--custom_color4);
        border-bottom: 2px solid var(--custom_color5);
    }
}
.apperance-module-tabs div a.active , .apperance-module-tabs div a:hover{
    transition: .5s;
}
.apperance-head{
    display: flex;
    align-items: center;
    gap: 20px;
}
.apperance-head .primary-submit-web-btn , .apperance-head .primary-submit-web-disable-btn{
    margin-left: 0px;
    min-width: 87px;
}
/* .apperance-head p{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    margin-right: 20px;
    text-align: center;
} */
.apperance-module-tabs button{
    text-decoration: none;
    border-radius: 3px;
    color: white;
    background-color: #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 18px;
    transition: 0.5s;
    cursor: pointer;
    width: 100px;
    height: 40px;
    border: none;
    &:hover {
        color: var(--custom_color4)!important;
        background-color: var(--custom_color5)!important;
    }
}
.apperance-module-tabs button:hover{
  transition: 0.5s;
}
.splash-section{
    background-color: #fff;
    min-height: 78vh;
    /* overflow: hidden; */
    background-image: url(../images/dashboard/app-icon-background.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    justify-content: center;
    padding-top: 62px;
    padding-bottom: 89px;
    position: relative;
    background-size: contain;
    box-shadow: 0 2px 6px rgba(0,0,0,.06);
}
.splash-setting-section{
    background-color: #fff;
    min-height: 78vh;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0 2px 6px rgba(0,0,0,.06);
    display: flex;
}
.splash-setting-preview{
    max-height: 655px;
    width: 306px;
    background-color: #fff;
    margin: auto;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    overflow-y: hidden;
    border: 4px solid #E3E6F1;
    border-radius: 20px;
    overflow: hidden;
}
.splash-apply-duration-left-div{
width: 50%;
padding: 20px 20px 0px 30px;
}
.splash-apply-duration-head{
    padding: 0px 0px 20px;
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 30px;
}
.splash-apply-duration-h2{
    font-family: 'Axiforma-Bold';
    font-size: 18px;
    line-height: 25.29px;
    color: #1B2952;
    margin-bottom: 6px;
}
.splash-apply-duration-label{
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    line-height: 17.47px;
    color: #7782A1;
    margin-bottom: 10px;
}
.splash-apply-duration-para{
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    line-height: 17.47px;
    text-align: left !important;
    color: #7782A1;
    margin-bottom: 0px;
}
.splash-apply-duration-input-div{
    position: relative;
}
.splash-apply-duration-input{
font-family: 'Axiforma-Regular';
font-size: 13px;
line-height: 17.47px;
text-align: left;
border: 1px solid #BCC1CE;
width: 100%;
height: 50px;
padding: 15px 85px 15px 15px;
outline: none;
border-radius: 3px;
}
/* Chrome, Safari, Edge, Opera */
.splash-apply-duration-input::-webkit-outer-spin-button,
.splash-apply-duration-input::-webkit-inner-spin-button { 
  margin-right: 12px;
}


.splash-apply-duration{
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    line-height: 17.47px;
    color: #BCC1CE;
    position: absolute;
    top: 16px;
    margin-bottom: 0px;
    right: 37px;
}
.splash-setting-preview-div{
    width: 50%;
    padding-top: 60px;
}
.splash-section .splash-screen-spark{
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    fill: #bcc1ce;
    stroke: #bcc1ce;
    transition: 0.5s;
}
.splash-screen-width{
    display: flex;
    margin: auto;
    max-width: 1420px;
    justify-content: space-between;
}
.splash-section .splash-screen-spark:hover svg{
    fill: #F2C400;
    stroke: #F2C400;
    transition: .5s;
}
.splash-section-one-width{
    max-width: 520px;
    min-width: 520px;
    margin-top: 73px;
    background-color: #fff;
}

.splash-section-one-h2{
    color: #1B2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    padding-top: 0px;
}
.splash-section-one-para{
    color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Regular2";
    line-height: 18px;
    margin-top: 14px;
}
.splash-screen-buttons{
    display: flex;
    align-items: center;
    margin-top: 42px;
    margin-bottom: 40px;
}
.splash-screen-buttons span:nth-child(1){
    width: 200px;
    background-color: #3064F9;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Axiforma-Regular2";
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;
    line-height: 18px;
    &:hover {
        background-color: var(--custom_color5)!important;
        color: var(--custom_color4)!important;
      }
}
.splash-screen-buttons span:nth-child(1):hover{
    transition: .5s;
}
.splash-screen-buttons span:nth-child(2){
    border-radius: 3px;
    color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Regular2";
    text-align: center;
    margin-left: 39px;
    cursor: pointer;
    transition: .5s;
    line-height: 18px;
    &:hover {
        color: var(--custom_color6)!important;
      }
}
.splash-screen-buttons span:nth-child(2):hover{
    /* color: #3064F9; */
    transition: .5s;
}
.splash-screen-options span{
    border-radius: 3px;
    color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Regular2";
    text-align: center;
    margin-right: 39px;
    display: none;
}
.ask-an-expert , .ask-an-expert:hover{
    color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Regular2";
    text-decoration: none;
    margin-right: -2px;
    line-height: 18px;
}
.splash-screen-options .ask-an-expert ,.splash-screen-options .ask-an-expert:hover{
    margin-left: -2px;
}


.download-icon
{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    transition: 1.5s;
}
.splash-preview-child:hover .download-icon{
    opacity: 1;
    transition: 1.5s;
}
.download-icon svg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}

.protip-popup{
    display: flex;
}
.protip-popup-one {
    width: 50%;
    padding: 56px 30px;
  }
  .protip-popup-crossmodal {
    position: absolute;
    right: 15px;
    top: 6px;
    cursor: pointer;
}
.protip-popup-two{
    width: 50%;
    background-color: #F8FAFE;
    display: flex;
    align-items: center;
    justify-content: center;
}
.protip-popup-message{
    max-width: 1000px!important;
}
.protip-popup-message .modal-body{
    padding: 0px;
}
.protip-popup-one button{
    background-color: #3064F9;
    width: 120px;
    height: 40px;
    border-radius: 3px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-top: 20px;
}
.protip-popup-okay-btn{
    background-color: #3064F9;
    width: 120px;
    height: 40px;
    border-radius: 3px;
    border: none;
    color: #fff;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-top: 20px;
}
.protip-popup-one h4{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 20px; 
    margin-top: 0px;
    line-height: 30px;
}
.protip-popup-title{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
  margin-bottom: 16.5px;  
    margin-top: 0px;
    line-height: 30px;
}
.protip-popup-one h6{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 20px;
}
.protip-popup-desc{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 20px;
}
.protip-popup-one ul{
    margin-bottom: 0px;
    list-style-image: url('../images/dashboard/grey-tick-light.png');
    padding-left: 20px;
}
.protip-popup-list{
    margin-bottom: 0px;
    list-style-image: url('../images/dashboard/grey-tick-light.png');
    padding-left: 20px;
}
.protip-popup-one ul li{
    color: #7782A1;
    font-size: 13px;
    line-height: 24px;
    font-family: "Axiforma-Regular2";
    margin-bottom: 10px;
}
.protip-popup-list-item{
    color: #7782A1;
    font-size: 13px;
    line-height: 24px;
    font-family: "Axiforma-Regular2";
    margin-bottom: 10px;
}
.protip-popup-message .modal-content{
    overflow: hidden;
    border-radius: 6px;
}

.app-icon-small-preview{
    width: 45px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;
}



.splash-icon-grid{
    position: absolute;
    /* width: 100%; */
    height: 100%;
    top: 0;
    opacity: 1;
    transition: 1.5s;
    width: 302px;
    left: 0px;
    right: 0px;
    margin: auto;
}
.splash-the-tooltip{
    display: flex;
    align-items: center;
    margin-top: 22px;
    transition: 0.2s;
    justify-content: center;
}
.splash-the-tooltip .grid-line-show-tooltip {
    top: -95px;

}
.splash-the-tooltip .grid-line-show-tooltip::after {
    top: 110%;
    transform: rotate(270deg);
}
.spalsh-grid-line-icon-show{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    cursor: pointer;
    line-height: 17px;
}
.tooltip-view{
    position: relative;
    display: inline-block;
    margin-bottom: 3px;
}
.tooltip-view:hover .grid-line-show-tooltip-splash{
    visibility: visible;
    transition: 0.2s;
}
.splash-the-tooltip svg{
    margin-left: 7px;
}
.grid-line-show-tooltip-splash{
    visibility: hidden;
    width: 270px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: -135px;
    left: -170px;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.2s;
    cursor: text;
}
.grid-line-show-tooltip-splash::after {
    content: "";
    position: absolute;
    top: 108%;
    right: 78px;
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(270deg);
}
.slash-grid-line-icon-hide{
    color: #BCC1CE;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    cursor: pointer;
}
.edit-design-mobile-link{
    display: none;
}
.icon-slide-controls{
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
}
.icon-left-arow{
    width: 32px;
    height: 32px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    z-index: 1;
    background-image: url(../images/dashboard/arrow-left-slider.png);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
}
.icon-right-arow{
    width: 32px;
    height: 32px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    z-index: 1;
    background-image: url(../images/dashboard/arrow-right-slider.png);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
}
.apperance-slider{
    max-width: 420px;
    margin-top: -66px;
    animation: thefadeIn 3s;
    background-color: #fff;
    margin-left: 18px;
}
.icon-slide-1{
    width: 420px;
    margin: auto;
    padding-bottom: 100px;
}
.icon-slide-2{
    width: 302px;
    margin: auto;
}
.arrow-disabled{
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    cursor: auto;
    opacity: .5;
}
.the-icon-slider{
    display: flex;
    overflow: hidden;
}
.the-icon-width{
    min-width: 100%;
    transition: all 1s;
}
.the-icon-width-slide{
    transform: translateX(-100%);
    transition: all 1s;
}
.download-splash-popup-modal .modal-content{
    background-color: rgba(0, 0, 0, 0.7);
}
.download-splash-popup{
    position: relative;
}
.downloaded-icon{
    /* position: absolute;
    top: 31px;
    right: 0;
    left: 0;
    margin: auto; */
}


.download-icon-popup-modal .modal-body{
    display: flex;
        align-items: center;
}
.download-splash-popup-footer{
    position: fixed;
    bottom: 15px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin: auto;
    right: 0;
    left: 0;
}
.splash-mob-footer-save{
    display: none;
}
.app-icon-preview{
    position: relative;
}

.splash-section-two{
    text-align: center;
    width: 58%;
   
}
.splash-preview{
    width: 363px;
    margin: auto;
    background-color: #fff;
    animation: splashanimation 2.5s;
    height: 651px;
}
@keyframes splashanimation {
    0% { opacity: 0; }
    60% { opacity: 0; }
    100% { opacity: 1; }
  }
.grid-line-splash{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: auto;
    margin-top: 20px;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
}
.grid-line-splash-tooltip{
    visibility: hidden;
    width: 100%;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: -111px;
    left: 0;
    padding: 14px 22px 14px 22px;
    opacity: 0.8 !important;
    font-size: 11px;
    line-height: 21px;
    display: grid;
    transition: 0.5s;
    cursor: text;
}
.grid-line-splash-tooltip::after {
    content: "";
    position: absolute;
    bottom: -18%;
    right: 46%;
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(270deg);
}
.grid-line-splash:hover .grid-line-splash-tooltip{
    visibility: visible;
    transition: 0.5s;
}
.grid-line-splash svg{
    margin-right: 7px;
}
.the-app-icon{
    animation: thefadeIn 3s;
}
.splash-right{
    padding: 125px 30px!important;
}

@keyframes thefadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .splash-screen-spark .protiptooltip {
    visibility: hidden;
    width: 71px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: -85px;
    padding: 8px 9px 6px 16px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
}
.splash-screen-spark:hover .protiptooltip{
    visibility: visible;
    text-align: left !important;
}
.splash-screen-spark .protiptooltip::after {
    content: "";
    position: absolute;
    left: 100%;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
    top: 12px;
}
.splash-preview-child {
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
    transition: 2s;
    height: 100%;
    position: relative;
}
.splash-preview-child img{
    animation: thefadeIn 3s;
    height: 100%;
}
 .mob-splash-img{
    display: none;
    }

.splash-subheader{
    display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 20px
}
.splash-screen-buttons .primary-submit-web-btn , .splash-screen-buttons .primary-submit-web-disable-btn{
    width: 200px;
    height: 50px;
    margin-left: 0px;
}
.splash-start-and-edit-design{
    display: none;
}
.popup-cross-svg{
    width: 10px;
}
.splash-screen-scalling-div{
    height: 100%;
    box-shadow: 0px 10px 20px 0px #0000001A;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.splash-appicon-animation-loader{ 
    width: 200px;
      height: 112px;
}
.splash-animation-scale-text{
  font-family: 'Axiforma-Regular';
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  color: #7782A1;
  margin-bottom: 10px;
}
.splash-animation-scale-later{
  font-family: 'Axiforma-Regular';
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  color: #BCC1CE;
  margin-bottom: 0px;
}























  @media (min-width: 2000px) {
    .splash-preview {
        width: 361px;
    }
    .splash-section{
        max-height: inherit;
    }
    .grid-line-splash-tooltip {
        top: -90px;
    }
  }
  @media (min-width: 1701px) and (max-width: 1790px) {
    .splash-screen-width {
        max-width: 1315px;
    }
    .splash-section-one-width {
        max-width: 450px;
        min-width: 450px;
        /* margin-top: 68px; */
    }
  }

@media (min-width: 1501px) and (max-width: 1700px) {
   
    .splash-section-one {
        padding-left: 40px;
        margin-right: 10px;
    }
    .splash-section-one-para {
        width: 90%;
    }
    .splash-screen-width {
        max-width: 1200px;
        /* align-items: center; */
    }

    .splash-section-one-h2 {
        font-size: 40px;
        line-height: 50px;
       
    }
    .splash-section-one-width {
        max-width: 420px;
        min-width: 420px;
        /* margin-top: 0px; */
    }
    /* .splash-preview {
        width: 270px;
    } */
    .splash-screen-options {
        margin-bottom: 0px;
    }
}
@media (min-width: 1201px) and (max-width: 1500px) {
    .splash-section-one-h2{
        font-size: 35px;
        line-height: 50px;
        width: 100%;
    }
    .splash-section-one {
        padding-left: 80px;
        margin-right: 50px;
    }
    .splash-section-one-para{
        width: 90%;
    }
    .splash-screen-width {
        /* align-items: center; */
    }
    /* .splash-preview {
        width: 210px;
    } */
    .splash-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .splash-section-two{
        margin-right: 30px;
    }
    .splash-section-one-width {
        max-width: 450px;
        min-width: auto;
        /* margin-top: 60px; */
    }
    .splash-screen-buttons span:nth-child(2) {
        margin-left: 25px;
        font-size: 13px;
    }
    .splash-screen-buttons span:nth-child(1) {
        width: 160px;
        height: 40px;
        font-size: 13px;
    }
}
@media (min-width: 1201px) and (max-width: 1290px) {
    .splash-section-one-h2{
        font-size: 36px;
        line-height: 42px;
    }
    .splash-screen-buttons span:nth-child(1) {
        width: 140px;
        font-size: 13px;
        height: 40px;
    }
    .splash-screen-buttons span:nth-child(2) {
        margin-left: 25px;
        font-size: 13px;
    }
    .apperance-module-tabs div a {
        margin: 0px 50px -5px 0px;
        padding-bottom: 5px;
    }
    .apperance-module-tabs {
        margin-top: 10px;
        margin-bottom: 12px;
    }
    .splash-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .splash-section-one {
        padding-left: 50px;
        margin-right: 0px;
    }
    .splash-section-one-width {
        max-width: 450px;
        min-width: auto;
        /* margin-top: 60px; */
    }
    .splash-section-one-para{
        width: 90%;
        margin-top: 16px;
    }
    .splash-section-two{
        margin-right: 30px;
    }
}
@media (max-width: 1200px) {
    .protip-popup-crossmodal { 
        top: 40px;
        right: 20px;
    }
    .popup-cross-svg{
        width: 14px;
    }
    .splash-setting-preview-div{
        display: none;
    }
    .splash-apply-duration-maindiv{
        padding: 0px 15px 30px 15px;
    }
    .splash-setting-section {
        min-height: unset;
    }
    .splash-apply-duration-head {
        padding: 20px 15px 20px;
    }
    .splash-apply-duration-left-div{
        width: 100%;
        padding: 0px;
        }
    .protip-popup-one {
        width: 100%;
        padding: 0px 20px;
      }
      .splash-preview {
        height: unset;
      }
    .splash-screen-buttons .primary-submit-web-btn {
        width: 100%;
    }
    .splash-subheader{
        display: none;
    }
    .splash-section .splash-screen-spark {
        position: absolute;
        top: 20px;
        right: 15px;
    }
    .splash-preview-child{
        width: 363px;
    }
    .splash-start-and-edit-design{
        display: block;
        color: #3064F9;
        font-size: 14px;
        font-family: "Axiforma-Regular";
        text-align: center;
        margin: 40px auto;
        line-height: 18px;
        margin-bottom: 40px;
    }
    .splash-icon-head-mobile{
        display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
    }
    .upgrade-popuptext-div{
        display: unset;
    }
    .upgrade-protio-popup .protip-popup-one a {
        width: 100%;
        margin-bottom: 30px;
    }
    .upgrade-protio-popup .protip-popup-one {
        padding: 145px 20px 0px 20px !important;
    }
    .upgrade-protio-popup .modal-content{
        overflow-y: auto !important;
    }
    .upgrade-popuptext-first-ul {
        margin-right: 0px;
    }
    .splash-section{
        background-image: none;
        padding-top: 0px;
        padding-bottom: 10px;
        max-height: initial;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    }
    .ask-an-expert , .ask-an-expert:hover{
        margin-right: 0px;
        margin-top: 0px;
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .splash-the-tooltip{
        margin-top: 27px;
    }
    .splash-the-tooltip svg{
        margin-left: 10px;

    }
    .icon-left-arow,.icon-right-arow{
        width: 40px;
        height: 40px;
    }
    .splash-section-one-width {
        max-width: 100%;
        min-width: 100%;
        margin-top: 20px;
    }
    .splash-icon-page{
        padding: 20px 15px 15px 15px;
    }
    .protip-popup-one h6 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 23px;
    }
    .protip-popup-one ul li {
        font-size: 14px;
          }
          .protip-popup-one button {
            font-size: 16px;
            height: 50px;
            width: 100%;
          }
    .splash-section-one {
        width: 100%;
        margin-right: auto;
    }
    .splash-section-one-para{
        text-align: left!important;
        margin-top: 15px;
        margin-bottom: 0px;
        line-height: 18px;
    }
    .apperance-module-tabs{
        display: none;
    }
    .splash-screen-buttons a:nth-child(2){
        display: none;
    }
    .splash-screen-buttons {
        /* margin-top: 34px; */
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .splash-section-one-h2{
        color: #1B2952;
        font-size: 30px;
        font-family: "Axiforma-Bold";
        line-height: 40px;
        padding-top: 0px;
    }
    .splash-screen-buttons a:nth-child(1) {
        width: 100%;
    }
    .protip-popup-two{
        display: none;
    }

   .splash-screen-options span{
        display: flex;
    }
    .splash-screen-buttons span:nth-child(2){
        display: none;
    }
    .splash-screen-buttons span:nth-child(1) {
        width: 100%;
        font-size: 16px;
    }
    .apperance-slider {
        width: 100%;
        margin-top: 5px;
        margin-left: 0;
        margin-bottom: -20px;
    }
    .splash-screen-options{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  
    .app-icon-page h1{
        display: none;
    }
  
    .splash-screen-mob-head{
        color: #1B2952;
        font-size: 20px;
        font-family: "Axiforma-Bold";
        margin: 0px;
    }
    .app-icon-head-mobile .app-icon-spark {
        cursor: pointer;
        fill: #bcc1ce;
        stroke: #bcc1ce;
        transition: .5s;
        position: relative;
        margin-top: -11px;
    margin-right: 10px;
    }
    /* .splash-section .app-icon-spark{
        display: none;
    } */
     .app-icon-spark svg{
        height: 21px;
        width: 21px;
    }
    .protip-popup-message .modal-content {
        border-radius: 0px;
        border: none;
    }
    .edit-design-mobile-link{
        display: block;
        color: #3064F9;
        font-size: 14px;
        font-family: "Axiforma-Regular";
        text-align: center;
        margin: 40px auto;
        line-height: 18px;
        margin-bottom: 40px;
    }
    .icon-slide-1 , .icon-slide-2 {
        width: 100%;
    }
    .grid-line-show{
        padding-bottom: 0px;
    }
    .splash-mob-footer-save{
        display: block;
        margin-top: 20px;
    }
  
    .the-icon-width .splash-screen-options{
        margin-bottom: 0px;
    }
    .icon-slide-2 .edit-design-mobile-link{
        margin: 5px auto 50px auto!important;
    }
    .icon-slide-controls {
        margin-bottom: 30px;
        width: 122px;
    }
  
    .web-splash-img{
        display: none;
    }
    .mob-splash-img{
    display: block;
    margin: auto;
    padding-bottom: 22px;
    }
    .download-icon
    {
        display: none;
    }
    .splash-screen-width {
        display: block;
    }
    /* .splash-section .splash-screen-spark{
        display: none;
    } */
    .splash-section-two{
        width: 100%!important;
    }
    .splash-section-one{
        padding: 0px 20px 39px 20px;
    }
    .splash-preview {
        width: 100%;
        padding: 60px 15px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .the-icon-slider{
        overflow: hidden;
    }
    .icon-slide-1{
        padding-bottom: 0px;
    }
}


@media screen and (max-height: 800px) {
    .upgrade-protio-popup .protip-popup-one {
        padding: 80px 20px 20px 20px !important;
    }
}
@media screen and (max-width: 423px) {
    .splash-preview-child {
        width: unset;
    }
    .splash-icon-grid {
        width: 83.198%;
    }
}