.select-tag-type {
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.select-tag-type .form-group {
  margin-right: 31px;
  margin-bottom: 40px;
  position: relative;
}
.select-tag-type label {
  margin-bottom: 0px !important;
  width: 100%;
}
.tagtype-ratio > input {
  display: none;
}
.cms-setting-btn{
  margin-left: 20px;
}
.select-tag-type .tag-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 241px;
  height: 530px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  border-radius: 3px;
  transition: .5s;
  border: 2px solid #E3E6F1;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 6%)!important;
  background-color: #fff;
}
.tagtype-ratio input:checked + .tag-layout, .tagtype-ratio:hover input + .tag-layout {
  border: 2px solid #3064F9;
  box-shadow: 0px 3px 26px rgb(0 0 0 / 16%)!important;
  transition: .5s;
  background-color: #F9F9F9;
}
.tagpagelayout-svg svg{
  stroke: unset !important;
}
.tagpagelayout-svg{
  stroke: unset !important;
}
.taglayoutbg {
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #E3E6F1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
  padding: 19px 30px 30px 30px;
  min-height: 79vh;
}
.tag-layout-header{
  border-bottom: 1px solid #E3E6F1;
  margin-bottom: 30px;
}
.tag-layout-header h1{
  font-size: 18px;
  font-family: 'Axiforma-Bold';
  color: #1B2952;
  margin: 0px;
}
.tag-layout-header p{
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin: 7px 0px 19px 0px;
  line-height: 17px;
  text-align: left !important;
}
.tagsettingbg{
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #E3E6F1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
  padding: 19px 30px 30px 30px;
  min-height: 76vh;
}
.tagsetting-width{
  display: flex;
}
.tagsetting-left{
  width: 50%;
  min-width: 50%;
}
.tagsetting-right{
    width: 50%;
    padding: 75px 30px 60px 30px;
    text-align: center;
}
.tagsetting-preview{
  height: 651px;
  width: 302px;
  background-color: #F9F9F9;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: auto;
  overflow-x: hidden;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
}
.tagsetting-preview::-webkit-scrollbar {
  width: 0px !important;
}
.tagsetting-preview::-webkit-scrollbar-track {
  background-color: rgba(256, 256, 256, 0.2) !important;
  border-radius: 9px !important;
}
.tagsetting-preview::-webkit-scrollbar-thumb {
  background-color: #bcc1ce !important;
  border-radius: 9px !important;
}
.tagsetting-left-header{
  border-bottom: 1px solid #E3E6F1;
  margin-bottom: 10px;
}
.tagsetting-left-header h1 {
  font-size: 18px;
  font-family: 'Axiforma-Bold';
  color: #1B2952;
  margin: 0px;
}
.tagsetting-left-header p {
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin: 7px 0px 19px 0px;
  line-height: 17px;
  text-align: left !important;
}
.tags-layout-setting .color_input {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: .5s !important;
}
.tags-layout-setting h6{
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin-top: 15px;
}
.tags-layout-setting .color_input p {
  font-size: 13px;
  color: #1B2952;
}
.tag-arrow-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tag-arrow-toggle{
  margin-top: 30px;
  margin-bottom: 10px;
}
.tag-arrow-toggle h4{
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin: 0px;
}
.tagsetting-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px !important;
  margin-left: 5px !important;
}
.tagsetting-tooltip .tagsetting-tooltipsubheader {
  visibility: hidden;
  width: 250px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.tagsetting-tooltip .tagsetting-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.tagsetting-tooltip:hover .tagsetting-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.tagsetting-preview-header{
  display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
    height: 45.5px;
    padding: 0px 10px 0px 11px;
    margin-bottom: 17.5px;
}
.tagsetting-preview-header div{
  display: flex;
  align-items: center;
}
.tagsetting-preview-header div h4{
  font-size: 13px;
  font-family: 'Axiforma-Bold';
  color: #1A1A1A;
  margin: 0px 0px 0px 10px;
}
.tag-type-one div p{
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  color: #1A1A1A;
  margin: 0px;
  word-break: break-word;
  text-align: left;
}
.tag-type-one div{
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px 16px 11px;
    /* margin-bottom: 16px; */
    margin-top: 28px;
}
.tag-type-two{
  display: flex;
  flex-wrap: wrap;
  padding: 0px 13px;
}
.tag-type-two div{
  /* background-color: #B0B0B0; */
  border-radius: 96px;
  padding: 6px 16px 4px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.tag-type-two div p{
  font-size: 9px;
  font-family: 'Axiforma-Regular';
  /* color: #FFFFFF; */
  margin: 0px;
  word-break: break-word;
  text-align: left;
}
.category-setting-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category-setting-toggle{
  margin-top: 30px;
  margin-bottom: 30px;
}
.category-setting-toggle h4{
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin: 0px;
}
.catsetting-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px !important;
  margin-left: 5px !important;
}
.catsetting-tooltip .catsetting-tooltipsubheader {
  visibility: hidden;
  width: 250px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.catsetting-tooltip .catsetting-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.catsetting-tooltip:hover .catsetting-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.catsetting-preview-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E4E4E4;
  height: 39.3px;
  padding: 0px 10px 0px 11px;
  margin-bottom: 21px;
}
.catsetting-preview-header div{
  display: flex;
  align-items: center;
}
.catsetting-preview-header div h4{
  font-size: 13px;
  font-family: 'Axiforma-bold';
  color: #1A1A1A;
  margin: 0px 0px 0px 10px;
}
.categorysettingbg {
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #E3E6F1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
  padding: 19px 30px 30px 30px;
  min-height: 76vh;
}
.categorysetting-width {
  display: flex;
}
.categorysetting-left {
  width: 50%;
  min-width: 50%;
}
.categorysetting-right {
  width: 50%;
  padding: 55px 30px 60px 30px;
  text-align: center;
}
.category-column-number input {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  height: 50px;
  width: 100%;
  padding: 0px 15px;
  transition: .5s;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.color-picker-cross{
  position: relative;
}
.color-picker-cross svg{
  position: absolute;
  right: 20px;
  top: 19px;
}
.select-cat-dimension{
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.select-cat-dimension .form-group {
  margin-left: 30px;
  margin-bottom: 0px;
  position: relative;
}
.select-cat-dimension label {
  margin-bottom: 0px !important;
  width: 100%;
}
.select-cat-dimension-ratio > input {
  display: none;
}
.select-cat-dimension .cat-dimension-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
}
.select-cat-dimension .cat-dimension-layout svg{
  stroke: #e3e6f1;
  transition: .5s;
}
.select-cat-dimension-ratio input:checked + .cat-dimension-layout svg, .select-cat-dimension-ratio:hover input + .cat-dimension-layout svg{
  transition: .5s;
  stroke: #7782a1;
}
.category-image-dimension{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.category-image-dimension h4{
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin: 0px;
}
.select-cat-dimension p{
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  color: #BCC1CE;
  margin: 0px 0px 0px 6px;
}
.select-cat-dimension-ratio input:checked + .cat-dimension-layout p, .select-cat-dimension-ratio:hover input + .cat-dimension-layout p{
  color: #7782A1;
}
.category-layout-one{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 13px 21px 13px;
}
.cat-thumb-one{
  display: flex;
  align-items: center;
}
.cat-thumb-one div{
  width: 48px;
  /* height: 48px;
  max-height: 48px;
  min-height: 48px; */
  min-width: 48px;
  max-width: 48px;
  position: relative;
  margin-right: 10px;
}
.cat-thumb-one p{
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  margin: 0px 10px 0px 0px;
  word-break: break-word;
  text-align: left;
}
.cat-thumb-two div{
  position: relative;
}
.cat-layout-tint{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
  z-index: 1;
}
.category-layout-two{
  display: flex;
  flex-wrap: wrap;
  margin: 0px 10px 0px 10px;
  grid-gap: 10px;
  gap: 10px;
}
.cat-thumb-two p{
  font-size: 10px;
    font-family: 'Axiforma-Regular';
    text-align: center;
    margin: 7px 0px 11px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical; 
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;  
    word-break: break-word;
}
.category-layout-three{
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  margin: 0px 10px 0px 10px;
  grid-gap: 10px;
  gap: 10px;
}
.cat-thumb-three{
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cat-thumb-three p{
  font-size: 10px;
  font-family: 'Axiforma-Regular';
  color: #1A1A1A;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: max-content;
  z-index: 1;
  padding: 0px 4px;
  word-break: break-word;
  line-height: 13.44px;
}
.category-layout-four svg{
  min-width: 10px;
}
.category-layout-one svg{
  min-width: 10px;
}
.category-layout-four{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* background-color: #ccc; */
  border-radius: 10px;
  padding: 6px 10px 6px 6px;
  margin: 0px 10px 9px 10px;
  min-height: 61px;
}
.category-layout-four div{
  display: flex;
  align-items: center;
}
.category-layout-four div p{
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  margin: 0px 6px 0px 8px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;  
  word-break: break-word;
}
.cat-heights-one p{
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  line-clamp: 5;  
  word-break: break-word; 
}
.cat-heights-two p{
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;  
  word-break: break-word; 
}
.cat-heights-three p{
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;  
  word-break: break-word;
}
.category-layout-five{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #ccc;
  border-radius: 10px;
  padding: 6px 6px 6px 10px;
  margin: 0px 10px 9px 10px;
  min-height: 61px;
}
.category-layout-five p{
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  margin: 0px 0px 0px 0px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;  
  word-break: break-word;
}
.category-layout-five .cat-setting-img-cover{
  margin-left: 10px;
}
.cat-setting-img-cover{
  background-repeat: no-repeat !important;
  background-position: center center !important;
  /* background-size: cover !important; */
  min-width: 48px;
}
.custom-layout-gap{
  margin-right: 0px!important;
  margin-left: 20px!important;
}
.custom-layout-gap-mob{
  display: none;
}

.mob-category-layout{
  padding: 0px 20px 0px 0px 
}
.mob-category-layout .tagsetting-left-header{
  margin-top: 5px;
}
.mob-category-layout .category-setting-toggle {
  margin-top: 25px;
}
.advertismentblock-input{
  border: 1px solid #BCC1CE;
  position: relative;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 14px 15px;
}
.advertismentblock-input:hover{
  background-color: #F8FAFE !important;
    border-color: #3064F9 !important;
    transition: .5s !important;
}
.advertismentblock-input p{
  font-size: 13px;
  color: #1B2952;
  font-family: 'Axiforma-Regular';
  width: calc(100% - 40px);
}
.advertismentblock-input svg{
  position: absolute;
  right: 15px;
  top: 14px;
}
.advertismentblock-type{
  color:#7782A1;
  font-family: 'Axiforma-Regular';
  font-size: 13px;

}
.mob-cms-view-setting-home-btn{
  display: none;
}












@media screen and (max-width: 1200px) {
  .category-column-number{
    padding: 0px 15px ;
  }
  .mob-cms-view-setting-home-btn{
    display: flex;
  }
  .notification-setting.web-post-home-btn, .web-cms-setting-btn{
    display: none !important;
  }
.select-tag-type .form-group {
  margin: auto;
  margin-bottom: 40px;
}
.taglayoutbg {
  padding: 25px 0px 30px 0px;
  margin: 10px 15px;
}
.tag-layout-header {
  margin-bottom: 40px;
}
.tag-layout-header p {
  margin: 5px 0px 20px 0px;
}
.tag-layout-header{
  padding: 0px 15px;
}
.tagsetting-right{
  display: none;
}
.tagsetting-left {
  width: 100%;
}
.tagsettingbg {
  min-height: auto;
  padding: 25px 15px 30px 15px;
  margin-top: 10px;
}
.tags-layout-setting{
  margin: 0px 15px;
}
.tags-layout-setting h6 {
  margin-top: 17px;
}
.categorysetting-right{
  display: none;
}
.categorysetting-left {
  width: 100%;
  min-width: 100%;
}
.select-cat-dimension p{
  display: none;
}
.select-cat-dimension .form-group {
  margin-left: 10px;
}
.select-cat-dimension .form-group:nth-child(1){
  margin-left: 0px;
}
.categorysettingbg {
  padding: 25px 0px 0px 0px;
  min-height: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}
.mob-category-layout{
  padding: unset;
}
.mob-category-layout .tagsetting-left-header{
  margin: 0px;
}
.mob-category-layout .category-setting-toggle {
  margin: 30px 0px ;
}
.tagsetting-left-header{
  padding: 0px 15px;
  margin: 0px -15px;
}
.category-setting-toggle{
  padding: 0px 15px;
  margin: 0px -15px;
}
.categorysetting-left .row{
  padding: 0px 15px;
}
.category-image-dimension{
  padding: 0px 15px;
}
.custom-layout-gap{
  display: none;
}
.custom-layout-gap-mob{
  display: flex;
  margin-right: 0px!important;
  margin-left: 20px;
}
.category-mobile-header-btns{
  display: flex;
  align-items: center;
  margin-top: -5px;
}
.tags-mobile-header-btns{
  display: flex;
  align-items: center;
}


}