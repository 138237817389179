.email-support-page {
  background-image: url(../images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  min-height: 100vh;
  background-size: contain;
  background-attachment: fixed;
}

.email-support {
  padding: 0px 30px 34px 30px;
  width: 540px;
  margin: auto;
  background-color: #fff;
  margin-top: 100px;
}
.email-support h1 {
  font-family: "Axiforma-Bold";
  color: #1b2952;
  font-size: 40px;
  margin-bottom: 14px;
  line-height: 56px; /* Height given in design */
}

.email-support h5 {
  font-family: "Axiforma-Bold";
  color: #1b2952;
  font-size: 24px;
  margin-bottom: 20px;
}

.email-support p {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 14px;
  margin-bottom: 23px;
  line-height: 18px;
}

.email-support li {
  list-style: none;
  text-align: right;
  position: relative;

  padding: 0px 28px;
}
.email-support li:after {
  content: "";
  display: table;
  border-right: 1px solid #c8cedb;
  height: 19px;
  width: 1px;
  position: absolute;
  top: 3px;
  right: 0;
}

.email-support li a {
  color: #7782a1 !important;
  font-size: 14px;
  cursor: pointer;

  text-align: center;
}
.email-support li:first-child {
  padding-left: 0px;
}
.email-support li:last-child {
  padding-right: 0px;
}
.email-support li:last-child:after {
  border-right: 0px;
}

.email-support ul {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  margin-top: -75px;
  margin-bottom: 106px;
}

.custom-width-right {
  width: 500px;
}

.custom-width-left {
  width: calc(100% - 500px);
}

.custom-width-left label {
  font-family: "Axiforma-Regular";
  color: #1b2952;
  font-size: 13px;
  margin-bottom: 10px;
}

.email-support .form-group select,
.email-support .form-group input {
  width: 100%;
  padding: 12px 20px;
  height: 50px !important;
  border-radius: 3px;
  border-color: #bcc1ce;
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  background-position: 98%;
  outline: none !important;
  transition: 0.5s;
}

.email-support-page textarea {
  resize: none;
  border-color: #bcc1ce !important;
  outline: none !important;
  transition: 0.5s;
  padding: 12px 12px 12px 12px !important;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  height: 100px !important;
}

.email-support .form-group select:focus {
  border-color: #7782a1;
  outline: none !important;
}

.email-support .form-control:focus {
  box-shadow: none;
  border-color: #3064f9 !important;
}

.email-support .forgot_pw {
  margin-top: 20px;
  margin-bottom: 0;
}
.email-support .forgot_pw .container1.container1-red {
  font-size: 13px;
  line-height: 17px;
  padding-top: 1px;
  padding-left: 30px;
  margin-bottom: 30px;
}
.upload-screenshot {
  font-family: "Axiforma-Regular";
  color: #1b2952;
  font-size: 15px;
  line-height: 21px;
  cursor: pointer;
}

.upload-screenshot span {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  margin-top: 4px;
}

.related_to {
  border: 1px solid #e3e6f1;
  border-radius: 5px;
  height: 70px;
  width: 200px;
}
.related_to.active {
  background: #f6f8ff;
  border-color: #87a6ff;
}
.related_to.active p {
  color: #000000;
}
.related_to.active path,
.related_to.active line,
.related_to.active rect:first-child,
.related_to.active circle {
  stroke: #3064f9;
}
.related_to.active rect:not(:first-child) {
  fill: #b4c7fe;
}
.related_to:nth-child(2) {
  margin-left: 20px;
  margin-right: 20px;
}
.related_to label {
  padding: 20px;
}
.related_to p {
  color: #c8cedb;
  font-size: 14px;
}
.custom-width-left .btn:hover {
  color: #fff;
}
.related_to .checkmark {
  height: 20px;
  width: 20px;
  border-radius: 30px;
  background-color: #fff;
}
.related_to .checkmark:after {
  right: -1px;
  top: 0px;
  width: 20px;
  height: 20px;
  border: solid #3064f9;
  border-width: 5px;
  transform: none;
  border-radius: 30px;
}
.related-app-info {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  padding: 15px 20px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.related-app-icon {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
  border-radius: 10px;
  overflow: hidden;
  filter: drop-shadow(0px 2px 12px rgba(188, 193, 206, 0.3));
  margin-right: 15px;
}
.related-app-basic {
  display: flex;
  align-items: center;
}
.related-app-basic h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 9px;
}
.related-app-basic h2 span {
  margin-left: 10px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  border-radius: 20px;
  padding: 2px 9px 1px 9px;
}
.related-app-basic h2 span.freeapp {
  border: 1px solid #c8cedb;
  background-color: #fcfdff;
  color: #c8cedb;
}
.related-app-basic h2 span.liveapp {
  border: 1px solid #47ce70;
  background-color: #effff4;
  color: #47ce70;
}

.related-app-basic h2 span.pausedapp {
  border: 1px solid #ffbaba;
  background-color: #fffcfc;
  color: #ff4848;
}

.related-app-basic h5 {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
.related-app-status p {
  margin-bottom: 10px;
  text-align: right;
  color: #1b2952;
  font-size: 12px;
}
.related-app-status p span {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.related-app-status h6 {
  margin-bottom: 0px;
  text-align: right;
  color: #7782a1;
  font-size: 12px;
}
.related-app-status h6 span {
  margin-bottom: 0px;
  text-align: right;
  color: #7782a1;
  font-size: 12px;
}
.related-app-status h6 span a,
.related-app-status h6 span a:hover {
  margin-bottom: 0px;
  text-align: right;
  color: #7782a1;
  font-size: 12px;
  text-decoration: underline !important;
}
.related-app-status h4 {
  color: #ff4848;
  font-size: 12px;
  text-align: right;
}
.platform-option {
  align-items: center;
  margin: 20px 0px 30px 0px;
}
.platform-option h3 {
  margin-bottom: 19px;
  margin-right: 100px;
  color: #7782a1;
  font-size: 13px;
  line-height: 17px;
}
.platform-option .container1 input ~ .checkmark {
  background-color: #fff;
}
.platform-option .container1:hover input ~ .checkmark {
  background-color: #fff;
}
.platform-option .container1 input:checked ~ .checkmark {
  background-color: #3064f9 !important;
}
.platform-option input ~ .checkmark {
  border-radius: 3px;
  border: 1px solid #bcc1ce;
}
.platform-option .checkmark:after {
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  top: 2px;
}
.email-support-page .form-group {
  margin-bottom: 17px;
  line-height: 1; /* To remove the effect of bootstrap class */
  font-size: 0; /* To remove the effect of bootstrap class */
}
.email-support-page .form-group.platform-option {
  margin-bottom: 26px;
}
.platform-option p {
  font-family: "Axiforma-Regular";
  color: #1b2952;
  font-size: 13px !important;
  line-height: 17px !important;
}
.agree-terms h4 {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px !important;
  margin-bottom: 0;
  line-height: 17px;
}
.upload-screenshot h5.upload-screenshot-text {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 0;
  line-height: 17px;
  padding-top: 2px;
}
.upload-screenshot span.email-support-format {
  white-space: nowrap;
}
.upload-screenshot img.add-image-email {
  height: 44px;
  width: 44px;
  margin-right: 10px !important;
}
.email-support-page .css-1ejncba-indicatorContainer {
  color: #b6bbc9 !important;
  margin-right: 0px !important;
}
/* .email-support-page .css-1w7vqxu-ValueContainer{
  width: calc(100% - 40px)!important;
} */
.email-support-page .css-1hb7zxy-IndicatorsContainer {
  width: 40px !important;
  display: none;
  /* display: flex;
  width: 70px!important;
  justify-content: end; */
}
.email-support-dropdown__placeholder.css-1wa3eu0-placeholder {
  margin-left: 0;
}

.agree-terms .checkmark,
.agree-terms label:hover .checkmark {
  background-color: #fff;
}
.agree-terms input:checked ~ .checkmark {
  background-color: #3064f9 !important;
}
.agree-terms .checkmark:after {
  top: 2px;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}
.customer-screenshot {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.set-customer-screenshot:nth-child(4n + 1) {
  margin-left: 0;
}
.set-customer-screenshot {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 105px;
  height: 105px;
  border: 1px solid #e3e6f1;
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
  /* margin-bottom: 30px; */
}
.customer-screenshot-edit {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.set-customer-screenshot:hover .customer-screenshot-edit {
  opacity: 1;
}
.customer-screenshot-edit p {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #e3e6f1;
  margin-bottom: 0px;
  cursor: pointer;
}
.email-support-warning {
  position: relative;
}
.email-support-warning h5 {
  position: absolute;
  top: 18px;
  right: 20px;
  color: #ff4848;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.set-customer-screenshot img {
  display: none;
}
.email-support-page .branding1,
.email-support-page .branding2 {
  position: fixed;
}
.email-support-bg {
  margin-top: -19px;
}
.email-thank-you {
  background-image: url(../images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  /* padding-top: 269px; */
  justify-content: center;
}

.search-dropdown {
  line-height: 1;
  font-size: 0;
}

.email-thank-you img {
  margin-bottom: 45px;
}
.email-thank-you h1 {
  font: 30px "Axiforma-Bold";
  color: #1b2952;
  line-height: 42px;
  margin-bottom: 0;
}
.email-thank-you p {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
  line-height: 18px;
  margin-top: 38px;
  margin-bottom: 0;
}
.email-to-myapp {
  width: 200px;
  height: 50px;
  color: #3064f9 !important;
  background-color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 35px;
  border-radius: 3px;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18px;
  line-height: 1;
}
.email-to-myapp:hover {
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.select-version-parent-box {
  position: relative;
}
.select-version-parent-box p {
  position: absolute;
  top: 16px;
  right: 40px;
  color: #ff4848;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.email-support-dropdown__control {
  border: 1px solid #bcc1ce !important;
  transition: 0.5s !important;
  cursor: pointer !important;
}
.email-support-dropdown {
  font-size: 13px !important;
}
.email-support-dropdown__option {
  font-size: 13px !important;
  line-height: 17px;
  background-color: #ffffff !important;
  color: #1b2952 !important;
  padding: 12px 14px !important;
  margin: auto;
  width: calc(100% - 10px) !important;
  font-family: "Axiforma-Regular" !important;
}
.email-support-dropdown__option:hover {
  font-size: 13px !important;
  line-height: 17px;
  background-color: #f8f9fd !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 14px !important;
}
.email-support-dropdown__value-container .email-support-dropdown__placeholder {
  color: #7782a1 !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  /* top: 23px; */ /* Commenting to make it center */
}
.email-support-dropdown__menu-list {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
}
.email-support-dropdown__menu {
  margin-top: 0px !important;
}
.email-support-btns {
  width: 100%;
  margin-bottom: 35px;
  margin-top: 30px;
}
.email-support-btns .fixed-buttons button {
  width: 100%;
  margin-top: 18px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #fff;
  min-width: 140px;
  height: 50px;
  border: none;
  border-radius: 3px;
}
.email-support-page .container {
  overflow: auto;
}
.email-support-footer {
  position: fixed;
  bottom: 40px;
  padding-right: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.email-support-footer svg {
  margin-left: 29px;
  cursor: pointer;
}
.search-dropdown .dropdown-toggle {
  white-space: normal !important;
  width: 100%;
  border: 1px solid #bcc1ce !important;
  border-radius: 3px;
  text-align: left;
  height: 50px;
  margin-bottom: 15px;
  color: #1b2952 !important;
  background-image: url(../images/dashboard/email-support-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  -webkit-appearance: none;
  padding: 3px 15px 0px 15px;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  font-size: 13px;
}
.search-dropdown .dropdown-menu {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
}
.email-support label {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  margin-bottom: 9px;
}
.email-support label.upload-screenshot {
  margin-bottom: 0px;
}
.email-support-dropdown__indicator .css-tj5bde-Svg {
  display: inline-block;
  fill: #bcc1ce;
  line-height: 1;
  stroke: #bcc1ce;
  stroke-width: 0;
}
.email-support .dropdown-toggle::after {
  display: none;
}
.dropdown-search {
  position: relative;
}
.dropdown-search p {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #bcc1ce;
  font-size: 12px;
  margin: 0px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.dropdown-search input {
  height: 50px !important;
  border-top: 1px solid #f1f2f8 !important;
  border-left: 1px solid #f1f2f8 !important;
  border-right: 1px solid #f1f2f8 !important;
  border-bottom: 1px solid #f1f2f8 !important;
  padding: 0px 60px 0px 16px !important;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
  margin-bottom: 5px;
}
.search-dropdown .form-group {
  margin-bottom: 0px !important;
}
.search-dropdown .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px !important;
  color: #1b2952;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  margin: auto !important;
  border: 0px solid #fff !important;
}
.search-dropdown .dropdown-item:hover {
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
}
.search-dropdown .dropdown-item.active,
.search-dropdown .dropdown-item:active {
  background-color: #f8f9fd !important;
  color: #1b2952 !important;
}
.search-dropdown-before {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.email-support-dropdown__control:hover,
.email-support-dropdown__control:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.search-dropdown .dropdown-toggle:hover,
.search-dropdown .dropdown-toggle:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}

.email-support .form-group input:hover,
.email-support .form-group input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.email-support textarea:hover,
.email-support textarea:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.platform-option p {
  margin-bottom: 0px !important;
}
.email-support .dropdown-search input:hover,
.email-support .dropdown-search input:focus {
  border: 1px solid #f1f2f8 !important;
  background-color: #fff !important;
}
.email-support-dropdown__value-container {
  padding: 2px 0px !important;
}
.email-support-dropdown__control {
  padding-left: 15px !important;
  background-image: url(../images/dashboard/email-support-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
}
.support-dropdown-no-app {
  width: 100%;
  margin-top: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-support-thanks {
  position: fixed;
  bottom: 40px;
  padding-right: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.email-support-thanks svg {
  margin-left: 29px;
  cursor: pointer;
}
.no-app-dropdown {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-support-dropdown__indicators {
  display: none !important;
}
.search-dropdown .dropdown-menu::-webkit-scrollbar-track {
  background-color: white !important;
}
.email-support-dropdown__option--is-disabled {
  color: #7782a1 !important;
}
.email-support-dropdown__option--is-disabled:hover {
  background-color: #ffffff !important;
  cursor: not-allowed;
}
.search-dropdown .select-default {
  color: #7782a1 !important;
}
.email-support-dropdown__placeholder {
  color: #7782a1;
}
.mobile-view-click:hover .email-support-dropdown__control {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.mobile-view-click .email-support-dropdown__single-value {
  font-size: 13px;
  line-height: 17px;
  color: #1b2952 !important;
  font-family: "Axiforma-Regular" !important;
}
.email-support-plan-section{
  background-color: #F8FAFE;
  border-radius: 6px;
  border: 1px solid #E3E6F1;
  padding: 22px 20px 21px 20px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.email-support-plan h3{
  color: #1B2952;
  font-size: 12px;
  margin: 0px;
  font-family: "Axiforma-Bold";
  line-height: 19px;
}
.email-support-plan h3 span{
  color: #BCC1CE;
  font-size: 12px;
  margin: 0px 0px 0px 6px;
  font-family: "Axiforma-Regular";
}
.email-support-plan a , .email-support-plan a:hover{
  color: #3064F9;
  font-size: 12px;
  margin: 0px;
  font-family: "Axiforma-Regular";
  margin-left: 30px;
}
.email-support-plan h5{
  color: #7782A1;
  font-size: 12px;
  margin: 6px 0px 0px 0px;
  font-family: "Axiforma-Regular";
  line-height: 16px;
}

.mobilesearchsuggestion-para{
  font-size:16px !important;
 color: #1B2952 ;
font-family: "Axiforma-Regular" !important;
margin: 0px 20px 40px 20px !important;
display: block !important;
}















@media screen and (max-width: 1200px) {
  .email-support {
    width: 100%;
    box-shadow: none;
    padding: 20px 20px 0px 20px;
    margin-top: 67px;
  }
  .email-support-page {
    padding-top: 0px;
    background-color: #fff;
    /* padding-bottom: 40px; */
    background: none;
    padding-bottom: 0;
    min-height: calc(100% - 133px);
  }

  .email-support-page .container {
    padding: 0px;
  }
  .related_to {
    border: 1px solid #e3e6f1;
    border-radius: 5px;
    height: 115px;
    width: 32%;
  }
  .related_to label {
    padding: 10px;
    display: block !important;
  }
  .related_to:nth-child(2) {
    margin: 0px;
  }
  .email-three-tabs {
    justify-content: space-between !important;
  }
  .email-three-tabs-one {
    margin-top: 20px;
    text-align: center;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .email-three-tabs .ml-3 {
    margin-left: 0px !important;
    text-align: center;
    margin-top: 15px;
  }
  .related_to .checkmark {
    right: 10px;
    top: 10px;
    left: initial;
    bottom: initial;
  }
  .related-app-info {
    display: block;
  }
  .related-app-status p {
    text-align: left;
    margin-bottom: 4px;
  }
  .related-app-status h6 {
    text-align: left;
  }
  .related-app-status {
    margin-top: 20px;
  }
  .platform-option {
    display: block;
  }
  .platform-option h3 {
    margin-bottom: 15px;
  }
  .agree-terms h4 {
    line-height: 23px;
    margin-top: -5px;
  }
  .set-customer-screenshot {
    width: 27.08%;
    margin-left: 4.9%;
    margin-top: 4.9%;
  }
  .set-customer-screenshot:nth-child(3n + 1) {
    margin-left: 0 !important;
  }
  .set-customer-screenshot:nth-child(4n + 1) {
    margin-left: 4.9%;
  }
  .email-support-warning h5 {
    top: 20px;
  }
  .customer-screenshot-edit {
    display: none;
  }
  .set-customer-screenshot img {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .agree-terms {
    margin-bottom: 20px;
  }
  .cus-changes {
    display: block;
  }
  .cus-changes .fixed-buttons {
    margin-top: 20px;
  }
  .email-thank-you h1 {
    font: 24px "Axiforma-Bold";
    line-height: 42px;
  }
  .email-thank-you h1 br {
    /* display: none; */
  }
  .mobilesearchsuggestion p {
    /* text-align: left !important;  This will be applied globally need to ask */
  }
  
  .email-thank-you p {
    font: 14px "Axiforma-Regular";
    line-height: 18px;
    margin-top: 10px;
  }
  .email-thank-you p br {
    display: none;
  }
  .email-thank-you img {
    margin-bottom: 19px;
  }
  .email-to-myapp {
    margin-top: 40px;
    width: 280px;
    font-size: 16px;
    line-height: 21px;
  }
  .email-thank-you .mt-4 {
    margin-top: 0px !important;
  }
  .email-support .forgot_pw .container1.container1-red {
    margin-bottom: 28px;
  }
  .email-support .forgot_pw {
    margin-top: 21px;
  }
  .email-support-btns .fixed-buttons button {
    font-size: 16px;
  }
  .email-mob-popup p {
    text-align: left !important;
  }
  .email-support-footer {
    position: relative;
    /* bottom: 40px; */
    padding-right: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    /* margin-top: 40px; */
    /* margin-top: -32px; */
    top: 27px;
    padding-bottom: 40px;
  }
  .email-support-footer svg {
    margin-left: 29px;
  }
  .email-support-footer a:first-child svg {
    margin-left: 20px;
  }
  .email-support-page .container {
    width: 100%;
    max-width: 100%;
  }
  .email-support h1 {
    font-size: 30px;
    margin-bottom: 8px;
  }
  .email-support-thanks {
    position: fixed;
    bottom: 0px;
    padding: 20px 0px 30px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    background-color: #fff;
  }
  .email-support-thanks svg {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
  }
  .email-thank-you {
    padding: 185px 20px 100px 20px;
    height: auto;
    display: block;
    min-height: 450px;
    background-image: none;
  }
  .email-support-bg {
    margin-top: -1px;
  }
  .email-support-plan-section {
    padding: 20px 15px 21px 15px!important;
    margin-bottom: 20px;
  }
  .email-support-plan h5 {
    font-size: 12px;
    text-align: left;
  }


}
