.rebuild-required-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rebuild-required-modal-cross{
    position: absolute;
    right: 15px;
    top: 6px;
    cursor: pointer;
}
.rebuild-required-modal-title{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    text-align: center;
    margin: auto;
    line-height: 25px;
    margin-top: 39px;
    margin-bottom: 15px;
}
.rebuild-required-modal-subtitle{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 25px;
    text-align: center;
    width: 90%;
    margin: auto;

}
.rebuild-required-modal-subtitle-proceed{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 25px;
    text-align: center;
    width: 90%;
    margin: auto;
    margin-top: 20px !important;
}
.rebuild-customer-footer{
    display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 36px;
}
.rebuild-required-modal-cancel-btn{
    border: 1px solid #C8CEDB;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #7782A1;
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    font-family: "Axiforma-Regular";
}
.rebuild-required-modal-cancel-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.rebuild-required-modal-proceed-btn{
    border: 1px solid #3064F9;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #fff !important;
    background-color: #3064F9;
    font-size: 14px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Axiforma-Regular";
}
.rebuild-required-modal-proceed-btn:hover{
background-color: #134bec;
background-color: #134bec;
transition: 0.5s;
}
@media screen and (max-width: 1200px) {
    .rebuild-customer-footer {
        flex-direction: column-reverse;
    }
    .rebuild-required-modal-proceed-btn{
            width: 280px;
            margin-left: 0;
            margin: auto;
        }
        .rebuild-required-modal-cancel-btn{
            width: 280px;
        border: none;
        margin: auto;
        margin-top: 20px;
        }
        .rebuild-required-modal .modal-body{
            display: flex;
                align-items: center;
                justify-content: center;
        }
    
}