.pages-layout{
    background-color: #fff;
    border-radius: 6px !important;
    border: 1px solid #E3E6F1 !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
    min-height: calc(100% - 63px);
    padding: 20px 30px 30px 30px;
}
.page-layout-header{
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 30px;
}
.page-layout-header-heading{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    line-height: 25.29px;
    color: #1B2952;
    margin-top: 0px;
    margin-bottom: 0px;
}
.page-layout-header-subheading{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    line-height: 25.29px;
    color: #7782A1;
    margin-top: 6px;
    margin-bottom: 20px;
}
.select-page-type {
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin-bottom: 0px !important;
    flex-wrap: wrap;
}
.select-page-type .form-group {
    margin-right: 31px;
    margin-bottom: 40px;
    position: relative;
}
.select-page-type label {
    margin-bottom: 0px !important;
    width: 100%;
}
.pagetype-ratio > input {
    display: none;
}
.select-page-type .page-layout {
    display: flex;
    justify-content: center;
    width: 242px;
    height: 532px;
    cursor: pointer;
    position: relative;
    margin-bottom: 0px;
    border-radius: 3px;
    transition: .5s;
    border: 2px solid #fff;
    /* box-shadow: 0px 3px 6px rgb(0 0 0 / 6%) !important; */
    background-color: #fff;
}
.pagetype-ratio input:checked + .page-layout, .pagetype-ratio:hover input + .page-layout {
    border: 2px solid #3064F9;
    box-shadow: 0px 3px 26px rgb(0 0 0 / 16%) !important;
    transition: .5s;
    background-color: #F9F9F9;
}
.pages-settings{
    background-color: #fff;
    border-radius: 6px !important;
    border: 1px solid #E3E6F1 !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
    min-height: calc(100% - 63px);
    padding: 20px 30px 53px 30px;
    display: flex;
    justify-content: space-between;
}
.page-setting-header{
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 30px;
}
.page-setting-header-heading{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    line-height: 25.29px;
    color: #1B2952;
    margin-top: 0px;
    margin-bottom: 0px;
}
.page-setting-header-subheading{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    line-height: 25.29px;
    color: #7782A1;
    margin-top: 6px;
    margin-bottom: 20px;
    text-align: left !important;
}
.pages-settings-left{
    min-width: 50%;
}
.pages-settings-right{
    min-width: 50%;
}
.page-setting-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-setting-body-heading{
    font-family: "Axiforma-Bold";
    font-size: 14px;
    line-height: 19.67px;
    color: #1B2952;
    margin-top: 0px;
    margin-bottom: 0px;
}
.page-setting-toggle-name {
    color: #7782A1;
    line-height: 17.47px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    display: flex;
}
.colorpicker-input{
    position: relative;
}
.colorpicker-input .color_input{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #fff;
    padding: 7px 7px 7px 3px !important;
    height: 50px !important;
    transition: .5s !important;
}
.colorpicker-input-cross{
    position: absolute;
    right: 16px;
    top: 19px;
    cursor: pointer;
}
.page-setting-top-10{
    margin-top: 10px;
}
.page-setting-top-20{
    margin-top: 20px !important;
}
.page-setting-top-30{
    margin-top: 30px !important;
}
.colorpicker-input .color_input{
    height: 50px;
}
.page-setting-seprator{
    border-top: 1px solid #E3E6F1;
    margin-bottom: 0px;
}
.page-section-margin-second, .page-section-margin-first {
    display: flex;
    width: 50%;
    gap: 20px;
    margin-right: 20px;
}
.page-section-margin-second div, .page-section-margin-first div {
    margin-right: 0px;
    width: 50%;
    position: relative;
}
.page-section-margin-first input , .page-section-margin-second-input{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    font-size: 13px;
    color: #1B2952;
    padding: 13px 15px !important;
    font-family: 'Axiforma-Regular';
    height: 50px;
    width: 100%;
    outline: none;
}
.page-section-margin-value{
    color: #BCC1CE;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    position: absolute;
    margin-bottom: 0px;
    top: 18px;
    right: 37px;
    width: unset;
}
.set-page-margin{
    display: flex;
}
.pages-image-shape{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pages-setting-preview {
    height: 659px;
    width: 310px;
    background-color: #F7F7F7;
    margin: auto;
    box-shadow: 0px 10px 20px 0px #0000001A;
    overflow-y: auto;
    border: 4px solid #E3E6F1;
    border-radius: 20px;
}
.pages-setting-preview::-webkit-scrollbar{
    width: 0px!important;
}
.pages-setting-preview-header{
    height: 46px;
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 13px;
    background-color: #fff;
}
.pages-setting-header-left {
    display: flex;
    align-items: center;
}
.pages-setting-header-right{
    display: flex;
    align-items: center;
}
.pages-setting-header-heading{
    color: #1A1A1A;
    font-size: 13px;
    line-height: 18.27px;
    font-family: 'Axiforma-Bold';
    margin: 0px 0px 0px 11px;
}
.pages-setting-preview-layout-one{
    margin: 15px 13px 0px 13px;
}
.pages-preview-card{
    min-height: 60px;
    border-radius: 10px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    margin-bottom: 9px;
}
.card-type-menu{
    color: #1A1A1A;
    font-size: 11px;
    line-height: 14.78px;
    font-family: 'Axiforma-Regular';
    margin: 0px;
    word-break: break-word;
    padding: 0px 20px 0px 0px;
}
.pages-setting-preview-layout-two{
    margin-top: 28px;
}
.pages-preview-type-list{
    margin: 0px 13px 44px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.list-type-menu{
    color: #1A1A1A;
    font-size: 11px;
    line-height: 14.78px;
    font-family: 'Axiforma-Regular';
    margin: 0px;
    word-break: break-word;
    padding: 0px 20px 0px 0px;
}
.page-settings-carousel .carousel .slider-wrapper {	
    width: 100%;
}	
.page-settings-carousel .carousel .control-dots{	
    display: none;	
}	
.page-settings-carousel .carousel .carousel-status{	
    display: none;	
}	
.page-settings-carousel .slider:before{	
    display: none;	
}	
.page-settings-carousel .carousel .slide{	
    text-align: left;	
}	
.page-settings-carousel  .slider{	
    background-color: transparent;	
    background-image: none;	
}	
.page-settings-carousel .carousel .thumbs-wrapper{	
    display: none;	
}	
.page-settings-carousel .carousel.carousel-slider {	
    overflow: unset;	
}	
.page-settings-carousel  .carousel .control-prev.control-arrow {	
    left: 101px;	
} 	
.page-settings-carousel .carousel.carousel-slider .control-arrow {	
    top: -102px !important;	
    width: 32px;	
    height: 32px;   	
    font-size: 16px !important;	
    border-radius: 50%;  	
}	
.page-settings-carousel .carousel .control-next.control-arrow {	
    right: 101px;
}	
.page-settings-carousel .carousel .control-prev.control-arrow:before, .page-settings-carousel .carousel .control-next.control-arrow:before {	
   display: none;	
}	
.page-settings-carousel .carousel.carousel-slider .control-arrow:hover {	
    background: unset;	
}
.page-settings-carousel .carousel .slide img {
    width: fit-content;
}
.page-settings-carousel .carousel img {
    width: fit-content;
}
.pages-detail-preview{
    height: 659px;
    width: 300px;
    background-color: #F7F7F7;
    margin: auto;
    box-shadow: 0px 10px 20px 0px #0000001A;
    overflow-y: auto;
    /* border: 4px solid #E3E6F1; */
    border-radius: 20px;
}
.pages-detail-preview::-webkit-scrollbar{
    width: 0px!important;
}
.pages-detail-preview-body{
    padding-bottom: 15px;
}
.pages-detail-preview-heading{
    color: #1A1A1A;
    font-size: 15px;
    line-height: 21.08px;
    font-family: 'Axiforma-Bold';
    margin: 0px 0px 0px 0px;
}
.pages-detail-preview-text{
    color: #1A1A1A;
    font-size: 11px;
    line-height: 14.78px;
    font-family: 'Axiforma-Regular';
    margin: 0px 0px 0px 0px;
}
.pages-setting-preview-banner{
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
}
.pages-detail-preview-button{
    width: 100%;
    text-align: center;
    border: none;
    border-radius: 6px;
    height: 35px;
    font-size: 11px;
    line-height: 14.78px;
}
.pages-setting-preview-gallery{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.pages-setting-preview-gallery-img{
    width: 48%;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: 50%;
}
.pages-setting-preview-gallery-img:nth-last-child(2){
    margin-bottom: 0px;
}
.pages-setting-preview-gallery-img:nth-last-child(1){
    margin-bottom: 0px;
}
.pages-detail-preview-seprator{
    border-top: 1px solid #EBEBEB;
}
.pages-detail-preview-video{
    width: 100%;
    border-radius: 6px;
    height: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBEBEB;
}
.page-settings-mobile-btns{
    display: none;
}
.page-builder{
    background-color: #fff;
    border-radius: 6px !important;
    border: 1px solid #E3E6F1 !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
    min-height: calc(100% - 63px);
    padding: 20px 30px 53px 30px;
    display: flex;
    justify-content: space-between;
}
.page-builder-left{
    min-width: 50%;
}
.page-builder-right{
    display: flex;
    align-items: center;
    min-width: 50%;
}
.page-builder-header{
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 30px;
}
.page-builder-header-heading{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    line-height: 25.29px;
    color: #1B2952;
    margin-top: 0px;
    margin-bottom: 0px;
}
.page-builder-header-subheading{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    line-height: 25.29px;
    color: #7782A1;
    margin-top: 6px;
    margin-bottom: 20px;
    text-align: left;
}
.page-builder .page-builder-right{
    margin-top: 40px;
}
.mob-page-head-right{
    display: none;
}


@media screen and (max-width: 1500px) {
    .set-page-margin {
        display: block;
    }
    .page-section-margin-first , .page-section-margin-second{
        width: 100%;
        justify-content: space-between;
    }
}


@media screen and (max-width: 1200px) {
    .mob-page-head-right{
        display: flex;
    }
    .page-mob-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 0px;
    }
    .web-cmsbtns-div{
        display: none !important;
    }
    .mob-cms-create-btn{
        width: 100%;
        height: 44px;
        border: 1px solid #3064F9;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }
    .mob-cms-create-btn P{
font-size: 15px;
    }
    .mob-cms-page-header{
        flex-direction: column;
    
    }
    .select-page-type .form-group {
        margin: auto;
        margin-bottom: 40px;
    }
    .page-layout-header {
        margin-bottom: 40px;
    }
    .pages-layout{
        padding: 0px;
        margin: 10px 15px 15px 15px;
    }
    .page-layout-header{
        padding: 0px 15px;
    }
    .page-layout-header-heading{
        margin-top: 20px;
    }
    .page-layout-header-subheading{
        line-height: 17.47px;
    }
    .pages-settings-right{
        display: none;
    }
    .pages-settings-left {
        min-width: 100%;
    }
    .pages-settings{
        margin: 0px 15px 20px 15px;
        padding: 0px;
    }
    .page-setting-header{
        padding: 20px 20px 0px 20px;
    }
    .page-setting-body{
        padding: 0px 20px 30px 20px;
    }
    .set-page-margin {
        display: block;
    }
    .page-section-margin-first , .page-section-margin-second{
        width: 100%;
        justify-content: space-between;
    }
    .page-section-margin-second div, .page-section-margin-first div {
        margin-right: 0px;
        width: 50%;
    }
    .row.page-setting-top-30 .col-md-6:nth-child(2){
        margin-top: 20px;
    }
    .page-settings-mobile-btns{
        display: block;
        margin: 0px 15px 20px 15px;
    }
    .page-settings-save-mob{
        width: 100%;
        height: 50px;
        border: none;
        background-color: #3064F9;
        font-size: 16px;
        line-height: 21.5px;
        color: #FFFFFF;
        border-radius: 3px;
        margin-bottom: 20px;
    }
    .page-settings-mobile-timestamp{
        color: #BCC1CE;
        font-size: 12px;
        line-height: 16.13px;
        font-family: 'Axiforma-Regular';
        margin: 0px 0px 20px 0px;
        width: 100%;
        text-align: center;
    }
}



@media screen and (min-width: 767px) {
    .row.page-setting-top-30 .col-md-6:nth-child(2){
        margin-top: 0px;
    }
}