.apppreview-notification{
    margin-left: unset;
    margin-right: unset;
}
.review-headright{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 15px; */
    position: relative;
    top: -4px;
}
.previewapp .right-panel-gap {
    overflow-x: hidden;
}
.save-preview-btn {
    height: 40px;
    width: 200px;
    border: none;
    font-family: 'Axiforma-Regular';
    color: white;
    border-radius: 3px;
    background-color: #3064F9;
    cursor: pointer !important;
    transition: 0.5s;
    font-size: 14px;
    margin-left: 20px;
}
.lamda-preview-clickdiv{
    width: 340px;
    border: 5px solid #E3E6F1;
    height: 726px;
    border-radius: 50px;
    background-color: #ffff;
    background-image: url(../../assets/images/dashboard/lamda-default-img.png) !important;
    background-repeat: no-repeat !important;
    background-position: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06) ;
    cursor: pointer;
}
.save-preview-btn:hover {
    /* background-color: #134bec; */
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    /* border: 1px solid var(--custom_color6)!important; */
    transition: 0.5s;
}
.preview-main{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.preview-head{
    font-size: 24px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 0px;
}
.preview-status{
    margin-bottom: 0px;
    font-size: 14px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
}
.preview-online{
color: #47CE70;
}
.preview-main-page{
    background-color: #fff;
    border-radius: 6px!important;
    border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
    padding-bottom: 50px;
    position: relative;
    min-height: 81vh;
    padding-top: 30px;
}
.preview-main-page .app-icon-spark{
    position: absolute;
    top: 26px;
    right: 29px;
    cursor: pointer;
    fill: #bcc1ce;
    stroke: #bcc1ce;
    transition: .5s;
    z-index: 1;
}
.preview-test-popup-links{
    margin-top: 30px;
}
.previewspinner{
    border-radius: 6px;
    z-index: 1;
}
.preview-main-page .app-icon-spark:hover svg {
    fill: #f2c400;
    stroke: #f2c400;
    transition: .5s;
}
.preview-main-page .app-icon-spark:hover .protiptooltip {
    visibility: visible;
    text-align: left!important;
}
.preview-main-page .app-icon-spark .protiptooltip {
    visibility: hidden;
    width: 71px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: -85px;
    padding: 6px 15px 6px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
}

.apple-preview-div-left-top-left .reloadtooltip{
    visibility: hidden;
    /* width: 71px; */
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 6px;
    left: -80px;
    padding: 6px 15px 6px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
}
.android-preview-divmain{
    display: flex;
}
.apple-preview-div-left-top-left .reloadtooltip::after{
    content: "";
    position: absolute;
    left: 100%;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
    top: 12px;
}
.apple-preview-div-left-top-left:hover .reloadtooltip{
    visibility: visible;
    text-align: left!important;
}
.apple-preview-div-left-bottom-left .reloadtooltip{
    visibility: hidden;
    /* width: 71px; */
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 6px;
    left: -113px;
    padding: 6px 15px 6px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
}
.apple-preview-div-left-bottom-left .reloadtooltip::after{
    content: "";
    position: absolute;
    left: 100%;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
    top: 12px;
}
.apple-preview-div-left-bottom-left:hover .reloadtooltip{
    visibility: visible;
    text-align: left!important;
}

.apple-preview-div-left-top-right .reloadtooltip{
    visibility: hidden;
    /* width: 71px; */
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 6px;
    right: -81px;
    padding: 6px 15px 6px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
    text-align: left;
}
.apple-preview-div-left-top-right .reloadtooltip::after{
    content: "";
    position: absolute;
    right: 100%;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
    top: 12px;
}
.apple-preview-div-left-top-right:hover .reloadtooltip{
    visibility: visible;
    text-align: left!important;
}


.apple-preview-div-left-bottom-right .reloadtooltip{
    visibility: hidden;
    /* width: 71px; */
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 6px;
    right: -113px;
    padding: 6px 15px 6px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
}
.apple-preview-div-left-bottom-right .reloadtooltip::after{
    content: "";
    position: absolute;
    right: 100%;
    margin-top: -2px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(0deg);
    top: 12px;
}
.apple-preview-div-left-bottom-right:hover .reloadtooltip{
    visibility: visible;
    text-align: left!important;
}
.mobsave-preview-btn{
    display: none;
}
.apple-preview-div{
    display: flex;
}
.apple-preview-div-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    margin-right: 60px;
}
.apple-preview-div-left-top{
    height: 48px;
    width: 48px;
    border-radius: 50px;
    /* border: 1px solid #BCC1CE; */
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
}
.preview-btntransform{
    transform: scale(.95);
    transition: 0.5;

}
.protip-popup-one button:hover{
    background-color: #134bec;
    transition: 0.5s;
}
.apple-preview-div-left-top:hover{
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.apple-preview-div-left-bottom{
    height: 48px;
    width: 48px;
    border-radius: 50px;
    /* border: 1px solid #BCC1CE; */
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
}

.apple-preview-div-left-bottom:hover{
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.android-preview-div-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    margin-left: 60px;
}
.preview-left-pannel{
    margin-right: 60px;
}
.previeww-right-pannel{
    margin-left: 60px;
}
.mob-ios-android-tabs{
    display: none;
}
 .mobapple-preview-div-left{
    display: none;
}
.preview-mob-spark-protip{
    display: none;
       }
.previeww-right-pannel .android-frame{
    margin-right: 120px;
}
.previeww-right-pannel .appPreview-dropdown .dropdown-menu{
    left: -40px !important;
}
.preview-left-pannel .android-frame{
    margin-right: -100px;
}
.preview-rowreverse{
    flex-direction: row-reverse;
}
.lamda-previeww-right-pannel{
    margin-right: 98px;
    margin-left: 0px !important;
}
.lamda-android-preview-div-right{
    margin-right: 70px;
    margin-left: 0px !important;
}
.lamda-android-frame{
    margin-right: -132px !important;
    margin-bottom: 27px;
}
.lamda-preview-left-pannel{
    margin-left: 108px;
    margin-right: 0px !important;

}
.lamda-apple-frame{
margin-right: 110px !important;
margin-bottom: 27px;
}
.lamda-apple-preview-div-left{
    margin-left: 60px;
    margin-right: 0px !important;
}
.appPreview-dropdown-margin{
    margin-right: 39px;
}

.lamda-android-preview-div-right .apple-preview-div-left-top-right .reloadtooltip {
    right: 55px;
    width: 102px;
    text-align: left !important;
    padding: 5px 9px 5px 9px;
    opacity: 0.8 !important;
    font-size: 10px;
    line-height: 15px;
}
.lamda-android-preview-div-right .apple-preview-div-left-bottom-right .reloadtooltip {
    right: 55px;
    width: 102px;
    text-align: left !important;
    padding: 5px 9px 5px 9px;
    opacity: 0.8 !important;
    font-size: 10px;
    line-height: 15px;
} 
.lamda-android-preview-div-right .apple-preview-div-left-top-left .reloadtooltip {
    font-size: 10px;
    padding: 5px 9px 5px 9px;
    opacity: 0.8 !important;
    line-height: 15px;
    left: -54px;
    top: 11px;
}
.lamda-android-preview-div-right .apple-preview-div-left-top-left .reloadtooltip::after {
    margin-top: -6px;
} 
.lamda-android-preview-div-right .apple-preview-div-left-top-right .reloadtooltip::after{
    content: "";
    position: absolute;
    left: 100%;
    margin-top: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
    top: 12px;
}
.lamda-android-preview-div-right .apple-preview-div-left-bottom-right .reloadtooltip::after{
    content: "";
    position: absolute;
    left: 100%;
    margin-top: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(180deg);
    top: 12px;
}
.lamda-android-preview-div-right .apple-preview-div-left-top-right:hover .reloadtooltip {
    visibility: visible;
    text-align: left !important;
}
.lamda-android-preview-div-right .apple-preview-div-left-bottom-right:hover .reloadtooltip {
    visibility: visible;
    text-align: left !important;
}
       .appPreview-dropdown {
        line-height: 1;
        font-size: 0;
      }


      .lamda-apple-preview-div-left .apple-preview-div-left-top-left .reloadtooltip{
        visibility: hidden;
        width: 102px;
        background-color: #000000;
        color: #fff;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: 11px;
        left: 55px;
      padding: 5px 9px 5px 9px;
    opacity: 0.8 !important;
    font-size: 10px;
    line-height: 15px;
        display: grid;
        transition: 0.3s;
        margin-bottom: 0px;
        text-align: left;
      } 
      .lamda-apple-preview-div-left .apple-preview-div-left-bottom-left .reloadtooltip {
        visibility: hidden;
        width: 100px;
        background-color: #000000;
        color: #fff;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: 11px;
        left: 55px;
      padding: 5px 9px 5px 9px;
    opacity: 0.8 !important;
    font-size: 10px;
    line-height: 15px;
        display: grid;
        transition: 0.3s;
        margin-bottom: 0px;
        text-align: left;
      } 
      .lamda-apple-preview-div-left .apple-preview-div-left-bottom-left .reloadtooltip::after{
        content: "";
        position: absolute;
        left: -12px;
        margin-top: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent black transparent transparent;
        transform: rotate(0deg);
        top: 12px;
      }
      .lamda-apple-preview-div-left .apple-preview-div-left-top-left .reloadtooltip::after{
        content: "";
        position: absolute;
        left: -12px;
        margin-top: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent black transparent transparent;
        transform: rotate(0deg);
        top: 12px;
      }
      .lamda-apple-preview-div-left  .apple-preview-div-left-top-left:hover .reloadtooltip {
        visibility: visible;
        text-align: left !important;
    }

      .lamda-apple-preview-div-left  .apple-preview-div-left-bottom-left:hover .reloadtooltip {
        visibility: visible;
        text-align: left !important;
    }

      .appPreview-dropdown .dropdown-toggle {
        white-space: normal !important;
        width: 100%;
        border-radius: 3px;
        text-align: left;
        height: 22px;
        color: #7782A1 !important;
        background-image: url(../../assets/images/dashboard/email-support-arrow.png) !important;
        background-repeat: no-repeat !important;
        background-position: 97% !important;
        padding:0px;
        transition: 0.5s;
        font-family: "Axiforma-Regular";
        font-size: 13px;
        padding-right: 25px;
        /* max-width: 145px; */
         overflow: hidden;
      }
      .appPreview-dropdown .dropdown-menu {
        /* width: 100%; */
        padding: 0px;
        margin: 0px;
        max-height: 300px;
        overflow: auto;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
    top: 15px !important;
    /* left: -70px !important; */
    width: 200px;
    border: 1px solid #E3E6F1;
}
.report-aerotop-androidone, .report-aerotop-android {
    z-index: 10001;
    position: absolute;
    top: 10px;
    right: -2px;
    display: flex;
    top: 27px;
}
.report-aerotop-iosone, .report-aerotop-ios {
    z-index: 1001;
    position: absolute;
    top: 10px;
    right: -2px;
    /* height: 15px; */
    display: flex;
    top: 27px;
}
      .appPreview-dropdown .form-group {
        margin-bottom: 0px !important;
      }
      .appPreview-dropdown .dropdown-item {
        display: block;
        width: 100%;
        padding: 11px 0px 11px 29px !important;
        color: #1b2952;
        font-size: 13px !important;
        font-family: "Axiforma-Regular" !important;
        margin: 0px !important;
        border: 0px solid #fff !important;
        white-space: initial;
        line-height: 1.1;
        overflow-x: hidden;
      }
      .appPreview-dropdown .dropdown-item:hover {
        border: 0px solid #fff !important;
        background-color: #f8f9fd !important;
      }
      .appPreview-dropdown .dropdown-toggle::after {
            display: none;
      }
      .appPreview-dropdown .dropdown-item.active,
      .appPreview-dropdown .dropdown-item:active {
        background-color: #f8f9fd !important;
        color: #1b2952 !important;
      }
      .appPreview-dropdown-before {
        color: #1b2952;
        font-size: 13px;
        font-family: "Axiforma-Regular";
      }
      /* .appPreview-dropdown .dropdown-toggle:hover,
      .appPreview-dropdown .dropdown-toggle:focus {
        border: 1px solid #3064f9 !important;
        background-color: #f8fafe !important;
        transition: 0.5s;
      } */
      .appPreview-dropdown .dropdown-menu::-webkit-scrollbar-track {
        background-color: white !important;
      }
      .appPreview-dropdown .select-default {
        color: #7782a1 !important;
      }
.apppreview-testmodal-dialog .modal-content{   
        width: 600px;
        height: 540px;
}
.apppreview-testmodal-dialog .modal-body{   
    padding: 6px 50px 50px;
}
/* .preview-test-popup-links .preview-apple-test-link {
    display: flex;
    align-items: flex-start;
    width: 240px;
    height: 60px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background: #fff;
} */
.preview-test-popup-links .preview-gplay-test-link {
    margin-left: 0px;
}
.preview-test-popup-links{
    justify-content: space-between;
}
.preview-test-popup-links a{
    width: 200px;
}
.preview-qrcode{
    display: flex;
}
.preview-apple-qr{
    flex: 1 1;
    /* text-align: center; */
    border-right: 1px solid #E3E6F1;
}
.preview-gplay-qr{
    flex: 1 1;
    text-align: end;
}
.preview-gplay-qr-code, .preview-apple-qr-code{
    width: 200px;
    height: 200px;
}
.apppreview-testmodal-dialog .modal-content .close{
    padding: 10px 15px;
    color: #C8CEDB;
    font-weight: 500;
    opacity: 1;
    font-family: auto;

}
.apppreview-testmodal-dialog .modal-content .close:hover{
  
    opacity: 1 !important;
}
.protip-publish{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-bottom: 20px;
}
.preview-test-header{
    text-align: center;
}
.preview-test-header h5{
    font-size: 24px;
    font-family: "Axiforma-Bold";
}
.preview-test-header p{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 40px;
}
.preview-copy-redirect-linktext{
    display: inline-block;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
    color: #BCC1CE;
    margin-left: 6px;
}
.preview-left-link-copy{
    flex: 1 1;
    margin-top: 30px;
    margin-right: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-right-link-copy {
    flex: 1 1;
    margin-top: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview-apple-test-link{
    display: flex;
    align-items: center;
    width: 200px;
    height: 60px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    background: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    padding: 13px 15px;
}
.preview-apple-test-link p{
    margin-bottom: 0;
    margin-left: 16px;
    white-space: nowrap;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    color: #BCC1CE;
    line-height: 16px;
}
.preview-apple-test-link h5{
    margin-bottom: 0;
    margin-left: 16px;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #1B2952 !important;
    line-height: 20px;
}
.preview-btn{
    border: 1px solid transparent;
    background-color: white;
    padding: 0px;
    display: flex;
}
.previewstatus{
   margin-top: 8px; 
   padding-left: 5px
}
.mobapplestore{
display: none !important;
}
.preview-btn-on-web , .preview-btn-on-web:focus{
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
    width: 160px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #7782A1;
}
.preview-btn-off-web, .preview-btn-off-web:focus {
    background-color: #F8FAFE;
    border: none;
    box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset !important;
    width: 160px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    color: #7782A1;
}
.preview-btn-on-web span , .preview-btn-off-web span{
    background-color: #BCC1CE;
    border-radius: 2px;
    color: #fff;
    font-size: 8px;
    font-family: "Axiforma-Regular";
    padding: 2px 4px 0px 4px;
    margin-left: 5px;
    position: relative;
    top: -1px;
}
.preview-btn-on-mob , .preview-btn-off-mob{
    display: none;
}
.lamda-dropdown-select{
    display: none;
}
.lamda-android-preview-div-right .timerstart{
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    position: absolute;
    margin-bottom: 0px;
    right: 62px;
    top: 18px;
}
.lamda-apple-preview-div-left .timerstart{
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    position: absolute;
    margin-bottom: 0px;
    right: -62px;
    top: 18px;
}
.country-conf-secondmodal-crossmodal{
    position: absolute;
    top: 10px;
    right: 17px;
    cursor: pointer;
    height: 14px;
    width: 14px;
}
.country-conf-secondmodal .modal-header{
    padding: 22px 20px 20px;
}
.country-conf-secondmodal .modal-header h5{
    font-size: 16px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    line-height: 23px;
}
.country-conf-secondmodal .modal-body{
    padding: 0px 0px 16px ;
}
.language-search{
    padding: 0px 20px 7px;
    position: relative;
}
.language-search input{
    height: 50px;
    width: 100%;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    padding: 15px 35px 15px 15px;
    font-size: 13px;
    color: #1B2952;
    font-family: 'Axiforma-Regular';
    outline: none;
}
.language-search input:hover{
    background-color: #f8fafe;
    border-color: #3064f9 !important;
}
.language-search .search-cross-icon{
    position: absolute;
    cursor: pointer;
    display: block;
    top: 18px;
    right: 35px;
}


.language-type-div{
    padding: 13px 20px 13px 48px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.language-type-div input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.language-select-checkmark {
    position: absolute;
    top: 14px;
    left: 20px;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #bcc1ce;
}
.language-type-div:hover input ~ .language-select-checkmark {
    background-color: #ffff;
}
.language-type-div input:checked ~ .language-select-checkmark {
    background-color: #3064F9;
    border: unset;
}

.language-type-div input:checked ~ .language-select-checkmark:after {
    display: block;
}

.language-type-div .language-select-checkmark:after {
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
}
.language-select-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.language-type-body .newspinner{
    min-height: 305px;
}
.language-type-div-select{
    background-color: #EFF3F9;
}
.language-type-div:hover{
    background-color: #EFF3F9;
}
.language-type-div-select .language-type{
    color: #3064F9;
}
.language-type-div:hover .language-type{
    color: #3064F9;
}
.countryflag{
    margin-right: 10px;
}
.countryflag img{
    width: 20px;
    height: 14px;
  
    margin-bottom: 5px;
}
.language-type{
    color: #1B2952;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    margin-bottom: 0px;
} 
.language-search svg{
    display: none;
}
.language-search p{
    display: none;
}
.language-type-body{
    max-height: 270px;
    overflow: auto;
    min-height: 271px;
}
.language-type-body .select-no-data-found {
    height: 270px;
}
.country-conf-secondmodal .modal-footer{
    justify-content: space-between;
    border-top: 1px solid #E3E6F1 !important;
    padding: 15px 20px;
}
.country-conf-secondmodal .modal-footer .location-cancel-btn{
    margin: 0px;
    width: 100px;
    height: 40px;
    border: 1px solid #BCC1CE;
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    transition: 0.5s;
}

.country-conf-secondmodal .modal-footer .location-cancel-btn:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(188 193 206 / 69%);

}
.country-conf-secondmodal .modal-footer .location-done-btn{
    margin: 0px;
    width: 100px;
    height: 40px;
    border: unset;
    background-color: #3064F9;
    border-radius: 3px;
    color: #ffff;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    transition: 0.5s;
}
.country-conf-secondmodal .modal-footer .location-done-btn:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(112 155 250 / 69%);
    background-color: #134bec;
    transition: 0.5s;
}
.country-conf-secondmodal .modal-footer .location-done-btn:disabled{
    background-color: #BCC1CE;
    cursor: not-allowed;
}
.country-conf-secondmodal .modal-footer .location-done-btn:disabled:hover{
    box-shadow: none;
}
.mob-language-done-btn{
    display: none;
}
/* .lamda-preview-opacity{
    opacity: 0.3;
    cursor: not-allowed;
} */
/* .lamda-preview-opacity .top_screenshot{
    opacity: 0.5;
   
}

.lamda-preview-opacity .appPreview-dropdown .dropdown-toggle {
    cursor: not-allowed !important; 
} */
.lamda-preview-opacity .apple-preview-div-left-top{
    opacity: 0.5;
    cursor: not-allowed;
}
.lamda-preview-opacity .apple-preview-div-left-top:hover{
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14)!important;
}

.lamda-preview-opacity .apple-preview-div-left-bottom{
    opacity: 0.5;
    cursor: not-allowed;
}
.lamda-preview-opacity .apple-preview-div-left-bottom:hover{
   box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14) !important;
}
.lamda-preview-opacity  .reloadtooltip{
display: none !important;
}
.Reset-left-tooltip{
    width: fit-content !important;
}
@media (min-width: 1401px) and (max-width: 1700px) {
    .lamda-previeww-right-pannel {
        margin-right: 60px;
    }
    .lamda-preview-left-pannel {
        margin-left: 60px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .lamda-previeww-right-pannel {
        margin-right: 10px;
    }
    .lamda-android-frame {
        margin-right: -70px !important;
    }
    .lamda-apple-frame {
        margin-right: 60px !important;
    }
    .lamda-preview-left-pannel {
        margin-left: 10px;
    }
    .lamda-apple-preview-div-left {
        margin-left: 15px;
    }
    .lamda-android-preview-div-right {
        margin-right: 15px;
    }
    .preview-left-pannel{
        margin-right: 20px;
    }
    .previeww-right-pannel{
        margin-left: 20px;
    }
    .android-preview-div-right{
        margin-left: 20px;
    }
    .apple-preview-div-left{
        margin-right: 20px;
    }
    .previeww-right-pannel .android-frame{
        margin-right: 60px;
    }
    .preview-left-pannel .android-frame{
        margin-right: -60px;
    }
}

@media screen and (max-width: 1470px) {

    .lamda-android-preview-div-right .apple-preview-div-left-top-left .lamda-reset-for-android {
        top: 57px;
        left: 0px;
    }
    .lamda-android-preview-div-right .apple-preview-div-left-top-left .lamda-reset-for-android::after{
        content: "";
        position: absolute;
        left: 21px;
        margin-top: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent black transparent transparent;
        transform: rotate(90deg);
        top: -6px;
    }
    .lamda-android-preview-div-right .apple-preview-div-left-top-right .reloadtooltip {
        right: 0px;
        top: 57px;
    }
    .lamda-android-preview-div-right .apple-preview-div-left-top-right .reloadtooltip::after {
        left: 73%;
        transform: rotate(90deg);
        top: -6px;
    }
    .lamda-android-preview-div-right .apple-preview-div-left-bottom-right .reloadtooltip {
        right: 0px;
        top: 57px;
    }
    .lamda-android-preview-div-right .apple-preview-div-left-bottom-right .reloadtooltip::after {
        left: 73%;
        transform: rotate(90deg);
        top: -6px;
    }


    .lamda-apple-preview-div-left .apple-preview-div-left-top-left .reloadtooltip, .lamda-apple-preview-div-left .apple-preview-div-left-bottom-left .reloadtooltip {
        top: 57px;
        left: 0px;
    }

    .lamda-apple-preview-div-left .apple-preview-div-left-top-left .reloadtooltip::after, .lamda-apple-preview-div-left .apple-preview-div-left-bottom-left .reloadtooltip::after {
        content: "";
        position: absolute;
        left: 21px;
        margin-top: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent black transparent transparent;
        transform: rotate(90deg);
        top: -6px;
    }

}
@media screen and (max-width: 1200px) {
    .apple-preview-div-left-bottom{
        margin-bottom: 8px;
    }
    .language-type-body .select-no-data-found {
        height: calc(100vh - 100px);
    }
    .language-search .search-cross-icon{
        display: none;
    }
    .preview-main {
        margin-bottom: 20px;
    }
    .preview-head {
        font-size: 20px;
    }
    .language-type-body .newspinner{
        min-height: calc(100vh - 62px);
        margin-top: 2px;
    }
    /* .lamda-preview-opacity.lamda-dropdown-select{
        opacity: 0.5;
    } */
    .language-select-checkmark {
        top: 18px;
        height: 24px;
        width: 24px;
    }
    .language-type-div .language-select-checkmark:after {
        width: 12px;
        height: 12px;
    }

    .country-conf-secondmodal .modal-footer{
        display: none;
    }
    .mob-language-done-btn{
        display: block;
        color: #3064F9;
        font-family: 'Axiforma-Regular';
        font-size: 14px;
        border: unset;
        background-color: transparent;
        margin-right: 20px;
    }
    .mob-language-done-btn:disabled{
        color: #BCC1CE;
    }
    .language-type-body{
        overflow: auto;
        max-height: calc(100vh - 60px);
    }
    .lamda-android-preview-div-right .timerstart {
        right: -1px;
        top: 53px;
    }
    .lamda-dropdown-select .report-aerotop-ios {
        top: 29px;
        right: -4px;   
    }
    
    .lamda-dropdown-select .appPreview-dropdown .dropdown-menu {
        left: -30px !important;
        top: 13px !important;
    }
    .mobapple-preview-div-left .timerstart{
        font-size: 12px;
        font-family: 'Axiforma-Regular';
        color: #BCC1CE;
        position: absolute;
        margin-bottom: 0px;
    }
    .lamda-previeww-right-pannel {
        margin-right: 0px;
    }
    .lamda-preview-left-pannel {
        margin-left: 0px;
    }
    .lamda-preview-left-pannel .mobapple-preview-div-left{
        margin-top: 30px;
    }
    .lamda-preview-left-pannel .apple-preview-div-left-top {
        margin-right: 0px !important;
        margin-bottom: 8px;
    }
    .lamda-android-preview-div-right {
        margin-right: 0px;

    }
    .lamda-android-preview-div-right .apple-preview-div-left-top {
        margin-right: 0px;
        margin-bottom: 8px;
    }
    .lamda-android-preview-div-right{
        margin-top: 25px;
    }
    .lamda-dropdown-select{
        margin-bottom: 20px;
        display: block;
    }
    .lamda-dropdown-select .appPreview-dropdown .dropdown-toggle {
        white-space: normal !important;
        width: 100%;
        /* border: 1px solid #bcc1ce !important; */
        border-radius: 3px;
        text-align: left;
        height: 22px;
        /* margin-bottom: 15px; */
        color: #7782A1 !important;
        background-image: url(../../assets/images/dashboard/email-support-arrow.png) !important;
        background-repeat: no-repeat !important;
        background-position: 97% !important;
        padding:0px;
        transition: 0.5s;
        font-family: "Axiforma-Regular";
        font-size: 13px;
        padding-right: 25px;
        position: relative;
    }

    /* .android-preview-div-right{
        display: none;
    } */
    .android-preview-divmain{
        display: grid;
    }
    .webapplestore{
  display: none !important;
    }
    .mobapplestore{
        display: block !important;
        }
    .previewstatus{
        margin-top: 4px; 
        padding-left: 0px
     }
    .preview-apple-test-link p{
        font-size: 13px;
        line-height: 20px;
    }
    .preview-apple-test-link h5{
        font-size: 16px;
        line-height: 24px;
    }
    .report-aerotop-iosone {
        top: 54px;
        right: 10px;
    }
    .report-aerotop-androidone{
        right: 11px;
        top: 54px;
    }
    .appPreview-dropdown .dropdown-menu {
        left: -71px !important;
        top: 38px !important;
    }
    .report-aerotop-android {
        top: 37px
    }
    .appPreview-dropdown .dropdown-toggle {
        background-image: unset !important;
        padding: 0px;
        height: 22px;
        width: 18px;
        /* overflow: hidden; */
        font-size: 0px;
        position: absolute;
        top: 4px;
        left: 3px;
    }
    .previeww-right-pannel .android-frame, .preview-left-pannel .android-frame {
        display: none
    }
    .preview-test-popup-links .preview-gplay-test-link {
        margin-left: 0px;
        margin-top: 0px;
    }
    .apppreview-testmodal-dialog .modal-content{   
        width: 100%;
        height: 100%;
}
.preview-qrcode{
    display: none;
}

.apppreview-testmodal-dialog .modal-body{
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0px 34px;
}
.preview-test-popup-links{
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
}
.preview-test-header p {
    margin-bottom: 0px;
}
.preview-apple-test-link {
    width: 240px;
    height: 70px;
}
.preview-test-popup-links a {
    width: 240px;
    height: 70px;
    margin-top: 30px;
}
    .preview-main-page .app-icon-spark {
        display: none;
    }
   .preview-mob-spark-protip{
display: block;
position: relative;
margin-left: 10px;
   }
.preview-mob-spark-protip .app-icon-spark{
    /* position: absolute; */
    top: 26px;
    right: 29px;
    cursor: pointer;
    fill: #bcc1ce;
    stroke: #bcc1ce;
    transition: .5s;
}
.preview-mob-spark-protip .app-icon-spark:hover svg {
    fill: #f2c400;
    stroke: #f2c400;
    transition: .5s;
}
.preview-mob-spark-protip .app-icon-spark:hover .protiptooltip {
    visibility: visible;
    text-align: left!important;
}
.preview-mob-spark-protip .app-icon-spark .protiptooltip {
    visibility: hidden;
    width: 71px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: -85px;
    padding: 6px 15px 6px 15px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    margin-bottom: 0px;
}
.preview-main-page {
    padding-top: 0px;
    padding-bottom: 30px;
    margin-bottom: 20px;
}
    .mob-ios-android-tabs{
        height: 40px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
    }
    .lamda-mob-ios-android{
        flex-direction: row-reverse;
        margin-top: 20px;
        margin-bottom: 20px;

    }
    .mob-preview-splash-head{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .save-preview-btn{
        display: none;
    }
    .report-aerotop-android {
        right: -4px; 
    }
    .report-aerotop-android {
        top: 29px;
    }

     .mob-ios-android-tabs .preview-androidmob{
        margin-right: 30px;
        position: relative;
     }
     .android-preview-icon-new-tag{
        position: absolute;
        top: -10px;
        right: -30px;
     }
     /* .android-preview-div-right{
        margin-right: 20px;
     } */
    .mobsave-preview-btn{
    display: block;
    height: 50px;
    width: 100%;
    border: none;
    font-family: 'Axiforma-Regular';
    color: white;
    border-radius: 3px;
    background-color: #3064F9;
    cursor: pointer !important;
    transition: 0.5s;
    font-size: 16px;
    margin-left: 0px;
    margin-bottom: 10px;
    }
    .previewapp .right-panel-gap{
        padding: 20px 15px !important;
    }

    .apple-preview-div {
        flex-direction: column;
        align-items: center;
    

    }
    .previeww-right-pannel {
        margin-left: 0px;
    }
    .preview-left-pannel{
        margin-right: 0px;
    }
    .android-preview-div-right{
    flex-direction: unset;
    align-items: center;
    justify-content: space-evenly !important;
    width: 100%;
    margin-left: 0px;
    }
    .apple-preview-div-left-top {
        margin-bottom: 0px;
        margin-right: 0px
    }
    .apple-preview-div-left{
        display: none;
    }
    .mobapple-preview-div-left{
        display: flex;
      
        align-items: center;
        justify-content: space-evenly !important;
        width: 100%;
        margin-right: 0px;
    }
    .preview-btn-on-web , .preview-btn-off-web{
        display: none;
    }
    .preview-btn-on-mob , .preview-btn-on-mob:focus{
        background-color: #F8FAFE;
        border: none;
        box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 14px;
        font-family: "Axiforma-Regular";
        color: #7782A1;
    }
    .preview-btn-off-mob, .preview-btn-off-mob:focus {
        background-color: #F8FAFE;
        border: none;
        box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset !important;
        width: 100%;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 14px;
        font-family: "Axiforma-Bold";
        color: #7782A1;
    }
    .preview-btn-on-mob span , .preview-btn-off-mob span{
        background-color: #BCC1CE;
        border-radius: 2px;
        color: #fff;
        font-size: 8px;
        font-family: "Axiforma-Regular";
        padding: 2px 4px 0px 4px;
        margin-left: 5px;
        position: relative;
        top: -2px;
    }

    .country-conf-secondmodal .modal-header {
        display: none;
    }

    .language-search svg{
        display: block;
    }
    .language-search p{
        display: block;
        font-size: 12px;
        color: #BCC1CE;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        border-left: 1px solid #E3E6F1;
        padding-left: 20px;
    }
    .language-search {
        padding: 0px 20px;
        display: flex;
    align-items: center;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    }
    .language-search form {
        width: 100%;
    }
    .language-search input {
        border: unset;
            height: 60px;
            font-size: 16px;
    }
    .language-search input:hover {
        background-color: unset;
        border-color: unset !important;
    }
    .language-type-div {
        margin-top: 20px;
        padding: 18px 20px 18px 54px; 
        
    }
    .language-type {
        font-size: 16px;
    }
    .countryflag img {
        width: 27px;
        height: 19px;
    }
    .apple-preview-div-left-top-left .reloadtooltip{
        top: -42px !important;
        left: 2px !important;
        padding: 6px 15px 6px 15px !important;
        line-height: 18px !important;
        font-size: 12px !important;
        height: 30px;
    }
    .apple-preview-div-left-top-left .reloadtooltip::after{
        transform: rotate(270deg) !important;
        top: 32px ;
        left: 19px !important;
    }
    .apple-preview-div-left-top-left .lamda-reset-for-android::after{
        top: 36px !important ;
    }
    .android-div-tooltipcreate{
        right: -76px !important;
    }
    .android-div-tooltipcreate::after{
        top: 80px !important;
    }
    .apple-preview-div-left-bottom-right .reloadtooltip.mob-simulate_tooltip::after{
        top: 80px !important;
    } 
    .apple-preview-div-left-top-right .reloadtooltip.android-div-tooltipscreenshort{
        top: -125px !important;
        right: -76px !important;
    }
    .android-div-tooltipscreenshort::after{
        top: 122px !important;
    }
    .apple-preview-div-left-bottom-right .reloadtooltip {
        top: -90px !important;
        right: -5px;
        text-align: left !important;
        padding: 6px 15px 6px 15px !important;
        line-height: 21px !important;
        font-size: 12px !important;
        width: 129px !important;

    }
    .apple-preview-div-left-bottom-right .reloadtooltip::after {
        transform: rotate(270deg) !important;
        top: 77px !important;
        left: 95px !important;

    } 
    .apple-preview-div-left-top-right .reloadtooltip.stop-recording-tooltip{
        top: -42px !important;
     } 
     .apple-preview-div-left-top-right .reloadtooltip.stop-recording-tooltip::after{
        top: 34px !important;
     } 
    .apple-preview-div-left-top-right .reloadtooltip {
        top: -86px !important;
        padding: 6px 15px 6px 15px !important;
        line-height: 21px !important;
        font-size: 12px !important;
        width: 129px !important;
    }
    .apple-preview-div-left-top-right .mob-screenshort {
        top: -127px !important;
    }
    .apple-preview-div-left-top-right .mob-screenshort::after {
        top: 119px !important;
    }
    .apple-preview-div-left-top-right .reloadtooltip::after{
        transform: rotate(270deg) !important;
        top: 77px;
    left: 19px !important;
    }
    .apple-preview-div-left-top-right .reloadtooltip.mob-stop-recording-tooltip{
        top: -40px !important;
        right: -79px;
    }
    .apple-preview-div-left-top-right .reloadtooltip.mob-stop-recording-tooltip::after{
        top: 39px;
    }
}

@media screen and (max-width: 370px) {
    .lamda-preview-clickdiv {
        width: 276px;
    margin: auto;
    }
}