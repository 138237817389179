.create-workspace-main {
    display: flex;
}

.workspace-org-module {
    background-color: #F8FAFE;
    min-height: 100vh;
}
.workspace-org-module-customer-div{
  height:calc(100vh - 70px) !important;
  overflow:auto !important
}
.create-workspace-right .signup-crousel{
    margin-top: 30px;
}
.newcontainer-workspace{
        position: relative;
        min-height: calc(100vh - 90px);
        background-image: url("../../assets/images/dashboard/app-icon-background.png");
        justify-content: center;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: contain;
        background-attachment: fixed;
        background-color: #F8FAFE;
        min-height: 100vh;

}
.create-workspace-btn{
   justify-content:center;
   align-items:center;
   display:flex
}
.workspacelisting-type{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.workspace-filter-open{
    position: fixed;
    right: 0px;
    /* right: 0px; */
    height: 100%;
    transition: 0.5s;
    width: 400px;
    background-color: #fff;
    top: 60px;
    z-index: 200;
    border-left: 1px solid #e3e6f1;
    border-top: 1px solid #e3e6f1;
    padding: 22px 19px 75px 21px;
    overflow-y: auto;
}
.permanently-delete{
    color: #1B2952;
}
.email-label{
    font: 14px "Axiforma-Regular";
    color: #7782a1;
}
.workspace-filter-close{
    position: fixed;
    right: -400px;
    /* right: 0px; */
    height: 100%;
    transition: 0.5s;
    width: 400px;
    background-color: #fff;
    top: 60px;
    z-index: 99999;
    border-left: 1px solid #e3e6f1;
    border-top: 1px solid #e3e6f1;
    padding: 22px 19px 75px 21px;
    overflow-y: auto;
}
.workspace-filter-head{
    display: flex;
    justify-content: space-between;
}
.workspace-filter-head h4{
    font-size: 18px;
    font-family: "Axiforma-Bold";
    line-height: 18px;
    color: #1b2952;
    margin-bottom: 20px;
}
.workspace-filter-head p{
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #7782a1 !important;
    line-height: 16px;
    cursor: pointer;
}
.workspace-filter-seconddiv .select-filter__control {
    width: 100%;
    height: 40px !important;
    border: 1px solid #bcc1ce !important;
    border-radius: 3px !important;
    box-shadow: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #1b2952;
    min-height: 40px;
    padding: 0px 0px 0px 5px;
}
.workspace-filter-input{
    width: 100%;
    height: 40px !important;
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 13px !important;
    color: #1b2952;
    padding: 12px 15px;
    font-family: "Axiforma-Regular";
    line-height: 40px;
    outline: unset;
}
.workspace-filter-input:hover{
    background-color: #f8fafe;
    border-color: #3064f9 !important;
    outline: none;

}
.workspace-filter-thirddiv{
    display: flex;
}
.workspace-filter-delete{
    border-radius: 3px;
    border: 1px solid #bcc1ce;
    height: 40px;
    width: 40px;
    margin-left: 10px;
    margin-bottom: 0px !important;
    padding: 0px 12px;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.newworkspace-det-tile {
    border-radius: 0px;
    margin-bottom: 30px;
    background-color: white;
    border: none;
    padding: 40px 30px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
}
.workspace-add-filter{
    margin: 20px 0px 0px;
}
.add-fileld-btn{
    display: flex;
    align-items: center;
}
.workpsace-filter-btn{
    margin-top: 20px;
}
.workpsace-filter-search-btn{
    width: 100%;
    height: 40px;
    border: none;
    color: white;
    background-color: #3064f9;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    cursor: pointer;
}
.workpsace-filter-search-btn:hover{
    background-color: #134bec;
}
.workspace-filter-seconddiv:hover .select-filter__control {
    border-color: #3064f9 !important;
}
.workpsace-filter-cancle-btn{
    display: none;
    width: 100%;
    justify-content: center;
}
.workpsace-filter-cancle-btn p{
    color: #7782a1;
    font: 14px "Axiforma-Regular";
    cursor: pointer;
    margin-bottom: 0px;
}
.workspace-and{
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #c8cedb;
    margin-top: 15px;
    margin-bottom: 12px;
}
.workspace-add-filter p{
    color: #3064f9 !important;
    font: 13px "Axiforma-Regular";
    margin-left: 5px;
    margin-bottom: 0px;
    line-height: 14px;
    margin-top: 2px;
} 
.newadd-workspace-header{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.no-worksapce-created{
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.workspaceicontype{
 display: flex;
 align-items: center;
}
.newworkspace-type img{
    -webkit-filter: grayscale(100%);
    opacity: 0.3;
    margin-right: 30px;
    height: 30px!important;
    width: 30px !important;
   }
   .mob-workspace-type{
    display: none;
   }
.no-worksapce-created button {
    margin-top: 30px;
    background-color: #3064F9;
    color: white;
    font-family: 'Axiforma-Regular';
    height: 50px;
    width: 180px;
    border: none;
    border-radius: 3px;
    font-size: 16px;
}
.no-worksapce-created button:hover {
    background-color: #134BEC;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.no-worksapce-created p{
    max-width: 480px;
    align-items: center;
    display: flex;
    text-align: center;
    font-size: 14px;
    color: #7782A1;
    margin-bottom: 0px;
}
.no-worksapce-created h2{
    color: #1B2952;
    font-size: 30px;
    font-family: 'Axiforma-Bold';
    margin-top: 38px;
    margin-bottom: 16px;
}
.workspace-org-structure {
    background-color: #fcfdff;
    display: flex;
    min-height: 100vh;
    position: relative;
    max-width: 100%;
    margin: auto;
}
.add-workspace-inner {
    padding-top: 120px;
    width: 1080px;
    margin-bottom: 50px;
}
.newadd-workspace-inner{
   
        padding-top: 120px;
        width: 1080px;
        margin-bottom: 50px;

}
.newadd-workspace-head h5{
    font-size: 18px;
    color: #1B2952;
    font-family: "Axiforma-Bold";
    margin-bottom: 5px;
}
.newadd-workspace-head p{
    font-family: "Axiforma-regular";
    color: #7782A1;
    font-size: 12px;
    margin-bottom: 0px;
    text-align: left !important;
}
.workspace-create-add-btn {
    padding: 5px 25.3px 6px 16px;
    border-radius: 3px;
    background-color: #3064f9;
    color: white;
}
.workspace-create-add-btn:hover {
        background-color: #134BEC;
        transition: 0.5s;
        box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
    }

.workspace-create-mob-plus{
display: none;
}
.workspace-create-add-btn h5 {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    padding-left: 10px;
    color: white;
    margin-bottom: 0;
}
.create-workspace-left {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 60px 0 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 100vh;
}

.create-workspace-left-position {
    margin-top: 25vh;
}

.create-workspace-left h1 {
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 40px;
}

.create-workspace-left h2 {
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    margin: 17px 0px 23px 0px;
    max-width: 480px;
}

.create-workspace-left label {
    display: block;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 13px;
}

.create-workspace-left input {
    display: block;
    height: 50px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    width: 100%;
    max-width: 480px;
    outline: none;
    padding: 0px 20px;
    transition: .5s;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
}
.workspacelist-det{
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 100px);
    align-items: center;
}
.newcreation-date{
 display: flex;

}
.newcreation-date h5{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    padding-top: 0px;
    margin-bottom: 0;
    text-align: right;
    width: 130px;
}
.workspacelist-det h3{
    font-size: 18px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 7px;
}
.workspacelist-det p{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    text-align: start !important;
}
.create-workspace-left input:hover,
.create-workspace-left input:focus {
    border: 1px solid #3064f9;
    background-color: #f8fafe;
    transition: .5s;
}

.create-workspace-left button {
    width: 100%;
    max-width: 480px;
    background-color: #3064F9;
    height: 50px;
    border: 1px solid #3064F9;
    margin-top: 20px;
    color: #fff;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    border-radius: 3px;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-workspace-left button:hover {
    background-color: #134BEC;
    transition: .5s;
}

.create-workspace-right {
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: center;
    display: flex;
    padding: 60px 0 0;
    background-color: #f8fafe;
    height: 100vh;
}

.create-workspace-right-position {
    margin-top: 21vh;
    text-align: center;
}

.workspace_background {
    background-color: #f8fafe;
    padding: 24px 30px 30px 30px;
    width: calc(100% - 200px);
    margin-left: 200px;
    margin-top: 60px;
    position: relative;
    transition: 0.4s;
    min-height: calc(100vh - 60px);
}

.workspace-status-upgrade .workspace-reactivate-text {
    color:#BCC1CE !important;

}

.workspace-status-upgrade .workspace-reactivate-text:hover {
    color:#BCC1CE !important;


}

.workspace_background-expand {
    width: calc(100% - 60px);
    margin-left: 60px;
}
.workspace-headings-div{
   display: flex;
    align-items: center
}
.workspace-headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.workspace-headings-team-div{
  display: flex;
  align-items: center;
  margin-top:5px
}
.workspace-invite-userbody-label{
    font-size: 15px;
    font-family: "Axiforma-Bold";
     color: #1b2952;
      margin-bottom: 3px;
}
.workspace-headingdiv-text{
   display: flex;
    align-items: center
}
.workspaceapplist-dashboard-head {
    margin-bottom: 20px;
}
.workspaceinput-close-cursor-svg{
   position: relative;
   cursor:pointer
}
.workspaceapplist-dashboard-head p {
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
}

.workspaceapplist-dashboard-head h2 {
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
}
.workspace-teams-mobile{
    display: none;
}
.workspaceapplist-team-dashboard-head{
    display: flex;
    justify-content: space-between;
}
.workspace-dashboard-head p {
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 21px;
    margin-top: 5px;
}

.workspace-dashboard-head h2 {
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 17px;
    margin-top: 5px;
}
.workspace_background .access-loader {
    height: calc(100% - 50px) !important;
    margin: 0px !important;
    max-height: unset !important;
}
.workspace-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 6px;
}

.workspace-tooltip .workspace-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 159%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    text-align: left !important;
    transition: 0.3s;
}

.workspace-tooltip .workspace-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 7px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.web-workspace-createtext{
    display: block;
   }
   .mob-workspace-createtext{
       display: none;
      }
.workspace-tooltip:hover .workspace-tooltipsubheader {
    visibility: visible;
}

.workspace-dashboard {
    display: flex;
    align-items: flex-start;
}

.workspace-dashboard-midsection {
    width: calc(100% - 400px);
}
.workspace-dashboard-asuser{
    width: 100%;
}
.workspace-dash-miduser-panel{
    width: unset!important;
}
.workspace-user-app-data{
    width: unset!important;
}
.workspace-user-app-data div{
    width: 49%!important;
}
.workspace-org-right-panel {
    width: 400px;
}

.workspace-dash-mid-panel {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
}



.no-workspace-data-graph {
    background-color: #fff;
    width: calc(100% - 20px);
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 0px;
    border: 1px solid #E3E6F1;
    margin-top: 20px;
    overflow: hidden;
}

.workspace-plan-detail {
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    padding: 20px;
    margin-bottom: 20px;
}

.workspace-total-steps {
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    margin-top: 0px;
}

.workspace-dash-teams {
    padding: 20px 20px 20px 20px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
}

.workspace-app-info {
    background-color: #fff;
    width: 32%;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E3E6F1;
}

.workspace-personal-data {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    padding: 20px;
    width: 32%;
}

.workspace-app-data {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin-top: 20px;
}

.workspace-app-data div {
    display: flex;
    align-self: center;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    width: 32%;
}

.workspace-data {
    margin-left: 20px;
}

.workspace-data h3 {
    color: #1B2952;
    font-size: 16px;
    font-family: "Axiforma-Bold";
    margin: 0px;
}

.workspace-data h5 {
    color: #7782A1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 10px 0px 10px 0px;
}

.workspace-data h6 {
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    margin: 10px 0px;
}

.workspace-icon {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background-color: #E3E6F1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.workspace-personal-data div:nth-child(1) {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 15px;
}

.workspace-personal-data h2 {
    font-size: 16px;
    color: #1B2952;
    font-family: "Axiforma-Bold";
    margin: 0px;
}

.workspace-personal-data h5 {
    font-size: 13px;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    margin: 8px 0px 10px 0px;
}

.workspace-personal-data h6 {
    font-size: 12px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    margin: 0px;
}

.workspace-app-data div h3 {
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 30px;
    margin: 0px 9px 0px 17px;
    padding-top: 10px;
}

.workspace-app-data div p {
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    margin: 0px;
    padding-top: 20px;
}

.workspace-plan-alignment {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.workspace-plan-section-one {
    width: calc(100% - 100px);
}

.workspace-plan-section-one h3 {
    font-size: 16px;
    color: #1B2952;
    font-family: "Axiforma-Bold";
    margin-bottom: 10px;
}
.workspace-plan-name{
    text-transform: capitalize
}

.workspace-plan-section-one p {
    font-size: 12px;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    line-height: 16px;
    width: 90%;
    text-align: left !important;
}

.workspace-plan-section-one h6 {
    font-size: 12px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    line-height: 16px;
    margin-top: 10px;
}

.workspace-plan-section-two {
    min-height: 100px;
    max-width: 100px;
}

.workspace-status-upgrade {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 22px;
    margin-top: 10px;
}

.workspace-status-upgrade p {
    font-size: 13px;
    color: #47CE70;
    font-family: "Axiforma-Regular";
    margin: 0px;
}

.workspace-status-upgrade a,
.workspace-status-upgrade a:hover {
    font-size: 13px;
    color: #3064F9 !important;
    font-family: "Axiforma-Regular";
    margin: 0px;
}

.workspace-dashboard-progress {
    padding: 20px 0px 0px 20px;
}

.workspace-dashboard-progress h2 {
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 16px;
    display: flex;
    align-items: center;
}

.workspace-dashboard-progress h2 {
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 25px;

}
.workspace-dashboard-progress h2 span {
    margin-left: 8px;

}
.workspace-discaunt{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    background-color: #F8FAFE;
    border-radius: 4px;
    padding: 12px;
    color: #7782A1;
}
.workspace-discaunt p{
    margin-bottom: 0px;
}
.workspace-discaunt span{
    color: #1B2952;
}
.workspace-dashboard-progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EDF0F5;
    padding-bottom: 15px;
    margin-bottom: 17px;
    width: calc(100% - 20px);
}

.workspace-dashboard-progress-bar p {
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin: 0px 10px 0px 0px;
}

.workspace-three-steps {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    font-size: 0;
    display: block;
    width: calc(100% - 0px);
    padding: 0px 10px 10px 10px;
}

.workspace-steps {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    height: 76px;
    z-index: 1;
    width: 100%;
    padding: 0px 0px 0px 20px;
}

.workspace-steps a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.workspace-steps img {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: .5s;
}

.workspace-steps p {
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin-bottom: 0px;
}

.workspace-steps h4 {
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 13px;
    margin-top: 0px;
}

.workspace-steps:hover {
    background-color: #F1F4FB;
    transition: 0.6s;
}

.workspace-steps div:nth-child(3) {
    margin-right: 19px;
}

.workspace-steps:nth-child(1):hover~.workspace-steps-animation {
    width: 100%;
    top: 0;
    background-color: #F1F4FB;
}

.workspace-steps-animation {
    position: absolute;
    height: 76px;
    top: 0;
    z-index: 0;
    transition: all .2s ease 0s;
    border-radius: 4px;
}

.workspace-steps:hover img {
    opacity: 1;
    transition: .5s;
}

.workspace-dash-teams h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px;
}

.workspace-teamlist {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    background-image: url(../../Agency/assets/images/blank-placeholder.png);
    background-repeat: repeat-x;

}

.workspace-teamlist .org-teammember {
    width: 54px;
    margin-right: 10px;
    min-width: 54px;
    height: 54px;
    position: relative;
    background-color: #f8fafe;
    border-radius: 100%;
}
.org-mamber-images-div{
   width: 54px;
    height: 54px;
     border-radius: 100%;
      background-repeat: no-repeat; 
      background-size: cover
}
.workspace-state-id{
   margin-bottom: 0px;
}
.workspace-teamlist .org-teammember .online {
    background-color: #47ce70;
}

.workspace-teamlist .org-teammember .offonline {
    background-color: #bcc1ce;
}

.workspace-teamlist .org-teammember .online,
.workspace-teamlist .org-teammember .offonline {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0px;
    bottom: 6px;
    border: 3px solid #fff;
}
.team-right-panel-div{
   min-height: 80vh;
}
.workspace-module-shadow {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
}

.workspace-no-customer {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 180px);
    border-radius: 6px;
}

.workspace-no-customer div {
    text-align: center;
}

.workspace-no-customer p {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    margin: 32px 0px 30px 0px;
}

.workspace-no-customer a,
.workspace-no-customer a:hover {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #3064F9;
    margin: 0px;
}

.workspaceinput-search {
    border: 1px solid #E3E6F1;
    border-radius: 4px;
    height: 40px;
    width: 300px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0px 14px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    transition: .5s;
    margin-right: 20px;
}

.workspaceinput-search input {
    background-color: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 8px;
    font-size: 14px;
    font-family: "Axiforma-Regular";
}



.worksapce-customer-list {
    background-color: #fff;
    min-height: calc(100vh - 180px);
    border-radius: 6px;
}

.worksapce-applist-head {
    display: flex;
    align-items: center;
    height: 40.5px;
    padding-left: 20px;
    border-bottom: 1px solid #F1F2F8;
}

.worksapce-applist-head div h6 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}

.worksapce-applist-head div:nth-child(1),
.workspace-app-entry:nth-child(1) {
    width: 20%;
    padding-right: 10px;
}

.worksapce-applist-head div:nth-child(2),
.workspace-app-entry:nth-child(2) {
    width: 25%;
    padding-right: 10px;
}

.worksapce-applist-head div:nth-child(3),
.workspace-app-entry:nth-child(3) {
    width: 15%;
    padding-right: 10px;

}

.worksapce-applist-head div:nth-child(4),
.workspace-app-entry:nth-child(4) {
    width: 20%;
    padding-right: 10px;
    display: flex;
}

.worksapce-applist-head div:nth-child(5),
.workspace-app-entry:nth-child(5) {
    width: 20%;
    padding-right: 10px;
}

.worksapceapp-body-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F2F8;
    min-height: 108px;
    width: 100%;
    padding-left: 20px;
    transition: .5s;
}

.workspace-app-profile {
    display: flex;
    align-items: center;
}

.workspace-app-profile h2 {
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #1B2952;
    margin: 0px;
}

.workspace-app-profile h3 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin: 5px 0px 0px 0px;
}

.worksapceapp-body-row h4 {
    font-family: "Axiforma-Regular2";
    font-size: 14px;
    color: #1B2952;
    margin: 0px;
    word-break: break-all;
}

.workspace-apptype-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 6px;
}

.workspaceinput-search:hover,
.workspaceinput-search:focus {
    background-color: #F8FAFE;
    border-color: #3064F9 !important;
    transition: .5s;
}

.workspace-apptype-tooltip .workspace-apptype-tooltipsubheader {
    visibility: hidden;
    width: 140px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 33px;
    left: -58px;
    padding: 4px 14px 3px 14px;
    opacity: 0.8 !important;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    line-height: 21px;
    display: grid;
    text-align: center;
    transition: 0.3s;
}

.workspace-app-image {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
}

.workspace-app-download-stat {
    display: flex;
    align-items: center;
}

.workspace-app-download-stat:nth-child(1) {
    margin-right: 66px;
    min-width: 100px;
}

.workspace-app-download-stat p {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
    margin: 0px 0px 0px 6px;
}

.workspace-createapp-button p {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 14px;
    margin-top: 2px;
    color: white;
}

.workspace-createapp-button img {
    margin-right: 5px;
}

.workspace-createapp-button {
    background-color: #3064f9;
    border: none;
    color: white;
    height: 40px;
    width: 140px;
    border-radius: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.workspace-setting-input{
    width: 50%;
}
.workspace-advancesearch_takethere {
    background: transparent;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 14px;
    text-align: center;
    transition: 0.5s;
    font-family: "Axiforma-Regular";
    color: white;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    line-height: 40px;
}
.workspace-loader-div{
    overflow: hidden;
}
.workspace-right-pannel{
    min-height: 80vh
    }
.workspace-createapp-button:hover {
    color: var(--custom_color4) !important;
    background-color: var(--custom_color5) !important;
}

.workspaceteam-right-box {
    max-width: 100%;
    background-color: #f8fafe;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.workspace-box-img {
    background-size: cover;
    background-color: #fff;
    background-repeat: no-repeat;
    background-origin: content-box;
    padding-top: 122px;
    min-height: calc(100vh - 171px);
}

.workspaceteam-inner-box {
    background-image: url(../../Agency/assets/images/app-icon-background.png);
    border-radius: 6px;
    border: 1px solid #e3e6f1 !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}

.workspaceteam-box-wrapper {
    padding-bottom: 212px;
}
.workspace-team-popup .invite-user {
    padding: 25px 30px 0px 20px !important;
}

.workspaceteam-inner-content {
    /* max-width: 100%; */
    /* max-width: 1840px; */
    display: flex;
    padding-left: 100px;
    padding-right: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.workspaceteam-content {
    margin-right: 10px !important;
    /* max-width: 520px; */
    padding-top: 20px;
}

.workspaceteam-img {
    display: flex;
    align-items: center;
}

.workspaceteam-img img {
    width: 100%;
    height: auto;
}

h3.workspaceteam-paragraph {
    color: #1b2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    margin-bottom: 20px !important;
}

.workspaceteam-content-text {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 19px;
    margin-bottom: 30px;
    text-align: left !important;
}

.workspaceteam-content-details ul {
    padding-left: 0 !important;
}

.workspaceteam-content-details ul li {
    line-height: 17px;
    font-size: 13px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;
    padding-bottom: 12px;
    list-style-type: none;
    position: relative;
}

.workspaceteam-content-details ul li:before {
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 5px;
}

.workspacebtn.btn-primary {
    width: 200px;
    background-color: #3064f9;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-family: "Axiforma-Regular";
    height: 50px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    margin-top: 18px;
    border: 1px solid transparent;
}

.workspacebtn.btn-primary:hover {
    background-color: #0040f9;
    border-color: #0040f9;
}

.workspace-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 27px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 4px;
    background-color: #E3E6F1;
    border-radius: 50%;
}

.workspace-logo:hover .workspace-logo-shadow {
    opacity: 1;
    transition: .5s;
}
.workspace-logo img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.workspace-setting-head .workspacesetting-save{
    display: flex;
    align-items: center;
}
.workspace-logo-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .5s;
    cursor: pointer;
    border-radius: 50%;
}


.workspace-logo-edit {
    color: #7782A1;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 100%;
    padding: 10px 30px;
    font-size: 13px;
    line-height: 21px;
    display: flex;
    text-align: left !important;
    justify-content: space-between;
}

.workspace-logo-edit .profilepic-upload {
    margin: 0px;
    cursor: pointer;
    color: #7782A1;
    font-family: "Axiforma-Regular";
}
p.newapproval-overview {
    color: #FF4848 ;
    background-color: #FFF ;
        margin-bottom: 0px;
  }
.workspace-logo-edit .profilepic-remove {
    margin: 0px 0px 0px 30px;
    color: #FF4848;
    cursor: pointer;
    font-family: "Axiforma-Regular";
}

.workspace-datamain {
    display: flex;
    align-items: center;
}
.wprkspace-mobshow{
    display: none;
}
.workspace-headingdiv a{
  display: none;
}
.workspace-apps-mobile-view {
    display: none;
}
.invite-workspace-team-mob {
    display: none;
}
.workspace-save-team-btn{
    width: 120px;
    height: 40px;
    border: none;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: .5s;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
}
.workspace-head-right{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.workspace-head-right p {
    font-family: "Axiforma-Regular";
    font-size: 16px;
    color: #7782a1;
    margin-bottom: 0;
    margin-right: 0px;
}
.workspace-head-right p span {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #bcc1ce;
    margin-bottom: 0;
    margin-right: 0px;
}

.wprkspace-team-table-head {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    height: 40.5px;
    padding: 0 20px;
    border-bottom: 1px solid #f1f2f8;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.wprkspace-team-table-head div:first-child {
    width: 30%;
}
.wprkspace-team-table-head div:nth-child(2) {
    width: 25%;
}
.wprkspace-team-table-head div:nth-child(3) {
    width: 15%;
}
.wprkspace-team-table-head div:nth-child(4) {
    width: 20%;
}
.wprkspace-team-table-head div:nth-child(5) {
    width: 20%;
}
.wprkspace-team-table-head h6 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    margin: 0;
}
.workspace-team-body{
        background-color: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        min-height: 75vh
}
.workspace-member-img-name, .worksapce-team-member-detail {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.workspace-member-img-name {
    width: 30%;
}
.workspace-team-member-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E3E6F1;
    padding: 0px 20px;
    min-height: 108px;
}
.workspace-member-img {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 100px;
    margin-right: 11px;
}
.workspace-member-img-name p {
    color: #7782a1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
    margin-top: 10px;
}
.workspace-member-img-name h2 {
    color: #1b2952;
    font-size: 16px;
    font-family: "Axiforma-Bold";
    margin: 0;
}
.workspace-team-member-email {
    width: 25%;
}
.workspace-team-member-email p {
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0;
}
.workspace-team-member-role {
    width: 15%;
}
.workspace-team-member-role p {
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
}
.worksapce-team-member-time {
    width: 20%;
}
.worksapce-team-member-time h3 {
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0;
}
.worksapce-team-member-time h5 {
    color: #bcc1ce;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0;
    margin-top: 10px;
}
.workspace-domain-right, .workspace-teams-status {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.workspace-teams-status {
    width: 20%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.workspace-team-member-status {
    width: -webkit-max-content;
    width: max-content;
}

.workspace-team-member-status span{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #3064F9;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
    display: none;
    transition: .5s;
}
.workspace-team-member-detail:hover {

        background-color: #F8F9FD;
        transition: .5s;
    
}
.worksapceapp-body-row:hover {

    background-color: #F8F9FD;
    transition: .5s;

}
.workspace-team-member-detail:hover .workspace-team-member-status span{
    display: block;
    }
.workspace-team-member-status h6 {
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0;
}
.workspace-team-member-status .teamstatusgreen {
    color: #47ce70;
}
.workspace-team-member-status .teamstatusred {
        color: #FF4848
    }

.workspace-team-member-status .teamstatusgrey{
    color: #7782A1;
  }
  .workspace-team-member-edit {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: end;
    justify-content: end;
    width: 80px;
}
.workspace-team-member-edit svg:first-child {
    margin-left: 0;
}

.workspace-team-member-edit svg {
    cursor: pointer;
    margin-left: 40px;
}
.workspace-setting-head {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 0 0 20px;
    min-height: 60px;
}
.workspace-setting-head h1 {
    font-family: "Axiforma-Bold";
    font-size: 24px;
    color: #1B2952;
    margin-bottom: 0px;
}
.workspace-setting-head p {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
}
.workspace-web-timestamp {
    color: #bcc1ce;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0 20px 0 0;
}
.workspace-save-setting-btn{
        width: 100px;
        height: 40px;
        border: none;
        background-color: #3064f9;
        font-size: 14px;
        color: #fff;
        font-family: "Axiforma-Regular";
        border-radius: 3px;
        transition: .5s;
        cursor: pointer;
        text-align: center;
        line-height: 40px;
        display: flex;
    justify-content: center;
    align-items: center;
}
.workspace-save-setting-btn:hover {
    background-color: #134bec;
    transition: .5s;
    box-shadow: 0 2px 4px rgb(112 155 250/69%);
}
.workspace-save-social-btn-deactivate, .workspace-save-social-btn-deactivate:hover {
    background-color: #bcc1ce;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;
}
.workspace-setting-body {
    background-color: #fff;
    padding: 30px 30px 40px 30px;
    border-radius: 6px;
}
.workspace-setting-body input {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    background-color: #fff;
    padding: 0px 20px;
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    display: block;
    transition: .5s;
}
.workspace-setting-body label {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782a1;
    margin-bottom: 9px;
    display: block;
}
.workspace-delete-org {
    background-color: #fff;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
}
.workspace-delete-org h3 {
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1b2952;
    margin-bottom: 10px;
    margin-top: 0;
}
.workspace-delete-org a, .workspace-delete-org h4 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
}

.del-work-btn{
    width: 170px;
    height: 40px;
    border: 1px solid #E3E6F1;
    background-color: transparent;
    font-size: 14px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    display: block;
    text-align: center;
    line-height: 40px;
    transition: .5s;
    display: flex;
        align-items: center;
        justify-content: center;
}

.del-work-btn:hover {
    background-color: #FF4848;
    border: 1px solid #FF4848;
    color: #fff;
    transition: .5s;
}

.workspace-delete-org a {
    width: 170px;
    height: 40px;
    border: 1px solid #E3E6F1;
    background-color: transparent;
    font-size: 14px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    display: block;
    text-align: center;
    line-height: 40px;
    transition: .5s;
}
.workspace-delete-org a:hover {
    background-color: #FF4848;
    border: 1px solid #FF4848;
    color: #fff;
    transition: .5s;
}
.workspace-mobile-btns {
    display: none;
}
.workspace-setting-body input:hover, .workspace-setting-body input:focus {
        transition: .5s;
        background-color: #f8fafe;
        border-color: #3064f9!important;
    }
.workspacecancle{
    margin-right: 20px;
    display: flex;
justify-content: center;
align-items: center;
width: 140px !important;
}
.workspacedokaybtn{
display: flex;
justify-content: center;
align-items: center;
width: 140px !important;

}
.confirm-workspace-popups h5 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    line-height: 20px;
}
.confirm-workspace-popups h4 {
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
}
.confirm-workspace-popups h5 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    line-height: 20px;
}
.confirm-workspace-popups h6 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    line-height: 20px;
    margin-top: 20px;
}
.confirm-workspace-popups input {
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    background-color: #fff;
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    outline: none;
    font-family: "Axiforma-Regular";
    font-size: 13px;
}
.confirm-workspace-footer-button-cancel {
    border: 1px solid #BCC1CE;
    width: 100px;
    height: 40px;
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    font-size: 13px;
    transition: .5s;
    font-family: 'Axiforma-Regular';
}
.confirm-workspace-footer-button-delete {
    border: 1px solid #FF4848;
    width: 100px;
    height: 40px;
    background-color: transparent;
    border-radius: 3px;
    color: #FF4848;
    font-size: 13px;
    transition: .5s;
    font-family: 'Axiforma-Regular';
}
.confirm-workspace-footer {
    border-top: 1px solid #E3E6F1;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.confirm-workspace-popups .modal-body, .delete-org-popups .modal-body {
    padding: 0px;
}
.workspace-before-delete-footer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirm-workspace-popups .confirm-workspace-body {
    padding: 30px 20px 10px 20px;
}
.workspace-completesteps{
    padding: 25px 50px !important;
    text-align: center;
}
.workspace-completesteps h4{
    font-family: "Axiforma-Bold";
    color: #1b2952;
    font-size: 18px;

}
.workspace-completesteps p {
    color: #7782A1;
    font-size: 15px;
    font-family: "Axiforma-Regular";

    margin-bottom: 30px;
    margin-top: 18px;
}
.workspace-buttonbox {
    background-color: #3064f9;
    border-radius: 3px;
    width: 140px;
    margin: auto;
    height: 44px;
    line-height: 48px;
    margin-bottom: 30px;
}
.workspace-buttonbox p{
    color: white;
    font-size: 14px;
    margin: 0px;
    line-height: 44px;
}
.workspace-timediv{
    color: #BCC1CE;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    display: none;
}
.downloaddivstyle {

    align-self: unset !important;
    padding: unset !important;
    background-color: unset !important;
    border: unset !important;
    box-shadow: unset !important;
    border-radius: unset  !important;
    width: unset !important;
}
.workspace-team-popup .modal-content {
    border-radius: 6px;
    outline: 0;
    overflow: hidden;
}
.workspace-team-popup .workspace-team-popup-footer {
    padding: 15px 20px !important;
}
.workspace-team-popup .cancel-user .cancel-btn {
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    border: 1px solid #BCC1CE;
}
.workspace-team-popup .cancel-user .cancel-btn:hover {
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.workspace-team-popup .next_btn {
    border-radius: 3px;
    transition: .5s;
    text-transform: capitalize;
    min-width: 100px;
    padding: 0px;
}
.workspace-team-user-inactive {
    background-color: #BCC1CE;
    border: 1px solid #BCC1CE;
    box-shadow: none;
    cursor: not-allowed!important;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    font-family: "Axiforma-Regular";
    padding: 11px 30px;
    font-weight: 500;
    line-height: 15px;
    /* text-transform: uppercase; */
    transition: 0.5s;
    width: 100px;
    height: 40px;
}

.workspacenext_btn {
    background-color: #3064f9;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    font-family: "Axiforma-Regular";
    padding: 11px 30px;
    font-weight: 500;
    border: 1px solid #3064f9;
    line-height: 15px;
    /* text-transform: uppercase; */
    transition: 0.5s;
    cursor: pointer;
    width: 100px;
    height: 40px;
  }

  .workspacenext_btn:hover {
    background-color: #134bec;
    box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
    color: #fff;
    transition: 0.5s;
  }

.workspace-team-popup {
    max-height: fit-content!important;
}
.workspace-profile-pic-dummy{
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0px;
        border-radius: 50%;
        background-color: #E3E6F1
}
.workspace-profile-pic-row{
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 116px;
    background-position: center;
    border-radius: 58px
}
.workspace-profile-pic-rowsecond{
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 106px;
    background-position: center;
    border-radius: 58px
}
.workspace-leftpanel-dashboard-text{
   transform: translate3d(43px, 0px, 0px);
}

@media (min-width: 1650px) and (max-width: 1850px) {
    /* .workspaceteam-inner-content {
        padding-right: 200px;
        padding-left: 90px !important;
    } */

    /* .workspaceteam-content {
        margin-right: 80px !important;
    } */
}


@media (min-width: 1401px) and (max-width: 1649px) {
    .workspaceteam-content {
        margin-right: 10px !important;
    }
 
}
@media (min-width: 1201px) and (max-width: 1600px) {
    .worksapce-applist-head div:nth-child(4),
    .workspace-app-entry:nth-child(4) {
        display: grid;
    }
    .workspace-app-download-stat:nth-child(1) {
        margin-bottom: 5px;
    }
 
}

@media (min-width: 1201px) and (max-width: 1480px){
.workspace-org-right-panel {
    width: 300px;
}

.workspace-dashboard-asuser{
    width: 100% ;
}
.workspace-dashboard-midsection {
    width: calc(100% - 300px);
}
.workspace-datamain {
    display: block;
}
.workspace-data {
    margin-left: 0px;
    margin-top: 15px;
}
.workspace-personal-data{
    display: block;
}
.workspace-personal-data div:nth-child(1) {
    margin-bottom: 15px;
    margin-right: 0px;

}
.workspace-app-data div h3 {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
}
.downloaddivstyle {
    display: unset !important;
    
    margin-left: 10px;
}
.workspace-app-data div p {
    padding: 0px;
}
}




@media (max-width: 1200px) {
    .workspace-teams-mob-3-flex{
        display: flex;
    justify-content: space-between;
    width: 100%;
    }
    .workspace-teams-mob-3-flex .mobileactivate{
        position: relative;
        bottom: 5px;
    }
    .workspace-teams-mob-3-flex .workspace-team-member-edit svg {
        margin-left: 20px;
    }
    .newcreation-date h5 {
        text-align: left;
    }
    .workspace-team-user-inactive, .workspacenext_btn {
        width: 100%;
        position: relative;
        top: -30px;
    }
    .workspace-teams-mob-4 .workspace-team-member-edit svg {
       
        margin-left: 20px;
    }
    .workspace-team-member-status .teamstatusred {
        margin-top: 10px;
       
    }
    .workpsace-filter-search-btn{
        height: 50px;
    }
    .workpsace-filter-cancle-btn{
        display: flex;
        margin-top: 15px;
    }
    .workspace-filter-open {
        width: 100%;
    /* right: -100%; */
    right: 0%;
    }
    .workspace-filter-seconddiv .select-filter__control {
  
            height: 50px !important;
    }
    .workspace-filter-delete {
        height: 50px;
        width: 50px;
    }
    .workspace-filter-input {
        height: 50px !important;
    }
    .workspace-user-app-data div{
        width: 100%;
    }
    .workspacelisting-type{
        display: grid;
    }
    .newworkspace-type img{
        margin-right: 0px;
       }
    .workspaceicontype .newworkspace-type {
        display: none;
       }
       .mob-workspace-type{
        display: block;
       }
       .mob-workspace-type .newworkspace-type img{
        height: 30px!important;
        width: 30px !important;
       }
    .workspace-team-member-detail:hover {

        background-color: unset;
        transition: .5s;
    
}
    .workspace-create-add-btn:hover {
        background-color: unset;
        transition: 0.5s;
        box-shadow:unset;
    }
    .no-worksapce-created{
        padding: 100px 15px 0px 15px
    }
    .no-worksapce-created h2{
   
        margin-top: 50px;
        margin-bottom: 19px;
        text-align: center;
    }
    .no-worksapce-created button {
        margin-top: 40px;
    }
   
    .workspace-create-add-btn h5 {  
        padding-left: 6px;
    }
    .web-workspace-createtext{
     display: none;
    }
    .mob-workspace-createtext{
        display: block;
       }
    .newworkspace-det-tile img{
        width: 80px !important;
    height: 80px !important;
    }
    .newworkspace-det-tile .profile-pic-img{
        width: 80px !important;
    height: 80px !important;
    }
    .workspacelist-det p {
        margin-bottom: 10px;
    }
    .workspacelist-det{
        padding-left: 20px;
        display: flex;
        justify-content: unset;
        align-items: center;
        width: calc(100% - 80px);
    }
    .workspace-create-add-btn {
        padding: unset;  padding: unset;
        border-radius: 0px;
        background-color: unset;
        color: #3064F9;
    }
    .newadd-workspace-header {
        margin-bottom: 20px;
        align-items: unset;
    }
    .workspace-create-add-btn a{
        padding: unset;
    }
    .workspace-create-add-btn h5 {
        color: #3064F9;
    }
    .workspace-create-mob-plus{
display: block;
}
.workspace-create-web-plus{
    display: none;
}
    .newadd-workspace-inner{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 75px;
}
    .newcontainer-workspace{
        background-image: unset; 
    }
    .newworkspace-det-tile {
        border-radius: 6px;
        margin-bottom: 15px;
        background-color: white;
        border: none;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
        padding: 30px 20px;
    }
    .workspace-team-popup .workspace-team-popup-footer {
        display: block!important;
        width: 100%;
        border: none!important;
        padding: 12px 20px 45px 20px !important;
    }
    .workspace-team-popup .next_btn {
        width: 100%;
        position: relative;
        top: -30px;
    }
    .workspace-team-popup .cancel-user .cancel-btn {
        width: 100%;
        position: relative;
        top: 80px;
        border: none;
        text-transform: capitalize;
    }
    .workspace-team-popup .modal-content {
        position: inherit !important;
        padding-top: 100px;
        min-height: 100vh;
    }
    .workspace-team-popup p{
        text-align: left !important;
    }
    .workspace-team-popup .invite-user {
        padding: 0px 20px 0px 20px !important;
    }
    .workspace-teams-mobile{
        display: block;
    }
    .workspace-plan-detail{
        margin-bottom: 20px;
    }
    .workspace-headings-mob{
        display: flex !important;
    justify-content: space-between !important;
    }
    .workspace-buttonbox {
        background-color: #3064f9;
        border-radius: 3px;
        width: 280px;
        margin: unset;
        height: 50px;
        line-height: 48px;
        margin-bottom: 30px;
    }
    .workspace-buttonbox p{
        font-size: 16px;
        line-height: 50px;
    }
    .workspace-completesteps {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 15px 15px !important;
    }
    .confirm-workspace-popups .confirm-workspace-footer-button-delete {
        position: relative;
        top: -30px;
    }
    .confirm-workspace-popups .confirm-workspace-footer-button-cancel {
        position: relative;
        top: 80px;
    }
    .confirm-workspace-popups .confirm-workspace-body {
        padding: 250px 34px 10px 34px;
        text-align: center;
    }
    .workspacecancle{
        width: 100% !important;
    }
    .workspacedokaybtn{
        width: 100% !important;
    }
    .confirm-workspace-footer-button-delete {
        width: 100%;
        height: 50px;
        background-color: #FF4848;
        margin: auto;
        color: #fff;
        font-size: 16px;
    }
    .confirm-workspace-footer-button-cancel {
        width: 100%;
        height: 50px;
        margin: auto;
        font-size: 16px;
        border: none;
    }
    .confirm-workspace-footer {
        border-top: none;
    padding: 0px;
    display: block;
    padding: 0px 34px 0px 34px;
    margin: auto;
    }
    .workspace-before-delete-footer{
        display: unset;
    align-items: unset;
    justify-content: unset;
   
    }
    .workspace-leave-popup{
        padding: 15px;
    }
    .confirm-workspace-popups .workspacedokaybtn {
        position: relative;
        top: -54px;
    }
    .confirm-workspace-popups .workspacecancle {
        position: relative;
        top: 66px;
    }
    .workspace-setting-head .workspacesetting-save{
        display: none;
    }
    .workspace-setting-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 14px;
        min-height: unset;
    }
    .workspacecancle{
        margin-right: 0px;
    }
    p.newapproval-overview {
        color: white ;
        background-color: #FF4848 ;
            margin-bottom: 0px;
      }
    .workspace-tooltip .workspace-tooltipsubheader  {
        width: 190px;
        top: 35px;
        left: -50px;
    }
    .workspace-tooltip .workspace-tooltipsubheader::after {
        top: -3px;
        right: 65%;
        margin-top: -7px;
        transform: rotate(90deg);
    }
    .mob-workspace-mod-shadow{
        box-shadow: 0 2px 6px rgba(0,0,0,.06) !important;
    border-radius: 6px !important;
    border: 1px solid #e3e6f1 !important;
    }
    .workspace-delete-org {
        display: block;
        padding: 25px 15px 30px;
    }
    .workspace-mobile-btns {
        display: block;
    }

    .del-work-btn{
        width: 100%;
        height: 50px;
        margin-top: 29px;
        line-height: 50px;
        font-size: 16px;
    }

    .workspace-delete-org a {
        width: 100%;
        height: 50px;
        margin-top: 29px;
        line-height: 50px;
        font-size: 16px;
    }
    .workspace-mobile-btns-blue{
        background-color: #3064f9;
        color: #fff;
    height: 50px;
    border-radius: 3px;
    width: 100%;
    transition: .5s;
    position: relative;
    margin-top: 20px;
    text-align: center;
    line-height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    }
    .workspace-setting-body{ 
            padding: 30px 15px;
    }
    .workspace-mobile-btns h5 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        text-align: center;
        margin-top: 17px;
        width: 100%;
    }
    .workspace-mobile-btns h5 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        text-align: center;
        margin-top: 17px;
        width: 100%;
    }
    .workspace-teams-mob-1{
  
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            height: 108px;
            padding: 0 15px;
            border-bottom: 1px solid rgb(227 230 241/50%);

    }
    .workspace-teams-mob-1 h2 {
        font-family: "Axiforma-Bold";
        font-size: 14px;
        color: #1b2952;
        margin: 0;
    }
    .workspace-teams-mob-1 .member-img {
        margin-right: 0;
    }
    .workspace-teams-mob-1 h3 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782a1;
        margin: 7px 0 0;
    }
    .workspace-teams-mob-2 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 85px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-teams-mobile h4 {
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1b2952;
        margin: 6px 0 0;
    }
    .workspace-teams-mobile h6 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        margin: 0;
    }
    .workspace-teams-mob-3 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 109px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-teams-mob-4{
        display: flex;
    align-items: center;
    justify-content: space-between;
    height: 89.5px;
    padding: 0 15px;
    border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-teams-mob-4 h5 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        margin: 8px 0px 0px 0px;
    }
    .workspace-teams-mob-3 h5 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782a1;
        margin: 8px 0 0;
    }
    .agency-teams-mob-4 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 89.5px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-head-right{
            margin-right: 0;   
    }
    .workspace-teams-web-view {
        display: none;
    }
    .workspace-web-team-btn{
     display: none;
    }
    .invite-workspace-team-mob .workspace-save-team-btn {
        width: 100%;
        margin-bottom: 20px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        margin-left: 0px;
    }
    .invite-workspace-team-mob {
        display: block;
    }
    .workspaceteam-inner-content{
     display: grid;
     max-width: unset;
     padding-right:20px;
     padding-left: 20px;
     justify-content: unset;
    }
    .workspace-box-img {
        padding-top: 0px;
        background-image: unset;
    }
    .workspace-setting-input{
        width: 100%;
    }
    .workspace-apps-mobile-view {
        display: block;
    }
    .workspace-apps-web {
        display: none;
    }
    .workspaceteam-content {
     max-width: unset;
     margin-right: 0px !important;
    }
    h3.workspaceteam-paragraph {
        font-size: 30px;
        line-height: 30px;
    }
    .workspace-teams-mobile .workspace-module-shadow, .workspace-apps-mobile .workspace-module-shadow {
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        border: 1px solid #E3E6F1;
    }
    
    .workspaceteam-img {
   margin-top: 60px;
}
.workspaceteam-box-wrapper {
    padding-bottom: 20px;
}
.workspacebtn.btn-primary {
    width: 100%;
        font-size: 16px;
}
    .workspace-apps-mob-1 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 108px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-apps-mob-1  h2 {
        font-family: "Axiforma-Bold";
        font-size: 14px;
        color: #1b2952;
        margin: 0;
    }
    .workspace-app-image-mobile {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        height: 60px;
        min-height: 60px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        border-radius: 15px;
    }
    .workspace-apps-mob-1 h3 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782a1;
        margin: 7px 0 0;
    }
    .workspace-apps-mob-2 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 85px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-apps-mobile h6 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        margin: 0;
    }
    .workspace-apps-mob-2 h4 {
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1b2952;
        margin: 6px 0 0;
    }
    .workspace-apps-mob-3 {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 94px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-apps-mob-3 img {
        margin-top: 9px;
    }
    .workspace-apps-mob-4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 95px;
        padding: 0 15px;
        border-bottom: 1px solid rgb(227 230 241/50%);
    }
    .workspace-app-technology {
        width: 100%;
        margin-top: 9px;
    }
    .agency-apps-mobile h6 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        margin: 0;
    }
    .create-workspace-main {
        display: block;
    }

    .create-workspace-left {
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 100%;
        height: auto;
    }

    .create-workspace-left-position {
        margin-top: 31px;
        padding: 0 20px;
    }

    .create-workspace-right {
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 100%;
        -webkit-justify-content: center;
        justify-content: center;
        display: -webkit-flex;
        display: flex;
        padding: 0;
        background-color: #fff;
        height: auto;
    }

    .create-workspace-right-position {
        margin-top: 60px;
        max-width: 100%;
        padding: 0 20px;
    }

    .create-workspace-left h1 {
        font-size: 30px;
    }
    .create-workspace-left h2 {
        margin: 16px 0 31px;
        line-height: 18px;
    }
    .create-workspace-left button {
        font-size: 16px;
    }
    .workspace_background {
        background-color: #f8fafe;
        padding: 24px 15px 30px;
        width: 100%;
        margin-left: 0;
    }
    .workspace-dashboard {
        display: grid;
        align-items: flex-start;
    }
    .workspace-dashboard-midsection {
        width: 100%;
    }
    .workspace-org-right-panel {
        width: 100%;
    }
    .wprkspace-mobshow{
        display: block;
    }
    .workspace-dash-mid-panel {
        display: grid;
        width: 100%;
        justify-content: unset;
    }
    .workspace-app-info {
        width: 100%;
        margin-bottom: 20px;
    }
    .workspace-personal-data {
        width: 100%;
        margin-bottom: 0px;
    }
    .workspace-app-data {
        display: grid;
        justify-content: unset;
        width: 100% ;
        margin-top: 0px;
    }
    .workspace-app-data div {
     width: 100%!important;
     margin-top: 20px;
    }
    .downloaddivstyle{
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
    .no-workspace-data-graph {
        width:100%;
        margin-bottom: 20px;
        margin-top: 0px;
    }
    .workspace-total-steps {
        margin-top: 0px;
    }
    .workspaceinput-search {
        height: 50px;
        width: 100%;
        margin-right: 0px;
    }
    .workspace-advancesearch_takethere {
        margin-left: 10px;
        margin-right: 0px;
        height: 50px;
        padding: 0px 12px;
        display: flex;
        border-radius: 6px;
        border: 1px solid #E3E6F1;
        background-color: white;
    }
    .workspace-headings {
        display: grid;
        justify-content: unset;
    }
    .workspace-setting-headings {
       display: flex !important;
    }
    .workspace-createapp-button{
       display: none;
    }
    .workspace-headingdiv{
        display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    }
    .workspace-headingdiv a{
        display: flex;
        align-items: center;
      }
      .workspace-headingdiv a img{
        margin-right: 6px;
      }
      .workspace-headingdiv a p{
       color: #3064F9;
       font-size: 15px;
       font-family: "Axiforma-Regular";
      }
      .workspace-no-customer {
        background-color: unset;
        height: calc(100vh - 227px);
        border-radius: 0px;
    }
    .workspace-module-shadow {
        box-shadow: unset;
        border-radius: 0px;
        border: unset;
    }

}