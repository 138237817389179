@font-face {
  font-family: "Axiforma-Regular";
  src: url(../../assets/fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../../assets/fonts/Axiforma-Regular2.woff2);
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url(../../assets/fonts/Axiforma-Bold.woff);
}

.support-checkoutbtn
{
  border: none;
    background-color: #3064f9;
    color: #fff;
    border-radius: 3px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}

.support-checkouttext {
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 25px;
}

.support-checkouttext a {
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}

.checkout-page{
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 50px;
}
.checkout-billing-details{
  width: 720px;
  background-color: #fff;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 30px 30px 20px 30px;
}
.checkout-purchase-detail{
  width: 340px;
}
.checkout-payment-method{
  width: 340px;
  background-color: #fff;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 30px;
  margin-bottom: 20px;
}
.checkout-purchase-summary{
  width: 340px;
  background-color: #fff;
  border: 1px solid #E3E6F1;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 30px 30px 0px 30px;
}
.checkout-billing-details h4{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.checkout-billing-details h5{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 11px 0px 25px 0px;
}
.checkout-double{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.checkout-half{
  width: 48%;
}
.checkout-half h6{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 9px 0px;
}
.checkout-billing-details input{
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #BCC1CE;
  transition: .5s;
  outline: none;
  padding: 0px 20px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1B2952!important;
}
.checkout-billing-details input:hover , .checkout-billing-details input:focus{
  background-color: #F8FAFE;
  border-color: #3064F9!important;
  transition: .5s;
}
.checkout-single{
  margin-bottom: 18px;
}
.checkout-full{
  width: 100%;
}
.checkout-full h6{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 9px 0px;
}
.checkout-payment-method-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.checkout-payment-method-head h4{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.checkout-payment-method-head h6 , .checkout-payment-method-head a{
  color: #3064F9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  cursor: pointer;
}
.checkout-payment-method-head h6 svg , .checkout-payment-method-head a svg{
  margin-right: 5.5px;
}
.checkout-purchase-summary h4{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px 0px 31px 0px;
}
.checkout-country{
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #BCC1CE;
  transition: .5s;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.checkout-country .dropdown-toggle{
  width: 100%;
  height: 50px;
  background-image: url(../../assets/images/dashboard/email-support-arrow.png)!important;
  background-repeat: no-repeat!important;
  background-position: 97%!important;
  -webkit-appearance: none;
  padding: 0px 15px;
  color: #1B2952!important;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-align: left;
  -webkit-appearance: none;
}
.checkout-country .dropdown-toggle::after{
  display: none!important;
}
.checkout-country .dropdown-menu{
  width: calc(100% + 4px);
  margin: 0px;
  padding: 0px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -2px;
}
.checkout-country .dropdown-menu .form-group{
  margin-bottom: 0px;
}
.checkout-country .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px!important;
  color: #1B2952;
  font-size: 13px!important;
  font-family: "Axiforma-Regular"!important;
  margin: auto!important;
  border: 0px solid #fff !important;
}
.checkout-country .dropdown-item:hover {
  border: 0px solid #fff !important;
  background-color: #F8F9FD!important;
  color: #1B2952!important;
}
.checkout-country .dropdown-item.active, .checkout-country .dropdown-item:active {
  background-color: #F8F9FD!important;
  color: #1B2952!important;
}
.checkout-country .dropdown-item.disabled{
  background-color: #F8F9FD!important;
  opacity: 1!important;
}
.checkout-country .dropdown-menu::-webkit-scrollbar-track {
  background-color: white!important;
}
.checkout-payment-method .checkout-country .dropdown-toggle {
  height: 40px;
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-align: left;
  -webkit-appearance: none;
}
.checkout-payment-method .checkout-country {
  height: 40px;
}
.checkout-payment-method .checkout-country .dropdown-menu{
  height: auto;
}
.checkout-billing-details input:read-only{
  background-color: #F8FAFE!important;
  cursor: not-allowed;
  color: #7782A1!important;
  opacity: 1!important;
}
.checkout-country .dropdown-toggle:disabled{
  background-color: #F8FAFE!important;
  cursor: not-allowed;
  opacity: 1!important;
  height: 48px;
  color: #7782A1!important;
}
.purchase-summary-sections{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.purchase-summary-sections h2{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  max-width: 170px;
}
.purchase-summary-sections h3{
  color: #1B2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.purchase-summary-total{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-top: 30px;
  border-top: 1px solid #E0E5EC;
}
.purchase-summary-total h2{
  color: #1B2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.purchase-summary-total h3{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.checkout-purchase-summary button{
  background-color: #3064F9;
  border-radius: 3px;
  height: 40px;
  border: 1px solid #3064F9;
  width: 100%;
  margin-bottom: 20px;
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  transition: .5s;
}
.checkout-purchase-summary button:hover{
  background-color: #134BEC;
  border: 1px solid #3064F9;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
  transition: .5s;
}
.checkout-purchase-summary button:disabled{
  background-color: #BCC1CE;
  cursor: not-allowed;
  border: 1px solid #BCC1CE !important;
  box-shadow:none;
}
.checkout-purchase-summary button:disabled:hover{
  background-color: #BCC1CE;
  cursor: not-allowed;
  border: 1px solid #BCC1CE !important;
  box-shadow:none;
}
.checkout-purchase-summary p{
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 25px;
}
.checkout-purchase-summary a , .checkout-purchase-summary a:hover{
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.the-disabled{
  background-color: #F8FAFE!important;
  cursor: not-allowed;
  opacity: 1!important;
}
.checkout-page-head{
  display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1080px;
    margin: auto;
    margin-bottom: 16px;
    margin-top: 43px;
}
.checkout-page-head h1{
  color: #0F193B !important;
  font-size: 24px !important;
  font-family: "Axiforma-Bold" !important;
  margin: 0px !important;
  line-height: 30px;
}
.checkout-page-head h2{
  color: #BCC1CE!important;
  font-size: 13px!important;
  font-family: "Axiforma-Regular"!important;
  margin: 0px!important;
}
.checkout-country:hover, .checkout-country:focus {
  border: 1px solid #3064F9!important;
  background-color: #F8FAFE!important;
  transition: .5s;
}
.checkout-billing-details .dropdown-search input:hover, .checkout-billing-details .dropdown-search input:focus{
  border-top: 1px solid #F1F2F8!important;
  border-left: 1px solid #F1F2F8!important;
  border-right: 1px solid #F1F2F8!important;
  border-bottom: 1px solid #F1F2F8!important;
  background-color: #fff!important;
}
.checkout-active{
  color: #7782A1;
  cursor: pointer;
}
.checkout-active-underline{
  color: #7782A1;
  text-decoration: underline;
}
.show-card-number-preview{
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  height: 40px;
  width: 100%;
  padding: 11px 15px;
}
.show-card-number-preview h3{
  color: #1B2952!important;
  font-size: 14px!important;
  font-family: "Axiforma-Regular";
  margin: 0px;
}

.reviewsdiv{
  background-color: white;
  margin: 0px -30px -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 81px 0px 100px 0px;
}
.rattingdiv{
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificateimgdiv{
  margin-top: 110px;
}
.customersratting{
  width: 443px;
  margin-right: 108px;
}
.customersratting h2{
  font-size: 36px;
  color: #0F193B;
  font-family: 'Axiforma-Bold';
  line-height: 54px;
  margin-bottom: 0px;
}
.customersratting p{
  font-size: 16px;
  color: #5F6578;
  line-height: 30px;
  margin-bottom: 0px;
  margin-top: 25px;
  text-align: left !important;
}
.mobcertificateimg{
  display: none;
}
.mobrattingimg{
  display: none;
}
.checkout-checkbox{
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.checkout-terms{
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 12px !important;
  line-height: 16.13px;
  margin-bottom: 20px;
}
.checkout-checkbox input:checked ~ .checkmark {
  background-color: #3064f9 !important;
}
.checkout-checkbox .checkmark:after {
  top: 2px;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}
.checkout-checkbox .container1{
  padding-top: 0px;
}

@media screen and (max-width: 1600px) and (min-width: 1280px)  { 
  .reviewsdiv{
    margin: 0px -20px -25px;
  
  }
}
@media screen and (max-width: 700px) {
  .customersratting h2 {
    font-size: 26px;
    line-height: 36px;
}
.customersratting p {
  font-size: 16px;
  color: #5F6578;
  line-height: 24px;
  margin-top: 15px;
  margin-bottom: 50px;
}

  .mobcertificateimg{
    display: block;
    margin-top: 50px
  }
  .reviewsdiv {
    margin: 0px;
        padding: 60px 20px;
        align-items: unset;
  }
  .rattingdiv {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
}
  .customersratting {
    width: unset !important;
    margin-right: unset !important;
}
  .certificateimgdiv{
    display: none;
  }
  .rattingimg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }
  .certificateimg{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobrattingimg{
    display: block;
  }
  .webrattingimg{
    display: none;
  }
}


@media screen and (max-width: 1200px) {
  .rattingimg{
    width: 50%;
  }
  .certificateimg{
    width: 100%;
  }
  .reviewsdiv {
        margin: 0px;
        padding: 55px 20px;
        align-items: unset;
  }
  .customersratting{
    width: 50%;
    margin-right: 50px;
  }

  .purchase-summary-sections {
    margin-bottom: 12px;
  }
  .checkout-purchase-summary button {
    height: 50px;
    font-size: 16px;
  }
  .purchase-summary-total {
    margin-bottom: 25px;
  }
  .checkout-billing-details h5 {
    margin: 8px 0px 25px 0px;
  }
  .checkout-single {
    margin-bottom: 18px;
}
  .checkout-double {
    margin-bottom: 18px;
  }
  .checkout-page {
    max-width: 100%;
    display: block;
    margin: 0px 15px 50px 15px;
  }
  .checkout-page-head{
    margin: 24px 15px 18px 15px;
  }
  .checkout-billing-details , .checkout-purchase-detail , .checkout-payment-method , .checkout-purchase-summary{
    width: 100%;
  }
  .checkout-billing-details{
    margin-bottom: 15px;
    padding: 28px 15px 10px 15px;
  }
  
  .checkout-double {
    display: block;
  }
  .checkout-half {
    width: 100%;
  }
  .checkout-double .checkout-half:nth-child(2){
    margin-top: 18px;
  }
  .checkout-payment-method .checkout-country .dropdown-toggle {
    height: 50px;
  }
  .checkout-payment-method .checkout-country {
    height: 50px;
  }
  .new-checkout-page{
    background-color: #F8FAFE!important;
  }
  .new-checkout-page .addonpage {
    background-color: transparent!important;
  }
  .checkout-page-head h1 {
    font-size: 20px!important;
  }
  .checkout-payment-method {
    padding: 30px 15px;
  }
  .checkout-purchase-summary{
    padding: 25px 15px 0px 15px;
  }

}