.generalsetting-head{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 22px;
}
.generalsetting-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 6px;
}
.setting-arrownwext{
    width: unset !important;
}
.generalsetting-head .title{
    margin-top: -4px !important;
}
.generalsetting-tooltip .generalsetting-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 27px;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
}
.setting-map-cordinate-main-div{
    display: flex;
    margin-bottom: 15px !important;
}
.varifyPopupupdiv{
    max-width: 460px !important; 
    max-height: 240px !important
}
.Social-login-main-div{
    min-height: 80vh 
}
.generalsetting-parent-input-popup{
    display: flex;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #BCC1CE;
    padding: 15px 15px 15px 15px;
    color: #1b2952;
    outline: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    cursor: pointer;
    overflow: hidden; 
}
.generalsetting-popup-data-name{
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.generalsetting-tooltip .generalsetting-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 14%;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.generalsetting-tooltip:hover .generalsetting-tooltipsubheader {
    visibility: visible;
    text-align: left !important;
}
.generalsetting-head-right{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -10px;
}
.generalsetting-head-right .header-updated-at{
   width: unset;
}
.generalSetting-spinner{
    position: relative;
    border-radius: 6px!important;
    overflow: hidden;
    border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
    height: calc(100vh - 171px);

}
.generalsettingbg {
    background-color: #fff;
    border-radius: 6px!important;
    border: 1px solid #E3E6F1!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
    padding-bottom: 40px;
    /* height: 86vh; */
}
.generalsetting-save-btn{
    width: 86px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #3064F9;
    border: none;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    line-height: 40px;
    transition: .5s;
    /* margin-left: 20px; */
    &:hover {
        background-color: var(--custom_color5)!important;
        color: var(--custom_color4)!important;
      }
}
.generalsetting-save-btn:hover{
    transition: .5s;
    background-color: #134bec;
}
.generalsetting-width {
    display: flex;
}
.generalsetting-settings {
    width: 50%;
    min-width: 50%;
    padding-left: 30px;
    padding-right: 15px;
    /* height: 85vh;
    overflow: auto; */
}
.generalsetting-right {
    width: 50%;
    min-width: 50%;
    position: relative;
}
.generalsetting-right .newfeatureicon-div{
    position: absolute;
    left: 0;
    right: 0;
    top: 7px;
    margin: auto;
    width: max-content;
}
.generalsetting-preview-right {
    padding: 26px 43px 0px 30px;
    border-left: none;
    text-align: center;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.general-setting-head {
    padding: 21px 0px 18px 0px;
    border-bottom: 1px solid #E3E6F1;
}
.generalsetting-head-title{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    margin-bottom: 5px;
}
.generalsetting-head-description{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}
.general-setting-body{
    margin-top: 29px;
}
.general-setting-body-label{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin: 0px 0px 10px 0px;
    display: flex;
    align-items: center;
}
.general-setting-body-input{
    border: unset;
    border-left: 1px solid #BCC1CE;
    border-radius: 0px;
    height: 48px;
    width: 100%;
    padding: 0px 80px 0px 15px;
    transition: .5s;
    outline: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #1B2952;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: calc(100% - 80px);
}
.general-setting-body-input:hover, .general-setting-body-input:focus {
    outline: none;
    background-color: #F8FAFE!important;
    border-color: #3064F9!important;
    transition: .5s!important;
}
.general-setting-body-input-app-name{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0px 15px 0px 15px;
    transition: .5s;
    outline: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #1B2952;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.general-setting-body-input-app-name:hover, .general-setting-body-input-app-name:focus {
    outline: none;
    background-color: #F8FAFE!important;
    border-color: #3064F9!important;
    transition: .5s!important;
}
.gsetting-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 2px!important;
    margin-left: 6px!important;
}
.gsetting-tooltip .gsetting-tooltipsubheader {
    visibility: hidden;
    width: 250px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 27px;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
}
.gsetting-tooltip .gsetting-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.gsetting-tooltip:hover .gsetting-tooltipsubheader {
    visibility: visible;
    text-align: left !important;
}
.general-setting-gap1{
    margin-top: 19px;
}
.app-type-general-settings{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0px 15px 0px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1B2952;
}
.app-settingtype-select-div{
    display: flex;
    align-items: center;
}
.app-setting-type-text{
    color: #1B2952;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin: 0px 0px 0px 10px;
}
.app-setting-website-div{
    display: flex;
    position: relative;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    transition: .5s !important;
}
.app-setting-website-div:focus-within{
    border-color: #3064F9 !important;
    transition: .5s !important;
}
.app-setting-website-div:hover{
    border-color: #3064F9 !important;
}
.app-type-change-btn, .app-type-change-btn:hover{
    color: #3064F9;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin: 0px!important;
    cursor: pointer;
}
.general-setting-link{
    width: 79px;
    height: 48px;
    line-height: 46px;
    /* border-top: 1px solid #BCC1CE;
    border-bottom: 1px solid #BCC1CE;
    border-left: 1px solid #BCC1CE; */
    border-right: none;
    /* border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px; */
    padding-left: 12px !important;
}
.general-setting-link span {
    font-family: "Axiforma-Regular2";
    font-size: 13px;
    color: #7782A1;
}
.general-setting-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.general-setting-toggle-label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
}
.general-setting-toggle-right{
    display: flex;
    align-items: center;
}
.general-setting-toggle-right .generalsetting-crown-icon-div{
    margin-right: 20px;
}
.generalsetting-delete-btn {
    width: 118px;
    height: 40px;
    border-radius: 3px;
    color: #BCC1CE;
    background-color: #F8FAFE;
    border: none;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    line-height: 40px;
    transition: .5s;
    /* margin-left: 20px; */
    border: 1px solid #C8CEDB;
}
.general-setting-move-btn{
    width: 108px !important;
}
.generalsetting-delete-btn svg{
    margin-right: 6px;
}
.generalsetting-delete-btn:hover{
    box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
    transition: .5s;
}
.generalstngs1 .modal-body{
    padding: 23px 0px 64px 0px;
}
.generalstngs1 h4 {
    color: #1B2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    line-height: 25px;
}
.generalstngs1 h6 {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    line-height: 18px;
    margin-bottom: 30px;
}
.generalstngs1-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.generalstngs1-ok {
    background-color: #3064f9;
    border: 1px solid #3064f9;
    border-radius: 4px;
    width: 140px;
    height: 44px;
    line-height: 42px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
}
.generalstngs1-ok p {
    color: #fff;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0px;
}

.premiun-setting-div{
    display: flex;
    align-items: flex-start;
}
.generalsetting-crown-icon-div{
    position: relative;
    display: flex;
    margin-left: 10px;
    align-items: center;
    top: 1px;
}
.generalsetting-crown-icon-div:hover .general-setting-premium-tooltip{
visibility: visible;
}
.general-setting-premium-tooltip{
    margin-bottom: 0px;
    visibility: hidden;
    position: absolute;
    width: 103px;
    height: 25px;
    z-index: 9;
    font-family: "Axiforma-Regular";
    font-size: 10px;
    line-height: 13.44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    opacity: 0.8 !important;
    color: #ffff;
    border-radius: 3px;
    top: 26px;
    left: -40px;
    transition: 0.3s;
}

.general-setting-premium-tooltip::after{
    content: "";
    position: absolute;
    top: 10px;
    right: 52px;
    margin-top: -19px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}


.generalstngs2 h4 {
    color: #1B2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    line-height: 25px;
}
.generalstngs2 h6 {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    line-height: 18px;
    margin-bottom: 30px;
}


.generalstngs2-ok p {
    color: #fff;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0px;
}




.generalstngs2{
    max-width: 720px;
    max-height: 600px;
}
.generalstngs2 .modal-body{
    padding: 23px 0px 64px 0px;
}
.move-modal-confirm-head{
    color: #1B2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
    line-height: 25px;
}
.move-modal-confirm-para {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    line-height: 18px;
    margin-bottom: 30px;
}
.generalstngs2-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.generalstngs2-ok {
    background-color: #3064f9;
    border: 1px solid #3064f9;
    border-radius: 4px;
    width: 140px;
    height: 44px;
    line-height: 44px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.generalstngs2-ok .btn-text {
    color: #fff;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0px;
}
.generalstngs2-cancel{
    border: 1px solid #C8CEDB;
    border-radius: 4px;
    width: 140px;
    height: 44px;
    line-height: 44px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
}
.generalstngs2-cancel p {
    color: #7782A1;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0px;
}
.setting-tech-change-head{
    color: #1B2952;
    font-size: 16px;
    font-family: "Axiforma-Bold";
    margin-top: 8px;
}
.seting-tech-change-para{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
}
.move-app-popup h6{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 10px;
    margin-top: 20px;
}
.move-app-view{
    display: flex;
    align-items: center;
}
.move-app-view img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}
.move-app-view .select-tech-change-head{
    color: #1B2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 5px 0px 0px 0px;
}
.setting-popup-app-count{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 3px 0px 0px 0px;
}
.g2reviewiframe iframe{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0px;
}








.general-setting-radio-button {
    cursor: pointer;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E3E6F1;
    border-radius: 4px;
    padding: 15px 20px 15px 15px;
    transition: .5s;
    position: relative;
    margin-bottom: 8px;
}
.general-setting-radio-button:hover{
    background-color: #F8FAFE;
    transition: .5s;
}
.general-setting-radio-button input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.general-setting-radio-button input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
}
.general-setting-radio-button__input {
    opacity: 0;
    position: absolute;
}
.general-setting-radio-button__control {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 0px;
    vertical-align: middle;
    background-color: inherit;
    color: #3064F9;
    border: 1px solid #BCC1CE;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.general-setting-radio-button__input:checked+.general-setting-radio-button__control:after {
    content: "";
    display: block;
    /* position: absolute; */
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
}
.general-setting-radio-button__input:checked+.general-setting-radio-button__control {
    border-color: #3064F9;
    background-color: #3064F9;
}
.general-setting-url .d-flex{
    position: relative;
}
.app-setting-varify-btn{
    color: #FF4848;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    position: absolute;
    right: 15px;
    top: 18px;
    cursor: pointer;
}
.app-setting-varify-tick{
    position: absolute;
    right: 15px;
    top: 13px;
}
/* .general-setting-url .d-flex svg{
    position: absolute;
    right: 20px;
    top: 17px;
} */
.move-app-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E3E6F1;
    padding: 15px 20px;
}
.move-app-cancel {
    border: 1px solid #C8CEDB;
    border-radius: 4px;
    width: 100px;
    height: 44px;
    line-height: 44px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
}
.move-app-cancel p {
    color: #7782A1;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0px;
}
.move-app-ok {
    background-color: #3064f9;
    border: 1px solid #3064f9;
    border-radius: 4px;
    width: 100px;
    height: 44px;
    line-height: 44px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
    margin-left: 10px;
}
.move-app-ok p {
    color: #fff;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0px;
}
.move-app-popup .modal-body{
    padding: 0px;
}
.move-app-body{
    padding: 20px 20px 0px 20px;
}
.move-app-list{
    height: 374px;
    overflow: auto;
}
.move-app-list::-webkit-scrollbar {
    width: 4px !important;
}
.select-move-type{
    margin-top: 30px;
    margin-bottom: 20px;
}
.move-active-app{
    background-color: #F8FAFE;
}
.change-active-app{
    background-color: #F8FAFE;
}
.select-move-type .select-tech-change-head{
    color: #7782A1;
}
.change-active-app .select-tech-change-head{
    color: #1B2952!important;
}
/* .general-setting-url input{
    padding-right: 90px;
} */
.general-mobile-btns {
    display: none;
}
.generalsetting-head-right-mob{
    display: none;
}
.move-back-arrow{
    display: none;
}
.generalsettingbg .app-setting-phonecode{
    background-position: 98%!important;
    background-repeat: no-repeat;
    background-size: auto;
    padding-left: 15px;
  
}
.app-setting-phonecode{
    background: url(../images/deals/downArrow.png) 82%/10% no-repeat;
    appearance: none;
}
.app-setting-country-flag{
    margin-right: 8px;
}
.app-setting-flag-div{
    font-size: 13px;
    font-family: "Axiforma-Regular";
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1B2952;
}
.general-setting-gap1 .select-input{
    margin-bottom: 20px !important;
}
.generalsettingbg .app-setting-phonecode .profile-selectflagtop input , .generalsettingbg .app-setting-phonecode .profile-selectflagtop input:hover , .generalsettingbg .app-setting-phonecode .profile-selectflagtop input:focus{
    background-color: #fff!important;
}
.domain-vrfy{
    margin-left: 0;
}
.generalstngs1-ok-grey{
    color: #bcc1ce;
    background-color: transparent;
    border: 1px solid #bcc1ce;
    border-radius: 4px;
    width: 140px;
    height: 44px;
    line-height: 42px;
    transition: 0.5s;
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
}

.generalstngs1-ok-grey p{
    color: #bcc1ce;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
}
.generalstngs1-ok-grey:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(188 193 206 / 39%);
}
.map-coordinate-popup .modal-header {
    padding: 20px 20px 0px;
}
.map-coordinate-popup .modal-footer{
    border-top: 1px solid #E3E6F1 !important;
    padding: 15px 20px;
}
.map-coordinate-popup .modal-body{
    padding: 0 20px 20px;
}
.map-coordinate-popup-footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px !important;
}
.map-coordinate-popup .modal-header h2{
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
}
.map-popup-head-div{
    display: flex;
    align-items: center;

}
.web-popup-search-div{
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #BCC1CE;
    height: 50px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.web-popup-search-div input:focus{
   border: none;
}
.web-popup-search-div input{
    border: none;
    height: 100%;
    font-size: 13px;
    color: #1B2952;
    font-family: 'Axiforma-Regular';
    outline: none;
   padding-right: 60px;
 }
 .web-popup-search-div input::placeholder{
    color: #BCC1CE;
 }

.web-popup-search-div .cleartextweb{
    margin-bottom: 0px;
    font-size: 14px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    position: absolute;
    right: 35px;
    cursor: pointer;
}
.map-donetextmob{
    display: none;
}
.map-popup-head-div svg{
    display: none;
}
.mob-map-done-div{
    display: none

}

.pac-container{
    z-index: 1100 !important;
        font-family: 'Axiforma-Regular';
        font-size: 14px;
}

.map-cancel-btn{
    border: 1px solid #BCC1CE;
    background: transparent;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    transition: .5s;
}
.map-cancel-btn:hover{
    color: #484e5e;
    border: 1px solid #7782a1 !important;
    transition: .5s;
    box-shadow: 0 2px 4px rgb(188 193 206 / 69%);
}
.map-done-btn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    border: unset;
    color: #fff;
    font-size: 13px;
    margin: 0 !important;
    transition: .5s;
    background-color: #3064f9;
    font-family: "Axiforma-Regular";
}
.map-done-btn:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(112 155 250 / 69%);
    background-color: #134bec; 
}
.popupmap-div{
    height: 352px;
    min-height: 352px;
}
.general-setting-footer{
    position: fixed;
    bottom: 0px;
}
.map-current-location{
    position: absolute;
    bottom: 112px;
    right: 10px;
    z-index: 1;
    width: 40px;
    height: 40px;
    border: unset;
    border-radius: 2px;
    background-color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map-coordinate-popup .modal-title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}
.map-coordinate-popup .modal-title img{
    position: absolute;
    top: -7px;
    right: -5px;
    cursor: pointer;
}
.map-done-btn-disabled {
    background-color: #bcc1ce !important;
    border: 1px solid #bcc1ce !important;
    cursor: not-allowed !important;
}
.map-done-btn-disabled:hover {
    box-shadow: none !important;
    background-color: #bcc1ce !important;
    border: 1px solid #bcc1ce !important;
}
.generalsetting-crown-icon-div a{
    display: flex;
}

.general-setting-toggle .notification-access-slider:before {
    bottom: 3px;
}
.general-setting-toggle .notification-access-slider {
    background-color: #E3E6F1
}
.icon-text-btndiv{
    display: flex;
        align-items: center;
}
.generalsetting-preview-product-frame{
    width: 364px;
    border: 4px solid #E3E6F1;
    box-shadow: 0px 6px 16px 0px #0000000F;
    height: 778px;
    border-radius: 30px;
    overflow: hidden;
}
.generalsetting-preview-product-frame .img-fluid{
    height: 100%;
}
.generalsetting-preview-right::-webkit-scrollbar{
    width: 0px !important;
}
.general-setting-preview-body{
    height: calc(100% - 111px);
    padding: 15px 15px 0px;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}
.general-setting-preview-body::-webkit-scrollbar{
    width: 0px !important;
}
.setting-page-with-header{
   position: relative;
    height: 100%;
}
.general-setting-preview-tabs {
    position: relative;
    border-radius: 10px; /* Ensures rounded corners */
    padding: 11px 11px 12px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    margin-bottom: 7px;
    background-color: #FFFFFF;
    border: 2px solid #EBEBEB; /* Default grey border */
    transition: .5s;
}
.general-setting-preview-tab-hidden{
    height: 0px;
    transition: .5s;
    overflow: hidden;
    padding: 0px 11px;
    border: none;
    margin: 0px;
}
.general-setting-preview-tab-hidden .general-setting-preview-tabs-left{
    opacity: 0;
    transition: .2s;
}
/* Glow Effect with Smooth Animation */
.general-setting-preview-tabs.glow-effect {
    animation: glowBorder 2s ease-in-out 4;

}
.general-setting-preview-tabs.glow-effect-infinite {
    animation: glowBorder 2s ease-in-out infinite;
}
/* Keyframes for Smooth Border Transition */
@keyframes glowBorder {
    0%, 100% {
        border: 2px solid #EBEBEB; /* Default grey */
        box-shadow: 0px 0px 0px 0px rgba(76, 126, 252, 0);
    }
    50% {
        border: 2px solid transparent;
        background: linear-gradient(white, white) padding-box,
                    linear-gradient(90deg, #6C66FC 0%, #4A7EFC 100%) border-box;
        box-shadow: 0px 0px 10px 2px rgba(76, 125, 252, 0.4);
    }
}


.setting-preview-version-text{
    font-family: "Axiforma-Regular";
font-size: 9px;
line-height: 12.1px;
text-align: center;
color: #DEDEDE;
margin-bottom: 9px;
}
.setting-preview-toggle-div{
    position: relative;
    width: 24px;
    height: 12px;
    background-color: #cfcfcf;
    border-radius: 50px;
}
.setting-rounded-toggle{
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 100%;
    top: 1px;
    left: 1px;
}
.general-setting-tagname{
    font-family: "Axiforma-Regular";
font-size: 13px;
line-height: 17.47px;
color: #1A1A1A;
margin-bottom: 0px;
}
.general-setting-preview-tabs-left{
    display: flex;
        align-items: center;
        gap: 9px;
        width: 95%;
        overflow: hidden;
        position: relative;
}
.general-setting-preview-tabs-left .icon-img{
    width: 19px !important;
}
.general-setting-site-div{
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
}
.general-setting-spantag{
    font-family: "Axiforma-regular";
    font-size: 10px;
    color: #b0b0b0;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;

}
.language-select-body{
    height: calc(100% - 58px);
    background-color: #F9F9F9;
}
.location-map-head-text{
    font-family: 'Axiforma-Bold';
    font-size: 23px;
    color: #1A1A1A;
    text-align: left;
    padding: 27px 20px;
    margin-bottom: 0px;
}

.setting-app-name-disatance-div{
    display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        
}
.settingapp-icon-with-name{
    display: flex;
    align-items: center;
    gap: 13px;
    width: 88%;
    overflow: hidden;
}
.setting-appicon-div{
    min-width: 62px;
    height: 62px;
    background-color: #EBEBEB;
    border-radius: 14px;
}
.seting-appsname{
    font-family: 'Axiforma-Bold';
    font-size: 13px !important;
    line-height: 18.27px !important;
    color: #1F1F1F !important;
    text-align: left !important;
    margin-bottom: 4px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 94%;
}
.setting-appname-url{
    font-size: 11px;
    line-height: 14.78px;
    color: #6F6F6F;
    font-family: 'Axiforma-Regular';
    margin-bottom: 2px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 94%;

}
.setting-toggle-on{
    background-color: #1A1A1A;
}
.setting-toggle-on .setting-rounded-toggle{
    right: 1px;
    left: unset;
}
.app-distance{
    font-family: 'Axiforma-Regular';
font-size: 9px;
line-height: 12.1px;
margin-bottom: 0px;
color: #B0B0B0;
}
.setting-apptype-div{
    width: 77%;
}


.generalsetting-preview-product-frame .carousel .slider-wrapper {
    width: 100%;
    height: 100%;
  }
  .generalsetting-preview-product-frame .carousel .control-dots {
    display: none;
  }
  .generalsetting-preview-product-frame .carousel .carousel-status {
    display: none;
  }
  .generalsetting-preview-product-frame .slider:before {
    display: none;
  }
  .generalsetting-preview-product-frame .carousel .slide {
    text-align: left;
    cursor: default !important;
  }
  .generalsetting-preview-product-frame .slider {
    background-color: transparent;
    background-image: none;
  }
  .generalsetting-preview-product-frame .carousel .thumbs-wrapper {
    display: none;
  }
  .generalsetting-preview-product-frame .carousel.carousel-slider {
    overflow: unset;
  }
  .generalsetting-preview-product-frame .carousel .control-prev.control-arrow {
    left: 101px;
  }
  .generalsetting-preview-product-frame .carousel.carousel-slider .control-arrow {
    top: -102px !important;
    width: 32px;
    height: 32px;
    font-size: 16px !important;
    border-radius: 50%;
  }
  .generalsetting-preview-product-frame .carousel .control-next.control-arrow {
    right: 101px;
  }
  .generalsetting-preview-product-frame .carousel .control-prev.control-arrow:before,
  .generalsetting-preview-product-frame .carousel .control-next.control-arrow:before {
    display: none;
  }
  .generalsetting-preview-product-frame .carousel.carousel-slider .control-arrow:hover {
    background: unset;
  }
  /* .generalsetting-preview-product-frame .carousel .slide img {
    width: fit-content;
    width: 16px;
      object-fit: contain;
  }
  .generalsetting-preview-product-frame .carousel img {
    width: fit-content;
  } */
.setting-preview-arrow-right{
    width: 32px;
    height: 32px;
    box-shadow: 0px 3px 8px 0px #00000024;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.setting-preview-frame-div{
    display: flex;
        align-items: center;
        gap: 40px;
}
.carousel-last-item{
    opacity: 0.3;
}
.setting-header-div{
    display: flex;
    align-items: center;
   height: 52px;
   gap: 13px;
   border-bottom: 1px solid #DEDEDE;
   padding: 0px 19px;
}
.back-arrow-width{
    width: 17px !important;
    height: 16px;
}
.setting-head-div-text{
    font-family: "Axiforma-Bold";
    font-size: 15px;
    line-height: 100%;
    color: #1A1A1A;
    margin-bottom: 0px;
}
.setting-page-preview-foolter{
    display: flex;
    align-items: center;
    height: 58px;
    border-top: 1px solid #DEDEDE;
    padding: 0px 3px;

}
.setting-page-footer-item-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}
.setting-page-footer-item-div img{
    width: unset !important;
    height: 19px;
}
.setting-page-item-text{
    font-family: 'Axiforma-Regular';
    font-size: 10px;
    line-height: 13.44px;
    letter-spacing: 0%;
    text-align: center;
    color: #1A1A1A;
    margin-bottom: 0px;
    margin-top: 0px;
}
.exit-bar-background{
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.update-poup-background{
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.update-poup-frame{
background-color: #F2F2F2;
border-radius: 12px;
margin: 0px 40px;
}
.update-poup-frame-top{
    border-bottom: 1px solid #D2D2D2;
    padding: 20px 30px
}

.update-poup-header{
font-family: 'Axiforma-Bold';
font-size: 14px;
line-height: 100%;
text-align: center;
color: #000000;
text-align: center ;
}
.update-poup-text{
    font-family: "Axiforma-Regular";
    font-size: 11px;
    line-height: 100%;
    text-align: center;  
    color: #000000;
    text-align: center ;
    margin-bottom: 0px;
}
.update-poup-update{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: #197AE4;
    text-align: center ;
    margin: 15px 0px;
    
}
.exit-bar-frame{
    height: 152px;
    position: absolute;
   bottom: -150px;
    background-color: #FFFFFF;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 30px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.5s;
}
.exit-bar-frame-appear{
    bottom: 0px;
}
.exit-bar-cross{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 22px !important;
}
.exitbar-header{
    text-align: left !important;
    font-family: "Axiforma-Bold";
    font-size: 19px !important;
    line-height: 26.7px !important;
    color: #1A1A1A !important;
    margin-bottom: 5px !important;
}
.exitbar-text{
    text-align: left!important;
    font-family: 'Axiforma-Regular';
    font-size: 11px;
    line-height: 14.78px;
    color: #6F6F6F;
}
.exit-bar-buttons{
    display: flex;
    gap: 10px;
}
.exit-barcancel-btn{
    border: 1px solid #CFCFCF;
    font-family: 'Axiforma-Regular';
    font-size: 15px;
    line-height: 20.16px;
    color: #B0B0B0;
    background-color: #ffffff;
    flex: 1;
    border-radius: 4px;
    height: 42px;
}
.exit-bar-okay-btn{
    border: unset;
    font-family: 'Axiforma-Regular';
    font-size: 15px;
    line-height: 20.16px;
    color: #FFFFFF;
    background-color: #1A1A1A;
    flex: 1;
    border-radius: 4px;
    height: 42px;
}




/* new-preview */
/* .technology-head{
    display: flex;
    justify-content: space-between;
    padding: 17px 18px;
}
.technology-head-left{
    display: flex;
    align-items: center;
    gap: 12px;
}
.header-text{
    font-family: 'Axiforma-Bold';
    font-size: 18px;
    line-height: 25.29px;
    color: #BCC1CE;
    margin-bottom: 0px;
}
.apptype-preview-body{
    padding: 0px 18px;
}
.apptype-preview{
    background: linear-gradient(148.58deg, #EBF8FF 6.68%, #E9EAFF 50.92%, #F6EEFF 91.92%);
    height: 253px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.preview-app-devider{
    width: 70%;
    height: 8px;
    background-color: #EDEFF6;
    border-radius: 10px;
    margin-top: 20px;
}
.apptype-preview-items-div{
    padding: 24px 0px 0px;
    display: flex;
    gap: 12px;
    overflow: hidden;
}

.app-type-preview-item{
    width: 116px;
    height: 116px;
    min-width: 116px;
    background-color: #EAF0FF;
    border-radius: 10px;
}
.preview-app-list-load{
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 24px 0px 0px;
}
.preview-app-half-devider{
    width: 70%;
    height: 8px;
    background-color: #EDEFF6;
    border-radius: 10px;
}
.preview-app-full-devider{
    width: 100%;
    height: 8px;
    background-color: #EDEFF6;
    border-radius: 10px;
    
}
.app-type-bottombar{
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0px;
    gap: 30px;
}
.app-type-bottombar-pngicon{
    width: 20px;
}
.webview-preview{
    height: 356px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAEFFF;
}
.webview-app-list-load{
    display: flex;
        flex-direction: column;
        grid-gap: 18px;
        gap: 18px;
        padding: 24px 18px 0px;
        align-items: center;
}
.webview-btn-div{
    display: flex;
    justify-content: center;
    margin-top: 24px;
}
.webview-btn{
    border: 2px solid #EAECF2;
    height: 50px;
    width: 224px;
    height: 50;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.preview-button-text{
    width: 84px;
    border-radius: 10px;
    background-color: #EDEFF6;
    height: 8px;
}
.webview-preview-items-div{
    display: flex;
    gap: 12px;
    padding: 24px 18px 0px;
}
.webview-type-preview-item{
    flex: 1;
    background-color: #EEF2FF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 107px;
}
.setting-wordpress-profile{
display: flex;
gap: 10px;
align-items: center;
}
.setting-wordpress-profile-div{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #EDEFF6;

}
.wordpress-apptype-preview{
    background-color: #E8F9FF;
    height: 276px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 18px 0px;
}
.setting-wordpress-profile-name{
    width: 70px;
height: 8px;
border-radius: 10px;
background-color: #EDEFF6;
}
.preview-wordpress-app-like-chat-div{
    display: flex;
    margin-bottom: 21px;
}
.preview-wordpress-like-div{
    display: flex;
    gap: 10px;
    flex: 1;
    align-items: center;
}
.preview-like-count{
    width: 56px;
    height: 8px;
    border-radius: 10px;
    background-color: #EDEFF6;
}
.wordpress-app-type-preview-item{
    width: 116px;
    height: 116px;
    min-width: 116px;
    background-color: #E8F9FF;
    border-radius: 10px;
}
.wordpress-preview-div-top{
    display: flex;
    gap: 18px;
    flex-direction: column;
}
.woo-preview-two-text{
    display: flex;
    gap: 15px;
}
.woo-preview-text{
    width: 63px;
    height: 8px;
    border-radius: 10px;
    background-color: #EDEFF6;
}
.wooPreview-preview-div-top{
    border: 1px solid #BCEDC4;
    background-color: #FAFFFB;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.woo-preview-app-half-devider{
    width: 70%;
    height: 8px;
    background-color: #E8F9EB;
    border-radius: 10px;
}
.woo-preview-app-full-devider{
    width: 100%;
    height: 8px;
    background-color: #E8F9EB;
    border-radius: 10px;
}
.woo-apptype-preview{
    background-color: #F9F3FF;
    height: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
}
.woo-apptype-preview-top{
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 32px 22px;

}
.woo-ratting-div{
    display: flex;
    justify-content: space-between;
    margin: 20px 0px ;
}
.woo-ratting-div-right-icon{
    margin-right: 20px;
}
.woo-preview-body{
    padding: 22px 18px 14px;
} */















  @media (max-width: 1200px) {
    .general-setting-toggle .notification-access-slider{
        background-position: bottom 4px left 22px;
    }
    .generalsetting-head .app-dashboard-head{
     margin: 0px !important;
    }
    .generalSetting-spinner{
        margin: 0px 15px;
        height: calc(100vh - 140px);
    }
    .map-coordinate-popup .modal-title img{
        display: none;
    }
    .pac-container{
        width: 100vw !important;
        margin-left: -41px;
        font-family: 'Axiforma-Regular';
        font-size: 13px;
    }
    .popupmap-div{
        height: 100%;
        border-radius: 0px;
    }
    .web-popup-search-div {
        border: none;
        margin-bottom: 0px;
    }
    .mob-map-done-div hr{
        border: 1px solid #E3E6F1;
        margin: 0px 15px 0px 13px;
        height: 30px;
    }
    .map-popup-head-div {
        padding: 0px 20px;
        height: 60px;
    }
    .map-coordinate-popup .modal-body {
        padding: 0px;
    }
    .map-coordinate-popup .modal-header {
        padding: 0px;
    }
    .map-popup-head-div svg{
        display: block;
        min-width: 20.759px;
    }
    .map-coordinate-popup .modal-header h2 {
        display: none;
       
    }
    .web-popup-search-div input {
          /* padding-right: 10px; */
          height: 60px;
          padding-left: 15px !important;
          
    }
    .map-coordinate-popup .modal-footer{
        display: none;
    }
    .web-popup-search-div .cleartextweb{
        font-size: 13px;
        right: 70px;
    }
    .mob-map-done-div{
        display: flex;
        align-items: center;
    }
    .map-donetextmob-disable{
        display: block;
        margin-bottom: 0px;
        font-size: 14px;
        color: #BCC1CE;
        font-family: 'Axiforma-Regular';
        margin-right: 0px;
    }
    
    .map-donetextmob{
        display: block;
        margin-bottom: 0px;
        font-size: 14px;
      
        color: #3064f9;
        font-family: 'Axiforma-Regular';
        margin-right: 0px;
    }
    .generalstngs1-footer{
        flex-direction: column-reverse;
    }

    .generalstngs1-ok-grey{
        margin-right: 0px;
        margin-top: 20px;
        width: 80%;
        border: unset;
    }
    .generalstngs2 .modal-body{
        padding: 23px 0px 64px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .domain-vrfy{
        width: 280px;
        margin-left: 0;
    }
    .generalstngs1 .modal-body{
        display: flex;
        align-items: center;
    }
    .generalstngs1-ok {
        width: 280px;
    }
    .generalsetting-right{
        display: none;
    }
    .generalsettingbg{
        margin: 0px 15px;
    }
    .generalsetting-settings {
        width: 100%;
        min-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .general-setting-body {
        padding: 0px 20px;
    }
    .general-setting-head {
        padding: 20px;
    }
    .general-setting-toggle-label{
        font-size: 12px;
    }
    .generalsetting-head-right{
        display: none;
    }
    .generalsetting-head{
        margin: 12px 15px 13px 15px;
        align-items: center;
    }
    .generalsetting-head .title {
        margin-top: 0px !important;
    }
    .general-mobile-btns {
        display: block;
        margin: 0px;
        padding: 0px 15px;
        border: none;
        width: 100%;
        background: transparent;
    }
    .general-mobile-btns-blue {
        border: 1px solid #3064F9;
        height: 50px;
        background-color: #3064F9;
        border-radius: 3px;
        color: #fff;
        width: 100%;
        transition: .5s;
        position: relative;
        margin-top: 20px;
        text-align: center;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Axiforma-Regular';
        &:hover {
            background-color: var(--custom_color5)!important;
            color: var(--custom_color4)!important;
            border: 1px solid var(--custom_color6)!important;
          }
    }
    .general-mobile-btns h5 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        text-align: center;
        margin-top: 17px;
        width: 100%;
    }
    .generalsetting-tooltip .generalsetting-tooltipsubheader {
        width: 202px;
        top: 34px;
        left: -20px;
    }
    .generalsetting-tooltip .generalsetting-tooltipsubheader::after {
        top: -8px;
        right: 168px;
        transform: rotate(90deg);
    }
    .generalsetting-head-right-mob{
        display: flex;
    }
    .generalsetting-head-right-mob div{
        width: 40px;
        height: 40px;
        margin-left: 20px;
        border-radius: 3px;
        border: 1px solid #C8CEDB;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gsetting-tooltip .gsetting-tooltipsubheader {
        width: 200px;
        top: 24px;
        left: -15px;
    }
    .gsetting-tooltip .gsetting-tooltipsubheader::after {
        top: 10px;
        right: 173px;
        margin-top: -19px;
        transform: rotate(90deg);
    }
    .move-app-popup .move-app-footer .move-app-cancel{
        display: none;
    }
    .move-app-popup .move-app-footer .move-app-ok {
        width: 100%;
        margin-left: 0px;
    }
    .moveapp-header{
        height: 60px;
        background-color: #fff;
        box-shadow: 0px 0px 6px rgb(188 193 206 / 36%)!important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 1;
    }
    .move-app-body {
        padding: 0px;
    }
    .setting-tech-change-head{
        padding: 20px 20px 0px 20px;
    }
    .seting-tech-change-para{
        padding: 0px 20px;
    }
    .move-app-popup .modal-content{
        background-color: #F8FAFE;
    }
    .general-setting-radio-button{
        background-color: #fff;
    }
    .move-app-list {
        height: unset;
        padding: 0px 20px;
        overflow: hidden;
    }
    .select-move-type {
        height: unset;
        padding: 0px 20px;
        overflow: hidden;     
    }
    .move-app-popup .modal-body {
        overflow: auto;
        padding-top: 60px;
    }
    .move-app-footer{
        border: none!important;
    }
    .moveapp-header .crossmodal{
        display: none;
    }
    .general-setting-radio-button:hover {
        background-color: #fff;
        transition: .5s;
    }
    .move-back-arrow{
        display: flex;
    }
    .select-move-type .general-setting-radio-button , .move-app-list .general-setting-radio-button{
        margin-bottom: 10px;
    }
    .select-move-type .general-setting-radio-button:last-child{
        margin-bottom: 0px;
    }
    .select-move-type {
        margin-top: 30px;
        margin-bottom: 5px;
    }
    .generalsettingbg {
        padding-bottom: 40px;
    }



    .generalstngs2-footer {
       flex-direction: column-reverse;
    }
    .generalstngs2-ok {
        margin-left: 0px;
        max-width: 280px;
        width: 100%;
        margin-bottom: 20px;
        height: 50px;
        font-size: 16px;
    }
    .generalstngs2-ok .btn-text {
    
        font-size: 16px;
    }
    .generalstngs2-cancel{
        margin-right: 0px;
        max-width: 280px;
        width: 100%;
        height: 50px;
       
    }
    .generalstngs2-cancel p {
        font-size: 16px;
    }
    .move-app-ok {
        height: 50px;
    }
    .move-app-ok p {
        font-size: 16px;
    }
  }