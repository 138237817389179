@font-face {
    font-family: "Axiforma-Regular";
    src: url(../fonts/Axiforma-Regular.woff);
}

@font-face {
    font-family: "Axiforma-Regular2";
    src: url(../fonts/Axiforma-Regular2.woff2);
}

@font-face {
    font-family: "Axiforma-Bold";
    src: url(../fonts/Axiforma-Bold.ttf);
}

.user-dashboard-page {
    background-image: url(../images/dashboard/app-icon-background.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    min-height: 100vh;
    background-size: contain;
    background-attachment: fixed;
}

.user-dashboard-page h1 {
    color: #1B2952;
    font-size: 36px;
    font-family: "Axiforma-Bold";
    padding: 130px 0px 60px 0px;
    line-height: 51px;
    text-align: center;
    min-height: 241px;
    animation: thefadeIn 3s;
}
.user-dashboard-page h1 span{
    animation: thefadeIn 3s;
}
.three-products {
    width: 1080px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin: auto;
}

.each-single-product {
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    padding: 25px 50px 39px 50px;
    text-align: center;
    width: 340px;
    position: relative;
    transition: .5s;
    height: 400px;
}

.each-single-product img {
    height: 150px;
    width: 150px;
}

.each-single-product h2 {
    color: #0F193B;
    font-size: 20px;
    font-family: "Axiforma-Bold";
    padding: 22px 0px 15px 0px;
    animation: thefadeIn 3s;
    margin-bottom: 0px;
}

.each-single-product h4 {
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    line-height: 24px;
    animation: thefadeIn 3s;
}

.each-single-product h6 {
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 55px 0px 0px 0px;
    animation: useranimation 2s ease-out;
}

.each-single-product h6 span{
    animation: useranimation 2s ease-out;
}

@keyframes thefadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes useranimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.each-single-product a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.each-single-product:hover {
    border: 1px solid #C7D5FF;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    transition: .5s;
}

.g2submitreview-maindiv {
    position: relative;
    right: 0px;
    /* z-index: 99999; */
}

.g2submitreview {
    display: flex;
    position: fixed;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.10);
    background-color: white;
    padding: 12px 16px;
    width: 172px;
    border-radius: 50px 0px 0px 50px;
    margin-bottom: 20px;
    right: 0px;
    align-items: center;
    transition: 0.5s;
}

.g2submitnotextract {
    right: -108px;
    /* right: 0; */

}
.g2submitnotextract-head {
    top: 50%;
}
.single-emoji-g2{
    right: -108px;
}

.g2submitnotextract:hover {
 right: 0;
}

.g2submitreview svg {
    margin-right: 16px;
    cursor: pointer;
}

.g2submitreview svg:last-child {
    margin-right: 16px;
}

/* .g2submitreview-svgdiv{
    position: fixed;
    right: 0px;
    z-index: 99999;
} */
.g2submitreviewsubmit {
    display: flex;
    position: fixed;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.10);
    background-color: white;
    padding: 12px 16px;
    width: 172px;
    border-radius: 50px 0px 0px 50px;
    /* margin-bottom: 20px; */
    right: 0px;
    align-items: center;
    border: 1px solid #C7D5FF;
    color: #3064F9;
    font-family: 'Axiforma-Bold';
    height: 60px;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.5s;
    top: 50%;
}

/* Green Active */
.activeGreenG2icon .greensvgback{
    fill: #52cb86;
    stroke: none;
}
.activeGreenG2icon .greensvgeye{
fill: white;

}

.activeGreenG2icon .greensvgsmile {
    fill: none;
    stroke: white;
}
/* Green Active */

/* Red Active */

.activeRedG2icon .redsvgback {
    fill: #ff4848;
    stroke: none;
}
.activeRedG2icon .redsvgeye {
    fill: white;
}
.activeRedG2icon .redsvgsmile {
    fill: none;
    stroke: white;
}


/* Red Active */

/* Yellow Active */
.activeYellowG2icon .yellowsvgback {
    fill: #ffba19;
    stroke: none;
}
.activeYellowG2icon .yellowsvgeye {
    fill: white;
}
.activeYellowG2icon .yellowsvgsmile {
    fill: none;
    stroke: white;
}

/* Yellow Active */



.greensvgback {
    fill: none;
    stroke: #52cb86;
}
.greensvgeye {
    fill: #52cb86;
}
.greensvgsmile {
    fill: none;
    stroke: #52cb86;
}
.g2greensvg:hover .greensvgback {
    fill: #52cb86;
    stroke: none;
}
.g2greensvg:hover .greensvgeye {
    fill: white;
}
.g2greensvg:hover .greensvgsmile {
    fill: none;
    stroke: white;
}



.yellowsvgback {
    fill: none;
    stroke: #ffba19;
}
.yellowsvgeye {
    fill: #ffba19;
}
.yellowsvgsmile {
    fill: none;
    stroke: #ffba19;
}
.g2yellowsvg:hover .yellowsvgback {
    fill: #ffba19;
    stroke: none;
}
.g2yellowsvg:hover .yellowsvgeye {
    fill: white;
}
.g2yellowsvg:hover .yellowsvgsmile {
    fill: none;
    stroke: white;
}




.redsvgback {
    fill: none;
    stroke: #ff4848;
}
.redsvgeye {
    fill: #ff4848;
}
.redsvgsmile {
    fill: none;
    stroke: #ff4848;
}
.g2redsvg:hover .redsvgback {
    fill: #ff4848;
    stroke: none;
}
.g2redsvg:hover .redsvgeye {
    fill: white;
}
.g2redsvg:hover .redsvgsmile {
    fill: none;
    stroke: white;
}


.mobileg2appearscreen{
    display: none;
}

.mainpopupgtwo .modal-header{
    padding: 0px !important;

}
.mainpopupgtwo .crossmodal {

    right: -23px !important;
    top: -10px !important;
 
}
.mainpopupgtwo .modal-body {

    padding: 20px !important;
    
}
/* .mainpopupgtwo .modal-content {
 height: 600px !important;
    
} */
.g2reviewiframe{
    height: 600px;
}










@media (min-width: 1201px) and (max-width: 1400px) {

.each-single-product {
    padding: 30px 40px 39px 40px;
    height: 380px;
}
.each-single-product h2 {
    padding: 20px 0px 18px 0px;
}
.each-single-product h6 {
    margin: 30px 0px 0px 0px;
}

}











@media (max-width: 1200px) {
     .mainpopupgtwo .modal-header{
        padding: 20px !important;   
    }
    .mainpopupgtwo .crossmodal {

        right: 15px !important;
    top: 3px !important;
     
    }
    .mainpopupgtwo .modal-content {

        height: 100% !important;
     
    }
    .g2reviewiframe{
        height: 100%;
        width: 100%;
    }

    .mobileg2appearscreen{
        margin-bottom: 20px;
        padding: 9px;
        border-radius: 50px 0px 0px 50px;
        position: fixed;
    bottom: 15%;
    width: 60px;
    height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.10);
        background-color: white;
    }
    .mobileg2appearscreen svg{
    cursor: pointer;
    }
    .g2submitreview-maindiv {
        position: unset;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .g2submitreviewsubmit {
        width: 160px;
        position: fixed;
        bottom: 15%;
        top: unset;
        /* border-radius: 50px; */
        height: 60px;
        border-right: unset;
    }

    /* .g2submitreview {
        width: 172px;
        position: unset;
        border-radius: 50px;
        height: 60px;
        padding: 6px 16px;
    } */

    .user-dashboard-page h1 {
        font-size: 26px;
        padding: 97px 0px 25px 0px;
    line-height: 26px;
    min-height: 77px;

    }

    .three-products {
        width: calc(100% - 40px);
        display: block;
        margin: 0px 20px 20px 20px;
        padding: 0px 0px 20px 0px;
    }

    .each-single-product {
        width: 100%;
        margin-bottom: 20px;
        padding: 40px 50px 39px 50px;
    }

    .each-single-product h6 {
        margin: 37px 0px 0px 0px;
    }
    .g2submitnotextract-head {
        bottom: 15%;
        top: unset;
        position: fixed !important;
    }
    
    .g2submitnotextract {
        /* right: -108px; */
        right: 0;
    }
    .each-single-product h4 {
        margin: 0px 29px;
    }
}