/*Preview Page Starts*/

/* Page Details */
:root {
    --menu-fontname: "Axiforma-Bold";
  }
  @font-face {
    font-family: "Montserrat-Regular";
    src: url(../fonts/Montserrat-Regular.ttf);
  }
  
  @font-face {
    font-family: "Montserrat-Bold";
    src: url(../fonts/Montserrat-Bold.otf);
  }
  @font-face {
    font-family: "Montserrat-Medium";
    src: url(../fonts/Montserrat-Medium.ttf);
  }
  @font-face {
    font-family: "Axiforma-Regular";
    src: url(../fonts/Axiforma-Regular.ttf);
  }
  
  @font-face {
    font-family: "Axiforma-Bold";
    src: url(../fonts/Axiforma-Bold.ttf);
  }
  
  
.profile-social{
  background-color: #fff;
  padding: 30px 30px 40px 30px;
}
.profile-social h4{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.profile-social h5{
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 31px;
}
.profile-social p{
  color: #1B2952!important;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.profile-social a{
  color: #3064F9!important;
  font-size: 13px!important;
  text-decoration: none;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
}
.profile-social .col-md-6 .d-flex .social-parent-div{
  margin-left: 33px;
}
.profile-social a.remove{
  color: #7782A1!important;
  font-size: 13px!important;
}
.my-profile-page {
  width: 100%;
}
.my-profile-head{
  background-color: #fff;
  border-bottom: 1px solid #E3E6F1;
  padding: 20px 30px!important;
}
.my-profile-personal{
  background-color: #fff;
  padding: 20px 30px;
}
.my-profile h1 {
  color: #1B2952;
  font-size: 20px!important;
  font-family: "Axiforma-Bold";
  margin-bottom: 5px;
  margin-top: 0px;
}
.my-profile p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 0px;
  color: #7782A1;
  text-align: left!important;
}
.profile-save-btn {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border: 1px solid #3064f9;
  line-height: 15px;
  text-transform: uppercase;
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
}
.profile-save-btn:hover{
  background-color: #0040f9;
  border: 1px solid #0040f9;
  transition:0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.my-profile-shadow{
  filter: drop-shadow(0px 0px 6px rgba(188, 193, 206, 0.5));
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}
.my-profile-personal h4{
  color: #1B2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-profile-personal .color_picker{
  margin-bottom: 10px;
}
.my-profile-personal .color_picker input , .my-profile-personal .color_picker input:focus , .my-profile-personal .color_picker input:focus-visible{
  background-color: #F8FAFE;
  outline: none!important;
  border: 1px solid #E3E6F1!important;
  height: 50px!important;
  color: #1B2952!important;
  font-size: 13px!important;
}
.my-profile-personal .color_picker select , .my-profile-personal .color_picker select:focus , .my-profile-personal .color_picker select:focus-visible{
  background-color: #F8FAFE!important;
  outline: none!important;
  border: 1px solid #E3E6F1!important;
  background: url(../images/dashboard/dark-grey.png) 98%/10% no-repeat;
  background-size: auto;
  -webkit-appearance: none;
  height: 50px!important;
  color: #1B2952!important;
  font-size: 13px!important;
  padding-left: 15px!important;
}
.my-profile-personal .color_picker .phonecodeselect{
  background-color: #F8FAFE;
  border: 1px solid #E3E6F1!important;
  padding: 12px 15px;
  font-size: 13px!important;
  height: 50px !important;
  display: flex;
  align-items: center;
}
.my-profile-personal .color_picker .phone-inputs{
  border-left: none!important;
}
.my-profile-personal .select-input {
  border: none;
}
.profile-delete{
  background-color: #fff;
  padding: 40px 30px;
}
.my-profile-delete{
  width: 160px;
  height: 40px;
  color: #BCC1CE;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-transform: uppercase;
  border: 1px solid #E3E6F1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
  transition: .5s;
}
.my-profile-delete:hover{
  background-color: #FF4848;
  border: 1px solid #FF4848;
  color: #fff;
  transition: .5s;
}
.profile-delete h3 {
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 10px;
}
.profile-delete h5 {
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.before-delete-ok {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 4px;
  width: 140px;
  height: 44px;
  line-height: 44px;
  transition: .5s;
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
}
.before-delete-ok p{
  color: #fff;
  font-size: 14px;
  transition: .5s;
  margin-bottom: 0px;
}
.before-delete-ok:hover{
  background-color: #fff;
  transition: .5s;
}
.before-delete-ok:hover p{
  color: #3064f9;
  transition: .5s;
}
.before-delete-cancel {
  background-color: #fff;
  border: 1px solid #C8CEDB;
  border-radius: 4px;
  width: 140px;
  height: 44px;
  line-height: 44px;
  transition: .5s;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}
.before-delete-cancel p{
  color: #7782A1;
  font-size: 14px;
  transition: .5s;
  margin-bottom: 0px;
}
.before-delete-cancel:hover{
  background-color: #C8CEDB;
  transition: .5s;
}
.before-delete-cancel:hover p{
  color: #fff;
  transition: .5s;
}
.before-delete-step h4{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  text-align: center;
}
.before-delete-step h6{
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  line-height: 25px;
  margin-bottom: 30px;
}
.before-delete-step{
  padding-top: 25px;
  padding-bottom: 40px;
}
.before-delete-footer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-account-popup h4{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.delete-account-popup h5{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 20px;
  line-height: 20px;
}
.delete-account-popup h6{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 22px;
  line-height: 20px;
}
.delete-account-popup input{
  width: 100%;
  height: 50px;
  border: 1px solid #E3E6F1;
  background-color: #F8FAFE;
  padding: 0px 15px;
  margin-bottom: 40px;
  outline: none!important;
}
.delete-account-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E3E6F1;
}
.delete-my-account{
  padding: 0px!important;
}
.delete-account-popup{
  padding: 30px 20px 0px 20px;
}
.delete-account-footer{
  padding: 15px 20px;
}
.delete-account-footer div:nth-child(1){
  background-color: #fff;
  border: 1px solid #C8CEDB;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  transition: .5s;
  cursor: pointer;
  text-align: center;
}
.delete-account-footer div:nth-child(1) p{
  color: #7782A1;
  font-size: 14px;
  transition: .5s;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.delete-account-footer div:nth-child(1):hover{
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}

.delete-account-footer div:nth-child(2){
  background-color: #fff;
  border: 1px solid #FF4848;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  transition: .5s;
  cursor: pointer;
  text-align: center;
}
.delete-account-footer div:nth-child(2) p{
  color: #FF4848;
  font-size: 14px;
  transition: .5s;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.delete-account-footer div:nth-child(2):hover{
  color: #FF4848;
  border: 1px solid #FF4848!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(250 165 165 / 40%);
}
.qr-authenticate{
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 40px 30px;
}  
.qr-code{
  width: 120px;
  height: 120px;
  position: relative;
}
.qr-code button{
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
}
.hide-qr{
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  opacity: .96;
}
.qr-verify{
  margin-left: 30px;
}
.qr-verify input{
  width: 60px;
  height: 50px;
  border: 1px solid #E3E6F1;
  border-radius: 3px;
  background-color: rgba(227, 230, 241, 0.5);
  margin-right: 10px;
  outline: none!important;
  padding: 10px 23px;
  font-size: 15px;
  font-family: "Axiforma-Regular";
}
.qr-verify input.error-authenticate{
  border: 1px solid #FFBABA;
  background-color: #FFFCFC;
}
.qr-authenticate h4{
  font-size: 13px;
  color: #1B2952;
  margin-left: 30px;
  margin-bottom: 10px;
  font-family: "Axiforma-Regular";
}
.enable-authenticate{
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  transition: .5s;
  text-transform: uppercase;
}
.enable-authenticate:hover{
  background-color: #779AFF;
  border: 1px solid #779AFF;
  transition:0.5s;
}
.disable-authenticate{
  background-color: #fff;
  border: 1px solid #E3E6F1;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #BCC1CE;
  font-size: 14px;
  transition: .5s;
  text-transform: uppercase;
}
.disable-authenticate:hover{
  color: #fff;
  background-color: #BCC1CE;
  transition: .5s;
}
.qr-verified{
  padding: 40px 30px;
  background-color: #fff;
}
.qr-verified h5{
  font-size: 14px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  margin-bottom: 0px;
}
.qr-verified h5 img{
  margin-right: 10px;
}
.code-popup{
  max-width: 600px;
  max-height: 400px;
}
.code-popup h4{
  font-size: 18px;
  color: #1B2952;
  font-family: "Axiforma-Bold";
}
.code-popup h6{
  font-size: 13px;
  color: #7782A1;
  line-height: 20px;
  font-family: "Axiforma-Regular";
  margin-bottom: 23px;
}
.code-popup .modal-body{
  padding: 0px!important;
}
.code-list{
  background-color: #F8FAFE;
  border: 1px solid #E3E6F1;
  border-radius: 3px;
  padding: 14px 20px 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}
.code-controls{
  position: relative;
}
.code-controls img{
  margin-left: 20px;
  cursor: pointer;
}
.code-list p{
  font-size: 16px;
  color: #7782A1;
  line-height: 26px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.code-popup-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E3E6F1;
  padding: 15px 20px;
}
.code-popup-footer button:nth-child(1){
  background-color: #fff;
  border: 1px solid #E3E6F1;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #BCC1CE;
  font-size: 13px;
  transition: .5s;
  text-transform: uppercase;
}
.code-popup-footer button:nth-child(1):hover{
  border-color: #E3E6F1;
  background-color: #E3E6F1;
  transition: .5s;
}
.code-popup-footer button:nth-child(2){
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 2px;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 13px;
  transition: .5s;
  text-transform: uppercase;
}
.code-popup-footer button:nth-child(2):hover{
  background-color: #779AFF;
  border: 1px solid #779AFF;
  transition:0.5s;
}
.code-popup-body{
  padding: 30px 20px;
}
.my-profile-picture{
  margin-bottom: 0px!important;
}
.new-profile-panel .pannel_title{
  margin-top: 15px!important;
}
.new-profile-panel  .web_logo {
  margin-top: 15px;
}
.code-popup .modal-content{
  border: none;
}
.social-error{
  border-radius: 3px;
  background-color: #FFECEC;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.social-error p{
  color: #FF4848!important;
  font-family: "Axiforma-Regular";
  font-size: 14px!important;
  line-height: 22px;
}
.social-error div:nth-child(2){
  min-width: 50px;
  text-align: right;
}
.delete-account-footer div .new-red-loader , .delete-account-footer div .new-red-loader:hover {
  border: 2px solid #FF4848!important;
  border-radius: 50%!important;
  border-top: 2px solid transparent!important;
  width: 16px!important;
  height: 16px!important;
  -webkit-animation: spin 0.6s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;
  box-shadow: none!important;
  margin: auto;
  margin-top: 10px;
}










@media (min-width: 600px) and (max-width: 1200px) {
  
  .dasboard_page.mobile-display .transparent_background.transparent_background_profile.my-profile {
    margin-top: 70px !important;
  }
  .qr-verify input {
    width: 50px!important;
    height: 45px;
    margin-bottom: 10px;
    padding: 10px 16px;
  }
  .code-popup{
    max-width: 600px!important;
  }
  .code-popup .code-popup-footer button{
    width: 100px!important;
  }
  .code-popup .code-list p {
    text-align: left!important;
  }
}
@media screen and (max-width: 1200px) {
  .social-error{
    padding: 19px 19px 16px 19px;
  }
  .social-error p{
    text-align: left!important;
    font-size: 13px!important;
  }
  .social-error div:nth-child(2){
    display: none;
  }
  .profile-social .row .col-md-6:nth-child(1){
    margin-bottom: 30px;
  }
  .my-profile-head button{
    display: none!important;
  }
  .my-profile-personal {
    padding: 20px;
  }
  .my-profile-personal .color_picker input , .my-profile-personal .color_picker input:focus , .my-profile-personal .color_picker input:focus-visible{
    padding-left: 10px!important;
  }
  .my-profile-personal .color_picker select , .my-profile-personal .color_picker select:focus , .my-profile-personal .color_picker select:focus-visible{
    padding-left: 10px!important;
  }
  .my-profile-personal button{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    height: 50px;
  }
  .my-profile-shadow {
    border-radius: 0px;
  }
  .profile-social {
    background-color: #fff;
    padding: 30px 20px 30px 20px;
  }
  .profile-social h5{
    line-height: 24px;
    font-size: 13px;
  }
  .profile-social .col-md-6 .d-flex .social-parent-div {
    margin-left: 15px;
  }
  .profile-delete{
    display: block!important;
    padding: 30px 20px;
  }
  .my-profile-delete{
    width: 100%;
    margin-top: 30px;
    height: 50px;
  }
  .profile-delete h5{
    line-height: 24px;
    font-size: 13px;
  }
  .qr-authenticate {
    display: block;
  }
  .qr-authenticate h4 {
    margin-left: 0px;
    margin-top: 30px;
  }
  .qr-verify {
    margin-left: 0px;
  }
  .qr-verify input {
    width: 12.5%;
    height: 45px;
    padding: 10px 16px;
  }
  .qr-code {
    width: 160px;
    height: 160px;
  }
  .qr-authenticate {
    padding: 30px 20px;
  }
  .qr-verified {
    padding: 30px 20px;
  }
  .qr-verified h5 img {
    margin-right: 5px;
  } 
  .qr-verified h5 {
    font-size: 12px;
  }
  .enable-authenticate {
    width: 100%;
    margin-top: 30px;
    display: flex!important;
    align-items: center;
    justify-content: center;
  }
  .disable-authenticate {
    width: 100%;
    margin-top: 30px;
  }
  .code-popup-footer button{
    width: 45%!important;
  }
  .delete-account-popups .modal-content{
    position: inherit !important;
    height: auto;
  }

}

