.newmergeapp-head h1{
    font-size: 24px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 0px;
}
.newmergeapp-head h1 .productsetting-tooltip{
    margin-left: 6px
}
.newmergeapp-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-bottom: 15px;
}
.newmergeapp-head-right{
    display: flex;
    margin-top: -5px;
}
.newmergeapp-head-right button{
    width: 100px;
    color: #FFFFFF;
    height: 40px;
    border: unset;
    border-radius: 3px;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    margin-left: 20px;
}
.mergeapp-select-container input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.mergeapp-select-container:hover input ~ .meregeapp-select-checkmark {
    background-color: #ffff;
}
.mergeapp-select-container input:checked ~ .meregeapp-select-checkmark {
    background-color: #3064F9;
    border: unset;
}
.newmergeapp-head-right  .consent-setting{
    width: 40px !important;
}
.meregeapp-select-checkmark {
    position: absolute;
    top: 0px;
    left: -18px;
    height: 20px;
    width: 20px;
    background-color: #ffff;
    border-radius: 50%;
    border: 1px solid #BCC1CE;
    cursor: pointer;
}
.mergeapp-select-container input:checked ~ .meregeapp-select-checkmark:after {
    display: block;
}

.mergeapp-select-container .meregeapp-select-checkmark:after {
    left: 7px;
    top: 3.7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.meregeapp-select-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.mergeapp-select-maindiv {
    position: relative;
}
.newmergeapp-btn-disable{
background-color: #BCC1CE;
}
.newmergeapp-btn-enable{
    background-color: #3064F9;
    transition: .5s;
    cursor: pointer;
}
.newmergeapp-btn-enable:hover{
        transition: .5s;
        box-shadow: 0 2px 4px rgb(112 155 250 / 69%);
        background-color: #134bec;   
}
.newmergeapp-maindiv{
    position: relative;

   
}
.newmergeapp-maindiv .newspinner{
    height: 100%;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    min-height: 81vh;
}

.newmergeapp-updatediv{
    position: relative;
    background-image: url("../../../assets/images/dashboard/app-icon-background.png");
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    background-attachment: fixed;
    min-height: 81vh;
    display: flex;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.newmergeapp-updatediv-left h2{
    color: #1b2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    margin-bottom: 15px !important;
}
.newmergeapp-updatediv-left{
    padding: 160px 40px 0px 120px;
    width: 41%;
}
.newmergeapp-updatediv-left p{
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 19px;
    margin-bottom: 27px;
    text-align: left !important;
}
.newmergeapp-updatediv-left ul li{
    line-height: 17px;
    font-size: 14px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;
    padding-bottom: 18px;
    list-style-type: none;
    position: relative;
}
.newmergeapp-updatediv-left ul li::before{
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 5px;
}
.newmergeapp-updatediv-left a{
    width: 200px;
    height: 50px;
    border-radius: 3px;
    background-color: #3064f9;
    font-size: 14px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    font-family: 'Axiforma-Regular';
    transition: 0.5s;
}
.newmergeapp-updatediv-left a:hover {
    background-color: #134bec !important;
}
.newmergeapp-updatediv-left ul {
    padding-left: 0 !important;
}
.newmergeapp-updatediv-right{
    padding: 110px 30px 0px 25px;
    width: 59%;
}
.newmergeapp-updatediv-right img{
    max-width: 100%;
}
.newmergeapp-listing-div{
    position: relative;
    display: flex;
    min-height: 81vh;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    /* border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06); */
}
.nomerge-listed{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 275px;
    width: 100%;
    min-height: 81vh;
    height: 100%;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}

.nomerge-listed p{
    font-size: 14px;
    color: #7782A1;
    margin-top: 20px;
    font-family: 'Axiforma-Regular';
    margin-bottom: 25px;
    text-align: center;
}
.nomerge-listed button{
    width: 140px;
    height: 40px;
    border: unset;
    background-color: #3064F9;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    transition: .5s;
}
.nomerge-listed button:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(112 155 250 / 69%);
    background-color: #134bec;
}
.nomerge-listed button img{
    margin-right: 5px;
}
.mergeapp-popup-appicon .merge-app-appicon{
    border-radius: 50px;
}
.merge-app-appicon{
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: cover !important;
    width: 60px;
    height: 60px;
    border-radius: 15px;
    /* background-image: url("../../../assets/images/dashboard/the-icon.png"); */
}
.merge-country-flag{
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: cover !important;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-image: url("../../../assets/images/dashboard/mergeapp-preview-flag5.png");  
}
.newmergeapp-list-preview-div{
    height: 651px;
    width: 302px;
    border: 4px solid #E3E6F1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.01);
    border-radius: 20px;
    padding: 30px 16px;
    overflow-y: auto;
    overflow-x: hidden;

}
.newmergeapp-list-preview-div::-webkit-scrollbar { 
    width: 0px !important;
  }
.newmergeapp-list-preview{
    width: 50%;
    display: flex;
    justify-content: center;
    padding-top: 84px;
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;
    padding-right: 15px;

}
.newmergeapp-list-left{
width: 50%;
}
.newmergeapp-list-left-head{
    border-bottom: 1px solid #F1F2F8;
    display: flex;
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
}
.newmergeapp-list-left-head{
    border-bottom: 1px solid #F1F2F8;
    display: flex;
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    padding: 12px 20px;
}
.newmergeapp-list-left-head p{
margin-bottom: 0px;
}
.newmergeapp-list-left-first{
    width: 89%;
}
.newmergeapp-list-left-last{
    width: 10%;
}
.merge-app-apptype{
    display: flex;
    width: 90%;
    align-items: center;
}
.marge-app-nameId{
    margin-left: 15px;
}
.marge-app-nameId h3{
    font-size: 14px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    margin-bottom: 5px;
}
.marge-app-nameId h3 span{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-left: 5px;
}
.marge-app-nameId p{
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}
.merge-app-list-div{
    display: flex;
    padding: 23px 20px;
    align-items: center;
    border-bottom: 1px solid #F1F2F8;
}
.delete-merge-app{
    width: 10%;
    text-align: end;
    margin-bottom: 0px;
}
.delete-merge-app img{
    cursor: pointer;
}
.add-apps-btn{
    margin: 30px 20px;
    font-size: 13px;
    color: #3064F9;
    font-family: 'Axiforma-Regular';
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content
}
.add-apps-btn img{
    margin-right: 5px;
}

.merged-app-icon{
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: cover !important;
    width: 54px;
    height: 54px;
    border-radius: 15px;
}
.newmergeapp-list-preview-div h1{
    font-size: 19px;
    color: #1A1A1A;
    font-family: 'Axiforma-Bold';
    line-height: 25px;
    margin-bottom: 27px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.merged-app-details{
    width: 85%;
}
.merged-app-details h2{
    font-size: 11px;
    color: #1F1F1F;
    font-family: 'Axiforma-Bold';
    margin-bottom: 3px;
    line-height: 16px;
    /* word-break: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.merged-app-details p{
    font-size: 9px;
    color: #6F6F6F;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    line-height: 15px;
    /* word-break: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.maerged-app-second-layout{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;
    flex-direction: row-reverse;
}
.maerged-app-first-layout{
    align-items: center;
    margin-bottom: 30px;
}
.maerged-app-first-layout .merged-app-icon{
 margin-right: 10px;
}
.newmergeapp-maindiv button{
    /* display: none; */
}
.mergelayout-maindiv{
    min-height: 79vh;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    padding: 20px 30px;
}

.mergelayot-head{
    border-bottom: 1px solid #E3E6F1;
    padding-bottom: 20px;
}
.mergelayot-head h2{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    margin-bottom: 7px;
    line-height: 25px;
}
.mergelayot-head p{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    text-align: left !important;
    line-height: 17px;
}
.merge-layout-type{
    display: flex;
    padding-top: 25px;
}
.merge-layouts{
    padding: 25px 12px;
    width: 245px;
    height: 530px;
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    border-radius: 3px;
    transition: .5s;
    border: 2px solid #e3e6f1;
    box-shadow: 0 3px 6px rgb(0 0 0 / 6%) !important;
    background-color: #fff;
    margin-right: 25px;
}
.layout-selected, .merge-layouts:hover{
    border: 2px solid #3064f9;
    box-shadow: 0 3px 26px rgb(0 0 0 / 16%) !important;
    transition: .5s;
    background-color: #f9f9f9;
}

.mergeapp-setting-maindiv{
    min-height: 79vh;
    background-color: white;
    display: flex;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.mergeapp-setting-left{
    width: 50%;
    padding: 20px 20px 20px 30px;
}
.mergeapp-setting-right{
    width: 50%;
    padding-top: 84px;
    display: flex;
    justify-content: center;
    padding-right: 15px;
    padding-bottom: 30px;
}
.mergeapp-setting-head{
    border-bottom: 1px solid #E3E6F1;
    padding-bottom: 20px;
}
.mergeapp-setting-head h2{
    font-size: 18px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    line-height: 25px;
    margin-bottom: 4px;
}
.mergeapp-setting-head p{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    text-align: start !important;
}
.merge-setting-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;
    margin-bottom: 23px;
}
.merge-setting-toggle h4{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin: 0px;
}
.mergeapp-tooltip{
    position: relative;
    display: inline-block;
    margin-bottom: 2px !important;
    margin-left: 5px !important;
    
}
.mergeapp-tooltip:hover .mergeapp-tooltipsubheader {
    visibility: visible;
    text-align: left !important;
}

.mergeapp-tooltip .mergeapp-tooltipsubheader {
    visibility: hidden;
    width: 250px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 27px;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
}
.mergeapp-tooltip .mergeapp-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 100%;
    margin-top: -2px;
    border: 5px solid transparent;
    border-right-color: #000;
}
.merge-setting-title{
    display: grid;
    margin-top: 25px;
}
.merge-setting-title h4{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin: 0px 0px 7px;
}
.merge-setting-title input{
    border: 1px solid #BCC1CE;
    height: 50px;
    border-radius: 3px;
    font-size: 13px;
    color: #1B2952;
    font-family: 'Axiforma-Regular';
    padding: 15px;
    outline: none;
}
.merge-location-markerdiv{
    display: flex;
    justify-content: center;
}
.merge-location-markerdiv .merge-setting-title{
    width: 50%;
}
.merge-location-markerdiv .marker-color{
    margin-right: 20px;
}
.merge-location-markerdiv .merge-setting-title{
    margin-top: 10px;
}
.merge-setting-title input:hover, .merge-setting-title input:focus{
    background-color: #f8fafe !important;
    border-color: #3064f9 !important;
    transition: .5s !important;
}
.merge-setting-title .color_input{
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    background-color: #fff;
    padding: 7px 7px 7px 3px !important;
    height: 50px !important;
    transition: .5s !important;
    position: relative;
}
.merge-setting-title .color_input p {
    font-size: 13px;
    color: #1b2952;
    margin-bottom: 0;
    font-family: "Axiforma-Regular";
    margin-left: 5px !important;
}
.mergeapp-icon-field {
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.mergeapp-icon-field svg{
    position: absolute;
    right: 15px;
    cursor: pointer;
    top: 19px;
}
.mergeapp-icon-field p{
    font-size: 13px;
    color: #1B2952;
    font-family: "Axiforma-Regular";
}
.mergeapp-Selected_icon {
    background-color: #EDF0F5;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-left: 7px;
    margin-right: 10px !important;
}
.merged-app-location{
    height: 302px;
    margin: -15px -16px 24px;
    overflow: hidden;
    position: relative;
    filter: grayscale(1);
    background-image: url("../../../assets/images/dashboard/mergeapp-map.png");
}
.app-location-zoom{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 17px;
    bottom: 17px;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background-color: #FFFFFF;
    opacity: 0.8;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}
.firstapp-location{
    display: flex;
    align-items: center;
    position: absolute;
    top: 28px;
    left: 46px;
}
.firstapp-location svg{
    fill: #1f1f1f;
    margin-right: 0px;
}
.firstapp-location p{
    font-size: 8px;
    font-family: "Axiforma-Regular";
    line-height: 11px;
    margin-bottom: 0px;
    margin-left: 5px;
}


.secondapp-location{
    display: flex;
    position: absolute;
    top: 47px;
    right: 28px;
    align-items: center;
}
.secondapp-location svg{
    fill: #1f1f1f;
    margin-right: 0px;
}
.secondapp-location p{
    font-size: 8px;
    font-family: "Axiforma-Regular";
    line-height: 11px;
    margin-bottom: 0px;
    margin-left: 5px;
}

.thirdapp-location{
    display: flex;
    align-items: center;
    position: absolute;
    top: 106px;
    left: 123px;
}
.selected-app svg{
    margin-right: 0px;
}
.selected-app svg{
    fill: #FF4848;
   
}
.mergeapp-save-date-time{
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 0px;
}
.selected-app p{
    font-size: 8px;
    font-family: "Axiforma-Regular";
    line-height: 11px;
    margin-bottom: 0px;
    margin-left: 5px;
}
.current-location{
    position: absolute ;
    bottom: 56px;
    left: 144px;
    border-radius: 100%;
}
.app-location-distance{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 64px);
}
.location-distance{
    margin-bottom: 0px;
    color: #B0B0B0;
    font-size: 8px;
}
.mergeapp-selectpopup .modal-header{
    border-bottom: 1px solid #E3E6F1 !important;
    padding: 15px 20px 15px 20px;
}
.mergeapp-selectpopup .modal-header h1 {
    margin-bottom: 0px;
    font-size: 18px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
}
.mergeapp-selectpopup .modal-header .modal-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mergeapp-selectpopup .modal-body{
    padding: 0 !important;
}
.mergeapp-selectpopup .app-list-body{
    display: flex;
    flex-wrap: wrap;
    /* height: 519px;
    overflow: auto; */
    gap: 20px;
    padding: 16px;
    /* background-color: #F8FAFE; */
    /* height: 100%; */
}
.applist-with-loader{
    height: 519px;
    overflow: auto;
    background-color: #F8FAFE;
}

.mergeapp-searchdiv{
    display: flex;
}
.mergeapp-modal-headright{
    display: flex;
}
.mergeapp-react-select-dropdown{
    width: 295px;
    margin-right: 20px;
    height: 40px;
}
.mergeapp-react-select-dropdown .select-filter__single-value{
    font-size: 13px;
}
.select-filter__menu-notice--no-options{
    font-size: 13px !important;
    font-family: 'Axiforma-Bold' !important;  
}
.mergeapp-react-select-second-dropdown .select-filter__single-value{
    font-size: 13px;
}
.mergeapp-react-select-dropdown .select-filter__control{
   /* border: unset;  */
   border: 1px solid #E3E6F1 !important;
   border-radius: 3px !important;
   box-shadow: none;
   font-size: 13px;
   font-family: "Axiforma-Regular";
   color: #1b2952;
}
.mergeapp-react-select-second-dropdown .select-filter__control{
    border: 1px solid #E3E6F1 !important;
    border-radius: 3px !important;
    box-shadow: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #1b2952;
 }
.mergeapp-react-select-dropdown .select-filter__menu {
    margin-top: 3px !important;
}
.mergeapp-react-select-dropdown .select-filter__control:hover{
  
    background-color: #f8fafe !important;
    border-color: #3064f9 !important;
}
.mergeapp-react-select-second-dropdown .select-filter__control:hover{
  
    background-color: #f8fafe !important;
    border-color: #3064f9 !important;
}
.mergeapp-react-select-second-dropdown .select-filter__menu {
    margin-top: 3px !important;
}
.newmergeapp-head .productsetting-tooltip .productsetting-tooltipsubheader {
    top: -1px;

}
.no-app-found-on-search{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.no-app-found-on-search .notingat-the-moment{
    font-size: 14px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-top: 20px;
}
.mergeapp-react-select-second-dropdown{
    width: 295px;
    margin-right: 20px;
    height: 40px;
}
.mergeapp-searchdiv{
    display: flex;
    width: 295px;
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    height: 40px;
}
.mergeapp-searchicon{
    padding: 1px 12px 7px 15px;
}
.newmergeapp-updatediv-left .disable-role-btn{
    height: 50px ;
    line-height: 48px ;
    padding: 0 ;
    width: 220px ;
    margin: 20px 0 ;
    text-align: center ;
    font-size: 14px ;
    background-color: #fff;
    text-decoration: none;
    border-radius: 4px;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-weight: 500;
    border: 1px solid #E3E6F1;
    transition: .5s;
}
.mergeapp-searchdiv input{
    border: unset;
    outline: none;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    padding: 0px;
    width: calc(100% - 28px);
}
.mergeapp-searchdiv form{
    display: flex;
    width: 100%;
}
.mergeapp-selectpopup .modal-footer{
    padding: 15px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border-top: 1px solid #e3e6f1 !important;
    z-index: 1;
    background-color: #fff;
}
.mergeApp-popup-cancel{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #bcc1ce !important;
    color: #7782a1;
    font-size: 13px;
    margin: 0 !important;
    transition: .5s;
    font-family: 'Axiforma-Regular';
    background-color: transparent;
}
.mergeApp-popup-cancel:hover{
    color: #484e5e;
    border: 1px solid #7782a1 !important;
    transition: .5s;
    box-shadow: 0 2px 4px rgb(188 193 206 / 69%);
}
.mergeApp-popup-done button{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    border: unset;
    color: #fff;
    font-size: 13px;
    margin: 0 !important;
    transition: .5s;
    background-color: #3064F9;
    font-family: 'Axiforma-Regular';
}
.mergeApp-popup-done button:hover{
    transition: .5s;
    box-shadow: 0 2px 4px rgb(112 155 250 / 69%);
    background-color: #134bec;
}
.mergeApp-popup-done button:disabled:hover{
   cursor: not-allowed;
   box-shadow: unset;
}
.mergeApp-popup-done button:disabled{
    background-color: #bcc1ce;
}
.mergeApp-popup-done{
    display: flex;
    align-items: center;
}
.mergeApp-popup-done p{
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    margin-right: 20px;
}
.mergeapp-appdiv{
    border: 1px solid #E3E6F1;
    height: 300px;
    width: 295px;
    padding: 13px 15px 15px;
    border-radius: 6px;
    background-color: #ffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: 0.5s;
}
.mergeapp-appdiv:hover {
    background-color: #f8fafe;
    cursor: pointer;
    box-shadow: 0 6px 16px rgba(0, 0, 0, .13);
    transition: 0.5s;
}
 .mergeapp-appdiv-head{
    display: flex;
    justify-content: space-between;
}
.mergeapp-appdiv-head-left{
    width: calc(100% - 30px);
}
.mergeapp-appdiv-head-left h1{
    font-size: 16px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mergeapp-appdiv-head-left p{
    font-size: 12px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
}
.mergeapp-appdiv-foot{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mergeapp-appdiv-foot p{
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    font-size: 10px;
    margin-bottom: 0px;
}

.merge-free-app{
    background-color: #F8FAFE;
}

/* .merge-free-app .mergeapp-popup-appicon{
    filter: grayscale(100%)
} */

.mergeapp-popup-appicon{
    width: 170px;
    height: 170px;
    /* background-image: url("../../../assets/images/dashboard/the-icon.png"); */
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: cover !important;
    margin: auto;
    margin-bottom: 24px;
    margin-top: 17px;
    border-radius: 48px;
    position: relative;
}
.mergeapp-selectpopup{
    max-width: 1280px !important;
    max-height: 660px !important;
}
.mergeApp-popup-cancel-aero{
    display: none
}
.mob-mergeapp-modal-headright{
    display: flex;
}
.mergeapp-parent-app{
    background-color: #fff;
    border-radius: 8px;
    width: 220px;
    min-height: 220px;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
    text-align: center;
    position: relative;
    padding: 0px 10px;
}
.mergeapp-child-app{
    background-color: #fff;
    border-radius: 8px;
    width: 220px;
    min-height: 220px;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 8%);
    text-align: center;
    position: relative;
    padding: 0px 10px;
}
.mergeapp-conf-popup-delete{
    width: 140px;
    height: 44px;
    color: #FF4848;
    border: 1px solid #FF4848;
    border-radius: 3px;
    background-color: #ffff;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    /* border: none; */
    transition: 0.5s;
}
.mergeapp-conf-popup-delete:hover{
    background-color: #FF4848;
    color: #ffff;
    transition: 0.5s;
}
.mergeapp-conf-popup-delete:hover .new-red-loader{
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 2px solid transparent;
}
.mergeapp-parent-tag h3, .mergeapp-child-app h3{
    font-family: "Axiforma-Regular";
    font-size: 10px;
    color: #BCC1CE;
    text-align: center;
    margin-top: 4px;
}
.mergeapp-appicon{
    width: 80px;
    max-width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgb(188 193 206 / 30%);
    margin: auto;
    margin-top: 42px;
    margin-bottom: 20px;
}
.mergeapp-grid{
    display: flex;
    align-items: center;
    width: 530px;
    margin: auto;
}
.mergeapp-grid h4{
    font-family: "Axiforma-Bold";
    font-size: 15px;
    color: #1B2952;
}
.mergeapp-grid h5{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    word-break: break-word;
}
.merged-app-maindiv h1{
    font-size: 30px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    text-align: center;
    margin-top: 73px;
    margin-bottom: 15px;
}
.mergeapp-appicon img{
    height: auto;
    max-width: 100%;
}
.merged-app-maindiv h2{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    text-align: center;
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0px auto 40px auto;
}
.mergedapp-merged-btns a:nth-child(1) {
    color: #3064F9;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #3064F9;
    border-radius: 3px;
    transition: 0.5s;
    text-decoration: none;
    height: 50px;
    line-height: 48px;
    display: block;
    width: 160px;
    text-align: center;
    margin-right: 10px;
}
.mergedapp-merged-btns a:nth-child(1):hover {
    color: #134bec;
    border: 1px solid #134bec;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.mergedapp-merged-btns a:nth-child(2) {
    color: #7782A1 !important;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    transition: 0.5s;
    text-decoration: none;
    height: 50px;
    line-height: 48px;
    display: block;
    width: 160px;
    text-align: center;
    margin-left: 10px;
}
.mergedapp-merged-btns a:nth-child(2):hover {
    color: #484e5e;
    border: 1px solid #7782A1;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.mergedapp-merged-btns{
    display: flex;
    justify-content: center;
}
.mergeapp-parent-tag, .mergeapp-child-tag {
    border: 1px solid #E3E6F1;
    border-radius: 30px;
    width: 90px;
    height: 22px;
    position: absolute;
    top: -9px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #fff;
}
.merged-app-maindiv{
        padding-top: 150px;
}

.select-filter__placeholder{
    font-size: 14px;
}

.icon-picker-disabled .color_input {
    background-color: #F8FAFE;
      cursor: not-allowed;
      border: 1px solid #E3E6F1;
}
.icon-picker-disabled .color_input div {
    cursor: not-allowed !important; 
}
.icon-picker-disabled .colorbox {
border: 1px solid #E3E6F1 !important;
}
.icon-picker-disabled .color_input p{
color: #BCC1CE;
}
.mergeapp-mob-save-button-div{
    display: none;
}
.mergeapp-popup-appicon:hover .appType-tooltip{
    /* visibility: visible; */
    visibility: hidden;
}
.appType-tooltip::after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: calc(100% + 5px);
    top: -11px;
    margin-left: -55px;
    border: 6px solid transparent;
    border-bottom-color: rgb(0 0 0 / 80%);
    transform: rotate(0deg);
}
/* .merge-free-app .mergeapp-popup-appicon img{
    opacity: 0.4;
} */
.mergeapp-agencyupgrade-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.appType-tooltip{
    visibility: hidden;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    font-size: 10px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    bottom: -27px;
    right: 40px;
    padding: 9px 12px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 24%);
    transition: all .2s ease 0s;
    width: max-content;
    font-family: "Axiforma-Regular";
    min-width: 80px;
    text-transform: capitalize;
}

.add-app-search-input:disabled{
    background-color: #fff;
    cursor: not-allowed;
}
.upgrade-skip-merge {
    display: flex;
    align-items: center;
    margin-top: 22px;
    grid-gap: 20px;
    gap: 20px;
}

@media screen and (max-width: 1280px) and (min-width: 1201px)  { 
    .mergeapp-appdiv {
        width: 275px ;
    }
    .mergeapp-selectpopup{
        max-width: 1200px !important;
    }
}

@media screen and (max-width: 1500px) and (min-width: 1201px)  { 
    .newmergeapp-updatediv-left{
        padding: 146px 10px 0px 20px;
        width: 50%;
    }
    .newmergeapp-updatediv-right{
        padding: 93px 20px 0px 0px;
        width: 50%;
    }
}
@media (min-width: 2200px) { 
    .newmergeapp-updatediv-left {
        display: flex;
        justify-content: end;
        width: 50%;
    }
    .newmergeapp-updatediv-right {
        width: 50%;
    }
}
@media screen and (max-width: 1200px) and (min-width: 751px)  { 
    .mergeapp-appdiv {
        width: calc(33.33% - 10px);
    }
}
@media screen and (max-width: 750px) and (min-width: 500px) { 
    .mergeapp-appdiv {
        width: calc(50% - 10px);
       
    }
}
@media (max-width: 500px) { 
    .mergeapp-appdiv {
        width: 100%;

    }
    .mergedapp-merged-btns{
        width: 100%;
        margin-bottom: 45px;
    }
    .merged-app-maindiv {
        padding: 0px 27px;
    }
    .mergedapp-merged-btns a:nth-child(1) {
        width: 50%;
    }
    .mergedapp-merged-btns a:nth-child(2) {
        width: 50%;
    }
}
@media (max-width: 1200px) { 
    .mob-mergeapp-modal-headright .billing-options{
        overflow: hidden;
        text-overflow: ellipsis;
        display: unset !important;
    }
    .newmergeapp-updatediv-left .disable-role-btn{
        width: 100%;
        margin: 0px 0px 40px;
    }
    .mergeapp-agencyupgrade-div .upgrade-feature{
            margin: 10px 0px 0px;
    }
    .newmergeapp-head-right .newmergeapp-btn-enable{
        display: none !important;
    }
    .mergeapp-agencyupgrade-div{
        display: grid;
    }
    .newmergeapp-head .productsetting-tooltip .productsetting-tooltipsubheader {
        top: 31px;
        left: -54px;
    }
    .mergeapp-selectpopup .modal-header h1 {
        font-size: 20px
    }
    .mergeapp-selectpopup .modal-body {
        overflow: auto;
    }
    .mergeapp-conf-popup-delete{
        width: 280px;
        height: 50px;
        font-size: 16px;
        border: none;
        color: #FFFFFF;
        background-color: #FF4848;
    }
    .mergeapp-conf-popup-delete .new-red-loader{
        border: 2px solid #fff;
    border-radius: 50%;
    border-top: 2px solid transparent;
    }
    .merge-setting-title {
        margin-top: 25px;
    }
    .mergeapp-save-date-time{
        display: none;
    }
    .marge-app-nameId h3{
        margin-bottom: 5px;
    }
    .mergeapp-mob-save-button-div{
        display: block;
    }
    .mergeapp-mob-save-button-div p{
        color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    }
    .newmergeapp-head-right{
        margin-top: -7px;
    }
    .newmergeapp-maindiv .nomerge-listed button {
        width: 140px;
        font-size: 14px;
        height: 40px;
        margin-top: 0px;
    }
    .mob-done-loader{
        border: 2px solid #3064F9;
        border-top: 2px solid transparent;
    }
    .mergeApp-popup-done button:disabled{
        color: #bcc1ce;
        background-color: unset;
    }
    .mergeapp-react-select-dropdown .select-filter__control{
       
        height: 50px !important;
    }
    .mergeapp-react-select-dropdown .select-filter__menu{
        width: 100vw  !important;
        margin-left: -15px;
        top: 60px;
    }
    .mergeapp-react-select-dropdown .select-filter__menu::before{
       
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 0 10px 10px;
        position: absolute;
        z-index: 99;
        top: -10px;
        left: 25%;
        margin: auto;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }



    .mergeapp-react-select-second-dropdown .select-filter__control{

        height: 50px !important;
    }
    .mergeapp-react-select-second-dropdown .select-filter__menu{
        width: 100vw  !important;
        right: -15px;
        top: 60px;
    }
    .mergeapp-react-select-second-dropdown .select-filter__menu::after{
        background-color: rgba(0, 0, 0, .4);
    }
    .mergeapp-react-select-second-dropdown .select-filter__menu::before{
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 0 10px 10px;
        position: absolute;
        z-index: 99;
        top: -10px;
        right: 25%;
        margin: auto;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    .mergeapp-grid h4{
        display: none;
    }
    .mergeapp-grid h5{
        display: none;
    }
    
    .mergeapp-grid{
        width: unset;
        margin: 0px auto;

    }
   
    .merged-app-maindiv h2 {
        margin: 0px auto 20px auto;
    }
    .mergeapp-child-app, .mergeapp-parent-app {
        width: 140px;
    min-height: 140px;

    }
    .merged-app-maindiv h1 {
        font-size: 24px;
        margin-top: 25px;
        margin-bottom: 5px;
    }
    .mergeapp-appicon {
        margin-top: 32px;
    }
    .mergeapp-selectpopup .modal-header {
        border-bottom: unset !important;
        padding: 20px 15px 10px 15px;
    }
    .merged-app-maindiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 79vh;
        padding-top: 0px;
    }
    .mergeapp-modal-headright{
        display: grid;
        width: 100%;
    }
    .mergeapp-searchicon {
        padding: 10px 15px 10px 15px;
    }
    .mob-mergeapp-modal-headright .mergeapp-react-select-second-dropdown{
     margin-left: 10px;
    }
    .mob-mergeapp-modal-headright{
        display: flex;
        margin: 15px 0px 10px;
    }
    .mergeapp-react-select-dropdown{
        width: 100%;
        margin-right: 0px;
        height: 50px;
    }
    .mergeapp-react-select-second-dropdown{
        width: 100%;
        margin-right: 0px;
        height: 50px;
    }
    .mergeapp-selectpopup .modal-title svg {
        margin-bottom: 0px;
    }
    .mergeapp-react-select-second-dropdown .react-select22__control{
        height: 50px;
    }
    .mergeapp-react-select-second-dropdown .react-select22__value-container{
        height: 50px;
    }
    .mergeapp-react-select-dropdown .react-select22__control{
        height: 50px;
    }
    .mergeapp-react-select-dropdown .react-select22__value-container{
        height: 50px;
    }
    .mergeapp-searchicon {
        padding: 5px 12px 10px 15px;
    }
    .mergeapp-tooltip .mergeapp-tooltipsubheader::after {
        content: "";
        position: absolute;
        top: -8px;
        right: 102px;
        margin-top: -2px;
        border: 6px solid transparent;
        border-right-color: #000;
        transform: rotate(90deg);
    }
    .mergeapp-tooltip .mergeapp-tooltipsubheader {
        top: 26px;
        left: -80px;

        width: 195px;
        padding: 10px 10px 10px 10px;
    }
    .mergeApp-popup-done button:hover {
        transition: unset;
        box-shadow:unset;
        background-color: unset;
    }
    .mergeapp-selectpopup .modal-content {
        flex-direction: column-reverse;
    }
    .mergeapp-searchdiv {
        height: 50px;
        background-color: #ffff;
        width: 100%;
    }
    .mob-modal-body{
        display: flex;
        flex-direction: column;
        height: calc(100% - 60px);
        background-color: #F8FAFE;
    }
    .applist-with-loader{
        height: 100%;
    }
    .mergeapp-selectpopup .app-list-body {
        gap: 15px;
        padding-top: 0px;
       
    }
    .mergeApp-popup-cancel {
        display: none;
    }
    .mergeApp-popup-cancel-aero{
        cursor: pointer;
        display: block
    }
    .mergeApp-popup-done p {
        font-size: 12px;
        color: #7782A1;
        margin-bottom: 0px;
        padding-right: 20px;
        border-right: 1px solid #E3E6F1;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 0px;
    }
    .mergeapp-selectpopup .modal-footer {
        padding: 7px 15px;
        height: 60px;
        border-top: unset !important;
        background-color: #fff;
        box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    }
    .mergeapp-selectpopup .modal-header .modal-title {
        flex-direction: column;
        align-items: flex-start;
    }
    .mergeApp-popup-done button {
        background: transparent;
        color: #3064F9;
        width: fit-content;
        height: unset;
        padding: 0px;
        font-size: 14px;
        margin-left: 20px !important;
    }
    /* .mergeapp-selectpopup .modal-header h1 {
       display: none;
    } */
    .mergeapp-setting-head{
        padding: 23px 15px 20px;
    }
    .merge-location-markerdiv .marker-color {
        margin-right: 0px;
    }
    .merge-location-markerdiv .merge-setting-title {
        width: 100%;
    }
    .merge-location-markerdiv {
        display: grid;
        justify-content: unset;
    }
    .mob-mergesetting-pagediv{
        padding: 0px 15px;
    }
    .mergeapp-setting-right{
        display: none;
    }
    .mergeapp-setting-left {
        width: 100%;
        padding: 0px ;
    }
    .mergelayot-head h2 {
        font-size: 16px;
        margin-bottom: 2px;
        line-height: 23px;
    }
    .merge-layouts {
        margin: 0px 0px 39px 0px;
        height: 521px;
        width: 241px;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #ffff;
    }
    .layout-selected, .merge-layouts:hover {
        background-color: #ffff;
    }
    /* .merge-layouts img{
        height: 485px;
    } */
    .mergelayout-maindiv {
        padding: 20px 0px 0px;
    }
    .mergelayot-head {
        padding: 0px 15px 20px 15px;
    }
    .merge-layout-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
    }
    .newmergeapp-head {
        margin-bottom: 13px;
        margin-top: -2px;
    }
    .newmergeapp-updatediv-left{
        width: 100%;
        padding: 21px 20px 0px 20px;
    }
    .newmergeapp-updatediv-left h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 5px !important;
    }
    .newmergeapp-updatediv-left p {
        margin-bottom: 15px;
    }
    .newmergeapp-updatediv {
        display: flex;
        background-image: unset;
        min-height: 79vh;
        flex-direction: column;
        justify-content: unset;
    }
    .newmergeapp-updatediv-right {
        width: 100%;
        padding: 0px 10px 20px 10px;
        display: flex;
        justify-content: center;
    }
    .newmergeapp-updatediv-left a {
        font-size: 16px;
    width: 100%;
    }
    .upgrade-skip-merge{
        margin-bottom: 20px;
    }
    .upgrade-skip-merge .skip-btn-ui{
        display: flex!important;
    }
    .newmergeapp-head-right button {
        display: none;
    }
    .nomerge-listed {
        height: 79vh;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
        background-color: unset;
        border-radius: unset;
        border: unset;
        box-shadow: unset;
        padding-bottom: 55px;
    }
    .newmergeapp-listing-div {
        background-color: unset;
        border-radius: unset;
        border: unset;
        box-shadow: unset;
        min-height: unset;
    }
    .newmergeapp-head h1 {
        font-size: 20px;
    }
    .newmergeapp-list-left-head {
     display: none;
    }
    .merge-country-flag-div{
        display: none;
    }
    .newmergeapp-list-preview{
        display: none;
    }
    .newmergeapp-list-left {
        width: 100%;
        background-color: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    }
    .merge-app-list-div {
        justify-content: space-between;
        padding: 16px 15px 15px;
    }
    .delete-merge-app{
        width: unset;
    }
    .add-apps-btn {
        margin-top: 0px;
        padding: 28px 20px;
        margin: 0px;
        width: 100%;
    }
    .mergeapp-all-pages button{
        width: 99%;
        color: #FFFFFF;
        height: 48px;
        border: unset;
        border-radius: 3px;
        font-size: 16px;
        font-family: 'Axiforma-Regular';
        display: block;
        margin-left: 1px;
        margin-top: 16px;
    }
}