.primary-submit-web-btn {
  height: 40px;
  width: 87px;
  background-color: var(--BtnBgColor);
  color: var(--BtnTextColor);
  border: none;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  font-size: 14px;
  /* margin-left: 20px; */
  transition: 0.5s;
}

.primary-submit-web-btn:hover {
  background-color: var(--BtnHoverBgColor);
  color: var(--BtnHoverTextColor);
  transition: 0.5s;
}

.primary-submit-mob-btn {
  height: 50px;
  width: 100%;
  background-color: var(--BtnBgColor);
  color: var(--BtnTextColor);
  border: none;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  font-size: 16px;
  margin-bottom: 20px;
}

.primary-submit-mob-btn:hover {
  background-color: var(--BtnHoverBgColor);
  color: var(--BtnHoverTextColor);
  transition: 0.5s;
}

.primary-submit-web-disable-btn {
  width: 100px;
  height: 40px;
  background-color: #bcc1ce;
  color: white;
  border: none;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: not-allowed !important;
  justify-content: center;
  display: flex;
  align-items: center;
}
.primary-submit-mob-disable-btn {
  height: 50px;
  width: 100%;
  background-color: #bcc1ce;
  color: white;
  border: none;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  font-size: 16px;
  margin-bottom: 20px;
}
.header-updated-at {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #bcc1ce;
  text-align: center;
  margin-bottom: 0px;
  width: 100%;
}
.common-navigation-btn {
  width: 40px !important;
  height: 40px;
  min-width: 40px;
  border-radius: 3px;
  color: white;
  background-color: #f8fafe;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.2), 0px 0px 3px 3px #ffff;
  /* margin-left: 20px; */
  justify-content: center !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
}
.common-navigation-btn-clicked {
  width: 40px !important;
  height: 40px;
  min-width: 40px;
  border-radius: 3px;
  color: white;
  background-color: #f8fafe;
  border: none;
  box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset;
  /* margin-left: 20px; */
  justify-content: center !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
}

.common-navigation-setting-btn {
  width: 103px !important;
  height: 40px;
  font-family: "axiforma-regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
  min-width: 103px;
  gap: 6px;
  border-radius: 3px;
  background-color: #f8fafe;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.2), 0px 0px 3px 3px #ffff;
  /* margin-left: 20px; */
  justify-content: center !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
}
.common-navigation-setting-btn-clicked {
  width: 103px !important;
  height: 40px;
  font-family: "axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
  min-width: 103px;
  gap: 6px;
  border-radius: 3px;
  background-color: #f8fafe;
  border: none;
  box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset;
  /* margin-left: 20px; */
  justify-content: center !important;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
}

/* swithch */
.common-toogle-switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
  margin-bottom: 0px;
  padding: 0px;
}

.common-switch-input {
  display: none;
}

.common-switch-input:checked + .switch-slider {
  background-color: #668cfd;
  background-image: url(../../assets/images/dashboard/tick-toggle.png);
  background-size: 10.92px 8px;
  background-repeat: no-repeat;
  background-position: bottom 5px left 8px;
}
.switch-slider.round {
  border-radius: 34px;
}

input:checked + .switch-slider:before {
  transform: translateX(20px);
  background-color: #fff;
}

.switch-slider-disable {
  background-image: url(../../assets/images/dashboard/toggle-lock.png) !important;
  background-size: 8.47px 11.29px !important;
  background-position: bottom 5.2px right 6.8px !important;
}

.switch-slider.round:before {
  border-radius: 50%;
}
.switch-slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 2px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  /* right: -2px; */
}

.switch-slider {
  background-color: #d7dbeb;
  background-image: url(../../assets/images/dashboard/togglecross.png);
  background-size: 7px 7px;
  background-repeat: no-repeat;
  background-position: bottom 6.2px right 8.8px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.lock-common-toogle-switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
  margin-bottom: 0px;
  padding: 0px;
}

.lock-common-toogle-switch .common-switch-input {
  display: none;
}
.common-switch-input:checked + .lock-switch-slider {
  background-image: url(../../assets/images/dashboard/toggle-lock.png);
  background-color: #bbccff;
  background-size: 8.47px 11.29px;
  background-repeat: no-repeat;
  background-position: bottom 5px left 8px;
}
.common-switch-input:checked + .lock-switch-slider:before {
  transform: translateX(20px);
  background-color: #fff;
}
.lock-switch-slider.round {
  border-radius: 34px;
}
.lock-switch-slider.round:before {
  border-radius: 50%;
}
.lock-switch-slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 2px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  /* right: -2px; */
}
.lock-switch-slider {
  background-color: #d7dbeb;
  background-image: url(../../assets/images/dashboard/togglecross.png);
  background-size: 7px 7px;
  background-repeat: no-repeat;
  background-position: bottom 6.2px right 8.8px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
/* swithch */

.commonModal-title {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  line-height: 25.29px;
  text-align: center;
  color: #1b2952;
  margin-bottom: 15px;
}
.commonModal-crossmodal {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 15px 15px 0px 0px;
}
.common-cross-img {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.commonModal-description {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  color: #7782a1;
  margin-bottom: 30px;
}
.commonModal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.commonModal-cancel-btn {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  border: 1px solid #bcc1ce;
  background-color: #ffff;
  color: #7782a1;
  height: 40px;
  width: 140px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.commonModal-submit-btn-red {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  border: 1px solid #ff4848;
  background-color: #ffff;
  color: #ff4848;
  height: 40px;
  width: 140px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.commonModal-submit-btn-red:hover {
  transition: 0.5s;
  box-shadow: 0 2px 4px rgb(255 72 72/29%);
  color: #fff;
  background-color: #ff4848;
}
.commonModal-submit-btn-blue {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  border: 1px solid #3064f9;
  background-color: #3064f9;
  color: #ffffff;
  height: 40px;
  width: 140px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.commonModal-submit-btn-blue:hover {
  background-color: #134bec;
  transition: 0.5s;
}
.commonModal .modal-body {
  padding: 36px 20px 60px;
}
.common-shape-tooltip-box{
  position: relative;
}
.common-shape-tooltip-box:hover .common-shape-tooltip{
visibility: visible;
}
.common-shape-tooltip{
  visibility: hidden;
    width: 200px;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 0;
    padding: 12px 16px;
    opacity: .8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: .3s;
    font-family: "Axiforma-Regular";
}

.common-shape-tooltip::after{
  content: "";
    position: absolute;
    top: -8px;
    right: 25px;
    margin-top: -2px;
    border: 5px solid transparent;
    border-right-color: #000;
    transform: rotate(90deg);
}
.common-img-sixteen-ratio{
  width: 24px;
  height: 14px;
  border: 2px solid #e3e6f1;
}

.common-img-four-ratio{
  width: 20px;
  height: 15px;
  border: 2px solid #e3e6f1;
}
.common-img-one-ratio{
  width: 18px;
  height: 18px;
  border: 2px solid #e3e6f1;
}
.common-img-three-ratio{
  width: 15px;
  height: 20px;
  border: 2px solid #e3e6f1;
}

.common-img-nine-ratio{
  width: 14px;
  height: 24px;
  border: 2px solid #e3e6f1;
}
.common-img-ratio-border-selected{
  border: 2px solid #7782A1;
}
.common-img-ratio-selected{
  font-family: "Axiforma-Regular";
font-size: 11px;
line-height: 14.78px;
text-align: left;
color: #7782A1;
}
.common-img-ratio-unselected{
  font-family: "Axiforma-Regular";
  font-size: 11px;
  line-height: 14.78px;
  text-align: left;
  color: #BCC1CE;
}
/* .common-img-ratio-container{
  display: flex;

  align-items: center;
  cursor: pointer;
} */
.common-img-ratio{
  display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      position: relative;
}
.common-img-ratio-disabled{
 cursor: not-allowed !important;
}
.common-img-ratio-disabled .common-img-ratio-selected{
  font-family: "Axiforma-Regular";
font-size: 11px;
line-height: 14.78px;
text-align: left;
color: #BCC1CE !important;
}

.common-img-ratio-disabled .common-img-ratio-border-selected{
  border: 2px solid #e3e6f1 !important;
  }
  .common-img-ratio-disabled .common-img-ratio-tooltip{
    visibility: hidden !important;
  }
.common-img-ratio-tooltip{
  visibility: hidden;
    width: 200px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 0px;
    padding: 12px 16px 12px 16px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    transition: 0.3s;
    font-family: "Axiforma-Regular";
}
.common-img-ratio-tooltip::after {
  content: "";
  position: absolute;
  top: -8px;
  right: 10px;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}
.common-img-ratio:hover .common-img-ratio-tooltip{
  visibility: visible;

}

.common-locktoggle-switch{
  display: inline-block;
  height: 22px;
  position: relative;
  width: 44px;
  margin-bottom: 0;
}
.common-slider-round{
  background-color: #D7DBEB;
  background-image: url(../../assets/images/dashboard/toggle-lock.png);
  background-size: 8.47px 11.29px;
  background-repeat: no-repeat;
  background-position: bottom 5px left 26px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 34px;
}
.common-slider-round:before{
  background-color: #fff;
  bottom: 3px;
  content: "";
  height: 16px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 50%;

}
.newfeatureicon-div{
  background-color: #E9EEFF;
  width: 32px!important;
  min-width: 32px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
  margin-left: 10px;
}
.newfeaturestar-div{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
  margin-left: 10px;
}
.newfeatureicon-text{
  font-family: "Axiforma-Regular" !important;
  font-size: 9px !important;
  line-height: 12.1px !important;
  color: #3064F9 !important;
  margin: 0px !important;
  padding-bottom: 0px !important;
}
.newfeatureicon-svg{
position: absolute;
right: -3px;
top: -3px;
}

.disabled-upload-field-input{
  border-radius: 3px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 10px;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  position: relative;
  border: 1px solid #E3E6F1 !important;
  background-color: #F8FAFE;
  color: #7782A1 !important;
  cursor: not-allowed;
}
.skip-btn-ui{
  width: 200px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #3064F9!important;
  border-radius: 3px;
  font-family: "Axiforma-Regular" !important;
  font-size: 14px;
  color: #3064F9!important;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skip-btn-ui:hover{
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.skip-btn-ui .newfeatureicon-div{
  margin-left: 6px;
}
.skip-btn-ui .newfeatureicon-div .newfeatureicon-text{
  margin: 0px!important;
}
.upload-image-div{
  width: 36px;
    height: 36px;
    min-width: 36px;
}
.premium-tag{
  display: flex;
  align-items: center;
  background-color: #E8EEFF;
  border-radius: 30px;
  height: 26px;
  padding: 0px 10px;
  position: relative;
  width: max-content;
}
.premium-tag .premium-tag-text{
  font-family: "Axiforma-Regular";
  font-size: 12px;
  line-height: 16.13px;
  color: #3064F9;
  margin: 0px 0px 0px 6px;
}
.premium-tag-icon{
  position: relative;
  top: -1px;
}
.premium-tooltip{
  visibility: hidden;
  width: 135px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  z-index: 1;
  top: 37px;
  padding: 6px 10px 6px 10px;
  opacity: 0.8 !important;
  font-size: 10px;
  line-height: 13.44px;
  display: grid;
  transition: 0.3s;
  right: 0;
  left: -20px;
  margin: auto;
  position: absolute;
  top: 100%; 
  left: 50%; 
  transform: translateX(-50%);
  margin-top: 8px;
  white-space: nowrap;
}
.premium-tag:hover .premium-tooltip{
  visibility: visible;
  text-align: center !important;
}
.premium-tooltip::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  margin-top: -4px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}

@media (max-width: 1200px) {
  .common-cross-img {
    width: 14px;
    height: 14px;
  }
  .common-locktoggle-switch{
    height: 20px;
    width: 36px;
  }
  .common-slider-round{
    background-size: 8.47px 11.29px;
    background-repeat: no-repeat;
    background-position: bottom 5px left 23px;
    border-radius: 34px;
  }
  .common-slider-round:before{
    bottom: 3px;
    height: 14px;
    width: 14px;
    background-position: bottom 5px left 23px;
  
  }








  .common-img-ratio .common-img-ratio-unselected{
    display: none;
  }
  .common-img-ratio .common-img-ratio-selected{
    display: none;
  }
  .web-setting-btn-text{
      display: none;
  }
  .setting-btn-svg-icon{
    width: 26px !important;
    height: 26px !important;
  }
  .common-navigation-setting-btn {
    width: 40px !important;
      min-width: 40px
  }
  .common-navigation-setting-btn-clicked {
      width: 40px !important;
      min-width: 40px
  }
  .common-shape-tooltip{
    right: -21px;
    top: 34px;
  }
  .commonModal .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .commonModal-footer {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .commonModal .modal-body {
    padding: 0px 34px 60px;
  }
  .commonModal-crossmodal {
    padding: 40px 20px 0px 0px;
  }
  .commonModal-cancel-btn {
    height: unset;
    width: unset;
    font-size: 16px;
    border: unset;
  }
  .commonModal-submit-btn-blue {
    height: 50px;
    width: 280px;
    font-size: 16px;
  }
  .commonModal-submit-btn-red {
    height: 50px;
    width: 280px;
    font-size: 16px;
    border: 1px solid #ff4848;
    background-color: #ff4848;
    color: #ffffff;
  }
  .skip-btn-ui {
    width: 100%!important;
    font-size: 16px!important;
    padding: 0px;
    margin: 0px!important;
  }
  .skip-btn-head{
    width: 100%;
  }
  .premium-tag .premium-tag-text{
    display: none;
  }
  .premium-tag {
    padding: 0px 6px;
  }
  .premium-tooltip{
    left: auto;
    right: -4px;
    transform: unset;
  }
  .premium-tooltip::after{
    left: unset;
    right: 12px;
  }
}
