.grantaccess-toggle{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
border-bottom: 1px solid #E3E6F1;
/* padding-bottom: 20px; */
}
.connectivityspinner{
  background-color: white;
  border: 1px solid #E3E6F1;
  height: 78vh !important;
}
.connectmainapidiv{
  display: flex;
    flex-wrap: wrap;
}
.grantaccess-toggle p{
  font-size: 12px;
  color: #BCC1CE;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
  margin-top: 5px;
}
.grantaccess-toggle a img{
  margin-right: 30px;
  padding-bottom: 25px;
  height: 50px;
}
.grantaccess-toggle .active{
  border-bottom: 2px solid #3064F9;
}
.checkboxmainbox {
  position: relative;
  cursor: pointer;
  font-size: 22px;
}

.api_maindiv {
  margin-bottom: 0px !important;
}

.crossicons {
  padding-top: 14px !important;
}
/* Hide the browser's default checkbox */
.checkboxmainbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkboxmainbox:hover input ~ .checkmark {
  background-color: #cccc;
}
.buttonDiv button:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
  color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkboxmainbox input:checked ~ .checkmark {
  background-color: #3064f9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxmainbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxmainbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6.31px;
  height: 11.34px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disable-trobleshoot-btn button {
  background-color: #bcc1ce;
  border: #bcc1ce;
  cursor: not-allowed;
}
.disable-trobleshoot-save-btn button{
  text-decoration: none;
    border-radius: 2px;
    width: 100px;
    height: 40px;
    /* margin-right: 20px; */
    font: 14px "Axiforma-Regular";
    color: white;
    background-color: #bcc1ce;
    border: #bcc1ce;
    cursor: not-allowed;
}
.disable-trobleshoot-btn .next_btn {
  padding: 0px !important;
}

.notificationdiv3 .notificationdiv1 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.eye_apikeys {
  position: absolute;
  top: 12px !important;
  right: 20.7px !important;
}
.word-application-input {
  height: 50px !important;
  font-size: 13px !important;
  margin-top: 10px !important;
  padding: 19px 0px 18px 16px !important;
  border: 1px solid #BCC1CE ;
}



.word-application-input:focus, .word-application-input:hover{
  border: 1px solid #3064f9!important;
    background-color: #f8fafe!important;
    transition: .5s;
}

.word-application-input:disabled{
  border: 1px solid #e3e6f1 !important;
  background-color: #F8FAFE!important;
  color:#7782A1!important;
  cursor: not-allowed !important;
}

.word-application-input:disabled:hover{
  border: 1px solid #e3e6f1 !important;
  background-color: #F8FAFE!important;
  
}


.ste2woo {
  display: none !important;
}
.iconImg2 {
  display: grid;
  margin-top: -9px;
  margin-bottom: 11px;
}
.iconImg2p {
  height: 135%;
}
.iconImg2 label {
  margin-bottom: 0px !important;
  display: flex;
  justify-content: center;
}
.iconImg2 img {
  margin-bottom: 11px;
}
.buttonwidth {
  width: 100px !important;
}
.notificationdiv3 {
  padding: 0px !important;
}
.switch11 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}
.switch11 input[type="checkbox"] {
  accent-color: #3064f9;
  width: 20px;
  height: 20px;
}
.submitapikeys {
  width: 100px !important;
}
.crossmodall {
  top: 0px !important;
}
.checkboxtext h4 {
  margin-left: 28px !important;
  font-size: 13px !important;
  margin-right: 6px !important;
  font-family: 'Axiforma-Regular';
}
.checkboxdiv {
  margin-top: 30px !important;
  display: flex;
}
.checkboxtext div {
  margin-bottom: 0px !important;
}
.getuser {
  padding: 0px 0px 30px 0px !important;
  font-size: 12px !important;
}
.getuser span {
  margin-left: 6px !important;
}
/* .disable-trobleshoot-btn{
    background-color: #BCC1CE !important;
    border-color: #C8CEDB !important;
} */
.stepslabel {
  text-align: center;
}
.getuser p {
  cursor: pointer;
  margin-bottom: 0px !important;
  /* padding: 0px 0px 30px 0px; */
  color: #7782a1;
  border: 1px solid #BCC1CE;
  padding: 12px 31px;
  border-radius: 3px;
  font-size: 13px
}
.formdivheight {
  height: 68px !important;
}
/* .disable-trobleshoot-btn{
    background-color: #BCC1CE;
    color: white;
} */
.uploadquestion {
  padding: 20px 20px 30px 20px !important;
}
.update-woo-plugin1 {
  padding: 25px 60px 30px 60px !important;
}
.wootoggleswitch img{
  margin-right: 0px !important;
}
.conecttoapi1 {
  width: 160px;
  height: 44px;
  border: 1px solid #C8CEDB;
  color: #7782A1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 26px;
}
.connect-underpopup{
  height: 44px;
    width: 160px;
    /* color: white; */
    background-color: #3064F9;
    border-radius: 4px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.connect-underpopup:hover {
  background-color: #134bec;
}
.connect-troubleshootdiv{
  display: flex;
    justify-content: center;
}
.conecttoapi1 a {
  color: #7782A1 !important;
  font-size: 14px;
  font-family: 'Axiforma-Regular';
}
/* .conecttoapi1 a:hover {
  color: white !important;
  background-color: #3064f9 !important;
} */
.disable-trobleshoot-save-btn button:hover, .disable-trobleshoot-btn button:hover {
  box-shadow: 0px 2px 4px rgba(188, 193, 206, 0.69);
}
.divform {
  background-color: white !important ;
}
.iconImg1 {
  margin-top: 18px;
  margin-bottom: 5px !important;
}
.linktroubleshoot {
  font-size: 13px !important;
}
.wronguser h2 {
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 10px !important;
  line-height: 34px;
}
/* .grant {
  max-width: 80%;
} */
.wronguser {
  margin-bottom: 14px !important;
}

.wronguser h3 {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  line-height: 25px;
  margin-bottom: 30px !important;
}

.module1 {
  max-width: 480px;
}

.grantaccess {
  padding: 30px 0px 25px 0px;
}

.grantaccess h3 {
  line-height: 29px !important;
}
.headerofpage {
  padding: 0px !important;
}

.headdiv {
  margin-left: 30px !important;
}

.buttonDiv button {
  max-width: 100% !important;
}

.linkheader {
  margin-left: 4px;
  font-size: 13px;
}

.linkheader:hover {
  color: white;
}

.notificationdiv4 {
  display: flex;
  border: 1px solid #7782a1 !important;
  height: 40px;
  background: #7782a1 !important;
  border-radius: 3px;
  margin: 0px 0px 15px 0px;
}
.notificationdiv4 h6 {
  color: white;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
}
.notificationdiv4 a {
  font-size: 13px !important;
  color: white;
    font-family: 'Axiforma-Regular';
    text-decoration: underline !important;
}
.notificationdiv4 h5 {
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
  color: #e3e6f1;
}

.mainCol {
  padding: 0px !important;
}
.colImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
}


.coll1 {
  /* min-height: 80vh; */
    /* padding-top: 31.5px; */
    padding: 20px 20px 20px 30px !important;
    flex: 0 0 50%;
    max-width: 50%;
}
.coll1 form {
  margin-top: 0px !important;
}

.stepapisecond p {
  padding: 0 4px 1px !important;
}
.stepApi p {
  margin-top: 0;
  font-size: 12px;
  color: #7782a1;
  padding: 0 18px 20px;
  margin-bottom: 0px;
  text-align: center;
  font-family: "Axiforma-Regular";
  line-height: 21px !important;
}

.stepApi {
  border: 1px solid #e3e6f1;
  display: grid;
  place-items: center;
  margin-right: 10px;
  width: 200px;
  height: auto;
  border-radius: 3px;
}

.stepApi label {
  margin-bottom: 20px;
  color: #bcc1ce;
  font-size: 12px;
}

.stepss {
  padding: 0px 30px 25px 30px !important;
}
.stepsswoo {
  padding: 0px 30px 36px 30px !important;
}
.stepsdis {
  color: #bcc1ce;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  line-height: 21px;
  margin-bottom: 0px !important;
}
.step1label {
  display: contents;
}
.pluginname h4 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 20px;
}
.formsbox {
  background-color: white !important;
}
.stepsboxes {
  display: flex;
}
.stepbox1 {
  display: flex;
  margin-top: 10px;
}
.notificationdiv3 {
  display: none !important;
}
.notificationdiv1 img {
  margin-top: -2px;
  margin-right: 8px;
}
.gotosite {
  color: #3064f9 !important;
  cursor: pointer;
  font-size: 12px !important;
}
.recommend {
  margin-right: 20px;
  font-size: 12px !important;
  font-family: "Axiforma-Regular" !important;
  color: #bcc1ce !important;
  margin-left: 0px !important;
}
.formsize {
  padding: 0px 0px 30px 0px !important;
}
.pluggedinornot1 {
  display: none;
}
.gotoappicon1 {
  width: 10px !important;
  margin-left: 6.5px !important;
  margin-bottom: 0px !important;
}
.userpasswordFont h4 {
  font-size: 13px !important;
  font-family: "Axiforma-Regular";
  color: #7782A1;
    position: relative;
}
.secondinputheader {
  margin-top: 21px !important;
}
.vibrate-message {
  animation: squeeze 1s ease-out;
  background: #586077 !important;
  border: 1px solid #586077 !important;
}
/* Faisal changes */
.install-plugin-text-api-key {
  display: flex;
  justify-content: space-between;
}
.underline-hover:hover {
  text-decoration: underline !important;
}
.connectionupdatedatetime{
  font-size: 12px;
  color: #BCC1CE;
  margin: 0px 20px 0px 0px;
}
.connectionsavedisable{
  border: 1px solid #BCC1CE;
  color: #BCC1CE;
}
.connectionsavebtn{
  font-size: 14px;
    font-family: 'Axiforma-Regular';
    border: 1px solid #3064F9;
    color: #3064F9;
    background-color: white;
    margin-right: 20px;
    height: 40px;
    width: 100px;
    border-radius: 3px;
}
.mob-view-api-btns{
  display: none;
}
.modalipad .modal-footer{
  border-top: 1px solid #E3E6F1 !important;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}
.connect-modalokeybtn{
  font-size: 14px;
    border: 1px solid #BCC1CE;
    font-family: 'Axiforma-Regular';
    padding: 12px 0px;
    border-radius: 3px;
    background-color: white;
    color: #7782A1;
    margin: 0px !important;
    width: 89px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connect-gotowebsite{
  font-size: 14px;
    border: 1px solid #3064F9;
    font-family: 'Axiforma-Regular';
    padding: 12px 0px;
    border-radius: 3px;
    background-color: #3064F9;
    color: white;
    margin: 0px !important;
    width: 164px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connect-gotowebsite svg{
  margin-left: 6px;
}
.wronguser p{
  font-size: 13px;
    color: #7782A1;
    margin-bottom: 26px;
    text-align: left !important;

}
.connect-stepsul{
  padding: 0px 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    line-height: 20px;
    /* list-style-type: none;  */
}
.connect-stepsul li span{
  color: #7782A1 !important;
}
.connect-stepsul li {
  margin-bottom: 25px;
  color: #BCC1CE;
}
/* .connect-stepsul li::before {
  content: "\2022";
  color: #BCC1CE;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-right: 10px;
} */
/* .connect-stepsul .child-li-text::before {
  display: none !important;
 
} */
.child-li-text{
  list-style-type: none;
}
.connectmodal-mob-btn{
  display: none;
}
.connect-crossmodal{
  position: absolute;
    right: 15px;
    top: 6px;
    cursor: pointer;
}

/* Faisal changes */
@keyframes squeeze {
  0%,
  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90% {
    transform: translate3d(-6px, 0, 0);
  }
  11%,
  21%,
  31%,
  41%,
  51%,
  61%,
  71%,
  81%,
  91% {
    transform: translate3d(6px, 0, 0);
  }
  92%,
  100% {
    transform: translate3d(0, 0, 0);
  }

 
}

@media (max-width: 1200px) {
  .btn-conn-disable-margin{
    margin-top: 20px;
  }
  .disable-trobleshoot-btn button {
margin: 15px 0px;;
}
  .notificationdiv4 {
    height: auto !important;
    padding: 10px 0px 10px 0px;
    margin: 20px 15px 0px 15px;
  }
  .connectivityspinner{
    margin: 0px 15px;
  }
  .disable-trobleshoot-save-btn button{
    width: 100%;
    height: 50px;
    font: 16px "Axiforma-Regular";
  }
  .connect-crossmodal{
  
    top: 20px;
  
  }
 
  .crossicons {
    padding-top: 30px !important;
}
  .connect-modalokeybtn {
    width: 100%;
    font-size: 16px;
    height: 50px;
    margin: 30px 0px 20px 0px !important;
  }
  .connect-gotowebsite {
    width: 100%;
    font-size: 16px;
    height: 50px;
  }
  .connectmodal-mob-btn{
    display: block;
  }
  .modalipad .modal-footer {
    display: none;
  }
  .stepss {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0px 15px 36px 15px !important;
    max-width: 550px;
}
.stepsswoo {
  justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0px 15px 36px 15px !important;
    max-width: 550px;
}
  .modalipad .modal-content {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px !important;
    align-items: center;
  }
  .modalipad1 .modal-content {
    /* height: auto; */
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px !important;
  }
  .colImage {
    display: none !important;
}
.coll1 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  padding: 20px 0px 20px 0px !important;

}
.grantaccess-toggle {
padding: 0px 15px ;
}
.pluggedinornot1 {
  display: block;
  padding: 0px 15px;
}
.noticealert {
  display: flex;
  margin: 30px 0px;
}
.noticealert p {
  margin-top: 7px;
  color: #bcc1ce;
  font-size: 11px;
  margin-bottom: 0px;
}
.getuser {
  padding: 0px 15px 25px 15px !important;
}
.grantaccess-toggle p {
  display: none;
}
.grantaccess h3 {
  line-height: 24px !important;
  width: calc(100% - 50px);
}
.mob-view-api-btns{
  display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px 15px;
}
.connectionsavebtn {
  width: 100%;
  height: 50px;
  font-size: 16px;
  margin-right: 0px;
  margin-bottom: 20px;
}
.connectionvarifybtn{
  background-color: #3064F9;
  color: white;
  width: 100%;
  height: 50px;
  font-size: 16px;
  margin-right: 0px;
  margin-bottom: 20px;
  font-family: 'Axiforma-Regular';
  border-radius: 3px;
  border: none;
  cursor: pointer !important;
}
.connectionvarifydisable{

}
.connectionupdatedatetime{
  justify-content: center;
    display: flex;
    margin: 20px 0px;
    font-family: 'Axiforma-Regular';
}
.grantaccess {
  padding: 31px 15px 0px 15px;
}
.formsize {
  padding: 0px 15px 30px !important;
}
.update-woo-plugin1 {
  padding: 0px 15px 30px 15px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wronguser h3 {
  margin-bottom: 0px !important;
}
.connect-stepsul li {
  margin-bottom: 10px;
}
.wronguser{
  margin-bottom: 0px !important;
}
.connect-troubleshootdiv {
  flex-direction: column-reverse;
    align-items: center;
}
.conecttoapi1 {
  width: 50px; 
  height: unset;
  border: none;
  margin-top: 36px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.connect-underpopup {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
.conecttoapi1 a {
  font-size: 16px;
}
.connect-troublshootinglink {
  font-size: 16px;
}
}

/* @media (min-width: 768px) and (max-width: 1000px) {
  .notificationdiv4 {
    height: 40px !important;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
    margin-top: 0px !important;
  }
  .dowmloadpluginnotice {
    margin-top: 25px;
  }
} */

/* @media (min-width: 1001px) and (max-width: 1200px) {
  .ste2woo {
    display: none !important;
  }
  .modal_toheader {
    padding-top: 15px !important;
  }
  .top .transparent_background {
    margin-top: 0px !important;
  }
  .headerofpage {
    border-bottom: 1px solid #e3e6f1;
    padding-bottom: 18px !important;
  }
  .notificationdiv3 h6 {
    font-size: 13px;
    line-height: 20px;
  }
  .notificationdiv3 img {
    width: 40px;
  }
  .recommend {
    margin-right: 10px;
  }
  .gotosite {
    color: #3064f9 !important;
    cursor: pointer;
    font-size: 12px !important;
  }
  .notificationdiv4 h5 {
    font-size: 10px !important;
    font-family: "Axiforma-Regular";
    color: #e3e6f1;
  }
  .notificationdiv4 a {
    font-size: 10px !important;
  }
  .notificationdiv4 h6 {
    color: white;
    font-size: 11px !important;
    font-family: "Axiforma-Regular";
  }
  .notificationdiv1 img {
    margin-right: 8px !important;
    width: 16px;
  }
  .update-woo-plugin1 {
    padding: 20px 40px 30px 40px !important;
  }
  .notificationdiv4 {
    height: 40px !important;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
    margin-top: 15px !important;
  }
  .notificationdiv3 {
    display: none !important;
  }
  .linkheader {
    font-size: 12px !important;
  }
 
  .searchicons {
    margin-right: 0px !important;
  }
  .module1 .modal-content {
    height: auto;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 480px;
    border-radius: 6px !important;
  }

  .grantaccess {
    padding: 31px 15px 0px 15px;
  }

  .formsize {
    padding: 15px !important;
  }

  .stepslabel {
    margin-left: 1px;
  }
  .stepslabel {
    text-align: center;
  }
  .steps1label {
    margin-left: 1px;
  }

  .installheader {
    width: 100% !important;
  }
} */
/* 
@media (min-width: 768px) and (max-width: 1000px) {
  .ste2woo {
    display: none !important;
  }
  .modal_toheader {
    padding-top: 15px !important;
  }
  .top .transparent_background {
    margin-top: 0px !important;
  }
  .headerofpage {
    border-bottom: 1px solid #e3e6f1;
    padding-bottom: 18px !important;
  }
  .notificationdiv3 h6 {
    font-size: 13px;
    line-height: 20px;
  }
  .notificationdiv3 img {
    width: 40px;
  }
  .recommend {
    margin-right: 10px;
  }
  .gotosite {
    color: #3064f9 !important;
    cursor: pointer;
    font-size: 12px !important;
  }
  .notificationdiv4 h5 {
    font-size: 10px !important;
    font-family: "Axiforma-Regular";
    color: #e3e6f1;
  }
  .notificationdiv4 a {
    font-size: 10px !important;
  }
  .notificationdiv4 h6 {
    color: white;
    font-size: 11px !important;
    font-family: "Axiforma-Regular";
  }
  .notificationdiv1 img {
    margin-right: 8px !important;
    width: 16px;
  }
  .update-woo-plugin1 {
    padding: 20px 40px 30px 40px !important;
  }
  .notificationdiv4 {
    height: 40px !important;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
    margin-top: 15px !important;
  }
  .notificationdiv3 {
    display: none !important;
  }
  .linkheader {
    font-size: 12px !important;
  }
  
  .modalipad1 .modal-content {
    height: auto;
    width: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .searchicons {
    margin-right: 0px !important;
  }
  .module1 .modal-content {
    height: auto;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 480px;
    border-radius: 6px !important;
  }

  .grantaccess {
    padding: 31px 15px 0px 15px;
  }

  .formsize {
    padding: 15px !important;
  }

  .stepslabel {
    margin-left: 1px;
  }
  .stepslabel {
    text-align: center;
  }
  .steps1label {
    margin-left: 1px;
  }

  .noticealert p {
    margin-top: 5px;
    color: #bcc1ce;
    font-size: 13px;
  }
  .noticealert {
    display: flex !important;
  }
  .noticealert img {
    margin-right: 6px !important;
  }

  .installheader {
    width: 100% !important;
  }
} */

/* @media (min-width: 360px) and (max-width: 767px) {
  .vibrate-mob-notificatindiv{
    display: flex;
  }
  .vibrate-mob-notificatindivimg{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .iconImg2 label {
    display: none !important;
  }
  .toplogos {
    margin-bottom: 26px !important;
  }
  .notificationdiv3 {
    display: block !important;
    border: 1px solid #7782a1 !important;
    height: auto;
    background: #7782a1 !important;
    border-radius: 3px;
    margin: 25px 15px 15px 15px !important;
  }
  .module1 .modal-content .modal-categories {
    padding: 15px !important;
  }
  .recommend {
    font-size: 12px !important;
  }
  .update-woo-plugin1 {
    padding: 20px 40px 30px 40px !important;
  }
  .stepslabel {
    text-align: left;
  }
  .recommend {
    margin-right: 5px;
  }
  .linkheader {
    font-size: 12px !important;
  }
  .notificationdiv3 img {
    min-width: 22px;
    margin-right: 0px !important;
  }
  .notification3 {
    font-size: 12px;
    line-height: 20px;
  }
  .notificationdiv4 {
    display: none !important;
  }
  .notificationdiv3 h5 {
    font-size: 12px !important;
    font-family: "Axiforma-Regular";
    color: #e3e6f1;
    margin-top: 2px !important;
  }

  .module1 .modal-content {
    height: auto;
    position: fixed;
    max-width: 388px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px !important;
  }
 
  .rightdivv {
    box-shadow: 0px 0px 0px 0px !important;
  }
  .mobileimg {
    display: flex;
    align-items: center;
  }

  .noticealert {
    display: flex;
  }
  .noticealert p {
    margin-top: 5px;
    color: #bcc1ce;
    font-size: 13px;
  }
  .noticealert img {
    margin-right: 6px !important;
  }


  .grantaccess {
    padding: 31px 15px 0px 15px;
  }


  .formwoo {
    padding: 15px 15px 30px !important;
  }
  .formwoo h4 {
    font-size: 13px !important;
  }

  .gotosite {
    color: #3064f9 !important;
    cursor: pointer;
    font-size: 12px !important;
  }

  .stepsboxes {
    display: grid;
  }
  .stepbox1 {
    display: grid;
    margin-top: 0px;
  }
  .stepApi {
    border: 1px solid #e3e6f1;
    display: flex;
    place-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 100px;
    border-radius: 3px;
  }
  .stepwoowheight {
    height: 80px !important;
  }
  .stepApi p {
    text-align: left !important;
  }
  .stepApi label {
    margin-bottom: 15px !important;
  }
  .step1label {
    display: grid;
    padding-right: 20px;
  }

  .stepslabel {
    display: grid;
    margin-left: 0px;
  }
  .stepApi p {
    font-size: 12px;
    padding: 0px;
    line-height: 21px;
  }

  .modalipad1 .modal-content {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px !important;
  }
  .stepapi_smodal {
    height: 142px !important;
  }
  .stepapi_smodal .stepslabel {
    padding-right: 20px;
  }
  .wronguser h2 {
    font-size: 18px;
    font-family: "Axiforma-Bold";
    line-height: 28px;
  }
  .conecttoapi1 {
    margin-bottom: 30px !important;
  }
  .wronguser {
    margin-bottom: 0px !important;
  }
  .stepApi label {
    font-size: 12px;
    color: #bcc1ce;
  }
  .stepsdis {
    color: #bcc1ce;
    font-size: 12px;
    margin-top: 5px !important;
    font-family: "Axiforma-Regular";
    line-height: 20px;
    margin-bottom: 0px;
  }
  .stepss {
    padding: 0px 20px 21px 20px !important;
    overflow-y: auto;
  }
 
  .iconImg1 {
    margin-top: 0px;
    width: 60px;
    margin-bottom: 0px !important;
    min-width: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .iconImg1 img {
    margin-left: 0px;
  }

  .notificationdiv3 h6 {
    font-size: 13px;
    line-height: 20px;
    font-family: "Axiforma-Regular";
    line-height: 24px !important;
  }

  .wronguser {
    margin-bottom: 0px !important;
  }
  .iconImg2 img {
    margin-bottom: 0px !important;
  }
  .iconImg2 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .eye {
    right: 15.2px !important;
  }
  .ste2woo {
    display: block !important;
  }
 
} */

/* @media (min-width: 250px) and (max-width: 359px) {
  .vibrate-mob-notificatindiv{
    display: flex;
  }
  .vibrate-mob-notificatindivimg{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .ste2woo {
    display: block !important;
  }
  .notificationdiv3 {
    display: block !important;
    border: 1px solid #7782a1 !important;
    height: auto;
    background: #7782a1 !important;
    border-radius: 3px;
    margin: 0px 0px 0px 0px !important;
  }
  .toplogos {
    margin-bottom: 26px !important;
  }

  .update-woo-plugin1 {
    padding: 10px 20px 20px 20px !important;
  }
  .update-woo-plugin1 h2 {
    line-height: 15px !important;
  }
  .stepslabel {
    text-align: left;
  }
  .recommend {
    margin-right: 5px;
    font-size: 9px !important;
  }

  .woodropimg {
    width: 15px !important;
  }
  .notificationdiv3 img {
    min-width: 22px;
    margin-right: 0px !important;
  }
  .notificationdiv3 h6 {
    font-size: 12px;
    line-height: 20px;
  }
  .linkheader {
    font-size: 10px !important;
  }
  .notificationdiv4 {
    display: none !important;
  }
  .notificationdiv3 h5 {
    font-size: 11px !important;
    font-family: "Axiforma-Regular";
    color: #e3e6f1;
  }
  .notification3 {
    font-size: 10px;
    line-height: 15px;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .module1 .modal-content {
    height: 326px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px !important;
  }

  .rightdivv {
    box-shadow: 0px 0px 0px 0px !important;
  }
  .mobileimg {
    display: flex;
    align-items: center;
  }
 
  .noticealert {
    display: flex;
  }
  .noticealert p {
    margin-top: 5px;
    color: #bcc1ce;
    font-size: 11px;
  }

 
  .grantaccess {
    padding: 31px 15px 0px 15px;
  }

  .formwoo {
    padding: 15px 15px 30px !important;
  }
  .formwoo h4 {
    font-size: 9px !important;
  }

  .gotosite {
    color: #3064f9 !important;
    cursor: pointer;
    font-size: 9px !important;
  }

  .stepsboxes {
    display: grid;
  }
  .stepbox1 {
    display: grid;
    margin-top: 0px;
  }
  .stepApi {
    border: 1px solid #e3e6f1;
    display: flex;
    place-items: center;
    margin-bottom: 4px;
    width: 100%;
    height: 50px;
    border-radius: 3px;
  }
  .step1label {
    display: grid;
  }
  .stepApi p {
    font-size: 8px;
    padding: 0px;
    text-align: left !important;
  }
 
  .modalipad1 .modal-content {
    height: auto;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px !important;
  }
  .wronguser h2 {
    font-size: 18px;
    font-family: "Axiforma-Bold";
    line-height: 28px;
  }
  .stepApi label {
    font-size: 10px;
    color: #bcc1ce;
    margin-bottom: 0px !important;
  }
  .stepsdis {
    color: #bcc1ce;
    font-size: 10px;

    font-family: "Axiforma-Regular";
  }
  .stepss {
    padding: 0px 20px 20px 20px !important;
  }

  .iconImg1 {
    margin-top: 0px;
    width: 34px;
  }


  .stepapi_smodal {
    height: 80px !important;
  }
  .stepapi_smodal .stepslabel {
    padding-right: 10px !important;
  }
  .iconImg2 label {
    display: none !important;
  }
  .iconImg2 img {
    margin-bottom: 0px !important;
  }
  .iconImg2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .eye {
    right: 15.2px !important;
  }
  .conecttoapi1 {
    margin-bottom: 30px !important;
  }
} */
