@font-face {
  font-family: "Proxima-Extrabold";
  src: url(../fonts/ProximaNova-Extrabold.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../fonts/ProximaNova-Bold.otf);
}
@font-face {
  font-family: "Proxima-Regular";
  src: url(../fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Thin";
  src: url(../fonts/ProximaNova-Thin.otf);
}
@font-face {
  font-family: "Proxima-Black";
  src: url(../fonts/ProximaNova-Black.otf);
}

/* Axiforma */
@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.ttf);
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}
/* New Font Axiforma */

body {
  font-family: "Axiforma-Regular";
}
html {
  height: 100%;
}
#root {
  height: 100% !important;
}
.fixposition .container {
  max-width: 100%;
  width: 75%;
}
header .container {
  max-width: 100%;
  width: 98%;
}
a:hover {
  text-decoration: none !important;
}

.back-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signhead {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}
.signupcontent h3 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";

  margin-bottom: 0px;
}
header {
  background: #f8fafe;
}

.signupcontent h5 {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 4px;
  margin-bottom: 0px;
}
.signupcontent p {
  color: #333333;
  font-size: 18px;
  font-family: "Axiforma-Regular";
  margin-top: 20px;
}
.height100 {
  height: calc(100% - 80px);
}
.create-account {
  max-width: 1080px;
  margin: 0 auto;
}
.create-account .footer {
  position: relative;
}

.error p {
  margin-bottom: 0px;
}
.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}
.signup_left {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-group input {
  height: 56px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border: 1px solid #e3e6f1;
  outline: none !important;
  color: #1b2952;
}
.form-group select {
  background-image: url(../images/signup/down.png);
  background-repeat: no-repeat;
  background-position: 96%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular";

  border: 1px solid rgba(188, 199, 230, 1);
  height: 60px;
  border-radius: 0.25rem;
  width: 100%;
  padding-left: 1.25rem !important;
  outline: none !important;
  color: #1b2952;
}
option {
  font: -moz-pull-down-menu;
  font-size: 14px;
}
.form-group select:invalid {
  color: #7782a1;
}
.form-group select:focus {
  color: #1b2952;
  background-color: #fff;
  border-color: #e3e6f1;
  outline: none !important;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  font-size: 14px;
  padding: 18px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  font-family: "Axiforma-Regular";
}
.form-group input::placeholder {
  color: #bcc1ce !important;
  font-family: "Axiforma-Regular";
}
.form-control:focus {
  color: #1b2952;
  background-color: #fff;
  border-color: #e3e6f1 !important;
  outline: none !important;
  box-shadow: none !important;
}
.signup_left .form-control {
  padding: 15px 1.25rem;
}
.sendbtn {
  background-color: #3064f9;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 56px !important;
  line-height: 56px;
  padding: 0;
  border: 1px solid #3064f9;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.5s ease;
}
.backbtn {
  background-color: #fff;
  color: #3064f9;
  border-radius: 4px;
  width: 48%;
  height: 60px;
  line-height: 60px;
  border: 1px solid #3064f9;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.5s ease;
}
.backbtn:hover {
  color: #3064f9;
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.sendbtn:hover {
  color: white;
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.loginbtn {
  background-color: #3064f9;
  color: #fff;
  border-radius: 4px;
  width: auto;
  height: 60px;
  line-height: 60px;
  border: 1px solid #3064f9;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.5s ease;
  width: 100%;
}
.stackcommerce-login-btn{
  background: #0AAFC7;
   border-color: #0AAFC7;
}
.stackcommerce-login-btn-spinner{
  width: 27px; 
  height: 27px
}
.eye {
  cursor: pointer;
}
.loginbtn:hover {
  color: white;
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.tc h6 {
  color: #c8cedb;
  padding: 5px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-align: center;
  line-height: 18px;
}
.tc a {
  text-decoration: none;
}
.already h5 {
  color: #333;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.error p {
  margin-bottom: 0px;
  color: #ff4848;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
  margin-top: 0px;
}

.already a {
  text-decoration: none;
}
.socialoptions p {
  color: #333333;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.socialoptions img {
  cursor: pointer;
}
.socialoptions .fb-login-logo{
  max-width: 40px;
  margin-left: 10px;
}
.nsm7Bb-HzV7m-LgbsSe{
  border: 1px solid #EAECF5!important;
  border-radius: 1px!important;
  min-height: 54px !important;
  margin-right: 5px!important;
  height: 54px!important;
}
.nsm7Bb-HzV7m-LgbsSe.jVeSEe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK
{
  height: 54px!important;
  min-height: 54px !important;
}
.S9gUrf-YoZ4jf iframe {
  height: 56px!important;
}
.S9gUrf-YoZ4jf
{
  margin-right: 5px!important;
}
.S9gUrf-YoZ4jf iframe {
  height: 56px!important;
}
.eye {
  position: absolute;
  top: 16px;
  right: 18px;
}
.signhead_new {
  padding: 13px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_icon .dropdown-toggle::after {
  color: white;
}
.signup_form {
  text-align: left;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.forgot_pw {
  margin-top: 25px;
  margin-bottom: 5px;
}
.white-bg {
  background-color: #fff;
  width: 700px;
  border: none;
  border-radius: 5px;
  box-shadow: 3px 10px 25px 1px rgb(0 0 0 / 9.5%);
}
.socialoptions a:hover {
  text-decoration: none;
}

.appmysite-certificate {
  width: calc(100% - 540px);
  padding-right: 124px;
}
.latest-signup {
  width: 540px;
}

.appmysite-certificate h1 {
  font-size: 30px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  line-height: 40px;

  margin-bottom: 39px;
}

.appmysite-certificate h1 strong {
  font-weight: 700;
  font-family: "Axiforma-Bold";
}

ul.grid-listing {
  list-style: none;
  margin-bottom: 74px;
}
.grid-listing li {
  position: relative;

  margin-bottom: 35px;
}
.grid-listing li:last-of-type {
  margin-bottom: 0;
}
.grid-listing li::before {
  position: absolute;
  content: "";
  background-image: url("../images/signup/grey-tick.png");
  width: 23px;
  height: 23px;
  left: -35px;
  top: 0%;
  background-repeat: no-repeat;
}
.grid-listing li p {
  font-size: 14px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
}
.grid-listing li h6 {
  line-height: 21px;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 7px;
}

.certificate h6 {
  font-size: 13px;
  color: #bcc1ce;
  line-height: 17px;
  margin-bottom: 30px;
}

.certi-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.loginbtn.darkshade {
  background-color: #bcc1ce;
  border-color: #bcc1ce;
  box-shadow: none;
  border: 1px solid #bcc1ce;
}
.signuppage {
  position: relative;
  min-height: calc(100vh - 80px);
  background-color: #f8fafe;
}
.signuppage .container {
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
}
.signinpage {
  position: relative;
  min-height: calc(100vh - 80px);
  background-color: #f8fafe;
}
.signinpage .container {
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
}

.branding1 {
  position: absolute;
  top: -80px;
  bottom: 0;
  right: 0;
  margin: auto;
}
.branding2 {
  position: absolute;
  top: -80px;
  bottom: 0;
  left: 0;
  margin: auto;
}
.loginbtn {
  margin-top: 20px;
}

/*Sign Up Page Ends*/

/*Sign in Page Starts*/

.forgot_pw label {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 20px;
}
.container1 {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 3px;
}
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0px;
  width: 0px;
}
.checkmark {
  position: absolute;
  border: 1px solid #bcc7e6;
  border-radius: 3px;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
}
.container1:hover input ~ .checkmark {
  background-color: #ccc;
}
.container1 input:checked ~ .checkmark {
  background-color: #fff;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container1 input:checked ~ .checkmark:after {
  display: block;
}
.container1 .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #547df2;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.back-home a {
  text-decoration: none;
  margin-bottom: 0px;
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  font-size: 15px;
}
.back-home a:hover {
  color: #c8cedb;
}
.forgot_password a {
  text-decoration: none;
  margin-bottom: 0px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
}
/*Sign in Page Ends*/

/*Forgot Password Starts*/

.two-btn {
  text-align: center;
}
.forgot-content {
  justify-content: center;
}
.forgot-content img {
  display: none;
}

a.backlink {
  font-size: 14px;
  display: inline-block;
  color: #7782a1;
  font-family: "Axiforma-Regular";

  margin-top: 35px;
}

.cus-mb-3 {
  margin-bottom: 20px !important;
}
.signinoption p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.signinoption p a {
  color: #3064f9;
}
.signinmodule .login-help .btn {
  padding-bottom: 0;
  padding-top: 0px !important;
  margin-left: 40px;
  padding: 0px;
}
.signinpage .main-reset-body-color {
  background-color: #f8fafe !important;
}
.inputerror {
  border: 1px solid #ffbaba !important;
  background-color: #fffcfc !important;
}
.integrationpages {
  min-height: 100vh;
  background-color: #f8fafe;
}
.integrationsignup {
  position: relative;
  padding-top: 80px;
}
/* .integrationsignup .signup_left {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
} */
.integrationsignup .white-bg {
  width: 540px;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 12%);
}
.integrationsignup .white-bg .form-group {
  margin-bottom: 20px;
}
.integrationheader .appsumologo {
  width: 330px;
}
.stackcommerce {
  width: 400px !important;
}
.integrationheader .stackcommerce img {
  width: 400px !important;
}
.appsumosignup button {
  color: #272727;
}
.integrationpages h3 {
  margin-bottom: 30px;
}
.resetpasswordhead {
  margin-bottom: 30px;
}
.progressbar{
  margin-bottom: 18px;
}
.woocommerce-page-spinner{
 width: 27px;
 height: 27px;
 position:unset 
}
.woocommerce-login-btn{
  background: #7F54B3;
  border-color: #7F54B3
}
.woocommerce-page-div-body{
  position: relative;
}
.progressbar p {
  color: #bcc1ce;
  font-size: 11.5px;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
  display: none;
}
.progressbar p span {
  color: #1b2952;
}
.progressbar div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progressbar div progress {
  margin-left: 0px !important;
  background-color: #e3e6f1 !important;
  height: 3px;
  margin-right: 5px;
  width: 25%;
}
.progressbar div progress:last-child {
  margin-right: 0px !important;
}
.redprogress::-webkit-progress-value {
  background: #FF0000;
}
.orangeprogress::-webkit-progress-value {
  background: #FF8426;
}
.lightgreenprogress::-webkit-progress-value {
  background: #FFC814;
}
.greenprogress::-webkit-progress-value {
  background: #5CCE7B;
}
.signin-error{
  background-color: #FFECEC;
  padding: 14px 16px;
  margin-bottom: 20px;
  border: 1px solid #E3E6F1;
  border-radius: 3px;
}
.signin-error p{
  color: #FF4848;
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 22px;
}
.google-disabled{
  cursor: not-allowed!important;
}
.two-factor-links p{
  margin-top: 40px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
  font-size: 14px;
}
.two-factor-links a , .two-factor-links a:hover{
  margin-top: 0px;
  color: #3064F9;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  font-size: 14px;
}
.getstarted-page{
  margin-top: 250px;
}


@media (min-width: 1537px) and (max-width: 1700px) {
  .signinpage .container {
    position: relative;
    top: 80px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .integrationpages h3 {
    margin-bottom: 15px;
  }
  .signinpage .container {
    position: relative;
    top: 80px;
  }
}

@media (min-width: 1000px) {
  .back-home a {
    margin-left: 40px;
  }
  .login-help .btn {
    padding: 0px;
    padding-top: 0px !important;
    margin-left: 40px;
  }
}
@media (min-width: 900px) and (max-width: 1366px) {
  .signinpage .container {
    position: relative;
    top: 80px;
  }
  .signinpage .container.container-reset {
    position: absolute;
    top: 0;
  }
  .appmysite-certificate {
    display: none;
  }
  .latest-signup {
    margin: auto;
    margin-top: 80px;
  }
  .woocommercepage
  {
    height: 100%!important;
  }
}
@media (max-width: 767px) {
  .signinmodule .login-help .btn {
    margin-left: 26px;
  }
  .tc h6 {
    font-size: 12px;
    line-height: 18px !important;
    color: #bcc1ce;
  }
  .signinpage .container {
    position: initial;
  }
  .integrationsignup .white-bg {
    width: 90%;
    box-shadow: none;
  }
  .integrationsignup {
    position: inherit;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .integrationsignup .signup_left {
    position: inherit;
  }
  .integrationheader .appsumologo {
    width: 200px;
  }
  .integrationheader .appsumologo .back-home {
    display: none;
  }
  .stackcommerce {
    width: 200px !important;
  }
  .integrationheader .stackcommerce img {
    width: 200px !important;
  }
}
@media (max-width: 320px) {
  .customcheck > div > div {
    width: 280px !important;
  }
  .forgot_pw {
    margin-top: 25px;
    margin-bottom: 25px;
    flex-wrap: wrap;
  }

  .customcheck .rc-anchor-normal .rc-anchor-content {
    width: 180px !important;
  }
  .signupcontent h3 {
    font-size: 16px !important;
  }
  .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
    font-size: 10px;
    
  }
}
