.customer-head {
  justify-content: space-between;
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;

}
.customer-listing {
  background-color: #f8fafe;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.customers-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customers-head-title {
  color: #1b2952;
  font-size: 24px;
  line-height: 33.72px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.customers-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
}
.customer-sorting-visible {
  margin-left: 8px;
  visibility: visible;
  margin-bottom: 3px;
}
.customer-filterapplyed-reddot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #f8fafe;
  position: absolute;
  background-color: #ff4848;
  top: 3px;
  right: 2px;
}
.customers-tooltip .customers-tooltip-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -4px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.customers-tooltip:hover .customers-tooltip-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.customers-tooltip .customers-tooltip-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.customer-page-search .customer-page-search-input {
  width: 300px;
  height: 40px;
  border: 1px solid #e3e6f1;
  padding: 0px 34px 0px 40px;
  font-size: 14px;
  border-radius: 2px 0px 0px 2px;
  font-family: "Axiforma-Regular";
  box-shadow: 0px 3px 6px 0px #bcc1ce33;
  color: #1b2952;
}
.customer-page-search {
  position: relative;
}
.customer-page-search .customer-page-search-icon {
  position: absolute;
  left: 14px;
  top: 11px;
}
.customer-page-search-clear {
  position: absolute;
  right: 6px;
  top: 10px;
}
.customer-page-search .customer-page-search-input:focus {
  border: 1px solid #e3e6f1;
  outline: none;
}
.no-customer-found {
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  height: calc(100% - 75px);
  margin-top: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 284px;
  min-height: 775px;
}
.no-customer-found-content {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18.82px;
  color: #7782a1;
  margin-top: 31px;
}
.customer-table {
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  height: calc(100vh - 176px);
  margin-top: 20px;
  overflow: hidden;
  overflow-y: auto;
}
.customer-table-head {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #e3e6f1;
}
.customer-table-head .customer-table-heading {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  line-height: 16.13px;
  color: #bcc1ce;
  margin: 0px;
}
.customer-sorting-aero {
  margin-left: 8px;
  visibility: hidden;
  margin-bottom: 3px;
}
.customer-table-head-one {
  width: 22.89%;
  padding-left: 20px;
  cursor: pointer;
}
.customer-table::-webkit-scrollbar{
  width: 2px !important;
}
.customer-table-head-one:hover .customer-sorting-aero {
  visibility: visible;
}
.customer-table-head-two {
  width: 27.7%;
  cursor: pointer;
}
.customer-table-head-two:hover .customer-sorting-aero {
  visibility: visible;
}
.customer-table-head-three {
  width: 22.89%;
  cursor: pointer;
}

.customer-table-head-three:hover .customer-sorting-aero {
  visibility: visible;
}
.customer-table-head-four {
  width: 22.89%;
  cursor: pointer;
}

.customer-table-head-four:hover .customer-sorting-aero {
  visibility: visible;
}
.customer-table-head-five {
  width: 6.04%;
  cursor: pointer;
}

.customer-table-head-five:hover .customer-sorting-aero {
  visibility: visible;
}
.customer-table-body-one {
  width: 22.89%;
  padding-left: 20px;
}
.customer-table-body-two {
  width: 27.7%;
}
.customer-table-body-three {
  width: 22.89%;
}
.customer-table-body-four {
  width: 22.89%;
}
.customer-table-body-five {
  width: 6.04%;
}
.customer-name {
  color: #1b2952;
  font-size: 16px;
  line-height: 19.67px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.customer-id {
  color: #7782a1;
  font-size: 12px;
  line-height: 16.13px;
  font-family: "Axiforma-Regular";
  margin: 6px 0px 0px 0px;
}
.customer-table-entry {
  min-height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e6f1;
  transition: 0.5s;
}
.customer-table-entry:hover {
  background-color: #f8fafe;
  transition: 0.5s;
}
.customer-email {
  color: #1b2952;
  font-size: 14px;
  line-height: 18.82px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.customer-last-login {
  color: #1b2952;
  font-size: 14px;
  line-height: 18.82px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.customer-created {
  color: #1b2952;
  font-size: 14px;
  line-height: 18.82px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.customer-active-status {
  color: #52cb86;
  font-size: 14px;
  line-height: 18.82px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.customer-blocked-status {
  color: #ff4848;
  font-size: 14px;
  line-height: 18.82px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.block-unblock {
  color: #3064f9;
  font-size: 12px;
  line-height: 16.13px;
  text-decoration: none;
  display: none;
  margin: 10px 0px 0px 0px;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
}
.block-unblock:hover {
  color: #3064f9;
}
.customer-table-entry:hover .block-unblock {
  display: block;
  transition: 0.5s;
}
.customer-table-mobile {
  display: none;
}
.customers-head-right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.customer-filter-icon {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
  position: relative;
}
.pagination-spinner svg{
  height: 100px;
}
.customer-screen-loader{
  overflow: hidden;
  min-height: 81vh;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}
/* @media (min-width: 1201px) and (max-width: 1480px) {
  .no-customer-found {
    padding-top: 150px;
  }
} */

@media (max-width: 1200px) {
  .customer-screen-loader {
    margin: 0px 15px 15px;
    min-height: 78vh;
  }
  .customer-serach-filter-screenloader{
    min-height: 69vh;
  }
  .block-unblock {
    display: block;
  }
  .customer-head {
    flex-direction: column;
  }
  .customer-page-search {
    width: 100%;
  }
  .customers-head-right {
    width: 100%;
    padding: 15px 15px 0px;
    gap: 10px;
  }
  .customer-filter-icon {
    height: 44px;
    width: 44px;
    max-width: 44px;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.2), 0 0 3px 3px #fff;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid #e3e6f1;
  }
  .customer-filterapplyed-reddot {
    top: 9px;
    right: 8px;
  }
  .customer-filter-icon-select {
    box-shadow: inset -3px -3px 3px #fff, inset 3px 3px 3px rgba(0, 0, 0, 0.16);
  }
  .customer-table {
    display: none;
  }
  .customers-head {
    display: block;
    padding: 20px 15px 0px 15px;
  }
  .customer-page-search .customer-page-search-input {
    width: 100%;
    height: 44px;
  }
  .customers-head-title {
    font-size: 20px;
    line-height: 28.1px;
    margin: 0px 0px 20px 0px;
  }
  .customer-page-search-clear {
    right: 10px;
    top: 12px;
  }
  .no-customer-found {
    background-color: transparent;
    border-radius: 6px !important;
    border: none !important;
    box-shadow: none !important;
    height: calc(100% - 75px);
    margin-top: 0px;
    padding-top: unset;
    align-items: center;
    margin: 0px 15px 0px 15px;
    height: calc(100% - 61px);
    min-height: unset;
    padding-bottom: 60px;
  }
  .no-Search-found{
    height: calc(100% - 120px) !important;
  } 
  .customer-entry-section-one {
    padding: 0px 15px;
    min-height: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .customer-table-mobile {
    display: block;
    margin: 0px 15px 15px 15px;
  }
  .customer-mobile-entry {
    background-color: #fff;
    border: 1px solid #e3e6f1;
    border-radius: 6px;
    margin-top: 15px;
  }
  .customer-entry-head {
    color: #bcc1ce;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .customer-entry-name {
    color: #1b2952;
    font-size: 14px;
    line-height: 19.67px;
    font-family: "Axiforma-Bold";
    margin: 6px 0px;
  }
  .customer-entry-id {
    color: #7782a1;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .customer-entry-datail {
    color: #1b2952;
    font-size: 14px;
    line-height: 19.67px;
    font-family: "Axiforma-Regular";
    margin: 6px 0px 0px 0px;
  }
  .customer-entry-section-two {
    min-height: 81px;
    border-top: 1px solid #e3e6f1;
    padding: 0px 15px;
    display: flex;
    align-items: center;
  }
  .customer-entry-section-three {
    min-height: 81px;
    border-top: 1px solid #e3e6f1;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .block-unblock-mob,
  .block-unblock-mob:hover,
  .block-unblock-mob:focus {
    color: #3064f9;
    font-size: 12px;
    line-height: 16.13px;
    text-decoration: none;
    margin: 0px;
    font-family: "Axiforma-Regular";
  }
  .customers-tooltip .customers-tooltip-tooltipsubheader {
    width: 160px;
    padding: 10px 15px 10px 16px;
  }
  .customers-tooltip .customers-tooltip-tooltipsubheader::after {
    top: 18px;
  }
}
