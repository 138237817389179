.product-main-spinner {
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.product-main-spinner .newspinner {
  border-radius: 6px;
}
.product-setting-tophead {
  padding-bottom: 19px;
  border-bottom: 1px solid #e3e6f1;
}
.nottoggle-product-svg {
  stroke: #e3e6f1;
  z-index: 1;
}
.toggle-product-svg {
  stroke: #7782a1;
  z-index: 1;
}
.product-togglegrid svg {
  cursor: pointer;
}
.product-setting-tophead-text {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 8px;
}
.product-setting-tophead-subtext {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: left !important;
}
.productsetting-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px !important;
}
.productsetting-tooltip .productsetting-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: 30px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  text-align: left !important;
  transition: 0.3s;
  margin-bottom: 0px !important;
}
.productsetting-tooltip:hover .productsetting-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.productsetting-tooltip .productsetting-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.setting-productdiv {
  min-height: 80vh;
  background-color: white;
  border: 1px solid #e3e6f1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
}
.product-setting-left {
  width: 50%;
  padding: 20px 15px 20px 30px;
  height: 80vh;
  overflow: auto;
  overflow-x: hidden;
}
.product-setting-left .full-page-tooltip .full-page-tooltipsubheader {
  width: 170px;
  padding: 6px 10px;
}
.bagde-icon-color-tag .full-page-tooltip .full-page-tooltipsubheader {
  top: 27px;
  left: -103px;
}
.bagde-icon-color-tag .full-page-tooltip .full-page-tooltipsubheader::after {
  top: -8px;
  right: 33%;
  left: unset !important;
  border-color: transparent transparent black transparent;
}
.product-back-icon {
  width: 15px !important;
}
.productpreview-filter {
  position: relative;
}
.product-filter-icon {
  vertical-align: unset !important;
}
.product-setting-left::-webkit-scrollbar {
  width: 2px !important;
}
.pro-first-screen-scroll {
  max-height: 643px;
  overflow: auto;
  background-color: #f9f9f9;
}

.pro-first-screen-scroll::-webkit-scrollbar {
  width: 0px !important;
}

.product-list-head-maindiv {
  padding: 0px;
  list-style-type: none;
  padding: 30px 0px 0px;
  border-bottom: 1px solid #e3e6f1;
}

.product-list-head-text {
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 31px;
}

.product-list-listtag {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.product-list-listtag .menu-crosssvg {
  top: 50px;
}
.product-dropdown-label {
  margin-bottom: 10px !important;
}
.product-list-listtag .setting-enable {
  margin-bottom: 30px;
  text-align: left !important;
}
.product-setting-left .consent-tooltip {
  margin-bottom: 0px !important;
}
.product-setting-right {
  width: 50%;
  padding: 20px 0px;
}
.product-color-picker {
  display: flex;
  justify-content: space-between;
}
.product-color-picker .setting-enable {
  margin-bottom: 10px !important;
  text-align: left !important;
}
.productsetting-tophead-div {
  display: flex;
  justify-content: space-between;
}
.product-color-picker .product-list-listtag {
  display: grid;
  width: 50%;
  justify-content: normal;
  position: relative;
}
.product-color-picker .product-list-listtag:first-child {
  margin-right: 20px;
  position: relative;
}
.product-color-picker .color_input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: 0.5s !important;
  margin-bottom: 30px;
}
.productdetails-ul {
  border-bottom: none !important;
}
.product-color-picker .color_input:hover {
  background-color: #f8fafe !important;
  border-color: #3064f9 !important;
  transition: 0.5s !important;
}
.product-mob-previewbtn {
  display: flex;
  justify-content: center;
  height: 32px;
  margin-bottom: 20px;
}
.product-mob-previewtopback {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 20px;
}
.product-mob-previewtopnext {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
  /* margin-right: 30px; */
  margin-bottom: 20px;
  cursor: pointer;
}
.product-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13.5px 10px;
  border-bottom: 1px solid #e4e4e4;
  background-color: #ffff;
}
.product-preview-header-maintag {
  display: flex;
  margin-bottom: 0px;
}
.product-preview-header-text {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin-left: 11px;
  color: #333333;
  line-height: 16px;
}
.product-multi-img {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  bottom: 8px;
  margin-bottom: 0px;
}
.product-alignmenttoggle .product-filterswitch {
  width: 28px;
  height: 13px;
  background-color: #6f6f6f;
  border-radius: 50px;
  color: #ffff;
  font-size: 8px;
  padding: 0px 0px 0px;
}
.product-preview-header-secondtag {
  margin-bottom: 0px;
  line-height: 13px;
  position: relative;
  display: flex;
  gap: 10px;
}
.productsetting-tophead-div .connectionupdatedate {
  margin: 0px 0px;
}

.StarRatingIcon-icon,
.product-default-img {
  width: auto !important;
}
.product-details-right {
  gap: 10px;
}
.product-preview-header-secondtag svg {
  margin-left: 16px;
}
.product-flex-preview-list-page ul {
  padding: 0px;
  list-style-type: none;
  display: grid;
  border: 1px solid #ebebeb;
  /* border-radius: 10px; */
  background-color: #ffffff;
}
.product-preview-list-page ul {
  padding: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  background-color: #ffff;
}
.product-detailslist .product-preview-prdetails {
  border: unset;
  /* border-radius: unset; */
  padding: 5px 8px 6px;
}
.product-preview-list-page {
  padding: 0px 5px 8px;
  /* border-bottom: 1px solid #F5F5F5; */
}
.product-preview-prdetails {
  display: grid !important;
}
.product-preview-img {
  width: 100px;
  height: 80px;
  background-color: #f2f2f2;
  position: relative;
}
.product-img-head {
  margin-bottom: 0px;
  line-height: 13px;
  justify-content: space-between;
  padding: 5px;
  position: relative;
}
.product-wishlist {
  margin-bottom: 0px;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  bottom: 6px;
}
.product-preview-list-page .product-wishlist {
  bottom: unset;
}
.product-percentage {
  margin-bottom: 0px;
  height: 13px;
  width: 13px;
  background-color: red;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* right: 5px; */
}
.product-percentage-with-text {
  margin-bottom: 0px;
  height: 13px;
  width: 25px;
  background-color: red;
  display: flex;
  align-items: center;
  position: absolute;
  /* right: 5px; */
  font-size: 8px;
  border-radius: 3px;
  justify-content: center;
}
.product-percentage-text-div {
  position: absolute;
  top: 0px;
}
.grid-add-tocart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.product-in-stock {
  margin-bottom: 0px;
  font-size: 7px;
  color: #4ab170;
}
.product-out-of-stock {
  margin-bottom: 0px;
  font-size: 7px;
  color: #ff4d5f;
}
.grid-product-detailslist {
  width: calc(100% - 100px);
}
.webview-preview-head {
  height: 45px;
  padding: 13px;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
}
.product-webview-preview {
  background-color: #f9f9f9;
}
.filter-related-post-disable {
  pointer-events: none !important;
}
.filter-related-post-disable .profile-role-dropdown__single-value {
  color: #c8cedb !important;
}
.filter-related-post-disable .profile-role-dropdown__control {
  border: 1px solid #e3e6f1 !important;
  background-color: #f8fafe !important;
  cursor: not-allowed !important;
}
.product-percentage-text-div p {
  font-size: 8px;
  margin-bottom: 0px;
  top: 2px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Axiforma-Regular";
}
.product-percentage-div {
  position: absolute;
  top: 0px;
}
.product-percentage-div p {
  font-size: 8px;
  margin-bottom: 0px;
  top: 2px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Axiforma-Regular";
}
.product-percentage-with-text svg {
  margin-left: 1px;
}

.product-prev-image {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 7px; */
  height: calc(100% - 25px);
}
/* .product-detailslist{
    margin-left: 10px;
} */
.product-preview-name {
  margin-bottom: 0px;
  color: #1a1a1a;
  font-size: 10px;
  font-family: "Axiforma-bold";
  line-height: 15px;
}
.product-preview-rating {
  display: flex;
  margin-top: 3px;
  margin-bottom: 0px;
}
.product-rating-point {
  margin-bottom: 0px;
  color: #6f6f6f;
  font-size: 8px;
  margin-left: 3px;
  font-family: "Axiforma-Regular";
  padding-top: 1px;
}
.product-rating-point-span {
  margin-left: 4px;
}
.product-prev-pricing {
  margin-top: 6px;
  align-items: baseline;
}

.product-price {
  font-size: 7px;
  color: #a1a1a1;
  font-family: "Axiforma-Regular";
  margin-right: 4px;
  text-decoration: line-through;
  margin-bottom: 0px;
  margin-top: 2px;
}
.product-discounted-price {
  font-size: 8px;
  color: #1a1a1a;
  font-family: "Axiforma-Bold";
  margin-right: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.product-prev-pricing p {
  font-size: 10px;
  color: #58d16c;
  font-family: "Axiforma-Regular";
  margin-right: 4px;
  /* text-decoration: line-through; */
  margin-bottom: 0px;
  background-color: #eefbf0;
  padding: 2px 5px;
  border-radius: 2px;
}
.product-save-cart {
  display: flex;
  border: 1px solid #1a1a1a;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  color: #1a1a1a;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.productsettingsave {
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: white;
  background-color: #3064f9;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.product-flex-view {
  display: flex;
  flex-wrap: wrap;
}

.product-descripton-seconddiv-div-ul {
  list-style-type: none;
  background-color: white;
  box-shadow: 0px 7px 0px rgba(249, 249, 249, 1);
  margin-bottom: 8px !important;
  padding: 17px 13px 17px;
}
.product-descripton-first-div-ul,
.product-descripton-thirddiv-div-ul,
.product-descripton-fourthdiv-div-ul,
.product-descripton-fifthdiv-div-ul {
  list-style-type: none;
  background-color: white;
  box-shadow: 0px 7px 0px rgba(249, 249, 249, 1);
  margin-bottom: 7px !important;
  padding: 15px 13px;
}
.product-descripton-sixdiv {
  box-shadow: 0px 7px 0px rgba(249, 249, 249, 1);
}
.product-descripton-fourthdiv p {
  margin-bottom: 0px;
}
.product-username {
  font-size: 8px;
  color: #1a1a1a;
  font-family: "Axiforma-Regular";
}
.user-comment-text {
  font-size: 9px;
  color: #1a1a1a;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  line-height: 12px;
}
.dateof-comment p {
  font-size: 8px;
  color: #b0b0b0;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.ratting-count {
  color: #6f6f6f;
  font-size: 9px;
  display: flex;
}
.ratting-count svg {
  margin-right: 5px;
}
.dateof-comment {
  margin-top: 9px;
  margin-bottom: 9.2px;
}
.product-username svg {
  margin-left: 6px;
}

.products-detilas {
  font-size: 11px;
  color: #1a1a1a;
  font-family: "Axiforma-Regular";
  line-height: 15px;
}
.see-reviewdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.see-reviewdiv p {
  font-size: 9px;
  font-family: "Axiforma-Regular";
  color: #a1a1a1;
  margin-bottom: 0px;
}
.rating-count {
  font-size: 9px !important;
  font-family: "Axiforma-Regular" !important;
  color: #6f6f6f !important;
}
.inventorydata {
  margin-bottom: 0px;
  font-size: 8px;
  /* background-color: #F2FFF4; */
  color: #4ab170;
  padding: 3px 0px 0px;
  border-radius: 2px;
  font-family: "Axiforma-Regular";
  height: 16px;
}
.product-percentege-div {
  background-color: #ff4d5f;
  color: #ffffff;
  font-size: 8px;
  display: flex;
  align-items: center;
  height: 13px;
  width: 21px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.product-percentege-div p {
  margin-bottom: 0px;
  line-height: 13px;
}
.previousprice {
  margin-bottom: 0px;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  text-decoration: line-through;
  color: #b0b0b0;
  margin-right: 6px;
  margin-left: 5px;
  line-height: 13px;
}
.additional-information {
  font-size: 10px;
  font-family: "Axiforma-Regular";
  color: #a1a1a1;
  margin-bottom: 15px;
}
.material h1 {
  font-size: 10px;
  font-family: "Axiforma-Bold";
  color: #1f1f1f;
  margin-bottom: 9px;
}
.material li {
  font-size: 9px;
  color: #6f6f6f;
  font-family: "Axiforma-Regular";
  line-height: 9px;
}
.fit-type {
  margin-bottom: 15px;
}
.fit-type li {
  font-size: 9px;
  color: #6f6f6f;
  font-family: "Axiforma-Regular";
  line-height: 9px;
}
.fit-type h1 {
  font-size: 10px;
  font-family: "Axiforma-Bold";
  color: #1f1f1f;
  margin-bottom: 9px;
}
.currentprice {
  margin-bottom: 0px;
  font-size: 11px;
  font-family: "Axiforma-Bold";
  color: #1f1f1f;
  display: flex;
  line-height: 13px;
}
.productname-text {
  font-size: 12px;
  font-family: "Axiforma-Bold";
  color: #1f1f1f;
  margin-bottom: 6px;
}
.products-detilasnumber {
  font-size: 8px;
  color: #b0b0b0;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
}
.sizedropdownhead {
  color: #1f1f1f;
  font-size: 10px;
  font-family: "Axiforma-Bold";
  margin-bottom: 8px;
  margin-top: 8px;
}
.product-addtocart {
  display: flex;
  justify-content: space-between;
  width: 50%;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding: 8px 14px 6px;
  align-items: center;
  font-size: 15px;
  color: #333333;
  font-family: "Axiforma-Regular";
}
.addtocartbluebtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  padding: 10px 14px 10px;
  align-items: center;
  font-size: 11px;
  justify-content: center;
  background-color: #1a1a1a;
  color: white;
  font-family: "Axiforma-Regular";
  margin-top: 9px;
}
.sizedetails {
  border: 1px solid #cfcfcf;
  padding: 6px 16px;
  background-color: #ffff;
  border-radius: 4px;
  font-size: 9px;
  font-family: "Axiforma-Regular";
  color: #1a1a1a;
  position: relative;
  margin: 0px 5px 6px 0px;
}
.sizedropdown div {
  display: flex;
  flex-wrap: wrap;
}
.sizedetails span {
  position: absolute;
  right: 11px;
}
.product-points {
  padding: 9px 10px 8px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  /* margin-bottom: 10px; */
  display: flex;
}
.product-points-text {
  font-size: 8px;
  font-family: "Axiforma-Regular";
  color: #6f6f6f;
  margin-bottom: 0px;
  margin-left: 8px;
}
.product-points span {
  color: #1f1f1f;
  font-family: "Axiforma-Bold";
}
.product-points svg {
  margin-right: 0px;
  min-width: 16px;
  min-height: 16px;
}
.product-detial-flex-view {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 7px 0px rgba(249, 249, 249, 1);
  margin-bottom: 8px !important;
  padding: 15px 9px;
}
.product-detial-flex-view ul {
  min-width: 121px;
}
.see-reviewdiv span {
  margin-right: 0px !important;
  margin-left: 3px;
}
.user-total-ratting {
  font-size: 9px !important;
  font-family: "Axiforma-Bold";
  color: #6f6f6f !important;
  display: flex;
  margin-bottom: 9px;
  align-items: center;
}
.user-total-ratting .starandrating {
  display: flex;
  gap: 4px;
  align-items: self-start;
}

.user-total-ratting div svg {
  margin-right: 3.3px;
}
.writereview-btn {
  border: 1px solid #b0b0b0;
  color: #a1a1a1;
  height: 35px;
  border-radius: 6px;
  color: #6f6f6f;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Axiforma-Regular";
  margin-top: 15px;
}
.user-comment {
  border-radius: 10px;
  padding: 11px 11px 16px 11px;
  margin-bottom: 10px;
  border: 1px solid #ebebeb;
}
.product-username span {
  margin-left: 6px;
  color: #b0b0b0;
}
.writereview-btn svg {
  margin-right: 5.4px;
}

.producttype-img {
  height: 159px;
  background-color: #f2f2f2;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}
.product-flex-preview-list-page {
  padding: 0px 4px 8px;
  /* border-bottom: 1px solid #F5F5F5; */
  position: relative;
  width: 50%;

  /* border-right: 1px solid #F5F5F5; */
  /* padding-right: 5px; */
  /* border-bottom: 1px solid #F5F5F5; */
  /* padding-top: 5px; */
}
.product-flex-view .product-preview-img {
  width: 100%;
  height: 136px;
  background-color: #ebebeb;
  position: relative;
}
.product-flex-view .product-detailslist {
  margin-left: 0px;
  margin-top: 1px;
}
/* .product-flex-view .product-prev-image {
    margin-top: 35px;
} */
.stocklist-flex-page {
  font-size: 7px;
  color: #4ab170;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  padding: 0px 5px;
  width: 65px;
  border-radius: 3px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 6px;
  background-color: #eefff4;
}
.toggleswitch-flex {
  display: flex;
  justify-content: space-between;
  padding: 2px 11px;
  /* border-bottom: 1px solid #F5F5F5; */
  height: 37px;
}
.product-filterswitch {
  margin-bottom: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.product-alignmenttoggle {
  gap: 12px !important;
}

.checkout-prev-firstimg {
  background-color: #fafafa;
  background-image: url("../../../assets/images/dashboard/checkout-previewbackground.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
}
.checkout-prev-secondimg {
  background-image: url("../../../assets/images/dashboard/checkout-prev-secondtimg.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
}

.product-img {
  background-color: #fafafa;
  background-image: url("../../../assets/images/dashboard/product-img.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
}
.product-imagetwo {
  background-image: url("../../../assets/images/dashboard/product-imagetwo.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
}
.previewthumbnail-img {
  background-color: #fafafa;
  background-image: url("../../../assets/images/dashboard/previewthumbnail.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
}
.previewthumbnail-imagetwo {
  background-image: url("../../../assets/images/dashboard/previewthumbnail-two.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
}
.product-ratio-select {
  color: #7782a1;
}
.product-shapenotselect {
  stroke: #e3e6f1;
  fill: #e3e6f1;
}
.product-shapeselect {
  stroke: #7782a1;
  fill: #7782a1;
}
.product-togglegrid {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.connectionupdatedate {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin: 0px 20px;
}
.cartitem-save {
  position: absolute;
  top: -3px;
  right: -4px;
  font-size: 7px;
  color: white;
  background-color: #ff0000;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border-radius: 18px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.red-dot-filter {
  border-radius: 18px;
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #ff0000;
  top: 6px;
  right: 15px;
  display: none;
}
.product-box-size {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}
.product-box-size p {
  margin-left: 30px;
  cursor: pointer;
}
.product-box-size p span {
  margin-left: 6px;
  color: #bcc1ce;
  font-size: 11px;
  font-family: "Axiforma-Regular";
}
.shape-of-product {
  display: flex;
  gap: 30px;
}
.shape-of-product svg {
  cursor: pointer;
}
.checkout-enable-para {
  margin-bottom: 25px;
  text-align: left !important;
}
.product-setting-mobhead {
  display: none;
}
.productsetting-save-mob-btn {
  display: none;
}
.setting-last-li {
  display: grid !important;
  justify-content: normal !important;
  margin-bottom: 33px;
}
.selected-product {
  background-color: #f2f2f2;
  /* height: 302px; */
  position: relative;
}
.productimg-topdiv {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.productsetting-mobile-screen .control-dots {
  display: none;
}
.productimg-view {
  display: flex;
  justify-content: center;
  height: calc(100% - 120px);
  align-items: center;
}
.product-details-images {
  bottom: 2px;
  position: absolute;
  right: 43%;
}
.productimag-backside {
  margin-bottom: 0px;
  width: 32px;
  height: 32px;
  background-color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 18px;
}
.product-svgimg {
  width: 15px !important;
}
.productsetting-mobile-screen {
  max-height: 651px;
  width: 302px;
  background-color: #fff;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: hidden;
  border: 4px solid #e3e6f1;
  border-radius: 20px;
}
.productsetting-mobile-screen .slider-wrapper {
  width: 100%;
}
.productsetting-mobile-screen .slider {
  background-color: unset;
}
.productsetting-mobile-screen .product-firstscreen {
  max-height: 643px;
  overflow: auto;
}
.productsetting-mobile-screen .carousel .slide {
  text-align: start;
}
.productsetting-mobile-screen .carousel-status {
  display: none;
}
.productsetting-mobile-screen .product-firstscreen::-webkit-scrollbar {
  width: 0px !important;
}
.productsetting-mobile-screen::-webkit-scrollbar {
  width: 0px !important;
}
.checkout-enable-para .full-page-tooltipsubheader {
  top: unset;
  bottom: -12px;
}
.checkout-enable-para .full-page-tooltipsubheader::after {
  bottom: 15px;
  top: unset;
}

@media (max-width: 1650px) {
  .setting-productdiv .productsetting-tooltip .productsetting-tooltipsubheader {
    width: 190px;
    top: 27px;
    left: -46px;
  }
  .setting-productdiv
    .productsetting-tooltip
    .productsetting-tooltipsubheader::after {
    top: -3px;
    right: 65%;
    margin-top: -7px;
    transform: rotate(90deg);
  }
}
@media (max-width: 1200px) {
  .product-box-size {
    gap: 15px;
  }

  .checkout-enable-para .full-page-tooltipsubheader {
    top: unset;
    bottom: 27px;
  }
  .checkout-enable-para .full-page-tooltipsubheader::after {
    bottom: -10px;
    top: unset;
    border-color: black transparent transparent transparent;
  }
  .product-main-spinner {
    margin: 15px;
  }
  .productsetting-tophead-div {
    display: none;
  }
  .productsetting-tooltip .productsetting-tooltipsubheader {
    width: 190px;
    top: 27px;
    left: -46px;
  }
  .productsetting-tooltip .productsetting-tooltipsubheader::after {
    top: -3px;
    right: 65%;
    margin-top: -7px;
    transform: rotate(90deg);
  }
  .productsetting-save-mob-btn .productSetting-save-btn {
    width: 100%;
    height: 50px;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    color: white;
    background-color: #3064f9;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .productsetting-save-mob-btn {
    margin: 0px 15px 20px;
    display: block;
  }
  .productsetting-save-mob-btn .product-update-time {
    font-size: 12px;
    color: #bcc1ce;
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
  .product-box-size p span {
    display: none;
  }
  .product-setting-tophead {
    padding: 25px 15px;
  }
  .product-list-head-maindiv {
    padding: 25px 15px 0px;
  }
  .product-setting-mobhead {
    display: block;
    font-size: 20px;
    color: #1b2952;
    font-family: "Axiforma-Bold";
    padding: 20px 15px 0px 15px;
    margin-bottom: 0px;
    line-height: 20px;
  }
  .product-setting-right {
    display: none;
  }
  .product-setting-left {
    width: 100%;
    padding: 0px;
    height: unset;
    overflow: unset;
    overflow-x: unset;
  }
  .setting-productdiv {
    margin: 15px 15px;
    min-height: auto;
  }
  .product-color-picker {
    display: grid;
    justify-content: unset;
  }
  .product-color-picker .product-list-listtag:first-child {
    margin-right: 0px;
  }
  .product-color-picker .product-list-listtag {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .product-setting-left .full-page-tooltip .full-page-tooltipsubheader {
    width: 120px;
    padding: 5px 10px;
    font-size: 11px;
    line-height: 15px;
    left: -27px;
  }
  .product-setting-left .full-page-tooltip .full-page-tooltipsubheader::after {
    left: 31px;
  }
  .checkout-enable-para,
  .product-list-listtag .setting-enable {
    width: 75%;
  }
}
