.borderr {
    border-bottom: 1px solid #e0e5ec;
}
.aphead{
    margin-top: 80px;
   
}
.web-connectivity-head .billing-tooltip{
    margin-left: 6px;
}
.headerh5{
    font-size: 13px !important;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-top: 3.41px !important;
    color: #7782a1;
}
.intallplugin-generate{
    display: grid;
    position: relative;
}
.intallplugin-generate label{
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    color: #7782A1;
}
.connectivity-tooltip{

        position: relative;
        display: inline-block;
        margin-bottom: 0px;
        margin-left: 6px;
}
.connectivity-tooltip .connectivity-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 7px;
    left: 159%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    text-align: left !important;
    transition: 0.3s;
}

.connectivity-tooltip .connectivity-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 7px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.connectivity-tooltip:hover .connectivity-tooltipsubheader {
    visibility: visible;
}
.intallplugin-generate input{
   height: 50px;
   font-size: 13px;
   color: #7782A1;
   padding: 16px 85px 17px 20px;
   border-radius: 3px;
   border: 1px solid #BCC1CE;
   outline: none;
   font-family: 'Axiforma-Regular';
   background-color: #FFFFFF;
}
.intallplugin-generate .copysvg{
    position: absolute;
    top: 43px;
    right: 20px;
 }
.intallplugin-generate .ip-generate-section{
    position: absolute;
    right: 20px;
    top: 40px;
    font-size: 13px;
    color: #3064F9;
    font-family: 'Axiforma-Regular';
    cursor: pointer;
}
.intallplugin-generate input:hover, .intallplugin-generate input:focus{
    border: 1px solid #3064F9!important;
    background-color: #F8FAFE!important;
    transition: .5s;
}
.intallplugin-generate input::placeholder{
    font-size: 13px;
    color: #E3E6F1;
}
.downloadheader{
  font-size: 20px !important;
  margin-top: 4px;
}

.varifytext{
    font-size: 14px !important;
}
.stepsDiv{
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
   
}
.takefull{
   border-radius: 2px !important;
}
.wordpressimage{
margin-top: 54px;
}

.stepsDiv1{
    display: none;
}
.stepsDiv1 .row{

  border: 1px solid #E3E6F1;
    margin-bottom: 10px;
    border-radius: 3px;   
}
.steps{
    display: flex;
}
.steps label{
    font-size: 12px;
    color: #BCC1CE;
    margin-bottom: 16px;
}

.steps p{
    margin-top: 0;
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
    font-weight: 400;
   
}
.step1{
    /* border: 1px solid #E3E6F1; */
    display: flex;
    place-items: center;
    /* margin-right: 15px; */
    width: 100%;
    border-radius: 3px;
    justify-content: center;
}
.step2{
    border: 1px solid #E3E6F1;
    display: grid;
    place-items: center;
    width: 100%;
    border-radius: 3px;
}
.pluginheadtext{
    font-size: 16px;
    font-family: "Axiforma-Bold";
    color: #1B2952;
    margin-top: 20px;
    margin-bottom: 0px;
    line-height: 20px;
}
.pluginhead{
    font-size: 14px;
    font-family: "Axiforma-Regular";
    color: #7782A1;
    margin-top: 10px;
    margin-bottom: 40px;
    line-height: 20px;
}
.pluggedinornot{
    display: none;
}
.iconImg{
    margin-right: 20px;
}
.plugin_img{
    margin-top: 43.5px;
}
.step1 p{
    margin-top: 0;
    font-size: 13px;
    color: #7782A1;
    /* max-width: 77%; */
    margin-bottom: 0px !important;
    font-weight: 400;
    /* text-align: center; */
    line-height: 21px;
}
.step1 label{
    font-size: 12px;
    color: #BCC1CE;
    /* margin-top: 11px; */
    /* margin-bottom: 20px; */
}
.step2 p{
    margin-top: 0;
    font-size: 12px;
    color: #7782A1;
    max-width: 63%;
    margin-bottom: 24px !important;
    margin-bottom: 0px;
    font-weight: 400;
    text-align: center;
    line-height: 21px;
}
.step2 label{
    font-size: 12px;
    color: #BCC1CE;
    margin-top: 11px;
    margin-bottom: 20px;
}

.downloadButtons{
    margin-top: 40px;
  
    
}
/* .downloadButtons .downloads{
margin-right: 20px !important;
} */
.downloads{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    background-color: #3064F9;
    color: #FFFFFF;
    border: none;
width: 180px;
    height: 42px;
    border-radius: 3px;
    margin-right: 20px;
    /* width: 100%;
    max-width: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
}
.downloads:hover {
    box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
    color: #fff;
  }


.gosite{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    min-width: 180px;
    /* max-width: 180px !important; */
    background-color: #FFFFFF !important;
    color: #3064F9 !important;
    width: 180px;
    border: 1px solid #3064F9;
    height: 40px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.gosite:hover {
    box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
    color: #fff;
  }
/* .installedpluginlink:hover {
    background-color: #3064F9;
    color: #FFFFFF;
} */

.takethere:hover{
  color: #3064F9;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.plugin_content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}
.plugin h4{
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    margin-top: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0px;
}
.panel1{
    padding: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    background: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 4px 6px rgba(0,0,0, 0.06);
}


.rowdiv{
    margin-left: 30px !important;
    margin-right: 30px !important;
    padding-bottom: 54px;
    display: flex;
}
.divpanel{
    padding-right: 30px !important;
    padding-left: 0px !important;
    flex: 0 0 50%;
    max-width: 50%;
}
.divpanel1{
    padding-right: 0px !important;
    padding-left: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 50%;
    max-width: 50%;
}
.headmargin{
    padding: 19px 30px 19.5px 30px !important;
} 

.notificationdiv{
    display: none !important;

}
.mob-connect-varify{
display: none;
}
.connectivity-mob-head{
    display: none;
    
}
.web-connectivity-head h1{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin: 0px;
}
.web-connectivity-head .consent-tooltip svg{
    margin-left: 6px;
}
.monitorLink{
    margin-right: 0px !important;
}
.connectivity-btn{
    background-color: #3064f9;
    text-decoration: none;
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    border: 1px solid #3064f9;
    line-height: 15px;
    transition: 0.5s;
    cursor: pointer;
    width: 100px;
    height: 40px;
}
.connectivity-btn:hover{
    background-color: var(--custom_color5)!important;
    border: 1px solid var(--custom_color6)!important;
    color: var(--custom_color4)!important;
}
.generate-key-active{
    position: absolute;
    top: 40px;
    right: 20px;
    color: #3064F9;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    line-height: 17.47px;
    cursor: pointer;
    margin-bottom: 0px;
}
.generate-key-inactive{
    position: absolute;
    top: 40px;
    right: 20px;
    color: #BCC1CE;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    line-height: 17.47px;
    margin-bottom: 0px;
}
/* @media (max-width: 767px){
    .stepsDiv1{
        display: block;
        margin-right: 15px;
        margin-left: 15px;
    }
   
    .pluggedinornot{
      
        display: flex;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .pluggedinornot .images{
        margin-top: 0px !important;
        display: flex;
        align-items: center; 
        margin-right: 6px !important;
    }

    .divpanel{
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .rowdiv{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .downloads{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        border: 1px solid #3064F9;
        min-height: 0px;
        height: 48px;
        border-radius: 3px;
        margin-right: 20px;
    }
   
    .gosite{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px !important;
       
    }
    .borderr {
        border-bottom: none;
        padding: 0px !important;
    }
    .wordpressimage{
        margin-top: 0px;
        }
  
        .stepsDiv1 .row{
            height: 100px;
            align-items: center !important;
        }
        .steps img{
            padding: 0px 10px 0px 10px;
        }
       
        .pluginwebsite{
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        .pluginwebsite h4{
            display: flex;
    align-items: center;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
        }
} */
@media (max-width: 1200px) {
    .connectivity-tooltip .connectivity-tooltipsubheader {
        width: 190px;
        top: 35px;
        left: -50px;
    }
    .connectivity-tooltip .connectivity-tooltipsubheader::after {
        top: -3px;
        right: 65%;
        margin-top: -7px;
        transform: rotate(90deg);
    }
    .send-buttondisabled, .connectivity-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;

    }
    .varifytext {
        font-size: 16px !important;
    }
    .step1 p {
        font-size: 13px;
    }
    .web-connectivity-head{
        display: none;
    }
    .connectivity-mob-head{
        display: block;

    }
    .panel1 {
        margin: 0px 15px !important;
    }
    .rowdiv {
        margin: 0px !important;
        padding: 30px 15px;
    }
    .divpanel {
        padding-right: 0px !important;
    }
    .wordpressimage {
        margin-top: 0px;
    }
    /* .intallplugin-generate .ip-generate-section {
        right: 0px;
        top: 0px;     
    } */
    .divpanel {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .pluggedinornot {
        display: flex;
        margin-bottom: 30px !important;
    }
    .divpanel1 {
        display: none !important;
    }
    .stepsDiv {
        display: grid;
        justify-content: unset;
        margin: 10px 15px;
    }
    .step1 { 
    justify-content: unset;
    margin-bottom: 30px;
    }
    .mob-connect-varify{
        padding: 20px 15px;
        display: block;
    }
    .mob-connect-varify p{
        justify-content: center;
    display: flex;
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    }

    .connectivity-mob-head{
        padding: 20px 15px 20px 15px;
    }

    .connectivity-mob-head p{
        color: #1B2952;
    font-family: 'Axiforma-Bold';
    text-align: left !important;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 20px;
    }
    .pluginhead {
     margin-bottom: 30px ;
    }
    .pluggedinornot .plugin_img {
        margin-top: 0px !important;
        margin-right: 10px !important;
        display: flex;
        align-items: center;
    }
    .downloads {
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #3064F9;
        color: #FFFFFF;
        min-width: 0px;
        height: 48px;
        border-radius: 3px;
        border: 1px solid #3064F9;
        width: 50%;
    }
    .gosite {
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
        width: 50%;
    }
    .generate-key-active , .generate-key-inactive {
        position: absolute;
        top: 0px;
        right: 2px;
    }
}
/* @media (min-width: 1001px) and (max-width: 1200px) {
    .headmargin{
        padding: 20px 0px 20px 30px !important;
        margin-top: 0px;
    } 
    .stepsDiv1{
        display: none;
       
    }
    .divpanel1{
        min-height: 75vh !important;
        padding-bottom: 20px;
    }
    .downloads{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
    background-color: #3064F9;
    color:  #FFFFFF;
    min-width: 0px;
    height: 48px;
    border-radius: 3px;
    border: 1px solid #3064F9;
    }
    .tback .transparent_background{
        margin-top: 70px !important;
    }

    .stepsDiv1 .row{
        height: 100px;
        align-items: center;
    }
  
    .steps img{
        padding: 0px 10px 0px 10px;
    }
    .gosite{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
       
    }
} */

/* @media (min-width: 767px) and (max-width: 1000px) {
    .headmargin{
        padding: 20px 0px 20px 30px !important;
        margin-top: 0px;
    } 
    .stepsDiv1{
        display: block;
       
    }
    .divpanel1{
        display: none !important;
    }
    .downloads{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
    background-color: #3064F9;
    color:  #FFFFFF;
    min-width: 0px;
    height: 48px;
    border-radius: 3px;
    border: 1px solid #3064F9;
    }
    .tback .transparent_background{
        margin-top: 70px !important;
    }
 
    .stepsDiv1 .row{
        height: 100px;
        align-items: center;
    }
  
    .steps img{
        padding: 0px 10px 0px 10px;
    }
    .gosite{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
       
    }

    .rowdiv{
         margin-right: 0px !important;
    }
   
    .pluggedinornot .plugin_img{
        margin-top: 4px !important;
        margin-right: 10px !important;
    }
  
} */
/* @media (min-width: 200px) and (max-width: 767px) {
     .pluginwebsite h4 span{
        margin-left: 4px !important;
        margin-right: 4px !important;
     }
    .downloadButtons .downloads{
        background-color: white;
        color: #3064F9;
        border: 1px solid #3064F9;
        border-radius: 3px;
    }

    .panel3{
        height: 10px;
    }
    .tback .transparent_background{
       margin-top: 60px !important;
    }
    .downloadButtons{
        margin-top: 30px;
    }
    .panel1{
        padding: 0px !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        min-height: 100% !important;
    }
    .steps img{
        padding: 0px 10px 0px 10px;
    }
    .pluginhead{
        margin-bottom: 30px !important;
    }
    .pluginwebsite{
        margin-top: 1px !important;
    }
} */
/* @media (max-width: 400px) {
    .downloads{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
    background-color: #FFFFFF;
    color: #3064F9;
    min-width: 0px;
    height: 48px;
    border-radius: 3px;
    border: 1px solid #3064F9;
    }
    
   
    .gosite{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
       
    }
    .stepsDiv1 .row{
        height: 100px;
        align-items: center;
    }
   
    .notificationdiv{
        display: none !important;
    }

} */

/* @media (min-width: 300px) and (max-width: 380px){
    .downloads{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
        border-radius: 3px;
        margin-right: 20px;
        border: 1px solid #3064F9;
    }
    
    
    .gosite{
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
       
    }
    .stepsDiv1 .row{
        height: 100px;
        align-items: center;
    }
 
    .notificationdiv{
        display: none !important;
    }

} */

/* @media(min-width: 200px) and (max-width: 380px) {
    .downloads{
        font-size: 10px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
        border-radius: 3px;
        margin-right: 20px;
        border: 1px solid #3064F9;
    }
    
    
    .gosite{
        font-size: 10px;
        font-family: 'Axiforma-Regular';
        background-color: #FFFFFF;
        color: #3064F9;
        min-width: 0px;
        height: 48px;
       
    }

    .steps label{
        font-size: 12px;
        margin-bottom: 16px;
    }
    
    .steps p{

        font-size: 12px;
       
    }
    .pluginhead{
        margin-bottom: 30px !important;
    }
    .plugin h4{
        font-size: 10px;
    }

    .stepsDiv1 .row{
        height: 100px;
        align-items: center;
    }
   
    .notificationdiv{
        display: none !important;
    }
} */

