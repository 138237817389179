.appleappstore-connect {
    display: flex;
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.inappPurchage_header h3{
    font-size: 24px !important;
    /* line-height: 37px !important; */
    margin: 0px !important;
    font-family: 'Axiforma-Bold';
    line-height: 24px;
    color: #1B2952;
}
.appleappstore-div{
    position: relative;
    min-height: 78vh;
    border-radius: 6px;
}
.appleappstore-div .newspinner{
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.inappPurchage_header .consent-tooltip{
   margin-left: 6px;
}
.appleappstore-connect .consent-tooltip {
margin-left: 6px;
}
.appleappstore-connect-left {
    width: 50%;
    padding: 23px 20px 18px 30px;
}
/* .in-app-connect-left{
    padding: 23px 20px 30px 30px;
} */
.in-app-connect-left .appleappstoreconnect {
    margin-bottom: 30px;
}
.appleappstoreconnect {
    padding-bottom: 19px;
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 25px;
}
.appleappstoreconnect h2 {
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    margin-bottom: 8px;
}
.appleappstoreconnect p {
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}
.mob-publish-connect {
    display: none;
}
.in-app-publish-apple-id-field {
    display: grid;
    margin-bottom: 18px;
}
.in-app-publish-apple-id-field label {
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 6px;
}
.in-app-publish-apple-id-input {
    height: 50px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #bcc1ce;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 15px;
}

.publish-howtoget {
    width: 140px;
    height: 40px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #ffff;
    margin-top: 10px;
}

.publish-howtoget {
    width: 140px;
    height: 40px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #ffff;
    margin-top: 10px;
}
.appleappstore-connect-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.appleappstore-connect-right img{
    padding: 10px 0px 0px;
}
.in-app-mob-publish-btns {
    display: none;
}
.publishconectmethod {
    display: flex;
    margin-top: 60px;
}
/* .publishconectsteps {
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: center;
} */
.publishstepwise {
    margin-left: 20px;
}
.publishstepwise h2 {
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 10px;
}
.publishstepwise p {
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}
.inappPurchage_header h2{
    display: none;
   }
.in-app-publish-apple-id-input:hover, .in-app-publish-apple-id-input:focus {
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}
.in-app-publish-update{
    font-size: 12px;
    font-family: Axiforma-Regular;
    color: rgb(188, 193, 206);
    margin: 11px 20px 11px 0px;
}
@media (max-width: 1200px) { 
    .appleappstore-div {
  
        min-height: 80vh;
   
    }
    .in-app-publish-apple-id-field label {
        margin-bottom: 8px;
    }
    .in-app-connect-left .appleappstoreconnect {
        margin-bottom: unset;
    }
    .in-app-publish-update{
        margin: 11px 0px 0px 0px;
    }
    .in-app-publish-apple-id-field {
        margin-bottom: 20px;
    }
    .in-app-publish-apple-id-field label {
        margin-bottom: 9px;
    }
    .inappPurchage_header{
        margin-bottom: 19px;
    }
    .in-app-input-div{
        margin-top: 25px;
    }
    .appleappstore-connect-left {
        width: 100%;
        padding: 0px;
    }
.inappPurchage_header h3{
 display: none;
}
.inappPurchage_header h2{
    font-size: 20px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    line-height: 28px;
    display: block;
    margin-bottom: 0px;
   }
    .appleappstoreconnect {
        padding: 24px 15px 15px;
    }
    .mob-publish-connect {
        display: block;
        margin: 20px 0px 21px;
    }
    .mob-publish-Disconnected {
        display: flex;
    }
    .mob-publish-Disconnected p {
        font-size: 13px;
        color: #FF4848;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        text-align: left !important;
        display: flex;
        margin-left: 6px;
    }
    .mob-publish-Connected {
        display: flex;
    }
    .mob-publish-Connected p {
        font-size: 13px;
        color: #52CB86;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        text-align: left !important;
        margin-left: 6px;
    }
    .appleappstore-connect-right {
        display: none;
    }
    .in-app-mob-publish-btns {
        display: block;
    }
    .in-app-publish-save-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: "Axiforma-Regular";
        margin-top: 30px;
        margin-bottom: 20px;
        border-radius: 3px;
        background-color: unset;
    }
    .in-app-publish-save-btn.in-app-publish-enable {
        color: #3064F9;
        border: 1px solid #3064F9;
    }
    
    .in-app-publish-save-btn.in-app-publish-disable {
        color: #BCC1CE;
        border: 1px solid #BCC1CE;
    }
    .in-app-publish-varify-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: "Axiforma-Regular";
        border-radius: 3px;
        border: none;
    }
    .in-app-publish-varify-btn.in-app-publish-enable {
        color: white;
        background-color: #3064F9;
    }
    
    .in-app-publish-varify-btn.in-app-publish-disable {
        color: white;
        background-color: #BCC1CE;
    }
    .in-app-publish-update {
        font-size: 12px;
        font-family: "Axiforma-Regular";
        color: #BCC1CE;
        margin-top: 20px;
        text-align: center;
    }
    .publishconectmethod {
        display: grid;
        margin-top: 0px;
    }
    .publishconectsteps {
        display: flex;
        width: 100%;
        align-items: unset;
        justify-content: unset;
        margin: 40px 0px 20px;

    }
} 