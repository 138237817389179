@font-face {
  font-family: "Proxima-Regular";
  src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../assets/fonts/ProximaNova-Bold.otf);
}
p {
  font-family: "Axiforma-Regular";
}
.headstyle {
  font-family: "Axiforma-Bold" !important;
}
.add_footer_setting {
  border-top: 1px solid #e1e6ee;
  padding: 15px 0px 0px 0px !important;
}
.notingat-the-moment{
  color: #BCC1CE;
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  line-height: normal;
  margin-top: 18px;
}
.heading {
  margin: 0px;
  font-family: "Axiforma-Bold" !important;
  font-size: 20px;
}
.default {
  color: #c8cedb !important;
  font-size: 13px;
}
.fixwidth {
  width: calc(100% - 60px);
}
.fixwidth p {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.buttonsetting {
  border: 1px solid #c8cedb;
  color: #7782a1;
  font-size: 15px;
  border-radius: 2px;
  background: none;
  height: 40px;
  width: 120px;
  cursor: pointer;
  font-family: "Axiforma-Regular";
}
.button {
  border: 1px solid #3064f9;
  color: #3064f9;
  font-size: 13px;
  border-radius: 2px;
  background: none;
  height: 40px;
  width: 120px;
  cursor: pointer;
  font-family: "Axiforma-Regular";
}
.buttononnotapp {
  border: 1px solid #3064f9;
  color: #fff;
  font-size: 15px;
  border-radius: 2px;
  background: #3064f9;
  height: 44px;
  line-height: 44px;
  margin: 45px auto;
  width: 140px;
  cursor: pointer;
  font-family: "Axiforma-Regular";
  border-radius: 4px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}
.buttononnotapp:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.connectborder {
  border-bottom: 1px solid #dce1eb;
}
.connectborder p {
  font-size: 14px;
}
.connectborder p a {
  color: #3064f9;
}
.selectcountry {
  color: #3064f9 !important;
  cursor: pointer;
  text-transform: capitalize;
}
.lastsection {
  width: 88px;
}
.lastsection img {
  position: relative;
  top: -2px;
  cursor: pointer;
}
.countryselection span {
  cursor: pointer;
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.appbackground {
  padding: 20px;
}
.parentdiv {
  box-shadow: 0px 0px 12px #d8dfeb;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}
.appname {
  background: white;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 13px;
}
.appname label {
  font-size: 16px;
  font-family: "Axiforma-Bold" !important;
  padding-left: 32px !important;
  width: 186px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.appname span {
  position: relative;
  top: -2px;
}
/* .notanyapp{
    padding: 0px 15px;
} */
.notanyapp h2 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
  margin: 47px 0px 7px;
}

.notanyapp p {
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.add_app_multi p {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Regular";
}
.add_app_multi .search_box_multi {
  width: 40%;
  height: 45px;
  padding: 0px 10px !important;
  font-family: "Axiforma-Regular";
}
.add_app_multi .search_box_multi input {
  width: 100%;
  font-family: "Axiforma-Regular";
  font-size: 16px;
}
[dataclass~="countrycode"] .check_multi span {
  height: 22px;
  width: 22px;
  font-family: "Axiforma-Regular";
}
[dataclass~="countrycode"] input:checked ~ span:after {
  left: 7px !important;
  top: 4px !important;
  border-color: #fff !important;
  width: 6px !important;
}
[dataclass~="countrycode"] input:checked ~ span {
  background-color: #3064f9 !important;
}
.table_data {
  padding: 0px 15px;
}
.contry_notselected h2 {
  font-family: "Axiforma-Bold";
}
.contry_notselected p {
  font-family: "Axiforma-Regular";
}

.add_app_body {
  background: #f8fafe;
  border-top: 1px solid #c8cedb;
  border-bottom: 1px solid #c8cedb;
}

.outer_border {
  border: 1px solid #c8cedb;
  background: #f8fafe;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.outer_border h2 {
  font-size: 15px;
  color: #333333;
  margin: 0px;
  word-break: break-all;
}
.outer_border p {
  font-size: 12px;
  color: #a1a1a1;
  margin-top: 3px;
  margin-bottom: 0px;
  word-break: break-all;
}
.parastyle {
  font-size: 12px;
  color: #7782a1;

  margin-bottom: 0px;
}





@media screen and (max-width: 980px) {
  .table_data {
    margin-bottom: 34px;
  }
  .add_app_btn {
    position: fixed;
    bottom: 0px;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
    z-index: 9;
  }
  .add_app_btn .button,
  .add_app_btn .buttonsetting {
    width: 50%;
    height: 44px;
  }
  .add_app_btn .connectborder {
    border: none;
    background: white;
  }
  .contry_notselected h2 {
    font-size: 16px;
  }
  .contry_notselected p {
    font-size: 14px;
  }
  .table_data_table {
    flex-wrap: wrap;
    padding: 10px 0px;
    height: 115px;
  }
  .mobile_country {
    width: 100%;
  }
  .mobile_country .countryselection {
    justify-content: flex-end;
  }
  .app_icon {
    width: calc(100% - 70px);
  }
  .lastsection {
    width: 70px;
    margin-right: 0px;
  }
  .add_app_body {
    height: 300px;
  }
  .add_footer_multi {
    margin: 0px !important;
  }
  .add_footer_multi button {
    width: 48% !important;
  }
  .add_app_multi .search_box_multi {
    width: 100%;
    margin-top: 10px;
  }
  .buttononnotapp {
    margin: 30px auto;
  }
  .notanyapp h2 {
    font-size: 18px;
  }
  .notanyapp p {
    font-size: 14px;
  }
  .connectborder {
    padding: 15px !important;
  }
}
