@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}

.mainheader{
  background-color: #fff;
  height: 60px;
  z-index: 2;
  position: fixed;
  box-shadow: 0px 0px 6px rgb(188 193 206 / 36%);
  width: 100%;
  top: 0;
}
.header-width{
  width: 100%;
  padding: 0px 30px 0px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
.header-menu-list{
  display: none;
  align-items: center;
  justify-content: space-between;
  transition: .5s;
  transform: translateZ(100px) rotateX(  90deg);
  opacity: 0;
  visibility: hidden;
}
.header-menu-list-show{
  transition: .5s;
  transform: translateZ(100px) rotateX(  0deg);
  display: flex;
  opacity: 1;
  visibility: visible;
}
.profile-dropdown{
  height: 36px;
  width: 36px;
  border-radius: 100%;
}
.opec , .opec1, .opec2 , .opec3{
  overflow: hidden!important;
}
.opec:after , .opec1:after, .opec2:after, .opec3:after{
  background: rgba(0,0,0,.2);
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: 2s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  z-index: 1;
  position: fixed;
}
.profile-menu{
  position: fixed;
  right: -400px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 99999;
  border-left: 1px solid #E3E6F1;
  border-top: 1px solid #E3E6F1;
  padding-left: 10px;
  padding-bottom: 40px;
}
.profile-menu-height{
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
}
.support-menu-height{
  overflow: auto;
  height: 100%;
  position: relative;
}
.create-menu-height {
  overflow: auto;
  height: 100%;
  position: relative;
}
.create-product-type{
  border: 1px solid #EDF0F5;
  width: calc(100% - 10px);
  margin-top: 10px;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 20px;
  transition: .5s;
  border-radius: 4px;
}
.create-product-type:first-child{
  margin-top: 21px;
}
.create-product-type:hover{
  box-shadow: 0px 3px 6px rgb(0 0 0 / 6%);
  transition: .5s;
}
.create-product-type a{
  position: absolute;
  width: 100%;
  height: 100%;
}
.create-product-type h3{
  color: #1B2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  line-height: 23px;
}
.create-product-type h4{
  color: #7782A1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px;
}
.support-menu{
  position: fixed;
  right: -400px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 99999;
  padding-left: 10px;
  border-left: 1px solid #E3E6F1;
  border-top: 1px solid #E3E6F1;
  padding-bottom: 40px;
}
.login-customer{
  position: fixed;
  right: -400px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 99999;
  border-left: 1px solid #E3E6F1;
  border-top: 1px solid #E3E6F1;
  padding-bottom: 40px;
}
.show-profile-menu , .show-support-menu , .show-login-customer{
  right: 0px;
}
.profile-holder-img{
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 36px;
}
.support-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  margin-left: 10px;
  padding-right: 20px;
}
.support-head svg{
  margin: 0px 17px 0px 9px;
}
.support-head h4{
  font-family: "Axiforma-Bold";
  color: #1B2952;
  font-size: 18px;
  margin: 0px;
}
.support-head-one{
  display: flex;
  align-items: center;
}
.support-head p{
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 11px;
  margin: 0px 0px -5px 0px;
  text-align: right;
}
.support-head a , .support-head a:hover{
  font-family: "Axiforma-Regular";
  color: #3064F9;
  font-size: 11px;
  margin: 0px;
  text-align: right;
}
.header-elements{
  margin-left: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.header-elements svg{
  /* stroke: #bcc1ce; */
  fill: #bcc1ce;
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  &:hover {
    fill: var(--custom_color);
  }
}
.header-elements svg:hover{
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.profile-menu::before {
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  right: 38px;
  margin: auto;
  transform: rotate(0deg);
}
.support-menu::before {
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  right: 149px;
  margin: auto;
  transform: rotate(0deg);
}
.create-menu::before {
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  right: 252px;
  margin: auto;
  transform: rotate(0deg);
}
.create-menu-withoutsupport:before
{
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  margin: auto;
  transform: rotate(0deg);
  right: 149px !important;
}
.create-menu-withsupport:before
{
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  margin: auto;
  transform: rotate(0deg);
  right: 200px !important;
} 

.create-menu-withkey:before
{
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  margin: auto;
  transform: rotate(0deg);
  right: 305px !important;
}
.login-customer::before {
  display: block;
  content: unset;
  position: absolute;
  z-index: 99;
  top: -16px;
  right: 200px;
  margin: auto;
  transform: rotate(0deg);
}
.support-menu-list-head {
position: relative;
background-color: #fff;
border-radius: 8px;
font-size: 0;
display: block;
width: 400px;
  margin-top: 21px;
}
.support-menu-list{
  border-radius: 4px;
  height: 76px;
font-size: 15px;
display: flex;
  align-items: center;
position: relative;
z-index: 1;
width: 100%;
}
.support-menu-list a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.support-menu-list h6{
  font-family: "Axiforma-Bold";
  color: #1B2952;
  font-size: 13px;
  margin: 0px;
  display: flex;
  align-items: center;
}
.support-menu-list h6 img{
  margin-left: 10.5px;
  opacity: 0;
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.support-menu-list p{
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 7px;
}
.support-menu-list div:nth-child(2){
  margin-right: 18px;
  padding-left: 19px;
}
.support-menu-list:hover h6 img{
  opacity: 1;
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.support-animation {
position: absolute;
height: 76px;
top: 0;
z-index: 0;
transition: all .2s ease 0s;
border-radius: 4px;
}
.support-menu-list:nth-child(1):hover~.support-animation {
  width: calc(100% - 20px);
  top: 0;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(2):hover~.support-animation {
  width: calc(100% - 20px);
  top: 76px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(3):hover~.support-animation {
  width: calc(100% - 20px);
  top: 152px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(4):hover~.support-animation {
  width: calc(100% - 20px);
  top: 228px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(5):hover~.support-animation {
  width: calc(100% - 20px);
  top: 304px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(6):hover~.support-animation {
  width: calc(100% - 20px);
  top: 380px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(7):hover~.support-animation {
  width: calc(100% - 20px);
  top: 404px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(8):hover~.support-animation {
  width: calc(100% - 20px);
  top: 480px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(9):hover~.support-animation {
  width: calc(100% - 20px);
  top: 556px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(10):hover~.support-animation {
  width: calc(100% - 20px);
  top: 632px;
  background-color: #F1F4FB;
}
.support-menu-list:nth-child(11):hover~.support-animation {
  width: calc(100% - 20px);
  top: 708px;
  background-color: #F1F4FB;
}
.support-menu-list.supportdisabled:hover~.support-animation {
  background-color: transparent;
  cursor: not-allowed!important;
}
.supportdisabled:hover , .supportdisabled a{
  cursor: not-allowed!important;
}
.supportdisabled:hover .supportgoto{
  display: none;
  opacity: 0;
}
.supportdisabled h6 {
  color: #BCC1CE;
}
.supportdisabled p {
  color: #BCC1CE;
}
.supportdisabled div:nth-child(2){
  opacity: .5!important;
}
.acount-holder-img{
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.acount-basic{
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 10px;
}
.acount-basic h2{
  font-family: "Axiforma-Regular";
  color: #1B2952;
  font-size: 13px;
  margin: 0px;
}
.acount-basic h3{
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.profile-menu-list{
  margin-top: 24px;
}
.profile-menu-list ul{
  list-style-type: none;
  padding-left: 10px;
}
.profile-menu-list ul li{
  margin-bottom: 24px;
  color: #1B2952;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: var(--custom_color3);
  }
}
.profile-menu-list ul li:hover{
  font-size: 13px;
  transition: .3s ease-in-out;
}
.profile-menu-list ul li a{
  color: #1B2952;
  font-size: 13px;
  text-decoration: none;
  transition: .3s ease-in-out;
  margin: 0px;
  display: block;
  &:hover {
    color: var(--custom_color2);
  }
}
.profile-menu-list ul li a:hover{
  font-size: 13px;
  text-decoration: none;
  transition: .3s ease-in-out;
}
.profile-account-type{
  border-top: 1px solid #EDF0F5;
  margin-bottom: 24px;
}
.profile-account-type-head{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 24px;
  padding-right: 20px;
  margin-left: 10px;
}
.profile-account-type-head h4{
  font-family: "Axiforma-Bold";
  color: #1B2952;
  font-size: 14px;
  margin: 0px;
}
.profile-account-type-head h5{
  font-family: "Axiforma-Regular";
  color: #BCC1CE;
  font-size: 10px;
  margin-bottom: 0px;
  margin-top: 9px;
}
.profile-account-type-head a{
  font-family: "Axiforma-Regular";
  color: #3064F9;
  font-size: 12px;
  margin: 0px;
  text-decoration: none;
  transition: .3s ease-in-out;
  margin-left: 11px;
}
.profile-account-type-head a:hover{
  font-family: "Axiforma-Regular";
  color: #3064F9;
  font-size: 12px;
  margin: 0px;
  text-decoration: none;
  transition: .3s ease-in-out;
  margin-left: 11px;
}
.profile-app-icon{
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.created-app-profile{
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  z-index: 1;
  width: calc(100% - 20px);
  padding-left: 10px;
  cursor: pointer;
}
.created-app-profile h5{
  font-family: "Axiforma-Regular";
  color: #1B2952;
  font-size: 13px;
  margin: 0px;
}
.created-app-profile h6{
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 9px;
}
.created-app-profile h6 span{
  font-family: "Axiforma-Regular";
  font-size: 10px;
  background-color: rgba(227, 230, 241,.7);
  border-radius: 20px;
  color: #7782A1;
  padding: 4px 12px 4px 12px;
  margin-left: 6px;
  line-height: normal;
  line-height: initial;
}
.create-app-profile{
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-left: 10px;
}
.create-app-profile img{
  margin-right: 10px;
}
.create-app-profile h5{
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 13px;
  margin: 0px;
}
.created-app-profile a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.create-app-profile a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.profile-app-list{
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  font-size: 0;
  display: block;
  width: 400px;
  margin-top: 21px;
}
.profile-app-animation {
position: absolute;
height: 72px;
top: 0;
z-index: 0;
transition: all .2s ease 0s;
border-radius: 4px;
}
.profile-animate:nth-child(1):hover~.profile-app-animation {
width: calc(100% - 20px);
top: 0;
background-color: #F1F4FB;
}
.profile-animate:nth-child(2):hover~.profile-app-animation {
width: calc(100% - 20px);
top: 72px;
background-color: #F1F4FB;
}
.created-app-profile.justify-content-between .profile-app-icon{
  border-radius: 100%;
}
.profile-app-list svg{
  opacity: 0;
  margin-right: 10px;
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  z-index: 1;
  cursor: pointer;
}
.profile-app-list .created-app-profile:hover svg{
  opacity: 1;
  stroke: #bcc1ce;
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.profile-app-list .created-app-profile:hover svg:hover{
  stroke: #3064F9;
  transition: .5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.header-mobile-elements{
  display: none;
}
.headertiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 10px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -3px;
  padding: 9px 12px 9px 12px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 24%);
  transition: all .2s ease 0s;
  width: max-content;
  z-index: 99999999;
}
.headertiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: calc(100% + 5px);
  top: 10px;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent rgb(0 0 0 / 80%) transparent;
  transform: rotate(90deg);
}
.myapptooltip{
  left: -79px;
}
.searchtooltip{
  left: -70px;
}
.logincustomertooltip{
  left: -128px;
}
.supporttooltip{
  left: -60px;
}
.notificationtooltip{
  left: -95px;
}
.rebuildtooltip{
  left: -115px;
}
.header-elements:hover .headertiptext {
  visibility: visible;
  transition: all .2s ease 0s;
}
.headertiptext:hover{
  visibility: hidden!important;
}
.header-plan-upgrade a{
  color: #3064F9!important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  margin-right: 12px;
}
.header-right-side{
  display: flex;
  align-items: center;
}
.header-search .css-2b097c-container{
  width: 280px;
  outline: none!important;
  margin-left: 20px;
  border-radius: 3px;
}
.header-search .css-yk16xz-control{
  border: 1px solid #EAECF4!important;
  background-color: #F8FAFE!important;
  font-size: 13px!important;
  color: #1B2952!important;
  border-radius: 3px;
  cursor: text;
}
.header-search .css-1pahdxg-control{
  outline: none!important;
  border: 1px solid #EAECF4!important;
  background-color: #F8FAFE!important;
  box-shadow: none!important;
  font-size: 13px!important;
  color: #1B2952!important;
  border-radius: 3px;
}
.header-search .css-tlfecz-indicatorContainer , .header-search .css-1gtu0rj-indicatorContainer {
  display: none!important;
}
.header-search{
  display: flex;
  align-items: center;
  position: relative;
  transition: .5s;
  transform: translateZ(-100px) rotateX( 0deg);
  opacity: 1;
  visibility: visible;
  width: max-content;
  position: relative;
  width: 100%;
}
.header-search-show{
  transition: .5s;
  transform: translateZ(100px) rotateX(  90deg);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0px;
}
.header-search h5{
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  cursor: pointer;
  position: absolute;
  right: 40px;
}
.header-search .css-1wa3eu0-placeholder{
  margin-top: 2px;
}
.header-search .css-1uccc91-singleValue{
  font-size: 13px!important;
  color: #1B2952!important;
  border-radius: 3px;
}
.header-search-cross{
  margin-left: 10px;
  cursor: pointer;
}
.login-customer-search{
  height: 52px;
  border-bottom: 1px solid #F1F2F8;
  padding: 0px 15px;
  position: relative;
}
.login-customer-search input{
  height: 50px;
  border: none;
  padding: 0px;
  outline: none;
  width: 100%;
  padding-right: 80px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.login-customer-search p{
  position: absolute;
  right: 15px;
  top: 17px;
  font-size: 12px;
  color: #BCC1CE;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.login-customer-search-loader{
  margin-top: 50px;
}
.login-customer-search-not-fount{
  margin-top: 102px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searched-customer{
  display: flex;
  align-items: center;
  margin: 0px 10px;
  padding: 20px 10px;
  height: 100px;
  position: relative;
  z-index: 1;
}
.searched-customer img{
  border-radius: 100%;
  width: 48px;
  height: 48px;
}
.searched-customer div{
  margin-left: 10px;
}
.searched-customer h4{
  font-size: 13px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.searched-customer h5{
  font-size: 12px;
  color: #7782A1;
  font-family: "Axiforma-Regular";
  margin: 8px 0px;
  max-width: 240px;
  word-break: break-all;
}
.searched-customer h6{
  font-size: 12px;
  color: #7782A1;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.searched-customer a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.searched-customer span{
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  color: #3064F9;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  height: 20px;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
  transition: .5s;
}
.searched-customer:hover span{
  opacity: 1;
  transition: .5s;
}
.animate-customer {
position: absolute;
height: 100px;
top: 0;
z-index: 0;
transition: all .2s ease 0s;
border-radius: 4px;
}
.searched-customer-height{
  position: fixed;
  background-color: #fff;
  font-size: 0;
  display: block;
  width: 400px;
  overflow: auto;
  height: 100%;
}
.searched-customer:nth-child(1):hover~.animate-customer {
  width: calc(100% - 20px);
top: 0;
  left: 10px;
background-color: #F8F9FD;
}
.searched-customer:nth-child(2):hover~.animate-customer {
  width: calc(100% - 20px);
top: 100px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(3):hover~.animate-customer {
  width: calc(100% - 20px);
top: 200px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(4):hover~.animate-customer {
  width: calc(100% - 20px);
top: 300px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(5):hover~.animate-customer {
  width: calc(100% - 20px);
top: 400px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(6):hover~.animate-customer {
  width: calc(100% - 20px);
top: 500px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(7):hover~.animate-customer {
  width: calc(100% - 20px);
top: 600px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(8):hover~.animate-customer {
  width: calc(100% - 20px);
top: 700px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(9):hover~.animate-customer {
  width: calc(100% - 20px);
top: 800px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(10):hover~.animate-customer {
  width: calc(100% - 20px);
top: 900px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(11):hover~.animate-customer {
  width: calc(100% - 20px);
top: 1000px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(12):hover~.animate-customer {
  width: calc(100% - 20px);
top: 1100px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(13):hover~.animate-customer {
  width: calc(100% - 20px);
top: 1200px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(14):hover~.animate-customer {
  width: calc(100% - 20px);
top: 1300px;
background-color: #F8F9FD;
  left: 10px;
}
.searched-customer:nth-child(15):hover~.animate-customer {
  width: calc(100% - 20px);
top: 1400px;
background-color: #F8F9FD;
  left: 10px;
}
.mobileglobalsearch{
  display: flex;
  box-shadow: 0px 0px 6px rgb(188 193 206 / 36%);
  height: 60px;
  padding: 0px 20px;
  position: relative;
}
.mobileglobalsearch button{
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
}
.mobileglobalsearch input{
  border: none;
  outline: none;
  margin-left: 17px;
  width: 100%;
  padding-right: 60px;
  font-size: 16px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
}
.mobileglobalsearch p{
  position: absolute;
  right: 20px;
  top: 22px;
  font-size: 12px;
  color: #BCC1CE;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.mobilesearchsuggestion{
  padding-top: 43px;
  overflow: auto;
  height: calc(100vh - 80px);
}
.mobilesearchsuggestion a{
  font-size: 16px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
  margin: 0px 20px 40px 20px;
  display: block;
}
.header-search-mobile{
  display: none;
}
.workspace-setting{
  position: absolute!important;
  right: 10px!important;
  left: auto!important;
  top: 0px!important;
  bottom: 0!important;
  width: 23px!important;
  height: 23px!important;
  margin: auto;
}
.header-loader{
  background-color: #fff;
  height: calc(100vh + 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -60px;
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 9999999;
  display: none;
}
.header-loader-show{
  display: flex;
}
.no-scroll{
  overflow: hidden;
}
.profile-mobile-menu .modal-body {
  padding: 0px;
}
.profile-mobile-head {
  display: flex;
  box-shadow: 0px 0px 6px rgb(188 193 206 / 36%);
  height: 60px;
  padding: 0px 20px;
  align-items: center;
}
.profile-mobile-menu-body h4{
  font-size: 16px;
  color: #1B2952;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.profile-mobile-menu-body h5{
  font-size: 12px;
  color: #7782A1;
  font-family: "Axiforma-Regular";
  margin: 0px;
  margin-top: 10px;
}
.profile-mobile-menu-body div:nth-child(1){
  margin-top: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #EDF0F5;
}
.profile-mobile-menu-body ul{
  padding-left: 20px;
  padding-top: 30px;
}
.profile-mobile-menu-body ul li a{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.profile-mobile-menu-body p{
  font-size: 16px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  padding-left: 14px;
}
.header-my-app-link{
  font-size: 0px;
}
.header-search-class{
  font-size: 13px!important;
}
.header-search-class__option{
  font-size: 13px!important;
  background-color: #ffffff!important;
}
.header-search-class__option:hover{
  font-size: 13px!important;
  background-color: #F8F9FD!important;
  cursor: pointer;
  border-radius: 4px;
}
.header-search-class__value-container .header-search-class__placeholder{
  color: #BCC1CE !important;
}



.profile-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 6px;
}
.profile-tooltip .profile-tooltipsubheader {
  visibility: hidden;
  width: 125px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 33px;
  left: -46px;
  padding: 4px 14px 3px 14px;
  opacity: 0.8 !important;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  line-height: 21px;
  display: grid;
  text-align: center;
  transition: 0.3s;
}
.profile-tooltip:hover .profile-tooltipsubheader {
  visibility: visible;
}
.profile-tooltip .profile-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}


.profile-arrow-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 6px;
}
.profile-arrow-tooltip .profile-arrow-tooltipsubheader {
  visibility: hidden;
  width: 135px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 33px;
  left: -93px;
  padding: 4px 14px 3px 14px;
  opacity: 0.8 !important;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  line-height: 21px;
  display: grid;
  text-align: center;
  transition: 0.3s;
}
.profile-arrow-tooltip:hover .profile-arrow-tooltipsubheader {
  visibility: visible;
}
.profile-arrow-tooltip .profile-arrow-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 81%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}
.youtubelink {
  display: flex;
  align-items: center;
  position: relative;
}
.youtubelink div {
  min-width: 56px;
}
.youtubelink p {
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding-left: 14px;
  margin-top: 5px;
  margin-bottom: 0;
  width: 100%!important;
}
.youtubelink p span {
  font-family: "Axiforma-Regular";
  color: #C8CEDB;
  font-size: 12px;
  position: relative;
  top: -4px;
}
.support-video-link{
  position: relative;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  top: 40px;
  padding-bottom: 40px;
}
.youtuber-sam-popup.modal-dialog{
  max-width: 100%!important;
}
.youtuber-sam-popup.modal-dialog .modal-content{
  background-color: transparent;
  border: none;
}
.youtuber-sam-popup.modal-dialog .modal-content .modal-body{
  margin: auto;
}
.youtuber-sam-popup.modal-dialog .modal-content .modal-body iframe{
  margin: auto;
}
.youtuber-sam-popup.modal-dialog .modal-content .modal-body svg{
  position: absolute;
  right: 14px;
  top: -16px;
  cursor: pointer;
}
.header-plus{
  position: relative;
}
.header-plus img{
  position: absolute;
  top: -9px;
  right: -20px;
}
.profile-first-section{
  display: flex;
  justify-content: space-between;
}
.profile-progressbar{
  margin-top: 24px;
  margin-right: 22px;
  position: relative;
  display: inline-block;
}
.profile-progressbar .CircularProgressbar .CircularProgressbar-path {
  stroke: #80A0FF;
}
.profile-progressbar .CircularProgressbar .CircularProgressbar-text{
  fill: #7782A1!important;
  font-family: "Axiforma-Bold"!important;
}
.profile-progressbar-tooltipsubheader{
  visibility: hidden;
  width: 220px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 55px;
  right: 0px;
  padding: 15px 15px 5px 15px;
  opacity: 0.8 !important;
  display: grid;
  transition: 0.3s;
}
.profile-progressbar:hover .profile-progressbar-tooltipsubheader{
  visibility: visible;
  text-align: left !important;
}
.profile-progressbar .profile-progressbar-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: -8px;
  right: 16px;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}
.profile-progressbar-tooltipsubheader h4{
  font-family: "Axiforma-Regular";
  color: #FFFFFF;
  font-size: 12px;
  margin: 0px 0px 10px 0px;
}
.profile-progressbar-tooltipsubheader h6{
  font-family: "Axiforma-Regular";
  color: #C8CEDB;
  font-size: 10px;
  margin: 0px 0px 10px 0px;
}
.profile-progressbar-tooltipsubheader div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-progressbar div a{
  position: absolute;
  width: 100%;
  height: 100%;
}
.support-seprator{
  border-top: 1px solid #EDF0F5;
  margin: 12px 0px;
}
.support-plan-right-panel{
  margin-bottom: 16px!important;
  margin-right: 10px;
  box-shadow: none;
}






















@media (min-width: 2300px) {
  .youtuber-sam-popup.modal-dialog .modal-content .modal-body iframe{
    width: 2000px;
    height: 850px;
  }
}







@media (max-width: 1200px) {
  .profile-app-list{
    width: unset;
  }
  .billing-newmob-sidebar{
      box-shadow: 0px 0px 6px rgb(188 193 206 / 36%);
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 22px;
    }
    
    .billing-newmob-sidebardiv{
      padding-left: 20px;
    }
    .billing-newmob-sidebardivid h4{
      font-size: 16px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
    }
    .billing-newmob-sidebardivid h5{
      font-size: 12px;
      font-family: 'Axiforma-Regular';
        color: #7782A1;
        margin-top: 5px;
        margin-bottom: 0px;
    }
    .billing-newmob-sidebardivid{
      padding: 24px 0px 20px;
        border-bottom: 1px solid #EDF0F5;
    }
    .billing-newmob-sidebardiv ul{
      padding: 0px;
      
    }
    .billing-newmob-sidebardiv ul li a{
      display: flex;
      padding: 29.5px 0px 10.5px 0px;
    }
    .billing-newmob-sidebardiv ul li a p{
     margin: 0px;
     padding: 0px;
        margin-left: 12px;
        font-size: 16px;
        color: #1B2952;
    
    }
    .billing-newmob-sidebardiv ul li a svg{
      margin: 0px;
     
     }
  .header-width {
      padding: 0px 20px 0px 20px;
  }
  .header-elements {
      margin-left: 14px;
  }
  
  .header-plan-upgrade a {
    margin-right: 0px;
}
  .header-web-logo{
      display: none;
  }
  .header-mobile-elements{
      display: block;
  }
  .support-menu::before{
      right: 124px;
  }
  .create-menu::before{
      right: 255px;
  }
  .profile-menu::before{
      right: 28px;
  }
  .login-customer::before{
      right: 168px;
  }
  .profile-menu{
      width: 100%;
      right: -100%;
  }
  .support-menu {
      width: 100%;
      right: -100%;
  }
  .login-customer {
      width: 100%;
      right: -100%;
  }
  .show-profile-menu, .show-support-menu, .show-login-customer{
      right: 0%;
      width: 100%;
  }
  .header-mobile-elements svg{
      margin-right: 20px;
  }
  /* .header-plan-upgrade{
      display: none;
  } */
  .header-search-mobile{
      display: flex;
  }
  .header-search-web{
      display: none;
  }
  .searched-customer span{
      opacity: 1!important;
  }
  .searched-customer-height {
      width: 100%;
  }
  .support-menu-list-head {
    width: 100%;
  }

  .youtuber-sam-popup.modal-dialog .modal-content .modal-body iframe{
    max-width: 100%;
    height: 100%;
  }
  .youtuber-sam-popup.modal-dialog .modal-content .modal-body svg {
    top: 10px;
  }
  .youtuber-sam-popup.modal-dialog .modal-content .modal-body {
    padding: 0px;
  }
  .support-menu-height{
    max-width: 100%;
    overflow-x: hidden;
  }
  .header-plan-upgrade{
    display: none;
  }

}


@media (max-width: 600px) {
  .icon-hide-for-mob{
    display: none !important;
  }
}
@media (max-width: 360px) {
  .icon-hide-for-smallmob{
    display: none !important;
  }
}
@media (max-width: 380px) {

.support-menu::before{
  top: -24px;
}
.profile-menu::before{
  top: -24px;
}
.login-customer::before{
  top: -24px;
}

}

