.webView-header-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.webView-header-right{
    display: flex;
        align-items: center;
        gap: 20px;
}
.webView-updated-date{
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #bcc1ce;
    margin-bottom: 0px;
}
.webView-save-btn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #3064f9;
    border: none;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 40px;
    transition: 0.5s;
}
.webView-save-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.webView-page-layout{
    background-color: #fff;
    border-radius: 6px !important;
    border: 1px solid #e3e6f1 !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    display: flex;
}
.webView-page{
    width: 100%;
}
.webView-width {
    display: flex;
}
.webView-settings{
    width: 50%;
    min-width: 50%;
    padding-left: 30px;
}
.webview-cache-selection-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.webview-cache-number-type{
    /* width: 50% !important; */
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0 15px;
    transition: .5s;
    outline: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
}
.webview-cache-number-type:hover{
    outline: none;
    background-color: #f8fafe !important;
    border-color: #3064f9 !important;
    transition: .5s !important;
}
/* .webview-cache-selection-div .webViewdropdown{
    width: 50%;
} */
.webView-settings-head{
        padding: 19px 0px 19px 0px;
        border-bottom: 1px solid #e3e6f1;
    
}
.webView-settings-title {
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1b2952;
    margin-bottom: 7px;
}
.webView-settings-description {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782a1;
    margin-bottom: 0px;
}

.webview-setting-one-title {
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #1b2952;
    margin: 30px 0px 7px;
}
.webview-settings-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.webview-settings-toggle-title{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782a1;
    margin: 0px;
    display: flex;
  
}

.webview-bottom-menu {
    border-top: 1px solid #e3e6f1;
    margin-top: 30px;
}
.webView-color-picker-label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782a1;
    margin-bottom: 9px;
}

.webview-input-type-div{
    margin-top: 30px !important;
    position: relative;
}
.webview-input-type-title{
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    margin: 0 0 9px;
    display: flex;
    align-items: center;
}
.webview-input-type-subtitle{
    position: absolute;
    right: 55px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #bcc1ce;
    top: 45px;
}
.webview-input-type-input{
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0 15px;
    transition: .5s;
    outline: none;
    font-size: 13px;
    font-family: "Axiforma-Regular";
}
.webview-input-type-input:hover{
    outline: none;
    background-color: #f8fafe !important;
    border-color: #3064f9 !important;
    transition: .5s !important;
}
.webview-input-type-purge{
    font-family: "Axiforma-Regular";
font-size: 13px;
font-weight: 400;
line-height: 17.47px;
color: #3064F9;
position: absolute;
top: 43px;
right: 47px;
}


.cache-duration-disable .webview-cache-number-type{
    border: 1px solid #E3E6F1 ;
      background-color: #F8FAFE;
      color: #BCC1CE;
      cursor: not-allowed;
  }
  .webview-cache-number-type[type=number]::-webkit-inner-spin-button {
    padding-left: 20px;
  }
  .cache-duration-disable .webview-cache-number-type[type=number]::-webkit-inner-spin-button {
    opacity: 0.5;
    background-color:  #F8FAFE;
    cursor: not-allowed;
  }
  .cache-duration-disable .webview-cache-number-type:hover{
    border: 1px solid #E3E6F1 !important ;
    background-color: #F8FAFE !important;

}
.cache-duration-disable .search-dropdown .dropdown-toggle{
    border: 1px solid #E3E6F1 !important;
    background-color: #F8FAFE !important;
    cursor: not-allowed !important;
    color: #BCC1CE !important;
    background-position: 98% !important;
}
.cache-duration-disable .search-dropdown .dropdown-toggle:hover{
    border: 1px solid #E3E6F1 !important;
    background-color: #F8FAFE !important;
    cursor: not-allowed !important;
}
.webview-color-picker-div{
    margin-top: 30px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.webview-color-picker-second-div{
    margin-top: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.webView-theme-options-div{
position: relative;
}
.webview-theme-gap{
    margin-top: 17px !important;
    margin-bottom: 3px;
}
.webView-theme-options-div .color_input {
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    background-color: #fff;
    padding: 7px 7px 7px 3px !important;
    height: 50px !important;
    transition: 0.5s !important;
    position: relative;
}
.webView-theme-options-div .color_input p {
    font-size: 13px;
    color: #1b2952;
    margin-bottom: 0px;
    font-family: "Axiforma-Regular";
}
.webview-mobile-btns{
   display: none;
}
.web-view-input {
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    transition: 0.5s;
    outline: none;
    margin: 22px 0px 0px 0px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
  }
  .web-view-input:focus,
  .web-view-input:hover {
    background-color: #f8fafe;
    border-color: #3064f9 !important;
    transition: 0.5s;
  }
  .webview-right {
    width: 50%;
    min-width: 50%;
  }
  .webview-preview-right {
    padding: 75px 30px 60px 30px;
    border-left: none;
  }
  .webview-preview-screen {
    height: 651px;
    width: 310px;
    background-color: #f9f9f9;
    margin: auto;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
    overflow-y: auto;
    overflow-x: hidden;
    border: 4px solid #e3e6f1;
    border-radius: 20px;
    position: relative;
  }
  .webview-preview-screen::-webkit-scrollbar {
    width: 0px !important;
  }
  .webview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0px 10.5px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 6%);
    background-color: #fff;
  }
  .webview-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 10.5px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 6%);
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .webview-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
  }
  .webview-ams-header-one {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px 0px 12px;
  }
 
  .webview-ams-header-one .webview-ams-header-right svg:first-child {
    margin-left: 0px;
  }
  .webview-ams-header-right {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .webview-ams-header-left {
    display: flex;
    align-items: center;
    gap: 11px;
    
  }
  .webview-ams-header-title{
    font-size: 10px;
    font-family: "Axiforma-Bold";
    color: #ffffff;
    margin: 0px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 14px;
  }
  .webview-ams-header-domain{
    font-size: 8px;
    font-family: "Axiforma-Regular";
    color: #ffffff;
    margin: 0px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .webview-ams-header-two {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px 0px 12px;
  }
  /* .webview-ams-header-left-two svg {
    margin-right: 30px;
  } */
  .webview-ams-header-left-two svg:last-child {
    margin-right: 0px;
  }
  /* .webview-ams-header-right-two svg {
    margin-left: 30px;
  } */
  .webview-ams-header-right-two svg:first-child {
    margin-right: 0px;
  }
  .webview-ams-header-left-two,
  .webview-ams-header-right-two {
    display: flex;
    align-items: center;
    gap: 30px
  }
  .webview-ams-header-center-div{
    text-align: center;
  }
 
  
 
@media screen and (max-width: 1200px) {
    .webView-header-right{
        display: none;
    }
    .webview-settings-toggle-title {
      width: 70%;
    }
    .webview-settings-toggle-title .full-page-tooltip .full-page-tooltipsubheader, .webView-color-picker-label .full-page-tooltip .full-page-tooltipsubheader {
      left: -90px;
    }
    .webview-settings-toggle-title .full-page-tooltip .full-page-tooltipsubheader::after, .webView-color-picker-label .full-page-tooltip .full-page-tooltipsubheader::after {
      left: 90px;
    } 
    .webview-input-type-title .full-page-tooltip .full-page-tooltipsubheader{
      left: -90px;
    }
    .webview-input-type-title .full-page-tooltip .full-page-tooltipsubheader::after{
      left: 90px;
    } 
    .webview-right {
        display: none;
      }
    .webview-cache-selection-div {
        grid-template-columns: 1fr;
            grid-gap: 10px;
    }
    .webview-color-picker-div {
        grid-template-columns: 1fr;
    }
    .webview-cache-number-type{
        width: 100% !important;
    }
    .webview-cache-selection-div .webViewdropdown{
        width: 100%;
    }
    .webview-color-picker-second-div {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }
    .webview-theme-gap {
        margin-top: 0px !important;
        margin-bottom: 0px;
    }
    .webView-settings {
        width: 100%;
        padding-left: 0px;
    }
    .webView-page-layout {
        margin: 0px 15px;
        width: unset;
        padding-bottom: 30px;
    }
    .webView-settings-head {
        padding: 20px;
    }
    .webview-bottom-menu, .webview-setting-one{
        margin: 30px 20px 0px;
    }
    .webview-mobile-btns{
        margin: 15px;
        display: block;
    }
    .webView-updated-date{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #bcc1ce;
        text-align: center;
        margin-top: 17px;
        width: 100%;
    }
    .webview-mobile-btns-blue{
        border: 1px solid #3064F9;
        height: 50px;
        background-color: #3064F9;
        border-radius: 3px;
        color: #fff;
        width: 100%;
        transition: .5s;
        position: relative;
        margin-top: 20px;
        text-align: center;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}