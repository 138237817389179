.siteground-text-info, .wpengine-text-info {
    line-height: 1.8 !important;
}
.webhostspac-redesign {
    padding-bottom: 0 !important;
}
.wpengine-redesign-subtext {
    line-height: 1.8 !important;
}
.row-security-redesign {
    padding-bottom: 30px !important;
}
.row-security-second-redesign {
    padding-top: 61px !important;
}
.row-security-maintenance {
    padding-bottom: 47px !important;
}
.threesteps .col-md-6 .security-steps {
    padding: 28px 35px 54px 45px !important;
}
.threesteps .col-md-6 .security-steps .subheadings2 {
    font-family: 'Axiforma-Bold' !important;
    font-size: 20px !important;
    color: #0F193B !important;
    margin-bottom: 20px !important;
}
.threesteps .col-md-6 .security-steps .normaltext {
    font-family: 'Axiforma-Regular' !important;
    font-size: 14px !important;
    color: #5F6578 !important;
}
.blueback-redesign {
    padding-top: 49px !important;
}
.phone-host-laptop {
    padding-bottom: 3px !important;
}
@media (max-width: 767px) {
    .subheadings1-mobile {
        text-align: left !important;
    }
    .head-cloudflare {
        margin-bottom: 0 !important;
    }
}