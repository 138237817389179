.vscreate-open-btn{
  background: white url(../../assets/images/dashboard/vs-cretebtnback.png);
  background-repeat: no-repeat;
  border: none;
  border-radius: 3px;
  color: white;
    font-family: 'Axiforma-Regular';
    height: 40px;
    font-size: 14px;
    align-items: center;
    padding: 11px 23.5px;
    display: flex;
    /* margin-left: 30px; */
}
.aunsplash{
  margin-left: 30px;
}
.category-pagehead{
height: 60px !important;
}
.post-img-ratio-div{
  display: flex;
      height: 44px;
      align-items: center;
      gap: 30px;
     
}
.color-plates label:last-child{
  margin-right: 0px !important;
}
.bottombar-pexels-unsplash{
  margin: 0px !important;
    justify-content: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
}
.web-footer-error{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.web-footer-error p{
  font-size: 12px;
  line-height: 12px;
  font-family: 'Axiforma-Regular';
  color: #FF4848;
  margin-bottom: 0px;
  margin-left: 5.5px;
  margin-right: 19.5px;
  margin-top: 3px;
}
.bottombar-pexels-unsplash p{
  margin-bottom: 0px;
  font-size: 10px;
  font-family: 'Axiforma-Regular';
  color: #BCC1CE;
  margin-top: 9px;
}
.goback-mediaupload, .mob-uploadpopup-error, .vscreate-open-mobbtn, .mob-view-selectmediahead, .image-mobile-filter-btn, .mob-filter-head, .mob-upload-done-error, .delete-mobupload-image {
  display: none;
}
.gallery_color_input svg{
  cursor: pointer;
}
.vscreate-open-btn svg{
margin-right: 5px;
}
.footer-image-selectedtext{
  margin-bottom: 0px;
    font-size: 12px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-right: 20px;
}
.whitebg {
  background-color: #fff;
  border-radius: 6px!important;
  border: 1px solid #E3E6F1!important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%)!important;
}
.pageshead {
  padding: 0px 0px 17px 0px;
  display: flex;
  align-items: flex-start;
    justify-content: space-between;
    margin-top: 6px;

}
.pageshead h1 {
  font-family: "Axiforma-Bold";
  font-size: 24px;
  line-height: 27px;
  color: #1b2952;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.pageshead p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17px;
  color: #7782a1;
  margin-bottom: 0px;
}
.pageshead .profile-latest-update{
  color: #bcc1ce;
}
.pageshead p a {
  font-family: "Axiforma-Regular";
  color: #3064f9;
  display: none;
}
.createpage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.createpage div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cms-pages-search{
  position: relative;
}
.createpage .cms-pages-search input {
  width: 300px;
  height: 40px;
  border: 1px solid #e3e6f1;
  padding: 0px 17px 0px 40px;
  font-size: 13px;
  border-radius: 2px 0px 0px 2px;
}
.inputSearchclear {
  font-size: 12px;
    font-family: 'Axiforma-Regular';
    cursor: pointer;
    color: #BCC1CE;
    margin-right: 15px;
}
.createpage .cms-pages-search input:focus,
.createpage .cms-pages-search input:focus-visible {
  border: 1px solid #e3e6f1;
  outline: none;
}
.createpage .cms-pages-search img{
  position: absolute;
  left: 14px;
  top: 11px;
}
.createpage .createnew {
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  margin-left: 20px;
  text-transform: capitalize;
  font-family: "Axiforma-Regular";
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}
.createpage .createnew p{
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  position: relative;
  top: 1px;
  &:hover {
    color: var(--custom_color4)!important;
  }
}
.createpage .createnew:hover {
  /* background-color: #0040f9;
  border: 1px solid #0040f9; */
  transition:0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}

.createpage .saveasdraft-disable p {
  font-size: 14px;
  position: relative;
  top: 1px;
}
.saveasdraft-disable{
  background-color: transparent;
  color: #bcc1ce;
  border: 1px solid #bcc1ce;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px !important;
  width: 120px;
  height: 40px;
  cursor: not-allowed;
  /* text-transform: capitalize; */
  font-family: "Axiforma-Regular";
}
.select-popup-for-buttonblock{
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  padding: 11px 10px 11px 15px !important;
  height: 50px !important;
  transition: .5s !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.select-popup-for-buttonblock:hover{
  border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: 0.5s;
}
.select-popup-for-buttonblock span{
  color: #1b2952;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.createpage .saveasdraft {
  background-color: transparent;
  color: #3064f9;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 120px;
  height: 40px;
  /* text-transform: capitalize; */
  font-family: "Axiforma-Regular";
  &:hover {
    /* background-color: var(--custom_color5)!important; */
    /* color: var(--custom_color4)!important; */
    /* border: 1px solid var(--custom_color6)!important; */
  }
}
.createpage .saveasdraft p{
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  position: relative;
  top: 1px;
}
.createpage .saveasdraft:hover {
  color: #0040f9;
  border: 1px solid #0040f9;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.createpage .backbutton {
  background-color: #fff;
  color: #7782a1;
  border: 1px solid #bcc1ce;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  margin-right: 20px;
  text-transform: uppercase;
  font-family: "Axiforma-Regular";
}
.createpage .backbutton:hover {
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.createpage .createpage {
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 120px;
  height: 40px;
  margin-left: 20px;
  text-transform: capitalize;
  font-family: "Axiforma-Regular";
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}
.createpage .createpage p{
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  position: relative;
  top: 1px;
  &:hover {
    color: var(--custom_color4)!important;
  }
}
.createpage .createpage:hover {
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
  transition:0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.createpage select {
  background: white url(../../assets/images/dashboard/grey-dropdown.png);
  background-repeat: no-repeat;
  background-position: 96%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular2";
  border: 1px solid #e3e6f1;
  color: #7782a1;
  height: 40px;
  width: 160px;
  border-right: none;
  padding-left: 17px;
  font-size: 13px;
  outline: none;
}
.nopagecreated {
  text-align: center;
  min-height: 78vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nopagecreated div h4 {
  font-family: "Axiforma-Bold";
  font-size: 20px;
  line-height: 27px;
  color: #1b2952;
  margin-top: 32px;
}
.nopagecreated div p {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 24px;
  color: #7782a1;
  margin-top: 16px;
}
.pagedetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px 23px 20px;
  min-height: 128px;
  border-bottom: 1px solid #F1F2F8;
  position: relative;
  /* animation: theshadow 2s; */
}
.pagedetail:hover{
  background-color: #F8FAFE;
}
.pagedetail-name {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 8px;
}
.page-list-link{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.pagedetail-name h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  word-break: break-word;
}
.pagedetail-name a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.postdetail {
  min-height: 111px;
  padding: 24px 10px 24px 20px!important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #F1F2F8;
}
.postdetail-name {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.postdetail-name h3{
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  line-height: 28px;
  margin-top: 0px;
  margin-left: 14px;
  margin-bottom: 0px;
  width: 90%;
  word-break: break-word;
}
.postdetail-name p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-left: 14px;
  text-align: left!important;
}
.postdetail-name a {
  position: absolute;
  width: 100%;
  height: 100%;
}
.postdetail-name .post-thumbnail {
  width: 80px;
  min-width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
}
.post-list-featured {
  width: 58px;
  display: flex;
}
.post-list-featured img {
  cursor: pointer;
}
.pagecreator-detail {
  display: flex;
  align-items: center;
}
.postcreator-detail{
  display: flex;
  align-items: center;
  margin-left: 14px;
}
.pagecreator-detail div {
  width: 24px;
  height: 24px;
}
.postcreator-detail div{
  width: 24px;
  height: 24px;
}
.pagecreator-detail div img {
  border-radius: 100%;
}
.postcreator-detail div img{
  border-radius: 100%;
}
.pagecreator-detail h2 {
  font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin: 0px;
    margin-left: 10px;
    margin-top: 2px;
}
.postcreator-detail h2 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782A1;
    margin: 0px;
    margin-left: 10px;
    margin-top: 2px;
}
.pagestatussection h4 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  margin: 0px;
}
.pagestatus {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
  text-transform: capitalize;
  margin-right: 10px;
}
.statusgreen {
  color: #47ce70!important;
}
.statusgrey {
  color: #7782a1!important;
}
.statusblue {
  color: #3064f9!important;
}
.pageaction {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 150px;
  min-width: 150px;
  z-index: 1;
}
.pageaction a{
  margin: 0px 25px;
}
.pageaction img{
  cursor: pointer;
}
.pageaction button:nth-child(2) {
  margin-left: 40px;
}
.pageaction button {
  border: none;
  background-color: transparent;
}
.pagestatussection {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.firstchild {
  padding-left: 30px;
}
.secondchild {
  padding-left: 60px;
}
.thirdchild {
  padding-left: 90px;
}
.createpage div input::placeholder {
  color: #c8cedb;
  opacity: 1;
}

.createpage div input:-ms-input-placeholder {
  color: #c8cedb;
}

.createpage div input::-ms-input-placeholder {
  color: #c8cedb;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bcc1ce;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcc1ce;
}
.zoom-range{
  width: 360px;
  height: 4px;
  margin-right: 170px;
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcc1ce;
}
.undosection {
  margin-right: 40px;
  display: none !important;
}
.undosection a:nth-child(1) {
  margin-right: 40px;
}
.page-preview-screen {
  height: 651px;
  width: 310px;
  background-color: #F9F9F9;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: auto;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
}
.create-page-preview {
  padding: 70px 20px 60px 0px;
  border-left: none;
}
.preview-header {
  height: 45px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  background-color: #fff;
}
.preview-header div{
  display: flex;
  align-items: center;
}
.preview-header h1 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 12px;
  margin-bottom: -3px;
  margin-top: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 237px;
  white-space: nowrap;
}
/* Faisal Changes */
.preview-header img{
width: unset !important;
}
.posts-preview-list .slider{
  background-color: unset !important;
}

.post-listing-head img{
  width: unset !important;
}
.posts-preview-detail .control-dots{
display: none !important;
}

.posts-preview-detail .carousel-status {
  display: none !important;
}

.posts-preview-detail .carousel .slider-wrapper {
  width: 100%;
 
}

.post-list-bookmark-img{
  position: absolute;
    right: 5px;
    top: 5px;
}

.post-type-one-thumbnail img{
  width: unset !important;
}


.fullwidthbanner {
  width: 100%;
}
.page-editor {
  /* border-right: 1px solid #e3e6f1; */
  /* min-height: 760px; */
  padding-left: 20px;
  transform:translateZ(1px);
}
.page-editor::-webkit-scrollbar{
  width: 3px !important;
}
.pageaction .cms-icon-tooltip .cms-icon-tooltipsubheader {
  transition: 0s;
}
.headings {
  font-family: "Axiforma-Regular";
  color: #333333;
  font-size: 15px;
  margin: 0px;
  line-height: 21.08px;
  word-break: break-word;
}
.page-body {
  padding: 0px;
}
.page-preview-screen::-webkit-scrollbar {
  width: 0.1px !important;
}

.page-preview-screen::-webkit-scrollbar-track {
  background-color: rgba(256, 256, 256, 0.1) !important;
  border-radius: 9px !important;
}

.page-preview-screen::-webkit-scrollbar-thumb {
  background-color: rgba(256, 256, 256, 0.1) !important;
  border-radius: 9px !important;
}
/* .pages-tabs.nav-tabs a{
  padding: 0px 10px;
} */
.Separator {
  border-style: solid;
}
.page-body p , .page-body ul li , .page-body ol li, .page-body span {
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  line-height: 15px;
  word-break: break-word;
}
.page-body a{
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  line-height: 14.78px;
  color: #3064f9!important;
}
.page-body ul , .page-body ol{
  margin-bottom: 0px;
}
.pageslistdetail {
  min-height: 78vh;
  min-width: 50%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}
.post-pageslistdetail{
  height: 100vh !important;
}
.pageslistdetail::-webkit-scrollbar{
  width: 3px !important;
}
.pages-options a {
  font-family: "Axiforma-Bold";
  color: #7782a1 !important;
  font-size: 14px;
}
.pages-options a:active {
  color: #3064f9;
}
.pages-options .addmoreblock,
.pages-options .addmoreblock:hover,
.pages-options .addmoreblock:focus {
  color: #3064f9 !important;
  font-family: "Axiforma-Regular" !important;
  font-size: 13px !important;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.pages-tabs.nav-tabs {
  border: none !important;
  border-radius: none !important;
}
.pages-tabs .nav-link {
  border: none !important;
  border-radius: none !important;
  padding-bottom: 13px;
  margin-right: 20px;
}
.pages-tabs .nav-link.active {
  color: #3064f9 !important;
  border: none;
  border-bottom: 2px solid #3064f9 !important;
}
.pages-tabs.nav-tabs .nav-link:hover,
.pages-tabs .nav-tabs .nav-link:focus {
  border: none !important;
  border-bottom: 2px solid #3064f9 !important;
  color: #3064f9 !important;
}
.pages-tabs.nav-tabs .nav-item {
  margin-bottom: -7px;
}
.pages-fields input{
  height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #FFFFFF;
  border: 1px solid #BCC1CE;
  padding: 0px 20px 0px 20px;
  outline: none;
  font-size: 13px;
  color: #1B2952;
  font-family: "Axiforma-Regular";
  transition: .5s;
}
.pages-fields input:hover , .pages-fields input:focus , .pages-fields input:focus-visible{
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s!important;
}
.bulk-delete-cross-head{
  position: relative;
  /* padding: 0px !important; */
}
.delete-popup.bulk-delete-popup .modal-body {
  min-height: 218px !important;
  padding: 40px 40px;
}
.bulk-delete-cross-head svg{
  position: absolute;
  right: 15px;
  top: 15px;
}
.pages-fields input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bcc1ce;
  opacity: 1; /* Firefox */
}

.pages-fields input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcc1ce;
}

.pages-fields input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcc1ce;
}
.select-img-ratio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #f8fafe;
  margin-bottom: 0px !important;
}
.select-img-ratio div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e3e6f1;
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin-bottom: 0px;
}
.select-img-ratio div:last-child {
  border-right: none;
}
.select-img-ratio div p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 0px;
  margin-left: 10px;
}
.img-ratio > input:checked + div {
  background-color: #e3e6f1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.img-ratio > input {
  display: none;
}
.img-ratio > input + div svg {
  stroke: #bcc1ce !important;
}
.img-ratio > input + div svg {
  fill: #f8fafe !important;
}
.img-ratio > input:checked + div svg {
  stroke: #3064f9 !important;
}
.img-ratio > input:checked + div svg {
  fill: #e2eaff !important;
}
.select-img-ratio label {
  margin-bottom: 0px !important;
  width: 100%;
}

/* Heading CSS */
.heading-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  background-color: #f8fafe;
  margin-top: 20px;
  margin-bottom: 0px !important;
}
.heading-options div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e3e6f1;
  width: 100%;
  height: 50px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
}
.heading-options div:last-child {
  border-right: none;
}
.heading-ratio > input:checked + div {
  background-color: #e3e6f1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.heading-ratio > input {
  display: none;
}
.heading-ratio > input + div svg {
  stroke: #bcc1ce !important;
}
.heading-ratio > input + div svg {
  fill: #f8fafe !important;
}
.heading-ratio > input:checked + div svg {
  stroke: #3064f9 !important;
}
.heading-ratio > input:checked + div svg {
  fill: #e2eaff !important;
}
.heading-options label {
  margin-bottom: 0px !important;
  width: 100%;
}
/* Heading CSS */
.duplicate-delete {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
  padding-right: 2px;
}

.duplicate-delete .cms-dup-del-btn:nth-child(1),
.duplicate-delete .cms-dup-del-btn:nth-child(1):hover,
.duplicate-delete .cms-dup-del-btn:nth-child(1):focus {
  color: #bcc1ce;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.duplicate-delete .cms-dup-del-btn:nth-child(2),
.duplicate-delete .cms-dup-del-btn:nth-child(2):hover,
.duplicate-delete .cms-dup-del-btn:nth-child(2):focus {
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
}

.duplicate-delete a:nth-child(1),
.duplicate-delete a:nth-child(1):hover,
.duplicate-delete a:nth-child(1):focus {
  color: #bcc1ce;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-decoration: none;
  display: flex;
  align-items: center;
}
.duplicate-delete a:nth-child(2),
.duplicate-delete a:nth-child(2):hover,
.duplicate-delete a:nth-child(2):focus {
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 25px;
}
/* .duplicate-delete a img {
  margin-right: 8px;
} */
.set-full-img {
  width: 100%;
}
.image-uploader {
  border: 1px solid #BCC1CE;
  background-color: #fff;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  transition: .5s!important;
}
.image-uploader:hover , .image-uploader:focus , .image-uploader:focus-visible{
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s!important;
}
.image-uploader .d-flex {
  width: calc(100% - 50px);
}
.image-uploader .uploadpixabayimage {
  background-position: 50% !important;
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
}
.image-uploader p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1b2952;
  font-size: 13px !important;
  margin-left: 7px;
}
.image-uploader svg {
  z-index: 20;
}
.heading-bold {
  font-family: "Axiforma-Bold" !important;
}
.heading-underline {
  text-decoration: underline;
}
.heading-italiq {
  font-style: italic;
}
.heading-left {
  text-align: left;
}
.heading-center {
  text-align: center;
}
.heading-right {
  text-align: right;
}

/* select Block */
.addblockpopup .modal-header {
  padding: 23px 0px 23px 20px;
  border-bottom: 1px solid #E3E6F1!important;
}
.addblockpopup .modal-header h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.addblockpopup .modal-body {
  background-color: #fff;
  min-height: 400px;
  height: 520px;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}
.select-block-type {
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.select-block-type .image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 137px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  background-color: #ffffff;
  border: 1px solid #E3E6F1;
  transition: .5s;
}
.select-block-type .form-group {
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
.select-block-type .form-group span{
  visibility: hidden;
  width: 137px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: 0px;
  right: 0px;
  padding: 4px 14px 3px 14px;
  opacity: 0.8 !important;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  line-height: 18px;
  display: grid;
  text-align: center;
  transition: 0.3s;
}
.block-type-tooltip1{
  bottom: -70px;
}
.block-type-tooltip2{
  bottom: -87px;
}
.block-type-tooltip3{
  bottom: -105px;
}
.select-block-type .form-group span::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 47%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}
.select-block-type .form-group:hover span{
  visibility: visible;
  transition: 0.3s;
}
.select-block-type > input {
  display: none;
}
.select-block-type label {
  margin-bottom: 0px !important;
  width: 100%;
}
.select-block-type .image p {
  margin-top: 20px;
  color: #7782a1;
  font-size: 13px;
  transition: .5s;
}
.select-block-type .image svg {
  margin: auto;
  margin-top: 35px;
  fill: #bcc1ce;
  transition: .5s;
}
/* .addblock-ratio input:checked + .image:before {
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
  content: url("../../assets/images/dashboard/tick.png");
  display: block;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 140px;
  transition: 0.4s;
  z-index: 3;
  border: 2px solid #3064f9;
} */
.addblock-ratio input:checked + .image , .addblock-ratio:hover input + .image{
  background-color: #F1F4FB;
  border: 1px solid #3064F9;
  transition: .5s;
}
.addblock-ratio input:checked + .image p , .addblock-ratio:hover input + .image p{
  color: #3064f9;
  transition: .5s;
}
.addblock-ratio input:checked + .image svg , .addblock-ratio:hover input + .image svg{
  fill: #3064f9;
  transition: .5s;
}
.addblock-ratio > input {
  display: none;
}
.addblockpopup .modal-footer {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E3E6F1!important;
}
.addblockpopup .modal-footer button:nth-child(1) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 13px;
  transition: 0.5s;
  font-family: 'Axiforma-Regular';
}
.addblockpopup .modal-footer button:nth-child(1):hover {
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.addblockpopup .modal-footer button:nth-child(2) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #3064f9 !important;
  background-color: #3064f9 !important;
  color: #fff !important;
  font-size: 13px;
  transition: 0.5s;
  font-family: 'Axiforma-Regular';
}
.addblockpopup .modal-footer button:nth-child(2):hover {
  background-color: #0040f9;
  border: 1px solid #0040f9;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}

/* select Block */

.select-parent {
  /* background-color: #f1f5fd; */
  /* padding: 6px 30px 30px 30px; */
}
.select-parent .parentsection {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 15px 15px 18px 15px; */
  background-color: #fff;
}
.select-parent .parentsection .react-datepicker__input-container {
  z-index: 9;
}
.select-parent .parentsection .react-datepicker-wrapper {
 width: 100%;
}
.select-parent .parentsection img{
  position: absolute;
  right: 15px
}
.tagparent {
  min-height: 50px;
  height: auto !important;
}
.taglist {
  border: none !important;
  height: auto !important;
  padding: 0px !important;
  min-height: auto !important;
}
.select-parent div a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.select-parent label {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
  margin-top: 20px;
}
.select-parent div p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  /* width: calc(100% - 40px); */
  margin-bottom: 0px;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;
    max-width: 200px; */
}
.select-parent textarea {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 18px 15px;
  background-color: #fff;
  min-width: 100%;
  outline: none;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
}
.select-parent textarea::placeholder{
  color: #bcc1ce;
}
.select-parent input {
  height: 50px;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0px 15px 0px 0px;
}
.select-parent img {
  cursor: pointer;
}
.select-parent ul {
  display: flex;
  padding-left: 0px;
  margin-bottom: 0px;
  flex-wrap: wrap;
}
.select-parent ul li {
  background-color: #bcc1ce;
  list-style-type: none;
  color: #ffffff;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-right: 6px;
  border-radius: 20px;
  padding: 2px 6px 1px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  margin-top: 3px;
}
.select-parent ul li button {
  background-color: transparent;
  border: none;
}
.select-parent .featured-post {
  padding-top: 10px !important;
}
.select-parent h4 {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  display: flex;
  width: 100%;
}
.select-parent .featured-post h4 {
  margin-top: 10px;
}
.accordionItem {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6f1;
  padding: 0px 0px;
}

.cms-accordian-p{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.cms-accordian-p img{
  position: absolute;
    top: 30px;
    right: 0;
}

.page-editor .accordionItemHeading {
  cursor: pointer;
  padding: 32px 0px 32px 0px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  justify-content: flex-start !important;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}
.accordionWrapper .close {
  color: #1b2952 !important;
  opacity: 1 !important;
}
.page-editor .close .accordionItemContent {
  height: 0px;
  transition: height 1s ease-out;
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  float: left;
  display: block;
}
.page-editor .accordionItemContent p {
  font-size: 13px!important;
  color: #333333!important;
  font-family: "Axiforma-Regular2";
  margin-bottom: 0px;
}
.page-editor .accordionItemContent ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
}
.page-editor .accordionItemContent p a,
.page-editor .accordionItemContent p a:hover {
  font-size: 14px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  text-decoration: none;
}
.page-editor .accordionItemContent p span,
.page-editor .accordionItemContent ul li span {
  font-family: "Axiforma-Regular";
  color: #000;
}
.accordionItemContent ul li, .accordionItemContent ol li{
  font-size: 14px;
  color: #5F6578;
  font-family: 'Axiforma-Regular';
  margin-bottom: 15px;
}
.page-editor .open .accordionItemContent {
  padding: 30px 0px;
  background-color: #fff;
  width: 100%;
  margin: 0px 0px 10px 0px;
  display: block;
  transition: transform 0.1s ease-in;
}
.page-editor .accordionItemContent {
  padding-bottom: 30px;
}
.jodit-wysiwyg {
  cursor: text;
}
.jodit-toolbar-editor-collection .jodit-toolbar-button {
  margin: 2px 12px !important;
}
.jodit-toolbar-editor-collection {
  background-color: #fff !important;
}
.page-editor .open .accordionItemHeading {
  padding: 30px 0px 0px 0px;
  background-color: #fff;
  color: #000;
}
.accordionWrapper h5 {
  margin-top: 40px;
  color: #000;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.page-editor .accordionItemHeading a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-editor .accordionItemHeading a img {
  position: absolute;
  top: 30px;
  right: 0;
}

a.select-opt {
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 9;
}

.jodit-status-bar,
.jodit-add-new-line {
  display: none !important;
}

/* slider */
.slider-range {
  /* -webkit-appearance: none;
  appearance: none !important; */
  width: 100% !important;
  height: 6px !important;
  background: #3064f9 !important;
  padding: 0 !important;
}
.slider-range:hover {
  opacity: 1;
}
.slider-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #3064f9;
  border-radius: 50%;
  cursor: pointer;
}
.para-editor .rangevalue,
.para-editor .rangevalue:hover,
.para-editor .rangevalue:focus {
  width: 70px !important;
  height: 40px;
  border: 1px solid #e3e6f1;
  background-color: #fff;
  font-family: "Axiforma-Regular";
  padding: 12px 12px 10px 12px;
}
.page-editor .color_input {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px!important;
  height: 50px!important;
  transition: .5s!important;
}
.pages-fields select {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  outline: none;
  font-size: 13px;
  background: #fff url(../../assets/images/dashboard/grey-dropdown.png);
  background-repeat: no-repeat;
  background-position: 96%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular";
  color: #1B2952;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.page-editor h6 {
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 13px;
  margin-bottom: 9px;
}
.linkbutton {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.managebutton{
  margin: 0px;
}
.gallery .select-img-ratio {
  margin-top: 40px;
}
.gallery-images {
  margin-top: 20px!important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gallery-images .gallery-single {
  width: 22.5%;
  height: 180px;
  margin-right: 18.5px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}
.gallery-edit {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-edit a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.page-editor .gallery-edit p {
  color: #bcc1ce !important;
  font-size: 12px!important;
  cursor: pointer;
}
.gallery-single:hover .gallery-edit {
  opacity: 1;
}
.gallery .gallery-images {
  padding-right: 0px;
}
.gallery-preview div {
  position: relative;
}
.gallery-preview a {
  position: absolute;
  top: 0px;
  right: 4px;
}
.default-gallery {
  border: 2px dashed #e3e6f1;
  background-color: #f8fafe;
}
.default-gallery img {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.default-gallery a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.gallery-row-column{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gallery-row-column .gallery-column {
  width: 48%;
}
.gallery-row-column input , .gallery-row-column input:focus{
  width: 100%;
}

.gallerygrid{
  display: flex;
  flex-wrap: wrap;
}
.row-column-tabs .nav-link {
  border: 1px solid #e3e6f1 !important;
  border-radius: 3px !important;
  background-color: #f8fafe;
  border-radius: 3px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  position: relative;
}
.row-column-tabs .nav-link.active {
  border: 1px solid #e3e6f1 !important;
  border-radius: 3px !important;
  background-color: #e3e6f1 !important;
  border-radius: 3px;
}


.row-column-tabs .nav-link svg {
  fill: #bcc1ce !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.row-column-tabs .nav-link.active svg {
  fill: #7782a1 !important;
}
.popup-listimages{
  height: 520px;
   overflow: auto;
    width: 100%;
    background-color: #f8fafe;
    border-left: 1px solid #e3e6f1;
}
.image-height-fit-grid {
  /* display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  width: calc(100% - 200px);
    flex-wrap: wrap;
    overflow: auto;
    height: 520px;
    padding: 10px;
    padding-right: 0px; */
    columns: 5 120.8px;
  column-gap: 10px;
  width: 100%;
  margin: 0 auto;
  /* height: 520px;
  width: calc(100% - 200px); */
  padding: 20px 20px 10px 20px;
}
.columnunset{
  /* display: flex;
 flex-wrap: wrap;
 padding: 0 4px;
 width: calc(100% - 200px);
   flex-wrap: wrap;
   overflow: auto;
   height: 520px;
   padding: 10px;
   padding-right: 0px; */
   columns: unset !important;
 column-gap: 10px;
 width: 100%;
 margin: 0 auto;
 /* height: 520px;
 width: calc(100% - 200px); */
 padding: 20px 20px 10px 20px !important;
}
.alllayout{
    position: absolute;
    right: 0px;
     left: 180px;
     bottom: -24px;
}
.difflayout{
  width: 18%;
  /* margin: 0 1.5rem 1.5rem 0; */
  display: inline-block;
  width: 100%;
  /* padding: 0px 0px 10px 0px; */
  margin-bottom: 10px;
  position: relative;

  /* padding: 5px; */
  border-radius: 5px;
  /* transition: all .25s ease-in-out; */
}
.difflayout label{
position: absolute;
left: 0px;
cursor: pointer;
border-radius: 4px;
}
.difflayout img{
  width: 100%;
  border-radius: 5px;
}
.difflayout label:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.all-filter-images{
  flex: 16%;
  max-width: 25%;
  padding: 5px 8px;
  width: 100%;
}

.image-library {
  width: 18.8%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  margin: 5px;
}
.imageuploadpopup .apigallery {
  display: flex;
}
.color_filter .darkblue-chose{
  margin-right: 0px !important;
}
.imageuploadpopup .modal-body {
  padding: 0px;
  height: 520px;
}
.imagefilter {
  width: 200px;
  min-width: 200px;
  box-shadow: 0px 0px 5px rgb(188 193 206 / 50%);
  padding: 18px 20px;
}
.imagefilter-result {
  /* width: calc(100% - 200px); */
  flex-wrap: wrap;
  /* overflow: auto; */
  /* height: 100%; */
  padding: 15px;
  padding-right: 0px;
  background-color: #F8FAFE;
    border-left: 1px solid #E3E6F1;
}
.image-library label {
  cursor: pointer;
}
.image-library label:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.image-library input:checked ~ span {
  display: block;
  transition: all 0.5s ease;
}

.difflayout input:checked ~ span {
  display: block;
  transition: all 0.5s ease;
}


/* .galleryactiveone {

  border-radius: 4px;
  background: rgba(0, 0, 0, 50%);
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
}
.galleryactiveone:after {
  content: url("../../assets/images/dashboard/tick.png");
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
} */

.mobImgSelectSpinner{
  display: none !important;
}

.image-library input:checked ~ .mobImgSelectSpinner {
  display: flex !important;
  
  /* transition: all 0.5s ease; */
}

.difflayout input:checked ~ .mobImgSelectSpinner {
  display: flex !important;
  /* transition: all 0.5s ease; */
}

.galleryactive {
  /* border: 2px solid #3064f9; */
  border-radius: 4px;
  background: rgba(0, 0, 0, 50%);
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
}
.galleryactive:after {
  content: url("../../assets/images/dashboard/tick.png");
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.select-gallery-layout {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}
.select-gallery-layout .form-group{
  margin-bottom: 0px !important;
}
.select-gallery-layout .image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #e3e6f1; */
  width: 34px;
  height: 34px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 8px;
  border-radius: 4px;
  transition: 0.5s;
}
.select-gallery-layout label {
  margin-bottom: 0px !important;
  width: 100%;
  border: 0px !important;
}
.gallery-ratio > input:checked + div {
  background-color: #F1F4FB;
}
.gallery-ratio > input {
  display: none;
}
.gallery-ratio > input + div svg {
  stroke: #E3E6F1 !important;
}
.gallery-ratio > input + div svg {
  fill: #f8fafe !important;
}
.gallery-ratio > input + div .svglayoutdiv {
  fill: #E3E6F1 !important;
}
.gallery-ratio > input:checked + div .svglayoutdiv {
  fill: #7782a1 !important;
}
.gallery-ratio > input:checked + div svg {
  stroke: #7782A1 !important;
}
.gallery-ratio > input:checked + div svg {
  fill: #e2eaff !important;
}
.imagefilter p {
  margin-bottom: 8px;
    color: #7782a1;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    line-height: 13px;
    text-align: left !important;
}
.imagefilter .gallery_color_input p {
  margin-left: 10px !important;
  color: #1b2952;
  font-size: 13px;
  margin-top: 4px !important;
}
.gallery-header h2 {
  color: #1b2952;
    font-family: "Axiforma-Bold";
    font-size: 18px;
    min-width: 200px;
    margin-bottom: 0px;
    line-height: 18px;
    margin-top: 3px;
}
.gallery-web-search {
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  height: 40px;
  /* width: calc(100% - 400px); */
  width: calc(100% - 449px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.gallery-web-search input {
  border: none;
  width: 100%;
  outline: none !important;
  font-size: 15px;
  font-family: "Axiforma-Regular";
  padding: 10px 10px;
  height: 38px;
  color: #1B2952;
}
.gallery-web-search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bcc1ce;
  opacity: 1; /* Firefox */
}

.gallery-web-search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcc1ce;
}

.gallery-web-search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcc1ce;
}
.gallery-web-search form {
  width: 100%;
}
.gallery-web-search button {
  border: none;
  background-color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  width: 35px;
  padding: 10px 0px 10px 15px;
}
/* .galley-switch button {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3064f9;
  border-radius: 3px;
  font-size: 13px;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  padding-top: 2px;
  transition: 0.5s;
  background-color: #fff;
} */
.galley-switch{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-left: 30px;

}
.dragupload-image{
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 480px !important;
  width: 480px !important;
  border: 2px dashed #BCC1CE;
  border-radius: 6px;
}
.gotoimage-library{
  font-size: 14px;
    color: #3064F9;
    margin-bottom: 0px;
    margin-right: 0px;
    cursor: pointer;
}
.dragupload-imagediv{
  width: 60px;
  height: 60px;
  display: flex;
  background-color: #3064F9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.galley-switch .uploadbtn{
  margin-bottom: 0px;
  cursor: pointer;
  margin: 0px 30px;
  font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #3064F9;
    margin: 0px 0px;
}
.galley-switch img{
  margin-right: 5px;
}
/* .galley-switch button:hover {
  border: 1px solid #0040f9;
  color: #0040f9;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
} */
.imageuploadpopup .modal-header {
  padding: 0px !important;
  z-index: 3;
  background-color: #fff;
}
.imageuploadpopup .gallery-header {
  border-bottom: 1px solid #e3e6f1;
  padding: 0px 20px !important;
  height: 71px;
}
.uploadimagesection {
  display: flex;
}
.gallery-upload-options {
  width: 200px;
  min-width: 200px;
  box-shadow: 0px 0px 5px rgb(188 193 206 / 50%);
  padding: 21px 10px 10px 10px;
  z-index: 1;
}
.gallery-upload-detail {
  width: calc(100% - 200px);
  overflow: auto;
  height: 520px;
  padding: 0px;
  background-color: #f8fafe;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-upload-options .nav {
  display: block;
}
.gallery-upload-options .nav .nav-item {
  margin-bottom: 10px;
}
.gallery-upload-options .nav .nav-item .nav-link {
  width: 180px;
  border: none !important;
  border-radius: 4px;
  color: #1B2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  height: 32px;
  background-color: #fff;
  padding-left: 41px;
  display: flex;
  align-items: center;
}
.gallery-upload-options .nav .nav-item .nav-link.active {
  border: none !important;
  color: #3064F9 !important;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  background-color: #F1F4FB !important;
  border-radius: 4px !important;
}
.gallery-upload-options .nav .nav-item .customuploadtabs {
  position: absolute;
  top: 3px;
  left: 7px;
  /* background-color: #f8fafe; */
  border-radius: 3px;
  width: 24px;
  height: 24px;
}
.gallery-upload-options .nav .nav-item .nav-link.active svg .upload-linksvg{
  stroke: #3064f9 !important;
}
.row-column-tabs .nav-link svg .upload-linksvg{
  stroke: #1B2952 !important;
}
.gallery-upload-options .nav .nav-item .nav-link.active svg {
  fill: #3064f9 !important;
}
.upload-gallery-image {
  position: inherit;
  width: 100%;
  height: 100%;
}
.upload-gallery-box {
  width: 100%;
  height: 100%;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 135px;
  width: 280px; */
}
.upload-gallery-box section{
  width: 100%;
  height: 100%;
}
.upload-gallery-box p {
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 26px;
  margin-top: 26px;
  line-height: 26px;
  margin-bottom: 6px;
}
.upload-gallery-box p span {
  color: #3064f9;
}
.upload-gallery-box p a,
.upload-gallery-box p a:hover {
  color: #3064f9;
}
.upload-gallery-box h6 {
  color: #7782A1;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  line-height: 15px;
}
.upload-gallery-box h4 {
  color: #1b2952;
  font-size: 11px;
  line-height: 19px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
}
.any-url {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.any-url-option p {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 9px;
}
.any-url-option h6 {
  font-size: 11px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin-top: 10px;
}
.any-url-option div {
  width: 600px;
  border: 1px solid #BCC1CE;
  height: 50px;
  border-radius: 3px;
  background-size: 21px;
  background-position: 98% center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.any-url-option input {
  width: 100%;
  border: none;
  height: 48px;
  padding: 0px 40px 2px 20px;
  outline: none !important;
  background-color: transparent;
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  color: #1B2952;
}
.color_filter input {
  display: none;
}
.color_filter label {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  margin: 0 8px 8px 0 !important;
  border: 1px solid #fff;
  cursor: pointer;
}
.color_filter b {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 15px;
}
/* .color_filter input:checked + label b {
  width: 20px;
  height: 20px;
  position: relative;
  
} */
/* .color_filter input:checked + label .colorchose {
  border: 1px solid #ccc;
  position: relative;
  top: 0px;
} */
/* .color_filter input:checked + label {
  border: 1px solid #ccc;
  position: relative;
  top: 0px;
} */
.bg_colors .black {

    background: #4D4D4D;
  
}
.bg_colors .red {
  background: #f44e3b;
}
.bg_colors .orange {
  background: #fe9200;
}
.bg_colors .yellow {
  background: #fcdc00;
}
.bg_colors .green {
  background: #8bc34a;
}
.bg_colors .purple {
  background: #7b64ff;
}
.bg_colors .blue {
  background: #2196f3;
}
.bg_colors .water {
  background: #68CCCA;
}
.bg_colors .gray {
  background: #bbb;
}
.bg_colors .white {
  background: #FFFFFF;
  border: 1px solid #E3E6F1;
}
.bg_colors .brown {
  background: #795548;
}
.bg_colors .grey {
  background: #9e9e9e;
}
.bg_colors .skyblue {
  background: #009CE0;
}
.bg_colors .darkblue{
  background: #346FF8;
}
.bg_colors .magenta {
  background: #9c27b0;
}
.bg_colors .teal {
  background: #607d8b;
}
.mehroon {
  background: #b13152;
}
/* .color-plates {
  height: 40px;
  min-height: 40px;
} */
.gallery_color_input {
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  padding: 5px 15px 5px 5px;
  margin-top: 2px;
  height: 34px;
}
.gallery_color_input .color_input {
  height: auto;
}
.gallery_color_input .color_input div div {
  /* margin-right: 0px !important;
  margin-left: 0px !important; */
  border-radius: 2px !important;
}
.imagefilter .gallery_color_input .color_input .colorbox {
  width: 24px !important;
  height: 24px !important;
}

.imageuploadpopup .modal-footer {
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1 !important;
  z-index: 1;
  background-color: #fff;
}
.imageuploadpopup-footer-cancel{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.imageuploadpopup-footer-cancel button{
  width: 102px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 14px;
  margin: 0px !important;
  transition: 0.5s;
  font-family: 'Axiforma-Regular';
  line-height: 29px;
}
.imageuploadpopup-footer-cancel button:focus{
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.imageuploadpopup-footer-cancel button:hover {
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.imageuploadpopup .modal-footer h4{
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-right: 20px;
  margin-bottom: 0px;
}
.imageuploadpopup-footer-disable button{
  width: 88px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #BCC1CE !important;
  background-color: #BCC1CE !important;
  color: #fff !important;
  font-size: 13px;
  margin: 0px !important;
  transition: 0.5s;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Axiforma-Regular';
  cursor: not-allowed !important;
}
.imageuploadpopup-footer-done{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.imageuploadpopup-footer-done button div, .imageuploadpopup-footer-disable button div{
  line-height: 40px;
}
.imageuploadpopup-footer-done button{
  width: 88px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--custom_color1)!important;
  background-color: var(--custom_color2)!important;
  color: #fff;
  font-size: 13px;
  margin: 0px !important;
  transition: 0.5s;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Axiforma-Regular';
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}
.imageuploadpopup-footer-done button:hover {
  /* color: #0040f9;
  border: 1px solid #0040f9; */
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.imageuploadpopup-footer-done h4{
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-right: 20px;
  margin-bottom: 0px;
}
.imageuploadpopup-footer-done{
  display: flex;
  align-items: center;
}
.imageuploadpopup .modal-footer button:nth-child(4) .new-loader{
  margin: auto;
}
.image-uploaded-by-url {
  text-align: center;
  height: 100%;
  /* margin-top: 50px; */
}
.image-uploaded-by-url p {
  color: #1b2952;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
}
.image-uploaded-by-url a {
  color: #7782a1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-top: 18px;
}
.image-uploaded-by-url div {
  width: 100%;
  max-height: 100%;
  height: 100%;
}
.modal-dialog.imageuploadpopup {
  max-width: 80%;
}
/* Gallery Popup Ends */
/* Category Popup starts */
.selectcategorypopup .modal-header {
  padding: 0px !important;
  background-color: #fff;
  border-bottom: 1px solid #e3e6f1 !important;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 20px !important;
}
.selectcategorypopup .modal-header h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: -4px;
}
.selectcategorypopup .modal-body {
  padding: 0px;
  height: 520px;
  overflow: auto;
}
.selectcategorypopup .modal-footer {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1 !important;
  background-color: #fff;
}
.selectcategorypopup .modal-footer button:nth-child(1) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 13px;
  margin: 0px !important;
}
textarea.code-line {
  width: 100%;
  height: 300px;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  resize: none;
}
.selectcategorypopup .modal-footer button:nth-child(2) {
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #3064f9 !important;
  background-color: #3064f9 !important;
  color: #fff !important;
  font-size: 13px;
  margin: 0px !important;
}
.category-layouts {
  background-color: #f8fafe;
  height: 520px;
  overflow: auto;
  padding: 10px;
}
.category-layouts .cat-layout-section {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.category-library {
  width: 23.4%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  margin: 10px;
  position: relative;
}
.category-library label {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}
.categoryactive {
  border: 2px solid #3064f9;
  border-radius: 4px;
  background: rgba(0, 0, 0, 50%);
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
}
.category-library input:checked ~ span {
  display: block;
  transition: all 0.5s ease;
}
.categoryactive:after {
  content: url("../../assets/images/dashboard/tick.png");
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.category-layouts h4 {
  font-family: "Axiforma-Bold";
  font-size: 12px;
  color: #1b2952;
  margin-bottom: 0px;
  margin-top: 16px;
  padding-left: 10px;
}
/* Category Popup Ends */
/* Category Page Starts */
.category-tag-head {
  padding: 20px 30px;
  border-bottom: 1px solid #e3e6f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-tag-head h1 {
  font-family: "Axiforma-Bold";
  font-size: 20px;
  line-height: 27px;
  color: #1b2952;
  margin-bottom: 4px;
}
.category-tag-head p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17px;
  color: #7782a1;
  margin-bottom: 0px;
}
.category-tag-head p a {
  font-family: "Axiforma-Regular";
  color: #3064f9;
  display: none;
}
.category-tag-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category-tag-search input {
  width: 290px;
  height: 40px;
  border: 1px solid #e3e6f1;
  padding: 0px 17px;
  font-size: 13px;
  border-radius: 2px 0px 0px 2px;
  border-right: none;
  outline: none !important;
}
.category-tag-search button {
  width: 45px;
  height: 40px;
  border: 1px solid #e3e6f1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 2px 2px 0px;
}
.category-tag-list {
  min-height: 80vh;
}
.category-tag-view .tags-list {
  min-height: 80vh;
}
.category-tags-editor input{
  height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #BCC1CE;
  padding: 15px 90px 15px 20px;
  color: #1b2952;
  outline: none;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.category-tags-editor input:focus-visible,
.category-tags-editor input:focus , 
.category-tags-editor input:hover {
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s!important;
}
.category-tags-editor h6 {
  font-family: "Axiforma-Regular";
  color: #7782A1;
  font-size: 13px;
}
.category-tags-editor p {
  margin-bottom: 0px;
}
.category-tags-editor .image-uploader {
  margin-top: 0px;
}
.cms-tag-footer-btn2{
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
}
.cms-tag-footer-btn2:hover {
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
  /* background-color: #0040f9;
  border: 1px solid #0040f9; */
  transition:0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.add-cat-tag-btn2{
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
}
.add-cat-tag-btn2:hover {
  /* background-color: #0040f9;
  border: 1px solid #0040f9; */
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
  transition:0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.cat-tag {
  text-align: center;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-cat-tag p {
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 30px;
  margin-bottom: 0px;
}
.categorydetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e3e6f1;
  width: 100%;
}
.categoryaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  margin-left: 80px;
}
.categoryaction img{
  cursor: pointer;
}
.create-category-tag {
  padding-right: 0px !important;
}
.category-img {
  display: flex;
  align-items: center;
  width: calc(100% - 395px);
  cursor: pointer;
}
.category-img div:nth-child(1) {
  width: 60px;
  height: 60px!important;
  min-width: 60px;
}

.category-img1 div:nth-child(1) {
  width: 60px;
  margin-right: 10px!important;
  min-width: 60px;
}
.category-img h6 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-left: 15px;
  margin-bottom: 0px;
  word-break: break-word;
}
.category-img p {
  color: #7782a1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-left: 15px;
  margin-bottom: 0px;
}
.category-blog-no {
  width: 100px;
  text-align: left;
  min-width: 100px;
}
.category-blog-no p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.category-child-one {
  margin-right: 11px;
}
.category-child-two {
  margin-right: 11px;
  margin-left: 30px;
}
.category-child-three {
  margin-right: 11px;
  margin-left: 60px;
}
.tags-list .category-img h6 {
  margin-left: 0px;
}
.tags-list .category-img div:nth-child(1) {
  height: auto;
}
/* Category Page Ends */
/* Select Parent Popup Starts */
.select-parent-page ul {
  padding-left: 0px;
}
.parent-radio-btn input[type="radio"]:checked,
.parent-radio-btn input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.parent-radio-btn input[type="radio"]:checked + label,
.parent-radio-btn input[type="radio"]:not(:checked) + label {
  padding-right: 75px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.parent-radio-btn [type="radio"]:checked + label:before,
.parent-radio-btn [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 21px !important;
  width: 19px;
  height: 19px;
  border: 1px solid #c8cedb;
  border-radius: 100%;
  background: #fff;
}
.parent-radio-btn [type="radio"]:checked + label:after {
  opacity: 1;
}
.parent-radio-btn input[type="radio"]:checked + label,
.parent-radio-btn input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 10px;
  padding-right: 105px;
  padding-top: 6px;
  padding-bottom: 10px;
  cursor: pointer;
  line-height: 50px;
  display: inline-block;
  color: #bcc1ce;
  height: 100%;
  width: 100%;
}
.page-list {
  position: relative;
}
.page-list:hover {
  box-shadow: none;
  background-color: #f1f4ff !important;
}
.page-list .card-num {
  max-width: 100%;
  padding: 5px 10px;
}
.parent-radio-btn [type="radio"]:checked + label:after {
  content: "";
  width: 19px;
  height: 19px;
  background: #fff;
  position: absolute;
  top: 30%;
  right: 20px;
  border-radius: 100%;
  transition: all 0.2s ease;
  border: 5px solid #3064f9;
  display: inline-block;
  transition: 1s;
}
.page-list .card-num p {
  color: #1b2952;
  font-size: 14px;
  top: 2px;
}
.select-parent-page {
  background-color: #f8fafe;
  height: 520px;
  overflow: auto;
  /* padding-top: 20px; */
}
.no-page {
  height: 100%;
  background-color: #f8fafe;
  text-align: center;
}
.no-page p {
  color: #bcc1ce;
  font-size: 14px;
  margin-top: 17px;
}
/* Select Parent Popup Ends */
textarea.code-line:focus-visible {
  outline: none;
}
.custom-gallery {
  /* padding-right: 10px; */
  position: relative;
  padding: 4px;
}
.custom-gallery img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
}
/* .not-padding-class {
  padding-left: 5px;
} */
/* .padding-class {
  padding-right: 5px;
} */
.page-body span {
  display: block;
  word-break: break-word;
}
.pages-fields .draggable-container {
  top: auto!important;
  left: 10px !important;
  position: relative!important;
}
@keyframes theshadow {
  0% { opacity: 0;}
  100% { opacity: 1;}
}
.marginleftClass {
  padding-left: 20px;
}
.cmsmobilepreview {
  display: none;
}
.imagefilter-result .image-library label {
  border-radius: 4px;
  position: relative;
}
.imagefilter-result .image-library label p{
  display: none !important;
}
.imagefilter-result .image-library label p link , .imagefilter-result .image-library label p a{
  color: #7782a1;
  text-decoration: none;
}
.imagefilter-result .image-library label:hover p {
  content: attr(data-title);
  position: absolute;
  top: -24px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #ffffff;
  color: #7782a1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  white-space: nowrap;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
  width: max-content;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
.imagefilter-result .image-library label:hover p::after {
  content: "";
  position: absolute;
  bottom: -15px;
  display: inline-block;
  color: #7782a1;
  border: 8px solid transparent;
  border-top: 8px solid #ffffff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
  left: 0;
  right: 0;
  margin: auto;
  width: 10px;
}
.parentpagename {
  display: flex;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  height: 50px;
  align-items: center;
  padding: 0px 0px 0px 15px;
  background-color: #f8fafe;
}
.page-editor .accordionItemContent  .jodit-wysiwyg {
  background-color: #fff;
  transition: .5s!important;
}
.category-selection .parentpagename {
  display: flex;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  height: 50px;
  align-items: center;
  padding: 0px 0px 0px 15px;
}
/* .select-parent .tagparent.category-selection {
  padding: 0px 20px 0px 12px;
}
.category-selection.tagparent .cat-tag-select .css-yk16xz-control,
.category-selection.tagparent .css-1pahdxg-control {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.category-selection.tagparent .css-g1d714-ValueContainer {
  background-color: transparent !important;
  height: auto!important;
}
.category-selection.tagparent .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.category-selection .taglist ul {
  display: flex;
  width: 100%;
}
.category-selection.tagparent .css-2613qy-menu {
  min-width: 100% !important;
  position: absolute !important;
  display: block !important;
}
.category-selection.tagparent .css-1wa3eu0-placeholder {
  color: #bcc1ce !important;
}
.category-selection.tagparent .css-g1d714-ValueContainer,
.category-selection .css-b8ldur-Input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.category-selection .category-parent label {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
  margin-top: 0px;
}
.category-selection .category-parent .parentpagename {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 15px;
  background-color: rgba(227, 230, 241, 0.5);
}
.category-selection .cat-tag-select {
  width: auto;
  min-width: 160px;
}
.category-selection .react-datepicker__time-list-item {
  height: auto !important;
  background-color: transparent !important;
  color: #000 !important;
  font-size: 12px !important;
  border-radius: 0px !important;
  padding: 0px 0px 0px 7px !important;
}
.categogy-chip-section {
  width: calc(100% - 40px) !important;
  display: flex;
  flex-wrap: wrap;
}
.category-selection {
  display: flex;
}
.category-selection ul li {
  margin-top: 7px !important;
}
.category-selection .css-1wa3eu0-placeholder {
  top: 54%;
  font-size: 13px;
} */
.drags-drops {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
/* .drags-drops div {
  max-width: 285px;
  max-height: 300px;
}
.drags-drops div img{
  max-height: 300px;
} */
.tab-content.gallery-tabs{
  width: 100%;
  height: 100%;
}
.upload-gallery-box .zoom-range{
  z-index: 9;
  position: absolute;
  bottom: 20px;
  width: 50%;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}
.tab-content.gallery-tabs .tab-pane{
  width: 100%;
  height: 100%;
}
.select-parent-popups {
  position: relative;
  top: -2px;
}
.page-editor .accordionItemHeading:before {
  content: url("../../assets/images/dashboard/bottom-bar/dots.png");
  margin-right: 10px;
  cursor: move;
  margin-top: 0px;
  z-index: 0;
  opacity: 0;
  position: absolute;
  left: -18px;
}
.page-editor .accordionItemHeading:hover.accordionItemHeading:before {
  opacity: 1;
}
.page-editor .non-draggable:hover.accordionItemHeading:before {
  opacity: 0 !important;
  cursor: pointer;
}
.create-post-title h1 {
  color: #000000;
  font-size: 15px;
  font-family: "Axiforma-Bold";
  padding: 0px 0px 7.5px 0px;
  line-height: 21.08px;
  margin-bottom: 0px;
  word-break: break-word;
}
.create-post-title h4 {
  color: #a1a1a1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin: 0px 10px 10px 10px;
}
.category-parent .parentpagename {
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}
.date-input-post2 {
  /* margin-left: -15px;
  width: calc(100% + 50px) !important; */
  padding-left: 15px!important;
  cursor: pointer;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
}
.statusTrashed {
  background-color: #f1f3f9;
  color: #bcc1ce !important;
}
.delete-popup {
  max-width: 480px !important;
}
.delete-popup .modal-body {
  width: 480px !important;
  min-width: 480px !important;
  height: 200px !important;
  min-height: 200px !important;
  max-width: 480px !important;
  max-height: 200px !important;
  padding: 40px 50px;
}
.delete-popup h5 {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  text-align: center;
  margin-bottom: 20px;
}
.delete-popup h6 {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-align: center;
  margin-bottom: 30px;
  line-height: 18px;
}
.delete-popup .grey-btn p{
  width: 140px;
  height: 44px;
  border: 1px solid #c8cedb;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  transition: 0.5s;
  line-height: 44px;
  text-transform: capitalize;
  text-align: center;	
  border-radius: 3px;	
  cursor: pointer;
  font-size: 14px;
  margin-right: 20px;
  margin-bottom: 0px;
}
.delete-popup .grey-btn p:hover{
  color: #fff;
  background-color: #c8cedb;
  transition: 0.5s;
}
.delete-popup .red-btn p {
  width: 140px;
  height: 44px;
  border: 1px solid #ffa7a7;
  color: #ff4848;
  background-color: #fff;
  transition: 0.5s;
  line-height: 44px;
  text-transform: capitalize;
  text-align: center;	
  border-radius: 3px;	
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 0px;
}
.delete-popup .red-btn p:hover{
  color: #fff;
  background-color: #ff4848;
  transition: 0.5s;
}
.delete-popup .red-btn p:hover .new-red-loader{
  border: 2px solid #f4f4f9;
 border-Top: 2px solid transparent
}
.react-datepicker-popper .react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
}
.react-datepicker-popper .react-datepicker__time-container {
  border-left: none !important;
  width: 120px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
    width: 115px !important;
    margin: 0px!important;
}
.react-datepicker__time-box .react-datepicker__time-list li {
  background-color: #fff !important;
  color: #7782a1 !important;
  min-width: 100px;
  text-align: center !important;
}
.react-datepicker__time-box .react-datepicker__time-list li:hover {
  background-color: #e3e6f1 !important;
  border-radius: 4px !important;
  color: #1b2952 !important;
}
.react-datepicker {
  border: none !important;
  filter: drop-shadow(0px 0px 6px rgba(188, 193, 206, 0.5));
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  border-radius: 100% !important;
  background-color: #5884ff !important;
  height: 45px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 13px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-align: center !important;
  margin: 0.166rem !important;
}

.react-datepicker__day:hover {
  border-radius: 100% !important;
  height: 40px !important;
}
.react-datepicker__time-list {
  height: 300px !important;
}
.react-datepicker__navigation {
  top: 10px!important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 40px !important;
  padding: 7px 10px 5px 20px !important;
  line-height: 28px;
}
.react-datepicker-time__header {
  display: none;
}
.react-datepicker__time-container .react-datepicker__time {
  margin-top: 28px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px !important;
    font-family: 'Axiforma-Regular' !important;
    color: #1B2952 !important;
    font-weight: unset !important;
}
.react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--disabled{
  display: none!important;
}
.react-datepicker__day-names {
  background-color: transparent !important;
}
.no-image-icon{
  width: 100%;
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-image-icon p{
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #BCC1CE;
  margin-top: 18px;
}
.no-image-icon img{
  width: 109px!important;
  height: 94px!important;
}
.image-uploaded-by-url button{
  background-color: transparent;
  border: none;
  color: #7782A1;
  font-size: 12px;
}
.tags-names.category-img div:nth-child(1) {
  width: 100%!important;
}
.jodit-source{
  padding: 10px 0px;
}
.cmsphone .phonecodeselect{
  height: 50px !important;
  border: none!important;
  background-color: #f8fafe;
  border-right: 1px solid #e3e6f1!important;
  padding: 15px 15px 18px 15px!important;
}
.cmsphone input{
  border: none!important;
}
.page-editor .accordionItemContent .jodit-wysiwyg , .page-editor .accordionItemContent .jodit-placeholder{
  padding: 20px!important;
}
.jodit-source * {
  font-size: 13px!important;
}
.page-body .jodit-toolbar__box{
  border-bottom: none!important;
}
.categogy-chip-section .css-2b097c-container {
  width: 100%;
}
.categogy-chip-section .css-yk16xz-control {
  border: none!important;
  background-color: #f1f5fd;
}
.select-parent .category-selection input {
  height: auto!important;
}
.category-selection{
  height: auto!important;
  min-height: auto!important;

}
.category-selection .css-1rhbuit-multiValue{
  background-color: #BCC1CE!important;
  border-radius: 20px!important;
}
.category-selection .css-12jo7m5 {
  border-radius: 0px;
  color: #fff;
  padding: 2px 3px 4px 12px!important;
}
.category-selection .css-xb97g8 svg{
  fill: #fff;
  width: 15px;
  height: 15px;
}
.category-selection .css-xb97g8{
  cursor: pointer;
}
.category-selection .css-xb97g8:hover{
  background-color: transparent;
}
.crop-delete{
  position: absolute;
  top: 19px;
  right: 19px;
  z-index: 999;
  cursor: pointer;
  width: 27px;
}
.libraryImage-select{
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  cursor: pointer;
  padding: 25px 0px;
  border-bottom: 2px solid transparent;
}
.libraryImage-select-selected{
  border-bottom: 2px solid #3064F9;
}
.libraryImage-select:hover .uploadtype-text{
  color: #3064F9;
}
.libraryImage-select svg{
  fill: #BCC1CE
}
.libraryImage-select-selected svg{
  fill: #3064F9;
}
.libraryImage-select:hover svg{
  fill: #3064F9;
}
.libraryImage-select:hover{
  border-bottom: 2px solid #3064F9;
}
.uploadtype-text{
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  line-height: 100%;
  color: #BCC1CE;
  margin-bottom: 0px;
}
.uploadtype-text-select{
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  line-height: 100%;
  color: #3064F9;
  margin-bottom: 0px;
}
.crop-upload{
  position: absolute;
  top: 21px;
  right: 57px;
  z-index: 999;
  cursor: pointer;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-upload-iconsize{
  width: 12px;
}
.select-gallery-ratio {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}
.select-gallery-ratio div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 27px;
}
.select-gallery-ratio div:last-child {
  border-right: none;
}
.page-editor .accordionItemContent .select-gallery-ratio div p {
  font-family: "Axiforma-Regular";
  font-size: 11px;
  color: #BCC1CE!important;
  margin-bottom: 0px;
  margin-left: 10px;
}
.galleryimg-ratio > input:checked + div {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.galleryimg-ratio > input {
  display: none;
}
.galleryimg-ratio > input + div svg {
  stroke: #E3E6F1 !important;
}
.galleryimg-ratio > input + div svg {
  fill: #fff !important;
}
.galleryimg-ratio > input:checked + div svg {
  stroke: #7782A1 !important;
}
.galleryimg-ratio > input:checked + div svg {
  fill: #fff !important;
}
.page-editor .accordionItemContent .galleryimg-ratio > input:checked + div p {
  color: #7782A1!important;
}
.select-gallery-ratio label {
  margin-bottom: 0px !important;
  width: 100%;
}
.select-headings-ratio div{
  width: 20px;
  height: 45px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1!important;
  margin-left: 10px!important;
}
.the-seprator{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.the-seprator #rangeid{
  width: 48%;
}
.the-seprator .color_input{
  width: 48%;
}
.addmoreblock , .addmoreblock:hover , .addmoreblock:focus{
  margin-left: 0px;
  color: #3064F9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
/* .jodit-source {
  background-color: #1B2952!important;
  border-radius: 3px!important;
}
.ace-idle-fingers .ace_gutter-active-line {
  background-color: #1B2952!important;
}
.ace-idle-fingers {
  background-color: #1B2952!important;
}
.ace-idle-fingers .ace_gutter {
  background-color: #1B2952!important;
}
.ace-idle-fingers .ace_print-margin {
  background-color: #1B2952!important;
}
.ace-idle-fingers .ace_marker-layer .ace_active-line {
  background-color: #1B2952!important;
} */
.setting-bt-on , .setting-bt-on:focus{
  background-color: #F8FAFE;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.setting-bt-on .setting-second-shadow{
  display: none;
}
.setting-bt-off , .setting-bt-off:focus{
  background-color: #F8FAFE;
  border: none;
  box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16)inset !important;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.setting-bt-off .setting-second-shadow , .setting-bt-off:focus .setting-second-shadow{
  box-shadow: -3px -3px 3px #ffffff inset!important;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.setting-bt-on .setting-first-shadow, .setting-bt-on:focus .setting-first-shadow {
  box-shadow: -3px -3px 3px #ffffff inset!important;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.setting-bt-on svg{
  stroke: #7782a1;
  fill: #fff;
  min-width: 23px;
}
.setting-bt-off svg{
  min-width: 23px;
}
.setting-bt-off{
  stroke: #7782a1;
  fill: #7782a1;
}
.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text;
  transition: .5s;
}
.react-tags:hover{
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
  transition: .5s;
}
.react-tags__selected {
  display: inline;
}
.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 10px 0;
  border: none;
  padding: 2px 10px 5px 12px;
  border-radius: 20px;
  background: #EDF0F5;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
}
.react-tags__selected-tag-name{
  font-size: 13px;
  color: #7782A1;
  word-break: break-word;
  font-family: "Axiforma-Regular";
}
.react-tags__selected-tag:after {
  font-size: 13px;
}
.react-tags__search {
  display: inline-block;
  padding: 7px 2px;
  margin-bottom: 6px;
  max-width: 100%;
  position: relative;
}
.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  min-width: 70px!important;
  transition: .5s;
}
.react-tags:hover .react-tags__search-input{
  background-color: #f8fafe;
  transition: .5s;
}
.categogy-chip-section{
  width: 100%;
}
.categogy-chip-section .dropdown {
  width: 100%;
}
.categogy-chip-section .dropdown .dropdown-toggle{
  border: 1px solid #BCC1CE!important;
  min-height: 50px!important;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  background-image: url(../../assets/images/dashboard/grey-dropdown.png)!important;
  background-repeat: no-repeat!important;
  background-position: 97% 20px!important;
}
.categogy-chip-section .dropdown .dropdown-menu{
  width: 100%;
  margin: 0px;
  padding: 0px 0px 5px 0px;
  max-height: 390px;
  overflow: auto;
  overflow-x: hidden;
  /* border: none; */
}

.categogy-chip-section input{
  height: 14px;
  width: 14px;
  border-radius: 50px!important;
  margin-right: 10px;
}
.categogy-chip-section label{
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.categogy-chip-section .dropdown-search input{
  width: 100%!important;
  border-radius: 0px!important;
}
.categogy-chip-section .search-dropdown-before p span img{
  margin-left: 5px!important;
}
.search-dropdown-before div{
  flex-wrap: wrap;
  display: flex;
  width: 100%;
}
.search-dropdown-before div div{
  display: flex;
  width: max-content;
  background-color: #EDF0F5;
  border-radius: 20px;
  padding: 5px 10.5px 4px 13px;
  margin: 5px 6px 5px 0px;
}
.search-dropdown-before div div p{
  color: #7782A1!important;
  font-size: 13px!important;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;
  max-width: 200px;
}

.categogy-chip-section .dropdown .dropdown-menu .form-group{
  margin-bottom: 0px;
}
.category-created{
  padding: 5px 15px;
  cursor: pointer;
  transition: .5s;
  border-radius: 4px;
  margin: 0px 5px;
}
.category-created:hover{
  padding: 5px 15px;
  cursor: pointer;
  background-color: #F8F9FD;
  transition: .5s;
}
.category-list-container{
  word-break: break-word;
  font-size: 16px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50px;
}
.category-list-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.category-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #BCC1CE;
  border-radius: 100%;
}
.category-list-container input:checked ~ .category-checkmark {
  background-color: #3064F9;
}
.category-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.category-list-container input:checked ~ .category-checkmark:after {
  display: block;
}
.category-list-container .category-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.categogy-chip-section .dropdown .dropdown-menu .dropdown-search p{
  position: absolute;
  right: 16px;
  font-size: 12px;
  color: #BCC1CE;
  width: max-content;
  top: 16px;
}
.categogy-chip-section .dropdown .dropdown-menu::-webkit-scrollbar-track {
  background-color: white!important;
}
.the-gallery-ratio {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}
.the-gallery-ratio div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 27px;
}
.the-gallery-ratio div:last-child {
  border-right: none;
}
.the-gallery-ratio div p {
  font-family: "Axiforma-Regular";
  font-size: 11px;
  color: #BCC1CE!important;
  margin-bottom: 0px;
  margin-left: 10px;
}
.imgallery-ratio > input:checked + div {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.imgallery-ratio > input {
  display: none;
}
.imgallery-ratio > input + div svg {
  stroke: #E3E6F1 !important;
}
.imgallery-ratio > input + div svg {
  fill: #fff !important;
}
.imgallery-ratio > input:checked + div svg {
  stroke: #7782A1 !important;
}
.imgallery-ratio > input:checked + div svg {
  fill: #fff !important;
}
.the-gallery-ratio label {
  margin-bottom: 0px !important;
  width: 100%;
}
.add-more-blocks{
  padding: 32px 0px;
}
.pages-setting h2{
  font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    margin-top: 21px;
    margin-bottom: 28px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E6F1;
}
.pages-setting .dropdown-toggle::after{
  display: none;
}
.pages-setting .dropdown-search input{
  width: 100%;
}
.post-heading h1{
  font-family: "Axiforma-Bold";
  font-size: 24px;
  color: #1B2952;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.post-heading h1 .cms-posts-tooltip{
  margin-left: 6px;
}
.post-sublinks{
  display: flex;
  align-items: center;
}
.post-sublinks a{
  color: #7782A1;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px 60px -5px 0px;
    border-bottom: 2px solid transparent;
    padding-bottom: 9px;
    transition: .5s;
}
.post-sublinks a.active , .post-sublinks a:hover{
  /* color: #3064F9;
  border-bottom: 2px solid #3064F9; */
  color: var(--custom_color4);
  border-bottom: 2px solid var(--custom_color5);
  transition: .5s;
}
.code-block .cm-gutters {
  background-color: #1B2952!important;
}
.code-block .cm-activeLineGutter {
  background-color: #1B2952!important;
}
.code-block .cm-editor{
  background-color: #1B2952!important;
}
.increase-height1{
  transform: translate3d(0px, 91px, 0px) !important;
}
.increase-height2{
  transform: translate3d(0px, 130px, 0px) !important;
}
.increase-height3{
  transform: translate3d(0px, 168px, 0px) !important;
}

.filter-increase-height1{
  transform: translate3d(0px, 69px, 0px) !important;
}
.filter-increase-height2{
  transform: translate3d(0px, 98px, 0px) !important;
}
.filter-increase-height3{
  transform: translate3d(0px, 120px, 0px) !important;
}



.cms-button-dropdown{
  border: 1px solid #BCC1CE!important;
  height: 50px!important;
  width: 100%;
  text-align: left;
  background-image: url(../../assets/images/dashboard/email-support-arrow.png)!important;
  background-repeat: no-repeat!important;
  background-position: 97%!important;
  border-radius: 3px;
}
.cms-button-dropdown .dropdown-toggle{
  width: 100%;
  height: 50px;
  text-align: left;
  padding-bottom: 10px;
}
.cms-button-dropdown .search-dropdown{
  padding: 0px 20px 0px 20px;
}
.cms-button-dropdown .dropdown-menu{
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.cms-button-dropdown .form-group{
  margin: 0px;
}
.cms-button-dropdown .dropdown-search input{
  margin-bottom: 0px;
}
.cms-button-dropdown .dropdown-menu .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px!important;
  color: #1B2952;
  font-size: 13px!important;
  font-family: "Axiforma-Regular"!important;
  margin: auto!important;
  border: 0px solid #fff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cms-button-dropdown .dropdown-menu .dropdown-item:hover , .cms-button-dropdown .dropdown-menu .dropdown-item:focus {
  border: 0px solid #fff !important;
  background-color: #F8F9FD!important;
}
.cms-phone-dropdown{
  position: absolute;
  width: calc(100% - 30px);
}
.cms-phone-dropdown .dropdown .connectus-dropdowntoggle{
  display: flex;
  border: 1px solid #BCC1CE!important;
  transition: .5s!important;
  cursor: pointer!important;
  border-right: none;
  height: 50px;
  align-items: center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100px;
  background-image: url(../../assets/images/dashboard/email-support-arrow.png)!important;
  background-repeat: no-repeat!important;
  background-position: 92%!important;
  padding: 0px 15px;
}
.cms-phone-dropdown .dropdown-menu{
  background-color: #fff;
}
.cms-phone-dropdown .form-group{
  margin: 0px;
}
.cms-category-popup{
  max-width: 600px!important;
}
.cms-category-popup .modal-body{
  padding: 30px 20px 0px 20px;
  min-height: 400px;
}
.cms-category-popup h4{
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1B2952!important;
  margin: 0px;
}
.cms-category-popup h5{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1!important;
  margin-top: 7px;
  margin-bottom: 30px;
}
.category-parent-dropdown .dropdown{
  border: 1px solid #BCC1CE!important;
  transition: .5s!important;
  cursor: pointer!important;
  height: 50px;
  background-image: url(../../assets/images/dashboard/grey-dropdown.png)!important;
  background-repeat: no-repeat!important;
  background-position: 97%!important;
  transition: .5s;
}
.category-parent-dropdown .dropdown:hover{
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s;
}
.category-parent-dropdown .dropdown-toggle{
  width: 100%;
  height: 50px;
}
.category-parent-dropdown .form-group{
  margin-bottom: 0px;
}
.category-parent-dropdown .dropdown-menu{
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
}
.category-parent-dropdown .dropdown-menu::-webkit-scrollbar-track {
  background-color: white!important;
}
.category-parent-dropdown .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px!important;
  color: #1B2952;
  font-size: 13px!important;
  font-family: "Axiforma-Regular"!important;
  margin: auto!important;
  border: 0px solid #fff !important;
}
.category-parent-dropdown .dropdown-search input{
  margin-bottom: 0px;
}
.category-parent-dropdown  .dropdown-item:hover , .category-parent-dropdown  .dropdown-item:focus , .category-parent-dropdown  .dropdown-item:active {
  border: 0px solid #fff !important;
  background-color: #F8F9FD!important;
  color: #1B2952!important;
}
.category-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E3E6F1;
  margin-top: 40px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.add-cat-tag-btn1{
  width: 100px;
  height: 40px;
  border: 1px solid #BCC1CE;
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #fff;
  transition: 0.5s;
  border-radius: 3px;
}
.add-cat-tag-btn1:hover{
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.category-parent-dropdown .dropdown .dropdown-toggle{
  text-align: left;
  padding: 10px 20px 15px 20px;
}
.cms-banner-img {
  position: relative;
  /* padding: 10px 0px; */

}
.cms-banner-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
}
.cms-tag-popup .modal-body{
  padding: 0px;
}
.cms-tag-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E3E6F1;
  margin-top: 40px;
  padding: 15px 20px 15px 20px;
}
.cms-tag-popup .cms-tag-body{
  padding: 0px 20px 0px 20px;
}
.cms-tag-body h4{
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1B2952!important;
  margin: 0px;
}
.cms-tag-body h5{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782A1!important;
  margin-top: 16px;
  margin-bottom: 25px;
}
.cms-tag-footer .cms-tag-footer-btn1{
  width: 100px;
  height: 40px;
  border: 1px solid #BCC1CE;
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #fff;
  border-radius: 3px;
  transition: 0.5s;
}
.cms-tag-footer .cms-tag-footer-btn1:hover {
  color: #484e5e;
  border: 1px solid #7782A1!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.tag-count-number {
  width: 100px;
  text-align: left;
}
.tag-count-number p{
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.tags-names{
  width: calc(100% - 370px);
}
.jodit-wysiwyg table tr td{
  border: none!important;
  min-width: auto!important;
}
.cms-mobile-btn-navigation{
  display: none;
}
.cms-phone-book .dropdown-menu{
  height: 300px;
  overflow: auto;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.cms-phone-book .dropdown-menu::-webkit-scrollbar-track {
  background-color: white!important;
}
.cms-phone-book .dropdown-search input{
  margin-bottom: 0px;
}
.cms-phone-book .dropdown-item{
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px!important;
  color: #1B2952;
  font-size: 13px!important;
  font-family: "Axiforma-Regular"!important;
  margin: auto!important;
  border: 0px solid #fff !important;
}
.cms-phone-book .dropdown-item:hover , .cms-phone-book .dropdown-item:active , .cms-phone-book .dropdown-item:focus{
  border: 0px solid #fff !important;
  background-color: #F8F9FD!important;
}
.cms-phone-book .dropdown-item img{
  margin-right: 7px;
}
.cms-phone-book .dropdown-search p {
  position: absolute!important;
  right: 10px!important;
  top: 15px!important;
  color: #BCC1CE!important;
  font-size: 12px!important;
  margin: 0px!important;
  font-family: "Axiforma-Regular"!important;
  cursor: pointer!important;
}
.category-heading{
  display: none;
}
.page-editor .accordionItemContent .jodit-wysiwyg:hover{
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s!important;
}
.page-editor .color_input:hover{
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s!important;
}
.jodit_theme_default:hover{
  background-color: #F8FAFE!important;
  border-color: #3064F9!important;
  transition: .5s!important;
}
.cms-pages-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 3px!important;
  margin-left: 6px;
}
.cms-pages-tooltip .cms-pages-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 32px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.cms-pages-tooltip .cms-pages-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 14%;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.cms-pages-tooltip:hover .cms-pages-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cms-posts-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
}
.cms-posts-tooltip .cms-posts-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: 32px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.cms-posts-tooltip .cms-posts-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 14%;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.cms-posts-tooltip:hover .cms-posts-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cm-scroller::-webkit-scrollbar{
  width: 8px !important;
}
.cms-tag-popup{
  max-width: 600px!important;
}
.page-body .jodit_theme_default:hover {
  background-color: #fff!important;
  border-color: #dadada!important;
}
.page-body .jodit-wysiwyg{
  overflow: hidden!important;
}
.non-draggable-parent{
  padding-left: 10px;
}
.cms-button-block .contact-flaginputbox{
  z-index: 9;
  background-color: transparent;
  width: calc(100% - 81px);
  margin-left: 100px;
}
.cms-button-block .contact-flaginputbox:hover , .cms-button-block .contact-flaginputbox:focus , .cms-button-block .contact-flaginputbox:focus-visible{
  background-color: transparent!important;
  border-color: #BCC1CE!important;
}
.cms-button-block .https-link {
  height: 50px;
  line-height: 50px;
  font-family: "Axiforma-Regular2";
  border-top: 1px solid #BCC1CE;
  border-bottom: 1px solid #BCC1CE;
  border-left: 1px solid #BCC1CE;
}
.cms-tag-add h2{
  color: #7782A1;
  font-size: 13px;
  margin: 0px 0px 10px 0px;
  font-family: "Axiforma-Regular";
}
.no-app-dropdown p{
  color: #BCC1CE;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-align: center;
  margin-top: 30px;
}
.no-app-dropdown div{
  text-align: center;
}
.pages-list-title{
  border-bottom: 1px solid #F1F2F8;
  padding: 13px 20px;
  width: 50%;
}
.pages-list-title h2{
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.pages-preview-list{
  padding: 32px 30px 60px 30px;
  border-left: none;
  width: 100%;
}
.pages-preview-list-box {
  height: 651px;
  width: 302px;
  background-color: #F9F9F9;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: auto;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
}
.cms-pages-listing{
  display: flex;
}
.page-listing-head{
  box-shadow: 0px 1px 4px rgb(0 0 0 / 6%);
  height: 39px;
  padding-left: 11px;
  padding-top: 14px;
  padding-right: 13px;
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
}
.page-listing-head h3 svg { 
  margin-right: 11px;
}
.page-listing-head h3{
  color: #1F1F1F;
  font-size: 12px;
  font-family: "Axiforma-bold";
  margin: 0px;
}
.page-listing-head h3 img{
  margin-right: 8px;
}
.page-listing-body{
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.page-listing-body p{
  color: #1A1A1A;
  font-size: 12px;
  font-family: 'Axiforma-Regular';
  margin: 0px;
  margin-bottom: 44px;
  word-break: break-word;
}
.pages-preview-list-box::-webkit-scrollbar {
  width: 0px !important;
}
.pages-preview-list-box::-webkit-scrollbar-thumb {
  background: #fff !important;
  border-radius: 0px !important;
}
.cms-page-list-mobile{
  display: none;
}
.post-status-detail{
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.post-status-detail h4{
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.cms-posts-listing{
  display: flex;
}
.posts-preview-list{
  padding: 40px 30px 60px 30px;
  border-left: none;
  width: 100%;
}
.posts-preview-list-box {
  height: 651px;
  width: 302px;
  background-color: #F9F9F9;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: auto;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
}
.post-listing-head {
  height: 45px;
  padding: 0px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #DEDEDE;
}
.post-listing-heading {
  color: #1A1A1A!important;
  font-size: 12px!important;
  font-family: "Axiforma-Bold"!important;
  margin: 0px 0px 0px 10px!important;
  line-height: 18.27px!important;
}
.post-listing-head h3 img {
  margin-right: 8px;
}
.post-listing-head-left{
  display: flex;
  align-items: center;
}
.post-listing-head-right{
  display: flex;
  align-items: center;
}
.post-listing-head-right .post-list-bookmark{
  margin-left: 20px;
}
.post-listing-filters{
  height: 16px;
  margin: 12px 0px 0px 0px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.post-listing-filters-left img{
  margin-right: 20px;
  vertical-align: sub!important;
}
.post-listing-filters-right img{
  margin-left: 20px;
  vertical-align: sub!important;
}
.post-listing-filters-left{
  display: flex;
  align-items: center;
}
.post-listing-filters-right{
  display: flex;
  align-items: center;
}
.total-post-count{
  padding: 2px 6px 1px 6px!important;
  background-color: #6F6F6F;
  width: max-content;
  color: #fff!important;
  margin: 0px;
  border-radius: 10px;
  font-size: 8px!important;
  line-height: 11px!important;
}
.change-post-view{
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-top: 30px;
  z-index: 9;
  position: relative;
}
.change-post-view svg{
  cursor: pointer;
  stroke: #e3e6f1;
  transition: .5s;
}
.change-post-view svg:hover{
  stroke: #7782A1;
  transition: .5s;
}
.change-post-view svg.active{
  stroke: #7782A1;
  transition: .5s;
}
.post-type-one-thumbnail{
  height: 140px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}
.post-list-type-one{
  position: relative;
  width: 50%;
}
.post-one-title{
  color: #1F1F1F;
  font-size: 10px;
  font-family: "Axiforma-bold";
  text-align: left;
  margin: 6px 8px 7px 8px;
  /* min-height: 27px;
  max-height: 40px; */
  word-break: break-word;
  line-height: 14.05px;
  overflow: hidden;
}
.cms_productId input{
  /* background-color: #F8FAFE; */
  border: 1px solid #BCC1CE;
  /* cursor: not-allowed; */
  font-size: 13px;
  text-align: left;
  color:#7782A1;
  font-family: "Axiforma-Regular"!important;
  padding: 15px;
}
.cms_productId input:hover{
  border: 1px solid #BCC1CE !important;
}
.cms_productId img{
  position: absolute;
  top: 18px;
  right: 30px;
  cursor: pointer;
}
.post-author-grid-lt{
display: flex;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 6px 0 0;
  width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin: 0 4px 0 0;
}
.post-author-grid-lt img{
  border-radius: 100%;
  width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.post-one-excerpt{
  color: #6F6F6F!important;
  font-size: 8px!important;
  font-family: "Axiforma-Regular"!important;
  margin: 6px 8px 0px 8px;
  text-align: left!important;
  /* min-height: 27px;
  max-height: 40px; */
  word-break: break-word;
  line-height: 10.75px!important;
  overflow: hidden;
}
.post-list-type-one .post-one-timestamp{
  color: #B0B0B0;
  font-size: 7px;
  font-family: "Axiforma-Regular";
  margin: 6px 8px 12px 8px;
  text-align: left;
  line-height: 9.41px;
}
.post-filter {
  position: fixed;
  right: -400px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 999;
  border-left: 1px solid #E3E6F1;
  border-top: 1px solid #E3E6F1;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}
.show-post-filter{
  right: 0px;
}
.post-filter-icon{
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
}
.post-filter-height{
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
}
.post-filter-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EDF0F5;
  padding: 17px 0px 20px 0px;
}
.post-filter-head h1{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin: 0px;
  line-height: 25px;
}
.post-filter-head p{
  cursor: pointer;
  color: #7782A1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.cms-post-listing-mobile{
  display: none;
}
.post-listing-style-one{
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0px 15px 0px;
}
.post-listing-style-two{
  margin: 12px 0px 15px 0px;
}
.post-list-type-one:nth-child(odd) {
  position: relative;
  width: 47%;
  margin-left: 5px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  margin-bottom: 7px;
  margin-right: 3px;
  background-color: #fff;
  /* padding-right: 5px;
  padding-top: 5px; */
}
.post-list-type-one:nth-child(even) {
  position: relative;
  width: 47%;
  margin-right: 5px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  margin-bottom: 7px;
  margin-left: 4px;
  background-color: #fff;
  /* padding-left: 5px;
  padding-top: 5px; */
}
.posts-preview-list-box::-webkit-scrollbar {
  width: 0px !important;
}
.post-list-type-two{
  display: flex;
  justify-content: space-between;
  padding: 11px;
  border: 1px solid #EBEBEB;
  margin: 0px 7px 10px 7px;
  border-radius: 10px;
  background-color: #fff;
}
.post-type-two-data{
  width: calc(100% - 70px);
}
.post-type-two-thumbnail{
  height: 64px;
  width: 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  position: relative;
}
.post-list-bookmark-div .post-list-bookmark-img-list-view{
  width: unset !important;
}
.post-list-bookmark-img-list-view{
 position: absolute;
 top: 4px;
 right: 4px;
}
.post-type-two-data .post-two-heading{
  color: #1A1A1A!important;
  font-size: 10px!important;
  line-height: 14px!important;
  font-family: "Axiforma-Bold";
  margin-top: 0px!important;
  text-align: left!important;
  word-break: break-word!important;
}
.post-type-two-data .post-two-excerpt{
  color: #6F6F6F!important;
  font-size: 8px!important;
  line-height: 10.75px!important;
  font-family: "Axiforma-Regular";
  margin-top: 7px !important;
  text-align: left!important;
  word-break: break-word!important;
  margin-bottom: 7px;
}
.post-type-two-data h6{
  color: #A1A1A1;
  font-size: 7px;
  font-family: "Axiforma-Regular";
  margin-top: 7px;
  text-align: left;
}
.category-tag-view .cats-list {
  min-height: 80vh;
}
.category-list-title {
  border-bottom: 1px solid #F1F2F8;
  padding: 13px 20px;
  display: flex;
  width: 50%;
}
.category-list-title h2 {
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  width: calc(100% - 315px);
}
.category-list-title h5 {
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.cms-category-listing{
  display: flex;
}
.category-tag-view{
  min-width: 50%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}
.tagpage-main-div{
  min-height: 77vh !important;
}
.tagpage-main-div .tags-list {
  min-height: 77vh !important;
}
.tagpage-main-div .cats-list {
  min-height: 77vh !important;
}
.tagpage-main-div .cat-tag{
  min-height: 77vh !important;
  display: unset;
}
.category-tag-view::-webkit-scrollbar{
  width: 3px !important;
}
.no-cat-tag{
  padding-top: 205px;
}

.no-categorydata{
  padding-top: 235px;
}
.no-categorydata p {
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 30px;
  margin-bottom: 0px;
}
.category-preview-list {
  padding: 32px 30px 60px 30px;
  border-left: none;
  width: 100%;
  min-width: 50%;
}
.category-preview-list-box {
  height: 651px;
  width: 302px;
  background-color: #fff;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: auto;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
}
.category-preview-list-box::-webkit-scrollbar {
  width: 0px !important;
}
.category-list-preview{
  margin-top: 35px;
}
.category-list-preview div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px 0px 11px;
  margin-bottom: 43px;
}
.category-list-preview div p{
  color: #333333;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.category-mobile-view{
  display: none;
}
.tags-names h6{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  word-break: break-word;
  text-align: left;
}
.tagsdetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 39.5px 20px;
  border-bottom: 1px solid #e3e6f1;
  width: 100%;
}
.tagsaction{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 70px;
  margin-left: 80px;
}
.tagsaction img{
  cursor: pointer;
}

.post-setting-create-page-preview{
     padding: 70px 20px 0px 0;
}
.post-setting-page-editor{
  height: calc(100vh - 148px);
}
.cms-author{	
  display: flex;	
  /* align-items: center;	 */
}	
.cms-author div{	
  overflow: hidden;
  width: 24px;	
  height: 24px;	
  border-radius: 100%;	
  margin-right: 10px;	
  background-size: cover;	
  background-repeat: no-repeat;	
  background-position: center;	
}	
.cms-author p{	
  margin-bottom: 0px;	
  color: #1B2952;	
  font-size: 13px;	
  font-family: "Axiforma-Regular";	
  margin: 0px;	
}	
.cms-filterby-post .dropdown-toggle::after{	
  display: none;	
}	
.cms-filterby-post .search-dropdown-before{	
  position: relative;	
  top: 0px;	
}	
.author-dropdown .dropdown-toggle::after{	
  display: none;	
}	
.author-dropdown .search-dropdown-before{	
  position: relative;	
  top: 3px;	
}	
.cms-author-selected div{	
  position: relative;	
  top: 0px;	
}	
.cms-author-selected div img{
  border-radius: 100%;
}
.cms-author-selected h3{	
  position: relative;	
  top: 4px;	
  margin-bottom: 0px;	
  color: #1B2952;	
  font-size: 13px;	
  font-family: "Axiforma-Regular";	
  margin: 0px;	
}	
.cms-author-mobile{	
  margin: 0px 10px 40px 10px;	
  display: flex;	
  align-items: center;	
}	
.cms-author-mobile div{	
  overflow: hidden;
  width: 24px;	
  height: 24px;	
  border-radius: 100%;	
  margin-right: 10px;	
  background-size: cover;	
  background-repeat: no-repeat;	
  background-position: center;	
}	
.cms-author-mobile p{	
  margin-bottom: 0px;	
  color: #1B2952;	
  font-size: 13px;	
  font-family: "Axiforma-Regular";	
  margin: 0px;	
}	
.pages-delete-mob{	
  background-color: transparent;	
  border: none;	
  outline: none;	
  margin-left: 25px;	
}	
.modal-content {	
  border: none;	
}

.cmsnewspinner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0px;
  opacity: 1;
}
.post-list-setting{
  margin-right: 0px!important;
  margin-left: 15px!important;
}
.post-list-preview-image{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.post-list-preview-banner{
  /* height: 189px; */
  /* width: 100%; */
  margin-bottom: 15px;
}
.post-list-preview-detail{
  padding: 0px;
  background-color: #fff;
}
.cms-icon-tooltip-delete .cms-icon-tooltipsubheader{
  left: -40px !important;
}
.cms-icon-tooltip-delete .cms-icon-tooltipsubheader::after {
  left: 42px !important;
}
.preview-1 , .preview-2{
  max-height: 643px;
  overflow: auto;
  overflow-x: hidden;
  background-color: #F9F9F9;
  min-height: 643px;
}
.preview-1::-webkit-scrollbar , .preview-2::-webkit-scrollbar {
  width: 0px !important;
}
.cms-posts-listing .posts-preview-list .posts-preview-detail{
  margin-top: 41.39px;
}
.posts-preview-detail {
  max-height: 651px;
  width: 302px;
  background-color: #fff;
  margin: auto;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  overflow-y: hidden;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
}
.posts-preview-detail h2{
  color: #1A1A1A;
  font-family: "Axiforma-Bold";
  font-size: 15px;
  line-height: 21.08px;
  text-align: left;
}
.posts-preview-detail p{
  color: #1A1A1A;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  line-height: 14.78px;
  text-align: left;
}

.posts-preview-detail p:last-child{
  margin-bottom: 0px;
}
.posts-preview-detail h3{
  color: #1A1A1A;
  font-family: "Axiforma-Bold";
  font-size: 11px;
  text-align: left;
}
.posts-preview-detail .gallerygrid .custom-gallery{
  width: 50%;
  /* height: 135px; */
}
.posts-preview-detail .gallerygrid .custom-gallery div{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.posts-preview-detail::-webkit-scrollbar {
  width: 0px !important;
}
.post-list-author{
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 7px;
}
.post-list-author div:nth-child(1){
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px 6px 0px 0px;
  background-color: #CFCFCF;
}
.post-list-author h5{
  color: #6F6F6F;
  font-family: "Axiforma-Regular";
  font-size: 10px;
  margin: 0px;
  line-height: 16px;
}
.post-list-author h6{
  color: #A1A1A1;
  font-family: "Axiforma-Regular";
  font-size: 10px;
  line-height: 16px;
  margin: 0px;
}
.post-list-category-preview{
  display: flex;
  align-items: center;
}
.post-list-category-preview h6{
  color: #6F6F6F;
  font-family: "Axiforma-Regular";
  font-size: 10px;
  line-height: 10.75px;
}
.post-list-category-preview h6 span{
  color: #E3E3E3!important;
  margin: 0px 9.5px 0px 10px!important;
}
.posts-preview-detail button{
  width: 100%;
  height: 35px;
  border-radius: 6px;
  background-color: #1A1A1A;
  font-family: "Axiforma-Regular";
  color: #fff;
  border: none;
  font-size: 11px;
  line-height: 14.78px;
}
.post-list-tag-preview{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.post-list-tag-preview h5{
  color: #6F6F6F;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  background-color: #EBEBEB;
  border-radius: 36px;
  line-height: 11px;
  margin-right: 6px;
  padding: 3.5px 10px 3.5px 10px;
}
.cms-posts-listing .cms-settings h2{
  margin-bottom: 0px;
}
.cms-posts-listing .cms-settings p{
  margin-bottom: 0px;
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border-bottom: 1px solid #E3E6F1;
  padding-bottom: 20px;
  margin-top: 7px;
}
.post-list-setting-seprator{
  border-bottom: 1px solid #E3E6F1;
  margin-top: 20px;
}
.post-advacnce-settings{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.post-advacnce-settings h4{
  color: #3064F9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  cursor: pointer;
  display: block;
}
.post-advacnce-settings h4 svg{
  margin-left: 6.2px;
}
.post-advacnce-settings h3{
  color: #BCC1CE;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  cursor: pointer;
}
.post-detail-author{
  display: flex;
  align-items: center;
  padding: 7.5px 0px 0px 0px;
}
.post-detail-author h4{
  color: #A1A1A1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 0px 6px!important;
}
.post-detail-author h5{
  color: #A1A1A1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin: 5px 0px 0px 6px!important;
}
.post-detail-category-preview{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
}
.post-detail-category-preview h6 {
  color: #3064F9;
  font-family: "Axiforma-Regular";
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

}
.post-detail-category-preview h6 span{
  margin: 0px 9.5px 0px 10.5px;
  color: #E3E3E3;
}
.post-detail-tag-preview {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* margin-top: 10px;
  margin-left: 10px; */
}
.post-detail-tag-preview h5 {
  color: #A1A1A1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  background-color: #F3F3F3;
  border-radius: 36px;
  line-height: 14px;
  margin-right: 6px;
  padding: 2px 7px 3px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-filter-body .post-filter-dropdown .dropdown-toggle:after{
  display: none;
}
.post-filter-dropdown .dropdown-toggle{
  white-space: normal!important;
  width: 100%;
  border: 1px solid #BCC1CE!important;
  border-radius: 3px;
  text-align: left;
  height: 40px;
  margin-bottom: 15px;
  color: #1B2952!important;
  background-image: url(../../assets/images/dashboard/grey-dropdown.png)!important;
  background-repeat: no-repeat!important;
  background-position: 97%!important;
  -webkit-appearance: none;
  padding: 2px 15px 0px 15px;
  transition: .5s;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.post-filter-dropdown .dropdown-menu{
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
  transform: translate3d(0px, 40px, 0px)!important;
}
.post-filter-dropdown label{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  word-break: break-all;
}
.post-filter-dropdown .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px!important;
  color: #1B2952;
  font-size: 13px!important;
  font-family: "Axiforma-Regular"!important;
  margin: auto!important;
  border: 0px solid #fff !important;
}
.post-filter-dropdown .dropdown-item:hover {
  border: 0px solid #fff !important;
  background-color: #F8F9FD!important;
}
.delete-post-filter-button{
  border: 1px solid #BCC1CE;
  background-color: transparent;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  margin-bottom: 15px;
}
.delete-post-filter{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.post-filter-dropdown{
  width: 100%;
}
.post-filter-body h6{
  color: #C8CEDB;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-transform: uppercase;
  margin-bottom: 12px;
}
.post-filter__menu {
  box-shadow: 0 10px 16px rgba(0,0,0,.16) !important;
  margin-top: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.post-filter__menu-list {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}
.post-filter__option {
  font-size: 13px!important;
  background-color: #ffffff!important;
  color: #1B2952!important;
  padding: 11px 10px!important;
  margin: auto;
  width: calc(100% - 10px)!important;
  font-family: "Axiforma-Regular";
}
.post-filter__option:hover {
  font-size: 13px!important;
  background-color: #F8F9FD!important;
  cursor: pointer;
  border-radius: 4px;
  padding: 11px 10px!important;
}
.post-filter__value-container , .post-filter__value-container:focus{
  height: 40px!important;
  min-height: 40px!important;
  cursor: pointer;
  background-image: url(../../assets/images/dashboard/grey-dropdown.png)!important;
  background-repeat: no-repeat!important;
  background-position: 97%!important;
  outline: none!important;
  padding-left: 12px!important;
}
.post-filter__control , .post-filter__control:focus{
  height: 40px!important;
  min-height: 40px!important;
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 0px!important;
  outline: none!important;
}
.post-filter__indicators{
  display: none!important;
}
.post-filter__single-value, .post-filter-dropdown .post-filter__single-value{
  font-size: 13px!important;
  color: #1B2952!important;
    font-family: "Axiforma-Regular"
}
.post-filter-dropdown .post-filter__placeholder{
  font-size: 13px!important;
  color: #1B2952!important;
}
.delete-post-filter .categogy-chip-section .dropdown .dropdown-toggle{
  min-height: 40px!important;
  background-repeat: no-repeat!important;
  background-position: 97%!important;
  height: auto;
}
.categogy-chip-section .dropdown-menu .dropdown-item .cms-author{
  align-items: center!important;
}

.post-filters-buttons{
  background-color: #3064F9;
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  width: 100%;
  height: 40px;
  border-radius: 3px;
  padding-top: 4px;
  margin: 0px 10px;
}

.post-filters-buttons-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin-left: -10px;
}


.filter-buttondisabled {
  width: 100%;
  height: 40px;
  border: none;
  color: white;
  background-color: #bcc1ce;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: not-allowed !important;
}

.post-filters-buttons button:hover{
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
}


.delete-post-filter .search-dropdown-before div div {
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
  margin: 6px 0px 0px 0px;
}
.delete-post-filter .search-dropdown-before div div p{
  margin-bottom: 3px;
  margin-right: 6px;
  color: #1B2952!important;
  margin-top: -3px;
}
.post-filter__option--is-disabled{
  cursor: not-allowed!important;
  background-color: #F8F9FD!important;
}
.delete-post-filter .post-filter-dropdown .dropdown-item:hover {
  color: #1B2952!important;
}
.delete-post-filter .dropdown-item.active, .delete-post-filter .dropdown-item:active {
  background-color: #F8F9FD;
  color: #1B2952!important;
}
.delete-post-filter .search-dropdown-before{
  margin: 2px 0px 0px 0px;
}
.otherpostmargin{
  margin-bottom: 20px;
}
.cms-section-visiblity {
  stroke: #bcc1ce;
  position: absolute;
  right: 45px;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
  transition: .5s;
}
.accordionItem:hover .cms-section-visiblity{
  opacity: 1;
  transition: .5s;
}
.cms-section-hidden .cms-section-visiblity , .cms-section-hidden:hover .cms-section-visiblity {
  stroke: #1B2952;
  opacity: 1;
}
.cms-section-hidden .accordionItemHeading{
  color: #BCC1CE!important;
}
.accordionItemContent .video-upload{
  border: 1px solid #BCC1CE;
  height: 50px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border-right: none!important;
}
.accordionItemContent .video-upload p{
  color: #1B2952!important;
  font-size: 13px!important;
  font-family: "Axiforma-Regular2"!important;
  margin: 0px!important;
  padding: 0px 28px 0px 16px;
}
.accordionItemContent .video-upload input{
  color: #1B2952!important;
  font-size: 13px!important;
  font-family: "Axiforma-Regular2"!important;
  margin: 0px!important;
}
.accordionItemContent .video-upload input::placeholder{
  color: #BCC1CE!important;
}
.accordionItemContent .video-error-text{
  display: flex;
  align-items: center;
  color: #FF4848;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 11px 0px 0px 0px;
  position: absolute;
}
.video-error-text svg{
  margin-right: 5.5px;
  position: relative;
  top: -2px;
}
.video-error{
  border: 1px solid #FF8E8E!important;
}
.video-error input{
  border: 1px solid #FF8E8E!important;
}






.progress-bar-parent .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  margin: 0px;
  width: 60px;
  height: 60px;
  border: none;
}

.progress-bar-parent .card .percent {
  position: relative;
}

.progress-bar-parent .card svg {
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(-90deg);
}

.progress-bar-parent .card svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f0f073;
  stroke-width: 5;
  stroke-linecap: round;
}

.progress-bar-parent .card svg circle:last-of-type {
  stroke-dasharray: 188.5px;
  stroke-dashoffset: calc(188.5px - (188.5px * var(--percent)) / 100);
  stroke: #3498db; 
}

.progress-bar-parent .card .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-bar-parent .card .number h3 {
  font-size: 13px!important;
  font-family: "Axiforma-Regular2"!important;
  color: #fff;
  position: relative;
  top: 7px;
}

.progress-bar-parent .card .number h3 span {
  font-size: 12px;
}

.progress-bar-parent .card .title h2 {
  margin: 25px 0 0;
}

.progress-bar-parent .card:nth-child(1) svg circle:last-of-type {
  stroke: #fff;
}

.progress-bar-parent .card:nth-child(2) svg circle:last-of-type {
  stroke: #2ecc71;
}
.custom-app-player{
  width: 810px!important;
  height: 455px!important;
  position: absolute;
  right: 0;
  left: 0;
  top: 20px;
  margin: auto;
}
.progress-bar-parent{
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 810px;
  height: 455px;
  background-color: rgba(256, 256, 256, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageuploadpopup-footer-disable{
  align-items: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.upload-gallery-box h5{
  position: absolute;
  bottom: 10px;
  left: 133px;
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular"!important;
}
.uploadvideopopup .crop-delete {
  top: 40px;
  right: 156px;
}
.page-editor .accordionItem .cms-image-placeholder{
  color: #BCC1CE!important;
}
.post-list-settings .select-parent label {
  margin-top: 10px!important;
}
.post-list-settings .post-setting-title {
  font-family: "Axiforma-Bold";
  font-size: 15px;
  color: #1B2952;
  margin-bottom: 0px;
  margin-top: 20px;
  line-height: 21.08px;
}
.post-settings-head h2{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-top: 19px;
  margin-bottom: 7px;
}
.post-settings-head h3{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  margin-bottom: 19.5px;
}
.post-settings-head{
  border-bottom: 1px solid #E3E6F1;
  margin-bottom: 27.5px;
}
.post-settings-filter-dropdown .cms-filterby-post .search-dropdown-before {
  top: -2px!important;
}
.post-list-settings-head  h2{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-top: 19px;
  margin-bottom: 7px;
}
.no-attributes{
  background-color: #fff;
  /* border: 1px solid #E3E6F1!important; */
  border-radius: 6px;
  height: 100%;
  /* box-shadow: 0px 2px 6px rgb(0 0 0 / 6%); */
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-attributes div{
  text-align: center;
}
.no-attributes div p{
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 33px;
}
.attributes-width{
  background-color: #fff;
  border: 1px solid #E3E6F1!important;
  border-radius: 6px;
  min-height: calc(100vh - 250px);
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
  display: flex;
}
.attributes-preview-screen {
  height: 651px;
  width: 310px;
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  overflow-y: auto;
  overflow-x: hidden;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
  position: relative;
}
.attributes-listing-left{
  /* width: 50%; */
  min-width: 50%;
  padding-right: 10px;
}
.attributes-listing-right{
  width: 50%;
  min-width: 50%;
  padding: 75px 30px 60px 30px;
}
.attributes-list-title {
  border-bottom: 1px solid #F1F2F8;
  padding: 13px 0px;
  display: flex;
  min-width: 50%;
}
.attributes-list-title h2 {
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  width: calc(100% - 240px);
  padding-left: 20px;
}
.attributes-list-title h5 {
  color: #BCC1CE;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
}
.attribute-entry{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F1F2F8;
  min-height: 101px;
  width: 100%;
  position: relative;
  padding: 15px 0px;
}
.attribute-entry a{
  width: 100%;
  height: 100%;
  position: absolute;
}
.attribute-summry{
  display: flex;
  align-items: center;
  width: calc(100% - 240px);
  padding-right: 20px;
}
.attribute-sub{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
}
.attribute-summry h2{
  color: #1B2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin: 0px;
  word-break: break-word;
}
.attribute-summry h3{
  color: #3064F9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 6px 0px 0px 0px;
}
.attribute-icon{
  margin: 0px 15px 0px 20px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.attribute-related-post p{
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.attribute-action{
  width: 61px;
  min-width: 61px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.attribute-action img{
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.attributes-preview-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  border-bottom: 1px solid #DEDEDE;
  padding: 0px 14px 0px 11px;
}
.attributes-preview-header-left , .attributes-preview-header-right{
  display: flex;
  align-items: center;
}
.attributes-preview-header-left p{
  color: #1A1A1A;
  font-size: 13px;
  font-family: "Axiforma-Bold";
  margin: 0px 0px 0px 11px;
  width: 210px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.attributes-preview-header-right p{
  color: #A1A1A1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 0px 0px;
}
.cms-pages-head-div{
  display: flex;
}
.cms-pages-head-div svg{
  margin-right: 10px;
}
.cms-pages-main-head{
  display: flex;
}
.attribute-preview-list{
  margin-top: 26px;
  height: 500px;
  overflow: auto;
}
.attribute-preview-list div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 43px;
  padding: 0px 12px 0px 11px;
}
.attribute-preview-list div h4{
  color: #333333;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  width: 90%;
  word-break: break-word;
}
.attribute-preview-list::-webkit-scrollbar, .attributes-preview-screen::-webkit-scrollbar {
  width: 1px!important;
}
.attribute-preview-list::-webkit-scrollbar-track, .attributes-preview-screen::-webkit-scrollbar-track{
  background: transparent!important; 
}
.attribute-preview-list::-webkit-scrollbar-thumb, .attributes-preview-screen::-webkit-scrollbar-thumb{
  background: transparent!important; 
}
.attribute-preview-list::-webkit-scrollbar-thumb:hover, .attributes-preview-screen::-webkit-scrollbar-thumb:hover{
  background: transparent!important; 
}
.attributes-preview-screen a , .attributes-preview-screen a:hover{
  width: 95%;
  height: 38px;
  line-height: 39px;
  display: block;
  text-align: center;
  background-color: #3064F9;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  text-transform: uppercase;
  margin: 0px auto;
}
.add-attribute-popup{
  min-width: 600px;
  max-width: 600px!important;
}
.add-attribute-body h2{
  color: #1B2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin: 0px;
}
.add-attribute-body h3{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 10px 0px 24px 0px;
}
.add-attribute-body h4{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 20px 0px 9px 0px;
}
.add-attribute-body .add-attribute-input{
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  height: 50px;
  width: 100%;
  padding: 0px 15px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none;
  transition: .5s;
}
.add-attribute-body .add-attribute-input:hover , .add-attribute-body .add-attribute-input:focus{
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
  transition: .5s;
}
.add-attribute-popup .modal-body{
  padding: 0px;
}
.add-attribute-body{
  padding: 0px 20px 31px 20px;
}
.add-attribute-footer{
  padding: 14.5px 20px 15px 20px;
  border-top: 1px solid #E3E6F1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-attribute-footer-btn2 {
  background-color: #3064f9;
  color: #fff;
  border: 1px solid #3064f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
}
.add-attribute-footer-btn2:hover {
  /* background-color: #0040f9;
  border: 1px solid #0040f9; */
  background-color: var(--custom_color5)!important;
  color: var(--custom_color4)!important;
  border: 1px solid var(--custom_color6)!important;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.disabled-attribute-btn , .disabled-attribute-btn:hover {
  background-color: #BCC1CE !important;
  color: #fff !important;
  border: 1px solid #BCC1CE !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
  cursor: not-allowed;
  box-shadow: none;
}
.add-attribute-footer-btn1 {
  color: #7782A1;
  background-color: transparent;
  border: 1px solid #BCC1CE;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 14px;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
}
.add-attribute-footer-btn1:hover {
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.attribute-delete-popup{
  max-width: 420px!important;
}
.attribute-delete-popup .modal-body {
  width: 420px;
  min-width: 420px;
  height: 200px;
  min-height: 200px;
  max-width: 420px;
  max-height: 200px;
  padding: 35px 42px;
}
.attribute-delete-popup h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  text-align: center;
}
.attribute-delete-popup h6 {
  color: #7782a1;
  font-size: 13.5px;
  font-family: "Axiforma-Regular";
  text-align: center;
  margin-bottom: 30px;
}
.attribute-delete-popup-footer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.attribute-delete-popup .grey-btn p {
  width: 140px;
  height: 44px;
  border: 1px solid #c8cedb;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  transition: 0.5s;
  line-height: 44px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
}
.attribute-delete-popup .red-btn p {
  width: 140px;
  height: 44px;
  border: 1px solid #ffa7a7;
  color: #ff4848;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #fff;
  transition: 0.5s;
  line-height: 44px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}
.attributes-mobile{
  display: none;
}
.attribute-name-head img{
  max-width: 21px;
  max-height: 21px;
}
.attribute-pagehead{
  justify-content: flex-end;
}
.attribute-child{
  margin-left: 20px;
}
.cms-list-visiblity {
  cursor: pointer;
  z-index: 9;
  opacity: 0;
  transition: .5s;
}
.postdetail:hover .cms-list-visiblity {
  opacity: 1;
  transition: .5s;
  stroke: #bcc1ce;
}
.pagedetail:hover .cms-list-visiblity {
  opacity: 1;
  transition: .5s;
  stroke: #bcc1ce;
}
.postdetail:hover .cms-list-visiblity svg{
  stroke: #bcc1ce;
}
.cms-list-visiblity{
  margin-right: 20px;
}
.cms-entry-hidden .cms-list-visiblity, .cms-entry-hidden:hover .cms-list-visiblity{
  stroke: #1B2952 !important;
  opacity: 1;
}
.cms-entry-hidden:hover .cms-list-visiblity svg{
  stroke: #1B2952;
}
.postdetail.cms-entry-hidden .postdetail-name{
  opacity: .5;
}
.pagedetail.cms-entry-hidden .pagedetail-name{
  opacity: .5;
}
.pagedetail.cms-entry-hidden .pagecreator-detail{
  opacity: .5;
}
.pagedetail.cms-entry-hidden .pagestatussection{
  opacity: .5;
}
.child-atr{
  margin-left: 35px;
}
.atr-plus-minus{
  display: flex;
}
.atr-plus-minus svg{
  position: relative;
  top: 9px;
}
.parent-atr{
  display: flex;
  align-items: center;
}
.attributes-added .category-checkmark {
  left: 8px;
}
.attributes-added .child-atr .category-checkmark {
  left: 10px;
}
.child-atr.category-list-container{
  margin-bottom: 14px;
  margin-top: 14px;
}
.add-attribute-body .react-tags {
  border-radius: 3px;
  min-height: 120px;
  padding: 5px 15px;
}
.attributes-preview h4{
  color: #1F1F1F;
  font-size: 12px;
  line-height: 16.86px;
  font-family: "Axiforma-Bold";
  display: flex;
  align-items: center;
  word-break: break-word;
}
.attributes-preview h4 img , .attributes-preview h4 svg{
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;
  position: relative;
  top: -2px;
}
.attributes-preview p{
  color: #6F6F6F;
  font-size: 9px;
  line-height: 15px;
  font-family: "Axiforma-Regular";
  margin-bottom: 5px;
  word-break: break-word;
}
.attributes-segment{
  margin-bottom: 20px;
}

.attributes-preview .attributes-segment:last-child{
  margin-bottom: 0px;
}
.attributes-segment div{
  padding-left: 26px;
}
.attributes-mobile-entry-first img{
  max-width: 24px;
  max-height: 24px;
}
.add-attribute-popup .newspinner{
  height: 300px;
  position: relative;
}
.attribute-page-head img{
  margin-right: 10px;
}
.attributes-added-mob .category-list-container{
  color: #1B2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  word-break: break-all;
}
.attributes-added-mob .category-checkmark {
  top: 0px;
}
.attributes-added-mob .atr-plus-minus svg {
  top: 4px;
}
.attributes-added-mob{
  margin-bottom: 20px;
}
.attributes-added-mob.category-created:hover {
  background-color: transparent!important;
}
.post-list-type-one .post-list-author{
  margin: 8px 8px 0px 8px;
}
.post-list-type-one .post-list-author div:nth-child(1) , .post-list-type-two .post-list-author div:nth-child(1){
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  margin: 0px 4px 0px 0px;
}
.author-seprator{
  width: 2px;
  height: 2px;
  border-radius: 100%;
  margin: 0px 4px;
  background-color: #CFCFCF;
}
.post-list-type-one .post-list-author .post-one-author{
  text-align: left;
  font-size: 8px;
}
.post-type-two-data .post-list-author .post-two-author{
  color: #6F6F6F;
  font-size: 7px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.post-type-two-data .post-list-author .post-two-timestamp{
  color: #B0B0B0;
  font-size: 7px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  line-height: 9.41px;
}

.custom-settings-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px!important;
  margin-left: 6px!important;
  top: -1px;
}
.custom-settings-tooltip .custom-settings-tooltipsubheader {
  visibility: hidden;
  width: 250px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -3px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.custom-settings-tooltip .custom-settings-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.custom-settings-tooltip:hover .custom-settings-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cmsafterupload {
  height: 24px!important;
  width: 24px!important;
  min-width: 24px!important;
}
.attribute-icon-background{
  background-color: #EDF0F5;
  border-radius: 3px;
  width: 36px;
  height: 36px;
  margin: 0px 0px 0px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cmspostsetting-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px!important;
  margin-left: 6px!important;
}
.cmspostsetting-tooltip .cmspostsetting-tooltipsubheader {
  visibility: hidden;
  width: 250px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 27px;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
}
.cmspostsetting-tooltip .cmspostsetting-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.cmspostsetting-tooltip:hover .cmspostsetting-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cat-parent-input-popup p{
  width: 90%;
  word-break: break-word;
}
.menu-upload-logo{
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.menu-upload-logo img{
  max-width: 480px;
  max-height: 480px;
}

.cat-parent-input-popup{
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #BCC1CE;
  padding: 15px 20px 15px 20px;
  color: #1b2952;
  outline: none;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  align-items: center;

}








.cms-icon-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
  display: flex;
  align-items: center;
}
.post-shape-icon-tooltip .cms-icon-tooltipsubheader{
  right: 0px;
  left: unset !important;
}
.post-shape-icon-tooltip .cms-icon-tooltipsubheader::after {
    left: unset !important;
    right: 10px;
}

.cms-icon-tooltip .cms-icon-tooltipsubheader {
  visibility: hidden;
  width: 200px;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 0px;
  padding: 12px 16px 12px 16px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  font-family: "Axiforma-Regular";
}
.cms-icon-tooltip:hover .cms-icon-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cms-icon-tooltip .cms-icon-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 10px;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}

.creatnew-disable{
    background-color: #bcc1ce;
    color: #fff;
    border: 1px solid #bcc1ce;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: 0.5s;
    font-size: 14px;
    width: 100px;
    height: 40px;
    margin-left: 20px;
    text-transform: capitalize;
    font-family: "Axiforma-Regular";
}
.attribute-filter-body-left h1{
  font-size: 9px;
  color: #1F1F1F;
  font-family: 'Axiforma-Bold';
  min-height: 40px;
  margin-bottom: 0px;
  width: 107px;
  display: flex;
  align-items: center;
  padding: 4px 13px;
  line-height: 11px;
  background-color: #FFFFFF;
  word-break: break-word;
}
.attribute-filter-body{
  display: flex;
}
.attribute-filter-body-left{
  height: 535px;
  overflow: auto;
  background-color: #F7F7F7;
  max-width: 107px;

}
.attribute-filter-body-left::-webkit-scrollbar{
  width: 0px !important;
}
.attributes-listing-left::-webkit-scrollbar{
  width: 3px !important;
}
.attribute-filter-body-left-parent{
  height: calc(100% - 40px);
    /* background-color: #F7F7F7; */
}

.attribute-filter-body-left-parent p{
  color: #1A1A1A;
  font-size: 9px;
  font-family: 'Axiforma-Regular';
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 16px 0px 14px;
  margin: 0px;
  margin: 0px 13px 0px 13px;
  text-align: left;
  word-break: break-word;

}
.attribute-filter-body-right{
  width: calc(100% - 107px);
  padding: 0px 13px 0px 11px;
  height: 535px;
  overflow: auto;
}
.attribute-filter-body-right::-webkit-scrollbar{
  width: 0px !important;
}
.attribute-filter-body-right-search{
  border-bottom: 1px solid #EBEBEB;
  padding: 13px 0px 11px;
  font-size: 11px;
  color: #A1A1A1;
  font-family: 'Axiforma-Regular';
 
}
.attribute-filter-body-right-search svg{
  margin-right: 7px;
}
.attribute-filter-body-right-child p{
  font-size: 9px;
  color: #A1A1A1;
  font-family: 'Axiforma-Regular';
  margin-top: 27px;
  display: flex;
    align-items: center;
}

.attribute-filter-body-right-child p svg{
  margin-right: 11px;
  min-width: 10.16px;
}
.attribute-filter-body-bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 16px;
}
.attribute-filter-body-clear{
  width: 50%;
    margin-right: 11px;
    height: 37px;
    border: 1px solid #CFCFCF;
    background: transparent;
    font-family: 'Axiforma-Regular';
    color: #B0B0B0;
    font-size: 11px;
    border-radius: 6px;
    cursor: default !important;
}
.attribute-filter-body-apply{
  width: 50%;
    height: 37px;
    background-color: #1A1A1A;
    font-family: 'Axiforma-Regular';
    color: #FFFFFF;
    font-size: 11px;
    border-radius: 6px;
    border: unset;
    cursor: default !important;
}
.attribute-filter-body-right-child p span{
  word-break: break-word;
}
.post-box-size, .shape-of-post{
  display: flex;
    height: 44px;
    align-items: center;
}
.post-box-size p, .shape-of-post svg{
  margin-left: 25px !important;
    cursor: pointer;
    margin: unset;
    margin-top: unset !important;
    padding-bottom: unset !important;
    border-bottom: unset !important;
}
.post-box-size p span {
  margin-left: 6px;
  color: #BCC1CE;
  font-size: 11px;
  font-family: 'Axiforma-Regular';
}
.post-shapenotselect {
  stroke: #e3e6f1;
  fill: #e3e6f1;
}
.post-shapeselect {
  stroke: #7782a1;
  fill: #7782a1;
}
.post-section-margin-top{
 display: flex;
}
.post-section-margin-second, .post-section-margin-first{
  display: flex;
  width: 50%;
}
.post-section-margin-second div, .post-section-margin-first div{
  margin-right: 20px;
  width: 50%;
  position: relative;
}
.post-section-margin-second div h4, .post-section-margin-first div h4{
  color: #BCC1CE;
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  position: absolute;
  margin-bottom: 0px;
  top: 18px;
  right: 37px;
  width: unset;
}
.post-section-margin-first input{
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  font-size: 13px;
  color: #1B2952;
  padding: 13px 15px !important;
  font-family: 'Axiforma-Regular';
}
.post-section-margin-second input{
  border: 1px solid #BCC1CE;
  border-radius: 3px;
  font-size: 13px;
  color: #1B2952;
  padding: 13px 15px !important;
  font-family: 'Axiforma-Regular';
}
.banner-ad-block{
    width: 100%;
    /* margin: 15px 10px; */
    height: 44px;
    background-image: linear-gradient(#8f2dff, #ff486d);
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-ad-block p{
  font-size: 12px;
  color: #ffff;
  font-family: "Axiforma-Regular";
  opacity: 0.5;
  margin-bottom: 0px;

}

.custom-ad-block{
  width: 100%;
  /* margin: 15px 10px; */
  height: 44px;
  background-image: linear-gradient(to top right, #66B9FF, #6865FF);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-ad-block p{
  font-size: 12px;
  color: #ffff;
  font-family: "Axiforma-Regular";
  opacity: 0.5;
  margin-bottom: 0px;

}
.category-character-limit{
  font-size: 10px;
  color: #BCC1CE;
  font-family: 'Axiforma-Regular';
  position: absolute;
  right: 30px;
  bottom: 17px;
  margin-bottom: 0px;
}
.setting-bt-on-home-web{
  background-color: #F8FAFE;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.setting-bt-off-hide-web{
  background-color: #F8FAFE;
  border: none;
  box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset !important;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.setting-bt-on-hide-web{
  background-color: #F8FAFE;
  border: none;
  box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.pagesetting-layout{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 244px;
  height: 538px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  border-radius: 3px;
  transition: .5s;
  border: 2px solid #E3E6F1;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 6%) !important;
  background-color: #fff;
}
.pagesetting-layout:hover{
  border: 2px solid #3064F9;
  box-shadow: 0px 3px 26px rgb(0 0 0 / 16%) !important;
  transition: .5s;
  background-color: #F9F9F9;
}
.pagesetting-layoutselect{
  border: 2px solid #3064F9;
    box-shadow: 0px 3px 26px rgb(0 0 0 / 16%) !important;
    transition: .5s;
    background-color: #F9F9F9;
}
.page-layout-head{
  padding: 15px 30px 0px;
}
.page-layout-head h2{
  font-size: 18px;
    color: #1B2952;
    line-height: 25px;
    font-family: 'Axiforma-Bold';
    margin-bottom: 5px;
}
.page-layout-head p{
  font-size: 13px;
  color: #7782A1;
  font-family: 'Axiforma-Regular';
  padding-bottom: 20px;
  margin-bottom: 0px;
  border-bottom: 1px solid #E3E6F1;
}
.pagesetting-layout-body{
  display: flex;
    padding: 30px 30px 120px;
}
.pagesetting-layout-body .pagesetting-layout:first-child{
margin-right: 30px;
}
.whitebg-layout{
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #E3E6F1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
}
.cmspageSetting{
  display: flex;
}
.cmspageSetting-leftdiv{
  width: 50%;
    padding: 17px 15px 15px 30px;
}
.cmspageSetting-leftdiv-head h2{
  font-size: 18px;
  color: #1B2952;
  font-family: 'Axiforma-Bold';
  margin-bottom: 5px;
}
.cmspageSetting-leftdiv-head p{
  font-size: 13px;
  color: #7782A1;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
  text-align: left !important;
}
.cmspageSetting-rightdiv{
  padding: 40px 30px 60px 30px;
  width: 50%;
}
.cmspageSetting-leftdiv-head{
  padding-bottom: 17px;
    border-bottom: 1px solid #E3E6F1;
}
.cmspagesetting-pagelist{
  border-bottom: 1px solid #E3E6F1;
  padding: 30px 0px;
}
.cmspageSetting-pagedetails{
  padding: 30px 0px;
}
.cmspageSetting-pagedetails .section-margin-toggle {
  margin-top: 32px;
  margin-bottom: 0px;
}
.cmspagesetting-pagelist-head{
  font-size: 14px;
  color: #1B2952;
  font-family: 'Axiforma-Bold';
  line-height: 20px;
  margin-bottom: 30px;
}
.cmspageSetting-show-head{
  margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cmspageSetting-show-head h4{
  font-size: 13px;
  color: #7782A1;
  font-family: 'Axiforma-Regular';
  line-height: 17px;
}
.cmspageSetting-imgshape{
padding-top: 10px;
display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.cmspageSetting-imgshape h4{
  font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    line-height: 17px;
}

.cms-heading-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
  display: flex;
  align-items: center;
}
.cms-heading-tooltip .cms-heading-tooltipsubheader {
  visibility: hidden;
  width: max-content;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: -10px;
  padding: 6px 10px 6px 10px;
  opacity: 0.8 !important;
  font-size: 11px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  font-family: "Axiforma-Regular";
}
.cms-heading-tooltip:hover .cms-heading-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cms-heading-tooltip .cms-heading-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 10px;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}


.cms-delete-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 6px;
  display: flex;
  align-items: center;
}
.cms-delete-tooltip .cms-delete-tooltipsubheader {
  visibility: hidden;
  width: max-content;
  background-color: #000000;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: -5px;
  padding: 6px 10px 6px 10px;
  opacity: 0.8 !important;
  font-size: 11px;
  line-height: 21px;
  display: grid;
  transition: 0.3s;
  font-family: "Axiforma-Regular";
}
.cms-delete-tooltip:hover .cms-delete-tooltipsubheader {
  visibility: visible;
  text-align: left !important;
}
.cms-delete-tooltip .cms-delete-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: -8px;
  right: 8px;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  transform: rotate(90deg);
}
.comment-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.comment-heading{
  font-size: 12px;
  color: #1F1F1F;
  line-height: 16.86px;
  margin: 0px;
  font-family: 'Axiforma-Bold';
}
.comment-head-img{
  width: auto!important;
}
.preview-comment-entry{
  margin-bottom: 10px;
  border: 1px solid #EBEBEB;
  padding: 12px;
  border-radius: 10px;
}
.preview-comment-author{
  display: flex;
  align-items: center;
}
.preview-comment-author div{
  border-radius: 100%;
  width: 18px;
  height: 18px;
}
.preview-comment-author-name{
  font-size: 8px;
  color: #1F1F1F;
  line-height: 10.75px;
  margin: 3px 6px 0px 6px;
  font-family: 'Axiforma-Regular';
}
.preview-comment-author-status{
  font-size: 8px;
  color: #B0B0B0;
  line-height: 10.75px;
  margin: 3px 0px 0px 0px;
  font-family: 'Axiforma-Regular';
}
.preview-comments-data{
  font-size: 9px;
  color: #1A1A1A;
  line-height: 12.1px;
  margin: 8px 0px 17px 0px;
  text-align: left;
  font-family: 'Axiforma-Regular';
}
.preview-comments-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.preview-comments-reply{
  font-size: 8px;
  color: #6F6F6F;
  line-height: 10.75px;
  margin: 0px;
  font-family: 'Axiforma-Regular';
}
.preview-comments-timestamp{
  font-size: 6px;
  color: #B0B0B0;
  line-height: 8.06px;
  margin: 0px;
  font-family: 'Axiforma-Regular';
}
.comments-preview .comment-button{
  border: 1px solid #B0B0B0;
  color: #6F6F6F;
  height: 35px;
  line-height: 35px;
  border-radius: 6px;
  background-color: transparent;
}
.custom-gallery-default-img{
  background-color: #EBEBEB;
  height: 100%;
}
.filter-related-post-disable .search-dropdown .dropdown-toggle{
  border: 1px solid #E3E6F1 !important;
  background-color: #F8FAFE !important;
  cursor: not-allowed !important;
  color: #7782A1 !important;
  background-image: url(../../assets/images/dashboard/email-support-arrow.png) !important;
}
.filter-related-post-disable .search-dropdown .search-dropdown-before{
  color: #7782A1 !important;
}
.filter-related-post-disable .search-dropdown .dropdown-toggle:hover, .filter-related-post-disable .search-dropdown .dropdown-toggle:focus{
  border: 1px solid #E3E6F1 !important;
  background-color: #F8FAFE !important;
  cursor: not-allowed !important;
}
.post-section-margin-disable input{
  border: 1px solid #E3E6F1 !important;
    background-color: #F8FAFE;
    color: #7782A1 !important;
    cursor: not-allowed;
}



.post-section-margin-disable input[type=number]::-webkit-inner-spin-button {
  opacity: 0.5;
  background-color:  #F8FAFE;
  cursor: not-allowed;
}
.shape-of-post-disable .post-shapeselect, .shape-of-post-disable .post-shapenotselect {
  stroke: #e3e6f1 !important;
    fill: #e3e6f1 !important;
    cursor: not-allowed !important;
}

.postlist-webview-body{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 598px;
}

.postlist-webview-body img{
  width: unset !important;
}
.cms-editpage-btn{
  margin-right: 20px;
}

.imagescaling-div{
  width: 400px !important;
height: 400px !important;
border-radius: 100px;
border: 2px dotted #D7DCEA;
background-color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
position: relative;
flex-direction: column;
gap: 20px;
}
.linkupload-imagecenter{
  display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
}
.anamation-loader-text{
  height: unset !important;
}
.appicon-animation-loader{ 
  width: 200px !important;
    height: 112px !important;
}
.animation-loader{
  width: 200px;
    height: 112px;
}

.imagescaling-Loader {
  position: absolute;

  height: 36px;
  width: 36px;
  box-sizing: border-box;
  background:conic-gradient(from 88.94deg at 50% 50%, #FFFFFF 0deg, #BCC1CE 360deg);
  border-radius: 36px;
  animation: 1s rotate infinite linear;
}
.imagescaling-Loader::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 28px;
  width: 28px;
  background: #fff;
  border-radius: 28px;
}

.footer-err-scaling-text{
  text-align: right;
  font-size: 12px;
  color: #7782A1;
  line-height: 16px;
  margin: 0px;
  font-family: 'Axiforma-Regular';
 
}

.web-footer-scaling-err{
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px
}
/* .upscale-image-div{
  height: 480px;
  width: 480px;
} */
.animation-scale-text{
  font-family: 'Axiforma-Regular';
  font-size: 14px !important;
  line-height: 18.82px;
  text-align: center;
  color: #7782A1 !important;
  margin: 20px 0px !important;
}

.mob-footer-scaling-err{
  display: none;
}
.crop-upload-label{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  cursor: pointer;
}

.crop-upload-input{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: none;
}

@media screen and (min-width: 2000px) {
  .category-list-container .category-checkmark:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .post-filter-dropdown label {
    font-size: 14px;
  }
}




@media (min-width: 1601px) and (max-width: 1900px) {
  .gallery-images .gallery-single {
    height: 140px;
    margin-right: 10px;
    width: 23%;
  }
}


@media (min-width: 1201px) and (max-width: 1600px) {
  .pagedetail-name {
    width: 100%;
  }
  .pagedetail-name h2 {
    font-size: 14px;
  }
  .gallery-images .gallery-single {
    height: 100px;
    margin-right: 10px;
    width: 22.7%;
  }
  .gallery-tabs{
    padding-bottom: 0px!important;
  }
  .pagestatussection {
    width: 250px;
    min-width: 100px;
  }
  .pageaction {
    width: 60px;
  }
  .pageaction button:nth-child(2) {
    margin-left: 10px;
    min-width: 50px;
  }
  .pagecreator-detail {
    width: 350px;
    min-width: 350px;
  }
  .postdetail-name {
    width: calc(100% - 130px);
  }
  .postdetail-name h2 {
    font-size: 12px;
    line-height: 18px;
  }
  .postdetail-name h3 {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .category-list-title h2 {
    width: calc(100% - 190px);
  }
  .category-img {
    width: calc(100% - 190px)!important;
  }
  .categoryaction {
    margin-left: 0px;
  }
  .tagsaction {
    margin-left: 0px;
  }
  .tags-names {
    width: calc(100% - 203px);
}

}

@media (min-width: 1201px) and (max-width: 1400px) {
  .page-editor , .pageslistdetail{
    height: auto!important;
    overflow: inherit!important;
  }
  .pagecreator-detail {
    width: 300px;
    min-width: 300px;
  }
  .post-sublinks a {
    margin: 0px 30px -2px 0px;
  }
  .createpage .cms-pages-search input {
    width: 200px;
  }
 

}

@media (min-width: 1000px) and (max-width: 1200px) {
  .mob-footer-scaling-err{
    display: block;
  }
  
  #root .cms-module .transparent_background{
    margin-top: 60px !important;
  }
  .gallery-images .gallery-single {
    height: 100px;
    margin-right: 10px;
    width: 22.7%;
  }
  .gallery-tabs{
    padding-bottom: 0px!important;
  }
  .select-parent.cms-settings {
    padding-bottom: 30px;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .jodit-workplace p {
    text-align: left !important;
  }
  .gallery-tabs{
    padding-bottom: 0px!important;
  }
  .gallery-images .gallery-single {
    width: 22.65%;
    height: 120px;
    margin-right: 10.5px;
  }
  .createpage.allpages .createnew {
    width: 95% !important;
  }
  
}
@media (min-width: 600px) and (max-width: 767px) {
  #root .cms-module .transparent_background{
    margin-top: -10px !important;
  }
  .gallery-images .gallery-single {
    width: 32%!important;
  }
}
@media screen and (min-width: 1201px) {

  .uploadvideopopup .dragupload-image{
    border: none;
  }
  .page-editor {
    /* padding-left: 30px; */
    height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;

  }
  .addblockpopup {
    width: 1280px !important;
    max-width: 100% !important;
  }
  .imageuploadpopup {
    width: 1280px !important;
    max-width: 100% !important;
  }
  .selectcategorypopup {
    width: 1280px !important;
    max-width: 100% !important;
  }
  .gallery-mobile-search {
    display: none;
  }
  .post-list-setting-btn-mobile{
    display: none;
  }
  .select-parent.cms-settings {
    padding-bottom: 30px;
  }
  .imageuploadpopup .modal-content {
    margin-top: 72px;
  }



}



@media screen and (max-width: 1500px) {
  .post-section-margin-top {
    display: grid;
}
.post-section-margin-second, .post-section-margin-first {
  width: 100%;
  margin-bottom: 10px;
}
.post-section-margin-first div:last-child{
  margin-right: 0px !important;
} 
}








@media screen and (max-width: 1200px) {
  .uploadtype-text, .uploadtype-text-select{
    display: none;
  }
  .crop-upload{
    display: none;
  }
  .libraryImage-select {
    padding: 13px 0px;
  }
  .libraryImage-select svg{
    width: 28px;
    height: 28px;
  }
  .footer-err-scaling-text {
    text-align: center !important;
  }
  .web-footer-scaling-err {
    margin-right: 0px;
    margin: 20px 0px;
}
    
  .post-img-ratio-div{
    gap: 25px
  }
  .cms-editpage-btn{
    margin-right: 0px;
  }
  .last-icon-tooltip{
    left: -134px !important;
  }
  .last-icon-tooltip::after{
    right: 50px;
    left: unset !important;
  }
  .bulk-delete-cross-head svg{
    right: 15px;
    top: 15px;
  }
  .postCreate-mob-btn{
    margin: 10px 15px 0px !important;
  }
  /* .postlist-setting-clicked{
    position: absolute;
    top: 12px;
    right: 75px;
  } */
  .post-box-size p span {
    display: none;
  }
  .delete-popup h5 {
    margin-bottom: 18px;
  }
  .delete-popup h6 {
    margin-bottom: 30px;
  }
  .category-mob-padding{
    padding: 15px 17px 15px 15px !important;
  }
  .category-pagehead {
    height: unset !important;
}
  .menu-upload-logo img{
    max-width: 100%;
    max-height: 439px;
  }
  /* .menu-upload-logo{
    padding: 0px 15px;
  } */
.accordionItem .cms-section-visiblity {
    opacity: 1;
    transition: .5s;
}
.show-post-filter{	
  right: 0px;	
  width: 100%;	
}
.custom-app-player {
  width: 90%!important;
  height: 215px!important;
  max-width: 450px;
}
.progress-bar-parent {
  height: 300px!important;
  max-width: 100%;
  top: 0px;
}
.uploadvideopopup .tab-content.gallery-tabs {
  padding-bottom: 0px !important;
  height: 240px;
}
.no-image-icon{
  height: unset;
    margin-top: 40%;
}
  .color_filter .darkblue-chose {
    margin-right: 20px !important;
}
.image-mobile-filter-btn svg{
  margin-bottom: 4px !important;
}
.color_filter b {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.color_filter label {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 0 20px 20px 0 !important;
  border: 1px solid #fff;
  cursor: pointer;
}
  .popup-listimages{
    height: calc(100vh - 204px);
    background-color: unset;
    border-left: unset;
   
  }
  .alllayout{
  position: absolute;
  right: 0px;
   left: 0px;
   bottom: 0px;
}
  .delete-popup .cms-delete-popup-footer{	
    display: block!important;	
    max-width: 280px;
    margin: auto;
  }	
  .delete-popup .red-btn p {
    height: 50px;
    margin-bottom: 30px;
        margin-top: 0px;
  }
  .delete-popup .red-btn p , .delete-popup .grey-btn p{	
    width: 100%;	
  }	
  .delete-popup .red-btn p , .delete-popup .red-btn p:hover {	
    background-color: #ff4848!important;	
    color: #fff!important;	
  }	
  .delete-popup .grey-btn p{	
    border: none;	
    margin-right: 0px;
  }
  .any-url .any-url-option{
    width: 100% !important;
  }
  .uploadimagesection {
    flex-direction: column;
    /* height: calc(100vh - 93px); */
}
  .mobile-search-filter{
    display: flex;
    padding: 4px 20px 0px 20px;
    margin-bottom: 20px;
  }
  .image-height-fit-grid {
    padding: 0px 20px 20px;
}
  .vscreate-open-btn{
    display: none;
  }
  .gallery-header h2{
   display: none;
  }
  .goback-mediaupload{
    cursor: pointer;
  }
  .gallery-upload-options .nav .nav-item .customuploadtabs {
    width: 20px;
    height: 20px;
}
  .goback-mediaupload, .vscreate-open-mobbtn, .image-mobile-filter-btn, .mob-upload-done-error{
    display: block;
  }
  /* .vscreate-open-mobbtn{
    margin-left: 30px;
  } */
  .mob-view-selectmediahead{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 14px;
    align-items: center;
  }
  .mob-view-selectmediahead svg{
    margin-left: 20px;
    margin-bottom: 0px !important;
  }
  .mob-view-selectmediahead h2{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    line-height: 25px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
  .tags-info-mob {
    width: 100%;
  }
  .tags-info-mob h2{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-top: 0px;
  }
  .tags-info-mob h5{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
    word-break: break-word;
  }
  .add-category-page{
    display: none;
  }
  .category-mobile-view{
    display: block;
    margin: 0px 15px;
  }
  .category-thumb-mob{
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .category-info-mob{
    width: calc(100% - 60px);
  }
  .category-info-mob h2{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-top: 12px;
  }
  .category-info-mob h5{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
    word-break: break-word;
    width: 90%;
  }
  .category-entry-two h3{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
  }
  .category-entry-two h6{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .category-entry-two div:nth-child(2){
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .category-entry-mob{
    background-color: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
    margin-bottom: 15px;
  }
  .category-entry-one {
    padding: 22px 17px 22px 15px;
    border-bottom: 1px solid rgb(227 230 241 / 50%);
    display: flex;
    align-items: center;
  }
  .category-entry-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 15px 22px 15px;
  }
  .cms-post-listing-mobile{
    display: block;
  }
  .cms-post-listing-mobile{
    background-color: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
    margin: 0px 15px;
    margin-bottom: 15px;
  }
  .cms-post-mob-one{
    display: flex;
    padding: 17px 15px 11px 15px;
    border-bottom: 1px solid rgb(227 230 241 / 50%);
  }
  .cms-post-mob-one h3{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    line-height: 20px;
    word-break: break-word;
        margin-right: 10px;
  }
  .cms-post-mob-one .postcreator-detail{
    margin-left: 0px;
  }
  .cms-post-mob-one .postcreator-detail h2 {
    color: #7782A1;
  }
  .cms-post-mob-two{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 16px 15px;
  }
  .post-thumbnail-mobile{
    width: 80px;
    min-width: 80px;
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
  }
  .post-filter-icon {
    margin-left: 10px;
    margin-right: 0px;
    padding: 12px 12px;
    background-color: #FFFFFF;
    border: 1px solid #E3E6F1;
    border-radius: 3px;
  }
  .cms-post-status-detail{
    display: flex;
    align-items: center;
  }
  .cms-post-status-detail h4 {
    color: #7782A1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
    margin-left: 10px;
  }
  .post-actions-mob {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 120px;
    min-width: 120px;
    z-index: 9;
  }
  .post-actions-mob a {
    margin: 0px 20px;
  }
  .post-actions-mob button {
    border: none;
    background-color: transparent;
  }
  .tags-edit-mob div:nth-child(2){
   width: 20px; 
   justify-content: end;
  }
  .cms-post-status-detail .pagestatus{
    padding: 0px;
    margin: 0px!important;
  }
  .delete-popup .finalselection{
    display: block!important;
  }
  .delete-popup .finalselection div p , .delete-popup .finalselection div:last-child p{
    width: 100%;
  }
  .page-list-mob-entry h3{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin-bottom: 12px;
    line-height: 20px;
    word-break: break-word;
  }
  .pagelist-mobile-two{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 15px 15px 15px;
  }
  .pagelist-mobile-two .pagestatussection{
    margin: 0px!important;
  }
  .pagelist-mobile-two a{
    margin-left: 25px;
  }
  .pagelist-mobile-two .pagestatus{
    padding: 0px;
    margin: 0px!important;
  }
  .pagelist-mobile-two h4{
    margin-left: 10px;
  }
  .page-list-mob-entry h2{
    color: #7782A1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
  }
  .pages-delete-mob {
    padding: 0px;
    margin-left: 14px;
  }
  .pagelist-mobile-one{
    padding: 24px 15px 28px 15px;
    border-bottom: 1px solid rgb(227 230 241 / 50%);
  }
  .cms-page-list-mobile{
    display: block;
    margin: 0px 15px;
  }
  .page-list-mob-entry{
    background-color: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
    margin-bottom: 15px;
  }
  .all-added-pages{
    display: none;
  }
  .pageshead p {
    color: #3064F9;
  }
  
  .cms-tag-popup .security-inputbox-div {
    display: inherit!important;
  }
  .cms-tag-popup .security-inputbox-div .react-tags {
    width: 100%;
    text-align: left;
    display: inherit!important;
    justify-content: flex-start;
  }
  .cms-tag-popup .security-crossmodal {
    z-index: 99;
    position: relative;
  }
  .cms-tag-popup #verifyqrcodesection{
    margin-top: 150px;
  }
  .cms-tag-footer-btn1{
    display: none;
  }
  .cms-tag-footer-btn2{
    width: 100%!important;
    height: 50px;
  }
  .cms-tag-footer {
    border: none!important;
    margin-top: 15px;
  }
  .cms-page-mobile{
    margin-top: 18px;
  }
  .cms-settings{
    background-color: #fff;
    padding: 1px 15px 20px 15px;
    margin: 0px 15px;
    border: 1px solid #E3E6F1 !important;
    border-radius: 6px;
  }
  .pages-fields .draggable-container {
    left: 0px !important;
  }
  .cms-pages-tooltip .cms-pages-tooltipsubheader  , .cms-posts-tooltip .cms-posts-tooltipsubheader{
    width: 242px;
    top: 33px;
    left: -20px;
    padding: 14px 17px 14px 17px;
    text-align: left!important;
  }
  .cms-pages-tooltip .cms-pages-tooltipsubheader::after , .cms-posts-tooltip .cms-posts-tooltipsubheader::after{
    top: -8px;
    left: 24px;
    transform: rotate(90deg);
  }
  .page-editor .accordionItemHeading {
    padding: 33px 0px 30px 23px;
  }
  .page-editor .accordionItemHeading a img {
    top: 27px;
  }
  .gallery-upload-options .nav .nav-item .nav-link.active {
    border: none!important;
    background-color: #fff !important;
    border-radius: 0px!important;
  }
  .gallery-upload-options .nav .nav-item .nav-link{
    border: none!important;
    padding: 0px 0px 0px 35px;
  }
  .gallery-upload-options .nav .nav-item:nth-child(2) .nav-link{
    border-radius: 0px!important;
    margin-left: 30px;
  }
  .any-url-option div {
    background-position: 95% center;
  }
  .pageedit .dropdown-toggle{
    padding: 0px!important;
  }
  .mobile-status{
    margin-left: 10px;
    margin-top: 5px;
  }
  .mobile-star{
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
    z-index: 9;
  }
  .mobile-star img{
    width: 14px;
  }
  .mobile-star p{
    margin-left: 5px!important;
  }
  .custom-drop .dropdown-menu {
    left: 0px !important;
    min-width: 100px;
    border: none;
  }
  .custom-drop .dropdown-menu::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    z-index: 99;
    top: -10px;
    right: 5px;
    margin: auto;
    transform: rotate(0deg);
  }
  .custom-drop .dropdown-menu button {
    color: #1b2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    padding: 5px 0px 4px 10px !important;
  }
  .imagefilter {
    position: fixed;
    background-color: #fff;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.2));
    top: 0px;
    width: 100%;
    z-index: 9;
    height: 100vh;
  }
  /* .imagefilter:before {
    content: "";
    position: absolute;
    background: #fff;
    z-index: -2;
    top: -7px;
    right: 14px;
    height: 26px;
    width: 26px;
    box-shadow: -6px 6px 6px 0 rgb(15 25 59 / 5%);
    transform: rotate(46deg);
    -ms-transform: rotate(46deg);
    -webkit-transform: rotate(135deg);
} */
.drags-drops {

  cursor: default;
}
.imagefilter {
  padding: 0px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.imageuploadpopup .gallery-header {
  border-bottom: unset;
  padding: 15px 20px !important;
  box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36 );
  height: 60px;
}
.mob-filter-head{
  height: 60px;
    display: flex;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36 );
    padding: 0px 20px;
    margin-bottom: 26px;
}
.mob-filter-head img{
  cursor: pointer;
}
  .imagefilter .color_filter {
    display: flex;
  }
  .imagefilter .color_filter .color-plates:last-child {
    display: block;
  }
  
  .imagefilter .gallery_color_input {
    padding: 0px 15.5px 0px 7px !important;
    width: 100%;
  }
  
  .imagefilter .gallery_color_input .color_input {
    padding: 0px !important;
  }
  .imagefilter p {
    margin-bottom: 19px;
  }
  .imagefilter .gallery_color_input .color_input .colorbox {
    width: 36px !important;
    height: 36px !important;
    margin: 0px !important;

}
  .imagefilter .gallery_color_input .color_input p {
    margin-left: 10px !important;
    font-size: 15px !important;
    color: #1b2952 !important;
  }
  /* .imagefilter-result {
    width: 100%;
    height: 82vh;
  } */
  .gallery_color_input {
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    padding: 5px 15px 5px 5px;
    margin-top: 0px;
    height: 50px;
}
  .imageuploadpopup .modal-body {
    height: calc(100% - 165px);
    overflow: scroll;
  }
  .imagefilter-result {
    /* width: 100%;
    height: 100%; */
    padding: 0px 20px 0px 20px;
    background-color: unset;
    border-left: unset;
  }
  .image-library {
    width: 45.9%;
   
  }
  .mob-btn-imagesave{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
  }
  .mob-btn-imagesave h4{
    margin: 19px 0px;
    color: #7782A1;
    font-size: 12px;
    line-height: 12px;
    font-family: 'Axiforma-Regular';
  }
  .mob-btn-imagesave button{
    width: 100%;
    height: 50px;
    border: none;
    color: white;
    background-color: #3064F9;
    border-radius: 3px;
    font-family: 'Axiforma-Regular';
    transition: 0.5s;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-uploadpopup-error{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
  }
  .mob-uploadpopup-error p{
    font-size: 12px;
    line-height: 12px;
    font-family: 'Axiforma-Regular' !important;
    color: #FF4848 !important;
    margin-bottom: 0px;
    margin-left: 5.5px;
  }
  .pasteurlerror{
    border: none !important;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    height: 12px !important;
  }
  .pasteurlerror p{
    display: block !important;
    margin-top: 3px;
  }
  .mob-btn-imagesave button:hover{
    background-color: #134bec;
  }
  .createpage {
    display: block;
    width: 100%;
  }
  .cms-module .pageshead .createpage {
    display: flex;
    width: 50px;
    justify-content: end;
  }
  .createpage a {
    background-color: #fff;
    width: 90%;
    height: 50px;
    border: 1px solid #c8cedb;
    color: #7782a1 !important;
    border-radius: 4px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: none !important;
  }
  .createpage div button , .createpage div button:focus {
    margin-right: 0px!important;
  }
  .createpage .cms-pages-search input , .createpage .cms-pages-search input:focus {
    width: 100%;
    height: 44px;
  }
  .nopagecreated {
    min-height: 75vh;
  }
  .nopagecreated div h4 {
    font-size: 18px;
  }
  .pageaction {
    display: none;
  }
  .pagecreator-detail {
    display: flex!important;
  }
  .pagestatussection {
    width: 100px;
  }
  .pagedetail-name {
    width: calc(100% - 120px)!important;
    height: auto;
  }
  .firstchild,
  .secondchild,
  .thirdchild {
    padding-left: 0px;
  }
  .pagedetail-name h2 {
    font-size: 14px;
    margin-bottom: -5px;
  }
  
  .postdetail-name p {
    line-height: 18px;
    margin-top: 4px;
    margin-left: 0px;
  }
  .pagestatus {
    font-size: 12px!important;
    margin-top: 3px!important;
    padding: 2px 10px 0px 10px;
  }
  .pageedit {
    width: 20px;
  }
  .create-page-preview {
    background-color: #fff;
    min-height: auto;
    padding: 0px;
    border: none;
    padding-top: 20px;
    display: none;
  }
  .page-editor {
    border-right: none;
    min-height: auto;
    padding-left: 0px;
  }
  .cms-module {
    min-height: auto;
    height: calc(100% - 57px);
    margin-top: -20px;
  }
  .cms-module .whitebg {
    filter: none;
  }
  .cms-module .transparent_background,
  .cms-module .cmspagelayout {
    /* height: 100%; */
    border: none!important;
  }
  .createpage div {
    width: 100%;
  }
  .cms-module .pageshead .createpage div {
    width: max-content;
  }
  .cmspreviewbtn {
    color: #3064f9;
    border: none !important;
  }
  .saveasdraft {
    text-transform: uppercase;
  }
  .gallery .select-img-ratio div svg {
    display: none !important;
  }
  .gallery-images .gallery-single {
    width: 47%;
    height: 180px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .tab-content.gallery-tabs {
    padding-bottom: 0px !important;
    height: 480px;

  }
  .gallery .select-img-ratio div p {
    margin-bottom: 0px !important;
    margin-left: 0px !important;
  }
  .select-img-ratio div p {
    margin-bottom: 0px !important;
  }

  /* category popup */

  .selectcategorypopup .category-library {
    width: 100%;
    height: auto !important;
  }
  .category-layouts {
    height: auto;
  }
  .category-library img {
    width: 100%;
  }

  /* Category Page */

  .create-category-tag {
    padding-right: 15px !important;
  }
  .category-tags-editor {
    min-height: auto;
    padding: 76px 0px 0px 0px;
  }
  .category-footer{
    border: none!important;
    margin-top: 15px!important;
  }
  .category-tag-head {
    padding: 0px;
  }
  .category-tag-search {
    width: 100%;
  }
  .category-tag-search input {
    width: 100%;
    border-bottom: none;
    height: 50px;
    font-size: 15px;
  }
  .category-tag-search button {
    margin-right: 0px;
    border-bottom: none;
    height: 50px;
    width: 55px;
  }
  .add-cat-tag {
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    padding-bottom: 0px;
  }
  .category-img {
    width: calc(100% - 40px);
  }
  .category-img h6 {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .categorydetail {
    padding: 15px;
    min-height: 100px;
    background-color: #fff;
    margin: 0px 15px 15px 15px;
    border: 1px solid #E3E6F1;
    width: auto;
    border-radius: 6px;
  }
  .add-cat-tag-btn1{
    display:none;
  }
  .add-cat-tag-btn2{
    width: 100%!important;
    height: 50px;
  }
  .add-category-page {
    overflow-x: hidden;
  }
  .tags-list .category-img p {
    margin-left: 0px;
  }
  .cat-tag {
    min-height: 50vh;
  }
  .cmsmobilepreview {
    display: block;
    position: fixed;
    bottom: 100px;
    left: 0px;
    right: 0px;
    text-align: center;
    cursor: pointer;
  }
  .galley-switch button {
    border: none;
    width: auto;
    height: auto;
  }
  .gallery-header h2 {
    font-size: 18px;
    min-width: auto;
  }
  .gallery-web-search {
    display: none;
  }
  .color-plates label:last-child {
    margin-right: 0px !important;
}
  .gallery-mobile-search {
    border: 1px solid #e3e6f1;
    border-radius: 3px;
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    /* padding: 0px 20px; */
    margin: 0px 10px 0px 0px;
    box-shadow: 0px 3px 6px rgba(188, 193, 206, 0.2 );
  }
  .image-mobile-filter-btn{
    width: 44px;
    height: 44px;
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(188, 193, 206, 0.2 );
  }
  .gallery-mobile-search button {
    border: none;
    background-color: #fff;
    height: 44px;
    display: flex;
    align-items: center;
    width: 19px;
    /* border-left: 1px solid #e3e6f1; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 56px; */
    padding: 0px;
    margin: 0px 8px 0px 14px;
  }
  .gallery-mobile-search button svg {
    margin-bottom: 0px;
  }
  .gallery-mobile-search input {
    border: none;
    width: 100%;
    outline: none !important;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    padding: 0px 14px 0px 0px;
    height: 38px;
  }
  .gallery-mobile-search form {
    width: 100%;
  }
  .gallery-mobile-search input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bcc1ce;
    opacity: 1; /* Firefox */
  }

  .gallery-mobile-search input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bcc1ce;
  }

  .gallery-mobile-search input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bcc1ce;
  }
  .allpages {
    position: inherit;
    top: 0;
    display: block;
    filter: none;
    padding: 0px;
  }
  .pageshead .allpages button {
    width: 55px !important;
    margin: 0px !important;
    height: 44px !important;
  }
  .createpage.allpages .createnew {
    width: 90%;
    height: 50px !important;
    background-color: #fff !important;
    border: 1px solid #c8cedb;
  }
  .category-img p {
    margin-left: 15px !important;
  }
  .tags-list .category-img p {
    margin-left: 0px !important;
  }
  .createpage select {
    display: none;
  }
  .pages-tabs .nav-link {
    margin-right: 5px;
  }
  .pages-options {
    padding-right: 20px;
    padding-left: 0px;
  }
  .postdetail-name h2 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 3px;
    margin-left: 15px;
    max-height: 36px;
    overflow: hidden;
    margin-bottom: 4px;
  }
  .postdetail {
    min-height: 131px !important;
  }
  .dragupload-image{
    background-color: #F8FAFE;
    cursor: pointer;
    height: 100%;
    width: 100%;   
    margin: 0px 20px; 
  }
  .crop-delete{
    display: none;
  }
  .postdetail-name .post-thumbnail {
    width: 90px;
    min-width: 90px;
    height: 90px;
  }
  .pagestatussection {
    width: auto!important;
    min-width: auto!important;
  }
  .post-list-featured {
    width: auto!important;
    max-width: 25px!important;
  }
  .postdetail-name {
    width: 100%;
  }
  .page-editor .accordionItemHeading.accordionItemHeading:before {
    opacity: 1;
    left: 0px;
  }
  .page-editor .accordionItemHeading.non-draggable.accordionItemHeading:before {
    opacity: 0 !important;
    display: none;
  }
  .gallery-upload-options {
    /* position: absolute; */
    /* background-color: #fff; */
    /* width: 0px; */
    /* top: 0px; */
    /* height: 0px; */
    box-shadow: unset;
    padding: 0px 20px;
    width: auto;
  }
  .gallery-upload-detail {
    width: 100%;
    top: 16px;
    /* height: 83vh; */
    display: block;
    background-color: unset;
    height: unset;
      padding: 0px 0px;
     
  }

  .gallery-upload-options {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
  }
  .delete-mobupload-image{
   cursor: pointer;
   display: block;
  }
  .any-url {
    position: unset;
    display: unset;
   
}
  .gallery-upload-options .nav .nav-item .nav-link {
    width: auto;
    margin-right: 0px;
   
  }
  .gallery-upload-options .nav {
    display: flex;
    padding: 0px 0px;
  }
  .gallery-upload-options .nav .nav-item {
    /* width: 50%; */
    margin-bottom: 0px;
  }
  .select-block-type .form-group {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 30%;
  }
  .select-block-type .image {
    width: 100%;
    height: 116px;
  }
  .addblockpopup .modal-footer button:nth-child(1) {
    width: 47%;
  }
  .addblockpopup .modal-footer button:nth-child(2) {
    width: 47%;
  }
  .addblockpopup .modal-footer {
    padding: 10px 20px;
  }
  .addblockpopup .modal-header {
    padding: 18px 0px 18px 20px;
    border: none!important;
  }
  .addblockpopup .modal-header h5 {
    padding-top: 50px;
  }
  .imageuploadpopup-footer-done button{
    width: 85px;
  }
  .imageuploadpopup-footer-cancel button {
    width: 85px;
  }
  .imageuploadpopup .modal-footer {
    padding: 10px 20px;
    display: none;
  }
  .gallery-upload-options .nav .nav-item div {
    top: 13px;
  }
  .drags-drops {
    width: 100%;
    height: 100%;
  }
  .upload-gallery-box .zoom-range {
    bottom: 100px;
  }
  .any-url-option div {
    width: 100%;
  }
  .mob-upload-done-error {
    padding: 0px 20px;
}
  .any-url-option {
    /* position: relative;
    top: 30px; */
    padding: 0px 20px;
  }
  .any-url-option p {
    color: #1b2952;
    font-size: 13px;
    display: none;
  }
  .pageedit .dropdown-item a{
    padding: 0px 0px 0px 0px !important;
  }
  .saveasdraft div , .createnew div{
    justify-content: center;
  }
  .post-list-tabs .nav-item a{
    padding: 10px;
  }
  .post-list-tabs{
    padding-left: 10px!important;
    width: 100%;
  }
  .pageslistdetail .pages-options{
    padding: 0px;
  }
  .cms-pages-left .accordionItem {
    padding: 0px 15px 0px 15px;
  }
  .pages-fields input, .pages-fields input:focus-visible, .pages-fields input:focus {
    padding: 15px 16px 12px 16px;
  }
  .duplicate-delete a:nth-child(2), .duplicate-delete a:nth-child(2):hover, .duplicate-delete a:nth-child(2):focus {
    margin-left: 25px;
  }
  .page-editor .image-uploader {
    padding-right: 10px;
  }
  .select-img-ratio .form-group:nth-child(1) svg{
    width: 26px;
  }
  .select-img-ratio .form-group:nth-child(2) svg{
    width: 20px;
  }
  .select-img-ratio .form-group:nth-child(3) svg{
    width: 18px;
  }
  .select-img-ratio div {
    height: 48px;
  }
  .select-parent-page {
    height: 100%;
  }
  /* .jodit-toolbar-editor-collection .jodit-toolbar-button {
    margin: 2px 0px !important;
  } */
  .createnew .d-block .new-loader{
    margin: auto;
  }
  .saveasdraft .d-block .new-blue-loader{
    margin: auto;
  } 
  .whitebg{
    background-color: transparent!important;
    filter: none!important;
    box-shadow: none!important;
  }
  .pageslistdetail .pagedetail{
    background-color: #fff;
    padding: 10px 20px!important;
    margin: 0px 15px 15px 15px;
    min-height: 79px;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    border: 1px solid #E3E6F1;
    border-radius: 6px;
  }
  .pageshead{
    display: block;
    padding: 20px 15px 15px 15px;
  }
  .cms-module .pageshead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px 13px 15px;
  }
  .cms-module .pageshead .allpages{
    margin-top: 0px;
    width: 100%!important;
  }
  .pageslistdetail {
    min-height: auto;
  }
  .whitebg .newspinner{
    background-color: transparent!important;
  }
  .pageedit .dropdown-menu{
    transform: translate3d(-74px, 32px, 0px)!important;
  }
  .delete-popup .modal-body{
    width: auto!important;
    min-width: auto !important;
    height: auto !important;
    min-height: auto !important;
    max-width: inherit !important;
    max-height: inherit !important;
    padding: inherit!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cms-module .tab-content {
    padding-bottom: 0px!important;
  }
  .cms-pages-left{
    margin: 0px 15px;
    background-color: #fff;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
  }
  .add-more-blocks {
    padding: 27px 15px;
  }
  .page-editor .accordionItemHeading.non-draggable {
    padding: 33px 0px 30px 0px;
  }
  .select-gallery-ratio div {
    width: 58px;
    height: 54px;
    margin-right: 3px;
  }
  .cms-mobile-btn-navigation{
    display: block;
    margin: 20px 15px;
  }
  .cms-mobile-btn-navigation .save-draft-mobile{
    width: 100%;
    color: #3064F9;
    font-size: 16px;
    font-family: "Axiforma-Regular";
  }
  .save-draft-mobile-disable{
    width: 100%;
    color: #bcc1ce;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    background-color: transparent;
    height: 50px;
    cursor: not-allowed;
  }
  .cms-mobile-btn-navigation .save-draft-mobile{
    width: 100%;
    color: #3064F9;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border: 1px solid #3064F9;
    border-radius: 3px;
    background-color: transparent;
    height: 50px;
  }
  .publish-mobile-disable{
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border: 1px solid #bcc1ce;
    border-radius: 3px;
    background-color: #bcc1ce;
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cms-mobile-btn-navigation .publish-mobile{
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    border: 1px solid #3064F9;
    border-radius: 3px;
    background-color: #3064F9;
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jodit-toolbar-editor-collection .jodit-toolbar-button {
    margin: 2px 6px!important;
  }
  .select-headings-ratio div {
    width: 20px!important;
    height: 48px!important;
    margin-right: 20px!important;
  }
  .cms-button-block .row .col-md-6:last-child{
    margin-top: 20px!important;
  }
  .cms-button-block .email-support-dropdown__single-value{
    position: relative;
    top: 19px!important;
  }
  .the-seprator {
    display: block;
  }
  .the-seprator #rangeid {
    width: 100%;
  }
  .the-seprator .color_input {
    width: 100%;
    margin-top: 20px;
  }
  .addmoreblock, .addmoreblock:hover, .addmoreblock:focus {
    width: 100%;
    display: inherit;
  }
  .gallery-row-column {
    display: block;
  }
  .gallery-row-column .gallery-column {
    width: 100%;
  }
  .gallery-row-column .gallery-column:last-child {
    margin-top: 20px;
  }
  .the-gallery-ratio div {
    width: unset;
    height: 54px;
    margin-right: 3px;
  }
  .imgallery-ratio > input:checked + div {
    background-color: unset;
    position: unset;
  }
  .custom-page-preview{
    overflow-x: hidden;
  }
  .pages-setting h2 {
    margin-top: 0px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0px 15px 25px 15px;
  }
  .pages-setting{
    background-color: #fff;
    margin: 0px 15px;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 30px 15px 30px 15px;
  }
  .post-sublinks{
    display: none;
  }
  .post-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 0px 15px;
    width: 100%;
  }
  .category-heading{
    padding: 17px 15px 0px 15px !important
  }
  .no-cat-tag {
    padding-top: 80px;
}
.no-cat-tag p {
  margin-top: 15px;
}
.no-categorydata{
  padding-top: 110px;
}
.no-categorydata p {
  margin-top: 17px;

}
  .cms-pages-search{
    width: 100%!important;
  }
  .post-heading h1{
    margin-bottom: 0px;
    font-size: 20px!important;
    max-width: 250px;
    word-break: break-word;
    align-items: center;
  }
  .cms-phone-dropdown {
    position: relative;
    width: 100px;
  }
  .category-heading{
    display: flex;
  }
  .tags-names p{
    margin-bottom: 0px;
    margin-top: 20px;
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
  }
  .category-heading a{
    display: flex;
    align-items: center;
    margin-right: 10px;

  }
  .category-heading a p{
    margin-bottom: 0px;
    font-size: 15px;
  }
  .posts-preview-list{
    display: none;
  }
  .post-list-settings{
    border:none!important;
    box-shadow: none!important;
  }
  .post-list-settings .page-editor{
    padding-left: 15px!important;
  }
  .post-list-settings .pageslistdetail {
    height: auto!important;
    overflow: visible;
  }
  .post-list-setting-btn-mobile{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .post-list-setting-btn-mobile button{
    width: calc(100% - 30px);
    height: 50px;
    background-color: #3064F9;
    color: #fff;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    border: none;
    border-radius: 3px;
    margin: auto;
  }
  .post-list-stng-btns{
    position: absolute!important;
    top: 20px!important;
    z-index: 9!important;
  }
  .pageshead .allpages .post-list-setting{
    position: absolute!important;
    top: 11px!important;
    width: 40px!important;
    height: 40px !important;
  }
  .post-list-settings .cms-posts-listing .cms-settings{
    /* margin-top: -12px !important; */
    width: 100%;
  }
  .select-block-type .form-group span {
    display: none;
  }
  .uploadvideopopup .mob-btn-imagesave button{
    margin-top: 20px;
  }
  .video-btn-disabled{
    background-color: #BCC1CE!important;
    color: #fff!important;
    cursor: pointer;
  }
  .no-attributes {
    background-color: transparent;
    border-bottom: none!important;
    box-shadow: none;
  }
  .attributes-listing-right{
    display: none;
  }
  .attributes-listing-left {
    width: 100%;
    min-width: 100%;
  }
  .attributes-width {
    display: none;
  }
  .add-attribute-body {
    padding: 0px 20px 20px;
  }
  .add-attribute-popup .modal-body {
    display: grid;
    align-items: center;
}
  .add-attribute-footer {
    padding: 0px 20px 15px 20px;
    border-top: none;
  }
  .add-attribute-footer-btn1{
    display: none;
  }
  .add-attribute-footer-btn2{
    width: 100%;
    min-width: 100%;
  }
  .attribute-delete-popup .modal-body {
    padding: 0px;
    width: 320px;
    min-width: 320px;
    height: auto;
    min-height: auto;
    max-width: 320px;
    max-height: auto;
    margin: auto;
  }
  .attribute-delete-popup-footer {
    display: block;
  }
  .attribute-delete-popup .red-btn p {
    width: 80%;
    margin-left: 0px;
    border: 1px solid #ffa7a7;
    color: #fff;
    background-color: #ff4848;
  }
  .attribute-delete-popup .grey-btn p {
    width: 100%;
    margin-right: 0px;
  }
  .attribute-delete-popup .grey-btn p{
    border: none;
  }
  .add-attribute-popup .modal-header{
    z-index: 2;
  }
  .attributes-mobile-entry-first{
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 0px 17px 20px 15px;
  }
  .attributes-mobile-entry-second{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E3E6F1;
    padding: 20px 17px 20px 15px;
  }
  .attributes-mobile{
    margin: 0px 15px;
    display: block;
  }
  .attributes-mobile-entry{
    background-color: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    padding: 20px 0px 0px 0px;
    margin-bottom: 15px;
  }
  .attributes-mobile-entry h3{
    font-family: "Axiforma-Regular";
    color: #BCC1CE;
    font-size: 12px;
    margin-bottom: 6px;
    margin-left: 15px;
  }
  .attributes-mobile-entry-first h4{
    font-family: "Axiforma-Bold";
    color: #1B2952;
    font-size: 14px;
    margin: 6px 0px;
    word-break: break-word;
  }
  .attributes-mobile-entry-first h5{
    font-family: "Axiforma-Regular";
    color: #3064F9;
    font-size: 14px;
  }
  .attributes-mobile-entry-second h4{
    font-family: "Axiforma-Regular";
    color: #BCC1CE;
    font-size: 12px;
    margin: 0px 0px 6px 0px;
  }
  .attributes-mobile-entry-second h5{
    font-family: "Axiforma-Regular";
    color: #1B2952;
    font-size: 14px;
    margin: 0px;
  }
  .attribute-action {
    width: 60px;
    min-width: 60px;
  }
  .attribute-page-head h1{
    display: none;
  }
  .post-actions-mob .cms-list-visiblity {
    opacity: 1;
    transition: .5s;
    stroke: #bcc1ce;
  }
  .cms-entry-mob .cms-list-visiblity, .cms-entry-mob:hover .cms-list-visiblity{
    stroke: #1B2952;
  }
  .cms-list-visiblity {
    margin-right: 12px;
    opacity: 1;
  }
  .add-attribute-popup .newspinner{
    height: 700px;
    position: relative;
  }
  .cmspostsetting-tooltip .cmspostsetting-tooltipsubheader {
    width: 139px;
    padding: 10px 10px 10px 10px;
    top: 32px;
    left: -10px;
  }
  .cmspostsetting-tooltip .cmspostsetting-tooltipsubheader::after {
    top: -8px;
    transform: rotate(90deg);
    right: 116px;
  }
  .post-list-settings-head p{
    white-space: normal!important;
  }
  .no-attributes {
    border: none!important;
    height: calc(100vh - 216px);
  }
  .disabled-attribute-btn, .disabled-attribute-btn:hover, .add-attribute-footer-btn2{
    height: 50px;
    font-size: 16px;
  }
  .createpage .cms-pages-search img{
    top: 11px;
  }
  .whitebg-layout{
    margin: 0px 15px 15px;
  }
  .page-layout-head {
    padding: 15px;
    border-bottom: 1px solid #E3E6F1;
  }
  .page-layout-head h2{
    font-size: 16px;
  }
  .page-layout-head p{
    padding-bottom: 0px;
    border-bottom: unset;
  }
  .pagesetting-layout-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
}
.setting-btn-web{
  display: none;
}
.pagesetting-layout-body .pagesetting-layout:first-child {
  margin-right: 0px;
  margin-bottom: 40px;
}
.cmspageSetting-rightdiv{
  display: none;
 }
 .post-filters-buttons-div .filter-buttondisabled{
  height: 40px!important;
 }
}




@media screen and (max-width: 390px) {
  .post-list-tabs{
    width: 100%;
  }
  .post-list-tabs .nav-item a{
    font-size: 12px;
    padding: 10px 5px;
  }
  .select-gallery-ratio , .the-gallery-ratio{
    position: relative!important;
    margin-bottom: -15px!important;
  }
  .jodit-toolbar-editor-collection .jodit-toolbar-button {
    margin: 2px 0px!important;
  }
  .jodit-toolbar-button button {
    min-width: 24px!important;
  }
  .gallery-images .gallery-single {
    width: 47%;
    height: 140px;
  }
  .addblockpopup .select-block-type .form-group {
    width: 44%!important;
  }
  .category-checkmark {
    top: 8px;
  }
  .mobilesearchsuggestion .category-list-container {
    margin-bottom: 6px;
  }
}
@media (max-width: 520px) {
  /* .gallery_color_input .color_input .fixback #rbgcp-wrapper div:nth-child(2) {
    margin-left: -18px !important;
  }
  .gallery_color_input .color_input .fixback #rbgcp-wrapper div:nth-child(3) {
    margin-left: -18px !important;
  } */
   .react-datepicker__day-name,  .react-datepicker__day,  .react-datepicker__time-name {
      font-size: 13px !important;
      width: 24px !important;
      height: 24px !important;
      line-height: 24px !important;
      text-align: center !important;
      margin: 0.166rem !important;
  }
   .react-datepicker__day:hover {
      height: 24px !important;
  }
   .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 80px !important;
  }
   .react-datepicker-popper .react-datepicker__time-container {
      width: 80px !important;
  }
   .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      height: 30px !important;
  padding: 8px 0px 8px 0px !important;
  line-height: 17px;
  }
   .react-datepicker__time-list {
      height: 215px !important;
  }
   .react-datepicker__time-box .react-datepicker__time-list li { 
      min-width: 74px      
  }
  .cms-icon-tooltip .cms-icon-tooltipsubheader {
    width: 150px;
    padding: 10px 10px 10px 10px;
  }
}

@media (max-width: 470px) {
  .imagescaling-div{
    width: 100%;
    margin: 0px 2px;
  }
}