.bulkupdate-head-ptag .bulkhead-first-div{
    width: 30%;
}
.bulkupdate-head-ptag .post-bulkhead-first-div{
    width: 40% !important;
}

.bulkupdate-head-ptag .bulkhead-second-div {
    width: 23%;
}
.bulkhead-third-div{
    width: 20%;
}
.bulkhead-fourth-div{
    width: 20%;
}
.bulkhead-fifth-div{
    width: 8% !important;
}
.bulkUpdate-page-body-sixthdiv .pages-delete-mob{
    padding: 0px !important;
}
.bulk-update-Reset .full-page-tooltipsubheader  {
    top: 25px;
    left: -148px;
}
.bulk-update-Reset .full-page-tooltipsubheader::after{
    top: -11%;
    right: 8%;
    border-color: transparent transparent black transparent;
}
.bulkhead-sixth-div{
    width: 0%;
    min-width: 137px !important;
}
.bulkUpdate-page-head p{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    width: 100%;
}
.setting-btn-web{
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: #F8FAFE;
    border: none;
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.bulkUpdatewhitebg{
    background-color: #fff;
    border-radius: 6px !important;
    border: 1px solid #E3E6F1 !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
    height: calc(100% - 63px);
} 
.postbulkUpdatewhitebg{
    height: calc(100% - 95px);
}
.bulkUpdate-page{
    height: 100%;
    overflow: auto;
}
.bulk-update-clickable{
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 78px;
    cursor: pointer;
    padding: 10px 0px;
}
.bulkUpdate-page::-webkit-scrollbar{
    width: 2px !important;
}
.setting-btn-web:focus{
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
}
.setting-bt-off-hide-web:focus{
    box-shadow: -3px -3px 3px #ffff inset, 3px 3px 3px rgba(0, 0, 0, 0.16) inset !important;
}
.setting-bt-on-hide-web:focus{
    box-shadow: 3px 3px 3px rgba(119, 130, 161, 0.20), 0px 0px 3px 3px #ffff !important;
}
.Features-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.bulkUpdate-page-head{
    display: flex;
    align-items: center;
    height: 40.5px;
    padding: 0 20px;
    border-bottom: 1px solid #f1f2f8;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #bcc1ce;
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
}
.bulkUpdate-page-body .bulkUpdate-page-body-firstdiv {
    width: 30%;
    font-size: 16px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
    word-break: break-word;
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.bulkUpdate-page-body .bulkUpdate-post-body-firstdiv {
    width: 40% !important;
}
.bulk-update-type-div-disable .bulkUpdate-page-body-firstdiv {
    width: 30%;
    font-size: 16px;
    font-family: 'Axiforma-Bold';
    color: #BCC1CE;
    word-break: break-word;
}
.bulk-update-type-div-disable .bulkUpdate-post-body-firstdiv {
    width: 40% !important;
}
.bulkUpdate-page-body .bulkUpdate-page-body-seconddiv img{
margin-right: 6px;
}
.bulkUpdate-page-body .bulkUpdate-page-body-seconddiv {
    width: 23%;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    display: flex;
    align-items: center;
    word-break: break-word;
    padding-right: 10px;

}
.bulk-update-type-div-disable .bulkUpdate-page-body-seconddiv img{
   opacity: 0.5;
    }
    .bulk-update-type-div-disable .bulkUpdate-page-body-seconddiv {
        width: 23%;
        font-size: 14px;
        font-family: 'Axiforma-Regular';
        color: #BCC1CE;
        display: flex;
        align-items: center;
        word-break: break-word;
        padding-right: 10px;
    
    }
.bulkUpdate-page-body .bulkUpdate-page-body-thirddiv {
    width: 20%;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;

}
.bulk-update-type-div-disable .bulkUpdate-page-body-thirddiv {
    width: 20%;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;

}
.bulkUpdate-filter .full-page-tooltip .full-page-tooltipsubheader{
    width: 175px;
    padding: 10px 15px 10px 15px;
}
.bulkUpdate-page-body .bulkUpdate-page-body-fourthdiv{
    width: 20%;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;

}
.bulk-update-type-div-disable .bulkUpdate-page-body-fourthdiv{
    width: 20%;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;

}
.bulkUpdate-page-body .bulkUpdate-page-body-fifthdiv {
    width: 8%;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    margin-right: 0px;
}
.bulk-update-type-div-disable .bulkUpdate-page-body-fifthdiv{
opacity: 0.5;
}
.bulkUpdate-page-body .bulkUpdate-page-body-sixthdiv {
    width: 13%;
    min-width: 137px !important;
}
.bulk-radio-button{
    position: relative;
    width: 20px;
    margin-right: 15px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
}
.bulkUpdate-filter-head span:last-child{
  font-size: 12px;
  color: #7782A1;
  font-family: 'Axiforma-Regular';
}
.bulkUpdate-filter-head span{
    display: flex;
    align-items: center;
}
.bulkUpdate-filter-head span .full-page-tooltip{
 display: flex;
}
/* .bulkUpdate-page-body .bulkUpdate-page-body-firstdiv span{
    margin-right: 10px;
} */
.bulk-update-type-div:hover .bulkUpdate-page-body-sixthdiv .cms-list-visiblity{
    opacity: 1;
    transition: .5s;
    stroke: #bcc1ce;
}
.bulk-update-type-div-disable .cms-list-visiblity{
    stroke: #1B2952 !important;
    opacity: 1;
    z-index: 0;
}
.bulk-update-type-div:hover{
    background-color: #F8FAFE;
    transition: 0.5s;
}
.bulk-update-type-div, .bulk-update-type-div-disable{
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    border-bottom: 1px solid #f1f2f8;
    padding: 0px 20px;
    min-height: 79px;
}
.bulk-radio-button input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.bulk-update-select-checkmark {
    position: absolute;
    /* top: 14px;
    left: 20px; */
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #bcc1ce;
}
.bulk-radio-button:hover input ~ .bulk-update-select-checkmark {
    background-color: #ffff;
}
.bulk-radio-button input:checked ~ .bulk-update-select-checkmark {
    background-color: #3064F9;
    border: unset;
}

.bulk-radio-button input:checked ~ .bulk-update-select-checkmark:after {
    display: block;
}

.bulk-radio-button .bulk-update-select-checkmark:after {
    left: 6.1px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
      /* background: white; */
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
}
.bulk-update-select-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.bulk-update-type-body .newspinner{
    min-height: 305px;
}
/* .bulk-update-type-div-select{
    background-color: #EFF3F9;
} */
.bulk-radio-button:hover{
    background-color: #EFF3F9;
}
.bulk-update-type-div-select .bulk-update-type{
    color: #3064F9;
}
.bulk-radio-button:hover .bulk-update-type{
    color: #3064F9;
}
.bulkupdate-post-thumbnail{
    width: 48px;
    height: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
    margin-right: 15px;
    min-width: 48px;
}


.bulkUpdate-filter{
    position: fixed;
   right: -400px;
    height: 100%;
    transition: 0.5s;
    width: 400px;
    background-color: #fff;
    top: 60px;
    z-index: 999;
    border-left: 1px solid #E3E6F1;
    border-top: 1px solid #E3E6F1;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;
    padding-top: 20px;
    overflow-y: auto;
}
.bulkUpdate-filter::-webkit-scrollbar{
    width: 2px !important;
}
.show-bulkUpdate-filte{
    right: 0px;
}
.bulkUpdate-filter-head{
    font-size: 18px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 20px;
}
.bulkupdate-filter-selectdiv{
    margin-bottom: 20px;
}
.bulkUpdate-filter-body-parent h3, .bulkupdate-filter-selectdiv h3{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 10px;
}
.bulkupdate-filter-selectdiv .post-filter__control, .bulkupdate-filter-selectdiv .post-filter__control:focus {
    box-shadow: none !important;
    border-color: #BCC1CE !important;
}
.bulkUpdate-filter-body-select{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding: 9px 15px 0px;
    min-height: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    max-height: 101px;
    overflow-y: auto;
}
.bulkUpdate-filter-body-select::-webkit-scrollbar{
    width: 2px !important;
}
.bulkUpdate-filter-body-select svg{
    min-width: 20px;
    margin-top: 1px;
}



.bulkUpdate-filter-body-datepicker{
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    display: flex;

}

.bulkUpdate-filter-body-datepicker .react-datepicker-wrapper{
    width: 100%;
    height: 40px;
}
.bulkUpdate-filter-body-datepicker .date-input-post2{
    width: 100% !important;
    border: unset;
    height: 100%;
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    margin: 0px;
    outline: unset;
    background-image: url(../../assets/images/dashboard/cms/calendar.png)!important;
    background-repeat: no-repeat!important;
    background-position: 97%!important;
}

.bulkUpdate-filter-body-select span img{
margin-left: 5px;
}
/* .bulkUpdate-filter-body-datepicker img{
    position: absolute;
    right: 0px;
    right: 15px;
    top: 9px;
} */
.bulkUpdate-filter-body-datepicker .react-datepicker__input-container{
    height: 100%;
    
}

.bulkUpdate-filter-body-datepicker .react-datepicker__day-name, .bulkUpdate-filter-body-datepicker .react-datepicker__day, .bulkUpdate-filter-body-datepicker .react-datepicker__time-name {
    font-size: 11px !important;
    width: 25px !important;
    height: 25px !important;
    line-height: 26px !important;
}

.bulkUpdate-filter-body-datepicker .react-datepicker__day:hover {
    border-radius: 100% !important;
    height: 25px !important;
}

.bulkUpdate-filter-body-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 21px !important;
    padding: 5px 10px 5px 20px !important;
    line-height: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bulkUpdate-filter-body-datepicker .react-datepicker__time-list {
    height: 185px !important;
}




.bulkUpdate-filter-body-parent .search-dropdown-before div div {
    padding: 2.5px 10.5px 2px 13px;
    margin: 0px 6px 6px 0px;
    /* max-width: 130px; */
}
.bulkUpdate-filter-body-select p{
    font-size: 13px;
    color: #1B2952;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    max-width: 130px !important;
    word-break: break-word;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}
.bulkupdate-filter-apply-enable{
    font-size: 14px;
    background-color: #3064F9;
    border: unset;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Axiforma-Regular';
    margin-bottom: 22px;
    transition: 0.5s;
}
.bulkupdate-filter-apply-enable:hover{
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.bulkupdate-filter-apply-enable:disabled{
    font-size: 14px;
    background-color: #BCC1CE;
    border: unset;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Axiforma-Regular';
    margin-bottom: 20px;
    cursor: not-allowed;
}
.bulkupdate-filter-apply-enable:disabled:hover{
    box-shadow: unset !important;
}
.bulkupdate-filter-apply-enable:hover{
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.bulkupdate-filter-apply-enable:disabled{
    font-size: 14px;
    background-color: #BCC1CE;
    border: unset;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    color: #fff;
    font-family: 'Axiforma-Regular';
    margin-bottom: 22px;
    cursor: not-allowed;
}
.bulkupdate-filter-apply-enable:disabled:hover{
    box-shadow: unset !important;
}
.bulkupdate-filter-apply-enable:hover{
    background-color: #134bec;
    transition: 0.5s;
}
.bulkupdate-filter-apply-enable:disabled:hover{
    background-color: #BCC1CE;
    transition: 0.5s;
}
.bulkupdate-filter-delete-enable{
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #3064F9;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    color: #3064F9;
    font-family: 'Axiforma-Regular';
    margin-bottom: 20px;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bulkupdate-filter-delete-enable:hover {
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.bulkupdate-filter-delete-enable svg{
    margin-right: 6px;
}
.bulkupdate-filter-delete-enable:disabled{
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #C8CEDB;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 20px;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
}

.sort-icon-container .table-sort-icon{
    display: none;
}

.sort-icon-container:hover .table-sort-icon{
    display: inline;
}

.bulkupdate-filter-delete-disable svg{
    margin-right: 6px;
    }
    .post-filter__placeholder{
        font-size: 13px !important;
        color: #bcc1ce !important;
    }
    .bulkupdate-head-ptag .sort-icon-container{
        display: flex;
    }
    .post-filter__single-value p{
        line-height: 24px;
    }
    @media screen and (max-width: 1400px) {
        .bulk-update-type-div-disable .bulkUpdate-page-body-firstdiv, .bulkUpdate-page-body .bulkUpdate-page-body-firstdiv {
            width: 26%;
            font-size: 14px;
        }

        .bulk-update-type-div-disable .bulkUpdate-post-body-firstdiv, .bulkUpdate-page-body .bulkUpdate-post-body-firstdiv {
            width: 26% !important;
            font-size: 14px;
        }
        .bulk-update-type-div-disable .bulkUpdate-page-body-seconddiv, .bulkUpdate-page-body .bulkUpdate-page-body-seconddiv {
            width: 22%;
            font-size: 12px;
        }
        .bulkUpdate-page-body .bulkUpdate-page-body-thirddiv, .bulkUpdate-page-body .bulkUpdate-page-body-thirddiv {
            width: 22.5%;
            font-size: 12px;
        }
        .bulk-update-type-div-disable .bulkUpdate-page-body-fourthdiv, .bulkUpdate-page-body .bulkUpdate-page-body-fourthdiv {
            width: 22.5%;
            font-size: 12px;
        }


        .bulkupdate-head-ptag .bulkhead-first-div{
            width: 26%;
        
        }
        .bulkupdate-head-ptag .post-bulkhead-first-div{
            width: 26% !important;
        }
        .bulkupdate-head-ptag .bulkhead-second-div {
            width: 22%;
        
        }
        .bulkhead-third-div{
            width: 22.5%;
        
        }
        .bulkhead-fourth-div{
            width: 22.5%;
        
        }
        
    }
    @media screen and (max-width: 1200px) {
        .bulkUpdate-filter{
            display: none;
        }
        .bulkUpdatewhitebg{
            display: none;
        }
        .setting-btn-web{
            display: none;
        }
    }