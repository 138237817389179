.myapps_container {
  margin-top: 60px !important;
  /* max-width: 1920px !important; */
  width: 87% !important;
}
/* .select-myapps-div::-webkit-scrollbar {
  display: none;
}  */
.myapps_topdiv {
  margin-top: 40px !important;
}
.myapps_left_worspace {
  margin-left: 13px;
}
.myapps-checkinput {
  position: absolute;
  left: -999em;
}
.myapps-profile-dropdown input[type="checkbox"]:checked + .select-myapss {
  /* opacity: 0;
    transform: translateY(-122%);
    margin-bottom: -999em;
    transition: all 0.5s linear, margin 0s linear 0.5s; */
  height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 0.7s ease;
  border: none;
  /* display: none; */
}
.myapps-profile-dropdown
  input[type="checkbox"]:checked
  + .select-myapss
  .select-myapps-div {
  max-height: 0;
  padding-bottom: 0;
  padding: 0px;
  transition: all 0.6s ease;
  overflow: hidden;
  /* border: none; */
}
.myapps-dropdown-head {
  display: flex;
}
.myapp_heading svg {
  margin-left: 6px;
  cursor: pointer;
}
.myapp_headingone svg {
  margin-left: 6px;
  cursor: pointer;
}
.select-myapss {
  position: absolute;
  left: -72px;
  z-index: 1;
  border: 1px solid #e3e6f1;
  top: 30px;
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  /* opacity: 1;
    transition: all 0.5s linear, margin 0s linear 0s; */
  transition: all 0.8s ease;
  opacity: 1;
}
.select-myapps-div {
  overflow: auto;
  width: 300px;
  max-height: 434px;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
  top: 30px;
  padding: 10px;
  padding-bottom: 24px;
  transition: all 0.8s ease;
}
.select-myapss::before {
  display: block;
  content: url(./../../assets/images/dashboard/support-arrow.png);
  position: absolute;
  z-index: 99;
  top: -15px;
  right: 129px;
  margin: auto;
  transform: rotate(0deg);
}
.select-prosnalprofile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.select-prosnalprofile:hover {
  background-color: #f8f9fd;
  border-radius: 4px;
}

.select-workspaceprofile {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.select-workspaceprofile:hover {
  background-color: #f8f9fd;
  border-radius: 4px;
}
.selected-workspace-div {
  background-color: #f8f9fd;
  border-radius: 4px;
}
.select-prosnal {
  padding: 10px 0px 14px 0px;
  /* border-bottom: 1px solid #EDF0F5; */
  margin: 0px;
}
.select-prosnal h1 {
  font-size: 12px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  line-height: 16px;
  margin: 0px 10px 9px 10px;
}
.select-prosnal p {
  color: #bcc1ce;
  font-size: 10px;
  margin-bottom: 0px;
  text-align: left !important;
}
.myprofile-name-app {
  margin-left: 10px;
}
.select-prosnal h2 {
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
}

/* .select-workspace{
    padding: 10px 0px 24px 0px;
    margin: 0px 10px 0px 10px;
} */
.select-workspace h1 {
  font-size: 12px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
  /* margin: 22.5px 10px 9px 10px; */
  padding: 22.5px 10px 9px 10px;
  border-top: 1px solid #edf0f5;
  margin: 0px;
}
.select-workspace p {
  color: #bcc1ce;
  font-size: 10px;
  margin-bottom: 0px;
  text-align: left !important;
}

.select-workspace h2 {
  font-size: 13px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
}
.myapps-filter-divopen {
  right: 0px;
}
.myapps-filter-div {
  position: fixed;
  right: -400px;
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 99999;
  border-left: 1px solid #e3e6f1;
  border-top: 1px solid #e3e6f1;
}
.justifycontentcenter {
  justify-content: center !important;
}
.filtersearch-head {
  padding: 21px;
  line-height: 15px;
  box-shadow: 0px 0px 6px rgba(118, 193, 206, 0.36);
}
.filtersearch {
  display: none;
}
.filtersearch ul li {
  margin-top: 43px;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  list-style: none;
  padding: 0px;
  color: #1b2952;
}
.filtersearch ul {
  padding-left: 20px;
}
.filtermain {
  padding-left: 21px;
  padding-top: 22px;
  padding-right: 19px;
  padding-bottom: 40px;
}
.myapps-filter-head h1 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  line-height: 18px;
  color: #1b2952;
  margin-bottom: 20px;
}
.myapps-filter-head a {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #7782a1 !important;
  line-height: 12px;
}
.myapps-filter-head {
  display: flex;
  justify-content: space-between;
}
.mobile-createapp {
  display: none;
}
.myapps-filter-seconddiv .filter-input::placeholder {
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  line-height: 13px;
}
.myapps-filter-seconddiv .select-paymentgateway__control {
  width: 100%;
  height: 40px !important;
  border: 1px solid #bcc1ce !important;
  border-radius: 3px !important;
  margin-top: 10px;
  box-shadow: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  min-height: 40px;
}
.select-paymentgateway__menu,
.select-filter__menu {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.16) !important;
  margin-top: 0px !important;
}
.select-paymentgateway__menu-list,
.select-filter__menu-list {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.select-paymentgateway__option,
.select-filter__option {
  font-size: 13px !important;
  background-color: #ffffff !important;
  color: #1b2952 !important;
  padding: 13px 10px !important;
  margin: auto;
  width: calc(100% - 10px) !important;
  font-family: "Axiforma-Regular";
}
.select-paymentgateway__option:hover,
.select-filter__option:hover {
  font-size: 13px !important;
  background-color: #f8f9fd !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 10px !important;
}
.myapps-filter-seconddiv .select-paymentgateway__value-container {
  padding: 0px 5px!important;
  min-height: 40px;
  height: 40px;
}

.select-paymentgatewaydiv {
  width: 100%;
}
.myapps-filter-seconddiv .select-filter__control {
  width: 100%;
  height: 40px !important;
  border: 1px solid #bcc1ce !important;
  border-radius: 3px !important;
  box-shadow: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  min-height: 40px;

}
.clone-conf-modal-popup-confirm-disable{
  background-color: #bcc1ce !important;
  cursor: not-allowed;
}

/* .myapps-filter-seconddiv .select-filter__placeholder{
        margin-top: 4px;
} */
.myapps-filter-seconddiv .select-filter__value-container {
  padding: 0 5px!important;
    min-height: 40px;
    height: 40px;
    color: #1b2952 !important;
}
.myapps-filter-seconddiv .select-filter__control:hover,
.myapps-filter-seconddiv .select-filter__control:focus,
.myapps-filter-seconddiv .select-paymentgateway__control:hover,
.myapps-filter-seconddiv .select-paymentgateway__control:focus {
  background-color: #f8fafe;
  border: 1px solid #3064f9 !important;
  outline: none;
  height: 40px !important;
}

.myapps-filter-seconddiv .filter-input {
  width: 100%;
  height: 40px !important;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 13px !important;
  color: #1b2952;
  padding: 12px 15px;
  font-family: "Axiforma-Regular";
  line-height: 40px;
}
.myapps-filter-seconddiv .filter-input:focus,
.myapps-filter-seconddiv .filter-input:hover {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
}
.myapps-filter-seconddiv .select-filter__single-value {
  margin: 0px !important;
}
.myapps_mobileviewdiv {
  background-color: #f8fafe;
  display: flex;
  min-height: 100vh;
  position: relative;
  justify-content: center;
}
.myapssdelete-icon {
  height: 40px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  padding: 4.5px 11.5px;
  margin-left: 10px;
  width: 40px;
  margin-top: 10px;
}
.myapps-filterand {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #c8cedb;
  margin-top: 15px;
  margin-bottom: 12px;
}
.myappnotificationdiv {
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 20px !important;
}
.myapps-filteradd {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.myapps-filteradd p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  color: #3064f9;
  margin-left: 5px;
}
.filter-buttondisabled {
  width: 100%;
  height: 40px;
  border: none;
  color: white;
  background-color: #bcc1ce;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: not-allowed !important;
}
.myapps-filter-close{
 display: none;
}
.advance-search-button {
  width: 100%;
  margin-top: 20px;
}
.filter-buttonenable {
  width: 100%;
  height: 40px;
  border: none;
  color: white;
  background-color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
}
.getstartedcustom {
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
}
/* .filter-buttonenable:hover {
  background-color: #134bec;
} */
.myapps_left_worspace {
  margin-left: 15px;
  width: 100%;
}
.myapps_account_flex {
  flex: 1 1 !important;
  display: flex;
}
.myapps_topdiv {
  margin-bottom: 0px !important;
  margin-top: 41px !important;
  /* margin-left: 1.01%;
  margin-right: 4.01%; */
  display: flex;
  justify-content: space-between;
  /* margin-left: auto;
  margin-right: auto; */
  margin-left: 1.01%;
  margin-right: 1.01%;
  /* max-width: 95%; */
}
.myapp_heading {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 7px;
  text-align: left;
  position: relative;
  height: 21px;
}
.myapp_headingone {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 7px;
  text-align: left;
  position: relative;
  height: 21px;
}
.myapps_peronal-apps,
.namespac2 {
  font-size: 12px;
  color: #7782a1;
  margin-bottom: 0;
  line-height: 16px;
  text-align: left !important;
}
.myapps_hover .myapps_topsection .clone-svg{
  display: none;
  }

  
.myapps_hover:hover .myapps_topsection .clone-svg{
display: block;
transition: 0.5s;
display: flex;
}
.myapps_topsection div:first-child{
  width: calc(100% - 30px);
}
.myapps_topsection {
  padding: 15px 15px 10px 15px !important;
  display: flex;
  justify-content: space-between;
}
.myappnotificationdiv span {
  color: white;
  font-family: "Axiforma-Regular";
  text-decoration: underline;
  font-size: 13px !important;
  cursor: pointer;
}
.clone-svg:hover .cloneTooltip{
  visibility: visible;
}
.cloneTooltip{
  visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 10px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    top: 7px;
    right: 40px;
    padding: 9px 12px 9px 12px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 24%);
    transition: all .2s ease 0s;
    width: max-content;
    font-family: "Axiforma-Regular";
}
.cloneTooltip::after{
  content: "";
    position: absolute;
    bottom: 100%;
    left: calc(100% + 5px);
    top: 10px;
    margin-left: -5px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent rgb(0 0 0 / 80%) transparent;
    transform: rotate(90deg);
}
.advance_searchmargin {
  margin-right: 0px !important;
  /* padding-left: 16px !important; */
  width: 300px;
  border: 1px solid #e3e6f1;
  height: 40px;
  justify-content: space-between;
  border-radius: 3px;
  align-items: center;
  background-color: white;
  display: flex;
}
.advance_searchmargin .form-group {
  margin-bottom: 0px;
  width: 100%;
  height: 40px;
}
.advance_searchmargin .form-group input {
  font-size: 14px !important;
  width: 100%;
  border: none;
  height: 40px;
  background-color: transparent;
  outline: none !important;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  padding: 12px 0px 10px 0px !important;
}
.myappnotificationdiv {
  display: flex;
  border: 1px solid #7782a1 !important;
  height: 40px;
  background: #7782a1 !important;
  border-radius: 3px;
}
.myappnotificationdiv h6 {
  color: white;
  font-size: 14px !important;
  font-family: "Axiforma-Regular";
}
.myapps_appbox {
  max-height: 320px !important;
  position: relative;
  background-color: white;
  border-radius: 6px;
  height: 320px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border: 1px solid #e3e6f1;
  overflow: hidden;
  transition: 0.5s;
}
/* .myapps_appboxs:nth-child(5n + 1) {
  margin-left: 1.01%;
} */

.myapps_appbox:hover {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.13);
}
.myapps_hover:hover {
  background-color: #f8fafe;
  cursor: pointer;
}
.myapps_hover .onhoverdata {
  background: none !important;
}
.myapps_statusicon div {
  padding-bottom: 9px !important;
  width: 33%;
}
.myappnotificationdiv span:hover {
  text-decoration: underline !important;
}
.myappnotificationdiv h5 {
  display: flex;
  margin-bottom: 3px;
}
.myapps_topsection h2 {
  color: #1b2952;
  white-space: nowrap;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* width: calc(100% - 45px); */
  font-family: "Axiforma-Bold";
  font-size: 16px !important;
  line-height: 23px;
}

.myapps_topsection p.myapps_viewstatus-responsive {
  width: auto !important;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 11px;
  margin: 0px;
  font-family: "Axiforma-Regular";
  max-width: fit-content;
  border-radius: 10px;
  text-align: center !important;
  height: 19px !important;
  line-height: 17px !important;
}

.new-createapp-button {
  background-color: #3064f9;
  border: none;
  color: white;
  height: 40px;
  width: 140px;
  border-radius: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
  }
}
.new-createapp-button img {
  margin-right: 5px;
}
.new-createapp-button p {
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 14px;
  margin-top: 2px;
}
/* .new-createapp-button:hover {
  background-color: #134bec;
} */
.myapps_topsection h3 {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin: 0px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-basis: 77%;
  width: auto;
  line-height: 16px;
}
.myapps-basediv {
  padding: 0px 15px;
}
.myapps-basediv img {
  margin-left: 5px;
}
.app-created-date {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #bcc1ce;
  margin-top: 7px;
  line-height: 12px;
}
.myapps_topsection .created-date-personal,
.created-date-workspace {
  font-size: 11px;
  color: #7782a1;
  margin: 0px;
  font-family: "Axiforma-Regular";
  text-align: right;
  width: 40%;
}

.myapps_hover {
  position: relative;
  transition: all 0.5s ease;
  overflow: hidden;
  height: 100%;
  background-color: white;
}

.myapps_hover .new-appimg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin: auto;
  margin-top: 14px;
  /* margin-bottom: 60px; */
  transition: all 0.5s ease;
  overflow: hidden;
  margin-bottom: 35px;
}

.myapps_hover .status-icons-parent {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 11px;
}

.advancesearch_takethere {
  background: transparent;
  /* border: 1px solid #e3e6f1;
    width: 44px;
    height: 44px; */
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  color: white;
  cursor: pointer;
  border-radius: 4px;
}
.filterapplyed-reddot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #f8fafe;
  position: absolute;
  background-color: red;
  top: 9px;
  right: 0px;
}
.advancesearch_takethere p {
  position: relative;
}
/* .advancesearch_takethere:hover {
    box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
    color: #fff;
  } */
.myapps_createappbox {
  margin: 1%;
  width: 18%;
  border-radius: 8px;
  position: relative;
}
.myapps_appboxs {
  margin: 1.01%;
  width: 17.97%;
  border-radius: 8px;
  position: relative;
  /* max-width: 362px; */
  margin-top: 1.25%;
  /* max-width: 310px; */
  /* margin-top: 17px !important;
    margin-bottom: 0px; */
}
/* .advance_searchmargin::placeholder{
    padding-top: 4px;
    
  } */
.myapps_applinks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  font-family: "Axiforma-Regular";
}
.myapps_createappbox .myapps_inside_image {
  height: 50px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 4px !important;
  background-image: none !important;
  color: #7782a1;
  margin: 0px;
  background-color: #fff !important;
  height: 360px !important;
  border: 1px dashed #c8cedb !important;
  position: relative;
  overflow: hidden;
}
.myapps-grey-name {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  margin-top: 22px;
  margin-bottom: 0px;
}
.myapps-filter-seconddiv .select-filter__placeholder {
  top: 52%;
}
.myapps-flex-new .advance-filter-post-myapps p {
  line-height: 16px;
}
.myapps-flex-new .search_search_dropdown__1gwgA {
  padding-top: 22px;
  padding-left: 19px;
}
.noapps-filtered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 260px;
  margin-left: -10px;
}
.noapps-filtered p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #bcc1ce;
  line-height: 13px;
  margin-top: 21px;
}
.noapps-created {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}
.noapps-created button {
  height: 50px;
  width: 180px;
  border: none;
  color: white;
  font-size: 14px;
  background-color: #3064f9;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: "Axiforma-Regular";
}
.noapps-created button:hover {
  background-color: #134bec;
}
.noapps-created h1 {
  font-size: 30px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-top: 35px;
  margin-bottom: 15px;
  text-align: center;
  line-height: 43px;
}
.noapps-created p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 50px;
  text-align: center;
  line-height: 18px;
}
.myapps-search-btn-main {
  /* border-left: 1px solid #e3e6f1 !important; */
  padding-left: 3.8px;
  padding-right: 3.5px;
  width: 44px;
  background: none;
  border: none;
  height: 100%;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back_btns {
  background-color: #f6f9ff;
  text-decoration: none;
  border-radius: 4px;
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  padding: 10px 22px;
  font-weight: 500;
  border: 1px solid #bcc7e6;
  line-height: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  max-width: 100px;
  height: 40px;
  padding: 13px 27px 10px 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_buttons {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  font-weight: 500;
  border: 1px solid #3064f9;
  line-height: 15px;
  text-transform: uppercase;
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
  min-width: 100px;
}
.advance-search-red {
  display: flex;
  align-items: center;
}
.myapps_all-status p {
  font-size: 12px;
  line-height: 16px;
  margin-right: 5.5px;
  margin-bottom: 0;
}
.myapps-filter-seconddiv:hover .select-filter__control {
  border-color: #3064f9 !important;
}
.myapps_upgrade-text {
  font-size: 12px !important;
  color: #3064f9 !important;
  line-height: 16px !important;
  margin: 0 !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
}
.select-filter__menu-list .select-filter__option--is-disabled {
  background-color: #f8f9fd !important;
  border-radius: 4px !important;
}
.mob-clone-svg{
  display: none !important;
}
.clone-conf-modal-popup-head h2{
  font-size: 18px;
  color: #1B2952;
  font-family: 'Axiforma-Bold';
  margin-bottom: 20px;
}
.clone-conf-modal-popup-maindiv p{
  font-size: 14px;
  color: #7782A1;
  font-family: 'Axiforma-Regular';
  line-height: 20px;
  margin-bottom: 20px;
}
.clone-conf-modal .modal-body{
  padding: 0px 20px 0px 20px
}
.clone-conf-modal-input h2{
  color: #7782A1;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    margin-bottom: 10px;
}
.clone-conf-modal-input input{
  min-height: 50px;
    width: 100%;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    outline: none;
    font-size: 13px;
    color: #1B2952;
    padding: 15px;
    font-family: 'Axiforma-Regular';
}
.clone-conf-modal-input input:hover{
  background-color: #F8FAFE !important;
    border-color: #3064F9 !important;
    transition: .5s !important;
}
.clone-conf-modal-popup-bottom{
  display: flex;
    justify-content: space-between;
    margin: 0px -20px;
    border-top: 1px solid #E3E6F1;
    padding: 15px 20px;
}
.clone-conf-modal-popup-cancel{
  border: 1px solid #bcc1ce;
    border-radius: 3px;
    color: #7782a1;
    background-color: #fff;
    width: 100px;
    height: 40px;
    font-family: "Axiforma-Regular";
    font-size: 13px;
}
.clone-conf-modal-popup-confirm{
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  border-radius: 3px;
  color: #ffff;
  background-color: #fff;
  width: 100px;
  height: 40px;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  background-color: #3064f9;
  transition: 0.5s;
}
.clone-conf-tick{
  display: flex;
    padding: 30px 0px;
}
.clone-conf-tick p{
  margin-bottom: 0px;
    padding: 0px 0px 0px 32px;
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    line-height: 20px;
}
.clone-conf-modal .modal-header{
  padding: 12.5px;
}
.clone-conf-modal-popup-confirm:hover{
  background-color: #134bec;
  transition: 0.5s;
}
@media (min-width: 2200px) and (max-width: 3499px) {
  .myapps-appsdiv {
    min-height: calc(100vh - 157px);
  }
  .myapps_appboxs {
    width: 14.64%;
  }
}
@media (min-width: 3500px) and (max-width: 4799px) {
  .myapps_appboxs {
    width: 12.26%;
  }
}
@media (min-width: 4800px) {
  .myapps_appboxs {
    width: 7.98%;
  }
}
@media (max-width: 1600px) {
  .myapps_hover .new-appimg {
    width: 130px !important;
    height: 130px !important;
    border-radius: 35px !important;
  }
  .myapps_appbox {
    height: 280px;
  }
  .myapps_appboxs {
    width: 22.97%;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .myapps_createappbox {
    width: 31%;
  }
  .myapps_appboxs {
    width: 31%;
  }
  .myapps_createappbox .myapps_inside_image {
    height: 290px !important;
    border: 1px dashed #c8cedb !important;
  }
  .myapps_appbox {
    height: 280px !important;
  }
  .myapps_hover .new-appimg {
    width: 130px !important;
    height: 130px !important;
    border-radius: 35px !important;
  }
  .myapps_all-status p {
    font-size: 12px;
    line-height: 16px;
    margin-right: 6px;
    margin-bottom: 0;
    max-width: 170px;
    text-align: left !important;
  }
}
@media (max-width: 1200px) {
  .clone-conf-modal .notification-conf-secondmodal-crossmodal{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
  .clone-conf-modal .modal-body {
    display: flex;
    align-items: center;
    padding: 0px 34px 0px 34px;
  }
  .clone-conf-modal-popup-confirm {
    width: 100%;
    max-width: 360px;
    height: 50px;
    font-size: 16px;
  }
  .clone-conf-modal-popup-cancel {
    border: unset;
    width: max-content;
    height: max-content;
    margin-top: 36px;
    font-size: 16px;
  }
  .clone-conf-modal-popup-bottom {
    border-top: unset;
    padding: 0px;
    flex-direction: column-reverse;
    align-items: center;
    margin: unset;
  }
  .clone-conf-modal-input input {
    text-align: center;
  }
  .clone-conf-tick {
    justify-content: center;
}
.clone-conf-modal-input h2 {
  text-align: center;
}
.clone-conf-modal-popup-maindiv p {
  text-align: center;
}
.clone-conf-modal-popup-head h2 {
  text-align: center;
}
  .cloneTooltip{
    top: 9px;
    right: 46px;
  }
  .mob-clone-svg{
    display: block !important;
  }
  .web-clone-svg{
    display: none !important;
  }
  .myapps_hover .myapps_topsection .clone-svg{
    display: block;
    }
  .myapps-filter-seconddiv .select-filter__single-value {
    color: #1b2952 !important;
    font-size: 14px !important;
    font-family: "Axiforma-Regular" !important;
  }
  .myapps-filter-close{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .myapps-fiter-btn-cancel{
      color: #bcc1ce;
      font-size: 16px;
      font-family: "Axiforma-Regular";
      border-radius: 3px;
      cursor: pointer;
      margin-top: 15px;
    
  }
  .myapps_peronal-apps{
    font-size: 14px;
  }
  .myapp_headingone {
    font-size: 16px;
  }
  .myapp_heading {
    font-size: 16px;
  }
  .myapp_headingone {
    display: flex;
  }
  .select-myapps-div {
    max-height: 420px;
  }
  .select-myapss {
    position: absolute;
    left: 0px;
    z-index: 1;
    border: unset;
    top: 112px;
    width: 100%;
    width: 100%;
    height: 100%;
    /* overflow: auto; */
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    transition: 0s;
  }
  .select-myapps-div {
    width: 100%;
    border-radius: 0px;
  }
  .select-myapss::before {
    /* display: block;
        position: absolute;
        z-index: 99;
        left: 100px;
        margin: auto;
        transform: rotate(0deg); */

    display: block;
    content: unset;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    z-index: 99;
    top: -10px;
    left: 87px;
    margin: auto;
    transform: rotate(0deg);
    right: unset;
    margin-left: 1%;
  }
  /* .new-createapp-button{
        display: none;
    } */
  .noapps-filtered {
    margin-top: 229px;
  }
  .myapps-filter-seconddiv .select-filter__control:hover,
  .myapps-filter-seconddiv .select-filter__control:focus,
  .myapps-filter-seconddiv .select-paymentgateway__control:hover,
  .myapps-filter-seconddiv .select-paymentgateway__control:focus {
    height: 50px !important;
  }
  .myapps-filter-seconddiv .select-filter__control {
    height: 50px !important;
  }
  .myapps-filter-seconddiv .filter-input {
    height: 50px !important;
  }
  .myapps-filter-seconddiv .select-paymentgateway__control {
    height: 50px !important;
  }
  .myapps_topsection h2 {
    font-size: 14px !important;
    margin-bottom: 5px;
}
.myapps_topsection {
  padding: 15px 15px 10px 15px !important;
}
  .noapps-created button {
    font-size: 16px;
  }
  .noapps-created h1 {
    font-size: 24px;
    line-height: 40px;
    margin-right: 13px;
    margin-left: 13px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  .noapps-created p {
    margin-bottom: 40px;
  }
  .noapps-created {
    margin-top: 118px;
  }
  .filtersearch {
    display: block;
  }
  .myapps_appboxs {
    margin-top: 15px !important;
  }
  .myapps_topdiv {
    margin-bottom: 0px !important;
    margin-top: 18px !important;
    margin-right: 1.01%;
  }
  .filter-buttondisabled {
    font-size: 16px;
    height: 50px;
  }
  .filter-buttonenable {
    font-size: 16px;
    height: 50px;
  }
  .myapps-filter-div {
    width: 100%;
    /* right: -100%; */
    right: 0%;
  }
  .myapps-filter-divopen {
    right: 0%;
    width: 100%;
  }
  .left-image-container {
    display: none;
  }
  .myapps-web-profileimg {
    display: none;
  }
  .myapps_left_worspace {
    margin-left: 0px;
  }
  .myapps-search-btn-main {
    height: 44px;
    min-width: 44px;
  }
  /* .advancesearch_takethere{
        border: 1px solid #E3E6F1;
    border-radius: 3px;
    margin-left: 10px;
    padding-left: 0px; 
     padding-right: 0px;
     line-height: 44px;
    width: 44px;
    height: 44px;
    } */
  .myapps_container {
    margin-top: 60px !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px 15px;
  }
  .advance_searchmargin {
    margin: 0px;
  }

  .mobile-createapp {
    display: flex;
    align-items: center;
  }
  .mobile-createapptext {
    color: #3064f9;
    font-size: 15px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-left: 5.8px;
  }
  .myapp_heading {
    justify-content: space-between;
    display: flex;
  }
  .filterapplyed-reddot {
    right: 9px;
  }
  .advancesearch_takethere {
    border: 1px solid #e3e6f1;
    border-radius: 3px;
    margin-left: 10px;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 44px;
    width: 44px;
    height: 44px;
    margin-right: 0px;
    /* display: none; */
  }
  .myapps_topdiv {
    display: grid;
    justify-content: inherit;
  }
  .new-createapp-button {
    display: none;
  }
  .myappnotificationdiv {
    display: none !important;
  }
  .myapps_appboxs {
    max-width: 380px;
    width: 31.3%;
  }
  .advance_searchmargin {
    width: 100%;
  }
  .advance_bordertop {
    margin-top: 12px;
  }

  .advance_searchmargin {
    margin-right: 0px !important;
    /* width: calc(100% - 54px) !important; */
    width: 100%;
    margin: 0;
    height: 44px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .myappnotificationdiv {
    margin-top: 80px;
    margin-bottom: 0px !important;
  }

  .myapps_topdiv {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }

  .myappnotificationdiv span {
    font-size: 9px !important;
  }
  .myappnotificationdiv h6 {
    font-size: 10px !important;
  }
  .myapps_statusicon img {
    width: 20px !important;
  }

  .myapps_topsection .created-date-personal,
  .created-date-workspace {
    text-align: right !important;
    font-size: 9px;
  }
  .myapps_topsection h3 {
    font-size: 9px;
  }
}
@media (max-width: 767px) {
  /* @media (min-width: 421px) and (max-width: 767px){ */
  /* .myapps-search-btn-main{
        height: 50px;
        min-width: 56px;
    } */
  /* .myapps_container{
        margin-top: 70px !important;
    }
     */
  .myapps_appbox {
    height: 340px;
    max-height: 340px !important;
  }
  .myapps_hover .new-appimg {
    width: 170px !important;
    height: 170px !important;
    border-radius: 48px !important;
    margin-top: 18px;
    margin-bottom: 45px;
  
  }
  /* .myapps-filter-seconddiv .select-paymentgateway__control{
        height: 50px !important;
    }
    .myapps-filter-seconddiv .select-filter__control{
        height: 50px !important;
} */
  .mobile-createapp {
    display: flex;
    align-items: center;
  }
  .app-created-date {
    margin-top: 4px;
  }
  .mobile-createapptext {
    color: #3064f9;
    font-size: 15px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-left: 5.8px;
  }
  .myapp_heading {
    justify-content: space-between;
    display: flex;
  }
  .filterapplyed-reddot {
    right: 9px;
  }
  .advancesearch_takethere {
    border: 1px solid #e3e6f1;
    border-radius: 3px;
    margin-left: 10px;
    padding-left: 0px;
    padding-right: 0px;
    line-height: 44px;
    width: 44px;
    height: 44px;
  }
  .myapps_topdiv {
    display: grid;
    justify-content: inherit;
  }
  .new-createapp-button {
    display: none;
  }
  .myappnotificationdiv {
    display: none !important;
  }
  .myapps_appboxs {
    max-width: 350px;
    width: 47.97%;
  }
  .myapps_appboxs .myapps_inside_image .onhoverparent .new-appimg {
    background-size: cover !important;
    width: 84px !important;
    height: 84px !important;
    margin: 22px !important;
  }

  /* .advance_bordertop{
        border-top: 1px solid #E3E6F1;
    } */
  /* .myapps_mobileviewdiv{
        margin-top: -10px !important;
    } */

  .myapps_createappbox {
    margin-top: 0px !important;
  }
  .myapps_createappbox .myapps_inside_image {
    height: 50px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-radius: 4px !important;
    border: 1px solid #c8cedb !important;
    background-image: none !important;
    color: #7782a1;
    margin: 0px;
    background-color: #fff !important;
  }
  .myapps_createappbox .applinks {
    margin-bottom: 0px !important;
  }
  .myapps_createappbox .first-app {
    display: flex !important;
    align-items: center !important;
  }
  .myapps_createappbox .first-app p {
    margin-bottom: 0px !important;
    margin-left: 10px !important;
  }
  .myapps_inside_image .myapps_smallscreen .new-appimg {
    width: 84px !important;
    height: 84px !important;
  }
  .myapps_appbox .topsection.topsectionapp-myapps {
    width: 100% !important;
  }
  .myapps_mobiledate {
    width: 170px !important;
  }
  .myapps_mobiledate .myapps_viewstatus-responsive {
    margin-left: 8px !important;
    border-radius: 10px;
    width: 42px !important;
    text-align: center !important;
    height: 18px !important;
    line-height: 17px !important;
  }
  .notificationdiv3 {
    display: block !important;
  }
  .myappps_topsectionapp {
    padding: 17px 14px 19px 14px !important;
  }
  .myappps_topsectionapp h2 {
    color: #1b2952;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 45px);
    text-transform: capitalize;
    margin-bottom: 7px;
    font-family: "Axiforma-Bold";
  }

  /* .advancesearch_takethere{
    width: 56px;
    height: 50px;
    border-radius: 0px;
    border-left: none;
    border-top: none;
    line-height: 50px !important;
  } */
  .myapps_createappbox {
    margin: 0%;
    width: 100%;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
  }

  .myapps_mobiledate p {
    font-size: 11px;
    width: auto !important;
    white-space: nowrap;
    color: #7782a1;
    margin: 0px;
    font-family: "Axiforma-Regular";
  }
  .myapps_mobiledate {
    margin-top: 7px !important;
  }
  .myappps_topsectionapp h3 {
    font-size: 11px;
    margin: 0px;
    color: #7782a1;
    text-transform: capitalize;
    font-family: "Axiforma-Regular";
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: auto;
  }
  .myapps_statusiconimg img {
    max-width: 20px;
  }
  .myapps_smallscreen {
    background-color: #f8fafe;
    width: 128px;
    overflow: inherit;
    position: relative;
    transition: all 0.5s ease;
  }
  .mobile_span {
    text-decoration: underline !important;
  }
  .mobile_span:hover {
    text-decoration: underline !important;
  }
  /* .myapps_container{
    margin-top: 0px !important;
  } */
  /* .myapps_topdiv{
    margin-top: px !important;
  } */
  /* .advance_filter-post-myapps{
    top: 125px;
   
  } */
}

@media (max-width: 654px) {
  .myapps_appboxs {
    max-width: 100%;
    width: 100%;
  }
}
