.container {
  position: relative;
}
.add-workspace-inner {
  padding-top: 120px;
  width: 1080px;
  margin-bottom: 50px;
}
.add-workspace-header {
  margin-bottom: 30px;
}
.add-workspace-head {
  flex: 1;
}
.add-workspace-head h5 {
  margin-bottom: 0;
}
.workspace-search {
  width: 333px;
  height: 44px;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
  padding: 0px 14px 0px 16px;
  margin-right: 20px;
}
.text-search-bar {
  padding-top: 9px;
  padding-bottom: 9px;
}
/* .text-search-bar input {
  font-family: "Axiforma-Regular";
  font-size: 15px;
  color: #c8cedb;
  margin-bottom: 0;
} */
.removecontainerimage {
  background-image: none!important;
}
.work-search-icon {
  padding-top: 6px;
  padding-bottom: 12px;
  padding-left: 12.3px;
  border-left: 1px solid #e3e6f1;
}
.workspace-creation-add-btn {
  padding: 5px 25.3px 6px 16px;
  border: 1px solid #3064f9;
  border-radius: 3px;
  color: #3064f9;
}
.workspace-creation-add-btn h5 {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  padding-left: 14.7px;
  color: #3064f9;
  margin-bottom: 0;
}
.workspace-det-tile {
  border: 1px solid #e3e6f1;
  border-radius: 4px;
  margin-bottom: 22px;
}
.workspace-det {
  flex: 1;
  padding-left: 20px;
}

.workspace-det-inner h5 {
  margin-bottom: 0;
}
.workspace-det-inner p {
  font-family: "Axiforma-Regular";
  font-size: 10px;
  margin-bottom: 0;
}
.workspace-det-inner a {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0;
}
.workspace-det h3 {
  font-family: "Axiforma-Bold";
  font-size: 16px;
  color: #1b2952;
}
.workspace-det h5 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
}
.workspace-user-det {
  padding-right: 65px;
}
.workspace-user-det h5 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  margin-bottom: 0;
}
.workspace-app-det {
  padding-right: 30px;
}
.workspace-app-det h5 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #bcc1ce;
  margin-bottom: 0;
}
.creation-date h5 {
  font-family: "Axiforma-Regular";
  font-size: 11px;
  color: #bcc1ce;
  padding-top: 7px;
  margin-bottom: 0;
}

.text-search-bar input {
  font-family: "Axiforma-Regular";
  font-size: 15px;
  color: #c8cedb;
  margin-bottom: 0;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.listing-plan-name {
  margin-right: 10px;
  width: max-content;
}

.listing-status {
  margin-right: 10px;
}

.profile-detail-btn .next_btn_overview {
  width: 120px;
  height: 40px;
}
.right_panel_overview {
  background: transparent !important;
  box-shadow: none !important;
}

.your-info-overview {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left: none;
}

input.work-name-input {
  background-color: #F8FAFE;
}

.overview-head {
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #E3E6F1;
  margin-bottom: 24px;
}

.overview-mid {
  padding-left: 30px;
  padding-right: 30px;
}

.overview-bottom {
  margin-top: 20px;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 0px 10px 0px rgb(36 116 249 / 15%);
  border-radius: 4px;
  background-color: #FFF;
}

.overview-intro-section {
  box-shadow: 0px 0px 10px 0px rgb(36 116 249 / 15%);
  border-radius: 4px;
  background-color: #FFF;
  padding-top: 18px;
}

.my-profile p.overview-head-subtext {
  text-align: left !important;
  margin-top: 6px;
}

.del.del-overview {
  padding-bottom: 0;
  width: 140px;
   cursor:pointer;
}

.billing-heading-overview {
  margin-top: 0px;
}

.app_iconname.app_iconname_overview {
  margin-top: 0;
}

.billing-heading-overview .app-iconname-workspace-mobile h5 {
  margin-top: 6px;
}

.confirmpopup.confirmpopup-workspace {
  background-color: #FFF !important;
  padding-top: 25px !important;
  padding-left: 20px !important;
  border-radius: 6px!important;
}

.your-info.your-info-overview h3 {
  font-size: 16px !important;
}

.name-input-box-spacing h4 {
  font-size: 13px !important;
  color: #1B2952 !important;
}

.overview-heading-main h1 {
  font-size: 20px !important;
}

.overview-heading-main p {
  font-size: 13px !important;
  margin-bottom: 19.5px !important;
}

.delete-btn.delete-workspace-btn {
  height: 40px;
  padding: 11px 44px 12px 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BCC1CE;
  font-size: 13px;
  border: 1px solid #E3E6F1 !important;
}

.app-iconname-workspace-mobile.app-iconname-overview h5 {
  margin-bottom: 19px !important;
}

.confirmpopup.confirmpopup-workspace .confirm-over-red {
  font-family: 'Axiforma-Bold';
  font-size: 18px;
  color: #1B2952;
}

.crossmodal.crossmodal-over {
  right: 9.8px;
  top: 0;
}

.workspace-warning-text1, .workspace-warning-text2 {
  font-family: 'Axiforma-Regular';
  font-size: 13px;
  color: #7782A1;
  line-height: 1.5;
}

.workspace-warning-text1 {
  margin-bottom: 24px;
}

.workspace-warning-text2 {
  margin-bottom: 2px;
}

.work-over-red {
  width: 560px;
  height: 50px;
  background-color: #F8FAFE;
  border: 1px solid #E3E6F1;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  outline: none !important;
  font-family: 'Axiforma-Regular';
  font-size: 13px !important;
  color: #BCC1CE;
}

input.work-over-red::placeholder {
  font-family: 'Axiforma-Regular';
  font-size: 13px !important;
  color: #BCC1CE !important;
}

.work-over-main {
  margin-bottom: 39.5px;
}

.finalselection-overview {
  border-top: 1px solid #E3E6F1;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.overview-modal-body {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.overview-flex-main {
  padding-left: 20px;
  padding-right: 20px;
}

.work-over-main {
  padding-left: 20px;
  padding-right: 20px;
}

p.refusal-overview {
  margin-left: 0 !important;
  border-color: #BCC1CE !important;
}

p.approval-overview {
  color: #FF4848 !important;
  background-color: #FFF !important;
}

.workspace-delete-modal .modal-content {
  position: relative !important;
}
.workspace-type{
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.workspace-type img{
  margin-left: 20px;
  max-width: 36px;
}
.leave-workspace button{
  width: 176px;
  height: 40px;
  color: #BCC1CE;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #E3E6F1 !important;
  margin-right: 20px;
  background-color: #fff;
  font-family: 'Axiforma-Regular';
}
.leave-workspace button svg{
  margin-right: 6px;
}
.workspace-role h4{
  font-family: "Axiforma-Regular";
  font-size: 10px;
  background-color: #E3E6F1;
  border-radius: 20px;
  color: #7782A1;
  margin-top: 10px;
  padding: 4px 12px;
  line-height: normal;
  line-height: initial;
  width: max-content;
}
.leaveworkspacemodal h4{
  color: #1B2952;
  font-family: "Axiforma-Bold";
  font-size: 18px;
  text-align: center;
  line-height: 34px;
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
}
.leaveworkspacemodal h6{
  color: #7782A1;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  width: 80%;
  margin: auto;
}
.leave-workspace-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.leave-workspace-footer .leave-workspace-cancel{
  width: 140px;
  height: 44px;
  border: 1px solid #C8CEDB;
  color: #7782A1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-right: 10px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  transition: .5s;
  text-transform: uppercase;
}
.leave-workspace-footer .leave-workspace-cancel:hover{
  transition:0.5s;
  box-shadow: 0px 2px 4px rgba(188, 189, 190, 0.39);
}
.leave-workspace-footer .leave-workspace-ok{
  width: 140px;
  height: 44px;
  border: 1px solid #FFA7A7;
  color: #FF4848;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 10px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  transition: .5s;
  text-transform: uppercase;
}
.redloader{
  border: 2px solid #FF4848!important;
  border-radius: 50%!important;
  border-top: 2px solid transparent!important;
  width: 16px!important;
  height: 16px!important;
  -webkit-animation: spin 0.6s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;
  box-shadow: none!important;
  margin: auto;
  margin-top: 10px;
}
.leave-workspace-footer .leave-workspace-ok:hover{
  transition:0.5s;
  box-shadow: 0px 2px 4px rgba(248, 160, 160, 0.39);
}
.workspace-setting-link{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.workspace-profile-pic-img{
    width: 82px;
    border-radius: 50%;
    height: 82px;
    background-repeat: no-repeat;
    background-size: cover;
}
.workspace-loader-img{
 margin-top: -5px
}
/* .upgrade-plan-link-index{
  z-index: unset;
} */























@media screen and (max-width: 280px) {
  .workspace-det h5 {
    font-size: 8px;
}
}

@media screen and (max-width: 359px) {
  .workspace-det.workspace-det-mobile {
    padding-left: 0px;
}
}

@media screen and (max-width: 330px) {
  .workspace-det h3 {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .workspace-det h5 {
    font-size: 9px;
  }
  h5.listing-plan-name {
    margin-right: 8px;
  }
  .workspace-det-inner p {
    font-size: 9px;
  }
  p.listing-status {
    margin-right: 8px;
  }
  p.viewstatus-responsive.viewstatus {
    padding-left: 10px;
    padding-right: 10px;
    height: 16px !important;
    line-height: 16px !important;
  }
  .workspace-det-inner a {
    font-size: 8px;
  }
  .user-app-info {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {

  .leaveworkspacemodal h4{
    width: 100%;
  }
  .leaveworkspacemodal h6{
    width: 100%;
  }
  .listing-status.listing-status{
    line-height: 16px !important;
  }
  .workspace-role h4 {
    font-size: 9px;
    margin-top: 10px;
    padding: 2px 7px;
  }
  .customiseappfeatures-workspace .overview-head .next_btn_overview{
    display: none;
  }
  .customiseappfeatures-workspace .overview-head .overview-heading-main{
    display: none;
  }
  .workspace-save-web{
    width: 100%;
  }
  .leave-workspace button{
    min-width: 100%;
    margin-right: 0px;
    display: block;
    margin-top: 15px;
  }
  .leave-workspace{
    width: 100%;
  }
  .workspace-role{
    position: absolute;
    right: 7px;
    bottom: 6px;
  }
  .profile-image-workspace.profile-img-mob-new {
    margin-bottom: 24px !important;
}
  .overview-mid, .overview-bottom {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
  div.your-info.your-info-mobile {
    margin-top: 0px !important;
}
  .overview-intro-section {
    padding-top: 0;
  }
  .workspace-save-mobile {
    margin-top: 48px;
  }
  .workspace-save-mobile button {
    width: 100% !important;
  }
  .color_picker.color-picker-workspace {
    margin-bottom: 48px !important;
  }
  .work-over-red {
    width: 100% !important;
  }
  .workspace-save-web {
    display: none !important;
  }
  .profile-pic-actual {
    width: 84px !important;
    min-width: 84px !important;
    height: 84px !important;
    border-radius: 5px !important;
    margin: 16px;
  }
  .creation-date h5 {
    padding-top: 8px;
    font-size: 10px;
  }
  .workspace-det h3 {
    font-size: 14px;
  }
  .user-app-info {
    margin-top: 5px;
  }
  .add-workspace-inner {
    width: 100%;
  }
  section.container {
    padding-left: 0;
  }
  .add-workspace-inner {
    padding-top: 0;
  }
  .workspace-search {
    width: 100%;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
    border-top: 0;
    margin-bottom: 20.5px;
  }
  .workspace-creation-add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .workspace-det-tile {
    margin-left: 20px;
    margin-right: 20px;
  }
  .overview-head {
    display: none !important;
  }
  .workspace-type {
    margin-right: 0px;
  }
  .workspace-type img {
    margin-left: 0px;
    max-width: 16px;
    margin-right: 5px;
  }
  .workspace-user-det , .workspace-app-det{
    padding-right: 0px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .workspace-save-mobile {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .overview-head, .overview-mid, .overview-bottom {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.add-workspace-inner{
  margin-top: 60px!important;
}
}

@media (min-width: 768px) and (max-width: 1200px) {
    .color_picker.color-picker-workspace {
      margin-bottom: 40px !important;
  }
  .work-over-red {
    width: 100% !important;
  }
  .workspace-user-det {
    padding-right: 20px;
  }
  .workspace-app-det {
    padding-right: 20px;
  }
  .workspace-type {
    margin-right: 20px;
  }
  .workspace-type img {
    margin-left: 15px;
    max-width: 25px;
  }
  .workspace-det {
    padding-left: 10px;
  }

}

@media (min-width: 1001px) and (max-width: 1200px) {
  .transparent_background.transparent_background_overview {
    margin-top: 60px !important;
  }
}