.payment-status .active-green {
    background-color: unset !important;
    border: none !important;
    width: auto;
    height: auto;
    font-size: 12px !important;
    border-radius: 10px;
    line-height: 18px;
    padding: 0px 8px 0px 8px;
    float: left !important;
    margin-bottom: 0px;
    font-family: "Axiforma-Regular";
    text-align: center;
    color: #47ce70 !important;
}

.payment-status .inactive-grey {
    background-color: unset !important;
    border: none !important;
    width: auto;
    height: auto;
    font-size: 12px !important;
    border-radius: 10px;
    line-height: 18px;
    padding: 0px 8px 0px 8px;
    float: left !important;
}
.payment-status .canceled-red {
    background-color: unset !important;
    border: none !important;
    width: auto;
    height: auto;
    font-size: 12px !important;
    border-radius: 10px;
    line-height: 18px;
    padding: 0px 8px 0px 8px;
    float: left !important;
}
.bill-date p, .bill-app p, .bill-total p, .bill-download p {
    margin-top: 0 !important;
}
.invoicess-para a{
    color: #3064F9 !important;
    font-size: 12px !important;
}
.invoicess-para a:hover{
    color: #3064F9 !important;
    font-size: 12px !important;
}
@media (max-width: 1200px) {
    .invoice-mob-paymentmethod{
        padding: 25px 15px 20px 15px;
    border-radius: 2px;
}
    
    .payment-status .active-green {
        font-size: 14px !important;
        padding: 0px !important;
    }
    .logsub.billingsub {
        padding: 15px 15px 0 15px !important;
    }
    .bill-invoicegoto{
        margin-left: 6.5px;
        margin-bottom: 2.5px;
    }
    .invoicess-plan-start  .bill-invoice{
        font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #3064f9;
    }
    .invoicess-date-time p{
        color: #7782A1;
    margin-bottom: 2px;
    margin-top: 0px;
    font-size: 14px;
   
    }
    .invoicess-text p{
     margin-bottom: 4px;
    align-items: start;
    display: flex;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
}
.invoicess-nametexturl{
    margin-bottom: 0px;
    align-items: start;
    display: flex;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #1B2952;
    margin-top: 0px !important;
}
.invoicess-nameurl{
    margin-bottom: 0px;
    align-items: start;
    display: flex;
    font-size: 14px;
 
    color: #7782A1;
}
.invoicess-bill-payment{
    justify-content: end;
    display: flex;
    align-items: center;
}
.invoicess-bill-payment p{
    margin-bottom: 0px !important;
}
.invoicess-date-time-payment p{
   margin-bottom: 0px !important;
   margin-top: 4.5px !important;
   color: #1b2952;
   line-height: 14px;
   font-size: 14px;
}
.invoice-status{
    width: auto !important;
    text-align: left !important;
    display: flex;
    align-items: center;
}
.invoice-mob-firstdiv{
    padding: 22px 15px 21.5px 15px;
    border-bottom: 1px solid #E3E6F1;
}
.invoicess-date-time p{
    text-align: left !important;
}
}
