@font-face {
  font-family: "Proxima-Extrabold";
  src: url(../fonts/ProximaNova-Extrabold.otf);
}

@font-face {
  font-family: "Proxima-Bold";
  src: url(../fonts/ProximaNova-Bold.otf);
}

@font-face {
  font-family: "Proxima-Regular";
  src: url(../fonts/ProximaNova-Regular.otf);
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.otf);
}

@font-face {
  font-family: "Proxima-Thin";
  src: url(../fonts/ProximaNova-Thin.otf);
}

@font-face {
  font-family: "Proxima-Black";
  src: url(../fonts/ProximaNova-Black.otf);
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins-Light";
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Arial";
  src: url(../fonts/Arial.ttf);
}

@font-face {
  font-family: "Sans-Serif";
  src: url(../fonts/Sans-Serif.ttf);
}
/* change */
@font-face {
  font-family: "ComicSansMS";
  src: url(../fonts/Comic-Sans-MS.ttf);
}
/* change */
@font-face {
  font-family: "TimesNewRomanPSMT";
  src: url(../fonts/Times-New-Roman.ttf);
}

@font-face {
  font-family: "Courier-New";
  src: url(../fonts/Courier-New.ttf);
}

@font-face {
  font-family: "Verdana";
  src: url(../fonts/Verdana.ttf);
}

@font-face {
  font-family: "Trebuchet-MS";
  src: url(../fonts/Trebuchet-MS.ttf);
}

@font-face {
  font-family: "Arial-Black";
  src: url(../fonts/Arial-Black.ttf);
}

@font-face {
  font-family: "Impact";
  src: url(../fonts/Impact.ttf);
}

@font-face {
  font-family: "Bookman";
  src: url(../fonts/Bookman-Old-Style.ttf);
}

@font-face {
  font-family: "Garamond";
  src: url(../fonts/Garamond.ttf);
}

@font-face {
  font-family: "Palatino";
  src: url(../fonts/Palatino.ttf);
}

@font-face {
  font-family: "Georgia";
  src: url(../fonts/Georgia.ttf);
}

@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.woff);
}

/* New fonts */

@font-face {
  font-family: "bainsley-bold";
  src: url(../fonts/new-fonts/bainsley/BainsleyBold.ttf);
}

@font-face {
  font-family: "bainsley-regular";
  src: url(../fonts/new-fonts/bainsley/Bainsley.ttf);
}

@font-face {
  font-family: "barracuda-bold";
  src: url(../fonts/new-fonts/barracuda/Barracuda-Bold.ttf);
}
@font-face {
  font-family: "barracuda-regular";
  src: url(../fonts/new-fonts/barracuda/Barracuda-regular.ttf);
}

@font-face {
  font-family: "betelgeuse-bold";
  src: url(../fonts/new-fonts/betelgeuse/Betelgeuse-Bold.ttf);
}

@font-face {
  font-family: "betelgeuse-regular";
  src: url(../fonts/new-fonts/betelgeuse/Betelgeuse-Regular.ttf);
}

/* change */
@font-face {
  font-family: "cactronbold";
  src: url(../fonts/new-fonts/cactron/Cactron-Bold.otf);
}

@font-face {
  font-family: "cactron-regular";
  src: url(../fonts/new-fonts/cactron/Cactron-Regular.otf);
}

@font-face {
  font-family: "cokanut-bold";
  src: url(../fonts/new-fonts/cokanut/Cokanut-Bold.otf);
}

@font-face {
  font-family: "cokanut-regular";
  src: url(../fonts/new-fonts/cokanut/Cokanut.otf);
}

@font-face {
  font-family: "cormorant-bold";
  src: url(../fonts/new-fonts/cormorant/Cormorant-Bold.ttf);
}

@font-face {
  font-family: "cormorant-regular";
  src: url(../fonts/new-fonts/cormorant/Cormorant-Regular.ttf);
}

/* change */
@font-face {
  font-family: "dukascf-bold";
  src: url(../fonts/new-fonts/dukas_cf/DukasCFBold-Regular.otf);
}
/* change */
@font-face {
  font-family: "dukascf-regular";
  src: url(../fonts/new-fonts/dukas_cf/DukasCFRegular-Regular.otf);
}

@font-face {
  font-family: "emberly-bold";
  src: url(../fonts/new-fonts/emberly/Emberly-Bold.ttf);
}
@font-face {
  font-family: "emberly-regular";
  src: url(../fonts/new-fonts/emberly/Emberly-Regular.ttf);
}

@font-face {
  font-family: "epigrafica-bold";
  src: url(../fonts/new-fonts/epigrafica/Epigrafica-Bold.ttf);
}
@font-face {
  font-family: "epigrafica-regular";
  src: url(../fonts/new-fonts/epigrafica/Epigrafica-Regular.ttf);
}
@font-face {
  font-family: "grenze-bold";
  src: url(../fonts/new-fonts/grenze/Grenze-Bold.ttf);
}

@font-face {
  font-family: "grenze-regular";
  src: url(../fonts/new-fonts/grenze/Grenze-Regular.ttf);
}

@font-face {
  font-family: "horizon-bold";
  src: url(../fonts/new-fonts/horizon/Horizon-bold.ttf);
}
@font-face {
  font-family: "horizon-regular";
  src: url(../fonts/new-fonts/horizon/Horizon-regular.ttf);
}

/* change */
@font-face {
  font-family: "hitmo20-bold";
  src: url(../fonts/new-fonts/hitmo/Hitmo2.0-Bold.ttf);
}
/* change */
@font-face {
  font-family: "hitmo20-regular";
  src: url(../fonts/new-fonts/hitmo/Hitmo2.0-Regular.ttf);
}

@font-face {
  font-family: "karantina-bold";
  src: url(../fonts/new-fonts/karantina/karantina-Bold.ttf);
}
@font-face {
  font-family: "karantina-regular";
  src: url(../fonts/new-fonts/karantina/karantina-Regular.ttf);
}

@font-face {
  font-family: "kufam-bold";
  src: url(../fonts/new-fonts/kufam/Kufam-SemiBold.ttf);
}
@font-face {
  font-family: "kufam-regular";
  src: url(../fonts/new-fonts/kufam/Kufam-Regular.ttf);
}
/* change */
@font-face {
  font-family: "kubanpro-bold";
  src: url(../fonts/new-fonts/kuban_pro/KubanPro-Bold.otf);
}
/* change */
@font-face {
  font-family: "kubanpro-regular";
  src: url(../fonts/new-fonts/kuban_pro/KubanPro-Regular.otf);
}

@font-face {
  font-family: "mangoGrotesque-bold";
  src: url(../fonts/new-fonts/mangogrotesque/MangoGrotesque-Bold.ttf);
}
@font-face {
  font-family: "mangoGrotesque-regular";
  src: url(../fonts/new-fonts/mangogrotesque/MangoGrotesque-Regular.ttf);
}
/* change */
@font-face {
  font-family: "mesheddisplay-bold";
  src: url(../fonts/new-fonts/mesheddisplay/MeshedDisplay-Bold.ttf);
}
/* change */
@font-face {
  font-family: "mesheddisplay-regular";
  src: url(../fonts/new-fonts/mesheddisplay/MeshedDisplay-Regular.ttf);
}

@font-face {
  font-family: "nymeria-bold";
  src: url(../fonts/new-fonts/nymeria/Nymeria-Bold.ttf);
}
@font-face {
  font-family: "nymeria-regular";
  src: url(../fonts/new-fonts/nymeria/Nymeria-Regular.ttf);
}

@font-face {
  font-family: "piazzolla-bold";
  src: url(../fonts/new-fonts/piazzolla/Piazzolla-Bold.ttf);
}
@font-face {
  font-family: "piazzolla-regular";
  src: url(../fonts/new-fonts/piazzolla/Piazzolla-Regular.ttf);
}
/* change */
@font-face {
  font-family: "radiocanada-bold";
  src: url(../fonts/new-fonts/radio_canada/Radio-Canada-Bold.ttf);
}
/* change */
@font-face {
  font-family: "radiocanada-regular";
  src: url(../fonts/new-fonts/radio_canada/Radio-Canada-Regular.ttf);
}
/* change */
@font-face {
  font-family: "RozinaV06Bold";
  src: url(../fonts/new-fonts/rozina/RozinaV07-Bold.otf);
}
/* change */
@font-face {
  font-family: "RozinaV04Regular";
  src: url(../fonts/new-fonts/rozina/RozinaV06.otf);
}

@font-face {
  font-family: "rogerex-bold";
  src: url(../fonts/new-fonts/rogerex/RogerexBold.ttf);
}

@font-face {
  font-family: "rogerex-regular";
  src: url(../fonts/new-fonts/rogerex/RogerexRegular.ttf);
}

@font-face {
  font-family: "rebeqa-bold";
  src: url(../fonts/new-fonts/rebeqafont/Rebeqa-Bold.ttf);
}
@font-face {
  font-family: "rebeqa-regular";
  src: url(../fonts/new-fonts/rebeqafont/Rebeqa-Regular.ttf);
}
/* change */

@font-face {
  font-family: "unbounded-bold";
  src: url(../fonts/new-fonts/unbounded/Unbounded-Bold.ttf);
}
/* change */
@font-face {
  font-family: "unbounded-regular";
  src: url(../fonts/new-fonts/unbounded/Unbounded-Regular.ttf);
}

body {
  max-width: 100%;
  margin: auto !important;
  font-family: "Axiforma-Regular";

  background-color: #fff !important;
}
header {
  max-width: 100%;
  margin: auto !important;
}
.headborder {
  border-bottom: 1px solid #e0e5ec;
}
.webhostspac {
  border-bottom: 1px solid #e0e5ec;
  padding-bottom: 16px;
}
.securityspac {
  border-bottom: 1px solid #e0e5ec;
  padding-bottom: 9px;
  padding-top: 1px;
}
.midspac {
  padding-bottom: 6px;
}
.apispac {
  padding: 0 20px;
}
.new_division {
  width: 48%;
  margin-right: 4%;
}
.new_division:last-child {
  margin-right: 0px;
}
#androidmodalform .gf-config .ios-buid-width-change.ios-new-modal p {
  width: auto;
  font-size: 13px;
  color: #7782a1;
}
#androidmodalform .upload-provision .certificate-file {
  height: 60px;
}
.grey-content {
  border-bottom: 0px !important;
}
.ad-mob-div {
  border-top: 1px solid #ced4da;
  padding-top: 30px;
}
#androidmodalform .new-button-upload,
#iosform .new-button-upload {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 6px;
}
.new_width_input .ios-buid-width-change {
  width: 100%;
}
.new_width_input .upload-btn [type="file"] + label {
  display: flex;
  border: none;
  height: 44px !important;
  margin-left: 0px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: #7782a1 !important;
  background-color: #f4f7fd !important;
  width: 130px;
}
.uploaded-file p {
  width: auto !important;
  padding-left: 20px;
}
.uploaded-file p.newspace {
  padding-left: 5px;
}
.new_width_input .upload-btn.ios-buid-width-fix {
  width: 100%;
}
.modal-backdrop {
  background-color: #000000a1 !important;
}
.desk-none {
  display: none;
}
/* Mobile Mneu */
.mobile-menu-lower .panel {
  width: 25%;
}
.hide-mob {
  display: none !important;
}
.showmobilemenu {
  display: none !important;
}
.showmobileprofilemenunew {
  display: none;
}
.showmobileprofilemenu{
  display: none !important;
}
.showbillingmenumenunew {
  display: none;
}
.showmenu {
  display: block !important;
}
.mobile-menu {
  width: 100%;
  background-color: white;
  position: fixed;
  overflow: hidden;
  z-index: 999;
  left: 0px;
  height: 100vh;
  right: 0px;
  top: 0px;
}
.mobscroll {
  overflow: auto;
}
.mob-menu-title p {
  font: 12px "Axiforma-Regular";
  color: #1b2952;
  margin: 10px 0px 20px 0px;
}
.mobile-menu-lower.logout-mobile {
  background-color: #f8fafe;
  padding: 10px 10px 4px 10px;
  margin-bottom: 0px;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.mobile-menu-lower .pannel_title h4 {
  font: 13px "Axiforma-Regular";
  color: #7782a1;
  margin-left: 0px;
  margin-top: 8px;
}
.mobile-menu-lower {
  padding-top: 20px;
  border-top: 1px solid #edf0f5;
  margin-bottom: 20px;
}
.mobile-menu-lower .web_logo {
  margin-left: 0px;
  height: 35px;
}
/* Mobile menu end */
.my_apps_width .annual-plan-offer {
  margin: -25px 11px 30px 8px !important;
}
.apidescription .datashow {
  margin-bottom: 0px;
}
.new-apikeys-section.upload_files {
  background-color: #fff;
}
.plandata p {
  text-transform: capitalize;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Bold";
}
/* New CSS */
.copy_function.api-details .upload_files {
  padding: 20px 25px 10px 25px;
}
.download-dashboard a {
  font-size: 14px;
}
.download-dashboard {
  width: 200px;
}
.apkbutton {
  margin-right: 17px;
}
.force-auth label {
  padding: 0px !important;
}
.generallogo h6 {
  color: #7782a1;
  font-size: 20px;
  font-family: "Axiforma-Regular";
  margin-top: 10px;
}
.generallogo p {
  color: #c8cedb;
  font-size: 18px;
  font-family: "Axiforma-Regular";
}
.up p {
  margin-bottom: 4px;
  font-size: 13px;
}
.force-auth .guest-login-toggle p {
  margin-bottom: 0px;
}
/* Bugs CSS Starts */
.guest-login-toggle .coming-soon-social {
  color: #3064f9;
  font-family: "Axiforma-Bold";
}
/* Bugs CSS Ends */
.tocapital {
  text-transform: capitalize;
}
.signshow {
  color: #1b2952;
  font-size: 16px;
  margin-bottom: 0px;
  padding: 0px 5px;

  font-family: "Axiforma-Bold";
}
.module_title .showbread h6 {
  color: #3064f9;
  font-size: 16px;
  margin-bottom: 0px;
  cursor: pointer;
  font-family: "Axiforma-Bold";
}

.module_title .showbread:last-child h6 {
  color: #1b2952;
}
.category-class-four.categoriesset .category-list .cat-product {
  margin-bottom: 5px;
  width: 47.8%;
}
.category-class-four .cat-product.solid-category p,
.blog_categories1 .cat-product.solid-category p {
  display: flex;
  border-radius: 3px;
  justify-content: center;
  height: 105px;
  align-items: center;
  margin-bottom: 5px;
  transition: border-radius 0.5s ease;
}
.category-class-four.categoriesset .category-list .cat-product.solid-category {
  margin-bottom: -2px;
}
.solid-category svg {
  padding-right: 5px;
}
.category-class-four.categoriesset {
  width: 100%;
  height: auto !important;
  margin-bottom: 10px;
}
.category-class-four .cat-product p,
.blog_categories1 .cat-product p {
  font: 10px/13px "Axiforma-Regular";
  text-align: center;
  border-radius: 0px 0px 3px 3px;
}
/* IOS Form Starts */
.app-meta.app-meta-ios .short-description {
  height: 110px;
}
.app-meta.app-meta-ios .long-description {
  height: 328px;
}
/* IOS Form Ends */
/* Color Theme Starts */
.colortheme-detail {
  padding: 8px;
}
.colortheme-detail h2 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 3px;
  text-align: left;
}
.colortheme-detail p {
  font-size: 13px;
  line-height: 15px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
  text-align: left;
}
/* Color Theme Ends */
/* Troubleshoot Popup Starts */

.troubleshoot.modal-body {
  padding: 0px;
}
.troubleshoot.modal-body h4 {
  padding: 20px 30px;
  margin-bottom: 0px;
  border-bottom: 1px solid #e8edf8;
}
.troubleshoot.modal-body .crossmodal {
  top: 22px;
  right: 25px;
}
.trouble-shoot-steps {
  padding: 0px 30px;
  padding-left: 0px;
}
.troubleshoot-detail {
  padding: 50px 0px;
}
.trouble-shoot-steps .col-md-5 {
  padding-right: 0px;
}
.trouble-shoot-steps .col-md-7 {
  border-left: 1px solid #e8edf8;
}
.troubleshoot-detail p {
  color: #c8cedb;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 15px;
  font-family: "Axiforma-Regular";
}
.trouble-shoot-steps h6 {
  color: #1b2952;
  font-size: 17px;
  margin-bottom: 30px;
  font-family: "Axiforma-Bold";
}
.trouble-shoot-steps h6 .media {
  color: #3064f9;
  text-decoration: underline;
  cursor: pointer;
}
.trouble-shoot-steps ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.trouble-shoot-steps ul li svg {
  margin-right: 10px;
}
.underpopup {
  margin-bottom: 20px;
}
.underpopup p {
  color: #3064f9;
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-family: "Axiforma-Regular";
}
.trouble-shoot-steps ul li {
  color: #1b2952;
  font-size: 15px;
  padding: 14px 15px 14px 20px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.contatcus {
  cursor: initial !important;
}
.contatcus span {
  cursor: pointer !important;
  color: #3064f9;
}
.troubleshootbutton {
  width: 200px;
  height: 48px;
  display: block;
  margin: auto;
  margin-top: 30px;
  border: none;
  background: #3064f9;
  border-radius: 4px;
  color: white;
  font-size: 16px;
}
.disabledbutton {
  width: 200px;
  height: 48px;
  display: block;
  margin: auto;
  margin-top: 30px;
  border: 1px solid #c8cedb;
  background: white;
  border-radius: 4px;
  color: #c8cedb;
  font-size: 16px;
}
.listsuccess:hover {
  background: #f6f8fe;
}
.activelist {
  background: #f6f8fe;
}
.listnotsuccess:hover {
  background: #fff6f6;
}
.deactivelist {
  background: #fff6f6;
}
.trouble-shoot-steps ul li:last-child {
  margin-bottom: 0px;
}
/* Troubleshoot Popup Ends */
/* Api Page Starts */
.get-support-pop {
  cursor: pointer;
}
.apikeys-error {
  margin-top: 22px;
}
.force-auth {
  margin-top: 20px;
}
.apikeys-error h5 {
  color: #000;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}
.apikeys-error p {
  color: #3064f9;
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-family: "Axiforma-Regular";
}
.apikeys-error .error-in-api {
  color: #000;
  margin-bottom: 10px;
  font: 16px "Axiforma-Regular";
}
.see_video {
  cursor: pointer;
}
.see_video p {
  color: #c8cedb;
  margin-bottom: 0px;
  font: 14px "Axiforma-Regular";
}
/* Api Page Starts */
/* Publish App Starts */
.two-apps .creating-build {
  margin-top: 20px;
}
.two-apps .creating-build img {
  margin-right: 15px;
}
.two-apps .creating-build h4 {
  color: #1b2952;
  font: 14px "Axiforma-Bold";
}
.two-apps .creating-build p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  margin-bottom: 0px;
}
/* Publish App Ends */
/* Premium Plan Starts */
.upgrade-now {
  background-color: #fff;
  border-radius: 4px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid #fff;
}
.upgrade-now:hover {
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: transparent;
  cursor: pointer;
  color: white;
}
.upgrade-now p:hover {
  color: white;
}
.upgrade-now p {
  color: #3064f9;
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  height: 40px;
  line-height: 41px;
  text-transform: uppercase;
}
.upgrade-premium {
  background-color: #4d7afb;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 12px 30px;
}
.premium-heading {
  margin-left: 30px;
}
.premium-heading h3 {
  color: #fff;
  font: 20px "Axiforma-Bold";
}
.premium-heading p {
  color: #fff;
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
}
/* Premium Plan Ends */
/* Slider with range */
.slidecontainer p {
  font: 15px "Axiforma-Regular";
  color: #1b2952;
}
.slidersplash {
  width: 100%;
  -webkit-appearance: #3064f9;
  cursor: pointer;
  height: 5px;
  background: #d3d3d3;
  position: absolute;
  border-radius: 25px;
  outline: none !important;
  top: 40px !important;
  transition: 0.5s;
}
.slidecontainer {
  width: 50%;
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;
}

.slidersplash:hover {
  opacity: 1;
}

.slidersplash::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 100%;
  cursor: pointer;
}
.slidersplash::-moz-range-progress {
  background-color: #3064f9;
  height: 5px;
}

.slidersplash::-moz-range-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 100%;
  cursor: pointer;
}
/* Slider with range */
/* App Add-ons Starts */
.addons-upper-body {
  padding: 40px 40px 20px 40px;
}
.addons ul li:last-child {
  padding-bottom: 0px;
}

.app-addons .add-on-not-added {
  padding: 8px 18px;
}
/* App Add-ons Ends */

/* Android Form Start */
.country-or-region p {
  margin-bottom: 0px;
  margin-right: 5px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
}
.country-list .meta-table {
  width: 25%;
}
.meta-table-new ul li:last-child {
  padding-bottom: 0px !important;
}
.custom-100button .w-50 {
  width: 100% !important;
}
.developer-account-access p:hover {
  cursor: pointer;
}
.important-links.meta-table .container4 {
  margin-top: 25px;
  margin-bottom: 0px !important;
}
.app-meta .customiseappfeatures {
  padding-top: 10px;
}
.grant-login-access {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.meta-table-new {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
}
.meta-table-new ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.meta-table-new ul li {
  padding-left: 0px;
  padding-bottom: 15px;
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  list-style: none;
}
.grant-login-access h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 25px;
}
.grant-login-access .meta-table {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dce1eb;
}
.save-draft-form {
  border: 1px solid #3064f9;
  border-radius: 3px;
  height: 39px;
  line-height: 40px;
  transition: 0.5s;
  margin-right: 25px;
  padding: 0px 15px;
  cursor: pointer;
}
.save-draft-form:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.save-draft-form p {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}
.submit-last-step .meta-table .container4 .oauth-secure {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;
}
.submit-last-step .please-wait {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}
.submit-last-step h6 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-top: 10px;
  display: block;
  font-size: 14px;
}
.submit-last-step .upload_files {
  background-color: transparent;
  border: 1px solid #c8cedb;
  border-radius: 3px;
}
.submit-last-step .meta-table .container4 p {
  font-size: 15px;
  color: #1b2952;
}
.submit-last-step .meta-table .checkmark {
  height: 22px;
  width: 22px;
}
.submit-last-step .meta-table .container4 {
  padding-left: 33px;
}
.need-account {
  cursor: pointer;
}
.need-account p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  text-decoration: underline;
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 14px;
}
.developer-account-access p {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  font-size: 15px;
}
.developer-account-access svg {
  margin-left: 5px;
  margin-bottom: 2px;
}
.ios-form-toggle {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  font-size: 16px;
  margin-left: 10px;
}
.restriction-limit p {
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  font-size: 12px;
}
.app-meta textarea {
  resize: none;
}
.app-meta .short-description {
  height: 130px;
}
.app-meta .long-description {
  height: 462px;
}
.meta-table .container4 {
  margin-top: 10px;
}
/* Android Form Ends */
/* Provision Certificate Starts */
.ios-modal-bottom {
  padding: 20px;
  border-top: 1px solid #e8edf8;
}
.ios-modal-bottom .next_btn {
  width: 200px;
  height: 48px;
  padding: 0px;
  text-align: center;
  line-height: 48px;
}
.upload-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
.upload-btn [type="file"] + label {
  background: #fff;
  border: 1px solid #7782a1;
  border-radius: 4px;
  color: #7782a1;
  cursor: pointer;
  font-family: "Axiforma-Regular";
  font-size: 15px;
  height: 48px;
  width: 200px;
  margin-left: 15px;
  text-align: center;
  line-height: 48px;
  position: relative;
  transition: 0.5s;
  margin-bottom: 0px;
}
.upload-provision {
  padding-bottom: 15px;
  padding-top: 15px;
}
.custom-class-add {
  padding-top: 37px;
}
.down-csr {
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.csr-file h5 {
  color: #3064f9;
  font-size: 15px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.uploaded-file p {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 170px;
  white-space: nowrap;
}
.uploaded-file svg {
  cursor: pointer;
}
.certificate-file::placeholder,
.com-place::placeholder {
  color: #c8cedb !important;
}

.grey-content .file-supported {
  color: #7782a1;
  font-size: 12px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  margin-right: 10px;
}
.svgstyles {
  margin-right: 6px;
  margin-top: auto;
  margin-bottom: auto;
}
.upload-provision h3 {
  color: #1b2952;
  font-size: 16px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.upload-provision .certificate-file {
  height: 60px;
  width: 200px;
}
[dataclass~="iosformupload"] #iosform {
  border-top: 1px solid #e8edf8;
}
.grey-content {
  border-bottom: 1px solid #e8edf8;
}
.border-top {
  border-top: 1px solid #e8edf8;
}
.grey-content p {
  color: #1b2952;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.grey-content a {
  color: #3064f9;
  text-decoration: underline;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
.grey-content p span {
  color: #7782a1;
}
.provision-certificate h4 {
  color: #1b2952;
  font-size: 22px;
  font-family: "Axiforma-Bold";
}
.provision-certificate {
  padding: 20px !important;
}
.provision-certificate .crossmodal {
  top: 18px;
  right: 30px;
}
/* Provision Certificate Ends */
.new-modal-spacing {
  border: none;
}
/* Add On Starts */
/* .add-on-plan:nth-child(3n+3){
	margin-right: 0px;
} */
.modal-title svg {
  cursor: pointer;
}
.mobile-heading-modal {
  display: none;
}
.purchase-add-on-modal {
  padding: 30px 25px !important;
}
.publish-on-modal ul {
  list-style: none;
  padding-left: 0px;
}
.publish-on-modal span {
  color: #3064f9;
  font-size: 14px;
  cursor: pointer;
}
.publish-on-modal h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 20px;
}
.publish-on-modal h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 18px;
  margin-top: 35px;
}
.publish-on-modal ol {
  padding-left: 0px;
  list-style: none;
}
.publish-on-modal ol li {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 15px;
  font-size: 14px;
}
.publish-on-modal p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 15px;
}
.add-on-plan-description {
  margin-bottom: 40px;
  padding: 0px 18px;
  height: 280px;
}
.add-on-plan {
  border: 1px solid #e3eafb;
  border-radius: 3px;
  text-align: center;
  padding-top: 50px;
  margin-right: 2%;
  margin-bottom: 2%;
  width: 32%;
  background-color: #fff;
  position: relative;
}
.add-on-plan p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 15px;
  margin-bottom: 0px;
}
.add-on-plan p span {
  color: #3064f9;
  cursor: pointer;
}
.add-on-plan h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 18px;
  margin-top: 40px;
}
.add-on-plan-price {
  border-top: 1px solid #dce1eb;
  padding: 18px;
}
.add-on-plan-price p {
  margin-bottom: 0px;
  font-size: 12px;
  margin-left: 5px;
}
.add-on-plan-price .price-discount h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 0px;
}
.add-on-plan-price .price-discount h6 {
  color: #47ce70;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
  font-size: 12px;
}
.add-on-added {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 25px;
  font-weight: 500;
  border: 1px solid #3064f9;
  transition: 0.5s;
  cursor: pointer;
}
.add-on-not-added svg,
.add-on-added svg {
  margin-right: 5px;
}
.add-on-plan-price .price-discount h3 span {
  font-family: "Axiforma-Regular";
  font-size: 14px;
}
.add-on-not-added:hover,
.addpackage:hover,
.add-on-added:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.add-on-not-added {
  background-color: #f5f7ff;
  text-decoration: none;
  border-radius: 4px;
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 25px;
  font-weight: 500;
  border: 1px solid #3064f9;
  transition: 0.5s;
  cursor: pointer;
}

/* Add On Ends */
body {
  position: relative;
  margin: 0;
}

html {
  height: 100%;
  box-sizing: border-box !important;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box !important;
}
.recent-dashboard img {
  width: 70%;
}
.product-image img {
  width: auto;
  padding-right: 5px;
}
.change-website .type {
  color: #7782a1;
  margin-right: 5px;
}
/* Form */
.meta-table .promo-text {
  height: 113px;
  resize: none;
}
.meta-table .form-des {
  height: 330px;
  resize: none;
}
.word-limit p {
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  margin-bottom: 0px;
}
/* Form Ends*/
.update-type h2 {
  width: 80%;
  margin: auto;
}
.maintainance {
  width: 80%;
}

.forfooter {
  position: relative;
  min-height: 100%;
  height: auto;
}

.mobiletoaster {
  padding: 15px;
  text-align: left;
}

.headerdropdown svg {
  width: 16px;
}
.dashboard-content .blog_categories {
  padding: 0px;
}

.takethere {
  width: 160px;
  height: 42px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid #3064f9;
  color: white;
  cursor: pointer;
  background: #3064f9;
  font-family: "Axiforma-Regular";
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}
.altbutspac {
  width: 140px;
}
.dowbutton {
  width: 100%;
  max-width: 320px;
}
.dbutton {
  width: 100%;
  max-width: 100%;
}
.rebutton {
  width: 100%;
  max-width: 100%;
}
.disabledtakethere {
  width: 160px;
  height: 42px;
  display: block !important;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
  line-height: 38px;
  border-radius: 4px;

  background: none;
  border: 1px solid #c8cedb;
  color: #c8cedb;
}

.installedpluginlink:hover {
  background-color: #3064f9;
  color: #fff;
}

.footer {
  position: absolute;
  font-size: 14px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  line-height: 50px;
  height: 50px;
  text-align: center;
}

.innerfoot {
  position: relative;
  z-index: 1;
  height: 60px;

  margin-top: 0px;
}

.signup_left .signup_form .eye-close {
  padding-right: 48px;
}
.transparent_background .right_panel {
  margin-bottom: 10px !important;

  min-height: calc(100% - 100px);
}

/* Verify Account  */
.verify-alert p {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0px;
  padding-left: 8px;
}
.verify-account {
  height: 50px;
  margin-top: -5px;
  margin-bottom: 25px;
  padding: 0px 20px;
  border-radius: 3px;
  background-color: #fffbfb;
  border: 1px solid #ffbfbf;
}
.resend-verify-email .resend-email {
  color: #3064f9;
  cursor: pointer;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  text-decoration: underline;
}
/* Email Page Starts */

.custom-width-right img {
  margin-top: -80px;
}

.css-reu10z-Input div {
  max-width: 350px;
}

.new-hover-element .new-hover-element-text.custom-hover-btn {
  visibility: hidden;
  width: 160px !important;
  background-color: rgba(51, 51, 51);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: -72px !important;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px !important;
  font-family: "Axiforma-Regular" !important;
}
.new-create-app
  .new-hover-element
  .new-hover-element-text.custom-hover-btn::before {
  content: "";
  position: absolute;
  background-color: rgba(51, 51, 51);
  z-index: -2;
  top: 40px;
  left: 70px;
  height: 26px;
  width: 26px;
  box-shadow: -6px 6px 6px 0 rgb(15 25 59 / 5%);
  transform: rotate(46deg);
  -ms-transform: rotate(46deg);
  -webkit-transform: rotate(135deg);
}

.new-hover-element .new-hover-element-text.custom-hover-btn-build {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-height: 22px;
  background-color: #fff;
  color: #7782a1;
  font-size: 10px;
  text-align: center;
  border-radius: 3px;
  padding: 2.5px 9.32px 1px;
  position: absolute;
  z-index: 1;
  top: -17px;
  left: 42px;
  transform: translateX(-50%);
  border: 1px solid #bcc1ce;
}

.new-hover-element
  .new-hover-element-text.custom-hover-btn-build.custom-hover-apps {
  left: 83px;
}

.new-hover-element
  .new-hover-element-text.custom-hover-btn-build.custom-hover-help {
  top: -10px;
  left: 58px;
}

/* Email Page Support */
/* Verify Account Ends */
.footer p {
  color: #bdc0c5 !important;
  font-family: "Axiforma-Regular";
}

.add-notification {
  width: 170px;
}

.tooltip-container {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.loginlogo {
  height: 100px !important;
  width: 214px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.tooltip1 {
  display: none;
  z-index: 1;
  font-family: "Axiforma-Regular";
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  background: rgba(51, 51, 51);
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 250px;
  /* right: -120px; */
  right: -181px;
  bottom: 27px;
}

.beta-spacing {
  right: -120px;
}

.tooltip-container .toolspace {
  width: 298px;
}

.removecart del {
  display: none;
}
.keydone {
  font-size: 14px;
}
.chatleft,
.chatright {
  width: 50%;
}
.tooltip1:before {
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(51, 51, 51);
  position: fixed;
  bottom: -6px;
  /* left: 46%; */
  left: 22%;
}
.tooltip2 + img {
  position: relative;
  top: -3px;
}
.tooltip2:before {
  left: 82%;
}
.not-applicable {
  color: #c8cedb !important;
  display: block;
  font-size: 14px !important;
  margin-top: -25px;
  font-family: "Axiforma-Regular";
}
.not-applicable img {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* App Status Starts */
.app-status {
  padding-top: 5px;
  margin-right: 1px;
  cursor: pointer;
}
.app-status svg {
  margin-top: -5px;
}
.rename-app .guest-login-toggle h6:nth-child(3) {
  color: #3064f9;
  font-family: "Axiforma-Bold";
}
.analytics-app-download {
  width: 50px;
  justify-content: center;
  text-align: center;
}
.my-apps-search button {
  width: 44px;
  background: none;
  border: none;
  height: 100%;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-apps-search input::placeholder {
  color: #c8cedb;
  font: 14px "Axiforma-Regular";
}
.my-apps-search .form-group {
  margin-bottom: 0px;
}
.my-apps-search .search-my-app {
  border: none;
  height: 18px;
  background-color: transparent;
}
.app-version-draft-code {
  color: #c8cedb;
  border: 1px solid #c8cedb;
  font: 12px/15px "Axiforma-Regular";
  padding: 4px 7px;
  float: left;
  margin: auto;
  border-radius: 18px;
  text-align: center;
  width: 94px;
}
#domainname {
  padding-left: 0px !important;
}
.my-apps-search {
  border: 1px solid #c8cedb;
  height: 44px;
  margin-right: 12px;
  padding-left: 10px;
  justify-content: space-between;
  display: flex;
  border-radius: 3px;
  align-items: center;
}
.app-status p {
  color: #c8cedb;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-left: 10px;
  margin-bottom: 0px;
}
.bottom_menu_bar.bottom-menu-topspacing {
  padding-top: 5px;
  padding-bottom: 2px;
}
/* App Status Ends */

.tooltip-container:hover .tooltip1,
a:hover .tooltip1 {
  transform: scale(1);
  display: block;
}

.slide-menu {
  width: 205px;
  background-color: #fff;
  min-height: 588px;
}

.slide-menu li {
  padding-top: 20px;
  color: #000000;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  padding-left: 20px;
}

.slide-menu li:hover {
  cursor: pointer;
}

.slide-menu::-webkit-scrollbar {
  width: 0px !important;
}

.slide-menu .arrow:after {
  content: url("../images/dashboard/rightper.png");
  position: absolute;
  right: 12px;
  transform: rotate(-90deg);
  transition: all 0.5s ease;
}

.slide-menu .arrow.rotate:after {
  content: url("../images/dashboard/perdown.png");
  position: absolute;
  right: 12px;
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.sidenav .sub-slide-menu ul {
  padding-top: 0px;
}

.sub-slide-menu li {
  color: #7581a1;
}

.slide-menu ul {
  padding-left: 0px;
  padding-top: 32px;
  padding-bottom: 25px;
  list-style: none;
  margin: 0px;
  text-align: left;
}
.react-infinity-menu-default-search-input {
  display: none;
}
.infinity-menu-node-container {
  background-color: transparent !important;
  cursor: pointer;
}
.infinity-menu-node-container:hover {
  background-color: transparent !important;
}
.sidenav {
  height: 588px;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: all 0.5s ease;
  border-radius: 20px 0px 0px 24px;
}

.apidescription .media {
  display: inline;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 2px;
  right: 7px;
  color: #000;
  font-size: 25px;
  margin-left: 50px;
  transition: all 0.5s ease;
}

.sidenav .closebtn:hover {
  color: #000;
  opacity: 0.8;
}

.color_input {
  height: 55px;
}

.color_input input {
  padding-left: 0px !important;
}

.css-192das5-InputColor {
  background: none !important;
  border: none !important;
}

.css-gmziuu-InputColor {
  background: none !important;
  height: 55px !important;
}

#clockid {
  word-break: break-all;
}
iframe header {
  display: none;
}
.conecttoapi h2 {
  color: #1b2952;
  font-size: 18px;
  font-weight: 600;
}
.update-type h2 {
  width: 80% !important;
  margin: auto;
}

.conecttoapi h3 {
  color: #1b2952;
  font-size: 16px;
}
/* Maintain */
.maintainance {
  width: 80%;
}
.conecttoapi p {
  font-size: 15px;
  font-family: "Axiforma-Regular";
  width: 80%;
  margin: auto;
  color: #1b2952;
}

.mobleft:before {
  content: url("../images/dashboard/drag.png");
  margin-right: 10px;
  cursor: move;
  margin-top: 4px;
}

.mobleft {
  align-items: center;
}

.dashboard-customizer {
  transition: all 0.5s ease;
}

.install-plugin-steps h4 {
  margin-bottom: 0px;
  font-family: "Axiforma-Bold";
}

.gu-transit {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease;
  transform: scale(1);
}

.green_toggle .dashboard-customizer:hover {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}

.gu-mirror {
  display: none !important;
}

.green_toggle .dashboard-customizer .common-height {
  padding: 0px 15px !important;
}

.conecttoapi a {
  display: block;
  background: #3064f9;
  width: 180px;
  height: 44px;
  color: white;
  border-radius: 4px;
  margin: auto;
  line-height: 42px;
  transition: all 0.5s ease;
  border: 1px solid #3064f9;
  text-transform: uppercase;
}

.conecttoapi a:hover {
  color: #3064f9;
  background: white;
}

.css-192das5-InputColor {
  height: 37px !important;
}

.gettingcolors {
  border: none !important;
  background: none !important;
  height: 100% !important;
}

.gettingcolors span {
  background: none !important;
}

.carouseldot {
  width: 6px;
  cursor: pointer;
  height: 6px;
  border-radius: 50%;
  background: #bcc7e6;
}

.carouseldot.active {
  background: #3064f9;
}

.internet-error {
  position: fixed;
  width: 100%;
  height: 62px;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
  line-height: 55px;
  background: #3064f9;
}

.internet-error p {
  color: white;
}

.backcheck {
  font-family: "Axiforma-Regular";
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.backcolorview {
  width: 36px;
  height: 36px;
  border: 1px solid #c8cedb;
  border-radius: 0.25rem;
  margin-right: 5px;
}

.css-1cxzg2x-ColorPicker,
.css-fp8utj {
  height: 161px !important;
}

.videopart img {
  cursor: pointer;
}
.guest-login-toggle h6 {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 0px;
}
.guest-login-toggle p {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 10px;
}
.viewstatus {
  border-radius: 10px;
  width: 42px !important;
  text-align: center !important;
  height: 18px !important;
  line-height: 18px !important;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.topsectionapp p.viewstatus-responsive {
  width: auto !important;
  padding-left: 11px;
  padding-right: 11px;
}
p.viewstatus-responsive {
  width: auto !important;
  padding-left: 12px;
  padding-right: 12px;
  max-width: fit-content;
}
.payment-status-responsive {
  width: 100px;
}
.other-settings .common-height {
  height: 120px;
  border-bottom: 1px solid #f1f5fc;
  align-items: center;
}

.notconnected {
  color: #ff4848;
}

.successinstalled {
  color: #01c99b;
}

.pluginh2 h2 {
  font-size: 20px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}

.pluginh2 p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}

.installpluginlink {
  width: 160px;
  height: 45px;
  background: #bcc7e6;
  display: block;
  color: white;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
  line-height: 45px;
  border-radius: 4px;
}

.installedpluginlink {
  width: 160px;
  height: 45px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #3064f9;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
  line-height: 43px;
  border-radius: 4px;
  border: 1px solid #3064f9;
  font-family: "Axiforma-Regular";
}

.bill-pay-top {
  border-top: 1px solid #dce1eb;
}

.new-menu-border {
  border: none;
}

.installpluginlink:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
  color: #fff;
}

.backcheck p {
  color: #1b2952;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
  text-align: left;
}

/* .dashboardheader Starts*/

.dashboardheader {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
  margin-bottom: 4px;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.dashboardheader .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  height: 60px;
}

.logo {
  width: 175px;
}

.profile_icon .dropdown {
  outline: none;
}

.profile_icon .dropdown-toggle::after {
  display: none;
}

.profile_icon .dropdown .btn {
  padding: 0px;
}

.profile_icon .dropdown .btn:focus {
  box-shadow: none !important;
}

.dropdown-menu {
  border: none;
  box-shadow: 0px 0px 12px rgb(188 193 206 / 36%);
  border-radius: 7px;
}

.dropdown-item a,
.logoutt {
  padding: 15px 25px 15px 0px !important;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
}

.addcartbtn .addtocartbtn {
  position: sticky;
  margin-top: 0px !important;
}
.dropdown-item {
  border-left: 2px solid #fff !important;
}
.dropdown-item:hover {
  border-color: #3064f9 !important;
  background-color: white;
  padding-left: 21px;
  color: white;
}

.perhead:after {
  content: url("../images/dashboard/rightper.png");
  position: absolute;
  right: 12px;
  transform: rotate(-90deg);
  transition: all 0.5s ease;
}

.perhead.rotate:after {
  content: url("../images/dashboard/perdown.png");
  position: absolute;
  right: 12px;
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.yamm_tabs {
  cursor: pointer;
}
.dashboardheader.sticky-header {
  transition: top 3s ease 2s;
  top: 0;
  position: fixed;
}

/* .dashboardheader Ends*/
/* Common CSS Starts*/

.white-mobile-border::-webkit-scrollbar {
  width: 0px !important;
}

.white-mobile-border::-webkit-scrollbar-track {
  background-color: rgba(48, 100, 249, 0.2) !important;
  border-radius: 9px !important;
}

.white-mobile-border::-webkit-scrollbar-thumb {
  background-color: #3064f9 !important;
  border-radius: 9px !important;
}

::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-track {
  /* background-color: rgba(48, 100, 249, 0.2) !important; */
  border-radius: 9px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #bcc1ce;
  border-radius: 9px !important;
}

.dasboard_page {
  background-color: #fcfdff;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.deals-page {
  background-color: #f8fafe !important;
}
.dasboard_page.mobile-display {
  /* max-width: 1920px; */
  margin: 0 auto;
}

.dasboard_page.consistent-email-color {
  background-color: #fff;
}
.dasboard_page.consistent-email-color.link-old {
  background-color: #f8fafe;
}
.left_panel {
  width: 200px;
  background: white;
  box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
  position: fixed;
  height: 100%;
  margin-top: 60px;
  z-index: 1;
}
/* Faisal */
/* .left_panel{width: 200px;} */
/* New dasboard end */
.left_panel.left-panel-responsive {
  margin-top: -57px;
}

.custom-pages {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.left_panel .app_heading {
  padding: 20px 4px 20px 0px;
}

.linkmain {
  padding-right: 14px;
}

.linkspace {
  max-width: 100%;
  min-height: 79px;
}

.linkstyles {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.app_icon {
  border-bottom: 2px solid #f1f5fd;
  min-height: 128px;
  margin-left: 15px;
}

.pricing-tabs {
  width: calc(100% - 777px);
  float: right;
}
/* Dashboard Page Scroll Down Starts */
.click-to-scroll {
  position: sticky;
  bottom: 39px;
  left: 0px;
  right: 0px;
  cursor: pointer;
}
/* Dashboard Page Scroll Ends */
.app_iconname .media {
  display: none;
}

.app_iconname h3 {
  font: 20px "Axiforma-Bold";
  margin-bottom: 0px;
  color: #1b2952;
}

.createnotif {
  padding-bottom: 16px;
}

.createnotif .app_iconname h5.altsub {
  padding-top: 1px;
}

.app_iconname h5 {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 3px;
  color: #7782a1;
}
.billing-head-flex h1,
.invoice-head {
  color: #1b2952 !important;
  font-size: 20px !important;
  font-family: "Axiforma-Bold";
  margin-bottom: 8px;
  margin-top: 0px;
}

.app_iconname h5 a {
  color: #3064f9;
  text-decoration: none;
}

.web_logo {
  margin-left: 20px;
  margin-top: 0px;
}

.common-height {
  height: 40px;
}

.yamm_tabs .panel-body ul {
  display: block;
  margin-left: 32px;
  list-style: none;
}

.yamm_tabs .panel-body ul li a {
  color: black;
  font-size: 13px;
  text-decoration: none;
  font-family: "Axiforma-Regular";
  display: flex;
  align-items: center;
}

.yamm_tabs {
  margin-top: 20px;
}

.transparent_background {
  background-color: #f8fafe;
  padding: 20px 30px 30px 30px;
  width: calc(100% - 200px);
  margin-left: 200px;
  margin-top: 60px !important;
  position: relative;
}

#root .transparent_background {
  margin-top: 60px !important;
}

/* Faisal */
/* .transparent_background{ width: calc(100% - 200px);
  margin-left: 200px;} */

.transparent_background.transparent-background-responsive {
  margin-top: 0;
}

.transparent_background.dealselect_background {
  margin: 5% 0 0 0;
}
.container-xlg {
  max-width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.right_panel {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(36, 116, 249, 0.15);
  height: auto;
  min-height: 100%;
  padding: 30px;
  margin-bottom: 12px;
  padding-top: 0px;
  position: relative;
}

.errorspace {
  padding-top: 1px;
}

.app_heading h2 {
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px !important;
  word-break: break-word;
  line-height: 20px;
}

.spinn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 200px;
  opacity: 0.5;
}
.newspinn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 200px;
  opacity: 0.5;
}
.newspinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0px;
  opacity: 1;
}

.spinnmyapp {
  position: fixed !important;
}
.adminlogin-bold-text{
font-size: 40px;
}

.app_heading p {
  color: #7782a1;
  font-size: 12px;
}

.color_picker_full .color_input {
  background-color: #fff;
  height: 55px;
  padding: 5px;
  border: 1px solid rgba(188, 199, 230, 1);
  border-radius: 0.25rem;
}
.color_input p {
  font-size: 14px;
}
.customiseapp {
  width: 450px;
  position: fixed;
  right: 40px;
  height: 82%;
}

.customiseappfeatures {
  width: calc(100% - 450px) !important;
  padding-right: 0px;
  padding-top: 24px;
}
.customiseappfeatures .common-height {
  height: 70px;
}
.apptab {
  padding-top: 0;
}

.two-btn-tabs .createtabs li a {
  text-decoration: none;
  color: #7782a1;
  width: 100%;
  display: block;
  border-radius: 4px;
  font-family: "Axiforma-Bold";
  border: none !important;
}

.two-btn-tabs .createtabs li {
  width: 45%;
  font-size: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 4px;
  color: #3064f94d;
  margin: 5px;
  font-family: "Axiforma-Bold";
}
.two-btn-tabs .createtabs li {
  margin: 0;
  margin-right: 5px;
}
.two-btn-tabs .createtabs {
  border-radius: 30px;
  width: 225px;
  margin: auto;
}

.product-delete svg {
  width: 46px;
}

.createtabs li .active {
  color: #547df2 !important;
  padding-bottom: 12px;
  border-radius: 0 !important;
  border-bottom: 2px solid #547df2 !important;
  /* box-shadow: 0px 3px 6px 0px rgba(84, 125, 242, 0.5); */
  width: 100%;
  display: block;
  border-radius: 4px;
  /* border: none!important; */
}

.large-up .up {
  min-height: 300px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.large-up label {
  width: 100%;
}

.defaultimage {
  width: 270px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 24px;
}

a.changeimage.media {
  background: #fff;
}

.Toaster__alert {
  padding: 0px !important;
}

.small-up label input {
  visibility: hidden;
}

.small-up .up {
  border: 1px dashed #bcc7e6;
  min-height: 55px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.small-up label {
  width: 100%;
  background: #fff;
}

.next_btn {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  font-weight: 500;
  border: 1px solid #3064f9;
  line-height: 15px;
  text-transform: capitalize;
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
  &:hover {
    color: var(--custom_color4)!important;
    background-color: var(--custom_color5)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}

.profile-detail-btn .next_btn {
  width: 250px;
  height: 50px;
}

.next_btn:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
  color: #fff;
  transition: 0.5s;
}

.back_btn {
  background-color: #fff;
  text-decoration: none;
  border-radius: 4px;
  color: #bcc7e6;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  padding: 10px 22px;
  font-weight: 500;
  border: 1px solid #bcc7e6;
  line-height: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.publish_app_btn {
  width: 171px;
}

.del-btn img {
  margin-bottom: 8px;
}

.back_btn:hover {
  color: #7782a1;
  text-decoration: none;
  background: transparent;
  border: 1px solid #7782a1;
}

.two-btn-tabs .promotertabs li a {
  text-decoration: none;
  color: #bcc7e6;
  width: 100%;
  display: block;
  text-align: center;
  border-top: 2px solid #c8cedb;
  cursor: pointer;
}

.platswitch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -135px;
}

.altswitch {
  bottom: -70px;
}

.newswitch {
  bottom: -75px;
}

.push-notify .mobile_img.notimobimg:before {
  height: 92%;
}

.push-notify .mobile_img_android.notimobimg:before {
  height: 90%;
}

#myTabs .tab_mob.notifictab {
  border-bottom: 0;
  border-top: 2px solid #c8cedb;
}

.two-btn-tabs .promotertabs li {
  width: 50%;
  font-size: 16px;
  background-color: white;
  line-height: 40px;
  color: #3064f94d;
  font-family: "Axiforma-Regular";
}

.two-btn-tabs .promotertabs {
  border-radius: 30px;
  width: 275px;
  margin: auto;
  padding-top: 23px;
}

.promotertabs li .active {
  color: #547df2 !important;
  width: 100%;
  display: block;
  border-top: 2px solid #3064f9 !important;
}

.tabwrapper {
  position: relative;
}

.mobile_bg {
  position: relative;
  width: 300px;
  height: 514px;
  margin: auto;
}

.small_mobile_img {
  width: 69%;
  position: absolute;
  text-align: center;
  right: 0px;
  left: 0px;
  top: 95px;
  margin: auto;
}
.billing-page-mobile .pannel_title h4,
.mobile-view-password .pannel_title h4,
.mobile-view-profile .pannel_title h4 {
  font-size: 14px !important;
  margin-top: 8px;
}
.pannel_title h4 {
  text-decoration: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #000;
  cursor: pointer;
}
.second-left-panel .panel-title {
  margin-top: 15px !important;
}
.pannel_title {
  margin-left: 10px;
  margin-top: 0px;
}
.upload_files h4 {
  font-family: "Axiforma-Regular";
  font-size: 15px;
  color: #1b2952;
  position: relative;
}
input::placeholder {
  font-family: "Axiforma-Regular2" !important;
  font-size: 14px;
}
.greentick:last-child h4:after {
  content: none;
}

.form-control {
  padding-left: 1.25rem !important;
}

.upload_files {
  padding: 20px 25px 30px 25px;
  background-color: rgba(241, 245, 253, 0.5);
  border-radius: 5px;
}

.upload_files h5 {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #bcc7e6;
}

.website {
  position: relative;
}

.website a {
  position: absolute;
  width: 100%;
  height: 100%;
}

.peronalize {
  position: relative;
}

.peronalize a {
  position: absolute;
  width: 100%;
  height: 100%;
}

.peronalizeme {
  position: relative;
}

.peronalizeme a {
  position: absolute;
  width: 100%;
  height: 100%;
}

.setting {
  position: relative;
}

.setting a {
  position: absolute;
  width: 100%;
  height: 100%;
}

.download {
  position: relative;
}

.download a {
  position: absolute;
  width: 100%;
  height: 100%;
}

.publish {
  position: relative;
}
.delete-app-now p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 0px;
}
.delete-app-now {
  border: 1px solid #c8cedb;
  border-radius: 4px;
  height: 40px;
  padding: 0px 15px;
  cursor: pointer;
}
.delete-app-now:hover {
  border-color: #7782a1;
}
.publish a {
  position: absolute;
  width: 100%;
  height: 100%;
}
/* Download App */

.build-history-tab ul li.notactive {
  padding: 20px 50px;
  color: #7782a1;
  font: 16px "Axiforma-Bold";
  border-bottom: 2px solid #fff;
  cursor: pointer;
}
.build-history-tab ul li.active {
  padding: 20px 50px;
  color: #1b2952;
  font: 16px "Axiforma-Bold";
  border-bottom: 2px solid #3064f9;
  cursor: pointer;
}
.build-history-tab ul li:hover {
  border-bottom: 2px solid #3064f9;
  color: #1b2952;
}
.build-history-tab ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.app-versions .dashboard-version-page.table-top p {
  color: #7782a1 !important;
}
.app-versions .dashboard-version-page.table-top {
  border-top: 1px solid #dce1eb;
  border-bottom: 1px solid #dce1eb;
}
.app-versions .dashboard-version-page h4 {
  font: 14px/21px "Axiforma-Regular";
  color: #1b2952;
  word-break: break-all;
}
.app-versions .dashboard-version-page .subscription-type p {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}
.app-versions .dashboard-version-page .subscription-type {
  width: 170px;
}
.app-versions .dashboard-version-page .bundle-id {
  width: 130px;
  word-break: break-all;
  font-size: 14px;
}
.app-versions .dashboard-version-page .last-payment {
  width: 140px;
}
.app-versions .dashboard-version-page .next-payment {
  width: 140px;
}
.app-versions .dashboard-version-page .payment-method {
  width: 140px;
}
.app-versions .dashboard-version-page .payment-status {
  text-align: left;
  float: none;
}
.app-versions .dashboard-version-page .payment-status p {
  width: 100px;
}
.app-versions .dashboard-version-page .payment-status p.green-active.working,
.app-versions
  .dashboard-version-page
  .payment-status
  p.app-version-draft.notworking,
.app-versions
  .dashboard-version-page
  .payment-status
  p.app-version-draft-code.notworking {
  width: auto;
}
.app-versions .dashboard-version-page .block6 {
  width: 100px;
}
/* Download App */

.color_input input {
  font-family: "Axiforma-Regular";
  border: 1px solid rgb(240, 242, 247);
  height: 60px;
  border-radius: 0.25rem;
  width: 100%;
  padding-left: 18px;
  width: 92px;
  height: 45px;
}

.color_picker .color_input {
  background-color: #fff;
  padding: 5px;
  border: 1px solid rgba(188, 199, 230, 1);
  border-radius: 0.25rem;
}

.customwidth .color_input {
  background-color: #fff;
  padding: 5px;
  border: 1px solid rgba(188, 199, 230, 1);
  border-radius: 0.25rem;
}

.form-control {
  border: 1px solid rgba(188, 199, 230, 1);
}

.color_picker select,
.color_picker_full select {
  font-family: "Axiforma-Regular";
  border: 1px solid rgba(188, 199, 230, 1);
  height: 60px;
  border-radius: 0.25rem;
  width: 100%;
  padding-left: 18px;
}

.jscolor:focus {
  color: #495057;
  background-color: #f1f5fd;
  border-color: #ccc;
  outline: 0;
  box-shadow: none !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
  border: 1px solid rgba(188, 199, 230, 1);
}

.color_picker .form-name {
  height: 55px;
}

.color_picker {
  width: 49%;
  position: relative;
}

.color_picker_full {
  width: 100%;
}

.upload_files .color_picker .form-control,
.color_picker_full .form-control {
  opacity: 1;
}

.color_picker .form-group,
.color_picker_full .form-group {
  margin-bottom: 0px;
}
.store-url a {
  font-family: "Axiforma-Regular";
}
.panel-body ul li {
  margin-bottom: 14px !important;
}

.webpages .common-height {
  padding-left: 15px;
  padding-right: 15px;
}

.webpages .col-md-12 {
  padding: 0px;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs li {
  cursor: pointer;
}

.custom-page-head {
  padding: 0px 15px;
  min-height: 50px;
  background-color: #f1f5fe;
}
.app-versions .app-history {
  width: 100px;
}
.app-versions .app-history select {
  background: url(../images/signup/down.png) 90%/10% no-repeat;
  -webkit-appearance: none;
  font: 14px "Axiforma-Regular2";
  border: 1px solid rgba(188, 199, 230, 1);
  border-radius: 4px;
  height: 35px;
  width: 100%;
  padding-left: 12px;
  outline: none !important;
}
.form-group select {
  background: white url(../images/dashboard/dark-grey.png);
  background-repeat: no-repeat;
  background-position: 96%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular2";
  border: 1px solid #e3e6f1;
  height: 55px;
  border-radius: 0.25rem;
  width: 100%;
  padding-left: 12px;
  outline: none !important;
  font-size: 14px;
}

.small-up label {
  width: 100%;
  background: url(../images/dashboard/small-up.png) rgb(255, 255, 255);
  background-position: 1% center;
  background-repeat: no-repeat;
}

.small-up .uploadicon {
  background-position: 1% !important;
  background-size: 75px !important;
}

.not-exist h1 {
  font-family: "Axiforma-Bold";
  font-size: 48px;
  color: #1b2952;
}
.errorpage {
  height: 100vh;
}
.not-exist p {
  font-family: "Axiforma-Regular";
  font-size: 18px;
  color: #7782a1;
  margin-bottom: 65px;
  width: 80%;
  text-align: left;
  margin-top: 25px;
}

.dontshow {
  display: none !important;
}

.switch {
  display: inline-block;
  height: 22px;
  position: relative;
  width: 44px;
  margin-bottom: 0px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #D7DBEB;
  background-image: url(../images/dashboard/togglecross.png);
  background-size: 7.5px 7.5px;
  background-repeat: no-repeat;
  background-position: bottom 7px right 10px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 3px;
  content: "";
  height: 16px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
}

input:checked + .slider {
  background-color: #678EFF;
  background-image: url(../images/dashboard/tick-toggle.png);
  background-size: 13px 9px;
  background-repeat: no-repeat;
  background-position: bottom 5.5px left 9px;
}

input:checked + .slider:before {
  transform: translateX(22px);
  background-color: #fff;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.green_toggle label {
  padding: 12px 26px;
}

.large-up-back label {
  width: 100%;
}

.large-up-back .selectpixabayimage {
  background-position: center !important;
  height: 43px;
  width: 43px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 5px;
  border-radius: 4px;
}

.login-signup .right_panel {
  margin-bottom: 28px;
}

.hollow_phone .splashlogo.logo-alignment-vertical {
  height: 100%;
  padding: 30px 0px;
}

.large-up-back .up {
  border: 1px dashed #bcc7e6;
  min-height: 55px;
  border-radius: 5px;
  padding-right: 15px;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: white;
  overflow: hidden;
}

.large-up-back label input {
  visibility: hidden;
}
.large-up-back .upload-long-text {
  max-width: 81%;
  overflow: hidden;
}

.color_input {
  height: 55px;
}

.color_input input {
  padding-left: 0px !important;
}

.css-192das5-InputColor {
  background: none !important;
  border: none !important;
}

.css-gmziuu-InputColor {
  background: none !important;
  height: 55px !important;
}

.css-192das5-InputColor {
  height: 37px !important;
}

.gettingcolors {
  border: none !important;
  background: none !important;
  height: 100% !important;
}

.gettingcolors span {
  background: none !important;
}

.backcheck {
  font-family: "Axiforma-Regular";
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.backcolorview {
  width: 36px;
  height: 36px;
  border: 1px solid #c8cedb;
  border-radius: 0.25rem;
  margin-right: 5px;
  margin-left: 4px;
}

.css-1cxzg2x-ColorPicker,
.css-fp8utj {
  height: 161px !important;
}

.backcheck p {
  color: #1b2952;
  font-size: 16px;
}

.copyright_section {
  background-color: #3064f9;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.copyright_section .container {
  margin-top: 0px !important;
}

.copyright_section p {
  margin-bottom: 0px;
  color: #fff;
}

.footer-links a {
  color: #fff;
  transition: all 0.5s ease;
  margin-left: 25px;
}

.footer-links a:hover {
  opacity: 0.8;
  color: #fff;
  transition: all 0.5s ease;
}
/* common css end */
/* General Page Starts*/

.premade_icon {
  width: 25%;
  margin-top: 138px;
}

.create_own .yf {
  font-size: 9px;
  color: #333;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 4px;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icontextspace {
  margin-right: 10px;
}

.create_own {
  width: 45px;
  height: 45px;
  margin: auto;
  border-radius: 8px;
  background-color: #ffffff8f;
  position: relative;
  display: block !important;
}

.create_own #applogo {
  width: 38px;
  height: 38px;
  margin: auto;
  border-radius: 8px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  overflow: hidden;
}

.form-group h6,
.color_picker h6 {
  color: #c8cedb;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.two-banner {
  height: 100%;
}

.large-up label input {
  visibility: hidden;
  border: 1px dashed #c8cedb;
}
/* General Page Ends*/
/*Login & Signup Starts*/

.hollow_phone {
  background-image: url(../images/dashboard/phoneback.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 300px;
  height: 575px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.hollow_phone .splashlogo {
  background: transparent;
  overflow: hidden;
  width: 85px;
  height: 85px;
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.completesteps h4 {
  font-family: "Axiforma-Bold";
  color: #1b2952;
  font-size: 24px;

  margin: auto;
}

.completesteps {
  text-align: center;
}

.completesteps img {
  margin-top: 65px;
  margin-bottom: 65px;
}

.completesteps p {
  color: #1b2952;
  font-size: 15px;
  font-family: "Axiforma-Regular";

  margin: auto;
  margin-bottom: 30px;
}
.buttonbox {
  background-color: #3064f9;
  border-radius: 4px;
  width: 210px;
  margin: auto;
  height: 48px;
  line-height: 48px;
  margin-bottom: 30px;
}

.buttonbox p {
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-transform: uppercase;
  cursor: pointer;
}
#clock {
  background-size: contain;
  background-position: center;
  height: 125px;
  width: 125px;
  margin: auto;
  z-index: 1;
  left: 0px;
  top: 62px;
  right: 0px;
  position: absolute;
  background-repeat: no-repeat;
  word-break: break-all;
}

.signup-form {
  padding: 1px;
  position: absolute;
  width: 91%;
  bottom: 20px;
}

.cart-items {
  padding-right: 8px;
  padding-top: 8px;
  padding-left: 5px;
  padding-bottom: 6px;
  border-bottom: 1px solid #e6ebfb;
  background-color: #fff;
}

.app-dash-analytics .two-apps {
  margin-top: 25px;
  padding: 40px 30px 40px 25px;
}

.product-name {
  width: 55%;
}

.product-delete {
  width: 20%;
  text-align: right;
}

.product-image {
  width: 25%;
}

.cart-bottom input {
  border: none;
  background-color: transparent !important;
  height: auto;
  padding: 0px !important;
}

.cart-bottom input::placeholder {
  font-size: 12px;
  color: #bbc3db;
}

.applycoupon {
  color: #3064f9 !important;
  font-size: 14px !important;
}

.cart-items h5 {
  color: #333333;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 1px;
}

.woo-below-content {
  font-size: 15px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}

.cart-items .price {
  color: #ff3d3d;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}

.white-mobile-border {
  overflow: hidden;
  width: 234px;
  height: 507px;
  border-radius: 23px;
  position: relative;
  left: 0px;
  right: 0px;
  top: 34px;
  padding: 20px 10px;
  margin: auto;
  display: flex;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.typeselect {
  padding-right: 26px;
}

.modal-header.croppopup {
  height: 65px;
  background: white;
  border-bottom: 1px solid #c8cedb !important;
  border-radius: 4px 4px 0px 0px;
  padding: 15px 10px !important;
}

.total p {
  font-size: 14px !important;
  font-weight: 600;
}

.croppopup p {
  color: #1b2952;
  font-size: 20px;
  padding: 0px;
  margin: 0px;
}

.cropbody {
  width: 100%;
  background: #f8fafe;
  border-bottom: 1px solid #c8cedb !important;
  height: 400px;
}

.customwidth {
  width: calc(50% - 70px) !important;
  height: 80px;
  position: relative;
}

.customwidth .color_input {
  background-color: #fff;
  padding: 5px;
  border-radius: 4px;
}

.customwidthor {
  width: 100px;
  margin-top: 40px;
  text-align: center;
}

.customwidthor p {
  border-radius: 3px;
  cursor: pointer;
  width: 55px;
  height: 52px;
  justify-content: center;
  margin: auto;
  display: flex;
  align-items: center;
  background: #fff;
  color: #c8cedb;
}
.customwidthor p.active_tab {
  color: #3064f9;
  border: 1px solid #3064f9;
}
.customwidthor p.or_not_active_tab {
  color: #c8cedb;
  border: 1px solid #c8cedb;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
}
.green_checkmark .container1 {
  position: absolute;
  right: 5px;
  top: 49px;
}
.green_checkmark .container1 input:checked ~ .checkmark {
  background-color: #47ce70;
}
.green_checkmark .container1 .checkmark:after {
  border: solid #fff;
  border-width: 0 3px 3px 0 !important;
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
}
.green_checkmark .container1 .checkmark {
  border-radius: 100%;
  width: 18px;
  height: 18px;
}
.customwidthor p.not_active_tab {
  color: #c8cedb;
  border: 1px solid #c8cedb;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
}

.on-off h5 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 0px;
}

.on-off p {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0px;
  margin-right: 15px;
}

.logindesignicon {
  width: 20px;
  margin-right: 10px;
  text-align: left;
}

.logindesigninput {
  border-bottom: 1px solid #b1b1b1;
}

.signupdesigninput p {
  color: #b1b1b1;
  font-size: 11px;
  text-align: left;
  margin-bottom: 5px;
}

.signupdesigninput {
  width: 100% !important;
  border-bottom: 1px solid #b1b1b1;
}

.logindesigninput input {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #b1b1b1;
  outline: none;
}

.logindesigninput p {
  color: #b1b1b1;
  font-size: 11px;
  text-align: left;
  margin-bottom: 0px;
}

.logindesigninput h6 {
  font-size: 12px;
  color: #fff;
  margin-bottom: 0px;
}
.logingradientclass {
  background-color: #fff !important;
}
.logindesignotherinput p {
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.logindesignbutton {
  background-color: #fff;
  border-radius: 3px;
  width: 100%;
  height: 35px;
}

.loginsocialbtn {
  background-color: rgba(177, 177, 177, 0.4);
  width: 48%;
  padding: 4px 6px 6px 6px;
  border-radius: 4px;
  height: 38px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginotheroptions p span {
  color: #b1b1b1;
  font-size: 11px;
}

.loginotheroptions p {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0px;
}

.loginotheroptions h4 span {
  color: #b1b1b1;
  font-size: 10px;
}

.loginotheroptions h4 {
  color: #fff;
  font-size: 11px;
  margin-bottom: 0px;
}

.logindesignbutton h5 {
  color: #000;
  text-align: center;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 35px;
  margin-bottom: 0px;
}

.logindesignbutton h5:hover {
  font-size: 13px !important;
}

.forgotscreen h6 {
  color: #ffffff;
  font-size: 13px;
}

.forgotscreen p {
  color: #b1b1b1;
  font-size: 11px;
}
/*Login & Signup Ends*/
/*Splash Page Starts*/

.backselect:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background: rgba(0, 0, 0, 0.4)*/
}

/* Homescreen */
.backselect0:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background: rgba(0, 0, 0, 0.4)*/
}
.backselect1:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background: rgba(0, 0, 0, 0.4)*/
}
.backselect2:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background: rgba(0, 0, 0, 0.4)*/
}
.backselect3:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background: rgba(0, 0, 0, 0.4)*/
}
/* Homescreen */

.overlay-text {
  position: absolute;
  background-color: rgba(246, 249, 255, 1);
  width: 237px;
  height: 509px;
  top: 33px;
  margin: auto;
  border-radius: 24px;
  left: 0px;
  right: 0px;
  z-index: 9;
}

.overlay-text p {
  color: #7782a1;
  font-size: 14px;
}

.mobile-head {
  position: absolute;
  z-index: 8;
  top: 28px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 230px;
}

.app_iconname h4 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  color: #1b2952;
}

.alignment h5 {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  color: #7782a1;
}

.alignment svg {
  cursor: pointer;
}

/*.customwidth {
	width: calc(50% - 50px)!important;
	height: 80px;
}

.customwidth .color_input {
	background-color: #fff;
	padding: 5px;
	border-radius: 4px;
}

.customwidthor {
	width: 100px;
	height: 20px;
	text-align: center;
}*/

/*Splash Page Ends*/

.logindesignicon svg {
  margin-top: -10px;
}
/*pixabay modal*/

.modal-dialog {
  max-width: 80%;
  max-height: 775px;
  margin: auto;
}

.modal-xxl-content {
  top: 100px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #c8cedb4d !important;
  width: 100%;
}

.previewbanner {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 130px;
  margin: auto;
  padding: 0px 5px;
}

.modal-xxl-content .search-box .form-control {
  border: none;
  height: 42px !important;
}

.modal-xxl-content .form-group {
  margin-bottom: 0px;
}

.search-box {
  border: 1px solid #c8cedb;
  width: calc(100% - 330px);
  height: 44px;
  border-radius: 3px;
}

.search-box .btn:focus {
  box-shadow: none;
}

.search-box button {
  width: 60px;
  background: none;
  border: none;
  height: 100%;
  margin-top: -5px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.search-box input {
  width: calc(100% - 60px);
}

.search-box input::placeholder {
  color: #c8cedb;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.modal-xxl-content h4 {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.modaltext {
  width: 170px;
}

.pricing-tabs .two-btn-tabs .createtabs li {
  line-height: 40px;
}

.next_btn.test-app-on {
  width: 212px;
}

.next_btn.test-app-on:hover {
  color: #fff;
}

.upload-image {
  border: 1px solid #3064f9;
  background-color: #f5f7ff;
  border-radius: 4px;
  width: 160px;
  height: 44px;
  line-height: 44px;
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}

[dataclass*="addonpopup"] .modal-dialog {
  margin-top: 40px !important;
}

.chosse-image {
  border-bottom: 1px solid #c8cedb4d;
  background-color: #fff;
  width: 100px;
}

.modal-title {
  width: 100%;
}

.modal-header,
.modal-footer {
  border: none !important;
}

.modal-header.splash-modal {
  padding: 0px 10px 0px 14px !important;
}

.modal-body-content {
  height: 590px;
  max-height: 700px;
  width: 100%;
  background-color: #f5f8fe;
  border: none;
  overflow-x: hidden;
  padding-left: 22px;
  padding-top: 20px;
  border-top: 1px solid #c8cedb4d;
  border-bottom: 1px solid #c8cedb4d;
  -webkit-overflow-scrolling: touch;
}

.footer-button .modal-next {
  width: 100px !important;
}

.footer-button {
  background-color: #fff;
}

.modal-body-content .first_app {
  margin-right: 10px;
  margin-bottom: 10px;
}

.close {
  display: none;
}

.app_dummy {
  width: 13.3%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  margin: 7px;
}

.showapp {
  background-color: #cad5f5;
  border-radius: 4px;
  border-radius: 15px;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.app_dummy label {
  cursor: pointer;
}

.app_dummy input:checked ~ span {
  display: block;
  transition: all 0.5s ease;
}

.showapp p {
  margin-bottom: 0px;
  font-size: 50px;
  text-transform: uppercase;
  color: #e9efff;
  letter-spacing: 5px;
  font-family: "Axiforma-Bold";
}

.activestock {
  border: 3px solid #3064f9;
  border-radius: 4px;
  background: rgba(90, 56, 29, 30%);
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
}

.activestock:after {
  content: url(../images/dashboard/tick.png);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.changeicon {
  background: #4d7afb;
  border-radius: 4px;
  height: 100px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.changeicon h2 {
  color: white;
  font-size: 20px;
}

.changeicon p {
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.createtext {
  width: calc(100% - 120px);
}

.hideparent {
  position: relative;
}

.hidesection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(241, 241, 241, 0.4);
  z-index: 9;
}

.buyappaddon {
  width: 120px;
  height: 40px;
  background: white;
  border-radius: 4px;
}

.buyappaddon a {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #3064f9;
  line-height: 40px;
  transition: all 0.5s ease;
}
.datashow li {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  color: #7782a1;
  line-height: 28px;
}

/*email support*/

.helpheaderdropdown {
  transform: translate3d(-190px, 45px, 0px) !important;
  width: 260px;
  border: none !important;
  border-radius: 4px;
  padding: 0px !important;
  left: 62px !important;
}

.headerdropdown {
  transform: translate3d(-190px, 45px, 0px) !important;
  width: 240px;
  border: none !important;
  border-radius: 4px;
  padding: 0px !important;

  /* height: 100vh;
  overflow: scroll; */
}

.support-tab p {
  margin-bottom: 0px;
}

.support-tab {
  padding: 15px 0px 15px 20px;
  color: #fff;
  background-color: #3064f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 16px;
  font-weight: bold;
}

.helpheaderdropdown .dropdown-item a,
.headerdropdown .dropdown-item a {
  padding: 10px 0px 10px 0px !important;
}

.helpheaderdropdown .dropdown-item:hover,
.headerdropdown .dropdown-item:hover {
  padding-left: 20px;
  background-color: #fff;
}

.helpheaderdropdown .dropdown-item a:hover,
.headerdropdown .dropdown-item a:hover {
  color: #7782a1;
}

.headerdropdown .dropdown-item:first-child:hover {
  border-left: 0px;
}

.helpheaderdropdown::before {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #3064f9 transparent;
  position: absolute;
  z-index: 99;
  top: -10px;
  right: 110px;
  margin: auto;
  transform: rotate(0deg);
}

.headerdropdown .profilecontent h4 {
  color: #3064f9;
}

.headerdropdown::before {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  z-index: 99;
  top: -10px;
  right: 22px;
  margin: auto;
  transform: rotate(0deg);
}

.preview-red-cross {
  display: none;
}
/*email support end*/

.previewheight {
  min-height: auto;
}
.productsdetailpg video,
.productsdetailpg iframe {
  width: 100% !important;
}
.troublshootinglink,
.troublshootinglink:hover {
  color: #3064f9;
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-family: "Axiforma-Regular";
  background: none !important;
  border: none !important;
  text-transform: capitalize !important;
}
.response-result {
  width: 200px;
}
li.test-message {
  padding-right: 15px !important;
}
.two-apps h4 span {
  color: #7782a1;
  font-size: 15px;
  font-family: "Axiforma-Regular";
  position: relative;
  top: -2px;
}
.for-date select {
  width: auto !important;
  height: auto !important;
}
.left_scheduled {
  width: 200px;
}
.right_scheduled {
  width: calc(100% - 200px);
}
/* Admob */

.ad_position_child:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ad_position_child {
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #c8cedb;
  width: 50%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad_position_child.active {
  border: 1px solid #3064f9;
}
.ad_position_child:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ad_position_child p {
  font: 16px "Axiforma-Regular";
  color: #c8cedb;
  margin-bottom: 0px;
}
.ad_position_child p.active {
  color: #3064f9;
}

.ad_mo {
  cursor: pointer;
}
.product_list {
  width: 48%;
  margin-bottom: 10px;
}
.prod_detail h6 {
  text-align: left;
  font: 12px "Axiforma-Bold";
  color: #333333;
  margin-bottom: 0px;
  margin-top: 2px;
}
.prod_detail p {
  text-align: left;
  margin: 3px 0px;
  font: 10px "Axiforma-Bold";
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
}
.prod_detail p.instock {
  background-color: #edfaef;
  border-radius: 2px;
  color: #58d16c;
  font: 7px "Axiforma-Regular";
  padding: 4px 7px;
  margin-top: 0px;
}
.prod_detail p.product_des {
  color: #333;
  font: 9px "Axiforma-Regular";
}
.prod_detail p.sku_des {
  color: #a1a1a1;
  font: 9px "Axiforma-Regular";
}
.prod_detail .add_cart,
.checkout_btn {
  background-color: #3064f9;
  border-radius: 4px;
  padding: 8px;
}
.cartt .cart-page img {
  width: 30%;
}
.cart-page h5 {
  text-align: left;
  font: 8px "Axiforma-Regular";
  color: #a1a1a1;
  margin-bottom: 0px;
}
.point-sec {
  background-color: #fafafa;
  padding: 10px 8px;
  margin-top: 10px;
}
.mono .menu-icon p {
  margin-top: 7px;
}
.point-sec p {
  font: 8px "Axiforma-Regular";
  color: #a1a1a1;
  margin: 0px;
}
.cartt .cart-page {
  padding: 0px 9px;
}
.checkout_btn {
  width: 94%;
  margin: auto;
}
.cart-page h6 {
  text-align: left;
  font: 9px "Axiforma-Regular";
  color: #333333;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 0px;
}
.coupon-sec {
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0px;
}
.coupon-sec p {
  color: #333;
  margin-bottom: 0px;
  margin-left: 10px;
  text-align: left;
  font: 9px "Axiforma-Regular";
}
.cart-page p {
  color: #333;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: left;
  font: 9px "Axiforma-Regular";
}
.total_sec h6 {
  margin-bottom: 0px;
  font: 10px "Axiforma-Bold";
  color: #000;
}
.total_sec p {
  margin-bottom: 0px;
  font: 12px "Axiforma-Bold";
  color: #000;
}
.prod_detail .add_cart p,
.checkout_btn p {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
  font: 10px "Axiforma-Bold";
  color: #fff;
}
.see_review img {
  width: 30%;
  float: left;
}
.see_review p {
  font: 8px "Axiforma-Regular";
  color: #a1a1a1;
  margin: 0px;
}
.product_list p {
  text-align: left;
  margin: 3px 0px;
  font: 8px "Axiforma-Regular";
  font-weight: 500;
  color: #333333;
  margin-bottom: 0px;
}
.product_list h6 {
  text-align: left;
  font: 10px "Axiforma-Bold";
  color: #333333;
  margin-bottom: 0px;
}
.ad-mob h4 {
  font: 14px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}
.ad-mob p {
  font: 13px "Axiforma-Regular";
  color: #3064f9;
  cursor: pointer;
  margin-bottom: 0px;
}
.position_bottom {
  position: absolute;
  bottom: 5px;
}
.category_list {
  min-height: 45px;
  padding: 0px 15px;
}
.category_list p {
  font: 11px "Axiforma-Regular";
  color: #333333;
  cursor: pointer;
  margin-bottom: 0px;
}
.ui_selection li a {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  width: 122px;
  display: block;
  text-align: center;
  padding-bottom: 10px;
}
.ui_selection li.active a {
  border-bottom: 2px solid #3064f9;
  color: #7782a1;
}
/* Admob end */
.taco {
  position: absolute;
  left: 12px;
  top: 15px;
  z-index: 1;
}
.connectivity {
  position: absolute;
  left: 12px;
  bottom: 15px;
  z-index: 1;
}
.cart-my-app {
  position: absolute;
  left: 44px;
  bottom: 15px;
  z-index: 1;
}
/* .android-my-app {
  position: absolute;
  right: 12px;
  bottom: 15px;
  z-index: 1;
} */
.modal-body.preview_steps {
  padding: 0px 35px 15px 35px;
}

.preview_steps {
  margin-top: 28px;
}

.preview_steps h2 {
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  color: #7782a1;
}

.preview_steps h4 {
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  color: #7782a1;
}
.steps_preview {
  padding-bottom: 15px;
  position: relative;
  z-index: 11;
}

.steps_preview img {
  position: relative;
  z-index: 9;
}
.steps_preview:last-child:after {
  height: 0px;
}
.leftsidepreview {
  width: 30%;
}
.rightsidepreview {
  width: 100%;
}
.rightsidepreview .test-button {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  padding: 7px 15px 4px 15px;
}
.rebuildrequire {
  /* background-color: #FF4848; */
  /* height: 24px; */
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
}
.rebuildrequire a {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-align: center;

  color: #1b2952;
  display: block !important;
  width: 100%;
}
.rebuildrequire a:hover {
  color: #1b2952;
}
.rebuildrequire a img {
  max-width: 27px;
  margin-right: 7px;
}
.icontab {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e0e5ec;
  padding-top: 15px;
}
.icontab .li:active {
  color: #547df2;
  width: 100%;
  display: block;
  border-bottom: 2px solid #3064f9;
}
.altpad {
  border-bottom: 1px solid #e0e5ec;
  padding-bottom: 16px;
}
.redeemheading {
  font-family: "Axiforma-Bold";
  font-size: 30px;
}
.redeemsubheading {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #7782a1;
  margin-bottom: 37px;
}
.redeemminor {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
}
form input.formspace {
  width: 60%;
  height: 40px;
  margin-right: 20px;
}
.redeemtext {
  width: 100%;
}
a.redeemlink-text {
  font-family: "Axiforma-Regular";
  color: #3064f9;
}
.linkspec {
  margin-bottom: 2.5px;
}
.timespacing {
  white-space: nowrap;
}
.statusspace {
  text-align: end;
}
.userinfospace {
  padding-top: 18px;
}
.app-created h5.nameinfospacing {
  padding-top: 1px;
}
.linkiconspace {
  white-space: nowrap;
}
.betatext {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #1b2952;
  white-space: nowrap;
}
.versionspac {
  padding-bottom: 21px;
}
.gotospacing {
  margin-bottom: 2px;
}
.gotospacekey {
  margin-left: 5px;
}
.gotospaceinv {
  margin-left: 2px;
}
.dashboard-gotospace {
  margin-left: 5px;
}
.jks-spacing {
  margin-left: 5px;
  margin-top: 5px;
}
.notif-spacing {
  margin-top: 1px;
}
.authkey-spacing {
  margin-bottom: 1px;
  padding-left: 5px;
}
.appear-spacing {
  margin-bottom: 1px;
  padding-left: 5px;
}
.uploaded-file p.jks-link-text {
  font-size: 14px;
}
.bottom-addon-button div.banner-bottom-spacing {
  justify-content: end !important;
}
.addon-pad {
  padding: 22px 28px 21px 26px;
  background-color: #f8f9fc;
  border: 1px solid #dce1eb;
  border-radius: 15%;
}
.toggle-subtext h5 {
  color: #7782a1;
  font: 14px "Axiforma-Regular";
}
.userinfo-demarcation {
  border-bottom: 1px solid #dce1eb;
  padding-bottom: 16px;
  padding-top: 33px !important;
}
.mobile-view-plantext {
  width: -webkit-fill-available;
}
.new-mobilehead-padding h3 {
  padding-bottom: 8px;
}
.usertextspace {
  margin-bottom: 0;
}
.original_img.img-overlay-mobile {
  z-index: auto;
}
.keychain-subtext-space {
  white-space: break-spaces;
}
.dashboardheadrcss.new-gear-padding {
  padding-right: 83px;
}
.toggle-align {
  align-self: flex-end;
}
.mobile-toggle-align.temp-toggle-align {
  padding-bottom: 0;
  padding-top: 15px;
}
.web_hosting p span.offer-color {
  color: #47ce70;
}
.test-button:hover {
  color: #007bff !important;
  background-color: #fff !important;
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69) !important;
}
.testmodal-header .close {
  display: block;
}
.test-header {
  text-align: center;
}
.test-header h5 {
  font-size: 24px;
  font-family: "Axiforma-Bold";
}
.testmodal-dialog .modal-content {
  width: 600px;
  height: 570px;
}
.test-popup-links {
  padding-left: 44px;
  padding-right: 44px;
  margin-top: 28px;
  margin-bottom: 30px;
}
.apple-test-link {
  display: flex;
  align-items: flex-start;
  width: 200px;
  height: 60px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: #fff;
}
.gplay-test-link {
  margin-left: 80px;
}
img.apple-test-img {
  margin-left: 20px;
  padding-top: 14px;
}
.apple-test-linktext {
  padding-top: 12px;
  padding-bottom: 11px;
}
.apple-test-linktext.gplay-test-linktext p {
  margin-left: 13px;
}
.apple-test-linktext.gplay-test-linktext h5 {
  margin-left: 13px;
}
.apple-test-link p {
  margin-bottom: 0;
  margin-left: 16px;
  white-space: nowrap;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.apple-test-link h5 {
  margin-bottom: 0;
  margin-left: 16px;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}
.apple-qr,
.gplay-qr {
  flex: 1;
  text-align: center;
}
.apple-qr-code,
.gplay-qr-code {
  width: 200px;
  height: 200px;
  margin-left: 5px;
}
.gplay-qr-code {
  margin-left: 0;
}

a h5.apple-link-subtext {
  text-decoration: none;
  color: #000;
}
a h5.gplay-link-subtext {
  text-decoration: none;
  color: #000;
}
.copy-redirect-link {
  width: 11px;
  height: 11px;
  display: inline-block;
  margin-right: 6px;
}
.copy-redirect-linktext {
  display: inline-block;
  font-size: 13px;
  font-family: "Axiforma-Regular";

  margin-bottom: 0;
}
.left-link-copy {
  flex: 1;
  margin-top: 15px;
  text-align: center;
}
.new-random {
  height: 100%;
}
.right-link-copy {
  flex: 1;
  margin-top: 15px;
  text-align: center;
}

.user-popup-mobile {
  width: calc(100% - 6px) !important;
}
.payment-status .viewstatus {
  text-align: left;
}

.new-overlay-mobile {
  z-index: 999 !important;
}
.deals-page-direct-btn .deals-btn {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";

  font-weight: 500;
}
.deals-page-direct-btn .deals-btn:hover {
  color: #3064f9;
}
.deals-arrow-img {
  padding-left: 6px;
}
.deals-page-direct-btn {
  margin-top: 17px;
  display: flex;
  justify-content: flex-end;
}

.dealselect-page-direct-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  cursor: pointer;
}
.mt-md-5.mt-2.new-dealsub-spacing {
  margin-top: 18px !important;
}
.dealselect-heading h3 {
  font-size: 36px;
  font-family: "Axiforma-Bold";
  color: #000000;
  margin-bottom: 0;
}
.dealselect-arrow-img {
  margin-right: 5px;
}
.dealselect-btn-panel {
  width: 100%;
  height: 140px;
  background: #fff;
  border: 1px solid #dce1eb;
  border-width: 0 0 1px 0;
  border-radius: 4px 3px 4px 4px;
}
.deals-btn-panel {
  margin-top: 30px;
  width: 1560px;
  height: 140px;
  background: #fff;
  border: 1px solid #dce1eb;
  border-radius: 4px 3px 0 0;
}
.deal-plan-head {
  padding-left: 30px;
  padding-top: 31px;
  padding-bottom: 31px;
}
.deals-plan-heading {
  padding-left: 23px;
  padding-top: 31px;
  padding-bottom: 31px;
}
.deal-plan-head h5 {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.deal-plan-head h3 {
  font-size: 20px;
  font-family: "Axiforma-Bold";
  color: #1b2952;

  display: flex;
  align-items: center;
}
.deal-plan-head h5.deal-activation-date {
  font-size: 13px;
}
.deal-count h5 {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
h6.coupon-code-text {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 10px;
  line-height: 1.5;
}
.dealselect-tag-img {
  margin: 8px 8px 8px 10px;
}
.deal-coupon-head {
  background: #f9fffb;
  border: 1px dashed #47ce70;
}
.deal-expiry-date h5,
h5.expiry-date {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 0;
}
.deal-info-coupons {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30.5px;
  border-right: 1px solid #dce1eb;
}
.deals-coupon-info {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 31px;
}
.deal-select-btn {
  padding: 20px 30px 20px 29.5px;
}
.btn.deal-selection-btn {
  background: #3064f9;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  width: 140px;
  height: 48px;
  border-radius: 4px;
}

.flex-1 {
  flex-basis: 39%;
}
.flex-2 {
  flex-basis: 22%;
}

.deal-container-div {
  border: 1px solid #dce1eb;
  border-radius: 4px;
  margin-top: 30px;
}

.deals-description-chart {
  background-color: #f8fafe;
  border-radius: 4px;
}

h5.deal-desc-heading {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-top: 20px;
  margin-bottom: 21px;
}

.deal-desc-list li {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  padding-left: 20px;
}

ul.deal-desc-list li:not(:last-child) {
  margin-bottom: 12px;
}

ul.deal-desc-list {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
  flex: 1;
}

ul.deal-desc-list li::before {
  content: url(./../images/dashboard/list-blue-tick.png);
  position: absolute;
  left: 20px;
}
.btn-lg {
  min-width: 210px;
  height: 55px;
}

.release-notif-main {
  position: relative;
  padding: 12px 50px 12px 48px;
  background-color: #e5ebff;
}

.release-notif-img {
  object-fit: none;
  display: inline-block;
}

.release-notif-text {
  display: inline-table;
}

h5.release-notif-maintext {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}

h5.release-notif-subtext {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}

a.new-plans-link {
  text-decoration: underline;
}

.close-release-notif button {
  display: block;
}

.close-cross-btn {
  color: #a1a9c1;
}

.customer-login-link a {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
}

.release-notif-main {
  position: relative;
  padding: 12px 50px 12px 48px;
  background-color: #e5ebff;
}
.release-notif-text {
  display: inline-table;
}
h5.release-notif-maintext {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}
h5.release-notif-subtext {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}

.close-release-notif button {
  display: block;
}
.close-cross-btn {
  color: #a1a9c1;
}

.monitor-menu-spacing {
  margin-top: 6px;
  padding: 30px 38px 6px 30px;
}

.monitor-img-spacing {
  margin-top: 36px;
}

.switch.monitor-slider-padding {
  padding: 0;
}

.monitor-mainmenu h3 {
  font-size: 22px;
  font-family: "Axiforma-Bold";
}

.monitor-mainmenu p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.monitor-dropdown-heading h3 {
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.color_picker.monitor-dropdown-heading {
  margin-top: 32px;
  width: 100%;
}

.bottom_menu_bar.bottom-menu-topspacing {
  padding-top: 5px;
}

.bottom-icon-space {
  width: 47px;
  padding-bottom: 12px;
}

.mb-2.users-button-prespacing {
  display: inline-flex !important;
  width: 20%;
}
.color_picker optgroup {
  font-size: 15px;
  color: rgba(51, 51, 51);
}

.right_general div.web_hosting.addon-details-size {
  width: 100%;
}

.addon-details-top-padding {
  padding-top: 30px;
}

.dasboard_page.addon-details-background {
  background-color: #fcfdff !important;
}

h4.users-addon-last-subtext {
  font: 16px Axiforma-bold;
  color: #1b2952;
}

p.users-addon-final-subtext {
  font: 15px/25px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 25px;
}

.web_hosting h2.consent-form-heading {
  font: 28px Axiforma-bold;
  color: #1b2952;
  margin-bottom: 25px;
}

.cus-reset .buildbtn {
  padding: 0 !important;
  width: 70px;
  background: transparent !important;
  border: transparent !important;
  color: #7782a1;
  text-transform: capitalize;
  margin-right: 10px;
}
.cus-reset .buildbtn:hover {
  box-shadow: none;
}
.cus-save .buildbtn {
  padding: 0 !important;
  width: 100px;
  background: transparent !important;
  border: #c8cedb 1px solid !important;
  color: #7782a1;

  margin-right: 15px;
}

.cus-build .buildbtn {
  padding: 0 !important;
  width: 140px;
}

.whitelabel-heading-spacing {
  margin-bottom: 0;
  margin-left: 15px;
}
.item-customizer.media {
  display: block;
}
.green_checkmark.custommark {
  position: absolute;
  top: 0;
  right: 25px;
}

.bill-app p:first-of-type {
  font-weight: 700;
}

.bill-app p:nth-of-type(2) {
  color: rgb(119, 130, 161);
  margin-top: 5px;
}

.addpackage {
  color: rgba(255, 255, 255, 0.95) !important;
  font-size: 16px;
  font-family: Axiforma-regular;
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 3px;
  width: 100%;
  transition: 0.5s;
  height: 48px;
  display: inline-block;
  margin-top: 15px;
}

.dummy-template-spacing {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.new-background-color {
  border: none;
}

.main-reset-body-color {
  background-color: #fcfdff;
}

.main-reset-body-color.main-reset-body-redesign {
  background-color: transparent !important;
}

.password-instructions {
  text-align: left;
  padding: 0 50px 50px 50px;
}

.signup-form-padding {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 43px;
  padding-bottom: 35px;
}

.reset-password-text {
  font-size: 36px;
}
.two-btn-spacing {
  padding-bottom: 40px;
}

.main-reset-body-color {
  background-color: #fcfdff;
}

.password-instructions {
  text-align: left;
  padding: 0 50px 50px 50px;
}

.signup-form-padding {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 43px;
}

.reset-password-text {
  font-size: 36px;
}

.two-btn-spacing {
  padding-bottom: 40px;
}

.menu-phone-icon.ipadview-menu-leftpanel.myapps-display-spec {
  display: none !important;
}
.menu-phone-icon-new.ipadview-menu-leftpanel.myapps-display-spec {
  display: none !important;
}

.w-75.mobile-url {
  flex-basis: 82%;

  width: 82% !important;
}
.w-25.edit-link {
  flex-basis: 18%;

  width: 18% !important;
}

.kb-link,
.kb-link:hover {
  color: #3064f9;
}

.custom-radio-btn:hover {
  background-color: rgba(255, 255, 255, 0.97) !important;

  border-color: #87a6ff !important;
  transition: all ease 0.3s;
  box-shadow: 1px 2px 3px 1px rgb(0 0 0 / 10%);
}
.custom-radio-btn input[type="radio"]:checked,
.custom-radio-btn input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-radio-btn input[type="radio"]:checked + label,
.custom-radio-btn input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 10px;
  padding-right: 105px;
  padding-top: 6px;
  padding-bottom: 10px;
  cursor: pointer;
  line-height: 50px;
  display: inline-block;
  color: #7782a1;
  height: 100%;
  width: 100%;
  font-size: 13px;
}
.form-label-text2 {
  position: relative;
}
.abs-badge {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
  margin-left: 10px;
}
label.form-check-label.form-label-text2 {
  padding-right: 140px !important;
}
.inner-radio input[type="radio"]:checked + label,
.inner-radio input[type="radio"]:not(:checked) + label {
  padding-right: 220px;
}

.inner-radio .form-check-label.form-label-text2 {
  padding-right: 250px !important;
}
.custom-radio-btn [type="radio"]:checked + label:before,
.custom-radio-btn [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 6%;
  width: 19px;
  height: 19px;
  border: 1px solid #c8cedb;
  border-radius: 100%;
  background: #fff;
}

.custom-radio-btn [type="radio"]:checked + label:after,
.custom-radio-btn [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 19px;
  height: 19px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: auto;
  border-radius: 100%;
  transition: all 0.2s ease;
  right: 15px;
  border: 5px solid #3064f9;
  display: inline-block;
}

.custom-radio-btn [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1) translateY(-50%);
}
.custom-radio-btn [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(1) translateY(-50%);
}

.btn.custom-radio-button1,
.btn.custom-radio-button2 {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  display: flex;
  width: 50%;
  justify-content: space-between;
  border-radius: 5px;
  border-color: #e3e6f1;
  padding: 0;
  height: 65px;
}

.custom-radio-button2 {
  margin: 0 !important;
}
.workspace-dropdown-heading {
  padding-left: 20px;
  padding-right: 20px;
}
.workspace-dropdown-heading h5 {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  color: #1b2952;
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.workspace-dropdown-heading h4 {
  color: #bcc1ce;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
  margin-bottom: 14px;
}
.profile-seprator {
  border-top: 1px solid #edf0f5 !important;
  margin-top: 20px;
}

.all-workspaces-link,
.all-workspaces-link:hover {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #3064f9;
  margin-left: 80px;
  display: block;
  margin-top: 20px;
}

.workspace-dropdown-user-info {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  margin-bottom: 8px;
  margin-top: 20.5px;
  margin-left: 20px;
}

.dropdown-work-info {
  flex: 1;
  margin-left: 10px;
}

.dropdown-work-info h5,
.dropdown-work-info p {
  margin-bottom: 0;
}

.dropdown-work-info h5 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 180px;
  color: #1b2952;
  padding-top: 7px;
}

.dropdown-work-info p {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  text-align: left !important;
  color: #7782a1;
  display: flex;
  align-items: center;
  line-height: 30px;
}
.dropdown-work-info p span {
  font-family: "Axiforma-Regular";
  font-size: 10px;
  background-color: #e3e6f1;
  border-radius: 20px;
  color: #7782a1;
  padding: 3px 12px 2px 12px;
  margin-left: 6px;
  line-height: initial;
}

h5.create-workspace-text {
  font-family: "Axiforma-Regular";
  color: #333;
}

.dropdown-item.dropdown-item-padding {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 0;
  padding-bottom: 0;
  cursor: auto;
}

/* 
.headerdropdown.dropdown-menu {
 
} */

.headerdropdown.headerdropdown-spacing {
  left: -100px !important;
  width: 320px;
  max-height: 600px;
  overflow: auto;
}

/* .dropdown-item.create-workspace-dropdown {
  border-bottom: 1px solid #dce1eb !important;
  padding-bottom: 10.5px;
} */
.dropdown-item.create-workspace-dropdown .dropdown-work-info h5 {
  padding-top: 0px !important;
}
.sub-nav-element {
  font-size: 14px;
}

.logout-dropdown-item {
  margin-bottom: 3px;
}

.common-height-new {
  height: 44px;
}

.top-panel-option {
  margin-top: 18px;
}

.color_picker.color-picker-workspace {
  margin-bottom: 56px !important;
}

.customiseappfeatures-workspace {
  padding-top: 0px;
  margin-bottom: 0px;
}

.mb-1.name-input-box-spacing {
  margin-bottom: 6px !important;
}
.workspace-name-prompt {
  width: 100%;
  margin-top: 36px;
}

input.workspace-name-input {
  height: 60px;
  margin-top: 9px;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
}

input.workspace-name-input::placeholder {
  font-size: 16px !important;
}

.workspace-btn-info {
  padding: 18px 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.workspace-feature {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.workspace-feature-text {
  margin-left: 14px;
  width: 349px;
}

h1.workspace-main-heading {
  font-family: "Axiforma-Regular";
  font-size: 30px;
  color: #1b2952;
  padding-bottom: 20px;
}

p.workspace-main-subtext {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #7782a1;
  line-height: 30px;
}

.workspace-prompt-heading {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
}

.workspace-feature-heading {
  font-family: "Axiforma-Bold";
  font-size: 24px;
  color: #000;
  margin-bottom: 0;
}

.workspace-feature-container {
  margin-top: 20px;
}

.workspace-feature-text h5 {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #7782a1;
  margin-bottom: 10px;
}

.workspace-feature-text p {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782a1;
  margin-bottom: 35px;
}

.workspace-features {
  flex: 1;
}
.cus-web {
  padding-left: 12px !important;
}

.cus-web p {
  margin-bottom: 0;
}

.inner-radio [type="radio"]:checked + label:after,
.inner-radio [type="radio"]:not(:checked) + label:after {
  right: 23px;
}

.btn.workspace-btn {
  width: 240px;
  height: 54px;
  color: #fff;
  background-color: #3064f9;
  border-color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 40px;
}
.btn.workspace-btn:hover {
  color: #fff;
}

.cus-abs {
  position: absolute;
  top: -30px;
  right: 5px;
}
.cus-abs img {
  max-width: 23px;
}
.cus-rel {
  position: relative;
}

.store-url2 {
  min-height: 612px;
}
.store-url3 {
  min-height: 512px;
}

.owner {
  height: 55px;
}
.owner-lable {
  color: #000000 !important;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.flags-footer-subtext {
  width: 100%;
}

.homescreen-new-bottompadding {
  padding-bottom: 9px;
}

.modal-content .cus-web p {
  font-size: 14px;
}

.profile-image-leftpanel {
  margin-bottom: 12px;
}
.leftpanel-top {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 23.5px;
}

.leftpanel-top::after {
  display: block;
  content: "";
  width: 90%;
  height: 1px;
  background: #edf0f5;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  top: auto;
}

.leftpanel-profile-head {
  font-family: "Axiforma-Bold";
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.edit-btn {
  cursor: pointer;
  margin-left: 10px;
}

.main-dropdown-container {
  padding: 10px 0px 10px 0px !important;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #333;
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
}

.left-dropdown-container {
  cursor: pointer;
}

.setting-img {
  cursor: pointer;
  display: none;
  transition: 0.5s;
}
.headerdropdown .dropdown-item-padding :hover .setting-img {
  display: block;
  transition: 0.5s;
}
option.please-select {
  color: rgb(200, 206, 219);
}

/* New design Pricing Page */

.subscriptions-top-head .setbackground-new {
  background: #f4f4f9;
  padding-bottom: 60px;
}

.widthset-new {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 92.5px;
}

.text-center.subscription-tab-new ul li div {
  background-color: #e3e6f1;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 12px;

  font-weight: 600;
}

.text-center.subscription-tab-new ul li.yearly-spec div {
  background: #fff !important;
  color: #0f193b !important;
  border-radius: 3px;
  height: 36px;
}

.text-center.subscription-tab-new ul.plan-nav {
  width: auto;
  background: #e3e6f1;
  border-radius: 3px;
  margin-left: 28px;
}

.text-center.subscription-tab-new ul li.yearly-spec div {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
}

.text-center.subscription-tab-new ul li.lifetime-spec div,
.text-center.subscription-tab-new ul li.monthly-spec div,
.text-center.subscription-tab-new ul li.yearly-spec div {
  height: auto;
  text-transform: uppercase;
  line-height: 1.5;

  padding: 9px 25px;
}

.text-center.subscription-tab-new ul li.monthly-spec div,
.text-center.subscription-tab-new ul li.lifetime-spec div,
.text-center.subscription-tab-new ul li.yearly-spec div {
  border-radius: 3px 3px 3px 3px;
}

.summary-chart-mobile.summary-chart-new {
  background: none;
  border: none;
  border-radius: 0;
}

div.summary-detail-new {
  background: #fff;
  margin-right: 13px;
  padding: 29px 0px 20px 0px;
  border-right: none;
  border-radius: 5px;
  height: 504px;
  min-height: 435px;
  position: relative;
}

div.summary-detail-new h2 {
  font-size: 18px;

  line-height: 25px;
  text-transform: capitalize;
}

div.summary-detail-new:last-of-type {
  margin-right: 0;
}

.setbackground-new .card-container-width {
  max-width: 1110px !important;
  padding: 0 15px;
}

.summary-detail-new.last-plan-card {
  margin-right: 0;
}

.summary-detail-new h2.card-heading-first {
  border-left: 3px solid #7297ff;
  padding-left: 20px;
  margin-bottom: 0;
  color: #0f193b;
  text-transform: capitalize;
}

.summary-detail-new h2.card-heading-second,
.summary-detail-new:nth-of-type(2) h2.card-heading-first {
  border-left: 3px solid #ffc834;
  padding-left: 20px;
  margin-bottom: 0;
  color: #0f193b;
}

.summary-detail-new h2.card-heading-third,
.summary-detail-new:nth-of-type(3) h2.card-heading-first {
  border-left: 3px solid #fc8855;
  padding-left: 20px;
  margin-bottom: 0;
  color: #0f193b;
}

.summary-detail-new h2.card-heading-fourth,
.summary-detail-new:nth-of-type(4) h2.card-heading-first {
  border-left: 3px solid #83d298;
  padding-left: 20px;
  margin-bottom: 0;
  color: #0f193b;
}

.card-underline {
  margin-left: 20px;
}

.device-icons,
.plan-desc-data,
.plan-select-new-btn {
  padding-left: 20px;
  padding-right: 20px;
}

.plan-desc-data ul {
  margin-top: 0;
}

.plan-desc-data {
  margin-top: 32px;
}

a.select-button-new {
  width: 91.58%;
  padding-right: 0;
  font-family: "Axiforma-Regular";
  font-size: 14px;
}

.plan-savings {
  padding-right: 20px;
  line-height: 1;
}

.plan-savings span {
  font-family: "Axiforma-Regular";
  font-size: 11px;
  color: #b8bfd0;
}

.plan-select-new-btn h4.price-fonts {
  font-size: 24px;
  margin-bottom: 0;
}

.plan-detail-list {
  list-style-image: url("../../assets/images/dashboard/blue-tick.png");
}

.plan-detail-list strong {
  color: #000;
}

ul.plan-detail-list li {
  margin-bottom: 12.38px;
  font-size: 13px;
}

.select-btn-text {
  display: flex;
  line-height: 2;
  align-items: baseline;
}

.plan-select-new-btn .select-btn-text span {
  margin-left: 6px;
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
}

.plan-select-new-btn h4.card-foot-text {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #3064f9;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-bottom: 14px;
  padding-top: 5px;
}

.plan-first {
  margin-bottom: 37px;
}

div.select-button-spacing-first {
  margin-top: 76px;
  position: absolute;
  bottom: 2px;
  width: 100%;
}

div.select-button-spacing-second {
  margin-top: 59px;
}

div.select-button-spacing-third {
  margin-top: 59px;
}

div.select-button-spacing-fourth {
  margin-top: 27px;
}

.plan-select-new-btn {
  padding-right: 0;
}

.customer-reviews {
  padding-top: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
}

.customer-review-text {
  display: flex;
  flex-direction: column;
  width: 401px;
}

.customer-review-text h3 {
  font-family: "Axiforma-Bold";
  font-size: 36px;
  padding-bottom: 28px;
  line-height: 54px;
  margin-bottom: 0;
  color: #0f193b;
}

.customer-review-text p {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #5f6578;
  line-height: 30px;
}

.customer-review-stars {
  display: flex;
  box-shadow: 0 3px 6px 0 rgb(188 193 206 / 20%);
  /* border: 1px solid #dce1eb; */
  border-radius: 5px;
}

.review-section-first {
  border-right: 1px solid #dce1eb;
}

.trustpilot-review {
  padding: 47px 51.9px 45.5px 49px;
  border-bottom: 1px solid #dce1eb;
}

.gplay-review {
  padding: 47.5px 50.9px 45px 49px;
}

.capterra-review {
  padding: 47px 48.4px 48.5px 50.5px;
  border-bottom: 1px solid #dce1eb;
}

.wp-review {
  padding: 47.5px 48.4px 45px 50.5px;
}

.featured-platforms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 102px;
  margin-bottom: 100px;
}

.featured-platforms h5 {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #c4c7d1;
  margin-bottom: 43px;
}

.featured-platforms-icons {
  display: flex;
  flex-wrap: wrap;
  max-width: 1080px;
  justify-content: space-between;
}
.pf-group {
  padding-right: 60px;
}

.wpengine-icon,
.trustpilot-icon,
.associatedpress-icon,
.wpexplorer-icon,
.newswire-icon {
  padding-bottom: 36px;
}

.compare-plan-main {
  background: #f4f4f9;
}

.compare-plan-head {
  padding-top: 87px;
  margin-bottom: 47.5px;
}

.widthset-new div.compare-plan-head h5 {
  font-family: "Axiforma-Bold";
  font-size: 36px;
  color: #0f193b;
}

.table thead th.top-plan-row {
  border-top: none;
  border-bottom: none;
}

.comparison-table tr.row-info-main {
  background-color: #e8e8f2;
  height: 40px;
  color: #0f193b;
}

.comparison-table tr.row-info-main th {
  color: #0f193b;
  font-size: 14px;
}

.comparison-table tr th {
  color: #5f6578;
  font-size: 13px;
}

.table th.top-plan-row,
.table .comparison-table th,
.table .comparison-table td {
  padding: 0.47rem 1rem;
  font-weight: 400;
  color: #5f6578 !important;
  vertical-align: middle;
}

.table th.top-plan-row {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  font-weight: 400;
  color: #5f6578;
}

tbody.comparison-table {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #5f6578;
}

tbody.comparison-table > tr:nth-child(even) > th {
  background-color: transparent;
}

tbody.comparison-table > tr:nth-child(even) > td {
  background-color: transparent;
}

.compare-plan-container.table {
  width: 1110px;
  margin: auto;
}

tbody.comparison-table tr td {
  text-align: center;
}

.compare-plan-container thead {
  text-align: center;
}

.compare-plan-container thead tr th {
  color: #0f193b;
}

.comparison-table th {
  width: 30%;
}

.comparison-table tr {
  height: 52.5px;
}

.comparison-table .row-info-borderless th,
.comparison-table .row-info-borderless td {
  border-top: none;
}

.comparison-table tr.last-plan-row td {
  color: #3064f9 !important;
}

.faq-container {
  max-width: 1110px;
  margin: auto;
}

.faq-container .faq h2 {
  margin-top: 89px;
  font-family: "Axiforma-Bold";
  font-size: 36px;
  color: #0f193b;
  margin-bottom: 72px;
}

.faq .faq-sub h5,
.faq .faq-expanded h5 {
  font-family: "Axiforma-Regular";
  font-size: 18px;
  margin-bottom: 0;
}

.faq-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid #e3e6f1;
}

.faq-toppadding {
  padding-top: 37px;
}

.third-faq {
  padding-bottom: 40px;
  border-bottom: 1px solid #e3e6f1;
}

.faq-expanded {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq .third-faq p {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #5f6578;
  margin-top: 36px;
}

.btn.btn-outline-light.expand-button {
  color: #fff;
  border: none;
  line-height: 1;
}

.btn.btn-outline-light.expand-button:hover {
  background-color: #fff;
}

.see-more-faq {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #7782a1;
  padding-top: 40px;
  padding-bottom: 100px;
}

.plans-new-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e6f1;
  padding: 46px 40px 56px 40px;
}

.plans-new-cta h1 {
  margin-bottom: 0;
  font-family: "Axiforma-Regular";
  font-size: 36px;
  color: #0f193b;
}

.btn.btn.btn-outline-light.plans-cta-contact-btn {
  color: #3064f9;
  background-color: #fff;
  border: none;
  font-family: "Axiforma-Regular";
  font-size: 16px;
}

.btn.btn.btn-outline-light.plans-cta-contact-btn:hover {
  background-color: #fff;
}

.plans-arrow-img {
  padding-left: 4px;
}

.transparent_background.plans-bottompadding {
  padding-bottom: 32px;
}

.faq.mb-5.faq-bottompadding {
  margin-bottom: 32px !important;
}

.subscription-plan-head h3.subscriptions-new-head {
  color: #0f193b;
}

.homescreen-new-bottompadding {
  padding-bottom: 9px;
}

.flags-footer-subtext {
  width: 100%;
}

.deals-btn.subscription-deals-btn {
  font-size: 13px;
  line-height: 1.2;
}

.deals-page-direct-btn.subscription-direct-btn {
  margin-top: 20px;
}

.mt-md-5.mt-2.new-dealsub-spacing.subscription-cards-spacing {
  margin-top: 30px !important;
}

.plan-desc-data ul.plan-detail-list li {
  color: #5f6578;
  padding-left: 3px;
}

.select-btn-text h4.price-fonts {
  color: #0f193b;
}

.third-faq a.refund-policy-link {
  color: #3064f9;
}

.pricing-tab-content {
  color: #777;
}

.tabbed
  [type="radio"]:nth-of-type(1):checked
  ~ .pricing-tab-content:nth-of-type(1),
.tabbed
  [type="radio"]:nth-of-type(2):checked
  ~ .pricing-tab-content:nth-of-type(2),
.tabbed
  [type="radio"]:nth-of-type(3):checked
  ~ .pricing-tab-content:nth-of-type(3),
.tabbed
  [type="radio"]:nth-of-type(4):checked
  ~ .pricing-tab-content:nth-of-type(4) {
  display: block;
}

.tab-plan-detail .pricesechead {
  background-color: #e8e8f2;
  padding: 12px 0 4px 12px;
}

.tab-plan-detail .allflex {
  border-bottom: 1px solid #e3e6f1;
  height: 51px;
}

.allflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricemajor {
  width: 30%;
  min-width: 30%;
  padding-left: 11px;
}

.priceminor {
  width: 17.5%;
  min-width: 17.5%;
  text-align: center;
}

.pricetick {
  padding-right: 49px;
}

.pricecross {
  padding-right: 51.5px;
}

.tab-plan-detail p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #5f6578;
  margin-top: 16px;
}

.tab-plan-detail a {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #3064f9;
}

.pricesechead h6 {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  color: #0f193b;
}

.pricingflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 28px;
  flex-direction: column;
}

/* New Design price page close */

select#leftdropdown4,
select#leftdropdown2,
select#leftdropdown3,
select#leftdropdown1,
select#leftdropdown5 {
  padding-right: 11%;
}

.proimg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.awards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 85px;
  padding-left: 20px;
  padding-right: 20px;
}

.awards-container h5 {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #c4c7d1;
  margin-bottom: 48px;
}

.awards-container img {
  max-width: 100%;
  height: auto;
}

.plan-nav.plan-nav-mobile .plan-tab-active {
  background: #fff !important;
  color: #7782a1 !important;
  /* border-radius: 5px 5px 5px 5px !important; */
  /* border: 3px solid #e3e6f1; */
  line-height: 1.5;
}

.text-center.subscription-tab-new ul li.yearly-spec div.plan-tab-inactive {
  background: #e3e6f1 !important;
  color: #7782a1 !important;
  border: none;
}

.preview-red-cross-style {
  padding-bottom: 52px;
}

.delete-workspace-btn {
  width: 120px;
  background-color: #fbfbfb;
  text-align: center;
}

.dropdown-border:hover {
  border-left: none;
}

p.workspace-prompt-heading {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
}

.sparkles-new {
  position: relative;
  left: -10px;
  bottom: 9px;
}

.common-container {
  max-width: 1110px;
  margin: 0 auto;
}

.accordion-item {
  border-bottom: 1px solid #e3e6f1 !important;
}

/* Faisal Section */

p.workspace-prompt-heading {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #1b2952;
  text-align: left !important;
}

.hover-effect {
  cursor: pointer;
}

.created-date-workspace {
  text-align: left;
}

.topsectionapp .created-date-personal {
  text-align: right;
  width: 33%;
}

.left-workspace-info-container {
  margin-left: 14px;
  width: 100%;
}

.left-workspace-info-container.left-workspace-info-container h1 {
  text-align: left;
}

.left-workspace-info-container .peronal-apps {
  text-align: left !important;
}

.peronal-apps {
  font-size: 12px;
  color: #7782a1;
  margin-bottom: 0;
  line-height: 16px;
}

.all-status p {
  font-size: 12px;

  line-height: 16px;
  margin-right: 15px;
  margin-bottom: 0;
}

p.viewstatus-responsive.status-set-in-center {
  width: auto !important;
  height: 16px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  line-height: 16px !important;
  text-align: center !important;
  font-size: 9px !important;
}

.app_icon_workspace {
  border-bottom: 2px solid #f1f5fd;
  min-height: 90px;
  margin-left: 15px;
}

.upgrade-text {
  font-size: 12px !important;
  color: #3064f9 !important;
  line-height: 16px !important;
  margin: 0 !important;
}

/* credit card */
.card-list {
  display: flex;
  flex-direction: column;
}

.pd-md-3 {
  padding: 30px 35px !important;
}
.plr-md-3 {
  padding: 0 35px 30px !important;
}

.credit-card-list {
  margin-right: 0 !important;
  margin-bottom: 25px;
  border: 1px solid #eaecf4 !important;
}
.credit-card-list:last-of-type {
  margin-bottom: 0;
}

.form-credit-label {
  padding: 0 !important;
  text-align: left;
}

.card-num {
  max-width: 90%;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
}

.card-num p,
.card-num span {
  color: #7782a1;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  font-family: "Axiforma-Regular";
}
.creditpopup button {
  font-family: "Axiforma-Regular";
}
.creditpopup .modal-title {
  font-family: "Axiforma-Bold";
}
.card-num p {
  color: #1b2952;
}
.cus-secondary,
.cus-primary {
  color: #7581a1 !important;
  min-width: 125px !important;
  padding: 12px 15px !important;
  border: 1px solid #c8cedb !important;
  font-size: 16px !important;
}
.cus-primary {
  background-color: #3064f9 !important;
  color: #fff !important;
  border: 1px solid #3064f9 !important;
}

.delete-loader {
  height: 28px;
}

.creditpopup .spinn {
  padding-top: 100px;
}

.creditpopup .modal-body {
  min-height: 250px;
  max-height: 350px;
  overflow: auto;
}

.credit-card-list [type="radio"]:checked + label:before,
.credit-card-list [type="radio"]:not(:checked) + label:before {
  right: 5%;

  width: 25px;
  height: 25px;
}
.credit-card-list [type="radio"]:checked + label:after,
.credit-card-list [type="radio"]:not(:checked) + label:after {
  border: 7px solid #3064f9;

  width: 25px;
  height: 25px;
  right: 33px;
}

img.cus-star {
  position: relative;
  top: -10px;
  right: 12px;
}

.ipad-view-myapps-header {
  margin-left: 1%;
  margin-right: 1%;
}

a.upgrade-link {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  line-height: 1.5;
  margin-left: 10px;
}

.profile_icon .header-profil-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

/* Tojo */

.right-dropdown-container {
  padding: 30px 0px 42px 0px;
  margin-left: 21px;
  margin-right: 19px;
  margin-bottom: 11px;
  border-top: 1px solid #edf0f5;
}

.right-dropdown-heading {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  padding-bottom: 12px;
}

.main-dropdown-container.dropdown-new-spacing {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.workspace-dropdown-spacing {
  padding-top: 15px;
}

.shownonemenu {
  display: none;
}
.shownewmenu {
  display: block;
}

.sparkles-overlap-spacing {
  position: relative;
  bottom: 10px;
  left: -1px;
  display: none;
}

.publish-portion {
  padding-top: 0 !important;
}

.publish-new-spacing {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.alt-portion-new {
  width: 100%;
  margin: 0 15px;
}

.table-data-merge-new {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ipad-view-cross {
  cursor: pointer;
}

.accordion-title {
  padding: 38px 0;

  cursor: pointer;
}
.accordion-content {
  height: auto;
  transition: height ease 0.3s;
  padding-bottom: 37px;
}
.accordion-content p {
  font-size: 14px;
  line-height: 24px;
  color: #5f6578;

  margin-bottom: 0;
}

.unlimited-btn {
  background-color: #7a41ff !important;
  border: 1px solid #7a41ff !important;
}

.unlimitedselect {
  color: #7782a1 !important;
  border: 1px solid #dce0ee !important;
  background: #dce0ee !important;
}
.unlimited-text {
  color: #7a41ff !important;
}
.unlimited-list {
  list-style-image: url("../../assets/images/dashboard/purple-tick.png");
}

/* Pricing */

.pricing-background {
  background: #f4f4f9 !important;
}

.price-card {
  min-height: 470px;
  background: #fff;
  border-radius: 5px;
  padding: 46px 20px 64px;
  position: relative;
  text-align: center;
}

.price-card:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 3px;
  background: #7297ff;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.price-card.word::before {
  background-color: #4bb1d2;
}
.price-card.woo::before {
  background-color: #ab79e8;
}

.price-card h3 {
  font-size: 22px;
  color: #0f193b;

  font-family: "Axiforma-Bold";
  line-height: 30px;

  margin-bottom: 21px;
}

.price-card p span {
  font-size: 22px;
  color: #0f193b;
  line-height: 30px;
}
.price-card p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #5f6578;
  line-height: 24px;
  margin-bottom: 12px;
}

.pricing-mb-5 {
  margin-bottom: 50px;
}

.icon-mb-3 {
  margin-bottom: 35px;
}
.select-price {
  font-size: 14px !important;
  font-family: "Axiforma-Regular" !important;
  height: 44px !important;
  line-height: 44px !important;

  margin: 23px auto 0;
}

.see-more-btn {
  cursor: pointer;
}
.accordion-item-new,
.search-icon-spacing-mobile {
  display: none;
}

.see-more-new {
  padding-top: 0;
}

.see-more-btn-new {
  padding-top: 40px;
}

.webview-cart {
  width: 100%;
}

.custom-radio-btn.new-hover-element .new-hover-element-text.custom-hover-btn {
  width: 175px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  line-height: 24px;
  padding: 5px 0px;
  position: absolute;
  z-index: 1;
  top: 58px;
  transform: translateX(-50%);
  box-shadow: none;
}
.custom-radio-btn.new-hover-element:hover {
  border-color: #c8cedb !important;
  box-shadow: none;
}

.zoho-loader-img {
  width: 20px;
}

.new-publish-margin {
  margin-top: 2px;
}
.new-notif-width {
  margin: 0 15px;
  width: 100%;
}

.disable-wp-label1 {
  color: #7782a1 !important;
}

.user-role-dropdown .next_btn.coming-soon {
  padding: 10px 15px;
  justify-content: center !important;
}

.disable-card {
  background-color: #bcc1ce !important;
  color: rgba(255, 255, 255, 0.95) !important;
}
.no-card {
  margin-top: 6%;
}

.app_iconname.app-iconname-workspace-mobile h5 {
  display: block;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 15px;
}

.cus-rel select {
  padding-right: 8%;
}

/* Menu builder */

.left-border {
  position: relative;
}
.left-border:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #e3e6f1;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
}

.menubuilder {
  padding-bottom: 0 !important;

  padding-left: 0;
  padding-right: 0;
}

.ptb-3 {
  padding: 15px 29px;
}

.menubuilder .ipad-screen-menu {
  padding: 30px 0 50px;
}

.col-md-6.pd-3 {
  padding-left: 30px;
  padding-right: 30px;
}

.menu-builder-block {
  height: 70px;
}

.module_title.wpicon {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
}

.menubuilder-card {
  width: 100%;
}
.menubuilder-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 30px 55px 30px 30px;
}
.menu-list-content {
  border-bottom: 1px solid #e3e6f1;
}
.menumover {
  position: relative;
}
.menumover::before {
  content: url("../images/dashboard/drag-option.png");
  margin-right: 10px;
  margin-top: 5px;
  display: none;
  cursor: move;
}

h5.menubuilder-h5 {
  font-size: 16px;
  line-height: 21px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0;
  height: 15px;
}
.addmenubuilder a.addlink {
  color: #3064f9;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 25px 30px;
}

/* .addmenubuilder {
  padding: 30px;
} */
.menu-inner {
  padding: 0 30px;
}
.menubuilder .right_general .col-md-6:nth-child(2) {
  background-color: #f8fafe;
  border: 30px solid #fff;
  border-top: none;
  border-bottom: 0px;
}
.form-value label {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 17px;
  color: #1b2952;
}
.form-value .form-control {
  border: 1px solid #e3e6f1;
  min-height: 50px;
  color: #1b2952;
  font-size: 13px;
}
.delete-menu-list a {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.delete-menu-list a:hover {
  color: #7782a1;
}
.menu-inner .menubuilder-icon {
  min-height: 50px;
  border-radius: 5px;
  border: 1px solid #e3e6f1;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.menu-inner .selectpixabayimage {
  background-position: center !important;
  height: 43px;
  width: 43px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
}
.menubuilder-select {
  position: relative;
}
.select-box {
  position: absolute;
  top: 15px;
  transform: none;
  right: 21px;
  cursor: pointer;
  z-index: 1;
  width: 22px;
  height: 18px;
}

.v-top {
  vertical-align: top;
}

/* New Layout design Css */

.latest-signin {
  width: 540px;
}

.design-art {
  font-family: "Daniel-Black";
  font-size: 18px;
}

.new-addon-art {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.banner-addon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 660px;
  position: relative;
  background: #f4f4f9;
}

.banner-side-img {
  width: 10%;
  margin-right: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.addon-new-height .addon-banner-text {
  width: 48%;
  padding-left: 0px;
  flex: 0 0 48% !important;
  max-width: 48% !important;
}

.addon-banner-text h2.tocapital {
  font-family: "Axiforma-Regular";
  font-size: 48px;
}

/* .addon-banner-text p.firstheading {
  font-family: 'Axiforma-Bold';
  font-size: 16px;
  color: #0F193B;
  margin-bottom: 37px !important;
  margin-top: 29px !important;
} */

.addon-arrow {
  margin-top: 14px;
}

.addon-service-text {
  margin-left: 10.5px;
}

.btn.btn-get-addon {
  flex: 1;
  margin-right: 20px;
  color: #3064f9;
  background-color: #fff;
  box-shadow: 0px 4px 8px #b8bfd0;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  padding: 14px 30px 19px 30px;
}

.btn.btn-work-addon {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #b8bfd0;
  padding: 14.5px 56.4px 18.5px 52px;
}

.addon-play-btn {
  margin-right: 6.3px;
}

.addon-btns-spacing {
  padding-top: 67px;
}
.app-compliance-spacing img {
  position: relative;
  left: -40px;
}
.banner-addon-main h2.tocapital {
  font-size: 42px !important;
  font-family: axiforma-bold !important;
  line-height: 64px !important;
}
.banner-addon-main p {
  font-size: 16px !important;
  font-family: axiforma-bold !important;
  margin: 29px 0 51px;
  line-height: 30px !important;
}

.dropdown-text button {
  font-size: 14px;
  color: #000;
  padding: 0;
}

.banner-addon-main h2.tocapital {
  font-size: 42px !important;
  font-family: axiforma-bold !important;
  line-height: 64px !important;
}
.banner-addon-main p {
  font-size: 16px !important;
  font-family: axiforma-bold !important;
  margin: 29px 0 51px;
  line-height: 30px !important;
}
.banner-position {
  position: absolute;
  right: 0;
  width: 50%;
}
.banner-addon-main.banner-padding {
  margin-bottom: 0 !important;
}
.banner-padding .container {
  padding-left: 0;
  padding-right: 0;
}
.banner-padding h2.tocapital {
  font-size: 48px !important;
  font-family: "Axiforma-Bold" !important;
  line-height: 64px !important;
}
.banner-padding p.firstheading {
  font-family: "Axiforma-Bold" !important;
  font-size: 16px !important;
  margin: 30px 0 0 !important;
  line-height: 30px !important;
  color: #212529 !important;
}
.banner-padding p {
  font-size: 16px !important;
  font-family: "Axiforma-Regular" !important;
  margin: 29px 0 51px;
  line-height: 30px !important;
  color: #5f6578 !important;
}
.pay-para-margin p {
  margin: 0;
}
.add_user_quantity.pay-para-margin p.p-0 {
  line-height: 40px !important;
}
.pay-button-newspacing {
  margin-right: 40px;
}
.banner-padding .new-price-spacing p {
  color: #212529 !important;
}
.banner-position.banner-spec {
  bottom: 0;
}

a.loginclient {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
a.loginclient:hover {
  color: #7782a1;
}
.custom-dropdown {
  position: relative;
}

.searchdropdown {
  position: absolute;
  top: 40px;
  left: -90px;
  right: 0;
  width: 300px;
  box-shadow: 0px 0px 12px 0px rgba(188, 193, 206, 0.36);
  background-color: transparent;
}
.searchdropdown.searchdropdown-new::before {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  z-index: 99;
  top: -10px;
  right: 143px;
  margin: auto;
  transform: rotate(0deg);
}

.searchdropdown.searchdropdown-new {
  background-color: #fff;
}

.search-result.search-result-new ul {
  border: none;
}

.loginsearch input {
  width: 100%;
  border: 1px solid #e3e6f1;
  padding: 10px 16px 10px;
  border-radius: 3px;
  font-size: 14px;
  height: 40px !important;
}

.loginsearch input:focus-visible,
.loginsearch input:active {
  border: 1px solid #e3e6f1;
  outline: none;
}

.search-result ul {
  list-style: none;
  padding-left: 0px;
  border: 1px solid #e3e6f1;
  border-top: 0 !important;
  border-radius: 3px;
  /* padding-bottom: 5px; */
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0px;
}
.search-result li {
  padding: 12px 10px;
  font-size: 14px;
  color: #7782a1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.99);
  min-height: 48px;
}

.loginsearch {
  position: relative;
}
.spinn.login-spin {
  padding: 0;
  right: 10px;
  left: auto;
  display: block;
  width: 25px;
  height: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.spinn.login-spin .spinner-border {
  width: 18px;
  height: 18px;
  border-width: 3px;
}

.adminsearchicon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.email-link {
  display: flex;
  align-items: center;
}

.header-profil-img {
  background-size: cover !important;
}

li.email-link.no-record {
  border: 1px solid #e3e6f1;
  position: relative;
  /* top: 50%;
  transform: translateY(-50%); */
  width: 100%;
  left: 0;
  right: 0;
  display: inline-block;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
}

.search-result .spinn {
  padding-top: 0;
  top: 35%;
  bottom: 0;
  height: auto;
}
.right-2 {
  margin-right: 10px;
}

.profile_icon.pro-icon-spacing {
  padding-left: 12px !important;
}
.container-myapps {
  position: relative;
}
.myapps-new-block {
  position: absolute;
  top: -55px;
  bottom: 55px;
  left: 0;
  right: 0;
  margin: auto;
  height: 400px;
}
.img-fluid.email-unverified-spacing {
  padding-bottom: 60px !important;
}
.link-expired.link-expired-new p {
  font-family: "Axiforma-Regular";
  font-size: 16px !important;
  color: #7782a1 !important;
  margin-bottom: 0;
}
.link-expired.link-expired-new h1 {
  font-family: "Axiforma-Bold";
  font-size: 24px !important;
  color: #1b2952 !important;
}
.link-expired-msg-tag{
font-size:16px;
color:#7782A1
}
.pf-new-main {
  margin-top: 20px;
  margin-bottom: 20px;
}
.resend-expired-link.resend-expired-link-new {
  margin-top: 51px !important;
}

a.select-button-new:hover {
  transition: 0.5s;
  background-color: #fff !important;
  color: #3064f9;
}

a.select-button-new-workspace:hover {
  transition: 0.5s;
  background-color: #fff !important;
  color: #7a41ff;
}

a.select-button-new:hover {
  transition: 0.5s;
  background-color: #fff !important;
  color: #3064f9;
}

a.select-button-new-workspace:hover {
  transition: 0.5s;
  background-color: #fff !important;
  color: #7a41ff;
}

.app-status-start {
  margin-right: 2px;
}

.gapforfooter.gapforfooter-new {
  padding-top: 0 !important;
}

.createdetail-new {
  padding-top: 19px;
}

.container-createapp {
  padding-top: 91px;
}

.guest-login-toggle.guest-login-toggle-new p {
  margin-bottom: 0;
}

.pt-md-2.button-create {
  padding-top: 24px !important;
}

.form-group.form-group-appname p {
  margin-bottom: 6.5px;
}

.workspace-profile p {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
  margin-top: 20px;
}

.resend-expired-link .skipbtn.linkexp-btn {
  width: 180px !important;
  height: 54px;
  font-family: "Axiforma-Regular";
  font-size: 16px !important;
}

/* .app-status.app-status-padding {
  margin-left: 15px !important;
} */

.delete-btn.delete-btn-pro {
  display: flex;
  justify-content: center;
  padding: 17px 40px 17px 40px;
}

.profile-head-border {
  margin-bottom: 40.5px !important;
  padding-bottom: 16.5px !important;
  border-bottom: 1px solid #e0e5ec;
}

.subs-head {
  border-bottom: 1px solid #e0e5ec;
  padding-top: 17px;
}

.invoice-head {
  padding-top: 17px;
}

.invoice-head-sub {
  border-bottom: 1px solid #e0e5ec;
  padding-bottom: 16px;
}

.draft-wrap {
  white-space: nowrap;
}

.toggle-sub-height {
  height: 48px;
  display: none;
}

.leftscroll::-webkit-scrollbar {
  width: 4px !important;
}

.leftscroll::-webkit-scrollbar-track {
  background-color: rgba(256, 256, 256, 0.2) !important;
  border-radius: 9px !important;
}

.leftscroll::-webkit-scrollbar-thumb {
  background-color: #bcc1ce !important;
  border-radius: 9px !important;
}

.web-view-monetize {
  position: relative;
}

.web-view-bottom {
  position: absolute;
  top: 300px;
  left: 30px;
}

.web-view-top {
  position: absolute;
  top: 100px;
  left: 30px;
}

.setting-space {
  margin-left: 18px;
  margin-right: 18px;
  cursor: pointer;
}

.plat-back {
  background-color: #f6f8ff;
}

.toggle-sub-height {
  height: 48px;
}

.scrollbar-settings {
  overflow-x: auto;
}

.scrollbar-settings::-webkit-scrollbar {
  height: 3px;
}

.scrollbar-settings::-webkit-scrollbar-thumb {
  background-color: #7782a1 !important;
}

.setting-space {
  margin-left: 18px;
  margin-right: 18px;
}

.onhoverparent-app-new {
  background-color: #f8fafe;
}

.new-info-icons {
  position: absolute;
  left: 12px;
  right: 2px;
  bottom: 15px;
  z-index: 1;
}

.myapps-notif-space {
  margin-right: 10px;
}

.date-created-personal,
.date-created-workspace {
  text-align: left;
}

.topsectionapp-new h2 {
  font-family: "Axiforma-Bold";
}

.search-funnel-filter {
  padding: 9px 12.3px 9.5px 12.2px;
  border: 1px solid #e3e6f1;
  border-radius: 3px;
}

.logo-replacement h3 {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1b2952;
  margin-bottom: 0;
}

.logo-replacement h5 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
  margin-bottom: 0;
}

.edit-btn-card {
  margin-left: 0;
}

.new-hover-social {
  margin-right: 30px;
}

.container-workspace {
  justify-content: center;
  background-color: #fff;
  min-height: 100vh;
}

.premium-notif {
  background-color: #e5ebff;
}

.premium-notif-inner {
  max-width: 1920px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.toggle-new-spacing {
  flex: 1;
}

.toggle-flex-spacing {
  flex: 0;
}

.price-card-new {
  min-height: auto;
  padding-bottom: 26px;
}

.workspace-clickable {
  position: relative;
}

.workspace-clickable .workspace-anchor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.upgrade-plan-link {
  z-index: 3;
}

h6.onward-plans span {
  color: #0f193b;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-right: 4px;
}

p.rest-api-info {
  color: #b8bfd0;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0;
  margin-top: 22px;
  height: 16px;
}

a.select-price:hover {
  transition: 0.5s;
  background-color: #fff !important;
  color: #3064f9;
}

.customer-review-text.customer-review-pad {
  padding-right: 80px;
}

.row.row-spec {
  margin: auto;
  margin-left: auto;
  margin-right: auto;
}

.upgradepage-background {
  background-color: #fff;
}

.listing-plan-name,
.listing-status {
  margin-right: 10px;
}

.email-support-label {
  position: relative;
}

.email-support-format {
  position: absolute;
  top: 24px;
  left: 53px;
}

p.email-support-checkbox {
  margin-top: 0px;
}

.right-dropdown-head-new h5 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7782a1;
}

.live-app-padding {
  padding-bottom: 20px;
}

.email-sup-spin .email-spin {
  width: 15px !important;
  height: 15px !important;
  margin-left: 2px;
  color: #c8cedb;
}

.right-dropdown-height {
  padding-top: 18px;
}

.workspace-det h3 {
  margin-bottom: 12px;
}

.billing-head-flex {
  flex: 1;
}

.billingsub {
  margin-right: 30px;
}

.billingsub .billing-select {
  width: 234px;
  height: 40px;
  -webkit-appearance: none;
}

.billing-select li {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  display: none;
  cursor: pointer;
  padding: 5px 10px;
  border-top: 1px solid black;
  min-width: 150px;
}

.billing-select li:first-child {
  display: block;
  border-top: 0px;
}

.billing-select {
  border: 1px solid #e3e6f1;
  display: inline-block;
  padding: 0;
  border-radius: 2px;
  position: relative;
  margin-bottom: 0;
}

.billing-select li:hover {
  background-color: #ddd;
}

.billing-select li:first-child:hover {
  background-color: transparent;
}

.billing-select.open li {
  display: block;
}

.billing-select span:before {
  position: absolute;
  top: 5px;
  right: 12.5px;
  content: url("../../assets/images/dashboard/billing-arrow.png");
  cursor: pointer;
}

.billing-select.open span:before {
  content: url("../../assets/images/dashboard/billing-arrow-open.png");
}

.my-apps-search-width {
  width: 333px;
  border: 1px solid #e3e6f1;
}

.my-apps-search-form {
  width: 100%;
  height: 44px;
}

button.my-apps-search-btn-main {
  padding-left: 1.8px;
  padding-right: 3.5px;
  border-left: 1px solid #e3e6f1;
}

.takethere-main {
  background: transparent;
  border: 1px solid #e3e6f1;
  width: 100%;
  padding-left: 12.2px;
  padding-right: 12.3px;
  height: 44px;
}

.takethere-main:hover {
  background: transparent;
}

.ipad-view-myapps-header .filter-post {
  left: -488px;
}

.filter-post div.filter-reset-tab {
  background-color: #f8fafe;
}

.filter-tags {
  background-color: #bcc1ce;
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
}

.filter-tags h5 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 0;
  padding: 5px 9.5px 4px 23px;
}

.myapps-account-flex {
  flex: 1;
}

.filter-tag-main {
  margin-right: 20px;
}

.filter-cross-img {
  padding-right: 8.5px;
}

/* .web-woo-wp {
  position: absolute;
  left: 12px;
  bottom: 15px;
  z-index: 1;
}

.connectivity-myapps-new {
  left: 140px;
} */

.invoice-head-sub-new {
  border-bottom: none;
}

.invoice-dropdown {
  border-bottom: 1px solid #e0e5ec;
}

.billingsub {
  margin-right: 30px;
  width: 240px;
}
.react-select-dropdown {
  width: 100%;
}

.invoice-billing-sub {
  margin-right: 0;
}

.my-apps-search-width {
  width: 333px;
  border: 1px solid #e3e6f1;
}

.my-apps-search-form {
  width: 100%;
  height: 44px;
}

button.my-apps-search-btn-main {
  /* padding-left: 1.8px;
  padding-right: 3.5px; */
  border-left: 1px solid #e3e6f1;
}

.takethere-main {
  background: transparent;
  border: 1px solid #e3e6f1;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.takethere-main:hover {
  background: transparent;
}

.ipad-view-myapps-header .filter-post {
  left: -488px;
}

.filter-post div.filter-reset-tab {
  background-color: #f8fafe;
}

.showagencymobprofilemenu{
  display: none !important;
}

.showworkspacemobprofilemenu{
  display: none !important;
}

.filter-tags {
  background-color: #bcc1ce;
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
}

.filter-tags h5 {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 0;
  padding: 5px 9.5px 4px 23px;
}

.myapps-account-flex {
  flex: 1;
}

.filter-tag-main {
  margin-right: 20px;
}

.filter-cross-img {
  padding-right: 8.5px;
}

/* .web-woo-wp {
  position: absolute;
  left: 12px;
  bottom: 15px;
  z-index: 1;
}

.connectivity-myapps-new {
  left: 140px;
} */

.right_panel_redesign {
  background: #f8fafe;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.transparent_background_analytics {
  padding-left: 30px;
  padding-right: 30px;
}

.alt-portion.redesign-portion {
  border-bottom: none;
}

div.redesign-border {
  border-bottom: none;
}

.analytics-tips-list {
  list-style-image: url("../images/dashboard/analytics-tick.png");
}

div.analytics-user-stats {
  padding: 0;
}

.analytics-gplay-stats,
.analytics-iostore-stats,
.analytics-customer-stats {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(188, 193, 206, 0.2);
}

.analytics-gplay-stats,
.analytics-iostore-stats {
  margin-right: 20px;
}

h2.user-stats-data {
  margin-right: 12px !important;
}

.row .new-analytics-histogram {
  margin-bottom: 0;
  height: 414px;
  background-color: #fff;
  margin-left: 0;
  padding: 0px;
  position: relative;
}

.new-analytics-histogram .new-analytics-barheight {
  z-index: 1;
  position: relative;
  height: 93%;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgb(188 193 206 / 20%);
}

.chart-data-tables-redesign {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 3px 6px 0px rgba(188, 193, 206, 0.2);
  border-radius: 4px;
}

.chart-data-tables-redesign .table-head-redesign {
  background-color: #fff;
}

.row .new-analytics-piechart {
  height: 479px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 3px 6px 0px rgba(188, 193, 206, 0.2);
  border-radius: 4px;
  margin-left: 0;
}

.chart-data-tables-redesign .analytics-table-redesign {
  border: none;
}

.row.row-analytics {
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}

.white-back {
  width: 100%;
  height: 20px;
  background-color: #fff;
  position: absolute;
  bottom: -20px;
  left: 0;
  box-shadow: 0px 5px 6px 0px rgb(188 193 206 / 20%);
}

.white-bg-new {
  width: 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
  height: 30px;
  background-color: #fff;
}

.bordetobottom.grant-new-padding {
  padding-left: 0 !important;
  padding-top: 18px !important;
  padding-right: 0 !important;
}

.api-head.grant-margin-new {
  margin-left: 10px;
  margin-right: 10px;
}

.topspace.troubleshoot-portion {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 18px !important;
}

.dashboardheader.email-support-display {
  display: block !important;
}

.dashboardheader.unlimited-workspaces-display {
  display: block !important;
}

.admin-search-main {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 11px 12px 11px 31.3px;
  border-left: 1px solid #e3e6f1;
}

.admin-search-input {
  width: 280px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
}

.admin-search-input p {
  display: flex;
  justify-content: center;
  color: #e3e6f1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-top: 52px !important;
}

.rightsidepreview-upper {
  min-height: 700px;
}

.d-flex.analytics-wp-woo h2 {
  margin-bottom: 0;
  padding-left: 10px;
}

.profile_new_placeholder {
  border-radius: 50%;
  width: 48px;
  height: 48px !important;
}

.right_panel_webhosting {
  background: #f8fafe;
  box-shadow: none;
  padding-left: 10px;
  padding-right: 10px;
}

.webhostspac-new,
.webhostspac.webhostspac-new {
  border-bottom: none;
}

.phone-host-viewspac.phone-new-viewspac {
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(188, 193, 206, 0.2);
}

.phone-host-view.phone-host-new {
  background-color: #fff;
}

.siteground-text-new {
  white-space: nowrap;
  padding-left: 19.7px;
}

.right_general_security {
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(188, 193, 206, 0.5);
  padding: 20px;
}

.row.row-security,
.row.row-security-second {
  width: 1080px;
  margin: auto;
}

.row.row-security .col-md-6:nth-child(1) {
  padding-left: 0;
  padding-right: 20px;
}

.row.row-security .col-md-6:nth-child(2) {
  padding-left: 0;
  padding-right: 0;
}
.row.row-security .col-md-6:nth-child(2) {
  padding-left: 0;
  padding-right: 0;
}

.row.row-security-second .col-md-6:nth-child(1) {
  padding-left: 0;
  padding-right: 0;
}

.row.row-security-second .col-md-6:nth-child(2) {
  padding-left: 80px;
  padding-right: 0;
}

.security-text-padding {
  padding-top: 72px;
}

.security-image-padding {
  padding-top: 70px;
}

.security-first-section {
  background-color: #f8fafe;
  border-radius: 10px;
}
.security-first-section.security-first-radius {
  border-radius: 0;
}
.thankyou-page {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.thankyou-page div {
  text-align: center;
}
.thankyou-page div h4 {
  margin-top: 40px;
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.thankyou-page div p {
  margin-top: 22px;
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.thankyou-page div a {
  background-color: #3064f9;
  border-radius: 3px;
  width: 200px;
  height: 54px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  text-transform: uppercase;
}

.thankyou-loader {
  margin-top: 25px;
}

.onhoverparent-advanced {
  height: calc(100% - 61px);
}

.status-icons-parent {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 11px;
}

.status-icons-parent div {
  width: 33%;
  padding-bottom: 7px;
}

.three-section-container {
  padding-top: 100px;
}

.billing-search-new input {
  width: 270px;
  padding-bottom: 0;
  padding-top: 0;
  outline: none;
}

.css-1uccc91-singleValue div.d-flex:first-child {
  align-items: center;
}

span.css-1okebmr-indicatorSeparator {
  display: none;
}

.filter-cross-img {
  cursor: pointer;
}

.css-1uccc91-singleValue span {
  color: #7782a1;
  font-size: 13px;
}

.css-26l3qy-menu .css-9gakcf-option {
  color: #7782a1;
  background-color: #fff;
  font-size: 13px;
}

.css-26l3qy-menu .css-9gakcf-option:hover {
  color: #fff;
  background-color: #3064f9;
}

.perform_ance.perform_ance_new h2.headspac {
  font-size: 20px;
  color: #1b2952;
  margin-bottom: 4px;
}

.perform_ance.perform_ance_new h5.ipad-webhost-subtext {
  font-size: 13px;
  color: #7782a1;
}

.web_hosting .siteground-subtext p {
  font-size: 14px;
  color: #1b2952;
}

.web_hosting .siteground-subtext ul li {
  font-size: 14px;
  color: #7782a1;
}

.siteground-btn.siteground-btn-redesign {
  margin-top: 0;
}

.text-right.siteground-btn-text {
  padding-top: 32px;
}

a.siteground-btn-padding.siteground-new-width {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  width: 180px;
  height: 44px;
}

.new_btn_perform.cloudflare-btn-main {
  width: 180px;
  height: 44px;
  white-space: nowrap;
  padding: 11px 23px 11px 24px;
}

.blueback.blueback-maintenance {
  background-color: #fff;
  padding-bottom: 41px;
}

.sixboxes-new {
  border: 1px solid #dbe4f3;
  border-radius: 4px;
}

.sixboxes-new h6.subheadings2 {
  font-family: "Axiforma-Regular" !important;
}

.sixboxes-new p.normaltext {
  font-size: 14px !important;
  color: #5f6578 !important;
}

/* .sectionheadings.sectionheadings-maintenance {
  font-family: "Axiforma-Regular" !important;
  font-size: 36px !important;
  color: #0f193b;
} */

.maintenance-wpbuffs-btn {
  width: 160px;
  height: 40px;
  white-space: nowrap;
  padding: 9px 27px 11px 27px !important;
}

.securityspac.maintenancespac {
  margin-bottom: 21px !important;
}

.security-first-text p.mt-3 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952 !important;
}

.security-first-text p.performance-first-subtext {
  font-size: 16px;
  color: #5f6578 !important;
}

.dasboard_page.dasboard_page_logs {
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.logtext.logtext-new {
  font-size: 20px;
  padding-top: 32.7px;
}

.log-subtext.log-subtext-new {
  padding-top: 0;
}

select.logs-select-dropdown {
  position: relative;
  z-index: 1;
}

.text-center h2.notanyapp-first-text {
  margin-top: 31.7px !important;
  font-size: 20px;
}

.text-center p.notanyapp-second-text {
  margin-bottom: 0px !important;
  font-size: 14px;
  color: #7782a1;
}

button.notanyapp-btn {
  margin-top: 29px !important;
}

button.notanyapp-btn h5 {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0;
  text-transform: none;
}

.mt-5.notanyapp-main {
  max-height: 789.5px;
}

.new-license-update {
  height: 32px;
  background-color: #fff;
  border-radius: 24px;
  max-width: fit-content;
  margin: 20px auto;
  margin-right: 0;
  margin-bottom: 0;
  padding: 15px;
  box-shadow: 0px 0px 12px 0px rgba(188, 193, 206, 0.2);
}

.new-license-update a h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.service_filter.mt-3.service_margin {
  padding: 3px 12px;
  width: fit-content;
  margin-top: 12px !important;
}

.apllied-img-border {
  border: 1px solid #47ce70;
  border-radius: 24px;
  padding: 3px 13px 3px 3px;
}

.analytics-status p {
  font: 14px "Axiforma-Regular" !important;
  color: #1b2952 !important;
  margin-bottom: 0px !important;
}

.analytics-status {
  margin: 0 15px;
}

.perform_ance.perform_ance_new h2.headspac {
  font-size: 20px;
  color: #1b2952;
  margin-bottom: 4px;
}

.perform_ance.perform_ance_new h5.ipad-webhost-subtext {
  font-size: 13px;
  color: #7782a1;
}

.web_hosting .siteground-subtext p {
  font-size: 14px;
  color: #1b2952;
}

.web_hosting .siteground-subtext ul li {
  font-size: 14px;
  color: #7782a1;
}

.siteground-btn.siteground-btn-redesign {
  margin-top: 0;
}

.text-right.siteground-btn-text {
  padding-top: 32px;
}

a.siteground-btn-padding.siteground-new-width {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  width: 180px;
  height: 44px;
  border-radius: 2px !important;
}

.new_btn_perform.cloudflare-btn-main {
  width: 180px;
  height: 44px;
  white-space: nowrap;
  padding: 11px 23px 11px 24px;
}

.blueback.blueback-maintenance {
  background-color: #fff;
  padding-bottom: 41px;
}

.sixboxes-new {
  border: 1px solid #dbe4f3;
  border-radius: 4px;
}

.sixboxes-new h6.subheadings2 {
  font-family: "Axiforma-Regular" !important;
}

.sixboxes-new p.normaltext {
  font-size: 14px !important;
  color: #5f6578 !important;
}

/* .sectionheadings.sectionheadings-maintenance {
  font-family: "Axiforma-Regular" !important;
  font-size: 36px !important;
  color: #0f193b;
} */

.maintenance-wpbuffs-btn {
  width: 160px;
  height: 40px;
  white-space: nowrap;
  padding: 9px 27px 11px 27px !important;
}

.securityspac.maintenancespac {
  margin-bottom: 21px !important;
}

.security-first-text p.mt-3 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952 !important;
}

.security-first-text p.performance-first-subtext {
  font-size: 16px;
  color: #5f6578 !important;
}

.dasboard_page.dasboard_page_logs {
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.logtext.logtext-new {
  font-size: 20px;
  padding-top: 32.7px;
}

.log-subtext.log-subtext-new {
  padding-top: 0;
}

select.logs-select-dropdown {
  position: relative;
  z-index: 1;
}

.text-center h2.notanyapp-first-text {
  margin-top: 31.7px !important;
  font-size: 20px;
}

.text-center p.notanyapp-second-text {
  margin-bottom: 0px !important;
  font-size: 14px;
  color: #7782a1;
}

button.notanyapp-btn {
  margin-top: 29px !important;
}

button.notanyapp-btn h5 {
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0;
  text-transform: none;
}

.mt-5.notanyapp-main {
  max-height: 789.5px;
}

.react-select-custom:nth-child(1) {
  width: 206.5px;
}

.react-select-custom div:nth-child(2) {
  height: 40px;
  border-radius: 2px 0 0 2px;
}

.react-select-custom div div:nth-child(1) {
  font-size: 13px;
}

.react-select-custom .css-1wa3eu0-placeholder {
  position: absolute;
}

span.css-1okebmr-indicatorSeparator {
  display: none;
}

.merge-first-flex {
  flex: 1;
  padding-left: 4px;
}

.search-box.country-line.search-box-addapp {
  width: 333.5px;
  height: 40px;
  border-radius: 0 2px 2px 0;
}

.search-box.country-line.search-box-addapp .w-100 input {
  height: 38px !important;
  font-size: 24px;
}

input.addapp-search {
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.addapp-search-image {
  padding: 11px 4px 11px 12.3px;
  border-left: 1px solid #e3e6f1;
}

p.addapp-popup-subtext {
  margin-bottom: 15px;
}

.transparent_background .right_panel.right_panel_bottombar {
  margin-bottom: 0 !important;
}

.myradio.myradio-addapp {
  height: 520.5px;
}

.modal-header.modalheader-addapp-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}

.modal-footer.modalfooter-addapp {
  padding-bottom: 11px;
}

.row-addapp .col-md-3 {
  padding-left: 11px;
  padding-right: 11px;
}

.merge-app-preview {
  background-color: #f8fafe;
  height: 100%;
  /* position: relative; */
}

.col-lg-6.mergeapp-main {
  height: 789.5px;
  background-color: #fff;
  border-left: 1px solid #e3e6f1;
  padding: 30.5px 30px 29px 29.5px;
}

.right_panel.right_panel_mergeapp {
  padding-bottom: 0 !important;
  padding-left: 0;
  padding-right: 0;
  /* min-height: 100% !important; */
}

.mergeapp-preview-box {
  background-color: #fff;
  width: 296px;
  height: 640px;
  padding: 26px 16px 35px 16px;
  position: relative;
}

.merge-preview-innerbox {
  padding-left: 15px;
  flex: 1;
}

h5.merge-innerbox-head {
  margin-bottom: 32px;
}

.merge-preview-innerbox h6 {
  font-family: "Axiforma-Regular";
  font-size: 11px;
  color: #333;
  padding-top: 3px;
  margin-bottom: 5px;
}

.merge-preview-innerbox p {
  font-family: "Axiforma-Regular";
  font-size: 10px;
  color: #a1a1a1;
}

.merge-preview-innerbox p:nth-child(2) {
  margin-bottom: 2px;
}

.merge-preview-innerbox p:nth-child(3) {
  margin-bottom: 0px;
}

.merge-preview-selection {
  max-height: 46px;
  margin-bottom: 33px;
}

input.mergeapp-preview-radio {
  width: 19px;
  height: 19px;
}

.btn.btn-primary.merge-preview-btn {
  width: 261px;
  height: 37px;
  background-color: #3064f9;
}

.merge-preview-btn h5 {
  font-family: "Axiforma-Bold";
  font-size: 11px;
  margin-bottom: 0;
}

.merge-btn-container {
  position: absolute;
  bottom: 35px;
}

.countryselection-mergeapp img {
  width: 24px;
  height: 24px;
}

h5.merge-tooltip-text {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #1b2952;
}

.icontab.icontab-mergeapp {
  padding-left: 30px;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.iconmethod.iconmethod-merge a {
  padding: 14.5px 18px;
}

.iconmethod.iconmethod-merge a.active {
  border-bottom: 2px solid #3064f9 !important;
}

.settings-mergeapp-tab {
  background-color: rgba(241, 245, 253, 0.5);
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.alt-portion.merge-app-portion {
  padding-left: 30px;
  padding-right: 30px;
}

.mergeapp-settings-first {
  padding-top: 41.5px;
  padding-bottom: 45px;
}

.mergeapp-settings-section {
  padding-bottom: 45px;
}

.row.row-mergeapp {
  margin-left: 0;
  margin-right: 0;
}

.user-details.user-details-mergeapp {
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-bottom: 1px solid #e3e6f1;
}

.table-data-merge-new.table-data-mergeapp {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

input.search-country-input {
  background-color: #f8fafe;
}

span.checkmark.checkmark-mergeapp {
  border-radius: 50%;
}

.country-flag-mergeapp {
  width: 24px;
  height: 24px;
}

.country-name-mergeapp {
  font-size: 14px;
  color: #1b2952;
}

.modal-footer.modal-footer-addcountry {
  border-top: 1px solid #e3e6f1 !important;
}

.two-btn-tabs .createtabs li.iconmethod-mergeapp:first-child {
  margin-right: 30px;
}

.multisite-setup-merge {
  margin-top: 60.5px;
}

.multisite-setup-image h5 {
  font-family: "Axiforma-Bold";
  font-size: 16px;
  color: #1b2952;
  margin-bottom: 5px;
}

.multisite-setup-image p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 30px;
}

.multisite-setup-text h3 {
  font-family: "Axiforma-Bold";
  font-size: 30px;
  color: #1b2952;
  margin-bottom: 29px;
  line-height: 48px;
}

.multisite-setup-text p {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
  margin-bottom: 27px;
}

.multisite-setup-buttons button {
  width: 140px;
  height: 44px;
}

.multisite-setup-buttons button:first-child {
  margin-right: 20px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
}

.multisite-setup-buttons button:first-child:hover {
  background-color: #fff;
  color: #7782a1;
}

.multisite-setup-buttons button.mergeapp-second-btn {
  background-color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  white-space: nowrap;
  width: auto;
  margin-right: 0;
}

.new-license-update {
  height: 32px;
  background-color: #fff;
  border-radius: 24px;
  max-width: fit-content;
  margin: 20px auto;
  margin-right: 0;
  margin-bottom: 0;
  padding: 15px;
  box-shadow: 0px 0px 12px 0px rgba(188, 193, 206, 0.2);
}

.new-license-update a h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.service_filter.mt-3.service_margin {
  padding: 3px 12px;
  width: fit-content;
  margin-top: 12px !important;
}

.apllied-img-border {
  border: 1px solid #47ce70;
  border-radius: 24px;
  padding: 3px 13px 3px 3px;
}

.device_chart_redesign {
  margin-top: 37px;
}

/* .common-container.container-red {
  margin-left: 0;
  margin-right: 0;
} */

.select-button-repadding {
  padding-bottom: 20px;
}

a.select-button-red {
  width: 91.58%;
}

.transparent_background_planlisting {
  background-color: #fff;
}

.select-button-repadding {
  padding-bottom: 20px;
}

a.select-button-red {
  width: 91.58%;
}

.transparent_background_planlisting {
  background-color: #fff;
}

.customer-review-shadow {
  box-shadow: 0px 0px 12px 0px rgba(188, 193, 206, 0.36);
}

h4.workspace-bill-red {
  margin-bottom: 0;
}

.my-profile p.workspace-card-red,
.viewstatus-responsive.viewstatus-responsive-red {
  margin-top: 0;
}

.haveidea {
  padding: 57px 40px;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  margin-bottom: 68px;
}

.haveidea h4 {
  margin-bottom: 0;
}

.admin-search-input-red {
  z-index: 999;
}

.advance-search-btns.advance-search-red {
  display: flex;
  align-items: center;
}

.advance-search-red .back_btn {
  max-width: 100px;
  height: 40px;
  padding: 13px 27px 10px 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advance-search-red .next_btn {
  height: 40px;
  min-width: 100px;
}

.loginsearch input.cus-login-input::-webkit-input-placeholder {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #c8cedb;
}

.my-apps-search-spacing input.search-my-app {
  width: 281px;
}

.compare-plan-subtext-red {
  color: #5f6578;
  margin-top: 28px;
}

.action.action-red {
  display: flex;
}

.action-red a {
  font-family: "Axiforma-Regular";
  font-size: 13px;
}

.row-spec-red,
.table th.top-plan-row.row-spec-red {
  color: #0f193b !important;
  font-family: "Axiforma-Bold";
}

.adminsearchicon.adminsearchicon-red {
  display: flex;
  align-items: center;
}

.profile_icon.pro-icon-spacing {
  padding-left: 0px !important;
}
.setting-space {
  margin-left: 0px !important;
  margin-right: 30px;
  opacity: 1 !important;
}
.new-hover-element.new-hover-red {
  padding-left: 0;
  padding-right: 0;
}
.pl-3.pl-3-custom-red {
  padding-left: 0px !important;
  margin-left: 15px;
  margin-right: 15px;
}

.container1.container1-red p {
  text-align: left !important;
}

.upload-screenshot-text.upload-screenshot-red {
  line-height: 1.5 !important;
}

.app-data-select {
  min-width: 210px;
}
.dropdown-workspace {
  margin-right: 15px;
}

.new-analytics-bar-main {
  padding-top: 20px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  box-shadow: 0px -4px 6px 0px rgb(188 193 206 / 20%);
}

.right_panel.right_panel_bottomred {
  box-shadow: 0px 0px 10px 0px rgb(36 116 249 / 15%);
}

.right_panel.right_panel_bottominner {
  box-shadow: none;
}

.ipad-custom-padding.ipad-login-welcome {
  padding-bottom: 5px !important;
}

.ipad-top-margin-red.ipad-login-background {
  margin-top: 0 !important;
}

.ipad-custom-padding.ipad-custom-padding-red.ipad-login-social,
.ipad-custom-padding-red.ipad-login-color {
  padding: 0px 0px 0px !important;
}
.ipad-custom-padding.ipad-custom-padding-red.ipad-login-logo {
  margin-top: 10px !important;
  padding: 10px 0px 0px !important;
}
.dash-border.dash-border-red {
  margin-top: 0 !important;
}

.cart-fields.cart-fields-red {
  padding-left: 15px;
  padding-right: 15px;
}

.cart-product-section {
  margin-bottom: 24px !important;
}

.custom-dropdown.custom-dropdown-myapps {
  margin-right: 15px;
}

.dashboardheader.header-fixed {
  top: 0;
  position: fixed;
}

.chart-analytics-heading {
  position: absolute;
  top: -41px;
}
.beamer_icon.active {
  right: -8px !important;
  top: -4px !important;
}
.logfirebase {
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.logfirebase p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.logfirebase a {
  margin-left: 30px;
  color: #3064f9;
  text-decoration: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.logfirebase svg {
  margin-right: 7px;
}
.analyticsfirebase {
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.analyticsfirebase p {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.analyticsfirebase a {
  margin-left: 30px;
  color: #3064f9;
  text-decoration: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.analyticsfirebase svg {
  margin-right: 7px;
}
.analytics-status {
  display: flex;
  align-items: center;
}
.font-dropdown .dropdown-toggle {
  background-color: #fff !important;
  color: #000 !important;
  width: 100%;
  text-align: left;
  height: 55px;
  border: 1px solid #e3e6f1;
  background: white url(../images/dashboard/dark-grey.png);
  background-repeat: no-repeat;
  background-position: 96%;
  padding: 0px 20px;
  text-align: left!important;
}
.font-dropdown .dropdown-toggle:hover,
.font-dropdown .dropdown-toggle:active,
.font-dropdown .dropdown-toggle:focus {
  border: 1px solid #e3e6f1 !important;
}
.font-dropdown .dropdown-menu {
  width: 100%;
  max-height: 400px;
  overflow: auto;
}
.font-dropdown .dropdown-toggle::after {
  -webkit-appearance: none;
  border: none;
}
.font-dropdown .dropdown-item {
  padding: 5px 20px;
}
.font-dropdown .dropdown-item:hover {
  color: #000 !important;
}
.color_input .fixback {
  box-shadow: 0px 2px 4px rgb(188 193 206 / 50%) !important;
  background-color: #fff !important;
  padding: 20px !important;
  height: auto !important;
  position: absolute !important;
  left: auto !important;
  top: auto !important;
  font-size: 12px;
  z-index: 12 !important;
}
.color_input .fixback .mobileok {
  width: 100% !important;
  margin-top: 20px;
}
.color_input .fixback .mobileok p {
  width: 100% !important;
}
.web-login-input input {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #e3e6f1;
  background-color: #f8fafe;
  padding: 0px 16px;
  outline: none;
}
.web-login-input {
  margin-top: 40px;
  margin-bottom: 30px;
}
.seemoresection {
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
}
.seemoresection svg {
  position: relative;
  right: -1px;
}
.bottombar-preview {
  display: none;
  border: 1px solid #3064f9;
  color: #3064f9;
  height: 40px;
  margin-right: 20px;
  font: 14px "Axiforma-Regular";
  background-color: #fff;
  width: 100%;
}
.website-type-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.website-type-all .new-website-type {
  border: 1px solid #e3e6f1;
  margin-bottom: 15px;
  border-radius: 3px;
  width: 32%;
  height: 120px;
  text-align: center;
  padding-top: 24px;
}
.website-type-all .new-website-type.active {
  border: 1px solid #87a6ff !important;
  background-color: #f6f8ff !important;
}
.website-type-all .blankwebtype {
  width: 31%;
}
.new-website-type h4 {
  margin-top: 10px;
  color: #bcc1ce !important;
  font: 13px "Axiforma-Regular";
}
.new-website-type.active h4 {
  color: #7782a1 !important;
}
.new-website-type p {
  margin-top: -8px;
  color: #bcc1ce !important;
  font: 10px "Axiforma-Regular" !important;
}
/* #rbgcp-wrapper div:nth-child(7) div:nth-child(2) div{
  border: 1px solid #e9e9f5;
  margin-right: 2px;
}
#rbgcp-wrapper div:nth-child(5) div:nth-child(2) div{
  border: 1px solid #e9e9f5;
  margin-right: 2px;
}
#rbgcp-wrapper div:nth-child(7) div:nth-child(1){
  border: 1px solid #e9e9f5;
}
#rbgcp-wrapper div:nth-child(5) div:nth-child(1){
  border: 1px solid #e9e9f5;
}
#rbgcp-wrapper div:nth-child(7) div:nth-child(2){
  justify-content: start!important;
}
#rbgcp-wrapper div:nth-child(5) div:nth-child(2){
  justify-content: start!important;
} */

.payment-center {
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 3px 6px rgb(188 193 206 / 20%);
}
.web-view-btn-style {
  width: 100%;
  background-color: #3064f9;
  border: 1px solid #3064f9;
  color: #fff;
  height: 40px;
  font-size: 15px;
  border-radius: 4px;
}
.team-status-updated {
  width: 150px;
}

.team-status-updated .user-status {
  width: max-content;
}
.no-media-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 200px;
  opacity: 0.5;
}
.no-media-popup p {
  color: #bcc1ce;
  font: 13px "Axiforma-Regular";
  margin-top: 18px;
}
.app-sidebar--Collapse{
  width: 60px!important;
    transition: .4s;
}

.app-sidebar--Collapse .pannel_title h4{
display: none;
}
.redirect-page-height{
  height: 100vh;
}

.redirect-page p{
  color: #7782A1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-top: 20px;
}
.redirect-page p a{
  color: #3064F9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
  text-decoration: underline;
}
.mergeclasss{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mergeclasss p{
  display: none;
}
.modal-open{
  overflow: hidden!important;
}
.old-themecolorPicker .accessfixback{
  bottom: unset !important;
}










@media (min-width: 280px) and (max-width: 300px) {
  .app-status.app-status-padding.app-status-red {
    margin-right: 5px !important;
  }
  .pl-3.header-left-support button.new-hover-element.btn.btn-secondary {
    padding-left: 5px;
    padding-right: 1px;
  }
  .setting-space-red {
    margin-left: 5px;
    margin-right: 5px;
  }
  .menu-phone-icon-new.hamburg-padding.hamburg-padding-red {
    padding-left: 5px !important;
  }
}

@media (min-width: 375px) and (max-width: 386px) {
  .signupcontent-new-red h3 {
    font-size: 19px;
  }
  .socialoptions-inner .mr-3-social-red {
    margin-right: 10px !important;
  }
}
@media (min-width: 600px) and (max-width: 999px) {
  .pay-card-red p.pay-card-red-inner {
    text-align: left !important;
  }
}
@media (min-width: 701px) and (max-width: 767px) {
  .pay-card-width-red {
    width: 38% !important;
  }
}

@media (max-width: 359px) {
  .logsub.billingsub {
    padding: 10px 15px 0 15px !important;
  }
  .custom-dropdown.custom-dropdown-myapps {
    margin-right: 10px !important;
  }
  .pl-3.header-left-support button.new-hover-element.new-hover-red {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pl-3.header-left-support {
    padding-right: 0px !important;
  }
  .cus-secondary.cus-secondary-redesign {
    width: 46%;
    min-width: auto !important;
  }
  .app-status.app-status-padding.app-status-red {
    margin-left: 4px !important;
  }
  .custom-dropdown.custom-dropdown-myapps {
    margin-right: 10px;
  }

  .namespac-red.namespac-red-margin {
    margin-left: 8px !important;
  }
  .app-status.app-status-padding.app-status-red {
    margin-left: 4px !important;
    margin-right: 10px !important;

    display: none !important;
  }
  .pl-3.header-left-support button.new-hover-element.btn.btn-secondary {
    padding-left: 10px;
    padding-right: 6px;
  }
  .setting-space-red {
    margin-left: 10px;
    margin-right: 10px;
  }
  .pro-icon-spacing.pro-icon-red {
    padding-left: 0 !important;
  }
  .menu-phone-icon-new.hamburg-padding.hamburg-padding-red {
    padding-left: 1px !important;
  }
  .email-support-format.email-support-red {
    white-space: normal;
    font-size: 12px;
  }
  .cus-changes.cus-changes-red {
    justify-content: center;
    margin-top: 25px;
  }

  .cus-text .cus-text-inner {
    width: 92% !important;
  }
  .rounded-red {
    max-height: 42.19px !important;
  }
  .ml-4.ml-4-sm-red {
    flex: 0 !important;
  }
}

@media (min-width: 280px) and (max-width: 359px) {
  .betatoggle {
    margin-left: 99px;
  }
}
@media (min-width: 360px) and (max-width: 374px) {
  .betatoggle {
    margin-left: 181px;
  }
}
@media (min-width: 375px) and (max-width: 380px) {
  .betatoggle {
    margin-left: 194px;
  }
}
@media (min-width: 380px) and (max-width: 393px) {
  .betatoggle {
    margin-left: 210px;
  }
}
@media (min-width: 393px) and (max-width: 414px) {
  .betatoggle {
    margin-left: 233px;
  }
}
@media (min-width: 420px) and (max-width: 540px) {
  .betatoggle {
    margin-left: 359px;
  }
}
@media (min-width: 541px) and (max-width: 1200px) {
  .betatoggle {
    margin-left: 90px;
  }
}
@media (min-width: 768px) and (max-width: 795px) {
  .ml-2.ml-2-new-red {
    font-size: 10px;
    margin-top: 4px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .back-home.back-home-ipad a {
    margin-left: 40px;
  }
  .login-help-red .login-help-red-inner {
    padding-left: 0 !important;
    margin-left: 40px !important;
  }
}
@media (min-width: 768px) and (max-width: 960px) {
  .rightsidepreview div.ipad-test-popup {
    display: flex !important;
    justify-content: center;
    padding-bottom: 60px;
  }

  .topsectionapp h3.namespac {
    flex-basis: 61% !important;
    width: auto !important;
  }
  .topsectionapp p {
    flex-basis: 38% !important;
    width: 38% !important;
  }
  .mt-4 div.pc-test-popup {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .ipad-view-myapps-icons {
    display: block !important;
  }
  .nameinfospacing {
    white-space: nowrap;
  }
  .app_iconname.app_iconname_ipadsettings {
    margin-top: 10px !important;
  }

  .show_border.show_border_checkout,
  .app_iconname.app_iconname_pushnotification {
    padding-top: 5px !important;
  }
  .notif-ipad-new.notif-ipad-red {
    padding-top: 10px !important;
  }
}
@media (min-width: 768px) and (max-width: 840px) {
  .left_panel.leftpanel-ipadview-landscape {
    width: 28%;
  }
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .dashhead.dashhead-redesign {
    margin-top: 70px !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_dashboard {
    padding: 20px !important;
  }

  .ipad-view-headbill.ipad-view-headbill {
    margin-top: 65px;
    padding: 17px 15px 15px 15px;
  }
  .top-portion.ipad-portion-appearance.ipad-portion-laptop {
    margin-top: 60px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .analytics-status {
    justify-content: space-between;
    margin: 10px 0px 20px 0px;
  }
  .analyticsfirebase {
    margin-right: 20px;
  }
  .top-portion.ipad-portion-appearance.ipad-special-appearance,
  .top-portion.ipad-iosadmob-margin.ipad-red-appearance {
    margin-top: 64px !important;
  }

  .workspace-feature-container-ipad .workspace-feature-text-mobile h5,
  .workspace-feature-container-ipad .workspace-feature-text-mobile p {
    text-align: left !important;
  }
  /* .workspace-feature-container-ipad {
    padding-left: 35px;
  } */
  .workspace-feature-heading-ipad {
    padding-left: 35px;
    text-align: left;
  }
  .w-t-ams.w-t-ams-laptop {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_profile {
    margin-top: 64px !important;
  }
  .right_panel.right_panel_profile {
    padding-top: 10px !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_changepassword {
    margin-top: 60px !important;
  }
  .app_iconname h5.profile-ipad-change {
    display: block;
  }

  .leftpanel-ipadview-dashboard {
    top: 62px !important;
  }
}

@media (min-width: 601px) and (max-width: 899px) {
  .dasboard_page.dasboard_page_style {
    min-width: 100vw;
  }
  .social-http {
    min-width: 75px !important;
  }
}

@media (min-width: 980px) and (max-width: 1199px) {
  .appmysite-certificate {
    display: none;
  }
  .signup_left.signup_left_ipad {
    justify-content: center !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .social-http {
    min-width: 80px !important;
  }
  .profile-head-border {
    display: flex !important;
  }
  .w-50.plan-text p,
  .w-50.pay-txt p,
  .invoice-text.w-50,
  .invoice-date-time.w-50 {
    display: flex;
  }
  .payment-status .active-green {
    background-color: transparent;
    border: none;
  }
  .text-right.profile-detail-btn .next_btn {
    width: 100%;
  }
  .mt-4 .pay-card-width {
    width: 100%;
  }

  .workspace-feature-container-ipad .workspace-feature-text-mobile h5,
  .workspace-feature-container-ipad .workspace-feature-text-mobile p {
    text-align: left !important;
  }
  .workspace-feature-heading-ipad {
    padding-left: 60px;
    text-align: left;
  }
  .workspace-feature-container-ipad {
    padding-left: 60px;
  }

  .workspace-feature-container-ipad .workspace-feature-text-mobile h5,
  .workspace-feature-container-ipad .workspace-feature-text-mobile p {
    text-align: left !important;
  }
  .workspace-feature-heading-ipad {
    padding-left: 60px;
    text-align: left;
  }
  /* .workspace-feature-container-ipad {
    padding-left: 60px;
  } */

  .leftpanel-ipadview-dashboard {
    top: 60px !important;
  }
  .show_border.show_border_product {
    padding-top: 0 !important;
  }

  .inside_image .onhoverparent .appimg.app-icon-display-status {
    width: 130px !important;
    height: 130px !important;
    border-radius: 24px !important;
  }

  .transparent_background .ipad-view-headbill.ipad-new-headbill {
    display: flex !important;
    padding: 17px 15px 15px 15px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1399px) {
  .inside_image {
    height: 300px !important;
  }
  .inside_image .onhoverparent .appimg.app-icon-display-status {
    width: 130px !important;
    height: 130px !important;
    border-radius: 24px !important;
  }
  .app-list {
    width: 23% !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .left_panel.leftpanel-ipadview-landscape {
    width: 23%;
  }

  .custom-radio-btn input[type="radio"]:checked + label,
  .custom-radio-btn input[type="radio"]:not(:checked) + label {
    padding-right: 75px;
  }
  label.form-check-label.form-label-text2 {
    padding-right: 115px !important;
  }

  .custom-radio-btn [type="radio"]:checked + label:after,
  .custom-radio-btn [type="radio"]:not(:checked) + label:after {
    right: 12px;
  }

  .cus-abs {
    position: absolute;
    top: -25px;
    right: 5px;
  }
  .cus-abs img {
    max-width: 19px;
  }
}
@media (min-width: 899px) and (max-width: 950px) {
  .left_panel.leftpanel-ipadview-landscape {
    width: 28%;
  }
}
@media (min-width: 991px) and (max-width: 1180px) {
  .leftpanel-ipadview-landscape {
    width: 28%;
  }
  .ipadview-icon-spacing {
    padding-left: 15px;
  }
}
@media (max-width: 380px) {
  .deals-arrow-img {
    margin-bottom: 2px;
  }
}
@media (min-width: 961px) {
  .rightsidepreview div.ipad-test-popup {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .next_btn.addon-button-newspacing {
    display: inline-block;
    width: 190px !important;
    height: 39px;
  }
  .menubuilder .fixed-button .next_btn {
    margin-left: 50px;
  }
  .dowbutton {
    max-width: 350px;
  }
  a.dbutton {
    width: 100%;
  }
  .mobilepreview {
    margin-top: 33px;
    position: relative;
    right: 0;
  }
  .mobilepreviewnew {
    margin-top: 15px;
    position: relative;
    right: 0;
  }
  .mobilepreviewalt {
    margin-top: 8px;
  }
  .top-portion {
    padding: 18px 0 16px 0;
    border-bottom: 1px solid #e0e5ec;
  }
  .alt-portion {
    padding: 18px 0 17px 0;
  }

  .app_iconname h5.alterext {
    margin-top: 8px;
  }
  .add-portion {
    padding: 17px 0 17px 0;
    border-bottom: 1px solid #e0e5ec;
  }
  .mobile-view-flex {
    justify-content: space-between;
    width: 75%;
  }
}

@media (min-width: 439px) and (max-width: 768px) {
  .codeinput-space {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .payment-center {
    box-shadow: 0px 0px 6px 2px rgb(188 193 206 / 10%);
    margin-top: 50px;
  }
  .payment-center .payment-border {
    border: none;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .responsive-img {
    padding-top: 70px;
  }
  .app-status {
    margin-right: 30px !important;
  }
  .subscriptions .action {
    text-align: right;
  }

  .cus-flex-direction {
    flex-direction: column;
  }
  .email-support .cus-mobile-none,
  .email-support .response-status {
    display: none !important;
  }
  .flex-column.tab-column {
    flex-direction: row !important;
  }
  .email-support ul {
    margin-top: 30px !important;
  }
  .email-support .custom-width-left {
    max-width: 100%;

    margin: 0 !important;
  }
  .email-support {
    margin-top: 7.5%;
  }

  .app_description-ipadview {
    padding: 18px 0 16px 0;
    border-bottom: 1px solid #e0e5ec;
  }

  .signup.container-signup {
    display: flex;
    align-items: center;
  }

  .upload-screenshot.upload-screenshot-custom {
    margin-bottom: 0 !important;
  }
  .tab-column.tab-column-red {
    align-items: center !important;
  }
  .cus-changes.cus-changes-red {
    margin-top: 0px !important;
  }
}
@media (min-width: 1280px) {
  .back-home-login a {
    margin-left: 40px;
  }
}

@media (max-width: 1199px) {
  .web-login-input {
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 22px;
  }
  .right-dropdown-heading h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #1b2952;
    font-family: "Axiforma-Bold";
    font-size: 14px;
  }
  .right-dropdown-heading h4 {
    color: #bcc1ce;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .setting-img {
    display: block;
  }
  .all-workspaces-link,
  .all-workspaces-link:hover {
    margin-left: 60px;
  }
  .icontextspace {
    margin-right: 0;
  }
  .transparent_background_general .css-yk16xz-control {
    border-top: 1px solid #e3e6f1 !important;
    border-bottom: 1px solid #e3e6f1 !important;
    border-left: 1px solid #e3e6f1 !important;
    border-right: 1px solid #e3e6f1 !important;
  }
  .transparent_background_general .app-data-select {
    width: 100%;
  }
  .transparent_background_general .justify-content-end {
    justify-content: space-between !important;
    margin: 10px 15px !important;
  }
  .transparent_background_general .dropdown-workspace {
    width: 100%;
  }
}

@media (min-width: 1201px) {
  .publishpopup {
    max-height: 247px;
  }
  .ipad-view-cross {
    display: none;
  }
  .menu-phone-icon.ipadview-menu-leftpanel {
    display: none !important;
  }
  .menu-phone-icon-new.ipadview-menu-leftpanel {
    display: none !important;
  }
  .app-status.app-status-cus-red {
    margin-right: 30px !important;
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 1451px) {
  .platswitch.altswitch {
    bottom: -75px;
  }
  .icontextspace {
    margin-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 790px) {
  .iconspace {
    width: 28%;
  }
  .logfirebase {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .website-type-all .new-website-type {
    width: 32%;
  }
}

@media (min-width: 768px) and (max-width: 810px) {
  .app-version svg.responsive-mobile-view-spacing {
    margin-left: 0.5rem !important;
    margin-right: 0.25rem !important;
  }
  .app-version svg.responsive-view-spacing {
    margin-right: 0.25rem !important;
  }
  .website-type-all .new-website-type {
    width: 32%;
  }
}

@media (min-width: 820px) and (max-width: 860px) {
  .iconspace {
    width: 33%;
  }
  .website-type-all .new-website-type {
    width: 32%;
  }
}

@media (min-width: 912px) and (max-width: 950px) {
  .iconspace {
    width: 19%;
  }
  .website-type-all .new-website-type {
    width: 32%;
  }
}

@media (min-width: 1240px) and (max-width: 1520px) {
  .symbolspace {
    padding-bottom: 0.25rem;
  }
}

@media (min-width: 1280px) and (max-width: 1600px) {
  .updateversion {
    margin: -25px -10px 30px -15px !important;
  }
  .updateversion.version-spacing {
    margin: 0 !important;
  }
  .two-apps .creating-build p {
    line-height: 14px;
  }
  .two-apps .androidpublishright {
    width: 80%;
    margin-left: 20px !important;
  }
  .big-tab .categories-dashboard {
    padding: 13px !important;
  }
  .categoryimage .big-tab h4,
  .categoryimage .active .big-tab h4 {
    font-size: 14px !important;
  }
  .api-details .two-apps {
    padding: 25px 10px 25px 15px;
  }
  .two-apps p {
    font-size: 14px;
    margin-top: 15px;
  }
  .big-tab p {
    font-size: 13px !important;
    line-height: 18px !important;
    margin-top: 5px;
  }
  .backcheck {
    margin-left: 3px;
    margin-top: 7px;
  }
  .transparent_background,
  .right_panel {
    padding: 20px 20px 25px 20px;
  }
  .right_panel {
    padding: 20px;
    padding-top: 0px;
  }
  .errorspace {
    padding-top: 1px;
  }
  .customiseappfeatures {
    padding-top: 20px;
    width: calc(100% - 390px) !important;
    padding-right: 0px;
  }
  .apptab {
    padding-top: 0;
  }
  .downspac {
    padding-top: 18px;
  }
  .app_iconname h3 {
    font-size: 18px;
  }
  .web_logo {
    margin-left: 12px;
  }
  .pannel_title h4 {
    margin-left: 0px;
  }
  .yamm_tabs .panel-body ul {
    margin-left: 20px;
  }
  .left_panel {
    width: 180px;
    margin-top: 50px;
  }
  .dashboardheader .container {
    height: 50px;
  }
  .logo {
    margin-top: 5px;
  }
  .transparent_background {
    width: calc(100% - 180px);
    margin-top: 50px;
    margin-left: 180px;
  }
  .customiseapp {
    width: 390px;
    right: 24px;
  }
  .your-info {
    padding-left: 20px;
    padding-right: 20px;
  }
  .customiseapp .two-btn-tabs .promotertabs {
    padding-top: 8px;
    margin-bottom: 16px !important;
  }
  .customiseapp .two-btn-tabs .promotertabs li {
    width: 50%;
  }
  .customiseappfeatures .two-btn-tabs .createtabs {
    width: 182px;
  }
  .color_picker .form-name,
  .form-group select,
  .color_picker_full .color_input,
  .color_picker .color_input,
  .css-gmziuu-InputColor,
  .phonecodeselect {
    height: 54px;
  }
  .color_picker .color_input,
  .color_picker_full .color_input {
    padding: 1px;
  }
  .upload_files h4 {
    font-size: 13px;
  }
  .app_iconname h5 {
    width: 100%;
  }
  .slide-arrow {
    width: 30px !important;
    height: 30px !important;
  }
  .customwidth .color_input {
    padding: 0px;
    height: 54px;
    margin-left: 3px;
    margin-top: 7px;
  }
  .horizontal-alignment .align {
    height: 54px;
  }
  .inside_image {
    height: 290px !important;
  }
  .appimg {
    width: 130px !important;
    height: 130px !important;
    border-radius: 24px !important;
  }
  .topsectionapp h2 {
    font-size: 14px;
  }

  .altswitch {
    bottom: -65px !important;
  }

  .mb-2.users-button-prespacing {
    width: 30%;
  }
  .chart_data_tables__2THT9 {
    width: 31.5%!important;
  }
}
@media (min-width: 1601px) and (max-width: 1900px) {
  .chart_data_tables__2THT9 {
    width: 31.5%!important;
  }
}

@media (min-width: 1521px) {
  .top-portion {
    padding: 18px 0 16px 0;
    border-bottom: 1px solid #e0e5ec;
  }
  .newswitch {
    bottom: -72px;
  }
}
@media (min-width: 1200px) and (max-width: 1350px) {
  .addon-button-newspacing {
    width: 38% !important;
  }
  .new-website-type h4 {
    font-size: 12px !important;
  }
  .chart_data_tables__2THT9 {
    width: 31%!important;
  }
}
@media screen and (max-width: 500px) {
  .old-themecolorPicker .accessfixback{
    right: -14px !important;
  }
  .old-themecolorPicker .loginfixback{
    right: -14px !important;
  }
}
@media screen and (max-width: 1200px) {
  .old-themecolorPicker .loginfixback{
    bottom: -45% !important;
  }
  .d-flex .customiseappfeatures.customiseappfeatures-splash {
    padding-top: 0px !important;
  }
  .pay-button-container {
    flex-wrap: wrap;
  }
  .apkbutton {
    margin-right: 10px;
  }
  .right_general div.mobile-view-ios-build {
    border-bottom: none !important;
  }
  .mobile-trouble-view {
    border-bottom: none;
  }
  .right_general div.mobile-border-android {
    border-bottom: none !important;
  }
  .aabbutton {
    margin-right: 14px;
  }
  .app-status-spacing {
    padding-right: 16px;
  }
  /* div.new-section-mobile {
		align-items: center !important;
	} */

  .ipad-custom-padding.ipad-custom-padding-red {
    padding: 10px 18px 5px !important;
  }
  .ipad-top-margin-zero.ipad-top-margin-red {
    margin-top: 8px !important;
    margin-bottom: 9px !important;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    text-align: left;
  }

  .guest-login-toggle p.web-wp-info {
    margin-bottom: 0;
  }
  .guest-login-toggle.guest-login-ipad p {
    text-align: left !important;
  }
  .mobile-view-wpengine {
    border-bottom: 0;
  }
  .mobile-view-border {
    border: 0;
  }
  .mobile-view-webhost {
    border-bottom: none;
  }
  .new-mobilehead-padding h3 {
    padding-bottom: 8px;
  }
  .mobile-view-ellipsis {
    width: 242px;
  }

  a.store-link.mobile-view-ellipsis span {
    max-width: calc(100% - 136px);
  }
  .checklist_first h2.mobile-view-left {
    padding-left: 0;
  }
  div.responsive-view-image {
    display: none !important;
  }
  .app-version h4.mobile-view-version {
    margin-right: 35px !important;
  }
  .right_general div.mobile-view-plan-name {
    margin-top: 4px !important;
  }
  .dashboardheadrcss.new-gear-padding {
    padding-right: 78px;
  }
  .on_off.toggle-ipad-alignment {
    margin-top: 0.25rem !important;
  }
}

@media (min-width: 980px) and (max-width: 1200px) {
  .mobspace {
    padding-top: 8px;
  }
  .right_panel.ipad-screen-padding {
    padding: 0 20px 20px !important;
  }
  .website-type-all .new-website-type {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .payment-center {
    box-shadow: none;
  }
  .payment-center .payment-border {
    border: none;
    padding: 0px;
    margin-top: 30px;
  }
  .analyticsfirebase {
    justify-content: space-between;
    margin-right: 0px;
    padding: 10px;
  }
  .new-website-type h4 {
    margin-top: 10px;
    color: #7782a1 !important;
    font: 13px "Axiforma-Regular" !important;
  }
  .website-type-all .new-website-type {
    width: 48%;
  }
  .logfirebase {
    display: flex;
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  .addon-new-height .addon-banner-text {
    width: 48%;
    padding-left: 36px;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  /* .transparent_background .right_panel.right_panel_publish {
    min-height: 100vh !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .right_panel.right_panel_plugin, .right_panel_plugin {
    min-height: 100% !important;
    overflow: hidden !important;
  } */
  /* .dasboard_page.mobile-display
    .transparent_background.transparent_background_dashboard {
    margin-top: 0 !important;
  } */
  /* .right_panel.right_panel_bottombar {
    height: 100vh;
  } */
  .upload_files.upload_files_social {
    margin-top: 12px !important;
  }
  .upload_files.upload_files_welcome {
    margin-top: 18px !important;
  }
  .ipad-custom-padding.ipad-login-welcome {
    padding: 10px 0px 0px !important;
  }
  .ipad-custom-padding.ipad-custom-padding-red.ipad-login-social {
    padding: 10px 0px 0px !important;
  }
  .ipad-custom-padding-red.ipad-login-color {
    padding: 10px 18px 0px !important;
  }
  .ipad-custom-padding.ipad-custom-padding-red.ipad-login-logo {
    margin-top: 6px !important;
    padding: 10px 0px 0px !important;
  }
  .dash-border.dash-border-red {
    margin-top: 0 !important;
  }
  .borbot .ipad-top-margin-zero {
    margin-top: 24px !important;
  }
  .appimg.appimg-mobile {
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
  }
  input.input-mobile-space {
    padding-right: 40px;
  }
  .headerdropdown-left-spacing {
    left: 0 !important;
  }
  .top-portion.loginlink-spacing-mob {
    justify-content: center !important;
    margin-bottom: 30px;
  }
  .bottom-mob-menu.bottom-mobile-menu-responsive {
    position: static;
  }
  a.contact-link-mob-spacing {
    white-space: nowrap;
  }
  .redeem-mob-space {
    padding-top: 23.2px;
    padding-bottom: 26.8px;
  }
  .altpad-responsive {
    border-bottom: 0;
  }
  .responsiveview-notes {
    display: none;
  }
  a.responsiveview-notes {
    display: none;
  }
  .two-btn-mobile {
    padding-bottom: 50px;
  }
  .signup-form-padding-mobile {
    padding: 24.5px 20px 0 20px;
  }
  .reset-mobile-bg {
    width: 100%;
  }
  .password-page-mobile {
    padding: 0 25px;
  }
  .right-reset-mobile {
    display: flex;
    flex-direction: column;
  }
  .main-mobileview {
    display: flex;
  }
  div.responsivehead {
    margin-top: 0;
  }
  .mobile-homescreen-padding {
    padding-top: 14px;
  }
  .mobile-preview-padding {
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .plugin_img img.success-mobile-size {
    max-width: 80%;
  }
  .dashboardheadrcss.new-gear-padding {
    padding-right: 60px;
  }
  .topspace div.general-alignment {
    justify-content: flex-start !important;
  }
  .deals-page-direct-btn.deals-page-mobile {
    justify-content: center;
  }
  .deals-btn-mobile {
    display: flex;
    align-items: center;
  }
  .mt-md-5.mt-2.new-dealsub-spacing.dealsub-mobile-spacing {
    margin-top: 0 !important;
  }
  .mt-md-5.mt-2.new-dealsub-spacing.dealsub-mobile-spacing
    div.summary-chart-mobile {
    padding-top: 4px;
  }

  .testmodal-dialog.mobile-layout .modal-content {
    max-width: 100%;
  }

  .mobile-layout .apple-test-link {
    width: 180px;
    margin-bottom: 30px;
  }
  .mobile-layout .gplay-test-link {
    margin-left: 0;
    margin-bottom: 15px;
  }
  .mobile-layout .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
  }
  .mobile-layout .modal-header {
    padding: 10px 21px 0;
  }
  .two-test-button {
    z-index: 9999;
  }
  .ml-2.ml-sm-0,
  .ml-3.ml-sm-0 {
    margin-left: 0 !important;
  }
  .mobile-view-ellipsis {
    width: auto;
  }
  .app-version {
    flex-direction: column;
  }
  .userinfo-demarcation {
    padding-top: 15px !important;
  }

  .new_dashboard_head .app-version .version-fontstyles {
    color: #7782a1;
  }
  .new_dashboard_head .app-version .version-fontstyles:first-of-type {
    margin-bottom: 15px !important;
  }
  .mobile-app-version strong {
    color: #1b2952;
    line-height: 1.5;
    display: block;
    font-size: 13px;
    font-family: "Axiforma-Regular";
  }
  .search-icon-spacing-mobile {
    display: block;
  }
  .sm-justify-between {
    justify-content: space-between;
    width: 100%;
  }
  .m-none {
    display: none;
  }
  .desk-none {
    display: block;
  }
  .new_dashboard_head .plan-name {
    margin-bottom: 7.5px !important;
  }
  .cus-mobile-txt {
    margin-bottom: 7px !important;
  }
  .cus-mobile-width svg {
    max-width: 83px;
  }
  .mb-3.cus-mobile-width {
    margin-bottom: 12px !important;
    margin-right: 12px !important;
  }
  .sm-order-2 {
    order: 2;
    width: 27% !important;
  }

  .flex-1 {
    flex-basis: 75%;
  }
  .flex-2 {
    flex-basis: 22%;
  }
  .mobile-grid {
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .sm-flex-wrap {
    flex-wrap: wrap;
  }
  .dealselect-btn-panel {
    height: auto;
  }

  .deal-info-coupons {
    width: 100%;
    padding: 10px 15px 15px;
    border: 0;
  }
  .deal-expiry-date {
    justify-content: start;
    display: flex;
  }
  .deal-plan-head {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 0px;
  }
  .mobile-container {
    padding: 0 15px;
  }
  .dealselect-btn-panel {
    border-radius: 7px;
    /* margin-top: 25px; */
  }
  .deal-plan-head h5.deal-activation-date {
    margin-bottom: 2px;
  }
  .deal-expiry-date h5 {
    margin-top: 10px;
  }
  .deal-count {
    align-self: start;
    margin-top: 5%;
    margin-right: 15px;
  }
  .deal-flex-bg {
    flex-basis: 68%;
  }
  .deal-count h5 {
    color: #7782a1;
  }
  .deal-coupon-head {
    border-radius: 3px;
  }
  .deal-plan-head h3 {
    font-size: 15px;
  }
  .deal-select-btn {
    padding: 0 15px 20px;
    width: 100%;
  }
  .transparent_background.dealselect_background {
    width: calc(100% - 30px) !important;
  }
  .d-flex.justify-content-between.align-items-center.dealselect-heading {
    padding: 0 15px;
  }
  .dealselect-heading h3 {
    font-size: 22px;
  }

  .btn.deal-selection-btn {
    width: 100%;
  }

  .checklist_first h2 {
    padding: 5px 0 21px 0px !important;
  }
  .w-100.sm-order-1 {
    order: 1;
    width: 73% !important;
  }
  a.store-link.mobile-view-ellipsis span {
    max-width: fit-content;
  }
  .new_dashboard_head .store-link {
    max-width: 95% !important;
  }
  .mob-responsive-appicon {
    flex: 1;
    flex-basis: 100%;
    width: 100% !important;
  }
  .topsectionapp h3.namespac {
    flex-basis: 80% !important;
    width: auto;
  }
  .color_picker .tooltip1 {
    right: -100px;
  }
  .mb-2.users-button-prespacing {
    width: 100%;
  }
  .sm-100,
  .banner-bottom-spacing {
    width: 100%;
  }
  .new-hover-element .new-hover-element-text.custom-hover-btn.cus-ischat {
    left: 41%;
    transform: translateX(-41%);
    z-index: 999;
  }

  .mobiletoaster {
    padding: 10px;
  }
  #showMoreText {
    width: 59%;
    margin: 2px auto 0;
    word-break: break-all;
  }
  .sm-mt-0 {
    margin-top: 2px !important;
  }

  .sm-mt-0 .pl-1 {
    padding-left: 0 !important;
  }
  .mobile-payment {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .mob-w-100 {
    width: 100% !important;
  }

  .two-btn-mobile {
    padding-bottom: 30px;
  }
  .signup-form-padding-mobile {
    padding: 24.5px 20px 0 20px;
  }

  .reset-password .signup_form {
    padding-top: 19px;
  }

  .reset-mobile-bg {
    width: 100%;
  }
  .password-page-mobile {
    padding: 0 21px 30px;
  }
  .right-reset-mobile {
    display: flex;
    flex-direction: column;
  }
  .main-mobileview {
    display: flex;
  }
  .menu-phone-icon.ipadview-menu-leftpanel {
    display: none !important;
  }
  .ipad-view-cross {
    display: none;
  }

  .removeprofile-mobile {
    margin-left: 18px;
  }
  .workspace-info-mobile-main {
    border-bottom: 1px solid #c8cedb;
  }
  .workspace-info-spacing-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
  .app_iconname.app-iconname-workspace-mobile h5 {
    display: block;
    margin-top: 12px;
    margin-bottom: 15px;
  }
  .profile-detail-btn.profile-detail-btn-mobile {
    border-bottom: none;
  }
  .your-info.your-info-mobile {
    margin-top: 19px !important;
    padding-left: 0;
    padding-right: 0;
  }
  .newupload-image-mobile {
    border-bottom: 1px solid #c8cedb;
  }
  .profile-image-mobileview {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 19px;
  }
  .headerdropdown.headerdropdown-spacing-mobile {
    left: -45px !important;
  }
  button.workspace-btn.workspace-btn-mobile {
    width: 100%;
    margin-top: 20px;
  }
  .white-bg {
    box-shadow: none;
  }
  .logo svg {
    width: 140px !important;
  }
}
a.deals-btn {
  color: #3064f9;
}
.see-more {
  border: none;
  outline: none;
  background: none;
  color: rgb(226, 0, 0);
  padding: 2px;
  text-decoration: underline;
}
#showMoreText {
  margin: 5px auto 0;
  max-width: 66%;
}

@media screen and (max-width: 768px) {
  .phone-menu.monitor-mobile-headpadding {
    margin-bottom: 0;
  }
  .monitor-img-mobile-view {
    width: 91%;
  }
  .release-notif-main-mobile {
    padding: 20px 0;
  }
  .release-notif-sub-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .release-notif-img-mobile {
    padding-left: 20px;
    padding-right: 9.5px;
  }
  .release-notif-text-mobile {
    padding-right: 20px;
    padding-left: 20px;
  }
  .release-notif-maintext-mobile {
    margin-bottom: 0;
  }
  .release-notif-subtext-mobile {
    margin-bottom: 0;
  }
  .close-release-notif-mobile {
    padding-right: 17px;
  }
  .deals-description-chart {
    flex-direction: column;
  }
  .mobile-view-toggle {
    flex-wrap: wrap;
  }
  .mobspace {
    padding-top: 8px;
  }
  .addonspac {
    margin-right: 24px;
    margin-left: 24px;
  }
  .downdspac {
    justify-content: flex-start !important;
  }
  .relnotespace {
    margin-right: 10px;
  }
  .mobilemain {
    flex-wrap: wrap;
  }
  .mobilesub {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }
  .mobileminor {
    width: 100%;
    margin-top: 12px;
  }
  .mobileform {
    flex-wrap: wrap;
  }
  .mobileform-sub {
    width: 100%;
    margin-bottom: 20px;
  }
  .mobileform-button {
    width: 100%;
  }
  form input.inputform-width {
    width: 100%;
    margin-right: 0;
  }
  form button.button-new-width {
    width: 100%;
  }
  .nameinfo-padding {
    width: 100%;
    padding-left: 0;
  }
  .versionspac.responsive-toggle-pad {
    margin-right: 0;
    padding-right: 20px;
  }
  .toggle-pad {
    margin-right: 0;
  }
  .right_panel.mobile-view-menu-length {
    padding-bottom: 2.5rem !important;
  }
  .sub-heading-spacing div.mobile-toggle-align {
    justify-content: flex-start !important;
    padding-top: 0;
  }
  .phone-menu.monitor-mobile-headpadding {
    margin-bottom: 0;
  }
  .monitor-img-mobile-view {
    width: 91%;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 40px;
  }
}

@media (min-width: 768px) {
  .app-created h5.mobile-view-nameinfo {
    padding-left: 42px;
  }
  .phone-host-view-ipad {
    flex-basis: auto;
  }
  .logsub.logsub-red {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 830px) {
  .nameinfo-padding {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (min-width: 883px) {
  .app-version .mobile-view-version {
    margin-right: 35px !important;
  }
}

@media screen and (min-width: 769px) {
  .downlspac {
    justify-content: space-between;
  }
}

@media (min-width: 980px) and (max-width: 1536px) {
  .redesign-border.d-xl-flex {
    display: block !important;
  }
  .analytics-status {
    margin: 10px 0px 20px 0px;
  }
  .app_dummy {
    width: 150px;
    height: 150px;
  }
  .two-btn-tabs .promotertabs {
    padding-top: 5px;
    margin-bottom: 0px !important;
  }
  .two-btn-tabs .promotertabs li a {
    font-size: 14px;
  }
  .topsectionapp h2 {
    font-size: 14px !important;
  }
  .topsectionapp h3 {
    font-size: 11px;
  }

  .blog_categories .color_picker .tooltip1 {
    right: -114px;
  }

  .verify-account {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
.blockuser {
  color: rgba(255, 255, 255, 0.95);
  /* background-color: #bcc1ce;
  border-color: #bcc1ce;
  cursor: default; */
}
.blockuser.next_btn:hover {
  box-shadow: none;
}
.frequency {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
@media screen and (max-height: 754px) {
  .height100 {
    height: auto;
  }
}
/*pixabay modal End*/
/*Image Slider*/

.image-slide {
  height: 100%;
  width: 100%;
  transition: background-image 0.3s ease-in-out;
}

.slide-arrow {
  border: 1px solid #7782a1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 350px;
  bottom: 0;
}

.slide-arrow.right {
  right: 1rem;
  background: url(../images/dashboard/leftarrow.png) no-repeat center;
  transform: rotate(180deg);
}

.slide-arrow.left {
  left: 1rem;
  background: url(../images/dashboard/leftarrow.png) no-repeat center;
}
/*Dashboard Page Starts*/

.dashboard-content .green_toggle label {
  padding: 0px;
}

.dashboard-contet .common-height {
  border-top: 1px solid #f1f5fc;
}

.dashboard-contet .common-height:last-child {
  border-bottom: 1px solid #f1f5fc;
}

.dashboard-mobile .white-mobile-border {
  overflow-y: scroll;
  -ms-overflow-style: none;
  width: 235px;
  scrollbar-width: none;
}
.dashboard-mobile .mainbanner {
  width: 235px;
  height: 111px;
  background-size: cover !important;
  background-position: center !important;
  padding: 0px 5px;
}
.dashboard-contet {
  margin-top: 35px;
}

.on_off a {
  text-decoration: none;
}

.edit-btn a {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.edit-btn.edit-btn-new a {
  position: relative;
}
.edit-btn a:hover {
  color: #7782a1;
}
.edit-btn a img {
  position: absolute;
  right: 25px;
  top: 30px;
}
.edit-btn.edit-btn-new a img {
  position: relative;
  right: 0;
  top: 0;
}
.menu-list-content .switch {
  z-index: 1;
}
.done-btn a {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border: 1px solid #7782a1;
  padding: 5px 14px;
  border-radius: 2px;
}

.done-btn a:hover {
  color: #3064f9;
  border: 1px solid #3064f9;
}

.rename-app {
  padding: 8px 15px;
}
.rename-app .guest-login-toggle h6:nth-child(3) {
  color: #3064f9;
  font-family: "Axiforma-Bold";
}
.dashboard-contet h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.dashboard-contet .green_toggle label {
  padding: 0px;
}

.customwidth .color_input {
  border: 1px solid rgba(188, 199, 230, 1);
}

.horizontal-alignment {
  background-color: #fff;
  border: 1px solid rgba(188, 199, 230, 1);
  border-radius: 0.25rem;
}

.horizontal-alignment .align {
  height: 55px;
}

.horizontal-alignment a {
  width: 33%;
  text-decoration: none;
}

.horizontal-alignment p {
  margin-bottom: 0px;
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.align-left,
.align-center {
  border-right: 1px solid #c8cedb;
}

.two-btn-tabs .categoryimage li a .big-tab {
  text-decoration: none;
  color: #bcc7e6;
  width: 100%;
  display: block;
  border: 1px solid #c8cedb;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  line-height: 70px;
  font-family: "Axiforma-Regular";
}

.mobile-create-new {
  justify-content: center;
}

.categoryimage li .active .big-tab.big-tab-1,
.categoryimage li .active .big-tab.big-tab-2 {
  color: #547df2 !important;
  width: 100%;
  display: block;
  border-radius: 4px !important;
  border-right: 1px solid #3064f9 !important;
  border: 1px solid #3064f9 !important;
}
.categoryimage li .active .big-tab.big-tab-1 #Rounded_Rectangle_2_copy_12 {
  fill: #f2f6ff;
  stroke: #3064f9;
}
.categoryimage li .active .big-tab.big-tab-1 #Group_2161,
.categoryimage li .active .big-tab.big-tab-1 #Rectangle_1500,
.categoryimage li .active .big-tab.big-tab-1 #Path_6088,
.categoryimage li .active .big-tab.big-tab-1 #Path_6087,
.categoryimage li .active .big-tab.big-tab-1 #Ellipse_259 {
  stroke: #3064f9;
}
.categoryimage li .active .big-tab.big-tab-2 #Path_6110,
.categoryimage li .active .big-tab.big-tab-2 #Path_6111,
.categoryimage li .active .big-tab.big-tab-2 #Line_6,
.categoryimage li .active .big-tab.big-tab-2 #Line_7,
.categoryimage li .active .big-tab.big-tab-2 #Line_8,
.categoryimage li .active .big-tab.big-tab-2 #Line_9 {
  stroke: #3064f9;
}
.categoryimage li .active .big-tab.big-tab-2 #Rounded_Rectangle_2_copy_12 {
  fill: #f2f6ff;
  stroke: #3064f9;
}
.categoryimage li .active .big-tab.big-tab-1 #Rounded_Rectangle_2_copy_12-2 {
  fill: #3064f9;
}
.categoryimage li .active .big-tab.big-tab-1 text {
  color: #fff;
}
.categoryimage li .active .big-tab.big-tab-2 #Shoes {
  fill: #000;
}
.two-btn-tabs .categoryimage li {
  width: 50%;
  font-size: 14px;
  background-color: white;
  line-height: 32px;
  border-radius: 4px;
  color: #3064f94d;
  font-family: "Axiforma-Bold";
}

.categoryimage p {
  margin-bottom: 0px;
  padding-left: 15px;
}

.categoryimage a {
  text-decoration: none;
}

.popup p {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  padding-bottom: 35px;
}

.white-mobile-border .top-header {
  height: 100px;
  width: 235px;
  background-color: black;
  padding-top: 30px;
  margin: auto;
  position: absolute;
  border-radius: 24px 24px 0px 0px;
}

.dashboard-mobile .white-mobile-border {
  padding: 0px;
}

.menu-icon p {
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-left: 10px;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.search-bar {
  height: 28px;
  width: 220px;
  border: 1px solid #fff;
  margin: auto;
  border-radius: 2px;
}

.search-bar p {
  color: #fff;
  font-size: 7px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.aligndiv {
  width: 33%;
  cursor: pointer;
  flex: 1;
}
.category-setting-dashboard a {
  font: 16px "Axiforma-Regular";
  padding-left: 7px;
}
.mainbanner {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.mainbanner p {
  color: #c8cedb;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  word-break: break-all;
}

.categoriesset p {
  color: #000000;
  font-size: 11px;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
}

.categoriesset {
  height: 175px;
  width: 300px;
  margin: auto;
  background-color: #fff;
  text-align: left;
}

.section-items {
  /* height: 250px; */
  width: 235px;
  margin: auto;
  /*background-color: #F8FAFE;*/
  text-align: left;
}
.category-top-strip {
  background-color: #f8fafe;
  height: 35px;
  line-height: 35px;
  padding: 0px 10px;
}
.category-top-strip .child-category {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #000;
  margin-left: 20px;
  margin-bottom: 0px;
}
.category-top-strip .parent-category {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #7581a1;
  margin-bottom: 0px;
}
.category-top-strip .parent-category svg {
  margin-left: 10px;
}
.pages-list ul,
.categoriesd-list ul {
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;
  background-color: #ffff;
}

.pages-list ul li,
.categoriesd-list ul li {
  border-bottom: 1px solid #d8ddee;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  height: 35px;
  line-height: 35px;
}

.dashboard-footer {
  background-color: #f6f9ff;
  border-top: 1px solid #d8ddee;
}

.dashboard-footer p {
  font-family: "Axiforma-Regular" !important;
  font-size: 11px;
  height: 35px;
  line-height: 35px;
  padding-right: 5px;
  color: #7581a1;
  margin-bottom: 0px;
}

.dashboard-footer img {
  width: 30%;
}

.pages-list ul li a,
.categoriesd-list ul li a {
  display: block;
  color: #000000;
  white-space: nowrap;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listarrow {
  position: relative;
}
.pages-list ul li a:after {
  content: url("../images/dashboard/rightper-new.png");
  position: absolute;
  right: 12px;
  transform: rotate(-90deg);
  transition: all 0.5s ease;
}
.listarrow:after {
  content: url("../images/dashboard/rightper-new.png");
  position: absolute;
  right: 12px;
  transform: rotate(-90deg);
  transition: all 0.5s ease;
  top: 0;
}

.pages-list ul .see-all-pages a,
.categoriesd-list ul .see-all-pages a {
  color: #3064f9;
}

.pages-list ul .see-all-pages {
  border-bottom: 0px;
}

.section-items .cat-product svg {
  width: 235px;
  height: auto;
}

.section-items p {
  color: #000000;
  font-size: 11px;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
}

.recently-viewed {
  height: 185px;
  width: 355px;
  margin: auto;
  background-color: #fff;
  text-align: left;
}

.recently-viewed p {
  color: #000000;
  font-size: 11px;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
}

.featured {
  height: 150px;
  width: 300px;
  margin: auto;
  background-color: #f8fafe;
  text-align: left;
}

.featured p {
  color: #000000;
  font-size: 12px;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
}

.new-arrival {
  height: 150px;
  width: 300px;
  margin: auto;
  background-color: #f8fafe;
  text-align: left;
}

.new-arrival p {
  color: #000000;
  font-size: 12px;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
}

.in-cart {
  height: 185px;
  width: 350px;
  margin: auto;
  background-color: #fff;
  text-align: left;
}

.width {
  width: 235px !important;
}

.recently-viewed .cat-product svg {
  width: 235px;
  height: auto;
}

.in-cart .cat-product svg {
  width: 235px;
  height: auto;
}

.in-cart p {
  color: #000000;
  font-size: 11px;
  font-family: "Axiforma-Bold" !important;
  margin-bottom: 0px;
}

.dashboard-contet .common-height {
  border-top: 1px solid #f1f5fc;
}

.dashboard-contet .common-height:last-child {
  border-bottom: 1px solid #f1f5fc;
}

.dashboard-contet {
  margin-top: 35px;
}

.on_off a {
  text-decoration: none;
}

.edit-btn a {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.done-btn a {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border: 1px solid #7782a1;
  padding: 5px 14px;
  border-radius: 2px;
}

.done-btn a:hover {
  color: #3064f9;
  border: 1px solid #3064f9;
}

.dashboard-contet h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.dashboard-contet .green_toggle label {
  padding: 0px;
}

.customwidth .color_input {
  border: 1px solid rgba(188, 199, 230, 1);
}

.horizontal-alignment {
  background-color: #fff;
  border: 1px solid rgba(188, 199, 230, 1);
  border-radius: 0.25rem;
}

.removeprofile p {
  color: #7782a1;
  font-size: 12px;
  cursor: pointer;
  font-family: "Axiforma-Regular";
  margin-top: 10px !important;
}

.horizontal-alignment a {
  width: 33%;
  text-decoration: none;
}

.horizontal-alignment p {
  margin-bottom: 0px;
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.align-left,
.align-center {
  border-right: 1px solid #c8cedb;
}

.two-btn-tabs .categoryimage li a .big-tab {
  text-decoration: none;
  color: #bcc7e6;
  width: 100%;
  display: block;
  border: 1px solid #c8cedb;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  line-height: 70px;
  font-family: "Axiforma-Regular";
}

.categoryimage li .active .big-tab {
  color: #547df2 !important;
  width: 100%;
  display: block;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #3064f9 !important;
}

.categoryimage li .big-tab.big-tab-2 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: 0px;
}

.categoryimage li .big-tab.big-tab-1 {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
}

.two-btn-tabs .categoryimage li {
  width: 50%;
  font-size: 14px;
  background-color: white;
  line-height: 32px;
  border-radius: 4px;
  color: #3064f94d;
  font-family: "Axiforma-Bold";
}

.categoryimage p {
  margin-bottom: 0px;
  padding-left: 15px;
}

.categoryimage a {
  text-decoration: none;
}

.popup p {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  padding-bottom: 35px;
}

.dashboard-mobile .white-mobile-border {
  padding: 0px;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}

.menu-icon p {
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-left: 10px;
}

.search-bar {
  height: 28px;
  width: 225px;
  border: 1px solid #fff;
  margin: auto;
  border-radius: 2px;
}

.search-bar p {
  color: #fff;
  font-size: 7px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.mainbanner {
  height: 102px;
  width: 232px;
  margin: auto;
  background-color: #f8fafe;
}

.mainbanner p {
  color: #c8cedb;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.categoriesset {
  height: 175px;
  width: 300px;
  margin: auto;
  background-color: #fff;
  text-align: left;
}

.recently-viewed p {
  color: #000000;
  font-size: 11px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}
.secureconnection .slider:before {
  bottom: 5px;
}
.secureconnection label {
  padding: 12px 22px !important;
}
.featured {
  height: 150px;
  width: 300px;
  margin: auto;
  background-color: #f8fafe;
  text-align: left;
}

.featured p {
  color: #000000;
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.new-arrival {
  height: 150px;
  width: 300px;
  margin: auto;
  background-color: #f8fafe;
  text-align: left;
}

.new-arrival p {
  color: #000000;
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.alignment svg {
  cursor: pointer;
}

.two-btn-tabs .categoryimage li {
  width: 50%;
  font-size: 14px;
  background-color: white;
  line-height: 32px;
  border-radius: 4px;
  color: #3064f94d;
  font-family: "Axiforma-Bold";
}

.categoryimage a {
  text-decoration: none;
}

.two-btn-tabs .categoryimage li a .big-tab {
  text-decoration: none;
  color: #bcc7e6;
  width: 100%;
  display: block;
  border: 1px solid #c8cedb;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: left;
  font-family: "Axiforma-Regular";
  position: relative;
}

.solid-categories p {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #c8cedb;
}

.categories-show.modal-body {
  padding: 0px 30px;
}

.blue-box {
  background-color: #5fa9fe;
  height: 150px;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.blue-box h4 {
  color: #fff;
  font-family: "Axiforma-Regular";
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0px;
}

.update-woo-plugin h2,
.categories-show h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 0px;
}

.woo-error {
  color: #ff4848 !important;
}

.publish-to-store a {
  width: 250px !important;
}

.publish-to-store a:first-child {
  color: #3064f9;
  background: white;
}

.publish-to-store a:first-child:hover {
  background: #3064f9;
  color: #fff;
}

.update-woo-plugin h3,
.categories-show h3 {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0px;
}

.categories-show h5 {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0px;
}

.modal-categories {
  padding-top: 15px !important;
}

.small-help {
  position: absolute;
  top: -17px;
  right: 12px;
}

.categoryimage.nav-pills .nav-link.active {
  background-color: white !important;
}

.categoryimage.nav-pills .nav-link {
  padding: 0px;
}

.categoryimage .active .big-tab h4 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.module_title h5 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 0px;
}

.big-tab h4 {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
}

.categoryimage .active .big-tab p {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  line-height: 20px;
}

.big-tab p {
  color: #c8cedb;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  line-height: 20px;
}
/*Dashboard Page Ends*/
/*Style Page Starts*/

.style-page-items {
  margin-top: 60px;
}

.style-mobile .top-header {
  height: 60px;
  width: 234px;
  background-color: black;
  padding-top: 30px;
}

.style-mobile {
  padding: 0px;
  overflow-y: scroll;
  display: block;
}

.menu-icon p {
  color: #fff;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.proceed-to-checkout p {
  margin-bottom: 0px;
  color: #000000;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Poppins-Regular";
}

.proceed-to-checkout {
  background-color: #f8d31e;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
  cursor: pointer;
}

.light-blue-cart {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 35px;
  box-shadow: 0px 1px 8px 0px rgba(25, 47, 107, 0.1);
}

.light-blue-cart h6 {
  color: #9ea9bb;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

ins {
  text-decoration: none;
}

.pt-checkout {
  background-color: #f8fafe;
}

.light-blue-cart p {
  color: #000000;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.cart-bottom {
  padding: 15px 10px;
  border-bottom: 1px solid #ebeef3;
}

/*Style Page Ends*/

/*App settings and social page Starts*/

.add-border {
  border: 1px solid #dbe4f3;
  border-radius: 4px;
}

.create-menu .forgot_pw .container1 input:checked ~ .checkmark {
  background-color: #3064f9;
}

.create-menu .forgot_pw .container1 .checkmark:after {
  color: #fff;
  border-color: #fff;
}

.social_pannel ul {
  padding-left: 0px;
  display: flex;
  margin-bottom: 0px;
}

.social_pannel ul li {
  list-style-type: none;
}

.social_pannel ul li a {
  color: #7782a1;
  padding: 10px 50px;
  font-family: "Axiforma-Bold";
  font-size: 15px;
  display: block;
  text-decoration: none;
}

.social_pannel ul li .active,
.social_pannel ul li a:hover {
  border-bottom: 2px solid #3064f9;
  padding-bottom: 20px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}

.module_title h5 {
  color: #1b2952;
  font-size: 14px;
  margin-bottom: 0px;
}
.premium_feature h5 {
  font-size: 20px;
}
.module_title p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}
.premium_feature p {
  font-size: 18px;
}
.social_pannel .common-height {
  padding-top: 40px;
  padding-bottom: 35px;
  border-bottom: 1px solid #f1f5fc;
}

.dashboard-content .common-height {
  border-bottom: 1px solid #f1f5fc;
}

.bill-pay-top-light {
  border-top: 1px solid #f1f5fc;
}

.social_settings .connectsocial a {
  color: #c8cedb;
  font-size: 14px;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  padding: 8px 15px;
  text-decoration: none;
  margin-left: 20px;
}

.social_settings .submitsocial a {
  color: #3064f9;
  font-size: 14px;
  border: 1px solid #3064f9;
  border-radius: 3px;
  padding: 8px 15px;
  text-decoration: none;
  margin-left: 20px;
}

.social_settings .cancelsocial a {
  color: #c8cedb;
  font-size: 14px;
  margin-left: 20px;
  text-decoration: none;
}

.social_settings h6 {
  margin-bottom: 0px;
  color: #1b2952;
  font-family: "Axiforma-Regular2" !important;
  font-size: 14px;
  line-height: 60px;
  margin-left: 12px;
}
.social-http {
  width: 90px;
  border-right: 1px solid #c8cedb;
  height: 60px;
  margin-right: 20px;
}
.socialinput .social_settings {
  width: 100%;
}
.socialinput input,
.socialinput input:focus {
  border: none;
  outline: none;
}

.socialinput input {
  width: calc(100% - 100px);
  padding-left: 0px !important;
}

.socialinput {
  border: 1px solid #c8cedb;
  border-radius: 3px;
  margin-bottom: 30px;
  height: 60px;
  padding: 5px 20px 5px 12px;
  line-height: 50px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.socialinput input::placeholder {
  color: #bcc7e6;
}

/*App settings and social page Ends*/

/*Payment Gateway Starts*/

.request-gateway {
  height: 122px;
}

.gateway-head p {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.gateway-head a {
  color: #3064f9;
}

.payment-gateway .container5 {
  display: flex;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  padding: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.payment-gateway .checkmark {
  position: absolute;
  border: 1px solid #bcc7e6;
  border-radius: 100%;
  top: 0;
  left: 100%;
  height: 23px;
  width: 23px;
  background-color: #fff;
}

.defaultpayment {
  position: absolute;
  top: 14px;
  right: 14px;
}

.notdefault {
  font-size: 14px;
  color: #c8cedb !important;
}

.green-check {
  position: relative;
}

.request-gateway {
  padding-left: 25px;
}

.payment-gateway .container5:hover input ~ .checkmark {
  background-color: #ccc;
}

.payment-gateway .container5 input:checked ~ .checkmark {
  background-color: #47ce70;
  border: none;
  color: white;
}

.payment-gateway .container5 input:checked ~ .notdefault {
  color: #1b2952 !important;
}

.payment-gateway .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container5 input:checked ~ .checkmark:after {
  display: block;
}

.container5 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.payment-box {
  border: 1px solid #c8cedb;
  border-radius: 3px;
  margin-top: 35px;
  position: relative;
  height: 146px;
}

.payment-box h4 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
}

.payment-box p {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.payment-box:hover {
  border: 1px solid #3064f9;
  box-shadow: 0px 3px 6px 0px rgba(48, 100, 249, 0.1);
}

.payment-gateway a:hover {
  text-decoration: none;
}

/*Payment Gateway Ends*/

.custom-modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 56px);
}

.shareapp h4 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}

.shareapp input {
  height: 60px;
}

.sharedetail p {
  color: #000000;
  font-family: "Axiforma-Regular";
  font-size: 15px;
  margin-top: 25px;
}

.sharedetail button {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  width: 160px;
  height: 50px;
  border-radius: 4px;
  margin-left: 10px;
  color: #fff;
  transition: 0.5s;
}

.sharedetail {
  margin-bottom: 10px;
}

.go_live .customiseappfeatures {
  width: 100% !important;
}

/*API credentials Starts*/

.big-tab.big-tab-1 {
  border-right-color: #fff !important;
}

.big-tab.big-tab-2 {
  border-left-color: #fff !important;
}

.copy_function .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  top: 55px;
  right: 10px;
}

.tooltip button {
  background-image: url(../images/dashboard/copy.png);
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  width: 56px;
  height: 20px;
  outline: none;
}

.apidescription h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-top: 10px;
}

.apidescription h5 {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 25px;
}

.apidescription h5 a {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
}

.plugin_img {
  text-align: center;
}

.plugin_content h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.plugin_content a {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 14px;
  text-decoration: none;
}

.plugin_content p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  width: 75%;
  margin: auto;
  margin-top: 20px;
}

/*API credentials Ends*/

/*Previewapp Starts*/

.test-on-real {
  width: 215px;
}

.two-apps {
  border: 1px solid #c8cedb;
  border-radius: 3px;
  text-align: left;
  padding: 33px;
  margin-top: 40px;
}

.two-apps h4 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}

.two-apps p {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 20px;
}

.two-apps .white_btn {
  color: #3064f9;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #3064f9;
  border-radius: 4px;
  transition: 0.5s;
  text-decoration: none;
  padding: 12px 20px;
  display: block;
  width: 200px;
  /* margin-top: 25px; */
  text-align: center;
}

.two-apps .white_btn:hover,
.two-apps .button_blue:hover {
  box-shadow: 0px 3px 6px 0px rgba(84, 125, 242, 0.5);
  text-decoration: none;
}

.two-apps .button_blue {
  color: #fff;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 4px;
  transition: 0.5s;
  text-decoration: none;
  padding: 12px 20px;
  display: block;
  width: 168px;
  text-align: center;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
  /* margin-top: 25px; */
}
/* Trouble New CSS Starts */
.trouble-tasks p {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
}
.trouble-tasks p span {
  font: 25px "Axiforma-Regular";
  color: #1b2952;
}
.test-item,
.test-result {
  width: 400px;
}
.test-message {
  width: calc(100% - 400px);
}
.test-result {
  position: relative;
}
.test-result .media {
  position: absolute;
  right: 17px;
  font-size: 12px;
}
.troubleshoot-table {
  padding: 10px 0px;
}
.trouble-head.troubleshoot-table {
  border-bottom: 1px solid #f1f5fc;
}
.trouble-head.troubleshoot-table li {
  min-height: auto;
  padding: 20px 0px 13px 0px;
}
.troubleshoot-table li h5 {
  font: 16px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}
.alternate-background .troubleshoot-table:nth-child(even) {
  background: #fcfdff;
}
li.test-item,
li.test-message,
li.test-result {
  font: 14px "Axiforma-Regular";
  min-height: 50px;
  padding: 0px 0px;
  color: #1b2952;
}
.test-result .app-version-draft {
  width: 50px;
}
.test-result .app-version-draft-code {
  width: 70px;
  color: #7782a1;
}
li.test-item svg {
  margin-right: 10px;
}
/* Trouble New CSS Ends */
.createtabs.upgradetabs li .active {
  color: #3064f9 !important;
  box-shadow: none;
  border: 1px solid #3064f9 !important;
}
#no-image {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0;
}
#null-image {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0;
}
/* Color Theme Starts */
.colortheme-detail {
  padding: 13px 10px 10px 10px;
}
.colortheme-detail h2 {
  font-size: 10px;
  font-family: "Poppins-Semibold";
  margin-bottom: 8px;
  text-align: left;
}
.colortheme-detail p {
  font-size: 10px;
  line-height: 17px;
  font-family: "Poppins-Regular";
  margin-bottom: 0px;
  text-align: left;
}

/* Color Theme Ends */
/* .rebuildbutton{
	background-color: #ffffff!important;
	border: 1px solid #3064F9!important;
	color: #3064F9!important;
} */
.textapp a {
  border: 1px solid #3064f9;
  border-radius: 3px;
  color: #3064f9;
  font-size: 14px;
  padding: 12px 20px;
  text-decoration: none;
  transition: 0.5s;
}

.textapp a:hover {
  box-shadow: 0px 3px 6px 0px rgba(84, 125, 242, 0.5);
}

.custom-modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 56px);
}

.shareapp h4 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}

.sharedetail p {
  color: #000000;
  font-family: "Axiforma-Regular";
  font-size: 15px;
  margin-top: 25px;
}

.sharedetail button {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  width: 160px;
  height: 60px;
  border-radius: 4px;
  margin-left: 10px;
  color: #fff;
  transition: 0.5s;
}

.sharedetail {
  margin-bottom: 10px;
}

.go_live .customiseappfeatures {
  width: 100% !important;
}
/*Previewapp Ends*/
/* App Submission Page Starts */
.app-meta .customiseappfeatures {
  width: 100% !important;
}

.app-heading h5 {
  font-size: 24px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}

.app-heading p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}

.app-meta h3 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}

.tool-tip a {
  margin-bottom: 10px;
  margin-left: 7px;
}

textarea {
  resize: none;
}

.meta-table {
  width: 48.5%;
}

.meta-table .color_picker {
  width: 100%;
}

.meta-table .container4 {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.meta-table .container4 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0px;
  width: 0px;
}

.meta-table .checkmark {
  position: absolute;
  border: 1px solid #bcc7e6;
  border-radius: 3px;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
}

.meta-table .container4:hover input ~ .checkmark {
  background-color: #ccc;
}

.meta-table .container4 input:checked ~ .checkmark {
  background-color: #fff;
}

.meta-table .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.meta-table .container4 input:checked ~ .checkmark:after {
  display: block;
}

.meta-table .container4 .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #547df2;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.meta-table .container4 p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #000;
  margin-bottom: 0px;
}

.container4 p a {
  text-decoration: none;
}

/* App Submission Page Ends */

/* My Apps Section Starts */

.my_apps {
  background-color: #fcfdff;
  height: calc(100% - 60px);
}

.my_apps_width .container {
  max-width: 1920px !important;
  margin-top: 110px;

  width: 90%;
}

.my-app-heading {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";

  margin-bottom: 7px;
}

.introstore {
  background: white;
  padding: 15px;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(84, 125, 242, 0.1);
  align-items: center;
  margin-top: 80px;
  position: relative;
  border-radius: 8px;
}

.introsecond .tips-heading {
  font-size: 19px;
  font-family: "Axiforma-Bold";
  color: #111426;
}

.introstore .clickable {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.introsecond .tips-text {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}

.app-name {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-top: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.green-active {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #47ce70;
  border: 1px solid #47ce70;
  background-color: #effff4;
  padding: 2px;
  width: 94px;
  margin: auto;
  border-radius: 18px;
  display: inline-block;
}

.three-dot a {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
}

.addedpackage {
  background: #3064f9 !important;
  color: white !important;
}

.addedpackage a {
  color: white;
}

.app-list {
  margin: 15px;
  width: 255px;
  border-radius: 8px;
  position: relative;
}

.dash-border {
  border: 1px dashed #c8cedb !important;
}

.app-grey-name {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-top: 22px;
  margin-bottom: 0px;
}

.tipsection {
  margin-bottom: 104px;
}

/* Thank You Page */
.thank-you-page h1 {
  font: 35px "Axiforma-Bold";
  color: #1b2952;
}
.divheight {
  height: 100%;
}
.thank-you-page p {
  font: 18px/24px "Axiforma-Regular";
  color: #1b2952;
}
/* Thank You Page */
.applinks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  font-family: "Axiforma-Regular";
}

.topsectionapp h2 {
  color: #1b2952;
  font-size: 16px;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 45px);
}

.topsectionapp {
  padding: 10px 15px;
}

.topsectionapp h3 {
  font-size: 11px;
  margin: 0px;
  width: 50%;
  color: #7782a1;
  text-transform: capitalize;
  font-family: "Axiforma-Regular";
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.topsectionapp h3.namespac {
  flex-basis: 77%;
  width: auto;
}

.topsectionapp p {
  font-size: 11px;
  color: #7782a1;
  margin: 0px;
  width: 30%;
  /* text-align: right; */
  font-family: "Axiforma-Regular";
}

.viewstatus {
  border-radius: 10px;
  width: 42px !important;
  text-align: center !important;
  height: 18px !important;
  line-height: 17px !important;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
/* Preview tab */
.preview-new-tab p {
  font: 16px "Axiforma-Regular";
  margin-bottom: 0px;
  color: #1b2952;
}
.preview-new-button {
  border: 1px solid #c8cedb;

  cursor: pointer;
  transition: 0.5s;
  width: 265px;
  border-radius: 2px;
  margin: 20px;
  height: 48px;
}
.preview-new-button p {
  font: 16px "Axiforma-Regular";
  margin-bottom: 0px;
  color: #7782a1;
}

.preview-new-button.form-group .http {
  width: 100%;
  border: none !important;
}
.preview-new-button.form-group .http select {
  width: 150px !important;
}
.preview-new-button:hover {
  box-shadow: 0 4px 6px rgba(119, 130, 161, 0.69);
}
.preview-new-button.form-group .http select {
  height: auto;
  border: 0px;
  color: #7782a1;
  width: 124px;
  padding-left: 0 !important;
  font-size: 13px;
  position: relative;
  top: -1px;
  background: url(../images/signup/down.png) 96%/8% no-repeat;
}
.preview-new-button.form-group .http.android-device select {
  width: 65px !important;
  background: url(../images/signup/down.png) 96%/15% no-repeat;
}
[title="Restart"],
[title="Screenshot"] {
  cursor: pointer;
}
.profileicon {
  background-image: url(../images/dashboard/profileicon.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 48px;
  height: 48px;
  background-size: contain;
  margin-right: 10px;
}

.profilecontent h4 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}

.profilecontent a,
.profilecontent a:hover {
  color: #fff !important;
  font-size: 13px;
  text-decoration: none;
}

/* My Apps Section Ends */

/*Addons Starts*/

.addonpage {
  width: 100% !important;
  margin-left: 0px !important;
}

.addonpage .container {
  width: 90%;
  max-width: 90%;
}

.addonpage h1 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 18px;
  margin-top: 8px;
}

.addonpackage {
  padding: 30px 40px;
}

.packageimg {
  width: 180px;
}

.packagecontent {
  width: calc(100% - 180px);
}

.packagecontent h3 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}

.packagecontent h5 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.packagecontent h6 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-top: 35px;
}

.packagecontent ul {
  padding-left: 0px;
}

.packagecontent ul li {
  list-style-type: none;
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-top: 15px;
}

.addonbox {
  box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
  background-color: #fff;
  margin-bottom: 30px;
}

.addonpackageprice {
  padding: 0px 30px 17px 30px;
}

.addonpackageprice svg {
  margin-right: 5px;
}

.addonpackageprice h4 {
  margin-bottom: 0px;
  color: #1b2952;
  font-size: 30px;
  font-family: "Axiforma-Bold";
}

.addonpackageprice h4 span {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.addonpackageprice p {
  margin-bottom: 2px;
  color: #47ce70;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-left: 10px;
}

.addonpackageprice .nothanks {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #fff;
  border: 1px solid #7782a1;
  border-radius: 3px;
  width: 100px;
  height: 40px;
}

.addonpackageprice .addedpackage {
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 3px;
  width: 100px;
  height: 40px;
}

.addonpackageprice .addpackage {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #f5f7ff;
  border: 1px solid #3064f9;
  border-radius: 3px;
  transition: 0.5s;
  width: 100px;
  height: 40px;
}

.addonpayment {
  background-color: #fff;
  border: 1px solid #e8edf8;
  padding: 30px;
}

.addonpayment h3 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
  margin-bottom: 30px;
}

.addonpayment h5,
.addonpayment h6 {
  color: #1b2952;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}

.addonpayment h2 {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-top: 10px;
}

.addonpayment h4 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  margin-top: 10px;
}
.addonpayment form {
  border-right: 1px solid #e8edf8;
}

.addonspurchased h4 {
  margin-top: 10px;
}

.addontotal h3 {
  margin-bottom: 0px !important;
}

.yamm_tabs .panel-body ul li a.active {
  background-color: gold;
  color: Black;
  font-weight: bold;
}

.addontotal h2 {
  margin-top: 0px !important;
}

.addonpayment button {
  color: #fff;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 3px;
  width: 100%;
  transition: 0.5s;
  height: 48px;
  margin-top: 15px;
}

/*Addons Ends*/

/* My Profile Page */

.biling-page label {
  font-family: "Axiforma-Regular";
}
.upload-image {
  width: 260px;
  text-align: center;
}
.your-info .customiseappfeatures {
  width: 100% !important;
}
.your-info {
  width: 100%;
  border-left: 1px solid #dce1eb;
  padding-left: 30px;
  padding-right: 30px;
}

.upload-image .draft {
  color: #3064f9 !important;
  padding: 5px 12px;
}

.remove-image {
  color: #7782a1 !important;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}

.gender {
  margin-top: 30px;
}

.gender-toolbar {
  margin: 5px;
}

.gender-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.gender-toolbar label {
  display: inline-block;
  background-color: #fff;
  width: 80px;
  line-height: 35px;
  height: 35px;
  font-size: 13px;
  border: 1px solid #d6dced;
  border-radius: 3px;
  cursor: pointer;
  color: #c8cedb;
}

.gender-toolbar input[type="radio"]:checked + label {
  background-color: #fff;
  border-color: #3064f9;
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.your-info h3 {
  color: #1b2952 !important;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}

.your-info h4 {
  color: #000000 !important;
  font-size: 15px;
  font-family: "Axiforma-Regular";
}

.datepickerva {
  height: 55px !important;
  padding: 0px !important;
  padding-left: 10px !important;
  cursor: pointer;
}

.datepickerva .react-date-picker__wrapper {
  width: 100%;
  border: none;
  height: 100%;
  height: 55px;
}

.datepickerva input {
  border: 0px;
  height: 100%;
}

.your-info .form-control {
  border-color: #c8cedb;
}

.select-input {
  border: 1px solid #c8cedb;
  border-radius: 3px;
}

.phonecodeselect {
  width: 100px !important;
  border: none !important;
  height: 55px !important;
  border-right: 1px solid #c8cedb !important;
  border-radius: 0px !important;
  padding: 15px;
  background-position: 85% !important;
  cursor: pointer;
}
/* Add On Starts */
.agree-terms.forgot_pw label {
  font-size: 14px;
}
.agree-terms.forgot_pw label .checkmark {
  top: 0px;
}
.payment-info .form-group input {
  height: 48px !important;
}
.three-input {
  width: 49%;
}
.expiry-month .color_picker input::placeholder {
  color: #c8cedb;
}
.expiry-month .color_picker {
  width: 48%;
}
.payment-info {
  padding-top: 20px;
  border-top: 1px solid #e8edf8;
}
.addonpayment .payment-info h3 {
  margin-bottom: 20px;
}
.modal-title svg {
  cursor: pointer;
}
.mobile-heading-modal {
  display: none;
}
.purchase-add-on-modal {
  padding: 30px 25px !important;
}
.publish-on-modal ul {
  list-style: none;
  padding-left: 0px;
}
.publish-on-modal span {
  color: #3064f9;
  font-size: 14px;
  cursor: pointer;
}
.publish-on-modal h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 20px;
}
.publish-on-modal h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 18px;
  margin-top: 35px;
}
.publish-on-modal ol {
  padding-left: 0px;
  list-style: none;
}
.publish-on-modal ol li {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 15px;
  font-size: 14px;
}
.publish-on-modal p {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 15px;
}
.add-on-plan-description {
  margin-bottom: 10px;
  padding: 0px 18px;
}
.add-on-plan {
  border: 1px solid #e3eafb;
  border-radius: 3px;
  text-align: center;
  padding-top: 50px;
  margin-right: 2%;
  margin-bottom: 2%;
  width: 47%;
  background-color: #fff;
  position: relative;
}
.add-on-plan p {
  color: #7782a1;
  height: 100px;
  font-family: "Axiforma-Regular";
  font-size: 15px;
  margin-bottom: 0px;
}
.add-on-plan p span {
  color: #3064f9;
  cursor: pointer;
}
.add-on-plan h4 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 18px;
  margin-top: 40px;
}
.add-on-plan-price {
  border-top: 1px solid #dce1eb;
  padding: 18px;
}
.add-on-plan-price p {
  margin-bottom: 0px;
  font-size: 12px;
  margin-left: 5px;
}
.add-on-plan-price .price-discount h3 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 0px;
}
.add-on-plan-price .price-discount h6 {
  color: #47ce70;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
  font-size: 12px;
}
.add-on-added {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 25px;
  font-weight: 500;
  border: 1px solid #3064f9;
  transition: 0.5s;
  cursor: pointer;
}
.add-on-not-added svg,
.add-on-added svg {
  margin-right: 5px;
}
.add-on-plan-price .price-discount h3 span {
  font-family: "Axiforma-Regular";
  font-size: 14px;
}
.add-on-not-added:hover,
.addpackage:hover,
.add-on-added:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.add-on-not-added {
  background-color: #f5f7ff;
  text-decoration: none;
  border-radius: 4px;
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 25px;
  font-weight: 500;
  border: 1px solid #3064f9;
  transition: 0.5s;
  cursor: pointer;
}

/* Add On Ends */
/* .select-input  .phonecodeselect{
	background: url(../images/signup/down.png)  90%/10% no-repeat;
	-webkit-appearance: none;
	font: 14px 'Axiforma-Regular';
	border: 1px solid rgba(188, 199, 230, 1);
    border-radius:4px ;
	height: 35px;    
	width: 100%;
	padding-left: 12px;
	outline: none!important;
} */
.select-input input {
  border: none;
}

.accountprofileimage {
  background-size: contain !important;
  background-position: center center !important;
  overflow: hidden;
  background-repeat: no-repeat !important;
  margin: auto;
  cursor: pointer;
}

.proimg {
  border-radius: 50%;
}

.select-input input:focus {
  border: none;
}

.select-input select:focus {
  border: none;
  border-right: 1px solid #c8cedb;
}

.your-info .next_btn {
  padding: 15px 20px;
}

.profile-detail-btn {
  border-bottom: 1px solid #dce1eb;
}

.billing-heading {
  margin-top: 35px;
}
.billing-heading-new {
  margin-top: 40px;
}

.not-subscribed {
  font-weight: 600;
  display: block;
}

.select-full select {
  width: 100%;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  background-position: 96%;
}

.delete-btn {
  background-color: #fff;
  border: 1px solid #c8cedb;
  color: #7782a1;
  font-size: 14px;
  border-radius: 4px;
  font-family: "Axiforma-Regular";
  padding: 15px 30px;
}

.del {
  padding-bottom: 65px;
}

.gender-toolbar .male-btn label {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.gender-toolbar .female-btn label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.del a {
  text-decoration: none;
}

.del a:hover {
  color: #7782a1;
}

.draft {
  padding: 2px 10px;
  margin-bottom: 0px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 18px;
  background: #f8faff;
}

.newupload-image {
  width: 260px;
  text-align: center;
}

/* My Profile Page Ends*/
/* Change Password Page */

.password-page {
  width: 100%;
}

.your-info input::placeholder,
.password-page input::placeholder {
  font-size: 13px;
}

.password-page .color_picker {
  width: 100%;
}

.change-password .your-info {
  border: none;
  width: 100% !important;
}

.change-password .profile-detail-btn {
  border-bottom: none;
}

.password-page h3 {
  color: #1b2952 !important;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-top: 1px;
  margin-bottom: 20px;
}

.password-page ul {
  list-style: none;
  padding-left: 0px;

  margin-bottom: 0;
}

.password-page ul li {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 18px;
}

.password-page ul li:last-of-type {
  margin-bottom: 0;
}

/* Change Password Page Ends */

/* Download Sample Page*/
.download-sample-app {
  margin-right: 20px;
}
.sampleappspac {
  margin-right: 0;
}
.download-sample-app p:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.download-sample-app p {
  cursor: pointer;
  transition: 0.5s;
  color: #3064f9;
  font-family: "Axiforma-Regular";
  text-transform: uppercase;
  margin-bottom: 0px;
  padding: 0px 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 39px;
  height: 39px;
  border: 1px solid #3064f9;
  border-radius: 4px;
}
/* Download Sample Page Ends */
/* Billing & Payment Page */

.payment-modal h5 {
  color: #1b2952 !important;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.hang-tight {
  width: 85%;
  margin: auto;
}

.biling-page .social_pannel ul {
  margin-bottom: 0px;
}

.biling-page .social_pannel ul li a {
  padding: 20px 50px;
  font-family: "Axiforma-Bold";
}
.biling-page .social_pannel ul li a.active {
  color: #3064f9;
}
.cus-text p {
  color: #1b2952;
}

.subscriptions p {
  margin-bottom: 0px;
}

.table-top p {
  color: #1b2952 !important;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.table-top {
  background-color: #fcfdff;
  border-bottom: 1px solid #dce1eb;
  padding: 10px;
}

.last-payment {
  width: 200px;
}

.next-payment {
  width: 250px;
}

.payment-method {
  width: 250px;
}

.payment-method span {
  color: #7782a1;
  font-size: 13px;
}

.payment-status {
  width: 100px;
  text-align: left;
}

.subscription-type {
  width: 380px;
  min-width: 380px;
}

.subscriptions .common-height {
  height: 90px;
  padding: 20px 10px;
}
.two-apps h4 span {
  color: #7782a1;
  font-size: 15px;
  font-family: "Axiforma-Regular";
}
.conecttoapi .cancel-btn {
  color: #3064f9;
  background-color: #fff;
  margin-right: 35px !important;
}
.conecttoapi.publish-app-modal .cancel-btn {
  color: #3064f9;
  background-color: #fff;
  margin-right: 0px !important;
  margin-left: 0px;
}
/* .conecttoapi.publish-app-modal .update-btn {
	margin-right: 35px!important;
} */

.conecttoapi .cancel-btn:hover {
  color: #fff;
  background-color: #3064f9;
}

.conecttoapi .website-type .cancel-btn {
  text-transform: uppercase;
  border: 1px solid #3064f9;
  border-radius: 4px;
  width: 160px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
}
.conecttoapi .website-type .update-btn {
  text-transform: uppercase;
  color: #fff;
  background-color: #3064f9;
  border-radius: 4px;
  width: 160px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
}
.conecttoapi .website-type .update-btn:hover {
  color: #3064f9;
  background-color: #fff;
  border: 1px solid #3064f9;
}
.change-website {
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  padding: 0px 20px;
}
.change-website p {
  color: #1b2952;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  font-size: 14px;
}
.change-website .edit-webiste {
  color: #3064f9;
  margin-left: 18px;
}
.dashboard-padding-15 .common-height {
  padding: 0px 15px 0px 44px !important;
}
.api-not-connected .conecttoapi h2 {
  font-weight: bold;
}
.api-not-connected .conecttoapi h3 {
  font-family: "Axiforma-Regular";
}
.api-not-connected .conecttoapi p {
  width: 35%;
}
.api-not-connected {
  padding: 180px 0px;
}

.subscriptions h4 {
  color: #1b2952 !important;
  font-size: 14px;
  font-family: "Axiforma-Bold";
}

.subscriptions p,
h5.expiry-date,
.custom-size {
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.mobile-payment h5.expiry-date {
  color: #7782a1;
}

.custom-size {
  margin-top: 30px;
  margin-bottom: 30px;
}

.subscription-type p a {
  text-decoration: none;
}

.payment-status .active-green {
  background-color: #eefef3;
  border: 1px solid #47ce70;
  color: #47ce70 !important;
  font-size: 11px !important;
  border-radius: 18px;
  font-family: "Axiforma-Regular";
  width: 65px;
  text-align: center;
  line-height: 22px;
  float: right;
  height: 22px;
}

.payment-status .inactive-grey {
  background-color: #f9f9f9;
  border: 1px solid #c8cedb;
  color: #7782a1 !important;
  font-size: 11px !important;
  border-radius: 18px;
  font-family: "Axiforma-Regular";
  width: 65px;
  text-align: center;
  line-height: 22px;
  float: left;
  height: 22px;
}

.payment-status .canceled-red {
  background-color: #fefcfc;
  border: 1px solid #ff4848;
  color: #ff4848 !important;
  font-size: 11px !important;
  border-radius: 18px;
  font-family: "Axiforma-Regular";
  width: 65px;
  text-align: center;
  line-height: 22px;
  float: left;
  height: 22px;
}

.bill-download.payment-status .canceled-red {
  float: right;
}

.biling-page .billingsearch input {
  border: none;
  height: 33px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.social_pannel .billingsearch {
  width: auto;
  height: 35px;
}

.billingsearch input {
  width: calc(100% - 40px);
}

.billingsearch button {
  width: 40px;
}
.profile_icon .dropdown-menu {
  z-index: 999 !important;
}
.subscriptions .product {
  max-width: 210px;
  width: 210px;
}
.product p:nth-of-type(2),
.subscription-type p {
  color: #7782a1;
  margin-top: 7px;
}
.next-payment p {
  margin-top: 7px;
}

.subscriptions .action {
  min-width: 75px;
  /* text-align: right;
  margin-right: 15px; */
}

/* Billing & Payment Page Ends */

/* Billing History Page */

.biling-page .month {
  width: 150px;
}

.bill-invoice {
  width: 227px;
  text-align: left;
}

.uploadedImageThumbnail img {
  max-width: 250px;
}

.bill-date {
  width: 15%;
}

.bill-app {
  width: 218px;
}

.bill-payment-method {
  width: 94px;
}

.bill-total {
  width: 95px;
}

.bill-download {
  width: 10%;
  text-align: right;
}

.biling-page .common-height {
  height: 90px;
  padding: 20px 20px;
}

.bill-invoice p {
  color: #1b2952 !important;
  font-size: 16px !important;
  font-family: "Axiforma-Regular";
}

.bill-total p {
  font-size: 14px !important;
  font-family: "Axiforma-Bold";
}

.search-box {
  border: 1px solid #c8cedb;
  height: 44px;
  border-radius: 3px;
}

.biling-page .search-box input {
  border: none;
  height: 33px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.biling-page .search-box .btn:focus {
  box-shadow: none;
}

.month select {
  background-image: url(../images/dashboard/grey-dropdown.png);
  background-repeat: no-repeat;
  background-position: 90%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular";
  border: 1px solid rgba(188, 199, 230, 1);
  height: 30px;
  border-radius: 0.25rem;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 0px;
}

.month select:focus {
  outline: none;
}

/* Billing History Page Ends */

/* Push Notification */

.push_notify .table_head {
  font: 16px "Axiforma-Bold";
  padding: 20px 50px !important;
}

.color_picker_full h6 {
  color: #c8cedb;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.notification-heading {
  color: #c8cedb;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  width: 100%;
}

.notifications h4 {
  color: #1b2952;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.notify-time p {
  color: #7782a1;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.notifications {
  width: 72%;
  margin: auto;
  position: absolute;
  padding: 8px;
  top: 60px;
  right: 0px;
  background-color: #fff;
  left: 0px;
  border-radius: 8px;
  z-index: 9999;
}

.push-notify .mobile_img:before {
  content: "";
  height: 99%;
  width: 78%;
  right: 0;
  margin: auto;
  position: absolute;
  border-radius: 21px;
  top: 34px;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.notification-title h2 {
  color: #1b2952;
  font: 12px "Axiforma-Bold";
  text-align: left;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.backselectRound:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  /*background: rgba(0, 0, 0, 0.4)*/
}
.backselectSharp:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 100%; */
  /*background: rgba(0, 0, 0, 0.4)*/
}
.backselectCircle:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  /*background: rgba(0, 0, 0, 0.4)*/
}

.notification-title p {
  color: #1b2952;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.notification-title {
  margin-top: 10px;
}

.notify-image p {
  color: #c8cedb;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: center;
}

.notify-image {
  height: 80px;
  width: 100%;
  border: 1px solid #dce1eb;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*Radio Button Starts*/

.push_notification .container_1 {
  display: block;
  border-radius: 40px;
  position: relative;

  min-height: 40px;
  line-height: 40px;
  padding-left: 45px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 16px;
  color: #7782a1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Axiforma-Regular";
}

.push_notification .container_1 input {
  display: none;
}

.push_notification .checkmark {
  position: absolute;
  top: 7px;
  left: 12px;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%;
  border-color: 2px solid #f1f5fd !important;
}

.push_notification .container_1:hover input ~ .checkmark {
  background-color: #ccc;
  border: none !important;
}

.push_notification .container_1 input:checked ~ .checkmark {
  background-color: #3064f9;
  border: none !important;
}

.push_notification .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.push_notification .container_1 input:checked ~ .checkmark:after {
  display: block;
}

.push_notification .container_1 .checkmark:after {
  top: 7px;
  left: 7px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.pointcursor {
  cursor: pointer;
}

#dateofbirth {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  border: 1px solid rgba(188, 199, 230, 1);
  padding-left: 10px;
  padding-right: 50px;
}

[type="date"] {
  background: url(../images/dashboard/date.png) 99% 50% no-repeat;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.notification-image label {
  height: 96px;
  background: #fff;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
}

.react-date-picker__clear-button {
  display: none;
}

.notification-image label {
  width: 100%;
}

.no-image-upload input {
  visibility: hidden;
  display: none;
}

.no-image-upload h4 {
  color: #000000;
  font-size: 14px;
  font-family: "Axiforma-Bold";
}

.no-image-upload p {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.color_picker_full h6 {
  color: #c8cedb;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.notification-heading {
  color: #c8cedb;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.notifications h4 {
  color: #1b2952;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}
.notify_img .create_own {
  width: 38px;
  height: 38px;
}
.notify_img .create_own .yf {
  margin-top: 5px;
}
/*  */
.date_picker .react-datetime-picker__wrapper {
  background-color: #fff;
  padding: 10px 15px;
  height: 55px;
  font-family: "Axiforma-Regular";
  border: 1px solid #c8cedb;
  outline: none !important;
  color: #1b2952;
  border-radius: 0.25rem;
}
.date_picker .react-datetime-picker__inputGroup__input {
  border: none;
  /* width: auto!important; */
  text-align: center;
}
.react-datetime-picker__inputGroup__input {
  width: 2.3em !important;
}
.date_picker .react-datetime-picker__inputGroup__input:focus {
  outline: none;
}
.date_picker .react-datetime-picker {
  width: auto;
}
.save_draft:hover {
  box-shadow: none;
}
.react-datetime-picker__inputGroup
  .react-datetime-picker__inputGroup__leadingZero {
  padding-left: 8px;
}
/*  */

.push-notify .mobile_img_android:before {
  content: "";
  height: 97%;
  width: 78%;
  right: 0;
  margin: auto;
  position: absolute;
  border-radius: 21px;
  top: 29px;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.push-notify .mobile_bg .mobile_head_android {
  top: 24px;
}
#myTabs .tab_mob {
  text-decoration: none;
  color: #bcc7e6;
  width: 100%;
  display: block;
  text-align: center;
  border-bottom: 2px solid #c8cedb;
  cursor: pointer;
}
.notify-time p {
  color: #7782a1;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
}

.notifications {
  width: 72%;
  margin: auto;
  position: absolute;
  padding: 8px;
  top: 60px;
  right: 0px;
  background-color: #fff;
  left: 0px;
  border-radius: 8px;
  z-index: 9999;
}

.push-notify .mobile-head {
  top: 29px;
}

.push-notify .mobile_img:before {
  content: "";
  height: 99%;
  width: 78%;
  right: 0;
  margin: auto;
  position: absolute;
  border-radius: 21px;
  top: 34px;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.notification-title h2 {
  color: #1b2952;
  font-size: 11px;
  font-family: "Axiforma-Bold";
  text-align: left;
  margin-bottom: 0px;
}

.notification-title p {
  color: #1b2952;
  font-size: 10px;
  margin-top: 3px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: left;
}

.bill-pay {
  border-bottom: 1px solid #dce1eb;
}

.notification-title h6 {
  color: #7782a1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: left;
  margin-top: 6px;
}

.notification-title {
  margin-top: 10px;
}

.notify-image p {
  color: #c8cedb;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  text-align: center;
}

.notify-image {
  height: 80px;
  width: 100%;
  border: 1px solid #dce1eb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-heading h3 {
  font-size: 14px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.published {
  padding: 2px 10px;
  margin-bottom: 0px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  color: #fff;
  border: 1px solid #47ce70;
  border-radius: 18px;
  background: #47ce70;
}

.scheduled {
  padding: 2px 10px;
  margin-bottom: 0px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  color: #c8cedb;
  border: 1px solid #c8cedb;
  border-radius: 18px;
}

.notification-heading p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 60px);
}

.draft {
  padding: 2px 10px;
  margin-bottom: 0px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 18px;
  background: #f8faff;
}

.notification-time {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
}

.notimage {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

.create-notify .common-height {
  height: 115px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.notify-y-n {
  width: 150px;
}

.notification-status {
  width: 250px;
}

.image-noftication {
  width: calc(100% - 750px);
}

/* Notification Page Ends */

/*Upgrade Starts*/

.upgradepage {
  width: 100% !important;
  margin-left: 0px !important;
}

.upgradesection {
  width: 1200px;
  margin: auto;
}

.upgradetabs {
  width: 100% !important;
  justify-content: flex-end;
}

.two-btn-tabs .upgradetabs li {
  width: 140px !important;
  text-align: center;
}

.planA {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
  text-align: center;
  padding: 38px 0px 42px 0px;
  border-top: 3px solid #3064f9;
}
.separate {
  color: #7782a1;
  font-size: 13px;
  padding-top: 19px;
  font-family: "Axiforma-Regular";
}

.planA h2,
.planB h2 {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  text-transform: uppercase;
}

.planA h4,
.planB h4 {
  color: #000;
  font-size: 15px;
  /* margin-top: 40px; */
  font-family: "Axiforma-Regular";
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .planA h4:nth-child(9){
	height: auto!important;
} */
.store_url_div {
  border-radius: 2px;
  background-color: white;
  border: 1px solid #c8cedb;
  outline: none !important;
  padding-left: 10px;
}
.store_url_div div {
  font-family: "Axiforma-Regular2";
  font-size: 14px;
}
.store_url_div input {
  border: none !important;
}
.website-view {
  text-align: center;
  height: 180px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.website-view h6 {
  color: #c8cedb;
  font: 11px "Axiforma-Regular";
  margin-top: 20px;
  margin-bottom: 0px;
}
.planA sup,
.planB sup {
  color: #1b2952;
  font-size: 26px;
  font-family: "Axiforma-Regular";
}

.planA p,
.planB p {
  color: #1b2952;
  font-size: 40px;
  font-family: "Axiforma-Bold";
  margin-top: 40px;
}

.planA span,
.planB span {
  color: #c8cedb;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  display: block;
}

.planB {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
  text-align: center;
  padding: 38px 0px 56px 0px;
  border-top: 3px solid #3064f9;
  background-image: url(../images/dashboard/popular.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.upgradeplan {
  color: #fff;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 3px;
  width: 130px;
  height: 40px;
  display: block;
  margin: auto;
  line-height: 40px;
  text-decoration: none;
  /* margin-top: 43px; */
  transition: 0.4s;
  box-shadow: 0px 3px 6px 0px rgba(84, 125, 242, 0.5);
}

.upgradeplan:hover {
  color: #3064f9;
  background-color: #fff;
  border: 1px solid #3064f9;
  text-decoration: none;
}

.planB .upgradeplan {
  margin-top: 56px;
}

.pricing-tabs .two-btn-tabs .createtabs li {
  margin: 0px;
}

.bigger-plan {
  border: 1px solid #ccd9ff;
  margin-top: 50px;
  padding: 40px;
  background-color: #fcfdff;
}

.bigger-plan h4 {
  color: #1b2952;
  font-size: 24px;
  font-family: "Axiforma-Bold";
}

.bigger-plan p {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.get-quote {
  padding: 10px 35px;
  border: 1px solid #3064f9;
  border-radius: 4px;
}

.get-quote a {
  color: #3064f9;
  font-size: 15px;
  font-family: "Axiforma-Regular";
  background-color: #fcfdff;
  text-decoration: none;
}

.upgrade-page {
  background-color: #fff;
}

.faq h2 {
  color: #1b2952;
  font-size: 36px;
  font-family: "Axiforma-Bold";
  text-align: center;
  margin-bottom: 80px;
  margin-top: 130px;
}

.faq h5 {
  color: #0f193b;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  font-weight: normal;
}

.faq p {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin-bottom: 10px;
}

/*Upgrade Ends*/
.price-update-top {
  border: 1px solid #8aa8ff;
  padding: 28px 10px;
  margin-bottom: 40px;
  border-radius: 4px;
}
.price-update-top h2 {
  font: 24px/35px "Axiforma-Bold";
  color: #000;
}
.price-update-top p {
  color: #7782a1;
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
}
.androidpublish {
  width: 180px;
}

.androidpublishright {
  width: calc(100% - 180px);
}

/* Add On Products Starts */

.app-security {
  border: 1px solid #dce1eb;
  border-radius: 3px;
  text-align: center;
  padding-top: 40px;
  height: 400px;
  position: relative;

  cursor: pointer;
}

.socialinput:first-child {
  margin-top: 15px;
}

.app-security h3 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-top: 25px;
}

.app-security p {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  padding: 10px 16px;
  margin-bottom: 0px;
}

.app-security a {
  text-decoration: none;
}

.add_app {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  border: 1px solid #3064f9;
  padding: 11px 18px;
  border-radius: 4px;
}

.add_app:hover {
  box-shadow: 0px 2px 3px rgba(112, 155, 250, 0.5);
  text-decoration: none;
}

.addonpack h4 {
  margin-bottom: 0px;
  color: #1b2952;
  font-size: 17px;
  font-family: "Axiforma-Bold";
}

.addonpack p {
  margin-bottom: 0px;
  color: #47ce70;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
  padding: 0px;
}

.addonpack span {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}

.subscribers p {
  margin-bottom: 0px;
  color: #7782a1;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  padding: 0px;
}

.total-subscriber {
  border-top: 1px solid #dce1eb;
  padding: 15px 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.addons .total-subscriber {
  position: relative;
}

.w-24 {
  width: 24%;
  margin-right: 1.33%;
  margin-bottom: 1.33%;
}
.w-24:nth-child(4n + 4) {
  margin-right: 0;
}

.section-theme .padding-15 {
  padding: 0px 78px 0px 42px !important;
}

.section-theme {
  margin-bottom: 35px;
  border: 1px solid #c8cedb;
  border-radius: 3px;
}

.categories-show .media {
  display: inline;
}

.modal-body .media {
  display: inline;
}

.installed-plugin .media {
  display: inline;
}

.add-image {
  width: 155px;
}

.add-details {
  width: calc(100% - 155px);
}

.add-details h4 {
  position: relative;
  line-height: 18px;
  margin-bottom: 25px;
}

.add-details .close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.addons p {
  color: #1b2952;
  font-size: 15px;
  font-family: "Axiforma-Regular";
  margin-bottom: 40px;
}

.addons h3 {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
}

.addons ul {
  padding: 0px;
  list-style: none;
}

.addons ul li {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding-bottom: 18px;
}

.addons.modal-body {
  padding: 0px;
}

.pop-modal p {
  margin: 0px;
}

.pop-modal .addonpack p {
  color: #47ce70;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  margin-bottom: 3px;
}

.pop-modal .addonpack h4 {
  color: #1b2952;
  font-size: 30px;
  font-family: "Axiforma-Bold";
}

.pop-modal .subscribers p {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}

.add-details a {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-decoration: none;
}

.addons .total-subscriber {
  padding: 15px;
}

/* Add On Products Ends */

/* Analytics Page Starts */

.grey-inactive {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  border: 1px solid #7782a1;
  background-color: #f9f9f9;
  padding: 2px;
  width: 45%;
  border-radius: 18px;
  text-align: center;
  line-height: 15px;
  margin-bottom: 0px;
}

.app-versions .green-active {
  margin-bottom: 0px;
  margin: initial;
}

.analytics .customiseappfeatures {
  width: 100% !important;
}

.month {
  width: 115px;
}

.analytics-data {
  width: 48%;
}

.analytics-data h3 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}

.analytics-data h2 {
  font-size: 20px;
  font-family: "Axiforma-Bold";
  color: #3064f9;
  margin-bottom: 0px;
}

.analytics-data p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}

.app-versions h3 {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  padding-bottom: 20px;
  margin-bottom: 0px;
  border-bottom: 1px solid #f1f5fc;
}

.version-name h3 {
  font-size: 16px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
  border-bottom: none;
  padding-bottom: 0px;
}

.version-update p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}

.version-update span,
.version-changes span {
  color: #1b2952;
}

.version-changes p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-align: center;
  color: #7782a1;
  margin-bottom: 0px;
}

.restore-version a {
  text-decoration: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  margin-bottom: 0px;
}

.version-name {
  width: 200px;
}

.version-update {
  width: 200px;
}
.app-version-draft {
  color: #ff4848;
  border: 1px solid #ff4848;
  background-color: #fff5f5;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  padding: 4px 7px;
  float: left;
  margin: auto;
  border-radius: 18px;
  text-align: center;
  line-height: 15px;
  width: 94px;
}
.app-version-grey {
  color: #7782a1;
  border: 1px solid #7782a1;
  background-color: #f9f9f9;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  padding: 4px 7px;
  float: left;
  margin: auto;
  border-radius: 18px;
  text-align: center;
  line-height: 15px;
}
.app-version-blue {
  color: #3064f9;
  border: 1px solid #3064f9;
  background-color: #fff;
  font-size: 12px;
  font-family: "Axiforma-Regular";
  padding: 4px 7px;
  float: left;
  margin: auto;
  border-radius: 18px;
  text-align: center;
  line-height: 15px;
}
.restore-version {
  width: 200px;
}

.version-changes {
  width: 200px;
}

.app-versions .common-height {
  height: 90px;
  border-bottom: 1px solid #f1f5fc;
}

/* Analytics Page Ends */

/* My Apps Section Starts */

.dash-border {
  /* background-image: repeating-linear-gradient(-15deg, #c8cedb, #c8cedb 5px, transparent 5px, transparent 10px, #c8cedb 10px), repeating-linear-gradient(75deg, #c8cedb, #c8cedb 5px, transparent 5px, transparent 10px, #c8cedb 10px), repeating-linear-gradient(165deg, #c8cedb, #c8cedb 5px, transparent 5px, transparent 10px, #c8cedb 10px), repeating-linear-gradient(255deg, #c8cedb, #c8cedb 5px, transparent 5px, transparent 10px, #c8cedb 10px);
background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
background-position: 0 0, 0 0, 100% 0, 0 100%;
background-repeat: no-repeat; */
  border: 1px dashed #c8cedb !important;
}

.my_apps {
  background-color: #fcfdff;
  height: calc(100% - 60px);
}

.my_apps_width .container {
  max-width: 1920px !important;
  margin-top: 114px;
}

.my-app-heading {
  color: #1b2952;
  font-size: 18px;
  font-family: "Axiforma-Bold";
}

.app-grey-name {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-top: 22px;
  margin-bottom: 0px;
}

.inside_image {
  position: relative;
  background-color: white;
  border-radius: 8px;
  height: 362px;
  /* box-shadow: 0px 0px 12px 0px rgba(84, 125, 242, 0.15); */
  border: 1px solid #e3e6f1;
  overflow: hidden;
}

.socila-pages {
  padding: 0px 15px;
}

.introstore {
  background: white;
  padding: 15px;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(84, 125, 242, 0.1);
  align-items: center;
  margin-top: 80px;
  position: relative;
  border-radius: 8px;
}

.introsecond .tips-heading {
  font-size: 19px;
  font-family: "Axiforma-Bold";
  color: #111426;
}

.introstore .clickable {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.introsecond .tips-text {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}

.app-name {
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-top: 22px;
  margin-bottom: 16px;
}

.green-active {
  font-size: 12px;
  font-family: "Axiforma-Regular";
  color: #47ce70;
  border: 1px solid #47ce70;
  background-color: #effff4;
  padding: 4px 7px;
  margin: auto;
  border-radius: 18px;
  text-align: center;
  line-height: 15px;
}

.subsbutton {
  margin-right: 35px;
}
.three-dot a {
  position: absolute;
  top: 20px;
  right: 20px;
}

.app-list {
  margin: 1%;
  width: 18%;
  border-radius: 8px;
}

.onhoverparent {
  position: relative;
  transition: all 0.5s ease;
  overflow: hidden;
}

.onhoverdata {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.64);
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hoverdashboard p {
  background: #3064f9;
  width: 144px;
  height: 44px;
  line-height: 44px;
  display: block;
  color: white;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 0px;
}

.appimg {
  transition: all 0.5s ease;
  overflow: hidden;
}

.finalselection div p {
  cursor: pointer;
  width: 117px;
  height: 43px;
  text-align: center;
  border: 1px solid #7782a1;
  border-radius: 4px;
  line-height: 40px;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 10px;
  color: #7782a1;
}

.finalselection div:last-child p {
  border-color: #ff4848;
  background: #ff4848;
  color: white;
}

.alertconfirm {
  width: 100px;
  text-align: center;
}
.rebuild-app {
  background-color: #ffffff !important;
  border: 1px solid #3064f9 !important;
  color: #3064f9 !important;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  border-radius: 4px;
  transition: 0.5s;
  text-decoration: none;
  padding: 12px 20px;
  display: block;
  width: 168px;
  text-align: center;
}
.confirmtext h6 {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.confirmpopup {
  background: #c8cedb;
  border-radius: 0px;
}

.confirmpopup p {
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  margin-top: -4px;
  color: #1b2952;
  font-size: 20px;
}

/* .onhoverparent:hover .appimg {
  transform: rotate(20deg) scale(1.3);
} */

.onhoverparent:hover .onhoverdata {
  opacity: 1;
}

.deleteapps {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.cart-bottom input:focus {
  border: none;
}

/* My Apps Section Ends */

.request-gateway {
  border: 1px solid #7782a1;
  height: 44px;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
}

.request-gateway h4 {
  color: #7782a1;
  font-size: 14px;
  margin-bottom: 0px;
}

.payment-name p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #7782a1;
}

.removedetail {
  font-size: 14px;
  color: #7782a1;
  cursor: pointer;
}

.addetails {
  font-size: 14px;
  color: #3064f9;
  margin-top: 8px;
  font-family: "Axiforma-Regular";
}
.updateversion {
  background: #eef2ff;
  border-radius: 2px;
  padding: 10px 0px;
  margin: -30px -20px 30px -15px;
}
.updateversion p {
  color: #1b2952;
  padding: 0px;
  margin: 0px;
  font-family: "Axiforma-Bold";
  font-size: 12px;
}
.updateversion p span {
  color: #3064f9;
  cursor: pointer;
}
.fileuploaded {
  background: #f4f6f9 !important;
  border-color: #e4e8ef !important;
  color: #c8cedb !important;
}
.blog_categories1 .cat-product.solid-category p {
  border: 1px solid #ededed;
}
.labcur {
  margin: 0px;

  display: inline;
  padding: 0px;
  position: relative;
  top: -2px;
  cursor: pointer;
}
.bottom_menu_bar {
  background-color: #3064f9;
  padding: 0px 10px;
  position: sticky;
  bottom: -1px;
  left: 0px;
  right: 0px;
}
.bottom_menu_bar.bottom_menu_bar_responsive {
  justify-content: center !important;
}
.bottom_menu_bar p {
  margin-bottom: 0px;
  font: 9px "Axiforma-Regular";
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 50px;
}
.bottom_menu_bar.bottom_menu_bar_responsive p {
  font-size: 8px;
  width: 47px;
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .redeemsmall {
    width: 315px;
    height: 218px;
  }
  .redeemsmallspace {
    padding-left: 162px;
    padding-top: 112px;
  }
  .redeemspacing1 {
    padding-left: 171px;
  }
}
@media screen and (min-width: 1367px) {
  .redeembig {
    width: 315px;
    height: 218px;
  }
  .redeembigspace {
    padding-left: 203px;
    padding-top: 140px;
  }
  .redeemspacing2 {
    padding-left: 214px;
  }
}
@media (min-width: 1200px) and (max-width: 1450px) {
  .web_logo svg {
    width: 32px;
    height: 32px;
  }
  .pannel_title h4 {
    font-size: 14px;
  }
  .panel-body ul li {
    margin-bottom: 8px;
  }
  .modal-body.review-modal ul {
    width: 90%;
  }
  .modal-body.review-modal ul li span {
    padding: 0px 15px 15px 15px;
  }
  .modal-body.review-modal h4 {
    font-size: 18px;
  }
  .modal-body.review-modal {
    padding: 0px 15px 15px 15px;
  }
  .modal-body.review-modal li span img {
    width: 80%;
  }
  .overlay-text {
    width: 206px;
    height: 443px;
    top: 25px;
  }
  .categoriesset .category-list .cat-product {
    width: 47.8%;
  }
  /* Today CSS 03.04 Starts */
  .guest-login-toggle h6 {
    width: 95%;
  }
  /* Today CSS 03.04 Ends */
  /* Today CSS 01.04 Starts */

  .yamm_tabs .panel-body ul {
    margin-bottom: 0px;
  }
  .leftscroll .common-height {
    height: 40px;
  }
  .leftscroll {
    padding-top: 10px;
  }
  .panel-body ul li {
    margin-bottom: 8px !important;
  }
  .yamm_tabs .panel-body ul li a {
    font-size: 13px;
  }
  .leftscroll .common-height .web_logo svg {
    width: 31px;
  }
  .plandata {
    margin: 0px 15px 10px 0px;
  }
  .pannel_title h4 {
    font-size: 14px;
  }
  .conecttoapi div {
    margin-bottom: 15px !important;
  }
  .social_pannel .bill-pay li a {
    padding: 0px 50px;
    padding-bottom: 15px !important;
  }
  /* Today CSS 01.04 Ends */
  .modal-body-content {
    height: 420px !important;
  }
  .app_dummy {
    width: 159px;
  }
  .add-on-plan {
    width: 45%;
  }
  .add-on-plan:nth-child(3n + 3) {
    margin-right: 2%;
  }
  .w-24 {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
  .w-24:nth-child(3n + 3) {
    margin-right: 0;
  }
  .w-24:nth-child(4n + 4) {
    margin-right: 2%;
  }

  /* Mobile */
  .customiseappfeatures {
    width: calc(100% - 400px) !important;
  }
  .authenticate #clock {
    width: 206px;
  }
  .logindesignbutton h5,
  .logindesignbutton,
  .loginsocialbtn {
    height: 30px;
    line-height: 30px;
  }
  .buttontextcolor {
    height: 30px;
    line-height: 30px !important;
  }
  .slide-arrow.left {
    left: 20px;
  }
  .slide-arrow {
    top: 295px;
  }
  .slide-arrow.right {
    right: 20px;
  }
  .sidespac {
    padding-left: 20px;
  }
  .customiseapp {
    width: 400px;
    right: 30px;
  }
  .mobilepreview {
    right: 0;
    left: 10px;
  }
  .customiseapp .two-btn-tabs .promotertabs {
    padding-top: 5px;
    margin-bottom: 10px !important;
  }
  .mobile_bg,
  .hollow_phone {
    width: 290px;
    height: 534px;
  }
  .hollow_phone .defaultimage {
    height: 46px !important;
  }
  .mobile_img .mobimg {
    height: 534px;
  }
  .white-mobile-border {
    height: 475px;
    top: 28px;
    width: 228px;
  }
  .mobile-head {
    top: 21px;
    width: 205px;
  }
  .module_title h5 {
    font-size: 14px;
  }
  .yamm_tabs .panel-body ul li a {
    font-size: 13px;
  }
  .categoriesset p {
    font-size: 10px;
  }
  .style-mobile .top-header,
  .dashboard-mobile .white-mobile-border,
  .white-mobile-border .top-header,
  .dashboard-mobile .mainbanner {
    width: 228px;
  }
  .search-bar {
    width: 195px;
  }
  .section-items {
    width: 228px;
  }
  .premade_icon {
    width: 30%;
    margin-top: 138px;
  }
  .create_own .yf {
    font-size: 6px;
  }
  .create_own #applogo {
    width: 34px;
    height: 34px;
    margin-left: 0px;
  }
  #clock {
    top: 50px;
  }
  .loginotheroptions p span,
  .loginotheroptions p {
    font-size: 10px;
  }
  .left_panel {
    width: 200px;
  }
  .transparent_background {
    width: calc(100% - 200px);
    margin-top: 50px;
    margin-left: 200px;
  }

  .videopart .datashow {
    padding-top: 0px !important;
  }
  .download-plugin .install {
    margin-left: 25px;
  }
  .settophead {
    top: 70px !important;
  }

  .topsectionapp h3.namespac {
    flex-basis: 61%;
    width: auto;
  }
  .topsectionapp p {
    width: 39%;
  }

  .platswitch {
    bottom: -85px;
  }
  .newswitch {
    bottom: -75px;
  }

  .custom-radio-btn [type="radio"]:checked + label:before,
  .custom-radio-btn [type="radio"]:not(:checked) + label:before {
    right: 5% !important;
  }

  .store-url2 {
    min-height: 520px;
  }

  .store-url3 {
    min-height: 430px;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 125px !important;
  }

  .custom-radio-btn [type="radio"]:checked + label:after,
  .custom-radio-btn [type="radio"]:not(:checked) + label:after {
    right: 14px;
  }
  .inner-radio .form-check-label.form-label-text2 {
    padding-right: 175px !important;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 27px;
  }
  .credit-card-list [type="radio"]:checked + label:before,
  .credit-card-list [type="radio"]:not(:checked) + label:before {
    right: 5%;
  }
}
.addonpara h4 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
  margin: 30px 0px;
}
.addonpara p {
  color: #676b77;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  padding-right: 50px;
}
.addonpara > div {
  width: 33.33%;
  margin-bottom: 70px;
}
.select_payment h4 {
  color: #000000;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  margin-top: 0px;
}
.selectcard {
  border: 1px solid #c8cedb;
  background: white;
  border-radius: 3px;
  width: 212px;
  color: #7782a1;
  font-size: 14px;
  padding: 0px 10px;
}
.select_payment a {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.select_payment svg {
  position: relative;
  top: -2px;
}
.select_payment select {
  font-family: "Axiforma-Regular";
  font-size: 15px;
}
@media screen and (max-height: 700px) {
  .leftscroll {
    overflow: auto;
    height: 450px;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .switch {
    height: 22px !important;
    width: 44px !important;
  }
  .style-mobile .top-header {
    width: 313px !important;
  }
  .right_general.my-app-preview .apppreview {
    height: 665px !important;
    position: relative;
    width: 350px;
    margin-top: 105px;
  }
  .my-app-preview .apphead {
    width: 272px !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
  .right_general.my-app-preview .newbox .white-mobile-border {
    width: 272px !important;
    height: 590px !important;
    left: 0px;
    top: 193px;
    border-radius: 32px !important;
  }
  .prodessection .addtocartbtn {
    position: fixed;
    width: 272px !important;
    z-index: 999;
    bottom: auto !important;
    margin-top: 550px !important;
  }
  .prodessection .addtocartbtn a button {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }
  .signup-form {
    width: 97% !important;
  }
  .align-mobile {
    padding: 10px;
    margin-top: 0px !important;
    line-height: 25px !important;
  }
  .color_input,
  .color_picker .form-name,
  .color_picker .form-group select {
    height: 60px !important;
  }
  .slide-arrow.right,
  .slide-arrow.left {
    top: 350px !important;
  }
  .two-btn-tabs {
    padding-top: 0px !important;
  }
  .backcheck {
    margin-top: 6px !important;
  }
  .create_own #applogo {
    width: 48px;
    height: 48px;
  }
  .small_mobile_img {
    width: 35%;
  }
  .premade_icon {
    margin-top: 365px;
  }
  .color_picker.ipad-margin-top {
    margin-top: 28px;
    padding: 0px;
  }
  .customiseappfeatures .fixed-button .back_btn {
    display: none !important;
  }
  .customiseapp {
    top: 65px !important;
  }
  .white-mobile-border {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
  }
  .borbot .ipad-top-margin-zero {
    margin-top: 15px !important;
  }
  .ipad .ipad-custom-padding,
  .dashboard-content {
    padding: 10px 18px 5px 18px !important;
  }
  .light-blue-cart {
    bottom: 154px !important;
  }
  .cart-items {
    padding: 25px 25px 25px 0px;
  }
  .proceed-to-checkout {
    bottom: 109px !important;
  }
  .style-page-cart .white-mobile-border {
    top: 50px !important;
  }
  .dashboard-mobile .white-mobile-border {
    top: 40px !important;
  }
  .mainbanner {
    height: 200px;
  }
  .cat-product.ipad-block {
    display: block !important;
    margin-top: 0px !important;
  }
  .ipad .ipad-block {
    display: block !important;
    margin-top: 0px;
  }
}
.sketch-picker input {
  box-shadow: none !important;
  border: 1px solid rgba(188, 199, 230, 1) !important;
  border-radius: 2px !important;
  -webkit-appearance: none !important;
}
.productspage {
  position: relative;
}
.productbadge {
  position: absolute;
  right: 0;
  top: 0;
}
.apidev {
  justify-content: flex-end;
}
/* Bundle Id Starts */
.app-exists-icon.android-icon svg #Path_7961:hover {
  fill: #01c99b;
  transition: 0.5s;
}
.app-exists-icon.ios-icon svg #Union_292:hover {
  fill: #f68751;
  transition: 0.5s;
}
[dataclass~="appsexists"] .modal-header {
  padding: 20px 25px 0px 25px;
}
[dataclass~="appsexists"] .modal-body {
  padding: 20px 25px 10px 25px;
}
.app-exists-modal {
  margin-left: 15px;
}
.app-exists-modal h3 {
  font: 24px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 5px;
}
.app-exists-modal h5 {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
}
.app-exists-modal h5 .media {
  display: inline;
}
.app-exists p {
  font: 14px "Axiforma-Regular";
  color: #1b2952;
  margin: 0px;
}
.app-exists-icon {
  cursor: pointer;
}
/* Bundle Id Ends */
.seeall {
  display: none;
}
.billingform label {
  font: 16px "Axiforma-Regular";
}
/* Country Starts */
[dataclass~="countrycode"] .container1 {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 10px;
  font-family: "Axiforma-Regular";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[dataclass~="countrycode"] .country-name {
  margin-bottom: 0px;
  padding-left: 10px;
}
.myradio {
  height: 400px;
  overflow-y: scroll;
}
.myradio::-webkit-scrollbar {
  width: 5px !important;
}
.myradio::-webkit-scrollbar-thumb {
  background-color: #a3acbf;
}
[dataclass~="countrycode"] .country-line {
  padding: 0px 20px;
}
[dataclass~="countrycode"] .container1 {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 10px;
  font-family: "Axiforma-Regular";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
[dataclass~="countrycode"] .container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
[dataclass~="countrycode"] .checkmark {
  position: absolute;
  top: 0;
  right: 25px;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #c8cedb;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
[dataclass~="countrycode"] .container1:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
[dataclass~="countrycode"] .container1 input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
[dataclass~="countrycode"] .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
[dataclass~="countrycode"] .container1 input:checked ~ .checkmark:after {
  display: block;
}
.container1 input:checked ~ .merge-parent-mobile {
  border: 1px solid red !important;
}

/* Style the checkmark/indicator */
[dataclass~="countrycode"] .container1 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
[dataclass~="countrycode"] h3 {
  font: 24px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 5px;
}
.code-selector .search-box input {
  border: 1px solid #c8cedb;
  height: 50px;
  border-radius: 5px;
  margin: auto;
  width: 100%;
  box-shadow: none;
  z-index: 1;
}
.select-input .phonecodeselect {
  background: url(../images/signup/down.png) 90%/10% no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.code-selector .search-box {
  width: 100%;
  border: 0px;
}
.search-box .form-control {
  border: none;
  height: 50px;
}
.search-box .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.search-box .form-control::placeholder {
  font-size: 18px;
  color: #c8cedb;
}
/* Country Ends */
.three-input .color_picker_full select {
  height: 48px;
}
.three-input select:focus {
  outline: 0px;
}
.annual-plan-offer {
  height: 50px;
  padding: 0px 10px;
  line-height: 50px;
  background-color: #3064f9;
  border-radius: 3px;
  justify-content: center;
  cursor: pointer;
}
.annual-plan-offer h5 {
  font: 17px "Axiforma-Bold";
  color: #fff;
  margin: 0px 20px 0px 0px;
}
.annual-plan-offer h5 span {
  font-family: "Axiforma-Regular";
  color: #fff;
  margin-bottom: 0px;
}
.pro {
  color: #c8cedb !important;
  display: inline-block !important;
  font-size: 40px !important;
  text-decoration: line-through;
  margin-right: 10px;
}
.forstrip {
  padding-top: 80px;
}

.item-customizer.pro {
  color: inherit !important;
  display: inherit !important;
  font-size: inherit !important;
  text-decoration: none;
  margin-right: 0;
}
.add-color {
  background: red !important;
}
.add-color p,
.add-color .resend-email {
  color: white !important;
}
.add_user_quantity {
  border: 1px solid #c8cedb;
  border-radius: 4px;
  width: 120px;
  height: 40px;
}
.add_user_quantity div:nth-child(1) p,
.add_user_quantity div:nth-child(3) p {
  color: #7782a1;
  font-size: 24px;
  font-family: "Axiforma-Bold";
  cursor: pointer;
  width: 39px;
  height: 40px;
  line-height: 40px;
}
.add_user_quantity div:nth-child(2) p {
  color: #1b2952;
  font-size: 16px;
}
.canceladdon {
  color: #7782a1;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  display: inline-block;
}
.active_btn {
  background-color: #6fe493;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  font-weight: 500;
  border: 1px solid #6fe493;
  line-height: 15px;
  text-transform: uppercase;
  transition: 0.5s;
  height: 39px;
}
.downgrade_button {
  background-color: #ff4848;
  text-decoration: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  padding: 11px 30px;
  font-weight: 500;
  border: 1px solid #ff4848;
  line-height: 15px;
  text-transform: uppercase;
  transition: 0.5s;
  height: 39px;
}
.user_status {
  width: 100px;
}
.user_status p {
  font-size: 15px;
  color: #1b2952;
  display: flex;
}
.user_status p span {
  color: #7782a1;
  margin-left: 5px;
}
.dashboardheadrcss {
  padding: 0px 78px 0px 43px;
}
.youtubevid .modal-content {
  background: none !important;
  border: none !important;
}
.youtubevid .crossmodal {
  right: 0px;
  top: 0px;
}
.app_iconname {
  margin-top: -10px;
}
.skipbtn {
  width: 130px !important;
  font-size: 13px !important;
}
/* New Dashboard */
.checklist_first {
  background: #f8fafe;
}
.checklist_first h2 {
  margin: 0px !important;
  padding: 20px 0px 20px 26px;
}
.new_dashboard_head {
  border-top: 1px solid #dce1eb;
}
.new_dashboard_head h3 {
  color: #1b2952;
  font: 20px "Axiforma-Bold";
  margin-bottom: 0px;
  word-break: break-word;
}

.new_dashboard_head h4 {
  color: #7782a1;
  font: 14px "Axiforma-Regular";
}
.new_dashboard_head .version-fontstyles {
  font: 13px "Axiforma-Regular";
}
.new_dashboard_head h5 {
  color: #c8cedb;
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  word-break: break-word;
}
.new_dashboard_head h6 {
  color: #1b2952;
  font: 14px "Axiforma-Bold";
  word-break: break-all;
}

.new_dashboard_head .store-link {
  color: #3064f9;
  font: 14px "Axiforma-Regular";
  max-width: fit-content;
}
.store-link.ipad-view-ellipsis {
  max-width: none;
}
a.store-link span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 17px);
}
.custom-flex {
  display: flex;
  align-items: center;
}
.new_dashboard_head .app-version p,
.new_dashboard_head .app-version a {
  color: #1b2952;
  font: 13px "Axiforma-Regular";
}
.new_dashboard_head .change-plan,
.see-plan {
  color: #3064f9;
  font: 14px "Axiforma-Regular";
  text-decoration: underline;
}
.new_dashboard_head .plan-name {
  margin-bottom: 15px;
}
.action-dropdown.form-group {
  width: 90px;
}
.action-dropdown.form-group select {
  height: 30px;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  color: #7782a1;
  font: 14px "Axiforma-Regular";
  padding-left: 10px !important;
  background-position: 90%;
}
.leftselection {
  width: 330px;
  padding: 25px 12px 9px 25px;
}
.leftselection li {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  cursor: pointer;
  position: relative;
}
.leftselection li.active:after {
  content: "";
  background: url("../images/newdashboard/active.png") no-repeat;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 14px;
  width: 10px;
}
.leftselection h3,
.rightselection h3 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
}
.leftselection p {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.leftselection a {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
  text-decoration: underline;
}

.rightselection {
  width: calc(100% - 330px);
  border-left: 1px solid #dce1eb;
  padding: 24px 20px 12px 30px;
}
.rightselection h4 {
  color: #1b2952;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}
.getstartedd {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #3064f9;
}
.chechlist h2 {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 20px;
}
.chechlist > span {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-left: 10px;
}
.rightselection p {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}
.rightselection li,
.rightselection li a {
  font-size: 16px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  position: relative;
  z-index: 1;
}
.rightselection li:before {
  background: url("../images/newdashboard/dots.png");
  content: "";
  height: 40px;
  width: 2px;
  display: inline-block;
  position: absolute;
  left: 11px;
  /* right: 0; */
  z-index: 2;
  top: 24px;
}
.rightselection li:last-child:before {
  content: none;
  height: 0px;
  width: 0px;
}
.new_dashboard_head .app-created {
  text-align: right;
  width: 165px;
}
.new_dashboard_head .userinfowidth {
  width: 100%;
}
.app-left {
  width: calc(100% - 165px);
}

/*language*/
.language-we-support .language-head {
  font: 22px "Axiforma-Bold";
  color: #1b2952;
  padding: 15px 20px;
}
.overview-right .flags-div h4 {
  margin-left: 15px;
  font: 15px "Axiforma-Regular";
  color: #1b2952;
}
.flags-div {
  width: 33%;
  padding: 12px 0px;
}
.flags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flags-new .flags-div h4 {
  margin-left: 15px;
  font: 15px "Axiforma-Regular";
  color: #1b2952;
}
/* Login Page */
.signup-new-form .image-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-account-app h6 {
  width: 100%;
}
.consent-content h3 {
  color: #333333;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.consent-content p {
  color: #a1a1a1;
  font-size: 10px;
  font-family: "Axiforma-Regular";
}
.consent-button {
  background-color: #3064f9;
  border-radius: 3px;
  height: 30px;
}
.consent-button .buttontextcolor {
  color: #fff;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  height: 30px;
}

.consent-button-close p {
  color: #a1a1a1;
  font-size: 11px;
  font-family: "Axiforma-Light";
}
.social-btn-list {
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  width: 100%;
  background: white;
}
.social-btn-list .social-image {
  padding: 3px 10px;
  border-right: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
}
.social-btn-list p {
  color: #a1a1a1;
  margin: 0px;
  width: calc(100% - 40px);
  text-align: center;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  line-height: 30px;
}
/* .or-account p::before,
.or-account p::after {
	display: inline-block;
    content: "";
    border-top: 1px solid #E4E4E4;
    width: 4rem;
    margin: 0px 10px;
} */
.or-account p {
  color: #e4e4e4;
  font: 12px "Axiforma-Regular";
  margin-bottom: 0px;
  width: 44px;
}
.forpara {
  font-family: "Axiforma-Regular";
}
.skip_login {
  position: absolute;
  color: #a1a1a1;
  top: 22px;
  right: 10px;
  font: 12px "Axiforma-Regular";
}
.settophead {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 70px;
  z-index: 9;
  width: 213px;
}
.setnewtophead {
  z-index: 8;
}
.sign-up-form-selection {
  display: block;
  align-items: center;
  height: 198px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.sign-up-form-selection-not-select {
  display: flex;
  align-items: center;
  height: 100%;
  bottom: 0px;
  top: 45px;
}
.freespan {
  color: #c8cedb;
  font: 11px "Axiforma-Regular";
  border: 1px solid #c8cedb;
  border-radius: 20px;
  margin-left: 10px;
  padding: 1px 5px;
}
.paidspan {
  color: #47ce70;
  font: 11px "Axiforma-Regular";
  border-radius: 20px;
  margin-left: 10px;
  padding: 1px 5px;
  background: #effff4;

  min-width: 42px;
  display: inline-block;
  text-align: center;
}
.paidspan-free {
  color: #7782a1;
  font: 11px "Axiforma-Regular";
  border: 1px solid #7782a1;
  border-radius: 20px;
  margin-left: 10px;
  padding: 1px 5px;
  background: #fff;
  min-width: 42px;
  display: inline-block;
  text-align: center;
}
.paidspan-inactive {
  color: #e20000;
  font: 11px "Axiforma-Regular";
  border: 1px solid #e20000;
  border-radius: 20px;
  margin-left: 10px;
  padding: 1px 5px;
  background: #fff;
  min-width: 42px;
  display: inline-block;
  text-align: center;
}
.changebackground {
  border: none !important;
}
.app-iconimg img {
  border-radius: 5px;
}
.error-description p,
.error-description span {
  color: white !important;
  font-size: 15px;
  font-family: "Axiforma-Regular" !important;
}
.error-description p {
  position: relative;
  top: 0.5px;
}
.error-description span {
  width: 43px;
  text-decoration: underline;
  cursor: pointer;
}
.lefterror {
  width: calc(100% - 40px);
}
.connection-status p {
  font: 20px "Axiforma-regular" !important;
  color: #7782a1;
  margin-bottom: 0px;
}
.connection-status p span {
  font-size: 14px !important;
}
.newwidthd {
  width: 207px;
}
/* Hide the browser's default checkbox */
.container3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container3 {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -ms-user-select: none;
  user-select: none;
}
/* Create a custom checkbox */
.container3 .checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0px;
  left: 83%;
  height: 25px;
  width: 25px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container3 .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container3 input:checked ~ .checkmark:after {
  display: block;
}
/* .container3 input:checked < .related_to {
	background: blue;
  } */
/* Style the checkmark/indicator */
.container3 .checkmark:after {
  right: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #3064f9;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 600px) {
  .setheg {
    min-height: auto !important;
  }
  .related_to {
    width: 100%;
    margin-bottom: 10px;
  }
  .related_to:nth-child(2) {
    margin: 0px;
    margin-bottom: 10px;
  }
  .container3 .checkmark {
    left: 86%;
  }
  .chatleft,
  .chatright {
    width: 100%;
    margin-bottom: 20px;
  }
  .chatleft .common-height,
  .chatright .common-height {
    margin: 0px !important;
  }
  .innerchat {
    padding: 0px 20px;
  }
  .selectcard {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
  }
  .buybutton {
    width: 100% !important;
  }
  .rightsidepreview {
    width: 100%;
  }
  .ui_selection li {
    width: 50%;
  }

  .ad-mob {
    margin: 10px;
  }
  .ui_selection li a {
    color: #1b2952;
    font-size: 16px;
    font-family: "Axiforma-Bold";
    width: 100%;
    display: block;
    text-align: center;
    padding-bottom: 10px;
  }
  .createtext {
    width: 100%;
    margin-bottom: 10px;
  }
  .app-left,
  .app-created {
    width: 100%;
  }
  .checklist_first h2 {
    padding-left: 0px;
  }
  .leftselection li.active::after {
    transform: rotate(90deg);
  }
  .leftselection {
    border-bottom: 1px solid #dce1eb;
    margin-bottom: 20px;
  }
  .checklist_first {
    padding-bottom: 5px !important;
  }
  .hollow_phone {
    background-image: none !important;
    width: 100%;
  }
  .youtubevid iframe {
    height: 200px;
  }
  .sign-up-form-selection {
    top: 42%;
  }
  .sign-up-form-selection-not-select {
    top: 0px;
  }
  .settophead {
    position: absolute;
    left: 0px;
    right: 0;
    margin: auto;
    top: -6px;
    z-index: 9;
    width: 93%;
  }

  .dashboardheadrcss {
    padding: 0px 14px 0px 13px;
  }
  .editlabel {
    display: block !important;
  }
  .user-status {
    margin-right: 10px;
  }
  .changeicon {
    height: auto !important;
  }
  .upload-provision h3 {
    font-size: 14px;
  }
  .salebadge {
    display: block;
  }
  /* Connectivity history */
  .api-pop-error h4 {
    font-size: 14px;
  }
  .forstrip {
    padding-top: 21px;
  }
  .connection-status p {
    font: 14px "Axiforma-regular" !important;
    padding-left: 6px;
  }
  .connection-status p span {
    font-size: 13px !important;
  }
  .connection-date .keys {
    margin-left: 0px;
    margin-top: 10px;
  }
  .modal-api .connection-date .keys {
    margin-top: 0px;
  }
  .connection-date .keys p {
    margin-left: 0px;
  }
  .connection-history h3 {
    font-size: 15px;
  }
  .connection-table {
    padding: 13px 0px;
    flex-wrap: wrap;
  }
  .connection-table .connection-date {
    flex-wrap: wrap;
    align-items: flex-start !important;
    width: 100%;
  }
  .only-mobile-conn {
    margin-left: 24px;
  }
  .only-mobile-conn .connection-status,
  .only-mobile-conn .connecion-details {
    width: auto;
  }
  /* Connectivity history end */
  .country_strip {
    height: auto !important;
    padding: 10px 0px 10px 0px;
  }

  .buildbtn,
  .supportipad {
    width: 50% !important;
  }
  .showwebstrip {
    height: auto !important;
    padding: 10px 0px 10px 0px;
    margin: 0px 15px 15px 15px;
  }
  .showalertonmobile {
    position: absolute;
    right: 0px;
    top: -6px;
  }
  .tocapital {
    margin-top: 0px !important;
  }
  .overview-right h2 {
    font-size: 18px;
  }

  .profile-detail-btn .next_btn {
    width: 100%;
  }
  [dataclass~="categorypop"] .modal-content {
    position: relative !important;
  }
  .overview-right h2 {
    font-size: 22px;
  }
  .mobile-store {
    text-align: center;
  }
  .module_title .showbread h6,
  .module_title h5 {
    font-size: 14px;
  }
  .custom-pages {
    font-size: 13px;
  }

  .note-section h4 {
    margin-left: 0px !important;
  }
  .note-section {
    padding: 10px;
  }
  .three-section.three-section-mob {
    width: 100% !important;
    display: block;
  }
  .overview-right h2 {
    font-size: 22px;
  }
  .add-on-m .col-12 {
    padding: 0px;
  }
  .subcategories-selection h5 {
    white-space: nowrap;
    width: 178px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* MY APPS */
  .app-list .inside_image .onhoverparent .appimg {
    background-size: cover !important;
    width: 84px !important;
    height: 84px !important;
    border-radius: 7px;
  }
  .creatapps {
    color: #3064f9;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 10px;
    font: 16px "Axiforma-Regular";
    margin-top: 2px;
  }
  .app-list .dash-border {
    border: 1px solid #3064f9 !important;
    border-radius: 3px;
    height: auto !important;
  }
  .app-list {
    /* border: 1px solid #bcc7e6 !important; */
    border-radius: 8px;
    margin: 0px 0px;
    /* box-shadow: 0px 0px 6px 0px rgba(84, 125, 242, 0.15); */
  }
  .app-list:first-child {
    border: 0px !important;
    margin-top: 0px;
  }
  .hoverdashboard {
    height: 100%;
  }
  .first-app h4 {
    margin: 5px 0px 3px 10px;
    color: #3064f9;
  }
  .onhoverparent {
    width: 100px;
  }
  .onhoverparent .onhoverdata {
    display: none;
  }
  .topsectionapp {
    width: calc(100% - 100px);
    padding: 0px 5px;
  }
  .inside_image {
    height: 110px !important;
    box-shadow: none;
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  /* MY APPS */
  [dataclass~="trouble"] .modal-content {
    position: relative !important;
    top: 40px;
  }
  .annual-plan-offer h5 {
    font-size: 15px;
    padding-right: 5px;
  }
  .annual-plan-offer {
    justify-content: space-between !important;
  }
  .annual-plan-offer h5 span {
    display: block;
  }
  .webpages {
    padding-bottom: 60px;
  }
  .payment-info .three-input {
    width: 100%;
  }
  .divheight {
    height: 70%;
  }
  .apidev {
    margin-top: -10px;
  }
  .version-name {
    width: 82px;
  }
  .restore-version,
  .version-update {
    width: 111px;
  }
  .analytics-app-download {
    width: 130px;
  }
  .downloadaab {
    width: 100% !important;
  }
  .setheight {
    display: none;
  }
  .planA {
    height: auto;
  }
  .seeall {
    font-size: 14px;
    color: #547df2;
    cursor: pointer;
    display: block;
  }
  [dataclass~="emailsupport"] .modal-content {
    position: relative !important;
  }
  .troubleshoot-table {
    padding-left: 15px;
  }
  .test-result .media {
    position: relative;
  }
  .troubleshoot-table li:nth-child(3) {
    min-height: auto;
    justify-content: flex-start !important;
    padding-right: 54px;
    left: 16px;
    padding-top: 8px;
  }
  .troubleshoot-table li:nth-child(2) {
    padding-left: 30px;
  }

  .my-apps-search .search-my-app {
    height: auto;
  }
  .my-apps-search button {
    width: auto;
  }
  .create_app .first-app {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .first-app h4 {
    font-size: 14px;
  }
  .first-app img {
    width: 50%;
  }
  .inside_image.dash-border {
    height: auto;
    padding: 10px;
  }
  .my-apps-search .form-group input,
  .my-apps-search .form-group {
    width: 100%;
  }
  .connectmobile {
    display: none;
  }
  .apidev {
    justify-content: start;
  }
  .mobilerequire {
    background: #e8effe;
    margin: 0px -15px;
    margin-bottom: 15px;
  }
  .mobilerequire ul {
    padding-left: 30px !important;
  }
  .mobilerequire h6 {
    color: #1b2952;
    font-family: "Axiforma-Bold";
  }
  [dataclass~="iosformupload"] .modal-content {
    position: relative !important;
  }
  .premium_feature h5 {
    font-size: 18px;
  }
  .trouble-shoot-steps ul li {
    padding: 8px 0px 8px 10px;
  }
  .trouble-shoot-steps ul li:first-child {
    padding-top: 15px;
  }
  .troubleright {
    border-right: none;
  }
  .trouble-shoot-steps ul {
    padding: 0px !important;
  }

  .addcartbtn .addtocartbtn {
    position: fixed;
    left: 0px;
    right: 0px;
  }
  [dataclass~="trouble"] .modal-content {
    position: relative !important;
  }
  .apivi {
    width: auto !important;
  }
  .coloumnreverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .completesteps h4 {
    font-family: "Axiforma-Bold";
    color: #1b2952;
    font-size: 24px;
    width: 100%;
    margin: auto;
    margin-top: 5px;
  }

  .completesteps {
    text-align: center;
  }

  .completesteps img {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .completesteps p {
    color: #1b2952;
    font-size: 15px;
    font-family: "Axiforma-Regular";
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
  }
  .buttonbox {
    background-color: #3064f9;
    border-radius: 4px;
    width: 210px;
    margin: auto;
    height: 48px;
    line-height: 48px;
    margin-bottom: 50px;
  }

  .buttonbox p {
    color: #fff;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-transform: uppercase;
  }
  .for-mobile {
    margin-top: 70px;
  }
  .mobleft:before {
    content: none;
  }
  .section-theme {
    margin-left: 5px;
    margin-right: 5px;
  }
  .section-theme {
    border-radius: 0px;
    margin-bottom: 20px;
  }
  .green-circle {
    background-color: #74c270;
  }
  .confirmpopup {
    border-radius: 4px !important;
  }
  .footer {
    display: none;
  }
  .api-details {
    margin-top: 18px;
    margin-bottom: 50px;
  }
  .api-details-responsive {
    margin-top: 0;
  }
  .dasboard_page.mobile-display .app-settings {
    min-height: 700px !important;
  }

  .profile_icon .headerdropdown .dropdown-item:first-child {
    padding: 0px 0px 0px 18px !important;
  }
  .profile_icon .headerdropdown .dropdown-item a {
    padding: 15px 0px 15px 0px !important;
  }
  .customiseapp {
    position: fixed !important;
  }
  .mobile_bg {
    width: 380px !important;
  }
  .create_own #applogo {
    width: 53px;
    height: 53px;
    border-radius: 12px;
  }
  .small_mobile_img {
    width: 100%;
    padding-left: 30px;
    top: 66px;
  }
  .create_own {
    width: 53px;
    height: 53px;
    border-radius: 12px;
  }
  .premade_icon {
    width: auto;
  }
  .create_own .yf {
    margin-top: 7px;
  }
  .style-mobile .top-header {
    border-radius: 0px;
  }
  .hollow_phone {
    background-image: none !important;
  }
  .mobile-head {
    display: none;
  }
  .white-mobile-border {
    width: 100% !important;
    height: 100%;
    border-radius: 0px !important;
  }
  .right_panel {
    overflow-x: hidden;
  }
  .fixback {
    position: fixed !important;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    /* width: 100%; */
    top: 0;
    z-index: 99999999999;
  }
  .mobileok {
    background: white;
    text-align: center;
    /* padding-bottom: 9px; */
    border-radius:4px;
    /* padding-top: 5px; */
    width: 90% !important;
    margin: auto;
    border: unset;
    color: #fff;
    height: 40px;
    font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Axiforma-Regular';
  }
  .sketch-picker {
    width: 90% !important;
    margin: auto;
  }
  .mobileok p {
    background: #3064f9 !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 95%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
  }
}
.dashboard-mobile .pages-list ul li a {
  pointer-events: none;
}

.app-not-verified h4 {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Axiforma-Bold";
  color: #7782a1;
}
.app-verified h4 {
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Axiforma-Bold";
  color: #7782a1;
}
.app-verified {
  height: 40px;
  border: 1px solid #47ce706b;
  padding: 0px 15px;
  border-radius: 100px;
}
.app-not-verified {
  height: 40px;
  border: 1px solid #c8cedb;
  padding: 0px 15px;
  border-radius: 100px;
}
.verify-grey {
  color: #ff4848;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 5px !important;
}
/* media  */
.plandata p {
  text-transform: capitalize;
  color: #1b2952;
  font: 11px/15px "Axiforma-Regular";
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plandata p span {
  color: #7782a1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
}
.plandata {
  background-color: #f4f7fd;
  margin: 0px 15px 20px 0px;
  padding: 8px 10px;
  border-radius: 3px;
}
.plandata a {
  color: #3064f9;
  font: 11px/15px "Axiforma-Regular";
}
[dataclass~="buildiosform"] .modal-header a {
  color: #3064f9;
  text-decoration: underline;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}
[dataclass~="buildiosform"] .modal-header {
  padding-bottom: 0px !important;
}
.grey-content-top {
  border-top: 1px solid #e8edf8;
}
.downloadaab {
  color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  width: 168px;
  text-align: center;
}
.downloadaab:hover {
  color: #3064f9;
}
#androidform .form-group input::placeholder {
  color: #c8cedb;
  font-size: 16px;
}
#androidform .form-group .nxt-version p {
  color: #7782a1;
  font: 13px "Axiforma-Regular";
  margin-top: 5px;
  margin-bottom: 0px;
}
.reset-btn {
  color: #3064f9;
  font: 16px "Axiforma-Regular";
  margin-right: 40px;
  cursor: pointer;
  margin-bottom: 0px;
}
.upload_files.reset-bottom {
  padding: 20px 25px;
}
.reset-body p {
  color: #1b2952;
  font: 16px "Axiforma-Regular";
  margin: auto;
  width: 80%;
  margin-bottom: 0px;
}
.provision-certificate {
  padding: 20px 0px 0px 0px !important;
  /* max-height: 500px;	 */
  overflow-y: auto;
}
.modalreset-footer .next_btn {
  width: 100%;
}
.cancelbuild {
  font-size: 14px;
  color: #ff4848;
  margin-left: 60px;
  font-family: "Axiforma-Regular";
}
.cancelbuild span {
  cursor: pointer;
}
/* Addon Dedicated Page */
.three-section {
  border: 1px solid #dce1eb;
  border-radius: 3px;
  padding: 60px 0px;
  text-align: center;
  width: 33%;
  margin-right: 2%;
}
.three-section:last-child {
  margin-right: 0px;
}
.overview-right h4 {
  font: 16px "Axiforma-Bold";
  color: #1b2952;
}
.overview-left-up p {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
  margin: 0px;
}
.overview-left-up p span {
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.overview-left-data {
  padding: 10px 5px;
  border-top: 1px solid #e2e6f1;
}
.overview-left-up .get-support {
  margin: 20px 0px;
}
.overview-left-up .get-support p {
  font: 14px "Axiforma-Regular";
  color: #3064f9;
  margin: 0px 8px;
}
.overview-left h3 {
  font: 20px "Axiforma-Bold";
  color: #1b2952;
  margin-top: 15px;
  margin-bottom: 0px;
}
.overview-left-up h4 {
  font: 16px "Axiforma-Regular";
  color: #1b2952;
  margin-top: 10px;
  margin-bottom: 25px;
}
.overview-left-up h4 span {
  font: 12px "Axiforma-Thin";
  color: #7782a1;
}
.addon-dedicated-page-list {
  list-style: none;
  padding: 0px;
  margin-bottom: 35px;
}
.addon-dedicated-page-list li {
  margin-right: 30px;
  position: relative;
  font: 16px "Axiforma-Regular";
  color: #1b2952;
}
.addon-dedicated-page .container {
  max-width: 90% !important;
  /* margin-top: 80px; */
}
.addon-dedicated-page .addon-layout {
  max-width: 100% !important;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding-top: 30px;
}
.addon-dedicated-page-list li:after {
  content: ">";
  right: -18px;
  position: absolute;
  bottom: 0px;
}
.addon-dedicated-page-list li:last-child:after {
  display: none;
}
.addon-dedicated-page-list li:last-child {
  color: #7782a1;
}
.overview-left-up {
  width: 360px;
  border-radius: 6px;
  box-shadow: 0px 0px 16px rgba(112, 155, 250, 0.24);
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  padding: 60px 25px 25px 25px;
  align-items: center;
  text-align: center;
}
.overview-left {
  width: 360px;
}
.overview-right {
  width: calc(100% - 360px);
  margin-left: 50px;
}
.overview-right h2 {
  font: 28px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 25px;
}
.overview-right p {
  font: 15px/25px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 25px;
}
.overview-left-down p {
  font: 14px "Axiforma-Regular";
  color: #3064f9;
  margin: 0px;
  cursor: pointer;
}
.realted-add-on {
  border-radius: 5px;
  border: 1px solid #dce1eb;
  margin: 15px 0px;
  padding: 15px;
}
.realted-add-on h6 {
  font: 16px "Axiforma-Bold";
  color: #1b2952;
}
/* Addon Dedicated Page */
/* Promo Code Starts */
.promo-code {
  border: 1px solid #3064f9;
  border-radius: 100px;
  margin-right: 40px;
  padding: 5px 10px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.promo-code p {
  margin-bottom: 0px;
  font: 14px "Axiforma-Regular";
  color: #3064f9;
}
.modal-body.review-modal {
  padding: 0px 40px 40px 40px;
}
.modal-body.review-modal p {
  margin-bottom: 15px;
  margin-top: 10px;
}
.modal-body.review-modal ul {
  margin-bottom: 40px;
  display: flex;
  list-style: none;
  padding-left: 0px;
}
.modal-body.review-modal ul li span {
  padding: 10px 25px 25px 25px;
  border-bottom: 1px solid #9ea9bb;
  cursor: pointer;
}
.modal-body.review-modal ul li span.active {
  border-bottom: 2px solid #3064f9;
}
/* Promo Code Ends */
.demo_app_pop p {
  color: #7782a1;
  font: 13px "Axiforma-Regular";
  margin-bottom: 0px;
}

.web_logo_alert {
  position: relative;
}

.web_logo_alert .alert_big {
  position: absolute;
  right: -8px;
  top: -12px;
}
.alert_li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert_li .alert_big img {
  width: 14px;
}

.alert_li .alert_big {
  padding-right: 10px;
}
.dashboard-customizer.main_banner .customwidthor {
  margin-top: 20px;
}
.wallet {
  background-color: #f1f3fe;
  border-radius: 3px;
  padding: 10px 15px;
}
.my-profile .wallet p {
  margin-top: 0px;
  color: #1b2952;
  font: 13px "Axiforma-Bold";
  margin-left: 7px;
  margin-bottom: 0px;
}
.my-profile .wallet p span {
  margin-top: 0px;
  color: #c8cedb;
  font: 13px "Axiforma-Bold";
}
.paid-support .back_btn,
.paid-support .next_btn {
  width: 200px;
  height: 50px;
  font-size: 14px;
  line-height: 28px;
}
.conecttoapi.category-modal .website-type .update-btn:hover {
  background-color: #3064f9;
  color: #fff;
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}
.rebuild-required {
  background-color: #fff1f1;
  margin-bottom: 25px;
  padding: 12px 20px;
  border-radius: 3px;
}
.overview-right .rebuild-required p {
  font: 14px "Axiforma-Regular";
  color: #ff0000;
  margin-bottom: 0px;
}
.addon-dedicated-page-list a {
  color: #1b2952;
  font-family: "Axiforma-Regular";
}
.rebuild-required {
  background-color: #fff1f1;
  margin-bottom: 25px;
  padding: 12px 20px;
  border-radius: 3px;
}
.overview-right .rebuild-required p {
  font: 14px "Axiforma-Regular";
  color: #ff0000;
  margin-bottom: 0px;
}
.addon-dedicated-page-list a {
  color: #1b2952;
  font-family: "Axiforma-Regular";
}
.overview-right .note-section p {
  line-height: 20px;
}
.overview-right .note-section h4 {
  line-height: 20px;
  margin-bottom: 0px;
}
.build_started {
  position: relative;
}

.creating-build .cancel_build {
  position: absolute;
  right: -5px;
  top: -3px;
  margin-right: 0px;
  cursor: pointer;
}
/* The container */
.containerstyle {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #1b2952;
  font-size: 16px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}

/* Hide the browser's default checkbox */
.containerstyle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.containerstyle .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerstyle:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerstyle input:checked ~ .checkmark {
  background-color: #3064f9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.containerstyle .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerstyle input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerstyle .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* User Management */
.table-top.user-management p {
  color: #7782a1 !important;
  font: 14px "Axiforma-Regular";
}
.user-role-dropdown select {
  font: 14px "Axiforma-Regular";
  color: #1b2952;
  padding-left: 10px !important;
  height: 44px !important;
}
.user-role-dropdown .http {
  width: 160px;
}
.user-role-dropdown .http select {
  height: 45px !important;
}
.invite-user {
  padding: 20px 30px 0px 30px !important;
}
.invite-user-body {
  padding: 0px 30px 0px 30px !important;
}
.cancel-user {
  padding: 12px 30px !important;
  margin-top: 25px;
  border-top: 1px solid #e1e6ee;
}
.cancel-user .next_btn {
  width: auto;
}
.cancel-user .cancel-btn {
  background-color: #c8cedb;
  border-radius: 4px;
  color: white;
  font: 14px/40px "Axiforma-Regular";
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  text-align: center;
  height: 40px;
}
.invite-user-body .push_notification .color_picker {
  margin-bottom: 20px;
}
.invite-user-body .push_notification .checkmark {
  left: 0px;
}
.invite-user-body .push_notification .container_1 {
  padding-left: 40px;
  width: 100%;
}
.invite-user-body .push_notification .container_1 h5 {
  font: 16px "Axiforma-Bold";
  color: #1b2952;
  padding-top: 8px;
}
.invite-user-body .push_notification .container_1 p {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 0px;
}
.invite-user p {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
}
.invite-user h4 {
  font: 22px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 10px !important;
}
.invite-user-body input {
  border-radius: 3px;
  padding-left: 20px;
}
.invite-user-body input::placeholder {
  color: #c8cedb;
}
.user-role-dropdown .next_btn {
  width: 160px;
  height: 44px;
}
.user-role-dropdown .next_btn p {
  font: 13px "Axiforma-Regular";
  color: #fff;
  margin: 0px;
}
.user-details {
  height: 100px;
  padding: 0px 10px;
  border-bottom: 1px solid #f1f5fc;
}
.user-details.mt-3 {
  margin-top: 0px !important;
}
.user-detail h3 {
  font: 16px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}
.user-details p {
  color: #1b2952;
  font: 13px "Axiforma-Regular";
  margin: 0px;
  margin-top: 9px;
}
.user-action p {
  cursor: pointer;
}
.user-action p:hover {
  color: #3064f9;
}
.user-role-dropdown .store-url input {
  height: 44px;
}
.user-role-dropdown .store-url input::placeholder {
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #c8cedb;
}
.user-details .invite-sent {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8edfb;
  border-radius: 10px;
  width: 100px;
  height: 20px;
}
.user-details .invite-sent p {
  color: #3064f9;
  font: 12px "Axiforma-Regular";
}
.user-details .online {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5ffed;
  border-radius: 10px;
  width: 60px;
  height: 20px;
}
.user-details .online p {
  color: #47ce70;
  font: 12px "Axiforma-Regular";
}
.user-details .offline {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f3f9;
  border-radius: 10px;
  width: 60px;
  height: 20px;
}
.user-details .offline p {
  color: #c8cedb;
  font: 12px "Axiforma-Regular";
}
.user-detail {
  width: 350px;
}
.user-detail.user-detail-merge {
  width: 254px;
}
.user-role {
  width: 200px;
}
.user-last-login {
  width: 200px;
}
.user-action {
  width: 100px;
}
.invitationsent {
  font-size: 12px !important;
  color: #3064f9 !important;
  background: #e8edfb;
  border-radius: 20px;
  padding: 5px 10px;
}
.user_modalheading {
  font: 1.25rem "Axiforma-Bold" !important;
  color: #1b2952 !important;
  margin-bottom: 5px;
}
.invite-user .user_modalheading {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
}
.on-mobile {
  display: none;
}
.invite-user .user_modalheading {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
}
.not-allowed h4 {
  font: 20px "Axiforma-Bold";
  color: #1b2952;
}
/* User Management */
/* Strips */
.country_strip {
  border: 1px solid #3064f9;
  height: 50px;
  background: #f6f9ff;
}
.country_strip h5 {
  color: #1b2952;
  font-size: 15px;
  font-family: "Axiforma-Regular";
}
.country_strip h5 span {
  color: #1b2952;
  font-family: "Axiforma-Bold";
}
.country_strip span:nth-child(2) {
  color: #3064f9;
  font-size: 15px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
}
.currencydiv {
  border: 1px solid #c8cedb;
  border-radius: 3px;
  width: 170px;
  height: 35px;
  cursor: pointer;
}
.currencydiv p {
  color: #1b2952;
  font-size: 14px;
  line-height: 34px;
}
.currencydiv span {
  color: #3064f9;
  font-size: 14px;
  line-height: 34px;
}
.showwebstrip {
  border: 1px solid #ff4848;
  height: 50px;
  background: #ff4848;
}
.showwebstrip h6 {
  color: white;
  font-size: 15px;
  font-family: "Axiforma-Bold";
}
.showwebstrip h6 img {
  position: relative;
  top: 0px;
}
.showwebstrip h5 {
  color: #ffc8c8;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.showwebstrip a {
  color: white;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-decoration: underline;
}
.latest_code_version {
  color: #7782a1 !important;
  font-family: "Axiforma-Regular" !important;
}
.pus_notification_head h2 {
  font: 20px "Axiforma-Bold";
  color: #1b2952;
}
.pus_notification_body h4 {
  font: 16px "Axiforma-Bold";
  font-weight: 700;
  color: #1b2952;
}
.pus_notification_body h4 span {
  font-family: "Axiforma-Regular" !important;
  font-weight: 100;
  color: #1b2952;
}
.pus_notification_body p {
  font: 15px "Axiforma-Regular" !important;
  font-weight: 100;
  margin-bottom: 0px;
}
[dataclass~="notification"] .modal-footer {
  border-top: 1px solid #e8edf8 !important;
}
.pus_notification_body {
  padding-top: 0px !important;
}
.pus_notification_body .round-corner {
  border: 1px solid #c8cedb;
  border-radius: 8px;
  padding: 15px;
}
.state_noti_par {
  position: relative;
}
.state_noti {
  width: 7px;
  margin: 5px;
  height: 7px;
  border-radius: 50%;
  border-style: solid;
  border-color: #7782a1;
  background-color: #7782a1;
  display: inline-block;
}
.react-datetime-picker__inputGroup__amPm {
  width: auto !important;
  padding: 5px !important;
  margin-left: 10px;
  border: none;
}
.react-datetime-picker__wrapper {
  padding: 0px 10px;
}
.savedraft:hover {
  box-shadow: none;
}
/*Strip end*/
.original_img {
  color: #3064f9;
  font-size: 15px;
  font-family: "Axiforma-Regular";
  position: relative;
  z-index: 99;
  display: inline-block !important;
}
.infoicons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
a.viewuploaded.media {
  display: flex;
  align-content: center;
  font-size: 14px;
  margin: 0 20px;
  color: #3064f9;
}
a img {
  align-content: center;
}
a img.penimg,
a img.eyeimg {
  margin-top: -3px;
}
a img.penimg {
  height: 14.69px;
  width: 14.69px;
}
a img.eyeimg {
  width: 20.4px;
  height: 11.5px;
}
a.viewuploaded.media:hover {
  color: #3064f9;
}
a.viewfile {
  text-decoration: none;
}
a.viewfile .svg1 {
  margin: 0 auto;
  display: block;
}
.crossicon {
  margin: 0 8px;
}
.editlabel {
  display: none;
  transition: all 0.5s ease;
  position: relative;
  top: -1px;
  cursor: pointer;
}
pre {
  height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: pre-line;
  text-align: justify;
}
select#list1_status {
  padding-right: 30px;
}
.common-height:hover .editlabel {
  display: block;
}
.salebadge {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 15px;
}
.label_input {
  font-size: 13px;
  color: #7782a1;
}
/*dashboard release link*/
.release-notes-blue {
  display: flex;
  align-items: center;

  justify-content: center;
  border-radius: 3px;
  padding: 7px 12px;
  /* margin-right: 20px; */
  padding-right: 0px;
}

.release-notes-blue p {
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  margin-right: 10px;
  color: #7782a1;
}
.latest_code_version {
  color: #7782a1 !important;
  font-family: "Axiforma-Regular" !important;
}
/* Connectivity history */
.history-seeless {
  padding: 15px 0px;
  border-bottom: 1px solid #f1f5fc;
}
.connection-history h3 {
  font: 20px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
}

.history-seeless p {
  font: 14px "Axiforma-Regular";
  color: #3064f9;
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.connection-date p {
  font: 15px "Axiforma-Regular";
  color: #1b2952;
  margin-left: 10px;
  margin-bottom: 0px;
}
.connecion-details p {
  font: 14px "Axiforma-Regular";
  color: #3064f9;
  margin-bottom: 0px;
}
.connection-table {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #f1f5fc;
  align-items: center;
}
.modal-xxl-content.modal-api.modal-header {
  border-bottom: 1px solid #f1f5fc !important;
  margin-bottom: 12px;
}
.connection-table:last-child {
  border-bottom: 0px;
}
.connection-date {
  width: calc(100% - 15px);
}
.api-pop-error {
  padding: 10px 0px;
}
.connecion-details {
  width: 400px;
  text-align: center;
  cursor: pointer;
}
.connection-status {
  width: 200px;
  text-align: left;
}
.payment-status.modal-connection {
  width: auto;
  text-align: left;
}
.payment-status.modal-connection .connection-status {
  text-align: left;
}
.connection-table-blue {
  width: 100%;
  background: #f4f7fd;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.connection-table-blue p {
  margin-bottom: 0px;
  font: 14px "Axiforma-Regular";
  color: #3064f9;
  display: flex;
  align-items: center;
}
.connection-date .keys {
  margin-left: 25px;
  width: 100%;
}
.connection-date .keys p {
  font: 12px "Axiforma-Regular";
  color: #7782a1;
  margin-top: 10px;
}
.api-pop-error h4 {
  font: 15px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 0px;
}

.latest_code_version {
  color: #7782a1 !important;
  font-family: "Axiforma-Regular" !important;
}
/* connectivity history end */
/*connection status on preview*/
.connection-status .connection-time-preview {
  width: 100%;
  text-align: right;
  color: #c8cedb;
  font-size: 12px !important;
  margin-top: 5px;
}
.connection-status .preview-top-connection {
  color: #1b2952;
  font-size: 20px !important;
}
.thank-you-page .link-expired h1 {
  font-size: 24px;
}
.conecttoapi .user-limit h2 {
  color: #1b2952;
  font-size: 22px "Axiforma-Bold";
}
/* Web Platform Starts */
.web-platform p,
.web-platform ul li {
  color: #1b2952;
  font: 15px "Axiforma-Regular";
  margin-bottom: 0px;
}
.web-platform ul li {
  margin: 20px 0px;
}
.web-platform ul {
  padding-left: 20px;
}
.web-platform h3 {
  color: #1b2952;
  font: 30px "Axiforma-Bold";
  margin: 30px 0px;
}
.web-platform .next_btn {
  height: 54px;
  line-height: 54px;
  padding: 0px;
  width: 180px;
  margin: 40px 0px;
  text-align: center;
}
/* Web Platform Ends */
.access-table-head {
  border-top: 1px solid #dce1eb;
  padding: 15px 10px;
  border-bottom: 1px solid #dce1eb;
}
.access-table-head h4 {
  margin-bottom: 0px;
  width: 25%;
  margin: auto;
  text-align: center;
  font-size: 14px;
}
.web_hosting .access-table-head h4 {
  margin-bottom: 0px;
  width: 25%;
  margin: auto;
  text-align: center;
  font-size: 14px;
}
.overview-right .access-table-body p {
  margin-bottom: 0px;
  font-size: 14px;
}
.web_hosting .access-table-body p {
  margin-bottom: 0px;
  font-size: 14px;
}
.overview-right .access-table-body {
  text-align: center;
  padding: 15px 10px;
  border-bottom: 1px solid #f1f5fc;
}
.web_hosting .access-table-body {
  text-align: center;
  padding: 15px 10px;
  border-bottom: 1px solid #f1f5fc;
}
.overview-right .access-table-body .w-25 {
  text-align: center;
}
.web_hosting .access-table-body .w-25 {
  text-align: center;
}
/* for pricing lifetime */
.app_sumo_menu::before {
  border-color: transparent transparent #fff transparent !important;
  right: 38px !important;
}
.helpheaderdropdown.app_sumo_menu .dropdown-item:hover {
  border-left: 0px !important;
  padding-left: 20px;
}
.helpheaderdropdown.app_sumo_menu .dropdown-item {
  padding-left: 20px !important;
}
.helpheaderdropdown.app_sumo_menu {
  width: 150px;
  left: 200px !important;
}
.appsumo_dropdown .appsumodropdown {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
}
.appsumo_dropdown .appsumodropdown span {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
  margin-left: 8px;
}
.billing_deals h3 {
  font: 18px "Axiforma-Bold";
  color: #1b2952;
}
.my-profile .billing_deals p {
  font: 14px "Axiforma-Regular";
  color: #1b2952;
  margin: 0px 10px;
}
.billing_deals_list {
  margin: 15px 0px;
}
.appsumo_section {
  width: 25%;
  margin: 30px 0px 50px 0px;
}
.pricing_table {
  text-align: center;
  box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
  border-top: 3px solid #3064f9;
  border-radius: 3px;
  padding: 30px 10px;
  margin: 20px auto 0px auto;
  width: 300px;
}
.billing_new_bottom h5 {
  font: 18px "Axiforma-Bold";
  margin: 20px;
  color: #1b2952;
}
.billing_new_bottom .next_btn,
.pricing_table .next_btn {
  margin: 40px auto 20px auto;
  width: 200px;
}
.pricing_table h3 {
  font: 16px "Axiforma-Bold";
  margin-bottom: 20px;
  color: #7782a1;
}
.change_plan {
  font: 14px "Axiforma-Regular";
}
.pricing_table h2 {
  font: 36px "Axiforma-Bold";
  margin-bottom: 10px;
  color: #1b2952;
}
.pricing_table h6 {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 35px;
}
.my-profile .pricing_table p {
  font: 15px "Axiforma-Regular";
  margin-bottom: 25px;
  color: #1b2952;
}
/* Add On Filter Starts*/

.filter_by_child {
  border: 1px solid #c8cedb;
  border-radius: 2px;
  margin-right: 15px;
  padding: 10px 18px;
  cursor: pointer;
}

.filter_by_child p {
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  color: #7782a1;
}

.filter_by {
  margin-top: 10px;
  margin-bottom: 20px;
}

.filter_by_active {
  background-color: #7782a1;
  border-color: #7782a1;
}

.filter_by_active p {
  color: #fff;
}

.filter_by_child:last-child {
  margin-right: 0px;
}

.service_filter {
  border: 1px solid #ebedf2;
  background-color: #f8fafe;
  border-radius: 2px;
  width: 135px;
  padding: 3px;
}

.service_filter.plugin_ {
  width: 54px;
}

.service_filter.comin_soon {
  width: 120px;
  background-color: #ebeef6;
  border: none;
}

.service_filter.comin_soon p {
  color: #a5adbf;
}

.update_add_on .app-security {
  padding: 0px;
  border: 0px;
  height: auto;
}
.service_filter.plugin_ p,
.service_filter p {
  color: #7782a1;
  text-align: center;
}

.update_add_on .service_filter p {
  font-size: 12px;
  padding: 0px;
}

/* Add On Filter Ends*/
/* Setting icons */
.other_settings .common-height {
  align-items: start !important;
  margin-top: 40px;
  height: 80px;
}
.other_settings .common-height img {
  margin-top: 0px;
}
.user-md-padding .tooltip-container .toolspace {
  width: 251px;
}
.other_settings .user-md-padding {
  width: 48%;
}

.other_settings .user-md-padding:nth-child(odd) {
  margin-right: 4%;
}
.image_width_fixed {
  flex-shrink: 0;
  object-fit: contain;
}
.other_settings .new-height {
  height: auto;
}
/* Settings icons end */
/* Security */
.web-platform p,
.web-platform li {
  color: #676b77 !important;
}
.set_security {
  margin-top: 30px;
}
.set_security h4 {
  font: 20px "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 20px;
}
.set_security_step {
  display: flex;
  align-items: center;
  flex-flow: column;
  min-height: 300px;
  background-color: #f8f9fc;
  border-radius: 10px;
  width: 32%;
  margin-right: 2%;
  text-align: center;
  padding: 50px 25px;
}
.set_security_step p {
  margin-top: 20px;
}
.set_security_step:last-child {
  margin-right: 0px;
}
.perform_ance h2 {
  font: 30px "Axiforma-Bold";
}
.perform_ance h2.headspac {
  font-size: 18px;
}
.new_btn_perform {
  text-transform: uppercase;
  color: #3064f9;
  font: 16px "Axiforma-Regular";
  border: 1px solid #3064f9;
  border-radius: 3px;
  padding: 16px 32px;
}
.three_hosting {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  border: 1px solid #dce1eb;
  border-radius: 10px;
  width: 32%;
  min-height: 340px;
  padding: 20px;
  margin-right: 2%;
}
.three_hosting_step p.caps {
  text-transform: uppercase;
  color: #7782a1;
  letter-spacing: 0.9px;
  font: 14px "Axiforma-Regular";
  margin-bottom: 5px;
  margin-top: 30px;
}
.web_hosting h4 {
  font: 20px "Axiforma-Bold";
}
.web_hosting .three_hosting p {
  margin-bottom: 0px;
  margin-top: 30px;
  letter-spacing: normal;
}

.web_hosting h2 {
  margin-bottom: 0px;
  font: 30px "Axiforma-Bold";
}
.web_hosting p {
  color: #676b77;
  margin-bottom: 0px;
  font: 15px "Axiforma-Regular";
}
.web_hosting p span {
  color: #1b2952;
  font: 14px "Axiforma-Regular";
}
.three-section h4.whitelabel-first-heading {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}
h4.ml-0.whitelabel-first-heading {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
}
h2.language-middle-head {
  font: 28px "Axiforma-bold";
  color: #1b2952;
  margin-bottom: 25px;
}
h2.usersaddon-footer-heading {
  font: 28px "Axiforma-bold";
  color: #1b2952;
  margin-bottom: 25px;
}
p.language-initial-text {
  font: 15px/25px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 25px;
}
p.whitelabel-initial-text {
  font: 15px/25px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 25px;
}
p.usermanagement-last-heading-text {
  font: 15px/25px "Axiforma-Regular";
  color: #1b2952;
  margin-bottom: 25px;
}
.flags-div h4.flag-text {
  margin-left: 15px;
  font: 15px "Axiforma-regular";
  color: #1b2952;
}
h4.languagesupport-last-heading {
  line-height: 20px;
  margin-bottom: 0;
  font: 16px "Axiforma-bold";
  color: #1b2952;
}
p.languagesupport-last-heading-text {
  line-height: 20px;
}
.site_security {
  width: 30%;
  margin-right: 2%;
  text-align: left;
}
.leftscroll.update_leftPannel .yamm_tabs .panel-body ul {
  display: block;
  margin-left: 20px;
  list-style: none;
  margin-top: 0px;
}
.leftscroll.update_leftPannel .yamm_tabs .panel-body ul li svg {
  display: none;
}
.leftscroll.update_leftPannel .web_logo_alert .alert_big {
  position: absolute;
  right: 0px;
  top: -10px;
}
.leftscroll.update_leftPannel .panel-body .alert_li {
  position: relative;
}
.leftscroll.update_leftPannel .panel-body .alert_li .alert_big {
  position: absolute;
  top: -4px;
  left: -30px;
  padding: 0px;
}
.leftscroll.update_leftPannel .panel-body .alert_li .alert_big img {
  width: auto;
}
.plandata {
  height: auto !important;
  margin: 0px 15px 15px 0px;
}
.leftscroll.update_leftPannel .perhead:after {
  display: none !important;
}
@media (min-width: 1920px) {
  .customiseapp {
    width: 450px;
    position: static;
    padding-left: 23px;
  }
  .two-banner {
    position: relative;
    right: auto;
    height: 82%;
    width: 450px;
  }
  .beamer_beamer.right {
    width: 400px !important;
  }
}

.two-apps.new-buid-grey.new-buil_app {
  padding: 0px;
  margin-top: 18px;
  border: 1px solid #dce1eb;
}

.two-apps.new-buid-grey.new-buil_app .grey-download-section {
  padding: 30px 25px 30px 25px;
}

.two-apps .androidpublishright p {
  font-size: 14px;
}

.build_new_section,
.version_new_section {
  padding: 18px;
  border-top: 1px solid #dce1eb;
}

.two-apps .build_new_section p {
  margin-top: 0px;
}
/* .androidpublishright .verify_steps{
	margin-bottom: 20px;
	margin-top: 0px;
  } */
.rebuild_new {
  color: #3064f9;
  font: 14px "Axiforma-Bold";
  width: 109px;
}
.rebuild_new_disable {
  color: #c8cedb;
  font: 14px "Axiforma-Bold";
  width: 109px;
}
a.rebuild_new_disable:hover {
  color: #c8cedb;
}
.new-hover-element {
  position: relative;
  display: inline-block;
}
.new-hover-element .new-hover-element-text {
  visibility: hidden;
  width: 250px;
  background-color: rgba(51, 51, 51);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 24px;
  right: -45px;
}
.new-hover-element:hover .new-hover-element-text {
  visibility: visible;
}
.new-hover-element span.new-hover-element-text {
  font-family: "Axiforma-Bold";
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
}
.new-hover-element span.new-hover-element-text p {
  font-family: "Axiforma-Regular";
  color: #fff;
  font-size: 12px;
}
.rebuild_disabled {
  color: #808080;
}
.rebuild_button_disable {
  color: #fff;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  background-color: #c8cedb;
  border: 1px solid #c8cedb;
  border-radius: 4px;
  transition: 0.5s;
  text-decoration: none;
  padding: 12px 20px;
  display: block;
  width: 168px;
  text-align: center;
  cursor: default;
}
.rebuild_button_disable:hover {
  color: #fff;
}

.two-apps .build_new_section h4,
.two-apps .version_new_section h4 {
  color: #7782a1;
  font: 14px "Axiforma-Regular";
  margin-bottom: 0px;
  width: 80px;
  flex-shrink: 0;
}
/* .build_status_new{
	flex-grow: 1;
  } */
.build_last_new_div {
  width: 50px;
  text-align: center;
}
.build_last_new_div span {
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Regular";
}
span.texthighlite {
  font-family: "Axiforma-Bold";
}
.provision-certificate .heading_config h4 {
  color: #1b2952;
  font: 16px "Axiforma-Bold";
}

.heading_config h5 {
  color: #7782a1;
  font: 14px "Axiforma-Regular";
}

.heading_config a {
  color: #3064f9;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
}

.half_width {
  width: 50%;
}

.half_width:first-child {
  padding-right: 1%;
  border-right: 1px solid #e8edf8;
}

.half_width:last-child {
  padding-left: 1%;
}
.bordetobottom {
  border-bottom: 1px solid #e0e5ec;
}
.css-1wa3eu0-placeholder {
  font-family: "Axiforma-Regular" !important;
  color: #7782a1 !important;
}

.css-1uccc91-singleValue {
  color: #1b2952 !important;
  font-size: 14px !important;
  font-family: "Axiforma-Regular" !important;
}
/* Email support */
.warning-body {
  background: #f8fafe;
  padding: 15px;
  border: 1px solid #f8fafe;
  border-radius: 3px;
  margin-bottom: 30px;
}

.warning-left h2 {
  font-size: 16px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}
.warning-left p {
  font-size: 12px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.warning-right p {
  font-size: 13px;
  color: #ff4848;
  font-family: "Axiforma-Regular";
  text-align: right;
}
.warning-right p span {
  font-family: "Axiforma-Bold";
}
.warning-right h3 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  text-align: right;
}
.warning-right h3 a {
  text-decoration: underline;
  color: #7782a1;
}
.warning-right h3 a:hover {
  color: #7782a1;
}
.response-status h6 {
  font-size: 16px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.response-status h6 span {
  font-family: "Axiforma-Bold";
}
.response-status h6 img {
  position: relative;
  top: -2px;
  margin-right: 3px;
}
.customer_get {
  margin-top: 30px;
  color: #1b2952 !important;
  font-size: 14px;
  font-family: "Axiforma-Bold";
}
.customer_get span {
  color: #47ce70;
}
/* Wallet */
.new_wallet_design {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new_wallet_design_p {
  text-align: right;
  margin-right: 15px;
}
.transparent_background.my-profile .new_wallet_design_p p {
  margin-top: 0px;
  font: 13px "Axiforma-Regular";
  color: #7782a1;
}
.transparent_background.my-profile .new_wallet_design .low-balance {
  background-color: #e3e6f1;
}
.transparent_background.my-profile .wallet p {
  color: #fff;
  font: 13px "Axiforma-Regular";
}
.transparent_background.my-profile .wallet p.add-money {
  cursor: pointer;
  text-decoration: underline;
  margin-left: 40px;
}
.add-money-pop {
  border: 1px solid #c8cedb;
  border-radius: 3px;
  padding: 10px 15px;
  margin-left: 15px;
}
.add-money-pop .add-money {
  margin-left: 15px;
  color: #3064f9;
  cursor: pointer;
}
.transparent_background.my-profile .new_wallet_design .add-money-pop p {
  font: 13px "Axiforma-Regular";
  margin-top: 0px;
}

.transparent_background.my-profile .new_wallet_design .low-balance-low {
  background-color: #e3e6f1;
}

.transparent_background.my-profile .new_wallet_design .high-balance {
  background-color: #3064f9;
}
.billin_head_wallet {
  width: 25%;
  flex-grow: 1;
}
#currencyform .color_picker p {
  color: #1b2952;
  font: 16px "Axiforma-Regular";
}
.adjustradius {
  /* transition: width 0.3s ease-in; */
  transform-origin: center;
}

.textleftclass {
  text-align: left !important;
}
.textcenterclass {
  /* transform: translate(0px, 0px); */
  text-align: center !important;
}
.movelogo {
  width: 70px !important;
  height: 54px !important;
  transform: translate(-70px, -9px);
}
.movecustomlogo {
  transform: scale(0.46) translate(-124px, -109px);
}
.welcometext {
  position: absolute;
  top: 116px;
  z-index: 1;
  left: 44px;
  text-align: left;
  word-break: break-word;
  width: 222px;
  display: flex;
  align-items: center;
  height: 78px;
}
@media screen and (max-width: 1200px) {
  .Bottom_mobile_part__LPSxl {
    background-color: transparent !important;
    padding: 0px !important;
  }
  .Bottom_custom_width_second__3JuvG {
    margin-top: 0px !important;
  }
  .bottom-bar-icons img {
    max-width: 15px !important;
  }
  .bottombar-preview {
    display: block;
  }
  .right_panel_bottombar .right_panel {
    overflow-x: visible !important;
  }
  .Bottom_mobile_parent__2TMqx {
    margin: 0px auto;
  }
  h2.usersaddon-footer-heading {
    margin-bottom: 15px;
    font-size: 22px !important;
  }
  .welcometext {
    top: 56px;
    left: 16px;
  }
  .addonpayment form {
    border-right: none;
  }
  .sub-heading-spacing {
    padding-left: 20px;
  }
  .payment-border {
    border: 1px solid #e8edf8;
    padding: 24px;
    margin-top: 30px;
    margin-bottom: 70px;
  }
  .addonpayment button {
    width: 100% !important;
    margin: 0px;
  }
  .addonpayment h3 {
    font-size: 16px;
  }
  .update_add_on {
    margin-bottom: 17px;
    min-height: auto !important;
  }
  .warning-left h2 {
    font-size: 15px;
  }
  .css-1ejncba-indicatorContainer {
    margin-right: 1px !important;
  }
  .warning-left {
    width: 30%;
  }
  .warning-right {
    width: 75%;
  }
  .rightselection li:before {
    left: 9px;
  }
  .rightselection p {
    font-size: 14px;
  }
  .list-unstyled img {
    width: 20px;
    height: 20px;
  }
  .bordetobottom {
    border-bottom: none;
  }
  .leftselection li {
    font-size: 16px;
  }
  .show_border_mobile {
    border-top: 1px solid #dce1eb;
    font-size: 15px;
  }
  .heading_config h5 {
    display: none !important;
  }
  .ad-mob-div {
    padding-top: 20px;
  }
  .new_division {
    width: 100%;
  }
  .two-apps.new-buid-grey.new-buil_app .grey-download-section {
    padding: 20px 15px 20px 15px !important;
    min-height: auto !important;
  }
  .two-apps.new-buid-grey.new-buil_app,
  .app-dash-analytics .two-apps:first-child {
    padding: 0px !important;
    margin: 0px !important;
  }
  .new_two_div {
    margin: 0px 15px;
  }
  .app-dash-analytics .two-apps:first-child {
    margin-bottom: 30px !important;
  }
  .androidpublishright {
    width: 100% !important;
  }
  .androidpublish {
    display: none;
  }
  .build_new_section,
  .version_new_section {
    padding: 10px;
  }
  .build_status_new {
    width: 145px;
  }
  .build-subtext-spacing {
    width: auto;
  }
  .two-apps .build_new_section h4,
  .two-apps .version_new_section h4 {
    width: 70px !important;
  }
  .other_settings .user-md-padding {
    width: 100%;
  }
  .other_settings .user-md-padding:nth-child(odd) {
    margin: 0px;
  }
  .other_settings .common-height {
    height: auto;
    margin: 0px 0px 25px 0px;
    padding: 0px 15px;
  }
  .flex-flow-row {
    flex-flow: column;
  }
  .flex-flow-row .new_division {
    width: 100%;
    margin: 0px;
  }
  .three_hosting {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 25px;
    min-height: 280px;
  }
  .web_hosting {
    padding: 0px 15px;
  }
  .site_security {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .web_hosting h2 {
    font: 23px "Axiforma-Bold";
  }

  .performance_mobile {
    padding: 0px 15px;
  }
  .set_security_step {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 25px;
    min-height: auto;
  }
  .set_security_step p {
    margin-top: 10px;
  }
  /* Setting icons */
  .other_settings .user-md-padding {
    width: 100%;
  }
  .other_settings .user-md-padding:nth-child(odd) {
    margin: 0px;
  }
  .other_settings .common-height {
    height: auto;
    margin: 0px 0px 25px 0px;
    padding: 0px 15px;
  }
  .takefull {
    width: 100% !important;
  }

  /* Settings icons end */
  /* Add On Filter Starts*/
  .filter_by {
    justify-content: center;
    padding: 0px 15px;
  }
  .filter_by_child {
    padding: 10px 21px;
  }

  .show_border {
    border-bottom: none !important;
  }
  .checknew {
    width: 100% !important;
  }
  .modal-body.preview_steps {
    padding: 0px 15px 10px 15px;
  }
  .update-type.modal-categories.preview_ste {
    padding-top: 10px !important;
  }
  .preview_steps h4 {
    font-size: 14px;
  }
  .steps_preview {
    padding-bottom: 15px;
  }
  .preview_steps h2 {
    font: 17px "Axiforma-Bold";
  }
  .preview_steps h2.preview-screen-text {
    font: 14px "Axiforma-Regular";
  }
  .push_notify .table_head {
    font: 15px "Axiforma-Bold";
    padding: 10px 20px !important;
  }
  .push_notify .notif-head-alignment {
    white-space: nowrap;
  }
  .table_head_mob {
    align-items: center;
    justify-content: space-around;
  }
  [dataclass~="notification"] .modal-content {
    position: relative !important;
  }
  .pus_notification_footer button {
    width: 48%;
    font-size: 14px;
  }
  /* lifetime pricing */
  .billing_deals h3 {
    font-size: 16px;
  }
  /* .pricing_table p{
		display: none;
	} */
  .pricing_table {
    padding: 20px 15px;
    margin: 0px 15px;
    width: 90%;
  }
  .billing_table_mob {
    padding: 20px 15px;
  }
  /*new dashboard*/
  .new_dashboard_head {
    border: none;
  }
  .chechlist {
    padding: 0px 15px;
  }
  .new_dashboard_head .plan-name {
    margin-bottom: 10px;
  }
  .new_dashboard_head .app-iconimg img {
    width: 95px;
  }
  .new_dashboard_head .app-created {
    text-align: left;
    margin-top: 5px;
  }
  .new_dashboard_head .mobile-view-subpadding {
    padding-top: 11px;
    margin-top: 0;
  }
  .mobile-view-usertext {
    margin-bottom: 7px;
  }
  .new_dashboard_head .usericonimage-mobile img {
    width: 100px;
  }
  .mobile-view-plan-text {
    margin-bottom: 15px;
  }
  .chechlist h2,
  .rightselection h4 {
    font-size: 18px;
  }
  .leftselection li,
  .rightselection li,
  .rightselection li a {
    font-size: 14px;
  }

  .chechlist .app-addons {
    padding: 0px;
  }
  .leftselection {
    padding: 0px;
    width: 100%;
  }
  .new_dashboard_head h3 {
    font-size: 18px;
  }
  .rightselection {
    padding: 0px;
    width: 100%;
    border: 0px;
  }
  /*new dashboard end*/

  .flags-div {
    width: 50%;
    padding: 8px 0px;
  }

  .section-theme .padding-15 {
    padding: 0px 15px !important;
  }
  .overview-right .access-table-head h4 {
    margin-left: 0px;
    font-size: 14px;
  }
  .web_hosting .access-table-head h4 {
    margin-left: 0px;
    font-size: 14px;
  }
  .overview-right .access-table-body,
  .access-table-head {
    padding: 10px 5px;
  }
  .web_hosting .access-table-body,
  .access-table-head {
    padding: 10px 5px;
  }
  .overview-right .access-table-users .access-table-head h4 {
    margin: auto;
  }
  .web_hosting .access-table-users .access-table-head h4 {
    margin: auto;
  }
  .web-platform p,
  .web-platform ul li {
    font-size: 14px;
  }
  .web-platform h3 {
    font-size: 20px;
    margin: 18px 0px;
  }
  .web-platform ul li {
    margin: 13px 0px;
  }
  .web-platform .next_btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin: 25px 0px;
  }
  .app-dash-analytics .api-details .androidpublish p {
    margin-top: 10px;
    font-size: 12px !important;
  }
  /* Invalid page css */
  .thank-you-page .link-expired h1 {
    font-size: 21px;
  }
  .resend-expired-link .back_btn,
  .resend-expired-link .next_btn {
    width: 47% !important;
    display: inline-block;
  }
  .resend-expired-link .next_btn {
    border: none;
  }

  /* Invalid page css end*/

  .dashboard-content .dashboard-customizer .module_title h5 {
    /* width: 200px;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .api-pop-error h4 {
    font-size: 14px !important;
  }
  .api-pop-error {
    padding: 5px 0px;
  }
  .connection-date .keys {
    margin-left: 40px;
  }
  .modal-xxl-content.modal-api.modal-header {
    margin-bottom: 0px;
  }
  .update-woo-plugin.modal-body .api-pop-error img {
    width: 18px;
  }
  .release-notes-blue {
    margin-right: 0px;
  }
  .phone-top-release {
    padding: 0px 15px;
  }
  /* User module */
  .not-allowed h4 {
    font: 16px "Axiforma-Bold";
  }
  .cancel-user .cancel-btn,
  .cancel-user .next_btn {
    width: 50%;
    height: 44px;
    line-height: 44px;
    text-transform: uppercase;
  }
  .cancel-user {
    margin-top: 10px;
  }
  .cancel-user,
  .invite-user-body,
  .invite-user {
    padding: 15px !important;
  }
  /* .push_notification .container_1{
		height: 52px;
	} */
  .notify-buttons-child,
  .date_picker .react-datetime-picker {
    width: 100%;
  }
  .notify-buttons .notify-buttons-child button {
    width: 5 0% !important;
  }
  .react-datetime-picker__inputGroup__input--hasLeadingZero {
    padding-left: 0px !important;
  }
  .react-datetime-picker__inputGroup
    .react-datetime-picker__inputGroup__leadingZero {
    padding-left: 5px;
  }
  .draft_fotter {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .notify-buttons.fixed-button .draft_fotter .save_draft {
    border: 1px solid #3064f9 !important;
  }
  .draft_fotter button {
    width: 50% !important;
  }
  .date_picker .react-datetime-picker__wrapper {
    width: 100%;
    padding: 10px 0px;
  }
  .showselection {
    position: relative;
  }
  .showselection .user-action {
    position: absolute;
    width: 85px;
    padding: 10px;
    box-shadow: 0 0 5px 0 rgba(36, 116, 249, 0.15);
    left: -83px;
    top: -22px;
    background: white;
  }
  .hidden {
    display: none;
  }
  .mobile-view-user {
    display: flex;
  }
  .invite-user p {
    margin-bottom: 6px;
  }
  .next_btn.pointcursor.mobilecreatenew.add-notification {
    justify-content: center !important;
  }
  .user-management.table-top {
    display: none !important;
  }
  .user-role-dropdown {
    width: 100%;
  }
  .user-details {
    padding: 15px 0px;
    height: 82px;
  }
  .user-detail h3 {
    font-size: 15px;
    margin-top: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 165px;
    overflow: hidden;
  }
  [dataclass~="useradd"] .modal-content {
    position: relative !important;
  }
  .showselection .user-action p {
    padding: 0px 0px 10px 0px;
  }
  .edit-dots {
    padding: 8px;
  }

  /* User Module ENd */

  .two-test-button {
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 15px;
    background: #fff;
  }
  .ios-android-tabs {
    height: 50px;
    margin-top: 25px;
  }

  .colour-blue-bottom-android,
  .colour-blue-bottom-ios {
    position: relative;
  }

  .colour-blue-bottom-ios::after {
    content: " ";
    display: block;
    position: absolute;
    height: 2px;
    background: #3064f9;
    width: 80px;
    left: -40px;
    bottom: -12px;
  }

  .colour-blue-bottom-android::after {
    content: " ";
    display: block;
    position: absolute;
    height: 2px;
    background: #3064f9;
    width: 100px;
    left: -40px;
    bottom: -12px;
  }

  .android-frame.android-form-mobile {
    width: 100%;
  }
  .android-frame.android-form-mobile .preview-new-button {
    width: 100%;
    padding: 0 15px;
    margin: 0px 0px 25px 0px;
  }
  .submenu-list {
    padding: 20px 15px;
    border-bottom: 1px solid #edf0f5;
  }
  .submenu-list p {
    margin-bottom: 0px;
    color: #7782a1;
    font: 16px "Axiforma-Regular";
  }
  .completesteps.delete_apps img {
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .completesteps.delete_apps h4 {
    font-size: 20px;
  }
  .completesteps.delete_apps p {
    width: 100%;
  }
  .completesteps.delete_apps .buttonbox {
    margin-bottom: 25px;
  }

  .transparent_background.addonpage.payment-last-step .container.add-ons-plan {
    max-width: 100%;
    width: 100%;
  }
  .rebuild-required {
    margin-bottom: 15px;
  }
  .paid-support .back_btn,
  .paid-support .next_btn {
    width: 150px;
    font-size: 12px;
    padding: 0px;
    height: 44px;
    line-height: 42px;
  }
  .paid-support h5 {
    line-height: 23px;
    font-size: 17px !important;
  }
  /*review*/
  [dataclass~="review"] .modal-content {
    position: relative !important;
  }
  [dataclass~="review"] .modal-content p {
    width: 100%;
  }
  [dataclass~="review"] .modal-content .upload-screenshot {
    margin-bottom: 20px;
  }
  .modal-body.review-modal ul li span,
  .modal-body.review-modal ul li {
    padding: 5px 0px 15px 0px;
  }
  .modal-body.review-modal ul {
    margin-bottom: 20px;
  }
  .modal-body.review-modal ul li span img,
  .modal-body.review-modal ul li img {
    padding: 0px 10px;
  }
  .modal-body.review-modal {
    padding: 10px 15px 25px 15px;
  }
  .modal-body.review-modal h4 {
    font-size: 18px;
  }
  /*review*/
  .promo-code p {
    margin-bottom: 0px;
    font: 12px "Axiforma-Regular";
    color: #3064f9;
  }
  .promo-code {
    border: none;
    margin-right: 0px;
    padding-top: 6px;
    padding-right: 0px;
  }

  .three-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 15px;
    margin: 20px 0px 20px 0px;
  }
  .overview-left h3 {
    margin-top: 10px;
  }
  .overview-right h4 {
    margin-bottom: 0px;
    margin-left: 15px;
  }
  .white-label-mob {
    margin-left: 20px;
    text-align: left;
  }
  .overview-left,
  .overview-left-up,
  .overview-right {
    width: 100%;
  }
  .overview-right h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  .overview-right {
    margin: 30px 0px 0px 0px;
  }
  .overview-left-up {
    padding: 0px;
    box-shadow: none;
  }
  .addon-dedicated-page .container {
    max-width: 100% !important;
    margin-top: 0px;
  }
  .addon-dedicated-page-list {
    margin-bottom: 25px;
  }
  .updateversion {
    margin: -15px -15px 20px -15px;
  }
  .updateversion p {
    margin-bottom: 0px !important;
  }
  .disabledtakethere {
    width: 100%;
  }
  .text-result {
    justify-content: flex-start !important;
  }
  .upgradetabs {
    justify-content: center;
  }
  .dasboard_page.mobile-display.billing-page-mobile {
    margin-top: -5px !important;
  }
  .dasboard_page.mobile-display.billing-page-mobile.mobile-view-billing {
    margin-top: 11px !important;
  }
  .dasboard_page.mobile-display.billing-page-mobile .billing-page-padding-r {
    padding: 0px;
  }
  .dasboard_page.mobile-display.billing-page-mobile
    .biling-page
    .social_pannel
    ul
    li
    a {
    height: auto;
    line-height: 18px;
  }
  .mobile_social_pannel ul a.active {
    color: #7782a1;
    padding: 10px 10px 0px 10px;
    width: 80%;
    text-align: center;
    font: 15px "Axiforma-Bold";
    display: block;
    text-decoration: none;
  }
  .mobile_social_pannel ul a.active:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 45%;
    padding-top: 10px;
    border-bottom: 2px solid #3064f9;
  }
  .mobile_social_pannel ul a {
    color: #3064f9;
    padding: 10px;
    font: 15px "Axiforma-Regular";
    display: block;
    text-decoration: none;
  }
  .mobile_social_pannel ul li {
    list-style-type: none;
  }
  .mobile_social_pannel ul {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(112, 155, 250, 0.1);
  }

  .thank-you-page h1 {
    font: 20px "Axiforma-Bold";
  }
  .user-md-padding {
    padding: 0px !important;
  }
  .my_apps_width .annual-plan-offer {
    height: auto !important;
    padding: 10px;
    padding-bottom: 20px;
    margin: 15px 0px 10px 0px !important;
  }
  .transparent_background .annual-plan-offer {
    margin-left: 15px;
    margin-right: 15px;
  }
  .thank-you-page p {
    font: 14px "Axiforma-Regular";
    width: 100%;
  }
  .build-history-tab {
    border-bottom: 1px solid #dce1eb;
    margin-top: 15px;
  }
  .mobile-app-version.app-versions {
    margin: 0px;
    padding: 0px 15px;
  }
  .mobile-app-version.app-versions .common-height {
    height: 70px;
  }
  .app-versions .dashboard-version-page .payment-status {
    text-align: right;
  }
  .build-history-tab ul li.active,
  .build-history-tab ul li.notactive {
    padding: 15px 20px;
    font: 14px "Axiforma-Bold";
    width: 100%;
    text-align: center;
  }

  [dataclass~="countrycode"] .country-line {
    padding: 10px 15px;
  }
  .search-box .form-control::placeholder {
    font-size: 16px;
  }
  /* [dataclass~="countrycode"] .modal-content {
    position: relative !important;
  } */
  .ios-buid-width-change {
    width: 50% !important;
  }
  .ios-buid-width-change.mobile-view-tooltext-align {
    width: 100% !important;
  }
  .mobile_padd_issue .toggle-width-change {
    width: 75% !important;
  }
  .reset-body p {
    width: 100%;
    padding-bottom: 30px;
  }
  .ios-modal-bottom .next_btn {
    width: 100%;
  }
  .ios-buid-width-fix {
    width: 100% !important;
  }
  div.toggle-width-fix {
    width: 25% !important;
  }
  .togglebuttonspac {
    margin-right: 14px;
  }
  .dasboard_page.my_apps_width.email-page {
    background-color: #fff;
  }
  .email-page .email-support {
    box-shadow: none;
    background-color: transparent;
  }
  [dataclass~="buildiosform"],
  [dataclass~="appsexists"] {
    max-height: inherit !important;
  }
  [dataclass~="buildiosform"] .modal-content {
    position: relative !important;
  }
  [dataclass~="buildiosform"] .modal-content .modal-xxl-content h4 {
    font-size: 15px;
  }
  .upload-provision .certificate-file {
    width: 100%;
  }
  [dataclass~="buildiosform"] .modal-content .grey-content p {
    width: 100%;
    overflow: hidden;
  }
  .reset-btn {
    margin-right: 0px;
    width: 85%;
    text-align: center;
    height: 48px;
    line-height: 48px;
  }
  .test-result .media {
    left: 15px;
  }
  .category-class-four.categoriesset .category-list .cat-product {
    width: 48.8%;
    margin-bottom: 10px;
  }
  .category-class-four.categoriesset
    .category-list
    .cat-product.solid-category {
    margin-bottom: 5px;
  }
  .test-result .app-version-draft-code {
    margin-left: 0px;
  }
  li.test-item {
    font: 15px "Axiforma-Bold";
  }
  li.test-item,
  li.test-message,
  li.test-result {
    min-height: 29px;
    padding-right: 20px;
  }
  .test-message,
  .test-item,
  .test-result {
    width: 100%;
  }
  .alternate-background {
    border-top: 1px solid #c8cedb;
  }
  .trouble-tasks {
    padding: 0px 15px;
  }
  .trouble-tasks,
  .trouble-tasks .takethere {
    width: 100%;
  }
  .listsuccess:hover {
    background: #fff;
  }
  .new-apikeys-section .apikeys-error div {
    justify-content: flex-start;
  }
  .apikeys-error,
  .force-auth {
    margin: 20px 0px 15px 0px;
  }
  .my-apps-search {
    margin: 20px 0px 10px 0px;
  }
  .force-auth .guest-login-toggle p {
    font-size: 13px;
  }
  .version-name img {
    display: none;
  }
  .version-update {
    display: none;
  }
  .categoriesset .all-category-text p {
    line-height: 25px;
  }
  .categoriesset .category-list .cat-product {
    width: 48.8%;
  }
  .app-meta .social_pannel ul {
    padding: 0px 15px;
  }
  /* Troubleshoot Modal Starts */
  .trouble-shoot-steps {
    padding: 0px 15px;
  }
  .troubleshoot-send-report {
    padding: 15px 0px 0px 0px;
    border-left: 0px;
  }
  .troubleshoot-send-report textarea {
    height: 85px;
  }
  .troubleshoot-send-report p {
    margin-bottom: 15px;
  }
  .troubleshoot-send-report .fixyourself p {
    margin-top: 20px;
    font-size: 14px;
  }
  .fixyourself .media {
    font-size: 14px;
  }
  .troubleshoot-detail.how-to-fix ul {
    padding-bottom: 0px;
  }
  .troubleshoot-detail.how-to-fix {
    margin-top: 5px;
    padding: 0px;
  }
  .troubleshoot-detail.how-to-fix ul li {
    margin-bottom: 10px;
  }
  .troubleshoot.modal-body h4 {
    font-size: 16px;
    padding: 18px 15px;
  }
  .trouble-shoot-steps ul li svg {
    width: 18px;
  }
  .troubleshoot.modal-body .crossmodal {
    top: 15px;
  }
  .trouble-shoot-steps ul {
    padding: 20px 0px;
  }
  .troubleshoot-detail {
    border: none;
    padding: 20px 0px;
  }
  /* Troubleshoot Modal Ends */
  .ios-modal-bottom {
    padding: 15px;
  }
  .csr-file h5 {
    font-size: 13px;
  }
  .provision-certificate .crossmodal {
    top: 10px;
  }
  .provision-certificate {
    padding: 20px 20px 0px 20px !important;
  }
  .mobile-ios-rebuild-padding {
    padding-bottom: 30px !important;
  }
  .provision-certificate h4 {
    font-size: 16px;
  }
  .custom-class-add,
  .upload-btn {
    padding-top: 10px;
  }
  .toggle-responsive-spacing {
    padding-top: 0;
  }
  .upload-provision {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .beta-toggle-alignment {
    padding-top: 0;
    padding-bottom: 0;
  }
  .upload-btn [type="file"] + label {
    width: 100%;
    margin: 0px;
  }
  /* Publish App  Starts */
  .two-apps .creating-build {
    margin-top: 10px;
  }
  .click-to-scroll {
    display: none;
  }
  .two-apps .button_blue {
    width: 100%;
    margin-top: 10px;
  }
  /* Publish App  Ends */
  .changeicon {
    margin: 20px 10px 15px 10px;
    padding: 10px;
    height: 83px;
  }
  /* .changeicon p{
		display: none;
	} */
  .changeicon h2 {
    margin-bottom: 5px !important;
    font-size: 16px;
  }
  .app-status {
    margin-right: 15px;
  }
  .premium-image {
    width: 25%;
  }
  .app-versions {
    margin: 0px 15px;
  }
  .app-dash-analytics .two-apps:first-child {
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 15px;
  }
  .app-dash-analytics .api-details {
    margin-bottom: 5px;
  }
  .upgrade-premium {
    padding: 15px;
    cursor: pointer;
    margin: 0px 15px;
  }
  .premium-heading {
    margin-left: 15px;
    width: 75%;
  }
  .premium-heading h3 {
    font-size: 15px;
  }
  .premium-heading p {
    height: 15px;
    overflow: hidden;
    font-size: 12px;
  }
  .upgrade-now {
    display: none;
  }
  .meta-table .container4 p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .country-or-region {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .country-list .meta-table {
    width: 48%;
    margin-right: 5px;
  }
  /* Add On Page Starts */
  .addons .pop-modal.total-subscriber {
    padding: 0px 15px;
  }
  .addons-upper-body .add-image {
    margin-bottom: 15px;
  }
  .addons-upper-body .add-details h4 {
    font-weight: 700;
  }
  .addons-upper-body .add-details h6 {
    margin-top: 10px;
  }
  .addons .total-subscriber .next_btn {
    width: 100%;
  }
  .addons .total-subscriber {
    width: 100%;
  }
  .addons-upper-body {
    padding: 20px 15px;
  }
  /* .app-addons .app-security{
		display: block;
		padding: 25px 15px;
		min-height: auto;
	} */
  /* .app-addons .app-security .custom-add{
		text-align: center;
		width: 100%;
		margin:auto;
		padding-top: 15px;
	} */
  .app-addons .addonpack h4 {
    padding-bottom: 15px;
  }
  .app-addons .total-subscriber {
    width: 100%;
    position: relative;
    margin-top: 15px;
  }
  .app-addons .app-security .custom-add h3 {
    font-size: 16px;
    margin-top: 15px;
  }
  /* .app-addons .app-security p{
		padding: 5px 0px!important;
		text-align: left;
	} */
  .app-addons {
    padding: 0px 15px;
  }
  /* Add On Page Ends */
  /* Android Form Starts */

  .submit-last-step .meta-table .container4 p {
    font-size: 14px;
  }
  .grant-login-access h2 {
    margin-bottom: 15px;
  }
  .grant-login-access .meta-table {
    padding: 0px;
    border: none;
  }
  .meta-table-new {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
  }
  .submit-last-step .upload_files {
    border: none;
  }
  .submit-last-step .meta-table .container4 .oauth-secure {
    display: none;
  }
  .ios-form-toggle {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .app-meta .short-description {
    height: 90px;
  }
  .app-meta .long-description {
    height: 150px;
  }
  .next-button-draft {
    width: 48%;
  }
  .next-button-draft .next_btn {
    width: 100%;
  }
  /* Android Form Ends */
  .meta-table .container4 p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .country-or-region {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .country-list .meta-table {
    width: 48%;
    margin-right: 5px;
  }
  /* Add On Screen Starts */
  .modal-title svg {
    margin-bottom: 5px;
  }
  .publish-on-modal h4 {
    font-size: 16px;
    margin-top: 15px;
  }
  .mobile-heading-modal {
    display: block;
  }
  .purchase-add-on-modal img {
    float: left;
  }
  .publish-on-modal h3 {
    margin-top: 15px;
  }
  .purchase-add-on-modal {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 20px !important;
  }
  .addonpayment {
    padding: 15px;
    margin-bottom: 35px;
    border: none;
  }
  .add-ons-plan {
    margin-top: 15px;
  }
  .addonpage {
    background-color: #fff;
  }
  .add-on-plan {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .add-ons-plan .row .col-md-9,
  .add-ons-plan .row .col-md-3 {
    padding: 0px;
  }
  /* Add On Screen Ends */
  .change-website {
    width: 90%;
    margin: auto;
    margin-top: 25px;
    height: 45px;
    line-height: 45px;
  }
  .api-not-connected .conecttoapi p {
    width: 100%;
  }
  /* Media */
  .maintainance .not-exist h1 {
    font-size: 25px;
  }
  .maintainance {
    width: 100%;
  }

  .maintainance .not-exist p {
    width: 80%;
    margin: auto;
  }
  .recent-dashboard img {
    width: 100%;
  }
  .maintainance .padding-15 {
    padding: 0px;
    margin-top: 25px;
    text-align: center;
  }
  .update-type h2 {
    width: 100%;
  }
  .update-type.modal-categories {
    padding-top: 20px !important;
  }
  .change-website div {
    justify-content: space-between;
  }
  .installedpluginlink {
    width: 100%;
  }
  .other-settings .common-height {
    height: 120px;
  }
  .download-sample-app {
    display: none;
  }
  .blog-banner-image {
    height: 210px !important;
  }
  .blog-detail p img {
    width: 100% !important;
    height: auto;
  }
  #pluginsub .row {
    margin: 0px;
  }
  .dashboard-content .dashboard-padding-15 .common-height {
    padding: 15px !important;
  }
  /* .rename-app {
		padding: 10px 0px;
	} */
  .app-verified h4,
  .app-not-verified h4 {
    margin-left: 5px !important;
  }
  .app-verified,
  .app-not-verified {
    border: none !important;
  }
  .app-verified img,
  .app-not-verified img {
    width: 17px;
  }
  .guest-login-toggle p {
    font-size: 14px;
    margin-bottom: 6px;
    text-align: left !important;
  }
  .guest-login-toggle h6 {
    font-size: 12px;
  }
  .dashboard-footer img {
    width: 20%;
  }
  .conecttoapi h2 {
    font-size: 15px;
  }
  .conecttoapi p {
    width: 100%;
  }
  .ipad-top-margin-zero.mobile-spacing-top {
    margin-top: 50px;
  }
  .not-exist p {
    text-align: center;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .not-exist h1 {
    text-align: center;
    font-size: 35px;
  }
  .tipsection {
    display: none;
  }
  .gapforfooter {
    margin-bottom: 104px;
  }
  .overlay-text {
    display: none;
  }
  .applinks {
    margin-bottom: 50px;
  }
  .mobiletoaster {
    width: 350px !important;
  }
  .mobiletoaster p {
    font-size: 14px;
    font-family: "Axiforma-Regular";
  }
  .modal-header.croppopup {
    border-radius: 0px;
  }
  [dataclass*="addonpopup"] .close {
    display: block;
  }
  .white-mobile-border .sidenav {
    height: 100%;
    border-radius: 0px;
    min-height: 900px;
  }
  .splash_mo .choose-from-library {
    min-height: 24px;
  }
  .splash_mo .not-choose-from-library {
    min-height: 72px;
  }
  .crop-save {
    height: 97% !important;
  }
  .deleteapps {
    top: 6px;
    right: 13px;
  }
  .inside_image {
    height: 311px;
  }
  .categoriesset p,
  .section-items p,
  .recently-viewed p,
  .in-cart p {
    font-size: 14px;
  }
  .installed-plugin {
    padding-top: 15px;
  }
  .apidescription h5 {
    margin-bottom: 14px;
    margin-top: 14px;
  }
  .section-items .cat-product svg,
  .in-cart .cat-product svg,
  .recently-viewed .cat-product svg {
    width: 375px;
  }
  .product-delete svg {
    width: 66px;
  }
  .arrival .cat-product svg {
    width: 365px;
  }
  .section-items {
    height: 515px;
  }
  .backcolorview {
    margin-right: 10px;
  }
  .tooltip-container svg {
    width: 13px;
    height: 13px;
  }
  .tooltip1:before {
    border-top: 7px solid transparent;
    border-bottom: 7px solid rgba(51, 51, 51);
    top: -12px;
    bottom: unset;
  }
  .tooltip1 {
    width: 180px !important;
    right: -127px !important;
    height: fit-content;
    top: 29px;
  }
  .mobile-web-view-tooltext {
    right: -87px;
  }
  .mobviewspacing {
    width: max-content !important;
  }

  .customwidthor p {
    padding: 0px !important;
  }
  .form-group .http select {
    background-size: 12px;
  }
  .my-all-apps {
    padding-bottom: 100px;
  }
  .footerbar {
    text-align: center;
  }
  .copyright_section p {
    margin-bottom: 5px;
  }
  .footer-links a {
    margin: 20px;
  }
  .video-image img {
    display: none;
  }
  .videopart {
    margin-left: 0px;
  }
  .socialinput .social_settings {
    justify-content: flex-start !important;
  }
  .socialinput .social_settings input {
    width: 100%;
    padding-left: 10px !important;
  }
  #clock {
    top: -24px !important;
    position: fixed;
    left: -70%;
  }
  .movecustomlogo {
    transform: scale(0.4);
  }
  .dashboard-mobile .white-mobile-border .top-header {
    width: 100%;
    border-radius: 0px;
    height: 95px;
    padding: 15px;
  }
  .dashboard-mobile .search-bar {
    width: 100%;
    height: 35px;
  }
  .dashboard-mobile .mainbanner {
    height: 150px;
    width: 100%;
  }
  .apps-image {
    padding: 40px 30px 0px 30px;
  }

  .categoriesset {
    padding: 15px;
  }
  .dashboard-mobile .categories,
  .dashboard-mobile .section-items,
  .in-cart,
  .recently-viewed,
  .categoriesset {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
  }
  .style-page-cart .white-mobile-border {
    top: 0px;
    background-color: #f8fafe;
  }
  .big-tab p {
    display: none;
  }
  .big-tab .cate {
    text-align: center;
  }
  .big-tab .cate svg {
    width: 75px;
    height: 75px;
  }
  .style-mobile .top-header {
    width: 100%;
    height: 60px;
    padding-top: 22px;
  }
  .light-blue-cart {
    bottom: 50px;
  }
  .forgotscreen p {
    margin-bottom: 40px;
  }
  .proceed-to-checkout {
    height: 50px;
  }
  .helpheaderdropdown::before {
    right: 55px;
  }
  .cart-items {
    padding-right: 15px;
  }
  .menu-icon p,
  .light-blue-cart h6,
  .light-blue-cart p {
    font-size: 15px;
  }
  .cart-bottom {
    padding: 15px;
  }
  .logindesignbutton {
    height: 50px;
    line-height: 50px;
  }
  .logindesignbutton h5 {
    line-height: 50px;
    font-size: 16px;
  }
  .cart-items h5 {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .cart-items {
    height: 75px;
  }
  .logindesignotherinput p {
    margin-top: 40px !important;
  }
  .loginsocialbtn {
    padding: 10px 6px 10px 6px;
    height: 40px;
  }
  .headerdropdown::before {
    right: 7px;
  }
  .headerdropdown .dropdown-item:first-child {
    padding: 10px !important;
  }
  .dropdown-item a,
  .profilecontent h4,
  .loginotheroptions p,
  .logoutt,
  .loginotheroptions p span {
    font-size: 14px;
  }
  .dropdown-item a svg,
  .logoutt svg {
    width: 17px;
  }
  .dropdown-item {
    padding: 0px 0px 0px 18px !important;
  }
  .support-tab {
    padding: 8px 0px 8px 20px;
  }
  .helpheaderdropdown {
    width: 240px;
    left: 39px !important;
  }
  .headerdropdown {
    width: 225px;
  }
  .helpheaderdropdown .dropdown-item a,
  .headerdropdown .dropdown-item a {
    padding: 10px 0px 10px 0px !important;
  }
  .white-mobile-border {
    left: -1px;
    top: 0px;
    position: fixed;
  }
  .preview-red-cross {
    display: none;
    position: fixed;
    bottom: 50px;
    left: 0px;
    right: 0px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
  }
  .signup-form {
    width: 100%;
    bottom: 25px;
    position: fixed;
    padding: 0px 15px;
    left: 0px;
    right: 0px;
  }
  .logindesign .logindesignbutton {
    margin-top: 30px !important;
  }
  .categories-show .modal-1 {
    margin-bottom: 40px !important;
  }
  .categories-show.modal-body {
    padding: 0px 15px;
  }
  .blue-box {
    height: 115px;
    width: 109px;
  }

  .search-bar p {
    font-size: 11px;
  }
  .modal-categories {
    padding-top: 60px !important;
  }
  .update-woo-plugin h2,
  .categories-show h2 {
    font-size: 17px;
    line-height: 22px;
  }
  .logindesigninput p,
  .signupdesigninput p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .signupdesigninput {
    margin-bottom: 25px;
  }
  .logindesign .logindesignotherinput {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .logindesigninput h6,
  .loginotheroptions h4,
  .loginotheroptions h4 span {
    font-size: 14px;
  }
  .logindesignotherinput p,
  .forgotscreen p {
    font-size: 14px;
  }
  .forgotscreen h6 {
    font-size: 16px;
  }
  .loginotheroptions p {
    margin-bottom: 10px;
  }
  .logindesignotherinput p {
    margin-bottom: 20px !important;
  }
  .slide-arrow.right,
  .slide-arrow.left {
    position: fixed;
    z-index: 9;
    top: 220px;
  }
  .preview-red-cross a:hover {
    cursor: pointer;
  }

  .iphone-full-width .my-app-heading {
    margin-top: 35px;
    text-align: center;
    font-family: "Axiforma-Regular";
  }
  .inside_image .onhoverparent .appimg {
    background-size: cover !important;
    width: 100% !important;
    height: 300px !important;
  }
  .deleteapps #Rectangle_2307,
  .deleteapps #Rectangle_2308,
  .deleteapps #Line_112,
  .deleteapps #Line_110,
  .deleteapps #Line_111 {
    stroke: #000;
  }
  .hoverdashboard p,
  .hoverdashboard {
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    color: #3064f9;
    font-weight: 500;
    background-color: #fff;
  }

  .androidpublish {
    width: 110px;
  }
  .androidpublishright {
    width: calc(100% - 110px);
  }
  .planA,
  .planB {
    margin-bottom: 15px;
    padding-bottom: 20px;
  }
  .my_apps_width .container {
    margin-top: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .get-quote {
    width: 100%;
    text-align: center;
  }

  .planA p,
  .planB p {
    margin-top: 20px;
  }
  .upgradeplan {
    margin-top: 20px;
  }
  #myTabss li a {
    padding: 10px 0px;
  }
  .planA h4,
  .planB h4 {
    margin-top: 20px;
  }
  .bigger-plan {
    margin-top: 0px;
  }
  .faq h2 {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: left;
  }
  .faq h5 {
    font-size: 16px;
  }
  .faq p {
    font-size: 14px;
  }
  .two-apps p {
    margin-top: 0px;
  }
  .two-apps .white_btn {
    margin-top: 10px;
    background: #f5f7ff;
  }
  .notify-y-n {
    width: 64px;
  }
  .packageimg {
    width: 100%;
  }
  .mobilecreatenew {
    width: 100% !important;
  }
  .girl-image {
    width: 72px;
  }
  .girl-image div {
    width: 72px !important;
  }
  .notification-heading {
    width: calc(100% - 72px);
    padding-left: 5px;
  }
  .image-noftication {
    width: calc(100% - 64px);
  }
  .notification-heading p {
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .notification-heading h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .packagecontent {
    width: 100%;
  }
  .meta-table {
    width: 100%;
  }
  .mobileappname {
    padding: 0px 15px;
  }
  .app-list {
    width: 100%;
    float: none;
  }
  .addonpackage {
    padding: 0px;
  }
  .pricing-tabs {
    float: none;
    width: 100%;
  }
  .upgradesection {
    width: 100%;
    padding: 15px;
  }

  /* App Icon */
  .dashboardheader {
    box-shadow: none;
  }
  .phone-heading p {
    font-size: 16px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
    color: #1b2952;
  }
  .menu-bar-img {
    margin-top: -5px;
  }
  .menu-phone-icon {
    margin-top: -3px;
  }
  .menu-phone-icon-new {
    margin-top: -3px;
  }

  .phone-menu {
    padding: 10px 15px;
    align-items: center;
    justify-content: space-between;
  }
  /* .mobile-display .transparent_background {
    padding: 0px;
    margin-top: 0px !important;
  } */
  .customiseapp {
    opacity: 0;
    z-index: -1;
  }
  .dashboardcust {
    display: none;
  }
  .transparent_background {
    margin-left: 0px;
    width: 100%;
  }
  .mobile-display .right_panel {
    box-shadow: none;
    padding: 0px;
  }
  .mobile-display .customiseappfeatures {
    padding: 0px;
  }
  .setting-name {
    display: block !important;
  }
  .two-btn-tabs .createtabs {
    width: 100%;
    padding: 0px 15px;
  }
  .setting-subheading {
    box-shadow: 0px 3px 6px rgba(112, 155, 250, 0.1);
    border-top: 1px solid #f1f5fc;
  }
  .app_iconname h3 {
    color: #1b2952;
    font-size: 20px;
    font-family: "Axiforma-Bold";
  }
  .defaultimage {
    width: 175px;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .two-btn-tabs .createtabs li {
    width: 50%;
    margin: 0px;
    line-height: 25px;
  }
  .two-btn-tabs .createtabs li:first-child {
    margin-right: 10px;
  }
  /*.createtabs li .active {
		border: 1px solid #BFD1FF !important;
		box-shadow: 0px 2px 4px rgba(84, 125, 242, 0.25)
	}*/
  .two-btn-tabs {
    padding-top: 30px;
  }
  .large-up-back .up {
    background-color: #f8fafe;
  }
  .backcheck {
    margin-left: 1px;
    margin-top: 4px;
  }
  .aligndiv p {
    display: none;
  }
  .categoryimage .active .first-category {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .categoryimage .second-category {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    margin-top: -1px;
    border-top-color: none !important;
  }
  .categoryimage .first-category {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-color: none !important;
  }
  .categoryimage .active .big-tab h4 {
    font-size: 13px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
  .categoryimage .active .big-tab p {
    font-size: 11px;
  }
  .categoryimage .big-tab h4 {
    font-size: 13px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
  .categoryimage .big-tab p {
    font-size: 11px;
  }
  .large-up label {
    background-color: #f3f7fe;
    border-radius: 4px;
  }
  .done-btn a {
    padding: 0px;
    color: #3064f9;
    border: none;
    text-transform: uppercase;
  }
  .large-up .up {
    min-height: 200px;
  }
  .upload_files.upload_file_inmobile {
    background-color: #fff;
    /* padding: 0px 15px 5px 15px!important; */
  }
  .getstartedhead {
    box-shadow: 0px 2px 6px rgba(112, 155, 250, 0.1);
    width: 100% !important;
    max-width: 100% !important;
  }
  .upload_files h4 {
    font-size: 14px;
  }
  .color_input,
  .color_picker .form-name,
  .color_picker .form-group select {
    height: 56px;
    padding: 10px 7px !important;
  }
  .color_input input {
    height: 44px;
  }
  .fixed-button {
    bottom: 0px;
    position: fixed;
    right: 0px;
    left: 0px;
    padding: 6px 15px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
    z-index: 10;
  }
  .preview-btn {
    color: #3064f9;
    font-size: 14px;
    width: 45%;
  }
  .next_btn {
    width: 48%;
    text-align: center;
    height: 44px;
    line-height: 24px;
    font-size: 14px;
    font-family: "Axiforma-Regular";
  }
  .app-head-ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
  }
  .app-head-ul ul {
    margin-bottom: 0px;
  }
  .app-head-ul li {
    width: 13px;
    height: 13px;
    border: 1px solid #bcc7e6;
    border-radius: 100%;
  }
  .app-head-ul .checked {
    background-color: #7782a1;
  }
  #upload .large-up .up {
    background-color: #f8fafe;
    border: 1px dashed #c8cedb;
  }
  .tab-content {
    padding-bottom: 60px;
  }
  .customiseappfeatures {
    width: 100% !important;
  }
  .app_iconname {
    width: 100%;
  }
  .color_picker {
    width: 100%;
  }
  .footer-button .modal-next {
    width: 50% !important;
  }
  /* App Icon Ends */
  /* Splash Page Starts*/
  .upload-image {
    border: none !important;
    background-color: #fff !important;
    position: absolute;
    top: 21px;
    right: 15px;
    width: auto;
    height: 16px;
    line-height: 7px;
  }
  .search-box {
    width: 100%;
  }
  .splash_mobile_upload .defaultimage {
    width: 175px !important;
    margin-bottom: 0px;
  }
  .modaltext {
    width: 120px !important;
    margin-bottom: 10px;
  }
  .choose-from-library .modaltext {
    margin-bottom: 0px;
  }
  .choose-from-library .upload-image {
    top: 23px;
  }
  .customwidth,
  .customwidthor {
    width: 100% !important;
  }
  .verify-account {
    margin: 30px 15px 0px 15px;
    height: 85px;
    padding: 10px 15px;
  }
  .resend-verify-email .resend-email {
    margin-left: 25px;
    margin-top: 5px;
  }
  .verify-alert p,
  .resend-verify-email .resend-email {
    font-size: 13px;
  }
  .customwidthor {
    padding-bottom: 20px;
    margin-top: 20px;
  }
  .app-logo .two-btn-tabs {
    padding-top: 10px;
  }
  #upload_app_logo .large-up .up {
    background-color: #f8fafe;
    border: 1px dashed #c8cedb;
  }
  .modal-dialog {
    max-width: 100% !important;
    margin: auto !important;
  }
  .app_dummy {
    width: 46%;
    height: 70px;
  }
  .customiseapp {
    width: 100%;
    position: absolute;
    right: 0;
    top: -62px;
    background: white;
    height: 100%;
  }
  .align-mobile-item {
    height: 45px;
    line-height: 42px;
  }
  .two-btn-tabs .promotertabs {
    display: none;
  }
  .mobile_bg {
    width: 100%;
  }
  .mobile_img {
    background: white;
  }
  .premade_icon {
    margin-top: 187px;
  }
  .upperspace {
    margin-top: 195px;
  }
  .align-mobile {
    width: 33.33%;
    text-align: center;
    line-height: 45px;
    background-color: #f1f5fd;
    margin-top: 15px;
  }
  .placement .active {
    background-color: #e0e5f4;
  }
  .modal-header.splash-modal {
    padding: 0px !important;
  }
  .modal-xxl-content.splash_mo {
    padding: 15px 15px;
  }
  .modal-body-content {
    height: 700px !important;
    padding: 0px 7px;
  }
  .modal-content {
    position: fixed !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border: none !important;
    border-radius: 0px !important;
  }
  .first_app .upload_files {
    background-color: #f3f7fe;
  }
  .modal-body-content .first_app {
    margin-right: 0px;
  }
  [dataclass*="addonpopup"] .modal-dialog {
    margin-top: 0px !important;
  }
  [dataclass*="addonpopup"] .modal-content {
    position: static !important;
    margin: auto;
    width: 355px;
  }
  [dataclass*="addonsdata"] .modal-content {
    position: fixed !important;
    margin: auto;
    height: 100%;
    width: auto;
  }
  [dataclass*="addonpopup"] .next_btn {
    border-radius: 0px;
  }
  [dataclass*="addonsdata"] .next_btn {
    border-radius: 4px;
  }
  .footer-button {
    position: fixed !important;
    box-shadow: 0px 0px 6px rgba(112, 155, 250, 0.2);
    left: 0;
    padding: 10px 15px;
    bottom: 0;
    width: 100% !important;
  }
  .back_btn {
    width: 50%;
    height: 44px;
    text-align: center;
    line-height: 22px;
  }
  .activestock:after {
    line-height: 78px;
  }
  .modal-xxl-content h4 {
    font-size: 18px;
  }
  .on-off {
    margin: 0px !important;
    padding: 0px 15px !important;
  }

  .customiseappfeatures {
    padding-bottom: 50px !important;
  }

  .two-btn-tabs .categoryimage li {
    width: 100%;
  }
  .switch {
    height: 22px;
    width: 44px;
  }
  .slider:before {
    height: 18px;
    width: 18px;
    bottom: 2px;
    left: 2px;
  }
  .social_pannel ul li {
    width: 33%;
    text-align: center;
  }
  .social_pannel ul li .active,
  .social_pannel ul li a:hover {
    padding-bottom: 9px;
  }
  .pricing-tabs .two-btn-tabs .createtabs.upgradetabs li {
    width: 48% !important;
  }
  .pricing-tabs .two-btn-tabs .createtabs {
    padding: 0px;
  }
  .social_pannel ul li a {
    padding: 0px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
  }
  .pricing-tabs .two-btn-tabs .createtabs li:first-child {
    margin-right: 0px;
  }
  .cart-bottom input:focus {
    border: none;
  }

  .right_general > .row,
  .right_general > .copy_function > .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .copy_function .upload_files {
    padding: 0px !important;
  }
  #pluginsub .mobilenxt {
    width: 100%;
  }
  .mobilenxt {
    width: 100%;
  }

  .payment-box p {
    display: none;
  }
  .socialinput {
    margin-top: 0px;
  }
  .socialinput:last-child {
    margin-bottom: 5px !important;
  }
  .install-plugin {
    padding: 20px 0px;
    margin: auto;
  }

  /* Splash Page Ends*/
  .custon_gap2 {
    padding-top: 0px !important;
  }
  .payment-gateway {
    padding: 0px 15px;
  }
  .social_pannel ul {
    box-shadow: 0px 2px 6px rgba(112, 155, 250, 0.1);
  }
  .app_iconname h5 {
    display: none;
  }
  .two-apps {
    padding: 10px;
    margin: 15px;
  }
  .two-apps .white_btn {
    width: 100%;
  }
  .payment-img img {
    height: 45px;
  }
  .newupload-image {
    width: 100%;
  }
  .your-info {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .your-info .next_btn {
    width: 100%;
  }
  .w-24 {
    width: 100%;
  }
  .total-subscriber {
    border-top: none;
    padding: 0px;
    width: 60px;
    height: auto;
  }
  /* .app-security {
		display: flex;
		padding-top: 0px;
		margin-bottom: 22px;
		padding: 10px;
		align-items: center;
	} */

  .subscribers img {
    width: 12px;
  }
  .app-security h3 {
    font-size: 14px;
    margin-top: 0px;
  }
  .addonpack {
    padding: 13px 0px;
  }
  .addonpack h4 {
    font-size: 15px;
  }
  /* .custom-add {
		width: calc(100% - 150px);
		text-align: left;
		margin-left: 12px
	} */
  .head-shadow {
    margin-bottom: 20px;
  }
  .dasboard_page {
    height: auto;
  }
  .left_panel {
    z-index: 99;
    top: 0;
    margin-top: 0px;
    right: 0;
    transform: translateX(100%);
    transition: all 0.5s ease;
    overflow-y: auto;
    width: 300px;
  }
  .movepanel {
    transform: translateX(0%);
  }
  .backcolor:after {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 99;
  }
  .mobilemenu h4 {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    padding: 0px;
    margin: 0px;
  }
  .mobilemenu {
    margin-left: 20px;
    margin-right: 10px;
    align-items: center;
    height: 50px;
  }
  .monmenu {
    border-top: 1px solid #edf0f5;
  }
  /*  Modal */
  .add-app {
    background-color: #3064f9;
    height: 60px;
  }
  .add-image img {
    width: 120px;
  }
  .productsdetailpg {
    padding: 0px 15px;
  }
  .addtocartbtn {
    margin-top: 0px !important;
    bottom: 0px !important;
    width: 100% !important;
  }
  .addtocartbtn button {
    width: 100% !important;
    border-radius: 0px !important;
  }
  .add-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .addons p {
    margin-bottom: 0px;
    margin-top: 0px;
    color: #7782a1;
    font-size: 12px;
  }
  .add-details p {
    color: #1b2952;
    font-size: 14px;
  }
  .add-details {
    width: 100% !important;
  }
  .add-details h4 {
    font-size: 16px;
  }
  .addonpack p {
    color: #47ce70;
  }
  .shareapp .addonpack h4 {
    font-size: 30px;
  }
  .subscribers img {
    width: 12px;
  }
  .addons ul li {
    padding-bottom: 10px;
  }
  .addons ul {
    margin-bottom: 0px !important;
  }
  .add-app a {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 60px !important;
    height: 60px !important;
    width: 100% !important;
    margin: auto;
    text-align: center;
  }
  .addons h3 {
    font-size: 16px;
  }
  .bill ul li {
    width: 50%;
  }
  .bill ul li a {
    padding: 10px !important;
  }
  /*    Modal Ends*/
  .biling-page .social_pannel ul li a {
    padding: 10px 50px;
  }
  .biling-page .social_pannel ul {
    border-bottom: 0px;
  }
  .phone-subscription img {
    width: 40px;
  }
  .subscriptions h4 {
    font-size: 14px;
  }
  .mobile-payment {
    border: 1px solid #bcc7e6;
    border-radius: 7px;
  }
  .phone-subscription h4 {
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1b2952;
    margin-bottom: 0px;
    word-break: break-word;
  }
  .plan-name p {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #1b2952;
    margin-bottom: 0px;
  }
  .plan-start p {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #1b2952;
    margin-bottom: 0px;
  }

  h5.expiry-date {
    color: #1b2952;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #1b2952;
    margin-bottom: 0px;
  }
  .social_pannel .billingsearch {
    height: 45px;
    margin: 15px;
  }
  .plan-start span {
    color: #7782a1;
  }
  .plan-expires p {
    font-family: "Axiforma-Regular";
    font-size: 10px;
    color: #1b2952;
    margin-bottom: 0px;
  }
  .plan-expires span {
    color: #f548488c;
  }

  .ptb-3 {
    padding: 14px;
  }

  .edit-link a {
    color: #3064f9 !important;
    font-size: 12px;
    font-family: "Axiforma-Regular";
  }
  .paid-link h4,
  .plan-text p,
  .pay-txt p {
    color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";

    line-height: 15px;
    margin: 0;
  }

  .plan-text span {
    font-size: 12px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
  }

  .plan-name h6 {
    font-size: 12px;
    color: #7782a1;
    font-weight: 500;
    margin-bottom: 0;
  }
  /* Billing history */
  .plan-start .bill-invoice {
    font-family: "Axiforma-Regular";
    font-size: 16px;
    line-height: 19px;
    color: #3064f9;
    margin-bottom: 0px;
  }
  .biling-page .month {
    width: 100%;
  }
  .month select {
    height: 43px;
    width: 92%;
    margin: 15px;
  }
  .plan-start .app-name {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782a1;
    margin-top: 0px;
  }
  .plan-cost p {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #1b2952;
    margin-bottom: 0px;
  }
  .plan-cost span {
    color: #1b2952;
    font-family: "Axiforma-Bold";
  }
  .analytics-data {
    width: 100%;
  }
  .month {
    width: 100%;
  }
  .installed-plugin .plugin_img {
    margin-top: 15px;
  }
  .installed-plugin .plugin_content {
    text-align: left;
  }
  .installed-plugin .plugin_content p {
    width: 100%;
    text-align: left;
    margin-top: 0px;
  }
  .installed-plugin .plugin_content h4 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .pluginh2 h2 {
    color: #1b2952;
    font-family: "Axiforma-Bold";
    font-size: 16px;
    margin-top: 10px;
  }
  .pluginh2 p {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782a1;
    line-height: 20px;
  }

  /* Biilling History Ends*/
  /* Analytic Starts */
  .version-name img {
    width: 29px;
  }
  .version-name h3 {
    font-family: "Axiforma-Bold";
    font-size: 14px;
  }
  .restore-version,
  .version-update {
    width: 150px;
  }
  .restore-version .green-active {
    width: 75px;
    font-size: 10px;
    margin: auto;
    text-align: center;
  }
  .restore-version .grey-inactive {
    width: 88%;
    text-align: center;
    font-size: 10px;
    margin: auto;
  }
  .version-update p {
    font-size: 10px;
  }
  .version-changes {
    width: 100%;
    border-bottom: 0px;
  }
  .app-versions .version-changes {
    width: 150px;
    text-align: center;
  }
  .download-dashboard a {
    font-size: 12px;
  }
  .download-dashboard {
    width: 160px;
    text-align: right;
  }
  .app-versions .common-height {
    border-bottom: none;
    height: 50px;
  }
  .padding-15 {
    padding: 0px 15px;
  }
  .version-changes p {
    font-size: 12px;
  }
  .app-versions h3 {
    margin-bottom: 0px;
  }
  .version-changes {
    text-align: left;
  }
  .analytics-data {
    margin-bottom: 10px;
  }
  .categoryimage .nav-item {
    margin-bottom: -2px;
  }
  .dash-border {
    background-color: #f3f7fe !important;
    margin: 15px;
  }
  .edit-btn {
    margin-right: 5px !important;
  }
  .edit-btn span {
    display: none;
  }
  .horizontal-alignment .align {
    height: 50px;
  }
  .dashboard-customizer .two-btn-tabs {
    padding-top: 10px;
  }
  .payment-gateway .container5 {
    padding: 10px;
    height: 70px;
  }
  .payment-gateway .checkmark {
    top: -12px;
    left: 110px;
  }
  .payment-box {
    margin-top: 20px;
    height: auto;
  }
  .request-gateway {
    height: 70px;
  }
  .payment-gateway-link button {
    width: 100%;
    margin: auto;
    height: 55px;
    border-radius: 0px;
  }
  .payment-gateway-link input {
    width: 93%;
    height: 55px;
    margin: auto;
  }
  .payment-gateway-link.modal-body {
    padding: 0px;
  }
  .payment-gateway-link .sharedetail p {
    padding: 0px 15px;
  }
  .payment-gateway-link h4 {
    text-align: center;
    width: 75%;
    margin: auto;
    padding-top: 25px;
  }
  .sharedetail {
    margin-bottom: 0px;
  }
  .request-gateway {
    padding-left: 10px;
  }
  .payment-img {
    width: 50px;
  }
  .green-check {
    width: 50px;
  }
  .payment-name {
    width: calc(100% - 100px);
  }
  .width-seventy {
    width: 70%;
  }
  .custon_gap2 .mobilenxt {
    width: 48%;
  }
  .plugin_content .plugin-how-it-works {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    font-size: 14px;
    height: 48px;
    border: 1px solid #1b2952;
    border-radius: 4px;
    padding: 8px;
  }
  .plugin_content .plugin-how-it-works a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plugin-how-it-works img {
    margin-right: 5px;
  }
  .two-apps h4 {
    font-size: 18px;
  }
  .two-apps p {
    font-size: 13px;
    margin-bottom: 10px;
  }
  p.build-text-spacing {
    margin-bottom: 0;
  }
  .upgradeplan:hover {
    margin-top: 0px;
  }
  .bigger-plan {
    padding: 15px !important;
  }
  .faq ul li {
    font-size: 14px;
  }
  .profile-detail-btn .next_btn {
    height: 48px;
  }
  ::-webkit-scrollbar {
    width: 2px !important;
  }
  .bigger-plan h4 {
    font-size: 18px;
  }
  .bigger-plan p {
    font-size: 14px;
  }
  .bigger-plan {
    margin-top: -40px;
  }
  .pricing-tabs .two-btn-tabs .createtabs li {
    line-height: 25px;
  }
  .pricing-tabs .two-btn-tabs {
    padding-top: 0px;
  }
  .mobilecreatenew {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ios-form h3,
  .your-info h3 {
    font-family: "Axiforma-Bold";
    font-size: 16px;
  }
  .fixed-button .back_btn {
    border: none;
  }
  .notification-heading h3 {
    margin-bottom: 0px;
  }
  .notification-heading p {
    display: none;
  }
  .notification-status p {
    display: block;
  }
  .gender-toolbar label {
    height: 28px;
    line-height: 28px;
  }

  .hollow_phone .splashlogo.logo-alignment-vertical {
    height: 550px;
  }
  .onhoverdata {
    position: relative;
    opacity: 1;
    background-color: #fff;
  }
  .showapp {
    width: 96px;
    height: 95px;
    margin-right: 15px;
    padding: 10px;
    border-radius: 7px;
  }
  .inside_image .green-active {
    width: 50%;
    margin-top: 80px;
  }
  .app-name {
    text-align: left;
  }
  .three-dot img {
    height: 14px;
  }
  .three-dot a {
    top: 8px;
  }
  .app-list {
    margin-top: 20px;
  }
  .app-list .dash-border {
    margin: 0px;
    background-color: #fff !important;
  }
  .create-new-app {
    display: flex;
  }
  .create-new-app img {
    width: 97px;
    margin-right: 15px;
  }
  .mobile-create-new {
    align-items: center !important;
  }
  .buttontextcolor,
  .buttontextcolor:hover {
    height: 50px !important;
    line-height: 50px !important;
  }
  .butt {
    width: 100% !important;
  }
  .productspage .pro {
    width: 100% !important;
  }
  .saleimg {
    height: 135px;
  }
  .product-image img {
    width: auto;
  }
  .product-image {
    margin-right: 15px;
  }
  .newlyaddedone div,
  .newlyaddedtwo div {
    background-position: center !important;
  }
  .prodessection {
    position: fixed !important;
  }
  /* Analytcics Ends */
  .custom-width-left {
    width: 100%;
  }
  .email-support {
    padding: 5px;
  }
  .email-support h5 {
    font-size: 18px;
  }

  .custom-width-left label,
  .upload-screenshot {
    font-size: 14px;
  }
  .email-support .form-group select,
  .email-support .form-group input {
    height: 55px !important;
    font-size: 12px;
  }
  .custom-width-left .btn {
    width: 100%;
    margin-top: 25px;
  }
  .email-support li a {
    font-size: 12px;
    font-family: "Axiforma-Regular";
  }
  .upload_files .download-plugin .videopart {
    display: block !important;
  }
  .upload_files .download-plugin .videopart div {
    width: 100% !important;
  }
  .upload_files .download-plugin .videopart div.takethere {
    width: 160px;
    background: transparent !important;
  }

  .greentick {
    padding-bottom: 37px;
  }
  .videopart img {
    width: 18px;
  }
  .install-plugin-steps h4 {
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .greentick h4::after {
    display: none;
  }
  .email-support ul {
    display: flex;
    justify-content: space-around;
    padding-left: 0px;
  }
  .custom-width-right {
    width: auto;
  }
  [dataclass~="appsexists"] .app-resubmit button {
    width: 100%;
  }
  .app-resubmit.ios-modal-bottom.upload_files {
    border: 0px;
    padding: 0px 15px 20px 15px !important;
  }
  [dataclass~="appsexists"] .modal-content {
    position: relative !important;
  }
  .app-exists-modal h3 {
    font-size: 16px;
  }
  [dataclass~="appsexists"] .modal-header {
    padding: 20px 15px 15px 15px;
  }
  [dataclass~="appsexists"] .modal-header .crossmodal {
    top: 10px;
  }
  .app-exists-modal {
    margin: 0px;
  }
  .upload_file_inmobile {
    background: rgb(241, 245, 253) !important;
    margin-right: -15px !important;
    margin-left: -15px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 20px !important;
  }
}

@media (max-width: 767px) {
  .addonpara .addonpara-text-column {
    width: 100%;
    margin-bottom: 50px;
    text-align: center;
  }
  .social_settings h6 {
    margin-left: 7px;
  }
  .social-http {
    margin-right: 0px;
    width: 125px !important;
  }
  .socialinput {
    padding: 5px 10px 5px 12px;
  }
  .social_settings div {
    min-width: 15px;
  }
  .addonpara .addonpara-text-column p {
    padding-right: 0;
  }
  .addonpara .addonpara-text-column h4 {
    margin-top: 0;
  }
  .slider {
    background-size: 7px 7px;
    background-position: bottom 6.1px right 7.5px;
  }
  .switch {
    align-self: flex-end;
    height: 20px;
    width: 36px;
  }
  .slider:before {
    height: 14px;
        width: 14px;
        bottom: 3px;
        left: 3px;
  }
  input:checked + .slider:before {
    transform: translateX(16px);
  }
  .phone-host-viewspac div.phone-host-view {
    flex-wrap: wrap;
    padding: 22px 20px 0 20px;
  }
  .phone-host-view div.siteground-head {
    display: flex;
    align-items: center;
    width: 100% !important;
  }
  .siteground-head img.siteground-img {
    float: left;
    margin-right: 15px;
    margin-bottom: 22px !important;
    width: 36px !important;
    height: 49px !important;
  }
  .siteground-text {
    width: 100%;
    font-size: 22px !important;
  }
  .siteground-subtext {
    width: 100% !important;
  }
  .siteground-subtext p {
    font-size: 14px !important;
  }
  .siteground-subtext ul li {
    font-size: 14px !important;
  }
  .phone-host-viewspac div.siteground-btn-text {
    text-align: left !important;
    padding-top: 3px;
    padding-bottom: 20px;
  }
  .for-mobile {
    margin-top: 0;
  }
  .siteground-btn-text div.siteground-btn {
    margin-top: 14px;
    justify-content: center !important;
  }
  .siteground-btn-padding {
    font-size: 14px !important;
    padding: 12px 13px 0px 13px !important;
    width: 100% !important;
  }
  .right_general.mobile-web-hosting {
    padding-left: 15px;
    padding-right: 15px;
  }

  .frequency {
    font-size: 12px;
  }

  .preview-red-cross.redcross-spacing {
    bottom: 96px;
  }

  .filter-tag-mobile {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .btn.custom-radio-button1,
  .btn.custom-radio-button2 {
    font-size: 14px;

    width: 48%;
    height: 60px;
  }
  .admin-search-input-red {
    /* height: 182px; */
    width: calc(100% - 40px);
  }
  .searchdropdown.searchdropdown-new {
    z-index: 2;
    left: 50%;
    transform: translateX(-60%);
    width: 100vw;
  }
  .searchdropdown.searchdropdown-new::before {
    right: 183px;
  }
  .header-responsive-myapps-new {
    max-width: 100%;
    overflow-x: clip;
  }
  .profile-detail-btn.profile-detail-red {
    padding-bottom: 20px !important;
  }
  .launch-steps.launch-steps-red {
    text-align: left !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_product {
    margin-top: 0 !important;
  }
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    white-space: nowrap;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_general {
    margin-top: 0 !important;
  }
  .module_title.module_title_red {
    display: flex;
    align-items: center;
  }
  .delete-app-now.delete-app-red {
    margin-right: 0 !important;
  }

  .custom-radio-btn input[type="radio"]:checked + label,
  .custom-radio-btn input[type="radio"]:not(:checked) + label {
    padding-right: 65px;
    padding-left: 7px;
    padding-top: 5px;
  }

  label.form-check-label.form-label-text2 {
    padding-right: 95px !important;
  }

  p.appname-third-redesign {
    padding-right: 5px;
  }
  .custom-radio-btn [type="radio"]:checked + label:before,
  .custom-radio-btn [type="radio"]:not(:checked) + label:before {
    right: 7% !important;
  }
  .label-profile-pic {
    margin: 0px;
  }

  .remove-profile-btn {
    margin-left: 18px;
  }

  .bottom_menu_bar {
    position: sticky;
  }

  .web-view-toggle {
    flex-wrap: wrap;
  }
  .ml-3.cus-web {
    margin-left: 0 !important;
  }
  .cus-web {
    margin-bottom: 10px;
  }

  .cus-abs {
    position: absolute;
    top: -23px;
    right: 5px;
  }
  .cus-abs img {
    max-width: 18px;
  }

  .dashboard-mobile .white-mobile-border.homescreen-bottom-menuspacing {
    padding-bottom: 52px;
  }

  .live-icon {
    left: 5px;
  }
  .filter-tag-mobile .filter-tags {
    /* margin: 7px auto; */
    margin-top: 7px;
    max-width: fit-content;
  }

  .pay-txt img {
    max-width: 100%;
    width: auto;
  }

  h5.delete-account-subtext {
    display: block;
  }

  .footer.pricing-bottomspacing {
    display: block;
    padding-top: 20px;
    text-align: left;
    margin-left: 21px;
  }
  .priceminor.team-census {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    padding-right: 38px;
  }
  .plans-cta-contact-btn.plans-cta-contact-btn-mobile {
    padding-top: 17px;
    padding-left: 0;
    font-family: "Axiforma-Regular";
  }
  .plans-new-cta-mobile h1 {
    font-family: "Axiforma-Bold";
    font-size: 26px;
  }
  .plans-new-cta-mobile {
    border-left: none;
    border-top: none;
    border-right: none;
    padding-left: 0;
    padding-bottom: 53px;
    flex-wrap: wrap;
  }
  .see-more-faq-mobile {
    /* padding-top: 32.5px; */
    padding-bottom: 13px;
  }
  .see-more-faq-mobile a {
    font-family: "Axiforma-Regular";
    font-size: 16px;
    color: #7782a1;
  }
  .faq-toppadding-mobile {
    padding-top: 27.5px;
  }
  .faq-sub-mobile {
    padding-bottom: 31.5px;
  }
  .faq-container.faq-container-mobile {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .faq.faq-bottom-mobile h5 {
    font-family: "Axiforma-Bold";
    font-size: 16px;
  }

  .faq.faq-bottom-mobile h2 {
    margin-top: 56px;
    margin-bottom: 42px;
    font-family: "Axiforma-Bold";
    font-size: 26px;
  }
  .widthset-new.widthset-new-mobile {
    padding-bottom: 60px;
  }
  .priceminor.pricetick {
    padding-right: 49px;
  }

  .priceminor.pricecross {
    padding-right: 51.5px;
  }

  .priceminor {
    width: 30%;
    min-width: 30%;
    text-align: right;
    padding-right: 10px;
  }
  .pricemajor {
    width: 70%;
    min-width: 70%;
  }
  .tabbed ul.tabs {
    list-style: none;
    padding: 0px;
    display: flex;
    margin-bottom: 0px;
  }
  li.preview-tab,
  li.starter-tab,
  li.pro-tab,
  li.premium-tab {
    background: transparent !important;
    color: #222 !important;
  }
  .compare-plan-container.compare-plan-container-mobile {
    display: none;
  }

  .profile-pic-container {
    height: 82px;
    width: 82px;
  }
  .dasboard_page.mobile-display.email-support-mobile-display {
    margin-top: 20px !important;
  }
  .container1 p.email-support-checkbox {
    font-size: 14px !important;
  }
  .table_head.notif-head-alignment.mobile-head-alignment {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .other_settings .common-height.ipad-checkout-height {
    padding-top: 15px;
  }
  .comparison-table tr td:nth-child(2),
  .comparison-table tr td:nth-child(3),
  .comparison-table tr td:nth-child(4),
  .comparison-table tr td:nth-child(5) {
    width: 5%;
  }
  .head-sub-mobile {
    display: none;
  }
  .featured-platforms.featured-platforms-mobile h5 {
    margin-bottom: 40px;
  }
  .pf-group-left,
  .pf-group-right {
    padding-bottom: 30px;
  }
  .wpengine-icon-mobile,
  .trustpilot-icon-mobile,
  .associatedpress-icon-mobile,
  .wpexplorer-icon-mobile,
  .newswire-icon {
    padding-bottom: 5px;
  }
  .newswire-icon-mobile {
    padding-right: 7px;
  }
  .logsub.billingsub {
    padding: 0px 15px 0 15px;
    padding-bottom: 0;
    margin-right: 0;
  }
  .billingsub .billing-select {
    width: 100%;
  }
  .last-pf-group-mobile {
    display: flex;
    flex-wrap: nowrap;
  }
  .featured-platforms-icons-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    max-width: 100%;
  }
  .pf-group-left {
    padding-right: 47px;
  }
  .pf-group-right {
    padding-right: 0;
  }
  .featured-platforms.featured-platforms-mobile {
    margin-top: 60px;
    margin-bottom: 35px;
  }
  .trustpilot-img {
    width: 136.29px;
    height: 54px;
  }
  .capterra-img {
    width: 136.99px;
    height: 52px;
  }
  .gplay-img {
    width: 136.67px;
    height: 53px;
  }
  .wp-img {
    width: 137px;
    height: 52.97px;
  }
  .trustpilot-review.trustpilot-review-mobile {
    padding: 40px 28.85px 41.5px 29px;
  }
  .capterra-review.capterra-review-mobile {
    padding: 41px 28px 42.5px 28.85px;
  }
  .gplay-review.gplay-review-mobile {
    padding: 40.5px 28.85px 41px 29px;
  }
  .wp-review.wp-review-mobile {
    padding: 40.5px 28px 41px 28.85px;
  }
  .customer-review-text.customer-review-text-mobile p {
    margin-bottom: 40px;
  }
  .customer-review-text.customer-review-text-mobile h3 {
    font-family: "Axiforma-Bold";
    font-size: 26px;
    padding-bottom: 30px;
    line-height: 35px !important;
  }
  .customer-reviews-mobile {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
  }
  h4.card-foot-text.card-foot-text-mobile {
    margin-top: 30px;
    /* text-align: left; */
  }
  .select-button-spacing-first.select-button-spacing-first-mobile,
  .select-button-spacing-second.select-button-spacing-second-mobile,
  .select-button-spacing-third.select-button-spacing-third-mobile,
  .select-button-spacing-fourth.select-button-spacing-fourth-mobile {
    width: 240px;
  }
  .select-button-spacing-first.select-button-spacing-first-mobile {
    margin-top: 66px;
    bottom: 15px;
    width: 100%;
  }
  .select-button-spacing-second.select-button-spacing-second-mobile,
  .select-button-spacing-third.select-button-spacing-third-mobile {
    margin-top: 33px;
  }
  .select-button-spacing-fourth.select-button-spacing-fourth-mobile {
    margin-top: -7px;
  }
  .plan-desc-data.plan-desc-data-mobile {
    margin-top: 28px;
  }
  /* ul.plan-detail-list.plan-detail-list-mobile li {
      font-size: 14px;
    } */
  h2.card-heading-first.card-heading-first-mobile,
  h2.card-heading-second.card-heading-second-mobile,
  h2.card-heading-third.card-heading-third-mobile,
  h2.card-heading-fourth.card-heading-fourth-mobile {
    font-size: 20px;
  }
  .summary-detail-new.summary-detail-new-mobile {
    margin-right: 0;
    padding-bottom: 30px;
  }
  .text-center.subscription-tab-new ul.plan-nav.plan-nav-mobile {
    margin-top: 33px !important;
    width: 100%;
    margin-left: 0;
    margin-bottom: 14px;
  }
  .subscriptions-new-head.subscriptions-new-head-mobile {
    font-size: 24px;
  }
  .summary-chart-new.summary-chart-new-mobile {
    padding: 10px 0;
  }
  .pricing-plan-cards.pricing-plan-cards-mobile {
    margin-left: 0;
    margin-right: 0;
  }
  .subscription-plan-head.subscription-plan-head-mobile {
    margin-left: 0;
    width: 100%;
  }
  .card-container-width.card-container-width-mobile {
    width: 90% !important;
  }
  .setbackground-new.setbackground-new-mobile {
    padding-top: 35px;
    padding-bottom: 40px;
  }
  .card-container-width h3.subscriptions-new-head {
    text-align: left;
  }
  .head-shadow.subs-mobile-top {
    margin-bottom: 0;
  }
  .dashboard-mobile .white-mobile-border.homescreen-bottom-menuspacing {
    padding-bottom: 52px;
  }

  select#leftdropdown4,
  select#leftdropdown2,
  select#leftdropdown3,
  select#leftdropdown1,
  select#leftdropdown5 {
    padding-right: 9% !important;
  }

  .preview-red-cross-style {
    padding-bottom: 25px;
  }

  .proceed-to-checkout-new {
    margin-bottom: 52px;
  }

  .customer-review-stars {
    display: block;
    margin-right: 0;
    border: none;
    border-radius: 0;
  }

  .newswire-icon-mobile img,
  .icon-mb-3 img {
    max-width: 100%;
  }
  .icon-mb-3 {
    margin-bottom: 25px;
    max-width: 104px;
    margin: 0 auto 25px;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 47px !important;
  }
  .profile-img-mobile.profile-image-workspace.profile-img-work-new {
    padding-top: 19px;
    padding-bottom: 16px;
  }
  .profile-image-workspace.profile-img-mobile.profile-img-work-new p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .menu-phone-icon-new.ipadview-menu-leftpanel {
    display: none !important;
  }

  .right-dropdown-container {
    padding: 30px 0px 42px 0px;
    margin-left: 21px;
    margin-right: 19px;
    margin-bottom: 11px;
    border-top: 1px solid #edf0f5;
  }

  .right-dropdown-heading {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782a1;
    padding-bottom: 12px;
  }
  .right-dropdown-container.right-dropdown-container-profile {
    padding-top: 10px;
    border-top: none;
    padding-bottom: 110px;
    margin-bottom: 19px;
  }
  .profile-image-workspace.profile-image-workspace-new.main-profile-options {
    padding-bottom: 40px;
    justify-content: flex-start;
  }
  .profile-image-workspace .profile-main-btns {
    margin-right: 30px;
  }
  .profile-image-workspace .profile-main-btns img {
    width: 50px;
    margin: auto;
  }
  .profile-image-workspace .profile-main-btns p {
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 0;
    color: #1b2952;
    font-size: 12px;
  }
  .profile-image-workspace.profile-image-workspace-new {
    padding-left: 0;
    margin-left: 19px;
    margin-right: 15px;
    margin-bottom: 20.5px;
    border-bottom: 1px solid #edf0f5;
  }
  .profile-image-workspace p.owner-info {
    flex: 1;
    /* Tojo Change */

    margin-bottom: 0px;
    /* Tojo Change */
    margin-left: 14px;
  }

  /* Tojo Change */
  p.profile-img-removetext {
    margin-bottom: 0px;
  }
  /* Tojo Change */

  .right-dropdown-new .profile-image-workspace {
    justify-content: space-between;
  }
  .mobile-menu.right-dropdown-new {
    overflow: auto;
  }

  .sparkles-overlap-spacing {
    display: none;
  }

  .pf-group {
    display: flex;
    width: 100%;
    padding-right: 0 !important;
    text-align: center;
  }

  .pf-group img {
    max-width: 100%;
  }

  .pricing-background {
    padding-top: 39px !important;
  }

  .price-card {
    padding: 41px 21px 46px;
    min-height: auto;
    margin-bottom: 25px;
  }
  .pricing-mb-5 {
    margin-bottom: 21px !important  ;
  }

  .create-account {
    margin-top: 0 !important;
  }
}
.crossmodal {
  position: absolute;
  right: 15px;
  top: 6px;
  cursor: pointer;
}

.dropdown-item .profilecontent a {
  padding: 0px !important;
}

.profilecontent {
  margin-left: 15px;
}

.appimg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin: auto;
  margin-top: 40px;
  /* margin-bottom: 60px; */
}

.spinntop {
  position: relative;
}

.spinnroller {
  position: absolute;
  right: 10px;
  top: 18px;
}
.flexbox-fix input {
  width: 100% !important;
  height: 40px !important;
  text-align: center;
  font-family: "Axiforma-Regular" !important;
}
.flexbox-fix span {
  font-family: "Axiforma-Regular" !important;
}
.sketch-picker {
  border-radius: 4px 4px 0px 0px !important;
}
.ios-buid-width-change {
  width: calc(100% - 300px);
}
.ios-buid-width-change p {
  width: 100%;
  /* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */
}
.ios-buid-width-fix {
  width: 300px;
}
.user-status {
  width: 50px;
}
.custom-secondary {
  color: #7782a1 !important;
  border: 1px solid #dce0ee !important;
  background: #dce0ee !important;
  cursor: not-allowed !important;
}

.customer-review-stars img {
  max-width: 100%;
}

@media (max-width: 767px) {
  .mobile-view-padding {
    padding-top: 0;
  }
  .mobile-splash-padding {
    padding-top: 20px;
  }
  .borbot div.mobile-login-padding {
    margin-top: 0.5rem !important;
  }
  .form-login-padding {
    padding-top: 6px;
  }
  .ipad div.style-mobile-padding {
    padding-top: 14px !important;
  }
  .userinfo-spacing {
    flex-wrap: wrap;
  }
  .transparent_background div.new-warning-mobile {
    margin: 10px 10px 15px 10px !important;
    height: 65px;
    padding: 10px 15px;
    width: auto !important;
  }
  .wallet-redesign-mobile p.balance-first-text {
    margin: 0;
  }

  .wallet-redesign-mobile {
    width: 100%;
    height: 40px;
    margin: 20px 15px 10px 15px;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_changepassword {
    margin-top: 4px !important;
  }

  .bill-pay.bill-pay-red {
    border-bottom: none;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_billsub {
    margin-top: 0px !important;
  }
  .mobiletoaster-red {
    max-width: 100%;
  }
  .wallet-redesign-mobile p.balance-first-text {
    margin: 0;
  }
  .col-md-12.col-md-product {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .badge-product-section,
  .cart-product-section {
    margin-bottom: 24px !important;
  }
  .guest-login-ipad.guest-login-new-red {
    flex-grow: 0 !important;
  }
  .w-100-red {
    padding-right: 80px !important;
    padding-left: 16px !important;
    padding-right: 90px !important;
  }
  .sitelocate-red {
    min-width: 98px !important;
  }
  .mr-2.image-form-red {
    margin: auto !important;
    margin-right: auto !important;
    display: block !important;
  }
  .form-check.form-check-create {
    width: 47% !important;
    height: 110px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }

  .form-check.form-check-create input[type="radio"]:checked + label,
  .form-check.form-check-create input[type="radio"]:not(:checked) + label {
    padding-right: 10px !important;
    padding-top: 25px !important;
    padding-bottom: 21px !important;
    text-align: center !important;
  }

  .abs-badge-laptop {
    top: 80% !important;
    right: 30% !important;
  }

  .form-check-create.inner-radio [type="radio"]:checked + label:after,
  .form-check-create.inner-radio [type="radio"]:not(:checked) + label:after {
    top: 17% !important;
    right: 6% !important;
  }

  .form-check-create.custom-radio-btn [type="radio"]:checked + label:before,
  .form-check-create.custom-radio-btn
    [type="radio"]:not(:checked)
    + label:before {
    top: 17% !important;
  }
  .cus-btn-mobile {
    width: 33.33%;
    left: 0;
  }
  .cus-btn-mobile:nth-of-type(2) {
    left: 33%;
  }
  .w-50.plan-text-responsive p {
    text-align: left !important;
  }
  .cus-btn-mobile:nth-of-type(3) {
    left: 66%;
  }
  .password-page.sm-ml-0 {
    margin-left: 0 !important;
  }
  .build_buttonfixed__2txQ6 {
    width: 32% !important;
  }
  .next_btn {
    height: 39px;
    line-height: 15px;
  }
  .custom-dropdown.custom-dropdown-myapps {
    margin-right: 18px;
  }
  .email-support .custom-width-left {
    max-width: 100%;
  }

  .thankyou-page div h4 {
    font-size: 20px;
  }
  .thankyou-page div p {
    font-size: 14px;
  }
  .login-help.login-help-woo .btn {
    padding-top: 0;
  }

  .form-group.form-group-slider-create {
    margin-bottom: 10px !important;
  }
  p.appname-first-redesign,
  p.appname-second-redesign,
  p.appname-third-redesign,
  p.appname-fourth-redesign,
  p.appname-fifth-redesign {
    text-align: left !important;
  }
  .plr-md-3.popup-spec-view {
    min-height: auto !important;
  }
  .device_chart_redesign {
    margin-top: 30px;
  }
  .new-analytics-bar-main {
    padding-left: 20px;
    padding-right: 20px;
  }
  .row.row-analytics .new-analytics-histogram {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 50px !important;
    padding-top: 15px !important;
  }

  .profile-detail-btn {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .order2 {
    order: 2;
  }

  .guest-login-toggle.guest-login-ipad p {
    text-align: left !important;
  }

  .dasboard_page.mobile-display
    .transparent_background.transparent_background_general {
    margin-top: 0 !important;
  }
  .module_title.module_title_red {
    display: flex;
    align-items: center;
  }
  .delete-app-now.delete-app-red {
    margin-right: 0 !important;
  }

  p.appname-third-redesign {
    padding-right: 5px;
  }

  .analytics-nodata-img {
    width: 100%;
    padding-left: 25px;
    padding-right: 20px;
  }

  .dasboard_page.mobile-display
    .transparent_background.transparent_background_style {
    margin-top: 0 !important;
  }
  .workspace-feature-container-ipad .workspace-feature-text-mobile h5,
  .workspace-feature-container-ipad .workspace-feature-text-mobile p {
    text-align: left !important;
  }
  .header-overflow-mobile {
    overflow: hidden;
  }
  .header-shadow-profile {
    box-shadow: 0px 0px 6px 0px rgba(188, 193, 206, 0.36);
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_bottombar {
    margin-top: 0px !important;
  }
  .merge-first-flex {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .new_wallet_design {
    flex-direction: column;
    padding: 0 10px;
  }
  .new_wallet_design_p {
    text-align: left;
    margin-right: 0;
    margin-top: 15px;
  }
  .react-select-custom div.css-yk16xz-control:nth-child(2) {
    height: 50px;
  }
  .addapp-search-image {
    min-width: 18px;
    height: 18px;
    padding: 0 !important;
    border-left: none;
  }
  .search-box.search-box-addapp.search-addapp-inner {
    height: 50px;
  }
  .react-select-custom div.css-tlfecz-indicatorContainer:nth-child(2) {
    height: 50px;
    align-items: center;
  }
  .react-select-custom div.css-1hb7zxy-IndicatorsContainer:nth-child(2) {
    height: 50px;
  }
  .react-select-custom .css-1wa3eu0-placeholder {
    position: absolute;
    top: 50%;
  }
  .react-select-custom div div.css-g1d714-ValueContainer:nth-child(1) {
    height: 50px;
  }
  .modal-header.modalheader-addapp-padding {
    padding-top: 17px;
    padding-bottom: 0;
  }
  .modal-footer.modalfooter-addapp {
    height: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .addcountry-search-bar .addcountry-search-image {
    padding: 17px 19px 15.5px 18.5px;
    border-left: 1px solid #e3e6f1;
  }
  .addcountry-search-bar {
    border-bottom: 1px solid #e3e6f1;
    justify-content: space-between;
  }
  .code-selector .search-box.search-box-addcountry input {
    border: none;
    border-radius: 0;
    padding-right: 0;
    width: 87%;
    margin-left: 0;
    margin-right: 0;
  }
  .code-selector-addapp {
    margin-top: 6px;
  }
  .merge-appname-mobile span {
    margin-left: 0;
  }
  .merge-appname-mobile label.merge-popup-label {
    width: auto;
  }
  .merge-popup-image {
    padding: 22px;
  }
  .merge-parent-mobile {
    display: flex;
  }
  .merge-parent-mobile .merge-appname-mobile {
    height: auto;
    width: 100%;
  }
  .col-md-3 .addapp-mobile-popup {
    width: 84px !important;
    height: 84px !important;
    border-radius: 24px !important;
    margin: 0 !important;
  }
  .topsectionapp-myapps p.date-personal-mobile {
    width: 36%;

    white-space: nowrap;
  }
  .search-box.country-line.search-box-addapp {
    width: auto;
    padding-left: 0 !important;
    border: none;
    margin-top: 0;
    height: 50px;
    padding-right: 19px !important;
    padding-left: 19px !important;
  }
  .search-box.country-line.search-box-addapp div input {
    display: none;
  }
  .react-select-custom:nth-child(1) {
    width: 372.5px;
  }
  .dropdown-merge-new {
    align-items: flex-end;
  }
  .modal-header.modalheader-addapp-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .modal-mergeapp-new.modal-dialog.modal-dialog-centered {
    max-height: none !important;
    min-height: auto;
  }
  .modal-mergeapp-new .modal-content {
    height: 100%;
  }
  .myradio.myradio-addapp {
    height: 432.5px;
  }
  .modal-header-addcountry {
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
  }
  .code-selector .search-box.search-box-addcountry {
    padding-left: 0;
    padding-right: 0;
  }
  input.search-country-input {
    background-color: #fff;
  }
  .modal-dialog.merge-popup-height {
    max-height: none !important;
  }
  .merge-popup-height .modal-content {
    height: 100vh;
  }
  .multisite-setup-text h3 {
    padding-left: 57px;
    padding-right: 57px;
  }

  .multisite-setup-text p {
    padding-left: 57px;
    padding-right: 57px;
  }

  .store-url2 {
    min-height: auto;
  }
  .store-url3 {
    min-height: auto;
  }

  .right_general.analytics_right_general {
    padding-left: 0;
    padding-right: 0;
  }
  .analytics-gplay-stats,
  .analytics-iostore-stats {
    margin-right: 0;
  }
  div.analytics-user-stats {
    padding-top: 10px;
  }

  .md-wrap {
    flex-wrap: wrap;
  }

  .mobile-payment .payment-status .canceled-red {
    float: right;
  }

  .deal-count h5 {
    font-size: 14px;
  }
  .mob-invoice {
    min-height: 205px;
  }

  .logsub.billingdrop {
    width: 100%;
  }
  .billing-search-new {
    margin: 0;
    margin-right: 0;
    width: 100%;
    padding: 0;
    height: 50px;
    border-right: none;
    border-top: none;
    border-left: none;
  }
  .billing-search-new .my-apps-search-btn-main {
    padding: 17px 19.5px 15.5px 18.5px;
    display: flex;
  }
  .mobile-menu-logout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ipad-multisite-bottompadding.mobile-multisite-merge {
    padding: 7px 15px 7px 15px !important;
    border-bottom: none;
  }
  .merge-image-mobile {
    display: none;
  }
  .container.container-maintenance {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sectionheadings.text-center.sectionheadings-maintenance {
    text-align: center !important;
    font-size: 26px !important;
  }
  .container-maintenance .subheadings1.text-center {
    text-align: center !important;
  }
  .web-platform .security-first-text h3 {
    font-family: "Axiforma-Regular";
    font-size: 26px;
    color: #0f193b;
  }
  .maintenance-image-banner {
    padding-left: 17px;
    padding-right: 17px;
  }
  .siteground-text-new {
    padding-left: 9.7px;
  }
  .container.container-security {
    padding-left: 20px;
    padding-right: 20px;
  }
  .row.row-security-second .col-md-6:nth-child(1) {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .row.row-security-second .col-md-6:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .security-first-section {
    padding-top: 10px;
  }
  .row.row-security .col-md-6:nth-child(1) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .right_general_security {
    box-shadow: none;
    padding: 0;
  }
  .row.row-security,
  .row.row-security-second {
    width: 100%;
    margin: auto;
  }
  .row.row-security-second {
    flex-wrap: wrap-reverse;
  }
  .security-text-padding {
    padding-top: 8px;
  }

  .security-image-padding {
    padding-top: 0px;
  }
  .siteground-btn-text div.siteground-btn.siteground-btn-redesign {
    justify-content: start !important;
  }
  .siteground-btn-padding.siteground-new-width {
    width: 180px !important;
    padding: 0 !important;
  }

  .change-password-new.right_general {
    padding-top: 24px;
  }
  .search-tag {
    background-color: #bcc1ce;
    color: #fff;
    border-radius: 20px;
    padding: 6px 8.5px 4px 23px;
    margin-right: 10px;
  }
  .search-tag h5 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin-bottom: 0;
    padding-right: 9.5px;
  }
  .myapps-search-main {
    margin-bottom: 10.5px;
  }

  .myapps-new-search {
    width: 333px;
    border: 1px solid #e3e6f1;
    border-radius: 3px;
    padding: 0px 14px 0px 16px;
    border-left: 0;
    border-top: 0;
  }
  .search-funnel-myapps {
    padding-top: 8px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
  .web_logo_spacing {
    margin-top: 7px;
  }
  .profile_icon.pro-icon-new {
    padding-left: 0px !important;
  }

  .dropdown-item-padding.new_logout_padding {
    padding: 0px !important;
  }
  .invoice-status .active-green,
  .invoice-status .canceled-red {
    background: transparent;
    border: 0;
    color: #47ce70;
    text-transform: capitalize;
    font-size: 12px;
    width: auto;
    height: auto;
    line-height: 1.5;
  }
  .invoice-status .canceled-red {
    color: #ff4848;
  }
  .inside_image.inside_image_new {
    height: 130px !important;
    padding: 0;
  }
  .myapps-status-icons {
    margin-top: 12px;
  }
  .topsectionapp.topsectionapp-myapps {
    padding: 10px 15px;
    padding-top: 14px;
    padding-bottom: 18px;
  }
  .topsectionapp-myapps h2 {
    text-transform: capitalize;
    margin-bottom: 7px;
  }
  .topsectionapp.topsectionapp-myapps p {
    margin-top: 0;
    font-size: 11px;
  }
  .onhoverparent.onhoverparent-myapps {
    background-color: #f8fafe;
    width: 128px;
    overflow: inherit;
  }
  .mt-3.new-mobile-myapp {
    margin-top: 7px !important;
  }
  .mt-3.new-mobile-myapp p {
    font-size: 11px;
  }
  .mt-3.new-mobile-myapp h3 {
    font-size: 11px;
  }
  .app-list .dash-border.dash-border-new {
    border: 1px solid #c8cedb !important;
    color: #7782a1;
  }
  .dash-border-new .first-app p.creatapps {
    color: #7782a1;
  }
  .filter-tag-myapps {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .work-search-icon.myapps-work-search {
    height: 50px;
    display: flex;
    align-items: center;
    width: 56px;
    justify-content: center;
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .myapps-input {
    width: 100%;
    height: 100%;
  }
  .workspace-search.workspace-myapps-search {
    height: 50px;
    margin-bottom: 10.5px;
    padding: 0;
  }
  .search-funnel-new {
    height: 50px;
    display: flex;
    align-items: center;
    width: 56px;
    justify-content: center;
    border-left: 1px solid #e3e6f1;
  }
  .myapps-bar {
    flex: 1;
    height: 50px;
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .appimg.appimg-myapps {
    margin: 22px !important;
  }
  .invoice-text p,
  .invoice-date-time p {
    font-size: 12px;
    font-family: "Axiforma-Regular";
    color: #7782a1;

    margin-top: 0;
  }

  .invoice-date-time p,
  .invoice-date-time span {
    color: #1b2952;
    line-height: 14px;
    font-size: 14px;
  }
  .invoice-date-time span {
    color: #7782a1 !important;
    font-size: 12px !important;
    line-height: 19px;
    font-family: "Axiforma-Regular";

    display: block;
  }
  .invoice-date-time span {
    margin-top: 5px;
  }

  .mt-5.pt-5.mobile-analytics-data {
    padding-top: 32px !important;
    margin-top: 0 !important;
  }
  .workspace-header-info .workspace-header-sub-info h5 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782a1;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .workspace-header-info .workspace-header-sub-info p {
    font-family: "Axiforma-Regular";
    font-size: 10px;
    color: #47ce70;
    margin-right: 10px;
  }
  .workspace-header-info .workspace-header-sub-info a {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #3064f9;
  }
  .workspace-header-info h5 {
    margin-bottom: 2px;
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1b2952;
  }
  .workspace-header-info p {
    white-space: nowrap;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782a1;
    margin-bottom: 0;
  }
  .inside_image.inside_image_new {
    height: 130px !important;
    padding: 0;
  }
  .myapps-status-icons {
    margin-top: 7px;
  }
  .topsectionapp.topsectionapp-myapps {
    padding: 10px 15px;
    padding-top: 14px;
    padding-bottom: 18px;
  }
  .topsectionapp-myapps h2 {
    text-transform: capitalize;
    margin-bottom: 7px;
  }
  .topsectionapp.topsectionapp-myapps p {
    margin-top: 0;
    font-size: 11px;
  }
  .onhoverparent.onhoverparent-myapps {
    background-color: #f8fafe;
    width: 128px;
    overflow: inherit;
  }
  .mt-3.new-mobile-myapp {
    margin-top: 7px !important;
  }
  .mt-3.new-mobile-myapp p {
    font-size: 11px;
  }
  .mt-3.new-mobile-myapp h3 {
    font-size: 11px;
  }
  .app-list .dash-border.dash-border-new {
    border: 1px solid #c8cedb !important;
    background-image: none !important;
    color: #7782a1;
  }
  .dash-border-new .first-app p.creatapps {
    color: #7782a1;
  }
  .filter-tag-myapps {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .work-search-icon.myapps-work-search {
    height: 50px;
    display: flex;
    align-items: center;
    width: 56px;
    justify-content: center;
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .myapps-input {
    width: 100%;
    height: 100%;
  }
  .dashboardheader.header-shadow-mobile {
    box-shadow: 0px 0px 10px rgba(188, 193, 206, 0.36);
    border-bottom: none;
  }
  .iphone-full-width {
    margin-top: 6px;
  }
  .myapps-status-icons.justify-content-between {
    justify-content: start !important;
  }
  .myapps-status-icons img {
    max-width: 20px;
  }
  .myapps-status-icons img.woo-myapps-new {
    max-width: 25px;
  }
  .d-flex .form-group.form-group-advanced {
    width: 41% !important;
  }
  .d-flex .form-group.form-group-delete {
    width: 12% !important;
    min-width: 44px;
  }
  .advance-search-btns {
    display: flex;
  }
  .advance-search-btns button {
    font-size: 13px !important;
    min-width: 100px;
    height: 40px;
    padding: 0;
  }
  .header-responsive-myapps-new {
    max-width: 100%;
    overflow-x: clip;
  }
  .myapps-flex-new {
    width: 100%;
  }
  .myapps-profile-bottom-new {
    width: 100%;
  }
  .search_search_dropdown__ifpwk::before {
    right: 15px !important;
  }
  .filter-post.filter-post-myapps {
    left: 0;
    width: 100%;
    top: 107px;
    position: fixed;
    border-radius: 0;
    z-index: 999;
  }
  .takethere-myapps {
    min-width: 56px;
    height: 50px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
  }
  .container.container-myapps-new .my-apps-search-btn-main {
    height: 50px;
    min-width: 56px;
  }
  .container.container-myapps-new .my-apps-search-spacing {
    width: calc(100% - 56px) !important;
    margin: 0;
    height: 50px;
    border-top: none;
    border-radius: 0;
    border-left: none;
  }
  .container.container-myapps-new .myapps-profile-bottom {
    margin-left: 0;
  }
  .create_app.create_app_myapps {
    padding-left: 15px;
    padding-right: 15px;
  }
  .container.container-myapps-new {
    padding-left: 0;
    padding-right: 0;
  }
  .header-left-support.header-left-myapps {
    padding-right: 0 !important;
  }
  .setting-space.setting-space-myapps {
    margin-left: 15px;
    margin-right: 15px;
  }
  .new-hover-element.new-myapps-element {
    padding-left: 15px;
    padding-right: 15px;
  }
  .custom-dropdown.custom-dropdown-myapps {
    margin-right: 0px;
  }
  .workspace-search.workspace-myapps-search {
    height: 50px;
    margin-bottom: 10.5px;
    padding: 0;
  }
  .search-funnel-new {
    height: 50px;
    display: flex;
    align-items: center;
    width: 56px;
    justify-content: center;
    border-left: 1px solid #e3e6f1;
  }
  .myapps-bar {
    flex: 1;
    height: 50px;
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .appimg.appimg-myapps {
    margin: 22px !important;
  }

  img.gotoappicon {
    width: auto;
    margin-left: 7px;
    vertical-align: middle;
  }

  .workspace-det-inner-mobile h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 63px;
    white-space: nowrap;
  }
  .workspace-det-tile.workspace-det-tile-mobile {
    margin-bottom: 20px;
  }
  .workspace-det.workspace-det-mobile {
    padding-left: 5px;
  }
  .workspace-det-inner a {
    font-size: 10px;
  }
  .workspace-app-det h5 {
    font-size: 10px;
  }
  .workspace-user-det h5 {
    font-size: 12px;
  }
  .workspace-det.workspace-det-mobile h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 165px;
    margin-bottom: 6px;
    white-space: nowrap;
  }
  .profile-pic-img-mobile {
    width: 115px !important;
    min-width: 115px !important;
    height: 115px !important;
  }

  .mb-25 {
    margin-bottom: 21px;
  }

  .store-url2 .gapforfooter {
    margin-bottom: 75px;
  }
  .canceladdon {
    margin-top: 15px;
  }

  /* Faisal */
  .profile-img-mobile {
    /* padding-top: 24px; */

    /* padding-left: 15px; */
    display: flex;
    align-items: center;
  }
  /* Faisal */
  .profile-image-workspace {
    margin-bottom: 33px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 19px;
    padding-bottom: 24px;
  }
  .profile-image-workspace.profile-img-mob-new {
    padding-bottom: 8px;
  }
  .profile-image-workspace p {
    margin-left: 18px;
  }
  .height64-mobile.height64-mobile-margin {
    margin-top: 60px;
  }
  h1.workspace-main-heading-mobile {
    padding-bottom: 10px;
  }
  p.workspace-main-subtext.workspace-main-subtext-mobile {
    padding-bottom: 0;
    text-align: left;
  }
  h1.workspace-main-heading-mobile {
    text-align: left;
    font-size: 26px;
    color: #1b2952;
    padding-top: 0;
  }
  h3.workspace-feature-heading-mobile {
    font-size: 18px;
    color: #1b2952;
  }
  .workspace-feature-container-mobile {
    margin-top: 30px;
  }
  .workspace-features-mobile {
    margin-top: 40px;
  }
  .workspace-name-prompt-mobile {
    width: 100%;
    margin-top: 30px;
  }

  .mb-4.my-apps-search-mobile {
    margin-bottom: 0px !important;
    margin-top: 15px !important;
  }

  .myapps-profile-bottom {
    padding-bottom: 20px;
  }

  .pl-3.header-left-support {
    padding-left: 3px !important;
    padding-right: 4px;
  }
  .search-icon-spacing-mobile {
    margin-top: 1px;
  }

  .awards-container {
    margin-top: 50px;
  }

  .web-view-bottom {
    left: 100px;
    top: 200px;
  }

  .web-view-top {
    left: 100px;
    top: 20px;
  }

  .compare-plan-head {
    padding-top: 41px;
    margin-bottom: 47.5px;
  }

  .namespac2-mobile {
    margin-top: 3px;
  }

  .mb-4.my-apps-search-mobile {
    margin-bottom: 0px !important;
    margin-top: 15px !important;
  }
  .myapps-profile-bottom {
    padding-bottom: 20px;
  }
  .menu-phone-icon-new.support-drop-spacing {
    margin-top: -2px;
  }
  .pl-3.header-left-support {
    padding-left: 0 !important;
    padding-right: 0px;
  }

  .header-myapps-spacing {
    margin-bottom: 20px;
    padding: 10px 15px;
  }

  .sm-wrap {
    flex-wrap: wrap;
  }

  .left-image-container {
    padding-top: 10px;
  }
  .right_panel.general-padding-mobile {
    padding-bottom: 24px !important;
  }

  .mr-md-1.ipad-1 {
    margin-right: 4px !important;
  }

  .sm-mb-2 {
    margin-bottom: 25px;
  }
  .zoho-loader-img {
    width: 15px !important;
  }
  .latest-signin {
    width: 100%;
  }
  header {
    border-bottom: 1px solid #e3e6f1;
  }

  .plan-select-new-btn h4.card-foot-text {
    padding-right: 0;
  }
  a.select-button-new {
    width: 100%;
  }
  .plan-select-new-btn {
    padding-right: 20px;
  }
  .setbackground-new .card-container-width {
    padding: 0;
  }
  .pf-new-main {
    width: 48%;
  }

  .welcome-new h1 {
    padding-bottom: 21px;
  }
  .form-group.form-group-appname {
    margin-bottom: 22.5px;
  }
  .form-group.form-group-wp {
    margin-bottom: 13px;
  }
  .pt-md-2 button.sendbtn-new {
    margin-top: 40px !important;
  }
  .form-group-license {
    margin-top: 12px;
  }
  .guest-login-toggle.guest-login-toggle-new p {
    margin-bottom: 0;
  }
  section.forstrip {
    padding-top: 0;
  }
  .container-createapp {
    padding-top: 0;
  }
  .createdetail-new {
    padding-top: 31px;
  }
  .menu-phone-icon-new.hamburg-padding {
    padding-left: 1px !important;
  }
  .plan-select-new-btn h4.card-foot-text {
    padding-right: 0;
  }
  a.select-button-new {
    width: 100%;
  }
  .plan-select-new-btn {
    padding-right: 20px;
  }
  .setbackground-new .card-container-width {
    padding: 0;
  }

  .link-expired.link-expired-new h1 {
    font-family: "Axiforma-Bold";
    font-size: 18px !important;
    color: #1b2952 !important;
  }
  .link-expired.link-expired-new p {
    font-family: "Axiforma-Regular";
    font-size: 14px !important;
    color: #7782a1 !important;
  }

  .phone-menu.addon-new-margin {
    margin-bottom: 0 !important;
  }
  .addon-dedicated-page.addon-dedicated-page-mobile {
    margin-top: 0 !important;
  }
  .right_panel.addon-details-top-padding.addon-details-top-padding-mobile {
    margin-bottom: 0 !important;
  }
  .addon-banner-text.addon-banner-text-mobile h2 {
    font-size: 34px;
    color: #0f193b;
  }
  .addon-banner-text.addon-banner-text-mobile p {
    font-family: "Axiforma-Regular";
    color: #5f6578;
  }
  .addon-banner-text.addon-banner-text-mobile p.firstheading {
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #0f193b;
    margin-top: 29px !important;
    margin-bottom: 31px !important;
  }
  .transparent_background.addon-details-sidepadding.addon-details-sidepadding-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .addon-new-height-mobile {
    padding-top: 0;
    width: 100%;
    margin-right: 0;
    height: auto;
  }
  .addon-new-height .addon-banner-text.addon-banner-text-mobile {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .addon-btns-spacing-mobile {
    padding-top: 61px;
  }
  .btn.btn-get-addon.btn-get-addon-mobile {
    white-space: nowrap;
    font-size: 14px;
    padding: 11px 28px 14px 12px;
  }
  .btn.btn-work-addon.btn-work-addon-mobile {
    white-space: nowrap;
    font-size: 14px;
    padding: 12.5px 29.4px 12.5px 26px;
  }
  .right-side-banner.right-side-banner-mobile {
    width: 100% !important;
    padding-top: 40px;
    position: relative;
  }

  .new-chat-pad {
    padding-bottom: 60px;
  }
  .new-merge-pad {
    padding-bottom: 40px;
  }
  .banner-padding .new-price-spacing p {
    margin: 20px 0 20px !important;
  }
  /* .right-side-banner.right-side-banner-mobile img {
    padding-bottom: 55px;
  } */
  .banner-addon-main.banner-addon-main-mobile {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    height: auto;
  }
}
@media (max-width: 980px) {
  .transparent_background.addon-details-sidepadding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .mobile-view-padding {
    padding-top: 0;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .mid-responsive-spacing {
    width: auto;
  }
}
@media (min-width: 769px) and (max-width: 800px) {
  .spec-responsive {
    align-items: center;
  }
}
@media screen and (max-width: 1366px) {
  .transparent_background .right_panel {
    margin-bottom: 10px !important;

    min-height: 96%;
  }

  .cus-web p {
    font-size: 14px;
  }
  .cus-web {
    padding: 10px !important;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 110px !important;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 12px !important;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 25px;
  }

  .gotoappicon {
    margin-bottom: 2.5px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .show_border.show_border_checkout,
  .app_iconname.app_iconname_pushnotification {
    padding-top: 24px !important;
  }
  .notif-ipad-new.notif-ipad-red {
    padding-top: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  .bottom_border {
    width: 50%;
    border-right: 1px solid rgb(224, 229, 236);
    padding: 0 18px;
  }
  .topspace {
    padding: 14px 30px 10px !important;
  }
  .troublespac {
    padding-bottom: 16px;
  }
  .mainspac {
    padding-top: 18px;
  }
  .secmain {
    padding-top: 16px;
  }
  .bottomspac {
    padding-left: 20px;
    padding-right: 20px;
  }
  .addonsubspac {
    margin-right: 20px;
  }
  .mobilemajor {
    margin-top: 11px;
  }
  .app-version .mobile-responsive-version {
    margin-right: 35px !important;
  }
}
@media (min-width: 800px) {
  .subtext-new-responsive {
    margin-top: -20px;
  }
}
@media (max-width: 768px) {
  .toggle-responsive {
    padding-top: 0;
  }
  .subtext-space {
    margin-top: 15px;
  }
  .topspace div.general-alignment {
    justify-content: flex-start !important;
    padding-left: 15px;
    padding-bottom: 9px;
  }

  .create-account {
    max-width: 100%;
    padding-top: 30px;
  }
  .signup_left.justify-content-between {
    justify-content: center !important;
  }
  .appmysite-certificate {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .ipad-view-myapps-icons {
    display: block !important;
  }
  .nameinfospacing {
    white-space: nowrap;
  }

  .subscriptions-top-head .setbackground-new {
    padding-top: 70px;
  }
  div.summary-detail-new {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .launch-steps.launch-steps-red {
    text-align: left !important;
  }
  .right_general div.icon-padding {
    padding-left: 16px !important;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 55px;
  }
  .redeem-mob-space.redeem-mob-redesign {
    padding-right: 15px;
  }

  .ipad-btn-width button {
    max-width: 100%;
    margin-left: auto;
  }

  .fixed-button.ipad-btn-width {
    margin: 0 !important;
  }

  .common-height.common-height-red {
    margin-bottom: 60px;
  }

  .ipad-btn-width .ipad-bottom-save {
    max-width: 100%;
  }

  .create-account {
    max-width: 990px;
  }

  .signinpage.signinpage-ipad {
    height: 100%;
    background-color: #f8fafe;
  }
  .container.container-login {
    top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .workspace-feature-container-ipad {
    padding-left: 19px;
  }
  .top-portion.ipad-portion-appearance.ipad-special-appearance.ipad-login-appearance {
    padding-top: 24px;
  }
  .transparent_background .right_panel {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .ipad-custom-padding.ipad-custom-padding-red.ipad-login-social,
  .ipad-custom-padding-red.ipad-login-color {
    padding: 10px 18px 0px !important;
  }
  .ipad-custom-padding.ipad-custom-padding-red.ipad-login-logo {
    margin-top: 10px !important;
    padding: 10px 18px 0px !important;
  }
  .dash-border.dash-border-red {
    margin-top: 0 !important;
  }
  .borbot .ipad-top-margin-zero {
    margin-top: 24px !important;
  }
  .transparent_background.transparent_background_bottombar {
    margin-top: 54px !important;
  }
  .inside_image .onhoverparent .app-icon-display-status {
    height: 250px !important;
  }
  .myapps-display-status {
    display: none;
  }
  .testmodal-dialog .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .aabbutton {
    margin-right: 0px;
  }
  .top-portion.top-portion-ipad-menu {
    padding-top: 18px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e5ec !important;
  }
  .relnotespace {
    margin-right: 16px;
  }
  .new-padding {
    margin-top: 55px;
  }
  div.mobileview {
    display: block;
  }
  .app-dash-analytics .two-apps.download-tab-spacing {
    margin-bottom: 0 !important;
  }
  .mobile-view-hide {
    display: none;
  }
  .btn.custom-radio-button1.settings-ipad-radio {
    margin-bottom: 15px;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_product {
    margin-top: 54px !important;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 23px !important;
    white-space: nowrap;
  }
  .ipad-block.ipad-block-red.ipad-flex-red {
    display: flex !important;
  }
  .linkstyles.linkstyles-ipad-font {
    font-size: 10.5px !important;
  }
  .notif-ipad-new.notif-ipad-red {
    padding-bottom: 10px !important;
  }
  .ipad-notif-padding.ipad-notif-red {
    padding-bottom: 0 !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_notification {
    margin-top: 54px !important;
  }
  .ipad-view-newpadding.ipad-view-newred {
    margin-top: 15px !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_product {
    margin-top: 64px !important;
  }
  .container .signup_left.signup_left_ipad {
    justify-content: center !important;
  }
  /* .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 21px !important;
  } */
  .guest-login-ipad.guest-login-new-red {
    flex-grow: 0 !important;
  }
  .mobile-view-general.mobile-view-red-ipad {
    flex: 1;
  }
  .abs-badge.abs-badge-ipad {
    margin-left: 10px;
  }
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    display: flex;
    padding-right: 23px !important;
  }
  .right_general div.ipad-view-spacing {
    margin-top: 0 !important;
  }
  .mobile-view-dash-border {
    border-top: none;
  }
  .app-created h5.mobile-view-nameinfo {
    padding-left: 23px;
  }
  .mobile-view-border-spacing {
    border-top: 1px solid #dce1eb;
  }
  .mobile-view-border-spac {
    border-left: 1px solid #dce1eb;
    padding: 24px 20px 12px 30px;
  }
  .mobile-view-border-space {
    padding: 25px 12px 9px 25px;
    width: 330px;
  }
  .row div.left-mobile-view-spacing {
    flex: 0 0 48%;
    max-width: 58%;
  }
  .row div.right-view-mobile-spacing {
    flex: 0 0 48%;
    max-width: 40%;
  }
  .transparent_background.middle-width-responsive {
    margin-top: 60px !important;
  }
  .topspace.ipad-topspacing {
    padding-left: 31px;
    padding-right: 16px;
    padding-bottom: 0 !important;
  }
  .ipad-btn-spacing {
    width: 100%;
  }
  .transparent_background.addon-details-ipadview {
    margin-top: 80px !important;
  }
  .btn.custom-radio-button1.custom-radio-red1,
  .btn.custom-radio-button2.custom-radio-red2 {
    width: 100%;
  }
  .setheg.ipad-view-downloadplugin {
    min-height: 0 !important;
    margin-top: 0 !important;
  }
  .transparent_background.ipad-width-responsive {
    margin-top: 80px !important;
  }
  .troublespac.ipad-view-troublehead {
    margin-left: 0;
    flex-wrap: wrap;
  }
  .right_panel.ipad-trouble-padding {
    padding-left: 18px !important;
  }
  .setting-name.ipad-trouble-subtext {
    display: none !important;
  }
  .app_iconname h5.ipad-plugin-subtext {
    display: block;
    margin-right: 0 !important;
  }
  .ipad-menu-padding {
    margin-top: 60px;
    padding-left: 15px;
  }
  .topspace.ipad-menu-spacing {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .switch.mobile-switch {
    width: 44px !important;
    height: 22px !important;
  }
  input:checked + .slider:before {
    transform: translateX(23px);
    bottom: 2px;
    left: 1px;
  }
  .ipad-screen-menu {
    padding-bottom: 50px;
  }
  .dashboardheadrcss.ipad-dashboard-head {
    padding-right: 0 !important;
    padding-left: 33px;
  }
  .dashboardheadrcss.ipad-gear-padding {
    padding-right: 53px;
    padding-left: 33px;
  }
  .dashboard-content .dashboard-padding-15 .common-height.ipad-last-padding {
    padding-right: 0 !important;
    padding-left: 33px !important;
  }
  .app_iconname h5.ipad-view-trouble-subtext {
    display: block;
  }
  .ipad-connec-padding {
    padding-bottom: 0;
  }
  .app_iconname h5.ipad-grant-subtext {
    display: block;
  }
  .app_iconname.ipad-grant-flex {
    flex-grow: 1;
  }
  .app_iconname h5.ipad-general-subtext {
    display: block;
  }
  .app_iconname h5.ipad-menu-subtext {
    display: block;
  }
  .align-items-center.cross-alignment {
    align-items: baseline !important;
  }
  .login-help .btn.help-ipad-padding {
    margin-left: 40px;
  }
  .ipad-signup-spacing {
    margin-left: 12px;
  }
  .media.ipad-signup-spacing {
    margin-left: 40px;
  }
  .signinpage.signinpage-new,
  .signuppage.signuppage-new {
    background-color: #f8fafe;
  }
  /* .ipdad-video.ipdad-video-display {
    display: block !important;
  } */
  .welcome.welcome-laptop {
    padding: 0;
  }
  .ipad-block.ipad-getstarted-flex {
    display: flex !important;
  }
  .ipad-getstarted-display {
    display: block !important;
  }
  .top-portion.ipad-iosadmob-margin {
    margin-top: 0 !important;
  }
  .add-workspace-inner.add-workspace-redesign {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dashboardheader.header-ipad-changepassword {
    display: block !important;
    padding-top: 5px;
  }
  .mobile-display .customise-ipad-password {
    padding-left: 15px;
    padding-top: 18px;
  }
  .dashboardheader.ipad-getstarted-new {
    display: block !important;
  }
  .ipad-login-padding {
    margin-left: 15px;
    margin-right: 15px;
  }
  .ipad-launch-new {
    padding-top: 10px !important;
  }
  .top-portion.ipad-portion-appearance,
  .right_general.ipad-portion-appearance {
    margin-top: 0 !important;
    padding: 18px 15px 16px 15px;
    border-bottom: 1px solid #e0e5ec;
  }
  .ipad-bottom-head-padding.ipad-bottom-head-red {
    border-bottom: none !important;
  }
  .ipadview-cart {
    margin-bottom: 30px;
  }
  .d-md-flex.ipad-cart-fields {
    display: block !important;
    margin-bottom: 10px !important;
  }
  .guest-login-toggle.guest-toggle-ipadview p {
    text-align: left !important;
  }
  .app_iconname.ipad-users-product-view.product-ipad-new {
    margin-top: 0;
    padding-left: 30px;
  }
  .app_iconname.ipad-users-product-view.checkout-ipad-new {
    margin-top: 0;
  }
  .app_iconname.notif-ipad-new {
    margin-top: 0;
    padding: 18px 15px 16px 0px;
  }
  .guest-login-toggle.guest-login-ipad p {
    text-align: left !important;
  }
  /* .d-flex.wp-other-ipad {
    display: block !important;
  } */
  .inner-radio.custom-radio-button2 label.form-check-label.form-label-text2 {
    padding-right: 64px !important;
  }
  .top-portion-ipad {
    padding: 18px 15px 16px 30px;
  }
  .ipad-menu-padding.top-portion-menu {
    margin-top: 0;
    padding-left: 0;
  }
  .top-portion.top-portion-ipad-menu {
    padding-top: 18px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e5ec !important;
  }
  .right_general.ipad-bottom-head-padding {
    margin-top: 56px;
    padding-top: 25px;
  }
  .app_iconname h5.ipad-bottom-subtext {
    display: block;
  }
  .show_border.ipad-bottom-border {
    border-bottom: 1px solid #e0e5ec !important;
    padding-bottom: 16px !important;
  }
  .custom-pages.social-accounts .app_iconname h3.ipad-menu-view {
    display: block;
  }
  .custom-pages.social-accounts .app_iconname.ipad-menupage-padding {
    margin-top: 50px;
  }
  .mobile-display .transparent_background.ipad-menupage-scrollview {
    padding-bottom: 45px !important;
  }
  .app_iconname.ipad-users-head-view {
    /* margin-top: 50px; */
    padding-left: 15px;
    padding-top: 12px;
  }
  .ipad-view-newpadding {
    margin-top: 20px;
  }
  .app_iconname.ipad-posts-head-view {
    margin-top: 70px;
    padding-left: 15px;
  }
  .app_iconname.ipad-users-product-view {
    margin-top: 50px;
    padding-left: 15px;
  }
  .custom-pages.social-accounts .app_iconname h3.social-ipad-view {
    display: block;
  }
  .custom-pages.social-accounts .app_iconname.ipad-posts-head-view {
    margin-top: 30px;
    padding-left: 15px;
  }
  .row .social_pannel.ipad-social-newpadding {
    margin-top: 0;
  }
  .mobile-display .transparent_background.ipad-preview-header-view {
    margin-top: 50px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  header.ipad-display-head {
    display: block;
  }

  .ipad-status-align {
    white-space: nowrap;
  }
  .transparent_background.ipad-publish-view {
    margin-top: 60px;
  }
  .webhostspac.ipad-webhost-view {
    margin-top: 50px !important;
    padding-left: 15px;
    padding-top: 28px;
  }
  .ipad-view-cross.profile-ipadview-cross {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 10px;
    margin-bottom: -15px;
  }
  .app_iconname h5.ipad-webhost-subtext {
    display: block;
    white-space: break-spaces;
  }
  .transparent_background.ipad-security-view {
    margin-top: 58px !important;
  }
  .right_panel.ipad-security-head-padding {
    padding-top: 20px !important;
  }
  .ipad-security-headspacing {
    padding-left: 15px;
  }
  .ipad-security-btn-padding {
    white-space: nowrap;
    margin-right: 15px;
  }
  .ipad-notif-view {
    margin-top: 70px;
  }
  .createnotif.ipad-notif-padding {
    margin-bottom: 0 !important;
  }
  .transparent_background.ipad-chat-head-view {
    margin-top: 50px !important;
  }
  .right_panel.ipad-chat-headpadding {
    padding-top: 20px !important;
  }
  .show_border.ipad-chat-leftpadding {
    padding-left: 15px;
    margin-bottom: 30px;
  }
  .transparent_background.ipad-multisite-view {
    margin-top: 70px !important;
  }
  .right_panel.ipad-multisite-headpadding {
    padding-top: 0px !important;
  }
  section.ipad-multisite-leftpadding {
    position: static;
  }
  .ipad-multisite-bottompadding {
    padding-bottom: 15px;
  }
  .ipad-multisite-rightpadding {
    padding-right: 15px;
  }
  .ipad-multisite-rightpadding button.ipad-multisite-buttonspacing {
    white-space: nowrap;
    width: 120px;
  }
  .right_panel.ipad-iosadmob-view {
    padding-top: 15px !important;
  }
  .right_general .ipad-iosadmob-headpadding {
    margin-top: 60px;
  }
  .ipad-platswitch-leftpadding {
    padding-left: 15px;
  }
  .transparent_background.ipad-addon-view {
    margin-top: 60px !important;
  }
  .right_panel.ipad-addon-headpadding {
    padding-top: 20px !important;
  }
  .ipad-addon-leftpadding {
    padding-left: 15px;
  }
  .ipad-link-viewspacing {
    white-space: pre;
  }
  .transparent_background.ipad-usermodule-view {
    margin-top: 60px !important;
  }
  .right_panel.ipad-usermodule-headpadding {
    padding-top: 20px !important;
  }
  .ipad-logs-viewpadding {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }

  .transparent_background.dealselect_background {
    margin: 10% 0 0 0;
  }

  .dealselect-heading h3 {
    font-size: 28px;
  }
  .dealselect-btn-panel {
    margin-top: 18px;
  }
  .transparent_background.dealselect_background {
    width: calc(100% - 70px) !important;
  }

  .inside_image .onhoverparent .app-icon-display-status {
    height: 250px !important;
  }
  .myapps-display-status {
    display: none;
  }
  .ipadview-leftpanel-spacing {
    margin-right: 30px;
  }
  .ipadview-logo {
    flex: 1;
  }
  .initial-leftpanel-ipadview {
    right: auto;
    left: -1000px;
    top: 62px;
    transition: 2.5s ease;
  }
  .menu-phone-icon.ipadview-menu-leftpanel {
    display: block !important;
  }
  .leftscroll.ipadview-leftscroll {
    overflow: visible;
  }
  .ipad-view-cross {
    display: block;
    padding-left: 0;
  }
  .leftpanel-ipadview-dashboard {
    width: 19%;
    position: fixed;
    left: 0;
    top: 62px;
    height: 100vh;
    transition: 1s ease;
    transform: none;
  }
  .setting-name.ipadview-setting-name-display {
    display: none !important;
  }
  .customer-link-ipadspacing {
    margin-right: 15px;
  }
  .ipad-view-myapps-header {
    margin-top: 65px;
  }
  .iphone-full-width .my-app-heading-spacing {
    margin-top: 05px;
    margin-bottom: 05px;
  }
  .my-apps-search-spacing {
    margin: 15px 10px 15px 0;
  }

  .transparent_background.maintenance-ipad-view-topspacing {
    margin-top: 62px !important;
  }
  .right_panel.maintenance-ipad-view-toppadding {
    padding-top: 10px !important;
  }
  .securityspac-ipadview {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .guest-login-toggle.mobileview p {
    text-align: left !important;
  }
}
@media (min-width: 981px) and (max-width: 1200px) {
  section.ipad-multisite-leftpadding {
    padding-left: 0px;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  p.web-type-text {
    text-align: left !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_ipadappearance {
    margin-top: 0 !important;
  }
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_notification {
    margin-top: 0 !important;
  }
  .ipad-notif-padding.ipad-notif-red {
    padding-bottom: 4px !important;
  }
}
@media (min-width: 600px) and (max-width: 710px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 40px !important;
  }
}
@media (min-width: 711px) and (max-width: 767px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 47px !important;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 19px !important;
  }
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 40px !important;
  }
  .ipad-notif-padding.ipad-notif-red {
    padding-bottom: 4px !important;
  }
}
@media (min-width: 801px) and (max-width: 880px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 45px !important;
  }
}
@media (min-width: 881px) and (max-width: 950px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 50px !important;
  }
}
@media (min-width: 951px) and (max-width: 1000px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 53px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 59px !important;
  }
}
@media (min-width: 1101px) and (max-width: 1199px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 64px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1200px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 55px !important;
  }
}
@media (min-width: 767px) and (max-width: 1200px) {
  .faq .row {
    margin: 0px;
  }
  .preview-new-button {
    width: 213px;
  }
  .upgradepage .upgradesection {
    padding-top: 75px;
  }
  .upgradeplan,
  .upgradeplan:hover {
    margin-top: 30px;
  }
  .planA {
    padding: 30px 0px 15px 0px;
  }
  .helpheaderdropdown .dropdown-item a,
  .headerdropdown .dropdown-item a {
    padding: 15px 0px 15px 0px !important;
  }
  .dasboard_page.mobile-display .not-found {
    display: block !important;
  }
  .dasboard_page.mobile-display .not-found .not-exist .conecttoapi {
    float: none !important;
  }
  .addcartbtn .addtocartbtn {
    display: none;
  }
  .right_general.my-app-preview .apppreview {
    margin-top: 125px;
  }
  .apppreview .signup-form {
    width: 37% !important;
  }
  .upload_files .install-plugin-steps .greentick {
    padding-bottom: 15px;
  }
  .api-head .app_iconname {
    width: 70%;
  }
  .guest-login-toggle h6 {
    display: none !important;
  }
  .mobileview h6 {
    display: block !important;
  }
  .green_toggle .dashboard-customizer .common-height {
    padding: 0px !important;
  }
  .store-url p {
    text-align: left;
  }
  .footer {
    display: none;
  }
  .app-list {
    width: 31%;
    float: none;
  }
  .headerdropdown .dropdown-item:first-child {
    padding: 0px 0px 0px 18px !important;
  }
  .email-support .custom-width-right {
    display: none !important;
  }

  .newupload-image {
    width: 300px;
  }
  .right_panel {
    margin-bottom: 0px;
  }
  .custom-pages.social-accounts .app_iconname h3 {
    display: none;
  }
  .apidescription .videopart h5 {
    padding-left: 0px !important;
  }
  .social_links .col-md-6.padding-15 {
    padding: 0px 20px !important;
  }
  .custom-pages.social-accounts .app_iconname {
    margin-top: 25px;
  }
  .social_pannel {
    margin-top: 25px;
  }
  .fixed-button.bill-pay-top {
    padding-top: 10px !important;
  }
  .fixed-button.bill-pay-top .next_btn {
    width: 50% !important;
  }
  .videopart img {
    width: auto;
  }
  .dashboard-mobile .white-mobile-border .top-header {
    width: 41%;
    border-radius: 24px 24px 0px 0px;
  }
  .section-items .cat-product svg,
  .in-cart .cat-product svg,
  .recently-viewed .cat-product svg {
    width: 313px;
  }
  .signup-form {
    width: 41%;
    margin: auto;
    bottom: 221px;
  }
  .mobile_img img {
    margin: auto;
  }
  .first_app .upload_files,
  .modal-body-content {
    background-color: #fff;
  }
  .splash_mo .not-choose-from-library {
    min-height: 46px;
  }
  .upload-image {
    top: 30px;
  }
  .modal-body-content {
    max-height: 900px;
    height: auto;
  }
  .app_dummy {
    width: 31%;
    margin: 8px;
  }
  .splash-modal .search-box {
    width: 60% !important;
  }
  .modaltext {
    width: 170px !important;
    margin-bottom: 0px;
  }
  .install-plugin-steps h4 {
    margin-bottom: 0px;
  }
  .social_links .col-md-6:first-child {
    padding-right: 0px !important;
  }
  .custom-pages .app_iconname {
    margin-top: 50px;
    margin-left: 15px;
  }
  .social_pannel .common-height h5 {
    font-size: 13px !important;
  }
  .app-settings .col-md-12 {
    padding: 0px 15px;
  }
  .socila-pages {
    padding: 0px;
  }
  .sign_in .authenticate-caraousel {
    display: none !important;
  }
  .white-mobile-border {
    width: 314px !important;
    height: 700px !important;
    left: 0px;
    top: 108px;
    border-radius: 32px !important;
  }
  .hollow_phone {
    width: 400px;
    height: 790px;
    position: fixed;
    left: 0px;
    right: 0px;
  }
  .mobile-head {
    top: 38px;
    width: 291px;
  }
  .color_picker.ipad-margin-top {
    margin-top: 28px;
    padding: 0px;
  }
  #clock {
    top: 250px;
    position: fixed;
  }
  .authenticate .signup-form {
    width: 41% !important;
  }
  .sign_in .mobile-head {
    top: 38px;
  }
  .style-page-cart .mobile-head {
    top: 43px;
  }
  .ipad .ipad-custom-padding,
  .dashboard-content {
    padding: 10px 18px 5px 18px !important;
  }
  .ipad-margin-top {
    margin-top: 85px;
    padding: 0px 18px 5px 18px;
  }
  .fixed-button .ipad-block {
    display: block !important;
    margin-top: 0px;
    text-align: center;
  }
  .ipad-block {
    display: block !important;
    margin-top: 0px;
    text-align: center;
  }
  .right_general.my-app-preview .newbox .white-mobile-border {
    top: 211px;
  }
  .apppreview .signup-form {
    width: 36%;
    margin: auto;
    bottom: 226px;
  }
  .style-page-cart .white-mobile-border {
    top: 112px !important;
    width: 314px !important;
    height: 700px;
    border-radius: 32px !important;
  }
  .light-blue-cart {
    bottom: 54px !important;
  }
  .proceed-to-checkout {
    bottom: 4px !important;
  }
  .dashboard-mobile .white-mobile-border {
    top: 112px !important;
    width: 314px !important;
    height: 700px;
    border-radius: 32px !important;
  }
  .social_pannel ul {
    box-shadow: none !important;
  }
  .api-details .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .api-head {
    margin-top: 80px;
    margin-left: 15px;
  }
  .androidpublish {
    width: 220px;
    text-align: center;
  }
  .androidpublishright {
    width: calc(100% - 220px);
  }
  .api-details .row {
    margin: 0px;
  }
  .api-details .row .two-apps {
    margin: 20px 0px;
  }
  .faq .col-md-6 {
    padding: 0px;
  }
  .bigger-plan .get-quote {
    width: 200px;
    margin-right: 0px !important;
  }
  .planA,
  .planB {
    min-height: 100%;
  }

  .bill ul li {
    width: auto;
  }
  .dasboard_page.mobile-display.billing-page-mobile.mobile-view-billing {
    margin-top: 0 !important;
  }

  .deal-plan-head h3 {
    font-size: 21px;
  }
  .deal-count h5 {
    font-size: 14px;
  }
  h6.coupon-code-text {
    font-size: 13px;
  }
  .deal-expiry-date h5 {
    margin-top: 10px;
  }
}
.topfixback {
  bottom: 63%;
}
.leftscroll {
  height: calc(100% - 200px);
  overflow: auto;
  padding-top: 10px;
}
.show_border {
  border-bottom: 1px solid #e0e5ec;
}
span.deal-status {
  display: inline-block;
  border-radius: 18px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  color: #fff;
  min-width: 56px;
  text-align: center;
  text-transform: capitalize;
  line-height: 13px;
  padding: 3px;
}

.nodeals-img {
  max-width: 100%;
}

@media (min-width: 1200px) and (max-width: 1536px) {
  /* .leftscroll{
		overflow: auto;
		max-height: 570px;
	} */
  .leftscroll .common-height {
    height: 40px;
  }
  .leftscroll .web_logo svg {
    width: 29px;
    height: 29px;
  }
  .pannel_title h4 {
    font-size: 12px;
  }
  .leftscroll {
    padding-top: 10px;
  }
  .plandata {
    margin: 0px 15px 12px 0px;
  }
  .panel-body ul li {
    margin-bottom: 10px !important;
  }
  .leftscroll .common-height .web_logo svg {
    width: 31px;
  }
  .sketch-picker {
    width: 212px !important;
  }
  .flexbox-fix input {
    height: 29px !important;
  }
  .downloadaab {
    width: 154px;
  }
  .preview-new-button {
    width: 213px;
  }

  .transparent_background.dealselect_background .flex-1 {
    flex-basis: 42%;
  }
  .transparent_background.dealselect_background .flex-2 {
    flex-basis: 16%;
  }
}
@media (min-width: 900px) and (max-width: 1100px) {
  .two-apps {
    padding: 50px;
  }
  .androidpublish {
    width: 450px;
    text-align: center;
  }
  .androidpublishright {
    width: calc(100% - 450px);
  }
  .greentick h4::after {
    display: none;
  }
  .customiseapp {
    padding-top: 150px;
    top: 62px;
  }
  .preview-red-cross {
    top: -185px;
    left: 470px;
  }
  .small_mobile_img {
    width: 25%;
  }
  .create_own #applogo {
    width: 45px;
    height: 45px;
  }
  .two-btn-tabs .createtabs {
    width: 100%;
  }
  .app-logo {
    padding: 15px;
  }
  .alignment svg {
    width: 50px;
  }
  .placement .align-mobile {
    margin-left: 15px !important;
  }
  .alignment h5 {
    margin-top: 12px;
  }
  .customiseappfeatures .fixed-button .back_btn {
    display: none !important;
  }
  .white-mobile-border {
    margin-top: 175px;
  }
  .slide-arrow.right,
  .slide-arrow.left {
    top: 500px;
  }
  #clock {
    margin-top: 150px;
  }
  .sign_in .mobile-head {
    top: 36px;
  }
  .app-settings .col-md-12 {
    padding: 0px 15px;
  }
  .socila-pages .module_title h5 {
    font-size: 13px;
  }

  .dealselect-heading h3 {
    font-size: 30px;
  }

  .transparent_background.dealselect_background {
    margin: 7% 0 0 0;
  }

  .dealselect-btn-panel {
    margin-top: 21px;
  }
  .deal-plan-head {
    padding-left: 20px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
}
.add-on-added.coming-soon {
  background-color: #f5f7ff;
  color: #3064f9;
  border: 1px solid #3064f9;
  transition: 0.5s;
}
@media (max-width: 900px) {
  .right_panel.right-panel-publish {
    background: #fff;
  }
}

.badge-secondary {
  font-size: 12px !important;
  font-family: "Axiforma-Regular" !important;
  font-weight: normal !important;
  line-height: 11px !important;
  background-color: #bcc1ce !important;
  padding: 4px 4px 2px 4px !important;
}
.item-customizer.h1,
.item-customizer.h2,
.item-customizer.h3,
.item-customizer.h4,
.item-customizer.h5,
.item-customizer.h6 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

@media (max-width: 1800px) {
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 175px;
  }
  .inner-radio .form-check-label.form-label-text2 {
    padding-right: 220px !important;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 19px;
  }
}
@media (max-width: 1680px) {
  .transparent_background.dealselect_background {
    width: calc(100% - 150px);
  }
  .topsectionapp h3.namespac {
    flex-basis: 65%;
    width: auto;
  }
  .topsectionapp p.created-date-personal {
    width: 35%;
    min-width: 85px;
  }
  .blog_categories .color_picker .tooltip1 {
    right: -134px;
  }
  .custom-radio-btn [type="radio"]:checked + label:before,
  .custom-radio-btn [type="radio"]:not(:checked) + label:before {
    right: 6%;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 162px;
  }

  .inner-radio .form-check-label.form-label-text2 {
    padding-right: 195px !important;
  }
  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 19px;
  }

  .altswitch {
    bottom: -75px;
  }
}

@media (min-width: 1510px) and (max-width: 1580px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 29px;
  }
}
@media (max-width: 1580px) {
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 135px;
  }
  .inner-radio .form-check-label.form-label-text2 {
    padding-right: 175px !important;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 17px;
  }
  p.mobile-general-text {
    white-space: nowrap;
  }

  select#leftdropdown4,
  select#leftdropdown2,
  select#leftdropdown3,
  select#leftdropdown1,
  select#leftdropdown5 {
    padding-right: 12%;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .website-type-all .new-website-type {
    width: 31.5%;
  }
  .blog_categories .color_picker .tooltip1 {
    right: -100px;
  }
  .blog_categories .color_picker .tooltip1:before {
    left: 38%;
  }
  .topsectionapp p {
    flex-basis: 42%;
    width: 42%;
  }
  .topsectionapp h3.namespac {
    flex-basis: 60%;
  }

  .large-up-back .upload-long-text {
    max-width: 70%;
    overflow: hidden;
  }

  .next-payment {
    width: 200px;
  }
  .payment-method {
    width: 230px;
  }
  .subscriptions .action {
    min-width: 75px;
    text-align: right;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 14px;
  }

  .cus-web p {
    font-size: 14px;
  }
  .product p:nth-of-type(2),
  .subscription-type p {
    color: #7782a1;
    margin-top: 5px;
  }

  .inner-radio.custom-radio-button2 .form-check-label.form-label-text2 {
    padding-right: 165px !important;
  }
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 140px;
  }
  .deal-expiry-date h5,
  h5.expiry-date,
  .next-payment p {
    margin-top: 5px;
  }

  .creditpopup {
    max-width: 45% !important;
  }

  .creditpopup .modal-body {
    max-height: 330px;
  }

  header .container {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .inner-radio.custom-radio-button2 .form-check-label.form-label-text2 {
    padding-right: 150px !important;
  }

  .appmysite-certificate {
    padding-right: 90px;
  }

  .price-card {
    padding: 46px 25px 50px;
  }
  .pricing-mb-5 {
    margin-bottom: 41px;
  }
}

@media (max-width: 1300px) {
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 90px !important;
  }

  .cus-web p {
    font-size: 12px;
  }

  .inner-radio.custom-radio-button2 .form-check-label.form-label-text2 {
    padding-right: 135px !important;
  }
}
@media (min-width: 1210px) and (max-width: 1260px) {
  .inner-radio.custom-radio-button2 .form-check-label.form-label-text2 {
    padding-right: 115px !important;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 70px !important;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 10px !important;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 23px;
  }
}

@media (max-width: 1100px) {
  .inner-radio.custom-radio-button2 .form-check-label.form-label-text2 {
    padding-right: 125px !important;
  }

  .price-card {
    padding: 46px 21px 50px;
  }

  .pf-group {
    padding-right: 10px;
  }

  .common-container {
    max-width: 950px;
    margin: 0 auto;
  }

  .item-customizer:last-of-type {
    margin-bottom: 40px;
  }

  .edit-btn.mr-4 {
    margin-right: 10px !important;
  }
  .edit-btn {
    margin-right: 0 !important;
  }
  .mr-md-1.ipad-1 {
    margin-right: 6px !important;
  }
}
@media (max-width: 599px) {
  .dashboardheader.header-mobile-shadow {
    box-shadow: 0px 0px 10px rgb(188 193 206 / 36%) !important;
  }
  section.dasboard_page.mobile-display {
    margin-top: 0px !important;
  }
}
@media (min-width: 500px) and (max-width: 599px) {
  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 17px !important;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 19px !important;
  }
}
/* @media (min-width: 850px) and (max-width: 950px) {
  .inner-radio [type="radio"]:checked + label:after, .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 24px !important;
  }
} */
@media (min-width: 1100px) and (max-width: 1200px) {
  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 32px !important;
  }
}
@media (min-width: 600px) and (max-width: 710px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 40px !important;
  }
}
@media (min-width: 711px) and (max-width: 767px) {
  .right_panel.right_panel_changepassword {
    padding-top: 4px !important;
  }
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 47px !important;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 40px !important;
  }
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    font-size: 13px;
  }
}
@media (min-width: 801px) and (max-width: 880px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 45px !important;
  }
}
@media (min-width: 881px) and (max-width: 950px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 50px !important;
  }
}
@media (min-width: 951px) and (max-width: 1000px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 53px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 59px !important;
  }
}
@media (min-width: 1101px) and (max-width: 1199px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 64px !important;
  }
}
@media screen and (max-width: 400px) {
  .table_head_mob .table_head.notif-head-alignment.mobile-head-alignment {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .form-check-label.form-check-label-red {
    white-space: nowrap;
  }
}
@media (max-width: 414px) {
  h6.coupon-code-text {
    font-size: 12px;
    line-height: 1.2;
  }
  .flex-1 {
    flex-basis: 70%;
  }
  .flex-2 {
    flex-basis: 27%;
  }
  .mobile-grid {
    flex-basis: 100%;
  }

  .transparent_background.dealselect_background {
    width: 100% !important;
  }
  .left-sec {
    flex: 1;
    flex-basis: 100%;
    width: 100%;
  }
  .mob-responsive-appicon {
    flex: 1;
    flex-basis: 100%;
    width: 100% !important;
  }
  .color_picker .tooltip1,
  .module_title .tooltip1,
  .color_picker_full .tooltip1 {
    right: -120px;
  }

  .app_dummy {
    width: 45.9%;
    height: 270px !important;
  }

  .abs-badge.abs-badge-ipad {
    top: 78% !important;
  }

  .custom-radio-btn input[type="radio"]:checked + label,
  .custom-radio-btn input[type="radio"]:not(:checked) + label {
    padding-right: 38px;
  }

  label.form-check-label.form-label-text2 {
    padding-right: 70px !important;
  }

  .custom-radio-btn [type="radio"]:checked + label:after,
  .custom-radio-btn [type="radio"]:not(:checked) + label:after {
    right: 12px;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 43px !important;
  }

  .inner-radio.custom-radio-button2 label.form-check-label.form-label-text2 {
    padding-right: 70px !important;
  }

  .cus-web p {
    font-size: 14px;
  }
  .badge-secondary {
    font-size: 11px !important;
  }
  .abs-badge {
    top: 35% !important;
    transform: translateY(-35%);
    margin-left: 3px;
  }
  .mobile-menu {
    overflow: scroll;
  }
  .sm-hidden,
  .sm-hidden2 {
    width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .sm-hidden2 {
    max-width: 95%;
  }

  .pd-md-3 {
    padding: 30px 20px !important;
  }

  .plr-md-3 {
    padding: 0 20px 20px !important;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 24px !important;
  }

  .creditpopup .modal-body {
    max-height: 100%;
  }

  .mobile-menu-lower {
    margin-bottom: 35px;
  }

  .mr-md-1.ipad-1 {
    margin-right: 3px !important;
  }
}

@media (max-width: 375px) {
  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 22px !important;
  }
  .color_input .fixback{
    right: -20px;
  }
  /* .custom-radio-btn
    input[type="radio"]:checked
    + label.form-check-label.form-check-label-red {
    display: flex;
  } */
}
@media (max-width: 361px) {
  .web-sm-red {
    margin-bottom: 18px;
  }
}
@media (max-width: 360px) {
  
  li.change-pass-inst {
    text-indent: -17px;
    padding-left: 19px;
  }
  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 38px !important;
  }

  .inner-radio.custom-radio-button2 label.form-check-label.form-label-text2 {
    padding-right: 65px !important;
  }

  .cus-web p {
    font-size: 14px;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 11px !important;
  }
  .custom-radio-btn input[type="radio"]:checked + label,
  .custom-radio-btn input[type="radio"]:not(:checked) + label {
    padding-left: 2px;
  }

  .sm-hidden {
    width: 200px;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 19px !important;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    font-size: 13px;
  }
  .form-check-label.form-check-label-red {
    white-space: nowrap;
  }
}

@media (max-width: 320px) {
  .mobile-menu .col-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .mobile-menu {
    overflow: scroll;
  }
  .mobile-menu-lower {
    padding-top: 0;

    margin-bottom: 25px;
  }
  .app_dummy {
    width: 45.2%;
    height: 210px !important;
  }

  .custom-radio-btn [type="radio"]:checked + label:before,
  .custom-radio-btn [type="radio"]:not(:checked) + label:before {
    right: 4%;
  }

  .custom-radio-btn input[type="radio"]:checked + label,
  .custom-radio-btn input[type="radio"]:not(:checked) + label {
    padding-right: 150px;
  }

  label.form-check-label.form-label-text2 {
    padding-right: 180px !important;
  }

  .modal-dialog.modal-dialog-centered.profile-code-modal {
    max-height: fit-content !important;
    min-height: auto !important;
  }
  .customcheck.customcheck-red {
    display: flex;
    justify-content: flex-end;
  }

  .custom-radio-btn [type="radio"]:checked + label:after,
  .custom-radio-btn [type="radio"]:not(:checked) + label:after {
    right: 19px;
  }

  .inner-radio input[type="radio"]:checked + label,
  .inner-radio input[type="radio"]:not(:checked) + label {
    padding-right: 140px !important;
  }

  .inner-radio.custom-radio-button2 label.form-check-label.form-label-text2 {
    padding-right: 165px !important;
  }

  .inner-radio [type="radio"]:checked + label:after,
  .inner-radio [type="radio"]:not(:checked) + label:after {
    right: 29px !important;
  }

  .inner-radio [type="radio"]:checked + label:before,
  .inner-radio [type="radio"]:not(:checked) + label:before {
    right: 10% !important;
  }

  .sm-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
    white-space: nowrap;
  }

  .w-75.mobile-url {
    flex-basis: 86%;
    width: 86% !important;
  }

  .w-25.edit-link {
    flex-basis: 14%;
    width: 14% !important;
  }

  .w-25.edit-link-redesign {
    width: 25% !important;
  }

  .btn.custom-radio-button1,
  .btn.custom-radio-button2 {
    width: 100%;

    margin: 5px 0 12px;
  }

  .white-bg {
    width: 100%;
  }
  .signup_form {
    padding-left: 15px;
    padding-right: 15px;

    max-width: 300px;
  }

  .credit-card-list [type="radio"]:checked + label:after,
  .credit-card-list [type="radio"]:not(:checked) + label:after {
    right: 17px !important;
  }

  .store_url_div div {
    font-size: 14px;
  }

  .sm-wrap {
    flex-wrap: wrap;
  }

  h4.p-0.sm-pb-1 {
    padding-right: 10px !important;
  }
  .sm-mb-2 {
    margin-bottom: 15px;
  }
}

/* Addon new layout */
.colorblack {
  color: #0f193b;
}
.colorblue {
  color: #3064f9;
}
.cologrey {
  color: #5f6578;
}
.blueback {
  background-color: #f4f4f9;
}
.allflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.semiflex {
  display: flex;
  justify-content: space-between;
}
.midflex {
  display: flex;
  align-items: center;
}
.simpleflex {
  display: flex;
}
.paddingtop100 {
  padding-top: 100px;
}
.paddingtop120 {
  padding-top: 120px;
}
.paddingbottom120 {
  padding-bottom: 120px;
}
.paddingbottom110 {
  padding-bottom: 110px;
}
.paddingbottom100 {
  padding-bottom: 100px;
}
.paddingbottom80 {
  padding-bottom: 80px;
}

.homebanner {
  position: relative;
}
.homebanner .container {
  padding: 150px 0 180px;
}
.homebanner2 .container {
  padding: 165px 0;
}
.homebanner .container-fluid {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homebanner .container-fluid .col-md-6:nth-child(2) {
  top: 65px;
  bottom: 0;
}
.homebanner h1 {
  font-size: 48px;
  font-family: axiforma-bold !important;
  line-height: 64px;
}
.homebanner h2 {
  font-size: 20px;
  margin: 29px 0 51px;
  line-height: 30px;
}
.homebannerimg {
  position: absolute;
  right: 0;
  width: 50%;
  padding-left: 130px;
}
.homebannerimg2 {
  position: absolute;
  right: 0;
  width: 50%;
  padding-left: 0;
  bottom: 0;
}
.homebannerbtn {
  background-color: #fff;
  width: 220px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #3064f9;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  border-radius: 3px;
  transition: 0.5s;
  padding-top: 4px;
}
.homebannerbtn:hover {
  box-shadow: none;
  transition: 0.5s;
}

.homebanner h4 {
  font-family: axiforma-bold !important;
  font-size: 16px !important;
  margin: 30px 0 0;
  color: #0f193b;
}
.homebanner h4 span {
  font-family: axiforma-regular !important;
  font-size: 16px !important;
}
.sectionheadings {
  color: #0f193b;
  font-size: 36px !important;
  font-family: axiforma-bold !important;
  line-height: 54px !important;
}
.subheadings1 {
  color: #5f6578;
  font-size: 16px !important;
  font-family: axiforma-regular !important;
  line-height: 30px !important;
}
.subheadingtop {
  margin-top: 28px;
}
.subheadings2 {
  color: #0f193b;
  font-size: 20px !important;
  font-family: axiforma-bold !important;
  line-height: 30px !important;
}
.normaltext {
  color: #5f6578;
  font-family: axiforma-regular !important;
  font-size: 14px !important;
  line-height: 24px !important;
}
.mobileappcreator .iconbox {
  width: 106px;
  height: 80px;
  margin: auto;
  margin-bottom: 35px;
}
.mobileappcreator .row {
  margin-top: 75px;
}
.buildapp {
  position: relative;
  bottom: -80px;
}
.autopublishapp .subheadings2 {
  margin-top: 45px;
}
.autopublishapp a {
  width: 160px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #b8bfd0;
  margin-top: 40px;
  border-radius: 3px;
  color: #7782a1;
  transition: 0.5s;
  font-size: 16px;
}
.autopublishapp a:hover {
  background-color: #fff;
  transition: 0.5s;
  border: 1px solid #fff;
}
.monetizeapp img {
  position: relative;
  left: -180px;
}
.engageusers .row .col-md-6:nth-child(1) {
  padding-right: 80px;
}
.engageusers {
  height: 600px;
}
.branding {
  position: absolute;
}
.tailormade {
  align-items: stretch;
  flex-wrap: wrap;
}
.portalfeatures {
  text-align: center;
  width: 50%;
}
.portalfeatures .subheadings2 {
  margin: 40px 0 10px;
}
.portalfeatures p {
  width: 80%;
  margin: auto;
}
.businesssync {
  flex-wrap: wrap;
  margin-top: 60px;
}
.businesssync .sixboxes {
  width: 32%;
  background-color: #fff;
  padding: 35px 30px 20px;
  margin-bottom: 2%;
  border-radius: 5px;
  position: relative;
}
.sixboxes div {
  position: absolute;
  left: 0;
  top: 25px;
  width: 3px;
  height: 40px;
}
.borderblue {
  background-color: #7297ff;
}
.borderyellow {
  background-color: #ffc834;
}
.borderorange {
  background-color: #fc8855;
}
.bordergreen {
  background-color: #83d298;
}
.borderred {
  background-color: #ff5858;
}
.borderlightblue {
  background-color: #47c2ff;
}
.businesssync .subheadings2 {
  margin-bottom: 20px;
}
.threesteps .row {
  margin-top: 60px;
}
.threesteps .col-md-6 div {
  border: 1px solid #dce1eb;
  border-radius: 5px;
  padding: 28px 35px 32px 45px;
  margin-bottom: 20px;
  margin-top: 0;
  width: 95%;
  position: relative;
}
.threesteps .col-md-6 div p {
  margin-bottom: 0;
}
.threesteps .one {
  position: absolute;
  left: -31px;
  top: 11px;
}
.threesteps .subheadings2 {
  margin-bottom: 14px;
}

.autopublishapp a:nth-child(3) {
  display: none !important;
}
.monetizeapp a:nth-child(3) {
  display: none !important;
}

.tagline {
  margin-bottom: 32px;
}
.mauto {
  margin: auto;
}
.posrelative {
  position: relative;
}
.posabsoluteright {
  position: absolute;
  right: 0;
  width: 50%;
}

.checklist .container div {
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  padding: 40px 40px 20px;
}
.checklist h4 {
  color: #0f193b;
  font-size: 20px;
  font-family: axiforma-bold !important;
  line-height: 30px;
}
.checklist ul {
  padding-left: 15px;
  list-style-image: url("../images/addons/storedesign/store-design-list-img.png");
}
.checklist ul li {
  color: #5f6578;
  font-size: 14px;
  font-family: axiforma-regular !important;
  line-height: 24px;
  margin-top: 20px;
}
.templates .allflex {
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 60px;
}
.templates .allflex div {
  width: 48%;
  margin-top: 40px;
}
.templates .allflex div p {
  color: #0f193b;
  font-size: 14px;
  font-family: axiforma-bold !important;
}
.templates a {
  text-decoration: none;
  color: #3064f9;
  font-size: 14px;
}
.similarleft {
  padding-left: 80px;
}
.similarright {
  padding-right: 80px;
}

@media screen and (min-width: 1001px) {
  .addon-dedicated-page .container {
    min-width: 1110px;
  }
  .container.containerstore-redesign {
    min-width: auto !important;
  }
  .webshow {
    display: block !important;
  }
  .mobileshow {
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    width: 100% !important;
  }

  .paddingtop100 {
    padding-top: 60px;
  }
  .paddingtop120 {
    padding-top: 60px;
  }
  .paddingbottom120 {
    padding-bottom: 60px;
  }
  .paddingbottom110 {
    padding-bottom: 60px;
  }
  .paddingbottom100 {
    padding-bottom: 60px;
  }
  .paddingbottom80 {
    padding-bottom: 60px;
  }
  .homebanner h1 {
    font-size: 34px;
    line-height: 46px;
  }
  .homebanner h1 br {
    display: none;
  }
  .homebanner h2 br {
    display: none;
  }
  .homebanner h2 {
    font-size: 16px;
    margin: 27px 0 30px;
    line-height: 26px;
  }
  .homebanner {
    padding: 48px 0 0 !important;
  }
  .homebanner h4 {
    margin: 30px 0 42px;
  }
  .homebanner .simpleflex a {
    width: 48%;
    height: 44px;
    font-size: 14px;
  }
  .sectionheadings {
    color: #0f193b;
    font-size: 26px;
    font-family: axiforma-bold !important;
    line-height: 35px;
    width: 100% !important;
  }
  .mobileappcreator .text-center {
    text-align: left !important;
  }
  .mobileappcreator .iconbox {
    margin: initial;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .mobileappcreator .row {
    margin-top: 0;
  }
  .normaltext {
    line-height: 19px;
    width: 100% !important;
  }
  .guest-login-toggle .guest-bg-colour,
  .guest-login-toggle .guest-icon-colour,
  .guest-login-toggle .discount-cent-text {
    text-align: left !important;
  }

  .subheadings2 {
    width: 100% !important;
  }
  .card-num p.card-num-inner {
    text-align: left !important;
  }

  .subheadingtop {
    margin-top: 23px;
  }
  .sectionheadings.text-center {
    text-align: left !important;
  }
  .subheadings1.text-center {
    text-align: left !important;
  }
  .subheadings1 {
    font-size: 16px;
    line-height: 24px;
  }
  .buildapp {
    position: initial;
    bottom: 0;
  }
  .main-addon img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
  .pro-icon-spacing button img {
    width: 24px !important;
  }
  .pro-icon-spacing button img.img-fluid {
    width: 24px !important;
  }
  .pro-icon-spacing button img.profile_new_placeholder {
    width: 48px !important;
  }
  .businesssync .sixboxes {
    width: 100%;
    padding: 30px 20px 32px;
    margin-bottom: 20px;
  }
  .businesssync div:last-child {
    margin-bottom: 0;
  }

  .portalfeatures {
    padding: 40px 24px 43px;
    text-align: left;
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  .portalfeatures:last-child {
    margin-bottom: 0 !important;
  }
  .portalfeatures div {
    width: auto;
    height: auto;
  }
  .tailormade {
    margin-top: 40px;
  }
  .threesteps .col-md-6 div {
    padding: 29px 28px 32px 38px;
    margin-bottom: 0 !important;
    margin-top: 20px !important;
    float: right !important;
  }
  .threesteps .col-md-6 div:last-child {
    margin-bottom: 60px !important;
  }
  .threesteps .row {
    margin-top: 50px;
  }

  .mobile-container {
    padding: 0 15px;
  }
  img.gotoappicon {
    width: auto;
    margin-left: 7px;
    vertical-align: middle;
  }
  .pay-txt img {
    max-width: 100%;
    width: auto;
  }
  .transparent_background .ipad-view-headbill,
  .table-top.table-top-ipad {
    display: none !important;
  }

  .engageusers img {
    max-width: 100%;
  }
  .businesssync {
    margin-top: 40px;
  }
  .autopublishapp .row .col-md-6:nth-child(2) {
    padding-left: 15px;
  }
  .homebanner .container {
    padding: 0 15px;
  }
  .homebanner .mobileshow {
    margin-top: 60px;
  }
  .buildyourapp .tailormade .portalfeatures {
    padding-top: 75px;
  }
  .buildyourapp .tailormade .portalfeatures .subheadings2 {
    margin: 75px 0 20px !important;
  }
  .autopublishapp .subheadings1 {
    margin-bottom: 40px;
  }
  .plan-select-new-btn {
    padding-right: 20px;
  }
  a.select-button-new {
    width: 100%;
  }

  .autopublishapp a {
    display: flex;
    width: 100%;
  }
  .autopublishapp a:nth-child(3) {
    display: flex !important;
    margin-bottom: 60px;
  }
  .autopublishapp a:nth-child(9) {
    display: none !important;
  }
  .monetizeapp img {
    left: 0;
    margin: auto;
  }
  .monetizeapp a:nth-child(3) {
    display: flex !important;
    margin-bottom: 60px;
  }
  .monetizeapp a:nth-child(9) {
    display: none !important;
  }

  .main-addon .text-center {
    text-align: left !important;
  }
  .webshow {
    display: none !important;
  }
  .mobileshow {
    display: block !important;
  }

  .tagline {
    margin-bottom: 23px;
    display: none;
  }

  .engageusers {
    height: auto;
  }
  .similarleft {
    padding-left: 15px;
  }
  .similarright {
    padding-right: 15px;
  }
  .templates .allflex {
    display: block;
  }
  .templates .allflex div {
    width: 100%;
    margin-top: 40px;
  }
  .checklist .container div {
    padding: 30px 20px 10px;
  }
  .templates .allflex div p {
    text-align: center;
  }
  .templates {
    text-align: center !important;
  }
  .mobileappcreator .col-md-4 p {
    margin-bottom: 0;
  }
  .mgap img {
    margin-top: 50px;
  }
  .bannergap {
    padding-bottom: 40px !important;
  }
  .storefix .portalfeatures {
    padding: 0 !important;
    margin-top: 0 !important;
  }
  .storefix .tailormade {
    margin-top: 0;
  }
  .storefix.buildyourapp .tailormade .subheadings2 {
    margin: 15px 0 20px !important;
  }
  .storefix .portalfeatures:nth-child(1) {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) {
  .analytics-status {
    justify-content: space-between;
    margin: 10px 0px 20px 0px;
  }
  .homebanner img {
    margin: auto;
  }

  .homebanner {
    text-align: center;
  }
  .homebanner .midflex {
    justify-content: center;
  }
  .homebanner .simpleflex a {
    height: 50px;
    font-size: 16px;
  }
  .mobileappcreator .row .col-md-4 {
    width: 33%;
    text-align: center !important;
  }
  .subheadings2 {
    font-size: 16px;
  }
  .mobileappcreator .text-center,
  .sectionheadings.text-center,
  .subheadings1.text-center {
    text-align: center !important;
  }
  .mobileappcreator .iconbox {
    margin: auto;
    margin-bottom: 30px;
  }
  .mobileappcreator .row {
    margin-top: 60px;
  }
  .subheadings2,
  p {
    text-align: center !important;
  }
  .portalfeatures {
    width: 48%;
  }
  .businesssync .sixboxes {
    width: 48%;
  }
  .businesssync {
    align-items: baseline;
  }
  .tailormade {
    align-items: start;
  }

  .addonservices.semiflex {
    display: flex;
  }
  .collaboratewithteam .semiflex div {
    width: 31%;
    padding: 50px 15px 20px;
  }
  .collaboratewithteam .semiflex div:last-child {
    margin-bottom: 20px;
  }
  .spacesection img {
    margin: auto;
  }
  .spacesection {
    text-align: center !important;
  }
  .mgap img {
    margin: auto;
    margin-top: 50px;
  }
  .threesteps img {
    margin: auto;
  }
  .templates .allflex {
    display: flex;
  }
  .templates .allflex div {
    width: 48%;
  }
  .text-center {
    text-align: center !important;
  }
  .mgap {
    text-align: center !important;
  }
  .threesteps .row {
    display: flex;
    align-items: center;
  }
  .threesteps .row .col-md-6 {
    width: 50%;
  }
  .threestepsdetail.allflex {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding-top: 0;
  }
  .threestepsdetail div {
    width: 48%;
  }
  .threestepsdetail {
    margin-top: 60px;
  }
  .threesteps .col-md-6 div:last-child {
    margin-bottom: 0 !important;
  }
  .threestepsdetail.allflex div:last-child p {
    margin-bottom: 40px;
  }
  .storefix .portalfeatures {
    text-align: center;
  }
}

.colorblack {
  color: #0f193b;
}
.colorblue {
  color: #3064f9;
}
.cologrey {
  color: #5f6578;
}
.blueback {
  background-color: #f4f4f9;
}
.allflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.semiflex {
  display: flex;
  justify-content: space-between;
}
.midflex {
  display: flex;
  align-items: center;
}
.simpleflex {
  display: flex;
}
.paddingtop100 {
  padding-top: 100px;
}
.paddingtop120 {
  padding-top: 120px;
}
.paddingbottom120 {
  padding-bottom: 120px;
}
.paddingbottom110 {
  padding-bottom: 110px;
}
.paddingbottom100 {
  padding-bottom: 100px;
}
.paddingbottom80 {
  padding-bottom: 80px;
}

.homebanner {
  position: relative;
}
.homebanner .container {
  padding: 150px 0 180px;
}
.homebanner2 .container {
  padding: 165px 0;
}
.homebanner .container-fluid {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homebanner .container-fluid .col-md-6:nth-child(2) {
  top: 65px;
  bottom: 0;
}
.homebanner h1 {
  font-size: 48px;
  font-family: axiforma-bold !important;
  line-height: 64px;
}
.homebanner h2 {
  font-size: 20px;
  margin: 29px 0 51px;
  line-height: 30px;
}
.homebannerimg {
  position: absolute;
  right: 0;
  width: 50%;
  padding-left: 130px;
}
.homebannerimg2 {
  position: absolute;
  right: 0;
  width: 50%;
  padding-left: 0;
  bottom: 0;
}
.homebannerbtn {
  background-color: #fff;
  width: 220px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #3064f9;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  border-radius: 3px;
  transition: 0.5s;
  padding-top: 4px;
}
.homebannerbtn:hover {
  box-shadow: none;
  transition: 0.5s;
}

.homebanner h4 {
  font-family: axiforma-bold !important;
  font-size: 16px !important;
  margin: 30px 0 0;
  color: #0f193b;
}
.homebanner h4 span {
  font-family: axiforma-regular !important;
  font-size: 16px !important;
}
.sectionheadings {
  color: #0f193b;
  font-size: 36px !important;
  font-family: axiforma-bold !important;
  line-height: 54px !important;
}
.subheadings1 {
  color: #5f6578;
  font-size: 16px !important;
  font-family: axiforma-regular !important;
  line-height: 30px !important;
}
.subheadingtop {
  margin-top: 28px;
}
.subheadings2 {
  color: #0f193b;
  font-size: 20px !important;
  font-family: axiforma-bold !important;
  line-height: 30px !important;
}
.normaltext {
  color: #5f6578;
  font-family: axiforma-regular !important;
  font-size: 14px !important;
  line-height: 24px !important;
}
.mobileappcreator .iconbox {
  width: 106px;
  height: 80px;
  margin: auto;
  margin-bottom: 35px;
}
.mobileappcreator .row {
  margin-top: 75px;
}
.buildapp {
  position: relative;
  bottom: -80px;
}
.autopublishapp .subheadings2 {
  margin-top: 45px;
}
.autopublishapp a {
  width: 160px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #b8bfd0;
  margin-top: 40px;
  border-radius: 3px;
  color: #7782a1;
  transition: 0.5s;
  font-size: 16px;
}
.autopublishapp a:hover {
  background-color: #fff;
  transition: 0.5s;
  border: 1px solid #fff;
}
.monetizeapp img {
  position: relative;
  left: -180px;
}
.engageusers .row .col-md-6:nth-child(1) {
  padding-right: 80px;
}
.engageusers {
  height: 600px;
}
.branding {
  position: absolute;
}
.tailormade {
  align-items: stretch;
  flex-wrap: wrap;
}
.portalfeatures {
  text-align: center;
  width: 50%;
}
.portalfeatures .subheadings2 {
  margin: 40px 0 10px;
}
.portalfeatures p {
  width: 80%;
  margin: auto;
}
.businesssync {
  flex-wrap: wrap;
  margin-top: 60px;
}
.businesssync .sixboxes {
  width: 32%;
  background-color: #fff;
  padding: 35px 30px 20px;
  margin-bottom: 2%;
  border-radius: 5px;
  position: relative;
}
.sixboxes div {
  position: absolute;
  left: 0;
  top: 25px;
  width: 3px;
  height: 40px;
}
.borderblue {
  background-color: #7297ff;
}
.borderyellow {
  background-color: #ffc834;
}
.borderorange {
  background-color: #fc8855;
}
.bordergreen {
  background-color: #83d298;
}
.borderred {
  background-color: #ff5858;
}
.borderlightblue {
  background-color: #47c2ff;
}
.businesssync .subheadings2 {
  margin-bottom: 20px;
}
.threesteps .row {
  margin-top: 60px;
}
.threesteps .col-md-6 div {
  border: 1px solid #dce1eb;
  border-radius: 5px;
  padding: 28px 35px 32px 45px;
  margin-bottom: 20px;
  margin-top: 0;
  width: 95%;
  position: relative;
}
.threesteps .col-md-6 div p {
  margin-bottom: 0;
}
.threesteps .one {
  position: absolute;
  left: -31px;
  top: 11px;
}
.threesteps .subheadings2 {
  margin-bottom: 14px;
}

.autopublishapp a:nth-child(3) {
  display: none !important;
}
.monetizeapp a:nth-child(3) {
  display: none !important;
}

.tagline {
  margin-bottom: 32px;
}
.mauto {
  margin: auto;
}
.posrelative {
  position: relative;
}
.posabsoluteright {
  position: absolute;
  right: 0;
  width: 50%;
}

.checklist .container div {
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  padding: 40px 40px 20px;
}
.checklist h4 {
  color: #0f193b;
  font-size: 20px;
  font-family: axiforma-bold !important;
  line-height: 30px;
}
.checklist ul {
  padding-left: 15px;
  list-style-image: url("../images/addons/storedesign/store-design-list-img.png");
}
.checklist ul li {
  color: #5f6578;
  font-size: 14px;
  font-family: axiforma-regular !important;
  line-height: 24px;
  margin-top: 20px;
}
.templates .allflex {
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 60px;
}
.templates .allflex div {
  width: 48%;
  margin-top: 40px;
}
.templates .allflex div p {
  color: #0f193b;
  font-size: 14px;
  font-family: axiforma-bold !important;
}
.templates a {
  text-decoration: none;
  color: #3064f9;
  font-size: 14px;
}
.similarleft {
  padding-left: 80px;
}
.similarright {
  padding-right: 80px;
}
.tailormade {
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 60px;
}
.tailormade1 {
  align-items: stretch;
  flex-wrap: wrap;
}
.portalfeatures1 {
  text-align: center;
  width: 50%;
}
.portalfeatures1 .subheadings2 {
  margin: 40px 0 10px;
}
.portalfeatures1 p {
  width: 80%;
  margin: auto;
}
.portalfeatures {
  background-color: #fff;
  padding: 47px 50px 45px;
  text-align: center;
  border-radius: 5px;
  width: 48.1%;
}
.portalfeatures div {
  width: 349px;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.portalfeatures .subheadings2 {
  margin: 38px 0 19px;
}
.collaboratewithteam {
  text-align: center;
}
.collaboratewithteam a {
  margin: auto;
  color: #3064f9;
  font-size: 14px;
  text-decoration: none;
}
.collaboratewithteam h4 {
  margin-bottom: 60px !important;
}
.collaboratewithteam .semiflex div {
  border: 1px solid #dce1eb;
  border-radius: 5px;
  padding: 63px 35px 20px;
  width: 32%;
}
.collaboratewithteam img {
  margin-bottom: 50px;
}
.collaboratewithteam a {
  color: #3064f9;
  text-decoration: none;
  font-size: 16px;
  margin-top: 27px;
}
.collaboratewithteam .normaltext {
  height: 125px;
}
.similarleft h6 {
  margin-top: 55px;
}
.spacesection .row {
  margin-top: 60px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1001px) {
  .addon-dedicated-page .container {
    min-width: 1110px;
  }
  .container.containerstore-redesign {
    min-width: auto !important;
  }
  .webshow {
    display: block !important;
  }
  .mobileshow {
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
  input:checked + .slider {
    background-position: bottom 4.5px left 5px !important;
  }
  .storefix .portalfeatures1:nth-child(1) {
    margin-bottom: 60px !important;
  }
  .portalfeatures1 {
    text-align: left;
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .banner-padding p.firstheading {
    margin: 30px 0 42px !important;
    line-height: 24px !important;
  }
  .banner-padding p {
    font-size: 16px !important;
    margin: 27px 0 30px !important;
    line-height: 26px !important;
  }
  .addon-art-display {
    display: none;
  }
  .banner-padding h2.tocapital {
    font-size: 34px !important;
    line-height: 46px !important;
  }
  .spacesection .row {
    margin-top: 40px;
  }

  .similarleft h6 {
    margin-top: 40px;
  }
  .collaboratewithteam .normaltext {
    height: 95px;
  }
  .addonservices.semiflex {
    display: block;
  }

  .collaboratewithteam .semiflex div {
    padding: 50px 25px 20px;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
  .collaboratewithteam .semiflex div:last-child {
    margin-bottom: 0;
  }
  .collaboratewithteam .normaltext {
    height: 95px;
  }

  .portalfeatures {
    padding: 52px 20px 43px;
    text-align: left;
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  .portalfeatures:last-child {
    margin-bottom: 0 !important;
  }
  .portalfeatures div {
    width: auto;
    height: auto;
  }
  .tailormade {
    margin-top: 40px;
  }

  .container {
    width: 100% !important;
  }

  .paddingtop100 {
    padding-top: 60px;
  }
  .paddingtop120 {
    padding-top: 60px;
  }
  .paddingbottom120 {
    padding-bottom: 60px;
  }
  .paddingbottom110 {
    padding-bottom: 60px;
  }
  .paddingbottom100 {
    padding-bottom: 60px;
  }
  .paddingbottom80 {
    padding-bottom: 60px;
  }
  .homebanner h1 {
    font-size: 34px;
    line-height: 46px;
  }
  .homebanner h1 br {
    display: none;
  }
  .homebanner h2 br {
    display: none;
  }
  .homebanner h2 {
    font-size: 16px;
    margin: 27px 0 30px;
    line-height: 26px;
  }
  .homebanner {
    padding: 48px 0 0 !important;
  }
  .homebanner h4 {
    margin: 30px 0 42px;
  }
  .homebanner .simpleflex a {
    width: 48%;
    height: 44px;
    font-size: 14px;
  }
  .sectionheadings {
    color: #0f193b;
    font-size: 26px !important;
    font-family: axiforma-bold !important;
    line-height: 35px !important;
    width: 100% !important;
  }
  .mobileappcreator .text-center {
    text-align: left !important;
  }
  .mobileappcreator .iconbox {
    margin: initial;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .mobileappcreator .row {
    margin-top: 0;
  }
  .normaltext {
    line-height: 19px !important;
    width: 100% !important;
  }
  .subheadings2 {
    width: 100% !important;
  }
  .subheadingtop {
    margin-top: 23px;
  }
  .sectionheadings.text-center {
    text-align: left !important;
  }
  .subheadings1.text-center {
    text-align: left !important;
  }
  .subheadings1 {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .buildapp {
    position: initial;
    bottom: 0;
  }
  .main-addon img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
  .businesssync .sixboxes {
    width: 100%;
    padding: 30px 20px 32px;
    margin-bottom: 20px;
  }
  .businesssync div:last-child {
    margin-bottom: 0;
  }

  .portalfeatures {
    padding: 40px 24px 43px;
    text-align: left;
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  .portalfeatures:last-child {
    margin-bottom: 0 !important;
  }
  .portalfeatures div {
    width: auto;
    height: auto;
  }
  .tailormade {
    margin-top: 40px;
  }
  .threesteps .col-md-6 div {
    padding: 29px 28px 32px 38px;
    margin-bottom: 0 !important;
    margin-top: 20px !important;
    float: right !important;
  }
  .threesteps .col-md-6 div:last-child {
    margin-bottom: 60px !important;
  }
  .threesteps .row {
    margin-top: 50px;
  }

  .engageusers img {
    max-width: 100%;
  }
  .businesssync {
    margin-top: 40px;
  }
  .autopublishapp .row .col-md-6:nth-child(2) {
    padding-left: 15px;
  }
  .homebanner .container {
    padding: 0 15px;
  }
  .homebanner .mobileshow {
    margin-top: 60px;
  }
  .buildyourapp .tailormade .portalfeatures {
    padding-top: 75px;
  }
  .buildyourapp .tailormade .portalfeatures .subheadings2 {
    margin: 75px 0 20px !important;
  }
  .autopublishapp .subheadings1 {
    margin-bottom: 40px;
  }
  .autopublishapp a {
    display: flex;
    width: 100%;
  }
  .autopublishapp a:nth-child(3) {
    display: flex !important;
    margin-bottom: 60px;
  }
  .autopublishapp a:nth-child(9) {
    display: none !important;
  }
  .monetizeapp img {
    left: 0;
    margin: auto;
  }
  .monetizeapp a:nth-child(3) {
    display: flex !important;
    margin-bottom: 60px;
  }
  .monetizeapp a:nth-child(9) {
    display: none !important;
  }

  .main-addon .text-center {
    text-align: left !important;
  }
  .webshow {
    display: none !important;
  }
  .mobileshow {
    display: block !important;
  }

  .tagline {
    margin-bottom: 23px;
    display: none;
  }

  .engageusers {
    height: auto;
  }
  .similarleft {
    padding-left: 15px;
  }
  .similarright {
    padding-right: 15px;
  }
  .templates .allflex {
    display: block;
  }
  .templates .allflex div {
    width: 100%;
    margin-top: 40px;
  }
  .checklist .container div {
    padding: 30px 20px 10px;
  }
  .templates .allflex div p {
    text-align: center;
  }
  .templates {
    text-align: center !important;
  }
  .mobileappcreator .col-md-4 p {
    margin-bottom: 0;
  }
  .mgap img {
    margin-top: 50px;
  }
  .bannergap {
    padding-bottom: 40px !important;
  }
  .storefix .portalfeatures {
    padding: 0 !important;
    margin-top: 0 !important;
  }
  .storefix .tailormade {
    margin-top: 0;
  }
  .storefix.buildyourapp .tailormade .subheadings2 {
    margin: 15px 0 20px !important;
  }
  .storefix .portalfeatures:nth-child(1) {
    margin-bottom: 60px !important;
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .searchdropdown.searchdropdown-new {
    position: fixed !important;
    transform: translate(-50%, 10%) !important;
  }
}
/* @media (min-width: 300px) and (max-width: 320px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-40%) !important;
  }
}
@media (min-width: 321px) and (max-width: 329px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-40%) !important;
  }
}
@media (min-width: 330px) and (max-width: 359px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-42%) !important;
  }
}
@media (min-width: 360px) and (max-width: 365px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-47%) !important;
  }
}
@media (min-width: 365px) and (max-width: 375px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-49%) !important;
  }
}
@media (min-width: 376px) and (max-width: 384px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-50%) !important;
  }
}
@media (min-width: 386px) and (max-width: 390px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-51%) !important;
  }
}
@media (min-width: 391px) and (max-width: 400px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-52%) !important;
  }
}
@media (min-width: 401px) and (max-width: 410px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-53%) !important;
  }
}
@media (min-width: 411px) and (max-width: 420px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-54%) !important;
  }
}
@media (min-width: 421px) and (max-width: 430px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-56%) !important;
  }
}
@media (min-width: 431px) and (max-width: 440px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-57%) !important;
  }
}
@media (min-width: 441px) and (max-width: 452px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-57%) !important;
  }
}
@media (min-width: 459px) and (max-width: 470px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-59%) !important;
  }
}
@media (min-width: 471px) and (max-width: 490px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-61%) !important;
  }
}
@media (min-width: 491px) and (max-width: 511px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-62%) !important;
  }
}
@media (min-width: 512px) and (max-width: 530px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-64%) !important;
  }
}
@media (min-width: 531px) and (max-width: 542px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-65%) !important;
  }
}
@media (min-width: 543px) and (max-width: 560px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-66%) !important;
  }
}
@media (min-width: 561px) and (max-width: 579px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-67%) !important;
  }
}
@media (min-width: 580px) and (max-width: 594px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-68%) !important;
  }
}
@media (min-width: 595px) and (max-width: 620px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-69%) !important;
  }
}
@media (min-width: 621px) and (max-width: 640px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-70%) !important;
  }
}
@media (min-width: 641px) and (max-width: 660px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-70%) !important;
  }
}
@media (min-width: 661px) and (max-width: 683px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-72%) !important;
  }
}
@media (min-width: 684px) and (max-width: 715px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-73%) !important;
  }
}
@media (min-width: 716px) and (max-width: 744px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-74%) !important;
  }
}
@media (min-width: 745px) and (max-width: 767px) {
  .searchdropdown.searchdropdown-new {
    transform: translateX(-75%) !important;
  }
} */
@media (min-width: 300px) and (max-width: 767px) {
  .admin-search-input {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width: 600px) and (max-width: 999px) {
  .dasboard_page.mobile-display
    .transparent_background.transparent_background_profile {
    margin-top: 6px !important;
  }
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
  .addonservices.semiflex {
    display: flex;
  }

  .collaboratewithteam .semiflex div {
    width: 31%;
    padding: 50px 15px 20px;
  }
  .collaboratewithteam .semiflex div:last-child {
    margin-bottom: 20px;
  }

  .homebanner img {
    margin: auto;
  }
  .portalfeatures {
    width: 48%;
  }
  .tailormade {
    align-items: start;
  }

  .homebanner {
    text-align: center;
  }
  .homebanner .midflex {
    justify-content: center;
  }
  .homebanner .simpleflex a {
    height: 50px;
    font-size: 16px;
  }
  .mobileappcreator .row .col-md-4 {
    width: 33%;
    text-align: center !important;
  }
  .subheadings2 {
    font-size: 16px;
  }
  .mobileappcreator .text-center,
  .sectionheadings.text-center,
  .subheadings1.text-center {
    text-align: center !important;
  }
  .mobileappcreator .iconbox {
    margin: auto;
    margin-bottom: 30px;
  }
  .mobileappcreator .row {
    margin-top: 60px;
  }
  .subheadings2,
  p {
    text-align: center !important;
  }
  .portalfeatures {
    width: 48%;
  }
  .businesssync .sixboxes {
    width: 48%;
  }
  .businesssync {
    align-items: baseline;
  }
  .tailormade {
    align-items: start;
  }

  .addonservices.semiflex {
    display: flex;
  }
  .collaboratewithteam .semiflex div {
    width: 31%;
    padding: 50px 15px 20px;
  }
  .collaboratewithteam .semiflex div:last-child {
    margin-bottom: 20px;
  }
  .spacesection img {
    margin: auto;
  }
  .spacesection {
    text-align: center !important;
  }
  .mgap img {
    margin: auto;
    margin-top: 50px;
  }
  .threesteps img {
    margin: auto;
  }
  .templates .allflex {
    display: flex;
  }
  .templates .allflex div {
    width: 48%;
  }
  .text-center {
    text-align: center !important;
  }
  .mgap {
    text-align: center !important;
  }
  .threesteps .row {
    display: flex;
    align-items: center;
  }
  .threesteps .row .col-md-6 {
    width: 50%;
  }
  .threestepsdetail.allflex {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding-top: 0;
  }
  .threestepsdetail div {
    width: 48%;
  }
  .threestepsdetail {
    margin-top: 60px;
  }
  .threesteps .col-md-6 div:last-child {
    margin-bottom: 0 !important;
  }
  .threestepsdetail.allflex div:last-child p {
    margin-bottom: 40px;
  }
  .storefix .portalfeatures {
    text-align: center;
  }
}
