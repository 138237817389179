.security-header {
  margin-bottom: 12px;
  height: 40px;
  margin-top: -8px;
}
.security-tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
}
.security-tooltip img {
  margin-bottom: 1px;
  margin-left: 0px;
}
.security-qr-controls .security-noqrcode-text {
  color: #bcc1ce !important;
  margin-right: 0px !important;
}

.security-tooltip .security-tooltipsubheader {
  visibility: hidden;
  width: 305px;
  background-color: #000000;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -1px;
  left: 159%;
  padding: 14px 30px 14px 22px;
  opacity: 0.8 !important;
  font-size: 12px;
  line-height: 21px;
  display: grid;
  text-align: left !important;
  transition: 0.3s;
}

.security-tooltip .security-tooltipsubheader::after {
  content: "";
  position: absolute;
  top: 14%;
  right: 100%;
  margin-top: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.security-tooltip:hover .security-tooltipsubheader {
  visibility: visible;
}

.security-header h1 {
  font-size: 24px;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-right: 6px;
  color: #1b2952;
  line-height: 33px;
}
.my-security-page {
  width: 100%;
  /* margin-top: -8px !important; */
}
.security-spinner {
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0px;
  opacity: 1;
  min-height: 75vh;
}
.security-firstdiv {
  margin-bottom: 20px;
  padding: 25px 30px 20px 30px;
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}

.security-firstdiv-head {
  margin-bottom: 26px;
}

.security-seconddiv {
  margin-bottom: 20px;
  padding: 28px 30px 38px 30px;
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}

.security-thirddiv {
  padding: 0px;
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}

.security-firstdiv h1,
.security-seconddiv h1,
.security-thirddiv h1 {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 6px;
  line-height: 23px;
}
.security-seconddiv h1 {
  margin-bottom: 4px;
}

.security-firstdiv p,
.security-seconddiv p,
.security-thirddiv p {
  margin-bottom: 0px;
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  text-align: left !important;
  line-height: 17px !important;
}

.security-firstdiv .changes-save {
  width: 100px;
  height: 40px;
  border: none;
  color: white;
  background-color: #bcc1ce;
  border-radius: 3px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  padding: 11px 33px 11px 33px;
}

.twofa-steps p {
  line-height: 24px;
}

.security-firstdiv .security-twofa-enable {
  width: 100px;
  height: 40px;
  border: none;
  color: white;
  background-color: #3064f9;
  border-radius: 3px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  /* margin-left: 20px; */
  padding: 12px;
  transition: 0.5s;
  line-height: 18px;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
  }
}
.security-firstdiv .security-twofa-enable img {
  margin-top: -4px;
}
/* .security-firstdiv .security-twofa-enable:hover {
  background-color: #134bec;
} */

.security-seconddiv .security-twofa-enable {
  width: 100px;
  height: 40px;
  border: none;
  color: white;
  background-color: #3064f9;
  border-radius: 3px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  padding: 11px 26px 11px 26px;
  transition: 0.5s;
  line-height: 18px;
}
.security-seconddiv .security-twofa-enable:hover {
  background-color: #134bec;
}

.security-thirddiv .security-signout-all {
  width: 200px;
  height: 40px;
  border: none;
  color: #bcc1ce;
  background-color: white;
  border-radius: 3px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  border: 1px solid #c8cedb;
  padding: 10px 10px 8px;
  line-height: 18px;
}

.twofa-para {
  line-height: 20px !important;
}

.security-passwordvarification {
  display: flex;
}

.security-passwordvarification .input-password-set {
  width: 51.3%;
  padding: 0px 20px 0px 0px;
}

.security-passwordvarification .password-set-contain {
  width: 50%;
  padding: 20px;
  padding-top: 23px;
}

.input-password-set h4 {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 9px;
  line-height: 17px;
}

.input-password-set input {
  height: 50px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  color: #1b2952;
  margin-bottom: 17px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  position: relative;
  padding: 18px 50px 17px 20px;
}
/*.input-password-set .security-eye-close{
    background: url(../images/signup/secure.png) 97.5%/10% no-repeat;
    background-size: auto;
}

.input-password-set .security-eye-open{
    background: url(../images/signup/eyeopen.png) 97.5%/10% no-repeat;
    background-size: auto;
    opacity: 50%;
}*/

.input-password-set input:hover,
.input-password-set input:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
}

.forminputfield {
  position: relative;
}
.eyeopenclose {
  position: absolute;
  top: 11px;
  right: 20px;
  cursor: pointer;
}
/* .eyeopenclose .eyeopen{
    opacity: 0.4;
} */

.new-loader-qr {
  border: 2px solid #bcc1ce;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 24px !important;
  height: 24px !important;
  animation: spin 0.6s linear infinite;
}

.new-red-loader {
  border: 2px solid #ff4848;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px !important;
  height: 16px !important;
  animation: spin 0.6s linear infinite;
}

.password-set-contain h2 {
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 18px;
  line-height: 20px;
}

.required-contain-filled {
  width: 100px;
  height: 3px;
  background-color: #e3e6f1;
  margin-right: 10px;
}

.required-contain-correct {
  width: 100px;
  height: 3px;
  background-color: #52cb86;
  margin-right: 10px;
}

.security-contain-list {
  list-style-type: none;
  position: relative;
  margin-bottom: 0px !important;
  padding: 5px 0px 0px 20px;
}

.security-contain-list .ticklist {
  color: #bcc1ce;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 40px;
  text-decoration: line-through;
}

.security-contain-list .notticklist {
  color: #7782a1;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  line-height: 40px;
}

ul.security-contain-list .ticklist::before {
  content: url("../../assets/images/dashboard/analytics-tick.png");
  position: absolute;
  left: 0px;
  opacity: 0.4;
}

ul.security-contain-list .notticklist::before {
  content: url("../../assets/images/dashboard/cms/dot.png");
  position: absolute;
  left: 0px;
  opacity: 0.5;
}

/* second div */

.twofa-steps {
  display: flex;
  margin-top: 29px;
  /* margin-bottom: 10px; */
}

.twofa-steps h2 {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #bcc1ce;
  line-height: 16px;
  margin-bottom: 10px;
}

.security-stepone .stepsnumber {
  margin-left: 40px;
  margin-top: -10px;
}

.security-stepone {
  border: 1px solid #edf0f5;
  padding: 38px 10px 38px 38px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  flex: 1;
  height: 140px;
}
.security-steptwo .stepsnumber {
  margin-left: 31px;
}
.security-steptwo {
  border: 1px solid #edf0f5;
  padding: 39px 19px 41px 31px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  flex: 1;
  margin: 0px 20px;
  height: 140px;
}
.security-stepthree .stepsnumber {
  margin-left: 30px;
}
.security-stepthree {
  border: 1px solid #edf0f5;
  padding: 41px 19px 43px 31px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  flex: 1;
  height: 140px;
}

/* third div */

.thirddivhead {
  border-bottom: 1px solid #f1f2f8;
  padding: 25px 30px 19px 30px;
  display: flex;
  justify-content: space-between !important;
}

.security-tablehead {
  display: flex;
  justify-content: space-between;
  padding: 11px 30px 12.5px 30px;
  margin: 0px;
  border-bottom: 1px solid #f1f2f8;
  padding-left: 1.8%;
  padding-right: 1.8%;
}

.security-tablehead p {
  font-size: 12px;
  color: #bcc1ce;
  line-height: 16px !important;
}

.security-validate-input {
  border-color: #ff4848 !important;
}
.security-tabledata:hover {
  background-color: #f8f9fd;
}
.security-tabledata {
  display: flex;
  justify-content: space-between;
  padding: 35px 30px 35px 30px;
  margin: 0px;
  border-bottom: 1px solid #f1f2f8;
  align-items: center;
  transition: 0.5s;
  padding-left: 1.8%;
  padding-right: 1.8%;
}

.security-tabledata p {
  font-size: 14px;
  color: #1b2952;
  line-height: 18px !important;
}
.security-tabledata p::first-letter {
  text-transform: capitalize;
}

.System {
  /* min-width: 200px;
  max-width: 200px; */
  width: 18.91%;
}

.Browser {
  /* width: 150px; */
  width: 17.83%;
}

.Location {
  /* width: 150px; */
  width: 17.1%;
}

.current {
  width: 120px;
}

.First-seen {
  /* width: 200px; */
  width: 19.87%;
}

.Last-seen {
  width: 23.64%;
}

.Location span {
  font-size: 12px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}

.Location p {
  margin-bottom: 0;
}

.current {
  color: #52cb86 !important;
}

/* modal */
.security-code-list {
  background-color: #f8fafe;
  border: 1px solid #edf0f5;
  border-radius: 3px;
  padding: 18px 20px 18px 20px;
  display: flex;
  align-items: center;
  height: 150px;
  position: relative;
  justify-content: center;
}

.modal-recovery-key .modal-body {
  padding: 0px;
  border-radius: 6px;
}

.security-crossmodal img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.security-code-popup-body {
  padding: 0px 20px 29px 20px;
}

.security-code-popup-body h4 {
  font-size: 18px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-top: -2px;
  margin-bottom: 0px;
  line-height: 25px;
}

.security-code-popup-body h6 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  line-height: 20px;
  margin-bottom: 25px;
  /* margin-top: 12px; */
  margin-top: 17px;
}

.security-code-popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1;
  padding: 15px 20px;
}

.security-code-popup-footer button:nth-child(1) {
  background-color: #fff;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  color: #7782a1;
  font-size: 14px;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  padding: 10px;
}

.security-code-popup-footer button:nth-child(2) {
  background-color: #3064f9;
  border: 1px solid #3064f9;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 13px;
  transition: 0.5s;
  font-family: "Axiforma-Regular";
  padding: 10px;
}

.security-code-controls {
  position: absolute;
  bottom: 18px;
  right: 20px;
}

.security-code-controls img {
  margin-right: 20px;
  cursor: pointer;
}

.security-code-controls svg {
  cursor: pointer;
}

.security-code-xx {
  font-size: 20px;
  font-family: "Axiforma-Bold";
  color: #7782a1;
  margin-bottom: -5px;
}

/* second modal */

.security-scan-code-list {
  background-color: white;
  border: 1px solid #edf0f5;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  height: 160px;
  position: relative;
  justify-content: center;
}

.security-qr-code-list {
  background-color: white;
  border: 1px solid #edf0f5;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  height: 160px;
  position: relative;
}

.qrcode-div {
  width: 120px;
}
.security-without-qr {
  background-color: #f8fafe;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.security-qr-controls {
  margin-left: 30px;
}

.security-qr-controls h1 {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}

.security-qr-controls p {
  font-size: 14px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  margin-bottom: 0px;
  margin-right: 10px;
}

.security-qr-controls svg {
  margin-top: 3px;
  opacity: 0.5;
  cursor: pointer;
}

.cancle-not-visible {
  visibility: hidden !important;
}

.security-code-input-head {
  margin-bottom: 35px !important;
}

.security-inputbox-div h2 {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 8px;
}
.security-enter-code:focus {
  border: 1px solid #3064f9 !important;
}
.security-enter-code {
  width: 60px;
  height: 50px;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}

.security-code-red .security-redinput {
  font-size: 12px;
  margin-left: 6px;
  color: #ff4848;
  margin-bottom: 0px;
}
.security-redinputs {
  font-size: 12px !important;
  margin-left: 6px;
  color: #ff4848 !important;
  margin-bottom: 0px;
}
.security-validation {
  margin-top: -10px;
  margin-bottom: -10px;
}
.security-code-red {
  align-items: center;
  margin-bottom: 6px;
}

.security-disable2fa {
  padding: 30px 58px 18px 57px;
}

.security-disable2fa h4 {
  text-align: center;
  font-size: 18px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  line-height: 25px;
  margin-bottom: 15px;
}

.security-disable2fa h6 {
  text-align: center;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  color: #7782a1;
  line-height: 18px;
}

.disable2fa-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
}

.disable2fa-button button:nth-child(1) {
  width: 140px;
  height: 44px;
  border: 1px solid #c8cedb;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  padding: 10px;
}

.disable2fa-button button:nth-child(2) {
  width: 140px;
  height: 44px;
  border: 1px solid #ff4848;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  color: #ff4848;
  font-family: "Axiforma-Regular";
  margin-left: 20px;
  padding: 10px;
  transition: 0.5s;
}
.disable2fa-button button:nth-child(2):hover {
  color: white;
  background-color: #ff4848;
}

.disable2fa-button button:nth-child(2):hover .new-red-loader {
  border: 2px solid #fff !important;
  transition: 0.5s;
  border-top: 2px solid transparent !important;
}
#btndisable2fid:hover {
  color: white;
  background-color: #ff4848;
}

#btndisable2fid:hover .new-red-loader {
  border: 2px solid #fff !important;
  transition: 0.5s;
  border-top: 2px solid transparent !important;
}
.mobile-view-lastSeenTable {
  display: none;
}

.security-mob-save-btn {
  display: none;
}

.security-enable-mob-btn {
  display: none;
}

.security-twofa-enableddiv {
  border: 1px solid #edf0f5;
  padding: 28px 30px;
  border-radius: 3px;
  margin-top: 27px;
  /* margin-bottom: 10px; */
}

.security-twofa-enableddiv .security-twofa-tick {
  align-items: center;
}

.security-twofa-tick svg {
  margin-right: 10px;
}

.security-twofa-disable {
  width: 100px;
  height: 40px;
  color: #bcc1ce;
  background-color: white;
  border-radius: 3px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  border: 1px solid #c8cedb;
  padding: 10px;
  cursor: pointer;
}
.security-twofa-disable:hover {
  background-color: #c8cedb;
  color: white;
}

.last-enable-text {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  color: #bcc1ce !important;
  margin-right: 20px;
}

.security-web-enablebtn {
  display: flex;
}

.security-websave-btn {
  display: flex;
}
.varify-code-error {
  border: 1px solid #ff8e8e;
}
.btnloader {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.modal-recovery-key .modal-content {
  border: none !important;
}
.required-contain-semicorrect {
  width: 100px;
  height: 3px;
  background-color: #ff8424;
  margin-right: 10px;
}
.required-contain-semicorrectlight {
  width: 100px;
  height: 3px;
  background-color: #ffc814;
  margin-right: 10px;
}
.required-contain-notcorrect {
  width: 100px;
  height: 3px;
  background-color: #fc0404;
  margin-right: 10px;
}
.security-myprofile-shadow {
  position: relative !important;
}
.security-thirddiv .security-signout-all:hover {
  background-color: #c8cedb;
  color: white;
}
.security-myprofile-shadow .security-spinner{
  min-height: 75vh;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 1px solid #E3E6F1;
}

/* mobile view */
@media (max-width: 1200px) {
  .security-spinner {
    min-height: 85vh;
  }
  .security-tooltip img{
    margin-left: 5px;
  }
  .div-security-page {
    padding: 0px 15px;
  }
  .security-spinner {
    border-radius: 0px;
  }
  .security-myprofile-shadow {
    position: unset !important;
  }
  .modal-recovery-key .modal-content {
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }
  .security-twofa-enableddiv {
    margin-bottom: 0px;
  }
  .last-enable-text {
    justify-content: center;
    margin-top: 20px;
    margin-right: 0px;
  }
  .security-passwordvarification {
    display: grid;
  }

  .security-passwordvarification .input-password-set {
    width: 100%;
    padding: 0px;
  }

  .twofa-steps {
    display: grid;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .security-steptwo {
    margin: 10px 0px;
    padding: 38px 24px 38px 21px;
    height: 140px;
  }

  .security-passwordvarification .password-set-contain {
    width: 100%;
    padding: 0px;
    padding-top: 10px;
  }

  .web-view-lastSeenTable {
    display: none;
  }

  .security-web-enablebtn {
    display: none;
  }

  .security-websave-btn {
    display: none;
  }

  .mobile-view-lastSeenTable {
    display: grid;
    border-bottom: 1px solid #e3e6f1;
    padding: 37.5px 15px 0px 15px;
  }

  .required-contain-filled {
    width: 25%;
  }

  .required-contain-correct {
    width: 25%;
  }

  .security-mob-save-btn {
    display: block;
  }

  .changes-save-mobview {
    width: 100%;
    height: 50px;
    border: none;
    color: white;
    background-color: #bcc1ce;
    border-radius: 3px;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    margin-top: 17px;
    padding: 15px 20px 15px 20px;
  }

  .security-enable-mob-btn {
    display: block;
  }

  .security-twofa-enable-mobbutton {
    width: 100%;
    height: 50px;
    border: none;
    color: white;
    background-color: #3064f9;
    border-radius: 3px;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    margin-top: 20px;
  }

  .security-twofa-disable-mobbutton {
    width: 100%;
    height: 50px;
    color: #bcc1ce;
    background-color: white;
    border-radius: 3px;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    margin-top: 20px;
    border: 1px solid #c8cedb;
  }

  .security-seconddiv .security-twofa-updatemob-text,
  .security-firstdiv .security-twofa-updatemob-text {
    font-size: 12px !important;
    color: #bcc1ce !important;
    text-align: center !important;
    line-height: 12px !important;
    margin-top: 20px;
  }
  .security-firstdiv .security-twofa-updatemob-text {
    margin-bottom: -7px;
  }

  .security-twofa-tick svg {
    margin-right: 15px;
  }

  .security-twofa-enableddiv {
    padding: 19px 15px 27px 15px;
    margin-top: 16px;
  }

  .thirddivhead {
    display: grid;
    justify-content: unset !important;
    padding: 25px 15px 20px 15px;
  }

  .security-thirddiv .security-signout-all {
    width: 100%;
    margin-top: 13px;
    height: 50px;
    font-size: 16px;
  }

  .security-mob-tbhd {
    font-size: 12px !important;
    color: #bcc1ce !important;
  }

  .security-mob-tbtr {
    font-size: 14px !important;
    color: #1b2952 !important;
    margin-bottom: 0px !important;
  }

  .security-tbrow {
    margin-bottom: 26px;
  }

  .security-tbrow:last-child {
    margin-bottom: 39.3px;
  }
  .mob-latest-login {
    font-size: 14px !important;
    color: #52cb86 !important;
  }

  .security-seconddiv {
    padding: 25px 15px 30px 15px;
  }

  .security-stepone {
    padding: 27px 20px 26px 30px;
    height: 140px;
  }

  .security-stepthree {
    padding: 38px 22px 38px 20px;
    height: 140px;
  }

  .security-firstdiv {
    padding: 25px 15px 29px 15px;
  }

  .security-header {
    margin-top: 19px !important;
    margin-bottom: 18px;
    height: inherit;
  }

  .security-thirddiv {
    margin-bottom: 20px;
  }

  .modal-recovery-key .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 27px 0px;
  }

  .disable2fa-button {
    padding-bottom: 0px;
    flex-direction: column-reverse;
  }

  .disable2fa-button button:nth-child(2) {
    width: 100%;
    background-color: #ff4848;
    color: white;
    font-family: "Axiforma-Regular";
    margin-left: 0px;
    height: 50px;
    font-size: 14px;
  }

  .disable2fa-button button:nth-child(1) {
    height: 20px;
    border: none;
    color: #7782a1;
    margin-top: 40px;
    font-size: 14px;
    padding: 0px;
  }

  .security-crossmodal img {
    right: 20px;
    top: 40px;
  }

  .disable-buttons {
    padding: 0px 74px;
  }

  .security-code-popup-body {
    padding: 0px 20px 20px 20px;
  }

  .security-code-popup-body h4 {
    text-align: center;
  }

  .security-code-popup-body h6 {
    text-align: center;
  }

  .security-code-popup-footer {
    justify-content: unset;
    flex-direction: column-reverse;
    border-top: none;
    padding: 0px 20px;
  }
  /* .security-code-popup-footerone {
        justify-content: unset;
        flex-direction: column-reverse;
        border-top: none;
        padding: 0px 34px;
    } */

  .security-code-popup-footer button:nth-child(1) {
    border: none;
    height: 20px;
    margin-top: 40px;
  }

  .security-code-popup-footer button:nth-child(2) {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 14px;
  }

  .security-inputbox-div h2 {
    display: none;
  }
  /* .security-code-popup-footerone button:nth-child(1) {
        border: none;
        height: 20px;
        margin-top: 34px;
    }

    .security-code-popup-footerone button:nth-child(2) {
        width: 100%;
        height: 50px;
    } */

  .security-code-red {
    justify-content: center;
  }

  .security-thirddiv p {
    text-align: left !important;
    margin-bottom: 6px;
  }

  .qrcode-div {
    width: 140px;
    margin: auto;
    margin-bottom: 30px;
  }

  .security-qr-code-list {
    border: none;
    display: grid;
    padding: 0px;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .security-qr-controls {
    margin-left: 0px;
  }

  .security-header h1 {
    font-size: 20px !important;
    line-height: 28px;
  }
  .security-tbrow span {
    font-size: 14px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
  }
  .security-firstdiv,
  .security-seconddiv,
  .security-thirddiv {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  }
  .security-stepone .stepsnumber {
    margin-left: 30px;
  }
  .security-steptwo .stepsnumber {
    margin-left: 21px;
  }
  .security-stepthree .stepsnumber {
    margin-left: 20px;
  }
  .security-enter-code {
    width: 50px;
    height: 50px;
    margin-right: 7px;
  }
  .security-inputbox-div input:last-child {
    margin-right: 0px;
  }
  .password-set-contain p:last-child {
    margin-right: 0px;
  }
  .security-inputbox-div {
    display: grid;
    justify-content: center;
  }
  .security-inputbox-div div {
    display: flex;
    justify-content: center;
  }

  .security-code-popup-footer button:nth-child(1) {
    font-size: 16px;
    padding-top: 0px;
  }
  .modal-firstcancle {
    margin-top: 34px !important;
  }
}
@media (max-width: 500px) {
  .security-tooltip .security-tooltipsubheader {
    width: 180px;
    top: -8px;
  }
}

@media (max-width: 375px) {
  .security-enter-code {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 500px) {
  .security-tooltip .security-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 33px;
    left: -265%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
  }
  .security-tooltip .security-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 75px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}
@media (max-width: 400px) {
  .security-steptwo,
  .security-stepone,
  .security-stepthree {
    height: 170px;
  }
}
