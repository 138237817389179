.save-menu-disabled {
  background-color: #bcc1ce;
  cursor: not-allowed !important;
}
.menubuilderbg {
  position: relative;
  min-height: 78vh;
  padding-bottom: 50px;
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #e3e6f1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  /* overflow: hidden; */
}
.menufixback {
  box-shadow: 0px 2px 4px rgb(188 193 206 / 50%) !important;
  background-color: #fff !important;
  padding: 20px !important;
  height: auto !important;
  position: absolute !important;
  left: auto !important;
  top: auto !important;
  font-size: 12px;
  z-index: 12 !important;
  bottom: 126%;
}
.menufixback .mobileok {
  width: 100% !important;
  margin-top: 20px;
  border-radius: 3px;
}
.primarymenufixback {
  box-shadow: 0px 2px 4px rgb(188 193 206 / 50%) !important;
  background-color: #fff !important;
  padding: 20px !important;
  height: auto !important;
  position: absolute !important;
  left: auto !important;
  top: auto !important;
  font-size: 12px;
  z-index: 12 !important;
  bottom: 126%;
}
.primarymenufixback .mobileok {
  width: 100% !important;
  margin-top: 20px;
  border-radius: 3px;
}
/* .menu-select-section .right-panel-gap{
  overflow-x: clip;

} */
.menubuilderbg .newspinner {
  border-radius: 6px !important;
}
.save-menu-enable {
  background-color: #3064f9;
  cursor: not-allowed !important;
  cursor: pointer !important;
  transition: 0.5s;
}
.save-menu-enable:hover {
  transition: 0.5s;
  background-color: var(--custom_color5) !important;
  color: var(--custom_color4) !important;
}
.save-menu-btn {
  height: 40px;
  width: 100px;
  border: none;
  font-family: "Axiforma-Regular";
  color: white;
  border-radius: 3px;
  font-size: 14px;
  margin-left: 20px;
}
.menu-header-rightdiv .consent-setting {
  margin-right: 0px;
  margin-left: 20px !important;
}

.menubuilder-left {
  width: 50%;
  min-width: 50%;
  padding-left: 30px;
  padding-right: 20px;
  /* padding-right: 10px;
    height: 78vh; */
  /* overflow: auto; */
}
.menubuilder-left::-webkit-scrollbar {
  width: 2px !important;
}
.menubuilder-right {
  width: 50%;
  min-width: 50%;
  padding: 56px 30px 60px 22px;
  border-left: none;
}
.menu-default-text {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  color: #bcc1ce;
  margin-bottom: 0px;
  margin-right: 32px;
}
.menubuilder-main {
  display: flex;
  position: relative;
}
.menubuilder-primary {
  padding: 0px 0px 30px;
  border-bottom: 1px solid #e3e6f1;
  /* border-top: 1px solid #E3E6F1; */
}
.menu-listfirst {
  border-bottom: 1px solid #e3e6f1;
}
.menubuilder-listtype {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 28px 0px 29px 0px;
}

.menu-accrdn-p {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 0px;
}

.menu-accrdn-p .accordian-drop-icon {
  position: absolute;
  top: 30px;
  right: 0px;
}

.menubuilder-listtype a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.menubuilder-listtype a img {
  position: absolute;
  top: 30px;
  right: 0px;
}
.menu-eye-visible {
  position: absolute;
  right: 40px;
  top: 28px;
  stroke: #bcc1ce;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
  transition: 0.5s;
}
.menubuilder-listtype:hover .menu-eye-visible {
  opacity: 1;
  transition: 0.5s;
}

.menu-section-hidden .menu-eye-visible,
.menu-section-hidden:hover .menu-eye-visible {
  stroke: #1b2952;
  opacity: 1;
}
.menubuilder-primary .menuBuilder-primary-head {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: baseline;
}
.menubuilder-primary .menuaccordian-Maindiv {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 0px;
  align-items: center;
}

.menubuild-master-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e6f1;
  margin-top: 30px;
  padding-bottom: 28px;
}
.menubuilder-primary .bbsetting-tooltip {
  margin-top: 0px;
}
.menubuilder-primary .bbsetting-tooltipsubheader {
  margin-top: 0px;
}
.menubuild-master-toggle .menuBuilder-enable-head {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin: 0px;
}
.menubuild-preview {
  background-image: linear-gradient(#32a7ff, #5a6ffb, #7757ff);
  min-height: 642px;
  width: 100%;
  /* height: 100%; */
  z-index: 1;
  position: absolute;
  top: 0px;
}
.menubar-preview-screen::-webkit-scrollbar {
  width: 0px !important;
}
.menubar-preview-screen {
  height: 651px;
  width: 310px;
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  overflow-y: auto;
  overflow-x: hidden;
  border: 4px solid #e3e6f1;
  border-radius: 20px;
  /* background-image: url(../../../assets/images/dashboard/menubuilder-disable.png); */
  background-size: auto;
  /* background-position: 0px 30px; */
  position: relative;
}
/* .menupreview-first{
        background-image: url(../../../assets/images/dashboard/bottombar-canvas.png);
        height: 648px;
        background-size: inherit;
        background-repeat: no-repeat;
        background-position: bottom;
    } */

.menuselect-radio-div {
  display: grid;
}
.menubuilder-radio-button {
  cursor: pointer;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 28px;
  display: flex;
  align-items: center;
}
.menubuilder-radio-button input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.menubuilder-radio-button input {
  font-size: 16px;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.menubuilder-radio-button__input {
  opacity: 0;
  position: absolute;
}

.menubuilder-radio-button__control {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 13px;
  vertical-align: middle;
  background-color: inherit;
  color: #3064F9;
  border: 2px solid #bcc1ce;
  border-radius: 24px;
}

.menubuilder-radio-button__input:checked
  + .menubuilder-radio-button__control:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 12px;
}

.menubuilder-radio-button__input:checked + .menubuilder-radio-button__control {
  border-color: #3064F9;
  background-color: #3064F9;
}

.menubuilder-radio-button__control {
  transform: scale(0.88);
}
.menu-preview-list {
  list-style-type: none;
  padding: 25px 0px 25px;
  margin: 0px 13px;
}
.primarymenu-border {
  border-bottom: 1px solid #74a4ff;
}
.menu-preview-listType {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-preview-listType:last-child {
  margin-bottom: 0px;
}
.munu-iconType-name {
  font-family: "Axiforma-Regular";
  color: white;
  font-size: 11px;
  word-break: break-word;
  text-align: center;
}
/* .secondry-menu-preview-list ul {
  list-style-type: none;
  padding: 15px 15px;
} */
/* .secondry-menu-preview-list ul li {
  margin-bottom: 25px;
} */
/* .secondry-menu-preview-list ul li:last-child {
  margin-bottom: 0px;
} */
/* .secondry-menu-preview-list ul li span {
  font-family: "Axiforma-Regular";
  color: white;
  font-size: 13px;
} */
.menubuilder-previewhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 15px 0px;
  /* border-bottom: 1px solid #74A4FF; */
}

.menubuilder-previewhead p {
  margin-bottom: 0px;
  font-size: 15px;
  color: white;
  font-family: "Axiforma-Bold";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-builderhead-cross {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}
/* .secondry-menu-preview-list li img, */
.menu-preview-listType img {
  margin-right: 12px;
}
/* .menu-iconcreatediv{
    padding: 30px 0px;
    border-bottom: 1px solid #E3E6F1;
  } */
.menu-createhead {
  display: flex;
  justify-content: space-between;
}
.menu-title-icon {
  display: flex;
  color: #1b2952;
  font-family: "Axiforma-Regular";
  font-size: 16px;
  word-break: break-word;
  width: calc(100% - 90px);
}

.menuinput-field label {
  display: grid;
}
.menu-texticon-div {
  display: grid;
}
.menu-texticon-div .menu-web-name {
  width: 100%;
  margin-bottom: 10px;
}
/* .menu-itemtype{
    margin-bottom: 17px;
  } */

.menu-itemtype .search-dropdown .dropdown-toggle {
  margin-bottom: 17px;
  padding: 14px 15px 17px 15px;
  background-position: 98.2% !important;
}
.menu-itemtype .dropdown-toggle::after {
  display: none !important;
}
.menuiconpictext {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.menuurltype {
  margin-bottom: 10px;
}
.menu-texticon-div .menu-icontype {
  margin-left: 0px;
}
.menubuilder-icon-field {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.menubuilder-icon-field p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1b2952;
  font-size: 13px !important;
  margin-left: 7px;
  margin-bottom: 0px;
}
.menu-Selected_icon {
  background-color: #edf0f5;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 7px;
  margin-right: 10px !important;
}
.menubuilder-icon-field .menu-crosssvg{
  top: 18px !important;
}
.menuversion-page {
  position: absolute;
  left: 15px;
  bottom: 20px;
  width: 100%;
  vertical-align: bottom;
  color: #c2e1ff;
  font-size: 10px;
  font-family: "Axiforma-Regular";
  padding: 0px 25px 0px 0px;
  display: flex;
  justify-content: center;
}
.menuversion-page-center {
  display: flex;
  justify-content: center;
}
.menubuildervaluegrid {
  display: grid;
}
.menu-inputtype-label {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 7px;
}
.menu-input-icon-label {
  width: 100%;
  margin-bottom: 0px;
}
.menu-bottom-default-select-div {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: 10px;
}
.menubuilder-icon-field img {
  background-position: 50% !important;
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
}
.menuinput-field {
  padding: 25px 0px 17px;
  position: relative;
}
.menubuilder-settings .bottombar-theme-options .color_input {
  position: relative;
}
.menubuilder-settings .bottombar-theme-options .color_input p {
  margin-left: 5px !important;
}
.menu-crosssvg {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 46px;
}
.menu-create-input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  width: 100%;
  padding: 15px 15px 15px 15px;
  transition: 0.5s;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
}
.disable-menu {
  opacity: 0.3;
}
.menu-create-input:hover,
.menu-create-input:focus {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.menuinput-field label {
  margin-bottom: 17px;
}
.menuinput-field label span {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  margin-bottom: 10px;
}
.menu-duplicate-delete {
  position: absolute;
  margin-top: 0px;
  right: 0px;
  bottom: -8px;
}
.delete-menu-list-copy {
  padding-bottom: 0px;
  text-align: right;
}
.delete-menu-list-copy a {
  margin-left: 25px;
}
.addmoreitem {
  color: #3064f9;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  cursor: pointer;
  margin-top: 0px;
  /* margin-bottom: 24px; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e6f1;
  padding: 27.5px 0px 28px;
}

.httptext {
  border-top: 1px solid #bcc1ce;
  border-bottom: 1px solid #bcc1ce;
  border-left: 1px solid #bcc1ce;
  border-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-left: 12px !important;
  width: 130px;
  font-family: "Axiforma-Regular2";
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 50px;
}
.orderselect-setting {
  fill: #7782a1;
}
.menubuild-second-toggle {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e6f1;
  /* margin-top: 30px; */
  padding-bottom: 28px;
  /* padding-top: 28px; */
  /* border-top: 1px solid #E3E6F1; */
}
.menubuild-second-toggle .menubuild-second-toggle-head {
  font-size: 16px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin: 0px;
}
.menu-icon-default{
  width: 18px;
  height: 20px;
  display: flex;
  padding: 3px;
  background-color: #EDF0F5;
  margin-right: 0px;
}
.menu-duplicate-delete span {
  cursor: pointer;
}
.disablealldiv {
  opacity: 0.4;
  cursor: not-allowed;
}
.nodata-available {
  padding: 30px 0px 30px !important;
  margin: 0px 15px;
}
.nodata-available li {
  background-color: #EFEFEF;
  border-radius: 10px;
  height: 8px;
  /* opacity: 0.2; */
  margin-bottom: 31px !important;
  list-style-type: none;
}
.nodata-available li:last-child {
  margin-bottom: 0px !important;
}
.menu-header-rightdiv {
  display: flex;
  align-items: center;
  gap: 20px;
}
.menubuilder-settings {
  width: 50%;
  min-width: 50%;
  padding-left: 30px;
  padding-right: 20px;

  /* padding-right: 10px;
    height: 78vh; */
  /* overflow-y: auto;
    overflow-x: hidden; */
}

.menubuilder-settings::-webkit-scrollbar {
  width: 2px !important;
}
.menubuilder-settings-head {
  padding: 19px 0px 19px 0px;
  border-bottom: 1px solid #e3e6f1;
  margin-bottom: 33px;
}
.menuBuilder-setting-descrption {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 0px;
}
.menuBuilder-setting-text {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  color: #1b2952;
  margin-bottom: 8px;
}

.menusettingicondiv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.menusettingicondiv .menusettingiconlabel {
  width: 50%;
  margin-bottom: 0px;
  text-align: left !important;
}
.menusettingiconpick {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.select_type_or {
  font-size: 13px;
  color: #bcc1ce;
  margin: 30px 10px 0px 10px;
  font-family: "Axiforma-Regular";
}
.menusettingiconpick img {
  background-position: 50% !important;
  max-height: 38px;
  width: 38px;
  min-width: 38px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}
.menuIcon-Name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1b2952;
  font-size: 13px !important;
  margin-left: 7px;
  margin-bottom: 0px;
}
.menuIcon-Name-upload{
    color: #BCC1CE;
    margin-bottom: 0px;
    font-size: 13px
}
.menubuilder-head-icons{
  max-width: 200px;
   max-height: 26px

}
.setting-menubuilder-radio {
  position: absolute;
  right: 0px;
  margin-bottom: 3px;
}
.menuBuilder-logolabel {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
  /* cursor: pointer; */
}
.background-setting-div {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}
.menusetting-toggle-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.menusetting-haptic-toggle-div{
  display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 0px;

}
.menusetting-toggle-div-head {
  font-size: 13px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}
.menu-tile-shape-selectdiv {
  display: flex;
  gap: 30px;
}
.menusetting-toggle-div svg {
  margin-left: 0px;
  cursor: pointer;
}
.menu-column-count {
  margin-bottom: 20px;
}
.menuBuilder-column-count-input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  height: 50px;
  width: 100%;
  padding: 0px 15px 0px 15px;
  transition: 0.5s;
  outline: none;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  width: 100%;
}
.menuBuilder-column-count-input:hover {
  border: 1px solid #3064f9 !important;
  background-color: #f8fafe !important;
  transition: 0.5s;
}
.menuBuilder-theme-options-disabled.menuBuilder-iconcolorUpdate-div
  .color_input {
  background-color: #f8fafe;
  cursor: not-allowed;
  border: 1px solid #e3e6f1;
}
.menuBuilder-theme-options-disabled .color_input p {
  color: #7782A1 !important;
}
.menuBuilder-theme-options-disabled .color_input div {
  cursor: not-allowed !important;
}
.menuBuilder-column-label {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px;
  margin-bottom: 9px;
}
.setting-menuhead {
  font-size: 14px;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 30px;
  text-align: left !important;
}
.icon-menu-setting {
  padding: 0px 0px 30px 0px;
  border-bottom: 1px solid #e3e6f1;
}
.setting-secondrymenu {
  padding: 20px 0px 0px 0px;
}
.setting-firstrymenu {
  padding: 30px 0px 30px 0px;
  border-bottom: 1px solid #e3e6f1;
}
.imageshape-svg-notselect {
  fill: #fff;
  stroke: #e3e6f1;
}
.imageshape-svg-select {
  fill: #fff;
  stroke: #7782a1;
}
.menuBuilder-theme-background-color {
  display: flex;
  gap: 20px;
}
.menuBuilder-theme-icon-color {
  display: flex;
  gap: 20px;
}
.menuBuilder-theme-background-color .color_input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: 0.5s !important;
  position: relative;
}
.menuBuilder-iconcolorUpdate-div .color_input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: 0.5s !important;
  position: relative;
}
.menuBuilder-iconcolorUpdate-div {
  width: 100%;
  position: relative;
}
.menuBuilder-theme-options {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}
.menuBuilder-theme-options .color_input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: 0.5s !important;
  position: relative;
  width: 50%;
}
.menuBuilder-colorUpdate-div {
  width: 100%;
}
.menuBuilder-theme-label {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #7782a1;
  margin-bottom: 9px;
}
.background-setting-div .bottombar-theme-options {
  position: relative;
}
.background-setting-div .bottombar-theme-options .color_input:first-child {
  width: calc(100% - 20px);
}
/* .background-setting-div .bottombar-theme-options svg{
        position: absolute;
        top: 44px;
        right: 15px;
        cursor: pointer;
          } */
.menuhead-formob {
  display: none;
}
.menu-mob-btn {
  display: none;
}
.menu-grid-ul {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0px;
  margin: 0px 12px !important;
  gap: 10px;
}
.menu-grid-ul li {
  margin-bottom: 0px !important;
  list-style-type: none;
}
.grid-view-menudiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}
.menubuilder-img-fluid {
  width: 16px;
}
.grid-view-menudiv .menubuilder-img-fluid {
  margin-right: 0px;
  margin-bottom: 0px;
}
.size-for3 {
  height: 84px;
  width: 84px;
}
.second-menu-preview {
  padding-bottom: 51px !important;
}
.menuorder-parent {
  display: flex;
  border: 1px solid #e3e6f1;
  width: calc(100% - 153px);
  height: 60px;
  align-items: center;
  border-radius: 3px;
  padding: 0px 15px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.menuorder-parent-image {
  display: flex;
}
.menuorder-parent-image img {
  margin-right: 10px;
}
/* .menu-builderhead-cross svg{
  margin-left: 2px;
} */
.menuorder-parent-image p {
  margin-bottom: 0px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
}
.menu-header-text-input {
  width: calc(100% - 50px);
  height: 47px;
  border: none;
  padding: 12px 6px 11px 15px;
  outline: none;
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
}
/* .menu-header-text-input:hover .menu-header-text-input, .menu-header-text-input:focus .menu-header-text-input{
  background-color: #f8fafe;
    border-color: #3064f9 !important;
} */
.menuorder-main-div {
  padding: 20px 0px 0px;
}
.menuorder-childmaindiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.menuorder-childmaindiv .order-aero {
  margin-right: 6px;
}
.menuorder-childmaindiv .ordermob-aero {
  display: none;
}
.menuorder-child {
  display: flex;
  border: 1px solid #e3e6f1;
  width: calc(100% - 153px);
  height: 60px;
  align-items: center;
  border-radius: 3px;
  padding: 0px 15px;
  justify-content: space-between;
}
.child-preview-div {
  display: flex;
  align-items: center;
}
.child-preview-div p {
  opacity: 1 !important;
  margin-left: 10px;
}
.menu-preview-head-logo {
  display: flex;
  align-items: center;
  height: 26px;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.menu-nested-main-div .is-dragging{
  box-shadow: 0px 2px 6px 0px #0000000F;

}
.menu-nested-main-div .nestable-item-name {
  display: flex;
  border: 1px solid #e3e6f1;
  width: 100%;
  height: 60px;
  align-items: center;
  border-radius: 3px;
  padding: 0px 15px;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  margin-bottom: 0px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  color: #1b2952;
  cursor: move;
}

.menu-nested-main-div .menuNested .nestable-item-name::before {
  content: url("../../../assets/images/dashboard/bottom-bar/dots.png");
  margin-right: 15px;
  cursor: move;
  margin-top: 5px;
  z-index: 0;
  opacity: 1;
  position: absolute;
  right: 0px;
}

.menu-nested-main-div {
  max-width: calc(100% - 155px);
  padding: 0px 0px 30px;
}
.menuSorting-devider{
  border-bottom: 1px solid #E3E6F1;
  margin-bottom: 25px;
}

.menuNested .nestable-item.is-dragging:before {
  background: #f8fafe;
  border: 1px dashed #e3e6f1;
  border-radius: 3px;
  height: 60px;
}
.nestable-list .nestable-list {
  position: relative;
  right: -40px;
}

.nestable-list .nestable-list li::before {
  content: url("../../../assets/images/dashboard/cms/child-page.png");
  position: absolute;
  left: -22px;
  top: 18px;
}
.menu-itemtype .search-dropdown .dropdown-item img {
  position: absolute;
  left: 15px;
  top: 14px;
}
.primary-menu-nested-head {
  color: #1b2952;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  text-align: left !important;
}
.disabledicon-div {
  margin-right: 12px;
  width: 16px;
}
.secondry-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 31px;
}
.secondry-menu-toggle-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 31px;
}
.second-menu-wordpress-sync {
  color: #7782a1;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 0px;
  display: flex;
}
.menuBuilder-logolabel {
  font-size: 13px;
  font-family: "Axiforma-Regular";
  color: #7782a1;
}
.menu-title-icon-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.menu-builder-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.menuBuilder-secondmodal-cross{
  z-index: 9;
}
.menu-default-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0px;
  height: inherit;
  width: inherit;
  top: 0px;
  padding: 0px;
}
.menu-default-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #bcc7e6;
}

.menu-default-checkbox:checked ~ .menu-default-checkmark {
  background-color: #3064f9 !important;
}
.menu-default-checkbox:checked ~ .menu-default-checkmark:after {
  display: block;
}
.menu-default-checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #547df2;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}
.menu-default-check-text {
  font-family: "Axiforma-Regular";
  color: #7782a1;
  font-size: 13px !important;
  margin-bottom: 0;
  line-height: 17px;
}
.menu-checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -ms-user-select: none;
  user-select: none;
  padding-top: 3px;
}
.menubuilder-settings .color_input p {
  font-family: "Axiforma-Regular";
  font-size: 13px !important;
  color: #1B2952;
}

.menu-checkbox-container .newfeatureicon-div{
  position: absolute;
  right: -42px;
  top: 4px;
}

@media screen and (max-width: 1200px) {
  .menu-header-rightdiv {
    gap: 15px;
  }
  .menuBuilder-divnotrequired{
    display: none;
  }
  .menu-web-save-btn-parent {
    display: none;
  }
  .menu-parentDiv .app-dashboard-head {
    display: none;
  }
  .menu-builder-head {
    margin-bottom: 0px;
  }
  .menu-parentDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 15px 0px 15px;
  }

  .menu-accrdn-p .accordian-drop-icon {
    right: 15px;
  }
  .menubuilderbgspinner .newspinner {
    min-height: 78vh;
    position: relative;
  }
  .menubuilderbg {
    min-height: unset;
    margin: 15px;
    padding: 0px;
  }
  .menufixback {
    left: -14px !important;
  }
  .primarymenufixback {
    bottom: unset;
    left: -14px !important;
  }

  /* .menu-select-section .right-panel-gap{
      overflow-x: hidden;
      } */
  .bbsetting-tooltip .bbsetting-tooltipsubheader {
    text-align: left !important;
  }
  .primary-menu-nested-head {
    margin-bottom: 20px;
  }
  .menu-itemtype .search-dropdown .dropdown-toggle {
    background-position: 98.2% !important;
  }
  .menubuildervaluegrid {
    padding: 0px 15px;
  }
  .menubuilder-listtype {
    padding: 29px 15px 29px 15px;
  }
  .menu-texticon-div .menu-web-name {
    width: 100%;
    margin-bottom: 15px;
  }
  .nestable-list .nestable-list li::before {
    content: url("../../../assets/images/dashboard/cms/mobchild-page.png");
    position: absolute;
    left: -15px;
  }
  .menuBuilder-theme-icon-color,
  .menuBuilder-theme-background-color {
    display: grid;
  }
  .menuBuilder-theme-options .color_input {
    width: 100%;
  }
  .menu-nested-main-div {
    max-width: 100%;
    padding: 30px 15px;
  }
  .menuSorting-devider{
 margin-bottom: 0px;
  }
  .nestable-list .nestable-list {
    position: relative;
    right: unset;
  }
  .menubuilder-right {
    display: none;
  }
  .menuhead-forweb {
    display: none;
  }
  .menuhead-formob {
    display: block;
  }
  .menubuilder-left {
    width: 100%;
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px!important;
    height: unset;
  }
  .menu-texticon-div .menu-icontype {
    margin-left: 0px;
    margin-bottom: 12px !important;
  }
  .menu-texticon-div {
    display: grid;
  }
  .menubuilder-listtype a {
    left: -15px;
  }
  .menu-eye-visible {
    right: 47px;
    opacity: 1 !important;
  }
  .menu-texticon-div label {
    width: 100%;
  }
  .save-menu-btn {
    display: none;
  }
  .menu-mob-btn {
    display: block;
    padding: 5px 15px 20px 15px;
  }
  .save-menu-mob-btn {
    height: 50px;
    width: 100%;
    border: none;
    font-family: "Axiforma-Regular";
    color: white;
    border-radius: 3px;
    font-size: 16px;
  }
  .menu-drag-mob {
    margin-right: 20px;
  }
  .menubuild-master-toggle {
    padding: 30px 15px 28px;
    margin-top: 0px;
  }
  .menubuilder-primary {
    padding: 0px 15px 30.5px 15px;
  }
  /* .menu-iconcreatediv {
  padding: 30px 15px;
} */
  .addmoreitem {
    padding: 24px 15px 24px;
    margin: 0px;
  }
  .menubuild-second-toggle {
    padding: 0px 15px 28px 15px;
  }

  .menubuilder-settings {
    width: 100%;
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px!important;
    height: unset;
  }
  .menubuilder-settings-head {
    padding: 19px 15px 19px 15px;
    margin: 0px;
  }
  .icon-menu-setting {
    padding: 30px 0px;
    margin: 0px 15px;
  }
  .menusettingicondiv {
    display: grid;
  }
  .menusettingicondiv .menusettingiconlabel {
    width: 100%;
  }
  .select_type_or {
    color: #bcc1ce;
    margin: 18px 0px 15px;
    font-family: "Axiforma-Regular";
    text-align: left !important;
  }
  .background-setting-div {
    display: grid;
    justify-content: unset;
  }
  .background-setting-div .bottombar-theme-options:first-child {
    margin-bottom: 17px;
  }
  .setting-firstrymenu {
    padding: 30px 0px;
    margin: 0px 15px;
  }
  .menusetting-haptic-toggle-div {
    padding: 30px 0px;
    margin: 0px 15px;
  }
  .setting-secondrymenu {
    padding: 20px 15px 13px 15px;
  }
  .menuorder-main-div {
    padding: 20px 15px 10px;
  }
  .menuorder-child,
  .menuorder-parent {
    width: 100%;
  }
  .menuorder-childmaindiv .order-aero {
    display: none;
  }
  .menuorder-childmaindiv .ordermob-aero {
    margin-right: 6px;
    display: block;
  }
  .menu-header-rightdiv p {
    display: none;
  }
  .troubleshoot-team-limit-btn {
    width: 280px !important;
    margin: auto;
  }
}
