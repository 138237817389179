@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../../assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

.checkBox {
  padding-left: 0px;
}
.checkBox input:checked ~ .checkmark {
  background-color: transparent;
}
.new_icon button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-top: 0px;
}
.new_icon input {
  padding-left: 0px !important;
  width: calc(100% - 50px);
}

.new_icon span {
  width: 15%;
}
.new_search {
  width: 50%;
}
.icon_width {
  width: 35%;
}
.three_width {
  width: 100%;
  height: 50px;
}
.child_three_div {
  height: 41px !important;
}
.child_three_div p {
  margin-bottom: 0px;
}
.child_three_div .default_icon_check {
  top: 0px !important;
  border-radius: 0px !important;
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  left: 0px !important;
}
.child_three_div .default_icon_check::after {
  top: 0px !important;
  left: 0px !important;
  width: 100%;
  height: 100%;
  transform: none;
  border: 1px solid #3064f9;
  background-color: #3064f9;
  opacity: 0.1;
}

.leftpad {
  padding-left: 0px !important;
}

.upload_pop_icon {
  width: 100%;
  margin: auto;
  padding-top: 20px;
}
.default_icon_check {
  top: -13px !important;
  left: 3px !important;
  border-radius: 4px !important;
}
.default_icon_check::after {
  top: 6px !important;
  left: 9px !important;
}

.mobile_parent::-webkit-scrollbar {
  width: 0px !important;
}
.category {
  padding: 10px 15px;
}
.cat_product,
.blogcategorytwo {
  width: 49% !important;
  margin-right: 2%;
  margin-bottom: 2% !important;
}
.cat_product:nth-child(even),
.blogcategorytwo:nth-child(even) {
  margin-right: 0px;
}
.recent_b p {
  color: #000000;
  font-size: 11px;
  font-family: "Montserrat-Bold" !important;
  margin-bottom: 0px;
}
.banner {
  height: 145px;
}
.heading_head {
  padding: 10px 15px 0px 15px;
  font-size: 11px !important;
}
.popupupdate {
  height: 400px;
}
.heading_head p {
  font: 11px "Montserrat-Bold";
  margin-bottom: 0px;
  color: #000;
}
.shadow_image {
  padding: 0px 8px 15px 8px;
}
.recent_view {
  height: 230px;
}
.recent_img img {
  padding: 10px 0px 0px 15px;
}
.blog_category {
  padding: 10px 15px;
}

.bottom_menu {
  padding-left: 0px;
  padding-right: 0px;
}

.bottom_menu .new_bottom_design {
  border-bottom: 1px solid #e0e5ec;
  padding-top: 18px;
  padding-bottom: 16px;
}
.bottom_style p {
  margin-bottom: 0px;
  font: 15px "Axiforma-Bold";
  padding: 15px 0px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  color: #7782a1;
}
p.active {
  border-bottom: 2px solid #3064f9;
  color: #3064f9;
}
.bottom_bar_items {
  padding: 5px 10px 5px 5px;
  height: 70px;
  border-bottom: 1px solid #f1f5fc;
  border-radius: 0px;
}
.bottom_border {
  position: relative;
  border-bottom: 1px solid #e0e5ec;
  width: 100%;
}

.moveBar:before {
  content: url("../../assets/images/dashboard/bottom-bar/dots.png");
  margin-right: 10px;
  cursor: move;
  margin-top: 4px;
}
.moveBar span {
  color: #c8cedb;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.common_items {
  display: none;
}
.edit_function {
  cursor: pointer;
}
.item-customizer:last-child {
  display: none;
}
.dummy p {
  font: 15px "Axiforma-Bold";
  color: #7782a1;
}
.save_as_draft {
  background-color: #fff;
  text-decoration: none;
  border-radius: 2px;
  margin-right: 20px;
  color: #3064f9;
  font: 12px "Axiforma-Regular";
  height: 40px;
  padding: 11px 30px;
  border: 1px solid #3064f9;
  line-height: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.save_btn {
  border-radius: 2px;
}
.custom_width_first {
  width: 50%;
}
.custom_width_second {
  margin-top: -50px;
  width: 50%;
  /* border-left: 1px solid #E0E5EC; */
}
.new_mobile {
  padding: 20px 20px 20px 20px;
}
.canvas_size {
  margin-bottom: 0px;
  font: 14px "Axiforma-Regular";
  text-align: left;
  color: #7782a1;
}
.bottom_bar_items h5 {
  margin-bottom: 0px;
  font: 16px "Axiforma-Bold";
  color: #1b2952;
}
.bottom_bar_items h6 {
  margin-bottom: 0px;
  font: 14px "Axiforma-Regular";
  color: #3064f9;
  margin-left: 5px;
}
.mobile_part {
  background-color: #f8fafe;
  text-align: center;
  padding: 20px;
}
.bottom_menu_bar {
  background-color: #3064f9;
  padding: 0px 10px;
}
.mobile_parent {
  position: relative;
  width: 95%;
  margin: 72px auto;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);

  height: 200px;

  padding: 0px;
}
.top_header {
  z-index: 1;
  width: 300px;
  top: 0;
  background: white;
  margin: auto;
  position: sticky;
}
.hidepara {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px !important;
}
.bottom_menu_bar p {
  margin-bottom: 0px;
  font: 13px "Poppins-SemiBold";
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin-bottom: -6px;
}
.upper_head h4 {
  font: 13px "Poppins-Regular";
  color: #3064f9;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 0px;
}
.cat_head h3 {
  font: 10px "Poppins-Regular";
  color: #000;
  font-weight: bold;
}
.cate_img_head {
  padding: 10px;
}
.cate_img_head p {
  font: 9px "Poppins-Regular";
  color: #000;
  margin-bottom: 8px;
  margin-top: 5px;
  font-weight: bold;
}
.cate_img_head img {
  width: 90%;
  margin: auto;
}
/* .modal_input input,.modal_input select{
    height: 50px;
} */
.modal_head span {
  font: 22px "Axiforma-Bold";
  color: #1b2952;
}
.modal_input h5 {
  font: 16px "Axiforma-Regular";
  color: #1b2952;
}
.upload_icon {
  border: 1px dashed #bcc7e6;
  min-height: 55px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: white;
  overflow: hidden;
}
.cancel_user {
  display: flex;
  padding: 0px 30px 20px 30px;
}

.new_next_btn {
  text-decoration: none;
  border: 1px solid var(--custom_color1)!important;
  background-color: var(--custom_color2)!important;
  border-radius: 3px;
  color: white;
  font: 14px "Axiforma-Regular";
  padding: 11px 30px;
  transition: 0.5s;
  width: 120px;
  height: 40px;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}

.new_next_btn:hover {
  box-shadow: 0px 2px 4px rgb(112, 155, 250, 0.69);
}

.cancel_btn {
  background-color: #c8cedb;
  text-decoration: none;
  border: 1px solid #c8cedb;
  border-radius: 3px;
  color: white;
  font: 14px "Axiforma-Regular";
  padding: 9px 30px;
  transition: 0.5s;
  cursor: pointer;
  width: 120px;
  height: 40px;
}
.modal_items_form {
  width: 49%;
}
.delete {
  cursor: pointer;
}
.delete h5 {
  font: 14px "Axiforma-Regular";
  color: #7782a1;
}

/* Updates */

.icons_pop {
  border-top: 1px solid #e0e5ec;
  padding-top: 20px;
}

.new_search {
  height: 50px;
}

.upload_pop_icon_img {
  width: 10%;
  /* margin: auto; */
  text-align: center;
  margin-bottom: 2%;
}
.upload_pop_icon_img input[type="radio"] {
  display: none;
}
.upload_pop_icon_img label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}
.upload_pop_icon_img label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 102px;
  transition: 0.4s;
  z-index: 3;
  transform: scale(0);
}
.upload_pop_icon_img :checked + label:before {
  content: url("../../assets/images/dashboard/bottom-bar/tick.png");
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
}
.pagealign ul {
  text-align: left;
}

.default_icon_check2 {
  top: -13px !important;
  left: 3px !important;
  border-radius: 4px !important;
}
.default_icon_check2::after {
  top: 4px !important;
  left: 7px !important;
}

.btm_mobile_preview {
  display: none;
}

@media screen and (max-width: 1200px) {

  .right_panel.right_panel_bottombar{
    overflow-x: visible!important;
  } 
  
  
  .btm_mobile_preview {
    display: block;
    /* background-color: transparent;
    border: none;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    margin: auto; */
  }

  .btm_mobile_preview button {
    /* display: block; */
    background-color: transparent;
    border: none;
    position: absolute;
    bottom: -160px;
    right: 0;
    left: 0;
    margin: auto;
  }

  .popupupdate {
    height: 300px;
  }
  .icon_width {
    width: 100%;
    margin-top: 6px;
  }
  .child_three_div p {
    display: block;
  }

  .new_icon span {
    width: 100%;
  }
  .new_search {
    width: 100%;
  }
  .bottom_style {
    width: 50%;
  }
  .bottom_style p {
    width: 100%;
  }
  .upload_pop_icon {
    width: 100%;
    padding-top: 0px;
  }
  .upload_pop_icon_img {
    width: 25%;
    margin-bottom: 2%;
  }
  .custom_width_first,
  .custom_width_second {
    width: 100%;
  }
  .add_mobile_pad {
    padding-top: 20px !important;
  }
  .bottom_menu .new_bottom_design {
    padding: 0px 15px;
  }
  .bottom_style p:first-child {
    margin-left: 0px;
  }
  .bottom_style p {
    padding: 15px 0px;
  }
  .bottom_bar_items {
    padding: 0px 15px;
  }
  .modal_items_form {
    width: 100%;
  }
  .delete {
    margin-top: 21px;
    margin-bottom: 21px;
  }
  .Mobile_Btn {
    padding: 10px 15px;
  }
  .save_as_draft,
  .cancel_btn {
    width: 49%;
  }
  .new_next_btn {
    width: 100%;
  }
  .save_as_draft,
  .cancel_btn {
    margin-right: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal_head span {
    font: 18px "Axiforma-Bold";
    color: #1b2952;
    margin-bottom: 15px;
  }
  .cancel_user {
    padding: 0px 15px 20px 15px;
    margin-top: 10px !important;
  }
  .cancel_user .new_next_btn {
    width: 49%;
  }
}

@media screen and (min-width: 768px) {
  .bottom_border {
    width: 50%;
    border-right: 1px solid rgb(224, 229, 236);
  }
}
