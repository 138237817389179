.maincontent {
    padding-top: 100px;
}
.mainimage {
    color: #1B2952;
    margin-bottom: 40px;
}

 .maintext {
    color: #7782A1;
    font-size: 14px;
    font-family: 'Axiforma-regular';
    margin-bottom: 25px;
}
 .smalltext {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 14px;
    color: #3064F9;
    cursor: pointer;
}
.No-payment{
    margin-left: 5.5px;
    margin-bottom: 4px;
}
/*Media Queries*/
@media screen and (min-width: 768px) {
    .maincontent {
        padding-top: 70px;
    }
    .smalltext {
        padding-bottom: 0px;
    }
}
