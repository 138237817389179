.new-pricing-dealhead{
    display: flex;
    justify-content: space-between;
    width: 1080px;
    align-items: center;
    margin-bottom: 20px;
}
.pricing-deal-body{
    width: 100%;
    margin-top: 60px;
    background-color: #F8FAFE;
    display: grid;
    justify-content: center;
}
.web-pricing-deals{
    background-color: #F8FAFE;
    padding: 69px 0px;
    min-height: calc(100vh - 60px);
}
.pricing-deals-head{
    font-size: 24px;
    font-family: 'Axiforma-Bold';
    color: #0F193B;
    margin-bottom: 0px;
    line-height: 24px;
}
.pricing-deals-subhead{
    display: flex;
}
.pricing-deals-subhead p{
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    margin: 0px;
    line-height: 13px;
    margin-left: 5.6px;
}
.pricing-deals-maindiv{
    border: 2px solid transparent;
    height: 160px;
    border-radius: 8px;
    border: 1px solid #E3E6F1;
    background-color: white;
    width: 1080px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.pricing-deals-maindiv:hover{
    border: 2px solid #7D9FFF;
    box-shadow: 0px 4px 8px rgba(48, 100, 249, 0.13);
}
.pricing-up-border {
    bottom: -6px;
    left: 151px;
    /* padding: 10px 10px; */
    width: 20px;
    height: 20px;
    background-color: #f8fafe;
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 0px -5px 3px rgba(188, 193, 206, 0.2);
    transform: rotate(180deg);
    border: 1px solid #E3E6F1;
    border-top: 3px solid transparent;
}
.pricing-up-border:hover{
    border: 2px solid #7D9FFF;
    box-shadow: inset 0px -5px 3px rgba(48, 100, 249, 0.13);
    border-top: 3px solid transparent;
}
.pricing-down-border {
    top: -6px;
    left: 151px;
    /* padding: 10px 10px; */
    height: 20px;
    width: 20px;
    background-color: #f8fafe;
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 0px -3px 3px rgba(188, 193, 206, 0.2);
    transform: rotate(0deg);
    border: 1px solid #E3E6F1;
    border-top: 3px solid transparent;
}
.pricing-down-border:hover{
    border: 2px solid #7D9FFF;
    box-shadow: inset 0px -3px 3px rgba(48, 100, 249, 0.13);
    border-top: 3px solid transparent;
}
.pricing-deals-imagediv{
    justify-content: center;
    display: flex;
    align-items: center;
    width: 160.5px;
}
.pricingline-div{
    height: 85%;
    border-right: 2px dashed #c8cedb;
    position: absolute;
    left: 160px;
    opacity: 0.5;
}
.web-pricing-deal-right{
    display: flex;
    justify-content: space-between;
    width: 86%;
    padding: 0px 30px;
    align-items: center;
}
.web-pricing-deal-rightseconddiv{
    display: flex;
}

.pricing-deals-created-date{
    display: flex;
    justify-content: end;
}
.mob-deals-div{
    display: none;
}
.web-pricing-rightdiv h1{
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 12px;
}
.web-pricing-rightdiv h2{
    font-size: 18px;
    color: #1B2952;
    font-family: 'Axiforma-Bold';
    margin-bottom: 12px;
}
.web-pricing-rightdiv p{
    margin-bottom: 0px;
    color: #7782A1;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    line-height: 12px;
}
.web-pricing-tokan-div{
    display: flex;
    background: #f9fffb;
    border: 1px dashed #47ce70;
    padding: 9px 12px;
    align-items: center;
    margin-bottom: 10px;
}
.web-pricing-deal-rightfirstdiv{
    text-align: right;
    padding: 0px 30px 0px;
    border-right: 1px solid #C8CEDB;
}
.web-pricing-tokan-div h1{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    line-height: 14px;
    margin-bottom: -1px;
    margin-left: 8px;
}
.web-pricing-deal-rightfirstdiv h2{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #1B2952;
    line-height: 14px;
    margin-bottom: 10px;
}
.webpricing-deal-expdate{
    font-size: 12px;
    margin-bottom: 0px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-right: 20px;
}
.webpricing-deal-expired{
    font-size: 12px;
    margin-bottom: 0px;
    font-family: 'Axiforma-Regular';
    color: #FF4848;
    margin-right: 20px;
}
.webpricing-deal-active{
    font-size: 12px;
    margin-bottom: 0px;
    font-family: 'Axiforma-Regular';
    color: #47CE70;
  
}
.webpricing-deal-inactive{
    font-size: 12px;
    margin-bottom: 0px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
}
.web-pricing-buttons-active{
    justify-content: center;
    display: flex;
    align-items: center;
    padding-left: 30px;
}
.web-active-btn{
    width: 100px;
    color: white;
    height: 40px;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    background-color: #3064F9;
    border-radius: 3px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
}
.web-active-btn:hover{
    background-color: #134bec;
}
.web-notactive-btn{
    width: 100px;
    color: #BCC1CE;
    height: 40px;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    background-color: white;
    border-radius: 3px;
    border: 1px solid #E3E6F1;
}
.pricing-deals-maindiv:hover .pricing-up-border{
    border: 2px solid #7D9FFF;
    box-shadow: inset 0px -5px 3px rgba(48, 100, 249, 0.13);
    border-top: 3px solid transparent;
}
.pricing-deals-maindiv:hover .pricing-down-border{
    border: 2px solid #7D9FFF;
    box-shadow: inset 0px -3px 3px rgba(48, 100, 249, 0.13);
    border-top: 3px solid transparent;
}
@media (max-width: 1200px) {
    .image-technology{
        width: 50px !important;
    }
    .web-pricing-deals{
        display: none;
        
    }
    .mob-deals-div{
        display: block;
        background-color: #F8FAFE;
        padding: 25px 15px;
        min-height: calc(100vh - 60px);
     
    }
    .pricing-deal-body{
      justify-content: unset;
    }
    .mob-pricing-deals{
        position: relative;
    }
    .mob-pricing-topdiv{
        display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0,0,0, 0.06);
    }
    .mob-topdiv-pricing{
        border: 1px solid #E3E6F1;
        border-bottom: 2px dotted rgba(200, 206, 219, 0.5);
        border-radius: 8px;
        background-color: white;
        position: relative;
    }
    .mob-pricing-bottomdiv{
        border: 1px solid #E3E6F1;
        border-top: none;
        background-color: white;
        border-radius: 8px;
        padding: 27px 20px;
        box-shadow: 0px 2px 6px rgba(0,0,0, 0.06);
    }
    .mob-pricing-exp{
        display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 12px;
    }
    .pricing-deals-mob-border{
        left: -6px;
    bottom: -10px;
    -webkit-transform: rotate(90deg);
    /* padding: 9px 10px; */
    width: 20px;
    height: 20px;
    background-color: #f8fafe;
    border-radius: 50%;
    border: 1px solid #E3E6F1;
    border-top: 3px solid transparent;
    position: absolute;
    box-shadow: inset 0px -5px 7px 0px rgba(188, 193, 206, 0.2);
    transform: rotate(270deg);
    }
    .down-pricing-deals-border{
        bottom: -10px;
        right: -7px;
        /* padding: 10px 10px; */
        width: 20px;
        height: 20px;
        background-color: #f8fafe;
        border-radius: 50%;
        position: absolute;
        box-shadow: inset 0px -4px 3px rgba(188, 193, 206, 0.2);
        transform: rotate(91deg);
        border: 1px solid #E3E6F1;
        border-top: 3px solid transparent;
    }
    .mob-pricing-head p{
        font-size: 20px;
        font-family: 'Axiforma-Bold';
        margin-bottom: 17px;
        line-height: 20px;
        text-align: left !important;
    }
    .mob-pricing-technology h1{
        font-size: 13px;
        color: #BCC1CE;
        font-family: 'Axiforma-Regular';
        margin-bottom: 7px;
        line-height: 13px;
    }
    .mob-pricing-technology h2{
        font-size: 16px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        margin-bottom: 0px;
        line-height: 29px;
        width: calc(100% - 10px);
    }
    .mob-pricing-technology p{
        font-size: 14px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    line-height: 14px;
    }
    .mob-pricing-bottomdiv h1{
        font-size: 14px;
        color: #1B2952;
        line-height: 14px;
        font-family: 'Axiforma-Regular';
        margin-bottom: 10px;
    }
    .mob-pricing-bottomdiv .mob-pricing-expactive{
        font-size: 12px;
        color:#7782A1;
        line-height: 12px;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
    }
    .mob-pricing-bottomdiv .mob-pricing-expinactive{
        font-size: 12px;
        color:#FF4848;
        line-height: 12px;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
    }
    .mob-pricing-exp .pricing-deals-active{
        font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #47CE70;
    margin-bottom: 0px;
    }
    .mob-pricing-exp .pricing-deals-inactive{
        font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-bottom: 0px;
    }
    .pricing-deals-coupon-code{
        display: flex;
        margin-top: 30px;
        background: #f9fffb;
        border: 1px dashed #47ce70;
        padding: 9px 12px;
        align-items: center;
    }
    .pricing-deals-coupon-code p{
        font-size: 14px;
    color: #1B2952;
    font-family: 'Axiforma-Regular';
    margin-bottom: 0px;
    line-height: 14px;
    margin-left: 8px;
    word-break: break-all;
    }
.pricing-delas-mob-activebtn{
    font-size: 16px;
    font-family: 'Axiforma-Regular';
    color: white;
    background-color: #3064F9;
    height: 50px;
    margin-top: 20px;
    border: none;
    border-radius: 3px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.pricing-delas-mob-dactiveebtn{
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    background-color: #FFFFFF;
    height: 50px;
    margin-top: 20px;
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    width: 100%;
}
}