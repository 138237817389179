@font-face {
  font-family: "Proxima-Extrabold";
  src: url(../fonts/ProximaNova-Extrabold.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../fonts/ProximaNova-Bold.otf);
}
@font-face {
  font-family: "Proxima-Regular";
  src: url(../fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Thin";
  src: url(../fonts/ProximaNova-Thin.otf);
}
@font-face {
  font-family: "Proxima-Black";
  src: url(../fonts/ProximaNova-Black.otf);
}
@font-face {
  font-family: "Axiforma-Regular";
  src: url(../fonts/Axiforma-Regular.woff);
}
@font-face {
  font-family: "Axiforma-Regular2";
  src: url(../fonts/Axiforma-Regular2.woff2);
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url(../fonts/Axiforma-Bold.ttf);
}

body {
  font-family: "Axiforma-Regular";
}
.login-help p {
  text-decoration: none;
  margin-bottom: 0px;
  color: #c8cedb;
  font-family: "Axiforma-Regular";
  font-size: 15px;
}
.support-tab p {
  color: #fff;
}
.login-help .btn {
  padding-right: 0px;
}
.signhead .helpheaderdropdown {
  left: 10px !important;
  width: 260px;
}
.signhead .helpheaderdropdown::before {
  right: 12px;
}
html {
  height: 100%;
}
#root {
  height: 100% !important;
}
.fixposition .container {
  max-width: 100%;
  width: 75%;
}
.react-date-picker__inputGroup__day,
.react-date-picker__inputGroup__month {
  width: 20px !important;
}
.react-date-picker__inputGroup__year {
  width: 45px !important;
}
header {
  box-shadow: none;
}
header .container {
  max-width: 1920px;
}
a:hover {
  text-decoration: none !important;
}
.height64 .container {
  width: 100%;
  max-width: 1080px;
}

.left-img {
  max-width: 540px;
  padding: 65px 46px;
}
.welcome {
  max-width: calc(100% - 580px);
}

/*Get Started Page*/

.getstartedhead {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
}
.height64 {
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome h1 {
  font-family: "Axiforma-Regular";
  font-size: 30px;
  color: #1b2952;
  margin-bottom: 0px;
  padding-top: 12px;
  padding-bottom: 25px;
}
.welcome-new h1 {
  font-family: "Axiforma-Regular";
}
.createdetail-new h1 {
  font-family: "Axiforma-Regular";
}
label.form-check-label-new {
  font-family: "Axiforma-Regular";
  font-size: 14px;
}
.welcome span {
  font-family: "Axiforma-Bold";
}
.welcome p {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #1b2952;
  margin-bottom: 0px;
}

.w-t-ams h4 {
  margin-bottom: 0px;
  font-size: 16px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
  position: relative;
}
.w-t-ams-new h4 {
  font-family: "Axiforma-Bold";
}
.greentick {
  padding-bottom: 37px;
}
.threestep {
  margin-top: 50px;
}
.greentick h4::after {
  content: "";
  background: url(../images/getstarted/dash-line.png) no-repeat;
  width: 1px;
  height: 40px;
  top: 28px;
  left: -20px;
  position: absolute;
  opacity: 1;
  display: block;
}
.greytext p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #7782a1;
  font-family: "Axiforma-Regular";
}
.confirm {
  color: #47ce70;
}

.start-btn {
  margin-top: 50px;
}
.how-it-work {
  font-size: 16px;
  color: #1b2952;
  font-family: "Axiforma-Bold";
}
.how-it-work:hover {
  color: #1b2952;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  font-size: 14px;
  padding: 18px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  font-family: "Axiforma-Regular";
}
.form-control::placeholder {
  color: #c8cedb;
}

.previewbtn {
  background-color: #fff;
  color: #3064f94d;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  width: 20%;
  height: 60px;
  line-height: 60px;
  border-top: 1px solid #c8cedb;
  border-bottom: 1px solid #c8cedb;
  border-right: 1px solid #c8cedb;
  border-left: 0px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-align: center;
}
.store-url .input-btn input {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.smallgrey p {
  font-size: 13px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
}
.error p {
  margin-bottom: 0px;
  color: #ff4848;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
}
.correct p {
  margin-bottom: 0px;
  color: #47ce70;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  font-size: 14px;
  padding: 18px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  font-family: "Axiforma-Regular";
}
.form-control::placeholder {
  color: #c8cedb;
}
.store-url .input-btn input {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  outline: none!important;
}
.smallgrey p {
  font-size: 13px;
  color: #bcc1ce;
  font-family: "Axiforma-Regular";
}
.error p {
  margin-bottom: 0px;
  color: #ff4848;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
}
.correct p {
  margin-bottom: 0px;
  color: #47ce70;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  margin-left: 5px;
}
.http {
  width: 100px;
  height: 56px;
  line-height: 56px;
  font-family: "Axiforma-Regular2"!important;
  /* border-top: 1px solid #e3e6f1;
  border-bottom: 1px solid #e3e6f1;
  border-left: 1px solid #e3e6f1; */
  border-right: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-left: 12px !important;
}
.http p{
  font-family: "Axiforma-Regular2"!important;
  font-size: 13px;
}
.com-place .http {
  width: 70px;
  height: 56px;
  line-height: 56px;
  font-family: "Axiforma-Regular2";
  border: none;
  border-right: 1px solid #e3e6f1!important;
  margin-right: 12px;
  border-right: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-left: 0px !important;
}
.appearance-menu-web-view input{
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

}
.appearance-menu-web-view .https-link {
  width: 100px;
  height: 55px;
  line-height: 55px;
  font-size: 13px;
}
.bbar .https-link {
  width: 100px;
  height: 55px;
  line-height: 55px;
  font-size: 13px;
}
.form-group .http select {
  background: white url(../images/signup/down.png);
  background-repeat: no-repeat;
  background-position: 90%;
  -webkit-appearance: none;
  font-family: "Axiforma-Regular2";
  border-top: 1px solid #e3e6f1;
  border-bottom: 1px solid #e3e6f1;
  border-left: 1px solid #e3e6f1;
  border-right: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 56px;
  width: 100%;
  padding-left: 12px !important;
  outline: none !important;
}
.https-link {
  width: 100px;
  height: 56px;
  line-height: 56px;
  font-family: "Axiforma-Regular2";
  border-top: 1px solid #e3e6f1;
  border-bottom: 1px solid #e3e6f1;
  border-left: 1px solid #e3e6f1;
  border-right: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-left: 12px !important;
}
.https-link p{
  font-family: "Axiforma-Regular2"!important;
}
.dashboard-customizer.web_view .store_url_div .https-link {
  width: 70px;
  padding-left: 0px!important;
  border: none;
  border-right: 1px solid #c8cedb;
  margin-right: 10px;
}
.welcome .form-control {
  padding: 0.375rem 1.25rem;
}
.welcome .sendbtn {
  width: 100%;
  max-width: 260px;
  height: 56px !important;
  line-height: 56px;
}
.content-width {
  width: 89%;
  text-align: left !important;
}

.dropdown-menu {
  padding: 15px 0px;
  border: none;
  box-shadow: 0px 0px 10px 0px #bcc7e6;
  border-radius: 7px;
}
.dropdown-item {
  font-family: "Axiforma-Regular";
  font-size: 16px;
  color: #333;
  margin-bottom: 0px;
}
.dropdown-item:hover {
  border-left: 4px solid #547df2;
  background-color: white;
  padding-left: 21px;
  color: #547df2;
}
.btn-secondary {
  background: none !important;
  border: none !important;
}
.dropdown-toggle::after {
  display: none;
}
.spinntop .http input {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 0px;
}

/* Create New App */

.createdetail {
  max-width: 540px;

  padding: 30px 0;

  width: 100%;
}
.common-container {
  max-width: 1080px !important;
}

.createimg {
  max-width: 460px;
  width: 100%;
}

.btn.custom-radio-button1.custom-new-design,
.btn.custom-radio-button2.custom-new-design {
  width: 31.49%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px!important;
}
.custom-radio-btn.custom-new-design input[type="radio"]:checked + label,
.custom-radio-btn.custom-new-design input[type="radio"]:not(:checked) + label {
  padding-right: 10px !important;
  padding-top: 25px;
  padding-bottom: 21px;
}

.custom-radio-btn.custom-new-design [type="radio"]:checked + label:before,
.custom-radio-btn.custom-new-design
  [type="radio"]:not(:checked)
  + label:before {
  top: 10px;
  right: 10px;
  transform: none;
}

.custom-radio-btn.custom-new-design [type="radio"]:checked + label:after,
.custom-radio-btn.custom-new-design [type="radio"]:not(:checked) + label:after {
  top: 20px;
  right: 10px;
}

.form-group-http .http select {
  background: none;
}

.custom-radio-btn.backclr,
.custom-radio-btn.backclr label {
  background-color: #f6f8ff !important;
  border-radius: 5px;
  border: 1px solid #87A6FF;
}
.create-app-new{
  margin-top: 160px;
}
.new-create-app{
  padding: 0px!important;
}
.new-create-app h1{
  color: #1B2952;
  font-family: "Axiforma-Regular";
  font-size: 30px;
  margin-bottom: 30px;
}
.my-app-name{
  color: #1B2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: left!important;
}
.new-create-app input , .new-create-app input:focus-visible, .new-create-app input:focus{
  background-color: #F8FAFE;
  outline: none!important;
  border-radius: 3px!important;
}
.create-app-group .web-type-text{
  color: #1B2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.types-of-apps{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.blankapp{
  width: 31.49%;
}
.createimg{
  margin-top: 80px;
}
.new-create-app select{
  background-color: #F8FAFE!important;
  border-radius: 3px!important;
}
.create-app-group{
  margin-bottom: 5px!important;
}
.create-app-group .custom-new-design label img{
  width: 44px;
  height: 44px;
}
.web-type-License{
  color: #1B2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.web-type-url{
  color: #1B2952;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left!important;
}
.form-check-label p{
  position: relative;
  top: -10px;
}
.form-check-label h6{
  color: #BCC1CE;
  font-size: 10px;
  margin-bottom: 0px;
  font-family: "Axiforma-Regular";
  position: relative;
  top: -40px;
}
.coming-soon label::before , .coming-soon label::after{
  display: none!important;
}
.create-app-group .coming-soon:hover{
  box-shadow: none!important;
  border: 1px solid #e3e6f1!important;
  cursor: auto!important;
}
.create-app-group .coming-soon label{
  cursor: auto!important;
}
.create-app-button{
  margin-top: 20px;
}
.createappbtn {
  background-color: #3064f9;
  color: #fff;
  border-radius: 3px;
  width: 260px;
  height: 56px !important;
  line-height: 56px;
  padding: 0;
  border: 1px solid #3064f9;
  font-size: 16px;
  font-family: "Axiforma-Regular";
  outline: none !important;
  text-decoration: none !important;
  display: block;
  text-transform: uppercase;
  text-align: center;
  transition:0.5s;
  margin-bottom: 20px;
}
.createappbtn:hover{
  background-color: #0040f9;
  border: 1px solid #0040f9;
  transition:0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.create-workspace-page{
  height: calc(100vh - 60px);
}
.new-loader {
  border: 2px solid #f4f4f9;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px!important;
  height: 16px!important;
  -webkit-animation: spin 0.6s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;
}
.new-blue-loader {
  border: 2px solid #3064f9;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px!important;
  height: 16px!important;
  -webkit-animation: spin 0.6s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;
}
.new-grey-loader {
  border: 2px solid #7782a1;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px!important;
  height: 16px!important;
  -webkit-animation: spin 0.6s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;
}
.types-of-apps h6{
  margin-top: 20px!important;
}

.getstarted-footer{
  position: relative;
  margin: auto; 
  max-width: 74%
}
.email-varify-section{
 height: auto;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@media (min-width: 1280px) and (max-width: 1536px) {
  .helpheaderdropdown::before {
    right: 93px;
  }
  .helpheaderdropdown {
    width: 245px;
  }
  .white-bg {
    width: 640px;
  }
  .signup_form {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 25px;
  }
  .ipdad-video video {
    width: 85%;
  }
  .signupcontent h3 {
    font-size: 28px;
  }
  .signup_form .form-group input,
  .signup_form .form-group select,
  .signup_form .loginbtn {
    height: 50px !important;
  }
  /* .loginbtn,.form-group .http select,.select-input .phonecodeselect{
        height: 50px!important;
    } */
  .sendbtn {
    height: 45px !important;
    line-height: 45px;
  }
  .tc h6 {
    font-size: 13px;
  }
  .forgot_pw {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .signupcontent h5 {
    margin-top: 3px;
    margin-bottom: 10px;
  }
  .store-url .form-group {
    margin-bottom: 5px;
  }
  .start-btn {
    margin-top: 40px;
  }
  /* .height64 {
    height: 100%;
  } */
  .w-t-ams h4 {
    margin-bottom: -3px;
  }
  .smallgrey p,
  .error p {
    font-size: 13px !important;
  }
  .store-url .error img {
    width: 17px;
  }

  .letsstart {
    margin-top: 35px !important;
  }
  .custom-radio-btn.custom-new-design [type="radio"]:checked + label:after,
  .custom-radio-btn.custom-new-design
    [type="radio"]:not(:checked)
    + label:after {
    top: 20px;
    right: 8px;
  }

  .store-url.store-url2 p {
    font-size: 14px;
  }
  .welcome {
    max-width: calc(100% - 600px);
    text-align: left !important;
  }
}

@media (max-width: 1024px) {
  .left-img {
    max-width: 500px;
    padding: 50px 36px;
  }
  .store-url2 {
    min-height: auto !important;
  }

  button.sendbtn.letsstart {
    margin-top: 21px !important;
  }
  .signuppage,
  .signinpage {
    background-color: #fff;
  }
  .signuppage .container {
    height: 100%;
    top: 80px;
  }
  .createappbtn {
    width: 100%;
  }
  .new-create-app{
    padding-bottom: 20px!important;
    margin-bottom: 20px!important;
  }
}
@media (min-width: 980px) and (max-width: 1504px) {
  .height64 .container {
    max-width: 1080px !important;
    width: 100% !important;
  }

  /* .welcome .sendbtn {
    margin-top: 51px;
  } */
  .create-app-new {
    margin-top: 130px;
  }
  .new-create-app h1{
    text-align: left;
  }
  .getstarted-page {
    margin-top: 190px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .height64.height64-ipad {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-top: 70px;
    height: 100% !important;
  }
  .welcome.welcome-ipad {
    max-width: 100% !important;
    padding-left: 20px !important;
  }
  .greentick.greentick-red h4:after {
    height: 18px !important;
    top: 23px !important;
  }
  .coming-soon .form-check-label p {
    text-align: center!important;
  }
  .welcome h1 , .welcome p{
    text-align: left!important;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .white-bg {
    border: 1px solid #dce1eb;
    width: 626px;
  }
  .signup_form {
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 40px;
  }
  .signupcontent h3 {
    font-size: 32px;
  }
  .ipad-none {
    display: none !important;
  }
  .fixed-button .ipad-block {
    display: block !important;
    margin-top: 0px;
    text-align: center;
  }
  .ipad-custom-padding {
    padding: 10px 18px 5px 18px;
  }
  .ipad-margin-top {
    margin-top: 85px;
    padding: 0px 18px 5px 18px;
  }
  .two-btn-tabs .createtabs {
    width: 100% !important;
  }
  .ipad-block {
    display: block !important;
    margin-top: 110px;
  }
  .w-t-ams.ipad-block {
    display: block !important;
    margin-top: 110px;
  }
  .w-t-ams .ipad-none {
    display: none !important;
  }
  .height64 {
    align-items: flex-start !important;
  }
  .height64 .container {
    width: 90%;
    max-width: 90%;
  }
  .welcome h1 {
    font-size: 30px;
  }
  .welcome .sendbtn {
    width: 50%;
  }
  .ipdad-video video {
    width: 80%;
    margin-top: 25px !important;
  }
  .threestep {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    margin-top: 51px;
  }
  .start-btn {
    margin-top: 51px !important;
  }
  .greentick h4::after {
    display: none;
  }
  .greentick {
    width: 100%;
    padding-bottom: 0px;
  }

  .launch-steps {
    text-align: left !important;
  }

  .d-md-block.left-img {
    display: none !important;
  }
  .welcome {
    max-width: 100%;
  }
  .welcome.ipad-block {
    text-align: left;
  }

  .createimg {
    display: none;
  }

  .createdetail {
    max-width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .store-url p {
    text-align: left !important;
  }
  .appmysite-certificate {
    display: none;
  }
  .coming-soon .form-check-label p {
    text-align: center!important;
  }
  .create-app-new {
    margin-top: 100px;
  }
  /* .welcome {
    max-width: calc(100% - 540px);
  } */

}

@media (min-width: 980px) and (max-width: 1200px) {
  .d-md-block.left-img {
    display: none !important;
  }
  .height64.height64-getstarted {
    align-items: flex-start !important;
    margin-top: 150px !important;
  }
  .height64.height64-getstarted .container {
    max-width: 959px !important;
  }
  .welcome {
    max-width: calc(100% - 540px);
  }
  .start-btn {
    margin-top: 50px!important;
}
}

@media (max-width: 1200px) {
  .greentick.greentick-red h4::after {
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAYAAADtlXTHAAAABHNCSVQICAgIfAhkiAAAABhJREFUCJljuHzz2X8mBgYGBgSBFdBVHQDnbg5dE1gl+QAAAABJRU5ErkJggg==)
      no-repeat;
    width: 1px;
    height: 40px;
    top: 28px;
    left: -20px;
    position: absolute;
    opacity: 1;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .types-of-apps h6{
    margin-top: 15px!important;
  }
  .custom-new-design.custom-new-design-margin {
    margin-right: 0.5rem !important;
  }

  .signhead .helpheaderdropdown::before {
    right: 12px;
  }
  .signhead .helpheaderdropdown {
    width: 240px;
  }
  .reset-password .signup_form {
    padding-top: 15px;
  }
  .rescan-btn {
    width: 70px;
  }
  .error-content {
    width: calc(100% -70px);
  }

  header {
    background-color: #fff;
    display: block !important;
  }

  .height100 {
    height: auto;
    margin-top: 0px;
  }
  .signupcontent {
    display: block;
    align-items: center;
  }
  .signup_form {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .signupcontent h3 {
    font-size: 20px;
    text-align: left;
    /* line-height: 0px; */
    /* margin-bottom: 42px; */
  }
  .white-bg {
    border: none;
  }
  .container.create-account {
    padding: 0;
  }

  .logo img {
    width: 130px;
    margin-top: 10px;
  }

  .signhead {
    height: 60px;
  }

  .form-group input {
    height: 56px;
  }
  .form-group select,
  .form-group .http select {
    height: 56px;
  }
  .eye {
    top: 15px;
    right: 7px;
  }

  .error,
  .correct {
    margin-top: 5px;
  }
  .signup_left {
    background-color: #fff;
  }

  .tc h6 {
    line-height: 20px;
  }

  .tc {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .loginbtn {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  /* .socialoptions {
        margin-top: 40px;
        margin-bottom: 40px;
    } */
  .already {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dce1eb;
  }
  .signup_form .form-control {
    border-radius: 3px;
  }
  .forgot-page {
  }
  .backbtn {
    display: none;
  }
  .sendbtn {
    width: 100%;
  }
  .two-btn {
    padding-top: 0px;
  }
  .http select,
  .form-control {
    padding-left: 10px !important;
  }
  .forgot-content img {
    display: block;
  }
  .forgot-content {
    justify-content: start;
    height: 51px;
    border-bottom: 1px solid #eff3f9;
    padding-left: 20px;
  }
  .remove-top-pad .signup_form {
    padding: 0px;
  }
  .remove-top-pad h5 {
    text-align: left;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-bottom: 25px; */
  }
  .remove-top-pad .form-group {
    padding: 0px;
  }
  .remove-top-pad .two-btn {
    padding: 0px;
  }
  .forgot-content a {
    font-size: 18px;
    color: #1b2952;
  }
  .sendbtn {
    height: 48px !important;
    line-height: 48px;
    font-size: 14px;
  }
  /*Get Started*/
  .welcome h1 {
    font-size: 28px;
    text-align: left;
    padding-bottom: 10px;
  }
  .welcome .launch-steps {
    font-size: 16px;
    /* color: #7782a1; */
    padding-bottom: 35px;
    text-align: left;
  }
  .height64 .container {
    width: 100%;
    max-width: 100%;
  }
  .height64 {
    align-items: start;
    margin-top: 15px;
  }
  .welcome {
    width: 100% !important;
    /* text-align: center */
    max-width: 100%;
    padding: 0;
  }
  .welcome .sendbtn {
    width: 100%;
    margin-bottom: 18px;
  }
  .welcome .letsstart {
    width: 100%;
  }

  .left-img {
    display: none;
  }

  .greentick {
    padding-bottom: 39px !important;
  }
  .threestep {
    margin-top: 15px;
  }
  .start-btn .how-it-work {
    width: 100%;
    font-size: 14px;
    height: 48px;
    border: 1px solid #c8cedb;
    border-radius: 4px;
    padding: 8px;
    color: #c8cedb;
    justify-content: center !important;
  }
  .store-url {
    margin-top: 20px;
  }
  .how-it-work svg {
    width: 20px;
  }
  .how-it-work #Ellipse_218 {
    fill: #c8cedb;
  }
  .how-it-work img {
    width: 18px;
    margin-right: 5px;
  }
  .start-btn {
    margin-top: 51px;
  }
  .dashboardheader {
    position: static !important;
  }
  .dashboardheader .container {
    height: 55px !important;
  }
  .appsumologo {
    margin-top: 0px !important;
  }
  .logo img {
    width: 123px !important;
    margin-top: 0px !important;
  }
  .appsumologo img {
    width: 200px !important;
  }
  /* .form-group input,.form-group select, .form-group .http select{
        height: 50px!important;
    } */
  .dasboard_page.mobile-display {
    margin-top: -22px;
  }
  .dasboard_page.mobile-view-consistent {
    margin-top: 0;
  }
  .dasboard_page.mobile-view-bottom {
    margin-top: -15px;
  }
  .dasboard_page.mobile-view-preview {
    margin-top: -14px;
  }
  .dasboard_page.mobile-view-download {
    margin-top: -11px;
  }
  .dasboard_page.mobile-view-wp {
    margin-top: 0 !important;
  }
  .dasboard_page.mobile-view-profile {
    margin-top: 8px !important;
  }
  .dasboard_page.mobile-view-user {
    margin-top: -3px;
  }
  .logspac.mobile-view-logs {
    border-bottom: none !important;
  }
  .dasboard_page.mobile-view-password {
    margin-top: 28px !important;
  }
  .dasboard_page.mobile-view-apikeys {
    margin-top: -17px !important;
  }

  /*Get Started end*/

  .btn.custom-radio-button1.custom-new-design,
  .btn.custom-radio-button2.custom-new-design {
    height: 120px;
  }
  .custom-radio-btn.custom-new-design [type="radio"]:checked + label:before,
  .custom-radio-btn.custom-new-design
    [type="radio"]:not(:checked)
    + label:before {
    right: 10px !important;
  }
  .smallgrey p {
    margin-top: 5px;
  }
  .signhead .helpheaderdropdown {
    left: 10px !important;
  }
  .branding1,
  .branding2 {
    display: none;
  }
  .signinpage .signinoption {
    margin-top: 57px;
  }
  .forgotpage .signupcontent {
    margin-top: 30px;
  }
  .resetpasswordpage .signup_form {
    padding-top: 30px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .resetpasswordpage .password-page-mobile {
    padding-top: 40px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .create-workspace {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100% !important;
    max-width: 100% !important;
  }
  .create-app-new {
    margin-top: 80px!important;
  }
  .new-create-app h1 {
    font-size: 28px;
  }
  .types-of-apps .custom-new-design{
    width: 48%!important;
    margin-right: 0px !important;
  }
  .createappbtn {
    width: 100%;
  }
  .new-create-app{
    padding-bottom: 20px!important;
    margin-bottom: 20px!important;
  }
  .getstarted-page {
    margin-top: 60px!important;
  }
}
@media (max-width: 320px) {
  .btn.custom-radio-button2.custom-new-design.custom-last-design-margin {
    margin-right: 0 !important;
  }
  .btn.custom-radio-button2.custom-new-design.custom-last-design-margin
    .form-check-label {
    white-space: nowrap;
  }
  .welcome h1 {
    line-height: 25px;
    font-size: 25px;
  }
  .custom-new-design-margin label{
    font-size: 11px!important;
  }
  .custom-last-design-margin label p , .custom-last-design-margin label h6{
    font-size: 11px!important;
  }
}
/* Create App Page Ends */
