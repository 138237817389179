.newanalytics_header h3{
    font-size: 24px !important;
    /* line-height: 37px !important; */
    margin: 0px !important;
    font-family: 'Axiforma-Bold';
    line-height: 24px;
    color: #1B2952;
}
.newanalytics_header .consent-tooltip {
margin-left: 6px;
}
.newAnalytics-page{
    background-color: #fcfdff;
    display: flex;
    min-height: 100vh;
    position: relative;
}
.analytics-connect-btn{
    display: flex;
    align-items: center;
}
.analytics-connect-btn button{
    width: 100px;
    height: 40px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    border-radius: 3px;
    
}
.analytics-connect-btn a{
    margin: 0px 20px 0px 20px!important;
}
.analytics-save-disablle{
    border: 1px solid #BCC1CE;
    color: #BCC1CE;
    background-color: transparent;
    margin-right: 20px;
    cursor: not-allowed;
}
.analytics-save-enable{
    border: 1px solid #3064F9;
    color: #3064F9;
    background-color: transparent;
    margin-right: 20px;
}
.analytics-varify-disablle{
    color: #ffff;
    background-color: #BCC1CE;
    border: unset;
    cursor: not-allowed;
}
.analytics-varify-enable{
    color: #ffff;
    background-color: #3064F9;   
    border: unset; 
}
.analytics-varify-enable:hover{
    background-color: #134bec; 
    transition: 0.5s;   
}
.analytics-connection-maindiv{
    display: flex;
    background-color: white;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.analytics-connection-leftdiv{
    width: 50%;
    padding: 20px 20px 18px 20px;
}
.analytics-connection-rightdiv{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.analytics-connection-head{
    padding-bottom: 19px;
    border-bottom: 1px solid #E3E6F1;
    margin-bottom: 29px;
}
.analytics-connection-head h2{
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    color: #1B2952;
}
.analytics-connection-head p{
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}
.analytics-connection-labeldiv{
    display: grid;
    margin-bottom: 20px;
}
.analytics-connection-labeldiv label{
    font-size: 13px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    margin-bottom: 9px;
}
.analytics-connection-labeldiv input{
    height: 50px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    border: 1px solid #bcc1ce;
    outline: none !important;
    color: #1b2952;
    border-radius: 3px;
    padding: 15px;
}
.analytics-connection-labeldiv input:hover, .analytics-connection-labeldiv input:focus {
    border: 1px solid #3064f9 !important;
    background-color: #f8fafe !important;
    transition: .5s;
}
.analytics-steps-popup-btn{
    width: 140px;
    height: 40px;
    color: #7782A1;
    font-family: 'Axiforma-Regular';
    font-size: 13px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    background-color: #ffff;
    margin-top: 10px;
}

.analyticsconectmethod {
    display: flex;
    margin-top: 60px;
}
.analyticsconectsteps {
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: center;
}
.analyticsstepwise {
    margin-left: 20px;
}
.analyticsstepwise h2 {
    font-size: 12px;
    color: #BCC1CE;
    font-family: 'Axiforma-Regular';
    margin-bottom: 10px;
}
.analyticsstepwise p {
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
    text-align: left !important;
}
.mob-analytics-btns {
    display: none;
}
.mob-analytics-connect{
    display: none;

}
.newanalytics_header h2{
    display: none;
     }
@media (max-width: 1200px){
    .newanalytics_header h2{
        font-size: 20px;
        font-family: 'Axiforma-Bold';
        color: #1B2952;
        line-height: 28px;
        display: block;
        margin-bottom: 0px;
    }
    .newanalytics_header{
        margin-bottom: 19px;
    }
    .newanalytics_header h3{
   display: none;
    }
    .mob-analytics-connect{
            display: block;
            margin: 30px 0px;
    }
    .mob-analytics-Disconnected {
        display: flex;
    }
    .mob-analytics-Connected {
        display: flex;
    }
    .mob-analytics-Disconnected p{
   
            font-size: 13px;
            color: #FF4848;
            font-family: 'Axiforma-Regular';
            margin-bottom: 0px;
            text-align: left !important;
            display: flex;
            margin-left: 6px;
        
    }
    .mob-analytics-Connected p{
            font-size: 13px;
            color: #52CB86;
            font-family: 'Axiforma-Regular';
            margin-bottom: 0px;
            text-align: left !important;
            margin-left: 6px;
    }
    .mob-analytics-btns {
        display: block;
    }
    .mob-analytics-save-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: "Axiforma-Regular";
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 3px;
        background-color: unset;
    }
    .mob-analytics-save-btn.analytics-enable {
        color: #3064F9;
        border: 1px solid #3064F9;
    }
    .mob-analytics-save-btn.analytics-disable {
        color: #BCC1CE;
        border: 1px solid #BCC1CE;
    }

    .consent-tooltip .consent-tooltipsubheader {
        text-align: left !important;
    }
    .newAnalytics-page .right-panel-gap {
        padding: 20px 15px !important;
    }
    .newAnalytics-page .newanalytics_header {
        margin-top: 0px !important;
        margin-bottom: 20px !important;
    }
    .mob-analytics-update {
        font-size: 12px;
        font-family: "Axiforma-Regular";
        color: #BCC1CE;
        margin-top: 20px;
        text-align: center;
    }
    /* .mob-analytics-save-btn.analytics-enable {
        color: #3064F9;
        border: 1px solid #3064F9;
    }
    .mob-analytics-save-btn.analytics-disable {
        color: #BCC1CE;
        border: 1px solid #BCC1CE;
    } */

    .mob-analytics-varify-btn.analytics-enable {
        color: white;
        background-color: #3064F9;
    }
    
    .mob-analytics-varify-btn.analytics-disable {
        color: white;
        background-color: #BCC1CE;
    }
    .mob-analytics-varify-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: "Axiforma-Regular";
        border-radius: 3px;
        border: none;
    }




.analyticsconectmethod {
        display: grid;
        margin-top: 0px;
}
.analyticsconectsteps{
    display: flex;
    width: 100%;
    align-items: unset;
    justify-content: unset;
    margin: 40px 0px 20px;
}
.analytics-connection-rightdiv{
    display: none;
}
.analytics-connection-leftdiv {
    width: 100%;
    padding: 0px;
}
.analytics-connection-head {
   
        padding: 24px 15px;
    
}
.analytics-connection-body{
    padding: 0px 15px 30px 15px;
}
.mob-inapp-header-right{
    display: flex;
    align-items: center;
}
.mob-inapp-header-right .mob-monetisation-tech-unclicked{
    margin-left: 20px;
}

}