.common-filter {
  position: fixed;
  right: -400px;
  /* right: 0px; */
  height: 100%;
  transition: 0.5s;
  width: 400px;
  background-color: #fff;
  top: 60px;
  z-index: 999;
  border-left: 1px solid #e3e6f1;
  border-top: 1px solid #e3e6f1;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  overflow-y: auto;
}
.commonModal-filter-backdrop {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.common-filter-btn-disable {
  width: 100%;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #bcc1ce;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  cursor: not-allowed !important;
}
.common-filter-open {
  right: 0px;
  transition: 0.5s;
}
.common-filter-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
.common-filter-header {
  font-family: "Axiforma-bold";
  font-size: 18px;
  line-height: 25.29px;
  text-align: left;
  margin-bottom: 0px;
  color: #1b2952;
}
.common-filter-reset {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  line-height: 16.13px;
  text-align: left;
  margin-bottom: 0px;
  color: #7782a1;
  cursor: pointer;
}
.common-filter-second-dropdown-div {
  display: flex;
  gap: 10px;
}
.commonSelect-popup-open {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0;
  min-height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  max-height: 101px;
  overflow-y: auto;
}
.common-filter-search-btn {
  width: 100%;
  height: 40px;
  font-family: "Axiforma-Regular";
  font-size: 14px;
  line-height: 18.82px;
  text-align: center;
  background-color: #3064f9;
  color: #ffffff;
  border: unset;
  border-radius: 3px;
  transition: 0.5s;
  margin-bottom: 20px;
}
.common-filter-search-btn:hover {
  background-color: #134bec;
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}

.common-filter-select__control {
  height: 40px !important;
  min-height: 40px !important;
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 0px !important;
  outline: none !important;
}
.common-filter-select__control--is-focused {
  background-color: #f8fafe;
  border: 1px solid #3064f9 !important;
  outline: none;
}
.common-filter-select__value-container,
.common-filter-select__value-container:focus {
  height: 38px !important;
  min-height: 38px !important;
  cursor: pointer;
  background-image: url(../../../assets/images/dashboard/grey-dropdown.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  outline: none !important;
  padding: 0px 15px 4px !important;
}

.common-filter-select__indicators {
  display: none !important;
}
.common-filter-select__placeholder {
  font-size: 13px !important;
  color: #bcc1ce !important;
}
.common-filter-select__input {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17.47px;

  color: #1b2952;
}
.common-filter-select__option {
  font-size: 13px !important;
  background-color: #ffffff !important;
  color: #1b2952 !important;
  padding: 13px 10px !important;
  margin: auto;
  width: calc(100% - 10px) !important;
  font-family: "Axiforma-Regular";
}
.common-filter-select__option:hover {
  font-size: 13px !important;
  background-color: #f8f9fd !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 10px !important;
}
.common-filter-select__option--is-disabled {
  background-color: #f8f9fd !important;
  border-radius: 4px !important;
}
.common-filter-select__menu {
  margin-top: 2px !important;
}
.common-filter-select__menu-notice {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17.47px;

  color: #1b2952;
}
.common-filter-delete {
  border-radius: 3px;
  border: 1px solid #bcc1ce;
  height: 40px;
  width: 40px;
  margin-bottom: 0 !important;
  padding: 0 12px;
  cursor: pointer;
  min-width: 40px;
  background: transparent;
}
.commonSelected-frompopUp {
  margin-bottom: 0px;
}
.Addbtn-common-filter {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 10px 0px 20px;
}
.Addbtn-common-filter-text {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin-bottom: 0px;
  color: #3064f9;
}
.addfilter {
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
}
.FilterAnd-text {
  font-family: "Axiforma-Regular";
  font-size: 12px;
  line-height: 16.13px;
  text-align: left;
  color: #bcc1ce;
  margin-bottom: 12px;
}
.common-filter-body-datepicker .react-datepicker {
  display: flex;
}
.common-filter-body-datepicker {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  justify-content: space-between;
  min-height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  display: flex;
}

.common-filter-body-datepicker .react-datepicker-wrapper {
  width: 100%;
  height: 40px;
}
.common-filter-body-datepicker .date-input-post2 {
  width: 100% !important;
  border: unset;
  height: 100%;
  font-family: "Axiforma-Regular";
  font-size: 13px;
  margin: 0px;
  outline: unset;
  background-image: url(../../../assets/images/dashboard/cms/calendar.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  border-radius: 3px;
  color: #1b2952;
}
.common-filter-body-datepicker .react-datepicker__input-container {
  height: 100%;
}

.common-filter-body-datepicker .react-datepicker__day-name,
.common-filter-body-datepicker .react-datepicker__day,
.common-filter-body-datepicker .react-datepicker__time-name {
  font-size: 13px !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  font-family: "Axiforma-Regular";
 
}
.common-filter-body-datepicker .react-datepicker__day--keyboard-selected{
  color: #ffffff !important;
}

.common-filter-body-datepicker .react-datepicker__day:hover {
  border-radius: 100% !important;
  height: 30px !important;

}
.common-filter-body-datepicker .react-datepicker__day{
  color: #1B2952;
}
.common-filter-body-datepicker .react-datepicker__day--disabled{
  cursor: default;
    color: #ccc;
}
.common-filter-body-datepicker .react-datepicker__day--selected{
  color: #fff;
}
.common-filter-body-datepicker .react-datepicker__day--in-range{
  color: #fff;
}
.common-filter-body-datepicker .react-datepicker__day--in-selecting-range:hover{
  background-color: #F1F4FB;
}
.common-filter-body-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 21px !important;
  padding: 5px 10px 5px 20px !important;
  line-height: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-filter-body-datepicker .react-datepicker__time-list {
  height: 185px !important;
}
.common-filter-body-datepicker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #ffffff;
}

.common-filter-body-datepicker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -16px;
}
.common-bulkUpdate-type-head {
  font-family: "Axiforma-Regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
}
.common-bulkUpdate-type-head .full-page-tooltip .full-page-tooltipsubheader {
  width: 210px;
}
.bulkUpdateFilter-common-Inputdiv {
  width: 100%;
  height: 40px !important;
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 13px !important;
  color: #1b2952;
  padding: 12px 15px;
  font-family: "Axiforma-Regular";
  line-height: 40px;
}
.bulkUpdateFilter-common-Inputdiv:focus {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
}
.common-BulkUpdate-maindiv {
  margin-bottom: 5px;
}
.common-filter-dropdown {
  width: 100%;
}
.common-filter-dropdown .dropdown-toggle {
  border: 1px solid #bcc1ce !important;
  min-height: 40px !important;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  background-image: url(../../../assets/images/dashboard/grey-dropdown.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% 16px !important;
  margin-bottom: 10px;
  padding: 2px 15px 0px 15px;
  transition: 0.5s;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  border-radius: 3px;
  text-align: left;
  color: #1b2952 !important;
}
.common-filter-dropdown .dropdown-toggle::after {
  display: none !important;
}
.common-filter-select__single-value {
  color: #1b2952 !important;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
}
.common-filter-dropdown .dropdown.show .dropdown-toggle {
  background-color: #f8fafe;
  border-color: #3064f9 !important;
  outline: none;
}
.common-filter-dropdown .dropdown-menu {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
  transform: translate3d(0px, 40px, 0px) !important;
  padding: 5px 0px;
}
.common-filter-dropdown .dropdown-item {
  display: block;
  width: calc(100% - 10px);
  padding: 12px 0px 12px 10px !important;
  color: #1b2952;
  font-size: 13px !important;
  font-family: "Axiforma-Regular" !important;
  margin: auto !important;
  border: 0px solid #fff !important;
}
.common-filter-dropdown .dropdown-item:hover {
  color: #1b2952 !important;
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
}
.common-filter-dropdown .dropdown-item.active {
  border: 0px solid #fff !important;
  background-color: #f8f9fd !important;
  color: #1b2952 !important;
}
.common-filter-body-datepicker .react-datepicker__day-name{
  font-size: 10px !important;
    font-family: 'Axiforma-Regular' !important;
    color: #7782A1 !important;
}

@media (max-width: 1200px) {
  .common-filter {
    width: 100%;
    right: -100%;
  }
  .common-filter-open {
    right: 0px;
  }
  .commonList-Bulkupdate-filter {
    display: none;
  }
}
