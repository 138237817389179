.restrictuser{
  height: 80vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.restrictuser h3{
display: none;
}
.restrictuser h4{
  font-size: 14px;
  color: #7782A1;
  font-family: 'Axiforma-Regular';
  margin-bottom: 0px;
  line-height: 25px;
}
.user-delete-popup {
    max-width: 420px !important;
  }
  .user-delete-popup .modal-body {
    /* width: 420px !important;
    min-width: 420px !important;
    height: 200px !important;
    min-height: 200px !important;
    /* max-width: 420px !important; */
    /* max-height: 200px !important;  */
    padding: 40px 42px;
  }
  .workspace-user-table-top{
   background-color: transparent
  }
  .user-delete-popup h5 {
    color: #1b2952;
    font-size: 18px;
    font-family: "Axiforma-Bold";
    text-align: center;
  }
  .userinvitation-tag{
    width: 80px;
  }
  .user-last-logintag{
    font-size: 12px
  }
  .userlastlogin-time{
      color: #7782A1;
      font-size: 12px
  }
  .userlastlogin-datetype{
      color: #7782A1;
      font-size: 13px

  }
  .user-modal-rightpannel{
   min-height: 80vh
  }
  .user-delete-popup h6 {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    text-align: center;
    margin-bottom: 30px;
    line-height: 18px;
  }
  .user-delete-popup .grey-btn p{
    width: 140px;
    height: 44px;
    border: 1px solid #c8cedb;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    transition: 0.5s;
    line-height: 44px;
    text-transform: capitalize;
    text-align: center;	
    border-radius: 3px;	
    cursor: pointer;
    font-size: 14px;
    margin: 0;
  }
  /* .user-delete-popup .grey-btn p:hover{
    color: #fff;
    background-color: #c8cedb;
    transition: 0.5s;
  } */
  .user-delete-popup .red-btn p {
    width: 140px;
    height: 44px;
    border: 1px solid #ffa7a7;
    color: #ff4848;
    background-color: #fff;
    transition: 0.5s;
    line-height: 44px;
    text-transform: capitalize;
    text-align: center;	
    border-radius: 3px;	
    cursor: pointer;
    margin: 0;
  }
  .delete-popup .red-btn p:hover{
    color: #fff;
    background-color: #ff4848;
    transition: 0.5s;
  }

  .grey-back-btn p{
    width: 140px;
    height: 44px;
    border: 1px solid #c8cedb;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    transition: 0.5s;
    line-height: 44px;
    text-transform: capitalize;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    margin-right: 20px;
  }
  /* .grey-back-btn p:hover{
    color: #fff;
    background-color: #c8cedb;
    transition: 0.5s;
  } */
  @media screen and (max-width: 1200px){
    [dataclass~="newuseradd"] .modal-content {
        margin-right: 15px;
        margin-left: 15px;
    }
    .restrictuser h4{
      display: none;
      }
      .restrictuser h3{
        display: block;
        font-size: 14px;
        color: #7782A1;
        font-family: 'Axiforma-Regular';
        margin-bottom: 0px;
        line-height: 25px;
      }
    }