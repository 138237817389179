.theme-icon-page-section {
  background-color: #fff;
  height: 78vh;
  background-image: url(../../../assets/images/dashboard/app-icon-background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  justify-content: center;
  padding-top: 140px;
  position: relative;
  background-size: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  padding-bottom: 35px;
  overflow: hidden;
}
.theme-page-section {
  background-color: #fcfdff;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.themeHead-module-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 18px;
}
.themeHead-div {
  display: flex;
  align-items: center;
  height: 40px;
}
.themeHead-div .themeHead-tab {
  color: #7782a1;
  font-size: 14px;
  font-family: "Axiforma-Bold";
  margin: 0px 60px -5px 0px;
  border-bottom: 2px solid transparent;
  padding-bottom: 10px;
  transition: 0.5s;
}
.themeHead-div .themeHead-tab:hover,
.themeHead-div .themeHead-tab .active {
  color: #3064f9;
  border-bottom: 2px solid #3064f9;
  transition: 0.5s;
}
.themeHead-savebtn {
  text-decoration: none;
  border-radius: 3px;
  color: white;
  background-color: #3064f9;
  font-size: 14px;
  font-family: "Axiforma-Regular";
  line-height: 18px;
  transition: 0.5s;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: none;
}
.themeHead-savebtn:hover {
  background-color: #134bec;
}
.themeSelct-color-div {
  position: relative;
  display: flex;
  min-height: 78vh;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e3e6f1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
}
.themeSelct-color-Left {
  width: 50%;
  padding: 0px 30px;
}
.themeSelct-color-Right {
  width: 50%;
}
.theme-left-head-accordian {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.theme-headText {
  font-size: 16px;
  justify-content: flex-start !important;
  font-family: "Axiforma-Bold";
  color: #1b2952;
  margin-bottom: 0px;
  line-height: 16px;
}

.theme-config-main {
  position: relative;
  border-bottom: 1px solid #e3e6f1;
  text-align: left;
}
.theme-config-main .accordion-header {
  margin-bottom: 0px;
}

.theme-config-main .accordion-header .accordion-button {
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 30px 0px 27px 0px;
  position: relative;
}
.theme-head-Link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: 0.5s;
}
.theme-config-main .accordion-body {
  padding: 0px 0px 30px;
}
.theme-config-main
  .accordion-header
  .accordion-button.collapsed
  .theme-head-Link {
  rotate: 180deg;
  transition: 0.5s;
}
.button-shape-label {
  font-family: "Axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
  margin-bottom: 0px;
  display: flex;
}
.theme-input-label {
  font-family: "Axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
  margin-bottom: 10px;
  display: flex;
}
.theme-color-picker {
  position: relative;
}
.icon-theme-options-disabled.theme-config-main .accordion-body .color_input {
  background-color: #f8fafe;
  cursor: not-allowed;
  border: 1px solid #e3e6f1;
}
.theme-config-main .accordion-body .color_input {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  background-color: #fff;
  padding: 7px 7px 7px 3px !important;
  height: 50px !important;
  transition: 0.5s !important;
}
.theme-config-main .accordion-collapse {
  margin-bottom: 30px;
}

.theme-color-picker-disabled .color_input {
  background-color: #f8fafe !important;
  cursor: not-allowed !important;
  border: 1px solid #e3e6f1 !important;
}
.theme-color-picker-disabled .color_input p {
  color: #bcc1ce;
}
.theme-color-picker-disabled .color_input div {
  cursor: not-allowed !important;
}

.theme-head-colorselect {
  display: flex;
  /* flex: 1 1 auto; */
  justify-content: center;
  margin-bottom: 15px;
}
.theme-textIcon {
  display: flex;
  /* flex: 1 1 auto; */
  justify-content: center;
}
.theme-headBackgroundColor {
  width: 50%;
  margin-right: 20px;
}
.button-background-color {
  margin-bottom: 30px;
}
.theme-Separatorcolor {
  width: 50%;
}
.button-shape-div {
  display: flex;
  justify-content: space-between;
}

.sharp-shape {
  width: 22px;
  height: 16px;
  border: 2px solid #e3e6f1;
  cursor: pointer;
}
.mid-rounder-shape {
  width: 22px;
  height: 16px;
  border: 2px solid #e3e6f1;
  border-radius: 4px;
  cursor: pointer;
}
.rounded-shape {
  width: 22px;
  height: 16px;
  border: 2px solid #e3e6f1;
  border-radius: 10px;
  cursor: pointer;
}
.selected-shape {
  border: 2px solid #7782a1 !important;
}
.button-shapes-div {
  display: flex;
  gap: 30px;
}
.themefixback {
  box-shadow: 0px 2px 4px rgb(188 193 206 / 50%) !important;
  background-color: #fff !important;
  padding: 20px !important;
  height: auto !important;
  position: absolute !important;
  left: auto !important;
  top: auto !important;
  font-size: 12px;
  z-index: 12 !important;
}
.themeSelct-color-Right {
  padding: 20px 30px !important;
}
.theme-font-setting-label {
  font-family: "Axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
  margin-top: 15px;
}
.theme-settings-frame-div {
  margin-top: 40px !important;
}
.theme-preview-frame {
  border: 4px solid #e3e6f1;
  width: 302px;
  height: 651px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  margin: auto;
}
.profile-icon-type {
  font-family: "Axiforma-regular";
  font-size: 8px;
  /* line-height: 10.75px; */

  color: #b0b0b0;
  margin-bottom: 0px;
  margin-top: 3px;
}
.theme-preview-header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  padding: 0px 15px;
  /* background-color: rgb(14, 118, 255); */
  /* background: linear-gradient(137.05deg, #32A7FF 7.01%, #5A6FFB 53.88%, #7757FF 96.63%); */
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}
.theme-preview-header-left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2px;
}

.theme-preview-head-text {
  font-family: "Axiforma-Bold";
  font-size: 14px;

  text-align: left;
  color: #c4d8ff;
  margin-top: 2px;
  margin-bottom: 0px;
}
.theme-preview-header-right {
  display: flex;
  gap: 18px;
}
.theme-paragraph {
  font-family: "Axiforma-regular";
  font-size: 11px;
  line-height: 14.78px;
  text-align: left;
  color: #1a1a1a;
}
.theme-heading-paragraph {
  font-family: "Axiforma-Bold";
  font-size: 16px;
  line-height: 22.48px;
  color: #1a1a1a;
}
.theme-paragraph-imagediv {
  width: 267px;
  height: 267px;
  background-color: #ebebeb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-preview-button {
  width: 267px;
  height: 36px;
  background-color: #4e7ffc;
  font-family: "Axiforma-regular";
  font-size: 12px;
  line-height: 16.13px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-body {
  padding: 15px 13px 13px 13px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.themeSelct-color-icon-update {
  width: 50%;
  padding: 15px 22px 15px 30px;
  max-height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.theme-remove-cross-icon {
  width: 10px;
}
.themeSelct-color-icon-update::-webkit-scrollbar {
  width: 4px !important;
}
.theme-icons-title {
  font-family: "Axiforma-Bold";
  font-size: 18px;
  line-height: 25.29px;
  text-align: left;
  color: #1b2952;
}
.theme-icons-head {
  border-bottom: 1px solid #e3e6f1;
}
.theme-icons-para {
  font-family: "Axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
  text-align: left !important;
}
.them-type-icon {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  line-height: 19.67px;
  text-align: left;
  margin: 30px 0px 20px;
}
.icon-head-name {
  font-family: "Axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #7782a1;
}
.theme-iconUpload-div {
  border: 1px solid #bcc1ce;
  display: flex;
  justify-content: space-between;
  padding: 7px 15px 7px 7px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 15px;
}
.theme-icon-uploaded {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #edf0f5;
}
.theme-icons-name {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 50px);
}

.theme-icon-name {
  font-family: "Axiforma-regular";
  font-size: 13px;
  line-height: 17.47px;
  text-align: left;
  color: #1b2952;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme-icon-deleteupload {
  display: flex;
  align-items: center;
  gap: 20px;
}
.preview-profilebody {
  padding: 13px 13px 0px;
}
.profile-details {
  border: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border-radius: 10px;
  height: 87px;
  margin-bottom: 7px;
}
.profile-email {
  font-family: "Axiforma-regular";
  font-size: 9px;
  line-height: 12.1px;
  text-align: left;
  color: #a1a1a1;
  margin-bottom: 0px;
}
.profile-name {
  font-family: "Axiforma-Bold";
  font-size: 15px;
  line-height: 21.08px;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 3px;
}
.profile-details-div {
  display: flex;
  align-items: center;
}
.profile-details-img img {
  border-radius: 100%;
}
.profile-details-img {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #cfcfcf;
  margin-right: 11px;
}

.profile-preview-icons {
  border: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border-radius: 10px;
  height: 48px;
  margin-bottom: 7px;
}

.profile-icons-div {
  display: flex;
  align-items: center;
  gap: 9px;
}

.theme-preview-icondiv {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-icons-div img {
  max-width: 16px !important;
  max-height: 16px !important;
}

/* .profile-icons-div>img{
  width: 20px;
    } */

.theme-post-listing-head {
  height: 45px;
  padding: 0px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #dedede;
}

.profile-icon-name {
  font-family: "Axiforma-Bold";
  font-size: 11px;
  line-height: 11px;
  text-align: left;
  color: #1f1f1f;
  margin-bottom: 0px;
  margin-top: 4px;
}

.profile-icons-point {
  font-family: "Axiforma-regular";
  font-size: 9px;
  line-height: 12.1px;
  text-align: left;
  margin-bottom: 0px;
  color: #b0b0b0;
}
.profile-icon-logout {
  font-family: "Axiforma-regular";
  font-size: 11px;
  line-height: 11px;
  text-align: left;
  color: #b0b0b0;
  margin-bottom: 0px;
}
.theme-buttonright-div {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}
.theme-buttonright-div .notification-setting {
  margin-right: 0px;
}
.theme-buttonright-div .consent-setting {
  margin-right: 0px;
}
.theme-buttonright-div .consent-setting-clicked {
  margin-right: 0px;
  width: 40px;
}
.icon_last_updated-date {
  font-family: "Axiforma-regular";
  font-size: 12px;
  color: #bcc1ce;
  margin-bottom: 0px;
}
.mob-themebuttonright {
  display: none;
}
.mob-save-button-div {
  display: none;
}
.theme-preview-carousel .carousel .slider-wrapper {
  width: 100%;
}
.theme-preview-carousel .carousel .control-dots {
  display: none;
}
.theme-preview-carousel .carousel .carousel-status {
  display: none;
}
.theme-preview-carousel .slider:before {
  display: none;
}
.theme-preview-carousel .carousel .slide {
  text-align: left;
}
.theme-preview-carousel .slider {
  background-color: transparent;
  background-image: none;
}
.theme-preview-carousel .carousel .thumbs-wrapper {
  display: none;
}
.theme-preview-carousel .carousel.carousel-slider {
  overflow: unset;
}
.theme-preview-carousel .carousel .control-prev.control-arrow {
  left: 101px;
}
.theme-preview-carousel .carousel.carousel-slider .control-arrow {
  top: -102px !important;
  width: 32px;
  height: 32px;
  font-size: 16px !important;
  border-radius: 50%;
}
.theme-preview-carousel .carousel .control-next.control-arrow {
  right: 101px;
}
.theme-preview-carousel .carousel .control-prev.control-arrow:before,
.theme-preview-carousel .carousel .control-next.control-arrow:before {
  display: none;
}
.theme-preview-carousel .carousel.carousel-slider .control-arrow:hover {
  background: unset;
}
.theme-preview-carousel .carousel .slide img {
  /* width: fit-content; */
  width: 16px;
    object-fit: contain;
}
.theme-preview-carousel .carousel img {
  width: fit-content;
}
.previewToggle {
  position: relative;
  width: 24px;
  height: 12px;
  background-color: #cfcfcf;
  border-radius: 50px;
}
.roundToggle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 100%;
  top: 1px;
  left: 1px;
}

.theme-preview-frame .preview-theme-icon-size {
  max-width: 15px !important;
  max-height: 15px !important;
}

.theme-icon-size {
  max-width: 20px;
  max-height: 20px;
}

.theme-preview-frame .preview-1,
.theme-preview-frame .preview-2 {
  min-height: 640px;
  border-radius: 16px;
  max-height: 640px;
}
.theme-post-list-preview-title {
  color: #1a1a1a;
  font-family: "Axiforma-Bold";
  font-size: 15px;
  line-height: 21.08px;
  text-align: left;
}
.theme-post-list-preview-description {
  color: #1a1a1a;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  line-height: 14.78px;
  text-align: left;
}
.theme-post-list-preview-button {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  background-color: #1a1a1a;
  font-family: "Axiforma-Regular";
  color: #fff;
  border: none;
  font-size: 11px;
  line-height: 14.78px;
}
.theme-comment-button {
  border: 1px solid #b0b0b0;
  color: #6f6f6f;
  height: 35px;
  line-height: 35px;
  border-radius: 6px;
  background-color: transparent;
  width: 100%;
  font-family: "Axiforma-Regular";
  font-size: 11px;
  line-height: 14.78px;
}
.post-theme-one-timestamp {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.theme-product-star{
  width: unset !important;
}
.theme-ratting {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.theme-select-fonts {
  border: 1px solid #bcc1ce;
  border-radius: 3px;
  padding: 0px 15px;
}
.theme-setting-phonecode {
  background: url(../../../assets/images/dashboard/rightper.png) 100%/12px
    no-repeat;
  appearance: none;
}
.theme-select-fonts .country-name {
  margin: 0px !important;
}
.fontselect-modal .country-line-flag-name-div .country-name {
  margin: 0px !important;
  padding-left: 0px !important;
}
.fontselect-modal .countrycode-flag-div {
  height: 100%;
}
.noresult-text {
  font-size: 13px;
  color: #bcc1ce;
  margin-top: 25px;
  margin-bottom: 0px;
}
.nofont-found {
  height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.theme-icon-light-opacity {
  /* opacity: 0.4; */
}
.theme-preview-head-left-icon {
  width: 12px !important;
  height: 11px;
}
.post-list-filter-left-icon {
  width: 16px;
  height: 16px;
}
.post-type-one-thumbnail-styling {
  position: relative;
  height: 101px;
  border-radius: 10px;
  background-size: cover;
}
.post-theme-one-excerpt-margin {
  margin-bottom: 7px;
}
.theme-right-icon-margin {
  margin-left: 20px;
}
.theme-preview-header-left-icon {
  width: 12px !important;
  height: 11px;
}
.theme-selected-product {
  height: 294px;
  border-radius: 16px 16px 0px 0px;
}
.theme-post-footer-gap {
  border-top: 7px solid #f9f9f9;
  background-color: #ffffff;
}
.theme-post-footer-div {
  margin: 0px 15px;
  padding: 15px 0px 0px;
}
.theme-productimag-backside {
  margin-right: 10px;
}
.theme-main-head .themeHead-title {
  display: none;
}

.theme-buttonright-div .consent-setting {
  width: 40px;
}
.theme-post-listing-heading {
  font-size: 12px;
  font-family: "Axiforma-Bold";
  margin: 2px 0px 0px 10px;
  line-height: 18.27px;
}

.theme-icon-new-tag{
  display: inline-block;
}
.theme-icon-star-tag{
  position: absolute;
  top: -5px;
  left: 33px;
  z-index: 1;
  max-width: 15px;
  max-height: 15px;
}

@media (max-width: 1200px) {
  .theme-main-head .app-dashboard-head {
    display: none;
  }
  .theme-main-head .themeHead-title {
    display: block;
    font-family: "Axiforma-Bold";
    font-size: 20px;
    margin-bottom: 0px;
    line-height: 28.1px;
    color: #1b2952;
  }
  .theme-page-section .right-panel-gap {
    overflow-x: unset;
    height: unset;
    padding: 15px !important;
  }
  .nofont-found {
    height: 96%;
  }
  .themeSelct-color-div {
    min-height: unset;
  }
  .mob-save-button-div {
    display: block;
    padding-bottom: 10px;
  }
  .mob-save-button {
    text-decoration: none;
    border-radius: 3px;
    color: white;
    background-color: #3064f9;
    font-size: 16px;
    font-family: "Axiforma-Regular";
    line-height: 18px;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: none;
    margin: 20px 0px;
  }
  .mob-save-button-date {
    font-family: "Axiforma-regular";
    font-size: 12px;

    line-height: 16.13px;
    text-align: center;
    color: #bcc1ce;
  }
  .mob-themebuttonright {
    display: flex;
  }
  .theme-main-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }
  .theme-headBackgroundColor {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .theme-Separatorcolor {
    width: 100%;
  }
  .theme-head-colorselect {
    display: unset;
  }
  .theme-textIcon {
    display: unset;
  }
  .mob-theme-inputbottomgap {
    margin-bottom: 15px;
  }
  .button-shape-div {
    margin-top: 10px;
  }
  .themeSelct-color-Left {
    width: 100%;
    padding: 0px 15px;
  }
  .themeSelct-color-Right {
    display: none;
  }
  .themeHead-module-tabs {
    display: none;
  }
  .themeHead .app-dashboard-head {
    margin: 0px 0px 0px 0px;
  }
  .themeSelct-color-icon-update {
    width: 100%;
    padding: 0px;
    max-height: unset;
    overflow: hidden;
  }
  .theme-icons-head {
    padding: 20px 15px 0px;
  }
  .theme-icon-select-maindiv {
    padding: 0px 15px 15px;
  }
  .theme-icon-deleteupload {
    gap: 10px;
  }
  .theme-icon-star-tag {
    position: absolute;
    top: -8px;
    left: 31px;
    z-index: 1;
    min-width: 15px;
    max-width: 15px;
    max-height: 15px;
  }
}
