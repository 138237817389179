.create-product{
  background-color: #fcfdff;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.create-product-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-product-head .appdash-tooltip{
  display: none;
}
.product-head-right{
  display: flex;
  align-items: center;
}
.product-timestamp{
  margin: 0px;
  color: #BCC1CE;
  line-height: 16.13px;
  font-size: 12px;
  font-family: "Axiforma-Regular";
}
.product-draft{
  margin: 0px 20px;
  color: #7782A1;
  line-height: 18.82px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.product-published{
  margin: 0px 20px;
  color: #47CE70;
  line-height: 18.82px;
  font-size: 14px;
  font-family: "Axiforma-Regular";
}
.product-draft-before{
  border: 1px solid #BCC1CE;
  width: 125px;
  height: 40px;
  background-color: transparent;
  border-radius: 3px;
  color: #BCC1CE;
  font-size: 14px;
  padding-bottom: 2px;
  margin-left: 20px;
  font-family: "Axiforma-Regular";
}
.product-draft-after{
  border: 1px solid #3064F9;
  width: 125px;
  height: 40px;
  background-color: transparent;
  border-radius: 3px;
  color: #3064F9;
  font-size: 14px;
  padding-bottom: 2px;
  margin-left: 20px;
  font-family: "Axiforma-Regular";
}
.product-publish-before{
  border: 1px solid #BCC1CE;
  width: 101px;
  height: 40px;
  background-color: #BCC1CE;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  padding-bottom: 2px;
  margin-left: 20px;
  font-family: "Axiforma-Regular";
}
.product-publish-after{
  border: 1px solid #3064F9;
  width: 101px;
  height: 40px;
  background-color: #3064F9;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  padding-bottom: 2px;
  margin-left: 20px;
  font-family: "Axiforma-Regular";
}
.product-create{
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #E3E6F1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  min-height: 80vh;
  margin-top: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.create-product-left {
  min-width: 50%;
  padding-left: 30px;
}
.create-product-right{
  min-width: 50%;
}
.create-product-preview{
  min-width: 50%;
  height: calc(100vh - 250px);
}
.product-preview-screen {
  height: 659px;
  width: 310px;
  background-color: #F7F7F7;
  margin: auto;
  box-shadow: 0px 10px 20px 0px #0000001A;
  overflow-y: auto;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
  margin-top: 64px;
}
.product-preview-screen::-webkit-scrollbar {
  width: 0px !important;
}
.product-preview-screen::-webkit-scrollbar-thumb {
  background-color: #bcc1ce;
  border-radius: 0px !important;
}
.product-preview-screen::-webkit-scrollbar-track {
  background-color: #fff !important;
  border-radius: 0px !important;
}
.product-preview-header{
  height: 46px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 13px;
  background-color: #fff;
}
.create-product-slider , .create-product-slider .carousel-item , .create-product-slider{
  height: 302px;
}
.create-product-slider .carousel-indicators li{
  width: 5px;
  height: 5px;
  border-radius: 100%;
  border: 3px solid #CFCFCF;
}
.create-product-slider .carousel-control-prev{
  display: none;
}
.create-product-slider .carousel-control-next{
  display: none;
}
.product-preview-header-left{
  display: flex;
  align-items: center;
}
.product-preview-header-right{
  display: flex;
  align-items: center;
}
.product-preview-summry{
  padding: 18px 13px 17px 13px;
  background-color: #fff;
}
.product-preview-summry-name{
  color: #1F1F1F;
  line-height: 16.86px;
  font-size: 12px;
  font-family: "Axiforma-Bold";
  text-align: left;
  margin: 0px 0px 5px 0px;
}
.product-preview-rating{
  display: flex;
  align-items: center;
  color: #6F6F6F;
  line-height: 12.1px;
  font-size: 9px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 0px 0px 9px 0px;
}
.product-preview-star{
  margin-right: 3.3px;
}
.product-preview-reviewnum{
  margin-left: 4px;
}
.product-price-preview-section{
  display: flex;
  align-items: center;
  margin: 0px 0px 4px 0px;
}
.product-price-preview{
  color: #1F1F1F;
  line-height: 15.46px;
  font-size: 11px;
  font-family: "Axiforma-Bold";
  text-align: left;
  margin: 0px;
}
.product-price-preview-original{
  color: #B0B0B0;
  line-height: 10.75px;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 0px 0px 0px 5px;
}
.product-preview-discount-percentage{
  color: #FFFFFF;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  text-align: center;
  margin: 0px;
}
.product-price-preview-discount{
  margin: 0px 0px 0px 8px;
  background-color: #FF4D5F;
  height: 13px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  top: -2px;
}
.preview-stock-status-green{
  color: #4AB170;
  line-height: 10.75px;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 0px 0px 0px 0px;
}
.product-preview-intro{
  background-color: #fff;
  padding: 16px 13px;
  margin: 7px 0px;
}
.product-summry-short-description{
  color: #1A1A1A;
  line-height: 14.78px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 0px;
}
.product-preview-sku{
  color: #B0B0B0;
  line-height: 10.75px;
  font-size: 8px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 16px 0px 0px 0px;
}
.product-preview-detail{
  background-color: #fff;
  padding: 16px 13px;
  margin: 7px 0px;
}
.product-preview-description{
  color: #1A1A1A;
  line-height: 14.78px;
  font-size: 11px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 0px;
}
.product-config-main {
  position: relative;
  border-bottom: 1px solid #e3e6f1;
  text-align: left;
}
.product-config-main .accordion-header {
  margin-bottom: 0px;
}
.product-config-main .accordion-header .accordion-button {
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 30px 0px 30px 0px;
  position: relative;
}
.product-config-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.product-config-toggle .product-config-section-heading {
  font-family: "Axiforma-Bold";
  font-size: 14px;
  line-height: 19.67px;
  color: #1B2952;
  margin: 0px 0px 0px 0px;
}
.create-product-left .product-config-main .collapsed .thechatarrow {
  transform: rotate(180deg);
}
.product-field-title{
  color: #7782A1;
  line-height: 17.47px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  text-align: left;
  margin: 0px 0px 10px 0px;
}
.create-product-input{
  width: 100%;
  height: 50px;
  border: 1px solid #BCC1CE;
  padding: 0px 15px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  outline: none;
  border-radius: 3px;
}
.create-product-input::placeholder {
  color: #BCC1CE;
  opacity: .8; /* Firefox */
}
.create-product-textarea{
  width: 100%;
  height: 100px;
  min-height: 100px;
  max-height: 150px;
  border: 1px solid #BCC1CE;
  padding: 12px 15px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
  outline: none;
  border-radius: 3px;
}
.create-product-textarea::placeholder {
  color: #BCC1CE;
  opacity: .8; /* Firefox */
}
.product-error{
  font-size: 12px;
  color: #ff4848;
  margin: 6px 0px 0px 0px;
  line-height: 10.75px;
  font-family: "Axiforma-Regular";
  text-align: left;
  display: flex;
  align-items: center;
  display: none;
}
.product-error .product-error-img{
  margin-right: 6px;
  position: relative;
  top: -1px;
}
.create-product-left .accordion-body{
  padding-bottom: 30px;
}
.product-top-20{
  margin-top: 20px;
}
.product-top-30{
  margin-top: 30px;
}
.product-currency-preset{
  color: #1B2952;
  line-height: 17.47px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  position: absolute;
  top: 16px;
  left: 15px;
  margin: 0px;
}
.create-product-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-product-toggle-name{
  color: #7782A1;
  line-height: 17.47px;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin: 0px;
}
.product-preview-buy{
  background-color: #fff;
  padding: 16px 13px;
  margin: 7px 0px;
}
.product-add-to-cart{
  width: 100%;
  height: 35px;
  text-align: center;
  margin: auto;
  font-size: 11px;
}
.product-create-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-column-gap: 1.8%;
  column-gap: 1.8%;
  /* grid-row-gap: 20px;
  row-gap: 20px; */
  flex-wrap: wrap;
}
.product-create-images .product-create-single {
  width: 15.095%;
  height: 120px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}
.product-create-single.product-create-upload{
  border: 1.5px dashed #BCC1CE;
}
.default-product-img {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.product-create-setting{
  background-color: #fff;
  border-radius: 6px !important;
  border: 1px solid #E3E6F1 !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 6%) !important;
  min-height: 80vh;
  margin-top: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.product-create-setting-left {
  min-width: 50%;
  padding-top: 20px;
  padding-left: 30px;
}
.product-create-setting-right {
  width: 50%;
  min-width: 50%;
  padding: 64px 30px 0px 30px;
}
.product-create-setting-screen {
  height: 659px;
  width: 310px;
  background-color: #F7F7F7;
  margin: auto;
  box-shadow: 0px 10px 20px 0px #0000001A;
  overflow-y: auto;
  overflow-x: hidden;
  border: 4px solid #E3E6F1;
  border-radius: 20px;
  position: relative;
}
.product-create-setting-header{
  display: flex;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #DEDEDE;
  padding: 0px 13px;
  background-color: #fff;
}
.pro-create-set-heading{
  font-size: 13px;
  line-height: 18.27px;
  color: #1A1A1A;
  font-family: "Axiforma-Regular";
  margin: 0px 0px 0px 11px;
}
.product-create-setting-body{
  display: flex;
}
.product-create-setting-body-left{
  height: 541px;
  overflow: auto;
  background-color: #F7F7F7;
  width: 107px;
}
.product-create-setting-body-left::-webkit-scrollbar {
  width: 0px !important;
}
.product-create-setting-body-right {
  width: calc(100% - 107px);
  padding: 14.5px 13px 0px 11px;
  height: 541px;
  overflow: auto;
  background-color: #fff;
}
.product-create-setting-body-right::-webkit-scrollbar {
  width: 0px !important;
}
.product-create-setting-body-right-child{
  font-size: 9px;
  line-height: 12.1px;
  color: #A1A1A1;
  font-family: 'Axiforma-Regular';
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  word-break: break-word;
}
.product-create-setting-body-right-child-svg{
  margin-right: 11px;
}
.product-create-setting-body-left-active{
  font-size: 9px;
  color: #1F1F1F;
  font-family: 'Axiforma-Bold';
  min-height: 40px;
  margin-bottom: 0px;
  width: 107px;
  display: flex;
  align-items: center;
  padding: 4px 13px;
  line-height: 12.65px;
  background-color: #FFFFFF;
  word-break: break-word;
}
.product-create-setting-body-left-parent{
  height: calc(100% - 40px);
}
.product-create-setting-body-left-parent-entry{
  color: #1A1A1A;
  font-size: 9px;
  font-family: 'Axiforma-Regular';
  display: flex;
  align-items: center;
  padding: 16px 0px 14px;
  margin: 0px;
  margin: 0px 13px 0px 13px;
  text-align: left;
  word-break: break-word;
  line-height: 12.1px;
}
.product-create-setting-body-bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 16px;
  background-color: #fff;
}
.product-create-setting-body-clear{
  width: 50%;
  margin-right: 11px;
  height: 37px;
  border: 1px solid #CFCFCF;
  background: transparent;
  font-family: 'Axiforma-Regular';
  color: #B0B0B0;
  font-size: 11px;
  border-radius: 6px;
  cursor: default !important;
}
.product-create-setting-body-apply{
  width: 50%;
  height: 37px;
  background-color: #1A1A1A;
  font-family: 'Axiforma-Regular';
  color: #FFFFFF;
  font-size: 11px;
  border-radius: 6px;
  border: unset;
  cursor: default !important;
}
.product-create-setting-left-header{
  border-bottom: 1px solid #E3E6F1;
  margin-bottom: 10px;
}
.product-create-setting-left-header .product-create-setting-left-header-heading {
  font-size: 18px;
  font-family: 'Axiforma-Bold';
  color: #1B2952;
  margin: 0px;
  text-align: left;
}
.product-create-setting-left-header .product-create-setting-left-header-para {
  font-size: 13px;
  font-family: 'Axiforma-Regular';
  color: #7782A1;
  margin: 7px 0px 19px 0px;
  line-height: 17px;
  text-align: left !important;
}
.create-product-chip-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #BCC1CE;
  padding: 0px 15px 0px 15px;
  outline: none;
  align-items: center;
}
.create-product-chip-input .create-product-chipset-name {
  color: #7782A1 !important;
  font-size: 13px !important;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;
  max-width: 200px;
}
.create-product-chip-input .create-product-chipset{
  flex-wrap: wrap;
  display: flex;
  width: max-content;
  background-color: #EDF0F5;
  border-radius: 20px;
  padding: 3px 8.5px 3px 12px;
  margin: 5px 6px 5px 0px;
  align-items: center;
}
.create-product-chipset-name{
  font-size: 13px;
  line-height: 17.47px;
  font-family: "Axiforma-Regular";
  color: #7782A1;
  margin: 0px 6px 0px 0px;
}
.chip-upload-sign{
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.remove-product-chipset{
  cursor: pointer;
  position: relative;
  top: 1px;
}
.product-create-mob-btns{
  display: none;
}











@media screen and (max-width: 1399px) and (min-width: 1201px) {
  .product-create-images .product-create-single {
    width: 23.095%;
    height: 110px;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1400px) {
  .product-create-images .product-create-single {
    width: 23.095%;
  }
}

@media screen and (max-width: 2300px) and (min-width: 2000px) {
  .product-create-images .product-create-single {
    height: 141px;
  }
}

@media screen and (max-width: 2600px) and (min-width: 2301px) {
  .product-create-images .product-create-single {
    height: 167px;
  }
}

@media only screen and (min-width: 2700px) {
  .product-create-images .product-create-single {
    width: 10%;
    height: 130px;
  }
}

@media (max-width: 1200px) {

  .create-product-right{
    display: none;
  }
  .create-product-left {
    min-width: 100%;
    padding-left: 0px;
  }
  .product-create {
    margin: 14px 15px 0px 15px;
    min-height: max-content;
    padding-bottom: 0px;
  }
  .product-config-main {
    padding: 0px 15px;
  }
  .product-draft-before , .product-draft-after{
    display: none;
  }
  .product-publish-before , .product-publish-after{
    display: none;
  }
  .product-timestamp{
    display: none;
  }
  .create-product-head{
    margin: 14px 15px 0px 15px;
  }
  .create-product-head .app-dashboard-head{
    margin: 0px 0px 0px 0px;
  }
  .product-create-images .product-create-single {
    width: 32.095%;
  }
  .product-create-setting-right{
    display: none;
  }
  .product-create-setting-left{
    min-width: 100%;
    padding-left: 0px;
  }
  .product-create-setting{
    margin: 14px 15px 0px 15px;
    min-height: max-content;
  }
  .product-create-setting-left-header{
    padding: 0px 0px 0px 15px;
  }
  .product-create-setting-tags{
    padding: 0px 15px;
  }
  .product-create-mob-btns{
    display: block;
    margin: 20px 15px 0px 15px;
  }
  .product-create-mob-draft-before{
    border: 1px solid #BCC1CE;
    width: 100%;
    height: 50px;
    background-color: transparent;
    border-radius: 3px;
    color: #BCC1CE;
    font-size: 16px;
    font-family: "Axiforma-Regular";
  }
  .product-create-mob-draft-after{
    border: 1px solid #3064F9;
    width: 100%;
    height: 50px;
    background-color: transparent;
    border-radius: 3px;
    color: #3064F9;
    font-size: 16px;
    font-family: "Axiforma-Regular";
  }
  .product-create-mob-publish-before{
    border: 1px solid #BCC1CE;
    width: 100%;
    height: 50px;
    background-color: #BCC1CE;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    font-family: "Axiforma-Regular";
  }
  .product-create-mob-publish-after{
    border: 1px solid #3064F9;
    width: 100%;
    height: 50px;
    background-color: #3064F9;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    font-family: "Axiforma-Regular";
  }
  .product-create-mob-timestamp{
    margin: 20px 0px 0px 0px;
    color: #BCC1CE;
    line-height: 16.13px;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    text-align: center;
  }
}