/* PopUps */
.addNewproductPopup{
  width: 1280px !important;
  max-width: 100% !important;
}
.addNewproductPopup .modal-body {
  padding: 0px;
  height: 520px;
}
.addNewproductPopup .modal-header {
  padding: 0px !important;
  z-index: 3;
  background-color: #fff;
}
.productPopupHead{
  height: 70px;
  display: flex;
  align-items: center ;
  justify-content: space-between;
  padding: 0px 30px 0px 20px;
  background-color: #ffffff;
  border-bottom:1px solid #E3E6F1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.productPopupHead .upload-media-title{
  font-family: "Axiforma-bold";
  font-size: 18px;
  color: #1B2952 ;
  margin-bottom: 0px;
  
}
.productPopup-headright{
  display: flex;
    gap: 30px;
}
.productPopup-headright .selecttab{
  font-family: "Axiforma-Regular";
  font-size: 13px;
  color: #BCC1CE;
  margin-bottom: 0px 20px;
  margin-bottom: 0px;
  height: 70px;
  display: flex;
    align-items: center;
    cursor: pointer;
}
.mobDoneError{
  display: none;
}
.productPopup-headright .selectedTab{
  color: #3064F9;
  height: 70px;
    border-bottom: 1px solid;
    
}

.addNewproductPopup .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  border-top: 1px solid #dee2e6 !important;
  z-index: 1;
  background-color: #fff;
  height: 70px;
}
.propductPopup-cancelbtn{
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #bcc1ce !important;
  color: #7782a1;
  font-size: 14px;
  margin: 0px !important;
  transition: 0.5s;
  font-family: 'Axiforma-Regular';
  line-height: 29px;
  background-color: transparent;
}
.propductPopup-cancelbtn:hover{
  color: #484e5e;
    border: 1px solid #7782A1 !important;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.propductPopup-donebtn{
  width: 100px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #BCC1CE !important;
  background-color: #BCC1CE !important;
  color: #fff !important;
  font-size: 14px;
  margin: 0px !important;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Axiforma-Regular';
  cursor: not-allowed !important;
}
.propductPopup-donebtn-enable{
  width: 100px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #3064f9 !important;
    background-color: #3064f9 !important;
    color: #fff;
    font-size: 14px;
    margin: 0px !important;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Axiforma-Regular';
}
.propductPopup-donebtn-enable:hover{
  transition: 0.5s;
  box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
  background-color: #134bec;
  border: 1px solid #134bec !important;
}
.productPopup-bodyMaindiv{
  display: flex;
}

.productPopup-bodyLeft{
  width: 200px;
  min-width: 200px;
  border-right: 1px solid #E3E6F1;
  padding: 10px;
  z-index: 1;
}
.productPopup-bodyRight{
  width: calc(100% - 200px);
  overflow: auto;
  height: 520px;
  padding: 0px;
  background-color: #f8fafe;
  position: relative;
 
}

.image-uploadfromfile{
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  padding: 7px 10px;
  margin-bottom: 10px;
}
.image-uploadfromLink{
  color: #1b2952;
  font-size: 13px;
  font-family: "Axiforma-Regular";
  margin-bottom: 0px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  padding: 7px 10px;
  margin-bottom: 10px;
}
.image-uploadfromfile.divSelected{
  color: #3064f9;
  background-color: #f1f4fb;
  border-radius: 4px;
}
.productLink-inputdiv{
position: relative;
}

.productLink-inputdiv .product-input{
  width: 600px;
    border: 1px solid #bcc1ce;
    height: 50px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 15px 30px 15px 15px;
    outline: none !important;
    background-color: transparent;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1b2952;
}
.productLink-inputdiv .product-input .product-inputimg{
  position: absolute;
  right: 80px;
  top: 15px;

}
.productLink-head{
  color: #7782a1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 9px;
}
.productLink-description{
  font-size: 11px;
    font-family: "Axiforma-Regular";
    color: #7782a1;
    margin-top: 10px;
    text-align: left !important;
}
.productLink-uploaddiv{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productUpload-mainDiv{
  width: 100%;
  height: 100%;
}
.productUpload-mainDiv section{
  width: 100%;
  height: 100%;
}
.product-drags-drops{
  width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}
.product-dragupload-image{
  justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 480px;
    /* width: 480px; */
    /* border: 2px dashed #BCC1CE; */
    /* border-radius: 6px; */
}
.product-dragupload-imagediv{
  width: 60px;
    height: 60px;
    display: flex;
    background-color: #3064F9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.product-dragupload-image .image-drag-type{
  color: #1b2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-top: 26px;
    margin-top: 26px;
    margin-bottom: 6px;
}
.product-dragupload-image .image-drag-type .image-drag-typebroused{
  color: #3064f9;
}
.product-dragupload-image .image-resolution-type{
  color: #7782a1;
    font-size: 11px;
    font-family: "Axiforma-Regular";
    margin-top: 0px;
    line-height: 15px;
}
.uploaded-productImg{

  padding: 2%;
  column-gap: 1%;
display: flex;
row-gap: 20px;
flex-wrap: wrap;
}
 .upload-Product-img{
  width: 19.2%;
  height: 200px;
  background-color: #ffffff;
  border: 1px dashed #BCC1CE;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Uploaded-product-img{
  /* background-image: url(../../../../assets/images/dashboard/p1.png); */
  background-image: url(../../../../assets/images/dashboard/offer-image.png);
  position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.Uploaded-product-img{
  width: 19.2%;
  height: 200px;
  background-color: #da5252;
  position: relative;
}
.Uploaded-product-img .productImg{
  width: 100%;
  height: 100%;
}
.Uploaded-product-img .deleteIcon{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer
}
.headerUploadtab{
  height: 18px;
  width: 18px;
  margin-right: 6px;
}
.gobackProductScreen{
  display: none;
}
.productPopup-bodyLeft .mob-upload-media-title{
    display: none;
}
.image-uploadfromfile .uploadfromfileimg{
  margin-right: 10px;
}
.image-uploadfromLink .uploadfromLinkimg {
  margin-right: 10px;
}
.productUpload-swich-delete .deleteIcon{
  display: none;
}
.itomCountText{
  font-family: 'Axiforma-Regular';
  font-size: 12px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: #7782A1;
  margin-bottom: 0px;
}
.errortext{
  font-family: 'Axiforma-Regular';
    font-size: 12px;
    text-align: left;
    color: #FF4848;
   margin-bottom: 0px;
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.alertIcon{
  height: 13px;
  width: 13px;
  margin-right: 6px;
}
@media (max-width: 1200px) {
  .gobackProductScreen{
    display: block;
  }
  .productPopupHead .upload-media-title {
    display: none;
  }
  .productPopup-headright .selecttab .tabName{
    display: none;
    height: 60px;
  }
  .productPopup-headright .selectedTab {
    height: 60px;
}
.productPopupHead {
  height: 60px;
  box-shadow: 0px 0px 6px 0px #BCC1CE5C;
  padding: 0px 20px;
  border-bottom: unset;

}
.productPopup-headright .selecttab {
  height: 60px;
}
.headerUploadtab {
  margin-right: 0px;
}
.productPopup-bodyMaindiv {
  display: unset;
}
.productPopup-bodyRight {
  width: 100% ;
  height: 100%;
  background-color: #ffffff
}
.productPopup-bodyLeft {
  width: 100%;
  border-right: unset;
  min-width: 200px;
  padding: 20px;
}
.productPopup-bodyLeft .mob-upload-media-title{
  font-family: 'Axiforma-Bold';
    font-size: 18px;
    color: #1B2952;
    display: block;
    margin-bottom: 20px;
}
.mob-product-switch{
display: flex;
gap: 40px;
}
.image-uploadfromLink {
  height: 20px;
  padding: 0px;
  margin-bottom: 0px;
}
.image-uploadfromfile.divSelected {
  color: #3064f9;
  background-color: unset;
 
}
.image-uploadfromfile{
  height: 20px;
  padding: 0px;
  margin-bottom: 0px;
}
.productUpload-swich-delete{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-Product-img {
  width: 24.2%;
}
.Uploaded-product-img {
  width: 24.2%;
}
.addNewproductPopup .modal-footer{
  display: none;
}
.mobDoneError{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px 20px;
}
.alertIcon{
  height: 12px;
  width: 12px;
  margin-right: 6px;
}
.mobDoneError .productadd-error{
  
  font-family: 'Axiforma-Regular';
    font-size: 12px;
    text-align: left;
    color: #FF4848;
    margin: 10px 0px 20px;
    display: flex;
        align-items: center;
}
.mobDoneError .productadd-errorButton{
  width: 100%;
    margin: 0px 15px;
    height: 50px;
    background-color: #3064F9;
    border: unset;
    border-radius: 4px;
    color: #ffff;
    font-family: 'Axiforma-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 21.5px;
}
.productUpload-mainDiv {
  width: 100%;
  height: calc(100% - 103px);
  overflow: auto;
}
}
@media (max-width: 700px) {

  .upload-Product-img {
    width: 32.5%;
  }
  .Uploaded-product-img {
    width: 32.5%;
  }

}
@media (max-width: 600px) {

  .upload-Product-img {
    width: 49%;
  }
  .Uploaded-product-img {
    width: 49%;
  }
  .uploaded-productImg {
    padding: 20px;
    column-gap: 2%;
  }

}
