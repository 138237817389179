.dropdown-selectflag {
    background-color: white;
    z-index: 1;
    width: 100%;
    top: 51px;
    left: 0;
    height: 340px;
    position: absolute;
    box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
}
.dropdown-selectflagtop {
    height: 50px;
    border: none;
    padding: 0px 20px 0px 20px;
    border-bottom: 1px solid #f1f2f8;
    align-items: center;
    display: flex;
}
.dropdown-select-searchfleginput {
    width: 100%;
    border: none !important;
    height: 28px !important;
    height: 56px;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    border: 1px solid #e3e6f1;
    outline: none !important;
    color: #1b2952;
    background-color: #fff !important;
}
.dropdown-selectflagtop .clear-search{
    margin-bottom: 0px;
    color: #bcc1ce;
    font-size: 12px;
    cursor: pointer;
}
.common-countrydiv {
    padding: 7px 10px;
    height: 290px;
    overflow-y: auto;
}
.common-countryflagtype {
    cursor: pointer;
    border-radius: 4px;
    display: grid !important;
}
.common-countryflagtype .country-line-flag-name-div {
    padding: 13px 10px;
    display: flex;
    align-items: center;
}
.country-flag-and-name{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.country-line-flag-name-div img {
    width: 16px;
    height: 16px;
}
.common-countryflagtype .country-name {
    padding-bottom: 0px;
    font-size: 13px;
    margin-left: 10px;
    color: #1b2952;
    margin-bottom: 0px;
}
.country-dropdown-modal .modal-header{
    padding: 0px
}

.country-search-div {
    height: 60px;
    border: none;
    padding: 20px;
    border: none;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    display: flex;
    align-items: center;
    justify-content: center;
}
.flag-backaero-popup {
    display: block;
}
.common-countryflagtype .country-line-flag-name-div:hover {
    background-color: #F8F9FD;
}












/* /// */

.common-language-dropdown__control {
    background-color: white !important;
    outline: none !important;
    border: 1px solid #bcc1ce !important;
    height: 50px !important;
    color: #1b2952 !important;
    font-size: 13px !important;
    border-radius: 3px !important;
    padding: 0px 5px 0px 15px !important;
    cursor: pointer !important;
}
.common-language-dropdown__value-container {
    padding: 0px !important;
}
.common-language-dropdown__single-value {
    color: #1b2952 !important;
    font-size: 13px !important;
    font-family: "Axiforma-Regular" !important;
}
.common-language-dropdown__menu {
    margin-top: 0px !important;
    padding: 7px 10px !important;
    box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16) !important;
}
.common-language-dropdown__menu-list {
    padding: 0px;
}

.common-language-dropdown__option {
    padding: 13px 10px !important;
    font-size: 13px !important;
    color: #1b2952 !important;
    font-family: "Axiforma-Regular" !important;
    cursor: pointer;
    background-color: transparent !important;
}
.common-language-dropdown__option:hover{
    background-color: #F8F9FD;
}

.common-language-dropdown__option:focus{
    background-color: #F8F9FD;
}

.common-language-dropdown__input{
    color: #1b2952 !important;
    font-size: 14px !important;
    font-family: "Axiforma-Regular" !important;
}


.productTechnologyModal-body-head{
    font-family: 'Axiforma-Bold';
    font-size: 18px;
    text-align: center;
    color: #1B2952;
    margin-bottom: 20px;
}
.productTechnologyModal-body-para{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    line-height: 18.82px;
    text-align: center;
    color: #7782A1;
    margin-bottom: 30px;
}
.productTechnologyModal .modal-body{
    padding: 23px 40px 60px 40px;
}
.productTechnologyModal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.productTechnologyModal-cancel{
    border: 1px solid #C8CEDB;
    border-radius: 4px;
    width: 140px;
    height: 44px;
    line-height: 44px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
}
.productTechnologyModal-cancel-text{
    color: #7782A1;
    font-size: 14px;
    margin-bottom: 0px;
    font-family: "Axiforma-Regular" !important;
}
.productTechnologyModal-ok{
    background-color: #3064f9;
    border: 1px solid #3064f9;
    border-radius: 4px;
    width: 140px;
    height: 44px;
    line-height: 44px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.productTechnologyModal-ok:hover{
    background-color: #134bec;
}
.productTechnologyModal-ok-text{
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 0px;
    font-family: "Axiforma-Regular" !important;
}
.transfer-modal-div{
max-width: 460px !important;
 max-height: 240px !important;
}
.product-change-modal{
    max-width: 600px !important;
    max-height: 560px !important;
}
.confirm-app-delete-modal{
    max-width: 600px !important;
    max-height: 340px !important;
}
.leaveAppModal{
    max-width: 460px !important;
    max-height: 240px !important;
   
}
.location-modal-div-text{
    display: flex;
    flex-direction: column;
}
.map-modal-popup{
    max-width: 700px !important;
    max-height: 350px !important;
}
.technology-change-modal{
    max-width: 480px !important;
    max-height: 368px !important;
}
.map-goback-iconimg{
    display: none;
}
@media (max-width: 1200px) {
    .map-goback-iconimg{ 
     display: block;
     min-width: 20.759px;
    }
    .productTechnologyModal .modal-body {
        display: flex;
            align-items: center;
            padding: 23px 15px 60px 15px;
    }
    .productTechnologyModal-ok{
        width: 280px;
    height: 50px;
    }
    .productTechnologyModal-footer {
        flex-direction: column-reverse;
    }
    .productTechnologyModal-cancel{
 border: unset;
    }
    .productTechnologyModal-cancel-text{
        font-size: 16px;
    }
    .productTechnologyModal-ok-text{
        font-size: 16px;
    }
    .flag-backaero-popup {
        display: flex;
    }
    .countrycode-flag-div {
        margin-top: 15px;
    }
    .countrycode-flag-div .country-line-flag-name-div {
        padding: 25px 20px;
        display: flex;
                align-items: center;
                justify-content: space-between;
    }
    .mob-country-flag-name-div{
        display: flex ;
        align-items: center;
        justify-content: start;
    }
    .country-search-div .mob-flagsearch-input {
        width: calc(100% - 60px);
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        border: none;
        height: 50px;
        color: #1b2952;
        font-size: 14px;
        font-family: "Axiforma-Regular";
    }
    .flag-code-selector{
        overflow-y: scroll;
        padding: 0px !important;
    }
    .country-search-div .mob-flagsearch-input::placeholder{
        color: #C8CEDB;
    }
    .country-search-div .cleartext {
        margin-bottom: 0px;
        color: #bcc1ce;
        font-size: 12px;
    }
    .country-line-flag-name-div .country-name{
        padding-bottom: 0px;
    font-size: 16px;
    margin-left: 10px;
    color: #1b2952;
    margin-bottom: 0px;
    }





/* ////// */

.language-country-search {
    height: 60px;
    border: none;
    padding: 20px;
    border: none;
    box-shadow: 0px 0px 6px rgba(188, 193, 206, 0.36);
    display: flex;
    align-items: center;
    justify-content: center;
}
.language-code-selector{
    overflow-y: scroll;
    padding: 0px !important;
}
.mob-popup-language-name-div {
    padding-top: 43px;
    overflow: auto;
    height: calc(100vh - 80px);
}
.mob-language-name{
    font-size: 16px;
    color: #1b2952;
    font-family: "Axiforma-Regular";
    margin: 0px 20px 40px;
    display: block;
}

}