.right_pannel {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgb(0, 0, 0, 0.06);
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #E3E6F1;
}

.page_heading {
  margin: 0px 0px;
  padding: 18px 0;
  border-bottom: 1px solid #e0e5ec;
}

.page_heading h3 {
  font: 18px "Axiforma-Bold";
  margin-bottom: 0px;
  color: #1b2952;
}

.save_btn {
  background-color: #3064f9;
  text-decoration: none;
  border-radius: 2px;
  color: white;
  font: 14px "Axiforma-Regular";
  width: 115px;
  border: 1px solid #3064f9;
  cursor: pointer;
  height: 40px;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}

.toggle_heading span {
  color: #c8cedb;
  font: 13px "Axiforma-Regular";
  margin-left: 15px;
}
.toggle_heading {
  padding: 20px;
  background: #f1f5fd;
  cursor: pointer;
}
.setborder {
  border-top: 1px solid #dbe4f3;
}
.page_body {
  padding: 30px;
}

.toggle_parent {
  /* border: 1px solid #dbe4f3;
  border-radius: 4px; */

  background: #f8fafe;
}

.success_install {
  color: #47ce70;
}

.slider {
  background-color: #bcc1ce;
  background-image: url(../../assets/images/dashboard/togglecross.png);
  background-size: 7.5px 7.5px;
  background-repeat: no-repeat;
  background-position: bottom 7.5px right 10px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 18px;
  left: 2px;
  position: absolute;
  border-radius: 50%;
  transition: 0.4s;
  width: 18px;
}
input:checked + .slider:before {
  transform: translateX(22px);
}

input:checked + .slider {
  background-color: #3064f9;
  background-image: none;
}

.upload_new {
  /* background-color: #F8FAFE; */
  padding: 20px;
}

.upload_new h5 {
  color: #7782a1;
}
.connect_green {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #47ce70;
  width: 150px;
  border-radius: 50px;
  margin-left: auto;
  padding: 8px 11px;
}
.stbackground span {
  font-family: "Axiforma-Regular";
}
.connect_grey {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c8cedb;
  width: 150px;
  border-radius: 50px;
  margin-left: auto;
  padding: 8px 11px;
}
.connect_red {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffa2a2;
  width: 150px;
  border-radius: 50px;
  margin-left: auto;
  padding: 8px 11px;
}
.connect_green p,
.connect_red p,
.connect_grey p {
  margin-bottom: 0px;
  font: 11px "Axiforma-Bold";
  color: #1b2952;
  margin-left: 10px;
}
.recommend {
  color: #bcc1ce !important;
}
.arrowdirection {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}
.arrowdirectionreverse {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.stbackground {
  background: none !important;
}
@media screen and (max-width: 1200px) {
  .right_pannel{
    margin: 0px 15px;
  }
  .save_btn {
    width: 100%;
  }
  /* .recommend{
        display: none;
    } */
  .toggle_heading span {
    font: 11px "Axiforma-Regular";
    margin-left: 0px;
  }
  .setborder h3 {
    width: calc(100% - 63px);
  }
  .upload_new {
    padding: 15px;
  }
  .upload_new li {
    line-height: 21px;
    margin-bottom: 10px;
  }
  .page_heading {
    padding: 0px;
    border-bottom: none;
  }
  .page_heading h3 {
    font: 20px "Axiforma-Bold";
  }
  .toggle_heading {
    padding: 15px;
  }
  .page_body {
    padding: 20px 15px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  /* .ipad_head_padding {
    padding-top: 18px;
    padding-bottom: 18px;
  } */
}
@media (max-width: 767px) {
  .slider {
    background-size: 7px 7px;
    background-position: bottom 6.4px right 7.5px;
  }
  .switch {
    align-self: flex-end;
    height: 20px;
    width: 36px;
  }
  .slider:before {
    height: 16px;
    width: 16px;
  }
  input:checked + .slider:before {
    transform: translateX(16px);
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  input:checked + .slider:before {
    transform: translateX(23px);
    bottom: 2px;
    left: 1px;
  }
}
