@font-face {
    font-family: "OpenSans-Semibold";
    src: url(../../../assets/fonts/OpenSans-Semibold.ttf);
  }
  @font-face {
    font-family: "OpenSans-Regular";
    src: url(../../../assets/fonts/OpenSans-Regular.ttf);
  } 

  .horizontal-slider {
    width: 100%;
    /* max-width: 500px; */
    height: 50px;
    /* border: 1px solid grey; */
}
.horizontal-slider .example-track {
    top: 20px;
    height: 4px;
}
.agency-report-aerotopsecond{

    width: 0;
    height: 0;
    /* margin: 200px auto; */
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #E3E6F1;
    top: 29px;
    z-index: 9991;
    right: 30px;
}
.agency-report-aerotopsecond:before{
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 13px solid #ffff;
    top: 1px;
    left: -8px;
}

.agency-report-day-aerotopsecond{

    width: 0;
    height: 0;
    /* margin: 200px auto; */
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #E3E6F1;
    top: 29px;
    z-index: 9991;
    right: 0px;
}
.agency-report-day-aerotopsecond:before{
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 13px solid #ffff;
    top: 1px;
    left: -8px;
}

.agency-dropdown-border-right .dropdown:first-child {
    padding-right: 30px;
    border-right: 1px solid #e3e6f1;
}
.agency-dropdown-border-right .dropdown-item.disabled, .agency-dropdown-border-right .dropdown-item:disabled{
    color: #E3E6F1 !important;
    background-color: unset !important;
    cursor: not-allowed !important;
    pointer-events: unset;
}
.example-track.example-track-0 {
    background: #E3E6F1;
}
.example-track.example-track-1 {
    background: #E3E6F1;
}
.horizontal-slider .example-thumb {
    top: 11%;
    width: 30px;
    height: 30px;
    line-height: 38px;
    background: url(../images/sliders.png) transparent;
  background-size: contain;
}

.example-thumb {
    font-size: 0.9em;
    text-align: center;
    /* background-color: black; */
    color: rgb(255 255 255 / 0%);
    cursor: pointer;
    /* border: 5px solid gray; */
    box-sizing: border-box;
}
.bar-graph-head .dropdown-menu .disabled.dropdown-item:hover {
    color: #E3E6F1 !important;
    background-color: unset !important;
}
.horizontal-slider .example-mark {
    margin: 0px calc(19px);
    bottom: calc(50% - 1px);
}

.example-mark {
    width: 8px;
    height: 8px;
    /* border: 2px solid rgb(0, 0, 0); */
    /* background-color: rgb(255, 255, 255); */
    background-color: #BCC1CE;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
}
  
.org-header{
    position: fixed;
    top: 0;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgb(188 193 206 / 36%);
    width: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
}
.org-header .container{
    max-width: 1910px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-menu .menu-items{
    margin-right: 30px;
}
.create-org{
    width: 1080px;
    display: flex;
    margin: auto;
    margin-top: 260px;
}
.create-org-btn{
    border: 1px solid #3064F9;
    height: 40px;
    background-color: #3064F9;
    border-radius: 3px;
    width: 174px;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    position: relative;
}
.create-org-btn:hover{
    color: #fff;
    transition: 0.5s;
    background-color: #134BEC;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.create-org-btn img{
    margin-right: 5px;
}
.create-org-btn a{
    position: absolute;
    width: 100%;
    height: 100%;
}
.create-org-btn p{
    color: #fff;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
}
.org-created{
    margin-top: 30px;
}
.org-summry{
    width: calc(100% - 440px);
}
.org-detail{
    width: 1080px;
    padding: 20px 30px 20px 20px;
    border: 1px solid #E3E6F1;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.org-selected-logo{
    background-color: #F8FAFE;
    border-radius: 4px;
    min-width: 200px;
    height: 120px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.org-selected-logo img{	
    max-width: 180px;	
    max-height: 100px;	
}
.org-selected-logo img{
    max-width: 180px;
    max-height: 100px;
}
.org-summry h2{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 15px;
}
.org-summry h4{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin: 0px;
}
.org-summry h5{
    font-family: "Axiforma-Regular";
    font-size: 11px;
    color: #BCC1CE;
    margin-top: 15px;
    margin-bottom: 12px;
}
.org-summry h6{
    font-family: "Axiforma-Regular";
    font-size: 10px;
    background-color: #E3E6F1;
    border-radius: 20px;
    width: max-content;
    color: #7782A1;
    padding: 3px 8px 2px 8px;
    margin: 0px;
}
.org-all-subs{
    min-width: 120px;
}
.org-all-subs p{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
}
.org-all-apps{
    min-width: 100px;
    text-align: right;
}
.org-all-apps h3{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
}
.org-structure{
    background-color: #fcfdff;
    display: flex;
    min-height: 100vh;
    position: relative;
    max-width: 100%;
    margin: auto;
}
.org-left-panel{
    width: 200px;
    background: white;
    box-shadow: 0px 0px 6px rgb(112 155 250 / 20%);
    position: fixed;
    height: 100%;
    margin-top: 60px;
    z-index: 1;
}
.org_background {
    background-color: #f8fafe;
    padding: 24px 30px 30px 30px;
    width: calc(100% - 200px);
    margin-left: 200px;
    margin-top: 60px;
    position: relative;
    transition: 0.4s;
    min-height: calc(100vh - 60px);
}
.org_background-expand{
    width: calc(100% - 60px);
    margin-left: 60px;
}
.left-pannel-menu{
    margin-top: 30px;
}
.left-pannel-menu div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.left-pannel-menu div img{
    margin: 0px 10px 0px 18px;
}
.left-pannel-menu div{
    cursor: pointer;
    transition: 1s;
}
.left-pannel-menu div p{
    margin-bottom: 0px;
    color: #1B2952;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    transition: 1s;
}
.left-pannel-menu div:hover p{
    margin-bottom: 0px;
    color: #3064F9;
    font-size: 13px;
    transition: 1s;
}
.left-pannel-menu div.active p{
    margin-bottom: 0px;
    color: #3064F9;
    font-size: 13px;
    transition: 1s;
    font-family: "Axiforma-Bold";
}
.org-setting-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
    min-height: 60px;
}
.org-setting-head h1{
    font-family: "Axiforma-Bold";
    font-size: 24px;
    color: #1B2952;
    margin-bottom: 0px;
}
.org-setting-head p{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
}
.org-setting-head p a{
    color: #3064F9;
}
.leave-org-btn-deactivate{
    width: 180px;
    height: 40px;
    border: 1px solid #BCC1CE;
    background-color: transparent;
    font-size: 13px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
}
.leave-org-btn-deactivate svg{
    margin-right: 6.5px;
    stroke: #bcc1ce;
}
.leave-org-btn-activate{
    width: 180px;
    height: 40px;
    border: 1px solid #3064F9;
    background-color: transparent;
    font-size: 13px;
    color: #3064F9;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.leave-org-btn-activate svg{
    margin-right: 6.5px;
    stroke: #3064F9;
}
.leave-org-btn-activate:hover{
    border: 1px solid #134BEC;
    color: #134BEC;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.save-setting-btn{
    width: 100px;
    height: 40px;
    border: none;
    background-color: #3064F9;
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: .5s;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
}
.save-setting-btn .new-loader{
    margin: auto;
    position: relative;
    top: 11px;
}
.save-setting-btn:hover {
    background-color: #134BEC;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.agency-plugin-btn-deactivate, .agency-plugin-btn-deactivate:hover {
    background-color: #BCC1CE;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;
}
.org-setting-body{
    background-color: #fff;
    padding: 30px 30px 40px 30px;
    border-radius: 6px;
}
.org-setting-body label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 9px;
    display: block;
}
.org-setting-body label img{
    margin-left: 6px;
}
.org-setting-body input{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    background-color: #fff;
    padding: 0px 20px;
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    display: block;
    transition: .5s;
}
.org-setting-body input:hover{
    transition: .5s;
    background-color: #F8FAFE;
    border-color: #3064F9!important;
}
.org-setting-body h3{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 5px;
}
.org-setting-body h4{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 27px;
}
.support-disable , .support-disable:hover{
    background-color: #F8FAFE!important;
    cursor: not-allowed;
    border: 1px solid #E3E6F1!important;
} 
.org-image-uploader {
    border: 1px solid #BCC1CE;
    background-color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    transition: .5s;
}
.org-image-uploader:hover{
    transition: .5s;
    background-color: #F8FAFE;
    border-color: #3064F9!important;
}
.org-image-uploader
.org-image-uploader .d-flex {
    width: calc(100% - 50px);
}

.org-image-uploader .uploadpixabayimage {
    background-position: 50% !important;
    height: 38px;
    width: 38px;
    min-width: 38px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    margin: 2px 10px 2px 5px;
    border-radius: 4px;
}
.org-image-uploader p{
    color: #1B2952;
    font-size: 13px;
    margin-bottom: 0px;
}
.org-color-input .color_input {
    border: 1px solid #BCC1CE;
    background-color: #fff;
    height: 50px;
    padding: 5px 5px 5px 3px;
    border-radius: 3px;
    transition: .5s;
}
.org-color-input .color_input:hover{
    transition: .5s;
    background-color: #F8FAFE;
    border-color: #3064F9!important;
}
.org-color-input p{
    color: #1B2952;
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 15px;
    font-family: "Axiforma-Regular";
}
.org-module{
    background-color: #F8FAFE;
    min-height: 100vh;
}
.org-module-customer-div{
    height: calc(100vh - 70px) !important;
    overflow: auto !important;
}
.org-module-shadow{
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
}
.delete-org{
    background-color: #fff;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
}
.delete-org h3{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 10px;
    margin-top: 0px;
}
.delete-org h4{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
    margin-top: 0px;
}
.delete-org a{
    width: 170px;
    height: 40px;
    border: 1px solid #E3E6F1;
    background-color: transparent;
    font-size: 14px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    display: block;
    text-align: center;
    line-height: 40px;
    transition: .5s;
}
.delete-org a:hover{
    background-color: #FF4848;
    border: 1px solid #FF4848;
    color: #fff;
    transition: .5s;
}
.upload-org-logo{
    border-bottom: 1px solid #E3E6F1;
    padding-bottom: 30px;
}
.left-panel-logo{
    margin: 20px 15px;
    background-color: #F8FAFE;
    border-radius: 4px;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 33px 17px;
    cursor: pointer;
    width: 170px;
    overflow:hidden;
}
.upload-org-logo h5{
    text-align: center;
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #1B2952;
    margin-bottom: 0px;
    margin-top: 0px;
}
.upload-org-logo h6{
    width: max-content;
    margin: auto;
    text-align: center;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin-bottom: 0px;
    margin-bottom: 10px;
    cursor: pointer;
}
.confirm-org-popups h4{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
}
.confirm-org-popups h5{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 20px;
}
.confirm-org-popups h5 span{
    color: #1B2952;
}
.confirm-org-popups h6{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 20px;
    margin-top: 20px;
}
.confirm-org-popups input{
    border: 1px solid #E3E6F1;
    border-radius: 3px;
    background-color: #fff;
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    outline: none;
    font-family: "Axiforma-Regular";
    font-size: 13px;
}
.confirm-org-popups .confirm-org-body{
    padding: 30px 20px 10px 20px;
}
.confirm-org-footer{
    border-top: 1px solid #E3E6F1;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.confirm-org-popups .modal-body , .delete-org-popups .modal-body{
    padding: 0px;
}
.confirm-org-footer-button-cancel{
    border: 1px solid #BCC1CE;
    width: 100px;
    height: 40px;
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    font-size: 14px;
    transition: .5s;
}
.confirm-org-footer-button-cancel:hover{
    transition: .5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.confirm-org-footer-button-delete{
    border: 1px solid #FF4848;
    width: 100px;
    height: 40px;
    background-color: transparent;
    border-radius: 3px;
    color: #FF4848;
    font-size: 13px;
    transition: .5s;
}
.confirm-org-footer-button-delete:hover{
    transition: .5s;
    background-color: #FF4848;
    color: #ffffff;
    box-shadow: 0px 2px 4px rgb(255 72 72 / 29%);
}
.delete-org-popups h4{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    text-align: center;
    width: 70%;
    margin: auto;
    line-height: 34px;
    margin-bottom: 20px;
}
.delete-org-popups h5{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 25px;
    text-align: center;
    width: 78%;
    margin: auto;
}
.delete-org-popups .delete-org-body{
    padding: 30px 20px 10px 20px;
}
.delete-org-footer{
    padding: 20px 20px 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.delete-org-footer button:nth-child(1){
    border: 1px solid #BCC1CE;
    width: 140px;
    height: 44px;
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
    transition: .5s;
}
.delete-org-footer button:nth-child(2){
    border: 1px solid #FF4848;
    width: 140px;
    height: 44px;
    background-color: transparent;
    border-radius: 3px;
    color: #FF4848;
    font-size: 14px;
    text-transform: capitalize;
    margin-left: 10px;
    transition: .5s;
}
.delete-org-footer button:nth-child(1):hover{
    transition: .5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.delete-org-footer button:nth-child(2):hover{
    transition: .5s;
    box-shadow: 0px 2px 4px rgb(255 72 72 / 29%);
}
.org-support-body{
    background-color: #fff;
    padding: 0px 30px 35px 30px;
    border-radius: 6px;
}
.org-support-body label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    display: block;
}
.org-support-phone input{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: none;
    border-left: 1px solid #BCC1CE;
    background-color: transparent;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    padding: 0px 20px;
    outline: none;
}
.org-support-body input{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    background-color: #fff;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    padding: 0px 20px;
    outline: none;
    transition: .5s;
}
.org-support-body input:hover , .org-support-body input:focus{
    transition: .5s;
    background-color: #F8FAFE;
    border: 1px solid #3064F9;
}
.org-support-phone{
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 50px;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
}
.org-support-phone h6{
    min-width: 115px;
    margin-bottom: 0px;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    padding-left: 15px;
    cursor: pointer;
    line-height: 48px;
    transition: .5s;
    border: 1px solid #BCC1CE;
    border-right: none;
}
.org-support-phone h6 svg{
    margin-left: 10px;
}
.org-support-phone p:hover{
    transition: .5s;
    background-color: #F8FAFE;
    border-color: #3064F9;
}
.org-support-phone .profile-selectflagtop p{
    position: relative;
    left: -15px;
}
.org-domain-body{
    background-color: #fff;
    border-radius: 6px;
}
.org-domain-body label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    display: block;
    margin-top: 17px;
    margin-bottom: 9px;
}
.org-domain-body input{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    padding: 0px 20px;
    outline: none;
    background-color: #FFFFFF;
    transition: .5s;
}
.org-domain-body input:hover , .org-domain-body input:focus{
    background-color: #F8FAFE;
    border-color: #3064F9;
    transition: .5s;
}
.org-domain-body textarea{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    height: 120px;
    width: 100%;
    border-radius: 3px;
    padding: 10px 20px;
    outline: none;
    background-color: #FFFFFF;
}
.org-domain-body textarea:hover , .org-domain-body textarea:focus{
    background-color: #F8FAFE;
    border-color: #3064F9;
    transition: .5s;
}
.org-domin-left{
    padding: 10px 0px 40px 30px;
  }
.org-domin-right{
    min-height: 492px;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }  
  .plugin-preview{
    max-width: 451px;
  }
  .plugin-preview h4{
    color: #BCC1CE!important;
    font-family: "Axiforma-Regular";
    font-size: 16px!important;
    margin-bottom: 9px!important;
  }
  .plugin-preview-box{
    background-color: #F9F9F9;
    padding: 18px 20px 13px 18px;
  }
  .plugin-preview-box h2{
    font-family: "OpenSans-Regular";
    font-size: 18px;
    color: #0073AA;
    margin-bottom: 8px;
  }
  .plugin-preview-box p{
    font-family: "OpenSans-Semibold";
    font-size: 14px;
    color: #32373C;
    line-height: 19px;
    width: 77%;
    margin-top: 9px;
    margin-bottom: 0px;
    min-width: 400px;
  }  
  .plugin-preview-box h5{
    font-family: "OpenSans-Semibold";
    font-size: 13px;
    color: #32373C;
    margin: 24px 0px 0px 0px;
    border-top: 2px solid #fff;
    padding-top: 13px;
  } 
  .plugin-preview-box h5 svg{
    margin-right: 6px;
  }
  .org-social-tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .org-social-tabs h4{
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 22px;
  }
  .org-social-tabs h4 span{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin-left: 10px;
  }
  .org-facebook-login{
    border-bottom: 1px solid #E3E6F1;
    padding: 30px 0px 30px 0px;
  }
  .org-google-login{
    border-bottom: 1px solid #E3E6F1;
    padding: 30px 0px 30px 0px;
  }
  .org-social-body {
    background-color: #fff;
    padding: 0px 30px 30px 30px;
    border-radius: 6px;
}
.org-social-body label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    display: block;
    margin: 0px;
}
.org-social-body input{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    padding: 0px 20px;
    outline: none;
    transition: .5s;
}
.org-social-body input:hover{
    transition: .5s;
    background-color: #F8FAFE;
    border-color: #3064F9!important;
}
.org-social-body textarea{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    height: 120px;
    width: 100%;
    border-radius: 3px;
    padding: 10px 20px;
    outline: none;
}
.org-social-body textarea::placeholder {
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
  }
  .org-login-form{
    background-color: #fff;
    padding: 50px 60px 63px 60px;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
  }
  .org-social-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .org-login-form h5{
    color: #E3E6F1;
    font-family: "Axiforma-Bold";
    font-size: 35px;
    margin-bottom: 18px;
  }
  .org-social-right {
    min-height: 520px;
    padding: 80px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: auto;
}
.org-clickable {
    position: relative;
}
.org-clickable .org-anchor{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.org-email-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 40px;
}
.org-email-head h1 {
    font-family: "Axiforma-Bold";
    font-size: 24px;
    color: #1B2952;
    margin: 0px;
}
.org-email-head p {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
}
.verify-org-email {
    width: 111px;
    height: 40px;
    border: 1px solid #3064F9;
    background-color: #3064F9;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    text-align: center;
    line-height: 39px;
    cursor: pointer;
    transition: .5s;
    position: relative;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.verify-org-email .new-loader{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.verify-org-email:hover{
    background-color: #134BEC;
    border: 1px solid #3064F9;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.save-org-email {
    width: 100px;
    height: 40px;
    line-height: 39px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: .5s;
    border: 1px solid #3064F9;
    color: #3064F9;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.org-email-body{
    background-color: #fff;
    border-radius: 6px;
}
.org-email-body h4 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
}
.org-email-body input {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #1B2952;
    border: 1px solid #BCC1CE;
    height: 50px;
    width: 100%;
    border-radius: 3px;
    padding: 0px 20px;
    outline: none;
    background-color: #fff;
    transition: .5s;
}
.org-email-body input:hover , .org-email-body input:focus{
    background-color: #F8FAFE;
    border-color: #3064F9!important;
    transition: .5s;
}
.org-email-right {
    padding: 70px 0px;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.org-email-settings {
    padding: 25px 0px 0px 30px;
}
.org-email-body .col-md-3 select{
    -webkit-appearance: none;
    font: 14px "Axiforma-Regular2";
    border: 1px solid #BCC1CE;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    outline: none !important;
    background: #fff url(../images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 92%;
  }
  .org-email-settings h1{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
  }
  .org-email-settings h2{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
  }
  .emailpreview{
    background-color: #fff;
    width: 560px;
    padding: 40px 0px 36px 47px;
    border-radius: 6px;
    border: 2px solid #E3E6F1;
  }
  .emailpreview{
    position: relative;
  }
  .emailpreview p{
    position: absolute;
    left: 150px;
    top: 38px;
    font-size: 13px;
    color: #1B2952;
    font: 14px "Axiforma-Bold";
  }
  .emailpreview p span{
    color: #7782A1;
    font-size: 13px;
    font: 14px "Axiforma-Regular";
  }
  .email-form-address{
    position: relative;
  }
.input-box-checkbox {
    display: block;
    position: relative;
    padding-left: 31px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -ms-user-select: none;
    user-select: none;
}
.organization-checkmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 3px;
}
.organization-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.input-box-checkbox p {
    color: #1B2952!important;
    font-size: 13px!important;
    font-family: "Axiforma-Regular";
}

.input-box-checkbox input:checked ~ .organization-checkmark {
    background-color: #3064F9;
}
.input-box-checkbox input:checked ~ .organization-checkmark:after {
    display: block;
}
.input-box-checkbox .organization-checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.grey-strip-message {
    border-radius: 3px;
    background-color: #7782A1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    margin-bottom: 10px;
    margin-top: -10px;
}
.grey-strip-message div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.grey-strip-message p{
    font: 14px "Axiforma-Regular";
    color: #FFFFFF;
    margin-bottom: 0px;
    margin-left: 8px;
}
.grey-strip-message span{
    font: 13px "Axiforma-Regular";
    color: #E3E6F1;
    margin-bottom: 0px;
}
.grey-strip-message span a{
    text-decoration: underline;
    margin-left: 3px;
    color: #FFFFFF;
}
.org-team-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
    min-height: 60px;
}
.org-team-head h1 {
    font-family: "Axiforma-Bold";
    font-size: 24px;
    color: #1B2952;
    margin-bottom: 0px;
}
.org-team-head p {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
}
.save-team-btn {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #3064F9;
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: .5s;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
}

.save-team-btn:hover{
    background-color: #134BEC;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.save-team-btn img{
    margin-right: 6px;
}
.team-search {
    width: 330px;
    height: 40px;
    border: 1px solid #E3E6F1;
    border-radius: 0px;
    overflow: hidden;
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.team-search input {
    border: none;
    height: 40px;
    outline: none;
    padding-left: 15px;
    width: calc(100% - 45px);
    font-family: "Axiforma-Regular";
    font-size: 13px;
}
.team-search button {
    border: none;
    background-color: transparent;
    height: 40px;
    outline: none;
    width: 44px;
    border-left: 1px solid #E3E6F1;
}
.org-head-right{
    display: flex;
    align-items: center;
}
.org-head-right select{
    -webkit-appearance: none;
    font: 13px "Axiforma-Regular2";
    border: 1px solid #E3E6F1;
    border-radius: 0px;
    color: #7782A1;
    height: 40px;
    width: 163px;
    padding-left: 17px;
    outline: none !important;
    background: #fff url(../images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 92%;
    border-right: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .org-head-right p{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #BCC1CE;
    margin-bottom: 0px;
    margin-right: 20px;
  }
  .org-head-right p span{
    font-size: 16px;
    color: #7782A1;
  }
  .email-form-address p{
    color: #FF4848;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .email-form-address svg{
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .org-email-config input{
    position: absolute;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .org-email-config{
    margin-bottom: 30px;
  }
  .use-my-smtp{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .use-my-smtp h4{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px;
  }
  .verifiedstatus{
    color: #47CE70;
  }
  .unverifiedstatus{
    color: #FF4848;
  }
  .use-my-smtp-toggle{
    display: flex;
    align-items: center;
  }
  .use-my-smtp-toggle p{
    margin-bottom: 0px;
    margin-right: 20px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
  } 
  .email-setup .verifiedstatus , .email-setup .unverifiedstatus{
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
  }
  .email-setup h2{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px;
  } 
  .email-setup hr{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .email-copy{
    border-radius: 3px;
    border: 1px solid #E3E6F1;
    background-color: rgba(227, 230, 241, 0.5);
    display: flex;
    justify-content: space-between;
    padding: 17px 20px 18px 16px;
    min-height: 50px;
    width: 100%;
  }
  .email-copy h6{
    color: #7782A1;
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    width: calc(100% - 50px);
    word-break: break-all;
  }
  .email-copy svg{
    min-width: 20px;
    margin-left: 30px;
    cursor: pointer;
  }  
  .email-copy-input{
    border-radius: 3px;
    border: 1px solid #E3E6F1;
    background-color: #F8FAFE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .email-copy-input input{
    border: none!important;
    background-color: transparent!important;
    width: calc(100% - 50px);
  }
  .email-copy-input svg{
    min-width: 50px;
    cursor: pointer;
  } 
  .email-setup{
    padding-bottom: 30px;
  }
  .team-member-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E3E6F1;
    padding: 0px 20px;
    min-height: 108px;
    transition: .5s;
  }
  .member-img-name{
    display: flex;
    align-items: center;
    width: 30%;
  }
  .member-img{
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 100px;
    margin-right: 11px;
  }
  .team-member-email{
    width: 25%;
  }
  .team-member-email p{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .member-img-name h2{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px;
  }
  .member-img-name p{
    color: #7782A1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .team-member-role{
    width: 15%;
  }
  .team-member-role p{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
  }
  .team-member-time{
    width: 20%;
  }
  .team-member-time h3{
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .team-member-time h5{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .team-member-edit{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 80px;
  }
  .team-member-edit{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 80px;
  }
  .team-member-edit svg{
    cursor: pointer;
    margin-left: 40px;
  }  
  .team-member-edit svg:first-child{
    margin-left: 0px;
  }
  .org-team-body {
    background-color: #fff;
  }
  .team-member-status h6{
    font-size: 14px;
    font-family: "Axiforma-Regular";
    margin: 0px;
  }
  .team-member-status{
    width: max-content;
  }
  .teamstatusgreen{
    color: #47CE70!important;
  }
  .teamstatusred{
    color: #FF4848!important;
  }
  .teamstatusgrey{
    color: #7782A1!important;
  }
  .teams-status{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .org-domain-right {
    padding: 70px 0px;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.org-domain-settings {
    padding: 25px 0px 0px 30px;
}
.org-domain-body h4 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
}
.domain-upgrade{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.domain-upgrade a , .domain-upgrade a:hover{
    color: #3064F9;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-top: 20px;
    margin-bottom: 10px;
}
.domain-form-address {
    position: relative;
}
.domain-form-address p {
    color: #FF4848;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    /* position: absolute; */
    right: 20px;
    top: 15px;
}
.new-domain-error-message-div{
    display: flex;
    gap: 6px;
    margin-top: 10px;
    align-items: center;
}
.domain-form-address svg {
    position: absolute;
    right: 20px;
    top: 15px;
}
.org-domain-settings hr{
    margin: 30px 0px;
}
.org-domain-settings h5 {
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #1B2952;
    display: block;
    margin-bottom: 30px;
}
.org-domain-settings h6 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.org-domain-settings h6 img{
    margin-left: 6px;
    cursor: pointer;
}
.org-domain-settings h2{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 5px;
}
.org-domain-settings h3{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 27px;
}
.explain-domain-structure{
    position: relative;
}
.explain-domain-structure p{
    position: absolute;
    top: 55px;
    left: 125px;
    color: #BCC1CE;
    font-size: 16px;
    font-family: "Axiforma-Regular";
}
.subdomain-type{
    position: relative;
    color: #1B2952;
}
.subdomain-type::before{
    content:url('../images/subdomain-sign1.png');
    position: absolute;
    top: 38px;
    left: -102px;
}
.domain-type{
    position: relative;
    color: #3064F9;
}
.domain-type::before{
    content:url('../images/domain-sign.png');
    position: absolute;
    top: 38px;
    left: 25px;
}
.extension-type{
    position: relative;
    color: #3064F9;
}
/* .extension-type::before{
    content:url('../images/extension-sign.png');
    position: absolute;
    top: 34px;
    right: -82px;
} */
.errormessages{
    border: 1px solid #FFBABA!important;
    background-color: #FFFCFC!important;
}
.cname-detail{
    position: relative;
}
.cname-detail svg{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.cname-detail input{
    border: 1px solid #E3E6F1!important;
    background-color: #F8FAFE!important;
}
.domain-popup-body h4{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 20px;
    margin-top: 0px;
}
.domain-popup-body p{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 26px;
    margin-top: 17px;
    text-align: left !important;
}
.domain-popup-body ul li{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 20px;
    list-style-type: disc;
    line-height: 17px;
}
.domain-popup-body ul{
    padding-left: 15px;
}
.domain-button{
    margin-top: 40px;
}
.domain-popup-body .domain-button span{
    width: 140px;
    background-color: #3064F9;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;
}
.domain-popup-body .domain-button span:hover{
    background-color: #134BEC;
    transition: .5s;
}
.domain-popup-body{
    padding: 34px 40px 40px 40px!important;
}
.domain-popup-body h5{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-top: 10px;
}
.org-dashboard{
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.org-dashboard-midsection{
    width: calc(100% - 400px);
}
.org-dashboard-right-panel {
    background-color: #fff;
    width: 400px;
    filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
    border-radius: 4px;
    padding: 27px 20px 20px 20px;
}
.org-dash-mid-panel{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.org-app-info {
    background-color: #fff;
    width: 49%;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E3E6F1;
}
.org-plan-info {
    background-color: #fff;
    width: 400px;
    margin: 0px 20px 0px 20px;
    filter: drop-shadow(0px 3px 6px rgba(188, 193, 206, 0.2));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-radius: 4px;
}
.org-dashboard-head h2{
    color: #1B2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin-bottom: 17px;
}
.org-dashboard-head p{
    color: #7782A1;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 21px;
}
.org-dashboard-head p a{
    color: #3064F9;
}
.org-icon {
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #F8FAFE;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.org-data{
    margin-left: 20px;
}
.org-data h3{
    color: #1B2952;
    font-size: 16px;
    font-family: "Axiforma-Bold";
    margin: 0px;
}
.org-data h4{
    color: #3064F9;
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 10px 0px 10px 0px;
}
.org-app-info h5{
    color: #7782A1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 10px 0px 10px 0px;
}
.org-app-info h6{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
}
.org-data p{
    color: #7782A1;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px;
}
.org-data p span{
    margin-left: 20px;
    color: #C8CEDB;
    font-family: "Axiforma-Regular";
}
.org-plan-info div:nth-child(1) {
    width: calc(100% - 120px);
}
.org-plan-info div:nth-child(2) {
    width: 100px;
    min-width: 100px;
    text-align: right;
}
.org-plan-info h3 {
    color: #1b2952;
    font-family: "Axiforma-Bold";
    font-size: 14px;
}
.org-plan-info p {
    color: #7782a1;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin: 10px 0px 11px 0px;
}
.org-plan-info a, .planinfo a:hover {
    color: #3064f9 !important;
    font-family: "Axiforma-Regular";
    font-size: 12px;
}
.org-plan-info h3 span {
    font-family: "Axiforma-Regular";
    font-size: 11px;
    border-radius: 20px;
    padding: 1px 8px 1px 8px;
    margin-left: 14px;
    letter-spacing: 0.5px;
}
.plangrey {
    color: #bcc1ce;
    border: 1px solid #c8cedb;
    background-color: #fcfdff;
}
  .plangreen {
    color: #47ce70;
    border: 1px solid #47ce70;
    background-color: #effff4;
}
  .planred {
    color: #ff4848;
    border: 1px solid #ff4848;
    background-color: #fefcfc;
}
.org-dash-teams{
    padding: 20px 20px 20px 20px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
}
.org-dash-teams img{
    display: none;
}
.org-teamlist {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    background-image: url(../images/blank-placeholder.png);
    background-repeat: repeat-x;
  }
.org-teamlist .org-teammember {
    width: 54px;
    margin-right: 10px;
    min-width: 54px;
    height: 54px;
    position: relative;
    background-color: #f8fafe;
    border-radius: 100%;
    background-size: cover !important;
  }
  .teammember-default-img{
background: url(../images/accountprofile.png)
  }
  .agency-analytics-spinner .sr-only{
    display: none;
  }
  .agency-mob-dropdown-selection-text{
        font-size: 16px;
        color: #1B2952;
        font-family: "Axiforma-Regular";
        margin: 0px 20px 40px 20px;
        display: block     
  }
.org-teamlist .org-teammember img {
    border-radius: 100%;
  }
.org-teamlist .org-teammember .online,
.org-teamlist .org-teammember .offonline {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0px;
    bottom: 6px;
    border: 3px solid #fff;
  }
.org-teamlist .org-teammember .online {
    background-color: #47ce70;
  }
.org-teamlist .org-teammember .offonline {
    background-color: #bcc1ce;
  }
  .org-dash-teams h3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1B2952;
    font-size: 14px;
    font-family: "Axiforma-Bold";
    margin: 0px;
  }
.btnloader{
    display: flex;
    align-items : center;
    justify-content: center;
}
.btnactive{
    border:1px solid #3064F9;
    background-color: #3064F9;
    color:#FFFFFF;
}
.btndisable , .btndisable:hover{
    background-color:#BCC1CE;
    color:#FFFFFF;
    border:1px solid #BCC1CE;
    cursor: not-allowed !important;
}
.btnsavedisable{
    border: 1px solid #E3E6F1;
    color: #BCC1CE;
    cursor: not-allowed !important;
}
.agencyspinner{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    min-height: 80vh;
}
.orgspinner {
    position: absolute;
    top: 43px;
    left: 0;
    width: calc(100% - 60px);
    height: calc(100vh - 180px);
    background: white;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0px;
    opacity: 1;
    min-height: 80vh;
    margin: 30px 30px 30px 30px;
}
.confirm-org-footer-button-delete:hover .new-red-loader{
    border: 2px solid #FFFFFF;
    border-top: 2px solid transparent;
}
.new-red-loader {
    border: 2px solid #FF4848;
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 16px!important;
    height: 16px!important;
    -webkit-animation: spin 0.6s linear infinite; /* Safari */
    animation: spin 0.6s linear infinite;
    margin: auto;
}
.new-blue-loader {
    border: 2px solid #3064f9;
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 16px!important;
    height: 16px!important;
    -webkit-animation: spin 0.6s linear infinite; /* Safari */
    animation: spin 0.6s linear infinite;
    margin: auto;
}
.new-grey-loader {
    border: 2px solid #BCC1CE;
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 16px!important;
    height: 16px!important;
    -webkit-animation: spin 0.6s linear infinite; /* Safari */
    animation: spin 0.6s linear infinite;
    margin: auto;
}
.dashboard-bar-graph{
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 20px 20px;
    border: 1px solid #E3E6F1;
    margin-top: 20px;
}
.agency-dashboard-support{
    margin-top: 30px;
}
.agency-dashboard-support .agency-dashboard-support-heading{
    color: #1B2952;
    font-size: 16px;
    line-height: 22.48px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
}
.agency-dashboard-support .agency-dashboard-support-heading-parent{
    display: flex;
    align-items: center;
    /* gap: 10px; */
}
.agency-dashboard-support-child{
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    margin-top: 20px;
}
.agency-dashboard-support-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    border-bottom: 1px solid #E3E6F1;
}
.agency-dashboard-support-header-heading{
    color: #1B2952;
    font-size: 13px;
    line-height: 18.27px;
    font-family: "Axiforma-Bold";
    margin-bottom: 2px;
}
.agency-dashboard-support-plan-name{
    color: #BCC1CE;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    margin: 0px 0px 0px 6px;
}
.agency-dashboard-support-header-subheading{
    color: #7782A1;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
}
.agency-dashboard-time{
    color: #FF4848;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    margin-right: 20px;
}
.agency-dashboard-support-header-right{
    display: flex;
    align-items: center;
}
.agency-dashboard-support-header-button{
    color: #3064F9;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px;
}
.agency-dashboard-support-body{
    padding: 20px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 21px;
}
.agency-support-body-card{
    border: 1px solid #E3E6F1;
    padding: 35px 10px 35px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    transition: .5s;
    position: relative;
}
.agency-dashboard-support-card-link{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.agency-support-link-icon{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 17px;
}
.agency-support-body-card:hover{
    border: 1px solid #6E94FF;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    transition: .5s;
}
.agency-support-body-title{
    color: #1B2952;
    font-size: 13px;
    line-height: 18.27px;
    font-family: "Axiforma-Bold";
    margin-bottom: 5px;
    margin-top: 6px;
}
.agency-support-body-description{
    color: #7782A1;
    font-size: 12px;
    line-height: 16.13px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
}
.agency-support-icon{
    margin-right: 10px;
}
.no-data-graph{
    background-color: #fff;
    width: calc(100% - 20px);
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 0px;
    border: 1px solid #E3E6F1;
    margin-top: 20px;
    overflow: hidden;
}
.dashboard-progress-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EDF0F5;
    padding-bottom: 15px;
    margin-bottom: 11px;
    width: calc(100% - 20px);
}
.dashboard-progress-bar p{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin: 0px 10px 0px 0px;
}
.progress{
    height: 3px!important;
    width: 100%;
}
.progress-bar {
    background-color: #6E94FF!important;
    height: 3px;
}
.dashboard-progress h2{
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}
.dashboard-progress h2 img{
    margin-left: 7px;
    position: relative;
    top: -4px;
}
.web-org-right-panel{
    width: 400px;
    min-width: 400px;
}
.agency-total-steps{
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
}
.agency-steps{
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    height: 76px;
    z-index: 1;
    width: 100%;
    padding: 0px 0px 0px 20px;
}
.agency-steps h4{
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 13px;
    margin-top: 0px;
}
.agency-steps p{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin-bottom: 0px;
}
.agency-steps div:nth-child(3){
    margin-right: 19px;
}
.agency-steps a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.agency-steps img{
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: .5s;
}
.agency-steps:hover img{
    opacity: 1;
    transition: .5s;
}
.agency-steps-animation{
    position: absolute;
    height: 76px;
    top: 0;
    z-index: 0;
    transition: all .2s ease 0s;
    border-radius: 4px;
}
.agency-seven-steps{
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    font-size: 0;
    display: block;
    width: calc(100% - 20px);
    padding: 0px 10px 10px 10px;
}
.agency-steps:nth-child(1):hover~.agency-steps-animation {
    width: 100%;
	top: 0;
	background-color: #F1F4FB;
}
.agency-steps:nth-child(2):hover~.agency-steps-animation {
    width: 100%;
	top: 76px;
	background-color: #F1F4FB;
}
.agency-steps:nth-child(3):hover~.agency-steps-animation {
    width: 100%;
	top: 152px;
	background-color: #F1F4FB;
}
.agency-steps:nth-child(4):hover~.agency-steps-animation {
    width: 100%;
	top: 228px;
	background-color: #F1F4FB;
}
.agency-steps:nth-child(5):hover~.agency-steps-animation {
    width: 100%;
	top: 304px;
	background-color: #F1F4FB;
}
.agency-steps:nth-child(6):hover~.agency-steps-animation {
    width: 100%;
	top: 380px;
	background-color: #F1F4FB;
}
.agency-steps:nth-child(7):hover~.agency-steps-animation {
    width: 100%;
	top: 456px;
	background-color: #F1F4FB;
}
.dashboard-progress{
    padding: 20px 0px 0px 20px;
}
.agency-data{
    display: flex;
    align-items: center;
}
.agency-app-data{
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}
.agency-app-data div{
    display: flex;
    align-self: center;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    width: 32%;
}
.agency-app-data div h3{
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 30px;
    margin: 0px 9px 0px 17px;
    padding-top: 10px;
}
.agency-app-data div p{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    margin: 0px;
    padding-top: 20px;
}
.create-agency {
    display: -webkit-flex;
    display: flex;
}
.create-agency-main{
    display: flex;
}
.create-agency-left {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 60px 0 0;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 100vh;
}
.create-agency-right {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-flex;
    display: flex;
    padding: 60px 0 0;
    background-color: #f8fafe;
    height: 100vh;
}
.create-agency-left h1{
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 40px;
}
.create-agency-left h2{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    margin: 17px 0px 23px 0px;
}
.create-agency-left label{
    display: block;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 13px;
}
.create-agency-left input{
    display: block;
    height: 50px;
    border: 1px solid #BCC1CE;
    border-radius: 3px;
    width: 100%;
    max-width: 480px;
    outline: none;
    padding: 0px 20px;
    transition: .5s;
    font-family: "Axiforma-Regular";
    font-size: 13px;
}
.create-agency-left input:hover , .create-agency-left input:focus{
    border: 1px solid #3064f9;
    background-color: #f8fafe;
    transition: .5s;
}
.create-agency-left #btncreateorgid{
    width: 100%;
    max-width: 480px;
    background-color: #3064F9;
    height: 50px;
    border: 1px solid #3064F9;
    margin-top: 20px;
    color: #fff;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    border-radius: 3px;
    transition: .5s;
}
.create-agency-left #btncreateorgid:hover{
    background-color: #134BEC;
    transition: .5s;
}
.create-agency-left-position{
    margin-top: 25vh;
}
.create-agency-right-position{
    margin-top: 21vh;
    text-align: center;
}
.create-agency-right-position img{
    margin: auto;
}
.create-agency-right-position .signup-crousel{
    margin-top: 30px;
}
.org-cname-body {
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
}
.org-cname-body{
    padding: 26px 30px 40px 30px;
    margin-top: 20px;
}
.org-cname-body h5{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.org-cname-body span{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
    cursor: pointer;
    transition: .5s;
}
.org-cname-body span:hover{
    color: #3064F9;
    transition: .5s;
}
.org-cname-body h6{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 10px;
}
.domain-steps{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 120px;
}
.setup-domain{
    display: flex;
    align-items: center;
    margin-top: 61px;
    margin-bottom: 30px;
}
.setup-domain p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px 0px 10px 0px;
}
.setup-domain h3{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
}
.setup-domain img{
    margin-right: 20px;
}
.org-email-body .img-ratio > input:checked + div {
    background-color: #BCC1CE;
}
.org-email-body .img-ratio > input:checked + div p{
    color: #FFFFFF;
}
.smtpcredentials{
    padding: 0px 30px 41px 30px;
}
.agency-web-timestamp-main-div{
    display: flex;
    align-items: center;
}
.smtp-view-error-btn{
   color:#3064F9;
   font-size:13px;
   cursor:pointer;
   margin-left:20px
}
.org-email-body .select-img-ratio {
    background-color: #fff;
}
.org-email-body .select-img-ratio {
    border: 1px solid #BCC1CE;
}
.org-social-tabs h6{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}
.no-customer{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 180px);
    border-radius: 6px;
}
.no-customer div{
    text-align: center;
}
.no-customer p{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    margin: 32px 0px 30px 0px;
}
.no-customer a , .no-customer a:hover{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #3064F9;
    margin: 0px;
}
.no-customer a svg{
    margin-left: 6.5px;
}
.org-customer-head{
    margin: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.org-customer-head h1{
    font-family: "Axiforma-Bold";
    font-size: 24px;
    color: #1B2952;
    margin: 0px;
}
.agency-search{
    border: 1px solid #E3E6F1;
    border-radius: 4px;
    height: 40px;
    width: 300px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0px 14px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    transition: .5s;
}
.agency-search input{
    background-color: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 8px;
    font-size: 13px;
    font-family: "Axiforma-Regular";
}
.agency-search:hover , .agency-search:focus{
    background-color: #F8FAFE;
    border-color: #3064F9!important;
    transition: .5s;
}
.agency-customer-list{
    background-color: #fff;
    min-height: calc(100vh - 180px);
    border-radius: 6px;
}
.agency-customer-head{
    display: flex;
    align-items: center;
    height: 40.5px;
    padding-left: 20px;
    border-bottom: 1px solid #F1F2F8;
}
.agency-customer-head div:nth-child(6) , .customer-entry:nth-child(6){
    width: 15%;
    padding-right: 10px;
}
.agency-customer-head div:nth-child(5) , .customer-entry:nth-child(5){
    width: 15%;
    padding-right: 10px;
}
.agency-customer-head div:nth-child(4) , .customer-entry:nth-child(4){
    width: 15%;
    padding-right: 10px;
}
.agency-customer-head div:nth-child(3) , .customer-entry:nth-child(3){
    width: 10%;
    padding-right: 10px;
}
.agency-customer-head div:nth-child(2) , .customer-entry:nth-child(2){
    width: 20%;
    padding-right: 10px;
}
.agency-customer-head div:nth-child(1) , .customer-entry:nth-child(1){
    width: 25%;
    padding-right: 10px;
}
.agency-customer-head div h6{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}
.customer-image{
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}
.customer-profile{
    display: flex;
    align-items: center;
}
.customer-profile h2{
    font-family: "Axiforma-Bold";
    font-size: 14px;
    color: #1B2952;
    margin: 0px;
}
.customer-profile h3{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin: 5px 0px 0px 0px;
}
.customer-body-row{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F2F8;
    min-height: 108px;
    width: 100%;
    padding-left: 20px;
    transition: .5s;
}
.customer-body-row:hover{
    background-color: #F8F9FD;
    transition: .5s;
}
.customer-body-row h4{
    font-family: "Axiforma-Regular2";
    font-size: 14px;
    color: #1B2952;
    margin: 0px;
    word-break: break-all;
}
.customer-status{
    display: flex;
    align-items: center;
}
.customer-status h5{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    /* color: #47CE70; */
    margin: 0px;
    transition: .5s;
}
.customer-entry span{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #3064F9;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
    display: none;
    transition: .5s;
}
.customer-status div{
    margin-right: 80px;
    width: 67px;
    transition: .5s;
}
.customer-body-row:hover span{
    display: block;
}
.customerstatusgreen{
    color: #47CE70!important;
}
.customerstatusred{
    color: #FF4848!important;
}
.agency-app-image{
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
}
.agency-applist-head{
    display: flex;
    align-items: center;
    height: 40.5px;
    padding-left: 20px;
    border-bottom: 1px solid #F1F2F8;
}
.agency-applist-head div:nth-child(6) , .agency-app-entry:nth-child(6){
    width: 12%;
    padding-right: 10px;
}
.agency-applist-head div:nth-child(5) , .agency-app-entry:nth-child(5){
    width: 20%;
    padding-right: 10px;
}
.agency-applist-head div:nth-child(4) , .agency-app-entry:nth-child(4){
    width: 18%;
    padding-right: 10px;
}
.agency-applist-head div:nth-child(3) , .agency-app-entry:nth-child(3){
    width: 10%;
    padding-right: 10px;
}
.agency-applist-head div:nth-child(2) , .agency-app-entry:nth-child(2){
    width: 20%;
    padding-right: 10px;
}
.agency-applist-head div:nth-child(1) , .agency-app-entry:nth-child(1){
    width: 20%;
    padding-right: 10px;
}
.agency-applist-head div h6{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}
.agency-app-download-stat{
    display: flex;
    align-items: center;
}
.agency-app-download-stat p{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #1B2952;
    margin: 0px 0px 0px 6px;
}
.agency-app-download-stat:nth-child(1){
    margin-right: 66px;
}
.agency-subscription-head{
    display: flex;
    align-items: center;
    height: 40.5px;
    padding-left: 20px;
    border-bottom: 1px solid #F1F2F8;
}
.agency-subscription-head div:nth-child(5) , .agency-subscription-entry:nth-child(5){
    width: 15%;
    padding-right: 10px;
}
.agency-subscription-head div:nth-child(4) , .agency-subscription-entry:nth-child(4){
    width: 25%;
    padding-right: 10px;
}
.agency-subscription-head div:nth-child(3) , .agency-subscription-entry:nth-child(3){
    width: 20%;
    padding-right: 10px;
}
.agency-subscription-head div:nth-child(2) , .agency-subscription-entry:nth-child(2){
    width: 20%;
    padding-right: 10px;
}
.agency-subscription-head div:nth-child(1) , .agency-subscription-entry:nth-child(1){
    width: 20%;
    padding-right: 10px;
}
.agency-subscription-head div h6{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}
.agency-subscription-list{
    background-color: #fff;
    min-height: calc(100vh - 180px);
}
.subscription-body-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F2F8;
    min-height: 108px;
    width: 100%;
    padding-left: 20px;
    transition: .5s;
}
.customer-body-row h5{
    font-family: "Axiforma-Regular2";
    font-size: 14px;
    color: #7782A1;
    margin: 6px 0px 0px 0px;
    display: flex;
}
.customer-body-row h5 span{
    font-size: 12px;
    color: #3064F9;
    margin-left: 15px;
    transition: .5s;
    display: none;
    cursor: pointer;
}
.customer-body-row:hover h5 span{
    display: block;
    transition: .5s;
    cursor: pointer;
    position: relative;
    top: 2px;
}
.subscription-profile-image{
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    min-height: 40px;
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}
.agency-background{
    background-image: url(../images/app-icon-background.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    min-height: 100vh;
    background-size: contain;
    background-attachment: fixed;
}
.agency-list-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 105px;
    width: 1080px;
    margin: auto;
}
.agency-list-head h1{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    margin: 0px;
}
.agency-list-head h2{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin: 5px 0px 0px 0px;
}
.start-with-agency{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
}
.start-with-agency div{
    text-align: center;
}
.start-with-agency div h3{
    font-family: "Axiforma-Bold";
    font-size: 30px;
    color: #1B2952;
    margin: 38px 0px 0px 0px;
}
.start-with-agency div h6{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 33px;
    width: 65%;
    line-height: 18px;
}
.agency-getstarted{
    border: 1px solid #3064F9;
    height: 50px;
    color: #fff;
    background-color: #3064F9;
    border-radius: 3px;
    width: 180px;
    transition: .5s;
    text-align: center;
    margin: auto;
    cursor: pointer;
    transition: 0.5s;
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #FFFFFF;
    line-height: 50px;
}
.agency-getstarted:hover{
    transition: 0.5s;
    background-color: #134BEC;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.agency-list{
    width: 1080px;
    max-width: 1080px;
    margin: auto;
    margin-top: 25px;
    padding-bottom: 50px;
}
.agency-list-app svg{
    opacity: 0;
    transition: .5s;
}
.agency-list-app:hover svg{
    opacity: 1;
    transition: .5s;
    cursor: pointer;
    stroke: #bcc1ce;
}
.agency-list-app:hover svg:hover{
    opacity: 1;
    transition: .5s;
    cursor: pointer;
    stroke: #3064F9;
}
.agency-single{
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #E3E6F1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    height: 201px;
    margin-bottom: 30px;
    padding: 40px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.agency-single p{
    position: absolute;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
    left: 0;
    top: 0;
}
.agency-single-placeholder{
    background-color: #F8FAFE;
    border-radius: 2px;
    width: 200px;
    height: 120px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
}
.agency-single-details h3 a{
    color: #3064F9;
}
.agency-single-details h3 a img{
    margin-left: 5px;
}
.agency-single-left{
    display: flex;
    align-items: center;
}
.agency-single-details h2{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
}
.agency-single-details h3{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #3064F9;
    margin: 10px 0px 0px 0px;
    position: relative;
    z-index: 1;
}
.agency-single-details h4{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 10px 0px 0px 0px;
}
.agency-single-right h5{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}
.agency-single-placeholder h2{
    font-family: "Axiforma-Bold";
    font-size: 32px;
    color: #E3E6F1;
    margin: 0px;
}
.agency-panel-active {
    background-color: #F1F4FB;
    transition: all .2s ease 0s;
    border-radius: 4px;
}
.agency-panel-active svg {
    fill: #3064F9!important;
}
.agency-panel-active p{
    color: #3064F9!important;
}
.contact-support-head{
    display: flex;
    align-items: center;
    height: 62.5px;
    padding-left: 30px;
    border-bottom: 1px solid #EDF0F5;
    margin-bottom: 35px;
}
.contact-support-head h4{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    margin: 5px 0px 0px 18px;
}
.contact-support-preview{
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    width: 428px;
    margin: auto;
    margin-top: 56px;
    margin-bottom: 45px;
}
.support-subhead-preview{
    width: 280px;
    height: 7px;
    border-radius: 10px;
    background-color: #F2F5F9;
    margin-top: 8px;
}
.contact-support-entry{
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 46px;
    min-height: 39px;
}
.contact-support-entry svg{
    margin-right: 19px;
}
.contact-support-entry h5{
    display: flex;
    align-items: center;
    font-family: "Axiforma-Bold";
    font-size: 13px;
    color: #1B2952;
    line-height: 19px;
    margin: 5px 0px 0px 0px;
}
.contact-support-entry h5 img{
    margin-left: 6.5px;
}
.support-page-link {
    width: 130px;
    height: 50px;
    line-height: 50px;
    font-family: "Axiforma-Regular2";
    border-top: 1px solid #BCC1CE;
    border-bottom: 1px solid #BCC1CE;
    border-left: 1px solid #BCC1CE;
    border-right: 0px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding-left: 12px !important;
    font-size: 13px;
    transition: .5s;
}
/* .support-page-link:hover{
    transition: .5s;
    border-top: 1px solid #3064F9;
    border-bottom: 1px solid #3064F9;
    border-left: 1px solid #3064F9;
    background-color: #F8FAFE;
} */
.support-page-link p{
    font-family: "Axiforma-Regular2";
}
.support-page-link-disabled{
    background-color: #F8FAFE!important;
    cursor: not-allowed!important;
    border: 1px solid #E3E6F1!important;
    border-right: none!important;
}
.support-page-link-disabled div{
    cursor: not-allowed!important;
}
.support-page-link-disabled .profile-selectflagtop{
    background-color: #F8FAFE;
}
.support-page-link-disabled .profile-selectflagtop .searchfleginput:hover{
    background-color: #F8FAFE!important;
    border: none!important;
}
.agency-logo{
    width: 160px;
    height: 50px;
    margin-bottom: 27px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 4px;
}
.agency-logo-shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .5s;
    cursor: pointer;
}
.agency-logo:hover .agency-logo-shadow{
    opacity: 1;
    transition: .5s;
}
.agency-logo-edit{
    visibility: hidden;
    background-color: #FFFFFF;
    color: #7782A1;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 105%;
    padding: 10px 20px 10px 20px;
    opacity: 0.8 !important;
    font-size: 13px;
    line-height: 21px;
    display: flex;
    text-align: left !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    justify-content: space-between;
    transition: .3s;
}
.agency-logo:hover .agency-logo-edit{
    visibility: visible;
    transition: .3s;
}
.agency-logo-edit .profilepic-upload{
    margin: 0px;
    cursor: pointer;
    color: #7782A1;
    font-family: "Axiforma-Regular";
}
.agency-logo-edit .profilepic-remove{
    margin: 0px 0px 0px 40px;
    color: #FF4848;
    cursor: pointer;
    font-family: "Axiforma-Regular";
}
.agency-logo-edit::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: #e6ebff14 white #e6ebff14 #e6ebff14;
}
.social-auth-detail{
    margin-top: 10px;
}
.social-auth-detail label{
    margin-top: 17px;
    margin-bottom: 5px;
}
.agency-mobile-btns{
    display: none;
}
.bar-graph-head h3{
    color: #1B2952;
    font-size: 16px;
    font-family: "Axiforma-Bold";
    margin-bottom: 0px;
}
.bar-graph-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.bar-graph-head .dropdown-toggle{
    color: #7782A1!important;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    background-image: url(../images/email-support-arrow.png)!important;
    background-repeat: no-repeat!important;
    background-position: 97%!important;
    -webkit-appearance: none;
    transition: .5s;
    min-width: 120px;
}
.bar-graph-head .dropdown-toggle:after{
    display: none;
}
.bar-graph-head .dropdown-menu{
    width: 140px;
    min-width: 100px;
    margin-top: 10px;
}
.bar-graph-head .dropdown-menu .dropdown-item{
    display: block;
    width: calc(100% - 10px);
    padding: 12px 0px 10px 10px!important;
    color: #7782A1;
    font-size: 13px!important;
    font-family: "Axiforma-Regular"!important;
    margin: auto!important;
    border: 0px solid #fff !important;
}
.bar-graph-head .dropdown-menu .dropdown-item:hover{
    border: 0px solid #fff !important;
    background-color: #F8F9FD!important;
}
.agency-web-timestamp{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px 20px 0px 0px;
}
.btndisable , .btndisable:hover{
    background-color:#c8cedb;
    color:#FFFFFF;
    border:1px solid #c8cedb;
    cursor: not-allowed !important;
    box-shadow: none!important;
}
.btndisable-blank , .btndisable-blank:hover{
    background-color:transparent;
    color:#c8cedb;
    border:1px solid #c8cedb;
    cursor: not-allowed !important;
    box-shadow: none!important;
}
.agency-headings{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.agency-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 6px;
    top: -1px;
}
.agency-tooltip .agency-tooltipsubheader {
    visibility: hidden;
    width: 305px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 159%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    text-align: left !important;
    transition: 0.3s;
}
.agency-tooltip:hover .agency-tooltipsubheader {
    visibility: visible;
}
.agency-tooltip .agency-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 9px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.agency-apps-mobile-view{
    display: none;
}
.agency-headings h6{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin: 0px 0px 10px 0px;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    align-items: center;
    justify-content: end;
}
.pagination > .active > a{
    background-color: #3064F9 ;
    border-color: #3064F9 ;
    color: #fff;
}
.pagination > li > a{
    border: 1px solid #3064F9 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    text-transform: capitalize;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #3064F9 ;
    border-color: #3064F9;
    outline: none ;
    color: #fff!important;
}
.pagination > li > a, .pagination > li > span{
    color: #3064F9;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
} 
.agency-change-plan .search-dropdown{
    width: 48%;
}
.agency-change-plan-popup .dropdown-toggle::after{
    display: none;
}
.agency-change-plan-popup h4{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    line-height: 25px;
    margin-bottom: 0px;
}
.agency-change-plan-popup h5{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 28px;
}
.agency-change-plan-popup .modal-body{
    padding: 0px;
}
.agency-change-plan-popup label{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 9px;
}
.delete-agency-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E3E6F1;
}
.delete-agency-footer-cancel{
    border: 1px solid #BCC1CE;
    height: 40px;
    color: #7782A1;
    background-color: #fff;
    border-radius: 3px;
    width: 100px;
    transition: .5s;
    text-align: center;
    cursor: pointer;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    line-height: 40px;
}
.delete-agency-footer-cancel:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 69%);
}
.delete-agency-footer-change{
    border: 1px solid #3064F9;
    height: 40px;
    color: #fff;
    background-color: #3064F9;
    border-radius: 3px;
    width: 100px;
    transition: .5s;
    text-align: center;
    cursor: pointer;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    line-height: 40px;
}
.delete-agency-footer-change:hover{
    background-color: #134BEC;
    border: 1px solid #3064F9;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.agency-change-plan-popup .delete-org-body{
    padding: 25px 20px 35px 20px;
}
.agency-change-plan-popup .delete-agency-footer{
    padding: 15px 20px;
}
.delete-agency-footer-change div{
    margin: 10px auto;
}
.agency-sub-mobile{
    display: none;
}
.agency-customer-mob{
    display: none;
}
.deactivate-customer-popup h4{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    text-align: center;
    margin: auto;
    line-height: 25px;
    margin-top: 39px;
    margin-bottom: 15px;
}
.deactivate-customer-popup h5 {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 25px;
    text-align: center;
    width: 85%;
    margin: auto;
}
.deactivate-customer-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 36px;
}
.customer-cancel-btn{
    border: 1px solid #C8CEDB;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #7782A1;
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
}
.customer-cancel-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.customer-deactivate-btn{
    border: 1px solid #FF4848;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #FF4848;
    font-size: 14px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.customer-deactivate-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(255 72 72 / 29%);
}
.deactivate-customer-popup .modal-body{
    /* display: flex; */
    align-items: center;
    justify-content: center;
}
.customer-deactivate-btn-mob{
    display: none;
}
.activate-customer-popup h4{
    font-family: "Axiforma-Bold";
    font-size: 18px;
    color: #1B2952;
    text-align: center;
    margin: auto;
    line-height: 25px;
    margin-top: 39px;
    margin-bottom: 15px;
}
.activate-customer-popup h5 {
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 25px;
    text-align: center;
    width: 94%;
    margin: auto;
}
.activate-customer-popup .modal-body{
    display: flex;
    align-items: center;
    justify-content: center;
}
.deactivate-customer-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 36px;
}
.customer-activate-btn-mob{
    display: none;
}
.activate-customer-cancel-btn{
    border: 1px solid #C8CEDB;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #7782A1;
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    font-family: "Axiforma-Regular";
}
.activate-customer-cancel-btn:hover{
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.customer-activate-btn{
    border: 1px solid #3064F9;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #fff!important;
    background-color: #3064F9;
    font-size: 14px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.customer-activate-btn:hover {
    background-color: #134BEC;
    border: 1px solid #3064F9;
    transition: 0.5s;
    color: #fff!important;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.smtpcredentials .select-img-ratio div p {
    margin-left: 0px;
}
.agency-google-btns{
    border: 1px solid #E3E6F1;
    height: 44px;
    width: calc(100% + 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 10px 22px 10px;
}
.agency-facebook-btns{
    border: 1px solid #E3E6F1;
    height: 44px;
    width: calc(100% + 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 10px 22px 10px;
}
.save-social-btn{
    width: 100px;
    height: 40px;
    border: none;
    background-color: #3064F9;
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Axiforma-Regular";
    border-radius: 3px;
    transition: .5s;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
}
.save-social-btn .new-loader{
    margin: auto;
    position: relative;
}
.save-social-btn:hover {
    background-color: #134BEC;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.save-social-btn-deactivate , .save-social-btn-deactivate:hover{
    background-color: #BCC1CE;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;
}
.org-support-body .countryflagtype .country-line-flag , .org-support-body .countryflagtype .country-line-flag:hover{
    padding: 0px 10px;
}
.org-support-body .profile-selectflag {
    background-color: white;
    z-index: 1;
    width: calc(100% - 30px);
    top: 50px;
    left: 0;
    height: 340px;
    position: absolute;
    box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin-left: 15px;
    top: 77px;
}
.agency-team-table-head {
    display: flex;
    align-items: center;
    height: 40.5px;
    padding: 0px 20px;
    border-bottom: 1px solid #F1F2F8;
    background-color: #fff;
}
.agency-team-table-head h6 {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin: 0px;
}
.agency-team-table-head div:nth-child(1) {
    width: 30%;
}
.agency-team-table-head div:nth-child(2) {
    width: 25%;
}
.agency-team-table-head div:nth-child(3) {
    width: 15%;
}
.agency-team-table-head div:nth-child(4) {
    width: 20%;
}
.agency-team-table-head div:nth-child(5) {
    width: 20%;
}
.org-support-body .org-support-phone p div img{
    margin-right: 5px;
}
.org-support-body .org-support-phone .profile-selectflagtop p:hover {
    transition: .5s;
    background-color: transparent;
}
.org-support-body .org-support-phone .profile-selectflagtop{
    padding: 0px;
}
.agencyerror{
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #FF4848;
    margin-top: 5px;
    margin-bottom: 0px;
}
.agencyerror svg{
    margin-right: 6px;
    position: relative;
    top: 1px;
}
.smtpcredentials .dropdown-toggle::after{
    display: none;
}
.customer-status svg{
    cursor: pointer;
}
.unverified-domain input{
    border: 1px solid #FF8E8E;
    background-color: #FFFFFF;
    border-radius: 3px;
}
.agency-pricing-disabled{
    cursor: not-allowed;
}
.createagency-spinner{
    z-index: 1;
}
.link-expired-msg{
    font-size:16px;
    color:#7782A1;
}
.subscriptionbottom-space{
    margin-bottom: 30px;
}
.agency-smtp-verification-background-color{
    background-color: #FFFFFF;
}
.agency-smtp-verification-msg{
    font-size:40px
}
.agency-analytics-second-dropdown{
    width: 160px !important;
}
.agency-analytics-spinner{
    position: relative;
}
.agency-single-link-cursor{
    cursor: pointer;
}
.cancel-btn-fontfamily{
    font-family:"Axiforma-Regular"
}
.agency-dashboard-header-div{
    display: flex;
    align-items: center;
}
.agency-profile-delete{
    margin-top:100px;
}
.text-type-delete{
color: black;
}
.agency-favicon-min{
    min-width : 95%
}
.agencytype-plan-name-capital{
    text-transform: capitalize;
}
.domain-form-address span{
    color: #BCC1CE;
    font-size: 12px;
    font-family: "Axiforma-Regular";
    margin-bottom: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    right: 20px;
    top: 15px;
}
.domain-form-address span svg{
    margin-left: 10px;
    position: initial;
}
.smtpcredentials-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.smtpcredentials-head p{
    font-size: 13px;
    font-family: "Axiforma-Regular";
    margin: 0px 30px 0px 0px;
}
.agency-label-tooltips{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 6px;
}
.agency-label-tooltips h4{
    margin-top: 0px;
    margin-bottom: 0px;
}
.invite-team-mob{
    display: none;
}
.agency-teams-mobile{
    display: none;
}
.social-captcha{
    margin: 20px 0px 0px 0px;
}
.social-btns{
    margin: 20px 0px 0px 0px;
}
.profile-mobile-menu-body ul{
    overflow: auto;
    height: calc(100vh - 150px);
}
.agency-support-master-toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E3E6F1;
    margin-top: 30px;
    padding-bottom: 28px;
}
.agency-support-master-toggle h4{
    font-size: 16px;
    font-family: "Axiforma-Bold";
    color: #1B2952;
    margin: 0px;
}
.customer-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 6px;
}
.customer-tooltip .customer-tooltipsubheader {
    visibility: hidden;
    width: 125px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 33px;
    left: -58px;
    padding: 4px 14px 3px 14px;
    opacity: 0.8 !important;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    line-height: 21px;
    display: grid;
    text-align: center;
    transition: 0.3s;
}
.customer-tooltip:hover .customer-tooltipsubheader {
    visibility: visible;
}
.customer-tooltip .customer-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: -8px;
    right: 46%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(90deg);
}
.modal-content {
    border: none!important;
}
.agency-team-popup .invite-user {
    padding: 25px 30px 0px 20px !important;
}
.agency-team-popup .invite-user-body {
    padding: 0px 20px 0px 20px !important;
}
.agency-team-popup .agency-team-popup-footer{
    padding: 15px 20px !important;
}
.agency-team-popup .cancel-user .cancel-btn {
    background-color: transparent;
    border-radius: 3px;
    color: #7782A1;
    border: 1px solid #BCC1CE;
}
.agency-team-popup .cancel-user .cancel-btn:hover {
transition: 0.5s;
box-shadow: 0px 2px 4px rgb(188 193 206 / 39%);
}
.agency-team-popup .next_btn {
    border-radius: 3px;
    transition: .5s;
    text-transform: capitalize;
    min-width: 100px;
    padding: 0px;
}
.agency-team-popup .next_btn:hover {
    background-color: #134BEC;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
.agency-team-popup .modal-content{
    border-radius: 6px;
    outline: 0;
    overflow: hidden;
}
.team-user-inactive{
    background-color: #BCC1CE;
    border: 1px solid #BCC1CE;
    box-shadow: none;
    cursor: not-allowed;
}
.team-limit-btn {
    border: 1px solid #3064F9;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border-radius: 4px;
    color: #fff!important;
    background-color: #3064F9;
    font-size: 14px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Axiforma-Regular";
}
.selected-country img{
    margin-right: 10px;
}
.selected-country span{
    position: relative;
    top: 1px;
}
.apptype-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 6px;
}
.apptype-tooltip .apptype-tooltipsubheader {
    visibility: hidden;
    width: 140px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 33px;
    left: -58px;
    padding: 4px 14px 3px 14px;
    opacity: 0.8 !important;
    font-size: 10px;
    font-family: "Axiforma-Regular";
    line-height: 21px;
    display: grid;
    text-align: center;
    transition: 0.3s;
}
.apptype-tooltip:hover .apptype-tooltipsubheader {
    visibility: visible;
}
.apptype-tooltip .apptype-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: -8px;
    right: 46%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
    transform: rotate(90deg);
}
.agency-subdomain-login{
    /* width: calc(100% + 20px)!important;
    margin-left: -10px!important;
    margin-bottom: 20px!important;
    margin-top: 15px!important; */
    display: flex;
    gap: 20px;
}
.agency-subdomain-login a{
    /* width: calc(100% + 10px)!important;
    margin: 0px 10px 0px 10px!important; */
    width: 100%;
}
.agency-subdomain-login .new-signup-google{
    width: 100%!important;
}
.agency-subdomain-login .new-facebooksignin{
    width: 100%!important;
}
/* .agency-subdomain-login .new-facebooksignup{
    width: calc(100% + 10px)!important;
    margin: 0 10px!important;
} */
.agencylist-tooltip {
    position: absolute;
    display: inline-block;
    right: 5px;
    top: 18px;
}
.agencylist-tooltip .agencylist-tooltipsubheader {
    visibility: hidden;
    width: 130px;
    background-color: #000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 159%;
    padding: 5px 16px 4px 13px;
    opacity: .8!important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    text-align: left!important;
    transition: .3s;
}
.agencylist-tooltip:hover .agencylist-tooltipsubheader {
    visibility: visible;
}
.agencylist-tooltip .agencylist-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.agency-list-head .justify-content-between a{
    display: none;
}
.agency-pricing-page{
    margin-top: 60px;
}
.agency-pricing-page .container{
    position: relative;
    padding-top: 70px;
}
.agency-pricing-heading{
    display: flex;
    justify-content: space-between;
}
.agency-pricing-page h1{
    font-family: "Axiforma-Bold";
    font-size: 36px;
    color: #1B2952;
    padding: 0px 0px 27px 0px;
    margin-bottom: 0px;
}
.agency-pricing-tabs .all-discount-percent{
    top: -32px;
    right: 56px;
   
}
.agency-pricing-page .container .pricing-notice {
max-width: unset !important;
margin: unset;
margin-bottom: 35px !important;
margin-top: -39px !important;
}
.agency-price-manage{
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.agency-price-left{
    width: calc(100% - 360px);
}
.agency-price-breakup{
    background-color: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    padding: 20px 30px 35px 30px;
    width: 100%;
    margin-bottom: 20px;
}
.agency-price-right{
    background-color: #fff;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    padding: 30px 30px 19px 30px;
    width: 340px;
    min-width: 340px;
}
.agency-price-breakup-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.agency-price-breakup-head h2{
    font-family: "Axiforma-Bold";
    font-size: 20px;
    color: #1B2952;
    margin-bottom: 0px;
}
.agency-price-breakup-head h3{
    font-family: "Axiforma-Bold";
    font-size: 12px;
    color: #0F193B;
    margin-bottom: 0px;
}
.agency-price-tooltip {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: 6px;
}
.agency-price-tooltip .agency-price-tooltipsubheader {
    visibility: hidden;
    width: 200px;
    background-color: #000000;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    z-index: 4;
    top: -1px;
    left: 159%;
    padding: 14px 30px 14px 22px;
    opacity: 0.8 !important;
    font-size: 12px;
    line-height: 21px;
    display: grid;
    text-align: left !important;
    transition: 0.3s;
}
.agency-price-tooltip:hover .agency-price-tooltipsubheader {
    visibility: visible;
}
.agency-price-tooltip .agency-price-tooltipsubheader::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 100%;
    margin-top: -2px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.pricing-whitelabel-features{
    margin-top: 34px;
}
.pricing-whitelabel-features p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 17px;
}
.pricing-whitelabel-features p svg{
    margin-right: 6px;
}
.agency-pricing-dynamic-stats{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
}
.agency-pricing-dynamic-stats p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
}
.agency-price-right h2{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 21px;
}
.agency-price-right h3{
    font-family: "Axiforma-Bold";
    font-size: 36px;
    color: #0F193B;
    margin: 0px;
}
.agency-price-right h3 span{
    font-family: "Axiforma-Regular";
    font-size: 18px;
    color: #0F193B;
}
.agency-price-right h4{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-top: 6px;
    margin-bottom: 27px;
}
.agency-price-right .agency-price-right-final{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.agency-price-right h5{
    font-family: "Axiforma-Bold";
    font-size: 12px;
    color: #1B2952;
    margin-top: 0px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.agency-price-right h6{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #7782A1;
    margin-bottom: 0px;
}
.agency-price-right .agency-price-btn{
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background-color: #3064F9;
    border-radius: 3px;
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: .5s;
    font-family: 'Axiforma-Regular';
}
.agency-cancelpopup h4{
    color: #1B2952;
    font-size: 18px;
    font-family: 'Axiforma-Bold';
    text-align: center;
    line-height: 25px;
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
}

.agency-cancelpopup h6{
    color: #7782A1;
    font-size: 14px;
    font-family: 'Axiforma-Regular';
    text-align: center;
    line-height: 25px;
    width: 100%;
    margin: auto;
    
}
.agency-pricing-currentplan{
    color: white!important;
    background-color: #BCC1CE!important;
    border: 1px solid #E3E6F1!important;
    cursor: not-allowed;
}
.agency-pricing-currentplan:hover{
    background-color: #BCC1CE !important;
    transition: 0.5s;
     box-shadow:unset !important;
}

.agency-cancelsubscription{
    cursor: pointer;
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #BCC1CE;
    margin-bottom: 0px;
    margin-top: -16px;
}
.agency-cancel-reactive{
    font-size: 12px;
    font-family: 'Axiforma-Regular';
    color: #FF4848;
    margin-bottom: 0px;
    /* margin-top: -16px; */
}
.agency-cancel-reactive span{
    float: right;
    color: #BCC1CE;
    cursor: pointer;
}
.plan-cacel-confirm{
    border: 1px solid #FF4848;
    background-color: white;
    color: #FF4848;
    font-family: 'Axiforma-Regular';
    width: 140px;
    height: 44px;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    line-height: 44px;
    margin-left: 10px;
    cursor: pointer;
}
.plan-cacel-confirm:hover{
    border: 1px solid #FF4848;
    background-color: #FF4848;
    color: white;
}
.plan-cacel-confirm:hover .new-red-loader {
    border: 2px solid #fff !important;
    transition: 0.5s;
    background-color: #ff4848;
    border-top: 2px solid transparent !important;
  }
.agency-price-right .agency-price-btn:hover{
    background-color: #134bec;
    transition: 0.5s;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
}
/* Range Slider as a Single Thumb Slider */
.agency-range-slider{
    margin-top: 42px;
    position: relative;
}
.single-thumb .range-slider__thumb[data-lower] {
    width: 0;
}
.single-thumb .range-slider__range {
    border-radius: 0px;
}
#range-slider-ab .range-slider__range {
    background: #E3E6F1;
}
#range-slider-ab .range-slider__thumb[data-upper]{
  background: url(../images/sliders.png) transparent;
  background-size: contain;
}
#range-slider-ab .range-slider__thumb[data-lower] {
    background: transparent!important;
    display: none;
}
#range-slider-ab1 .range-slider__range {
    background: #E3E6F1;
}
#range-slider-ab1 .range-slider__thumb[data-upper]{
  background: url(../images/sliders.png) transparent;
  background-size: contain;
}
#range-slider-ab1 .range-slider__thumb[data-lower] {
    background: transparent!important;
    display: none;
}
.range-slider .range-slider__thumb {
    width: 30px!important;
    height: 30px!important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 16%);
}
.agency-range-slider .range-slider{
    height: 4px;
    background: #E3E6F1;
}
.agency-dynamic-range{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -16px;
}
.agency-range-start p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin-bottom: 0px;
}
.agency-range-end p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin-bottom: 0px;
    position: relative;
    right: -8px;
}
.agency-range-dots{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: -2px;
    z-index: 1;
}
.agency-range-dots div{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #BCC1CE;
    border: 2px solid #fff;
}
.agency-range-dots div:first-child , .agency-range-dots div:last-child{
    border: 2px solid transparent;
    background-color: transparent;
}
.agency-range-start{
    text-align: left;
}
.agency-range-end{
    text-align: right;
}
.custom-quote{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E3E6F1;
    border-radius: 6px;
    padding: 50px 40px;
    margin-top: 45px;
}
.custom-quote h2{
    font-family: "Axiforma-Bold";
    font-size: 36px;
    color: #1B2952;
    margin-top: 0px;
    line-height: 69px;
}
.custom-quote h3{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    margin-top: 10px;
    line-height: 18px;
    margin-bottom: 0px;
}
.custom-quote a{
    width: 160px;
    height: 50px;
    text-align: center;
    line-height: 47px;
    color: #3064F9;
    border: 1px solid #3064F9;
    background-color: transparent;
    border-radius: 3px;
    display: block;
    font-size: 14px;
    transition: 0.5s;
}
.custom-quote a:hover{
    transition: 0.5s;
    color: #3064F9;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 29%);
}
.agencyfaq{
    background-color: #F8FAFE;
    margin-top: 100px;
    margin-bottom: 100px;
}
.agencyfaq .accordionWrapper{
    overflow: hidden;
}
.agency-faq-gap{
    height: 100px;
}
.agency-translate1 {
    transform: translate(-90%, -50%) !important;
}
.agency-translate2 {
    transform: translate(-10%, -50%) !important;
}
.agency-errorpage{
    text-align: center;
    background-color: #f4f4f9;
    min-height: 100vh ;
    display: flex;
    align-items: center;
    justify-content: center;
}
.agency-errorpage h1{
    font-size: 48px;
    font-family: "Axiforma-Bold";
    color: #0f193b;
    margin-bottom: 30px;
}
.agency-errorpage h2{
    font-size: 13px;
    font-family: "Axiforma-Regular";
    color: #BCC1CE;
    margin-bottom: 30px;
}
.agency-errorpage h3 {
    font-size: 16px;
    font-family: "Axiforma-Regular";
    margin-bottom: 40px;
    color: #5f6578;
}
.agency-errorpage a , .agency-errorpage a:hover {
    font-size: 16px;
    font-family: "Axiforma-Regular";
    margin: auto;
    margin-bottom: 40px;
    color: #3064F9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 54px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
}
.agency-error-mobimg{
    display: none;
}
.agency-error-webimg{
    max-width: 100%;
    height: auto;
}
.org-module .mainheader .create-menu::before{
    right: 200px!important;
}
.agency-module-list .mainheader .create-menu::before{
    right: 252px!important;
}
.rebuild-required-popup h5 {
    width: 90%;
}
.rebuild-required-popup h5:last-child{
    margin-top: 20px;
}

.team-right-box {
    max-width: 100%;
    background-color: #f8fafe;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .team-heading h1 {
    color: #1b2952;
    font-size: 24px;
    font-family: "Axiforma-Bold";
    margin: 0;
    padding-top: 27px;
    padding-bottom: 16px;
  }
  .team-heading-content .team-info-icon {
    visibility: hidden;
  }
  
  .box-img {
    background-size: cover;
    background-color: #fff;
    background-repeat: no-repeat;
    background-origin: content-box;
    padding-top: 122px;
    min-height: calc(100vh - 171px);
  }
  
  .team-inner-box {
    background-image: url(../images/app-icon-background.png);
    border-radius: 6px;
    border: 1px solid #e3e6f1 !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  }
  
  /* Changes done to handle bottom space - Padding bottom issue  */
  .team-box-wrapper {
    padding-bottom: 212px;
  }
  .team-inner-content {
    /* max-width: 100%; */
    max-width: 1840px;
    display: flex;
    padding-left: 120px;
    padding-right: 255px;
    margin-left: auto;
    margin-right: auto;
  }
  
  h3.team-paragraph {
    color: #1b2952;
    font-size: 48px;
    font-family: "Axiforma-Bold";
    line-height: 69px;
    margin-bottom: 20px !important;
  }
  
  .team-content-text {
    color: #7782a1;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    line-height: 19px;
    margin-bottom: 30px;
  }
  ul {
    margin-bottom: 0px !important;
  }
  .team-content-details ul {
    padding-left: 0 !important;
  }
  .team-content-details ul li {
    line-height: 17px;
    font-size: 13px;
    color: #7782a1;
    font-family: "Axiforma-Regular";
    padding-left: 15px !important;
    padding-bottom: 12px;
    list-style-type: none;
    position: relative;
  }
  
  .team-content-details ul li:before {
    content: "";
    background-color: #bcc1ce;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    border-radius: 100%;
    top: 5px;
  }
  
  .btn.btn-primary {
    width: 200px;
    background-color: #3064f9;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-family: "Axiforma-Regular";
    height: 50px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    margin-top: 18px;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #0040f9;
    transition: 0.5s;
  }
  
  .btn-primary:focus,
  .btn:focus {
    box-shadow: none !important;
  }
  
  /* Team Image starts here */
  
  .team-img {
    display: flex;
    align-items: center;
  }
  
  .team-img img {
    width: 100%;
    height: auto;
  }
  .team-content {
    margin-right: 90px !important;
    max-width: 560px;
    padding-top: 20px;
  }
  .team-member-status span {
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #3064F9;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
    display: none;
    transition: .5s;
    }
    .team-member-detail:hover .team-member-status span{
        display: block;
        transition: .5s;
    }
    .team-member-detail:hover {
        background-color: #F8F9FD;
        transition: .5s;
    }
  .btn.btn-primary:hover {
    background-color: #0040f9;
  }
  .agency-leftpanel-seprator{
    border-top: 1px solid #EDF0F5;
    margin: 20px 0px;
  }
.agency-plan-detail{
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    padding: 20px;
    margin-bottom: 20px;
}
.agency-plan-alignment{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.agency-plan-section-one{
    width: calc(100% - 100px);
}
.agency-plan-section-one h3{
    font-size: 16px;
    color: #1B2952;
    font-family: "Axiforma-Bold";
    margin-bottom: 12px ;
}
.agency-plan-section-one p{
    font-size: 12px;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    line-height: 16px;
    width: 90%;
}
.agency-plan-section-one p span{
    color: #1B2952;
    font-family: "Axiforma-Bold";
}
.agency-plan-section-one h6{
    font-size: 12px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    line-height: 16px;
    margin-top: 10px;
}
.agency-plan-section-two{
    min-height: 100px;
    max-width: 100px;
}
.agency-status-upgrade{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 22px;
    margin-top: 10px;
}
.agency-status-upgrade p{
    font-size: 13px;
    color: #47CE70;
    font-family: "Axiforma-Regular";
    margin: 0px;
}
.agency-status-upgrade a , .agency-status-upgrade a:hover{
    font-size: 13px;
    color: #3064F9;
    font-family: "Axiforma-Regular";
    margin: 0px;
}
.agency-personal-data{
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    padding: 20px;
    width: 49%;
}
.agency-personal-data div:nth-child(1){
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 15px;
}
.agency-personal-data h2{
    font-size: 16px;
    color: #1B2952;
    font-family: "Axiforma-Bold";
    margin: 0px;
}
.agency-personal-data h5{
    font-size: 13px;
    color: #7782A1;
    font-family: "Axiforma-Regular";
    margin: 8px 0px 10px 0px;
}
.agency-personal-data h6{
    font-size: 12px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    margin: 0px;
}
.org-app-head{
    display: flex;
    align-items: center;
}
.org-app-head h5 {
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #BCC1CE;
    margin-bottom: 0px;
    margin-right: 20px;
}
.org-app-head h5 span {
    font-size: 16px;
    margin-right: 3px;
    color: #7782A1;
}
.mobileactivate {
    color:#3064F9!important;
    font-size:13px;
    font-family: "Axiforma-Regular";
}
.smtperror-popup h6{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    color: #7782A1;
    line-height: 18px;
    text-align: center;
    width: 80%;
    margin: auto;
}
.agency-pricing{
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    box-shadow: 0 2px 6px rgb(0 0 0/6%);
    padding: 72px 0px 72px 130px;
    display: flex;
    align-items: center;
}
.agency-pricing div h3{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-top: 0;
}
.agency-pricing div h4{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    line-height: 17px;
    margin: 10px 0px 37px 0px;
}
.agency-pricing div h5{
    font-family: "Axiforma-Bold";
    font-size: 36px;
    color: #0F193B;
    margin: 0;
}
.agency-pricing div h5 span:nth-child(1){
    font-family: "Axiforma-Regular";
    font-size: 18px;
    color: #0F193B;
}
.agency-pricing div h5 span:nth-child(2){
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
}
.agency-pricing-buy{
    background-color: #3064F9;
    width: 240px;
    height: 40px;
    display: block;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 3px;
    border: 1px solid #3064F9;
    transition: .5s;
}
.agency-pricing-buy:hover{
    transition: .5s;
    background-color: #134bec;
    box-shadow: 0px 2px 4px rgb(112 155 250 / 69%);
    color: #FFFFFF;
}
.agency-pricing-details{
    margin-left: 130px;
}
.agency-pricing-buy.disabled , .agency-pricing-buy.disabled:hover{
    background-color: #BCC1CE;
    border: 1px solid #BCC1CE;
    box-shadow: none;
    cursor: not-allowed;
}
.agency-pricing-footer{
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-left: 0px;
}
.cancel-agency-subscription{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE;
    margin-left: 40px;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 0px;
}
.cancel-agency-subscription:hover{
    color: #3064f9;
    transition: .5s;
}
.agency-precing-reactivate{
    margin-left: 40px;
    display: block;
    text-align: left;
}
.agency-precing-reactivate p{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #FF4848;
    margin-bottom: -3px;
}
.agency-precing-reactivate a , .agency-precing-reactivate span{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    color: #BCC1CE!important;
    transition: .5s;
    cursor: pointer;
}
.agency-precing-reactivate a:hover , .agency-precing-reactivate span{
    color: #3064f9!important;
    transition: .5s;
    cursor: pointer;
}
.agency-table{
    padding: 100px 0px;
    background-color: #fff;
}
.agency-table h2{
    font-family: "Axiforma-Bold";
    font-size: 36px;
    color: #0F193B;
    text-align: center;
    line-height: 54px;
    margin-bottom: 11px;
}
.agency-table h3{
    font-family: "Axiforma-Regular";
    font-size: 16px;
    color: #7782A1;
    text-align: center;
    line-height: 30px;
    margin-bottom: 60px;
}
.agency-table h4{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #0F193B;
    margin-top: 30px;
    margin-bottom: 20px;
}
.agency-table-detail-list{
    border-top: 1px solid #E3E6F1;
}
.agency-feature-entry{
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    width: 33%;
}
.agency-feature-entry p{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #5F6578;
    margin: 0px 0px 0px 10px;
}
.agency-table-detail-feature{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 39px;
}
.agency-table-detail-list:last-child {
    border-bottom: none;
    margin-bottom: 20px;
}
.agency-table-detail-feature a , .agency-table-detail-feature a:hover{
    color: #5F6578;
    text-decoration: underline!important;
}
.agency-table-detail-feature a img{
    opacity: 0;
    margin-left: 5px;
    transition: .5s;
}
.agency-table-detail-feature a:hover img{
    opacity: 1;
    margin-left: 5px;
    transition: .5s;
}
.newagencypricingfaq .accordion-header{
    padding: 10px 0px 20px 0px!important;
}
.newagencypricingfaq .sectionheadings{
    margin-bottom: 40px;
}
.agency-mob-save-team-btn{
    display: none;
}
.apply-agency-deal{
    border: 1px dashed #52CB86;
    padding: 8px 15px 7px 16px;
    margin-right: 20px;
    cursor: pointer;
    width: max-content;
    background-color: #fff;
}
.apply-agency-deal p{
    color: #52CB86;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin-bottom: 0px;
}
.apply-agency-deal p img{
    margin-right: 6px;
}
.deal-applied{
    border: 1px solid #BACBFF;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    box-shadow: 0px 2px 6px 0px #3064F91A;
    border-radius: 6px;
    margin-bottom: 20px;
}
.deal-applied h5{
    color: #1B2952;
    font-family: "Axiforma-Bold";
    font-size: 14px;
    margin: 0px;
}
.agency-pricing-tabs{
    display: flex;
    align-items: baseline;
    position: relative;
}
.agency-pricing-tabs .tabbed ul.tabs{
    border: 3px solid #E3E6F1;
    border-radius: 4px;
}
.agency-subscription-stats{
    display: flex;
    align-items: center;
}
.agency-email-label{
  color: #7782A1; 
  font-size: 13px;
   margin-bottom: 5px
}
.agency-web-timestamp-maindiv{
    display: flex;
    align-items: center;
}
.org-team-body-div{
    min-height: 75vh
}
.invitation-msg{
  font-size: 40px;
}
.agency-invitation-section{
    background-color: #fff;
}
.agency-invitation-link-expired{
    font-size:16px;
    color:#7782A1;
}
.agency-country-flag{
    width: 100%;
     display: flex;
     align-items: center; 
     cursor: pointer;
}
.subscription-number{
    margin-right: 20px;
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 3px;
}
.subscription-number span{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 16px;
    margin-bottom: 0px;
}
.subscription-number-mob{
    display: none;
}
.agency-sub-head{
    display: flex;
}
.agency-disclamier{
    max-width: 480px;
}
.agency-disclamier h5{
    color: #BCC1CE;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    margin-top: 20px;
    line-height: 17px;
}
.agency-disclamier h5 a{
    color: #7782A1;
    font-family: "Axiforma-Regular";
    font-size: 12px;
    text-decoration: none;
}
.mob-org-right-panel{
    display: none;
}
.workspace-mobile-right{
    display: none;
}








.varify-subdomain-div{
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);
    border-radius: 6px;
    border: 1px solid #E3E6F1;
    padding: 20px 30px 40px 30px;
    margin-top: 20px;
}
.varify-subdomain-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.varify-subdomain-title{
    font-family: "Axiforma-Bold";
    font-size: 16px;
    color: #1B2952;
    margin-bottom: 5px;   
}
.varify-subdomain-subtitle{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    
}
.varify-domain-question{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin: 0px;
    cursor: pointer;
    transition: .5s;
    margin-top: 5px;
    text-align: left !important;
}
.varify-domain-question:hover{
    color: #3064F9;
    transition: .5s;
}
.varify-subdomain-right{
    display: flex;
    gap: 30px;
}
.domain-unvarified-div{
    display: flex;
        align-items: center;
        background-color: #FFEFEF;
        height: 26px;
        gap: 5px;
        padding: 0px 12px;
        border-radius: 30px;
}
.domain-validating-div{
    display: flex;
        align-items: center;
        background-color: #FFB300;
        height: 26px;
        gap: 5px;
        padding: 0px 12px;
        border-radius: 30px;
}
.domain-unvarified-img{
    width: 15px;
}
.domain-unvarified-text{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    color: #FF4848;
    margin-bottom: 0px;
}
.domain-validating-text{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 0px;
}
.domain-varified-div{
    display: flex;
    align-items: center;
    background-color: #E3F9EC;
    height: 26px;
    gap: 5px;
    padding: 0px 12px;
    border-radius: 30px;
}
.domain-varified-img{
    width: 14px;
}
.domain-varified-text{
    font-family: "Axiforma-Regular";
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    color: #52CB86;
    margin-bottom: 0px;
}
.varify-subdomain-first-tablehead-text{
    font-family: "Axiforma-Regular";
    font-size: 13px;
    color: #7782A1;
    margin-bottom: 0px;
}

.varify-subdomain-tablehead{
    display: flex;
    list-style: none;
    padding: 12px 0px;
    border-bottom: 1px solid #E3E6F1;
}
.mob-varify-subdomain-first-tablehead{
    display: none;
}
.varify-subdomain-first-tablehead{
    width: 36%;
}
.varify-subdomain-second-tablehead{
    width: 41%;
}
.varify-subdomain-third-tablehead{
    width: 11%;
}
.varify-subdomain-fourth-tablehead{
    width: 12%;
}
.varify-domain-created-list{
    display: flex;
        padding: 30px 0px;
        border-bottom: 1px solid #E3E6F1;
        list-style: none;
}
.domain-list-text{
    font-family: 'Axiforma-Regular';
    font-size: 14px;
    margin-bottom: 0px;
    color: #1B2952;
    text-align: left !important;
    word-break: break-all;
    padding-right: 10px;
}
.domain-list-copy-icon{
    margin-left: 10px;
    cursor: pointer;
}
.varify-subdomain-div .agency-tooltip .agency-tooltipsubheader {
    padding: 10px 10px 10px 10px;
    top: 26px;
    left: unset;
    width: 200px;
    right: -67px;
}
.varify-subdomain-div .agency-tooltip .agency-tooltipsubheader::after {
    top: -8px;
    right: 65px;
    border-color: transparent transparent black transparent;
}
.domain-error-popup-body{
    padding: 60px 40px 60px 40px !important;
}
.domain-error-message-div{
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.domain-error-head-text{
    font-family: "Axiforma-Bold";
font-size: 18px;
line-height: 100%;
color:#1B2952;
margin-bottom: 0px;

}
.domain-error-message-main-div{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.domain-error-text-message{
    font-family: "Axiforma-Regular";
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #7782A1;
margin-bottom: 30px;
}
.domain-okay-button{
    width: 140px;
    background-color: #3064F9;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Axiforma-Regular";
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;
}
.domain-okay-button:hover{
    background-color: #134BEC;
    transition: .5s;
}
.subdomain-varify{
    position: absolute;
    top: 20px;
    right: 28px
}
.mob-subdomain-varify{
    display: none;
}
.domain-error-popup{
    max-width: 480px;
    max-height: 292px
}

.validating-loader-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
}

.validating-loader {
    position: absolute;
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    background:conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, #FFFFFF 360deg);;
    border-radius:50%;
    animation: 1s rotate infinite linear;



  }
  .validating-loader::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    background: #FFB300;
    border-radius: 28px;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }







@media (min-width: 2400px) {
    .checkout-country .dropdown-toggle{
        padding: 0 15px 5px 15px!important;
    }
    .agency-tooltip {
        top: -2px;
    }
    .verify-org-email {
        line-height: 38px;
    }
    .save-org-email {
        line-height: 38px;
    }
}

  @media (min-width: 1650px) and (max-width: 1900px) {
    .team-inner-content {
        padding-right: 150px;
        padding-left: 140px !important;
    }
  
    .team-content {
      margin-right: 0px !important;
    }
    h3.team-paragraph {
        font-size: 42px;
    }
    .agency-dashboard-support-header-left{
        max-width: 70%;
    }
    
  }
  
  @media (min-width: 1401px) and (max-width: 1649px) {
    .team-inner-content {
      padding-right: 20px !important;
      padding-left: 60px !important;
    }
  
    .team-content {
      margin-right: 10px !important;
    }
    h3.team-paragraph {
        font-size: 40px;
    }
    .web-org-right-panel {
        width: 300px;
    }
    .org-dashboard-midsection {
        width: calc(100% - 300px);
    }
    .agency-data {
        display: block;
        width: 100%;
    }
    .org-data {
        margin-left: 0px;
        margin-top: 20px;
    }
    .org-icon {
        width: 100%;
    }
    .agency-personal-data {
        display: block;
    }
    .agency-personal-data h2{
        margin-top: 20px;
    }
    .agency-app-data div {
        padding: 10px;
    }
    .agency-app-data div h3 {
        font-size: 24px;
        margin: 0px 6px 0px 10px;
    }
    .agency-personal-data div:nth-child(1) {
        width: 90px;
        height: 90px;
    }
    .agency-personal-data div:nth-child(1) {
        width: 90px;
        height: 90px;
    }
    .agency-dashboard-support-body {
        grid-gap: 14px;
        gap: 14px;
        grid-template-columns: repeat(3, 1fr);
    }
    .agency-dashboard-support-header-right {
        margin-top: 12px;
    }
    .agency-dashboard-support-header {
        display: block;
    }
    .agency-support-body-description {
        padding-right: 8px;
    }
  }
  


@media (min-width: 1201px) and (max-width: 1400px) {
    .customer-body-row h4 {
        font-size: 13px;
        word-break: break-all;
    }
    .customer-status h5 {
        font-size: 13px;
        word-break: break-all;
    }
    .customer-entry span {
        margin: 0px 0px 0px 20px;
    }
    .agency-list-app {
        width: 22.8%;
    }
    .customer-status div {
        margin-right: 30px;
    }
    .customer-profile h2 {
        font-size: 13px;
        word-break: break-all;
    }
    .agency-applist-head div:nth-child(4), .agency-app-entry:nth-child(4) {
        width: 15%;
    }
    .agency-applist-head div:nth-child(1), .agency-app-entry:nth-child(1) {
        width: 23%;
    }
    .customer-body-row {
        padding-left: 15px;
        padding-right: 15px;
    }
    .agency-customer-head , .agency-applist-head , .agency-subscription-head{
        padding-left: 15px;
        padding-right: 15px;
    }
    .explain-domain-structure p {
        top: 50px;
    }
    .emailpreview {
        width: auto;
    }
    .org-email-right {
        padding: 70px 30px 70px 0px;
    }
    .team-inner-content {
        padding-right: 20px;
        padding-left: 60px !important;
      }
    
      .team-content {
        margin-right: 10px !important;
      }
    
      h3.team-paragraph {
        font-size: 32px !important;
        line-height: 50px;
      }
      .btn.btn-primary {
        width: 170px;
        height: 45px;
        font-size: 12px;
      }
      .team-content-details ul li {
        font-size: 11px;
      }
    
      .team-content-text {
        font-size: 12px;
      }
      .team-img {
        max-width: 50%;
    }
    .web-org-right-panel {
        width: 300px;
    }
    .org-dashboard-midsection {
        width: calc(100% - 300px);
    }
    .agency-data {
        display: block;
        width: 100%;
    }
    .org-data {
        margin-left: 0px;
        margin-top: 20px;
    }
    .org-icon {
        width: 100%;
    }
    .agency-personal-data {
        display: block;
    }
    .agency-personal-data h2{
        margin-top: 20px;
    }
    .agency-app-data div {
        padding: 10px;
    }
    .agency-app-data div h3 {
        font-size: 24px;
        margin: 0px 6px 0px 10px;
    }
    .agency-personal-data div:nth-child(1) {
        width: 90px;
        height: 90px;
    }
    .agency-dashboard-support-body {
        grid-gap: 14px;
        gap: 14px;
        grid-template-columns: repeat(2, 1fr);
    }
    .agency-dashboard-support-header-right {
        margin-top: 12px;
    }
    .agency-dashboard-support-header {
        display: block;
    }
    .agency-dashboard-support {
        margin-top: 20px;
    }
}

@media screen and (min-width: 2200px) {
    .agency-list-app {
        width: 14.6%;
    }
    .agency-list-box {
        width: calc(100% + 50px);
    }
    .emailpreview {
        width: 700px;
    }
    .save-team-btn img{
        position: relative;
        top: -1px;
    }
    .save-team-btn {
        line-height: 42px;
    }
    .team-inner-content {
        justify-content: space-evenly;
      }
      .team-content-details ul li:before {
        top: 8px;
      }
    
      h3.team-paragraph {
        font-size: 50px !important;
        line-height: 72.5px;
        padding-top: 10px;
      }
      .team-content-text {
        font-size: 20px;
        line-height: 35px;
        margin-bottom: 30px;
      }
    
      .team-content-details ul li {
        line-height: 25px;
        font-size: 17px;
        padding-left: 20px !important;
        padding-bottom: 15px;
      }
    
      .btn.btn-primary {
        width: 250px;
        font-size: 20px;
        height: 65px;
        margin-top: 20px;
      }
    
      .team-heading h1 {
        font-size: 30px;
      }
    
}





@media screen and (max-width: 400px) {
    .agency-price-breakup-head h2 {
        font-size: 14px !important;
    }
}

@media (min-width: 500px) and (max-width: 1199px) {
    .smtpcredentials-head p {
        position: inherit!important;
    }
    .smtpcredentials-head .org-email-settings {
        padding: 25px 15px 0px 15px!important;
    }
    .agency-pricing-tabs .all-discount-percent {
        top: -13px;
    }
    .org-module {
        background-color: #fff!important;
    }
}

  
@media screen and (max-width: 1200px) {
.agency-headings .app-dashboard-head{
     margin: 0px;
}
.org-domain-head-new{
    min-height: unset !important;
}
.domain-validating-div {
display: flex;
align-items: center;
background-color: unset;
padding: 0px;
}

.domain-validating-text{
color: #FFB300;
}

.validating-loader {
background:conic-gradient(from 90deg at 50% 50%, rgba(255, 179, 0, 0) 0deg, #FFB300 360deg);
}

.validating-loader::before {
background: #FFFFFF;
}

.varify-domain-question {
margin-top: 0px;
}
.mob-subdomain-varify{
    position: unset;
    display: block;
    padding-bottom: 10px;
}

    .varify-subdomain-tablehead{
        display: none;
    }
.varify-subdomain-div {
 padding: 0px;
}
.varify-subdomain-head {
    flex-direction: column;
    margin-bottom: 0px;
    padding: 30px 15px;
}
.varify-subdomain-right {
    flex-direction: column-reverse;
    padding-top: 20px;
}
.domain-unvarified-div, .domain-varified-div {
    background-color: unset;
    height: fit-content;
    padding: 0px;
}
.mob-varify-subdomain-first-tablehead{
    display: block;
}
.varify-domain-created-list {
    padding: 30px 15px;
    border-top: 1px solid #E3E6F1;
    border-bottom: unset;
    flex-direction: column;
    gap: 30px;
}
.varify-subdomain-first-tablehead{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.varify-subdomain-second-tablehead{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.varify-subdomain-third-tablehead{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.varify-subdomain-fourth-tablehead{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.left-org-domain-set{
    width: 100% !important
}
.varify-subdomain-div .agency-tooltip .agency-tooltipsubheader {
    padding: 10px 10px 10px 10px;
    top: 22px;
    left: -50px;
    width: 200px;
    right: unset;
}
.varify-subdomain-div .agency-tooltip .agency-tooltipsubheader::after {
    top: -3px;
    right: 69%;
        margin-top: -7px;
        transform: rotate(90deg);
        border-color: transparent black transparent transparent;
}
.domain-error-popup-body {
    display: flex;
        align-items: center;
        justify-content: center;
        padding: 60px 20 !important;
}
.domain-error-popup-body .crossmodal {
    top: 40px;
    right: 20px
}
.domain-okay-button {
    width: 280px;
    height: 50px;
    font-size: 16px;
}









    .agency-pricing-page .container .pricing-notice{
        margin-bottom: 0px !important;
        margin-top: 80px !important;
    }
    .subscription-number-mob{
        color: #BCC1CE;
        font-family: "Axiforma-Regular";
        font-size: 13px;
        margin-bottom: 0px;
        margin-left: 3px;
        display: block;
    }
    .subscription-number-mob span{
        color: #7782A1;
        font-family: "Axiforma-Regular";
        font-size: 16px;
        margin-bottom: 0px;
    }
    .agency-sub-head{
        display: flex;
        justify-content: space-between;
    }
    .agency-sub-minhead{
        justify-content: space-between!important;
    }
    .agency-report-aerotopsecond{
        display: none;
    }
    .agency-report-day-aerotopsecond{
        display: none;
          }
    .plan-cacel-confirm {
        width: 100%;
    margin-left: 0px;
    font-size: 16px;
    }
    .orgspinner {
        top: 0px;
        width: calc(100% - 30px);
        height: calc(100vh - 30px);
        min-height: 70vh;
        margin: 15px 15px 15px 15px;
    }
    .new-signupmain-div {
        display: block;
        min-height: 100vh;
        background-color: #fff;
    }
    .create-agency-left {
        flex: 0 0 50%;
        max-width: 100%;
        height: auto;
    }
    .create-agency-left-position {
        margin-top: 31px;
        padding: 0px 20px;
    }
    .create-agency-left h1 {
        font-size: 30px;
    }
    .create-agency-left h2 {
        margin: 16px 0px 31px 0px;
        line-height: 18px;
    }
    .create-agency-left button{
        font-size: 16px;
    }
    .create-agency-right {
        flex: 0 0 50%;
        max-width: 100%;
        justify-content: center;
        display: flex;
        padding: 0px;
        background-color: #ffffff;
        height: auto;
    }
    .create-agency-right-position {
        margin-top: 60px;
        max-width: 100%;
    }
    .create-agency-right .signup-crousel .slide {
        background-color: #ffffff !important;
    }
    .org_background {
        background-color: #f8fafe;
        padding: 24px 15px 30px 15px;
        width: 100%;
        margin-left: 0px;
    }
    .org-dashboard {
        display: block;
    }
    .org-dashboard-midsection {
        width: 100%;
    }
    .agency-app-data {
        width: 100%;
        display: block;
    }
    .agency-app-data div {
        width: 100%;
        margin-top: 20px;
    }
    .agency-data {
        display: block;
    }
    .org-app-info {
        width: 100%;
        display: block;
        padding: 15px 15px 20px 15px;
    }
    .dashboard-bar-graph {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 20px 0px 10px;
    }
    .no-data-graph{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 20px 0px 10px;
    }
    .org-icon {
        width: 100%;
        height: 100px;
    }
    .agency-steps {
        padding: 0px 0px 0px 6px;
    }
    .agency-list-app {
        margin: 0px 0px 35px 0%;
        width: 100%;
    }
    .agency-list-box {
        width: 100%;
    }
    .org-domain-settings {
        padding: 25px 15px 32px 15px;
    }
    .org-domain-body .org-domain-right{
        display: none;
    }
    .org-cname-body {
        padding: 25px 15px 32px 15px;
        margin-top: 20px;
        position: relative;
    }
    .domain-steps {
        display: block;
        padding: 0px;
    }
    .setup-domain {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .domain-popup-body .domain-button span {
        width: 100%;
        height: 50px;
        font-size: 16px;
    }
    .crossicon-popup{
        top: 20px;
    }
    .domain-popup-body {
        padding: 81px 20px 20px 20px!important;
        display: flex;
        align-items: center;
    }
    .org-email-head .save-org-email , .org-email-head .verify-org-email{
        display: none;
    }
    .org-email-head h1 {
        margin-bottom: 0px;
    }
    .org-email-head {
        margin-bottom: 18px;
    }
    .cname-detail svg {
        top: 18px;
    }
    .agency-mobile-btns{
        display: block;
    }
    .agency-mobile-btns-blank{
        border: 1px solid #3064F9;
        height: 50px;
        background-color: transparent;
        border-radius: 3px;
        color: #3064F9;
        width: 100%;
        transition: .5s;
        position: relative;
        margin-top: 20px;
        text-align: center;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .agency-mobile-btns-blue{
        border: 1px solid #3064F9;
        height: 50px;
        background-color: #3064F9;
        border-radius: 3px;
        color: #fff;
        width: 100%;
        transition: .5s;
        position: relative;
        margin-top: 20px;
        text-align: center;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btndisable , .btndisable:hover{
        background-color:#c8cedb;
        color:#FFFFFF;
        border:1px solid #c8cedb;
        cursor: not-allowed !important;
        box-shadow: none!important;
    }
    .agency-mobile-btns-blue p , .agency-mobile-btns-blank p{
        margin-bottom: 0px;
        font-size: 16px;
    }
    .agency-mobile-btns-blue-deavtivate , .agency-mobile-btns-blue-deavtivate:hover{
        background-color: #BCC1CE;
        border: 1px solid #BCC1CE;
        color: #fff;
        cursor: not-allowed;
    }
    .agency-mobile-btns-blank-deavtivate , .agency-mobile-btns-blank-deavtivate:hover{
        background-color: transparent;
        border: 1px solid #BCC1CE;
        color: #BCC1CE;
        cursor: not-allowed;
    }
    .agency-mobile-btns h5{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        text-align: center;
        margin-top: 17px;
        width: 100%;
    }
    .agency-apps-web{
        display: none;
    }
    .org-customer-head{
        display: block;
    }
    .agency-search {
        height: 44px;
        width: 100%;
        margin-top: 18px;
    }
    .org-customer-head h1 {
        font-size: 20px;
    }
    .agency-apps-mobile .org-module-shadow{
        background-color: #fff;
        margin-bottom: 20px;
    }
    .agency-apps-mob-1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 108px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-apps-mob-1 h2{
        font-family: "Axiforma-Bold";
        font-size: 14px;
        color: #1B2952;
        margin: 0px;
    }
    .agency-apps-mob-1 h3{
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782A1;
        margin: 7px 0px 0px 0px;
    }
    .agency-app-image-mobile{
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        height: 60px;
        min-height: 60px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 15px;
    }
    .agency-apps-mob-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 85px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-apps-mobile h6{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        margin: 0px;
    }
    .agency-apps-mob-2 h4{
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1B2952;
        margin: 6px 0px 0px 0px;
    }
    .agency-apps-mob-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 94px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-apps-mob-3 img{
        margin-top: 9px;
    }
    .agency-apps-mob-4{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 95px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-app-technology{
        width: 100%;
        align-items: center;
        margin-top: 9px;
    }
    .agency-tooltip .agency-tooltipsubheader {
        width: 190px;
        top: 35px;
        left: -50px;
    }
    .agency-tooltip .agency-tooltipsubheader::after {
        top: -3px;
        right: 65%;
        margin-top: -7px;
        transform: rotate(90deg);
    }
    .agency-apps-mobile-view{
        display: block;
    }
    .agency-apps-mobile-view .agency-apps-mobile:last-child .org-module-shadow{
        margin-bottom: 0px;
    }
    .agency-sub-web{
        display: none;
    }
    .agency-sub-mobile{
        display: block;
    }
    .agency-sub-mobile .org-module-shadow{
        background-color: #fff;
        margin-bottom: 20px;
    }
    .agency-sub-mob-1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 108.5px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-sub-mob-1 h2{
        font-family: "Axiforma-Bold";
        font-size: 14px;
        color: #1B2952;
        margin: 0px;
    }
    .agency-sub-mob-1 h3{
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782A1;
        margin: 7px 0px 0px 0px;
    }
    .agency-sub-mob-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 85px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-sub-mobile h6{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        margin: 0px;
    }
    .agency-sub-mobile h4{
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1B2952;
        margin: 6px 0px 0px 0px;
    }
    .agency-sub-mob-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 107px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-sub-mob-3 h5 , .agency-sub-mob-4 h5{
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782A1;
        margin: 8px 0px 0px 0px;
    }
    .agency-sub-mob-3 span{
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #3064F9;
    }
    .agency-sub-mob-4{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 109px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .subscription-profile-image-mob {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        min-height: 40px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 100%;
    }
    .agency-sub-mob-5{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-customer-web{
        display: none;
    }
    .agency-customer-mob{
        display: block;
    }
    .customer-image-mobile {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        height: 60px;
        min-height: 60px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 100%;
    }


    .agency-customer-mob .org-module-shadow{
        background-color: #fff;
        margin-bottom: 20px;
    }
    .customer-sub-mob-1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 108.5px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .customer-sub-mob-1 h2{
        font-family: "Axiforma-Bold";
        font-size: 14px;
        color: #1B2952;
        margin: 0px;
    }
    .customer-sub-mob-1 h3{
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782A1;
        margin: 7px 0px 0px 0px;
    }
    .customer-sub-mob-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 85px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-customer-mob h6{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        margin: 0px;
    }
    .agency-customer-mob h4{
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1B2952;
        margin: 6px 0px 0px 0px;
        word-break: break-word;
    }
    .customer-sub-mob-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 89.5px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .customer-sub-mob-3 h5{
        font-family: "Axiforma-Regular";
        font-size: 14px;
        margin: 8px 0px 0px 0px;
    }
    .customer-sub-mob-3 span{
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #3064F9;
    }
    .agency-customer-mob .org-module-shadow:last-child{
        margin-bottom: 0px;
    }
    .deactivate-customer-popup h5 {
        width: 95%;
    }
    .deactivate-customer-footer {
        flex-direction: column-reverse;
    }
    .customer-deactivate-btn-mob{
        width: 280px;
        border: 1px solid #FF4848;
        height: 44px;
        line-height: 40px;
        border-radius: 4px;
        background-color: #FF4848;
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
        text-align: center;
        cursor: pointer;
        margin: auto;
        display: block;
    }
    .customer-cancel-btn{
        width: 280px;
        border: none;
        margin: auto;
        margin-top: 20px;
    }
    .customer-deactivate-btn{
        display: none;
    }
    .customer-activate-btn-mob{
        width: 280px;
        border: 1px solid #3064F9;
        height: 44px;
        line-height: 40px;
        border-radius: 4px;
        background-color: #3064F9;
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
        text-align: center;
        cursor: pointer;
        margin: auto;
        display: block;
    }
    .activate-customer-cancel-btn{
        width: 280px;
        border: none;
        margin: auto;
        margin-top: 20px;
    }
    .customer-activate-btn{
        display: none;
    }
    .org-module-shadow .org-email-right{
        display: none;
    }
    .org-email-settings {
        padding: 25px 15px 30px 15px;
    }
    .smtpcredentials {
        padding: 0px 15px 30px 15px;
    }
    .agency-web-timestamp{
        display: none;
    }
    .org-social-right{
        display: none;
    }
    .org-facebook-login {
        padding: 20px 15px 20px 15px;
    }
    .save-social-btn{
        display: none;
    }
    .org-social-body {
        padding: 0px;
    }
    .org-social-tabs svg rect{
        fill: #fff!important;
    }
    .org-social-tabs svg{
        width: 44px;
        height: 44px;
    }
    .org-social-tabs h4 {
        margin-left: 10px;
    }
    .org-social-tabs h6{
        margin-right: 4px;
    }
    .org-google-login {
        padding: 20px 15px 20px 15px;
    }
    .contact-support-preview{
        display: none;
    }
    .org-setting-head .save-setting-btn{
        display: none;
    }
    .org-support-body {
        padding: 0px 15px 30px 15px;
    }
    .org-domin-right{
        display: none;
    }
    .org-domin-left {
        padding: 10px 15px 35px 15px;
    }
    .org-setting-body {
        padding: 30px 15px 30px 15px;
    }
    .org-color-input .color_input{
        padding: 4px !important;
    }
    .org-setting-body .row .col-md-6:nth-child(2){
        margin-top: 20px!important;
    }
    .delete-org {
        display: block;
        padding: 25px 15px 30px 15px;
    }
    .delete-org a{
        width: 100%;
        height: 50px;
        margin-top: 29px;
        line-height: 50px;
    }
    .delete-agency-btn{
        background-color: #FF4848!important;
        border: 1px solid #FF4848!important;
        color: #fff!important;
    }
    .confirm-org-popups .confirm-org-body {
        padding: 250px 34px 10px 34px;
        text-align: center;
    }
    .confirm-org-footer {
        border-top: none;
        padding: 0px;
        display: block;
    }
    .confirm-org-footer{
        padding: 00px 34px 0px 34px;
        margin: auto;
    }
    .confirm-org-footer-button-delete{
        width: 100%;
        height: 50px;
        background-color: #FF4848;
        margin: auto;
        color: #fff;
        font-size: 16px;
    }
    .confirm-org-footer-button-cancel{
        width: 100%;
        height: 50px;
        margin: auto;
        font-size: 16px;
        border: none;
    }
    .confirm-org-popups h6{
        margin-bottom: 35px;
    }
    .confirm-org-footer-button-cancel:hover{
        box-shadow: none;
    }
    .org-social-body label {
        margin: 0px 0px 10px 0px;
    }
    .org-support-phone p {
        min-width: 75px;
    }
    .org-setting-head .leave-org-btn-activate , .org-setting-head .leave-org-btn-deactivate{
        display: none;
    }
    .teams-web-view{
        display: none;
    }
    .org-head-right .web-save-agency-team-btn{
        display: none;
    }
    .agency-mob-save-team-btn{
        display: block;
    }
    .org-head-right .save-team-btn{
        width: 100%;
        margin-bottom: 20px;
        margin-top: -10px;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
    }
    .org-head-right p {
        margin-right: 0px;
    }
    .invite-team-mob{
        display: block;
    }
    .invite-team-mob .save-team-btn{
        width: 100%;
        margin-bottom: 20px;
    }
    .agency-teams-mobile .org-module-shadow {
        background-color: #fff;
        margin-bottom: 20px;
    }
    .agency-teams-mob-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 108px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-teams-mob-1 h2 {
        font-family: "Axiforma-Bold";
        font-size: 14px;
        color: #1B2952;
        margin: 0px;
    }
    .agency-teams-mob-1 h3 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782A1;
        margin: 7px 0px 0px 0px;
    }
    .agency-teams-mob-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 85px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-teams-mobile h6 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        margin: 0px;
    }
    .agency-teams-mobile h4 {
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1B2952;
        margin: 6px 0px 0px 0px;
    }
    .agency-teams-mob-3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 109px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-teams-mob-3 h6 {
        font-family: "Axiforma-Regular";
        font-size: 12px;
        color: #BCC1CE;
        margin: 0px;
    }
    .agency-teams-mob-3 h4 {
        font-family: "Axiforma-Regular2";
        font-size: 14px;
        color: #1B2952;
        margin: 6px 0px 0px 0px;
    }
    .agency-teams-mob-3 h5 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        color: #7782A1;
        margin: 8px 0px 0px 0px;
    }
    .agency-teams-mob-4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 89.5px;
        padding: 0px 15px;
        border-bottom: 1px solid rgb(227 230 241 / 50%);
    }
    .agency-teams-mob-4 h5 {
        font-family: "Axiforma-Regular";
        font-size: 14px;
        margin: 8px 0px 0px 0px;
    }
    .agency-teams-mobile{
        display: block;
    }
    .agency-team-popup .modal-content{
        position: inherit !important;
        padding-top: 100px;
        min-height: 100vh;
    }
    .org-social-body input{
        margin-bottom: 10px;
    }
    .web-org-right-panel {
        width: 100%;
    }
    .delete-agency-footer-cancel{
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        position: relative;
        top: 60px;
        border: none;
    }
    .agency-change-plan-popup .delete-agency-footer {
        display: block;
        border: none;
        padding: 0px 34px 20px 34px;
    }
    .delete-agency-footer-change{
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        position: relative;
        top: -60px;
    }
    .agency-change-plan-popup .delete-org-body {
        padding: 125px 34px 25px 34px;
    }
    .agency-support-master-toggle .agency-tooltip .agency-tooltipsubheader {
        left: -110px;
    }
    .agency-support-master-toggle .agency-tooltip .agency-tooltipsubheader::after {
        right: 34%;
    }
    .deactivate-customer-popup .modal-body {
        display: flex;
    }
    .org-support-phone h6 {
        min-width: 100px!important;
    }
    .org-data {
        margin-left: 0px;
        margin-top: 15px;
    }
    .org-app-info h5{
        margin: 0px 0px 10px 0px;
    }
    .setup-domain:last-child{
        margin-bottom: 28px;
    }
    .org-cname-body h5 {
        margin-bottom: 12px;
    }
    .agency-mobile-btns .agency-mobile-btns-blank{
        /* border: 1px solid #BCC1CE;
        color: #BCC1CE; */
    }
    .btnsavedisable{
        border: 1px solid #E3E6F1;
        color: #BCC1CE;
        cursor: not-allowed !important;
    }
    .confirm-org-popups .confirm-org-footer-button-cancel{
        position: relative;
        top: 80px;
    }
    .confirm-org-popups .confirm-org-footer-button-delete{
        position: relative;
        top: -30px;
    }
    .agency-team-popup{
        max-height: fit-content!important;
    }
    .agency-team-popup .agency-team-popup-footer{
        display: block!important;
        width: 100%;
        border: none!important;
        padding: 12px 20px 45px 20px !important;
    }
    .agency-team-popup{
        width: 100%!important;
    }
    .agency-team-popup .cancel-user .cancel-btn{
        width: 100%;
        position: relative;
        top: 80px;
        border: none;
        text-transform: capitalize;
    }
    .agency-team-popup .next_btn{
        width: 100%;
        position: relative;
        top: -30px;
    }
    .agency-team-popup .invite-user {
        padding: 0px 20px 0px 20px !important;
    }
    .agency-team-popup .modal-content p{
        text-align: left!important;
    }
    .agency-teams-mob-1 .member-img {
        margin-right: 0px;
    }
    .agency-team-popup .invite-user-body{
        margin-bottom: 15px;
        margin-top: 30px;
    }
    .team-member-edit {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 80px;
        text-align: right;
    }
    .team-member-edit span{
        margin-left: 20px;
    }
    .team-limit-btn{
        width: 280px;
        /* position: relative;
        top: -30px; */
        margin-left: 0;
        margin: auto;
    }
    .team-limit-btn-cancel{
        /* position: relative;
        top: 80px; */
    }
    .activate-customer-popup h5 {
        width: 90%;
    }
    .delete-agency-footer-change div {
        margin: 15px auto;
    }
    .org-cname-body h6 {
        margin-bottom: 70px;
    }
    .org-cname-body span {
        position: absolute;
        top: 125px;
    }
    .delete-org-popups .delete-org-body {
        padding: 230px 20px 10px 20px;
    }
    .smtpcredentials-head{
        position: relative;
    }
    .smtpcredentials-head p{
        position: absolute;
        left: 16px;
        top: 95px;
    }
    .smtperror-popup h6 {
        width: 100%;
    }
    .smtpcredentials-head .org-email-settings {
        padding: 25px 15px 20px 15px;
    }
    .smtpcredentials .select-img-ratio div p {
        margin-top: 4px;
    }
    .agency-list-head{
        display: block;
        padding-top: 80px;
        width: 100%;
    }
    .agency-list {
        width: 100%;
        max-width: 100%;
    }
    .agency-single-left {
        display: block;
    }
    .agency-background {
        padding: 15px;
    }
    .agency-single {
        height: auto;
        margin-bottom: 15px;
        padding: 15px 15px 22px 15px;
        display: block;
    }
    .agency-single-placeholder {
        width: 100%;
        margin-bottom: 15px;
    }
    .agency-single-details h4 {
        margin: 10px 0px 10px 0px;
    }
    .agency-list-head .d-flex .create-org-btn{
        display: none;
    }
    .agency-list-head .justify-content-between a{
        font-size: 15px;
        font-family: "Axiforma-Regular";
        color: #3064F9;
        display: block;
    }
    .agency-list-head .justify-content-between a svg{
        margin-right: 6px;
    }
    .create-agency-main {
        display: block;
    }
    .agency-price-manage {
        display: block;
    }
    .agency-price-left {
        width: 100%;
    }
    .agency-price-right {
        width: 100%;
        min-width: 100%;
        padding: 27px 20px 10px 20px;
    }
    .custom-quote {
        display: block;
        padding: 40px 20px;
        margin-top: 40px;
    }
    .custom-quote h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .custom-quote h3 {
        font-size: 14px;
        line-height: 18px;
    }
    .custom-quote a {
        width: 140px;
        height: 40px;
        line-height: 36px;
        margin-top: 30px;
    }
    .agencyfaq {
        margin-top: 60px;
    }
    .pricing-whitelabel-features {
        margin-top: 30px;
    }
    .agency-price-tooltip .agency-price-tooltipsubheader {
        width: 185px;
        top: 30px;
        left: -52px;
    }
    .agency-price-tooltip .agency-price-tooltipsubheader::after {
        top: -3px;
        right: 65%;
        margin-top: -7px;
        transform: rotate(90deg);
    }
    .agency-faq-gap{
        height: 60px;
    }
    .agency-pricing-page h1 {
        font-size: 26px;
        padding: 30px 0px 20px 0px;

    }
    .agency-price-breakup-head h2 {
        font-size: 16px;
    }
    .agency-price-breakup {
        padding: 25px 20px 15px 20px;
        margin-bottom: 20px;
    }
    .agencyfaq .accordion-item {
        padding-bottom: 0px;
    }
    .agency-error-webimg{
        display: none;
    }
    .agency-error-mobimg{
        display: block;
        max-width: 100%;
        height: auto;
        margin: auto;
    }
    .agency-errorpage{
        padding: 0px;
    }
    .agency-errorpage h1 {
        font-size: 32px;
        text-align: left;
        line-height: 44px;
        padding-left: 20px;
        margin-bottom: 10px;
    }
    .agency-errorpage h2 {
        font-size: 13px;
        text-align: left;
        line-height: 24px;
        padding-left: 20px;
    }
    .agency-errorpage h3 {
        font-size: 14px;
        text-align: left;
        line-height: 24px;
        padding-left: 20px;
    } 
    .agency-errorpage a {
        margin: inherit;
    }
    .agency-subdomain-login {
        /* width: calc(100% + -10px)!important; */
    }

    .box-img {
        padding-top: 0;
        padding-bottom: 36px;
      }
      .team-box-wrapper {
        padding-bottom: 0;
      }
      .team-right-box {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 15px !important;
      }
    
      .team-inner-content {
        flex-direction: column !important;
        padding-top: 30px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    
      .team-heading h1 {
        padding-top: 25px !important;
        padding-bottom: 21px !important;
        font-size: 20px;
      }
      .team-img {
        padding-left: 0 !important;
        padding-top: 40px;
      }
      /*---------------------------------->*/
    
      .tooltip-heading-content {
        position: relative !important;
        display: inline-block !important;
        justify-content: space-between !important;
      }
    
      .team-heading-content .team-info-icon {
        cursor: pointer !important;
        fill: #bcc1ce !important;
        stroke: #bcc1ce !important;
        transition: 0.5s !important;
        position: relative !important;
        padding-right: 10px;
        /* padding-top: 7px; */
      }
    
      .team-heading-content .team-info-icon {
        visibility: visible;
      }
    
      .team-heading-content .team-info-icon .info-tooltip {
        width: 21px !important;
        background-color: black !important;
        color: #fff !important;
        text-align: center;
        border-radius: 6px !important;
        padding: 22px 25px 21px 0 !important;
        visibility: hidden;
    
        /* Positioning the tooltip */
        position: absolute !important;
        z-index: 1 !important;
      }
    
      .team-heading-content .team-info-icon:hover .info-tooltip {
        visibility: visible !important;
      }
    
      .team-inner-box {
        width: 100% !important;
        background-color: #fff;
        background-image: none;
        height: auto;
      }
    
      .team-content {
        padding-top: 0;
        margin-right: 0 !important;
        max-width: 100%;
      }
    
      h3.team-paragraph {
        font-size: 30px !important;
        line-height: 40px !important;
        margin-bottom: 20px !important;
        /* margin-right: 75px !important; */
      }
    
      .team-content-text {
        color: #7782a1;
        font-size: 14px;
        font-family: "Axiforma-Regular";
        line-height: 19px;
        margin-bottom: 30px;
        margin-right: 20px;
      }
    
      ul {
        margin-bottom: 0 !important;
      }
    
      .team-content-details ul li {
        line-height: 25px !important;
        font-size: 14px !important;
        padding-left: 15px !important;
        padding-bottom: 15px !important;
      }
      .team-content-details ul li:before {
        top: 8px;
      }
      .btn.btn-primary {
        width: 100%;
        font-size: 16px !important;
        margin-top: 0 !important;
      }
      .agency-plan-detail{
        margin-top: 20px;
      }
      .agency-plan-section-one p{
        text-align: left!important;
      }
      .org-dash-mid-panel {
        display: block;
        width: 100%;
    }
    .agency-personal-data {
        width: 100%;
    }
    .range-slider .range-slider__thumb {
        width: 40px!important;
        height: 40px!important;
    }
    .subdomain-status-one{
        right: 0px!important;
        top: 56px!important;
    }
    .agency-headings{
        justify-content: flex-start;
    }
    .org-app-head{
        display: block;
        position: relative;
    }
    .org-app-head h5{
        position: absolute;
        right: 0;
        top: -40px;
        margin-right: 0px;
    }
    .smtperror-popup .customer-activate-btn {
        display: block;
        width: 80%;
        margin: auto;
    }
    .agency-pricing {
        padding: 60px 20px 0px 20px;
        display: block;
        text-align: center;
    }
    .agency-pricing img{
        margin: auto;
    }
    .agency-pricing-details{
        margin-left: 0px;
        text-align: left;
        margin-top: 60px;
    }
    .agency-pricing div h4 {
        font-size: 12px;
        max-width: 100%;
    }
    .agency-pricing-footer {
        display: block;
    }
    .agency-pricing-buy{
        width: 100%;
        margin-bottom: 30px;
    }
    .cancel-agency-subscription{
        margin-left: 0px;
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 20px;
    }
    .agency-precing-reactivate {
        margin-left: 0px;
        width: 100%;
        display: block;
        text-align: center;
    }
    .agency-precing-reactivate p {
        margin-bottom: 7px;
    }
    .agency-table {
        /* margin: 0px 15px 0px 15px;
        border-radius: 6px;
        padding: 50px 0px 0px 0px; */
        padding: 50px 15px 0;
    }
    .agency-table .container{
        padding: 0px;
    }
    .agency-table h2 {
        font-family: "Axiforma-Bold";
        font-size: 26px;
        color: #1B2952;
        text-align: left;
        line-height: 40px;
        margin-bottom: 10px;
    }
    .agency-table h3 {
        font-size: 14px;
        color: #7782A1;
        text-align: left;
        line-height: 18px;
        margin-bottom: 30px;
    }
    .agency-table-detail-list {
        padding: 0px 0px;
    }
    .agency-feature-entry {
        margin-bottom: 20px;
        width: 100%;
    }
    .agency-table-detail-feature {
        padding-bottom: 20px;
    }
    .agency-table h4 {
        margin-bottom: 24px;
    }
    .newagencypricingfaq .accordion-header {
        padding: 0px 0px 28px 0px!important;
    }
    .newagencypricingfaq{
        padding-top: 60px;
    }
    .newagencypricingfaq h3{
        margin-bottom: 36px;
    }
    .agency-pricing-heading{
        display: block;
        margin-bottom: 72px;
    }
    .agency-pricing-page .container {
        padding-top: 0px;
    }
    .agency-pricing-tabs{
        display: block;
        position: relative;
    }
    .agency-pricing-tabs .tabbed ul.tabs{
        width: 100%;
    }
    .agency-pricing-tabs .tab {
        width: 100%;
        text-align: center;
    }
    .apply-agency-deal{
        position: absolute;
        top: 80px;
        left: calc(50% - 62px);
    }
    .deal-applied{
        margin-top: 60px;
    }
    .agency-teamms .modal-content{
        display: block!important;
    }
    .agency-team-popup .modal-content{
        display: block!important;
    }
    .agency-dashboard-support-body {
        padding: 0px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        gap: 0px;
    }
    .agency-support-body-card {
        padding: 25px 10px 25px 10px;
        border-radius: 0px;
        position: relative;
        border: none;
        border-bottom: 1px solid #E3E6F1;
    }
    .agency-dashboard-support-child {
        width: 100%;
    }
    .agency-dashboard-support-header {
        display: block;
    }
    .agency-dashboard-support-header-subheading{
        margin-bottom: 15px;
    }
    .mob-org-right-panel{
        display: block;
    }
    .web-org-right-panel{
        display: none;
    }
    .workspace-mobile-right{
        display: block;
    }
    .agency-dashboard-support-header-right {
        align-items: start;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    .agency-dashboard-support-header-button{
        margin-right: 20px;
    }
    .workspace-web-right{
        display: none;
    }
    
}

@media (max-width: 499px) {
    .team-content-text {
      margin-bottom: 20px !important;
    }
    .agency-pricing-tabs .all-discount-percent {
        top: -12px;
    }
  }
  @media screen and (max-width: 400px) {
    .deal-applied {
        padding: 10px;
    }
    .deal-applied img{
       max-width: 100px !important;
    }
    .deal-applied h5{
        font-size: 12px;
    }
}
  @media (max-width: 375px) {
    h3.team-paragraph {
      font-size: 25px !important;
      line-height: 30px !important;
    }
  
    .team-content-details ul li {
      line-height: 20px !important;
    }
  }
  
  @media (max-width: 324px) {
    h3.team-paragraph {
      font-size: 20px !important;
    }
  }

/* @media (max-width: 850px) and (max-height:883px){
    .agency-version-div{
        position: unset;
       
    }
} */
@media (min-width: 1200px) and (min-height:883px) {
    .agency-version-div{
        position: absolute;
        width: 100%;
        bottom: 0px;
    }
  }