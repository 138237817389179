.bookacalldiv{
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}
.calendly-inline-widget{
  height: 800px!important;
  overflow-y: hidden !important;
}
@media screen and (max-width: 1200px) {

.calendly-inline-widget{
  height: 1200px!important;
  overflow-y: hidden !important;
}
.bookacalldiv{
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}
}