@font-face {
  font-family: "Proxima-Regular";
  src: url(../../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
  font-family: "Proxima-Bold";
  src: url(../../../assets/fonts/ProximaNova-Bold.otf);
}
p {
  font-family: "Axiforma-Regular";
}
.notanyapp {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divwidth {
  width: 50%;
}
.date {
  width: 22.33%;
}
.date:nth-child(1) {
  width: 28%;
}

.date:nth-child(5) {
  text-align: right;
  width: 12%;
}
.notanyapp h2 {
  color: #000000;
  font-size: 20px;
  font-family: "Axiforma-Bold";
}

.notanyapp p {
  color: #7782a1;
  font-size: 16px;
  font-family: "Axiforma-Regular";
}
.buttononnotapp {
  border: 1px solid #3064f9;
  color: #fff !important;
  font-size: 15px;
  border-radius: 2px;
  background: #3064f9;
  height: 44px;
  line-height: 44px;
  margin: 41px auto 0px auto;
  width: 200px;
  cursor: pointer;
  font-family: "Axiforma-Regular";
  border-radius: 4px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  &:hover {
    background-color: var(--custom_color5)!important;
    color: var(--custom_color4)!important;
    border: 1px solid var(--custom_color6)!important;
  }
}
.buttononnotapp:hover {
  box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}

.create_connection h4 {
  font-family: "Axiforma-Regular";
  font-size: 15px;
  color: #1b2952;
  position: relative;
}
.uploadp8 {
  font-family: "Axiforma-Regular";
  border: 1px solid #c8cedb;
  outline: none !important;
  color: #1b2952;
  border-radius: 3px;
  padding: 8px;
}
.uploadp8 label {
  background: #c8cedb;
  border: 1px solid #c8cedb;
  width: 130px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  border-radius: 2px;
  line-height: 40px;
}
.uploadp8 p {
  font-size: 12px;
  color: #1b2952;
  margin: 0px;
}
.appstore {
  border: 1px solid #c8cedb;
  border-radius: 2px;
  padding: 9px 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.appstore p {
  font-size: 14px;
  color: #7782a1;
  margin: 0px;
  padding-left: 10px;
  text-transform: uppercase;
}
.appheading h2 {
  color: #1b2952;
  font-family: "Axiforma-Bold";
  font-size: 20px;
  margin: 0px;
}
.publish h3 {
  color: #1b2952;
  font-size: 16px;
  font-family: "Axiforma-Bold";
  margin-bottom: 20px;
}
.font {
  font-family: "Axiforma-Bold";
}
/* .historytable{
    background: #000;
} */
.historytable h4 {
  font: 14px/21px "Axiforma-Regular";
  color: #1b2952;
  word-break: break-all;
}
.historytabletop p {
  color: #7782a1 !important;
  margin-bottom: 0px;
  font-size: 14px;
}
.hiscommonheight {
  height: 90px !important;
  border-bottom: 1px solid #f1f5fc;
}
.historytabletop {
  border-top: 1px solid #dce1eb;
  border-bottom: 1px solid #dce1eb;
  background: #fcfdff;
  padding: 10px;
}
.header {
  border-bottom: 1px solid #e0e5ec;
  padding-bottom: 17px;
  padding-top: 2px;
  margin-bottom: 30px;
}
@media screen and (max-width: 900px) {
  .divwidth {
    width: 100%;
  }

  .date {
    width: 22.33%;
    display: none;
  }
  .date:nth-child(1) {
    width: 50%;
    display: block;
  }
  .date:nth-child(5) {
    text-align: right;
    width: 50%;
    display: block;
  }
  .appstore {
    padding: 9px 8px;
  }
  .appstore p {
    font-size: 13px;
  }
  .header {
    padding: 0px !important;
    border: none;
  }
  .hideback {
    background: none;
    box-shadow: none;
  }
}
