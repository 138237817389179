@font-face {
	font-family: 'Proxima-Regular';
	src: url(../../assets/fonts/ProximaNova-Regular.otf);
}
@font-face {
	font-family: 'Proxima-Bold';
	src: url(../../assets/fonts/ProximaNova-Bold.otf);
}

p{
    font-family: 'Proxima-Regular';
}
.dropdown-button {
    color: #707070;
}
.notanyapp{
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.divwidth{
    width: 50%;
}
.date{
    width: 25%;
}
.date:nth-child(1){
    width: 40%;
}
.date:nth-child(2){
    width: 25%;
}
.date:nth-child(3){
    text-align: left;
    width: 50%;
    padding-right: 5px;
}
.date:nth-child(4){
    width: 65%;
}
.notanyapp h2{
    color: #000000;
    font-size: 20px;
    font-family: 'Proxima-Bold';
}
.errorimg {
    padding-bottom: 2px;
}
.notanyapp p{
    color: #7782A1;
    font-size: 16px;
    font-family: 'Proxima-Regular';
}
.buttononnotapp{
    border: 1px solid #3064F9;
    color:#fff!important;
    font-size: 15px;
    border-radius: 2px;
    background: #3064F9;
    height: 44px;
    line-height: 44px;
    margin: 41px auto 0px auto;
    width: 200px;
    cursor: pointer;
    font-family: 'Proxima-Regular';
    border-radius: 4px;
    text-transform: uppercase;
    transition: all 0.5s ease;
}
.buttononnotapp:hover{
    box-shadow: 0px 2px 4px rgba(112, 155, 250, 0.69);
}

.create_connection h4{
    font-family: 'Proxima-Regular';
    font-size: 15px;
    color: #1B2952;
    position: relative;
}
.uploadp8{
    font-family: 'Proxima-Regular';
    border: 1px solid #C8CEDB;
    outline: none!important;
    color: #1B2952;
    border-radius: 3px;
    padding: 8px;
}
.uploadp8 label{
    background: #C8CEDB;
    border: 1px solid #C8CEDB;
    width: 130px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 2px;
    line-height:40px
}
.uploadp8 p{
    font-size: 12px;
    color: #1b2952;
    margin: 0px;
}
.appstore{
    border: 1px solid #C8CEDB;
    border-radius: 2px;
    padding: 9px 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.appstore p{
    font-size: 14px;
    color: #7782A1;
    margin: 0px;
    padding-left: 10px;
    text-transform: uppercase;
}
.appheading h2{
    color: #1B2952;
    font-family: 'Proxima-Bold';
    font-size: 20px;
    margin: 0px;
}
.publish h3{
    color: #1B2952;
    font-size: 16px;
    font-family: 'Proxima-Bold';
    margin-bottom: 20px;
}
.font{
    font-family: 'Proxima-Bold';
}
/*.historytable{
    background: #000;
} */
.historytable h4{
    font: 14px/21px 'Proxima-Regular';
    color: #1B2952;
    word-break: break-all;
}
.historytabletop  p {
    color: #000!important;
    font-weight: bolder;
    margin-bottom: 0px;
    font-size: 16px;
}

.hiscommonheight {
    height: 90px!important;
}
.historytabletop  {
    border-top: 2px solid #DCE1EB;
    border-bottom: 1px solid #dce1eb;
    background: #FFF;
    padding: 10px;
}
/*Table Item Styles*/
.errorlist p {
    color: #000!important;
    margin-bottom: 0px;
    font-size: 14px;
    word-break: break-all;
}
.errorlist {
    border-bottom: 1px solid #dce1eb;
    padding: 20px 10px;
}
.errorlistalt p {
    color: #000!important;
    margin-bottom: 0px;
    font-size: 14px;
}
.errorlistalt:nth-child(odd) {
    background: #F6F9FF;
    padding: 20px 10px;
}
.errorlink {
    cursor: pointer;
}
.textwidth {
    max-width: 573px;
}
/* td{
    color: #1B2952 !important;
} */
/*Table Item Styles End*/
.header{
    border-bottom: 1px solid #E0E5EC;
    padding-bottom: 20px;
    padding-top: 10px;
    margin-bottom: 30px;
}
/*ErrorInfo Modal Styles*/
.date a {
    color: blue !important;
}
.topsection {
    padding: 20px 0px 20px 20px !important;
    border-bottom: 1px solid #E3E6F1 !important;
}
.boxtext {
    margin-top: 0;
    display: flex;
    flex-direction: column;
}
.errorbox {
    margin-left: 20px;
    margin-top: 5px;
    padding-left: 17px;
    padding-top: 17px;
    width: 62px;
    height: 62px;
    border: 1px solid #C8CEDB !important;
    border-radius: 15%;
}
.closebutton {
    margin-right: 16px;
    margin-top: -14px;
    font-size: 28px;
    font-weight: 100;
    color: #7782A1;
    cursor: pointer;
}
.errorimg {
    height: 28px;
    width: 28px;
}
.modaltext {
    min-height: 560px;
    padding: 20px !important;
    text-align: left;
    font-size: 13px;
    font-family: 'Axiforma-Regular';
    color: #7782A1;
    
}
@media (max-width: 1200px) {
    .modaltext {
        padding: 10px 20px !important;
        width: unset !important;
            }
            .topsection {
                
                border-bottom: unset!important;
            }
}
/*Error Info Styles End*/
@media screen and (max-width:900px){
    .divwidth{
        width: 100%;
    }
   
    .date{
        width: 25%;
        display: none;
    }
    .date:nth-child(1){
        width: 25%;
        display: block;
        text-align: center;
    }
    .date:nth-child(2){
        width: 25%;
        display: block;
        text-align: center;
    }
    .date:nth-child(3){
        width: 25%;
        display: block;
        text-align: center;
    }
    .date:nth-child(4){
        width: 25%;
        display: block;
        text-align: center;
    }
    .date:nth-child(5){
        text-align: right;
        width: 25%;
        display: block;
     }
     .appstore{
        padding: 9px 8px;
     }
     .appstore p{
         font-size: 13px;
     }
     .header{
      padding:0px!important;
      border:none
     }
     .hideback{
         background:none;
         box-shadow:none
     }
     .errorbox {
        padding-left: 12px;
        padding-right: 12px !important;
        padding-top: 12px;
        width: 48px;
        height: 48px;
    }
    .closebutton {
        font-size: 28px;
        font-weight: 100;
        color: #7782A1;
        cursor: pointer;
    }
}
@media screen and (max-width:900px){
    .errorimg {
        height: 18px;
        width: 18px;
    }
    
}
